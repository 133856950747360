import { useMemo, useState } from 'react';

import { Dropdown, Tooltip } from '@cognite/cogs.js-v10';

import { Button } from '../../components';
import { TRACKING_TOKENS } from '../../constants';
import { useTranslation } from '../../hooks';
import { useDocumentStatusQuery, useServicesAvailability } from '../../service';
import { getDocumentsWithEmbeddings } from '../../utils';
import { AskQuestionMultipleFiles } from '../AskQuestionMultipleFiles';

export interface Props {
  fileIds: number[];
  buttonType?: 'iconOnly' | 'iconAndText';
  visible?: boolean;
  size?: 'small' | 'medium' | 'large';
  onOpenDocument: (documentId: string, page: string) => void;
  /**
   * Mixpanel tracking function
   * Passing this function as a prop to avoid coupling with cdf-route-tracker library
   * This library is packaged as npm package and used in infield and cdf-route-tracker does not work in infield
   */
  track?: (
    eventName: string | TRACKING_TOKENS,
    properties?: Record<string, unknown>
  ) => void;
}

export const AskQuestionMultipleFilesButton: React.FC<Props> = ({
  fileIds,
  buttonType = 'iconOnly',
  visible = true,
  onOpenDocument,
  size,
  track,
}) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>();
  const { documentQA, isLoading: loadingServicesAvailability } =
    useServicesAvailability();
  const { data: documentStatuses, isLoading: loadingDocumentsStatuses } =
    useDocumentStatusQuery(fileIds, documentQA);

  const searchableDocumentIds = useMemo(
    () => getDocumentsWithEmbeddings(documentStatuses || []),
    [documentStatuses]
  );

  const isDocumentQAServiceEnabled =
    !loadingServicesAvailability && !documentQA;

  const hasSearchableDocuments = searchableDocumentIds.length > 0;

  if (
    !visible ||
    loadingServicesAvailability ||
    loadingDocumentsStatuses ||
    (isDocumentQAServiceEnabled && hasSearchableDocuments)
  ) {
    return null;
  }

  return (
    <Dropdown
      visible={isDropdownOpen}
      maxWidth="100%"
      appendTo="parent"
      content={
        <AskQuestionMultipleFiles
          fileIds={searchableDocumentIds}
          onCloseClick={() => setIsDropdownOpen(false)}
          onQuestionAsked={(data) => track?.('CogAi.QuestionAsked', data)}
          onQuestionAnswered={(data) => track?.('CogAi.QuestionAnswered', data)}
          onSourceClick={(fileId: string, pageNumber: number) => {
            track?.('CogAi.OnPageSourceClicked', { fileId, pageNumber });
            onOpenDocument(fileId, pageNumber.toString());
          }}
        />
      }
    >
      <Tooltip
        disabled={searchableDocumentIds.length > 0 || loadingDocumentsStatuses}
        content={t('AI_BUTTON_DISABLED_TOOLTIP_NO_SEARCHABLE_DOCUMENTS')}
      >
        {buttonType === 'iconOnly' ? (
          <Button.Ai
            iconPlacement="right"
            disabled={searchableDocumentIds.length === 0}
            loading={loadingDocumentsStatuses}
            type="secondary"
            size={size}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          />
        ) : (
          <Button.AskQuestion
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            size={size}
          />
        )}
      </Tooltip>
    </Dropdown>
  );
};
