import { useCallback, useEffect, useMemo, useState } from 'react';

import { PlotMouseEvent } from 'plotly.js-dist-min';
import { useRecoilValue } from 'recoil';

import { CogniteClient } from '@cognite/sdk';
import { CogniteClientAlpha } from '@cognite/sdk-alpha';
import { useSDK } from '@cognite/sdk-provider';

import { useChartsContext } from '../../ChartsContext/ChartsContext';
import { DEFAULT_AGGREGATION_DATA_POINTS_LIMIT } from '../../constants';
import { CalculationCollectionEffects } from '../../effects/calculations';
import { CoreTimeseriesCollectionEffects } from '../../effects/core-time-series';
import { EventResultEffects } from '../../effects/events';
import { ScheduledCalculationCollectionEffects } from '../../effects/scheduled-calculations';
import { TimeseriesCollectionEffects } from '../../effects/timeseries';
import {
  availableWorkflows,
  interactionsAtom,
  timeseriesAtom,
  useScheduledCalculationDataValue,
} from '../../models';
import { useCoreTimeSeriesValue } from '../../models/core-timeseries-results';
import {
  eventResultsAtom,
  selectedEventsAtom,
} from '../../models/event-results/atom';
import { Chart, CoreTimeseries } from '../../types';
import { useBetaSDK } from '../../utils';
import { AlphaSDKProvider } from '../../utils/alphaSDKProvider';
import {
  ChartingContainer,
  ContainerClickEvent,
  PlotlyChart,
  cleanTimeseriesCollection,
  cleanWorkflowCollection,
} from '../PlotlyChart';

type Props = {
  chart?: Chart;
  isYAxisShown?: boolean;
  isMinMaxShown?: boolean;
  isGridlinesShown?: boolean;
  stackedMode?: boolean;
  mergeUnits?: boolean;
  setPlotlyChartRef?: (ref: any) => void;
  onClick?: (event: PlotMouseEvent) => void;
  onContainerClick?: (e: ContainerClickEvent) => void;
  aggregationDataPointLimit?: number;
  calculationStaleTime?: number;
} & Pick<
  React.ComponentProps<typeof PlotlyChart>,
  'onPlotNavigation' | 'scrollZoomEnabled' | 'scatterType' | 'dragmode'
>;

let alphaClientSingleton: CogniteClientAlpha | null = null;
const getAlphaClient = async (
  stable: CogniteClient,
  getProject: () => string,
  getToken: () => Promise<string>
) => {
  if (!alphaClientSingleton) {
    alphaClientSingleton = new CogniteClientAlpha({
      appId: 'fusion.cognite.com',
      project: getProject(),
      getToken,
      baseUrl: stable.getBaseUrl(),
    });
    await alphaClientSingleton.authenticate();
  }
  return alphaClientSingleton;
};

const ChartPlotContainer = ({
  chart = undefined,
  isYAxisShown = true,
  isMinMaxShown = false,
  isGridlinesShown = false,
  stackedMode = false,
  mergeUnits = false,
  dragmode,
  setPlotlyChartRef,
  onPlotNavigation,
  scrollZoomEnabled,
  scatterType,
  onClick,
  onContainerClick,
  calculationStaleTime,
  aggregationDataPointLimit = DEFAULT_AGGREGATION_DATA_POINTS_LIMIT,
}: Props) => {
  const { getProject, getToken } = useChartsContext();
  const timeseriesData = useRecoilValue(timeseriesAtom);
  const coreTimeSeriesData = useCoreTimeSeriesValue();
  const calculationsData = useRecoilValue(availableWorkflows);
  const eventData = useRecoilValue(eventResultsAtom);
  const scheduledCalculationsData = useScheduledCalculationDataValue();
  const interactionData = useRecoilValue(interactionsAtom);
  const client = useSDK();
  const betaClient = useBetaSDK();

  const [alphaClient, setAlphaClient] = useState<CogniteClientAlpha | null>(
    null
  );

  /**
   * Get local chart context
   */
  const dateFrom = chart?.dateFrom;
  const dateTo = chart?.dateTo;

  /**
   * Filter out callIDs that trigger unnecessary recalcs/rerenders
   */
  const tsCollectionAsString = JSON.stringify(
    cleanTimeseriesCollection(chart?.timeSeriesCollection || [])
  );
  const wfCollectionAsString = JSON.stringify(
    cleanWorkflowCollection(chart?.workflowCollection || [])
  );
  const coreTimeseriesAsString = JSON.stringify(
    cleanTimeseriesCollection<CoreTimeseries>(
      chart?.coreTimeseriesCollection || []
    )
  );

  useEffect(() => {
    getAlphaClient(client, getProject, getToken).then(setAlphaClient);
  }, []);

  const timeseries = useMemo(
    () => JSON.parse(tsCollectionAsString),
    [tsCollectionAsString]
  );

  const coreTimeSeries = useMemo(
    () => JSON.parse(coreTimeseriesAsString),
    [coreTimeseriesAsString]
  );

  const calculations = useMemo(
    () => JSON.parse(wfCollectionAsString),
    [wfCollectionAsString]
  );

  // no need to clean this as there are no call references in this
  const scheduledCalculations = chart?.scheduledCalculationCollection;

  const thresholds = chart?.thresholdCollection;
  const verticalMarkers = chart?.verticalMarkerCollection;

  const storedSelectedEvents = useRecoilValue(selectedEventsAtom);

  const onRef = useCallback(
    (ref: any) => setPlotlyChartRef?.(ref),
    [setPlotlyChartRef]
  );

  const hasValidDates =
    !Number.isNaN(new Date(dateFrom || '').getTime()) &&
    !Number.isNaN(new Date(dateTo || '').getTime());

  if (!hasValidDates || !betaClient || !alphaClient) {
    return null;
  }

  const plotProps: React.ComponentProps<typeof PlotlyChart> = {
    dateFrom,
    dateTo,
    timeseries,
    timeseriesData,
    coreTimeSeries,
    coreTimeSeriesData,
    calculations,
    calculationsData,
    scheduledCalculations,
    scheduledCalculationsData,
    eventData,
    thresholds,
    verticalMarkers,
    storedSelectedEvents,
    isYAxisShown,
    isMinMaxShown,
    isGridlinesShown,
    stackedMode,
    mergeUnits,
    dragmode,
    interactionData,
    onPlotNavigation,
    scrollZoomEnabled,
    scatterType,
    onClick,
    onContainerClick,
  };

  return (
    <>
      <AlphaSDKProvider alphaClient={alphaClient}>
        <TimeseriesCollectionEffects
          aggregationDataPointLimit={aggregationDataPointLimit}
        />
        <CoreTimeseriesCollectionEffects
          aggregationDataPointLimit={aggregationDataPointLimit}
        />
        <CalculationCollectionEffects
          calculationStaleTime={calculationStaleTime}
          aggregationDataPointLimit={aggregationDataPointLimit}
        />
        <EventResultEffects />
        <ScheduledCalculationCollectionEffects
          aggregationDataPointLimit={aggregationDataPointLimit}
        />
        <ChartingContainer>
          <PlotlyChart {...plotProps} ref={onRef} />
        </ChartingContainer>
      </AlphaSDKProvider>
    </>
  );
};

export default ChartPlotContainer;
