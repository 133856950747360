import type { Filters } from '@cognite/fdm-client';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

export const useActivityAggregateType = (filter?: Filters) => {
  const { activityService } = useFDMServices();

  const { activity: configFilters } = useAppConfigFiltersContext();

  const filters: Filters[] = [];
  if (configFilters.rootAssetExternalIds) {
    filters.push(configFilters.rootAssetExternalIds);
  }
  if (filter) {
    filters.push(filter);
  }

  return useQuery(
    [QueryKeys.ACTIVITY_AGGREGATE_BY_TYPE, filters],
    async () => {
      return activityService.getActivitiesAggregateTypes({
        and: filters,
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: filters.length > 0,
    }
  );
};
