import type {
  APMAsset,
  FdmFile,
  Observation,
  ObservationStatus,
} from '@cognite/apm-client';
import type { Asset } from '@cognite/sdk';
import { QueryKeys } from '@infield/utils/queryKeys';
import type { QueryClient } from '@tanstack/react-query';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { v4 as uuid } from 'uuid';

import { DEFAULT_STATUS } from './constants';
import type { Step } from './types';

export const getIsObservationLocked = (observation?: Observation) =>
  observation?.status === 'Sent' || observation?.status === 'File not sent';

export const isFieldValueSet = (
  value?: string | object | null | boolean | number
) => {
  return (
    Boolean(value) &&
    ((typeof value === 'string' && value.trim() !== '') ||
      (typeof value === 'number' && !Number.isNaN(value)) ||
      (isArray(value) && value.length > 0) ||
      (isObject(value) && Object.keys(value).length > 0))
  );
};

export const getCompletedStepsNumber = (
  observation: Observation,
  steps: Step[]
): number => {
  let completedSteps = 0;

  steps.forEach((step) => {
    const { key } = step;

    const isStepCompleted = isFieldValueSet(observation[key]);
    if (isStepCompleted) {
      completedSteps += 1;
    }
  });

  return completedSteps;
};

export const getIsAllRequiredFieldsFilled = (
  observation: Observation,
  steps: Step[]
) =>
  steps.length > 0 &&
  steps.every(
    ({ key, isRequired }) => !isRequired || isFieldValueSet(observation[key])
  );

export const getDefaultObservation: (asset?: APMAsset) => Observation = (
  asset
) => ({
  externalId: uuid(),
  asset,
  status: DEFAULT_STATUS,
});

export const getShowCompleteButton = (
  isSapWriteBackEnabled: boolean,
  status?: ObservationStatus
) => !isSapWriteBackEnabled && status !== 'Completed' && status !== 'Sent';

export const getShowSendToSapButton = (
  isSapWriteBackEnabled: boolean,
  status?: ObservationStatus
) => isSapWriteBackEnabled && status !== 'Sent';

export const getContextualizedAssetsForMedia = (
  asset?: Asset,
  apmAsset?: APMAsset,
  rootAsset?: Asset
) => {
  if (asset && apmAsset) return [asset.id];
  if (rootAsset) return [rootAsset.id];
  return undefined;
};

export const prepareMediaUpdates = (
  files: (FdmFile | null)[],
  contextualizedAssets?: number[]
) => {
  return files.map((file) => ({
    externalId: file?.externalId,
    assetIds: contextualizedAssets,
  }));
};

export const invalidateMediaQueries = (
  queryClient: QueryClient,
  previousAssetExternalId?: string,
  newAssetExternalId?: string
) => {
  if (previousAssetExternalId) {
    queryClient.invalidateQueries([
      QueryKeys.MEDIA_LIST,
      previousAssetExternalId,
    ]);
  }
  if (newAssetExternalId) {
    queryClient.invalidateQueries([QueryKeys.MEDIA_LIST, newAssetExternalId]);
  }
};
