import type { InFieldUser } from '@cognite/apm-client';
import type { Discipline } from '@cognite/apm-config';
import type { DateRangeType } from '@cognite/apm-observation';
import {
  Body,
  Button,
  ChecklistIcon,
  Divider,
  Flex,
} from '@cognite/cogs.js-v10';
import { Assignment } from '@infield/features/assignment';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { DateRangePicker } from '@infield/features/ui';
import dayjs from 'dayjs';
import { useState } from 'react';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  visible: boolean;
  title: string;
  subtitle?: string;
  saveDescription: string;
  activityAssignees?: {
    disciplines: Discipline[] | undefined;
    users: InFieldUser[] | undefined;
  };
  isCreateChecklistLoading: boolean;
  onSave: (startTime: string, endTime: string, assignedTo?: string[]) => void;
  onClose: () => void;
}

export const ActivityCreateChecklistModalContent: FC<Props> = ({
  visible,
  title,
  subtitle,
  saveDescription,
  activityAssignees,
  isCreateChecklistLoading,
  onSave: onCreateChecklist,
  onClose,
}) => {
  const { Trans, t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const [dateFilter, setDateFilter] = useState<DateRangeType>([
    dayjs().startOf('day'),
    dayjs().endOf('day'),
  ]);
  const [assignedTo, setAssignedTo] = useState<string[]>();

  const handleSave = () => {
    if (dateFilter[0] && dateFilter[1]) {
      onCreateChecklist(
        dateFilter[0].utc().toISOString(),
        dateFilter[1].utc().toISOString(),
        assignedTo
      );
    }
  };

  return (
    <S.StyledModal
      visible={visible}
      icon={<ChecklistIcon />}
      size="small"
      title={title}
      subtitle={subtitle}
      hideFooter
      onCancel={onClose}
      disableEnforceFocus
    >
      <Flex direction="column" justifyContent="space-between" gap={8}>
        <Flex direction="column" gap={16}>
          <Trans i18nKey="ACTIVITY_DIALOGUE_OPEN_AS_CHECKLIST_DESCRIPTION">
            InField creates a checklist based on the selected operation(s). You
            can begin executing the checklist immediately by assigning it to a
            discipline or users.
          </Trans>
          <Flex direction="column" gap={4}>
            <Body size="medium" strong>
              {t(
                'ACTIVITY_DIALOGUE_OPEN_AS_CHECKLIST_TIME_TITLE',
                'Planned start & end time'
              )}
            </Body>
            <DateRangePicker
              defaultValue={dateFilter}
              fullWidth
              onChange={setDateFilter}
            />
            <S.DateRangeHelpText>
              {t(
                'ACTIVITY_DIALOGUE_OPEN_AS_CHECKLIST_TIME_DESCRIPTION',
                'This is when you expect to start and finish your work, and is only used for filtering in InField. You can use the checklist immediately.'
              )}
            </S.DateRangeHelpText>
          </Flex>
        </Flex>
        <Divider spacing="16px" />
        <Assignment
          onChange={setAssignedTo}
          activityAssignees={activityAssignees}
        />
        <Flex justifyContent="end" gap={8}>
          <Button
            type="ghost"
            onClick={onClose}
            data-testid="create-checklist-modal-close"
          >
            {t('ACTIVITY_DIALOGUE_OPEN_AS_CHECKLIST_BUTTON_CANCEL', 'Cancel')}
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            loading={isCreateChecklistLoading}
            data-testid="create-checklist-modal-save"
          >
            {saveDescription}
          </Button>
        </Flex>
      </Flex>
    </S.StyledModal>
  );
};
