import type { Language, LanguageCode } from '@cognite/user-profile-components';
import { useTranslation } from '@infield/features/i18n';
import dayjs from 'dayjs';
import enGB from 'dayjs/locale/en-gb';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DEFAULT_LANGUAGE, languageLabels } from './constants';
import { getAvailableLanguages } from './i18n';
import type { AvailableLanguages } from './types';
import { getIsUsingUSFormat, SELECTED_LANGUAGE_KEY } from './utils';

export const useInfieldTranslation = () => {
  const { i18n } = useTranslation();
  const [availableLanguages, setAvailableLanguages] =
    useState<AvailableLanguages>();

  const updateLanguage = useCallback(
    (language?: Language) => {
      if (language?.code) {
        i18n.changeLanguage(language.code);
        localStorage.setItem(SELECTED_LANGUAGE_KEY, language.code);

        if (language.code === 'en') {
          if (getIsUsingUSFormat()) {
            dayjs.locale(language.code);
          } else {
            dayjs.locale(enGB);
          }
          return;
        }

        dayjs.locale(language.code);
      }
    },
    [i18n]
  );

  const getInitialLanguage = useCallback(() => {
    const selectedLanguage = localStorage.getItem(
      SELECTED_LANGUAGE_KEY
    ) as LanguageCode | null;
    if (selectedLanguage) {
      updateLanguage({ code: selectedLanguage, label: '' });
    } else {
      updateLanguage(DEFAULT_LANGUAGE);
    }
  }, [updateLanguage]);

  useEffect(() => {
    getAvailableLanguages().then((res) => {
      setAvailableLanguages(res);
    });
  }, [setAvailableLanguages]);

  const supportedLanguages: Language[] = useMemo(() => {
    const languages = Object.keys(availableLanguages || {}).map(
      (languageCode) => ({
        code: languageCode as Language['code'],
        label:
          languageLabels[languageCode as Language['code']] ||
          availableLanguages?.[languageCode].nativeName ||
          '',
      })
    );
    if (!languages) return [DEFAULT_LANGUAGE];
    return languages.sort((a, b) => (a.label >= b.label ? 1 : -1));
  }, [availableLanguages]);

  const selectedLanguage = useMemo(() => {
    return (
      supportedLanguages.find((language) => language.code === i18n.language) ||
      supportedLanguages.find(
        (language) => language.code === DEFAULT_LANGUAGE.code
      ) ||
      supportedLanguages[0]
    );
  }, [i18n.language, supportedLanguages]);

  return {
    updateLanguage,
    getInitialLanguage,
    selectedLanguage,
    supportedLanguages,
  };
};
