import { CounterChip, Flex, StatusChip } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ColumnCounterChip = styled(CounterChip)<{ $toggled: boolean }>`
  margin-left: 6px;
  ${({ $toggled }) =>
    $toggled &&
    'background: var(--cogs-surface--interactive--toggled-default)'};

  .cogs-counterchip--text {
    ${({ $toggled }) =>
      $toggled && 'color: var(--cogs-text-icon--interactive--default)'};
  }
`;

export const DropdownContainer = styled(Flex).attrs({ direction: 'column' })`
  max-height: calc(100vh - 180px);
  overflow: auto;

  .Joy-checked {
    color: var(--cogs-themed-text-icon--on-contrast--strong);
  }
`;

export const LoaderChip = styled(StatusChip)`
  margin-left: 6px;
`;
