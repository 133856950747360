import type { APMAsset } from '@cognite/apm-client';
import {
  Button,
  CameraAddIcon,
  CloseLargeIcon,
  DeleteIcon,
  EditIcon,
  Flex,
  Menu,
  UrgentIcon,
} from '@cognite/cogs.js-v10';
import {
  useFeatureToggleConfig,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { OpenedObservationDetails } from '@infield/features/observation';
import { DismissableContent } from '@infield/features/ui';
import { getIsObservationsPageEnabled } from '@infield/pages/utils';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  hasNote: boolean;
  checklistItemExternalId?: string;
  asset?: APMAsset;
  onClose: () => void;
  onDismiss: () => void;
  onMediaClick: () => void;
  onAddNoteClick: () => void;
  onDeleteNoteClick: () => void;
  onCreateObservationClick: (
    observationDetails: OpenedObservationDetails
  ) => void;
}

export const ChecklistItemReportMenu: FC<Props> = ({
  checklistItemExternalId,
  onClose,
  onDismiss,
  onMediaClick,
  hasNote,
  asset,
  onAddNoteClick,
  onDeleteNoteClick,
  onCreateObservationClick,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);
  const { isOnline } = useNetworkStatusContext();

  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );
  const isObservationsFeatureEnabled = getIsObservationsPageEnabled(
    rootLocationFeatureToggles
  );

  return (
    <DismissableContent onDismiss={onDismiss}>
      <S.Container>
        <Flex gap={8} alignItems="center" justifyContent="space-between">
          <S.Container.Header>
            {t('CHECKLIST_TASK_REPORT_MENU_TITLE', 'Add additional reports')}
          </S.Container.Header>
          <Button
            aria-label="close-additional-reports-menu"
            data-testid="close-additional-reports-menu-button"
            icon={<CloseLargeIcon />}
            type="ghost"
            onClick={onClose}
          />
        </Flex>
        <Menu.Item
          key="media"
          icon={<CameraAddIcon />}
          iconPlacement="left"
          data-testid="add-checklist-image-button"
          onClick={onMediaClick}
          disabled={!isOnline}
        >
          {t(
            'CHECKLIST_TASK_REPORT_MENU_ACTION_ADD_MEDIA',
            'Upload images or videos'
          )}
        </Menu.Item>
        {hasNote ? (
          <Menu.Item
            key="note"
            icon={<DeleteIcon />}
            iconPlacement="left"
            destructive
            onClick={onDeleteNoteClick}
            data-testid="delete-checklist-note-button"
          >
            {t('CHECKLIST_TASK_REPORT_MENU_ACTION_DELETE_NODE', 'Delete note')}
          </Menu.Item>
        ) : (
          <Menu.Item
            key="note"
            icon={<EditIcon />}
            iconPlacement="left"
            onClick={onAddNoteClick}
            data-testid="add-checklist-note-button"
          >
            {t('CHECKLIST_TASK_REPORT_MENU_ACTION_ADD_NODE', 'Write note')}
          </Menu.Item>
        )}
        {isObservationsFeatureEnabled && (
          <Menu.Item
            key="observation"
            icon={<UrgentIcon />}
            iconPlacement="left"
            data-testid="add-checklist-observation-button"
            onClick={() =>
              onCreateObservationClick({
                checklistItemExternalId,
                asset,
              })
            }
            disabled={!isOnline}
          >
            {t(
              'CHECKLIST_TASK_REPORT_MENU_ACTION_ADD_OBSERVATION',
              'Create observation'
            )}
          </Menu.Item>
        )}
      </S.Container>
    </DismissableContent>
  );
};
