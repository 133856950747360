import Konva from 'konva';

import {
  AnnotationType,
  LineType,
  ShapeAnchorPoint,
} from '../../annotations/types';
import { UnifiedViewerNodeType } from '../../types';

export const TOP_AND_BOTTOM_ANCHORS = ['middle-right', 'middle-left'] as const;
export const ALL_ANCHORS = [
  'top-center',
  'middle-right',
  'middle-left',
  'bottom-center',
] as const;

const STROKE_WIDTH = 10;

export const TARGET_NODE_PROPS = {
  label: {
    unifiedViewerType: UnifiedViewerNodeType.ANNOTATION,
    annotationType: AnnotationType.CAUSE_MAP_NODE,
    strokeScaleEnabled: false,
    userGenerated: true,
    isSelectable: true,
    isDraggable: true,
    isResizable: true,
    visible: true,
  },
  tag: {
    fill: 'white',
    stroke: 'black',
    strokeWidth: STROKE_WIDTH,
    cornerRadius: 10,
    width: 500,
    height: 400,
  },
  text: {
    text: '',
    fill: 'black',
    width: 500,
    height: 400,
    align: 'center',
    verticalAlign: 'middle',
    padding: 15,
    fontSize: 64,
  },
};

// Cause maps go from left to right, so the default side for the from-node is
// the right side and the default side for the to-node is the left side
export const FROM_ANCHOR_POINT = ShapeAnchorPoint.RIGHT;
export const TO_ANCHOR_POINT = ShapeAnchorPoint.LEFT;

export const TARGET_ARROW_CONFIG: Konva.ArrowConfig = {
  name: 'target-arrow',
  annotationType: AnnotationType.POLYLINE,
  stroke: 'black',
  opacity: 0,
  strokeWidth: STROKE_WIDTH,
  pointerAtBeginning: true,
  pointerAtEnding: false,
  listening: false,
  points: [],
  x: 0,
  y: 0,
  lineType: LineType.ELBOWED,
  isResizable: false,
  isDraggable: false,
  anchorStartTo: FROM_ANCHOR_POINT,
  anchorEndTo: TO_ANCHOR_POINT,
  visible: true,
};

export const MAX_ANCHOR_SCALE = 0.14;
export const DEFAULT_ANCHOR_PADDING_PX = 60;
export const MIN_ANCHOR_PADDING_PX = 20;
export const MAX_ANCHOR_PADDING_PX = 200;
