import { Colors } from '@cognite/cogs-core';
import { CogniteIcon } from '@cognite/cogs-icons';
import { Body, Button, Flex, WorldIcon } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

const blueAccent300 = '#6daaff';
const MAX_SIDEBAR_WIDTH = '420px';

export const StyledSidebarContainer = styled(Flex)<{ $isCollapsed: boolean }>`
  position: absolute;
  flex-direction: column;
  gap: 24px;
  z-index: ${z.MOBILE_SIDEBAR};
  width: 100%;
  max-width: ${MAX_SIDEBAR_WIDTH};
  top: 0;
  left: ${(props) => (props.$isCollapsed ? `-${MAX_SIDEBAR_WIDTH}` : 0)};

  padding: 6px 0 12px;
  visibility: ${(props) => (props.$isCollapsed ? 'hidden' : 'visible')};
  transition: visibility 0.45s, left 0.5s;
  height: 100%;
  background-color: ${Colors['surface--muted--inverted']};

  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledHeaderContainer = styled.div`
  padding: 0 8px;
  display: flex;
  white-space: nowrap;
`;

export const StyledSidebarHeaderLeft = styled(Flex)`
  align-items: center;
  gap: 6px;
  padding: 6px;
`;

export const StyledLogoContainer = styled.div`
  color: ${Colors['text-icon--on-contrast--strong--inverted']};
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-color: ${blueAccent300};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCogniteIcon = styled(CogniteIcon)`
  margin-right: 0px;
  height: 20px;
  width: 20px;
`;

export const StyledSidebarTitle = styled(Body).attrs({
  size: 'medium',
  inverted: true,
  strong: true,
})`
  display: flex;
  align-items: center;
  cursor: default;
`;

export const StyledCloseButton = styled(Button)`
  margin-left: auto;
`;

export const StyledButton = styled(Button)`
  &.cogs-button {
    justify-content: left;
    align-items: center;
    gap: 12px;
    font-weight: 400;
    border-radius: 0;
    border-width: 0;
    border-style: solid;
    border-color: ${Colors['border--interactive--default--inverted']};
    padding: 6px 20px;
    width: 100%;
    height: 36px;

    &:hover:not(:focus-visible),
    &:active:not(:focus-visible) {
      border-left-width: 4px;
      padding-left: 16px;
    }

    :focus-visible {
      box-shadow: 0px 0px 0px 2px
        ${Colors['border--interactive--toggled-default']} inset;
    }

    &.cogs-button--toggled {
      background-color: ${Colors['surface--action--muted--default--inverted']};
      border-color: ${blueAccent300};
      color: ${blueAccent300};
      border-left-width: 4px;
      padding-left: 16px;

      &:hover {
        background-color: ${Colors['surface--action--muted--hover--inverted']};
        color: ${blueAccent300};
      }

      &:active {
        background-color: ${Colors[
          'surface--action--muted--pressed--inverted'
        ]};
        color: ${blueAccent300};
      }
    }
  }
`;

export const SidebarActionButton = styled(Button).attrs({
  type: 'ghost',
  size: 'large',
  themeProps: { theme: 'base', inverted: true },
})`
  &.cogs-button {
    width: 100%;
    justify-content: left;
    border-radius: 0;
    padding-left: 20px;
  }
`;

export const SidebarUserActionsWrapper = styled.div`
  margin-top: auto;
`;

export const SidebarOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${z.MOBILE_SIDEBAR_OVERLAY};
`;

export const LocationSelector = styled(Button).attrs({
  icon: <WorldIcon />,
  themeProps: { theme: 'base', inverted: true },
})`
  justify-content: left !important;
  padding: 10px 14px;
  margin: 2px 10px;
  gap: 8px;
`;
