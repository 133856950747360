import Konva from 'konva';

import { DocumentContainer } from '../../containers';
import type { UnifiedViewer } from '../../UnifiedViewer';
import downloadUrl from '../downloadUrl';
import isAnnotationNode from '../isAnnotationNode';
import isNotUndefined from '../isNotUndefined';
import isSingleDocumentAnnotationNode from '../isSingleDocumentAnnotationNode';
import { getEnclosingRectFromRects, isRectInsideRect } from '../rectUtils';
import withoutFileExtension from '../withoutFileExtension';

import { DEFAULT_FILENAME, DEFAULT_PAGE_OPTIONS } from './constants';
import getPrintStage, { findContentGroup } from './getPrintStage';
import {
  createFootNotesTextNode,
  exportPageDataToPdf,
  getPngEncodedBuffers,
} from './pdfUtilts';
import type {
  ExportPage,
  ExportPageData,
  ExportPdfOptions,
  ImageBuffer,
  PngBuffer,
} from './types';

const getStageAsBuffer = async (
  stage: Konva.Stage
): Promise<PngBuffer | undefined> => {
  const printStageCanvas = stage.toCanvas();
  const context = printStageCanvas.getContext('2d', {
    willReadFrequently: true,
  });
  const imageData = context?.getImageData(
    0,
    0,
    printStageCanvas.width,
    printStageCanvas.height
  );
  if (imageData === undefined) {
    return undefined;
  }
  const buffers = await getPngEncodedBuffers([
    { image: imageData, ...stage.size() },
  ]);
  return buffers[0];
};

const getPagesAsExportData = async (
  stage: Konva.Stage,
  viewer: UnifiedViewer,
  pages: ExportPage[]
): Promise<ExportPageData[] | undefined> => {
  const nodes =
    stage
      .getLayers()
      .find((layer) => layer.name() === viewer.layers.main.layer.name())
      ?.getChildren() ?? [];

  // The rasterized documents are hidden when exporting, in favor of copying the
  // PDF data directly. In order to draw annotations on top of them, we need to
  // render the annotations with transparency, so hide the background layer.
  const backgroundLayer = stage
    .getLayers()
    .find(
      (layer) =>
        layer.name() === `${viewer.layers.background.layer.name()}-copy`
    );
  if (backgroundLayer !== undefined) {
    backgroundLayer.remove();
  }

  // Remove the connections since they end up polluting the bounding box
  // calculations that we perform later
  nodes.forEach((node) => {
    if (
      node.getAttr('fromId') === undefined &&
      node.getAttr('toId') === undefined
    ) {
      return;
    }
    node.remove();
  });

  const canvasAnnotations = nodes.filter(
    (n) => isAnnotationNode(n) && !isSingleDocumentAnnotationNode(n)
  );

  const imageTasks: Array<{
    pageExportData: ExportPageData;
    task: ImageBuffer;
  }> = [];

  const exportData: Array<Promise<ExportPageData | undefined>> = pages.map(
    async (page) => {
      const {
        containerId,
        shouldIncludeContainerAnnotations = DEFAULT_PAGE_OPTIONS.shouldIncludeContainerAnnotations,
        shouldIncludeCanvasAnnotations = DEFAULT_PAGE_OPTIONS.shouldIncludeCanvasAnnotations,
        footNotes = DEFAULT_PAGE_OPTIONS.footNotes,
        footNoteStyle,
      } = page;

      const pageExportData: ExportPageData = {};

      const container = viewer.getContainerById(containerId);
      if (container === undefined) {
        // eslint-disable-next-line no-console
        console.warn(`Did not find container with id '${containerId}'`);
        return undefined;
      }

      const containerNode = nodes.find((n) => n.id() === containerId);
      if (
        containerNode === undefined ||
        !(containerNode instanceof Konva.Group)
      ) {
        // eslint-disable-next-line no-console
        console.warn(
          `Did not find container with id '${containerId}' in the stage`
        );
        return undefined;
      }

      const containerContentNode = findContentGroup(containerNode);
      if (containerContentNode === undefined) {
        // eslint-disable-next-line no-console
        console.warn(
          `Ignoring container '${containerId}', since it did not have a content node.`
        );
        return undefined;
      }

      if (container instanceof DocumentContainer) {
        const pdf = await container.getPdf();
        pageExportData.pdf = {
          data: await pdf.getData(),
          visiblePage: container.pageNumber - 1,
          pages: page.pages?.map((page) => page - 1),
        };
        containerContentNode.visible(false);
      }

      const containerAnnotations = (containerNode.children ?? []).filter(
        (child) => child !== containerContentNode
      );

      const annotationsToInclude = [];

      for (const canvasAnnotation of canvasAnnotations) {
        canvasAnnotation.visible(shouldIncludeCanvasAnnotations);
        if (shouldIncludeCanvasAnnotations) {
          annotationsToInclude.push(canvasAnnotation);
        }
      }

      for (const containerAnnotation of containerAnnotations) {
        containerAnnotation.visible(shouldIncludeContainerAnnotations);
        if (shouldIncludeContainerAnnotations) {
          annotationsToInclude.push(containerAnnotation);
        }
      }

      const contentContainerRect = containerContentNode.getClientRect();
      const overlappingCanvasAnnotationRects = annotationsToInclude
        .map((n) => n.getClientRect())
        .filter((rect) => isRectInsideRect(rect, contentContainerRect));

      const containerRect = getEnclosingRectFromRects(
        contentContainerRect,
        overlappingCanvasAnnotationRects
      );

      const containerSize = {
        width: containerRect.width,
        height: containerRect.height,
      };

      if (footNotes.length > 0) {
        containerNode.add(
          createFootNotesTextNode(footNotes, containerSize, footNoteStyle)
        );
      }

      if (annotationsToInclude.length > 0 || footNotes.length > 0) {
        const printStageCanvas = stage.toCanvas();
        const printStageContext = printStageCanvas.getContext('2d', {
          willReadFrequently: true,
        });
        const imageData = printStageContext?.getImageData(
          containerRect.x,
          containerRect.y,
          containerRect.width,
          containerRect.height
        );
        if (imageData !== undefined) {
          imageTasks.push({
            pageExportData,
            task: { image: imageData, ...containerSize },
          });
        }
      }

      return pageExportData;
    }
  );
  const readyExportData = (await Promise.all(exportData)).filter(
    isNotUndefined
  );
  const imageBuffers = imageTasks.map(({ task }) => task);
  const pngBuffers = await getPngEncodedBuffers(imageBuffers);
  pngBuffers.forEach((pngBuffer, index) => {
    imageTasks[index].pageExportData.png = pngBuffer;
  });

  return readyExportData;
};

const exportWorkspaceToPdf = async (
  viewer: UnifiedViewer,
  options?: ExportPdfOptions
): Promise<void> => {
  const {
    shouldForceFileExtension = true,
    shouldExportCanvasCoverPage = true,
    fileName: inputFileName = DEFAULT_FILENAME,
    pages,
  }: ExportPdfOptions = options || {};

  const printStage = await getPrintStage(viewer);

  const stageData: ExportPageData | undefined =
    shouldExportCanvasCoverPage === true
      ? { png: await getStageAsBuffer(printStage) }
      : undefined;
  const pageData =
    pages !== undefined && pages.length > 0
      ? await getPagesAsExportData(printStage, viewer, pages)
      : undefined;
  printStage.destroy();

  const pdfData = await exportPageDataToPdf(
    [stageData, ...(pageData ?? [])].filter(hasExportData)
  );
  const fileName = shouldForceFileExtension
    ? `${withoutFileExtension(inputFileName)}.pdf`
    : inputFileName;
  downloadUrl(pdfData, fileName);
};

const hasExportData = (
  exportData: ExportPageData | undefined
): exportData is ExportPageData => {
  return exportData?.png !== undefined || exportData?.pdf !== undefined;
};

export default exportWorkspaceToPdf;
