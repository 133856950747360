import { Button, Flex } from '@cognite/cogs.js-v10';
import { ComponentScrollableContent } from '@infield/features/ui';
import styled from 'styled-components';

export const TemplateList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

interface TemplateListItemProps {
  $isSelected: boolean;
}
export const TemplateListItem = styled.li<TemplateListItemProps>`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
  cursor: pointer;
  height: 84px;
  word-break: break-word;
  &:nth-child(odd) {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme['surface--interactive--toggled-default']
        : theme['surface--muted']};
  }
  &:nth-child(even) {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme['surface--interactive--toggled-default']
        : theme['surface--medium']};
  }

  &:hover {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme['surface--interactive--toggled-hover']
        : theme['surface--interactive--hover']};
  }
`;

export const TemplateListItemTitle = styled.h4`
  margin-bottom: 8px;
  line-height: 21px;
  height: 42px;
  overflow: hidden;
`;

export const ScrollableContent = styled(ComponentScrollableContent)`
  &&& {
    height: calc(100vh - 215px);
  }
`;

export const EmptyStateContainer = styled(Flex)`
  justify-content: center;
  margin: 16px;
`;

export const ExtraActionsButton = styled(Button)`
  &.cogs-v10.cogs-button {
    border-radius: 0;
  }
`;
