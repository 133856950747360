import { useEffect } from 'react';

import { LoadingStatus } from '../asyncContainerUtils';

// Shameful because we don't have a good way of knowing when the render is completed
// so we just delay it by some time.
const SHAMEFUL_ON_STATUS_CHANGED_DELAY_MS = 100;

const useStatusChange = <T>({
  data,
  isLoading,
  isError,
  setLoadingStatus,
}: {
  data: T | undefined;
  isLoading: boolean;
  isError: boolean;
  setLoadingStatus: (status: LoadingStatus) => void;
}): void => {
  useEffect(() => {
    setTimeout(() => {
      if (isLoading) {
        setLoadingStatus(LoadingStatus.LOADING);
        return;
      }
      if (isError) {
        setLoadingStatus(LoadingStatus.ERROR);
        return;
      }
      if (data !== undefined) {
        setLoadingStatus(LoadingStatus.SUCCESS);
        return;
      }
    }, SHAMEFUL_ON_STATUS_CHANGED_DELAY_MS);
  }, [isLoading, isError, data, setLoadingStatus]);
};

export default useStatusChange;
