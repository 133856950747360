import { Flex } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { linkifyOptions } from '@infield/utils/linkify';
import Linkify from 'linkify-react';
import type { MutableRefObject } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import * as S from './elements';
import { scrollToBottom } from './utils';

type Props = {
  value: string;
  placeholder?: string;
  characterLimit?: number;
  characterLimitMessage?: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  onFocus?: () => void;
};

export const TaskTextarea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      value,
      placeholder,
      characterLimit,
      characterLimitMessage,
      onChange,
      onBlur,
      onFocus,
    },
    ref
  ) => {
    const textAreaRef = ref as MutableRefObject<HTMLTextAreaElement | null>;
    const { t } = useTranslation(LOCIZE_NAMESPACES.template);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [textareaHeight, setTextareaHeight] = useState<number>(83);
    const limitReached = characterLimit
      ? value.length >= characterLimit
      : false;

    useEffect(() => {
      if (isFocused && textAreaRef.current) {
        const { length } = value;
        textAreaRef.current.focus();
        const cursorPosition = textAreaRef.current.selectionStart || length;
        textAreaRef.current.setSelectionRange(cursorPosition, cursorPosition);
        if (cursorPosition === length) {
          scrollToBottom(textAreaRef.current);
        }
      }
    }, [isFocused, t, value, value.length, textAreaRef]);

    useEffect(() => {
      if (isFocused && onFocus) onFocus();
    }, [isFocused, onFocus]);

    return (
      <Flex direction="column" gap={4}>
        {isFocused ? (
          <S.StyledTextArea
            data-testid="task-textarea-input"
            height={textareaHeight}
            ref={ref}
            onChange={(e) => {
              if (characterLimit && e.target.value.length > characterLimit) {
                return;
              }
              onChange(e.target.value);
            }}
            value={value}
            onBlur={() => {
              setIsFocused(false);
              onBlur(value);
            }}
          />
        ) : (
          <S.StyledDivContainer>
            <S.StyledDiv
              data-testid="task-textarea-input"
              onClick={(e) => {
                setTextareaHeight(e.currentTarget.clientHeight);
                setIsFocused(true);
              }}
              data-placeholder={value.length === 0 ? placeholder : ''}
              className="data-placeholder"
            >
              <Linkify options={linkifyOptions}>{value}</Linkify>
            </S.StyledDiv>
          </S.StyledDivContainer>
        )}
        {characterLimit && (
          <Flex>
            {characterLimitMessage && (
              <S.LimitMessage $limitReached={limitReached}>
                {characterLimitMessage}
              </S.LimitMessage>
            )}
            <S.LimitCounter
              $limitReached={limitReached}
            >{`${value.length}/${characterLimit}`}</S.LimitCounter>
          </Flex>
        )}
      </Flex>
    );
  }
);
