import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { CogniteClient } from '@cognite/sdk';
import { SDKProvider } from '@cognite/sdk-provider';

import {
  FdmInstance,
  TimeseriesAcdmInstance,
  TimeseriesDisplayMode,
} from '../../types';
import { ReactContainerRenderContentProps } from '../ReactContainer';

import TimeseriesGraphContent from './TimeseriesGraphContent';
import TimeseriesValueContent from './TimeseriesValueContent';
import { TimeseriesContainerStyleProperties } from './types';

export type TimeseriesContentProps = Pick<
  ReactContainerRenderContentProps,
  'width' | 'height' | 'unscaledWidth' | 'unscaledHeight' | 'setLoadingStatus'
> & {
  instance: TimeseriesAcdmInstance | FdmInstance;
  startDate: Date;
  endDate: Date;
  onRangeChange: (startDate: Date, endDate: Date) => void;
  numberOfPoints?: number | undefined;
  displayMode?: TimeseriesDisplayMode;
  style?: TimeseriesContainerStyleProperties;
};

const TimeseriesContent: React.FC<TimeseriesContentProps> = ({
  width,
  height,
  unscaledWidth,
  unscaledHeight,
  startDate,
  endDate,
  onRangeChange,
  instance,
  setLoadingStatus,
  numberOfPoints,
  displayMode = TimeseriesDisplayMode.GRAPH,
  style,
}): JSX.Element => {
  const Component =
    displayMode === TimeseriesDisplayMode.GRAPH
      ? TimeseriesGraphContent
      : TimeseriesValueContent;

  return (
    <Component
      {...{
        width,
        height,
        unscaledWidth,
        unscaledHeight,
        startDate,
        endDate,
        onRangeChange,
        instance,
        setLoadingStatus,
        numberOfPoints,
        style,
      }}
    />
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 10 * 60 * 1000, // Pretty long, 600 seconds
    },
  },
});

const WrappedTimeseriesContent = ({
  sdk,
  ...props
}: TimeseriesContentProps & { sdk: CogniteClient }): JSX.Element => {
  return (
    <SDKProvider sdk={sdk}>
      <QueryClientProvider client={queryClient}>
        <TimeseriesContent {...props} />
      </QueryClientProvider>
    </SDKProvider>
  );
};

export default WrappedTimeseriesContent;
