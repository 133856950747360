import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useSpaceQuery = (space: string[] | string, enabled: boolean) => {
  const { client } = useAuthContext();

  return useQuery({
    queryKey: [QueryKeys.FDM_SPACE_BYID, space],
    queryFn: async () => {
      return client.spaces
        .retrieve(typeof space === 'string' ? [space] : space)
        .then((res) => res.items);
    },
    onError: (err) =>
      captureException(err, {
        level: 'error',
        tags: {
          queryKey: QueryKeys.FDM_SPACE_BYID,
        },
      }),
    enabled: enabled && space !== '',
    retry: false,
  });
};
