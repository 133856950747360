export function isFileExtensionValid(
  file: Pick<File, 'name' | 'type'>,
  validExtensions: string | string[]
) {
  if (file && validExtensions) {
    const validExtensionsArray = Array.isArray(validExtensions)
      ? validExtensions
      : validExtensions.split(',');
    const fileName = file.name || '';
    const mimeType = (file.type || '').toLowerCase();
    const baseMimeType = mimeType.replace(/\/.*$/, '');

    return validExtensionsArray.some((type) => {
      const validType = type.trim().toLowerCase();
      if (validType.charAt(0) === '.') {
        return fileName.toLowerCase().endsWith(validType);
      }
      if (validType.endsWith('/*')) {
        // This is something like a image/* mime type
        return baseMimeType === validType.replace(/\/.*$/, '');
      }
      return mimeType === validType;
    });
  }
  return true;
}
