import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import type { Group } from '../types';

import {
  extractSpaceIdsRead,
  extractSpaceIdsWrite,
  hasGroupWithScopeAllRead,
} from './utils';

export const useUserSpaceAccess = () => {
  const { client } = useAuthContext();

  return useQuery<boolean, Error>(
    [QueryKeys.USER, 'userSpaceAccess'],
    async () => {
      const groups = (await client.groups.list()) as Group[];

      const hasReadAllSpacesAccess = hasGroupWithScopeAllRead(groups);
      const spaceIdsWithReadAccess = extractSpaceIdsRead(groups);
      const spaceIdsWithWriteAccess = extractSpaceIdsWrite(groups);

      const hasAccess =
        hasReadAllSpacesAccess ||
        (Boolean(spaceIdsWithReadAccess.includes('cognite_app_data')) &&
          Boolean(spaceIdsWithWriteAccess.includes('cognite_app_data')));

      return hasAccess;
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: `${QueryKeys.USER}, userSpaceAccess`,
          },
        }),
    }
  );
};
