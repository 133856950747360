import type { IRect } from 'konva/cmj/types';

import type { Position } from '../annotations/types';

const getBoundingBoxCornerPosition = (
  cornerName: string,
  { x, y, width, height }: IRect
): Position => {
  // Corner names are based on https://github.com/konvajs/konva/blob/a1660e1ccbd18c8b1b7d7303ea25f7a14a2d7858/src/shapes/Transformer.ts#L143C2-L143C2
  if (cornerName === 'top-left') {
    return { x, y };
  }
  if (cornerName === 'top-center') {
    return { x: x + width / 2, y };
  }
  if (cornerName === 'top-right') {
    return { x: x + width, y };
  }
  if (cornerName === 'middle-right') {
    return { x: x + width, y: y + height / 2 };
  }
  if (cornerName === 'middle-left') {
    return { x, y: y + height / 2 };
  }
  if (cornerName === 'bottom-left') {
    return { x, y: y + height };
  }
  if (cornerName === 'bottom-center') {
    return { x: x + width / 2, y: y + height };
  }
  if (cornerName === 'bottom-right') {
    return { x: x + width, y: y + height };
  }
  throw new Error(
    `Unknown anchor name '${cornerName}' was provided. This should not happen.`
  );
};

export default getBoundingBoxCornerPosition;
