import Konva from 'konva';
import { isEqual } from 'lodash';
import clamp from 'lodash/clamp';
import { v4 as uuid } from 'uuid';

import {
  Annotation,
  AnnotationType,
  ContainerConfig,
  UnifiedViewer,
  UnifiedViewerNode,
} from '..';

import getAnnotationSerializerByType from './annotations/getAnnotationSerializerByType';
import { Position } from './annotations/types';
import getUnifiedViewerNodeMinPosition from './getUnifiedViewerNodeMinPosition';
import getUnifiedViewerNodesWithOffset from './getUnifiedViewerNodesWithOffset';
import UnifiedViewerRenderer from './UnifiedViewerRenderer';
import constrainEllipsePosition from './utils/constrainEllipsePosition';
import constrainPolylinePosition from './utils/constrainPolylinePosition';
import findNearestContainerContentGroup from './utils/findNearestContainerContentGroup';
import { FileData } from './utils/getFileDataFromDropEvent';
import getFileDataFromSystemClipboard from './utils/getFileDataFromSystemClipboard';
import isAnnotationNode from './utils/isAnnotationNode';
import isContainerNode from './utils/isContainerNode';
import isNotUndefined from './utils/isNotUndefined';
import { getShamefulContainerTypeFromMimeType } from './utils/mimeTypes/getSupportedMimeTypeFromUrl';
import partitionIntoContainersAndAnnotations from './utils/partitionIntoContainersAndAnnotations';

type ClipboardEntry<DataType> = {
  createdTime: number;
  data: DataType[];
};

const getLocalStorageKey = (namespace: string) => `${namespace}Clipboard`;

class UnifiedViewerClipboard {
  private unifiedViewer: UnifiedViewer;
  private unifiedViewerRenderer: UnifiedViewerRenderer;
  private systemClipboard: ClipboardEntry<FileData> = {
    createdTime: -1,
    data: [],
  };

  public constructor(
    unifiedViewer: UnifiedViewer,
    unifiedViewerRenderer: UnifiedViewerRenderer
  ) {
    this.unifiedViewer = unifiedViewer;
    this.unifiedViewerRenderer = unifiedViewerRenderer;
  }

  private writeLocalStorageClipboard = (
    clipboard: ClipboardEntry<UnifiedViewerNode>
  ): void => {
    localStorage[getLocalStorageKey(this.unifiedViewer.namespace)] =
      JSON.stringify(clipboard);
  };

  private readLocalStorageClipboard = (): ClipboardEntry<UnifiedViewerNode> => {
    const clipboardJson =
      localStorage[getLocalStorageKey(this.unifiedViewer.namespace)];

    if (clipboardJson === null) {
      return {
        createdTime: -1,
        data: [],
      };
    }

    try {
      return JSON.parse(clipboardJson) as ClipboardEntry<UnifiedViewerNode>;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return {
        createdTime: -1,
        data: [],
      };
    }
  };

  private nodeToUnifiedViewerNode = (
    node: Konva.Node
  ): UnifiedViewerNode | undefined => {
    if (isContainerNode(node)) {
      const container = this.unifiedViewer.getContainerById(node.id());

      if (container === undefined) {
        return undefined;
      }

      if (container.serialize === undefined) {
        return undefined;
      }

      return container.serialize();
    }

    if (isAnnotationNode(node)) {
      return getAnnotationSerializerByType(node.attrs.annotationType).serialize(
        node,
        this.unifiedViewerRenderer
      );
    }

    throw new Error('Should never happen');
  };

  private normalizeUnifiedViewerNodesPositions = (
    nodes: UnifiedViewerNode[]
  ): UnifiedViewerNode[] => {
    const minX = Math.min(
      ...nodes.map((n) => getUnifiedViewerNodeMinPosition(n).x)
    );
    const minY = Math.min(
      ...nodes.map((n) => getUnifiedViewerNodeMinPosition(n).y)
    );

    return nodes.map((node) =>
      getUnifiedViewerNodesWithOffset(node, { x: -minX, y: -minY })
    );
  };

  public writeNodes = async (nodes: Konva.Node[]): Promise<void> => {
    // Initialize the system clipboard if it hasn't been done yet
    if (this.systemClipboard.createdTime === -1) {
      await this.refetchSystemClipboardContents();
    }

    this.writeLocalStorageClipboard({
      createdTime: Date.now(),
      data: this.normalizeUnifiedViewerNodesPositions(
        nodes.map((n) => this.nodeToUnifiedViewerNode(n)).filter(isNotUndefined)
      ),
    });
  };

  public getClipboardNodesAtCurrentPointerPosition = async (): Promise<
    UnifiedViewerNode[]
  > => {
    const intersection = this.unifiedViewer.stage.getIntersection(
      this.unifiedViewer.stage.getPointerPosition()!
    );
    if (intersection == null) {
      // eslint-disable-next-line no-console
      console.warn('Cannot paste outside the canvas');
      return [];
    }

    // Check first whether the contents of the system clipboard has changed.
    // If (and only if) that's the case, update the system clipboard entry.
    await this.refetchSystemClipboardContents();
    // System clipboard is newer than the node clipboard – paste content from the former
    const localStorageClipboard = this.readLocalStorageClipboard();

    if (this.systemClipboard.createdTime > localStorageClipboard.createdTime) {
      return this.readNodesFromSystemClipboard();
    }

    if (localStorageClipboard.data.length === 0) {
      return [];
    }

    const translatedMousePosition =
      this.unifiedViewer.stage.getRelativePointerPosition();

    if (translatedMousePosition === null) {
      return [];
    }

    return this.getClonedUnifiedViewerNodesAtIntersection(
      localStorageClipboard.data,
      {
        intersection,
        translatedMousePosition,
      }
    );
  };

  private getSingleDocumentAnnotationsWithClampedPositions = (
    annotation: Annotation,
    containerNode: Konva.Node
  ): Annotation => {
    if (annotation.type === AnnotationType.ELLIPSE) {
      return constrainEllipsePosition(annotation);
    }

    if (annotation.type === AnnotationType.POLYLINE) {
      return constrainPolylinePosition(annotation);
    }

    const node = getAnnotationSerializerByType(annotation.type).deserialize(
      annotation as any, // TODO: Fix typing. Known issue from before
      this.unifiedViewer,
      this.unifiedViewerRenderer
    );

    if (node === undefined) {
      return annotation;
    }

    const { width, height } = node.size();

    return {
      ...annotation,
      x: clamp(annotation.x, 0, 1 - width / containerNode.width()),
      y: clamp(annotation.y, 0, 1 - height / containerNode.height()),
    };
  };

  private getClonedWorkspaceAnnotations = (
    annotations: Annotation[],
    {
      translatedMousePosition,
    }: {
      translatedMousePosition: Position;
    }
  ) => {
    return annotations.map(
      (node): Annotation => ({
        ...getUnifiedViewerNodesWithOffset<Annotation>(
          node,
          translatedMousePosition
        ),
        id: uuid(),
      })
    );
  };

  private getClonedSingleDocumentNodes = (
    annotations: Annotation[],
    {
      intersection,
    }: {
      intersection: Konva.Node;
    }
  ) => {
    const nearestContainerConfig =
      this.findNearestContainerConfig(intersection);
    if (
      nearestContainerConfig === undefined ||
      annotations.some(
        (annotation) => annotation.containerId !== nearestContainerConfig.id
      )
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        'Some pasted container annotations are not from the container you are pasting into, this is a noop'
      );
      return [];
    }

    const offset = this.getMousePositionRelativeToContainer(intersection);
    if (offset === null) {
      return [];
    }

    return annotations.map((annotation) => ({
      ...this.getSingleDocumentAnnotationsWithClampedPositions(
        getUnifiedViewerNodesWithOffset<Annotation>(annotation, offset),
        intersection
      ),
      id: uuid(),
    }));
  };

  private findNearestContainerConfig = (
    node: Konva.Node
  ): ContainerConfig | undefined => {
    const nearestContainerContentGroup = findNearestContainerContentGroup(node);
    if (nearestContainerContentGroup === undefined) {
      // eslint-disable-next-line no-console
      console.warn('No nearest container content group found');
      return undefined;
    }
    const nearestContainerGroup = nearestContainerContentGroup.parent;
    if (nearestContainerGroup === null) {
      throw Error(
        `Container content group ${nearestContainerContentGroup.id} does not have a parent container group – this should never happen.`
      );
    }

    const containerGroupId = nearestContainerGroup.getAttr('id');

    const container = this.unifiedViewer.getContainerById(containerGroupId);
    if (container === undefined) {
      throw Error(
        `Container group ${containerGroupId} does not have a container – this should never happen.`
      );
    }

    if (container.serialize == undefined) {
      return undefined;
    }

    return container.serialize();
  };

  private isContainerAnnotationMode = (annotation: Annotation[]): boolean =>
    annotation.some((node) => node.containerId !== undefined);

  private getMousePositionRelativeToContainer = (
    node: Konva.Node
  ): Position | null => {
    const relativePosition = node.getRelativePointerPosition();
    if (relativePosition === null) {
      return null;
    }
    const { width, height } = node.size();
    return {
      x: relativePosition.x / width,
      y: relativePosition.y / height,
    };
  };

  private getClonedUnifiedViewerNodesAtIntersection = (
    nodes: UnifiedViewerNode[],
    {
      intersection,
      translatedMousePosition,
    }: {
      intersection: Konva.Node;
      translatedMousePosition: Position;
    }
  ) => {
    const { containers, annotations } =
      partitionIntoContainersAndAnnotations(nodes);

    return [
      ...containers.map((node) => {
        return {
          ...node,
          id: uuid(),
          x: translatedMousePosition.x + (node.x ?? 0),
          y: translatedMousePosition.y + (node.y ?? 0),
        };
      }),

      ...(this.isContainerAnnotationMode(annotations)
        ? this.getClonedSingleDocumentNodes(annotations, { intersection })
        : this.getClonedWorkspaceAnnotations(annotations, {
            translatedMousePosition,
          })),
    ];
  };

  private readNodesFromSystemClipboard = (): UnifiedViewerNode[] => {
    const translatedMousePosition =
      this.unifiedViewer.stage.getRelativePointerPosition();
    if (translatedMousePosition === null) {
      return [];
    }
    return this.systemClipboard.data.map<ContainerConfig>((file) => {
      return {
        id: uuid(),
        type: getShamefulContainerTypeFromMimeType(file.mimeType),
        url: file.dataUrl,
        x: translatedMousePosition.x,
        y: translatedMousePosition.y,
        metadata: {},
      };
    });
  };

  private refetchSystemClipboardContents = async () => {
    // As per https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/read,
    // navigator.clipboard.read isn't fully supported on, e.g., Firefox 90
    if (navigator.clipboard.read === undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        'This browser does not support copy-pasting of screenshots from the system clipboard.'
      );
      return;
    }
    const fileData = await getFileDataFromSystemClipboard(navigator.clipboard);
    if (!isEqual(fileData, this.systemClipboard.data)) {
      this.systemClipboard = { createdTime: Date.now(), data: fileData };
    }
  };
}

export default UnifiedViewerClipboard;
