import {
  ArrowLeftIcon,
  Button,
  EmptyState,
  ErrorCodePlaceholderIllustration,
  Flex,
  HomeIcon,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { captureException } from '@sentry/browser';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageNotFoundContainer } from './elements';

export const PageNotFound: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(LOCIZE_NAMESPACES.errorView);

  const handleGoHome = () => {
    navigate('/');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    captureException(`Unmatched route: ${window.location.pathname}`, {
      level: 'error',
    });
  }, []);

  return (
    <PageNotFoundContainer>
      <EmptyState
        illustration={<ErrorCodePlaceholderIllustration statusCode={404} />}
        title={t('ERROR_PAGE_NOT_FOUND_TITLE', 'Page not found')}
        primaryAction={
          <Flex gap={8}>
            <Button
              type="ghost"
              icon={<ArrowLeftIcon />}
              onClick={handleGoBack}
            >
              {t('ERROR_PAGE_NOT_FOUND_BUTTON_Back', 'Back')}
            </Button>
            <Button type="ghost" icon={<HomeIcon />} onClick={handleGoHome}>
              {t('ERROR_PAGE_NOT_FOUND_BUTTON_HOME', 'Home')}
            </Button>
          </Flex>
        }
        description={t(
          'ERROR_PAGE_NOT_FOUND_DESCRIPTION',
          'The page you are looking for does not exist. Please check the URL or go back to the home page.'
        )}
      />
    </PageNotFoundContainer>
  );
};
