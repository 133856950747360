import Konva from 'konva';
import { IRect } from 'konva/lib/types';

/**
 * Returns the rect of a node relative to the stage, ignoring stroke and shadow,
 * and accounting for "circle-like" shapes with a centered origin.
 *
 * As per https:konvajs.org/docs/posts/Position_vs_Offset.html, "Circle-like"
 * shapes have origin at the center of the shape, rather than the top-left as
 * "rectangle-like" shapes. To correct for this, we have to compute the
 * constrained positions using different offsets for "circle-like" shapes to
 * correct for this difference.
 */
const getNodeRectRelativeToStage = (node: Konva.Node): IRect | null => {
  const stage = node.getStage();
  if (stage === null) {
    return null;
  }
  const rect = node.getClientRect({
    // @ts-expect-error seems to be an issue with Konva's types as this is valid
    relativeTo: node.getStage(),
    skipShadow: true,
    skipStroke: true,
  });
  const originOffset = getOriginOffset(node);
  const { x, y, width, height } = rect;

  return {
    x: x + originOffset.x,
    y: y + originOffset.y,
    width,
    height,
  };
};

// Based on the shapes found in https://github.com/konvajs/konva/tree/master/src/shapes
// that has radius as a property
const CIRCLE_LIKE_SHAPES = [
  Konva.Ellipse,
  Konva.Circle,
  Konva.Wedge,
  Konva.Star,
  Konva.Ring,
  Konva.Arc,
  Konva.RegularPolygon,
];

const isNodeOriginAtCenter = (node: Konva.Node) =>
  CIRCLE_LIKE_SHAPES.some((shapeType) => node instanceof shapeType);

const getOriginOffset = (node: Konva.Node) => {
  const halfWidth = node.width() / 2;
  const halfHeight = node.height() / 2;
  const isOriginAtCenter = isNodeOriginAtCenter(node);
  return {
    x: isOriginAtCenter ? halfWidth : 0,
    y: isOriginAtCenter ? halfHeight : 0,
  };
};

export default getNodeRectRelativeToStage;
