import { bisector } from 'd3-array';
import {
  timeDay,
  timeHour,
  timeMinute,
  timeMonth,
  timeSecond,
  timeWeek,
  timeYear,
} from 'd3-time';
import dayjs from 'dayjs';

import { ValueType } from '@cognite/plotting-components';
import { DatapointAggregate } from '@cognite/sdk/dist/src';

// taken from https://github.com/cognitedata/fusion/blob/33871465c5e842532a34908f520c7ba088be4bef/libs/data-exploration-components/src/utils/dateTime.ts#L1-L54
export const formatDate = (date: Date): string =>
  dayjs(date).format('MMMM DD YYYY HH:mm G[M]T(Z)');

export const datetimeMultiFormat = (date: Date): string => {
  const formatMillisecond = (newDate: Date) => dayjs(newDate).format('.SSS');
  const formatSecond = (newDate: Date) => dayjs(newDate).format(':ss');
  const formatMinute = (newDate: Date) => dayjs(newDate).format('HH:mm');
  const formatHour = (newDate: Date) => dayjs(newDate).format('HH:mm');
  const formatDay = (newDate: Date) => dayjs(newDate).format('ddd DD');
  const formatWeek = (newDate: Date) => dayjs(newDate).format('MMM DD');
  const formatMonth = (newDate: Date) => dayjs(newDate).format('MMMM');
  const formatYear = (newDate: Date) => dayjs(newDate).format('YYYY');

  if (timeSecond(date) < date) {
    return formatMillisecond(date);
  }

  if (timeMinute(date) < date) {
    return formatSecond(date);
  }

  if (timeHour(date) < date) {
    return formatMinute(date);
  }

  if (timeDay(date) < date) {
    return formatHour(date);
  }

  if (timeMonth(date) < date) {
    if (timeWeek(date) < date) {
      return formatDay(date);
    }

    return formatWeek(date);
  }

  if (timeYear(date) < date) {
    return formatMonth(date);
  }

  return formatYear(date);
};

// taken from https://github.com/cognitedata/fusion/blob/4320800b694779f86c8b45c30705f2503c9173c1/libs/data-exploration-components/src/components/Chart/LineChart.tsx#L38-L50
export const getDataPointAverage = (
  d?: DatapointAggregate
): number | undefined => (d ? d.average : undefined);
export const getDataPointMaxValue = (
  d?: DatapointAggregate
): number | undefined => (d ? d.max : undefined);
export const getDataPointMinValue = (
  d?: DatapointAggregate
): number | undefined => (d ? d.min : undefined);
export const getDataPointCount = (d?: DatapointAggregate): number | undefined =>
  d ? d.count : undefined;
// accessors
export const getDate = (d?: DatapointAggregate): Date =>
  d ? new Date(d.timestamp) : new Date(0);

export const bisectDate = bisector<DatapointAggregate, Date>(
  (d) => new Date(d.timestamp)
).left;

export const isNumericalValueArray = (
  values: ValueType[]
): values is number[] => values.every((value) => Number.isFinite(value));
