import { Infobox, Skeleton } from '@cognite/cogs.js-v10';
import {
  useAppConfigQuery,
  useFeatureToggleConfig,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import {
  AssetExplorationViews,
  useClassicOrAPMAssetQuery,
} from '@infield/features/asset';
import { AssetTreedCard } from '@infield/features/asset/asset-3d';
import {
  AssetActivitiesCard,
  selectedActivityInPanelAtom,
} from '@infield/features/asset/asset-activities';
import { AssetDocumentsCard } from '@infield/features/asset/asset-documents';
import { AssetHeader } from '@infield/features/asset/asset-header/asset-header';
import { AssetMediaCard } from '@infield/features/asset/asset-media-card/asset-media-card';
import {
  AssetNotificationsCard,
  selectedNotificationInPanelAtom,
} from '@infield/features/asset/asset-notifications';
import { AssetPropertiesCard } from '@infield/features/asset/asset-properties';
import { AssetTrendCard } from '@infield/features/asset/asset-trends';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import * as S from './elements';

interface Props {
  assetExternalId: string;
  onClose?: () => void;
}

export const AssetExplorationView: FC<Props> = ({
  assetExternalId,
  onClose,
}) => {
  const {
    data: assetData,
    isInitialLoading,
    isSuccess,
  } = useClassicOrAPMAssetQuery(assetExternalId);

  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);
  const { data: appConfig } = useAppConfigQuery();

  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );

  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const propertyConfiguration =
    appConfig?.featureConfiguration?.assetPageConfiguration?.propertyCard;

  const setSelectedActivity = useSetRecoilState(selectedActivityInPanelAtom);
  const setSelectedNotification = useSetRecoilState(
    selectedNotificationInPanelAtom
  );

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleNavigate = (path: string, searchParams?: string) => {
    navigate({
      pathname: `/asset/${encodeURIComponent(assetExternalId)}/${path}`,
      search: searchParams,
    });
  };

  const handleOnActivityClick = (activityExternalId: string) => {
    if (isDesktop) {
      setSelectedActivity(activityExternalId);
      handleNavigate(AssetExplorationViews.ACTIVITIES);
    } else {
      navigate(`/activity/${encodeURIComponent(activityExternalId)}/details`);
    }
  };

  const handleOnNotificationClick = (notificationExternalId: string) => {
    if (isDesktop) {
      setSelectedNotification(notificationExternalId);
      handleNavigate(AssetExplorationViews.NOTIFICATIONS);
    } else {
      navigate(
        `/notification/${encodeURIComponent(notificationExternalId)}/details`
      );
    }
  };

  if (isInitialLoading) {
    return <Skeleton.List lines={4} borders />;
  }

  if (isSuccess && assetData) {
    return (
      <S.Container>
        <S.AssetDetailsWrapper>
          <AssetHeader
            onClose={handleClose}
            assetData={assetData}
            canBeExpanded={false}
          />
          <S.AssetDetailsContent>
            <S.CardContainer $isDesktop={isDesktop}>
              {rootLocationFeatureToggles?.threeD && (
                <AssetTreedCard
                  assetId={'id' in assetData ? assetData.id : undefined}
                  assetExternalId={assetExternalId}
                  onOpen={(searchParams) =>
                    handleNavigate(AssetExplorationViews.THREED, searchParams)
                  }
                />
              )}
              {rootLocationFeatureToggles?.trends && (
                <AssetTrendCard
                  assetExternalId={assetExternalId}
                  onOpen={() => handleNavigate(AssetExplorationViews.TRENDS)}
                />
              )}
              {rootLocationFeatureToggles?.documents && (
                <AssetDocumentsCard
                  assetExternalId={
                    assetData.externalId || 'missingAssetExternalId'
                  }
                  onOpen={() => handleNavigate(AssetExplorationViews.DOCUMENTS)}
                />
              )}
              {rootLocationFeatureToggles?.workorders && (
                <AssetActivitiesCard
                  assetExternalId={assetExternalId}
                  onOpen={() =>
                    handleNavigate(AssetExplorationViews.ACTIVITIES)
                  }
                  onActivityClick={handleOnActivityClick}
                />
              )}

              {rootLocationFeatureToggles?.notifications && (
                <AssetNotificationsCard
                  assetExternalId={assetExternalId}
                  onOpen={() =>
                    handleNavigate(AssetExplorationViews.NOTIFICATIONS)
                  }
                  onNotificationClick={handleOnNotificationClick}
                />
              )}
              <AssetPropertiesCard
                assetMetadata={assetData.metadata}
                config={propertyConfiguration}
                onOpen={() => handleNavigate(AssetExplorationViews.PROPERTIES)}
              />
              {rootLocationFeatureToggles?.media && (
                <AssetMediaCard
                  assetExternalId={assetExternalId}
                  assetSpace={
                    'space' in assetData ? assetData.space : undefined
                  }
                />
              )}
            </S.CardContainer>
          </S.AssetDetailsContent>
        </S.AssetDetailsWrapper>
      </S.Container>
    );
  }

  return (
    <Infobox status="warning">
      {t(
        'noDataforAssetId',
        'Did not find any data for the given assetExternalId'
      )}
    </Infobox>
  );
};
