import { Body, Input } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ReadingInputContainer = styled.form<{
  $isSuccess: boolean;
  $isError: boolean;
  $isDisabled?: boolean;
}>`
  border-color: ${({ $isSuccess, $isError }) => {
    if ($isSuccess) {
      return 'var(--cogs-border--status-success--strong)';
    }
    if ($isError) {
      return 'var(--cogs-border--status-critical--strong)';
    }
    return 'var(--cogs-border--interactive--default)';
  }} !important;

  border: 2px solid;
  border-radius: 6px;

  .cogs-input-container .input__postfix {
    border: none;
    background-color: ${({ $isDisabled }) =>
      $isDisabled
        ? 'var(--cogs-surface--interactive--disabled)'
        : 'var(--cogs-surface--muted)'};
  }

  .cogs-input-container .input-wrapper {
    width: 100%;
  }

  .cogs-input.cogs-input-default:disabled {
    border-color: var(--cogs-surface--interactive--disabled);
    background-color: var(--cogs-surface--interactive--disabled);
  }

  .cogs-input.cogs-input-default {
    transition: none;
  }

  .cogs-input.cogs-input-default:hover {
    border: none;
    background: ${({ $isDisabled }) =>
      $isDisabled
        ? 'var(--cogs-surface--interactive--disabled)'
        : 'var(--cogs-surface--muted)'};
    transition: none;
  }

  .cogs-input.cogs-input-default:focus {
    border-color: none;
    background: var(--cogs-surface--muted);
    box-shadow: none;
  }
`;

export const ReadingInputLabel = styled(Body)`
  color: var(--cogs-text-icon--medium);
`;

export const RangeText = styled(Body).attrs({
  size: 'small',
})<{ $isError: boolean }>`
  color: ${({ $isError }) => {
    if ($isError) {
      return 'var(--cogs-border--status-critical--strong)';
    }
    return 'var(--cogs-text-icon--medium)';
  }} !important;
`;
