import { styled } from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import { AssetsIcon, TagChip, Flex } from '@cognite/cogs.js-v10';
import { CogniteClient, Asset } from '@cognite/sdk';

import { AssetAcdmInstance } from '../../../types';
import resolveReadableProperty from '../../common/resolveReadableProperty';
import { ReactContainerRenderContentProps } from '../../ReactContainer';
import useStatusChange from '../../ReactContainer/useStatusChange';
import TableContent from '../../TableContainer/TableContent';

type AcdmTableProps = Pick<
  ReactContainerRenderContentProps,
  | 'width'
  | 'height'
  | 'unscaledWidth'
  | 'unscaledHeight'
  | 'setLoadingStatus'
  | 'onContentSizeChange'
  | 'shouldAutoSize'
> & {
  instance: AssetAcdmInstance;
  sdk: CogniteClient;
  properties: string[];
  containerPadding: number;
};

const resolveAssetPropertyDisplayItems = (
  asset: Asset,
  properties: string[]
) => {
  return properties.map((path) => {
    const resolvedProperty = resolveReadableProperty(asset, path);

    // Hard coded special cases per container type
    if (path === 'labels') {
      return {
        label: resolvedProperty.label,
        value: asset.labels?.map(({ externalId }) => (
          <LabelWrapper wrap="wrap" gap={8} justifyContent="flex-end">
            <TagChip label={externalId} key={externalId} size="small" />
          </LabelWrapper>
        )),
      };
    }

    return resolvedProperty;
  });
};

export function AcdmAssetTable({
  instance,
  sdk,
  width,
  height,
  unscaledHeight,
  unscaledWidth,
  containerPadding,
  shouldAutoSize,
  setLoadingStatus,
  onContentSizeChange,
  properties,
}: AcdmTableProps): React.ReactNode {
  const acdmAsset = useQuery(
    ['acdmAsset', instance],
    async () => {
      const assets = await sdk.assets.retrieve([{ id: instance.id }]);
      if (assets.length !== 1) {
        throw Error('There must be exactly one unique asset for an asset id');
      }
      return assets[0];
    },
    {
      enabled: instance.id !== undefined,
    }
  );
  useStatusChange({
    data: acdmAsset.data,
    isLoading: acdmAsset.isLoading,
    isError: acdmAsset.isError,
    setLoadingStatus,
  });

  if (acdmAsset.isLoading && acdmAsset.isFetching) {
    return (
      <div
        style={{
          position: 'absolute',
          inset: 0,
          background: 'rgba(255,255,255,0.7)',
        }}
      />
    );
  }
  if (acdmAsset.data === undefined) {
    return null;
  }

  return (
    <TableContent
      width={width}
      height={height}
      unscaledWidth={unscaledWidth}
      unscaledHeight={unscaledHeight}
      shouldAutoSize={shouldAutoSize}
      containerPadding={containerPadding}
      title={
        <TitleWrapper>
          <ColoredAssetsIcon /> {}
          {acdmAsset.data.name}
        </TitleWrapper>
      }
      items={resolveAssetPropertyDisplayItems(acdmAsset.data, properties)}
      setLoadingStatus={setLoadingStatus}
      onContentSizeChange={onContentSizeChange}
    />
  );
}

const ColoredAssetsIcon = styled(AssetsIcon)`
  color: #4a67fb;
  position: relative;
  margin-right: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const LabelWrapper = styled(Flex)`
  margin-bottom: 8px;
`;
