import { Button, Flex, Modal } from '@cognite/cogs.js-v10';
import type { RootLocationConfiguration } from '@infield/features/app-config/types';
import { useAPMAssetsQuery } from '@infield/features/asset';
import { useDebounce } from '@infield/hooks/use-debounce';
import { useEffect, useState } from 'react';
import type { FC } from 'react';

import * as S from './elements';
import { RootLocationConfig } from './root-location-config';

interface Props {
  hasAppConfigPermission: boolean;
  visible: boolean;
  existingRootLocations: RootLocationConfiguration[];
  onClose: () => void;
  onSave: (assetExternalId: string, displayName: string) => void;
}

export const RootLocationConfigDialogue: FC<Props> = ({
  hasAppConfigPermission,
  visible,
  existingRootLocations,
  onClose,
  onSave,
}) => {
  const [assetExternalId, setAssetExternalId] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const debouncedAssetExternalId = useDebounce(assetExternalId, 1000);
  const { data: [selectedAsset] = [] } = useAPMAssetsQuery({
    externalIds: [debouncedAssetExternalId],
    spaces: [],
  });

  const isDisplayNameUnique =
    Boolean(displayName) &&
    !existingRootLocations.some(
      (location) =>
        location.displayName?.toLowerCase().trim() ===
        displayName.toLowerCase().trim()
    );
  const isValidRootAsset = Boolean(selectedAsset);

  useEffect(() => {
    setDisplayName(selectedAsset?.title || '');
  }, [selectedAsset?.title]);

  const renderFooter = () => {
    return (
      <Flex direction="column" gap={8}>
        <Button
          onClick={() => onSave(assetExternalId, displayName)}
          disabled={!isValidRootAsset}
        >
          Add
        </Button>
      </Flex>
    );
  };
  return (
    <Modal
      visible={visible}
      size="small"
      hideFooter
      title="Root Location"
      destructive
      onCancel={() => onClose()}
    >
      <S.DialogueContentWrapper>
        <RootLocationConfig
          hasAppConfigPermission={hasAppConfigPermission}
          assetExternalId={assetExternalId}
          displayName={displayName}
          onAssetSelect={setAssetExternalId}
          onAssetExternalIdChange={setAssetExternalId}
          onDisplayNameChange={setDisplayName}
          isValidRoot={isValidRootAsset}
          isValidDisplayName={isDisplayNameUnique}
        />
      </S.DialogueContentWrapper>
      {renderFooter()}
    </Modal>
  );
};
