import { Position } from './types';

const getMaxDiffingScalingFactor = ({ x, y }: Position): number => {
  if (Math.abs(1 - x) > Math.abs(1 - y)) {
    return x;
  }
  return y;
};

export default getMaxDiffingScalingFactor;
