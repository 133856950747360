import { Body, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
})`
  width: 100%;
`;

export const ViewMappingTitle = styled(Body).attrs({
  size: 'x-small',
  strong: true,
})<{
  $isChanged: boolean;
}>`
  ${({ $isChanged }) =>
    $isChanged && 'color: var(--cogs-border--status-warning--strong)'}
`;
