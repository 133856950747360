import { ChartSource } from '@cognite/charts-lib';

import isNotUndefined from '../../utils/isNotUndefined';

import type { ChartSourcePropsById } from './types';

const getSourcePropsByIdFromSourceCollection = <
  SourceCollectionItem extends { id: string; range?: any[]; type?: string }
>(
  collection: SourceCollectionItem[]
): ChartSourcePropsById =>
  Object.fromEntries(
    collection
      .map(
        ({ id, range, type }): [string, Partial<ChartSource>] | undefined => {
          if (range === undefined || range.length !== 2) {
            return undefined;
          }

          if (
            type !== 'scheduledCalculation' &&
            type !== 'timeseries' &&
            type !== 'workflow'
          ) {
            return undefined;
          }

          const [startRange, endRange] = range;
          if (
            typeof startRange === 'number' &&
            typeof endRange === 'number' &&
            startRange > endRange
          ) {
            return undefined;
          }
          return [id, { range: [startRange, endRange], type }];
        }
      )
      .filter(isNotUndefined)
  );

export default getSourcePropsByIdFromSourceCollection;
