import { IdsByType } from '../types';

const isSingularlySelected = (id: string, idsByType: IdsByType): boolean => {
  const selected = [...idsByType.containerIds, ...idsByType.annotationIds];

  if (selected.length !== 1) {
    return false;
  }

  return selected[0] === id;
};

export default isSingularlySelected;
