import { Body, Button, Flex, Heading } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Wrapper = styled(Flex).attrs({
  direction: 'column',
  justifyContent: 'space-between',
})`
  height: 100%;
`;

export const ContentContainer = styled(Flex).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
})`
  height: 100%;
  padding: 16px;
  text-align: center;
`;

export const StyledTitle = styled(Heading).attrs({
  level: 5,
})`
  color: ${({ theme }) => theme['text-icon--strong']};
`;

export const StyledBody = styled(Body).attrs({
  size: 'small',
})`
  color: ${({ theme }) => theme['text-icon--strong']};
`;

export const ButtonContainer = styled(Flex).attrs({
  gap: 8,
})`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme['surface--strong']};
`;

export const FooterButton = styled(Button)`
  width: 100%;
`;
