import * as React from 'react';

import {
  Button,
  RestoreIcon,
  Tooltip,
  ZoomInIcon,
  ZoomOutIcon,
} from '@cognite/cogs.js-v10';

import { useTranslation } from '../../../useTranslation';
import { useAxisDirection } from '../../hooks/useAxisDirection';
import { AxisDirectionConfig } from '../../types';
import { getPlotZoomRange } from '../../utils/getPlotZoomRange';
import { PlotElement } from '../Plot';

import { ZoomActionsWrapper } from './elements';

export interface ZoomActionsProps {
  plotRef: React.RefObject<PlotElement>;
  zoomDirectionConfig: AxisDirectionConfig;
}

export const ZoomActions: React.FC<ZoomActionsProps> = ({
  plotRef,
  zoomDirectionConfig,
}) => {
  const { t } = useTranslation();
  const zoomDirection = useAxisDirection(zoomDirectionConfig);

  const handleZoom = (mode: 'zoom-in' | 'zoom-out') => {
    if (!zoomDirection) {
      return;
    }

    const newRange = getPlotZoomRange(plotRef.current, zoomDirection, mode);

    if (newRange) {
      plotRef.current?.setPlotRange(newRange);
    }
  };

  if (zoomDirectionConfig === false) {
    return null;
  }

  return (
    <ZoomActionsWrapper>
      <Tooltip content={t('ZOOM_OUT_CONTROL', 'Zoom out')}>
        <Button
          icon={<ZoomOutIcon />}
          aria-label="zoom-out"
          onClick={() => handleZoom('zoom-out')}
        />
      </Tooltip>

      <Tooltip content={t('GENERAL_RESET', 'Reset')}>
        <Button
          icon={<RestoreIcon />}
          aria-label="zoom-reset"
          onClick={() => plotRef.current?.resetPlotRange()}
        />
      </Tooltip>

      <Tooltip content={t('ZOOM_IN_CONTROL', 'Zoom in')}>
        <Button
          icon={<ZoomInIcon />}
          aria-label="zoom-in"
          onClick={() => handleZoom('zoom-in')}
        />
      </Tooltip>
    </ZoomActionsWrapper>
  );
};
