import { ContainerType } from '../containers';
import { DocumentContainerProps } from '../containers/DocumentContainer';
import { ImageContainerProps } from '../containers/ImageContainer';
import { TextContainerProps } from '../containers/TextContainer';
import { VideoContainerProps } from '../containers/VideoContainer';

import getSupportedMimeTypeFromUrl, {
  getContainerTypeFromMimeType,
} from './mimeTypes/getSupportedMimeTypeFromUrl';
import isNativelySupportedMimeType from './mimeTypes/isNativelySupportedMimeType';

// Omit `url` and `type` props as they are inferred from the file url in getContainerConfigFromUrl
export type FileContainerPropsWithoutUrlAndType =
  | Omit<ImageContainerProps, 'url' | 'type'>
  | Omit<DocumentContainerProps, 'url' | 'type'>
  | Omit<TextContainerProps, 'url' | 'type'>
  | Omit<VideoContainerProps, 'url' | 'type'>;

export type FileContainerProps =
  | ImageContainerProps
  | DocumentContainerProps
  | TextContainerProps
  | VideoContainerProps;

type UrlFunctionReturn = { url: string; mimeType?: string };
type UrlFunction = () => UrlFunctionReturn | Promise<UrlFunctionReturn>;

const resetFileUrlIfExpired = (key: string, autoExpireMs: number) => {
  const timer = expirationTimersByKey.get(key);
  if (timer !== undefined) {
    clearTimeout(timer);
  }
  expirationTimersByKey.set(
    key,
    setTimeout(() => {
      urlFunctionReturnByKey.delete(key);
    }, autoExpireMs)
  );
};

const urlFunctionReturnByKey = new Map<string, ReturnType<UrlFunction>>();
const expirationTimersByKey = new Map<string, NodeJS.Timeout>();

const getUrl = async (
  urlFn: UrlFunction,
  key: string | undefined
): Promise<UrlFunctionReturn> => {
  if (!key) {
    return urlFn();
  }

  return urlFunctionReturnByKey.get(key) || urlFn();
};

/**
 *
 * @param url
 * @param props
 * @param key - used to cache the resolved url. caching is skipped if no key is provided
 * @param autoExpireMs - expire cached url after this many milliseconds if specified
 * @param resolveMimeType
 */
const getContainerConfigFromUrl = async (
  url: UrlFunction,
  props: FileContainerPropsWithoutUrlAndType,
  {
    key,
    autoExpireMs,
  }: {
    key?: string;
    autoExpireMs?: number;
  } = {}
): Promise<FileContainerProps> => {
  if (key !== undefined && autoExpireMs !== undefined) {
    resetFileUrlIfExpired(key, autoExpireMs);
  }

  const urlFnPromise = getUrl(url, key);
  if (key !== undefined && !urlFunctionReturnByKey.has(key)) {
    urlFunctionReturnByKey.set(key, urlFnPromise);
  }

  const { url: resolvedUrl, mimeType: resolvedMimeType } = await urlFnPromise;
  const mimeType = resolvedMimeType || getSupportedMimeTypeFromUrl(resolvedUrl);
  if (!isNativelySupportedMimeType(mimeType)) {
    throw new Error(`Unsupported mime type: ${mimeType}`);
  }

  const resolvedContainerType = getContainerTypeFromMimeType(mimeType);

  if (resolvedContainerType === ContainerType.VIDEO) {
    return {
      /**
       * There's a mismatch between the expected props for (TEXT, DOCUMENT, IMAGE) and VIDEO containers,
       * since the latter expects an explicitly set width and height. Changing the type there would
       * not work since the requirements is defined by the ReactContainer class.
       * https://cognitedata.atlassian.net/browse/AH-2685
       */
      ...props,
      url: resolvedUrl,
      type: ContainerType.VIDEO,
      mimeType,
      width: props.width !== undefined ? props.width : props.maxWidth,
      height: props.height !== undefined ? props.height : props.maxHeight,
      shouldAutoSize:
        // @ts-expect-error
        props.shouldAutoSize === undefined ? true : props.shouldAutoSize,
    } as VideoContainerProps;
  }

  return {
    ...props,
    url: resolvedUrl,
    type: resolvedContainerType,
    mimeType,
  };
};

export default getContainerConfigFromUrl;
