import { Position } from '../annotations/types';

/**
 * returns an imaginary end position of a line, either horizontal or vertical to the start point
 * based on the angle the actual end position has with the start point
 *
 * If the angle is more 45% (relative to xAxis) the imaginary end point will be vertical, if not horizontal
 * @param startPoint
 * @param actualEndPoint
 */
export const getImaginaryHorizontalOrVerticalEndPosition = (
  startPoint: Position,
  actualEndPoint: Position
): Position => {
  const tangent =
    (actualEndPoint.y - startPoint.y) / (actualEndPoint.x - startPoint.x);
  const isVertical = Math.abs(tangent) > 1;

  if (isVertical) {
    return { x: startPoint.x, y: actualEndPoint.y };
  }
  return { x: actualEndPoint.x, y: startPoint.y };
};
