import dayjs from 'dayjs';

import { formatValueForDisplay, hexToRGBA } from '../../../utils';

export type TimeSeriesValueAnnotation = {
  value: number;
  timestamp: Date;
  yref: string;
  unit?: string | undefined;
  color?: string;
};

export const createVerticalLineShape = (timestamp: Date) => ({
  visible: true,
  xref: 'test',
  yref: 'paper',
  x0: timestamp,
  x1: timestamp,
  y0: 0,
  y1: 1,
  type: 'line',
  fillcolor: 'rgba(0, 0, 0, 0.1)',
  line: {
    color: 'rgba(0, 0, 0, 0.3)',
    width: 1,
  },
});

export const createXAxisTimestampAnnotation = (timestamp: Date) => ({
  x: timestamp,
  yanchor: 'top',
  y: -0.0,
  ay: 6,
  ax: 0,
  showarrow: true,
  arrowcolor: 'black',
  arrowhead: 1, // You can choose different styles (1-7)
  arrowsize: 1.2, // Adjusts the size of the arrow head
  xref: 'test',
  yref: 'paper',
  text: dayjs(timestamp).format('MMM D, YYYY HH:mm:ss'),
  bgcolor: '#444444',
  font: {
    family: 'Helvetica',
    color: '#ffffff',
    size: 12,
  },
  borderpad: 2,
  visible: true,
});

export const createTimeSeriesValueAnnotation = (
  valueAnnotation: TimeSeriesValueAnnotation
) => {
  const formattedValue = formatValueForDisplay(valueAnnotation.value, 4);
  const text = valueAnnotation.unit
    ? `${formattedValue} ${valueAnnotation.unit}`
    : `${formattedValue}`;

  return {
    yref: valueAnnotation.yref,
    x: valueAnnotation.timestamp,
    xanchor: 'left',
    y: valueAnnotation.value,
    text: text,
    align: 'center',
    showarrow: true,
    arrowhead: 6,
    arrowsize: 0.45,
    arrowcolor: valueAnnotation.color || 'black',
    arrowside: 'end',
    ax: 5,
    ay: 0,
    bgcolor: 'rgba(255, 255, 255, 0.8)',
    borderpad: 2,
    bordercolor: hexToRGBA(valueAnnotation.color, 0.5) || 'black',
    borderwidth: 1.5,
    font: {
      family: 'Helvetica',
      size: 12,
      color: valueAnnotation.color || 'black',
    },
  };
};
