import type { Checklist, Operation } from '@cognite/apm-client';

export const getOperationGroupIdToChecklistMap = (
  subActivities: Operation[],
  checklists: Checklist[]
) => {
  const operationIdToChecklistMap: Map<string, Checklist> = new Map();
  const operationExternalIdToIdMap: Map<string, string> = new Map();

  subActivities.forEach((subActivity) => {
    if (subActivity.id) {
      operationExternalIdToIdMap.set(subActivity.externalId, subActivity.id);
    }
  });

  checklists?.forEach((checklist) => {
    checklist.checklistItems?.forEach((checklistItem) => {
      if (checklistItem.sourceId) {
        const operationId = operationExternalIdToIdMap.get(
          checklistItem.sourceId
        );
        if (operationId) {
          operationIdToChecklistMap.set(operationId, checklist);
        }
      }
    });
  });

  return operationIdToChecklistMap;
};
