import type { ChecklistItem, ChecklistItemStatus } from '@cognite/apm-client';
import { Button, RestoreIcon } from '@cognite/cogs.js-v10';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import uniqBy from 'lodash/uniqBy';
import type { FC } from 'react';

import { TODO_STATUS } from '../constants';
import type { Filter, FilterKey } from '../hooks';
import { useChecklistItemStatusTranslation } from '../hooks';

import { FilterGroup } from './elements';
import { FilterDropdown } from './filter-dropdown';

type Props = {
  checklistItems: ChecklistItem[];
  filters: Filter;
  updateFilters: (filterKey: FilterKey, newValue: string) => void;
  resetFilters: () => void;
  checkTagFilterCondition: (
    assetExternalId?: string | null | undefined
  ) => boolean;
  checkStatusFilterCondition: (status?: ChecklistItemStatus | null) => boolean;
};

export const ChecklistFilter: FC<Props> = ({
  checklistItems,
  filters,
  updateFilters,
  resetFilters,
  checkTagFilterCondition,
  checkStatusFilterCondition,
}) => {
  const metrics = useMetrics(METRICS_NAMESPACES.checklists);
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);
  const translatedStatuses = useChecklistItemStatusTranslation();

  // if status filter is applied we want to show only tags relevant to the filtered statuses
  const filteredByStatus = checklistItems?.filter(({ status }) =>
    checkStatusFilterCondition(status)
  );

  const handleFilterUpdate = (filterKey: FilterKey, newValue: string) => {
    metrics.track('updateChecklistItemFilter', {
      filterKey,
      filterValue: newValue,
    });

    updateFilters(filterKey, newValue);
  };

  const filterOptionsTag = uniqBy(
    filteredByStatus.map(({ asset }) => {
      return {
        storedOption: asset?.externalId || '',
        shownOption: asset?.title || asset?.externalId || '',
      };
    }),
    'storedOption'
  ).filter(({ storedOption }) => storedOption);

  // if tag filter is applied we want to show only statuses relevant to the filtered tags
  const filteredByTags = checklistItems?.filter(({ asset }) =>
    checkTagFilterCondition(asset?.externalId)
  );

  const filterOptionsStatus = uniqBy(
    filteredByTags.map(({ status }) => {
      const finalStatus = status || TODO_STATUS;
      return {
        storedOption: finalStatus,
        shownOption: translatedStatuses[finalStatus as ChecklistItemStatus],
      };
    }),
    'storedOption'
  );

  return (
    <FilterGroup>
      {(filters.status.length > 0 || filters.tag.length > 0) && (
        <Button
          data-testid="checklist-filter-clear-button"
          onClick={() => resetFilters()}
          icon={<RestoreIcon />}
          iconPlacement="right"
          type="secondary"
        >
          {t('CHECKLIST_FILTER_CLEAR_BUTTON', 'Clear')}
        </Button>
      )}
      <FilterDropdown
        filterTitle={t('CHECKLIST_FILTER_TAG_MENU_TITLE', 'Filter on tag')}
        filterButtonText={t('CHECKLIST_FILTER_TAG_BUTTON', 'Tag')}
        filterOptions={filterOptionsTag}
        filterKey="tag"
        filters={filters}
        updateFilters={handleFilterUpdate}
      />
      <FilterDropdown
        filterTitle={t(
          'CHECKLIST_FILTER_STATUS_MENU_TITLE',
          'Filter on status'
        )}
        filterButtonText={t('CHECKLIST_FILTER_STATUS_BUTTON', 'Status')}
        filterOptions={filterOptionsStatus}
        filterKey="status"
        filters={filters}
        updateFilters={handleFilterUpdate}
      />
    </FilterGroup>
  );
};
