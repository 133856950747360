import type {
  APMAsset,
  Checklist,
  ChecklistItem,
  Operation,
} from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import type { Filters } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { useSelectedRootAPMAsset } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserQuery } from '@infield/features/user';
import { useFDMServices } from '@infield/providers/fdm-services';
import { dmsItemIngestionLimit } from '@infield/utils/dms-requests';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import uniq from 'lodash/uniq';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

import { generateChecklist } from './utils';

interface Props {
  activityExternalId: string;
  operations: Operation[];
  plannedStartTime?: string;
  plannedEndTime?: string;
  assignees?: string[];
}

type MutationContext = {
  sliTimerStartTime: number;
};

export const useActivityCreateChecklist = () => {
  const { activityService, assetService, apmClient, checklistService } =
    useFDMServices();
  const queryClient = useQueryClient();
  const { data: user } = useCurrentUserQuery();
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const { data: rootAPMAsset } = useSelectedRootAPMAsset();
  const { activity: configFilters } = useAppConfigFiltersContext();
  const checklistAuditMetrics = useMetrics(METRICS_NAMESPACES.auditChecklist);
  const checklistItemAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditChecklistItem
  );
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  return useMutation<
    {
      createdChecklist: Checklist;
      createdChecklistItems: ChecklistItem[];
    },
    Error,
    Props,
    MutationContext
  >(
    async ({
      activityExternalId,
      operations,
      plannedStartTime,
      plannedEndTime,
      assignees,
    }) => {
      if (operations.length === 0)
        throw new Error(
          t(
            'CREATE_CHECKLIST_FROM_ACTIVITY_ERROR_NO_TASKS',
            'Checklist cannot be created from an activity without tasks'
          )
        );

      const activityFilter: Filters[] = [];

      if (configFilters.rootAssetExternalIds) {
        activityFilter.push(configFilters.rootAssetExternalIds);
      }

      const [activity] = await activityService.getActivities({
        and: [
          ...activityFilter,
          {
            equals: {
              property: 'externalId',
              eq: activityExternalId,
            },
          },
        ],
      });
      const uniqueAssetExternalIds = uniq(
        operations.map(({ assetExternalId }) => assetExternalId).filter(Boolean)
      ) as string[];

      let apmAssets: APMAsset[] = [];
      if (
        Boolean(uniqueAssetExternalIds) &&
        uniqueAssetExternalIds.length > 0
      ) {
        apmAssets = await assetService.listAPMAssets({
          in: {
            property: 'externalId',
            in: uniqueAssetExternalIds,
          },
        });
      }

      const { newChecklist, newChecklistItems, newChecklistItemEdges } =
        generateChecklist({
          activity,
          operations,
          user: user!,
          rootAPMAsset: rootAPMAsset!,
          operationAPMAssets: apmAssets,
          assignees: assignees ?? [],
          userInstanceSpace: apmClient.userInstanceSpace,
          plannedStartTime: plannedStartTime!,
          plannedEndTime: plannedEndTime!,
        });

      const batchedChecklistItems = chunk(
        newChecklistItems,
        dmsItemIngestionLimit
      );
      const batchedChecklistItemEdges = chunk(
        newChecklistItemEdges,
        dmsItemIngestionLimit
      );

      try {
        await checklistService.createChecklist(newChecklist, user!);
        await Promise.all(
          batchedChecklistItems.map((items) =>
            checklistService.createChecklistItems(items, user!)
          )
        );
        await Promise.all(
          batchedChecklistItemEdges.map((edges) =>
            apmClient.edgeService
              .upsert(edges)
              .then((result) => result.data.items)
          )
        );

        return {
          createdChecklist: newChecklist,
          createdChecklistItems: newChecklistItems,
        };
      } catch (error) {
        await Promise.all(
          batchedChecklistItems.map((items) =>
            checklistService.deleteChecklistItems(
              items.map((item) => item.externalId)
            )
          )
        );
        await checklistService.deleteChecklist([newChecklist.externalId]);
        throw error;
      }
    },
    {
      onMutate: async () => {
        const sliTimerStartTime = Date.now();

        return { sliTimerStartTime };
      },
      onSuccess: async (data, _, context) => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.CHECKLIST_CREATE_FROM_ACTIVITY, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });

        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.ACTIVITY_INFINITE_WITH_CHECKLIST],
        });

        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.SEARCH_ACTIVITIES_WITH_CHECKLIST],
        });

        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.CHECKLIST_ITEM_STATUS],
        });
        makeToast({
          type: 'success',
          body: t(
            'CREATE_CHECKLIST_FROM_ACTIVITY_SUCCESS',
            'Checklist created'
          ),
        });
        checklistAuditMetrics.track('Create', data.createdChecklist);
        data.createdChecklistItems.forEach((checklistItem) => {
          checklistItemAuditMetrics.track('Create', checklistItem);
        });
      },
      onError: (err, _, context) => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.CHECKLIST_CREATE_FROM_ACTIVITY, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
        makeToast({
          type: 'danger',
          body: t(
            'CREATE_CHECKLIST_FROM_ACTIVITY_ERROR',
            'Failed to create checklist'
          ),
        });
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: MutationKeys.CHECKLIST_CREATE_FROM_ACTIVITY,
          },
        });
      },
    }
  );
};
