import Konva from 'konva';
import { cloneDeep, isEqual, pick } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  getCanonicalMimeType,
  UnifiedViewer,
  UnifiedViewerEventType,
} from '../../index';
import shouldBeDraggable from '../annotations/shouldBeDraggable';
import shouldBeResizable from '../annotations/shouldBeResizable';
import shouldBeSelectable from '../annotations/shouldBeSelectable';
import { Size } from '../annotations/types';
import { LoadingSpinner } from '../components';
import {
  DEFAULT_CONTAINER_HEIGHT,
  DEFAULT_CONTAINER_WIDTH,
} from '../constants';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import { UnifiedViewerErrorEvent } from '../UnifiedViewer';
import EventEmitter from '../utils/EventEmitter';
import getConstrainedDimensions from '../utils/getConstrainedDimensions';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';
import getResponseCache from '../utils/getResponseCache';
import { NativelySupportedMimeType } from '../utils/mimeTypes/isNativelySupportedMimeType';

import {
  defaultLoadingStatus,
  LoadingStatus,
  TLoadingStatus,
} from './asyncContainerUtils';
import BoundaryRect from './BoundaryRect';
import { CONTAINER_REF_KEY } from './constants';
import { Container, ContainerProps, EventableContainer } from './Container';
import { ContainerEventType } from './ContainerEventType';
import { ContainerType } from './ContainerType';
import { Errorable } from './Errorable';
import { EventListenerMap } from './EventListenerMap';
import getDimensionsFromContainerProps from './getDimensionsFromContainerProps';
import setContainerNodeEventHandlers from './setContainerNodeEventHandlers';
import { ContainerConfig, ImageMimeType } from './types';
import { getHeaderGroup } from './utils';

export type ImageContainerProps<MetadataType = any> = {
  id: string;
  url: string;
  width?: number;
  height?: number;
  type: ContainerType.IMAGE;
  mimeType?: NativelySupportedMimeType;
  dataCacheKey?: string;
} & ContainerProps<MetadataType> &
  EventableContainer;

export default class ImageContainer<MetadataType>
  extends EventEmitter<EventListenerMap>
  implements Container<MetadataType>, Errorable
{
  public readonly id: string;
  public readonly type = ContainerType.IMAGE;
  public loadingStatus: TLoadingStatus = defaultLoadingStatus;
  private isReady: boolean = false;
  protected loadingSpinner?: LoadingSpinner;
  private group: Konva.Group = new Konva.Group({
    [CONTAINER_REF_KEY]: this,
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.CONTAINER_GROUP,
  });
  private headerGroup: Konva.Group = new Konva.Group();
  private contentGroup: Konva.Group = new Konva.Group({
    id: uuid(),
    [UNIFIED_VIEWER_NODE_TYPE_KEY]:
      UnifiedViewerNodeType.CONTAINER_CONTENT_GROUP,
  });
  private boundaryRect: BoundaryRect = new BoundaryRect({
    width: 0,
    height: 0,
  });
  private image: Konva.Image = new Konva.Image({
    image: undefined,
    width: 0,
    height: 0,
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.CONTAINER_IMAGE,
  });

  private props: ImageContainerProps<MetadataType>;
  private contentType: string = '';
  // This is only used for logging/debugging purposes
  private lastReadResponseContentType: string | null = null;
  private hasErrorOccurred: boolean = false;
  private unifiedViewer: UnifiedViewer;

  public constructor(
    props: ImageContainerProps<MetadataType>,
    unifiedViewer: UnifiedViewer
  ) {
    super();
    this.id = props.id;
    this.getNode().id(this.id);
    this.getNode().add(this.headerGroup);
    this.getNode().add(this.contentGroup);

    this.boundaryRect = new BoundaryRect({
      width: this.getContentNode().width(),
      height: this.getContentNode().height(),
    });
    this.getContentNode().add(this.boundaryRect.getNode());
    this.addToContentNode(this.image);
    this.recreateLoadingSpinner();

    this.unifiedViewer = unifiedViewer;
    // This is just set here for TS to correctly detect that it is set.
    this.props = { ...props };
    this.setProps(props, true);
  }

  public setProps(
    props: ImageContainerProps<MetadataType>,
    forceUpdate: boolean = false
  ): void {
    const prevProps = { ...this.props };
    this.props = props;

    if (this.loadingStatus.isLoading) {
      this.recreateLoadingSpinner();
    }

    const propsRequiringUpdate = [
      'width',
      'height',
      'maxWidth',
      'maxHeight',
      'url',
      'label',
    ];
    if (
      !forceUpdate &&
      isEqual(
        pick(prevProps, propsRequiringUpdate),
        pick(props, propsRequiringUpdate)
      )
    ) {
      return;
    }

    // NOTE: The container might have a slightly different height than given by `height` or `maxHeight` due to the way we set label dimensions.
    //       Tracked by: https://cognitedata.atlassian.net/browse/UFV-366
    const propsWidth = this.props.width ?? this.props.maxWidth;
    const propsHeight = this.props.height ?? this.props.maxHeight;
    if (propsWidth !== undefined && propsHeight !== undefined) {
      this.setLabel({ width: propsWidth, height: propsHeight });
    }

    const { width, height, maxWidth, maxHeight } = props;
    this.setContainerDimensions(
      width ?? maxWidth ?? DEFAULT_CONTAINER_WIDTH,
      height ?? maxHeight ?? DEFAULT_CONTAINER_HEIGHT
    );
    this.loadingSpinner?.centerInsideBoundaryRect(this.boundaryRect);

    this.loadImageFromUrl(props.url).then(() => {
      getMetricsLogger()?.trackEvent(TrackedEventType.CREATE_IMAGE_CONTAINER, {
        ...getDimensionsFromContainerProps(this.props),
        mimeType: this.contentType,
      });
    });

    setContainerNodeEventHandlers(
      this.getNode(),
      this.props as ContainerConfig<any>,
      this.unifiedViewer
    );
  }

  public setLoadingStatus(status: LoadingStatus): void {
    this.loadingStatus = { ...defaultLoadingStatus, [status]: true };
    this.onLoadingStatusChange(status);
  }

  private setContentNodeDimensions(dimensions: Size): void {
    this.boundaryRect.setDimensions(dimensions);
    this.getContentNode().size(dimensions);

    if (this.loadingSpinner) {
      this.loadingSpinner.centerInsideBoundaryRect(this.boundaryRect);
    }

    this.emit(ContainerEventType.ON_LAYOUT_CHANGE);
  }

  public getChildren(): Container[] {
    return [];
  }

  public getContentNode(): Konva.Group {
    return this.contentGroup;
  }

  public addToContentNode(node: Konva.Group | Konva.Shape): void {
    this.getContentNode().add(node);
  }

  public setHeight(height: number): void {
    this.getNode().height(height);
  }

  public setWidth(width: number): void {
    this.getNode().width(width);
  }

  public getWidth(): number {
    return this.getContentNode().width();
  }

  public getHeight(): number {
    return (
      this.getContentNode().height() + this.getHeaderNodeDimensions().height
    );
  }

  public setContainerDimensions(width: number, height: number): void {
    this.setWidth(width);
    this.setHeight(height);

    const contentNodeSize = {
      width: width,
      height: height - this.getHeaderNodeDimensions().height,
    };

    this.getContentNode().size(contentNodeSize);
    const imageSize = this.image.size();
    if (Number.isFinite(imageSize.width) && Number.isFinite(imageSize.height)) {
      const constrainedContentNodeSize = getConstrainedDimensions(
        {
          maxWidth: contentNodeSize.width,
          maxHeight: contentNodeSize.height,
        },
        {
          width: this.image.width() || width,
          height: this.image.height() || height,
        }
      );
      this.image.size(constrainedContentNodeSize);
      this.boundaryRect.setDimensions(constrainedContentNodeSize);
    }

    this.emit(ContainerEventType.ON_LAYOUT_CHANGE);
  }

  public setIsReady(isReady: boolean): void {
    if (this.isReady !== isReady) {
      this.boundaryRect.getNode().fill(isReady ? 'transparent' : 'white');
      this.isReady = isReady;
      this.emit(ContainerEventType.ON_READY_STATE_CHANGE, this as Container);
    }
  }

  public getIsReady(): boolean {
    return this.isReady;
  }

  public addChild(_child: Container): void {
    throw new Error('The default addChild method is not implemented');
  }

  private setLabel(containerDimensions: Size): void {
    if (this.props.label !== undefined) {
      this.setHeader(getHeaderGroup(this.props.label, containerDimensions));
    }
  }

  public getNode(): Konva.Group {
    return this.group;
  }

  public setNode(node: Konva.Group): void {
    this.group = node;

    const contentContainerGroup = node.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_CONTENT_GROUP
    );

    this.contentGroup = contentContainerGroup as Konva.Group;
    const headerGroup = node.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_HEADER_GROUP
    );

    this.headerGroup = headerGroup as Konva.Group;

    this.image = this.contentGroup.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_IMAGE
    ) as Konva.Image;

    this.boundaryRect.setNode(
      this.contentGroup.children?.find(
        (n) =>
          n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
          UnifiedViewerNodeType.CONTAINER_BOUNDARY_RECT
      ) as Konva.Rect
    );
  }

  public getHeaderNodeDimensions(): { width: number; height: number } {
    if (this.headerGroup === undefined) {
      return {
        width: 0,
        height: 0,
      };
    }
    // NOTE: we ignore the transform when computing the client rect for the
    // header group. This is to fix the issue that the header group seems to get
    // transformed on update (given that the container id's are the same across
    // the update). The behavior for transforming containers (with headers)
    // should be monitored.
    const { width, height } = this.headerGroup.getClientRect({
      skipTransform: true,
    });
    return { width, height };
  }

  public setHeader(header: Konva.Group): void {
    this.headerGroup.destroy();
    this.headerGroup = header;
    this.getNode().add(this.headerGroup);
    this.recalculateLayout();
  }

  public loadImageFromUrl = async (url: string): Promise<void> => {
    this.setIsReady(false);
    try {
      this.setLoadingStatus(LoadingStatus.LOADING);
      const { contentType } = await getResponseCache().fetch(url, {
        key: this.props.dataCacheKey,
      });
      this.lastReadResponseContentType = contentType;
      this.contentType =
        this.props.mimeType ?? this.lastReadResponseContentType ?? 'unknown';
      switch (getCanonicalMimeType(this.contentType)) {
        // These image file types usually have built-in support by the major browsers.
        // See: https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types#common_image_file_types
        case ImageMimeType.JPEG:
        case ImageMimeType.PNG:
        case ImageMimeType.SVG:
        case ImageMimeType.WEBP:
          await this.loadImageWithBuiltInSupportFromUrl(url);
          break;
        case ImageMimeType.TIFF:
          await this.loadImageFromTiffUrl(url);
          break;
        default: {
          // eslint-disable-next-line no-console
          console.warn(
            `Cannot load image with unsupported mime type ${this.contentType}`
          );
          this.setLoadingStatus(LoadingStatus.ERROR);
        }
      }
    } catch (e) {
      this.onError(e);
    }
  };

  private recreateLoadingSpinner = (): void => {
    // To avoid getting the loading spinner into a bogus state,
    // re-create it when our content node already has one.
    const existingLoadingSpinner = this.getContentNode().findOne(
      (n: Konva.Node) => n instanceof LoadingSpinner
    );
    if (existingLoadingSpinner !== undefined) {
      existingLoadingSpinner.destroy();
      this.loadingSpinner = new LoadingSpinner(this.unifiedViewer);
      this.getContentNode().add(this.loadingSpinner);
    }
    this.loadingSpinner?.centerInsideBoundaryRect(this.boundaryRect);
    this.loadingSpinner?.recalculateSize(this.getWidth());
  };

  private onLoadingStatusChange(status: LoadingStatus) {
    if (status === LoadingStatus.ERROR) {
      // eslint-disable-next-line no-console
      console.error(`Load status: ${status}`);
    }
    if (status === LoadingStatus.LOADING) {
      this.recreateLoadingSpinner();
    } else {
      this.loadingSpinner?.destroy();
    }
  }

  private recalculateLayout(): void {
    const { height } = this.getHeaderNodeDimensions();
    this.headerGroup.y(-height);
  }

  private getConstrainedDimensions = (imageSize: Size): Size => {
    return getConstrainedDimensions(
      {
        maxWidth: this.getContentNode().width(),
        maxHeight: this.getContentNode().height(),
      },
      imageSize
    );
  };

  private loadImageWithBuiltInSupportFromUrl = async (url: string) => {
    Konva.Image.fromURL(
      url,
      (image: Konva.Image) => {
        // Reset the image node size to the original size of the loaded image
        this.image.size(image.size());
        this.setContainerDimensions(
          (this.props.width ?? this.props.maxWidth) === undefined
            ? image.width()
            : this.getWidth(),
          (this.props.height ?? this.props.maxHeight) === undefined
            ? image.height()
            : this.getHeight()
        );
        // A tongue-twister: transfers the content of the loaded image to the existing image node
        this.image.image(image.image());
        const { width: constrainedWidth, height: constrainedHeight } =
          this.getConstrainedDimensions(this.image.size());
        this.image.size({ width: constrainedWidth, height: constrainedHeight });
        this.setContentNodeDimensions(this.image.size());

        // NOTE: The container might have a slightly different height than given by `height` or `maxHeight` due to the way we set label dimensions.
        //       Tracked by: https://cognitedata.atlassian.net/browse/UFV-366
        // TODO: investigate? this could be undefined
        if (this.headerGroup?.width() > constrainedWidth) {
          this.setLabel({ width: constrainedWidth, height: constrainedHeight });
        }

        this.setLoadingStatus(LoadingStatus.SUCCESS);
        this.setIsReady(true);
        this.onUpdate();
      },
      // Note: The error parameter here really gives us nothing useful.
      (e) => {
        this.onError(e);
      }
    );
  };

  private onError = (e: unknown) => {
    // eslint-disable-next-line no-console
    this.setLoadingStatus(LoadingStatus.ERROR);
    this.setHasErrorOccurred(true);

    const error = {
      message: `Could not load image. Please contact support if this problem persists.`,
    };

    // eslint-disable-next-line no-console
    console.error(
      e,
      `This might be due to incorrect mimeType/contentType defined on the image. mimeType was ${this.props.mimeType} while response contentType was ${this.lastReadResponseContentType}`
    );

    this.emit(
      ContainerEventType.ON_CONTAINER_ERROR,
      this as ImageContainer<any>,
      error as UnifiedViewerErrorEvent
    );
  };

  private loadImageFromTiffUrl = async (url: string): Promise<void> => {
    const { data } = await getResponseCache().fetch(url, {
      key: this.props.dataCacheKey,
    });
    // Convert the TIFF blob to a data url which Konva can parse
    // See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs
    const UTIF = (await import('../../vendor/utif/UTIF')).default;
    const dataUrl: string = UTIF.bufferToURI(data);
    await this.loadImageWithBuiltInSupportFromUrl(dataUrl);
    this.onUpdate();
  };

  public serialize = (): ContainerConfig => {
    const scaleX = this.getNode().scaleX();
    const scaleY = this.getNode().scaleY();

    return {
      id: this.props.id,
      type: this.type,
      url: this.props.url,
      label: this.props.label,
      width: this.getWidth() * scaleX,
      height: this.getHeight() * scaleY,
      maxWidth: this.props.maxWidth ? this.props.maxWidth * scaleX : undefined,
      maxHeight: this.props.maxHeight
        ? this.props.maxHeight * scaleY
        : undefined,
      x: this.getNode().x(),
      y: this.getNode().y(),
      isSelectable: this.props.isSelectable,
      isDraggable: this.props.isDraggable,
      metadata: cloneDeep(this.props.metadata),
    };
  };

  public isSelectable = (): boolean =>
    shouldBeSelectable(this.props.isSelectable);

  public isDraggable = (): boolean => shouldBeDraggable(this.props.isDraggable);

  public isResizable = (): boolean => shouldBeResizable(this.props.isResizable);

  public getHasErrorOccurred(): boolean {
    return this.hasErrorOccurred;
  }

  public setHasErrorOccurred(errorOccurred: boolean): void {
    if (this.hasErrorOccurred !== errorOccurred) {
      this.hasErrorOccurred = errorOccurred;
    }
  }

  private onUpdate = () => {
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      containers: [this.serialize()],
      annotations: [],
    });
  };
}
