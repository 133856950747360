import type { FC } from 'react';

import type { ProgressType } from './elements';
import { Container, Progress } from './elements';

type Props = {
  progress: number;
  type?: ProgressType;
  rounded?: boolean;
};

export const ProgressBar: FC<Props> = ({ progress = 0, type, rounded }) => {
  return (
    <Container rounded={rounded} className="progress-bar">
      <Progress progress={progress} type={type} rounded={rounded} />
    </Container>
  );
};
