import Konva from 'konva';
import { cloneDeep, isEqual, pick, debounce } from 'lodash';
import { v4 as uuid } from 'uuid';

import { getPdfCache, UnifiedViewerEventType } from '../../../index';
import type { PDFDocumentProxy } from '../../../vendor/pdfjs-dist';
import { importPDFJS } from '../../../vendor/pdfjs-dist';
import shouldBeDraggable from '../../annotations/shouldBeDraggable';
import shouldBeResizable from '../../annotations/shouldBeResizable';
import shouldBeSelectable from '../../annotations/shouldBeSelectable';
import { Size } from '../../annotations/types';
import { LoadingSpinner } from '../../components';
import {
  DEFAULT_CONTAINER_HEIGHT,
  DEFAULT_CONTAINER_WIDTH,
} from '../../constants';
import {
  IdsByType,
  UNIFIED_VIEWER_NODE_TYPE_KEY,
  UnifiedViewerNodeType,
} from '../../types';
import { UnifiedViewerErrorEvent, UnifiedViewer } from '../../UnifiedViewer';
import { AbortError } from '../../utils/errors';
import EventEmitter from '../../utils/EventEmitter';
import getConstrainedDimensions from '../../utils/getConstrainedDimensions';
import getMetricsLogger, {
  TrackedEventType,
} from '../../utils/getMetricsLogger';
import isApproximatelyEqual from '../../utils/isApproximatelyEqual';
import { NativelySupportedMimeType } from '../../utils/mimeTypes/isNativelySupportedMimeType';
import retryWithExponentialBackoff from '../../utils/retryWithExponentialBackoff';
import {
  defaultLoadingStatus,
  LoadingStatus,
  TLoadingStatus,
} from '../asyncContainerUtils';
import BoundaryRect from '../BoundaryRect';
import { CONTAINER_REF_KEY } from '../constants';
import { Container, ContainerProps, EventableContainer } from '../Container';
import { ContainerEventType } from '../ContainerEventType';
import { ContainerType } from '../ContainerType';
import { Errorable } from '../Errorable';
import { EventListenerMap } from '../EventListenerMap';
import getDimensionsFromContainerProps from '../getDimensionsFromContainerProps';
import setContainerNodeEventHandlers from '../setContainerNodeEventHandlers';
import { ContainerConfig, DocumentMimeType } from '../types';
import { getHeaderGroup, getHeaderHeight } from '../utils';

import {
  DEFAULT_PAGE,
  LOADING_SPINNER_DELAY_MS,
  PDF_TO_CSS_UNITS,
  LOW_DETAIL_RESOLUTION,
  HIGH_DETAIL_RENDER_DEBOUNCE_MS,
} from './constants';
import getCanvasFromPdfPage from './getCanvasFromPdfPage';
import getVisibleRegion, { type VisibleRegion } from './getVisibleRegion';

export type DocumentContainerProps<MetadataType = any> = {
  id: string;
  url: string;
  page?: number;
  type: ContainerType.DOCUMENT;
  mimeType?: NativelySupportedMimeType;
  dataCacheKey?: string;
} & ContainerProps<MetadataType> &
  EventableContainer;

/**
 * A container for PDF documents
 *
 * ### Rendering Strategy ###
 *
 * PDF rendering is done using the PDFJS library. It is an expensive operation,
 * so great care has been taken to provide good performance for the user.
 *
 * Performance is in tension between image quality, rendering speed, and memory
 * usage. To balance these concerns, we use two different rendering strategies:
 *
 * 1. Persistent low detail:
 *
 *    The full page is rendered once at a low resolution, minimizing CPU and
 *    memory usage. It is kept in memory to be available when the user pans or
 *    zooms the canvas, but is only able to help the user see the "shape" of the
 *    image and not the details.
 *
 * 2. Visible high detail:
 *
 *    On top of the low detail image, we render a pixel perfect image of the
 *    visible region of the page.
 *
 *    "Pixel perfect" means that for every pixel on the user's display that is
 *    covered by the container, we render a pixel from the PDF, giving a high
 *    quality image.
 *
 *    "Visible region" means we check which part of the page is visible in the
 *    Konva stage viewport, and how large it appears on screen. If the container
 *    is entirely off screen, rendering is skipped. When the camera is zoomed
 *    out, we only need to render a small image. When zoomed in, we'll never
 *    render more than the stage size.
 *
 *    Since the visible region can change rapidly as the user pans and zooms,
 *    this image is constantly rerendered with a debounce from the
 *    UnifiedViewerRenderer.
 *
 * These strategies together provide a good user experience. The low detail
 * image helps wayfind while moving the camera, and the high detail image
 * provides the best quality possible after a brief delay.
 */
export default class DocumentContainer<MetadataType>
  extends EventEmitter<EventListenerMap>
  implements Container<MetadataType>, Errorable
{
  public readonly type = ContainerType.DOCUMENT;
  public readonly id: string;
  public loadingStatus: TLoadingStatus = defaultLoadingStatus;
  private isReady: boolean = false;
  private hasErrorOccurred: boolean = false;
  protected loadingSpinner: LoadingSpinner | null = null;
  private group: Konva.Group = new Konva.Group({
    [CONTAINER_REF_KEY]: this,
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.CONTAINER_GROUP,
  });
  private headerGroup: Konva.Group = new Konva.Group({
    [UNIFIED_VIEWER_NODE_TYPE_KEY]:
      UnifiedViewerNodeType.CONTAINER_HEADER_GROUP,
  });
  private contentGroup: Konva.Group = new Konva.Group({
    id: uuid(),
    [UNIFIED_VIEWER_NODE_TYPE_KEY]:
      UnifiedViewerNodeType.CONTAINER_CONTENT_GROUP,
  });
  private maxWidth: number | undefined;
  private maxHeight: number | undefined;
  private boundaryRect: BoundaryRect = new BoundaryRect({
    width: 0,
    height: 0,
  });
  private image: Konva.Image = new Konva.Image({
    image: undefined,
    width: 0,
    height: 0,
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.CONTAINER_IMAGE,
  });
  private lowDetailImage: Konva.Image = new Konva.Image({
    image: undefined,
    width: 0,
    height: 0,
    [UNIFIED_VIEWER_NODE_TYPE_KEY]:
      UnifiedViewerNodeType.CONTAINER_LOW_DETAIL_IMAGE,
  });
  private prevVisibleRegion: VisibleRegion | null = null;
  private lastRenderTaskAbortController?: AbortController;

  private props: DocumentContainerProps<MetadataType>;
  private unifiedViewer: UnifiedViewer;

  public constructor(
    props: DocumentContainerProps<MetadataType>,
    unifiedViewer: UnifiedViewer
  ) {
    super();
    this.id = props.id;
    this.getNode().id(this.id);
    this.getNode().add(this.headerGroup);
    this.getNode().add(this.contentGroup);
    this.getContentNode().add(this.boundaryRect.getNode());
    this.addToContentNode(this.lowDetailImage);
    this.addToContentNode(this.image);

    // This is just set here for TS to correctly detect that it is set.
    this.props = props;
    this.unifiedViewer = unifiedViewer;
    this.setProps(props, true);
    this.setLoadingStatus(LoadingStatus.LOADING);

    this.unifiedViewer.addEventListener(
      UnifiedViewerEventType.ON_VIEWPORT_CHANGE,
      this.onViewportChange
    );

    this.unifiedViewer.addEventListener(
      UnifiedViewerEventType.ON_DRAG_MOVE,
      this.onDragMove
    );
  }

  public onDestroy(): void {
    this.unifiedViewer.removeEventListener(
      UnifiedViewerEventType.ON_VIEWPORT_CHANGE,
      this.onViewportChange
    );

    this.unifiedViewer.removeEventListener(
      UnifiedViewerEventType.ON_DRAG_MOVE,
      this.onDragMove
    );
  }

  private propsRequiringUpdate = (
    props: DocumentContainerProps<MetadataType>
  ) =>
    pick(props, [
      'width',
      'height',
      'maxWidth',
      'maxHeight',
      'url',
      'page',
      'label',
      'x',
      'y',
    ]);

  private propsRequiringFullRender = (
    props: DocumentContainerProps<MetadataType>
  ) => pick(props, ['url', 'page']);

  public setProps(
    baseProps: DocumentContainerProps<MetadataType>,
    forceUpdate = false
  ): void {
    const prevProps = this.props;
    const props = { ...baseProps, page: baseProps.page ?? DEFAULT_PAGE };
    this.props = { ...props };

    const { maxWidth, maxHeight } = props;
    this.maxWidth = maxWidth;
    this.maxHeight = maxHeight;

    if (this.loadingStatus.isLoading) {
      this.recreateLoadingSpinner();
    }

    if (
      !forceUpdate &&
      isEqual(
        this.propsRequiringUpdate(prevProps),
        this.propsRequiringUpdate(props)
      )
    ) {
      return;
    }

    if (
      forceUpdate ||
      !isEqual(
        this.propsRequiringFullRender(prevProps),
        this.propsRequiringFullRender(props)
      )
    ) {
      // When the page content changes, we need to re-render both the low detail
      // and high detail images. Reset the visible region to force a high detail
      // render.
      this.prevVisibleRegion = null;

      // Let the high detail renders schedule before the low detail ones, as
      // they can actually be faster since offscreen rendering is skipped.
      queueMicrotask(() => this.safelyRenderLowDetailImage());
    }

    this.recalculateLayout();
    this.safelyRenderVisibleRegion();

    getPdfCache()
      .getPdfNumPages(this.props.url, { key: this.props.dataCacheKey })
      .then((pageCount) =>
        getMetricsLogger()?.trackEvent(
          TrackedEventType.CREATE_DOCUMENT_CONTAINER,
          {
            ...getDimensionsFromContainerProps(this.props),
            mimeType: DocumentMimeType.PDF,
            page: this.props.page ?? 1,
            pageCount,
          }
        )
      )
      .catch((e) => this.onError(e));

    setContainerNodeEventHandlers(
      this.getNode(),
      this.props as ContainerConfig<any>,
      this.unifiedViewer
    );
  }

  private onDragMove = debounce(
    (ids: IdsByType) => {
      if (ids.containerIds.includes(this.id)) {
        this.safelyRenderVisibleRegion();
      }
    },
    HIGH_DETAIL_RENDER_DEBOUNCE_MS,
    { leading: false, trailing: true }
  );

  private onViewportChange = debounce(
    () => this.safelyRenderVisibleRegion(),
    HIGH_DETAIL_RENDER_DEBOUNCE_MS,
    { leading: false, trailing: true }
  );

  public async getPdf(): Promise<PDFDocumentProxy> {
    return getPdfCache().getPdf(this.props.url, {
      key: this.props.dataCacheKey,
    });
  }

  public get pageNumber(): number {
    return this.props.page ?? DEFAULT_PAGE;
  }

  /**
   * Recalculates the layout of the document container.
   *
   * This function performs layout using normalized sizes to fit the header and
   * content inside the max width and height, taking into account the aspect
   * ratio of the page as well as the container.
   *
   * @param pageViewport - The size of the page viewport, as measured from
   * PDFJS. If not provided, the size of the content node is used, which after
   * the first render should be the same as the page viewport.
   * @returns The constrained size of the page after layout calculations.
   */
  private recalculateLayout(pageViewport: Size = this.getContentNode().size()) {
    const getNormalizedSize = (size: Size) => {
      const aspectRatio = size.height > 0 ? size.width / size.height : 1;
      const normalized =
        aspectRatio > 1
          ? { width: 1, height: 1 / aspectRatio }
          : { width: aspectRatio, height: 1 };
      return normalized;
    };
    const currentSize = { ...this.getNode().size() };

    const maxWidth =
      this.props.width ?? this.props.maxWidth ?? DEFAULT_CONTAINER_WIDTH;

    const maxHeight =
      this.props.height ?? this.props.maxHeight ?? DEFAULT_CONTAINER_HEIGHT;

    const normalizedPageSize = getNormalizedSize(pageViewport);
    const normalizedHeaderHeight =
      this.props.label !== undefined ? getHeaderHeight(normalizedPageSize) : 0;
    const normalizedTotalSize = getNormalizedSize({
      width: normalizedPageSize.width,
      height: normalizedPageSize.height + normalizedHeaderHeight,
    });
    const normalizedContainerSize = getNormalizedSize({
      width: maxWidth,
      height: maxHeight,
    });
    const normalizedAspectFit = getNormalizedSize(
      getConstrainedDimensions(
        {
          maxWidth: normalizedContainerSize.width,
          maxHeight: normalizedContainerSize.height,
        },
        normalizedTotalSize
      )
    );

    // Proportion of the content to the total size
    const { scale: contentScale } = getConstrainedDimensions(
      {
        maxWidth: normalizedTotalSize.width,
        maxHeight: normalizedTotalSize.height,
      },
      normalizedPageSize
    );

    const aspectFit = getConstrainedDimensions(
      { maxWidth, maxHeight },
      normalizedAspectFit
    );

    const contentSize = {
      width: normalizedPageSize.width * contentScale * aspectFit.scale,
      height: normalizedPageSize.height * contentScale * aspectFit.scale,
    };

    const constrainedPageSize = getConstrainedDimensions(
      { maxWidth: contentSize.width, maxHeight: contentSize.height },
      pageViewport
    );

    // Approximate comparison to avoid over-updating from floating point errors
    const { scale: _scale, ...nextSize } = aspectFit;
    if (!isApproximatelyEqual(currentSize, nextSize)) {
      // Update layout
      if (this.getMaxWidth() === undefined) {
        this.setMaxWidth(aspectFit.width);
      }
      if (this.getMaxHeight() === undefined) {
        this.setMaxHeight(aspectFit.height);
      }
      this.getNode().size(aspectFit);
      this.getContentNode().size(contentSize);
      this.lowDetailImage.size(contentSize);
      this.setLabel(contentSize);
      this.layoutImage(this.prevVisibleRegion);
      this.boundaryRect.setDimensions(contentSize);
      if (this.loadingSpinner) {
        this.loadingSpinner.centerInsideBoundaryRect(this.boundaryRect);
      }
      this.emit(ContainerEventType.ON_LAYOUT_CHANGE);
    }
    return constrainedPageSize;
  }

  private layoutImage = (visibleRegion: VisibleRegion | null) => {
    if (!visibleRegion) {
      return;
    }
    const contentSize = this.getContentNode().size();
    const { normalizedRect } = visibleRegion;
    this.image.size({
      width: contentSize.width * normalizedRect.width,
      height: contentSize.height * normalizedRect.height,
    });
    this.image.offset({
      x: contentSize.width * -normalizedRect.x,
      y: contentSize.height * -normalizedRect.y,
    });
  };

  /**
   * Ensures page is loaded from the PDF cache. When the page is loaded, the PDF
   * viewport is checked and the layout is recalculated.
   */
  private async loadPage(signal?: AbortSignal) {
    // Don't immediately set loading state, since on rerenders we usually have
    // the page already loaded. Setting the loading state creates a spinner so
    // it's preferable to wait a bit. If the page loads within a short delay,
    // cancel this timeout.
    const timer = setTimeout(() => {
      this.setIsReady(false);
      this.setLoadingStatus(LoadingStatus.LOADING);
    }, LOADING_SPINNER_DELAY_MS);

    const checkIfAborted = () => {
      if (signal?.aborted) {
        clearTimeout(timer);
        throw new AbortError();
      }
    };

    const pdf = await this.getPdf();
    checkIfAborted();
    const page = await pdf.getPage(this.pageNumber);
    checkIfAborted();

    // When the page loads, we need to update the container layout to match the
    // page dimensions.
    const viewport = page.getViewport({ scale: PDF_TO_CSS_UNITS });
    const pageSize = this.recalculateLayout(viewport);

    clearTimeout(timer);
    // Don't clear the spinner if we haven't rendered an image
    if (this.lowDetailImage.image() !== undefined) {
      this.setLoadingStatus(LoadingStatus.SUCCESS);
    }
    return { page, pageSize };
  }

  private async safelyRenderVisibleRegion() {
    const abortController = new AbortController();
    if (
      this.lastRenderTaskAbortController !== undefined &&
      !this.lastRenderTaskAbortController.signal.aborted
    ) {
      this.lastRenderTaskAbortController.abort();
    }
    this.lastRenderTaskAbortController = abortController;
    const signal = this.lastRenderTaskAbortController.signal;
    const run = async (backoffFactor: number) =>
      this.renderVisibleRegion(window.devicePixelRatio * backoffFactor, signal);
    try {
      await retryWithExponentialBackoff({ run, signal });
      if (this.image.image() !== undefined) {
        this.setLoadingStatus(LoadingStatus.SUCCESS);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      if (this.lastRenderTaskAbortController === abortController) {
        this.lastRenderTaskAbortController = undefined;
      }
    }
  }

  /**
   * Renders the visible portion of the PDF image into a canvas element, and
   * updates the image node. Has several early return conditions to avoid
   * rendering when the container is offscreen or the viewport has not changed.
   *
   * @param qualityScale resolution scale factor to adjust quality
   * @param signal abort signal to cancel rendering
   */
  private async renderVisibleRegion(
    qualityScale: number,
    signal: AbortSignal
  ): Promise<void> {
    const { page, pageSize } = await this.loadPage(signal);

    const visibleRegion = getVisibleRegion(
      this.unifiedViewer.stage,
      this.getContentNode().getAbsolutePosition(),
      pageSize,
      qualityScale
    );

    // If the container is not visible, we should not render the image
    if (visibleRegion === null) {
      this.image.image(undefined);
      return;
    }

    // If the resolution of this "high detail" render would be lower than the
    // low detail one, skip it.
    const effectiveResolution = Math.max(
      visibleRegion.canvasSize.width / visibleRegion.normalizedRect.width,
      visibleRegion.canvasSize.height / visibleRegion.normalizedRect.height
    );
    if (effectiveResolution < LOW_DETAIL_RESOLUTION) {
      this.image.image(undefined);
      return;
    }

    // If we have already rendered this region, we can skip rendering
    // Approximate comparison to avoid over-updating from floating point errors
    if (isApproximatelyEqual(visibleRegion, this.prevVisibleRegion)) {
      return;
    }

    const t1 = performance.now();
    const canvas = await getCanvasFromPdfPage(
      page,
      visibleRegion.pdfViewport,
      visibleRegion.canvasSize,
      signal
    );
    const t2 = performance.now();

    const operatorList = await page.getOperatorList({
      intent: 'print',
      annotationMode: (await importPDFJS()).AnnotationMode.DISABLE,
    });
    getMetricsLogger()?.trackEvent(
      TrackedEventType.PERFORMANCE_DOCUMENT_RENDER,
      {
        currentScale: visibleRegion.pdfViewport.scale,
        width: this.props.width,
        height: this.props.height,
        maxWidth: this.props.maxWidth,
        maxHeight: this.props.maxHeight,
        canvasSize: visibleRegion.canvasSize,
        viewport: visibleRegion.pdfViewport,
        numOperations: operatorList.fnArray.length,
        mimeType: DocumentMimeType.PDF,
        $duration: parseFloat(((t2 - t1) / 1000).toFixed(3)),
      }
    );

    const currentlyVisibleRegion = getVisibleRegion(
      this.unifiedViewer.stage,
      this.getContentNode().getAbsolutePosition(),
      pageSize,
      qualityScale
    );

    if (
      !currentlyVisibleRegion ||
      !isApproximatelyEqual(visibleRegion, currentlyVisibleRegion)
    ) {
      // The viewport has changed or is no longer visible since we started
      // rendering, so we should abort
      return;
    }

    this.layoutImage(visibleRegion);
    this.image.image(canvas);
    this.prevVisibleRegion = visibleRegion;
  }

  /** Renders the entire PDF page to the provided image and sizes it appropriately */
  public async safelyRenderPageToImage(
    image: Konva.Image,
    {
      scale = 1,
      maxSize,
      signal,
    }: {
      scale?: number;
      maxSize?: { maxWidth: number; maxHeight: number };
      signal?: AbortSignal;
    }
  ): Promise<void> {
    const run = async (backoffFactor: number) => {
      await this.renderPageToImage(image, {
        scale: scale * backoffFactor,
        maxSize,
        signal,
      });
      this.onUpdate();
    };
    try {
      await retryWithExponentialBackoff({ run, signal });
    } catch (e) {
      this.onError(e);
    }
  }

  private async renderPageToImage(
    image: Konva.Image,
    {
      scale = 1,
      maxSize,
      signal,
    }: {
      scale?: number;
      maxSize?: { maxWidth: number; maxHeight: number };
      signal?: AbortSignal;
    }
  ): Promise<void> {
    const { page, pageSize } = await this.loadPage(signal);
    const size = getConstrainedDimensions(
      {
        maxWidth: (maxSize?.maxWidth ?? pageSize.width) * scale,
        maxHeight: (maxSize?.maxHeight ?? pageSize.height) * scale,
      },
      pageSize
    );
    const renderScale = PDF_TO_CSS_UNITS * pageSize.scale * size.scale;

    const t1 = performance.now();
    const canvas = await getCanvasFromPdfPage(
      page,
      { scale: renderScale },
      size,
      signal
    );
    const t2 = performance.now();

    image.image(canvas);
    image.size(pageSize);
    image.offset({ x: 0, y: 0 });
    this.setIsReady(true);

    const operatorList = await page.getOperatorList({
      intent: 'print',
      annotationMode: (await importPDFJS()).AnnotationMode.DISABLE,
    });
    getMetricsLogger()?.trackEvent(
      TrackedEventType.PERFORMANCE_DOCUMENT_RENDER,
      {
        currentScale: renderScale,
        width: this.props.width,
        height: this.props.height,
        maxWidth: this.props.maxWidth,
        maxHeight: this.props.maxHeight,
        numOperations: operatorList.fnArray.length,
        mimeType: DocumentMimeType.PDF,
        viewport: { scale: renderScale },
        canvasSize: size,
        $duration: parseFloat(((t2 - t1) / 1000).toFixed(3)),
      }
    );
  }

  private async safelyRenderLowDetailImage(): Promise<void> {
    await this.safelyRenderPageToImage(this.lowDetailImage, {
      maxSize: {
        maxWidth: LOW_DETAIL_RESOLUTION,
        maxHeight: LOW_DETAIL_RESOLUTION,
      },
    });
    this.setLoadingStatus(LoadingStatus.SUCCESS);
  }

  private onError = (e: unknown) => {
    if (e instanceof AbortError) {
      // eslint-disable-next-line no-console
      console.debug(`Aborted loading of document container ${this.id}`);
      return;
    }

    // eslint-disable-next-line no-console
    console.error(e);
    this.setLoadingStatus(LoadingStatus.ERROR);
    this.setHasErrorOccurred(true);
    getPdfCache().deletePdfCacheEntry(this.props.url, {
      key: this.props.dataCacheKey,
    });
    const error = {
      message: `
      We couldn't find that file. Check with your admin that you have the correct access. If this doesn't solve the issue, the file may have a new ID or be corrupted.`,
    };
    this.emit(
      ContainerEventType.ON_CONTAINER_ERROR,
      this as DocumentContainer<any>,
      error as UnifiedViewerErrorEvent
    );
  };

  public getChildren(): Container[] {
    return [];
  }

  public setMaxWidth(maxWidth: number): void {
    this.maxWidth = maxWidth;
  }

  public setMaxHeight(maxHeight: number): void {
    this.maxHeight = maxHeight;
  }

  public getMaxWidth(): number | undefined {
    return this.maxWidth;
  }

  public getMaxHeight(): number | undefined {
    return this.maxHeight;
  }

  public getContentNode(): Konva.Group {
    return this.contentGroup;
  }

  public addToContentNode(node: Konva.Group | Konva.Shape): void {
    this.getContentNode().add(node);
  }

  public setHeight(height: number): void {
    this.getNode().height(height);
  }

  public setWidth(width: number): void {
    this.getNode().width(width);
  }

  public getWidth(): number {
    return this.getContentNode().width();
  }

  public getHeight(): number {
    return (
      this.getContentNode().height() + this.getHeaderNodeDimensions().height
    );
  }

  public setIsReady(isReady: boolean): void {
    if (this.isReady !== isReady) {
      this.isReady = isReady;
      this.emit(ContainerEventType.ON_READY_STATE_CHANGE, this as Container);
    }
  }

  public getIsReady(): boolean {
    return this.isReady;
  }

  public setHasErrorOccurred(errorOccurred: boolean): void {
    if (this.hasErrorOccurred !== errorOccurred) {
      this.hasErrorOccurred = errorOccurred;
    }
  }

  public getHasErrorOccurred(): boolean {
    return this.hasErrorOccurred;
  }

  public addChild(_child: Container): void {
    throw new Error('The default addChild method is not implemented');
  }

  private setLabel(containerDimensions: Size): void {
    if (this.props.label !== undefined) {
      this.setHeader(getHeaderGroup(this.props.label, containerDimensions));
    }
  }

  public getNode(): Konva.Group {
    return this.group;
  }

  public setNode(node: Konva.Group): void {
    this.group = node;

    const contentContainerGroup = node.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_CONTENT_GROUP
    );

    this.contentGroup = contentContainerGroup as Konva.Group;

    const headerGroup = node.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_HEADER_GROUP
    );

    this.headerGroup = headerGroup as Konva.Group;

    this.lowDetailImage = this.contentGroup.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_LOW_DETAIL_IMAGE
    ) as Konva.Image;

    this.image = this.contentGroup.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_IMAGE
    ) as Konva.Image;

    this.boundaryRect.setNode(
      this.contentGroup.children?.find(
        (n) =>
          n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
          UnifiedViewerNodeType.CONTAINER_BOUNDARY_RECT
      ) as Konva.Rect
    );
  }

  public getHeaderNodeDimensions(): { width: number; height: number } {
    if (this.headerGroup === undefined) {
      return {
        width: 0,
        height: 0,
      };
    }
    // NOTE: we ignore the transform when computing the client rect for the
    // header group. This is to fix the issue that the header group seems to get
    // transformed on update (given that the container ids are the same across
    // the update). The behavior for transforming containers (with headers)
    // should be monitored.
    const { width, height } = this.headerGroup.getClientRect({
      skipTransform: true,
    });
    return { width, height };
  }

  public setHeader(header: Konva.Group): void {
    this.headerGroup?.destroy();
    this.headerGroup = header;
    this.getNode().add(this.headerGroup);

    const { height } = this.getHeaderNodeDimensions();
    this.headerGroup.y(-height);
  }

  public setLoadingStatus(status: LoadingStatus): void {
    this.loadingStatus = { ...defaultLoadingStatus, [status]: true };
    this.onLoadingStatusChange(status);
  }

  private recreateLoadingSpinner = (): void => {
    // To avoid getting the loading spinner into a bogus state,
    // re-create it when our content node already has one.
    this.loadingSpinner?.destroy();
    this.loadingSpinner = new LoadingSpinner(this.unifiedViewer);
    this.getContentNode().add(this.loadingSpinner);
    this.loadingSpinner.centerInsideBoundaryRect(this.boundaryRect);
    this.loadingSpinner.recalculateSize(this.getWidth());
  };

  private onLoadingStatusChange(status: LoadingStatus): void {
    if (status === LoadingStatus.ERROR) {
      // eslint-disable-next-line no-console
      console.error(`Load status: ${status}`);
    }
    if (status === LoadingStatus.LOADING) {
      this.recreateLoadingSpinner();
    } else {
      this.loadingSpinner?.destroy();
      this.loadingSpinner = null;
    }
  }

  public serialize = (): ContainerConfig => {
    const scaleX = this.getNode().scaleX();
    const scaleY = this.getNode().scaleY();

    const maxWidth = this.getMaxWidth();
    const maxHeight = this.getMaxHeight();

    return {
      id: this.props.id,
      type: this.type,
      url: this.props.url,
      label: this.props.label,
      page: this.props.page,
      width: this.getWidth() * scaleX,
      height: this.getHeight() * scaleY,
      maxWidth: maxWidth ? maxWidth * scaleX : undefined,
      maxHeight: maxHeight ? maxHeight * scaleY : undefined,
      x: this.getNode().x(),
      y: this.getNode().y(),
      isSelectable: this.props.isSelectable,
      isDraggable: this.props.isDraggable,
      metadata: cloneDeep(this.props.metadata),
    };
  };

  public isSelectable = (): boolean =>
    shouldBeSelectable(this.props.isSelectable);

  public isDraggable = (): boolean => shouldBeDraggable(this.props.isDraggable);

  public isResizable = (): boolean => shouldBeResizable(this.props.isResizable);

  private onUpdate = () => {
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      containers: [this.serialize()],
      annotations: [],
    });
  };
}
