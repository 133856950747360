import type { FC } from 'react';

import * as S from './elements';
import {
  getIndexOfQuery,
  getIndexOfWordsInQuery,
  getIsHighlighted,
} from './utils';

type Props = {
  text: string;
  searchQuery: string;
};

export const SearchResultHighlight: FC<Props> = ({ text, searchQuery }) => {
  const indexOfSearchQuery = getIndexOfQuery(searchQuery, text);

  // If it contains full query
  if (indexOfSearchQuery >= 0) {
    return (
      <>
        {[...text].map((value, index) => {
          const isHighlighted = getIsHighlighted(
            index,
            indexOfSearchQuery,
            indexOfSearchQuery + searchQuery.length
          );

          return (
            <S.HighlightedName
              $active={isHighlighted}
              key={`highlighted-letter-${String(index)}`}
            >
              {value}
            </S.HighlightedName>
          );
        })}
      </>
    );
  }

  // If it contains one word from query
  const words = getIndexOfWordsInQuery(searchQuery, text);

  return (
    <>
      {[...text].map((value, index) => {
        let isInWord = false;

        words.forEach((word) => {
          if (getIsHighlighted(index, word.firstIndex, word.lastIndex)) {
            isInWord = true;
          }
        });

        return (
          <S.HighlightedName
            $active={isInWord}
            key={`highlighted-letter-${String(index)}`}
          >
            {value}
          </S.HighlightedName>
        );
      })}
    </>
  );
};
