import type {
  LocationFilterDTO,
  ViewConfigDTO,
  ViewConfigEntities,
} from '@cognite/apm-config';
import { ViewSelector } from '@cognite/apm-config/src/components/view-selector';
import { PageHeader } from '@cognite/cogs-lab';
import { Body, Button, Flex, SaveIcon } from '@cognite/cogs.js-v10';
import { useAuthContext } from '@cognite/e2e-auth';
import type { ViewReference } from '@cognite/sdk';
import startCase from 'lodash/startCase';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { ConfigPageContentWrapper, HelpTextAreaWrapper } from '../elements';

import * as S from './elements';
import type { ViewKey } from './types';
import { VIEW_KEYS } from './utils/constants';
import { createViewConfigFromViewReference } from './utils/create-view-config-from-view-reference';
import { getIsThisViewMappingChanged } from './utils/get-is-this-view-mapping-changed';
import { getisViewMappingsChanged } from './utils/get-is-view-mappings-changed';
import { getViewReference } from './utils/get-view-reference';
import { setDefaultViewMappings } from './utils/set-default-view-mappings';
import { updateViewMappings } from './utils/update-view-mappings';

type Props = {
  location: LocationFilterDTO | undefined;
  isSaving: boolean;
  onSave: (location: LocationFilterDTO) => void;
};

export const LocationViewMappings: FC<Props> = ({
  location,
  isSaving,
  onSave,
}) => {
  const { client } = useAuthContext();
  const [viewMappings, setViewMappings] = useState<ViewConfigDTO[]>([]);

  useEffect(() => {
    setViewMappings(setDefaultViewMappings(location?.views));
  }, [location?.views]);

  const isViewMappingsChanged = getisViewMappingsChanged(
    viewMappings,
    location?.views
  );

  const handleSave = () => {
    if (!viewMappings || !location) return;
    onSave({ ...location, views: viewMappings });
  };

  const handleSetViewMappings = (
    viewReference: ViewReference | undefined,
    representsEntity: ViewConfigEntities
  ): ViewConfigDTO[] | undefined => {
    if (!viewReference) return;

    const newViewConfig = createViewConfigFromViewReference(
      viewReference,
      representsEntity
    );

    setViewMappings((prevMappings) =>
      updateViewMappings(prevMappings, [newViewConfig])
    );
  };

  const renderViewSelector = (viewKey: ViewKey) => {
    const isValueUpdated = getIsThisViewMappingChanged(
      viewKey.representsEntity,
      viewMappings,
      location?.views
    );

    return (
      <Flex direction="column" gap={8} key={viewKey.representsEntity}>
        <S.ViewMappingTitle $isChanged={isValueUpdated}>
          {startCase(viewKey.name)} {isValueUpdated && '(updated)'}
        </S.ViewMappingTitle>
        <ViewSelector
          sdk={client}
          filterByInheritedView={viewKey.shouldImplementView}
          viewReference={getViewReference(
            viewMappings.find(
              (view) => view.representsEntity === viewKey.representsEntity
            )
          )}
          onViewReferenceChange={(viewReference) => {
            handleSetViewMappings(
              viewReference,
              viewKey.representsEntity as ViewConfigEntities
            );
          }}
        />
      </Flex>
    );
  };

  return (
    <S.Container>
      <PageHeader fullWidth>
        <PageHeader.TitleArea
          description="Do you want to use customised views?"
          title="View mappings"
        />
        <PageHeader.Actions>
          <Button
            type="primary"
            icon={<SaveIcon />}
            onClick={handleSave}
            loading={isSaving}
            disabled={!isViewMappingsChanged}
          >
            Save
          </Button>
        </PageHeader.Actions>
      </PageHeader>
      <ConfigPageContentWrapper>
        <Flex direction="column" gap={16}>
          {VIEW_KEYS.map((key) => renderViewSelector(key))}
        </Flex>
        <HelpTextAreaWrapper>
          <Body>
            Each entity type has a standard view defined in the cdf_idm space.
          </Body>
          <Body>
            If you&apos;ve created custom views that extend these standard
            views, you can select them here.
          </Body>
          <Body>Otherwise you can just pick the default ones.</Body>
        </HelpTextAreaWrapper>
      </ConfigPageContentWrapper>
    </S.Container>
  );
};
