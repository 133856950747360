import { Body, Flex, Switch } from '@cognite/cogs.js-v10';
import { ComponentContainer } from '@infield/features/ui';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { useAppConfigQuery } from '../hooks';
import { useUpsertAppConfigMutation } from '../hooks/use-mutation';
import type { AppConfig } from '../types';

import * as S from './elements';

interface Props {
  hasAppConfigPermission: boolean;
}

export const AppConfigCopilot: FC<Props> = ({ hasAppConfigPermission }) => {
  const [enableCopilot, setEnableCopilot] = useState(false);
  const { data: appConfig, isSuccess, isLoading } = useAppConfigQuery();
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();
  const copilotConfiguration = appConfig?.featureConfiguration?.copilot;

  useEffect(() => {
    if (isSuccess && copilotConfiguration) {
      setEnableCopilot(copilotConfiguration.enabled);
    }
  }, [isSuccess, copilotConfiguration, appConfig]);

  const hasConfigChanged = enableCopilot !== copilotConfiguration?.enabled;

  const handleSave = async () => {
    if (!appConfig) return;

    const newAppConfig: AppConfig = {
      ...appConfig,
      featureConfiguration: {
        ...appConfig.featureConfiguration,
        copilot: {
          ...appConfig.featureConfiguration?.copilot,
          enabled: enableCopilot,
        },
      },
    };

    await upsertAppConfig({
      newAppConfig,
    });
  };

  return (
    <ComponentContainer>
      <S.Wrapper>
        <Flex direction="column" gap={16}>
          <Flex direction="column" gap={8}>
            <Body size="x-small">
              Enable CoPilot for document search. Note that your documents need
              to be processed and searchable in order to use this feature.
            </Body>
            <Switch
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEnableCopilot(event.target.checked);
              }}
              checked={enableCopilot}
              disabled={!hasAppConfigPermission}
            />
          </Flex>
          <Flex direction="column" gap={4}>
            <S.SaveButton
              onClick={handleSave}
              disabled={!hasConfigChanged}
              loading={isLoading}
            >
              Save configuration
            </S.SaveButton>
          </Flex>
        </Flex>
      </S.Wrapper>
    </ComponentContainer>
  );
};
