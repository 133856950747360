import type {
  ChecklistItemStatus,
  CustomReadingLabelStatusOption,
} from '@cognite/apm-client';
import { Body, Flex, Menu } from '@cognite/cogs.js-v10';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export type Props = {
  option: CustomReadingLabelStatusOption;
  onSelectStatus: (status?: ChecklistItemStatus) => void;
};

export const StatusOption: React.FC<Props> = ({ option, onSelectStatus }) => {
  return (
    <Menu.Item
      key={option.value}
      onClick={() => {
        onSelectStatus(option.value);
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Body size="medium">
          <Trans
            t={t}
            i18nKey={`TEMPLATE_TASK_FORM_CUSTOM_READING_STATUS_LABEL_${option.label.toUpperCase()}`}
          >
            {option.label}
          </Trans>
        </Body>
      </Flex>
    </Menu.Item>
  );
};
