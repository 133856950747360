import { ArrowLeftIcon, Button, CloseIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import * as S from './elements';

type Props = {
  showHelpMenu: boolean;
  setShowHelpMenu: (showHelpMenu: boolean) => void;
  onCloseButtonClick: () => void;
};

export const SidebarHeader: FC<Props> = ({
  showHelpMenu,
  setShowHelpMenu,
  onCloseButtonClick,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.sidebar);

  return (
    <S.StyledHeaderContainer>
      <S.StyledSidebarHeaderLeft>
        {showHelpMenu ? (
          <Button
            type="ghost"
            size="small"
            themeProps={{ theme: 'base', inverted: true }}
            icon={<ArrowLeftIcon />}
            onClick={() => setShowHelpMenu(false)}
            aria-label="back-button"
          />
        ) : (
          <S.StyledLogoContainer>
            <S.StyledCogniteIcon type="logomark" />
          </S.StyledLogoContainer>
        )}
        <S.StyledSidebarTitle>
          {showHelpMenu ? t('SIDEBAR_TITLE_HELP', 'Help') : 'Cognite InField'}
        </S.StyledSidebarTitle>
      </S.StyledSidebarHeaderLeft>
      <S.StyledCloseButton
        type="ghost"
        size="large"
        icon={<CloseIcon />}
        aria-label="close-mobile-sidebar"
        themeProps={{ theme: 'base', inverted: true }}
        onClick={() => {
          onCloseButtonClick();
          setShowHelpMenu(false);
        }}
      />
    </S.StyledHeaderContainer>
  );
};
