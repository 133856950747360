import type { ViewCorePropertyDefinition } from '@cognite/sdk';

export const DEFAULT_ACTIVITY_COLUMNS_FIELD_NAME = [
  'title',
  'type',
  'assetExternalId',
  'startTime',
  'endTime',
  'status',
  'id',
  'resource',
];

export const DEFAULT_ACTIVITY_COLUMNS_FIELD_NAME_AKERBP = [
  'title',
  'assetExternalId',
  'startTime',
  'endTime',
  'id',
  'resource',
];

export const ACTIVITY_BLACKLIST_FIELDS = [
  'assetExternalIds',
  'functionalLocation',
  'isArchived',
  'isInApp',
  'createdAt',
  'createdBy',
  'lastUpdatedDate',
  'cloneOf',
  'mainAsset',
  'mutatedBy',
  'mutatedFields',
  'isMutated',
  'notifications',
  'space',
  'createdTime',
  'lastUpdatedTime',
  'asset',
  'assignedTo',
];

export const ACTIVITY_BLACKLIST_FIELDS_AKERBP = [
  'assetExternalIds',
  'isArchived',
  'isInApp',
  'createdAt',
  'createdBy',
  'lastUpdatedDate',
  'cloneOf',
  'mainAsset',
  'mutatedBy',
  'mutatedFields',
  'isMutated',
  'notifications',
  'space',
  'createdTime',
  'lastUpdatedTime',
  'asset',
  'assignedTo',
];

export type VIEW_FIELD_TYPE = ViewCorePropertyDefinition['type']['type'];

export const STRING_FIELD_TYPE: VIEW_FIELD_TYPE = 'text';
export const NUMBER_FIELD_TYPE: VIEW_FIELD_TYPE[] = [
  'float32',
  'float64',
  'int32',
  'int64',
];
export const DATE_FIELD_TYPE: VIEW_FIELD_TYPE[] = ['timestamp', 'date'];

export const SUPPORTED_FIELD_TYPES: VIEW_FIELD_TYPE[] = [
  // String
  STRING_FIELD_TYPE,
  // Number
  ...NUMBER_FIELD_TYPE,
  // Timestamp
  ...DATE_FIELD_TYPE,
];
