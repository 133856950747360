import { useCurrentUserQuery } from '@infield/features/user';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';

import type { RootLocationConfiguration } from '../types';
import { getIsNewConfigVersion } from '../utils/utils';

import { DEFAULT_IDM_USER_LOCATION_PREFERENCE } from './constants';

export const useSelectedRootLocationConfiguration = ():
  | RootLocationConfiguration
  | undefined => {
  const { appConfig, isIdm } = useAppConfigContext();
  const isNewConfigVersion = getIsNewConfigVersion(appConfig);
  const { data: userData } = useCurrentUserQuery();
  const userLocationPreference = userData?.preferences?.infield?.location;

  if (userLocationPreference) {
    if (isIdm) {
      return DEFAULT_IDM_USER_LOCATION_PREFERENCE;
    }

    const selectedRootLocation =
      appConfig?.featureConfiguration?.rootLocationConfigurations?.find(
        (rootLocation) => {
          if (isNewConfigVersion) {
            return (
              rootLocation.externalId === userLocationPreference.externalId
            );
          }
          return (
            rootLocation.assetExternalId ===
              userLocationPreference.externalId ||
            rootLocation.assetExternalId ===
              userLocationPreference.assetExternalId
          );
        }
      );

    return selectedRootLocation || undefined;
  }
};
