import { z } from '@infield/utils/z';
import styled from 'styled-components';

import handleSvg from './handle';

const cssContentUrl = `url("data:image/svg+xml,${encodeURIComponent(
  handleSvg
)}")`;

export const SplitViewStyleOverwrite = styled.div`
  width: 100%;
  height: 100%;

  .sash::before {
    content: ${cssContentUrl};
    position: absolute;
    isolation: isolate;
    background: transparent;
    z-index: ${z.MINIMUM};
    top: 50%;
    left: -1px;
    width: 12px;
    height: 36px;
  }
`;
