import styled from 'styled-components';

export const PanelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  border-bottom: 1px solid var(--cogs-border--muted);
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
