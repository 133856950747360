import { CDFIDMViewMigrator, type Notification } from '@cognite/apm-client';
import type { Filters, Sort } from '@cognite/fdm-client';
import cloneDeep from 'lodash/cloneDeep';

export class NotificationIDMMigrator extends CDFIDMViewMigrator {
  mapDataOutward = (data: Record<string, any>): Notification => {
    return {
      ...data,
      externalId: data.externalId,
      title: data.name,
      assetExternalId: data.asset?.externalId,
      createdDate: data.sourceCreatedTime,
    };
  };
  mapFiltersInward = (filters: Filters): Filters => {
    const newFilters = cloneDeep(filters);
    Object.keys(filters).forEach((key) => {
      if (key === 'and') {
        newFilters.and = filters.and!.map((f) => this.mapFiltersInward(f));
      }
      if (key === 'or') {
        newFilters.or = filters.or!.map((f) => this.mapFiltersInward(f));
      }
      if (key === 'not') {
        newFilters.not = this.mapFiltersInward(newFilters.not!);
      }

      if (key === 'equals') {
        if (filters.equals?.property === 'assetExternalId') {
          newFilters.equals = undefined;
          newFilters.directRelationFilter = {
            asset: {
              equals: { property: 'externalId', eq: String(filters.equals.eq) },
            },
          };
          newFilters.directRelationInstanceSpace = this.space;
        }
      }
    });
    return newFilters;
  };
  mapSortInward = (sort: Sort): Sort => {
    return sort.map((x) => {
      if (Object.keys(x)[0] === 'createdDate') {
        return { sourceCreatedTime: x.createdDate };
      }
      return x;
    });
  };
}
