import clamp from 'lodash/clamp';

import UnifiedViewerEventType from '../../core/UnifiedViewerEventType';
import type { UnifiedViewer } from '../UnifiedViewer';
import getInputTypeFromWheelEvent, {
  WheelEventInputType,
} from '../utils/getInputTypeFromWheelEvent';

import Interactions from './Interactions';

export default class SingleDocumentInteractions extends Interactions {
  public static readonly HORIZONTAL_INERTIA_THRESHOLD = 10;
  public static readonly HORIZONTAL_INTENT_SAMPLING_TIME = 100;

  public constructor(unifiedViewer: UnifiedViewer) {
    super(unifiedViewer);
    this.unifiedViewer.host.addEventListener('wheel', this.onStageMouseWheel);
    this.unifiedViewer.host.addEventListener(
      'mousedown',
      this.onStageMouseDown
    );
    this.unifiedViewer.host.addEventListener(
      'mousemove',
      this.onStageMouseMove
    );
    this.unifiedViewer.host.addEventListener('mouseup', this.onStageMouseUp);
    this.unifiedViewer.host.addEventListener(
      'contextmenu',
      this.onStageContextMenu
    );
    this.unifiedViewer.host.addEventListener(
      'mouseover',
      this.onStageMouseOver
    );
    this.unifiedViewer.host.addEventListener('mouseout', this.onStageMouseOut);
  }

  private lastEventProcessedAt = Date.now();
  private stickyHorizontalIntent = false;

  private hasHorizontalIntent = (
    evt: WheelEvent & { wheelDeltaY?: number }
  ): boolean => {
    // This method is called to determine if there is a horizontal intent in the current
    // pan sequence. The idea behind it is that if we are in a single document mode, we don't
    // want to easily react to small dx values since they are most likely unintentional.
    // We also don't want to block it entirely since the user might actually want to pan
    // horizontally (for instance when zoomed in).
    const isWithinSamplingTime =
      Date.now() - this.lastEventProcessedAt <
      SingleDocumentInteractions.HORIZONTAL_INTENT_SAMPLING_TIME;
    this.lastEventProcessedAt = Date.now();

    const doesCurrentEventPassThreshold =
      Math.abs(evt.deltaX) >=
      SingleDocumentInteractions.HORIZONTAL_INERTIA_THRESHOLD;

    this.stickyHorizontalIntent = isWithinSamplingTime
      ? this.stickyHorizontalIntent || doesCurrentEventPassThreshold
      : doesCurrentEventPassThreshold;

    return this.stickyHorizontalIntent;
  };

  private onStageMouseWheel = (evt: WheelEvent & { wheelDeltaY?: number }) => {
    evt.preventDefault();
    const inputType = getInputTypeFromWheelEvent(evt);
    if (
      evt.ctrlKey &&
      (inputType === WheelEventInputType.MOUSE_WHEEL ||
        inputType === WheelEventInputType.TOUCHPAD_PINCH_TO_ZOOM)
    ) {
      const scaleFactor = Interactions.TOUCHPAD_SCALE_FACTOR;
      this.onZoom(scaleFactor * evt.deltaY, true);
      return;
    }

    this.unifiedViewer.emit(UnifiedViewerEventType.ON_PAN_START);
    const dx = this.hasHorizontalIntent(evt)
      ? clamp(
          Interactions.TOUCHPAD_PAN_FACTOR * evt.deltaX,
          -Interactions.TOUCHPAD_PAN_DELTA_MAX,
          Interactions.TOUCHPAD_PAN_DELTA_MAX
        )
      : 0;
    const dy = clamp(
      Interactions.TOUCHPAD_PAN_FACTOR * evt.deltaY,
      -Interactions.TOUCHPAD_PAN_DELTA_MAX,
      Interactions.TOUCHPAD_PAN_DELTA_MAX
    );

    const x = this.stage.x() - dx;
    const y = this.stage.y() - dy;

    this.stage.x(x);
    this.stage.y(y);
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_PAN_END);
    this.unifiedViewer.onViewportChange();
  };
}
