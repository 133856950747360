import { Position } from '../types';

export type Path = Position[];

export const simplifyRightAnglesPath = (path: Path): Path => {
  if (path.length < 3) {
    return path;
  }

  const simplifiedPath = [path[0]];
  for (let i = 1; i < path.length - 1; i++) {
    const previousPoint = simplifiedPath[simplifiedPath.length - 1];
    const currentPoint = path[i];
    const nextPoint = path[i + 1];

    if (arePointsOnLine(previousPoint, currentPoint, nextPoint)) {
      continue;
    }

    simplifiedPath.push(currentPoint);
  }

  simplifiedPath.push(path[path.length - 1]);

  return simplifiedPath;
};

export enum TurnDirection {
  LEFT,
  RIGHT,
  STRAIGHT,
}

export const getTurnDirection = (
  point1: Position,
  point2: Position,
  point3: Position
): TurnDirection => {
  const [x1, y1] = [point1.x, point1.y];
  const [x2, y2] = [point2.x, point2.y];
  const [x3, y3] = [point3.x, point3.y];

  const crossProduct = (x2 - x1) * (y3 - y2) - (y2 - y1) * (x3 - x2);

  if (crossProduct > 0) {
    return TurnDirection.LEFT;
  }
  if (crossProduct < 0) {
    return TurnDirection.RIGHT;
  }
  return TurnDirection.STRAIGHT;
};

const arePointsOnLine = (
  point1: Position,
  point2: Position,
  point3: Position
): boolean => {
  return getTurnDirection(point1, point2, point3) === TurnDirection.STRAIGHT;
};

export const arePointsEqual = (point1: Position, point2: Position): boolean => {
  return point1.x === point2.x && point1.y === point2.y;
};
