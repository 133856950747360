import { PlotRange, PresetPlotRange } from '../types';

import { convertToPlotRange } from './convertToPlotRange';
import { getUnifiedAxisRange } from './getUnifiedAxisRange';

export const getUnifiedPlotRange = (
  range1: PlotRange | PresetPlotRange,
  range2: PlotRange | PresetPlotRange
): PlotRange | undefined => {
  const plotRange1 = convertToPlotRange(range1);
  const plotRange2 = convertToPlotRange(range2);

  if (!plotRange1 || !plotRange2) {
    return undefined;
  }

  return {
    x: getUnifiedAxisRange(plotRange1.x, plotRange2.x),
    y: getUnifiedAxisRange(plotRange1.y, plotRange2.y),
  };
};
