import type {
  APMAsset,
  AssetCDFViewMigrator,
  CogniteAsset,
} from '@cognite/apm-client';
import type { Filters, Sort } from '@cognite/fdm-client';
import type { ViewDefinition } from '@cognite/sdk';
import cloneDeep from 'lodash/cloneDeep';

import { getAssetMetadata } from './utils/getAssetMetadata';

export class AssetIDMMigrator implements AssetCDFViewMigrator {
  space: string;
  baseViewDefinition: ViewDefinition;
  customerViewDefinition: ViewDefinition;
  constructor(
    space: string,
    baseViewDefinition: ViewDefinition,
    customerViewDefinition: ViewDefinition
  ) {
    this.space = space;
    this.baseViewDefinition = baseViewDefinition;
    this.customerViewDefinition = customerViewDefinition;
  }
  mapDataOutward = (data: CogniteAsset): APMAsset => {
    const assetProperties = getAssetMetadata(
      data,
      this.baseViewDefinition.properties,
      this.customerViewDefinition.properties
    );
    return {
      externalId: data.externalId,
      createdTime: data.createdTime,
      lastUpdatedTime: data.lastUpdatedTime,
      space: data.space,
      sourceId: data.sourceId,
      description: data.description,

      // Changed properties
      source: data.source?.name,
      title: data.name,
      labels: data.tags,
      parent: data.parent ? this.mapDataOutward(data.parent) : undefined,
      root: data.root ? this.mapDataOutward(data.root) : undefined,
      path: data.path?.map((asset: any) => asset.externalId),

      metadata: assetProperties,
    };
  };
  mapFiltersInward = (filters: Filters): Filters => {
    const newFilters = cloneDeep(filters);
    Object.keys(filters).forEach((key) => {
      if (key === 'and') {
        newFilters.and = filters.and!.map((f) => this.mapFiltersInward(f));
      }
      if (key === 'or') {
        newFilters.or = filters.or!.map((f) => this.mapFiltersInward(f));
      }
      if (key === 'not') {
        newFilters.not = this.mapFiltersInward(newFilters.not!);
      }

      if (key === 'equals') {
        if (filters.equals?.property === 'title') {
          newFilters.equals!.property = 'name';
        }
      }
      if (key === 'isNull') {
        if (filters.isNull?.property === 'title') {
          newFilters.isNull!.property = 'name';
        }
      }
      if (key === 'in') {
        if (filters.in?.property === 'title') {
          newFilters.in!.property = 'name';
        }
      }
      if (key === 'containsAny') {
        if (
          filters.containsAny?.property === 'path' &&
          filters.containsAny.containsAny
        ) {
          newFilters.containsAny!.containsAny =
            filters.containsAny.containsAny.map((externalId) => ({
              space: this.space,
              externalId: String(externalId),
            }));
        }
        if (
          filters.containsAny?.property === 'labels' &&
          filters.containsAny.containsAny
        ) {
          newFilters.containsAny!.property = 'tags';
        }
      }
    });
    return newFilters;
  };
  mapPropertiesInward = (properties: string[]): string[] => {
    const propertyMap: Record<string, string> = {
      title: 'name',
      labels: 'tags',
    };
    return properties.map((property) => propertyMap[property] || property);
  };
  mapSortInward = (sort: Sort): Sort => {
    return sort.map((x) => {
      if (Object.keys(x)[0] === 'title') {
        return { name: x.title };
      }
      return x;
    });
  };
  mapDataInward(data: APMAsset): CogniteAsset {
    // We dont upsert activities, so this is not needed
    return data as CogniteAsset;
  }
}
