import { createContext, useContext, useMemo, useState } from 'react';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';

import type { BreakPointsType } from './breakpoints';
import { breakPoints } from './breakpoints';
import { themeCogsDark, themeCogsLight } from './theme-cogs';
import type { ThemeCogs } from './theme-cogs';

type Theme = ThemeCogs & { breakPoints: BreakPointsType };

type Themes = Record<'cogsLight' | 'cogsDark', Theme>;

const themes: Themes = {
  cogsLight: {
    ...themeCogsLight,
    breakPoints,
  },
  cogsDark: {
    ...themeCogsDark,
    breakPoints,
  },
};

const ThemeContextExtended = createContext<
  | {
      theme: keyof Themes;
      setTheme: (theme: keyof Themes) => void;
      isDarkTheme: boolean;
      isLightTheme: boolean;
    }
  | undefined
>(undefined);

interface Props {
  themeDefault?: keyof Themes;
}

// Do not use this to consume context. Use the `useThemeExtended` hook instead
export const ThemeProviderExtended: FunctionComponent<
  PropsWithChildren<Props>
> = ({ children, themeDefault = 'cogsLight' }) => {
  const [theme, setTheme] = useState<keyof Themes>(themeDefault);
  const isLightTheme = theme === 'cogsLight';
  const isDarkTheme = !isLightTheme;

  const themeMemoized = useMemo(
    () => ({
      theme,
      setTheme,
      isLightTheme,
      isDarkTheme,
    }),
    [theme, setTheme, isLightTheme, isDarkTheme]
  );

  return (
    <ThemeContextExtended.Provider value={themeMemoized}>
      <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>
    </ThemeContextExtended.Provider>
  );
};

// Use this hook to consume the context
export function useThemeExtended() {
  const context = useContext(ThemeContextExtended);
  if (!context) {
    throw new Error(
      'useThemeExtended must be used within an instance of ThemeProviderExtended'
    );
  }
  return context;
}
