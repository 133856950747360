import { WorkflowState } from '../../../models';
import { ChartThreshold, ChartWorkflow } from '../../../types';
import { convertThresholdUnits, convertUnits, units } from '../../../utils';

import { SeriesData, SeriesDataCollection } from './types';

const getWorkflowUnitLabel = (workflow: ChartWorkflow) =>
  units.find(
    (unitOption) => unitOption.value === workflow.preferredUnit?.toLowerCase()
  )?.label ||
  workflow.customUnitLabel ||
  '';

const createSeriesDataFromWorkflow = (
  workflow: ChartWorkflow,
  datapoints: WorkflowState | undefined
): SeriesData => ({
  id: workflow.id,
  name: workflow.name,
  type: 'timeseries',
  style: {
    color: workflow.color,
    size: workflow.lineWeight || 1,
    lineStyle: workflow.lineStyle || 'solid',
  },
  isDataOutdated: datapoints?.loading || false,
  datapoints: convertUnits(
    datapoints?.datapoints || [],
    workflow.unit,
    workflow.preferredUnit
  ),
  interpolation: workflow.interpolation,
});

export function calculateSeriesDataFromWorkflow(
  workFlowCollection: ChartWorkflow[],
  datapointsCollection: WorkflowState[],
  thresholds: ChartThreshold[]
): SeriesDataCollection[] {
  const seriesDataCollections: SeriesDataCollection[] = [];

  for (const workflow of workFlowCollection) {
    const datapoints = datapointsCollection.find((dp) => dp.id === workflow.id);

    const unitLabel = getWorkflowUnitLabel(workflow);
    const seriesData = createSeriesDataFromWorkflow(workflow, datapoints);

    let seriesThresholds = thresholds.filter(
      (th) => th.sourceId === workflow.id
    );
    if (seriesThresholds.length > 0) {
      seriesThresholds = convertThresholdUnits(
        seriesThresholds,
        workflow.unit,
        workflow.preferredUnit
      );
    }

    seriesDataCollections.push({
      unit: unitLabel,
      range: workflow.range,
      thresholds: seriesThresholds,
      series: [seriesData],
      isVisible: workflow.enabled,
    });
  }

  return seriesDataCollections;
}
