import {
  EmptyState,
  GraphIllustration,
  Skeleton,
  TimeseriesIcon,
} from '@cognite/cogs.js-v10';
import { AssetCard } from '@infield/features/asset/shared/asset-card/asset-card';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useTrendsList } from '@infield/features/trends';
import { EmptyStateWrapper } from '@infield/features/ui/table-empty-state/elements';
import type { FC, ReactNode } from 'react';

import { AssetTrendsCardChart } from './asset-trends-card-chart';
import * as S from './elements';

interface Props {
  assetExternalId: string;
  onOpen?: () => void;
}

export const AssetTrendCard: FC<Props> = ({ assetExternalId, onOpen }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);
  const { data: timeseries, isLoading: isLoadingTimeseries } =
    useTrendsList(assetExternalId);
  const {
    data: highlightedTimeseries,
    isLoading: isLoadingHighlightedTimeseries,
  } = useTrendsList(assetExternalId, true);

  const hasHighlightedTimeseries =
    !isLoadingHighlightedTimeseries &&
    highlightedTimeseries &&
    highlightedTimeseries.length > 0;

  const hasTimeseries =
    !isLoadingTimeseries && timeseries && timeseries.length > 0;

  let content: ReactNode;
  if (isLoadingHighlightedTimeseries) {
    content = <Skeleton.List lines={4} />;
  } else if (hasHighlightedTimeseries) {
    content = <AssetTrendsCardChart timeseries={highlightedTimeseries[0]} />;
  } else if (hasTimeseries) {
    content = (
      <EmptyStateWrapper>
        <EmptyState
          illustration={<GraphIllustration />}
          title={t(
            'ASSET_OVERVIEW_TREND_CARD_NO_HIGHLIGHTED_TIME_SERIES_HEAD',
            'No highlighted time series to display'
          )}
          description={t(
            'ASSET_OVERVIEW_TREND_CARD_NO_HIGHLIGHTED_TIME_SERIES_BODY',
            "A time series can still be accessed by pressing the 'Open' button."
          )}
        />
      </EmptyStateWrapper>
    );
  } else {
    content = (
      <EmptyStateWrapper>
        <EmptyState
          illustration={<GraphIllustration />}
          title={t(
            'ASSET_OVERVIEW_TREND_CARD_NO_TIME_SERIES_HEAD',
            'No time series to display'
          )}
          description={t(
            'ASSET_OVERVIEW_TREND_CARD_NO_TIME_SERIES_BODY',
            'There are no time series on this asset.'
          )}
        />
      </EmptyStateWrapper>
    );
  }

  return (
    <AssetCard
      icon={<TimeseriesIcon />}
      title={t('trends', 'Trends')}
      buttonText={t('open', 'Open')}
      onButtonClick={
        hasHighlightedTimeseries || hasTimeseries ? onOpen : undefined
      }
    >
      <S.ContentWrapper>{content}</S.ContentWrapper>
    </AssetCard>
  );
};
