import type { Group } from '@infield/features/access/types';

export const extractDatasetIdsRead = (groups: Group[]): string[] => {
  const ids: string[] = [];

  groups.forEach((group) => {
    if (!group.isDeleted && group.capabilities) {
      group.capabilities.forEach((capability) => {
        if (
          capability.timeSeriesAcl?.scope &&
          capability.timeSeriesAcl.actions
        ) {
          const { datasetScope, all } = capability.timeSeriesAcl.scope;
          const { actions } = capability.timeSeriesAcl;
          if (
            datasetScope?.ids &&
            datasetScope.ids.length > 0 &&
            actions.includes('READ')
          ) {
            ids.push(...datasetScope.ids);
          }
          if (all && actions.includes('READ')) {
            ids.push('all');
          }
        }
      });
    }
  });

  return [...new Set(ids)];
};

export const extractDatasetIdsWrite = (groups: Group[]): string[] => {
  const ids: string[] = [];

  groups.forEach((group) => {
    if (!group.isDeleted && group.capabilities) {
      group.capabilities.forEach((capability) => {
        if (
          capability.timeSeriesAcl?.scope &&
          capability.timeSeriesAcl.actions
        ) {
          const { datasetScope, all } = capability.timeSeriesAcl.scope;
          const { actions } = capability.timeSeriesAcl;
          if (
            datasetScope?.ids &&
            datasetScope.ids.length > 0 &&
            actions.includes('WRITE')
          ) {
            ids.push(...datasetScope.ids);
          }
          if (all && actions.includes('WRITE')) {
            ids.push('all');
          }
        }
      });
    }
  });

  return [...new Set(ids)];
};
