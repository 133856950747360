import {
  Body,
  Checkbox,
  ConditionIcon,
  DragHandleVerticalIcon,
  EventsIcon,
  Flex,
  GaugeIcon,
  GridIcon,
  HelpIcon,
} from '@cognite/cogs.js-v10';
import { TagChip } from '@cognite/cogs.js-v10';
import { Draggable } from '@hello-pangea/dnd';
import { showAssetDetailsAtom } from '@infield/features/template';
import type { FC } from 'react';
import { useSetRecoilState } from 'recoil';

import * as S from './elements';

type Props = {
  externalId: string;
  index: number;
  assetExternalId?: string;
  assetLabel?: string;
  title?: string;
  isSelected: boolean;
  isChecked: boolean;
  isEditable: boolean;
  hasNumericalMeasurement?: boolean;
  hasLabelMeasurement?: boolean;
  hasIntervals?: boolean;
  hasMessage?: boolean;
  hasCondition?: boolean;
  fadeColor?: boolean;
  onClick?: () => void;
  onClickCheck: () => void;
};

export const TaskListItem: FC<Props> = ({
  externalId,
  index,
  assetExternalId,
  assetLabel,
  title,
  isSelected,
  isChecked,
  isEditable,
  hasNumericalMeasurement,
  hasLabelMeasurement,
  hasIntervals,
  hasMessage,
  hasCondition,
  fadeColor,
  onClick,
  onClickCheck,
}) => {
  const setShowAssetDetails = useSetRecoilState(showAssetDetailsAtom);
  const handleAssetLinkClicked = (assetExternalId?: string) => {
    if (assetExternalId !== undefined) {
      setShowAssetDetails(true);
    }
  };

  // onClick is not exposed by Checkbox so we are using this hack until it does.
  const checkboxUnwantedProps = {
    onClick: (ev: MouseEvent) => ev.stopPropagation(),
  } as any;

  return (
    <Draggable
      key={externalId}
      isDragDisabled={!isEditable}
      draggableId={externalId}
      index={index}
    >
      {(provided, { isDragging }) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <S.Wrapper
            data-testid="template-task-list-item"
            $isSelected={isSelected}
            onClick={onClick}
            $fadeColor={fadeColor}
            $isDragging={isDragging}
            $isEditable={isEditable}
          >
            <S.Content>
              <Flex gap={8} alignItems="center">
                {isEditable && (
                  <>
                    <Checkbox
                      {...checkboxUnwantedProps}
                      checked={isChecked}
                      onChange={onClickCheck}
                    />
                    <TagChip
                      label=""
                      icon={<DragHandleVerticalIcon />}
                      size="x-small"
                    />
                  </>
                )}
                {assetLabel && (
                  <S.Link
                    onClick={() => handleAssetLinkClicked(assetExternalId)}
                  >
                    {assetLabel}
                  </S.Link>
                )}
                <Body strong size="medium">
                  {title}
                </Body>
              </Flex>
              <Flex gap={8}>
                {hasNumericalMeasurement && (
                  <TagChip label="" icon={<GaugeIcon />} size="x-small" />
                )}
                {hasLabelMeasurement && (
                  <TagChip label="" icon={<GridIcon />} size="x-small" />
                )}
                {hasIntervals && (
                  <TagChip label="" icon={<EventsIcon />} size="x-small" />
                )}
                {hasMessage && (
                  <TagChip label="" icon={<HelpIcon />} size="x-small" />
                )}
                {hasCondition && (
                  <TagChip label="" icon={<ConditionIcon />} size="x-small" />
                )}
              </Flex>
            </S.Content>
          </S.Wrapper>
        </div>
      )}
    </Draggable>
  );
};
