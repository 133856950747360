import { useQuery } from '@tanstack/react-query';
import chunk from 'lodash/chunk';

import { useSDK } from '@cognite/sdk-provider';

import { GetDocumentStatus } from '../network';
import { queryKeys } from '../queryKeys';

const DOCUMENT_STATUS_ITEM_LIMIT = 100;

export const useDocumentStatusQuery = (
  documentIds: number[],
  enabled = true
) => {
  const sdk = useSDK();

  return useQuery(
    queryKeys.documentStatus(documentIds),
    async () => {
      const batchedDocuments = chunk(documentIds, DOCUMENT_STATUS_ITEM_LIMIT);

      const statuses = await Promise.all(
        batchedDocuments.map((batch) => GetDocumentStatus(batch, sdk))
      );

      return statuses.flat();
    },
    {
      enabled,
    }
  );
};
