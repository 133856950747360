import { isEmpty } from 'lodash';

import { Data } from '@cognite/plotting-components';
import { CogniteClient } from '@cognite/sdk';

import { DataFetchOptions, getDataFetchMode } from './getDataFetchMode';
import getTimeseriesDatapoints from './getTimeseriesDatapoints';
import getTimeseriesSingleAggregate from './getTimeseriesSingleAggregate';
import { mapToChartData } from './mapToChartData';
import {
  mapToTimeseriesDatapointsQuery,
  TimeseriesChartQuery,
} from './mapToTimeseriesDatapointsQuery';
import {
  TimeseriesSingleAggregate,
  TimeseriesSingleAggregateQuery,
} from './types';

const EMPTY_DATA: Data = {
  x: [],
  y: [],
};

export const CHART_POINTS_PER_SERIES = 1000;
export const DEFAULT_RAW_DATAPOINTS_LIMIT = 500;

const getTimeseriesSingleAggregateQuery = (
  sdk: CogniteClient,
  { query }: { query: TimeseriesSingleAggregateQuery }
): Promise<TimeseriesSingleAggregate> => {
  return getTimeseriesSingleAggregate(sdk, query);
};

const getTimeseriesChartMetadata = async (
  sdk: CogniteClient,
  {
    query,
    dataFetchOptions,
  }: {
    query: TimeseriesChartQuery;
    dataFetchOptions?: DataFetchOptions;
  }
) => {
  const { timeseriesId, dateRange } = query;

  const data = await getTimeseriesSingleAggregateQuery(sdk, {
    query: {
      id: timeseriesId,
      aggregates: ['count'],
      start: dateRange?.[0].valueOf(),
      end: dateRange?.[1].valueOf(),
    },
  });

  const numberOfPoints = Math.min(
    query.numberOfPoints || data?.data.count || CHART_POINTS_PER_SERIES,
    CHART_POINTS_PER_SERIES
  );

  const isString = data?.isString;

  const dataFetchMode = getDataFetchMode({
    numberOfPoints,
    dataFetchOptions,
    isString,
  });

  return {
    id: timeseriesId,
    numberOfPoints,
    dataFetchMode,
    isStep: data?.isStep,
    isString,
    unit: data?.unit,
  };
};

export type GetTimeseriesChartData = {
  data: Data;
  metadata: Awaited<ReturnType<typeof getTimeseriesChartMetadata>>;
};

const getTimeseriesChartData = async (
  sdk: CogniteClient,
  {
    query,
    dataFetchOptions,
  }: {
    query: TimeseriesChartQuery;
    dataFetchOptions?: DataFetchOptions;
  }
): Promise<GetTimeseriesChartData> => {
  const metadata = await getTimeseriesChartMetadata(sdk, {
    query,
    dataFetchOptions,
  });

  const datapoints = await getTimeseriesDatapoints(
    sdk,
    mapToTimeseriesDatapointsQuery({ query, metadata })
  );

  if (!datapoints || isEmpty(datapoints)) {
    return {
      data: EMPTY_DATA,
      metadata,
    };
  }

  return {
    data: mapToChartData({ datapoints, metadata }),
    metadata,
  };
};

export default getTimeseriesChartData;
