import isNativelySupportedMimeType from './mimeTypes/isNativelySupportedMimeType';

export const readBlobAsDataUrl = async (
  blob: Blob
): Promise<string | undefined> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target?.result?.toString());
    reader.readAsDataURL(blob);
  });
};

export type FileData = {
  dataUrl: string;
  mimeType: string;
};

const getFileDataFromDropEvent = async (
  event: DragEvent
): Promise<FileData[]> => {
  const fileData: FileData[] = [];
  const files = event.dataTransfer?.files ?? [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (!isNativelySupportedMimeType(file.type)) {
      // eslint-disable-next-line no-console
      console.warn(
        `Cannot create container for '${file.name}' since it has type '${file.type}' which is not supported.`
      );
      continue;
    }
    const dataUrl = await readBlobAsDataUrl(file);
    if (dataUrl === undefined) {
      // eslint-disable-next-line no-console
      console.warn(`Was not able to generate data url for ${file.name}`);
      continue;
    }
    fileData.push({ dataUrl, mimeType: file.type });
  }
  return fileData;
};

export default getFileDataFromDropEvent;
