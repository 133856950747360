import { AxisRange } from '../../../types';

import { getArrayMax, getArrayMin } from './array';
import { SeriesDataCollection } from './types';

export function calculateMaxRange(series: SeriesDataCollection[]): AxisRange {
  const lowerRanges = series
    .map((s) => s.range?.[0])
    .filter((val) => typeof val === 'number') as number[];
  const upperRanges = series
    .map((s) => s.range?.[1])
    .filter((val) => typeof val === 'number') as number[];

  const lowerValue = getArrayMin(lowerRanges);
  const upperValue = getArrayMax(upperRanges);

  return [lowerValue, upperValue];
}
