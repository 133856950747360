import type { UserPreferenceLocation } from '@cognite/apm-client';

/**
 * Validates if the user location preference is selectable.
 * @param selectableLocations List of selectable locations.
 * @param userLocationPreference User location preference.
 * @param isNewConfig Flag to determine if the configuration is new or old version.
 * @returns True if the user location preference is selectable.
 */

export const validateUserLocation = (
  selectableLocations: UserPreferenceLocation[] | undefined,
  userLocationPreference: UserPreferenceLocation | undefined
): boolean => {
  if (!selectableLocations || !userLocationPreference) {
    return false;
  }

  return selectableLocations.some(
    (location) =>
      location.externalId === userLocationPreference.externalId ||
      location.externalId === userLocationPreference?.assetExternalId
  );
};
