import type { Filters } from '@cognite/fdm-client/src/types';
import type { ExternalId, IdEither } from '@cognite/sdk';
import { useSelectedRootLocationConfiguration } from '@infield/features/app-config';
import {
  getIsNewConfigVersion,
  mergeDefaultAndAdditionalFilters,
} from '@infield/features/app-config/utils/utils';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { truthy } from '@infield/utils/filtering-helpers';
import type { FC, PropsWithChildren } from 'react';
import { useContext, useMemo } from 'react';

import { AppConfigFiltersContext } from './app-config-filters-context';
import type { ConfigFiltersContextType } from './types';

export const AppConfigFiltersProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { appConfig } = useAppConfigContext();
  const configuredRootLocation = useSelectedRootLocationConfiguration();
  const rootAssetExternalId = configuredRootLocation?.assetExternalId;
  const dataFilters = configuredRootLocation?.dataFilters;

  const generalRootLocationFilterOld: Filters | undefined = useMemo(() => {
    if (rootAssetExternalId) {
      return {
        directRelationFilter: {
          rootLocation: {
            equals: {
              property: 'externalId',
              eq: rootAssetExternalId,
            },
          },
        },
        directRelationInstanceSpace:
          configuredRootLocation?.sourceDataInstanceSpace,
      };
    }
    return undefined;
  }, [configuredRootLocation?.sourceDataInstanceSpace, rootAssetExternalId]);

  const assetSubtreeExternalIdsOld: ExternalId[] | undefined = useMemo(() => {
    if (rootAssetExternalId) {
      return [{ externalId: rootAssetExternalId }];
    }
  }, [rootAssetExternalId]);

  const activityRootLocationFilterOld: Filters | undefined = useMemo(() => {
    if (rootAssetExternalId) {
      return {
        equals: {
          property: 'rootLocation',
          eq: rootAssetExternalId,
        },
      };
    }
    return undefined;
  }, [rootAssetExternalId]);

  const notArchivedFilter: Filters = useMemo(
    () => ({
      not: {
        equals: {
          property: 'isArchived',
          eq: true,
        },
      },
    }),
    []
  );

  const assetPathFilter: Filters | undefined = useMemo(() => {
    const rootAssetExternalIds = [
      configuredRootLocation?.assetExternalId,
      ...(dataFilters?.general?.rootAssetExternalIds || []),
    ].filter(truthy);

    if (rootAssetExternalIds?.length) {
      return {
        containsAny: {
          property: 'path',
          containsAny: rootAssetExternalIds,
        },
      };
    }
    return undefined;
  }, [
    configuredRootLocation?.assetExternalId,
    dataFilters?.general?.rootAssetExternalIds,
  ]);

  const previousVersionFiltersContext: ConfigFiltersContextType = useMemo(
    () => ({
      template: { rootAssetExternalIds: generalRootLocationFilterOld },
      checklist: { rootAssetExternalIds: generalRootLocationFilterOld },
      observation: { rootAssetExternalIds: generalRootLocationFilterOld },
      timeseries: {
        cdfClassicFilters: {
          assetSubtreeIds: assetSubtreeExternalIdsOld,
        },
      },
      asset: {
        cdfClassicFilters: {
          assetSubtreeIds: assetSubtreeExternalIdsOld,
        },
        dmFilters: assetPathFilter ? { and: [assetPathFilter] } : undefined,
      },
      files: {
        cdfClassicFilters: {
          assetSubtreeIds: assetSubtreeExternalIdsOld,
        },
      },
      activity: { rootAssetExternalIds: activityRootLocationFilterOld },
      notArchivedFilter,
    }),
    [
      generalRootLocationFilterOld,
      assetSubtreeExternalIdsOld,
      assetPathFilter,
      activityRootLocationFilterOld,
      notArchivedFilter,
    ]
  );

  const combinedRootAssetFilters = useMemo(
    () =>
      mergeDefaultAndAdditionalFilters(
        configuredRootLocation?.assetExternalId,
        dataFilters?.general?.rootAssetExternalIds
      ),
    [configuredRootLocation, dataFilters]
  );

  const generalRootLocationFilter: Filters | undefined = useMemo(() => {
    if (combinedRootAssetFilters.length > 0) {
      return {
        directRelationFilter: {
          rootLocation: {
            in: {
              property: 'externalId',
              in: combinedRootAssetFilters,
            },
          },
        },
        directRelationInstanceSpace:
          configuredRootLocation?.sourceDataInstanceSpace,
      };
    }
    return undefined;
  }, [combinedRootAssetFilters, configuredRootLocation]);

  const assetSubtreeExternalIds: ExternalId[] | undefined = useMemo(() => {
    if (dataFilters?.general?.assetSubtreeExternalIds) {
      return dataFilters?.general?.assetSubtreeExternalIds.map(
        (externalId) => ({ externalId })
      );
    }
  }, [dataFilters]);

  const dataSetIdsTimeseries: IdEither[] | undefined = useMemo(() => {
    const dataSetIds = (dataFilters?.timeseries?.datasetIds || []).map(
      (datasetId) => ({
        id: datasetId,
      })
    );

    if (dataSetIds.length > 0) {
      return dataSetIds;
    }
  }, [dataFilters]);

  const dataSetIdsFiles: IdEither[] | undefined = useMemo(() => {
    const dataSetIds = (dataFilters?.files?.datasetIds || []).map(
      (datasetId) => ({
        id: datasetId,
      })
    );

    if (dataSetIds.length > 0) {
      return dataSetIds;
    }
  }, [dataFilters]);

  const assetPrefixFilter: string | undefined = useMemo(() => {
    if (dataFilters?.assets?.externalIdPrefix) {
      return dataFilters.assets.externalIdPrefix;
    }
    return undefined;
  }, [dataFilters]);

  const timeseriesPrefix: string | undefined = useMemo(() => {
    if (dataFilters?.timeseries?.externalIdPrefix) {
      return dataFilters.timeseries.externalIdPrefix;
    }
    return undefined;
  }, [dataFilters]);

  const activityRootLocationFilter: Filters | undefined = useMemo(() => {
    if (combinedRootAssetFilters.length > 0) {
      return {
        in: {
          property: 'rootLocation',
          in: combinedRootAssetFilters,
        },
      };
    }
    return undefined;
  }, [combinedRootAssetFilters]);

  const context: ConfigFiltersContextType = useMemo(
    () => ({
      template: { rootAssetExternalIds: generalRootLocationFilter },
      checklist: { rootAssetExternalIds: generalRootLocationFilter },
      observation: { rootAssetExternalIds: generalRootLocationFilter },
      timeseries: {
        cdfClassicFilters: {
          dataSetIds: dataSetIdsTimeseries,
          assetSubtreeIds: assetSubtreeExternalIds,
          externalIdPrefix: timeseriesPrefix,
        },
      },
      asset: {
        cdfClassicFilters: {
          assetSubtreeIds: assetSubtreeExternalIds,
          externalIdPrefix: assetPrefixFilter,
        },
        dmFilters: assetPathFilter ? { and: [assetPathFilter] } : undefined,
      },
      files: {
        cdfClassicFilters: {
          dataSetIds: dataSetIdsFiles,
          assetSubtreeIds: assetSubtreeExternalIds,
        },
      },
      activity: { rootAssetExternalIds: activityRootLocationFilter },
      notArchivedFilter,
    }),
    [
      generalRootLocationFilter,
      dataSetIdsTimeseries,
      assetSubtreeExternalIds,
      timeseriesPrefix,
      assetPrefixFilter,
      assetPathFilter,
      dataSetIdsFiles,
      activityRootLocationFilter,
      notArchivedFilter,
    ]
  );

  return (
    <AppConfigFiltersContext.Provider
      value={
        getIsNewConfigVersion(appConfig)
          ? context
          : previousVersionFiltersContext
      }
    >
      {children}
    </AppConfigFiltersContext.Provider>
  );
};

export const useAppConfigFiltersContext = () => {
  const context = useContext(AppConfigFiltersContext);
  if (!context) {
    throw new Error(
      'AppConfigFiltersContext must be used within a AppConfigFiltersProvider'
    );
  }
  return context;
};
