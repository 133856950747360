import { LineStyle } from '../../../types';

export function convertLineStyle(
  lineStyle?: LineStyle
): 'solid' | 'dash' | 'dot' | undefined {
  switch (lineStyle) {
    case 'solid':
      return 'solid';
    case 'dashed':
      return 'dash';
    case 'dotted':
      return 'dot';
    default:
      return undefined;
  }
}
