import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';

import type { TaskStatusOptions } from '../types';

export const useTaskStatusTranslation = (): Record<
  TaskStatusOptions,
  string
> => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.conditions);

  return {
    Ok: t('ok', 'OK'),
    'Not ok': t('notOk', 'Not OK'),
    'Not applicable': t('notApplicableAbbreviation', 'N/A'),
    Blocked: t('blocked', 'Blocked'),
  };
};
