import type { TemplateStatus } from '@cognite/apm-client';
import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { handleTemplateStatusFilter } from '@infield/features/template/utils';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

export const useTemplateListQuery = (templateStatus?: TemplateStatus) => {
  const { templateService } = useFDMServices();
  const { template: configFilters } = useAppConfigFiltersContext();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const { filters, showArchived } = handleTemplateStatusFilter(templateStatus);

  const templateFilter: Filters = {
    and: [...filters],
  };

  if (configFilters.rootAssetExternalIds) {
    templateFilter.and?.push(configFilters.rootAssetExternalIds);
  }

  const sort: Sort = [
    {
      title: 'ASC',
    },
  ];

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  return useQuery(
    [QueryKeys.TEMPLATE_LIST, templateFilter, sort],
    async () => {
      sliTimerStartTime.current = Date.now();
      return templateService.getTemplates(
        templateFilter,
        sort,
        undefined,
        showArchived
      );
    },
    {
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.TEMPLATE_LIST,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.TEMPLATE_LIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.TEMPLATE_LIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(configFilters.rootAssetExternalIds),
    }
  );
};
