import { useAuthContext } from '@cognite/e2e-auth';
import type { AnnotationModel } from '@cognite/sdk';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

export const useAnnotations = (fileExternalId: string, enabled: boolean) => {
  const { client } = useAuthContext();

  return useQuery<AnnotationModel[]>(
    [QueryKeys.ANNOTATIONS, fileExternalId],
    async () =>
      (
        await client.annotations.list({
          filter: {
            annotatedResourceType: 'file',
            annotatedResourceIds: [{ externalId: fileExternalId! }],
          },
          limit: 1000,
        })
      ).items,
    {
      enabled: Boolean(fileExternalId) && enabled,
    }
  );
};
