import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { CoreTimeseriesEntryCollection } from './types';

export const coreTimeseriesAtom = atom<CoreTimeseriesEntryCollection>({
  key: 'coreTimeseriesAtom',
  default: [],
});

export const useCoreTimeSeriesAtom = () => useRecoilState(coreTimeseriesAtom);

export const useCoreTimeSeriesValue = () => useRecoilValue(coreTimeseriesAtom);

export const useSetCoreTimeSeries = () => useSetRecoilState(coreTimeseriesAtom);
