import styled from 'styled-components';

import { MAX_PAGE_WIDTH_MOBILE } from '@fusion/ui-components';

import { ActionList, ActionListItemProps, Select } from '@cognite/cogs-lab';
import { Colors, Flex } from '@cognite/cogs.js-v10';

import { VerticalTab } from '../../common/types';
import { OnTrackEvent, tabChangeEvent } from '../../metrics';

export type VerticalTabsProps = {
  activeKey: string;
  onChange: (key: string) => void;
  builtinTabs: VerticalTab[];
  additionalTabs?: VerticalTab[];
  additionalTabsCategoryLabel?: string;
  onTrackEvent?: OnTrackEvent;
};

export const VerticalTabs = ({
  activeKey,
  onChange,
  builtinTabs,
  additionalTabs = [],
  additionalTabsCategoryLabel = '',
  onTrackEvent,
}: VerticalTabsProps): JSX.Element => {
  const handleChange = (key: string): void => {
    onChange(key);
  };
  const builtinTabsOptions = builtinTabs.map(({ key, title }) => ({
    label: `${title}${key === 'language' ? ' (Beta)' : ''}`,
    value: key,
  }));
  const additionalTabsOptions = additionalTabs.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  return (
    <Container direction="column">
      <ActionList className="vertical-tabs-action-list">
        <ActionList.Section>
          {builtinTabs.map(({ key, icon, title }) => (
            <ActionList.Item
              key={key}
              icon={<>{icon}</>}
              onClick={() => {
                onTrackEvent?.(tabChangeEvent, { tabKey: key });
                handleChange(key);
              }}
              toggled={activeKey === key}
            >
              {title}
            </ActionList.Item>
          ))}
        </ActionList.Section>
        {additionalTabs && additionalTabs.length ? (
          <ActionList.Section label={additionalTabsCategoryLabel}>
            {additionalTabs.map(({ key, icon, title }) => (
              <ActionList.Item
                key={key}
                icon={(icon as ActionListItemProps['icon']) || undefined}
                onClick={() => {
                  onTrackEvent?.(tabChangeEvent, { tabKey: key });
                  handleChange(key);
                }}
                toggled={activeKey === key}
              >
                {title}
              </ActionList.Item>
            ))}
          </ActionList.Section>
        ) : null}
      </ActionList>
      <Select<string, false>
        className="vertical-tabs-select"
        fullWidth
        onChange={(_, value) => {
          onTrackEvent?.(tabChangeEvent, { tabKey: value });
          if (value) {
            handleChange(value);
          }
        }}
        options={[...builtinTabsOptions, ...additionalTabsOptions]}
        popperProps={{
          disablePortal: true,
        }}
        value={
          [...builtinTabsOptions, ...additionalTabsOptions].find(
            ({ value }) => value === activeKey
          )?.value
        }
      />
    </Container>
  );
};

const Container = styled(Flex)`
  .vertical-tabs-select {
    display: none;
  }

  @container main (inline-size <= ${MAX_PAGE_WIDTH_MOBILE}px) {
    background-color: ${Colors['surface--strong']};
    padding: 16px;

    .vertical-tabs-action-list {
      display: none;
    }

    /* to increase the specificity */
    .vertical-tabs-select.vertical-tabs-select {
      display: unset;
    }
  }
`;
