import { Body, Button, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const DescriptionWrapper = styled(Flex).attrs({
  gap: 4,
  direction: 'column',
})<{
  $expand: boolean;
}>`
  border: 1px solid ${({ theme }) => theme['border--interactive--default']};
  border-radius: 6px;
  padding: 8px;
  height: ${(props) => (props.$expand ? 'fit-content' : '100px')};
`;

export const DescriptionDetail = styled(Body).attrs({ size: 'x-small' })<{
  $expand: boolean;
}>`
  width: 95%;
  display: -webkit-box;
  ${(props) =>
    props.$expand
      ? ''
      : `
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`}
`;

export const ExpandDescriptionButton = styled(Button).attrs({
  'aria-label': 'expand-decryption',
})`
  width: 100%;
`;
