import {
  ScheduledCalculationData,
  ScheduledCalculationsDataMap,
} from '../../../models';
import { ChartThreshold, ScheduledCalculation } from '../../../types';
import { convertThresholdUnits, convertUnits, units } from '../../../utils';

import { SeriesData, SeriesDataCollection } from './types';

const getScheduledCalculationUnitLabel = (
  scheduledCalculation: ScheduledCalculation
) =>
  units.find(
    (unitOption) =>
      unitOption.value === scheduledCalculation.preferredUnit?.toLowerCase()
  )?.label || scheduledCalculation.customUnitLabel;

const createSeriesDataFromScheduledCalculation = (
  timeSeries: ScheduledCalculation,
  datapoints: ScheduledCalculationData | undefined
): SeriesData => ({
  id: timeSeries.id,
  name: timeSeries.name,
  type: 'timeseries',
  style: {
    color: timeSeries.color,
    size: timeSeries.lineWeight || 1,
    lineStyle: timeSeries.lineStyle || 'solid',
  },
  isDataOutdated: datapoints?.loading ?? false,
  datapoints: convertUnits(
    datapoints?.series?.datapoints || [],
    timeSeries.unit,
    timeSeries.preferredUnit
  ),
  interpolation: timeSeries.interpolation,
});

export function calculateSeriesDataFromScheduledCalculation(
  scheduledCalculationCollection: ScheduledCalculation[],
  datapointsCollection: ScheduledCalculationsDataMap,
  thresholds: ChartThreshold[]
): SeriesDataCollection[] {
  const seriesDataCollections: SeriesDataCollection[] = [];

  for (const scheduledCalculation of scheduledCalculationCollection) {
    const unitLabel = getScheduledCalculationUnitLabel(scheduledCalculation);
    const seriesData = createSeriesDataFromScheduledCalculation(
      scheduledCalculation,
      datapointsCollection[scheduledCalculation.id]
    );

    let seriesThresholds = thresholds.filter(
      (th) => th.sourceId === scheduledCalculation.id
    );
    if (seriesThresholds.length > 0) {
      seriesThresholds = convertThresholdUnits(
        seriesThresholds,
        scheduledCalculation.unit,
        scheduledCalculation.preferredUnit
      );
    }

    seriesDataCollections.push({
      unit: unitLabel,
      range: scheduledCalculation.range,
      thresholds: seriesThresholds,
      series: [seriesData],
      isVisible: scheduledCalculation.enabled,
    });
  }

  return seriesDataCollections;
}
