import type { GridFilterModel } from '@cognite/apm-observation';
import type { DatagridColumn } from '@cognite/cogs-lab';
import { DataGrid } from '@cognite/cogs-lab';
import { Infobox } from '@cognite/cogs.js-v10';
import type { Sort } from '@cognite/fdm-client/src/types';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { TableEmptyState } from '@infield/features/ui/table-empty-state';
import type { FC } from 'react';
import { useResetRecoilState } from 'recoil';

import * as S from '../common-table-cell-components/elements';
import { overviewTableRecentlyCreatedChecklistAtom } from '../state';
import type { SelectedColumnOption } from '../types';

import type { ChecklistDataGridRow } from './types';

type Props = {
  isError: boolean;
  columns: DatagridColumn[];
  selectedColumns: SelectedColumnOption[];
  dataGridData: ChecklistDataGridRow[];
  isFetchingNextPage: boolean;
  isLoading: boolean;
  handleSortClick: (sort: Sort) => void;
  handleRowClick: (params: { row: ChecklistDataGridRow }) => void;
  selectedRows: (string | number)[];
  handleRowSelectionChange: (nextRows: (string | number)[]) => void;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  setFilterState: (filterState: GridFilterModel) => void;
  setSelectedFilter: (filterExternalId?: string) => void;
  filterState: GridFilterModel;
};

export const ChecklistsTable: FC<Props> = ({
  isError,
  columns,
  selectedColumns,
  dataGridData,
  isFetchingNextPage,
  isLoading,
  handleSortClick,
  handleRowClick,
  selectedRows,
  handleRowSelectionChange,
  hasNextPage,
  fetchNextPage,
  setFilterState,
  setSelectedFilter,
  filterState,
}: Props) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const resetRecentlyCreatedChecklist = useResetRecoilState(
    overviewTableRecentlyCreatedChecklistAtom
  );

  const columnVisibilityModel = selectedColumns
    .filter((col) => !col.selected)
    .reduce((acc, col) => {
      return {
        ...acc,
        [col.key]: false,
      };
    }, {});

  const noColumnsSelected =
    selectedColumns.length > 0 &&
    selectedColumns.every((col) => col.selected === false);

  if (noColumnsSelected) {
    return (
      <TableEmptyState
        title={t(
          'CHECKLISTS_TABLE_NO_COLUMNS_SELECTED_TITLE',
          'No columns selected'
        )}
        description={t(
          'CHECKLISTS_TABLE_NO_COLUMNS_SELECTED_BODY',
          'Select at least one column to view data.'
        )}
      />
    );
  }

  if (isError) {
    return (
      <Infobox
        title={t('CHECKLISTS_TABLE_ERROR_TITLE', 'Error receiving data')}
        status="critical"
      >
        {t(
          'CHECKLISTS_TABLE_ERROR',
          'We encountered an error receiving checklists data.'
        )}
      </Infobox>
    );
  }

  return (
    <S.DataGridTableWrapper>
      <DataGrid
        size="large"
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        data={dataGridData}
        pagination={false}
        loading={isFetchingNextPage || isLoading}
        onSortClick={handleSortClick}
        onRowClick={handleRowClick}
        checkboxSelection
        disableSelectAllCheckbox
        disableColumnReorder
        selectedRows={selectedRows}
        onRowSelectionChange={handleRowSelectionChange}
        onRowsScrollEnd={hasNextPage ? () => fetchNextPage() : undefined}
        onFilterModelChange={(filterModel) => {
          setFilterState(filterModel.items);
          setSelectedFilter(undefined);
          resetRecentlyCreatedChecklist();
        }}
        filterModel={{ items: filterState }}
        loadingRows={9}
        localeText={{
          multiSelectFilterLocaleText: {
            filterSearchPlaceholder: t(
              'CHECKLISTS_TABLE_FILTER_SEARCH_PLACEHOLDER',
              'Search'
            ),
            filterOptionsSelectAll: t(
              'CHECKLISTS_TABLE_FILTER_SELECT_ALL',
              'Select all'
            ),
            filterSearchResult: t(
              'CHECKLISTS_TABLE_FILTER_SEARCH_RESULTS',
              'Results'
            ),
          },
          noResultsFound: {
            title: t(
              'CHECKLISTS_TABLE_NO_RESULT_FOUNT_TITLE',
              'No results found'
            ),
            description: t(
              'CHECKLISTS_TABLE_NO_RESULT_FOUNT_DESCRIPTION',
              'Try adjusting your search or filters or clear the filters'
            ),
          },
        }}
      />
    </S.DataGridTableWrapper>
  );
};
