import type { CustomReadingLabel, Measurement } from '@cognite/apm-client';
import { Body, Flex } from '@cognite/cogs.js-v10';
import { useMeasurementsUpsert } from '@infield/features/measurements';
import { useState } from 'react';
import type { FC } from 'react';

import { LabelReading } from '../measurement-label-reading/label-reading';

interface Props {
  measurement: Measurement;
  isLocked: boolean;
  onChange: (measurement: Measurement) => void;
}

export const MeasurementLabelReadingList: FC<Props> = ({
  measurement,
  isLocked,
  onChange,
}) => {
  const [readingInput, setReadingInput] = useState<string | undefined>(
    measurement.stringReading
  );
  const { mutateAsync: upsertMeasurement } = useMeasurementsUpsert();

  const onReadingChange = (option: CustomReadingLabel) => {
    if (isLocked) return;

    const newValue = option.label === readingInput ? '' : option.label;
    setReadingInput(newValue || undefined);

    const updatedMeasurement: Measurement = {
      ...measurement,
      stringReading: newValue,
    };

    onChange(updatedMeasurement);

    upsertMeasurement({
      measurementToUpsert: [
        {
          externalId: measurement.externalId,
          stringReading: newValue,
        },
      ],
      onMeasurementChange: () => onChange(updatedMeasurement),
    });
  };

  return (
    <Flex direction="column" gap={4}>
      <Body size="x-small">{measurement.title}</Body>
      <Flex direction="row" gap={8} wrap="wrap">
        {measurement.options?.map((measurementOption) => (
          <LabelReading
            key={measurementOption.label}
            isDisabled={isLocked}
            isSelected={measurementOption.label === readingInput}
            onClick={() => onReadingChange(measurementOption)}
            type={measurementOption.color}
          >
            {measurementOption.label || ''}
          </LabelReading>
        ))}
      </Flex>
    </Flex>
  );
};
