import { CustomDataType, ValueType } from '@cognite/plotting-components';

import { TimeseriesDatapoint } from '../../utils/timeseries/types';

import getTooltipNumericValue from './getTooltipNumericValue';
import getTooltipRawDatapointValue from './getTooltipRawDatapointValue';

interface TooltipRendererProps {
  x: ValueType;
  y: ValueType;
  name: string;
  color: string;
  customData?: CustomDataType;
}
const formatTooltipContent = (
  { customData }: TooltipRendererProps,
  unit?: string
): { label: string; value: string | number | undefined }[] => {
  const datapoint = customData as TimeseriesDatapoint;

  if (!datapoint) {
    return [];
  }

  if ('value' in datapoint) {
    return [
      {
        label: 'Value',
        value: getTooltipRawDatapointValue(datapoint.value, unit),
      },
    ];
  }

  const { average, max, min, count } = datapoint;

  return [
    { label: 'Min', value: getTooltipNumericValue(min, unit) },
    { label: 'Max', value: getTooltipNumericValue(max, unit) },
    { label: 'Average', value: getTooltipNumericValue(average, unit) },
    { label: 'Count', value: count },
  ];
};

export default formatTooltipContent;
