import type { Measurement } from '@cognite/apm-client';
import { Body, Flex, Textarea } from '@cognite/cogs.js-v10';
import { useMeasurementsUpsert } from '@infield/features/measurements';
import { useState } from 'react';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  measurement: Measurement;
  isLocked: boolean;
  onChange: (measurement: Measurement) => void;
}

export const MeasurementMessageReadingList: FC<Props> = ({
  measurement,
  isLocked,
  onChange,
}) => {
  const [userInput, setUserInput] = useState<string>(
    measurement.stringReading || ''
  );

  const { mutateAsync: upsertMeasurement } = useMeasurementsUpsert();

  const onMessageChange = () => {
    if (isLocked) return;
    if (userInput === measurement.stringReading) return;

    const updatedMeasurement: Measurement = {
      ...measurement,
      stringReading: userInput,
    };

    upsertMeasurement({
      measurementToUpsert: [
        { externalId: measurement.externalId, stringReading: userInput },
      ],
      onMeasurementChange: () => onChange(updatedMeasurement),
    });
  };

  return (
    <Flex direction="column" gap={4}>
      <Body size="medium" strong>
        {measurement.title}
      </Body>
      <Flex direction="row" gap={8} wrap="wrap">
        <S.TextareaContainer onBlur={onMessageChange}>
          <Textarea
            value={userInput}
            disabled={isLocked}
            key={measurement.externalId}
            onChange={(e) => setUserInput(e.target.value)}
            fullWidth
            placeholder="Reply"
          />
        </S.TextareaContainer>
      </Flex>
    </Flex>
  );
};
