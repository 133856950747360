import { Flex, Infobox, Select } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const InfoBlock = styled(Flex).attrs({
  gap: 6,
  direction: 'column',
})`
  width: 100%;
`;

export const StyledSelect = styled(Select)`
  background-color: ${({ theme }) => theme['surface--muted']};
  .cogs-select__menu {
    max-height: 160px;
    overflow-y: auto;
  }
`;

export const InfoBlockWithMargin = styled(InfoBlock)<{
  $noMargin?: boolean;
}>`
  margin-top: ${({ $noMargin }) => ($noMargin ? 0 : '26px')};
`;

export const StyledInfoBox = styled(Infobox)`
  padding: 4px 8px;
  .cogs-lab-infobox-content > p {
    margin-bottom: 0;
  }
`;
