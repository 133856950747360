import styled from 'styled-components';

import { Body, Button, Colors, Elevations } from '@cognite/cogs.js-v10';

export const shamefulSurfaceStatusAiStrongDefault = '#6F3BE4';
export const shamefulColorTextIconAi = '#602ACF';
export const shamefulBorderInteractiveToggledDefaultAi = '#6F3BE4';

type AiButtonExtraProps = { disabled?: boolean; isLoading?: boolean };

export const AiButton: React.ComponentType<
  React.ComponentPropsWithRef<typeof Button> & AiButtonExtraProps
> = styled(Button)<AiButtonExtraProps>`
  background: ${shamefulSurfaceStatusAiStrongDefault} !important;

  opacity: ${({ isLoading, disabled }) =>
    disabled || isLoading ? 0.5 : 1} !important;
  color: ${Colors['text-icon--on-contrast--strong']} !important;
`;

export const GhostAiButton: React.ComponentType<
  React.ComponentPropsWithRef<typeof Button> & AiButtonExtraProps
> = styled(Button)<AiButtonExtraProps>`
  color: ${shamefulColorTextIconAi} !important;

  opacity: ${({ isLoading, disabled }) =>
    disabled || isLoading ? 0.5 : 1} !important;

  i {
    color: ${shamefulColorTextIconAi} !important;
  }
`;

// Shameful because not part of cogs yet, will be replaced later;
export const Container = styled.div`
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  background: ${Colors['surface--muted']};
  border: 1px solid ${Colors['surface--muted']};
  border-radius: 6px;
  box-shadow: ${Elevations['elevation--overlay']};
  text-align: left;
`;

export const InnerContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const AnswerContainer = styled.div`
  padding: 8px 0;
  white-space: pre-wrap;
  & .cogs-button {
    width: unset;
  }
`;

export const AnswerInnerContainer = styled(Body)`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 250px;
`;

export const ErrorContainer = styled(AnswerContainer)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 4px 0;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const BetaChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 4px 0 0 0;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  & > * {
    width: 100%;
  }
`;
