import { useSDK } from '@cognite/sdk-provider';

import {
  AssetAcdmInstance,
  FdmInstance,
  isAssetAcdmInstance,
  isFdmInstance,
} from '../../../types';
import { ReactContainerRenderContentProps } from '../../ReactContainer';
import { DEFAULT_ASSET_CONTAINER_PROPERTIES } from '../constants';
import { PropertyListContainerProps } from '../types';

import { AcdmAssetTable } from './AcdmAssetTable';
import { CdmAssetTable } from './CdmAssetTable';

type AssetPropertyListContentProps = Pick<
  ReactContainerRenderContentProps,
  | 'width'
  | 'height'
  | 'unscaledWidth'
  | 'unscaledHeight'
  | 'setLoadingStatus'
  | 'onContentSizeChange'
  | 'shouldAutoSize'
> &
  Pick<PropertyListContainerProps, 'properties'> & {
    instance: AssetAcdmInstance | FdmInstance;
    containerPadding: number;
  };

const AssetPropertyList: React.FC<AssetPropertyListContentProps> = ({
  width,
  height,
  unscaledWidth,
  unscaledHeight,
  containerPadding,
  setLoadingStatus,
  instance,
  onContentSizeChange,
  shouldAutoSize,
  properties,
}) => {
  const sdk = useSDK();

  return (
    <div>
      {isAssetAcdmInstance(instance) && (
        <AcdmAssetTable
          height={height}
          width={width}
          unscaledHeight={unscaledHeight}
          unscaledWidth={unscaledWidth}
          shouldAutoSize={shouldAutoSize}
          containerPadding={containerPadding}
          instance={instance}
          properties={properties || DEFAULT_ASSET_CONTAINER_PROPERTIES}
          sdk={sdk}
          onContentSizeChange={onContentSizeChange}
          setLoadingStatus={setLoadingStatus}
        />
      )}
      {isFdmInstance(instance) && (
        <CdmAssetTable
          sdk={sdk}
          instance={instance}
          width={width}
          height={height}
          unscaledWidth={unscaledWidth}
          unscaledHeight={unscaledHeight}
          shouldAutoSize={shouldAutoSize}
          containerPadding={containerPadding}
          setLoadingStatus={setLoadingStatus}
          onContentSizeChange={onContentSizeChange}
          properties={properties}
        />
      )}
    </div>
  );
};

export default AssetPropertyList;
