import type UnifiedViewerRenderer from '../../UnifiedViewerRenderer';
import { ShapeAnchorPoint, isCauseMapNodeAnnotation } from '../types';

import getElbowConnectionPadding from './getElbowedConnectionPadding';
import getElbowConectionPathPoints from './getElbowedConnectionPathPoints';

const getElbowConnection = (
  fromId: string,
  toId: string,
  anchorStartTo: ShapeAnchorPoint | undefined,
  anchorEndTo: ShapeAnchorPoint | undefined,
  renderer: UnifiedViewerRenderer
): number[] | undefined => {
  const fromNode = renderer.getAnnotationNodeById(fromId);
  const toNode = renderer.getAnnotationNodeById(toId);
  if (fromNode === undefined || toNode === undefined) {
    return undefined;
  }

  const fromRect = renderer.getNodeRectRelativeToStage(fromNode);
  const toRect = renderer.getNodeRectRelativeToStage(toNode);
  if (fromRect === undefined || toRect === undefined) {
    return undefined;
  }

  const fromAnnotation = renderer.getAnnotationFromNode(fromNode);
  const toAnnotation = renderer.getAnnotationFromNode(fromNode);
  if (
    !isCauseMapNodeAnnotation(fromAnnotation) ||
    !isCauseMapNodeAnnotation(toAnnotation)
  ) {
    return getElbowConectionPathPoints(
      { ...fromRect, anchorPoint: anchorStartTo },
      { ...toRect, anchorPoint: anchorEndTo }
    );
  }

  const padding = getElbowConnectionPadding({
    fromAnnotation,
    fromRect,
    toAnnotation,
    toRect,
    renderer,
  });
  return getElbowConectionPathPoints(
    { ...fromRect, anchorPoint: anchorStartTo, padding: padding?.from },
    { ...toRect, anchorPoint: anchorEndTo, padding: padding?.to }
  );
};

export default getElbowConnection;
