import { Flex } from '@cognite/cogs.js-v10';
import { ProgressBar } from '@infield/features/ui/progress-bar';
import type { FC } from 'react';

import * as S from './elements';

type Props = {
  total?: number;
  completed?: number;
};

const getPercent = (total?: number, completed?: number) =>
  completed && total ? Math.floor((completed * 1000) / total) / 10 : 0;

export const ObservationDetailsProgress: FC<Props> = ({ total, completed }) => {
  const isProgressDescriptionVisible =
    completed !== undefined && total !== undefined;
  return (
    <S.Container>
      <ProgressBar progress={getPercent(total, completed)} />
      {isProgressDescriptionVisible && (
        <Flex justifyContent="space-between">
          <S.Description>{`${completed} of ${total} steps completed.`}</S.Description>
        </Flex>
      )}
    </S.Container>
  );
};
