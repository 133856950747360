const ANCHOR_RADIUS = 10;
export const ANCHOR_STYLE = {
  radius: ANCHOR_RADIUS,
  hoveredRadius: ANCHOR_RADIUS + 5,
  fill: 'rgba(74, 103, 251, 1)',
  stroke: 'rgba(255, 255, 255, 1)',
  shadowColor: 'black',
  shadowBlur: 3,
  strokeWidth: 4,
  name: 'sticky-anchor',
  crossStrokeWidth: 2,
  crossLength: 8,
  crossName: 'sticky-anchor-cross',
};
export const DEFAULT_ANCHOR_PADDING_PX = 40;
export const ANCHOR_NAMES = [
  'top-center',
  'middle-left',
  'middle-right',
  'bottom-center',
] as const;
export type AnchorName = (typeof ANCHOR_NAMES)[number];
