import type { ChecklistItemStatus, ChecklistStatus } from '@cognite/apm-client';
import {
  Body,
  Button,
  CheckmarkAlternativeIcon,
  Dropdown,
  EllipsisHorizontalIcon,
  EllipsisVerticalIcon,
  Flex,
  Menu,
  Modal,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  DismissableContent,
  showGlobalOverlayAtom,
} from '@infield/features/ui';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { ChecklistStatusMenu } from '../checklist-status-menu';

import { ActionsMenu } from './actions-menu';
import * as S from './elements';

interface Props {
  onMarkAs: (status: ChecklistStatus) => void;
  isMarkedAsDone: boolean;
  onChangeRemainingTaskStatuses: (status: ChecklistItemStatus) => void;
  disableSetRemainingTaskStatuses: boolean;
}

export const ChecklistActionMenu: FC<Props> = ({
  onMarkAs,
  isMarkedAsDone,
  onChangeRemainingTaskStatuses,
  disableSetRemainingTaskStatuses,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);
  const setShowOverlay = useSetRecoilState(showGlobalOverlayAtom);
  const [showMarkCompleteModal, setShowMarkCompleteModal] = useState(false);
  const [showMarkInProgressModal, setShowMarkInProgressModal] = useState(false);
  const [showActionDropdown, setShowActionDropdown] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const isDesktop = useIsDesktop();

  const handleClose = () => {
    setShowActionDropdown(false);
    setShowSubMenu(false);
    setShowStatusMenu(false);
  };

  const handleMarkAsDone = () => {
    onMarkAs('Done');
    setShowMarkCompleteModal(false);
  };

  const handleMarkAsInProgress = () => {
    onMarkAs('In progress');
    setShowMarkInProgressModal(false);
  };

  const handleMarkAsInProgressClick = () => {
    setShowActionDropdown(false);
    setShowMarkInProgressModal(true);
  };

  const handleMarkAsDoneClick = () => {
    setShowActionDropdown(false);
    setShowMarkCompleteModal(true);
  };

  const handleSetRemainingTasksClick = () => {
    setShowActionDropdown(false);
    setShowStatusMenu(true);
  };

  const handleSetChecklistClick = () => {
    setShowActionDropdown(false);
    setShowSubMenu(true);
  };

  useEffect(() => {
    if (!isDesktop) {
      setShowOverlay(showActionDropdown || showStatusMenu || showSubMenu);
    } else {
      setShowOverlay(false);
    }
  }, [
    setShowOverlay,
    showActionDropdown,
    isDesktop,
    showStatusMenu,
    showSubMenu,
  ]);

  const statusMenu = (
    <ChecklistStatusMenu
      status=""
      showAll
      onClose={handleClose}
      onStatusClick={(status) => {
        onChangeRemainingTaskStatuses(status);
        handleClose();
      }}
      contentOnly={isDesktop}
    />
  );

  const subMenu = (
    <S.ActionMenu $isDesktop={isDesktop}>
      <Menu.Item
        data-testid={
          !isMarkedAsDone
            ? 'checklist-mark-as-done-button'
            : 'checklist-mark-as-in-progress-button'
        }
        icon={<CheckmarkAlternativeIcon />}
        iconPlacement="left"
        onClick={
          !isMarkedAsDone ? handleMarkAsDoneClick : handleMarkAsInProgressClick
        }
      >
        {!isMarkedAsDone
          ? t('CHECKLIST_MARK_STATUS_DONE', 'Done')
          : t('CHECKLIST_MARK_STATUS_IN_PROGRESS', 'In progress')}
      </Menu.Item>
    </S.ActionMenu>
  );

  const ActionMenu = (
    <ActionsMenu
      statusMenu={statusMenu}
      subMenu={subMenu}
      handleSetRemainingTasksClick={handleSetRemainingTasksClick}
      handleSetChecklistClick={handleSetChecklistClick}
      disableSetRemainingTaskStatuses={disableSetRemainingTaskStatuses}
      isMarkedAsDone={isMarkedAsDone}
    />
  );

  return (
    <DismissableContent onDismiss={handleClose}>
      {isDesktop ? (
        <Dropdown
          visible={showActionDropdown}
          placement="bottom-start"
          content={ActionMenu}
        >
          <Button
            icon={<EllipsisHorizontalIcon />}
            type="ghost"
            aria-label="show-action-dropdown"
            onClick={() => setShowActionDropdown(!showActionDropdown)}
            data-testid="open-checklist-action-menu-button"
          />
        </Dropdown>
      ) : (
        <>
          {showStatusMenu && statusMenu}
          {showSubMenu && subMenu}
          {showActionDropdown && ActionMenu}
          <Button
            icon={<EllipsisVerticalIcon />}
            size="large"
            aria-label="show-action-dropdown"
            onClick={() => setShowActionDropdown(!showActionDropdown)}
            data-testid="open-checklist-action-menu-button"
          />
        </>
      )}

      <Modal
        visible={showMarkInProgressModal}
        icon={<CheckmarkAlternativeIcon />}
        size="small"
        title={t(
          'CHECKLIST_MODAL_IN_PROGRESS_TITLE',
          'Mark checklist as In progress?'
        )}
        onCancel={() => setShowMarkInProgressModal(false)}
        okButtonProps={{
          'data-testid': 'checklist-modal-set-to-in-progress-confirm-button',
        }}
        onOk={handleMarkAsInProgress}
        okText={t('CHECKLIST_MODAL_IN_PROGRESS_OK', 'Mark as In progress')}
      >
        <Flex direction="column" gap={16}>
          <Body strong size="x-small">
            {t(
              'CHECKLIST_MODAL_IN_PROGRESS_BODY_PARAGRAPH_ONE',
              'This will mark the checklist as In progress. It will be moved to the "In progress" section in the checklist list.'
            )}
          </Body>
          <Body strong size="x-small">
            {t(
              'CHECKLIST_MODAL_IN_PROGRESS_BODY_PARAGRAPH_TWO',
              'You can continue working on the checklist that is In progress.'
            )}
          </Body>
        </Flex>
      </Modal>

      <Modal
        visible={showMarkCompleteModal}
        icon={<CheckmarkAlternativeIcon />}
        size="small"
        title={t('CHECKLIST_MODAL_TITLE', 'Mark as done?')}
        onCancel={() => setShowMarkCompleteModal(false)}
        onOk={handleMarkAsDone}
        okButtonProps={{
          'data-testid': 'checklist-modal-set-to-done-confirm-button',
        }}
        okText={t('CHECKLIST_MODAL_OK', 'Mark as done')}
      >
        <Flex direction="column" gap={16}>
          <Body strong size="x-small">
            {t(
              'CHECKLIST_MODAL_BODY_PARAGRAPH_ONE',
              'This will mark the checklist as done. It will be moved to the “Done” section in the checklist list.'
            )}
          </Body>
          <Body strong size="x-small">
            {t(
              'CHECKLIST_MODAL_BODY_PARAGRAPH_TWO',
              'When marked as done, it will no longer be possible to make changes to the checklist.'
            )}
          </Body>
        </Flex>
      </Modal>
    </DismissableContent>
  );
};
