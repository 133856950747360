import type { Checklist, Template, TemplateStatus } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import {
  AddIcon,
  Button,
  ChecklistIcon,
  DeleteIcon,
  Dropdown,
  DuplicateIcon,
  EditIcon,
  EllipsisHorizontalIcon,
  Flex,
  LockIcon,
  Menu,
  Modal,
  PanelLeftIcon,
  PanelRightIcon,
  RefreshIcon,
  TagChip,
  Tooltip,
  UsersIcon,
} from '@cognite/cogs.js-v10';
import { useFlag } from '@cognite/react-feature-flags';
import { selectedActivityAtom } from '@infield/features/activities';
import {
  dateFilterAtom,
  overviewTableRecentlyCreatedChecklistAtom,
  selectedOverviewTableAtom,
} from '@infield/features/activities/activity-planning/state';
import { useIsTemplateAdminQuery } from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  selectedTemplateAtom,
  StatusSelector,
  TextInputModal,
  useTemplateCreateChecklist,
  useUpdateTemplate,
} from '@infield/features/template';
import { PanelHeader } from '@infield/features/ui';
import { AssignModal } from '@infield/features/ui/assign-modal';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { TEMPLATE_TASK_LIMIT } from '../constants';
import { formatTemplateName } from '../utils';

import { CreateChecklistModal } from './create-checklist-modal';
import * as S from './elements';

type Props = {
  externalId: string;
  name?: string;
  currentStatusValue?: TemplateStatus;
  isTaskListEmpty: boolean;
  isEditable: boolean;
  isTaskLimitReached: boolean;
  onAddTask: () => void;
  handleDuplicateTemplate: () => void;
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
};

export const TemplateMenu: FC<Props> = ({
  externalId,
  name,
  currentStatusValue,
  isTaskListEmpty,
  isEditable,
  isTaskLimitReached,
  onAddTask,
  handleDuplicateTemplate,
  isCollapsed,
  setIsCollapsed,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const template = useRecoilValue(selectedTemplateAtom);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
  const setDateFilter = useSetRecoilState(dateFilterAtom);
  const setSelectedTable = useSetRecoilState(selectedOverviewTableAtom);

  const setCreatedChecklist = useSetRecoilState(
    overviewTableRecentlyCreatedChecklistAtom
  );

  const [showCreateChecklistModal, setShowCreateChecklistModal] =
    useState(false);
  const { mutateAsync: upsertTemplate } = useUpdateTemplate();
  const isDeleted = template && template.isArchived;
  const isDraft = currentStatusValue === 'Draft';
  const { data: isTemplateAdmin } = useIsTemplateAdminQuery();
  const { isEnabled: isSearchFeatureEnabled } = useFlag(
    'INFIELD_SEARCH_EVERYTHING_IS_ENABLED'
  );

  const {
    mutateAsync: createChecklist,
    isLoading: isCreateChecklistLoading,
    isSuccess: isCreateChecklistSuccess,
  } = useTemplateCreateChecklist();
  const navigate = useNavigate();
  const setOpenChecklistId = useSetRecoilState(selectedActivityAtom);

  const handleRename = async (name: string) => {
    const template: Template = {
      externalId,
      title: formatTemplateName(name),
    };
    setShowRenameModal(false);
    await upsertTemplate(template);
  };

  const handleDelete = async (archive: boolean) => {
    const template: Template = {
      externalId,
      isArchived: archive,
    };
    setShowDeleteModal(false);
    await upsertTemplate(template);
  };

  const handleNavigateToOverviewTable = (
    createdChecklist: Checklist,
    startTime: string,
    endTime: string
  ) => {
    setSelectedTable('checklists-table');
    setDateFilter([
      dayjs(startTime).startOf('day'),
      dayjs(endTime).endOf('day'),
    ]);
    setCreatedChecklist(createdChecklist);
    navigate({
      pathname: '/overview',
    });
  };

  const handleCreateChecklist = (startTime: string, endTime: string) => {
    createChecklist(
      {
        templateExternalId: externalId,
        plannedStartTime: startTime,
        plannedEndTime: endTime,
      },
      {
        onSuccess: ({ createdChecklist }) => {
          if (isSearchFeatureEnabled) {
            makeToast({
              body: t(
                'CREATE_CHECKLIST_FROM_TEMPLATE_SUCCESS',
                'Checklist created'
              ),
              type: 'success',
              buttonText: t(
                'TEMPLATE_CREATE_CHECKLIST_SUCCESS_TOAST_VIEW_BUTTON',
                'View'
              ),
              onButtonClick: () =>
                handleNavigateToOverviewTable(
                  createdChecklist,
                  startTime,
                  endTime
                ),
            });
          } else {
            makeToast({
              body: t(
                'CREATE_CHECKLIST_FROM_TEMPLATE_SUCCESS',
                'Checklist created'
              ),
              type: 'success',
            });
            setOpenChecklistId(createdChecklist.externalId);
            navigate({
              pathname: '/checklists',
              search: '?activityStatus=Ready',
            });
          }
        },
      }
    );
  };

  const handleAssign = async (assignedTo: string[]) => {
    const template: Template = {
      externalId,
      assignedTo,
    };
    setShowAssignModal(false);
    await upsertTemplate(template);
  };

  useEffect(() => {
    if (isCreateChecklistSuccess) {
      setShowCreateChecklistModal(false);
    }
  }, [isCreateChecklistSuccess]);

  const getCreateChecklistTooltip = (): string => {
    if (isTaskListEmpty) {
      return t(
        'TEMPLATE_CREATE_CHECKLIST_BUTTON_DISABLED_BECAUSE_TASKLIST_EMPTY_TOOLTIP',
        'Task list cannot be empty'
      );
    }
    if (isDraft) {
      return t(
        'TEMPLATE_CREATE_CHECKLIST_BUTTON_DISABLED_BECAUSE_DRAFT_TOOLTIP',
        'Template status must be set to ready'
      );
    }
    return '';
  };

  return (
    <>
      <PanelHeader
        title={name}
        titleTestId="template-header-title"
        actionBeforeTitle={
          <Button
            data-testid="template-list-collapse-button"
            icon={isCollapsed ? <PanelRightIcon /> : <PanelLeftIcon />}
            type="ghost"
            aria-label="show-options"
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
        }
      >
        <Flex gap={8} alignItems="center">
          <StatusSelector
            externalId={externalId}
            currentStatus={currentStatusValue ?? 'Draft'}
            disabled={!isTemplateAdmin}
          />
          {!isEditable && (
            <TagChip
              icon={<LockIcon />}
              size="x-small"
              label={t('TEMPLATE_RESTRICTED', 'Restricted')}
              colorScheme="yellow"
              tooltip={
                !isTemplateAdmin
                  ? t(
                      'TEMPLATE_RESTRICTED_NOT_ADMIN',
                      'Only users with extended access can edit the template.'
                    )
                  : t(
                      'TEMPLATE_RESTRICTED_READY_STATE',
                      'You can edit templates only in Draft state'
                    )
              }
            />
          )}
        </Flex>
        <S.RightItemContainer>
          <Tooltip
            content={getCreateChecklistTooltip()}
            disabled={isDeleted || (!isDraft && !isTaskListEmpty)}
          >
            <Button
              icon={<UsersIcon />}
              onClick={() => setShowAssignModal(true)}
              disabled={isDeleted || isDraft || isTaskListEmpty}
            >
              {!template?.assignedTo || template?.assignedTo.length === 0
                ? t('TEMPLATE_TOOLBAR_BUTTON_ASSIGN', 'Assign')
                : t('TEMPLATE_TOOLBAR_BUTTON_REASSIGN', 'Reassign')}
            </Button>
          </Tooltip>
          <Tooltip
            content={getCreateChecklistTooltip()}
            disabled={isDeleted || (!isDraft && !isTaskListEmpty)}
          >
            <Button
              icon={<ChecklistIcon />}
              onClick={() => setShowCreateChecklistModal(true)}
              disabled={isDeleted || isDraft || isTaskListEmpty}
              data-testid="template-menu-create-checklist-button"
            >
              {t(
                'TEMPLATE_TOOLBAR_BUTTON_CREATE_CHECKLIST',
                'Create checklist'
              )}
            </Button>
          </Tooltip>
          {isTemplateAdmin && (
            <Tooltip
              content={
                <>
                  {t(
                    'TEMPLATE_TOOLBAR_BUTTON_ADD_TASK_DISABLED_TOOLTIP',
                    "You've reached the task limit of {{taskLimit}} for this template.",
                    {
                      taskLimit: TEMPLATE_TASK_LIMIT,
                    }
                  )}
                </>
              }
              disabled={!isTaskLimitReached}
            >
              <S.AddTaskButton
                data-testid="template-menu-add-task-button"
                icon={<AddIcon />}
                type="primary"
                onClick={onAddTask}
                disabled={isDeleted || !isDraft || isTaskLimitReached}
              >
                {t('TEMPLATE_TOOLBAR_BUTTON_ADD_TASK', 'Add task')}
              </S.AddTaskButton>
            </Tooltip>
          )}
          {isTemplateAdmin && (
            <Dropdown
              hideOnSelect={{
                hideOnContentClick: true,
                hideOnOutsideClick: true,
              }}
              placement="bottom-start"
              content={
                <Menu>
                  {!isDeleted && (
                    <>
                      <Menu.Item
                        icon={<EditIcon />}
                        iconPlacement="left"
                        data-testid="template-menu-dropdown-rename-button"
                        onClick={() => setShowRenameModal(true)}
                      >
                        {t('TEMPLATE_MENU_ITEM_RENAME', 'Rename template')}
                      </Menu.Item>
                      <Menu.Item
                        icon={<DuplicateIcon />}
                        iconPlacement="left"
                        onClick={() => handleDuplicateTemplate()}
                      >
                        {t(
                          'TEMPLATE_MENU_ITEM_DUPLICATE',
                          'Duplicate template'
                        )}
                      </Menu.Item>
                    </>
                  )}
                  {isDeleted ? (
                    <Menu.Item
                      icon={<RefreshIcon />}
                      iconPlacement="left"
                      onClick={() => handleDelete(false)}
                    >
                      {t('TEMPLATE_MENU_ITEM_RESTORE', 'Restore template')}
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      icon={<DeleteIcon />}
                      iconPlacement="left"
                      destructive
                      data-testid="template-menu-dropdown-delete-button"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      {t('TEMPLATE_MENU_ITEM_DELETE', 'Delete template')}
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <Button
                icon={<EllipsisHorizontalIcon />}
                type="ghost"
                aria-label="show-template-actions-dropdown"
                data-testid="template-menu-dropdown"
              />
            </Dropdown>
          )}
        </S.RightItemContainer>
      </PanelHeader>
      <TextInputModal
        size="small"
        visible={showRenameModal}
        title={t('TEMPLATE_RENAME_DIALOGUE_TITLE', 'Rename template')}
        inputLabel={t('TEMPLATE_RENAME_DIALOGUE_INPUT_NAME', 'Template name')}
        initialValue={name}
        okText={t('TEMPLATE_RENAME_DIALOGUE_BUTTON_SAVE', 'Save name')}
        cancelText={t('TEMPLATE_RENAME_DIALOGUE_BUTTON_CANCEL', 'Cancel')}
        onSubmit={handleRename}
        onCancel={() => setShowRenameModal(false)}
        okButtonProps={{
          'data-testid': 'template-rename-dialogue-save-button',
        }}
        textInputTestId="template-rename-dialogue"
        maxCharacters={60}
      >
        {null}
      </TextInputModal>
      <CreateChecklistModal
        visible={showCreateChecklistModal}
        onCreateChecklist={handleCreateChecklist}
        isCreateChecklistLoading={isCreateChecklistLoading}
        onClose={() => setShowCreateChecklistModal(false)}
      />
      <AssignModal
        title={t('TEMPLATE_ASSIGN_MODAL_TITLE', 'Assign template')}
        assignedTo={template?.assignedTo}
        visible={showAssignModal}
        onAssign={handleAssign}
        onClose={() => setShowAssignModal(false)}
      />
      <Modal
        size="small"
        destructive
        title={t('TEMPLATE_DELETE_MODAL_TITLE', 'Delete template')}
        okText={t('TEMPLATE_DELETE_MODAL_DELETE', 'Delete')}
        visible={showDeleteModal}
        onOk={() => handleDelete(true)}
        onCancel={() => setShowDeleteModal(false)}
      >
        <S.DeleteModalParagraph>
          {t(
            'TEMPLATE_DELETE_MODAL_WARNING',
            'The template {{template}} will be deleted and moved under the “Deleted” filter in the template list. You can restore the template from there.',
            { template: name ? formatTemplateName(name) : externalId }
          )}
        </S.DeleteModalParagraph>
        <S.DeleteModalParagraph>
          {t(
            'TEMPLATE_DELETE_MODAL_DISCLAIMER',
            'Any time series, comments, media, and readings from the checklists created from this template are still available in InField and Cognite Data Fusion.'
          )}
        </S.DeleteModalParagraph>
      </Modal>
    </>
  );
};
