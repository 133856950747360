const addUnit = <T extends string | number>(
  value: T,
  unit?: string
): string | number => {
  if (unit) {
    return `${value} (${unit})`;
  }

  return value;
};

export default addUnit;
