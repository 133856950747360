import { Button, Menu } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

const MOBILE_MENU_PADDING = 16;

export const StyledSelectButton = styled(Button)<{ $placeholder: boolean }>`
  &.cogs-v10.cogs-button--type-tertiary {
    width: 100%;
    justify-content: left;
    padding: 8px;
    font-weight: 400;
    ${({ $placeholder }) =>
      $placeholder && 'color: var(--cogs-themed-text-icon--muted);'}

    &:hover,
    &:active,
    &:focus {
      ${({ $placeholder }) =>
        $placeholder && 'color: var(--cogs-themed-text-icon--muted);'}
    }

    .cogs-icon-wrapper {
      margin-left: auto;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  &.cogs-v10 {
    max-height: 235px;
    overflow-y: auto;
    z-index: ${z.ACTION_MENU};
    position: absolute;
    bottom: ${MOBILE_MENU_PADDING}px;
    left: ${MOBILE_MENU_PADDING}px;
    max-width: 100vw;
    width: calc(100% - ${MOBILE_MENU_PADDING * 2}px);

    .cogs-tooltip__content + div {
      display: none;
    }

    .cogs-menu-item--toggled {
      background-color: var(
        --cogs-themed-surface--interactive--toggled-default
      );
    }
  }
`;
