import React from 'react';

import { PromoChip } from '@cognite/cogs.js-v10';

import { useTranslation } from '../../hooks';

export const BetaChip: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PromoChip dense tooltip={t('COMMON_BETA_TOOLTIP')}>
      {t('COMMON_BETA_CHIP')}
    </PromoChip>
  );
};
