import type { Filters } from '@cognite/fdm-client/src/types';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useNotificationDetails = (externalId: string) => {
  const { notificationService } = useFDMServices();

  const filters: Filters[] = [];

  if (externalId.length > 0) {
    filters.push({
      equals: {
        property: 'externalId',
        eq: externalId,
      },
    });
  }

  const notificationFilter: Filters = {
    and: [...filters],
  };

  return useQuery(
    [QueryKeys.NOTIFICATION_DETAILS, notificationFilter],
    async () => {
      return notificationService.getNotificationDetails(notificationFilter);
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.NOTIFICATION_DETAILS,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: externalId.length > 0,
    }
  );
};
