const conditionalDebounce = <T>(
  fn: (...args: T[]) => void,
  shouldDebounce: (...args: T[]) => boolean,
  ms: number
): ((...args: T[]) => void) => {
  let timeout: NodeJS.Timeout | undefined;
  return (...args: T[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    if (shouldDebounce(...args)) {
      timeout = setTimeout(() => fn(...args), ms);
      return;
    }

    fn(...args);
  };
};

export default conditionalDebounce;
