import { Body, Heading } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

import { EmptyStateWrapper } from './elements';

type Props = {
  title: string;
  description?: string;
};

export const TableEmptyState: FC<Props> = ({ title, description }) => {
  return (
    <EmptyStateWrapper>
      <Heading level={6}>{title}</Heading>
      {description && <Body size="medium">{description}</Body>}
    </EmptyStateWrapper>
  );
};
