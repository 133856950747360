import type { APMAsset, FdmFile } from '@cognite/apm-client';
import { SearchIcon, SegmentedControl } from '@cognite/cogs.js-v10';
import type { Asset, Timeseries } from '@cognite/sdk';
import { SearchResult } from '@infield/features/search';
import type { UseQueryResult } from '@tanstack/react-query';
import type { FC } from 'react';

import * as S from './elements';
import { TopBarSearchAssetHierarchy } from './top-bar-search-asset-hierarchy';
import type { SearchResourceType, SearchTab } from './types';

type Props = {
  searchInput: string;
  searchInputRef: React.RefObject<HTMLInputElement>;
  searchTabs: SearchTab[];
  selectedTab: SearchResourceType;
  assetSearchResult: UseQueryResult<(Asset | APMAsset)[] | undefined>;
  documentSearchResult: UseQueryResult<FdmFile[] | undefined>;
  timeseriesSearchResult: UseQueryResult<Timeseries[] | undefined>;
  parentAsset: (Asset | APMAsset) | undefined;
  childAssets: (Asset | APMAsset)[] | undefined;
  fullscreen: boolean;
  t: any;
  setSearchInput: (value: string) => void;
  setSelectedTab: (value: SearchResourceType) => void;
  onAssetSelect: (externalId: string) => void;
  onDocumentSelect: (documentExternalId: string, space?: string) => void;
  onTimeseriesSelect: (timeseries: Timeseries) => void;
  onClose: () => void;
};

export const TopBarSearchUI: FC<Props> = ({
  searchInput,
  searchInputRef,
  searchTabs,
  selectedTab,
  assetSearchResult,
  documentSearchResult,
  timeseriesSearchResult,
  parentAsset,
  childAssets,
  fullscreen,
  t,
  setSearchInput,
  setSelectedTab,
  onAssetSelect,
  onDocumentSelect,
  onTimeseriesSelect,
  onClose,
}) => {
  const startSearchText = {
    asset: t(
      'startSearchAssets',
      'Start finding assets by searching for a tag name or description.'
    ),
    documents: t(
      'startSearchDocuments',
      'Start finding documents by searching for a document name.'
    ),
    timeseries: t('startSearchTimeseries', 'Search for a time series name.'),
  };

  const searchingText = {
    asset: t('searchingAssets', 'Searching for assets'),
    documents: t('searchingDocuments', 'Searching for documents'),
    timeseries: t('searchingTimeseries', 'Searching for time series'),
  };

  const noResultText = {
    asset: t(
      'noSearchResultsAssets',
      'Did not find any asset from your search word.'
    ),
    documents: t(
      'noSearchResultsDocuments',
      'Did not find any documents from your search word.'
    ),
    timeseries: t(
      'noSearchResultsTimeseries',
      'Did not find any time series from your search word.'
    ),
  };

  const getSearchInputPlaceholder = (selectedTab: SearchResourceType) => {
    if (selectedTab === 'asset') {
      return t('TOP_BAR_SEARCH_INPUT_PLACEHOLDER_ASSET', 'Search assets');
    }

    if (selectedTab === 'hierarchy') {
      return t('TOP_BAR_SEARCH_INPUT_PLACEHOLDER_HIERARCHY', 'Search assets');
    }

    if (selectedTab === 'timeseries') {
      return t(
        'TOP_BAR_SEARCH_INPUT_PLACEHOLDER_TIMESERIES',
        'Search time series'
      );
    }

    if (selectedTab === 'documents') {
      return t(
        'TOP_BAR_SEARCH_INPUT_PLACEHOLDER_DOCUMENTS',
        'Search documents'
      );
    }
  };

  return (
    <S.SearchContainer $noShadow={fullscreen}>
      <S.HeaderContainer>
        <S.InputContainer>
          <S.SearchInput
            ref={searchInputRef}
            autoFocus
            placeholder={getSearchInputPlaceholder(selectedTab)}
            icon={<SearchIcon />}
            value={searchInput}
            variant="ghost"
            clearable
            onChange={(e) => setSearchInput(e.target.value)}
            data-testid="search-input"
          />
        </S.InputContainer>
        <S.CloseButton type="ghost" onClick={onClose}>
          {t(`TOP_BAR_SEARCH_TAB_BUTTON_CLOSE`, 'Close')}
        </S.CloseButton>
      </S.HeaderContainer>
      <S.TabContainer>
        <SegmentedControl
          currentKey={selectedTab}
          onButtonClicked={setSelectedTab}
        >
          {searchTabs.map((tab) => (
            <S.SegmentedControlButton key={tab.key}>
              {t(`TOP_BAR_SEARCH_TAB_LABEL_${tab.key}`, tab.label)}
            </S.SegmentedControlButton>
          ))}
        </SegmentedControl>
      </S.TabContainer>
      <S.ResultContainer gap={12} direction="column" $fullscreen={fullscreen}>
        {selectedTab === 'asset' && (
          <SearchResult
            query={searchInput}
            queryResult={assetSearchResult}
            onSelect={(selectedResource) =>
              onAssetSelect(selectedResource.externalId!)
            }
            startSearchText={startSearchText.asset}
            searchingText={searchingText.asset}
            noResultText={noResultText.asset}
          />
        )}
        {selectedTab === 'hierarchy' && (
          <TopBarSearchAssetHierarchy
            parentAsset={parentAsset}
            childAssets={childAssets}
            onSelect={onAssetSelect}
          />
        )}
        {selectedTab === 'timeseries' && (
          <SearchResult
            query={searchInput}
            queryResult={timeseriesSearchResult}
            startSearchText={startSearchText.timeseries}
            searchingText={searchingText.timeseries}
            noResultText={noResultText.timeseries}
            onSelect={(selectedResource) =>
              onTimeseriesSelect(selectedResource as Timeseries)
            }
          />
        )}
        {selectedTab === 'documents' && (
          <SearchResult
            query={searchInput}
            queryResult={documentSearchResult}
            onSelect={(selectedResource) =>
              onDocumentSelect(
                selectedResource.externalId || 'missingExternalId',
                'space' in selectedResource ? selectedResource.space : undefined
              )
            }
            startSearchText={startSearchText.documents}
            searchingText={searchingText.documents}
            noResultText={noResultText.documents}
          />
        )}
      </S.ResultContainer>
    </S.SearchContainer>
  );
};
