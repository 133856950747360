import produce from 'immer';
import type { Reducer } from 'react';
import { useCallback, useReducer } from 'react';

type PushAction<T> = { type: 'push'; payload: T };
type BackAction<T> = {
  type: 'back';
  payload: (prevValue: NonNullable<T>) => unknown;
};
type ForwardAction<T> = {
  type: 'forward';
  payload: (nextValue: NonNullable<T>) => unknown;
};

type SelectionHistoryActions<T> =
  | PushAction<T>
  | BackAction<T>
  | ForwardAction<T>;

type SelectionHistoryState<T> = {
  historyArray: T[];
  currentItemPointer: number;
};

const initialState = {
  historyArray: [],
  currentItemPointer: -1,
};

// TODO(INFIELD-625): eslint shouldn't trigger on state reassign in immer function
/* eslint-disable no-param-reassign */
const selectionHistoryReducer = produce(
  // `produce` fn here is to enable immer api for state mutations
  <T>(state: SelectionHistoryState<T>, action: SelectionHistoryActions<T>) => {
    switch (action.type) {
      // pushes the value on top of the history stack if it's not already on top
      // if `null` is on top – it'll be replaced
      // if the value before is the same as the pushed one – it won't be duplicated
      case 'push': {
        // no matter what, null is dropped from the top on push
        if (state.historyArray[state.currentItemPointer] === null) {
          state.historyArray.splice(state.currentItemPointer, 1);
          state.currentItemPointer -= 1;
        }
        const nextPointer = state.currentItemPointer + 1;

        // don't do anything for attempt to push the same value twice
        if (action.payload === state.historyArray[state.currentItemPointer]) {
          return state;
        }

        // avoid duplicates when insert before some value
        if (action.payload === state.historyArray[nextPointer]) {
          state.currentItemPointer = nextPointer;
          return state;
        }

        state.historyArray.splice(nextPointer, 0, action.payload); // insert right before nextPointer
        state.currentItemPointer = nextPointer;

        return state;
      }
      case 'back': {
        const onChange = action.payload;
        const previousItemPointer = state.currentItemPointer - 1;
        const previousItem = state.historyArray[previousItemPointer];
        if (previousItem !== undefined) {
          if (state.historyArray[state.currentItemPointer] === null) {
            state.historyArray.splice(state.currentItemPointer, 1);
          }

          state.currentItemPointer = previousItemPointer;
          onChange(previousItem!);
        }
        return state;
      }
      case 'forward': {
        const onChange = action.payload;
        let nextItemPointer = state.currentItemPointer + 1;
        let nextItem = state.historyArray[nextItemPointer];
        if (nextItem !== undefined) {
          if (state.historyArray[state.currentItemPointer] === null) {
            state.historyArray.splice(state.currentItemPointer, 1);
            // item removal shifts next index
            nextItemPointer -= 1;
            nextItem = state.historyArray[nextItemPointer];
          }

          state.currentItemPointer = nextItemPointer;
          onChange(nextItem!);
        }
        return state;
      }
      default:
        return state;
    }
  }
);

export function useSelectionHistory<T>(
  onChange: (value: NonNullable<T>) => unknown
) {
  const [selectionHistory, dispatch] = useReducer(
    selectionHistoryReducer as Reducer<
      SelectionHistoryState<T>,
      SelectionHistoryActions<T>
    >,
    initialState
  );

  // pushes the value on top of the history stack if it's not already on top
  const push = useCallback(
    (value: T) => dispatch({ type: 'push', payload: value }),
    []
  );

  // call onChange with the previous item from the history
  // if `null` value is on top of the stack it'll be removed
  const back = useCallback(
    () => dispatch({ type: 'back', payload: onChange }),
    [onChange]
  );

  // call onChange with the next item from the history
  // if `null` value is at the current position it'll be removed
  const forward = useCallback(
    () => dispatch({ type: 'forward', payload: onChange }),
    [onChange]
  );

  const previousItem =
    selectionHistory.historyArray[selectionHistory.currentItemPointer - 1];
  const nextItem =
    selectionHistory.historyArray[selectionHistory.currentItemPointer + 1];

  return {
    push,
    back,
    forward,
    previousItem,
    nextItem,
    hasPrevious: previousItem !== undefined,
    hasNext: nextItem !== undefined,
  };
}
