import { Avatar, AvatarGroup } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

import type { FDMFileWithDataUrl } from './type';

export interface Props {
  media?: FDMFileWithDataUrl[];
  onMediaClick: (externalId: string, space?: string) => void;
}

export const MediaCol: FC<Props> = ({ media, onMediaClick }) => {
  if (!media || media.length === 0) return null;

  return (
    <AvatarGroup>
      {media?.map(({ externalId, space, dataUrl }) => (
        <Avatar
          key={externalId}
          image={dataUrl}
          onClick={() => onMediaClick(externalId, space)}
        />
      ))}
    </AvatarGroup>
  );
};
