import { useQuery } from '@tanstack/react-query';

import { useChartsStorageService } from '../ChartsContext';

const useChart = ({ id, projectId }: { id: string; projectId: string }) => {
  const chartsStorageService = useChartsStorageService();
  return useQuery(
    ['chart', id],
    async () => chartsStorageService.fetchChart(projectId, id),
    {
      enabled: !!id,
      staleTime: Infinity,
    }
  );
};

export default useChart;
