import type { APMAsset, FdmFile } from '@cognite/apm-client';
import { Body, IconWrapper, Infobox, LoaderIcon } from '@cognite/cogs.js-v10';
import type { Asset, Timeseries } from '@cognite/sdk';
import type { UseQueryResult } from '@tanstack/react-query';
import type { FC } from 'react';

import * as S from './elements';
import { getTitle } from './utils';

interface Props {
  query: string;
  queryResult: UseQueryResult<
    (APMAsset | Asset)[] | FdmFile[] | Timeseries[] | undefined
  >;
  startSearchText?: string;
  searchingText?: string;
  noResultText?: string;
  onSelect?: (
    selectedResource: (Asset | APMAsset) | FdmFile | Timeseries
  ) => void;
}

export const SearchResult: FC<Props> = ({
  query,
  queryResult,
  startSearchText,
  searchingText,
  noResultText,
  onSelect,
}) => {
  const { data: results, isFetching, isSuccess } = queryResult;

  const isStartSearch = startSearchText && results === undefined;
  const isSearching = isFetching && searchingText && query.length > 0;
  const isResults = isSuccess && results && results.length > 0;
  const isNoResults =
    isSuccess && results && results.length === 0 && noResultText;

  if (isSearching) {
    return (
      <S.SearchingContainer>
        <IconWrapper size={32}>
          <LoaderIcon className="cogs-icon--rotating" />
        </IconWrapper>
        <Body size="x-small">{searchingText}</Body>
      </S.SearchingContainer>
    );
  }

  if (isStartSearch) {
    return <Infobox>{startSearchText}</Infobox>;
  }

  if (isResults) {
    return (
      <>
        {results.map((res) => (
          <S.ResultContainer
            data-testid="search-result-list"
            key={res.externalId}
            onClick={() => onSelect?.(res)}
          >
            <Body size="medium">{getTitle(res)}</Body>
            {'description' in res && (
              <Body size="x-small">{res.description}</Body>
            )}
          </S.ResultContainer>
        ))}
      </>
    );
  }

  if (isNoResults) {
    return <Infobox status="warning">{noResultText}</Infobox>;
  }

  return null;
};
