import type { GeometryFilter } from '@cognite/reveal';
import type { AssetMapping3D, Node3D } from '@cognite/sdk';
import { Box3, Vector3 } from 'three';

export const getGeometryFilterByAssetId = async (
  assetId: number,
  assetMappings: AssetMapping3D[],
  threeDNodes: Node3D[]
): Promise<GeometryFilter> => {
  if (!assetMappings.length) {
    throw new Error(`Asset mappings not found for assetId=${assetId}`);
  }

  // there is a problem with this code that it uses first found asset mapping,
  // this is something we have to do to support meaningful 3D area on mobile,
  // but it also may be inaccurate when multiple asset mappings exist,
  // i.e. one asset mapped to many 3d nodes

  const node = threeDNodes[0];

  const boundingBox = await calculateAreaBoundingBox(node);
  return { boundingBox };
};

const calculateAreaBoundingBox = (node: Node3D) => {
  // This is the bounding box of the equipment we searched for.
  const boundingBox = new Box3(
    new Vector3(...node.boundingBox!.min),
    new Vector3(...node.boundingBox!.max)
  );

  // The 3D viewer will download all geometries within the bounding box.
  // We want to show more of the surrounding area, so make the box larger.
  // The XY-plane is the floor, Z is the vertical space
  const extraDistanceToRender = [10, 10, 8];
  const [x, y, z] = process.env.REACT_APP_E2E_MOCK
    ? [0.1, 0.1, 0]
    : extraDistanceToRender;

  boundingBox.expandByVector(new Vector3(x, y, z));
  return boundingBox;
};
