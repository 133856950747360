import type {
  CustomReadingLabel,
  CustomReadingLabelColor,
} from '@cognite/apm-client';
import { customReadingLabelColors } from '@cognite/apm-client';
import { Dropdown, Flex, StyleIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { LabelReading } from '@infield/features/measurements/label/measurement-label-reading/label-reading';
import type { FC } from 'react';

import { ColorOption } from './color-option';
import * as S from './elements';

interface Props {
  customReadingLabel: CustomReadingLabel;
  onSelectColor: (value?: CustomReadingLabelColor) => void;
}
export const MeasurementLabelColorSelector: FC<Props> = ({
  onSelectColor,
  customReadingLabel,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);

  return (
    <Dropdown
      hideOnSelect={{
        hideOnContentClick: true,
        hideOnOutsideClick: true,
      }}
      content={
        <S.ColorMenu data-testid="task-form-label-reading-configuration-label-color-menu">
          <Flex direction="column">
            {customReadingLabelColors?.map((option) => (
              <ColorOption
                data-testid={`task-form-label-reading-configuration-label-color-selector-${option.value}`}
                key={option.value}
                option={option}
                onSelectColor={onSelectColor}
              />
            ))}
          </Flex>
        </S.ColorMenu>
      }
    >
      {customReadingLabel?.color ? (
        <LabelReading
          isDisabled={false}
          isSelected
          size="medium"
          data-testid="task-form-label-reading-configuration-label-color-chip"
          type={customReadingLabel.color}
        >
          {customReadingLabel.label}
        </LabelReading>
      ) : (
        <S.FullWidthButton
          data-testid="task-form-label-reading-configuration-label-color-button"
          type="tertiary"
          icon={<StyleIcon />}
          iconPlacement="right"
        >
          {t('TEMPLATE_TASK_FORM_CUSTOM_READING_COLOR_EDIT_INPUT', 'Set color')}
        </S.FullWidthButton>
      )}
    </Dropdown>
  );
};
