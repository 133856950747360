import {
  Body,
  Button,
  ChevronDownIcon,
  Heading,
  Infobox,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { useEffect, useState } from 'react';
import type { FC, ReactElement } from 'react';

import { CollapsibleContent } from './collapsible-content';
import * as S from './elements';

interface Props {
  status: 'critical' | 'neutral' | 'success' | 'warning';
  title: string;
  additionalContent?: ReactElement;
  description?: string;
  collapsible: boolean;
  initializeCollapsed?: boolean;
  okButtonText?: string;
  onOkButtonClick?: () => void;
}
export const AlertBox: FC<Props> = ({
  status,
  title,
  additionalContent,
  description,
  collapsible,
  initializeCollapsed,
  okButtonText,
  onOkButtonClick,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.infobar);
  const [isCollapsed, setIsCollapsed] = useState(Boolean(initializeCollapsed));

  useEffect(() => {
    if (!collapsible) {
      setIsCollapsed(false);
    }
  }, [collapsible]);

  const handleOkButtonClick = () => {
    setIsCollapsed(true);
    if (onOkButtonClick) {
      onOkButtonClick();
    }
  };

  return (
    <S.InfoBoxWrapper $isCollapsed={isCollapsed}>
      <Infobox status={status}>
        <S.Header
          $isCollapsed={isCollapsed}
          data-testid="offline-alert-box-header"
          onClick={() => setIsCollapsed(false)}
        >
          <Heading data-testid="offline-alert-box-header-title" level={5}>
            {title}
          </Heading>
          {collapsible && (
            <S.ExpandButton
              icon={<ChevronDownIcon />}
              type="ghost"
              aria-label={t('NETWORK_STATUS_BANNER_EXPAND_BUTTON', 'Expand')}
            />
          )}
        </S.Header>

        <CollapsibleContent isCollapsed={isCollapsed}>
          <div>
            {description && <Body>{description}</Body>}
            {additionalContent}

            {collapsible && (
              <S.CollapseButtonWrapper>
                <Button
                  type="tertiary"
                  onClick={handleOkButtonClick}
                  data-testid="offline-alert-box-ok-button"
                >
                  {okButtonText}
                </Button>
              </S.CollapseButtonWrapper>
            )}
          </div>
        </CollapsibleContent>
      </Infobox>
    </S.InfoBoxWrapper>
  );
};
