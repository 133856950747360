import type { ModelId } from '@cognite/apm-client/src/three-d/types';
import type { CognitePointCloudModel } from '@cognite/reveal';
import type { ClickedNodeData } from '@cognite/reveal-react-components';
import type {
  AnnotationData,
  AnnotationsBoundingVolume,
  AnnotationsBox,
} from '@cognite/sdk';
import * as THREE from 'three';

// at the time of writing the lowest device we need to support is iSafe 530
export const isLowEndDevice = () => {
  const LOWEST_SCREEN_WIDTH = 491; /* iSafe is 320x490 */

  return (
    window.screen.width < LOWEST_SCREEN_WIDTH &&
    // do not degrade experience for iPhones – those are powerful enough
    // we could also parse user agent for IS530 or /IS5\d\d/ but just in case apply the optimisations for all android devices for now
    !navigator.userAgent.match(/iPhone/i)
  );
};

const createBoxAnnotationAsBox3 = ({
  box,
  matrix,
}: {
  box: AnnotationsBox;
  matrix: THREE.Matrix4;
}): THREE.Box3 => {
  const cubeCorners = [
    new THREE.Vector3(-1, -1, -1),
    new THREE.Vector3(1, -1, -1),
    new THREE.Vector3(1, 1, -1),
    new THREE.Vector3(-1, 1, -1),
    new THREE.Vector3(-1, -1, 1),
    new THREE.Vector3(1, -1, 1),
    new THREE.Vector3(1, 1, 1),
    new THREE.Vector3(-1, 1, 1),
  ];

  // Create the boxMatrix from the given box.matrix
  const boxMatrix = new THREE.Matrix4();
  boxMatrix.fromArray(box.matrix);
  boxMatrix.transpose();

  // Calculate the actualMatrix
  const actualMatrix = matrix.clone();
  actualMatrix.multiply(boxMatrix);

  // Transform each corner of the cube using the actualMatrix
  const transformedCorners = cubeCorners.map((corner) =>
    corner.applyMatrix4(actualMatrix)
  );

  // Create a new Box3 encompassing all transformed corners
  const resultBox = new THREE.Box3().setFromPoints(transformedCorners);

  return resultBox;
};

export const getBoundingBoxFromAnnotation = (
  annotation: AnnotationData,
  model: CognitePointCloudModel
) => {
  const matrix4 = model.getCdfToDefaultModelTransformation();
  const annotationsBox = (annotation as AnnotationsBoundingVolume).region[0]
    .box as AnnotationsBox;
  if (!matrix4 || !annotationsBox) return undefined;
  const annotationBox3 = createBoxAnnotationAsBox3({
    box: annotationsBox,
    matrix: matrix4,
  });
  return annotationBox3;
};

export const getClickedAssetFromClickedNodeData = (
  clickedNodeData?: ClickedNodeData
) => {
  const clickedAssetCadClassic =
    clickedNodeData?.assetMappingResult?.assetIds[0];
  const clickedAssetCadIdm = clickedNodeData?.fdmResult?.fdmNodes[0];
  const clickedAssetPointCloud =
    clickedNodeData?.pointCloudAnnotationMappingResult
      ? clickedNodeData?.pointCloudAnnotationMappingResult[0].asset.id
      : undefined;
  const clickedAssetData = {
    ...clickedAssetCadIdm,
    assetId: clickedAssetCadClassic || clickedAssetPointCloud,
  };
  return clickedAssetData;
};

export const getModelIdFromExternalId = (externalId: string): ModelId => {
  // The externalId should be on the form `cog_3d_model_${modelId}`
  return Number(externalId.slice('cog_3d_model_'.length));
};
