import isUndefined from 'lodash/isUndefined';

import addUnit from './addUnit';

const getTooltipNumericValue = (
  value?: number,
  unit?: string
): string | number | undefined => {
  if (isUndefined(value)) {
    return undefined;
  }

  return addUnit(value.toFixed(3), unit);
};

export default getTooltipNumericValue;
