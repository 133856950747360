import Konva from 'konva';
import { v4 as uuid } from 'uuid';

import { DEFAULT_STROKE_SCALE_ENABLED } from '../annotations/constants';
import {
  AnnotationType,
  isCauseMapNodeAnnotationNode,
  LineType,
  PolylineEndType,
  Position,
} from '../annotations/types';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import { UnifiedViewer } from '../UnifiedViewer';
import UnifiedViewerEventType from '../UnifiedViewerEventType';
import { UnifiedViewerPointerEvent } from '../UnifiedViewerRenderer/UnifiedEventHandler';
import configureCauseMapConnection from '../utils/configureCauseMapConnection';
import { getImaginaryHorizontalOrVerticalEndPosition } from '../utils/getImaginaryHorizontalOrVerticalEndPosition';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';

import ConnectionHelper from './ConnectionHelper';
import { ContainerAttachmentHelper } from './ContainerAttachmentHelper';
import getShapeEventPropsFromKonvaShape from './getShapeEventPropsFromKonvaShape';
import Tool from './Tool';
import { LineToolConfig, ToolType } from './types';

export default class LineTool extends Tool {
  public readonly type = ToolType.LINE;
  public override readonly cursor = 'crosshair';
  private config: LineToolConfig;
  private pendingNode: Konva.Arrow | undefined;
  private connectionHelper: ConnectionHelper;
  private containerAttachmentHelper: ContainerAttachmentHelper;

  public constructor({
    unifiedViewer,
    config,
  }: {
    unifiedViewer: UnifiedViewer;
    config: LineToolConfig;
  }) {
    super(unifiedViewer);

    this.config = config;
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);
    this.connectionHelper = new ConnectionHelper(unifiedViewer);
    this.containerAttachmentHelper = new ContainerAttachmentHelper(
      unifiedViewer
    );
    this.startInteractionHelper();
    this.setConfig(config);
  }

  public setConfig(config: LineToolConfig): void {
    const didInteractionModeChange =
      config.shouldGenerateConnections !==
      this.config?.shouldGenerateConnections;
    this.config = config;
    if (didInteractionModeChange) {
      this.startInteractionHelper();
    }
  }

  private startInteractionHelper = (): void => {
    this.connectionHelper.disable();
    this.containerAttachmentHelper.end();

    if (this.config?.shouldGenerateConnections === true) {
      this.connectionHelper.enable();
      this.connectionHelper.updateHoverEffect();
    } else {
      this.containerAttachmentHelper.start();
    }
  };

  public override onMouseDown = (e: UnifiedViewerPointerEvent): void => {
    e.target.stopDrag();

    if (this.pendingNode === undefined) {
      this.initializePendingNodeIfAllowed();
    }

    if (this.pendingNode === undefined) {
      return;
    }

    this.addCurrentPositionAsPoint(false);

    this.unifiedViewer.emit(UnifiedViewerEventType.ON_TOOL_START);
  };

  public override onMouseMove = (e: UnifiedViewerPointerEvent): void => {
    this.addCurrentPositionAsPoint(e.evt.shiftKey);

    if (!this.shouldGenerateConnections()) {
      return;
    }

    this.connectionHelper.updateHoverEffect();
  };

  public onKeyDown = (e: KeyboardEvent): void => {
    this.addCurrentPositionAsPoint(e.shiftKey); //snaps line into straight line
  };

  public onKeyUp = (e: KeyboardEvent): void => {
    this.addCurrentPositionAsPoint(e.shiftKey); //snaps line out of straight line mode
  };

  public override onDestroy(): void {
    this.connectionHelper.onDestroy();
    this.containerAttachmentHelper.onDestroy();
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);
  }

  private shouldGenerateConnections = (): boolean =>
    this.config?.shouldGenerateConnections === true;

  private getRelativePointerPosition = (): Position | null => {
    return this.unifiedViewer.stage.getRelativePointerPosition();
  };

  private initializeConnectionLine = (): void => {
    if (this.pendingNode === undefined) {
      return;
    }

    const { mouseDownNode, mouseUpNode } =
      this.connectionHelper.getConnectionEndpointNodes();
    if (mouseDownNode === undefined && mouseUpNode === undefined) {
      return;
    }
    if (mouseDownNode === mouseUpNode) {
      return;
    }
    this.pendingNode.setAttrs({
      fromId: mouseDownNode?.id(),
      toId: mouseUpNode?.id(),
      lineType: this.pendingNode.attrs.lineType ?? LineType.STRAIGHT,
    });

    if (
      mouseDownNode !== undefined &&
      mouseUpNode !== undefined &&
      isCauseMapNodeAnnotationNode(mouseDownNode) &&
      isCauseMapNodeAnnotationNode(mouseUpNode)
    ) {
      configureCauseMapConnection({
        connection: this.pendingNode,
        fromNode: mouseDownNode,
        toNode: mouseUpNode,
      });
      return;
    }
  };

  private initializePendingNodeIfAllowed = () => {
    const currentPosition = this.getRelativePointerPosition();
    if (currentPosition === null) {
      return;
    }

    this.pendingNode = new Konva.Arrow({
      ...this.config,
      [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
      id: uuid(),
      userGenerated: true,
      name: 'user-drawing',
      source: this.type,
      containerId: undefined,
      annotationType: AnnotationType.POLYLINE,
      isSelectable: true,
      isDraggable: true,
      isResizable: true,
      pointerAtBeginning: this.config?.startEndType === PolylineEndType.ARROW,
      pointerAtEnding: this.config?.endEndType === PolylineEndType.ARROW,
      points: [
        currentPosition.x,
        currentPosition.y,
        currentPosition.x,
        currentPosition.y,
      ],
      strokeWidth: this.config?.strokeWidth,
      strokeScaleEnabled:
        this.config?.shouldEnableStrokeScale ?? DEFAULT_STROKE_SCALE_ENABLED,
    });
    this.pendingNode.listening(false);

    this.unifiedViewer.setDirtyNodes([this.pendingNode]);
    this.containerAttachmentHelper.lockTarget();
  };

  public override onMouseUp(e: UnifiedViewerPointerEvent): void {
    this.onComplete(e.evt.shiftKey);
  }

  public override onMouseLeave = (evt: MouseEvent): void => {
    if (this.pendingNode) {
      this.onComplete(evt.shiftKey);
    }
  };

  public override onComplete = (shouldEnforceRightAngles?: boolean): void => {
    this.addCurrentPositionAsPoint(shouldEnforceRightAngles === true);

    if (this.pendingNode === undefined) {
      return;
    }

    if (this.shouldGenerateConnections()) {
      this.initializeConnectionLine();
    } else {
      this.containerAttachmentHelper.end([this.pendingNode]);
      this.containerAttachmentHelper.start();
    }

    this.unifiedViewer.commitDirtyNodes();
    getMetricsLogger()?.trackEvent(TrackedEventType.CREATE_LINE, {
      ...getShapeEventPropsFromKonvaShape(this.pendingNode as Konva.Shape),
      points: this.pendingNode?.points() || [],
    });
    this.pendingNode = undefined;

    // We clear the connection state in the helper, but we don't remove the hover effect since the
    // user is still above the shape
    this.connectionHelper.clearConnection();

    this.unifiedViewer.emit(UnifiedViewerEventType.ON_TOOL_END);
  };

  private getNextLineSegmentEndPoint = (
    currentPoints: number[],
    newLinePosition: Position,
    shouldEnforceRightAngles: boolean
  ): Position => {
    return currentPoints.length >= 2 && shouldEnforceRightAngles
      ? getImaginaryHorizontalOrVerticalEndPosition(
          {
            x: currentPoints[0],
            y: currentPoints[1],
          },
          newLinePosition
        )
      : newLinePosition;
  };

  private addCurrentPositionAsPoint = (shouldEnforceRightAngles: boolean) => {
    if (this.pendingNode === undefined) {
      return;
    }
    const currentPosition = this.getRelativePointerPosition();
    if (currentPosition === null) {
      return;
    }
    const currentPoints = this.pendingNode.points();
    const nextLineSegmentEndpoint = this.getNextLineSegmentEndPoint(
      currentPoints,
      currentPosition,
      shouldEnforceRightAngles
    );
    const nextPoints = [
      ...currentPoints.slice(0, 2),
      nextLineSegmentEndpoint.x,
      nextLineSegmentEndpoint.y,
    ];
    this.pendingNode.points(nextPoints);
  };
}
