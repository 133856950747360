import type { APMAsset, FdmFile } from '@cognite/apm-client';
import type { Asset, Timeseries } from '@cognite/sdk';

export const getTitle = (res: Asset | APMAsset | FdmFile | Timeseries) => {
  if ('name' in res) {
    return res.name || res.externalId || '';
  }
  // APM Asset uses title instead of name
  if ('title' in res) {
    return res.title || res.externalId || '';
  }
  return '';
};
