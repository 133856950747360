import { Input, SearchIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { IsSearchOpenAtom } from '@infield/features/top-bar';
import type { FC } from 'react';
import { Trans } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import * as S from './elements';

export const LandingPage: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.landingPage);
  const setIsSearchOpen = useSetRecoilState(IsSearchOpenAtom);
  return (
    <S.Content>
      <S.Wrapper>
        <S.Header>
          <Trans t={t} i18nKey="LANDING_PAGE_TITLE">
            Explore your data
          </Trans>
        </S.Header>
        <Input
          fullWidth
          placeholder={t(
            'LANDING_PAGE_SEARCH_PLACEHOLDER',
            'Search for asset...'
          )}
          onFocus={() => setIsSearchOpen(true)}
          themeProps={{ theme: 'base', inverted: true }}
          variant="solid"
          icon={<SearchIcon />}
          size="large"
        />
      </S.Wrapper>
    </S.Content>
  );
};
