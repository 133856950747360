// Usage: useMetrics(METRICS_NAMESPACES.threeD)
export const METRICS_NAMESPACES = {
  auditChecklist: 'Audit.Checklist',
  auditChecklistItem: 'Audit.ChecklistItem',
  auditConditionalAction: 'Audit.ConditionalAction',
  auditCondition: 'Audit.Condition',
  auditAction: 'Audit.Action',
  auditMeasurement: 'Audit.Measurement',
  autitObservation: 'Audit.Observation',
  auditSchedule: 'Audit.Schedule',
  auditTemplate: 'Audit.Template',
  auditTemplateItem: 'Audit.TemplateItem',
  devNotification: 'devNotification',
  threeD: 'ThreeD',
  threeDArea: 'ThreedArea',
  user: 'User',
  navigation: 'Navigation',
  templates: 'Templates',
  activity: 'Activity',
  checklists: 'Checklists',
  offlineMode: 'OfflineMode',
  observation: 'Observation',
  observationsDesktop: 'observationsDesktop',
  SLI: 'SLI',
  timeseriesAccess: 'TimeseriesAccess',
  configVersion: 'ConfigVersion',
};
