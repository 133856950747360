export const allowedVersions = [
  {
    label: 'Production',
    value: 'production',
  },

  {
    label: 'Release 22 (Sep 3, 2024)',
    value: '22',
  },
  {
    label: 'Release 23 (Dec 3, 2024)',
    value: '23',
  },
];
