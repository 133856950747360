import type { InFieldUserPreferences } from '@cognite/apm-client';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useUserPreferencesQuery = () => {
  const { apmClient } = useFDMServices();

  return useQuery<InFieldUserPreferences[], Error>(
    [QueryKeys.USER_PREFERENCES, 'list'],
    async () => {
      const {
        list: { items: userPreferences },
      } = await apmClient.userPreferences.list(
        `
        externalId
        language
        apmAppConfig
        infield
      `,
        { pageSize: 1000 }
      );

      return userPreferences;
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.USER_PREFERENCES,
          },
        }),
    }
  );
};
