import type { ViewDefinitionProperty } from '@cognite/sdk';
import { SUPPORTED_FIELD_TYPES } from '@infield/features/activities';

export const getAssetMetadata = (
  data: Record<string, any>,
  baseViewDefinition: Record<string, ViewDefinitionProperty>,
  customerViewDefinition: Record<string, ViewDefinitionProperty>
): Record<string, any> => {
  const customerSpecificProperties = getCustomerSpecificProperties(
    baseViewDefinition,
    customerViewDefinition
  );
  const properties: Record<string, any> = {};

  Object.entries(customerSpecificProperties).forEach(
    ([propertyName, viewDefinitionProperty]) => {
      if (isSupportedType(viewDefinitionProperty)) {
        properties[viewDefinitionProperty.name || propertyName] =
          data[propertyName];
      }
    }
  );

  return properties;
};

export const getCustomerSpecificProperties = (
  baseObject: Record<string, ViewDefinitionProperty>,
  customerObject: Record<string, ViewDefinitionProperty>
): Record<string, ViewDefinitionProperty> => {
  const customerSpecificProperties: Record<string, ViewDefinitionProperty> = {};
  const baseViewPropertiesToInclude = ['aliases', 'tags'];

  Object.entries(customerObject).forEach(([key, value]) => {
    if (!baseObject[key] || baseViewPropertiesToInclude.includes(key)) {
      customerSpecificProperties[key] = value;
    }
  });

  return customerSpecificProperties;
};

export const isSupportedType = (property: ViewDefinitionProperty) => {
  if ('type' in property && 'type' in property.type) {
    return SUPPORTED_FIELD_TYPES.includes(property.type.type);
  }
};
