import Konva from 'konva';

const PREVIEW_NODE_OPACITY = 0.5;

const getPreviewNode = (props: {
  label: Konva.LabelConfig;
  tag: Konva.TagConfig;
  text: Konva.TextConfig;
}): Konva.Label => {
  const label = new Konva.Label({ ...props.label });
  label.add(new Konva.Tag({ ...props.tag, opacity: PREVIEW_NODE_OPACITY }));
  label.add(new Konva.Text({ ...props.text, text: '' }));
  label.listening(false);
  label.cache();
  return label;
};

export default getPreviewNode;
