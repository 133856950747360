import React, { useMemo } from 'react';

import { round } from 'lodash-es';

import { Colors, Flex, Heading, Overline } from '@cognite/cogs.js-v10';
import { useSDK } from '@cognite/sdk-provider';

import useStatusChange from '../ReactContainer/useStatusChange';

import { TimeseriesContentProps } from './TimeseriesContent';
import useTimeseriesLatestValue from './useTimeseriesLatestValue';

type TimeseriesValueContentProps = TimeseriesContentProps;

const FONT_SCALE_FACTOR = 5;
/**
 * PN 2024-10-20: I've extracted the key values from the design system to keep the proportions
 * correct while scaling up the actually used numbers to make sense with the default sizing
 * of the component. Using the defaults would lead to everything being too small and a lot of
 * dead space.
 */
const SHAMEFUL_DESIGN_VALUE_FONT_SIZE = 36;
const SHAMEFUL_DESIGN_UNIT_FONT_SIZE = 10;
const SHAMEFUL_DESIGN_VALUE_LINE_HEIGHT = 44;
const SHAMEFUL_DESIGN_UNIT_LINE_HEIGHT = 12;
const VALUE_FONT_SIZE = SHAMEFUL_DESIGN_VALUE_FONT_SIZE * FONT_SCALE_FACTOR;
const UNIT_FONT_SIZE = SHAMEFUL_DESIGN_UNIT_FONT_SIZE * FONT_SCALE_FACTOR;
const VALUE_LINE_HEIGHT =
  SHAMEFUL_DESIGN_VALUE_LINE_HEIGHT / SHAMEFUL_DESIGN_VALUE_FONT_SIZE;
const UNIT_LINE_HEIGHT =
  SHAMEFUL_DESIGN_UNIT_LINE_HEIGHT / SHAMEFUL_DESIGN_UNIT_FONT_SIZE;

const UNIT_MAX_CHARACTERS = 20;

const TimeseriesValueContent: React.FC<TimeseriesValueContentProps> = ({
  width,
  height,
  unscaledWidth,
  unscaledHeight,
  endDate,
  instance,
  setLoadingStatus,
  style,
}) => {
  const sdk = useSDK();
  const scale = Math.min(width / unscaledWidth, height / unscaledHeight);

  const timeseries = useTimeseriesLatestValue(instance, sdk, {
    endDate,
  });
  useStatusChange({
    data: timeseries.data,
    isLoading: timeseries.isLoading && timeseries.isFetching,
    isError: timeseries.isError,
    setLoadingStatus,
  });

  const parsedValue = useMemo(() => {
    if (timeseries.data === undefined) {
      return undefined;
    }

    if (timeseries.data.datapoints.length === 0) {
      return '-';
    }

    if (Number.isFinite(timeseries.data.datapoints[0].value)) {
      return round(Number(timeseries.data.datapoints[0].value), 2);
    }

    return timeseries.data.datapoints[0].value;
  }, [timeseries.data]);

  const unit = (timeseries.data?.unit ?? '').substring(0, UNIT_MAX_CHARACTERS);

  return (
    <>
      <div
        style={{ transform: `scale(${scale})`, transformOrigin: 'top left' }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            width: unscaledWidth,
            height: unscaledHeight,
            cursor: 'default',
            border: '1px solid #D9D9D9',
            display: 'flex',
          }}
        >
          {timeseries.data !== undefined && (
            <Flex
              flex={1}
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap={8}
              style={{
                background:
                  style?.fill ?? Colors['text-icon--status-undefined'],
              }}
            >
              <Flex direction="row" justifyContent="center">
                <Heading
                  level={1}
                  style={{
                    color: 'white',
                    fontSize: VALUE_FONT_SIZE,
                    lineHeight: VALUE_LINE_HEIGHT,
                  }}
                >
                  {parsedValue}
                </Heading>
              </Flex>
              {unit.length > 0 && (
                <Flex direction="row" justifyContent="center">
                  <Overline
                    size="small"
                    style={{
                      color: 'white',
                      fontSize: UNIT_FONT_SIZE,
                      lineHeight: UNIT_LINE_HEIGHT,
                    }}
                  >
                    {unit}
                  </Overline>
                </Flex>
              )}
            </Flex>
          )}
        </div>
      </div>
      {timeseries.isFetching && (
        <div
          style={{
            position: 'absolute',
            inset: 0,
            background: 'rgba(255,255,255,0.7)',
          }}
        />
      )}
    </>
  );
};

export default TimeseriesValueContent;
