import { Button, EllipsisHorizontalIcon, Flex } from '@cognite/cogs.js-v10';
import type { ChecklistItemStatusAggregation } from '@infield/features/checklist';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { CustomCollapse } from '@infield/features/ui/custom-collapse/custom-collapse';
import type { FC } from 'react';

import { getGroupStatusColor, getGroupStatusLabel } from '../utils';

import * as S from './elements';

type Props = {
  isLocked?: boolean;
  groupName: string;
  groupTaskList: JSX.Element[];
  aggregatedGroupStatuses?: ChecklistItemStatusAggregation;
  defaultOpened?: boolean;
  onClickActionMenu: () => void;
};

export const ChecklistGroup: FC<Props> = ({
  isLocked,
  groupName,
  groupTaskList,
  aggregatedGroupStatuses,
  defaultOpened,
  onClickActionMenu,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);

  const handleOnClickActionMenu = () => {
    if (!isLocked) {
      onClickActionMenu();
    }
  };

  return (
    <CustomCollapse
      isExpanded={defaultOpened}
      isWithoutPadding
      arrowButtonTestId={`checklist-${groupName}-group-arrow-button`}
      header={
        <S.MenuTitleContainer gap={8}>
          <Flex flex={1}>
            <S.GroupName level={6}>{groupName}</S.GroupName>
          </Flex>
          <Flex flex={0}>
            {aggregatedGroupStatuses && (
              <S.GroupStatus
                size="x-small"
                label={getGroupStatusLabel(
                  aggregatedGroupStatuses.completed,
                  aggregatedGroupStatuses.total
                )}
                colorScheme={getGroupStatusColor(
                  aggregatedGroupStatuses.notOk,
                  aggregatedGroupStatuses.ok
                )}
              />
            )}
          </Flex>
        </S.MenuTitleContainer>
      }
      extra={
        <Button
          data-testid="checklist-items-group-menu-button"
          className="menu-button"
          disabled={isLocked}
          icon={<EllipsisHorizontalIcon />}
          type="ghost"
          size="x-large"
          aria-label={t('CHECKLIST_GROUP_MENU_BUTTON', 'Group menu')}
          onClick={(ev) => {
            ev.stopPropagation();
            handleOnClickActionMenu();
          }}
        />
      }
    >
      {groupTaskList}
      <S.GroupEndContainer>
        <S.GroupEndText>
          {t('CHECKLIST_END_OF_GROUP_TEXT', 'End of')} {groupName}
        </S.GroupEndText>
      </S.GroupEndContainer>
    </CustomCollapse>
  );
};
