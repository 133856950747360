import { useAuthContext } from '@cognite/e2e-auth';
import { useSelectedRootLocationConfiguration } from '@infield/features/app-config';
import { QueryKeys } from '@infield/utils/queryKeys';
import {
  extractDatasetIdsRead,
  extractDatasetIdsWrite,
} from '@infield/utils/timeseriesAccess';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import type { Group } from '../../features/access/types';

export const useUserTimeseriesAccess = () => {
  const { client } = useAuthContext();

  const configuredRootLocation = useSelectedRootLocationConfiguration();
  const datasetId = configuredRootLocation?.dataSetId;

  return useQuery<boolean, Error>(
    [QueryKeys.USER, 'userTimeseriesAccess', datasetId],
    async () => {
      const groups = (await client.groups.list()) as Group[];
      const idsWithReadAccess = extractDatasetIdsRead(groups);
      const idsWithWriteAccess = extractDatasetIdsWrite(groups);

      const hasReadAccess =
        idsWithReadAccess.includes('all') ||
        (Boolean(datasetId) && idsWithReadAccess.includes(String(datasetId)));

      const hasWriteAccess =
        idsWithWriteAccess.includes('all') ||
        (Boolean(datasetId) && idsWithWriteAccess.includes(String(datasetId)));

      return hasReadAccess && hasWriteAccess;
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: `${QueryKeys.USER}, userTimeseriesAccess, ${datasetId}`,
          },
        }),
    }
  );
};
