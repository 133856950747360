import {
  AppConfigPage,
  useIsAppConfiguratorQuery,
} from '@infield/features/app-config';
import { IdmConfigPage } from '@infield/features/config/idm-config-page';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import type { FC } from 'react';

export const AppConfigView: FC = () => {
  const { data: hasAppConfigPermission } = useIsAppConfiguratorQuery();
  const { isIdm } = useAppConfigContext();

  if (isIdm) {
    return <IdmConfigPage />;
  }

  return <AppConfigPage hasAppConfigPermission={hasAppConfigPermission} />;
};
