import type { Position } from '../../annotations/types';
import assertNever from '../../utils/assertNever';

import { DEFAULT_ANCHOR_PADDING_PX, type AnchorName } from './constants';

export const getAnchorOffset = (
  name: AnchorName,
  anchorPaddingPx = DEFAULT_ANCHOR_PADDING_PX
): Position => {
  if (name === 'top-center') {
    return { x: 0, y: -anchorPaddingPx };
  }
  if (name === 'middle-left') {
    return { x: -anchorPaddingPx, y: 0 };
  }
  if (name === 'middle-right') {
    return { x: anchorPaddingPx, y: 0 };
  }
  if (name === 'bottom-center') {
    return { x: 0, y: anchorPaddingPx };
  }
  assertNever(name, 'Unknown anchor name provided. This should not happen.');
};
