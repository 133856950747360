import type { Observation } from '@cognite/apm-client';
import {
  ObservationDetails,
  useObservationCreateMutation,
  useObservationsDetailsQuery,
  useObservationUpsertMutation,
} from '@infield/features/observation';
import { useBackButtonUrlContext } from '@infield/providers/back-button-url-provider';
import type { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const ObservationDetailsMobile: FC = () => {
  const { observationExternalId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { history, navigateBack } = useBackButtonUrlContext();
  const pathname = history.at(-2);

  const { data: observations } = useObservationsDetailsQuery(
    observationExternalId ? [observationExternalId] : []
  );

  const { mutateAsync: upsertObservation } = useObservationUpsertMutation();
  const { mutateAsync: createObservation } = useObservationCreateMutation();
  // We pass edgeStartNode only once when observation is created for the first time
  const checklistItemExternalId = !observationExternalId
    ? location.state?.checklistItemExternalId
    : undefined;
  const asset = location.state?.asset;

  const handleUpdate = (observation: Observation) => {
    if (!observationExternalId) {
      createObservation(
        { observation, edgeStartNode: checklistItemExternalId },
        {
          onSuccess: () => {
            navigate(
              `/observation/${encodeURIComponent(observation.externalId)}`,
              {
                replace: true,
                state: { from: location.pathname },
              }
            );
          },
        }
      );
    } else {
      upsertObservation({ observation });
    }
  };

  const handleCancel = () => {
    /* Once user edits the observation, an observation is created 
    and added in the url params. navigateBack() takes them back to /observation
    while navigate(-1) brings them 1 page before that */
    if (pathname === '/observation') navigate(-1);
    else navigateBack();
  };

  return (
    <ObservationDetails
      checklistItemAsset={asset}
      observation={observations ? observations[0] : undefined}
      onCancel={handleCancel}
      onUpdate={handleUpdate}
    />
  );
};
