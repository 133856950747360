import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { AssetInfoDesktop } from './asset-info-desktop';
import { AssetInfoMobileContainer } from './asset-info-mobile';
import * as S from './elements';
import { ResizeObserverContainer } from './resize-observer-container';

type Props = {
  assetId: number | string | null;
  isLoading: boolean;
  onClose: () => void;
  onResize: (rect: DOMRect) => void;
};

/**
 * Picks desktop or mobile layout to display asset information on asset 3d page
 */
export const AssetInfo: FC<Props> = ({
  assetId,
  isLoading,
  onResize,
  onClose,
}) => {
  const isDesktop = useIsDesktop();

  const navigate = useNavigate();

  const handleNameClick = (assetExternalId: string) => {
    navigate(`/asset/${encodeURIComponent(assetExternalId)}`);
  };

  return isDesktop ? (
    <S.DesktopWrapper>
      <AssetInfoDesktop
        onClose={onClose}
        assetId={assetId}
        isLoading={isLoading}
      />
    </S.DesktopWrapper>
  ) : (
    <ResizeObserverContainer onResize={onResize}>
      <AssetInfoMobileContainer
        onAssetNameClick={handleNameClick}
        onClose={onClose}
        assetId={assetId}
        isLoading={isLoading}
      />
    </ResizeObserverContainer>
  );
};
