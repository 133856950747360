import { TemplateActivityTypes } from '@infield/features/activities';
import { currentChecklistAtom } from '@infield/features/checklist/state';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { Sidebar } from '@infield/features/sidebar/sidebar';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { type FunctionComponent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { IsSearchOpenAtom } from '../state';

import { TopBarMobileContent } from './top-bar-mobile-content';

type Props = {
  showSearch?: boolean;
  darkMode?: boolean;
};

export const TopBarMobile: FunctionComponent<Props> = ({
  showSearch = true,
  darkMode = false,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mainTopbar);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const [expandOverflow, setExpandOverflow] = useState(false);
  const setIsSearchOpen = useSetRecoilState(IsSearchOpenAtom);
  const checklist = useRecoilValue(currentChecklistAtom);
  const { isOnline } = useNetworkStatusContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentPage = pathname.split('/').pop();
  const getPageTitle = () => {
    if (currentPage === 'checklist') {
      return checklist?.title;
    }
    if (currentPage === 'checklists') {
      return t('TOP_BAR_NAVIGATION_CHECKLISTS', 'Checklists');
    }
    return undefined;
  };

  const isTitleClickable = Boolean(
    checklist &&
      checklist.sourceId &&
      checklist.type !== TemplateActivityTypes.round
  );

  const handleOnTitleClick = () => {
    // checklist.sourceId is externalId of activity
    if (isTitleClickable && checklist?.sourceId) {
      navigate(`/activity/${encodeURIComponent(checklist.sourceId)}/details`);
    }

    setExpandOverflow((prev) => !prev);
  };

  const handleMenuButtonClick = () => {
    setIsSidebarCollapsed(false);
  };

  return (
    <>
      <TopBarMobileContent
        title={getPageTitle()}
        isTitleClickable={isTitleClickable}
        showSearch={showSearch}
        darkMode={darkMode}
        isInteractionDisabled={!isOnline}
        expandOverflow={expandOverflow}
        onSearchButtonClick={() => setIsSearchOpen(true)}
        onMenuButtonClick={handleMenuButtonClick}
        handleOnTitleClick={handleOnTitleClick}
      />
      <Sidebar
        isCollapsed={isSidebarCollapsed}
        setIsCollapsed={setIsSidebarCollapsed}
      />
    </>
  );
};
