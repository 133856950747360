import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const CardContainer = styled.div<{
  $isDesktop?: boolean;
}>`
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;

  & > * {
    height: 416px;
    overflow: hidden;
    flex: 1;
    &:nth-child(n + 3) {
      flex: 2;
    }
    min-width: ${({ $isDesktop }) => ($isDesktop ? '384px' : '288px')};
  }
`;
