import Konva from 'konva';

import { Container } from './Container';
import LayerContainer from './LayerContainer';
import { ContainerConfig } from './types';

export default class ForegroundLayerContainer extends LayerContainer {
  private containerById: Map<string, Container> = new Map<string, Container>();
  private containerConfigs: ContainerConfig[] = [];

  public constructor({ name }: { name: string }) {
    super({ name });
  }

  public setContainerConfigs(containerConfigs: ContainerConfig[]): void {
    this.containerConfigs = containerConfigs;
  }

  public override removeChildren(): Konva.Layer {
    this.containerById.forEach((container) => {
      container.getNode().remove();
    });
    this.containerById.clear();
    return super.removeChildren();
  }

  public override addChild(child: Container): void {
    super.addChild(child);
    this.containerById.set(child.id, child);
    // We directly call `onChildReadyStateChange` right after adding an
    // *unrendered* child container to the layout: This is so that the
    // `isReady` props of the layout and the parent layer are immediately
    // updated to their correct values. Otherwise, when adding a new
    // (unrendered) child, `isReady` of the current layout may not be updated
    // to the correct/expected value until the child container emits an
    // ON_READY_STATE_CHANGE (e.g., when the child container is done
    // rendering)
    this.onChildReadyStateChange();

    const containerConfig = this.containerConfigs.find(
      ({ id }) => id === child.id
    );
    if (containerConfig === undefined) {
      // eslint-disable-next-line no-console
      console.warn('Container config not found for child', child);
      return;
    }
    child.getNode().position({
      x: containerConfig.x ?? 0,
      y: containerConfig.y ?? 0,
    });
  }
}
