import { CogniteEvent } from '@cognite/sdk';

const getEventTableTitle = (event: CogniteEvent): string => {
  let title = '';
  if (event.type) {
    title += event.type;
    if (event.externalId) {
      title += ': ';
    }
  }
  if (event.externalId) {
    title += event.externalId;
  }
  return title;
};

export default getEventTableTitle;
