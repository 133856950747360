import type { Timeseries } from '@cognite/sdk-beta';

import { HIGHLIGHTED_METADATA_NAME } from '../hooks';

export const getHighlightedTimeseries = (
  timeseries: Timeseries[] | undefined
) =>
  timeseries?.filter(
    ({ metadata }) => metadata?.[HIGHLIGHTED_METADATA_NAME] === 'true'
  );

export const getOtherTimeseries = (timeseries: Timeseries[] | undefined) =>
  timeseries?.filter(
    ({ metadata }) => metadata?.[HIGHLIGHTED_METADATA_NAME] !== 'true'
  );
