import {
  Body,
  Button,
  DeleteIcon,
  Flex,
  Heading,
  Row,
} from '@cognite/cogs.js-v10';
import type { ModelAndRevision3D } from '@infield/features/3d';
import { ModelDetails } from '@infield/features/3d';
import {
  useAppConfigQuery,
  useUpsertAppConfigMutation,
} from '@infield/features/app-config/hooks';
import type {
  AppConfig,
  ThreeDConfiguration,
} from '@infield/features/app-config/types';
import { getIsNewConfigVersion } from '@infield/features/app-config/utils/utils';
import { useEffect, useState } from 'react';
import type { FC } from 'react';

import * as S from './elements';
import { SelectModelDialogue } from './select-model-dialogue';

interface Props {
  hasAppConfigPermission: boolean;
  rootLocationExternalId: string;
}

export const RootLocationConfig3d: FC<Props> = ({
  hasAppConfigPermission,
  rootLocationExternalId,
}) => {
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();

  const { data: appConfig, isSuccess: isConfigLoadingSuccess } =
    useAppConfigQuery();

  const isNewConfigVersion = getIsNewConfigVersion(appConfig);

  const [showSelectModelDialogue, setShowSelectModelDialogue] = useState(false);
  const [isEditingLightWeightModel, setIsEditingLightWeightModel] =
    useState(false);

  const [threeDConfigLocal, setThreeDConfigLocal] =
    useState<ThreeDConfiguration>({ fullWeightModels: [] });

  const savedRootLocationConfig =
    appConfig?.featureConfiguration?.rootLocationConfigurations;

  useEffect(() => {
    if (isConfigLoadingSuccess) {
      const threeDConfig = savedRootLocationConfig?.find(
        (rootLocation) =>
          rootLocationExternalId ===
          (isNewConfigVersion
            ? rootLocation.externalId
            : rootLocation.assetExternalId)
      )?.threeDConfiguration;

      if (threeDConfig) setThreeDConfigLocal(threeDConfig);
    }
  }, [
    savedRootLocationConfig,
    isConfigLoadingSuccess,
    rootLocationExternalId,
    isNewConfigVersion,
  ]);

  const handleAddThreeDModel = ({
    revisionId,
    modelId,
  }: ModelAndRevision3D) => {
    setThreeDConfigLocal((prevThreeDModel) => {
      if (isEditingLightWeightModel)
        return {
          ...prevThreeDModel,
          lightWeightModel: {
            revisionId,
            modelId,
          },
        };

      return {
        ...prevThreeDModel,
        fullWeightModels: [
          ...prevThreeDModel.fullWeightModels,
          {
            revisionId,
            modelId,
          },
        ],
      };
    });
    setShowSelectModelDialogue(false);
    setIsEditingLightWeightModel(false);
  };

  const handleDeleteThreeDModel = (index: number) => {
    setThreeDConfigLocal((prevThreeDModel) => ({
      ...prevThreeDModel,
      fullWeightModels: [
        ...prevThreeDModel.fullWeightModels.slice(0, index),
        ...prevThreeDModel.fullWeightModels.slice(index + 1),
      ],
    }));
  };

  const handleDeleteLightWeightModel = () => {
    setThreeDConfigLocal((prevThreeDModel) => ({
      ...prevThreeDModel,
      lightWeightModel: undefined,
    }));
  };

  const handleSave = async () => {
    if (!appConfig) return;

    const newAppConfig: AppConfig = {
      ...appConfig,
      featureConfiguration: {
        ...appConfig.featureConfiguration,
        rootLocationConfigurations: [
          ...(savedRootLocationConfig?.map((rootLocationConfig) => {
            if (
              rootLocationExternalId ===
              (isNewConfigVersion
                ? rootLocationConfig.externalId
                : rootLocationConfig.assetExternalId)
            ) {
              return {
                ...rootLocationConfig,
                threeDConfiguration: { ...threeDConfigLocal },
              };
            }
            return { ...rootLocationConfig };
          }) || []),
        ],
      },
    };

    await upsertAppConfig({
      newAppConfig,
    });
  };

  const hasConfigChanged =
    JSON.stringify(threeDConfigLocal) !==
    JSON.stringify(
      savedRootLocationConfig?.find(
        (rootLocation) =>
          rootLocationExternalId ===
          (isNewConfigVersion
            ? rootLocation.externalId
            : rootLocation.assetExternalId)
      )?.threeDConfiguration
    );

  return (
    <S.ScrollableContainer>
      <S.Wrapper>
        <Flex gap={16} direction="column">
          {(isEditingLightWeightModel || showSelectModelDialogue) && (
            <SelectModelDialogue
              visible
              onClose={() => {
                setIsEditingLightWeightModel(false);
                setShowSelectModelDialogue(false);
              }}
              onSelect={handleAddThreeDModel}
            />
          )}

          <Flex gap={8} direction="column">
            <Heading level={5}>3d Configuration</Heading>
            <Body size="x-small">Configure 3d models for Root Locations</Body>
            <Flex direction="column" gap={16}>
              <Body size="x-small" strong>
                Lightweight Model
              </Body>
              <S.Card>
                {threeDConfigLocal.lightWeightModel && (
                  <ModelDetails
                    onClick={() => null}
                    {...threeDConfigLocal.lightWeightModel}
                  />
                )}
                <Button
                  disabled={!hasAppConfigPermission}
                  onClick={() => {
                    setIsEditingLightWeightModel(true);

                    setShowSelectModelDialogue(true);
                  }}
                >
                  {threeDConfigLocal.lightWeightModel
                    ? 'Change 3d Model'
                    : 'Add 3d Model'}
                </Button>
                {threeDConfigLocal.lightWeightModel && (
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteLightWeightModel()}
                  >
                    Delete
                  </Button>
                )}
              </S.Card>

              <Body size="x-small" strong>
                Full weight Model
              </Body>

              <Row auto={420}>
                {threeDConfigLocal.fullWeightModels.map((fullModel, index) => (
                  <S.Card key={fullModel.revisionId}>
                    <Flex gap={16} direction="column">
                      {/* fixme: onClick function */}
                      <ModelDetails onClick={() => null} {...fullModel} />

                      <Button
                        icon={<DeleteIcon />}
                        onClick={() => handleDeleteThreeDModel(index)}
                      >
                        Delete
                      </Button>
                    </Flex>
                  </S.Card>
                ))}
              </Row>

              <S.Add3dModelButton
                disabled={!hasAppConfigPermission}
                onClick={() => setShowSelectModelDialogue(true)}
              >
                Add 3d Model
              </S.Add3dModelButton>
            </Flex>
          </Flex>
          <Flex direction="column" gap={4}>
            <S.SaveButton onClick={handleSave} disabled={!hasConfigChanged}>
              Save configuration
            </S.SaveButton>
          </Flex>
        </Flex>
      </S.Wrapper>
    </S.ScrollableContainer>
  );
};
