import type { Checklist, ChecklistStatus } from '@cognite/apm-client';
import { Flex, TagChip } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

import { CHECKLIST_STATUS_OPTIONS } from '../consts';

type Props = {
  checklists: Checklist[];
};

export const ChecklistGroupStatusCell: FC<Props> = ({ checklists }) => {
  if (!checklists || checklists.length === 0) {
    return null;
  }

  const statusCount = checklists
    .map((checklist) => checklist.status || 'Ready')
    .reduce(
      (acc, curr) => {
        return { ...acc, [curr]: acc[curr] + 1 };
      },
      { Ready: 0, 'In progress': 0, Done: 0 } as Record<ChecklistStatus, number>
    );

  return (
    <Flex gap={4}>
      {CHECKLIST_STATUS_OPTIONS.map(({ value: status }) => {
        if (!statusCount[status] || statusCount[status] === 0) return null;

        return (
          <TagChip
            size="small"
            key={status}
            label={`${status} (${statusCount[status]})`}
          />
        );
      })}
    </Flex>
  );
};
