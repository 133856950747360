import UnifiedViewerRenderer from '../../UnifiedViewerRenderer';
import { LineType, ShapeAnchorPoint, Vertex } from '../types';

import getElbowedConnection from './getElbowedConnection';
import getRightAngleConnection from './getRightAngleConnection';
import getStraightLineConnection from './getStraightLineConnection';

const getConnectionPoints = (
  lineType: LineType,
  fromId: string | undefined,
  toId: string | undefined,
  anchorStartTo: ShapeAnchorPoint | undefined,
  anchorEndTo: ShapeAnchorPoint | undefined,
  vertices: Vertex[] | undefined,
  unifiedViewerRenderer: UnifiedViewerRenderer
): number[] | undefined => {
  if (lineType === LineType.RIGHT_ANGLES) {
    if (fromId === undefined || toId === undefined) {
      throw new Error(
        'fromId and toId must be defined for right angle connections'
      );
    }

    return getRightAngleConnection(fromId, toId, unifiedViewerRenderer);
  }

  if (lineType === LineType.ELBOWED) {
    if (fromId === undefined || toId === undefined) {
      throw new Error(
        'fromId and toId must be defined for elbowed connections'
      );
    }
    return getElbowedConnection(
      fromId,
      toId,
      anchorStartTo,
      anchorEndTo,
      unifiedViewerRenderer
    );
  }

  return getStraightLineConnection(
    fromId,
    toId,
    vertices,
    unifiedViewerRenderer
  );
};

export default getConnectionPoints;
