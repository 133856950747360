import { Body, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Wrapper = styled(Flex).attrs({
  justifyContent: 'center',
})`
  padding-top: 16px;
`;

export const Img = styled.img`
  width: 100%;
  max-width: 365px;
  padding: 0 15px;
  margin-bottom: 25px;

  @media (min-width: 992px) {
    max-width: 700px;
  }
`;

export const EmptyText = styled(Body).attrs(() => ({ size: 'medium' }))`
  text-align: center;
  color: ${({ theme }) => theme['text-icon--muted']};
`;

export const ExtrasWrapper = styled.div`
  margin: 20px;
`;
