import { Body, Flex } from '@cognite/cogs.js-v10';
import type { Step } from '@infield/features/observation';
import type { FC, PropsWithChildren } from 'react';

import { RequiredLabel } from '../elements';

import * as S from './elements';

interface Props {
  step: Step;
}

export const ObservationDetailsStepInfo: FC<PropsWithChildren<Props>> = ({
  step,
}) => {
  return (
    <Flex direction="column" gap={4}>
      <Body size="large" strong>
        {step.name}
        {step.isRequired && <RequiredLabel>*</RequiredLabel>}
      </Body>
      {step.description && (
        <S.DescriptionBody size="small">{step.description}</S.DescriptionBody>
      )}
    </Flex>
  );
};
