import Konva from 'konva';
import {
  FOCUS_COMMAND,
  KEY_DOWN_COMMAND,
  COMMAND_PRIORITY_LOW,
  type LexicalEditor,
} from 'lexical';

import createEditableTextFromNode from '../../utils/createEditableTextFromNode';
import createPlaceholderElementFromNode from '../../utils/createPlaceholderElementFromNode';
import createTextContainerDivFromNode from '../../utils/createTextContainerDivFromNode';
import doesStringContainComposedCharacters from '../../utils/doesStringContainComposedCharacters';
import createLexicalEditorAtDomNode from '../../utils/lexical/createLexicalEditorAtDomNode';
import setStyles from '../../utils/setStyles';

import findMaxFontSizeWithoutOverflow from './findMaxFontSizeWithoutOverflow';

const createEditableTextElements = (
  node: Konva.Label,
  getScaleFn: () => number
): {
  container: HTMLDivElement;
  editor: LexicalEditor;
  placeholder: HTMLElement;
} => {
  const sizeAndPadding = { ...node.size(), padding: node.getText().padding() };

  const scaleFactor = getScaleFn();
  const container = createTextContainerDivFromNode(node, scaleFactor);
  const editableText = createEditableTextFromNode(node, scaleFactor);
  editableText.className = 'editable-text';
  const placeholder = createPlaceholderElementFromNode(
    node,
    scaleFactor,
    sizeAndPadding
  );
  placeholder.className = 'editable-text-placeholder';
  const editor = createLexicalEditorAtDomNode(editableText, {
    initialText: node.getText().text(),
    shouldWrapText: true,
    shouldDisablePlaceholder: true,
  });

  setStyles(
    'sticky-tool-styles',
    `.editable-text:empty + .editable-text-placeholder {
      display: flex !important;
    }
    .editable-text:has(p):has(br:only-of-type):not(:has(span)) + .editable-text-placeholder {
      display: flex !important;
    }
    .editable-text-placeholder {
      display: none !important;
    }`
  );

  const refreshFontSize = () => {
    const newScale = getScaleFn();
    const fontSize = findMaxFontSizeWithoutOverflow(
      editableText.innerText,
      sizeAndPadding
    );
    editableText.style.fontSize = `${newScale * fontSize}px`;
  };

  // This tracks if the user is composing a word in for instance Japanese or Chinese.
  let isComposing = false;
  editableText.addEventListener('compositionstart', () => {
    isComposing = true;
  });
  editableText.addEventListener('compositionend', () => {
    isComposing = false;
  });

  editor.registerCommand(
    KEY_DOWN_COMMAND,
    (event) => {
      if (
        event.key === 'Enter' &&
        doesStringContainComposedCharacters(editableText.innerText)
      ) {
        // Enter is used to complete make a word selection for Japanese keyboard. Upon word selection
        // we can refresh the font size. Otherwise, we want to skip refreshing the font size
        // if the user is typing a string containing composed characters.
        refreshFontSize();
      }
      return false;
    },
    COMMAND_PRIORITY_LOW
  );

  editor.registerCommand(
    FOCUS_COMMAND,
    () => {
      refreshFontSize();
      return false;
    },
    COMMAND_PRIORITY_LOW
  );

  editor.registerUpdateListener(() => {
    if (
      isComposing ||
      doesStringContainComposedCharacters(editableText.innerText)
    ) {
      return;
    }
    refreshFontSize();
  });

  container.appendChild(editableText);
  container.appendChild(placeholder);

  return { container, editor, placeholder };
};

export default createEditableTextElements;
