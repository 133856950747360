import type { Checklist } from '@cognite/apm-client';
import { Flex, TagChip, ToolsIcon, UserIcon } from '@cognite/cogs.js-v10';
import uniq from 'lodash/uniq';
import type { FC } from 'react';

interface Props {
  checklists?: Checklist[];
}

export const AssigneeCell: FC<Props> = ({ checklists }) => {
  if (!checklists || checklists.length === 0) {
    return null;
  }

  const assignedTo = checklists
    .map((checklist) => checklist.assignedTo || [])
    .flat();

  const assigneeDisciplines = uniq(
    assignedTo.filter((assignee) => assignee.startsWith('discipline:'))
  );
  const assigneeUsers = uniq(
    assignedTo.filter((assignee) => !assignee.startsWith('discipline:'))
  );

  if (assignedTo.length > 0)
    return (
      <Flex gap={8}>
        {assigneeDisciplines && assigneeDisciplines.length > 0 && (
          <TagChip
            icon={<ToolsIcon />}
            label={assigneeDisciplines.length.toString()}
            size="small"
          />
        )}

        {assigneeUsers && assigneeUsers.length > 0 && (
          <TagChip
            icon={<UserIcon />}
            label={assigneeUsers.length.toString()}
            size="small"
          />
        )}
      </Flex>
    );

  return null;
};
