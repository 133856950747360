import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { I18nWrapper } from '@cognite/cdf-i18n-utils';
import { ToastContainer } from '@cognite/cogs-lab';

import { translations } from './i18n';

export const AiProvider: React.FC<{
  children?: React.ReactNode;
  customQueryClient?: QueryClient;
}> = ({ children, customQueryClient }) => {
  return (
    <QueryClientProvider client={customQueryClient || queryClient}>
      <I18nWrapper translations={translations}>
        <ToastContainer />
        {children}
      </I18nWrapper>
    </QueryClientProvider>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});
