// We provide two strategies for including the PDFJS dependency.
//
// Each strategy is responsible for dynamically importing the main PDFJS module.
// This is required because PDFJS is itself distributed as a webpacked bundle
// that includes its own async loading of a wasm module (as of v4) which breaks
// the SystemJS import.
//
// In addition, each strategy is responsible for configuring PDFJS to load its
// worker script and character map data. This is done by wrapping the module
// with a proxy that configures the `getDocument` method.
//
// A strategy is chosen by importing from the root level of this package:
// * `index.bundled.ts` (default)
// * `index.hosted.ts` (for Fusion)

export type {
  PDFDocumentLoadingTask,
  PDFDocumentProxy,
} from 'pdfjs-dist/types/src/display/api';

export let importPDFJS = async (): Promise<typeof import('pdfjs-dist')> => {
  throw new Error('PDFJS not configured');
};

export const setImportPDFJS = (importer: typeof importPDFJS) => {
  importPDFJS = importer;
};
