import { AnnotationType } from '../../annotations/types';
import { ANCHOR_STYLE } from '../Anchor/constants';

export const PREVIEW_NODE_OFFSET_PX = 100;

export const PREVIEW_ARROW_CONFIG = {
  name: 'preview-arrow',
  annotationType: AnnotationType.POLYLINE,
  stroke: ANCHOR_STYLE.fill,
  opacity: 0.4,
  strokeWidth: 10,
  pointerAtEnding: true,
  listening: false,
  x: 0,
  y: 0,
};

export const MIN_MOUSE_DELTA_PX = 20;

export const ANCHOR_CURSOR = 'pointer';
