import {
  CertificateIcon,
  DocumentationIcon,
  HelpIcon,
  LogoutIcon,
  SpeakerphoneIcon,
  UserIcon,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { type FC } from 'react';

import * as S from './elements';

type Props = {
  isOnline: boolean;
  userName?: string;
  showHelpMenu: boolean;
  isAkerbpCustomCode: boolean;
  onUserProfileClick: () => void;
  onUserLogout: () => void;
  setShowHelpMenu: (showHelpMenu: boolean) => void;
};

export const SidebarUserActions: FC<Props> = ({
  isOnline,
  userName,
  showHelpMenu,
  isAkerbpCustomCode,
  onUserProfileClick,
  onUserLogout,
  setShowHelpMenu,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.sidebar);

  return !showHelpMenu ? (
    <S.SidebarUserActionsWrapper>
      {isAkerbpCustomCode && (
        <S.SidebarActionButton
          icon={<HelpIcon />}
          aria-label="sidebar-mobile-help"
          data-testid="sidebar-mobile-help"
          disabled={!isOnline}
          onClick={() => setShowHelpMenu(true)}
        >
          {t('SIDEBAR_HELP_BUTTON', 'Help and resources')}
        </S.SidebarActionButton>
      )}
      <S.SidebarActionButton
        icon={<UserIcon />}
        aria-label="sidebar-mobile-user-profile"
        disabled={!isOnline}
        onClick={onUserProfileClick}
      >
        {userName}
      </S.SidebarActionButton>
      <S.SidebarActionButton
        icon={<LogoutIcon />}
        disabled={!isOnline}
        onClick={onUserLogout}
      >
        {t('SIDEBAR_USER_SIGN_OUT', 'Sign out')}
      </S.SidebarActionButton>
    </S.SidebarUserActionsWrapper>
  ) : (
    <div>
      <S.SidebarActionButton
        aria-label="view-documentation"
        data-testid="view-documentation-button"
        icon={<DocumentationIcon />}
        onClick={() =>
          window
            .open('https://docs.cognite.com/infield_vnext/', '_blank')
            ?.focus()
        }
      >
        {t('TOPBAR-HELP-BUTTON-VIEW-DOCUMENTATION', 'View documentation')}
      </S.SidebarActionButton>
      <S.SidebarActionButton
        aria-label="whats-new"
        data-testid="whats-new-button"
        icon={<SpeakerphoneIcon />}
        onClick={() =>
          window
            .open('https://docs.cognite.com/cdf/whatsnew/', '_blank')
            ?.focus()
        }
      >
        {t('TOPBAR-HELP-BUTTON-WHATS-NEW', "What's new")}
      </S.SidebarActionButton>
      <S.SidebarActionButton
        aria-label="view-academy"
        data-testid="view-academy-button"
        icon={<CertificateIcon />}
        onClick={() =>
          window
            .open(
              'https://learn.cognite.com/path/working-with-cognite-infield',
              '_blank'
            )
            ?.focus()
        }
      >
        {t('TOPBAR-HELP-BUTTON-ACADEMY', 'Learn at the Academy')}
      </S.SidebarActionButton>
    </div>
  );
};
