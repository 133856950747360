import { APMAppDataService } from '../apm-app-data-service';
import type { ServiceContract } from '../types';

import type { ObservationConfig } from './types';

export class ObservationConfigService extends APMAppDataService<ObservationConfig> {
  constructor(contract: ServiceContract) {
    super(contract, 'ObservationConfig', 'v1');
  }
}
