import { ComputationStep } from '@cognite/calculation-backend';

import { ChartTimeSeries, CoreTimeseries } from '../types';

/**
 * This will be unnecessary if we change to use tsExternalId
 * as 'value' for timeseries sources in getSourceOption
 *
 * The reason for not doing it straight away is that I
 * didn't want to break the existing charts/calculations
 */
export const resolveTimeseriesSourceInSteps = (
  steps: ComputationStep[] = [],
  sources: (ChartTimeSeries | CoreTimeseries)[] = []
): ComputationStep[] => {
  return steps.map((step) => {
    return {
      ...step,
      inputs: step.inputs.map((input) => {
        if (input.type === 'ts') {
          const source = sources.find(({ id }) => id === input.value);
          if (!source) return input;

          const value =
            'nodeReference' in source
              ? {
                  space: source.nodeReference.space,
                  external_id: source.nodeReference.externalId,
                }
              : source?.tsExternalId || '';

          return {
            ...input,
            value,
          };
        }

        return input;
      }),
    };
  }) as ComputationStep[];
};
