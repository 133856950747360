import type { UserPreferenceLocation } from '@cognite/apm-client';
import { Select } from '@cognite/cogs-lab';
import { Body, Flex } from '@cognite/cogs.js-v10';
import { TabContent } from '@cognite/user-profile-components';
import { selectedActivityAtom } from '@infield/features/activities';
import { useLocations } from '@infield/features/app-config/hooks';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { selectedTemplateAtom } from '@infield/features/template';
import type { FC } from 'react';
import { useSetRecoilState } from 'recoil';

export const LocationTabContent: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mainTopbar);

  const setOpenChecklistId = useSetRecoilState(selectedActivityAtom);
  const setSelectedTemplate = useSetRecoilState(selectedTemplateAtom);

  const {
    configuredRootLocationsOptions,
    selectedRootLocation,
    handleSaveLocation,
  } = useLocations();

  const handleRootLocationChange = async (
    newRootLocation: UserPreferenceLocation
  ) => {
    await handleSaveLocation(newRootLocation);
    setOpenChecklistId('');
    setSelectedTemplate(undefined);
    window.location.reload();
  };

  return (
    <TabContent.Container>
      <TabContent.Body>
        <Flex direction="column" gap={6}>
          <Body size="medium" strong>
            {t('selectLocation', 'Select location')}
          </Body>
          <Select
            fullWidth
            placeholder={t('selectLocation', 'Select location')}
            onChange={(_, value) =>
              value ? handleRootLocationChange(value) : null
            }
            disabled={!configuredRootLocationsOptions}
            options={configuredRootLocationsOptions}
            value={selectedRootLocation?.value}
          />
        </Flex>
      </TabContent.Body>
    </TabContent.Container>
  );
};
