import type { Observation } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useAuthContext } from '@cognite/e2e-auth';
import {
  useFeatureToggleConfig,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import {
  sendFilesToSAP,
  sendObservationsToSAP,
} from '../observation-write-back-service';
import type { SapObservation, SapRequestItem } from '../types';

import { useObservationsUpsertMutation } from './use-mutation';

// In useSendObservationsToSap hook:
// - There are two functions to trigger the new write-back service: sendObservationsToSAP and sendFilesToSAP.
// - We first send observations to SAP, then use the returned sourceId to upload files to the notification.
// - If only 'File not sent' observations are present, trigger sendFilesToSAP and skip sendObservationsToSAP.
// - If both 'File not sent' and 'Completed' observations are present, first send 'Completed' ones with sendObservationsToSAP,
//   then send files for both types with sendFilesToSAP (This approach simplifies error/success toast messages for users).
export const useSendObservationsToSap = () => {
  const { assetService } = useFDMServices();
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);
  const queryClient = useQueryClient();
  const { client } = useAuthContext();
  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );

  const observationToggles = rootLocationFeatureToggles?.observations;
  const { mutateAsync: upsertObservations } = useObservationsUpsertMutation();
  const [isSending, setIsSending] = useState(false);
  let hasError = false;

  const sendMediaToSAP = async (
    fileRequests: SapRequestItem[],
    observationsToSend: SapObservation[]
  ) => {
    const resultObservationsWithFailedMediaRequest: SapRequestItem[] = [];
    const resultObservationsWithSuccessMediaRequest: SapRequestItem[] = [];
    await Promise.allSettled(
      fileRequests.map(async (requestItem) => {
        await sendFilesToSAP(
          client,
          observationsToSend,
          requestItem,
          observationToggles?.attachmentsEndpointExternalId
        )
          .then(() => {
            resultObservationsWithSuccessMediaRequest.push(requestItem);
          })
          .catch(() => {
            hasError = true;
            resultObservationsWithFailedMediaRequest.push(requestItem);
          });
      })
    );
    if (resultObservationsWithSuccessMediaRequest.length > 0) {
      upsertObservations({
        observations: resultObservationsWithSuccessMediaRequest.map(
          (successItem) => ({
            externalId: successItem.key,
            sourceId: successItem.sapObjectId,
            status: 'Sent',
          })
        ),
      });
    }
    if (resultObservationsWithFailedMediaRequest.length > 0) {
      upsertObservations({
        observations: resultObservationsWithFailedMediaRequest.map(
          (failedItem) => ({
            externalId: failedItem.key,
            sourceId: failedItem.sapObjectId,
            status: 'File not sent',
          })
        ),
      });
      makeToast({
        type: 'warning',
        body: t(
          'OBSERVATION_WRITE_BACK_FILES_NOT_SENT_TOAST',
          'Observation sent but not the attached files, try again',
          { count: resultObservationsWithFailedMediaRequest.length }
        ),
      });
    }
  };

  const sendToSap = async (
    observations: Observation[] = [],
    onSuccess?: (sourceId?: string) => void
  ) => {
    setIsSending(true);
    const assetExternalIds = observations
      .map(({ asset }) => asset?.externalId)
      .filter(Boolean) as string[];
    const assets = await assetService.getLocationAssetsByExternalIds(
      assetExternalIds
    );

    const observationsToSend = observations.map(({ asset, ...observation }) => {
      const correspondingAsset = assets.find(
        ({ externalId }) => externalId === asset?.externalId
      );
      return {
        ...observation,
        asset: correspondingAsset || null,
      };
    }) as SapObservation[];

    const {
      observationsWithFailedFile,
      otherObservations,
      observationsWithNoFiles,
    } = observationsToSend.reduce(
      (
        acc: {
          observationsWithFailedFile: SapObservation[];
          otherObservations: SapObservation[];
          observationsWithNoFiles: string[];
        },
        observation
      ) => {
        if (observation.status === 'File not sent') {
          acc.observationsWithFailedFile.push(observation);
        } else {
          acc.otherObservations.push(observation);
        }
        if (!observation.files || observation.files.length === 0) {
          acc.observationsWithNoFiles.push(observation.externalId);
        }
        return acc;
      },
      {
        observationsWithFailedFile: [],
        otherObservations: [],
        observationsWithNoFiles: [],
      }
    );

    let sourceIdForSuccessMessage: string | undefined;

    // We only run this if user sends only 'File not sent' observations
    if (
      observationsWithFailedFile.length > 0 &&
      otherObservations.length === 0
    ) {
      const previouslyFailedFileRequests = observationsWithFailedFile.map(
        ({ externalId, sourceId }) => ({
          key: externalId,
          sapObjectId: sourceId!,
        })
      );
      await sendMediaToSAP(
        previouslyFailedFileRequests,
        observationsWithFailedFile
      );
      setIsSending(false);
      queryClient.refetchQueries({
        queryKey: [QueryKeys.OBSERVATION_INFINITE],
        type: 'all',
      });
      if (onSuccess && !hasError) {
        onSuccess();
      }
    }

    if (otherObservations.length > 0) {
      await sendObservationsToSAP(
        client,
        observationToggles?.notificationsEndpointExternalId,
        otherObservations
      )
        .then(async (result) => {
          sourceIdForSuccessMessage =
            otherObservations.length === 1
              ? result.request[0].sapObjectId
              : undefined;
          const previouslyFailedFileRequests = observationsWithFailedFile.map(
            ({ externalId, sourceId }) => ({
              key: externalId,
              sapObjectId: sourceId!,
            })
          );

          const observationsWithFiles = result.request.filter(
            ({ key: observationExternalId }) =>
              !observationsWithNoFiles.includes(observationExternalId)
          );
          const observationsWithoutFiles = result.request.filter(
            ({ key: observationExternalId }) =>
              observationsWithNoFiles.includes(observationExternalId)
          );

          // Update observationsWithoutFiles with 'Sent' status since we don't need to follow another step of sendMediaToSAP for them
          if (observationsWithoutFiles.length > 0) {
            upsertObservations({
              observations: observationsWithoutFiles.map((successItem) => ({
                externalId: successItem.key,
                sourceId: successItem.sapObjectId,
                status: 'Sent',
              })),
            });
          }

          const allFileRequests = observationsWithFiles.concat(
            previouslyFailedFileRequests
          );
          await sendMediaToSAP(allFileRequests, observationsToSend);
        })
        .catch(() => {
          setIsSending(false);
          hasError = true;
          upsertObservations({
            observations: otherObservations.map((observation) => ({
              externalId: observation.externalId,
              status: 'Not sent',
            })),
          });
          makeToast({
            type: 'danger',
            body: t(
              'OBSERVATION_WRITE_BACK_OBSERVATION_NOT_SENT_TOAST',
              'Observation not sent, try again',
              { count: otherObservations.length }
            ),
          });
        })
        .finally(() => {
          setIsSending(false);
          queryClient.invalidateQueries([QueryKeys.OBSERVATION_INFINITE]);
          if (onSuccess && !hasError) {
            onSuccess(sourceIdForSuccessMessage);
          }
        });
    }
  };

  return {
    sendToSap,
    isSending,
  };
};
