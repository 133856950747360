import '@cognite/cogs-lab/dist/cogs-lab.css';
import '@cognite/cogs.js-v10/dist/cogs.css';
import 'allotment/dist/style.css';

import { setupMetrics } from '@infield/features/metrics';
import { setupSentry } from '@infield/features/sentry';
import { Routes } from '@infield/pages/routes';
import { AppProviders } from '@infield/providers/app-providers';

setupMetrics();
setupSentry();

const App = () => (
  <AppProviders>
    <Routes />
  </AppProviders>
);
export default App;
