import {
  ADSLocationDeleteModal,
  ADSLocationEditModal,
  ADSLocationList,
} from '@cognite/apm-config';
import type {
  LocationFilterDTO,
  LocationFilterUpsertRequestDTO,
} from '@cognite/apm-config';
import { PageHeader } from '@cognite/cogs-lab';
import { AddLargeIcon, Button } from '@cognite/cogs.js-v10';
import type { FC } from 'react';
import { useState } from 'react';

import { LocationView } from './location-config';
import {
  useADSDeleteLocation,
  useADSLocationConfigQuery,
  useADSUpsertLocation,
} from './location-config/hooks';

export const ConfigView: FC = () => {
  const [showNewLocationModal, setShowNewLocationModal] = useState(false);
  const [locationInEdit, setLocationInEdit] =
    useState<LocationFilterDTO | null>(null);
  const [locationInDelete, setLocationInDelete] = useState<LocationFilterDTO>();
  const [selectedLocation, setSelectedLocation] = useState<LocationFilterDTO>();

  const { data: locationConfig } = useADSLocationConfigQuery();

  const selectedLocationData = locationConfig?.find(
    (location) => location.id === selectedLocation?.id
  );

  const { mutateAsync: upsertADSLocation } = useADSUpsertLocation();
  const { mutateAsync: deleteADSLocation } = useADSDeleteLocation();

  const handleSaveLocation = async (
    location: LocationFilterUpsertRequestDTO
  ) => {
    await upsertADSLocation({ nextLocation: location });
    setShowNewLocationModal(false);
  };

  const handleEditLocation = (location: LocationFilterDTO) => {
    setLocationInEdit(location);
    setShowNewLocationModal(true);
  };

  const handleDeleteButtonPress = async (location: LocationFilterDTO) => {
    setLocationInDelete(location);
  };

  const handleDelete = async (location: LocationFilterDTO) => {
    await deleteADSLocation({ location });
  };

  const handleAddLocation = () => {
    setLocationInEdit(null);
    setShowNewLocationModal(true);
  };

  const handleSelectLocation = (location: LocationFilterDTO) => {
    setSelectedLocation(location);
  };

  return (
    <>
      {selectedLocation === undefined ? (
        <>
          <PageHeader fullWidth>
            <PageHeader.TitleArea
              description="Manage locations across the industry applications"
              title="Locations"
            />
            <PageHeader.Actions>
              <Button
                type="primary"
                icon={<AddLargeIcon />}
                onClick={handleAddLocation}
              >
                Add location
              </Button>
            </PageHeader.Actions>
          </PageHeader>
          <ADSLocationList
            locations={locationConfig ?? []}
            onEdit={handleEditLocation}
            onDelete={handleDeleteButtonPress}
            onSelectLocation={handleSelectLocation}
          />
          <ADSLocationEditModal
            visible={showNewLocationModal}
            location={locationInEdit ?? undefined}
            onClose={() => setShowNewLocationModal(false)}
            onSave={handleSaveLocation}
          />
          <ADSLocationDeleteModal
            visible={Boolean(locationInDelete)}
            location={locationInDelete}
            onClose={() => setLocationInDelete(undefined)}
            onConfirm={handleDelete}
          />
        </>
      ) : (
        <LocationView
          selectedLocation={selectedLocationData}
          onNavigateToLocationList={() => setSelectedLocation(undefined)}
        />
      )}
    </>
  );
};
