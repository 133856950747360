import type { DataGridColumn } from '@cognite/apm-observation';
import type { GridFilterOptionsType } from '@cognite/cogs-lab';

import { getColumnFromViewFieldInfo } from '../activity-planning';
import type { FieldInfo } from '../hooks/use-query/types';

type CustomerSpecificColumnProps = {
  activityFields: FieldInfo[];
  standardColumnNames: string[];
  fetchMode: 'list' | 'search';
  isLoadingFilterLocationOptions: boolean;
  filterOptions: Record<string, GridFilterOptionsType[]>;
  gridFilterActivityTypeOptions: GridFilterOptionsType[];
  locationFilterOptions: GridFilterOptionsType[];
  handleTitleClick: DataGridColumn;
  handleLocationClick: DataGridColumn;
  handleLocationFilterSearch: (search: string) => void;
};

export const getCustomerSpecificColumns = ({
  activityFields,
  standardColumnNames,
  fetchMode,
  isLoadingFilterLocationOptions,
  filterOptions,
  gridFilterActivityTypeOptions,
  locationFilterOptions,
  handleTitleClick,
  handleLocationClick,
  handleLocationFilterSearch,
}: CustomerSpecificColumnProps) => {
  const customerSpecificColumns = activityFields
    .filter(
      ({ name }) =>
        name && !standardColumnNames.find((fieldName) => fieldName === name)
    )
    .map((field) => {
      let clickHandler;
      let customFilterOptions = filterOptions[field.name];
      let customFilterSearch;

      if (field.name === 'title') {
        clickHandler = handleTitleClick;
      }
      if (field.name === 'assetExternalId') {
        clickHandler = handleLocationClick;
        customFilterSearch = handleLocationFilterSearch;
        customFilterOptions = locationFilterOptions;
      }
      if (field.name === 'type') {
        customFilterOptions = gridFilterActivityTypeOptions;
      }
      return getColumnFromViewFieldInfo(
        field.label || field.name,
        field,
        fetchMode,
        customFilterOptions,
        isLoadingFilterLocationOptions,
        clickHandler,
        customFilterSearch
      );
    });

  return customerSpecificColumns;
};
