import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  width: 100%;
`;

export const ListItem = styled.li<{
  $isSelected?: boolean;
  $isClickable?: boolean;
}>`
  min-height: 58px;
  word-break: break-word;
  padding: 6px 16px;

  ${({ $isClickable }) => ($isClickable ? `cursor: pointer` : ``)};

  ${({ $isSelected, theme }) =>
    $isSelected
      ? `background-color: ${theme['surface--interactive--toggled-default']}`
      : `
      &:nth-child(2n) {
        background-color:  ${theme['surface--medium']}};
      }`};
`;
