import type { ChecklistItemStatus } from '@cognite/apm-client';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';

export const useChecklistItemStatusTranslation = (): Record<
  ChecklistItemStatus,
  string
> => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);

  return {
    Ok: t('ok', 'OK'),
    'Not ok': t('notOk', 'Not OK'),
    'Not applicable': t('notApplicable', 'Not applicable'),
    Blocked: t('blocked', 'Blocked'),
    'To do': t('toDo', 'To do'),
    Unknown: t('unknown', 'Unknown'),
  };
};
