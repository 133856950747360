import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
})`
  height: 100%;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme['surface--muted']};
  min-width: 320px;
`;

export const ChecklistList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  user-select: 'none';
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const EmptyStateContainer = styled(Flex)`
  justify-content: center;
  margin: 16px;
`;
