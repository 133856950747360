import { Button, ChevronDownIcon, Dropdown, Menu } from '@cognite/cogs.js-v10';
import type { CogniteClient, ViewReference } from '@cognite/sdk';
import { useQuery } from '@tanstack/react-query';
import groupBy from 'lodash/groupBy';
import { type FC, useState } from 'react';

type ViewSelectorProps = {
  sdk: CogniteClient;
  viewReference?: ViewReference;
  onViewReferenceChange: (viewReference?: ViewReference) => void;
  filterByInheritedView?: string;
};

export const ViewSelector: FC<ViewSelectorProps> = ({
  sdk,
  viewReference,
  onViewReferenceChange,
  filterByInheritedView,
}) => {
  const [ignoreInheritedViewFilter, setIgnoreInheritedViewFilter] =
    useState(false);
  const { data: views } = useQuery(['views'], () => {
    return sdk.views.list({
      allVersions: true,
      includeGlobal: true,
      includeInheritedProperties: true,
      limit: 1000,
    });
  });

  const viewsBySpace = groupBy(
    views?.items.filter((view) =>
      ignoreInheritedViewFilter
        ? true
        : view.implements?.some(
            (v) => v.externalId === filterByInheritedView
          ) ||
          (view.externalId === filterByInheritedView && view.isGlobal)
    ),
    (view) => view.space
  );
  const renderMenu = () => {
    return (
      <Menu style={{ maxHeight: 300, overflow: 'auto' }}>
        <Menu.Header>Space</Menu.Header>
        <Menu.Item
          hasCheckbox
          checkboxProps={{
            checked: ignoreInheritedViewFilter,
            onChange: () => {
              setIgnoreInheritedViewFilter(!ignoreInheritedViewFilter);
            },
          }}
        >
          Show all views
        </Menu.Item>
        {Object.keys(viewsBySpace).map((space) => (
          <Menu.Submenu
            key={space}
            content={
              <Menu style={{ maxHeight: 315, overflow: 'auto' }}>
                {viewsBySpace[space].map((view) => (
                  <Menu.Item
                    style={{ minWidth: 200 }}
                    key={view.externalId}
                    onClick={() => {
                      onViewReferenceChange({
                        space: view.space,
                        type: 'view',
                        version: view.version,
                        externalId: view.externalId,
                      });
                    }}
                  >
                    {view.externalId} / {view.version}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            {space}
          </Menu.Submenu>
        ))}
      </Menu>
    );
  };

  const renderReferenceName = () => {
    if (!viewReference) {
      return 'Select view';
    }
    return `${viewReference.space} / ${viewReference.externalId} / ${viewReference.version}`;
  };

  return (
    <div>
      <Dropdown content={renderMenu()}>
        <Button iconPlacement="right" icon={<ChevronDownIcon />}>
          {renderReferenceName()}
        </Button>
      </Dropdown>
    </div>
  );
};
