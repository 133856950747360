import { dequal as isEqual } from 'dequal';
import Konva from 'konva';
import { keyBy, pickBy } from 'lodash';

import {
  Annotation,
  isConnectionPolylineAnnotation,
  isRectangleAnnotation,
  isSingleDocumentAnnotation,
  LineType,
  PolylineAnnotation,
  SingleDocumentAnnotation,
} from '../../annotations/types';
import { ContainerConfig } from '../../containers/types';
import isNotUndefined from '../../utils/isNotUndefined';

import areConnectionPolylineVerticesDefined from './areConnectionPolylineVerticesDefined';
import hasAtLeastOneDependencyBeenRemoved from './hasAtLeastOneDependencyBeenRemoved';
import hasAtLeastOneDependencyBeenUpdated from './hasAtLeastOneDependencyBeenUpdated';
import haveBothDependenciesBeenRemoved from './haveBothDependenciesBeenRemoved';

type AnnotationAndNode = {
  annotation: Annotation;
  node: Konva.Node;
};

type AnnotationChangeSet = {
  annotationNodesToKeep: Konva.Node[];
  annotationAndNodesToUpdate: AnnotationAndNode[];
  annotationNodesToRemove: Konva.Node[];
  annotationsToAdd: Annotation[];
};

const isDirtyNode = (node: Konva.Node, dirtyNodes: Konva.Node[]) =>
  dirtyNodes.some((n) => n.id() === node.id());

const haveSingleDocumentAnnotationContainersChanged = (
  annotation: SingleDocumentAnnotation,
  previousContainerConfigsById: Record<string, ContainerConfig>,
  currentContainerConfigsById: Record<string, ContainerConfig>
) => {
  const { containerId } = annotation;
  const previousContainerConfig = previousContainerConfigsById[containerId];
  const currentContainerConfig = currentContainerConfigsById[containerId];
  return (
    previousContainerConfig !== undefined &&
    !isEqual(previousContainerConfig, currentContainerConfig)
  );
};

const hasCloudPropertyChanged = (
  annotation: Annotation,
  previousAnnotation: Annotation | undefined
) => {
  if (previousAnnotation === undefined) {
    return false;
  }
  return (
    isRectangleAnnotation(annotation) &&
    isRectangleAnnotation(previousAnnotation) &&
    annotation.style?.shouldApplyCloudTransform !==
      previousAnnotation.style?.shouldApplyCloudTransform
  );
};

const isDependantOnEverything = (polylineAnnotation: PolylineAnnotation) =>
  polylineAnnotation.style?.lineType === LineType.RIGHT_ANGLES;

const getContainerConfigById = (
  containerConfigs: ContainerConfig[] | undefined
): Record<string, ContainerConfig> => {
  if (containerConfigs === undefined) {
    return {};
  }
  return keyBy<ContainerConfig>(containerConfigs, (container) => container.id);
};

const reconcileAnnotations = ({
  previousContainers,
  currentContainers,
  dirtyNodes,
  currentAnnotationNodes,
  previousAnnotations,
  currentAnnotations,
}: {
  previousContainers: ContainerConfig[] | undefined;
  currentContainers: ContainerConfig[] | undefined;
  dirtyNodes: Konva.Node[];
  currentAnnotationNodes: Konva.Node[];
  previousAnnotations: Annotation[];
  currentAnnotations: Annotation[];
}): AnnotationChangeSet => {
  const previousContainerConfigsById =
    getContainerConfigById(previousContainers);
  const currentContainerConfigsById = getContainerConfigById(currentContainers);
  const updatedContainerConfigsById = pickBy(
    currentContainerConfigsById,
    (config) => !isEqual(config, previousContainerConfigsById[config.id])
  );

  const currentContainerIds = new Set(
    currentContainers === undefined ? [] : currentContainers.map(({ id }) => id)
  );

  const previousAnnotationsById = keyBy(
    previousAnnotations,
    (annotation) => annotation.id
  );

  const currentAnnotationNodesById = keyBy(currentAnnotationNodes, (node) =>
    node.id()
  );

  const currentAnnotationsById = keyBy(
    currentAnnotations,
    (annotation) => annotation.id
  );

  const updatedAnnotationsById = pickBy(
    currentAnnotationsById,
    (annotation) =>
      !isEqual(annotation, previousAnnotationsById[annotation.id]) ||
      (isSingleDocumentAnnotation(annotation) &&
        haveSingleDocumentAnnotationContainersChanged(
          annotation,
          previousContainerConfigsById,
          currentContainerConfigsById
        ))
  );

  const currentAnnotationsAndContainersById = {
    ...currentAnnotationsById,
    ...currentContainerConfigsById,
  };
  const updatedAnnotationsAndContainersById = {
    ...updatedAnnotationsById,
    ...updatedContainerConfigsById,
  };

  const updatedConnectionAnnotationsById = keyBy(
    currentAnnotations
      .filter(isConnectionPolylineAnnotation)
      .filter(
        (connectionAnnotation) =>
          isDependantOnEverything(connectionAnnotation) ||
          hasAtLeastOneDependencyBeenUpdated(
            connectionAnnotation,
            updatedAnnotationsAndContainersById
          ) ||
          hasAtLeastOneDependencyBeenRemoved(
            connectionAnnotation,
            currentAnnotationsAndContainersById
          )
      )
      .filter(isNotUndefined),
    (annotation) => annotation.id
  );
  const annotationsToUpdateById = {
    ...updatedAnnotationsById,
    ...updatedConnectionAnnotationsById,
  };

  const isAnnotationNodeToRemove = (node: Konva.Node) => {
    const annotation = currentAnnotationsById[node.id()];
    const previousAnnotation = previousAnnotationsById[node.id()];

    if (annotation === undefined) {
      return true;
    }

    if (
      isConnectionPolylineAnnotation(annotation) &&
      haveBothDependenciesBeenRemoved(
        annotation,
        currentAnnotationsAndContainersById
      )
    ) {
      return true;
    }

    if (
      isConnectionPolylineAnnotation(annotation) &&
      hasAtLeastOneDependencyBeenRemoved(
        annotation,
        currentAnnotationsAndContainersById
      ) &&
      !areConnectionPolylineVerticesDefined(annotation)
    ) {
      return true;
    }

    if (
      isSingleDocumentAnnotation(annotation) &&
      !currentContainerIds.has(annotation.containerId)
    ) {
      return true;
    }

    // If an annotation is being converted between its "cloud" and original form, we will need to
    // force a re-render to change the Konva.Node type.
    if (hasCloudPropertyChanged(annotation, previousAnnotation)) {
      return true;
    }

    // We also want to remove and add annotations that has changed its
    // `containerId` because we need to re-render the annotation in the new
    // container. This includes annotations that were moved from a container to
    // the workspace or vice versa.
    return node.attrs.containerId !== annotation.containerId;
  };

  const isAnnotationToAdd = (annotation: Annotation): boolean => {
    const previousAnnotation = previousAnnotationsById[annotation.id];
    const doesTargetContainerExist =
      !isSingleDocumentAnnotation(annotation) ||
      (isSingleDocumentAnnotation(annotation) &&
        currentContainerIds.has(annotation.containerId));

    const hasAnnotationAlreadyBeenRendered =
      currentAnnotationNodesById[annotation.id] !== undefined;
    if (doesTargetContainerExist && !hasAnnotationAlreadyBeenRendered) {
      return true;
    }

    const hasTargetContainerChanged =
      currentAnnotationNodesById[annotation.id]?.attrs.containerId !==
      annotation.containerId;

    // We also want to remove and add annotations that has changed its `containerId`
    // because we need to re-render the annotation in the new container.
    if (hasTargetContainerChanged && doesTargetContainerExist) {
      return true;
    }

    if (hasCloudPropertyChanged(annotation, previousAnnotation)) {
      return true;
    }

    return false;
  };

  const annotationNodesToKeep: Konva.Node[] = [];
  const annotationAndNodesToUpdate: AnnotationAndNode[] = [];
  const annotationNodesToRemove: Konva.Node[] = [];
  currentAnnotationNodes.forEach((node) => {
    if (isDirtyNode(node, dirtyNodes)) {
      annotationNodesToKeep.push(node);
      return;
    }

    if (isAnnotationNodeToRemove(node)) {
      annotationNodesToRemove.push(node);
      return;
    }

    const annotation = annotationsToUpdateById[node.id()];
    if (annotation !== undefined) {
      annotationAndNodesToUpdate.push({ annotation, node });
      return;
    }

    annotationNodesToKeep.push(node);
  });

  const annotationsToAdd = currentAnnotations.filter(isAnnotationToAdd);

  return {
    annotationNodesToKeep,
    annotationAndNodesToUpdate,
    annotationNodesToRemove,
    annotationsToAdd,
  };
};

export default reconcileAnnotations;
