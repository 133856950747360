import type { Measurement } from '@cognite/apm-client';
import { useMetrics } from '@cognite/metrics';
import { useMeasurementNumericReadingUpsert } from '@infield/features/measurements';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useAccessContext } from '@infield/providers/access-provider';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import type { FC } from 'react';

import { MeasurementNumericReading } from './measurement-numeric-reading';

interface Props {
  measurement: Measurement;
  isLocked: boolean;
  onChange: (measurement: Measurement) => void;
}

export const MeasurementNumericReadingContainer: FC<Props> = ({
  measurement,
  isLocked,
  onChange,
}) => {
  const metrics = useMetrics(METRICS_NAMESPACES.offlineMode);
  const [readingInput, setReadingInput] = useState<string>();
  const { hasTimeSeriesRwAccess } = useAccessContext();

  const { mutateAsync: upsertReading } = useMeasurementNumericReadingUpsert();
  const [previousTimestamp, setPreviousTimestamp] = useState(
    dayjs(measurement.measuredAt).valueOf()
  );
  const { isOnline } = useNetworkStatusContext();

  const onReadingChange = () => {
    if (!(measurement.timeseries && measurement.timeseries.externalId)) return;
    if (readingInput === measurement.numericReading?.toString()) return;

    const timestamp = dayjs().valueOf();

    const newDatapoint = () => {
      if (!readingInput || readingInput === '') return null;
      return {
        timestamp,
        value: Number(readingInput),
      };
    };

    const updatedMeasurement: Measurement = {
      ...measurement,
      numericReading: readingInput ? Number(readingInput) : null,
      measuredAt: dayjs(timestamp).utc().toISOString(),
    };

    upsertReading({
      measurement: updatedMeasurement,
      datapointExternalId: measurement.timeseries.externalId,
      previousMeasureTimestamp: previousTimestamp,
      newDatapoint: newDatapoint(),
      onMeasurementChange: () => onChange(updatedMeasurement),
    });

    setPreviousTimestamp(timestamp);

    if (!isOnline) {
      metrics.track('measurementAdded');
    }
  };

  useEffect(() => {
    const measurementReadingAsString = measurement.numericReading?.toString();
    if (measurementReadingAsString) {
      setReadingInput(measurementReadingAsString);
    }
  }, [measurement.numericReading]);

  return (
    <MeasurementNumericReading
      measurement={measurement}
      readingInput={readingInput}
      isLocked={isLocked}
      hasReadWritePermissions={hasTimeSeriesRwAccess}
      onReadingChange={onReadingChange}
      setReadingInput={setReadingInput}
    />
  );
};
