import type { CustomReadingLabelColor } from '@cognite/apm-client';
import type { FC, PropsWithChildren } from 'react';

import * as S from './elements';
import { getBackgroundColor, getColor } from './utils';

type Props = {
  isDisabled: boolean;
  isSelected: boolean;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  type?: CustomReadingLabelColor;
};

export const LabelReading: FC<PropsWithChildren<Props>> = ({
  isDisabled,
  isSelected,
  size = 'large',
  onClick,
  type,
  children,
  ...props
}) => {
  const color = getColor(type);
  const backgroundColor = getBackgroundColor(type);

  return (
    <S.LabelReading
      $disabled={isDisabled}
      $selected={isSelected}
      $color={color}
      $size={size}
      $backgroundColor={backgroundColor}
      onClick={onClick}
      {...props}
    >
      {children}
    </S.LabelReading>
  );
};
