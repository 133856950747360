import get from 'lodash/get';
import has from 'lodash/has';

const METADATA_KEY = 'metadata';
const METADATA_PATH_PREFIX = `${METADATA_KEY}.`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hasProperty = (obj: any, path: string): boolean => {
  if (METADATA_KEY in obj && path.startsWith(METADATA_PATH_PREFIX)) {
    return has(obj[METADATA_KEY], path.substring(METADATA_PATH_PREFIX.length));
  }
  return has(obj, path);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPropertyValue = (obj: any, path: string): any => {
  if (METADATA_KEY in obj && path.startsWith(METADATA_PATH_PREFIX)) {
    return get(obj[METADATA_KEY], path.substring(METADATA_PATH_PREFIX.length));
  }
  return get(obj, path);
};
