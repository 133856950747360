import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { type FC, useEffect } from 'react';

import { AlertBox } from '../alert-box';

export const DataLostBanner: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.infobar);
  const metrics = useMetrics(METRICS_NAMESPACES.offlineMode);

  useEffect(() => {
    metrics.track('dataLostBannerShown');
  }, [metrics]);

  return (
    <AlertBox
      status="critical"
      title={t('NETWORK_STATUS_BANNER_DATA_LOST_TITLE', 'Data is lost')}
      description={t(
        'NETWORK_STATUS_BANNER_DATA_LOST_DESCRIPTION',
        "We couldn't upload and save the data. Please do the round again."
      )}
      okButtonText={t('NETWORK_STATUS_BANNER_GOT_IT_BUTTON_TEXT', 'Got it')}
      collapsible={false}
    />
  );
};
