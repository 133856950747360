import { SelectPanel } from '@cognite/cogs-lab';
import { Button, EyeHideIcon, LoaderIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { type FC } from 'react';

import type { SelectedColumnOption } from '../../types';

import * as S from './elements';

interface Props {
  allColumns: SelectedColumnOption[];
  savedColumnState: SelectedColumnOption[];
  loadingColumns: boolean;
  unsavedChanges: SelectedColumnOption[];
  onChange: (columns: SelectedColumnOption[]) => void;
  setSelectedFilter: (filterExternalId?: string) => void;
  setUnsavedChanges: (columns: SelectedColumnOption[]) => void;
}

export const ActivityTableHiddenColumns: FC<Props> = ({
  allColumns,
  savedColumnState,
  loadingColumns,
  unsavedChanges,
  onChange,
  setSelectedFilter,
  setUnsavedChanges,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const columns = savedColumnState.length > 0 ? savedColumnState : allColumns;
  const unsavedColumns = unsavedChanges.length > 0 ? unsavedChanges : columns;

  const numberOfHiddenColumns = unsavedColumns.filter(
    (col) => !col.selected
  ).length;
  const showAllColumns = numberOfHiddenColumns === 0;
  const someHiddenColumns = numberOfHiddenColumns > 0;
  const showSomeColumns =
    someHiddenColumns && numberOfHiddenColumns < unsavedColumns.length;

  const handleSelectAllColumns = () => {
    if (showAllColumns) {
      setUnsavedChanges(
        unsavedColumns.map((col) => {
          return { ...col, selected: false };
        })
      );
    } else {
      setUnsavedChanges(
        unsavedColumns.map((col) => {
          return { ...col, selected: true };
        })
      );
    }
    setSelectedFilter(undefined);
  };

  const handleSelectColumn = (selectedColumn: SelectedColumnOption) => {
    const newColumns = unsavedColumns.map((col) => {
      if (col === selectedColumn) return { ...col, selected: !col.selected };
      return col;
    });
    setUnsavedChanges(newColumns);
    setSelectedFilter(undefined);
  };

  return (
    <SelectPanel
      themeProps={{
        inverted: false,
        theme: 'base',
      }}
      onClickOutside={() => setUnsavedChanges(savedColumnState)}
    >
      <SelectPanel.Trigger>
        <Button
          icon={<EyeHideIcon />}
          type="ghost"
          toggled={someHiddenColumns}
          data-testid="hidden-column-menu"
        >
          {numberOfHiddenColumns === 0
            ? t(
                'ACTIVITY_OVERVIEW_TOOLBAR_COLUMN_DROPDOWN_BUTTON_MANAGE_COLUMNS',
                'Manage columns'
              )
            : t(
                'ACTIVITY_OVERVIEW_TOOLBAR_COLUMN_DROPDOWN_BUTTON_HIDDEN_COLUMNS',
                'Hidden columns'
              )}
          {loadingColumns ? (
            <S.LoaderChip
              status={someHiddenColumns ? 'neutral' : 'undefined'}
              icon={<LoaderIcon />}
            />
          ) : (
            <S.ColumnCounterChip
              counter={numberOfHiddenColumns}
              $toggled={someHiddenColumns}
            />
          )}
        </Button>
      </SelectPanel.Trigger>
      <SelectPanel.Header
        title={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_COLUMN_DROPDOWN_HEADER_TITLE',
          'Manage columns'
        )}
        description={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_COLUMN_DROPDOWN_HEADER_DESCRIPTION',
          'Hide columns and reorder'
        )}
      >
        <SelectPanel.SelectAll
          indeterminate={showSomeColumns}
          independentIndeterminateState
          checked={showAllColumns}
          onChange={handleSelectAllColumns}
          label={t(
            'ACTIVITY_OVERVIEW_TOOLBAR_COLUMN_DROPDOWN_SHOW_ALL',
            'Show all'
          )}
        />
      </SelectPanel.Header>
      <SelectPanel.Body>
        <SelectPanel.Section
          items={unsavedColumns}
          onReorder={(newColumns: SelectedColumnOption[]) =>
            newColumns && setUnsavedChanges(newColumns)
          }
        >
          {unsavedColumns.map((item) => (
            <SelectPanel.Item
              reorderable
              key={item.key}
              item={item}
              label={item.label}
              variant="checkbox"
              checked={item.selected}
              onClick={() => handleSelectColumn(item)}
            />
          ))}
        </SelectPanel.Section>
      </SelectPanel.Body>
      <SelectPanel.Footer>
        <Button
          disabled={
            JSON.stringify(unsavedChanges) === JSON.stringify(savedColumnState)
          }
          onClick={() => onChange(unsavedChanges)}
          data-testid="hidden-columns-apply"
          type="primary"
        >
          {t('ACTIVITY_OVERVIEW_TOOLBAR_COLUMN_DROPDOWN_APPLY', 'Apply')}
        </Button>
      </SelectPanel.Footer>
    </SelectPanel>
  );
};
