import type { InFieldUserPreferences } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { Body, Heading, Modal } from '@cognite/cogs.js-v10';
import { APP_CONFIG_V2_EXTERNAL_ID } from '@infield/features/app-config/constants';
import {
  useAllInFieldLocationConfigs,
  useUpsertAppConfigMutation,
  useUpsertInfieldLocationConfig,
} from '@infield/features/app-config/hooks';
import type { AppConfig } from '@infield/features/app-config/types';
import { getDefaultDataFilters } from '@infield/features/app-config/utils/utils';
import { useObservationConfigQuery } from '@infield/features/observation';
import { useUpsertObservationConfig } from '@infield/features/observation/hooks/use-mutation/use-upsert-observation-config-mutation';
import {
  useUpsertUserPreferences,
  useUserPreferencesQuery,
} from '@infield/features/user';
import cloneDeep from 'lodash/cloneDeep';
import { type FC, useState } from 'react';
import { v4 as uuid } from 'uuid';

import * as S from './elements';

type Props = {
  appConfig: AppConfig;
  setEditingRootLocation: (rootLocationExternalId: string | undefined) => void;
};

export const MigrateConfig: FC<Props> = ({
  appConfig,
  setEditingRootLocation,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data: allUserPreferences } = useUserPreferencesQuery();
  const { data: observationConfigs } = useObservationConfigQuery();
  const { data: infieldLocationConfigs } = useAllInFieldLocationConfigs();

  const { mutateAsync: upsertUserPreferences } = useUpsertUserPreferences();
  const { mutateAsync: upsertAppConfig, isLoading: isLoadingUpsert } =
    useUpsertAppConfigMutation();
  const { mutateAsync: upsertObservationConfig } = useUpsertObservationConfig();
  const { mutateAsync: upsertInFieldLocationConfig } =
    useUpsertInfieldLocationConfig();
  const handleBeginConfigUpdateClicked = async () => {
    const newConfig: AppConfig = cloneDeep(appConfig!);
    newConfig.externalId = APP_CONFIG_V2_EXTERNAL_ID;

    const rootAssetToExternalIdMap = new Map<string, string>();
    const rootLocationConfigurations =
      newConfig.featureConfiguration?.rootLocationConfigurations || [];
    for (let i = 0; i < rootLocationConfigurations.length; i++) {
      const rootLocation = rootLocationConfigurations[i];
      rootLocation.externalId = uuid();

      // Store mapping for migrating user preferences
      rootAssetToExternalIdMap.set(
        rootLocation.assetExternalId,
        rootLocation.externalId
      );
      const defaultDataFilters = getDefaultDataFilters(
        rootLocation.assetExternalId
      );

      rootLocation.dataFilters = defaultDataFilters;
    }

    await upsertAppConfig(
      {
        newAppConfig: newConfig,
      },
      {
        onError: () => {
          makeToast({
            body: 'Failed to create the new config. Try again later or contact support.',
            type: 'danger',
          });
        },
      }
    );

    // Migrate observation field config
    if (observationConfigs && observationConfigs.length > 0) {
      const newObservationConfigs = observationConfigs.map(
        (observationConfig) => {
          const newRootLocationExternalIds =
            observationConfig.rootLocationExternalIds.map(
              (oldExternalId) =>
                rootAssetToExternalIdMap.get(oldExternalId) || oldExternalId // Get the new externalId if it exists, otherwise keep the old one
            );
          return {
            ...observationConfig,
            rootLocationExternalIds: newRootLocationExternalIds,
          };
        }
      );
      await upsertObservationConfig(newObservationConfigs);
    }

    // Migrate InField location config
    if (infieldLocationConfigs && infieldLocationConfigs.length > 0) {
      const newInFieldLocationConfigs = infieldLocationConfigs.map(
        (oldConfig) => {
          const newRootLocationExternalId =
            rootAssetToExternalIdMap.get(oldConfig.rootLocationExternalId) ||
            oldConfig.rootLocationExternalId;
          return {
            ...oldConfig,
            rootLocationExternalId: newRootLocationExternalId,
          };
        }
      );

      await upsertInFieldLocationConfig(newInFieldLocationConfigs);
    }

    // Migrate user preferences
    const IS_DRYRUN = false;

    if (allUserPreferences) {
      const newUserPreferences = allUserPreferences
        ?.filter(
          (userPreference) =>
            Boolean(userPreference.infield?.location) &&
            userPreference.infield?.location?.assetExternalId === undefined // Make sure we only migrate once, otherwise values will be incorrect
        )
        .map((userPreference) => {
          const oldExternalId = userPreference.infield!.location!.externalId;
          const newLocationExternalId =
            rootAssetToExternalIdMap.get(oldExternalId) || '';
          return {
            ...userPreference,
            infield: {
              ...userPreference.infield,
              location: {
                ...userPreference.infield!.location,
                externalId: newLocationExternalId,
                assetExternalId: oldExternalId,
              },
            },
          } as InFieldUserPreferences;
        });

      if (!IS_DRYRUN && newUserPreferences.length > 0) {
        await upsertUserPreferences(newUserPreferences);
      }
    }
    // Have to reset the root location after migration, since the selectedRootLocation in edit will be invalid
    setEditingRootLocation(undefined);

    setIsModalVisible(false);
    makeToast({
      body: 'Config migration successful! You may now configure data filters.',
      type: 'success',
    });
  };

  return (
    <>
      <S.MigrationRequiredMessageContainer
        direction="column"
        justifyContent="flex-start"
        gap={12}
      >
        <Heading level={2}>This feature requires a config update</Heading>
        <Body>
          This update will create a new APM_Config instance (externalId ={' '}
          {APP_CONFIG_V2_EXTERNAL_ID}) which supports this feature. Write down
          this externalId. When you are ready, press the button below to start
          the process.
        </Body>
        <Body>
          We recommend being ready to delete the new config in Fusion in case
          something goes wrong (make sure the externalID is correct). InField
          will then automatically revert to its old state using the old config.
        </Body>
        <S.MigrateConfigButton
          type="primary"
          onClick={() => setIsModalVisible(true)}
        >
          Begin config update
        </S.MigrateConfigButton>
      </S.MigrationRequiredMessageContainer>
      <Modal
        title="Confirm config migration"
        onOk={handleBeginConfigUpdateClicked}
        okButtonProps={{ loading: isLoadingUpsert }}
        onCancel={() => setIsModalVisible(false)}
        visible={isModalVisible}
      >
        Are you sure you would like to migrate now? This can be disruptive for
        your users if it fails. Have the new config external id ready to delete
        it if needed.
      </Modal>
    </>
  );
};
