import styled from 'styled-components';

export const AutocomleteWrapper = styled.div<{ $isChanged: boolean }>`
  ${({ $isChanged }) =>
    $isChanged &&
    `.cogs-v10.cogs-select__control {
    border: var(--cogs-border-width--emphasized) solid
      var(--cogs-themed-border--status-warning--strong);
  }`};
`;
