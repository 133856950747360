import {
  Body,
  Button,
  Divider,
  Flex,
  Heading,
  Input,
} from '@cognite/cogs.js-v10';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import type {
  ResourceFilters,
  RootLocationConfiguration,
  RootLocationDataFilters,
} from '../../../../types';
import * as S from '../../elements';

interface Props {
  hasAppConfigPermission: boolean;
  configuredRootLocation?: RootLocationConfiguration;
  isSuccess: boolean;
  isLoading: boolean;
  dataFiltersTab: keyof RootLocationDataFilters;
  onConfigSave?: (updatedDataFilters: RootLocationDataFilters) => void;
  onGeneralConfigSave?: (updatedDataFilters: ResourceFilters) => void;
}

export const DataFiltersTab: FC<Props> = ({
  hasAppConfigPermission,
  configuredRootLocation,
  isSuccess,
  isLoading,
  dataFiltersTab,
  onConfigSave,
  onGeneralConfigSave,
}) => {
  const [rootAssetExternalIds, setRootAssetExternalIds] = useState<string>('');
  const [assetSubtreeExternalIds, setAssetSubtreeExternalIds] =
    useState<string>('');
  const [externalIdPrefix, setExternalIdPrefix] = useState<string>('');
  const [defaultTimeseriesDataset, setDefaultTimeseriesDataset] = useState<
    number | undefined
  >();
  const [datasetIds, setDatasetIds] = useState<string>('');

  const selectedTabDataFilters = configuredRootLocation?.dataFilters
    ? configuredRootLocation?.dataFilters[dataFiltersTab]
    : undefined;

  const createKeyArray = (s: string) =>
    s ? s.split(',').map((key) => key.trim()) : null;

  const createKeyNumberArray = (s: string) =>
    s
      ? (s
          .split(',')
          .map((key) => {
            const num = parseFloat(key.trim());
            return Number.isNaN(num) ? null : num;
          })
          .filter(Boolean) as number[])
      : null;

  useEffect(() => {
    if (isSuccess && configuredRootLocation) {
      if (selectedTabDataFilters?.assetSubtreeExternalIds) {
        setAssetSubtreeExternalIds(
          selectedTabDataFilters?.assetSubtreeExternalIds.join(', ')
        );
      }
      if (selectedTabDataFilters?.rootAssetExternalIds) {
        setRootAssetExternalIds(
          selectedTabDataFilters.rootAssetExternalIds.join(', ')
        );
      }
      if (selectedTabDataFilters?.externalIdPrefix) {
        setExternalIdPrefix(selectedTabDataFilters.externalIdPrefix);
      }
      if (selectedTabDataFilters?.datasetIds) {
        setDatasetIds(selectedTabDataFilters.datasetIds.join(', '));
      }
      if (configuredRootLocation.dataSetId) {
        setDefaultTimeseriesDataset(configuredRootLocation.dataSetId);
      }
    }
  }, [isSuccess, configuredRootLocation, selectedTabDataFilters]);

  const hasConfigChanged =
    assetSubtreeExternalIds !==
      (selectedTabDataFilters?.assetSubtreeExternalIds ?? []).join(', ') ||
    rootAssetExternalIds !==
      (selectedTabDataFilters?.rootAssetExternalIds ?? []).join(', ') ||
    externalIdPrefix !== (selectedTabDataFilters?.externalIdPrefix ?? '') ||
    datasetIds !== (selectedTabDataFilters?.datasetIds ?? []).join(', ');

  const handleSave = async () => {
    if (!configuredRootLocation) return;

    const newFilters = {
      assetSubtreeExternalIds: createKeyArray(assetSubtreeExternalIds),
      rootAssetExternalIds: createKeyArray(rootAssetExternalIds),
      externalIdPrefix: externalIdPrefix || null,
      datasetIds: createKeyNumberArray(datasetIds),
    };

    if (onGeneralConfigSave) {
      onGeneralConfigSave(newFilters);
    }
    if (onConfigSave) {
      onConfigSave({
        [dataFiltersTab]: newFilters,
      });
    }
  };

  const assetSubtreeExternalIdsConfig = (
    <Flex direction="column" gap={8}>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Asset subtree externalIds
          </Body>
          <Input
            disabled={!hasAppConfigPermission || dataFiltersTab !== 'general'}
            value={assetSubtreeExternalIds}
            onChange={(e) => setAssetSubtreeExternalIds(e.target.value)}
          />
          <Body size="x-small">
            Comma-separated list of asset subtree externalIds will be used to
            filter {dataFiltersTab}
          </Body>
        </Flex>
      </Flex>
    </Flex>
  );

  const defaultRootAssetExternalIdConfig = (
    <Flex direction="column" gap={8}>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Default Root asset externalId
          </Body>
          <Input disabled value={configuredRootLocation?.assetExternalId} />
        </Flex>
      </Flex>
    </Flex>
  );

  const rootAssetExternalIdsConfig = (
    <Flex direction="column" gap={8}>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Additional root asset externalIds
          </Body>
          <Input
            disabled={!hasAppConfigPermission}
            value={rootAssetExternalIds}
            onChange={(e) => setRootAssetExternalIds(e.target.value)}
          />
          <Body size="x-small">
            Comma-separated list of root asset externalIds will be used to
            filter app data
          </Body>
        </Flex>
      </Flex>
    </Flex>
  );

  const prefixFilterConfig = (
    <Flex direction="column" gap={8}>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            {dataFiltersTab.charAt(0).toUpperCase() + dataFiltersTab.slice(1)}{' '}
            externalId prefix filter
          </Body>
          <Input
            disabled={!hasAppConfigPermission}
            value={externalIdPrefix}
            onChange={(e) => setExternalIdPrefix(e.target.value)}
          />
          <Body size="x-small">
            String value of externalId prefix filter will be used to filter{' '}
            {dataFiltersTab}
          </Body>
        </Flex>
      </Flex>
    </Flex>
  );

  const dataSetIdsConfig = (
    <Flex direction="column" gap={8}>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Dataset ids to filter on
          </Body>
          <Body size="x-small">
            To use dataset filters you must include the dataset that holds the
            files and timeseries created from InField.
            <br />
            {defaultTimeseriesDataset
              ? `For this location it is configured as ${defaultTimeseriesDataset}`
              : 'You do not have it configured for this location. You must add it under "Dataset" tab and migrate any previously added from InFiled files and timeseries to be stored with that newly added dataset'}
          </Body>
          <Input
            disabled={!hasAppConfigPermission}
            value={datasetIds}
            onChange={(e) => setDatasetIds(e.target.value)}
          />
          <Body size="x-small">
            Comma-separated list of datasetIds will be used to filter{' '}
            {dataFiltersTab}
          </Body>
        </Flex>
      </Flex>
    </Flex>
  );

  const saveButton = (
    <Flex direction="column" gap={4}>
      <Button
        onClick={handleSave}
        disabled={!hasConfigChanged}
        loading={isLoading}
      >
        Save configuration
      </Button>
    </Flex>
  );

  return (
    <S.Wrapper>
      <Flex direction="column" gap={16}>
        <Heading level={6}>CDF filters</Heading>
        {assetSubtreeExternalIdsConfig}
        {dataFiltersTab !== 'general' &&
          dataFiltersTab !== 'files' &&
          prefixFilterConfig}
        {dataFiltersTab !== 'general' &&
          dataFiltersTab !== 'assets' &&
          dataSetIdsConfig}
        {dataFiltersTab === 'general' && (
          <>
            <Divider weight="2px" />
            <Heading level={6}>FDM filter</Heading>
            {defaultRootAssetExternalIdConfig}
            {rootAssetExternalIdsConfig}
          </>
        )}
        {saveButton}
      </Flex>
    </S.Wrapper>
  );
};
