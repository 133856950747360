import { FileInfo } from '@cognite/sdk';

import getFileExtension from '../getFileExtension';

// Source: https://github.com/cognitedata/document-preview/blob/06ea038d23d6434469c7dfe4376a80bf27ccb798/src/main/kotlin/com/cognite/documentpreview/MediaType.kt

const wordDocumentMimeTypes = [
  'application/msword',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/rtf',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.text-template',
];

const wordDocumentExtensions = [
  'doc',
  'dot',
  'docx',
  'dotx',
  'docm',
  'dotm',
  'rtf',
  'odt',
  'ott',
];

const slidesMimeType = [
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.presentation-template',
];

const slidesExtensions = [
  'ppt',
  'pot',
  'pps',
  'pptx',
  'potx',
  'ppsx',
  'pptm',
  'potm',
  'ppsm',
  'odp',
  'otp',
];

const cellsMimeType = [
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.apple.numbers',
  'text/tab-separated-values',
  'application/vnd.oasis.opendocument.spreadsheet',
];

const cellsExtensions = [
  'xls',
  'xlt',
  'xlsx',
  'xltx',
  'xlsm',
  'xltm',
  'xlsb',
  'numbers',
  'tsv',
  'ods',
];

export const DocumentApiSupportedMimeTypes = [
  ...wordDocumentMimeTypes,
  ...slidesMimeType,
  ...cellsMimeType,
];
const supportedFileExtensions = [
  ...wordDocumentExtensions,
  ...slidesExtensions,
  ...cellsExtensions,
];

const doesDocumentPreviewApiSupportFile = ({
  mimeType,
  name,
}: Pick<FileInfo, 'mimeType' | 'name'>): boolean =>
  (mimeType !== undefined &&
    DocumentApiSupportedMimeTypes.includes(mimeType)) ||
  supportedFileExtensions.includes(getFileExtension(name));

export default doesDocumentPreviewApiSupportFile;
