import type { Notification } from '@cognite/apm-client';
import { CalendarIcon, Flex, TagChip } from '@cognite/cogs.js-v10';
import * as S from '@infield/features/asset/elements';
import type { ListItem } from '@infield/features/asset/shared/styled-list';
import { StyledList } from '@infield/features/asset/shared/styled-list';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { DEFAULT_DATE_FORMAT } from '@infield/utils/defaultDateFormats';
import { getLastUpdatedLabel } from '@infield/utils/humanizeDuration';
import dayjs from 'dayjs';
import type { FC } from 'react';

type Props = {
  notifications: Notification[];
  selectedNotification: string;
  onNotificationClick: (externalId: string) => void;
};

export const AssetNotificationsList: FC<Props> = ({
  notifications,
  selectedNotification,
  onNotificationClick,
}) => {
  const { Trans, t } = useTranslation(LOCIZE_NAMESPACES.notification);

  const items = (): ListItem[] => {
    if (notifications) {
      return notifications.map((notification) => {
        return {
          key: notification.externalId,
          isSelected: selectedNotification === notification.externalId,
          onClick: () => onNotificationClick(notification.externalId),
          content: (
            <S.Item>
              <Flex direction="column" gap={4}>
                <S.ItemTitle>{notification.title || '-'}</S.ItemTitle>
                <S.ItemDescription size="small">
                  {notification.sourceId}
                </S.ItemDescription>

                <Flex gap={8}>
                  {notification.type && (
                    <TagChip size="small" label={notification.type} />
                  )}

                  <TagChip
                    icon={<CalendarIcon />}
                    size="small"
                    label={
                      dayjs(notification.createdDate).format(
                        DEFAULT_DATE_FORMAT
                      ) || '-'
                    }
                  />
                </Flex>
              </Flex>

              <Flex>
                <S.LastUpdated>
                  <Trans i18nKey="ASSET_OVERVIEW_NOTIFICATIONS_EXPANDED_VIEW_ITEM_LAST_UPDATED">
                    Last updated:
                  </Trans>{' '}
                  {getLastUpdatedLabel(
                    new Date(notification.lastUpdatedTime!),
                    t(
                      'ASSET_OVERVIEW_NOTIFICATIONS_EXPANDED_VIEW_ITEM_UPDATED_SECONDS_AGO',
                      'seconds ago'
                    )
                  )}
                </S.LastUpdated>
              </Flex>
            </S.Item>
          ),
        };
      });
    }
    return [];
  };

  return <StyledList items={items()} />;
};
