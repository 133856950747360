import Konva from 'konva';

import type { UnifiedViewer } from '../UnifiedViewer';
import UnifiedViewerEventType from '../UnifiedViewerEventType';
import { UnifiedViewerPointerEvent } from '../UnifiedViewerRenderer/UnifiedEventHandler';
import { isSecondaryMouseButtonPressed } from '../utils/eventUtils';
import findNearestAncestor from '../utils/findNearestAncestor';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';

import getToolByToolConfig from './getToolByToolConfig';
import setConfigForTool from './setConfigForTool';
import Tool from './Tool';
import { ToolType, ToolConfig } from './types';

export class ToolManager {
  private readonly unifiedViewer: UnifiedViewer;

  public constructor({
    unifiedViewer,
    defaultTool,
  }: {
    unifiedViewer: UnifiedViewer;
    defaultTool: ToolConfig;
  }) {
    this.unifiedViewer = unifiedViewer;

    this.setTool(defaultTool);

    this.addDelegatedHostEventListener('mousedown', this.onStageMouseDown);
    this.addDelegatedHostEventListener('mousemove', this.onStageMouseMove);
    this.addDelegatedHostEventListener('mouseup', this.onStageMouseUp);
    this.addDelegatedHostEventListener('mouseover', this.onStageMouseOver);
    this.addDelegatedHostEventListener('mouseout', this.onStageMouseOut);
    this.addDelegatedHostEventListener('mouseenter', this.onStageMouseEnter);
    this.addDelegatedHostEventListener('mouseleave', this.onStageMouseLeave);

    // Mobile
    this.addDelegatedHostEventListener('touchstart', this.onStageMouseDown);
    this.addDelegatedHostEventListener('touchmove', this.onStageMouseMove);
    this.addDelegatedHostEventListener('touchend', this.onStageMouseUp);
  }

  private activeTool: Tool | undefined;

  public getActiveTool = (): Tool | undefined => this.activeTool;

  public getActiveToolType = (): ToolType | undefined => this.activeTool?.type;

  public onActiveToolComplete = (): void => this.activeTool?.onComplete?.();

  public setTool = (tool: ToolConfig): void => {
    if (this.activeTool?.type !== tool.type) {
      this.activeTool?.onDestroy?.();
      this.unifiedViewer.shamefullyRefreshAnchors([]);
      this.activeTool = getToolByToolConfig(tool, this.unifiedViewer);
    } else {
      setConfigForTool(this.activeTool, tool);
    }

    this.setCursor(this.activeTool!.cursor!);
    getMetricsLogger()?.trackEvent(TrackedEventType.SET_TOOL, tool);
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_TOOL_CHANGE, tool);
  };

  public setCursor = (cursor: string): void => {
    this.unifiedViewer.stage.container().style.cursor = cursor;
  };

  public getCursor = (): string =>
    this.unifiedViewer.stage.container().style.cursor;

  private updateActiveToolCursor = (e: UnifiedViewerPointerEvent) => {
    const doesAncestorHaveClickHandler = findNearestAncestor(
      (node: Konva.Node) => {
        return (
          (!(node instanceof Konva.Stage) &&
            this.unifiedViewer.eventHandler.getNodeEventListenersByEventType(
              node,
              'click'
            ).length > 0) ||
          this.unifiedViewer.eventHandler.getNodeEventListenersByEventType(
            node,
            'dblclick'
          ).length > 0
        );
      }
    );

    if (
      doesAncestorHaveClickHandler(e.target) &&
      (this.getActiveToolType() === ToolType.PAN ||
        this.getActiveToolType() === ToolType.SELECT)
    ) {
      this.setCursor('pointer');
      return;
    }
    if (this.unifiedViewer.stage.isDragging()) {
      this.setCursor('grabbing');
      return;
    }
    this.setCursor(this.activeTool?.cursor || 'default');
  };

  private onStageMouseDown = (e: UnifiedViewerPointerEvent) => {
    // Currently, only the select tool supports handling secondary mouse button clicks
    const skipToolHandler =
      isSecondaryMouseButtonPressed(e.evt) &&
      this.getActiveToolType() !== ToolType.SELECT;

    if (!skipToolHandler) {
      this.activeTool?.onMouseDown?.(e);
    }

    this.updateActiveToolCursor(e);
  };

  private onStageMouseMove = (e: UnifiedViewerPointerEvent) => {
    this.activeTool?.onMouseMove?.(e);
    this.updateActiveToolCursor(e);
  };

  private onStageMouseUp = (e: UnifiedViewerPointerEvent) => {
    // Currently, only the select tool supports handling secondary mouse button clicks
    const skipToolHandler =
      isSecondaryMouseButtonPressed(e.evt) &&
      this.getActiveToolType() !== ToolType.SELECT;

    if (!skipToolHandler) {
      this.activeTool?.onMouseUp?.(e);
    }

    this.updateActiveToolCursor(e);
  };

  private onStageMouseOver = (e: MouseEvent) => {
    this.activeTool?.onMouseOver?.(e);
  };

  private onStageMouseOut = (e: MouseEvent) => {
    this.activeTool?.onMouseOut?.(e);
  };

  private onStageMouseEnter = (e: MouseEvent) => {
    this.activeTool?.onMouseEnter?.(e);
  };

  private onStageMouseLeave = (e: MouseEvent) => {
    this.activeTool?.onMouseLeave?.(e);
  };

  private addDelegatedHostEventListener = (
    eventType: string,
    konvaEventHandler: any
  ): void => {
    this.unifiedViewer.eventHandler.addEventListener(
      this.unifiedViewer.stage,
      eventType,
      konvaEventHandler
    );
  };
}
