import { Select } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const TypeSelection = styled(Select).attrs({
  menuPlacement: 'bottom',
  disableTyping: true,
})`
  background-color: ${({ theme }) => theme['surface--muted']};
  .cogs-select__menu {
    max-height: 200px;
    overflow-y: auto;
  }
`;
