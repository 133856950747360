import { Textarea } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { Step } from '@infield/features/observation';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import { ObservationDetailsStepInfo } from '../observation-details-step-wrapper/observation-details-step-info';

interface Props {
  step: Step;
  value?: string;
  isLocked?: boolean;
  onChange: (value: string) => void;
}

export const ObservationDetailsStepTroubleshoot: FC<Props> = ({
  step,
  value,
  isLocked,
  onChange,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);
  const [problemText, setProblemText] = useState('');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (value) {
      setProblemText(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: string) => {
    setProblemText(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      onChange(value);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <ObservationDetailsStepInfo step={step} />
      <Textarea
        value={problemText}
        onChange={(e) => handleChange(e.target.value)}
        autoResize
        fullWidth
        rows={1}
        placeholder={t('OBSERVATION_STEP_PROBLEM_PLACEHOLDER', 'Entered text')}
        disabled={isLocked}
      />
    </>
  );
};
