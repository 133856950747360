import type { Template, TemplateStatus } from '@cognite/apm-client';
import {
  AddIcon,
  Button,
  EmptyState,
  FindIllustration,
  Skeleton,
} from '@cognite/cogs.js-v10';
import type { FilterItem } from '@infield/features/activities';
import { ActivityListFilter } from '@infield/features/activities';
import { useIsTemplateAdminQuery } from '@infield/features/app-config';
import { SearchResultHighlight } from '@infield/features/checklist/checklist-item';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { SearchFilterSortHeader } from '@infield/features/search';
import { useCreateTemplate } from '@infield/features/template/hooks';
import {
  isNewTemplateAtom,
  selectedTaskAtom,
  selectedTemplateAtom,
  showAssetDetailsAtom,
} from '@infield/features/template/state';
import { TextInputModal } from '@infield/features/template/text-input-modal';
import { ComponentSidebar, PanelHeader } from '@infield/features/ui';
import { useDebounce } from '@infield/hooks/use-debounce';
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import * as S from './elements';
import { formatTemplateName } from './utils';

type TemplateListUserViewProps = {
  templateStatus: TemplateStatus;
  templates: Template[];
  isLoading: boolean;
  debouncedSearchQuery: string;
  setDebouncedSearchQuery: (debouncedSearchQuery: string) => void;
};

export const TemplateList: FC<TemplateListUserViewProps> = ({
  templateStatus,
  templates,
  isLoading,
  debouncedSearchQuery,
  setDebouncedSearchQuery,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const setIsNewTemplate = useSetRecoilState(isNewTemplateAtom);
  const [showModal, setShowModal] = useState(false);
  const { mutateAsync: createTemplate } = useCreateTemplate();
  const [selectedTemplate, setSelectedTemplate] =
    useRecoilState(selectedTemplateAtom);
  const resetSelectedTask = useResetRecoilState(selectedTaskAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const resetShowAssetDetails = useResetRecoilState(showAssetDetailsAtom);
  const { data: isTemplateAdmin } = useIsTemplateAdminQuery();

  const [templateSearchQuery, setTemplateSearchQuery] =
    useState<string>(debouncedSearchQuery);
  const debouncedQuery = useDebounce(templateSearchQuery, 500);
  const fetchMode = debouncedQuery === '' ? 'list' : 'search';

  useEffect(() => {
    setDebouncedSearchQuery(debouncedQuery);
  }, [debouncedQuery, setDebouncedSearchQuery]);

  const handleSelectTemplate = (template: Template) => {
    setSelectedTemplate(template);
    resetSelectedTask();
    resetShowAssetDetails();
    setIsNewTemplate(false);
  };

  const handleCreate = async (name: string) => {
    setShowModal(false);

    const template: Template = {
      externalId: uuid(),
      title: formatTemplateName(name),
      status: 'Draft',
    };

    handleFilterClick('Draft');
    await createTemplate(template);
    setSelectedTemplate(template);
    resetSelectedTask();
    setIsNewTemplate(true);
  };

  const filterItems: FilterItem[] = [
    {
      displayName: t('TEMPLATE_FILTER_STATUS_ALL', 'All'),
      key: 'All',
    },
    {
      displayName: t('TEMPLATE_FILTER_STATUS_DRAFT', 'Draft'),
      key: 'Draft',
    },
    {
      displayName: t('TEMPLATE_FILTER_STATUS_READY', 'Ready'),
      key: 'Ready',
    },
    {
      displayName: t('TEMPLATE_FILTER_STATUS_DELETED', 'Deleted'),
      key: 'Deleted',
    },
  ];

  const handleFilterClick = (key: string) => {
    searchParams.set('templateStatus', key);
    setSearchParams(searchParams);
    resetSelectedTask();
  };

  return (
    <ComponentSidebar>
      <PanelHeader
        title={t('TEMPLATE_LIST_HEADER_TITLE', 'Templates')}
        data-testid="template-header-title"
      >
        {isTemplateAdmin && (
          <Button
            type="primary"
            icon={<AddIcon />}
            data-testid="show-create-template-modal"
            aria-label="show-create-template-modal"
            onClick={() => setShowModal(true)}
          >
            {t('TEMPLATE_LIST_HEADER_CREATE_BUTTON_TEXT', 'Create template')}
          </Button>
        )}
      </PanelHeader>
      <SearchFilterSortHeader
        searchInput={templateSearchQuery}
        setSearchInput={setTemplateSearchQuery}
        searchPlaceholder={t(
          'TEMPLATE_LIST_SEARCH_PLACEHOLDER',
          'Search by name'
        )}
      />
      <ActivityListFilter
        items={filterItems}
        activeFilterKey={templateStatus}
        onClick={handleFilterClick}
      />
      {isLoading ? (
        <Skeleton.List lines={4} borders />
      ) : (
        <S.ScrollableContent>
          {templates && templates.length > 0 ? (
            <S.TemplateList>
              {templates?.map((template) => (
                <S.TemplateListItem
                  data-testid="template-list-item"
                  $isSelected={
                    selectedTemplate?.externalId === template.externalId
                  }
                  key={template.externalId}
                  onClick={() => handleSelectTemplate(template)}
                >
                  <S.TemplateListItemTitle>
                    <SearchResultHighlight
                      text={template.title || ''}
                      searchQuery={debouncedQuery}
                    />
                  </S.TemplateListItemTitle>
                </S.TemplateListItem>
              ))}
            </S.TemplateList>
          ) : (
            <S.EmptyStateContainer>
              <EmptyState
                size="small"
                title={t(
                  'TEMPLATE_LIST_WARNING_NO_TEMPLATES_TITLE',
                  'No results'
                )}
                description={
                  fetchMode === 'search'
                    ? t(
                        'TEMPLATE_LIST_WARNING_NO_TEMPLATES_ON_SEARCH_TEXT',
                        'Try adjusting your search query or filters'
                      )
                    : t(
                        'TEMPLATE_LIST_WARNING_NO_TEMPLATES',
                        'No templates available'
                      )
                }
                illustration={<FindIllustration />}
              />
            </S.EmptyStateContainer>
          )}
        </S.ScrollableContent>
      )}

      <TextInputModal
        size="small"
        visible={showModal}
        title={t('TEMPLATE_CREATE_DIALOGUE_TITLE', 'Create a new template')}
        inputLabel={t(
          'TEMPLATE_CREATE_DIALOGUE_INPUT_TEMPLATE_NAME',
          'Template name'
        )}
        okText={t(
          'TEMPLATE_CREATE_DIALOGUE_BUTTON_CREATE_TEMPLATE',
          'Create template'
        )}
        cancelText={t('TEMPLATE_CREATE_DIALOGUE_BUTTON_CANCEL', 'Cancel')}
        onSubmit={handleCreate}
        onCancel={() => setShowModal(false)}
        okButtonTestId="create-template-modal-ok"
        cancelButtonTestId="create-template-modal-cancel"
        textInputTestId="create-template-modal-template-name-input"
        maxCharacters={60}
      >
        {null}
      </TextInputModal>
    </ComponentSidebar>
  );
};
