import { Body, Flex, Input } from '@cognite/cogs.js-v10';
import { ComponentContainer } from '@infield/features/ui';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { useAppConfigQuery } from '../hooks';
import { useUpsertAppConfigMutation } from '../hooks/use-mutation';
import type { AppConfig } from '../types';

import * as S from './elements';

interface Props {
  hasAppConfigPermission: boolean;
}

export const AppConfigDocument: FC<Props> = ({ hasAppConfigPermission }) => {
  const [documentTitle, setDocumentTitle] = useState('');
  const [documentDescription, setDocumentDescription] = useState('');
  const [documentType, setDocumentType] = useState('');

  const { data: appConfig, isSuccess, isLoading } = useAppConfigQuery();
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();
  const documentConfiguration = appConfig?.featureConfiguration?.documents;

  useEffect(() => {
    if (isSuccess && documentConfiguration) {
      if (documentConfiguration.title) {
        setDocumentTitle(documentConfiguration.title);
      }
      if (documentConfiguration.description) {
        setDocumentDescription(documentConfiguration.description);
      }
      if (documentConfiguration.type) {
        setDocumentType(documentConfiguration.type);
      }
    }
  }, [isSuccess, documentConfiguration, appConfig]);

  const hasConfigChanged =
    documentTitle !== (documentConfiguration?.title ?? '') ||
    documentDescription !== (documentConfiguration?.description ?? '') ||
    documentType !== (documentConfiguration?.type ?? '');

  const handleSave = async () => {
    if (!appConfig) return;

    const newAppConfig: AppConfig = {
      ...appConfig,
      featureConfiguration: {
        ...appConfig.featureConfiguration,
        documents: {
          ...appConfig.featureConfiguration?.documents,
          title: documentTitle,
          description: documentDescription,
          type: documentType,
        },
      },
    };

    await upsertAppConfig({
      newAppConfig,
    });
  };

  const documentCardConfigs = (
    <Flex direction="column" gap={8}>
      <Body size="x-small">
        Configure how the documents shall display data in the asset document
        card or document full page. Data is fetched from CDF files. Fields are
        configured by its CDF field name, eg *id*, *name* or *metadata.title*.
      </Body>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Title
          </Body>
          <Input
            disabled={!hasAppConfigPermission}
            value={documentTitle}
            onChange={(e) => setDocumentTitle(e.target.value)}
          />
          <Body size="x-small">
            This is the document title displayed for each document
          </Body>
        </Flex>
      </Flex>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Description
          </Body>
          <Input
            disabled={!hasAppConfigPermission}
            value={documentDescription}
            onChange={(e) => setDocumentDescription(e.target.value)}
          />
          <Body size="x-small">
            This is the document description displayed for each document under
            the title.
          </Body>
        </Flex>
      </Flex>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Type
          </Body>
          <Input
            disabled={!hasAppConfigPermission}
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
          />
          <Body size="x-small">
            When document types is configured, they are grouped in the document
            page.
          </Body>
        </Flex>
      </Flex>
    </Flex>
  );

  const saveButton = (
    <Flex direction="column" gap={4}>
      <S.SaveButton
        onClick={handleSave}
        disabled={!hasConfigChanged}
        loading={isLoading}
      >
        Save configuration
      </S.SaveButton>
    </Flex>
  );

  return (
    <ComponentContainer>
      <S.Wrapper>
        <Flex direction="column" gap={16}>
          {documentCardConfigs}
          {saveButton}
        </Flex>
      </S.Wrapper>
    </ComponentContainer>
  );
};
