import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

export const useAssetActivitiesQuery = (
  assetExternalId: string,
  filters?: Filters[],
  limit?: number
) => {
  const { activityService } = useFDMServices();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const activitiesFilter: Filters = {
    and: [
      ...(filters || []),
      {
        equals: {
          property: 'assetExternalId',
          eq: assetExternalId,
        },
      },
    ],
  };
  const sort: Sort = [{ endTime: 'DESC' }];

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  return useQuery(
    [QueryKeys.ASSET_ACTIVITIES, sort, activitiesFilter, limit],
    async () => {
      sliTimerStartTime.current = Date.now();

      const returnedActivities = await activityService.getActivities(
        activitiesFilter,
        sort,
        limit
      );

      return returnedActivities;
    },
    {
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.ASSET_ACTIVITIES,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.ASSET_ACTIVITIES, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.ASSET_ACTIVITIES, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      enabled: assetExternalId !== '',
    }
  );
};
