import { Body, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Wrapper = styled(Flex).attrs({ justifyContent: 'center' })`
  color: ${({ theme }) => theme['text-icon--medium']};
  flex-direction: column;
  width: 100%;
  text-align: center;
  padding-top: 16px;
  min-height: 200px;
`;

export const EmptyText = styled(Body).attrs(() => ({ size: 'medium' }))`
  color: ${({ theme }) => theme['text-icon--muted']};
`;
