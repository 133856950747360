import {
  BaseStyleProperties,
  FontSize,
  PixelUnit,
  PolylineAnnotation,
} from '../annotations/types';

export enum ToolType {
  ELLIPSE = 'ellipse',
  IMAGE = 'image',
  LINE = 'line',
  PAN = 'pan',
  POLYLINE = 'polyline',
  RECTANGLE = 'rectangle',
  SELECT = 'select',
  STICKY = 'sticky',
  TEXT = 'text',
  CAUSE_MAP = 'causeMap',
}

export type EllipseToolConfig = {
  type: ToolType.ELLIPSE;
} & BaseStyleProperties;

export const isEllipseToolConfig = (
  tool: ToolConfig
): tool is EllipseToolConfig => tool.type === ToolType.ELLIPSE;

export type ImageToolConfig = {
  type: ToolType.IMAGE;
} & {
  imageUrl: string;
  imageSize?: PixelUnit;
};
export const isImageToolConfig = (tool: ToolConfig): tool is ImageToolConfig =>
  tool.type === ToolType.IMAGE;

export type LineToolConfig = {
  type: ToolType.LINE;
} & BaseStyleProperties &
  Pick<PolylineAnnotation, 'startEndType' | 'endEndType'> & {
    shouldGenerateConnections?: boolean;
  };

export const isLineToolConfig = (tool: ToolConfig): tool is LineToolConfig =>
  tool.type === ToolType.LINE;

export type PanToolConfig = {
  type: ToolType.PAN;
};
export const isPanToolConfig = (tool: ToolConfig): tool is PanToolConfig =>
  tool.type === ToolType.PAN;

export type PolylineToolConfig = {
  type: ToolType.POLYLINE;
} & BaseStyleProperties &
  Pick<PolylineAnnotation, 'startEndType' | 'endEndType'> & {
    shouldGenerateConnections?: boolean;
  };
export const isPolylineToolConfig = (
  tool: ToolConfig
): tool is PolylineToolConfig => tool.type === ToolType.POLYLINE;

export type RectangleToolConfig = {
  type: ToolType.RECTANGLE;
} & BaseStyleProperties;

export const isRectangleToolConfig = (
  tool: ToolConfig
): tool is RectangleToolConfig => tool.type === ToolType.RECTANGLE;

export type SelectToolConfig = {
  type: ToolType.SELECT;
  shouldGenerateConnections?: boolean;
};
export const isSelectToolConfig = (
  tool: ToolConfig
): tool is SelectToolConfig => tool.type === ToolType.SELECT;

export type StickyToolConfig = {
  type: ToolType.STICKY;
} & {
  width: number;
  height: number;
  padding?: number;
  color?: string;
  backgroundColor?: string;
  borderRadius?: number;
  borderColor?: string;
  borderWidth?: number;
  lineHeight?: number;
  shadowColor?: string;
  shadowBlur?: number;
  shadowOffset?: {
    x: number;
    y: number;
  };
  fontSize?: FontSize;
};

export const isStickyToolConfig = (
  tool: ToolConfig
): tool is StickyToolConfig => tool.type === ToolType.STICKY;

export type TextToolConfig = {
  type: ToolType.TEXT;
} & {
  fontSize?: PixelUnit;
  fill?: string;
};
export const isTextToolConfig = (tool: ToolConfig): tool is TextToolConfig =>
  tool.type === ToolType.TEXT;

// TODO: separate styles for root and parent nodes?
export type CauseMapToolConfig = { type: ToolType.CAUSE_MAP } & {
  width: number;
  height: number;
  padding?: number;
  color?: string;
  backgroundColor?: string;
  borderRadius?: number;
  borderColor?: string;
  borderWidth?: number;
  lineHeight?: number;
  shadowColor?: string;
  shadowBlur?: number;
  shadowOffset?: {
    x: number;
    y: number;
  };
  fontSize?: FontSize;
};

export const isCauseMapToolConfig = (
  tool: ToolConfig
): tool is CauseMapToolConfig => tool.type === ToolType.CAUSE_MAP;

export type ToolConfigByToolType = {
  [ToolType.ELLIPSE]: EllipseToolConfig;
  [ToolType.IMAGE]: ImageToolConfig;
  [ToolType.LINE]: LineToolConfig;
  [ToolType.POLYLINE]: PolylineToolConfig;
  [ToolType.RECTANGLE]: RectangleToolConfig;
  [ToolType.STICKY]: StickyToolConfig;
  [ToolType.TEXT]: TextToolConfig;
  [ToolType.PAN]: PanToolConfig;
  [ToolType.SELECT]: SelectToolConfig;
  [ToolType.CAUSE_MAP]: CauseMapToolConfig;
};

export type ToolConfig =
  | EllipseToolConfig
  | ImageToolConfig
  | LineToolConfig
  | PanToolConfig
  | PolylineToolConfig
  | RectangleToolConfig
  | SelectToolConfig
  | StickyToolConfig
  | TextToolConfig
  | CauseMapToolConfig;
