import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import {
  eventResultsAtom,
  timeseriesAtom,
  useScheduledCalculationDataValue,
} from '@cognite/charts-lib';

const useIsLoadingChartData = (): boolean => {
  const timeseriesData = useRecoilValue(timeseriesAtom);
  const eventData = useRecoilValue(eventResultsAtom);
  const scheduledCalculationsData = useScheduledCalculationDataValue();

  const isTimeseriesDataLoading = useMemo(
    () => timeseriesData.some(({ loading }) => loading === true),
    [timeseriesData]
  );
  const isEventDataLoading = useMemo(
    () => eventData.some(({ isLoading }) => isLoading === true),
    [eventData]
  );
  const isScheduledCalculationsDataLoading = useMemo(
    () =>
      Object.values(scheduledCalculationsData).some(
        ({ loading }) => loading === true
      ),
    [scheduledCalculationsData]
  );

  const isLoadingData = useMemo(
    () =>
      isTimeseriesDataLoading ||
      isEventDataLoading ||
      isScheduledCalculationsDataLoading,
    [
      isTimeseriesDataLoading,
      isEventDataLoading,
      isScheduledCalculationsDataLoading,
    ]
  );

  return isLoadingData;
};

export default useIsLoadingChartData;
