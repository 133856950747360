import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { useRecoilState } from 'recoil';
import { useDebounce } from 'use-debounce';

import { DatapointsMultiQuery } from '@cognite/sdk-beta';

import { CHART_POINTS_PER_SERIES } from '../constants';
import { chartAtom, timeseriesAtom } from '../models';
import { fetchRawOrAggregatedDatapoints } from '../services/cdf-api';
import { ChartTimeSeries } from '../types';
import { calculateGranularity } from '../utils';
import { useBetaSDK } from '../utils/betaSDKProvider';

export function TimeseriesCollectionEffects({
  aggregationDataPointLimit,
}: {
  aggregationDataPointLimit: number;
}) {
  const [chart] = useRecoilState(chartAtom);

  const timeseriesEffectElements = chart?.timeSeriesCollection?.map(
    (timeseries) => (
      <TimeseriesEffects
        key={timeseries.id}
        timeseries={timeseries}
        aggregationDataPointLimit={aggregationDataPointLimit}
      />
    )
  );

  return <>{timeseriesEffectElements}</>;
}

function TimeseriesEffects({
  timeseries,
  aggregationDataPointLimit,
}: {
  timeseries: ChartTimeSeries;
  aggregationDataPointLimit: number;
}) {
  const { tsExternalId } = timeseries;
  const [chart] = useRecoilState(chartAtom);
  const { dateFrom, dateTo } = chart!;
  const [, setLocalTimeseries] = useRecoilState(timeseriesAtom);
  const sdkBeta = useBetaSDK();

  const [debouncedRange] = useDebounce({ dateFrom, dateTo }, 50, {
    equalityFn: (l, r) => isEqual(l, r),
  });

  const query: DatapointsMultiQuery = {
    items: [
      {
        externalId: tsExternalId || '',
        includeStatus: true,
        ignoreBadDataPoints: false,
        treatUncertainAsBad: false,
      },
    ],
    start: dayjs(debouncedRange.dateFrom!).toDate(),
    end: dayjs(debouncedRange.dateTo!).toDate(),
    granularity: calculateGranularity(
      [
        dayjs(debouncedRange.dateFrom!).valueOf(),
        dayjs(debouncedRange.dateTo!).valueOf(),
      ],
      CHART_POINTS_PER_SERIES
    ),
    aggregates: [
      'average',
      'min',
      'max',
      'count',
      'sum',
      'countGood',
      'countBad',
      'countUncertain',
    ],
    limit: CHART_POINTS_PER_SERIES,
  };

  const {
    data: timeseriesData,
    isFetching,
    isSuccess,
  } = useQuery(
    ['chart-data', 'timeseries', timeseries.tsExternalId, query],
    () =>
      fetchRawOrAggregatedDatapoints(sdkBeta, query, aggregationDataPointLimit),
    {
      enabled: !!timeseries.tsExternalId,
    }
  );

  useEffect(() => {
    setLocalTimeseries((timeseriesCollection) => {
      const existingEntry = timeseriesCollection.find(
        (entry) => entry.externalId === timeseries.tsExternalId
      );

      const output = timeseriesCollection
        .filter((entry) => entry.externalId !== timeseries.tsExternalId)
        .concat({
          externalId: timeseries.tsExternalId || '',
          loading: isFetching,
          series: isSuccess ? timeseriesData : existingEntry?.series,
        });

      return output;
    });
  }, [
    isSuccess,
    isFetching,
    timeseriesData,
    setLocalTimeseries,
    timeseries.id,
    timeseries.tsExternalId,
  ]);

  return null;
}
