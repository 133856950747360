import { useAssignedTo } from '@infield/features/assignment/hooks';
import type { FC } from 'react';

import { ActivityCreateChecklistModalContent } from './activity-create-checklist-modal-content';

interface Props {
  visible: boolean;
  title: string;
  subtitle?: string;
  saveDescription: string;
  assignees?: string[];
  isCreateChecklistLoading: boolean;
  onSave: (startTime: string, endTime: string, assignedTo?: string[]) => void;
  onClose: () => void;
}

export const ActivityCreateChecklistModal: FC<Props> = ({
  visible,
  title,
  subtitle,
  saveDescription,
  assignees,
  isCreateChecklistLoading,
  onSave: onCreateChecklist,
  onClose,
}) => {
  const { data: activityAssignees } = useAssignedTo(assignees ?? []);

  return (
    <ActivityCreateChecklistModalContent
      visible={visible}
      title={title}
      subtitle={subtitle}
      saveDescription={saveDescription}
      activityAssignees={activityAssignees}
      isCreateChecklistLoading={isCreateChecklistLoading}
      onSave={onCreateChecklist}
      onClose={onClose}
    />
  );
};
