import type { FdmFile } from '@cognite/apm-client';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

export const convertFilesToCorrectFormat = (
  files?: string[] | (FdmFile | null)[]
) => {
  if (!files) {
    return undefined;
  }
  const firstFile = files && files[0];
  if (!firstFile) {
    return [];
  }
  if (isString(firstFile)) {
    return files;
  }
  if (isObject(firstFile)) {
    return (files as FdmFile[])
      .filter((file) => file?.externalId)
      .map((file) => file.externalId);
  }
};
