import type { UnifiedViewer } from '../UnifiedViewer';
import assertNever from '../utils/assertNever';

import InteractionMode from './InteractionMode';
import Interactions from './Interactions';
import MultiDocumentInteractions from './MultiDocumentInteractions';
import SingleDocumentInteractions from './SingleDocumentInteractions';

const getInteractionsHandler = (
  interactionMode: InteractionMode,
  unifiedViewer: UnifiedViewer
): Interactions => {
  switch (interactionMode) {
    case InteractionMode.SCROLL_TO_PAN: {
      return new SingleDocumentInteractions(unifiedViewer);
    }

    case InteractionMode.SCROLL_TO_ZOOM: {
      return new MultiDocumentInteractions(unifiedViewer);
    }

    default:
      assertNever(
        interactionMode,
        `Unknown interaction mode: ${interactionMode}`
      );
  }
};

export default getInteractionsHandler;
