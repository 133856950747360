import {
  CollapseAlternativeIcon,
  ExpandAlternativeIcon,
  Flex,
  MergedChartIcon,
  MinMaxValueIcon,
  YAxisIcon,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import type { FunctionComponent } from 'react';

import * as S from './elements';
import { TrendsDomainSelector } from './trends-domain-selector';

const SHOW_UNIMPLEMENTED_FEATURE = false;

export type Props = {
  selectedDomain: string;
  yAxisShown: boolean;
  live: boolean;
  alarmLimitsShown: boolean;
  isFullView: boolean;
  containerWidth: number;
  onDomainClick: (value: string) => void;
  onLiveClick: (e: React.MouseEvent) => void;
  onYAxisToggle: () => void;
  onToggleFullscreen: () => void;
  onCompareTrendsClick: () => void;
  onShowAlarmLimitsToggle: () => void;
};

export const TrendControls: FunctionComponent<Props> = ({
  selectedDomain,
  live,
  isFullView,
  yAxisShown,
  alarmLimitsShown,
  containerWidth,
  onDomainClick,
  onLiveClick,
  onYAxisToggle,
  onToggleFullscreen,
  onCompareTrendsClick,
  onShowAlarmLimitsToggle,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.trends);
  const isDesktop = useIsDesktop();

  return (
    <S.Wrapper containerWidth={containerWidth}>
      <TrendsDomainSelector
        selectedDomain={selectedDomain}
        live={live}
        onDomainClick={onDomainClick}
        onLiveClick={onLiveClick}
      />
      <Flex alignItems="center" gap={8}>
        {/* This feature is not implemented yet */}
        {SHOW_UNIMPLEMENTED_FEATURE && (
          <S.ControlButton
            type={alarmLimitsShown ? 'primary' : 'secondary'}
            icon={<MinMaxValueIcon />}
            onClick={onShowAlarmLimitsToggle}
            aria-label={t('showAlarmLimits', 'Show alarm limits')}
          >
            {isDesktop && t('showAlarmLimits', 'Show alarm limits')}
          </S.ControlButton>
        )}
        {/* This feature is not implemented yet */}
        {SHOW_UNIMPLEMENTED_FEATURE && (
          <S.ControlButton
            icon={<MergedChartIcon />}
            onClick={onCompareTrendsClick}
            aria-label={t('compareTrends', 'Compare trends')}
          >
            {isDesktop && t('compareTrends', 'Compare trends')}
          </S.ControlButton>
        )}
        <S.YAxisButton
          type={yAxisShown ? 'primary' : 'secondary'}
          icon={<YAxisIcon />}
          onClick={onYAxisToggle}
          aria-label={t('toggleYAxis', 'Toggle y-axis')}
        >
          {t('toggleYAxis', 'Toggle y-axis')}
        </S.YAxisButton>
        {isFullView && (
          <S.ControlButton
            icon={<CollapseAlternativeIcon />}
            iconPlacement="right"
            onClick={onToggleFullscreen}
          >
            {t('TRENDS_CONTROL_EXIT_FULL_VIEW', 'Exit full view')}
          </S.ControlButton>
        )}
        {!isFullView && (
          <S.ControlButton
            icon={<ExpandAlternativeIcon />}
            iconPlacement="right"
            onClick={onToggleFullscreen}
          >
            {t('TRENDS_CONTROL_FULL_VIEW', 'Full view')}
          </S.ControlButton>
        )}
      </Flex>
    </S.Wrapper>
  );
};
