import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

interface ComponentContainerProps {
  noShadow?: boolean;
}

export const ComponentContainer = styled(Flex).attrs({
  direction: 'column',
  gap: 8,
})<ComponentContainerProps>`
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme['surface--muted']};
  box-shadow: ${({ noShadow }) =>
    noShadow
      ? 'none'
      : '0px 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(0, 0, 0, 0.2)'};
`;
