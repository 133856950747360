/**
 * NOTE(eiriklv): When working with large arrays (>50K) you will hit the argument limit
 * when doing things like Math.min(...data), causing a maximum callstack error.
 * The solution is to use a different approach where you handle less arguments (like with reduce)
 * Ref: https://stackoverflow.com/questions/63705432/maximum-call-stack-size-exceeded-when-using-the-dots-operator
 */
export function getArrayMin(array: number[]) {
  return array.reduce(
    (currentMin, currentVal) => Math.min(currentMin, currentVal),
    Infinity
  );
}

/**
 * NOTE(eiriklv): When working with large arrays (>50K) you will hit the argument limit
 * when doing things like Math.min(...data), causing a maximum callstack error.
 * The solution is to use a different approach where you handle less arguments (like with reduce)
 * Ref: https://stackoverflow.com/questions/63705432/maximum-call-stack-size-exceeded-when-using-the-dots-operator
 */
export function getArrayMax(array: number[]) {
  return array.reduce(
    (currentMax, currentVal) => Math.max(currentMax, currentVal),
    -Infinity
  );
}
