import { Body, Flex, Input } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

import * as S from './elements';
import { RootLocationDropdown } from './root-locations-dropdown';

interface Props {
  hasAppConfigPermission: boolean;
  assetExternalId: string;
  displayName: string;
  disableInputs?: boolean;
  isValidRoot?: boolean;
  isValidDisplayName?: boolean;
  onAssetSelect: (rootLocationExternalId: string) => void;
  onAssetExternalIdChange: (externalId: string) => void;
  onDisplayNameChange: (displayName: string) => void;
}

export const RootLocationConfig: FC<Props> = ({
  hasAppConfigPermission,
  assetExternalId,
  displayName,
  disableInputs,
  isValidRoot,
  isValidDisplayName,
  onAssetSelect,
  onAssetExternalIdChange,
  onDisplayNameChange,
}) => {
  return (
    <Flex direction="column" gap={8}>
      <Body size="x-small" strong>
        Asset Name
      </Body>
      <RootLocationDropdown
        disableInputs={disableInputs}
        hasAppConfigPermission={hasAppConfigPermission}
        onAssetChange={(asset) => onAssetSelect(asset.externalId!)}
        externalId={assetExternalId}
      />
      <Body size="x-small" strong>
        Asset External ID
      </Body>
      <Input
        disabled={disableInputs}
        fullWidth
        value={assetExternalId}
        onChange={(e) => onAssetExternalIdChange(e.target.value)}
      />
      {!isValidRoot && !disableInputs && (
        <S.NotValidRoot>This is not a valid asset</S.NotValidRoot>
      )}
      <Body size="x-small" strong>
        Display name
      </Body>
      <Input
        disabled={disableInputs}
        fullWidth
        value={displayName}
        onChange={(e) => onDisplayNameChange(e.target.value)}
      />
      {!isValidDisplayName && !disableInputs && (
        <S.NotValidRoot>Display name needs to be unique</S.NotValidRoot>
      )}
    </Flex>
  );
};
