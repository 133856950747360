import type { Operation } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

const OPERATIONS_PAGE_SIZE = 1000;
// while loops are dangerous so this is to make sure we don't accidentally create an infinite loop
const MAX_NUMBER_OF_REQUESTS = 10;

export const useSubActivitiesListQuery = (
  activityExternalIds: string[] | undefined
) => {
  const { activityService } = useFDMServices();
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  return useQuery<Operation[] | undefined>(
    [QueryKeys.SUB_ACTIVITIES_LIST, activityExternalIds],
    async () => {
      if (activityExternalIds && activityExternalIds?.length > 0) {
        const allOperations: Operation[] = [];
        let previouslyReturnedOperations = 0;
        let previouslyReturnedCursor: string | undefined;
        let numberOfRequests = 0;

        while (
          numberOfRequests < MAX_NUMBER_OF_REQUESTS &&
          (previouslyReturnedOperations === 0 ||
            (previouslyReturnedOperations === OPERATIONS_PAGE_SIZE &&
              Boolean(previouslyReturnedCursor)))
        ) {
          const { operations, nextCursor } =
            // I intentionally want to run them consequitively to avoid limit on concurrent requests
            // eslint-disable-next-line no-await-in-loop
            await activityService.getOperationsWithNextCursor(
              {
                and: [
                  {
                    in: {
                      property: 'parentActivityId',
                      in: activityExternalIds,
                    },
                  },
                ],
              },
              previouslyReturnedCursor,
              OPERATIONS_PAGE_SIZE
            );
          previouslyReturnedCursor = nextCursor;
          previouslyReturnedOperations = operations.length;
          numberOfRequests += 1;
          allOperations.push(...operations);
        }

        return allOperations;
      }
    },
    {
      onError: (err) => {
        makeToast({
          type: 'danger',
          body: t(
            'CHECKLIST_LIST_QUERY_ERROR',
            'Failed to fetch sub-activities'
          ),
        });
        captureException(err, {
          level: 'warning',
          tags: {
            queryKey: QueryKeys.SUB_ACTIVITIES_LIST,
          },
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: activityExternalIds && activityExternalIds.length > 0,
    }
  );
};
