import type { APMAppDataServiceOptions } from '../apm-app-data-service';
import { APMAppDataService } from '../apm-app-data-service';
import type { ServiceContract } from '../types';
import { VIEW_VERSIONS } from '../utils';

import type { ChecklistResponseType } from './types';

export class ChecklistsService extends APMAppDataService<ChecklistResponseType> {
  constructor(contract: ServiceContract, options: APMAppDataServiceOptions) {
    super(contract, 'Checklist', VIEW_VERSIONS.CHECKLIST, options);
  }
}
