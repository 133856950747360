import type { TaskInterval, TemplateItem } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { Button, Flex } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { IntervalList } from '@infield/features/task/interval-list';
import {
  useIntervalsCreate,
  useIntervalsDelete,
} from '@infield/features/task/interval-list/hooks';
import { getDefaultIntervalSettings } from '@infield/features/task/interval-list/utils';
import type { FC } from 'react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';

import * as S from './elements';

export type Props = {
  tasks: TemplateItem[];
  closeModal: () => void;
  onSubmit: () => void;
};

export const IntervalModal: FC<Props> = ({ tasks, closeModal, onSubmit }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);

  const taskExternalIds = tasks.map(({ externalId }) => externalId);

  const previousIntervals = tasks.flatMap(({ schedules }) => schedules || []);

  const [draftIntervals, setDraftIntervals] = useState<TaskInterval[]>([
    getDefaultIntervalSettings(),
  ]);

  const { mutateAsync: createIntervals, isLoading: isIntervalsCreateLoading } =
    useIntervalsCreate();
  const {
    mutateAsync: deleteIntervals,
    isLoading: isIntervalsDeletetLoading,
    isSuccess: isIntervalsDeleteSuccess,
  } = useIntervalsDelete();

  const [isUpsertTriggeredFromAddButton, setIsUpsertTriggeredFromAddButton] =
    useState(false);

  const isSaving = isIntervalsCreateLoading || isIntervalsDeletetLoading;

  const isReplacingIntervals =
    (isIntervalsCreateLoading || isIntervalsDeletetLoading) &&
    !isUpsertTriggeredFromAddButton;

  const isAddingIntervals =
    isIntervalsCreateLoading && isUpsertTriggeredFromAddButton;

  const checkIfReplaceIsHidden = () => {
    if (isIntervalsCreateLoading) {
      return previousIntervals.length === 0 && !isIntervalsDeleteSuccess;
    }
    return previousIntervals.length === 0;
  };

  const isReplaceButtonHidden = checkIfReplaceIsHidden();

  const addInterval = () => {
    const newDraftInterval = getDefaultIntervalSettings();
    setDraftIntervals((prevState) => [...prevState, newDraftInterval]);
  };

  const removeInterval = (intervalToRemove: TaskInterval) => {
    setDraftIntervals((prevState) =>
      prevState.filter((c) => c.externalId !== intervalToRemove.externalId)
    );
  };

  const updateInterval = (intervalToUpdate: TaskInterval) => {
    setDraftIntervals((prevState) => {
      const previousInterval = prevState.find(
        ({ externalId }) => externalId === intervalToUpdate.externalId
      );
      if (previousInterval) {
        return prevState.map((interval) => {
          if (interval.externalId === intervalToUpdate.externalId) {
            return {
              ...interval,
              ...intervalToUpdate,
            };
          }
          return interval;
        });
      }
      return [...prevState, intervalToUpdate];
    });
  };

  const saveIntervals = () => {
    draftIntervals.forEach((interval) => {
      const newIntervals = taskExternalIds.map((taskExternalId) => ({
        taskExternalId,
        intervals: [
          {
            ...interval,
            // update draftInterval with proper ids for each task
            externalId: uuid(),
          },
        ],
      }));
      createIntervals(
        {
          newIntervals,
        },
        {
          onSuccess: () => {
            makeToast({
              body: t(
                'TEMPLATE_TASKS_INTERVAL_MODAL_ADD_INTERVALS_SUCCESS',
                'You have added {{intervalCount}} intervals for {{taskCount}} tasks',
                {
                  intervalCount: draftIntervals.length,
                  taskCount: tasks.length,
                }
              ),
              type: 'success',
            });
            onSubmit();
          },
        }
      );
    });
  };

  const replaceExistingIntervals = () => {
    deleteIntervals(
      { intervalsToDelete: previousIntervals },
      {
        onSuccess: () => {
          saveIntervals();
        },
        onError: () => {
          makeToast({
            body: t(
              'TEMPLATE_TASKS_INTERVAL_MODAL_REPLACE_EXISTING_INTERVALS_ERROR',
              'Failed to replace existing intervals on {{taskCount}} tasks with new ones',
              {
                taskCount: tasks.length,
              }
            ),
            type: 'danger',
          });
        },
      }
    );
  };

  const handleOnAddClick = () => {
    setIsUpsertTriggeredFromAddButton(true);
    saveIntervals();
  };

  return (
    <S.StyledModal
      size="small"
      visible
      title={t(
        'TEMPLATE_TASKS_INTERVAL_MODAL_TITLE',
        'Add intervals for {{count}} tasks',
        {
          count: tasks.length,
        }
      )}
      onCancel={closeModal}
      hideFooter
    >
      <S.StyledModalContent data-testid="interval-modal-content">
        <IntervalList
          // We are handling upsert in interval-modal component therefore we don't need to pass taskExternalId
          taskExternalId=""
          intervals={draftIntervals}
          addInterval={addInterval}
          updateInterval={updateInterval}
          removeInterval={removeInterval}
          disableLastItemRemoval
        />
      </S.StyledModalContent>
      <S.StyledCustomFooter
        justifyContent={!isReplaceButtonHidden ? 'space-between' : 'end'}
      >
        {!isReplaceButtonHidden && (
          <Button
            type="ghost"
            data-testid="interval-modal-replace-existing-button"
            onClick={replaceExistingIntervals}
            disabled={isSaving}
            loading={isReplacingIntervals}
          >
            {t(
              'TEMPLATE_TASKS_INTERVAL_MODAL_REPLACE_EXISTING_BUTTON',
              'Replace existing'
            )}
          </Button>
        )}
        <Flex gap={8}>
          <Button
            type="ghost"
            data-testid="interval-modal-cancel-button"
            disabled={isSaving}
            onClick={closeModal}
          >
            {t('TEMPLATE_TASKS_INTERVAL_MODAL_CANCEL_BUTTON', 'Cancel')}
          </Button>
          <Button
            type="primary"
            data-testid="interval-modal-add-to-task-button"
            onClick={handleOnAddClick}
            disabled={isSaving}
            loading={isAddingIntervals}
          >
            {t('TEMPLATE_TASKS_INTERVAL_MODAL_ADD_BUTTON', 'Add')}
          </Button>
        </Flex>
      </S.StyledCustomFooter>
    </S.StyledModal>
  );
};
