import { Axis3DIcon, CogsIllustration, EmptyState } from '@cognite/cogs.js-v10';
import { useAssetModels } from '@infield/features/3d';
import { ModelDetails } from '@infield/features/3d/model-details';
import { AssetCard } from '@infield/features/asset/shared/asset-card/asset-card';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';
import { createSearchParams } from 'react-router-dom';

import * as S from './elements';

interface Props {
  assetId?: number;
  assetExternalId: string;
  onOpen?: (searchParams: string) => void;
}

export const AssetTreedCard: FC<Props> = ({
  assetId,
  assetExternalId,
  onOpen,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);

  const { firstAvailableModel: activeModel } = useAssetModels(
    assetExternalId,
    assetId
  );

  const handleButtonClick = () => {
    if (onOpen && activeModel) {
      const searchParams = createSearchParams({
        modelId: activeModel?.modelId.toString(),
        revisionId: activeModel?.revisionId.toString(),
      }).toString();
      onOpen(searchParams);
    }
  };

  return (
    <AssetCard
      icon={<Axis3DIcon />}
      title={t('3d', '3D')}
      buttonText={t('open', 'Open')}
      onButtonClick={activeModel ? handleButtonClick : undefined}
    >
      <S.Wrapper>
        {activeModel ? (
          <ModelDetails
            onClick={() => null}
            revisionId={activeModel.revisionId}
            modelId={activeModel.modelId}
            onlyImage
          />
        ) : (
          <EmptyState
            illustration={<CogsIllustration />}
            title={t(
              'ASSET_OVERVIEW_THREED_CARD_MISSING_CONFIGURATION_TITLE',
              'Missing configuration'
            )}
            description={t(
              'ASSET_OVERVIEW_THREED_CARD_MISSING_CONFIGURATION_BODY',
              'Please contact your admin to complete the configuration.'
            )}
          />
        )}
      </S.Wrapper>
    </AssetCard>
  );
};
