import {
  defaultFeatureToggleConfig,
  defaultIDMFeatureToggleConfig,
} from '@infield/features/app-config/default-config';
import type { RootLocationFeatureToggles } from '@infield/features/app-config/types';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';

import { useInFieldLocationConfig } from './use-infield-location-config';

export const useFeatureToggleConfig = (rootLocationExternalId: string) => {
  const { isIdm } = useAppConfigContext();
  const { data: infieldLocationConfig, isLoading } = useInFieldLocationConfig(
    rootLocationExternalId
  );

  const defaultConfig = isIdm
    ? defaultIDMFeatureToggleConfig
    : defaultFeatureToggleConfig;

  return {
    config: (infieldLocationConfig?.featureToggles ||
      defaultConfig) as RootLocationFeatureToggles,
    isLoading,
  };
};
