import type { ChecklistItemStatus } from '@cognite/apm-client';
import { Button } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const StatusButton = styled(Button)<{
  $activeStatus?: ChecklistItemStatus;
}>`
  width: 166px;
  max-height: 36px;

  color: ${({ $activeStatus, theme }) => {
    if ($activeStatus === 'Ok') {
      return theme['text-icon--status-success'];
    }
    if ($activeStatus === 'Not ok') {
      return theme['text-icon--status-critical'];
    }
    if ($activeStatus === 'Not applicable') {
      return theme['text-icon--status-neutral'];
    }
    if ($activeStatus === 'Blocked') {
      return theme['text-icon--status-warning'];
    }
    return theme['text-icon--strong'];
  }} !important;

  background-color: ${({ $activeStatus, theme }) => {
    if ($activeStatus === 'Ok') {
      return theme['surface--status-success--muted--default'];
    }
    if ($activeStatus === 'Not ok') {
      return theme['surface--status-critical--muted--default'];
    }
    if ($activeStatus === 'Not applicable') {
      return theme['surface--status-neutral--muted--default'];
    }
    if ($activeStatus === 'Blocked') {
      return theme['surface--status-warning--muted--default'];
    }
    return theme['surface--action--muted--default'];
  }} !important;

  border: 1px solid !important;
  border-color: ${({ $activeStatus, theme }) => {
    if ($activeStatus === 'Ok') {
      return theme['border--status-success--strong'];
    }
    if ($activeStatus === 'Not ok') {
      return theme['border--status-critical--strong'];
    }
    if ($activeStatus === 'Not applicable') {
      return theme['border--status-neutral--strong'];
    }
    if ($activeStatus === 'Blocked') {
      return theme['border--status-warning--strong'];
    }
    return theme['surface--action--muted--default'];
  }} !important;
`;
