import Konva from 'konva';
import { IRect } from 'konva/cmj/types';

const getNormalizedDimensionsFromNode = (
  node: Konva.Group | Konva.Rect | Konva.Image | Konva.Path,
  getContainerRectById: (id: string) => IRect | undefined
): { x: number; y: number; width: number; height: number } => {
  const useNormalizedCoordinates = node.attrs.containerId !== undefined;

  if (!useNormalizedCoordinates) {
    return {
      x: node.x(),
      y: node.y(),
      width: node.width(),
      height: node.height(),
    };
  }

  const containerRect = getContainerRectById(node.attrs.containerId);
  if (containerRect === undefined) {
    throw new Error('Could not find container dimensions for annotation');
  }

  return {
    x: node.x() / containerRect.width,
    y: node.y() / containerRect.height,
    width: node.width() / containerRect.width,
    height: node.height() / containerRect.height,
  };
};

export default getNormalizedDimensionsFromNode;
