import * as THREE from 'three';

import {
  CogniteCadModel,
  CogniteModel,
  CognitePointCloudModel,
} from '@cognite/reveal';
import { CogniteClient } from '@cognite/sdk';

import { AssetAcdmInstance } from '../../..';

import getAssetAcdmInstanceBoundingBoxForCadModel from './getAssetAcdmInstanceBoundingBoxForCadModel';
import getAssetAcdmInstanceBoundingBoxForPointCloudModel from './getAssetAcdmInstanceBoundingBoxForPointCloudModel';

const getInstanceBoundingBox = async (
  client: CogniteClient,
  model: CogniteModel,
  modelId: number,
  revisionId: number,
  instance: AssetAcdmInstance | undefined
): Promise<THREE.Box3 | undefined> => {
  if (instance === undefined) {
    return undefined;
  }

  /**
   * Notes for future travellers:
   *  Mappings for assets to nodes in CogniteCadModel are storing and provided by a 3D-specific
   *  api, whereas for CognitePointCloudModel, the mappings are stored in the Annotations API.
   */

  if (model instanceof CogniteCadModel) {
    return getAssetAcdmInstanceBoundingBoxForCadModel(
      client,
      model,
      modelId,
      revisionId,
      instance.id
    );
  }

  if (model instanceof CognitePointCloudModel) {
    return getAssetAcdmInstanceBoundingBoxForPointCloudModel(
      client,
      model,
      modelId,
      instance.id
    );
  }

  throw new Error('Unsupported model type');
};

export default getInstanceBoundingBox;
