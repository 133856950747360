import Konva from 'konva';

import type { Position } from '../../annotations/types';
import assertNever from '../../utils/assertNever';
import { AnchorName } from '../Anchor/constants';

import { PREVIEW_NODE_OFFSET_PX } from './constants';

export const getPreviewNodeOffset = (
  node: Konva.Label,
  name: AnchorName
): Position => {
  if (name === 'top-center') {
    return { x: 0, y: -(node.height() + PREVIEW_NODE_OFFSET_PX) };
  }
  if (name === 'middle-left') {
    return { x: -(node.width() + PREVIEW_NODE_OFFSET_PX), y: 0 };
  }
  if (name === 'middle-right') {
    return { x: node.width() + PREVIEW_NODE_OFFSET_PX, y: 0 };
  }
  if (name === 'bottom-center') {
    return { x: 0, y: node.height() + PREVIEW_NODE_OFFSET_PX };
  }
  assertNever(name, 'Unknown anchor name provided. This should not happen.');
};
