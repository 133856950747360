import { use3dModels } from '@cognite/reveal-react-components';
import type { AnnotationFilterProps } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const usePointcloudAnnotationByAssetIdQuery = (assetId?: number) => {
  const { threeDService } = useFDMServices();
  const model = use3dModels()[0];

  return useQuery(
    [
      QueryKeys.THREE_D,
      QueryKeys.POINT_CLOUD_ANNOTATIONS,
      assetId,
      model?.modelId,
    ],
    () => {
      const annotationFilterProps: AnnotationFilterProps = {
        annotatedResourceType: 'threedmodel',
        annotatedResourceIds: [{ id: model.modelId }],
        annotationType: 'pointcloud.BoundingVolume',
        data: {
          assetRef: { id: assetId },
        },
      };
      return threeDService
        .getAnnotations(annotationFilterProps)
        .then((res) => res.items)
        .catch((error) => {
          captureException(error, {
            level: 'warning',
            tags: {
              queryKey: 'pointcloud-annotation',
            },
          });
          return undefined;
        });
    },
    {
      enabled: Boolean(assetId) && Boolean(model?.modelId),
    }
  );
};
