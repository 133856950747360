import Konva from 'konva';

import isContainerNode from '../utils/isContainerNode';

import { CONTAINER_REF_KEY } from './constants';
import { Container } from './Container';

const getContainerFromContainerNode = (
  node: Konva.Node
): Container | undefined => {
  if (!isContainerNode(node)) {
    return undefined;
  }

  const container = node.getAttr(CONTAINER_REF_KEY);
  if (container === undefined) {
    throw new Error('Container node does not have a container reference');
  }

  return container;
};

export default getContainerFromContainerNode;
