import { Accordion, Divider } from '@cognite/cogs.js-v10';
import type { Timeseries } from '@cognite/sdk';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import * as S from './elements';
import { TrendsListPanelContent } from './trends-list-panel-content';

export interface Props {
  isLoading: boolean;
  // highlighted TimeSeries
  timeseries: Timeseries[];
  // other timeseries
  otherTimeseries: Timeseries[];
  selectedTimeseriesExternalIds: string[];
  onToggleSelectedTimeseries: (tsExternalId: string) => void;
}

// the list of all timeseries available on /trend page
export const TrendsList: FC<Props> = ({
  isLoading,
  timeseries,
  otherTimeseries,
  selectedTimeseriesExternalIds,
  onToggleSelectedTimeseries,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.trends);

  return (
    <S.TrendsListWrapper>
      <Accordion
        expanded
        type="ghost"
        title={t('highlightedTimeseries', 'Highlighted time series')}
      >
        <TrendsListPanelContent
          isLoading={isLoading}
          timeseries={timeseries}
          selectedTimeseriesExternalIds={selectedTimeseriesExternalIds}
          onToggleSelectedTimeseries={onToggleSelectedTimeseries}
        />
      </Accordion>
      <Divider />
      <Accordion
        expanded
        type="ghost"
        title={t('otherTimeseriesOnThisTag', 'Other timeseries on this tag')}
      >
        <TrendsListPanelContent
          isLoading={isLoading}
          timeseries={otherTimeseries}
          selectedTimeseriesExternalIds={selectedTimeseriesExternalIds}
          onToggleSelectedTimeseries={onToggleSelectedTimeseries}
        />
      </Accordion>
    </S.TrendsListWrapper>
  );
};
