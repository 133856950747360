import type { Activity } from '@cognite/apm-client';
import { Flex, TagChip } from '@cognite/cogs.js-v10';
import * as S from '@infield/features/asset/elements';
import type { ListItem } from '@infield/features/asset/shared';
import { StyledList } from '@infield/features/asset/shared';
import type { FC } from 'react';

type Props = {
  activities: Activity[];
  onActivityClick: (activity: string) => void;
};

export const AssetActivitiesList: FC<Props> = ({
  activities,
  onActivityClick,
}) => {
  const items = (): ListItem[] => {
    return activities.map((activity) => {
      return {
        key: String(activity.id),
        onClick: () => onActivityClick(activity.externalId!),
        content: (
          <>
            <Flex justifyContent="space-between" alignItems="center" gap={3}>
              <S.ItemTitle>{activity.title || '-'}</S.ItemTitle>
              {activity.status && (
                <div>
                  <TagChip size="x-small" label={activity.status} />
                </div>
              )}
            </Flex>
            <S.ItemDescription size="medium">
              {activity.type} - {activity.id}
            </S.ItemDescription>
          </>
        ),
      };
    });
  };

  return <StyledList items={items()} />;
};
