import React, { PropsWithChildren } from 'react';

import { Flex } from '@cognite/cogs.js-v10';

const TabContentContainer = ({ children }: PropsWithChildren) => (
  <Flex direction="column" gap={24}>
    {children}
  </Flex>
);

const TabContentBody = ({ children }: PropsWithChildren) => (
  <Flex direction="column" gap={24}>
    {children}
  </Flex>
);

export const TabContent = () => <></>;
TabContent.Container = TabContentContainer;
TabContent.Body = TabContentBody;
