import styled from 'styled-components';

export const MobileAssetInfoSection = styled.div`
  padding: 12px;
  background: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px 7px 0 0;
  button {
    margin: 0 0 8px 8px;
    align-self: baseline;
  }
`;

export const AssetInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
