import type { Measurement } from '@cognite/apm-client';
import { Body, Button, DeleteIcon, Flex } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  useMeasurementDelete,
  useMeasurementsUpsert,
} from '@infield/features/measurements';
import { TaskFormBlockWrapper } from '@infield/features/task/task-form/elements';
import { useOnClickOutside } from '@infield/features/ui';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import * as S from './elements';

type Props = {
  measurement: Measurement;
  taskExternalId: string;
};

export const MeasurementMessageConfiguration: FC<Props> = ({
  measurement,
  taskExternalId,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);

  const messageRef = useRef<HTMLTextAreaElement>(null);
  const [message, setMessage] = useState<string>(measurement.title || '');
  const [isMessageFocused, setIsMessageFocused] = useState<boolean>(false);

  const { mutateAsync: upsertMeasurement } = useMeasurementsUpsert();

  const { mutateAsync: deleteMeasurement, isLoading: isDeletingMeasurement } =
    useMeasurementDelete();

  useEffect(() => {
    if (isMessageFocused) return;
    setMessage(measurement.title || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurement.title]);

  const handleOnClickOutside = (event: MouseEvent | TouchEvent) => {
    if (messageRef.current) {
      messageRef.current.blur();
      event.stopPropagation();
    }
  };

  useOnClickOutside(messageRef, handleOnClickOutside);

  const handleMessageOnBlur = () => {
    if (measurement.title !== message) {
      upsertMeasurement({
        measurementToUpsert: [
          { externalId: measurement.externalId, title: message },
        ],
      });
    }

    setIsMessageFocused(false);
  };

  return (
    <Flex direction="column" gap={12}>
      <TaskFormBlockWrapper>
        <Flex gap={6} direction="column">
          <Body strong size="medium">
            {t(
              'TEMPLATE_TASK_FORM_MESSAGE_DESCRIPTION',
              'Enter a question or message that requires a reply'
            )}
          </Body>
          <S.MessageContainer
            onBlur={handleMessageOnBlur}
            onFocus={() => setIsMessageFocused(true)}
          >
            <S.MessageTextarea
              ref={messageRef}
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t(
                'TEMPLATE_TASK_FORM_MESSAGE_PLACEHOLDER',
                "e.g. Provide the car's plate number"
              )}
            />
          </S.MessageContainer>
          <Flex justifyContent="flex-end">
            <Button
              icon={<DeleteIcon />}
              type="tertiary"
              loading={isDeletingMeasurement}
              onClick={() =>
                deleteMeasurement({
                  measurementExternalIds: [measurement.externalId],
                  taskExternalId,
                })
              }
            >
              {t('TEMPLATE_TASK_FORM_CONDITION_REMOVE_BUTTON', 'Remove')}
            </Button>
          </Flex>
        </Flex>
      </TaskFormBlockWrapper>
    </Flex>
  );
};
