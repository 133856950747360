import type { LocationFilterDTO } from '@cognite/apm-config';
import { ADSLocationFilterSDK } from '@cognite/apm-config';
import { makeToast } from '@cognite/cogs-lab';
import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useADSDeleteLocation = () => {
  const coreAuth = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, Error, { location: LocationFilterDTO }>(
    async ({ location }) => {
      const locationConfigClient = new ADSLocationFilterSDK(coreAuth.client);

      await locationConfigClient.deleteLocationFilter(location.id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.ADS_LOCATION_CONFIG],
        });
      },
      onError: (err) => {
        makeToast({
          body: 'Failed to delete the location. Make sure that you have permissions to create/edit configs.',
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-delete-ads-location-config',
          },
        });
      },
    }
  );
};
