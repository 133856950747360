import styled from 'styled-components';

export const MobileAssetName = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme['text-icon--interactive--default']};
  cursor: pointer;
`;

export const AssetTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
