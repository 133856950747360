import { useAuthContext } from '@cognite/e2e-auth';
import type { Timeseries } from '@cognite/sdk';
import { DatapointService } from '@infield/features/timeseries/datapoint-service';
import { useFDMServices } from '@infield/providers/fdm-services';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { useEffect, useMemo, useState } from 'react';

import type { Domain } from '../../trends-chart/types';

import { CogniteLoaders } from './cognite-loaders';
import { getYDomain } from './utils';

type Props = {
  ySubDomains: Record<string, Domain | undefined>;
  setYSubDomains: (newDomains: Record<string, Domain | undefined>) => void;
  isLive: boolean;
  buttonClicked: boolean;
  timeseries: Timeseries[];
};

export const useTimeseriesChart = (props: Props) => {
  const { isIdm } = useAppConfigContext();

  const [rulerTimestamp, setRulerTimestamp] = useState<number | null>(
    Date.now()
  );

  const [error, setError] = useState<Error>();

  const handleLoaderError = (error: Error) => {
    console.error(error);
    setError(error);
  };

  const { apmClient } = useFDMServices();

  // TODO(INFIELD2-721): use service/hook approach if possible
  const { client } = useAuthContext();

  const datapointService = new DatapointService(client);
  const loader = CogniteLoaders(
    handleLoaderError,
    datapointService.retrieve(isIdm, apmClient.timeseries?.defaultInstanceSpace)
  );

  const onXSubDomainChange = (_: Domain) => {
    setRulerTimestamp(props.buttonClicked ? Date.now() : rulerTimestamp);
  };

  const timeseriesWithYSubDomains = useMemo(
    () =>
      props.timeseries.map((ts) => ({
        ...ts,
        ySubDomain: props.ySubDomains[ts.id],
      })),
    [props.timeseries, props.ySubDomains]
  );

  useEffect(() => {
    if (props.timeseries.length === 1) {
      setRulerTimestamp(Date.now());
    }
  }, [props.timeseries.length]);

  // update live ruler to now if ruler is visible and live
  useEffect(() => {
    if (!props.isLive) return;

    setRulerTimestamp((prevRulerTimestamp) => {
      if (prevRulerTimestamp === null) {
        return null;
      }

      return Date.now();
    });
  }, [props.isLive]);

  const handleFetchData = (loaderConfig: any) => {
    if (!loaderConfig?.data?.length) {
      return;
    }

    // only change ySubDomain if xDomain is changed
    // we change the ySubDomain for each timeseries to display the correct yAxis
    // for each timeseries
    if (loaderConfig.reason === 'MOUNTED') {
      const tempYSubDomains = { ...props.ySubDomains };
      // if yMin === yMax, we set ySubDomain to be a range that puts the value in the middle, as the griff library does by default
      // https://github.com/cognitedata/griff-react/blob/master/src/components/DataProvider/index.js#L34
      // We set it manually here because we need this value for our custom min/max axis for trends where we are not relying on griff's axis
      // if the y-axis hasn't been changed (ie. the inital load), floor the
      // min value and ceil the max value

      const ySubDomain: Domain | undefined = getYDomain(
        loaderConfig.ySubDomain
      );

      tempYSubDomains[loaderConfig.id] = ySubDomain;
      props.setYSubDomains(tempYSubDomains);
    }
  };

  const clearRulerStates = () => {
    setRulerTimestamp(null);
  };

  const handleMouseMove = () => null;

  const handleMouseOut = () => {
    clearRulerStates();
  };

  return {
    timeseries: timeseriesWithYSubDomains,
    loader,
    handleOutsideClick: clearRulerStates,
    handleMouseMove,
    handleMouseOut,
    rulerTimestamp,
    handleFetchData,
    onXSubDomainChange,
    zoomable: true,
    isRulerVisible: true,
    error,
  };
};
