import Konva from 'konva';
import { IRect } from 'konva/lib/types';
import cloneDeep from 'lodash/cloneDeep';

import findMaxFontSizeWithoutOverflow from '../tools/StickyTool/findMaxFontSizeWithoutOverflow';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import UnifiedViewerRenderer from '../UnifiedViewerRenderer';
import shamefulSafeKonvaScale from '../utils/shamefulSafeKonvaScale';

import getMaxDiffingScalingFactor from './getMaxDiffingScalingFactor';
import setAnnotationNodeEventHandlers from './setAnnotationNodeEventHandlers';
import shouldBeDraggable from './shouldBeDraggable';
import shouldBeResizable from './shouldBeResizable';
import shouldBeSelectable from './shouldBeSelectable';
import {
  AnnotationSerializer,
  AnnotationType,
  StickyAnnotation,
  Scale,
  Size,
} from './types';

const getNormalizedStickyRectFromNode = (
  node: Konva.Label,
  unifiedViewerRenderer: UnifiedViewerRenderer
): IRect => {
  const shouldUseNormalizedCoordinates = node.attrs.containerId !== undefined;

  if (!shouldUseNormalizedCoordinates) {
    return {
      x: node.x(),
      y: node.y(),
      width: node.width(),
      height: node.height(),
    };
  }

  const containerRect =
    unifiedViewerRenderer.getContainerRectRelativeToStageById(
      node.attrs.containerId
    );
  if (containerRect === undefined) {
    throw new Error('Could not find container dimensions for annotation');
  }

  return {
    x: node.x() / containerRect.width,
    y: node.y() / containerRect.height,
    width: node.width() / containerRect.width,
    height: node.height() / containerRect.height,
  };
};

const getDenormalizedStickyRectFromAnnotation = (
  annotation: StickyAnnotation,
  unifiedViewerRenderer: UnifiedViewerRenderer
): IRect => {
  const shouldUseNormalizedCoordinates = annotation.containerId !== undefined;

  if (!shouldUseNormalizedCoordinates) {
    return {
      x: annotation.x,
      y: annotation.y,
      width: annotation.width,
      height: annotation.height,
    };
  }

  const containerRect =
    unifiedViewerRenderer.getContainerRectRelativeToStageById(
      annotation.containerId
    );
  if (containerRect === undefined) {
    throw new Error('Could not find container dimensions for annotation');
  }

  return {
    x: annotation.x * containerRect.width,
    y: annotation.y * containerRect.height,
    width: annotation.width * containerRect.width,
    height: annotation.height * containerRect.height,
  };
};

export type SizeAndPadding = Size & { padding: number };

type LabelTransformationProperties = {
  scale: Scale;
  Text: SizeAndPadding;
};

type LabelNodeProperties<MetadataType> = {
  [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION;
  annotationType: AnnotationType.STICKY;
  id: string;
  strokeScaleEnabled: false;
  userGenerated: true;
  containerId?: string;
  isSelectable: boolean;
  isDraggable: boolean;
  isResizable: boolean;
  x: number;
  y: number;
  metadata?: MetadataType;
};

type TagNodeProperties = {
  fill: string | undefined;
  stroke: string | undefined;
  strokeWidth: number | undefined;
  cornerRadius: number[] | number | undefined;
};

type TextNodeProperties = {
  text: string;
  fill: string | undefined;
  padding: number | undefined;
  width: number;
  height: number;
  align: string;
  verticalAlign: string;
  fontSize: number;
  lineHeight: number | undefined;
};

type StickyNodeProperties<MetadataType> = {
  labelNodeProperties: LabelNodeProperties<MetadataType>;
  tagNodeProperties: TagNodeProperties;
  textNodeProperties: TextNodeProperties;
};

const getNodePropertiesFromAnnotation = <MetadataType>(
  annotation: StickyAnnotation<MetadataType>,
  unifiedViewerRenderer: UnifiedViewerRenderer
): StickyNodeProperties<MetadataType> | undefined => {
  const rect = getDenormalizedStickyRectFromAnnotation(
    annotation,
    unifiedViewerRenderer
  );
  const labelNodeProperties: LabelNodeProperties<MetadataType> = {
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
    annotationType: AnnotationType.STICKY,
    id: annotation.id,
    strokeScaleEnabled: false,
    userGenerated: true,
    containerId: annotation.containerId,
    isSelectable: shouldBeSelectable(annotation.isSelectable),
    isDraggable: shouldBeDraggable(annotation.isDraggable),
    isResizable: shouldBeResizable(annotation.isResizable),
    x: rect.x,
    y: rect.y,
    metadata: annotation.metadata,
  };

  const tagNodeProperties: TagNodeProperties = {
    fill: annotation.style.backgroundColor,
    stroke: annotation.style.borderColor,
    strokeWidth: annotation.style.borderWidth,
    cornerRadius: annotation.style.borderRadius,
  };

  const textNodeProperties: TextNodeProperties = {
    text: annotation.text,
    fill: annotation.style.color,
    padding: annotation.style.padding,
    width: rect.width,
    height: rect.height,
    align: 'center',
    verticalAlign: 'middle',
    lineHeight: annotation.style.lineHeight,
    fontSize: findMaxFontSizeWithoutOverflow(annotation.text, {
      width: rect.width,
      height: rect.height,
      padding: annotation.style.padding ?? 0,
    }),
  };

  return {
    labelNodeProperties,
    tagNodeProperties,
    textNodeProperties,
  };
};

interface StickyAnnotationSerializer
  extends AnnotationSerializer<
    StickyAnnotation,
    LabelTransformationProperties
  > {
  refreshFontSize: (node: Konva.Label) => void;
}

const StickySerializer: StickyAnnotationSerializer = {
  isSelectable: (node: Konva.Node) =>
    shouldBeSelectable(node.attrs.isSelectable),

  isDraggable: (node: Konva.Node) => shouldBeDraggable(node.attrs.isDraggable),

  isResizable: (node: Konva.Node) => shouldBeResizable(node.attrs.isResizable),

  getTransformationPropertiesFromNode: (node: Konva.Node) => {
    if (!(node instanceof Konva.Label)) {
      throw new Error('Expected node to be a Konva.Label');
    }

    const textNode = node.getText();

    return {
      scale: shamefulSafeKonvaScale(node.scale()),
      Text: {
        padding: textNode.padding(),
        width: textNode.width(),
        height: textNode.height(),
      },
    };
  },

  normalize: ({ scale, Text: { padding, width, height } }) => {
    const scaleFactor = getMaxDiffingScalingFactor(scale);

    return {
      // Rescale the points to the new scale factor
      Text: {
        padding: padding * scaleFactor,
        width: width * scaleFactor,
        height: height * scaleFactor,
      },
      scale: {
        x: 1,
        y: 1,
      },
    };
  },

  serialize: (node, unifiedViewerRenderer) => {
    if (!(node instanceof Konva.Label)) {
      throw new Error('Expected node to be a Konva.Label');
    }

    const rect = getNormalizedStickyRectFromNode(node, unifiedViewerRenderer);

    const tag = node.getTag();
    const text = node.getText();
    return {
      id: node.id(),
      type: AnnotationType.STICKY,
      text: text.text(),
      containerId: node.attrs.containerId,
      isSelectable: shouldBeSelectable(node.attrs.isSelectable),
      isDraggable: shouldBeDraggable(node.attrs.isDraggable),
      isResizable: shouldBeResizable(node.attrs.isResizable),
      ...rect,
      style: {
        padding: text.padding(),
        color: text.fill(),
        lineHeight: text.lineHeight(),
        backgroundColor: tag.fill(),
        borderColor: tag.stroke(),
        borderWidth: tag.strokeWidth(),
        borderRadius: tag.cornerRadius(),
      },
      metadata: cloneDeep(node.attrs.metadata),
    };
  },

  deserialize: (annotation, unifiedViewer, unifiedViewerRenderer) => {
    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return undefined;
    }

    const label = new Konva.Label(nodeProperties.labelNodeProperties);
    label.add(new Konva.Tag(nodeProperties.tagNodeProperties));
    label.add(new Konva.Text(nodeProperties.textNodeProperties));

    return setAnnotationNodeEventHandlers(
      label,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },

  updateNode: (
    node,
    annotation,
    unifiedViewer,
    unifiedViewerRenderer
  ): void => {
    if (!(node instanceof Konva.Label)) {
      throw new Error('Expected node to be a Konva.Label');
    }

    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return;
    }

    node.setAttrs(nodeProperties.labelNodeProperties);
    node.getChildren().forEach((child) => {
      if (child instanceof Konva.Tag) {
        child.setAttrs(nodeProperties.tagNodeProperties);
      }

      if (child instanceof Konva.Text) {
        child.setAttrs(nodeProperties.textNodeProperties);
      }
    });

    setAnnotationNodeEventHandlers(
      node,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },

  refreshFontSize: (node) => {
    const textNode = node.getText();
    const fontSize = findMaxFontSizeWithoutOverflow(textNode.text(), {
      width: node.width(),
      height: node.height(),
      padding: textNode.padding(),
    });

    textNode.fontSize(fontSize);
  },
};

export default StickySerializer;
