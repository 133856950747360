import { AssetDetailsView } from '@infield/features/asset/asset-details-view';
import { ComponentScrollableContent } from '@infield/features/ui';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import * as S from './elements';

export const AssetDetails: FC = () => {
  const { assetId } = useParams();

  return (
    <S.Container>
      <ComponentScrollableContent>
        <AssetDetailsView assetExternalId={assetId} />
      </ComponentScrollableContent>
    </S.Container>
  );
};
