type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

export const truthy = <T>(value: T): value is Truthy<T> => {
  return Boolean(value);
};

export const getUniqueNonEmptyValues = <T>(array: Array<T>) => {
  return [...new Set(array)].filter(truthy);
};

export const getUniqueExternalIds = (
  inputArray?: Array<{ [key: string]: unknown; externalId?: string }>
) => {
  const allExternalIds = inputArray?.map(({ externalId }) => externalId) ?? [];

  return getUniqueNonEmptyValues(allExternalIds);
};
