import {
  Button,
  ChevronDownIcon,
  CloseLargeIcon,
  Dropdown,
  Flex,
  Menu,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { useOnClickOutside } from '@infield/features/ui';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { type FC, useRef, useState } from 'react';

import * as S from './elements';

interface Props {
  selectedTypes: string[];
  filterValues: { types: string[] };
  onClickType: (value: string) => void;
}

export const AssetTypeFilter: FC<Props> = ({
  selectedTypes,
  filterValues,
  onClickType,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const outsideClickRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const isDesktop = useIsDesktop();

  useOnClickOutside(outsideClickRef, () => setShowDropdown(false));

  const FilterMenu = showDropdown && (
    <S.StyledMenu>
      <Flex gap={8} alignItems="center" justifyContent="space-between">
        <Menu.Header>
          {t(
            'ASSET_OVERVIEW_ACTIVITIES_EXPANDED_VIEW_FILTER_TYPE_TITLE',
            'Filter options'
          )}
        </Menu.Header>

        <Button
          aria-label="close-filter-menu"
          icon={<CloseLargeIcon />}
          type="ghost"
          onClick={() => setShowDropdown(false)}
        />
      </Flex>
      {filterValues.types.map((value) => (
        <Menu.Item
          key={value}
          onClick={() => onClickType(value)}
          toggled={selectedTypes.includes(value)}
        >
          {value}
        </Menu.Item>
      ))}
    </S.StyledMenu>
  );

  const FilterButton = (
    <Button
      type="secondary"
      icon={<ChevronDownIcon />}
      iconPlacement="right"
      toggled={selectedTypes.length > 0}
      onClick={() => setShowDropdown((prevValue) => !prevValue)}
    >
      {t('ASSET_OVERVIEW_ACTIVITIES_EXPANDED_VIEW_FILTER_TYPE', 'Type')}
    </Button>
  );

  const TypeFilterDropdown = isDesktop ? (
    <Dropdown
      visible={showDropdown}
      placement="bottom-start"
      content={FilterMenu}
    >
      {FilterButton}
    </Dropdown>
  ) : (
    <>
      {FilterMenu}
      {FilterButton}
    </>
  );

  return (
    <S.Container>
      <div ref={outsideClickRef}>{TypeFilterDropdown}</div>
    </S.Container>
  );
};
