import Konva from 'konva';

import { IdsByType } from '../types';

import isAnnotationNode from './isAnnotationNode';
import isContainerNode from './isContainerNode';

const getNodeIdsByType = (nodes: Konva.Node[]): IdsByType => ({
  containerIds: nodes.filter(isContainerNode).map((node) => node.id()),
  annotationIds: nodes.filter(isAnnotationNode).map((node) => node.id()),
});

export default getNodeIdsByType;
