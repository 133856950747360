import type { Activity, Checklist, Operation } from '@cognite/apm-client';

export type ActivityStatusType =
  | 'Creating'
  | 'Planning'
  | 'Ready'
  | 'In progress'
  | 'Done'
  | 'Deleted';

export enum TemplateActivityTypes {
  template = 'Template',
  round = 'Round',
}

export type ActivitiesPage = {
  items: Activity[];
  pageInfo: { hasNextPage: boolean; endCursor: string };
};

export type ActivitiesWithChecklistPage = {
  items: { activities: Activity[]; checklists: Checklist[] };
  pageInfo: { hasNextPage: boolean; endCursor: string };
};

type CoreApmOperationFields = keyof Operation;
export const OPERATION_METADATA_BLACKLIST: CoreApmOperationFields[] = [
  'id',
  'title',
  'source',
  'description',
  'parentActivityId',
  'labels',
  'startTime',
  'endTime',
  'assetExternalId',
  'files',
  'assignedTo',
  'mainResource',
  'personHours',
  'numberOfMainResource',
  'order',
  'type',
  'status',
  'isInApp',
  'createdAt',
  'createdBy',
  'lastUpdatedDate',
  'mutatedBy',
  'cloneOf',
  'mutatedFields',
  'isMutated',
  'createdTime',
  'lastUpdatedTime',
  'parentActivity',
  'space',
];
