import type { Filters } from '@cognite/fdm-client';
import { useAppConfigFiltersContext } from '@infield/providers/app-config-filters-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

export const useObservationAggregateLocations = (filter?: Filters) => {
  const { observationService } = useFDMServices();
  const { observation: configFilters, notArchivedFilter } =
    useAppConfigFiltersContext();
  const filters: Filters[] = [];
  if (configFilters.rootAssetExternalIds) {
    filters.push(configFilters.rootAssetExternalIds, notArchivedFilter);
  }

  if (filter) {
    filters.push(filter);
  }

  return useQuery(
    [QueryKeys.OBSERVATION_AGGREGATE_BY_LOCATION, filters],
    async () => {
      return observationService.aggregateObservationByLocation({
        and: filters,
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(configFilters.rootAssetExternalIds),
    }
  );
};
