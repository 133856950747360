import { addRecoilKey } from '@infield/utils/recoil';
import { atom } from 'recoil';

export const selectedObservationAtom = atom<string | undefined>({
  key: addRecoilKey('selected-observation'),
  default: undefined,
});

export const selectedPanelObservationView = atom<'none' | 'assetExploration'>({
  key: addRecoilKey('selected-panel-observation-view'),
  default: 'none',
});
