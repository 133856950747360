import type { Action, Condition, ConditionalAction } from '@cognite/apm-client';
import { Flex, Heading } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { TaskFormBlockTitleWrapper } from '@infield/features/task/task-form/elements';
import type { FC } from 'react';

import { ConditionConfigurationContainer } from '../condition-configuration';

type Props = {
  conditionalActionList: ConditionalAction[];
  conditionList: Condition[];
  actionList: Action[];
};

export const ConditionConfigurationList: FC<Props> = ({
  conditionalActionList,
  conditionList,
  actionList,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.conditions);

  return (
    <Flex direction="column" gap={12}>
      <TaskFormBlockTitleWrapper>
        <Heading level={6} data-testid="task-form-condition">
          {t('TEMPLATE_TASK_FORM_COMPONENT_MENU_ACTION_CONDITION', 'Condition')}
        </Heading>
      </TaskFormBlockTitleWrapper>
      {conditionList?.map((condition) => {
        const action = actionList.find(
          (action) =>
            action.conditionalActions?.externalId ===
            condition.conditionalAction?.externalId
        );
        const conditionalAction = conditionalActionList.find(
          (conditionalAction) =>
            conditionalAction.externalId ===
            condition.conditionalAction?.externalId
        );
        return (
          <ConditionConfigurationContainer
            key={condition.externalId}
            condition={condition}
            action={action!}
            conditionalAction={conditionalAction!}
          />
        );
      })}
    </Flex>
  );
};
