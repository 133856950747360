import type { Activity, APMAsset } from '@cognite/apm-client';
import { Body, Flex } from '@cognite/cogs.js-v10';
import type { Asset } from '@cognite/sdk';
import { DetailRow } from '@infield/features/activities';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { getTitle } from '@infield/features/search/search-result/utils';
import { DEFAULT_DATE_FORMAT } from '@infield/utils/defaultDateFormats';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import { type FC, useCallback } from 'react';

import { ActivityDescription } from '../activity-details-description';
import type { SearchResult } from '../activity-details-search';
import { ActivityDetailsSearch } from '../activity-details-search';
import * as S from '../elements';

import { METADATA_BLACKLIST } from './utils';

type Props = {
  activity?: Activity;
  asset?: Asset | APMAsset;
  onAssetClick: (assetExternalId: string) => void;
};

export const DetailView: FC<Props> = ({ activity, asset, onAssetClick }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const mapMetadataChild = useCallback(
    (result: SearchResult) => (
      <DetailRow
        key={result.key}
        metadataKey={startCase(result.key)}
        value={result.value}
      />
    ),
    []
  );

  const searchAlgorithm = useCallback(
    async (query: string) => {
      return Object.keys(activity || {})
        .filter((key) => !METADATA_BLACKLIST.includes(key))
        .map((metadataKey) => ({
          key: metadataKey,
          value: activity?.[metadataKey],
        }))
        .filter((child) =>
          child.key.toLowerCase().includes(query.toLowerCase())
        )
        .filter((child) => Boolean(child.value))
        .sort((a, b) => a.key.localeCompare(b.key));
    },
    [activity]
  );

  return (
    <S.Content>
      <S.MandatoryFields>
        <S.MandatoryField>
          <S.MandatoryKey>
            {t('ACTIVITY_DETAILS_ACTIVITY_ID', 'ACTIVITY ID')}
          </S.MandatoryKey>
          <Body size="medium" strong>
            {activity?.id || '-'}
          </Body>
        </S.MandatoryField>
        <S.MandatoryField>
          <S.MandatoryKey>
            {t('NOTIFICATION_DETAILS_TYPE', 'TYPE')}
          </S.MandatoryKey>
          <Body size="medium" strong>
            {activity?.type || '-'}
          </Body>
        </S.MandatoryField>
        <S.MandatoryField>
          <S.MandatoryKey>
            {t('NOTIFICATION_DETAILS_LOCATION', 'LOCATION')}
          </S.MandatoryKey>
          {asset ? (
            <S.AssetName onClick={() => onAssetClick(asset.externalId!)}>
              {getTitle(asset)}
            </S.AssetName>
          ) : (
            '-'
          )}
        </S.MandatoryField>
        <S.MandatoryField>
          <S.MandatoryKey>
            {t('NOTIFICATION_DETAILS_START_DATE', 'START TIME')}
          </S.MandatoryKey>
          <Body size="medium" strong>
            {dayjs(activity?.startTime).format(DEFAULT_DATE_FORMAT) || '-'}
          </Body>
        </S.MandatoryField>
        <S.MandatoryField>
          <S.MandatoryKey>
            {t('NOTIFICATION_DETAILS_END_DATE', 'END TIME')}
          </S.MandatoryKey>
          <Body size="medium" strong>
            {dayjs(activity?.endTime).format(DEFAULT_DATE_FORMAT) || '-'}
          </Body>
        </S.MandatoryField>
        <S.MandatoryField>
          <S.MandatoryKey>
            {t('NOTIFICATION_DETAILS_STATUS', 'STATUS')}
          </S.MandatoryKey>
          <Body size="medium" strong>
            {activity?.status || '-'}
          </Body>
        </S.MandatoryField>
        {activity?.resource && (
          <S.MandatoryField>
            <S.MandatoryKey>
              {t('NOTIFICATION_DETAILS_RESOURCE', 'RESOURCE')}
            </S.MandatoryKey>
            <Body size="medium" strong>
              {activity?.resource}
            </Body>
          </S.MandatoryField>
        )}
      </S.MandatoryFields>
      <Flex direction="column" gap={16}>
        {activity?.description && (
          <ActivityDescription description={activity?.description} />
        )}
        <ActivityDetailsSearch<SearchResult>
          key={activity?.externalId}
          searchAlgorithm={searchAlgorithm}
          mapChild={mapMetadataChild}
        />
      </Flex>
    </S.Content>
  );
};
