import {
  type LocationFilterDTO,
  type ViewConfigDTO,
} from '@cognite/apm-config';
import { Button, Heading, Loader } from '@cognite/cogs.js-v10';
import { Container, LoginForm } from '@cognite/e2e-auth';
import type { AppConfig } from '@infield/features/app-config';
import {
  selectedRootLocationAtom,
  useAppConfigQuery,
  useInFieldLocationConfigFromService,
} from '@infield/features/app-config';
import { SELECTED_ROOT_LOCATION_KEY } from '@infield/features/app-config/utils';
import {
  useADSLocationConfigQuery,
  useIsIdm,
} from '@infield/features/config/location-config/hooks';
import { useTranslation } from '@infield/features/i18n';
import { useInfieldLogout } from '@infield/hooks/use-infield-logout';
import type { FC, PropsWithChildren } from 'react';
import { useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { AppConfigContext } from './app-config-context';
import type { InfieldIdmConfig } from './types';
import { getViewMappings } from './utils/get-view-mappings';

export const AppConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const { Trans, t } = useTranslation();
  const {
    data: appConfig,
    isLoading: isAppConfigLoading,
    isError,
    error,
  } = useAppConfigQuery();
  const { logout } = useInfieldLogout();
  const { data: isProjectIdm } = useIsIdm();
  const isIdm = isProjectIdm ?? false;
  const { data: locations, isInitialLoading: isAdsLocationLoading } =
    useADSLocationConfigQuery(isIdm);
  const userPreferenceLocation = useRecoilValue(selectedRootLocationAtom);
  const userPreferenceLocationLocalStorage = localStorage.getItem(
    SELECTED_ROOT_LOCATION_KEY
  );

  const selectedLocationExternalId =
    userPreferenceLocation?.externalId || userPreferenceLocationLocalStorage;
  const selectedLocation = locations?.find(
    (location) => location.externalId === selectedLocationExternalId
  );
  const { data: infieldLocationConfig, isLoading: isInfieldLocationLoading } =
    useInFieldLocationConfigFromService(selectedLocation?.externalId);

  const isLoading =
    isAppConfigLoading || isAdsLocationLoading || isInfieldLocationLoading;

  const viewMappings: ViewConfigDTO[] | undefined = useMemo(() => {
    return getViewMappings(isIdm, selectedLocation?.views, appConfig);
  }, [appConfig, selectedLocation, isIdm]);

  const value = useMemo(() => {
    if (!isLoading && appConfig) {
      return {
        appConfig,
        isIdm,
        idmLocations: locations,
        location:
          isIdm && selectedLocation
            ? {
                ...selectedLocation,
                views: viewMappings,
                appInstanceSpace: infieldLocationConfig?.appInstanceSpace,
              }
            : undefined,
      };
    }
    return null;
  }, [
    appConfig,
    infieldLocationConfig?.appInstanceSpace,
    isIdm,
    isLoading,
    locations,
    selectedLocation,
    viewMappings,
  ]);

  if (isLoading) {
    return (
      <Loader
        infoText={
          <Heading level={6}>
            <Trans i18nKey="SERVICE_PROVIDER_LOADING_APP_CONFIGURATION">
              Loading app configuration
            </Trans>
          </Heading>
        }
      />
    );
  }
  if (isError) {
    return (
      <Container>
        <LoginForm>
          <header>
            <h2>Error</h2>
            <h1>Failed to find configuration</h1>
          </header>
          <p>
            Make sure you have
            <ul>
              <li>
                Created the correct app config FDM model named{' '}
                <code>APM_Config</code>
              </li>
              <li>
                You have the right datamodel capabilities to read models and
                instances{' '}
              </li>
            </ul>
          </p>
          <div className="error">{error?.message}</div>
          <Button onClick={logout} type="primary" style={{ width: '100%' }}>
            Logout
          </Button>
        </LoginForm>
      </Container>
    );
  }

  return (
    <AppConfigContext.Provider value={value}>
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfigContext = (): {
  appConfig: AppConfig;
  isIdm: boolean;
  idmLocations: LocationFilterDTO[] | undefined;
  location: InfieldIdmConfig | undefined;
} => {
  const context = useContext(AppConfigContext);
  if (context) {
    return context;
  }
  throw new Error(
    'useAppConfigContext must be used within a AppConfigProvider'
  );
};
