import { Checkbox, Flex } from '@cognite/cogs.js-v10';
import type { Timeseries } from '@cognite/sdk';
import { useTrendColor } from '@infield/features/trends';
import type { FC } from 'react';

import * as S from './elements';
import { TrendsListItemDatapoint } from './trends-list-item-datapoint/trends-list-item-datapoint';

interface Props {
  timeseries: Timeseries;
  selected: boolean;
  onToggleSelectedTimeseries: (tsExternalId: string) => unknown;
}

export const TrendsListItem: FC<Props> = ({
  timeseries,
  selected,
  onToggleSelectedTimeseries,
}) => {
  const color = useTrendColor(timeseries.name);

  return (
    <S.Item onClick={() => onToggleSelectedTimeseries(timeseries.externalId!)}>
      <S.ItemContent>
        <Flex alignItems="baseline">
          <Checkbox
            name={timeseries.externalId!}
            checked={selected}
            color={color}
          />
        </Flex>
        <S.ItemDetailsWrapper>
          <S.Name>{timeseries.name}</S.Name>
          <TrendsListItemDatapoint
            timeseriesExternalId={timeseries.externalId!}
            color={color}
          />
        </S.ItemDetailsWrapper>
      </S.ItemContent>
    </S.Item>
  );
};
