import { ToolType } from '../tools/types';
import { UnifiedViewer } from '../UnifiedViewer';

const shouldEnterActiveMode = (
  didJustDrag: boolean,
  shouldBeActive: boolean,
  isHidden: boolean,
  unifiedViewer: UnifiedViewer
): boolean =>
  // We don't want to enter active mode if we just dragged, for instance, if the user
  // mousedowns, mousemoves and then mouseups, that should not trigger active mode
  !didJustDrag &&
  shouldBeActive &&
  isHidden &&
  unifiedViewer.getActiveToolType() === ToolType.SELECT &&
  unifiedViewer.getTransformer()?.getNodes().length === 1;

export default shouldEnterActiveMode;
