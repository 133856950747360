import Konva from 'konva';

import QueuedTaskRunner from '../utils/QueuedTaskRunner';

const taskRunner = new QueuedTaskRunner<() => Promise<Konva.Image | undefined>>(
  1,
  'Screenshot'
);

const getQueuedScreenshotTaskRunner = (): typeof taskRunner => taskRunner;

export default getQueuedScreenshotTaskRunner;
