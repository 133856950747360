import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useGroupsQuery = () => {
  const { client } = useAuthContext();

  return useQuery(
    [QueryKeys.GROUPS],
    async () => {
      return client.groups.list({ all: true });
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: QueryKeys.GROUPS,
          },
        }),
    }
  );
};
