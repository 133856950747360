import { useCallback, useEffect, useRef, useState } from 'react';

import { AskQuestionPrompt } from '../../components';
import { DEFAULT_MAX_WORDS_IN_ANSWER } from '../../constants';
import { useAnswerQuestionMultipleFilesQuery } from '../../service';
import { AiDocument } from '../../types';

export interface AskQuestionMultipleFilesProps {
  fileIds: number[];
  maxWordsInAnswer?: number;
  onCloseClick: () => void;
  onSourceClick?: (fileId: string, pageNumber: number) => void;
  onAddAsSticky?: ({
    question,
    answer,
  }: {
    question: string;
    answer: string;
  }) => void;
  onQuestionAsked?: (data: {
    question: string;
    externalId?: string;
    time: number;
  }) => void;
  onQuestionAnswered?: (data: {
    question: string;
    answer?: string;
    externalId?: string;
    time: number;
    duration: number;
  }) => void;
}

export const AskQuestionMultipleFiles: React.FC<
  AskQuestionMultipleFilesProps
> = ({
  fileIds,
  maxWordsInAnswer = DEFAULT_MAX_WORDS_IN_ANSWER,
  onCloseClick,
  onSourceClick,
  onAddAsSticky,
  onQuestionAsked,
  onQuestionAnswered,
}) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState<string>('');
  const [sources, setSources] = useState<AiDocument[]>([]);
  const [submittedQuestion, setSubmittedQuestion] = useState('');
  const startTime = useRef<number>(0);

  const { data, isFetching, isError } = useAnswerQuestionMultipleFilesQuery({
    question: submittedQuestion,
    fileIds,
    limit: maxWordsInAnswer,
  });
  const handleTextChange = (query: string) => {
    setQuestion(query);
  };

  const handleAskClick = useCallback(() => {
    startTime.current = Date.now();
    setSubmittedQuestion(question);
    onQuestionAsked?.({
      question,
      externalId: fileIds.join(','),
      time: startTime.current,
    });
  }, [setSubmittedQuestion, question, fileIds, onQuestionAsked]);

  useEffect(() => {
    if (isFetching) {
      setAnswer('');
      setSources([]);
    } else if (data) {
      setAnswer(data.answer);
      setSources(data.sources as AiDocument[]);
      const endTime = Date.now();
      onQuestionAnswered?.({
        question,
        answer: data.answer,
        externalId: fileIds.join(','),
        time: endTime,
        duration: endTime - startTime.current,
      });
    }
  }, [data, isFetching, onQuestionAnswered]);

  const handleAddSticky = () => {
    if (question === undefined || answer === undefined) {
      return;
    }

    onAddAsSticky?.({
      question,
      answer,
    });
  };

  const handlePageSourceClick = (source: AiDocument) => {
    onSourceClick?.(
      source.metadata!.fileId!.toString(),
      source.metadata!.page!
    );
  };
  return (
    <AskQuestionPrompt
      fileIds={fileIds}
      question={question}
      answer={answer}
      sources={sources}
      isLoading={isFetching}
      isError={isError}
      progressPercentage={undefined}
      onChangeQuestion={handleTextChange}
      onAskClick={handleAskClick}
      onCloseClick={onCloseClick}
      onAddAsStickyClick={onAddAsSticky && handleAddSticky}
      onSourceClick={handlePageSourceClick}
    />
  );
};
