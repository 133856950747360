import Konva from 'konva';
import { IRect } from 'konva/cmj/types';
import cloneDeep from 'lodash/cloneDeep';
import svgpath from 'svgpath';

import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import UnifiedViewerRenderer from '../UnifiedViewerRenderer';
import getFontSizeInAbsoluteUnits from '../utils/getFontSizeInAbsoluteUnits';
import shamefulSafeKonvaScale from '../utils/shamefulSafeKonvaScale';

import getBaseStylePropertiesFromNode from './getBaseStylePropertiesFromNode';
import getNormalizedDimensionsFromNode from './getNormalizedDimensionsFromNode';
import setAnnotationNodeEventHandlers from './setAnnotationNodeEventHandlers';
import shouldBeDraggable from './shouldBeDraggable';
import shouldBeResizable from './shouldBeResizable';
import shouldBeSelectable from './shouldBeSelectable';
import shouldBeStrokeScaleEnabled from './shouldBeStrokeScaleEnabled';
import {
  AnnotationSerializer,
  AnnotationType,
  SvgAnnotation,
  Scale,
  Size,
  Position,
  SvgPath,
  SvgStyleProperties,
  SvgSize,
} from './types';

const DEFAULT_STROKE_WIDTH = 1;

type SvgNodeProperties<MetadataType> = {
  [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION;
  annotationType: AnnotationType.SVG;
  id: string;
  containerId?: string;
  isSelectable: boolean;
  isDraggable: boolean;
  isResizable: boolean;
  paths: SvgPath[];
  originalImageSize: SvgSize;
  style?: SvgStyleProperties;
  strokeScaleEnabled: boolean;
  metadata?: MetadataType;
} & IRect &
  SvgStyleProperties;

const getPosition = (
  annotation: SvgAnnotation,
  containerContentNodeSize: Size | undefined
): Position => {
  if (annotation.containerId === undefined) {
    return { x: annotation.x, y: annotation.y };
  }

  if (containerContentNodeSize === undefined) {
    return { x: annotation.x, y: annotation.y };
  }

  return {
    x: containerContentNodeSize.width * annotation.x,
    y: containerContentNodeSize.height * annotation.y,
  };
};

// Function that takes an array of svg paths and scales them relatively to the size of the container
const getNormalizedPaths = (
  annotation: SvgAnnotation,
  containerContentNodeSize: Size | undefined
): { paths: SvgPath[]; dimensions: Size } => {
  const annotationSize = getFontSizeInAbsoluteUnits(
    annotation.size,
    containerContentNodeSize?.width
  );
  /*
   * Implementation note: There is a tiny difference between the calculated dimensions and the
   * the dimensions that you would get by rendering and measuring again. In practice, it should
   * not matter since the difference is in the order of 10^-6 PIXELS which will never be visible
   * to the user anyway
   */
  const dimensions = getDimensionsFromPaths(annotation.paths);
  const scale = annotationSize / dimensions.width;
  return {
    paths: annotation.paths.map((path) => {
      return {
        d: svgpath(path.d).scale(scale).rel().toString(),
        style: path.style,
      };
    }),
    dimensions: {
      width: dimensions.width * scale,
      height: dimensions.height * scale,
    },
  };
};

export const getDimensionsFromPaths = (paths: SvgPath[]): IRect => {
  let maxStroke = DEFAULT_STROKE_WIDTH;
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  paths.forEach(({ d, style }) => {
    const svgPathElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );
    svgPathElement.setAttribute('d', d);
    svg.appendChild(svgPathElement);
    if (style?.strokeWidth && maxStroke < style.strokeWidth) {
      maxStroke = style.strokeWidth;
    }
  });

  // We cant measure a svg element that is not in the DOM
  document.body.appendChild(svg);
  const svgSize = svg.getBBox();
  document.body.removeChild(svg);

  return {
    ...svgSize,
    width: svgSize.width > 0 ? svgSize.width : svgSize.width + maxStroke,
    height: svgSize.height > 0 ? svgSize.height : svgSize.height + maxStroke,
  };
};

const getContainerContentNodeSizeFromAnnotation = (
  annotation: SvgAnnotation,
  unifiedViewerRenderer: UnifiedViewerRenderer
): Size | undefined => {
  if (annotation.containerId === undefined) {
    return undefined;
  }

  const container = unifiedViewerRenderer.getContainerById(
    annotation.containerId
  );

  return container?.getContentNode().size();
};

const getNodePropertiesFromAnnotation = <MetadataType>(
  annotation: SvgAnnotation<MetadataType>,
  unifiedViewerRenderer: UnifiedViewerRenderer
): SvgNodeProperties<MetadataType> | undefined => {
  const containerContentNodeSize = getContainerContentNodeSizeFromAnnotation(
    annotation,
    unifiedViewerRenderer
  );
  const position = getPosition(annotation, containerContentNodeSize);
  if (position === undefined) {
    // eslint-disable-next-line no-console
    console.warn(
      `Could not get position for annotation ${annotation.id}. If it has a container, maybe the container hasn't loaded yet?`
    );
    return undefined;
  }

  const { paths: normalizedPaths, dimensions } = getNormalizedPaths(
    annotation,
    containerContentNodeSize
  );

  return {
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
    annotationType: AnnotationType.SVG,
    id: annotation.id,
    containerId: annotation.containerId,
    isSelectable: shouldBeSelectable(annotation.isSelectable),
    isDraggable: shouldBeDraggable(annotation.isDraggable),
    isResizable: shouldBeResizable(annotation.isResizable),
    paths: normalizedPaths,
    width: dimensions.width,
    height: dimensions.height,
    style: annotation.style,
    strokeScaleEnabled: shouldBeStrokeScaleEnabled(annotation),
    originalImageSize: annotation.size,
    metadata: annotation.metadata,
    ...position,
  };
};

type SvgTransformationProperties = {
  paths: SvgPath[];
  scale: Scale;
  size: Size;
};

const SvgSerializer: AnnotationSerializer<
  SvgAnnotation,
  SvgTransformationProperties
> = {
  isSelectable: (node: Konva.Node) => {
    if (!(node instanceof Konva.Group)) {
      return false;
    }
    return shouldBeSelectable(node.attrs.isSelectable);
  },

  isDraggable: (node: Konva.Node) => {
    if (!(node instanceof Konva.Group)) {
      return false;
    }
    return shouldBeDraggable(node.attrs.isDraggable);
  },

  isResizable: (node: Konva.Node) => {
    if (!(node instanceof Konva.Group)) {
      return false;
    }
    return shouldBeResizable(node.attrs.isResizable);
  },

  getTransformationPropertiesFromNode: (node: Konva.Node) => {
    if (!(node instanceof Konva.Group)) {
      throw new Error('expected node to be a Konva.Group');
    }

    return {
      size: node.size(),
      scale: shamefulSafeKonvaScale(node.scale()),
      paths: node.getChildren().map((pathNode) => {
        return {
          d: (pathNode as Konva.Path).data(),
          style: getBaseStylePropertiesFromNode(pathNode as Konva.Path),
        };
      }),
    };
  },

  normalize: ({ paths, scale }) => {
    // This method is called during mouseMove while transforming an annotation.
    const { width, height } = getDimensionsFromPaths(paths);
    return {
      paths,
      scale,
      size: {
        width: width * scale.x,
        height: height * scale.y,
      },
    };
  },

  serialize: (node, unifiedViewerRenderer): SvgAnnotation => {
    if (!(node instanceof Konva.Group)) {
      node = node.getParent();
      if (!(node instanceof Konva.Group)) {
        throw new Error('expected node to be a Konva.Group');
      }
    }

    if (node.getChildren().length === 0) {
      throw new Error('Expected Konva.Group to have at least one child');
    }

    const paths: SvgPath[] = node.getChildren().map((path) => {
      if (!(path instanceof Konva.Path)) {
        throw new Error('expected node to be a Konva.Path');
      }
      // Path's data is not scaled by the group scale, so we need to scale it manually
      return {
        d: svgpath(path.data()).scale(node.scaleX(), node.scaleY()).toString(),
        style: getBaseStylePropertiesFromNode(path as Konva.Path),
      };
    });

    const {
      x,
      y,
      width: normalizedWidth,
    } = getNormalizedDimensionsFromNode(
      node,
      unifiedViewerRenderer.getContainerRectRelativeToStageById
    );

    return {
      id: node.id(),
      type: AnnotationType.SVG,
      containerId: node.attrs.containerId,
      isSelectable: shouldBeSelectable(node.attrs.isSelectable),
      isDraggable: shouldBeDraggable(node.attrs.isDraggable),
      isResizable: shouldBeResizable(node.attrs.isResizable),
      paths,
      x,
      y,
      size:
        node.attrs.containerId === undefined
          ? `${node.width()}px`
          : normalizedWidth,
      style: node.attrs.style,
      metadata: cloneDeep(node.attrs.metadata),
    };
  },

  deserialize: (annotation, unifiedViewer, unifiedViewerRenderer) => {
    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return undefined;
    }
    const node = new Konva.Group(nodeProperties);
    // strip position and size from the properties to avoid double transformation (group and children paths)
    const { x: _x, y: _y, style: groupStyle, ...rest } = nodeProperties;

    nodeProperties.paths.map(({ d, style: pathStyle }, index) => {
      const path = new Konva.Path({
        ...rest,
        ...{ ...groupStyle, ...pathStyle },
        id: `${nodeProperties.id}_${index}`,
        data: d,
      });
      node.add(path);
    });

    return setAnnotationNodeEventHandlers(
      node,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },

  updateNode: (
    node,
    annotation,
    unifiedViewer,
    unifiedViewerRenderer
  ): void => {
    if (!(node instanceof Konva.Group)) {
      throw new Error('Expected node to be a Konva.Group');
    }
    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return;
    }
    node.setAttrs(nodeProperties);

    // Redraw children paths based on the new data
    const {
      x: _x,
      y: _y,
      paths: newPaths,
      style: groupStyle,
      ...rest
    } = nodeProperties;
    const updatedChildren = node.getChildren().map((pathNode, index) => {
      const { d, style: pathStyle } = newPaths[index];
      return new Konva.Path({
        ...rest,
        ...{ ...groupStyle, ...pathStyle },
        id: pathNode.id(),
        data: d,
      });
    });
    node.destroyChildren();
    node.add(...updatedChildren);

    setAnnotationNodeEventHandlers(
      node,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },
};

export default SvgSerializer;
