import ErrorView from '@infield/features/ui/error-view';
import { captureException } from '@sentry/browser';
import type { FC, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export const ComponentErrorBoundary: FC<{ children?: ReactNode }> = (props) => (
  <ErrorBoundary
    fallbackRender={ErrorView}
    onError={(error) =>
      captureException(error, {
        level: 'warning',
      })
    }
  >
    {props.children}
  </ErrorBoundary>
);
