import isArray from 'lodash/isArray';
import isNaN from 'lodash/isNaN';
import isObject from 'lodash/isObject';

export const humanizeValue = (value: any): string => {
  if (value === undefined || value === null || isNaN(value)) {
    return '-';
  }
  if (isArray(value) && value.length === 0) {
    return '-';
  }
  if (isObject(value) && Object.keys(value).length === 0) {
    return '-';
  }

  let result = '';

  const parseValue = (val: any) => {
    if (isArray(val)) {
      val.forEach((innerValue) => {
        parseValue(innerValue);
      });
    } else if (isObject(val)) {
      Object.entries(val).forEach(([key, innerValue]) => {
        const humanizedInnerValue = humanizeValue(innerValue);
        result += `${key}: ${humanizedInnerValue} `;
      });
    } else {
      result += `${String(val)} `;
    }
  };

  parseValue(value);

  return result.trim();
};
