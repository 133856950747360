import type { InFieldUserPreferences } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useFDMServices } from '@infield/providers/fdm-services';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpsertUserPreferences = () => {
  const { apmClient } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  return useMutation<
    InFieldUserPreferences[] | undefined,
    Error,
    InFieldUserPreferences[]
  >(
    async (userPreferencesToUpsert) => {
      const {
        data: { items: userPreferences },
      } = await apmClient.userPreferences.upsert(userPreferencesToUpsert);
      return userPreferences;
    },
    {
      onError: (err) => {
        makeToast({
          type: 'danger',
          body: t('failedToUpdateUser', 'Failed to update user data'),
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.UPSERT_USER_PREFERENCES,
          },
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.USER]);
      },
      mutationKey: [MutationKeys.UPSERT_USER_PREFERENCES],
    }
  );
};
