import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useAssetNotificationsQuery = (
  assetExternalId: string,
  filters?: Filters[],
  sort?: Sort,
  limit?: number
) => {
  const { notificationService } = useFDMServices();

  const notificationsFilter: Filters = {
    and: [
      ...(filters || []),
      {
        equals: {
          property: 'assetExternalId',
          eq: assetExternalId,
        },
      },
    ],
  };

  return useQuery(
    [QueryKeys.ASSET_NOTIFICATIONS, sort, notificationsFilter, limit],
    async () => {
      const returnedNotifications = await notificationService.getNotifications(
        notificationsFilter,
        sort,
        limit
      );

      return returnedNotifications;
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.ASSET_NOTIFICATIONS,
          },
        }),
    }
  );
};
