import { ChecklistIcon, HomeIcon, UrgentIcon } from '@cognite/cogs.js-v10';
import type { RootLocationFeatureToggles } from '@infield/features/app-config';
import type { NavigationItem } from '@infield/features/top-bar';
import {
  getIsChecklistPageEnabled,
  getIsObservationsPageEnabled,
} from '@infield/pages/utils';
import { truthy } from '@infield/utils/filtering-helpers';

export const getGeneralNavigationItems = (
  t: any,
  rootLocationFeatureToggles?: RootLocationFeatureToggles
): NavigationItem[] => {
  const isObservationsFeatureEnabled = getIsObservationsPageEnabled(
    rootLocationFeatureToggles
  );
  const isChecklistPageEnabled = getIsChecklistPageEnabled(
    rootLocationFeatureToggles
  );

  return [
    {
      key: 'general-navigation-item-home',
      name: t('SIDEBAR_NAVIGATION_HOME', 'Home'),
      to: `/explore`,
      icon: <HomeIcon />,
    },
    isChecklistPageEnabled && {
      key: 'field-ops-navigation-item-checklists',
      name: t('SIDEBAR_NAVIGATION_CHECKLISTS', 'Checklists'),
      to: `/checklists`,
      icon: <ChecklistIcon />,
    },
    isObservationsFeatureEnabled && {
      key: 'field-ops-navigation-item-observations',
      name: t('SIDEBAR_NAVIGATION_CREATE_OBSERVATION', 'Create observation'),
      to: `/observation`,
      icon: <UrgentIcon />,
    },
  ].filter(truthy) as NavigationItem[];
};
