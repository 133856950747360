import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ImgStyled = styled.img`
  height: 225px;
  object-fit: cover;
  width: 100%;
`;

export const ImageWrapper = styled(Flex)`
  height: 226px;
  justify-content: center;
  align-items: center;
`;
