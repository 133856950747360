import { memoize } from 'lodash-es';

import { SizeAndPadding } from '../../annotations/StickySerializer';
import binarySearch from '../../utils/binarySearch';

import forceBreakWords from './forceBreakWords';

const MAX_RELATIVE_FONT_SIZE = 0.3;
const MIN_RELATIVE_FONT_SIZE = 0.01;
const FONT_SIZE_SEARCH_ACCURACY = 0.01;

const findMaxFontSizeWithoutOverflow: (
  text: string,
  sizeAndPadding: SizeAndPadding
) => number = memoize(
  (text, { width, height, padding }) => {
    const maxFontSize = MAX_RELATIVE_FONT_SIZE * Math.min(width, height);
    const minFontSize = MIN_RELATIVE_FONT_SIZE * Math.min(width, height);

    if (text.length === 0) {
      return maxFontSize;
    }

    const availableWidth = width - padding * 2;
    const availableHeight = height - padding * 2;

    // Create a temporary div to measure the text
    const node = document.createElement('div');
    node.style.position = 'absolute';
    node.style.display = 'flex';
    node.style.justifyContent = 'center';
    node.style.alignItems = 'center';
    node.style.width = `${availableWidth}px`;
    node.style.height = `${availableHeight}px`;
    node.innerText = forceBreakWords(text);

    // Add the div to the DOM
    // This is necessary because certain properties and methods related to
    // dimensions and layout (like scrollWidth and scrollHeight used below)
    // only work when the element is present in the DOM.
    document.body.appendChild(node);

    // Binary search for the maximum font size
    const foundFontSize = binarySearch(
      minFontSize,
      maxFontSize,
      (fontSize) => {
        node.style.fontSize = `${fontSize}px`;
        return (
          node.scrollWidth > Math.ceil(availableWidth) ||
          node.scrollHeight > Math.ceil(availableHeight)
        );
      },
      FONT_SIZE_SEARCH_ACCURACY
    );

    // Remove the temporary div from the DOM
    node.remove();

    return foundFontSize;
  },
  (text, { width, height, padding }) => `${text}-${width}-${height}-${padding}`
);

export default findMaxFontSizeWithoutOverflow;
