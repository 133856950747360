import { Metrics } from '@cognite/metrics';
import { isProduction } from '@infield/features/auth';

const MIXPANEL_TOKEN = '1026bd8ca92bd14b4e5297595f32b41c';

export const setupMetrics = () =>
  Metrics.init({
    mixpanelToken: MIXPANEL_TOKEN,
    debug: !isProduction(),
  });
