import { Button, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const StyledFlex = styled(Flex)`
  height: 100vh;
  padding: 0px 40px;
  text-align: center;
`;

export const StyledReloadButton = styled(Button)`
  margin-top: 1rem;
`;
