import { Component, ComponentType, forwardRef, memo } from 'react';

import styled from 'styled-components';

import { useDebouncePropsOnNavigation } from './hooks';

export function shallowCompareProperties(
  obj1: { [key: string]: any },
  obj2: { [key: string]: any }
) {
  return (
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every((key) => obj1[key] === obj2[key])
  );
}

export function createSafePlotlyComponent<P>(
  plotlyComponent: ComponentType<P>
) {
  const MemoizedPlot = memo(plotlyComponent, shallowCompareProperties);

  const SafePlotlyComponent = forwardRef(
    (props: React.PropsWithoutRef<P>, ref: React.Ref<Component<P>>) => {
      const debouncedPlotProps = useDebouncePropsOnNavigation(props);

      return (
        <PlotWrapper>
          <MemoizedPlot {...debouncedPlotProps} ref={ref} />
        </PlotWrapper>
      );
    }
  );

  return SafePlotlyComponent;
}

const PlotWrapper = styled.div`
  height: 100%;
  width: 100%;
  .nsdrag {
    width: 60px;
    transform: translateX(-17px);
  }
`;
