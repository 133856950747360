import type { CogniteClient } from '@cognite/sdk';

import { APMAppDataService } from '../apm-app-data-service';
import type { Options } from '../core';
import type { ServiceContract } from '../types';

import type { InFieldUser, User } from './types';

export class UserService extends APMAppDataService<User | InFieldUser> {
  cogniteClient: CogniteClient;
  contract: ServiceContract;

  constructor(
    contract: ServiceContract,
    cogniteClient: CogniteClient,
    options?: Options
  ) {
    super(contract, options?.userViewName ?? 'CDF_User', 'v1');
    this.contract = contract;
    this.cogniteClient = cogniteClient;
  }

  public async getCurrentUser(
    userIdentifier: string
  ): Promise<User | InFieldUser | null> {
    const {
      list: {
        items: [user = null],
      },
    } = await this.list(
      `
          externalId
          name
          email
          preferences {
            externalId
            language
            infield
          }
        `,
      {
        filters: {
          equals: { property: 'externalId', eq: userIdentifier },
        },
      }
    );
    return user;
  }
}
