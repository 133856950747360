import type { CogniteClient } from '@cognite/sdk';

import {
  NodeInstanceDefinition,
  TypeInformation,
  ViewReference,
} from './types';

export class FdmClient {
  protected BASE_URL: string;
  protected client: CogniteClient;

  public constructor(client: CogniteClient) {
    this.client = client;
    this.BASE_URL = `${this.client.getBaseUrl()}/api/v1/projects/${
      this.client.project
    }`;
  }

  public async getViewVersions(
    viewSpace: string,
    viewExternalId: string
  ): Promise<{ version: string; createdTime: number }[]> {
    const response = await this.client.post<{
      // NOTE: the output schema of /views/byids actually contains many more
      // properties. However, we're only interested in the createdTime and
      // version of the views, which is why we only specify them in the return type.
      items: { version: string; createdTime: number }[];
    }>(`${this.BASE_URL}/models/views/byids`, {
      data: { items: [{ externalId: viewExternalId, space: viewSpace }] },
    });
    const views = response.data.items;
    return views;
  }

  public async getInstanceById(
    space: string,
    instanceExternalId: string,
    view: ViewReference
  ): Promise<{ instance: NodeInstanceDefinition; typing: TypeInformation }> {
    const response = await this.client.post<{
      items: NodeInstanceDefinition[];
      typing: TypeInformation;
    }>(`${this.BASE_URL}/models/instances/byids`, {
      data: {
        sources: [{ source: { ...view, type: 'view' } }],
        items: [
          {
            space,
            instanceType: 'node', // TODO: add support for getting edge instances
            externalId: instanceExternalId,
          },
        ],
        includeTyping: true,
      },
    });
    const { items, typing } = response.data;
    if (response.data.items.length === 0) {
      throw new Error(
        `No instance with space ${space} and external id ${instanceExternalId} was found`
      );
    }
    if (response.data.items.length > 1) {
      throw new Error(
        `More than one instance with space ${space} and external id ${instanceExternalId} was found. This should not happen.`
      );
    }
    return { instance: items[0], typing };
  }
}
