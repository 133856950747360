import { min } from 'lodash';
import chunk from 'lodash/chunk';

import getMaxDiffingScalingFactor from '../getMaxDiffingScalingFactor';
import { Scale, Size } from '../types';

type RescalePointsReturn = {
  points: number[];
  scale: Scale;
  size: Size;
};

const rescalePoints: ({ points, scale }: RescalePointsReturn) => number[] = ({
  points,
  scale,
}: {
  points: number[];
  scale: Scale;
  size: Size;
}) => {
  if (points.length <= 2) {
    return points;
  }

  const scaleFactor = getMaxDiffingScalingFactor(scale);
  const centerPointX = min(points.filter((v, i) => i % 2 === 1));
  const centerPointY = min(points.filter((v, i) => i % 2 === 0));

  if (centerPointX === undefined || centerPointY === undefined) {
    throw new Error('This should never happen');
  }

  return chunk(points, 2).flatMap(([x, y]) => [
    (x - centerPointX) * scaleFactor + centerPointX,
    (y - centerPointY) * scaleFactor + centerPointY,
  ]);
};

export default rescalePoints;
