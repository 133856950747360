import { humanizeValue } from '@infield/utils/humanizeValue';
import type { FC } from 'react';

import * as S from './elements';

export const DetailRow: FC<{
  metadataKey: string;
  value: any;
}> = ({ metadataKey, value }) => {
  return (
    <S.MetadataRow key={metadataKey} direction="column">
      <S.MetadataKey>{metadataKey}</S.MetadataKey>
      <S.MetadataValue>{humanizeValue(value)}</S.MetadataValue>
    </S.MetadataRow>
  );
};
