import { FileInfo } from '@cognite/sdk/dist/src';

import { getCanonicalMimeType } from '../../index';

import getSupportedMimeTypeFromUrl from './mimeTypes/getSupportedMimeTypeFromUrl';

const getComputedMimeTypeFromFileInfo = ({
  mimeType,
  name,
}: Pick<FileInfo, 'mimeType' | 'name'>): string | undefined => {
  if (mimeType) {
    return getCanonicalMimeType(mimeType);
  }

  return getSupportedMimeTypeFromUrl(name);
};

export default getComputedMimeTypeFromFileInfo;
