import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  .operation-card {
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .operation-card-asset-list {
    border-left: none;
    border-right: none;
  }
  .operation-card-asset-list-wrapper {
    &:nth-child(even) .operation-card {
      background-color: ${({ theme }) => theme['surface--medium']};
    }
  }
  .operation-card {
    &:not(:first-child) {
      border-top: none;
    }
  }
`;
