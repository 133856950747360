import styled from 'styled-components';

import {
  Accordion,
  Body,
  Button,
  Colors,
  DocumentTextIcon,
  Flex,
} from '@cognite/cogs.js-v10';

import { useTranslation } from '../../hooks';

type Props = {
  fileId: string;
  documentName: string;
  pageNumbers: number[];
  expanded?: boolean;
  hideHeader?: boolean;
  onSourceClick?: (fileId: string, pageNumber: number) => void;
};

export const Source: React.FC<Props> = ({
  fileId,
  documentName,
  pageNumbers,
  expanded = false,
  hideHeader = false,
  onSourceClick,
}) => {
  const { t } = useTranslation();

  return (
    <Container direction="column">
      <StyledAccordion
        title={documentName}
        expanded={expanded}
        $hideHeader={hideHeader}
        type="ghost"
        size="medium"
      >
        <Flex direction="column">
          <PageGroup gap={6}>
            {pageNumbers.map((pageNumber) => (
              <StyledPageButton
                key={fileId + pageNumber}
                type="tertiary"
                size="small"
                icon={<DocumentTextIcon />}
                onClick={() => onSourceClick?.(fileId, pageNumber)}
                disabled={!onSourceClick}
              >
                <Body size="small" strong>
                  {t('COMMON_PAGE_NUMBER', {
                    pageNumber,
                  })}
                </Body>
              </StyledPageButton>
            ))}
          </PageGroup>
        </Flex>
      </StyledAccordion>
    </Container>
  );
};

const StyledAccordion = styled(Accordion)<{ $hideHeader?: boolean }>`
  .cogs-accordion__header {
    display: ${({ $hideHeader }) => ($hideHeader ? 'none' : 'grid')};
  }
`;

const Container = styled(Flex)`
  cursor: pointer;
  background-color: ${Colors['surface--misc-transparent']};
`;

const PageGroup = styled(Flex)`
  color: ${Colors['text-icon--strong']};
  flex-wrap: wrap;

  & .cogs-button {
    width: unset;
  }
`;

const StyledPageButton = styled(Button)`
  &&&&& {
    width: auto;
  }
`;
