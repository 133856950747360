import type { DataGridColumn } from '@cognite/apm-observation';
import type { DatagridColumn, GridFilterOptionsType } from '@cognite/cogs-lab';
import isArray from 'lodash/isArray';

import * as S from '../../../pages/activity/activity-planning/elements';
import { DATE_FIELD_TYPE, STRING_FIELD_TYPE } from '../consts';
import type { FieldInfo } from '../hooks/use-query/types';

import { DateCell, StringListCell } from './common-table-cell-components';
import { ColumnWidths } from './consts';

// We show string list as a label array in the cell

export const getDateCellRenderer: DatagridColumn['renderCell'] = (params) => (
  <DateCell date={params.value} />
);

export const getStringListCellRenderer: DatagridColumn['renderCell'] = (
  params
) => {
  if (!params.value) return undefined;
  return (
    <StringListCell
      values={isArray(params.value) ? params.value : [params.value]}
    />
  );
};

export const getClickableCellRenderer: DatagridColumn['renderCell'] = (
  params
) => {
  if (params.row.activity) {
    return <S.ClickableCellTitle>{params.value}</S.ClickableCellTitle>;
  }
  return <S.NonClickableCellTitle>{params.value}</S.NonClickableCellTitle>;
};

const getCustomCellRenderer = (
  field: FieldInfo
): DatagridColumn['renderCell'] => {
  if (field.name === 'title' || field.name === 'assetExternalId') {
    return getClickableCellRenderer;
  }
  if (DATE_FIELD_TYPE.includes(field.type)) {
    return getDateCellRenderer;
  }
  if (field.type === STRING_FIELD_TYPE && field.list) {
    return getStringListCellRenderer;
  }

  return undefined;
};

// Currently sorting is only allowed in list mode.
// We allow sorting for Timestamp fields
export const getColumnFromViewFieldInfo = (
  headerName: string,
  field: FieldInfo,
  fetchMode: 'list' | 'search',
  filterOptions?: GridFilterOptionsType[],
  isLoading?: boolean,
  onCellClick?: DataGridColumn,
  onSearch?: (search: string) => void
): DatagridColumn => {
  return {
    field: field.name,
    headerName,
    sortable:
      fetchMode === 'list' &&
      DATE_FIELD_TYPE.includes(field.type) &&
      !field.list,
    renderCell: getCustomCellRenderer(field),
    minWidth: ColumnWidths.MEDIUM,
    flex: 1,
    filter: filterOptions
      ? {
          options: filterOptions,
          isSearchable: true,
          onSearch,
          isLoading,
          showSelectAll: true,
          showSelectedOptionOnTop: true,
          hideResultCount: true,
        }
      : undefined,
    onCellClick,
  };
};
