import type {
  ChecklistItemStatus,
  CustomReadingLabelStatusOption,
} from '@cognite/apm-client';
import { customReadingLabelStatuses } from '@cognite/apm-client';
import { ChevronDownIcon, Dropdown, Flex } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';
import { Trans } from 'react-i18next';

import * as S from './elements';
import { StatusOption } from './status-option';

export type CustomReadingStatus = { label: string; key: string };

type Props = {
  onSelectStatus: (value?: ChecklistItemStatus) => void;
  customReadingStatus?: ChecklistItemStatus;
};
export const MeasurementLabelStatusSelector: FC<Props> = ({
  onSelectStatus,
  customReadingStatus,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);

  const status = customReadingLabelStatuses.find(
    ({ value }) => value === customReadingStatus
  );
  const buttonText = status?.value || 'Set status';

  const unselectOption: CustomReadingLabelStatusOption = {
    label: 'Unselect',
    value: undefined,
  };

  return (
    <Dropdown
      hideOnSelect={{
        hideOnContentClick: true,
        hideOnOutsideClick: true,
      }}
      content={
        <S.StatusMenu data-testid="task-form-label-reading-configuration-label-status-menu">
          <Flex direction="column">
            {customReadingLabelStatuses?.map((option) => (
              <StatusOption
                data-testid={`task-form-label-reading-configuration-label-status-selector-${option.value}`}
                key={option.value}
                option={option}
                onSelectStatus={onSelectStatus}
              />
            ))}
            {status && (
              <StatusOption
                option={unselectOption}
                onSelectStatus={onSelectStatus}
              />
            )}
          </Flex>
        </S.StatusMenu>
      }
    >
      <div>
        <S.FullWidthButton
          data-testid="task-form-label-reading-configuration-label-status-button"
          type="tertiary"
          icon={status ? undefined : <ChevronDownIcon />}
          iconPlacement="right"
        >
          <Trans
            t={t}
            i18nKey={`TEMPLATE_TASK_FORM_CUSTOM_READING_STATUS_LABEL_${buttonText.toUpperCase()}`}
          >
            {buttonText}
          </Trans>
        </S.FullWidthButton>
      </div>
    </Dropdown>
  );
};
