import { Button, Flex } from '@cognite/cogs.js-v10';
import { BREAKPOINT_PAD_LANDSCAPE } from '@infield/providers/theme';
import styled from 'styled-components';

export const Wrapper = styled(Flex).attrs({
  alignItems: 'start',
  justifyContent: 'start',
  gap: 8,
})<{ containerWidth: number }>`
  grid-area: topbar;
  padding: 8px 16px;
  flex-wrap: wrap;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};

  ${({ containerWidth }) =>
    containerWidth > BREAKPOINT_PAD_LANDSCAPE
      ? `flex-wrap: nowrap;
flex-direction: 'row';
align-items: 'center';
justify-content: 'space-between';`
      : ''}
`;

export const ControlButton = styled(Button)`
  white-space: nowrap;
`;

export const YAxisButton = styled(ControlButton)`
  .cogs-icon-YAxis {
    transform: scale(-1, 1);
  }
`;
