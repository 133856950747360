import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useEffect } from 'react';
import type { FC } from 'react';
import { useRecoilState } from 'recoil';

import { AlertBox } from '../alert-box';
import { isClickedGotItForOfflineAtom } from '../state';

export const OfflineBannerChecklist: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.infobar);
  const metrics = useMetrics(METRICS_NAMESPACES.offlineMode);

  const [isGotItButtonClicked, setIsGotItButtonClicked] = useRecoilState(
    isClickedGotItForOfflineAtom
  );

  useEffect(() => {
    metrics.track('youAreOfflineBannerShown');
  }, [metrics]);

  const handleGotItClick = () => {
    setIsGotItButtonClicked(true);
  };

  return (
    <AlertBox
      status="warning"
      title={t('NETWORK_STATUS_BANNER_OFFLINE_TITLE', "You're offline")}
      description={t(
        'NETWORK_STATUS_BANNER_CHECKLIST_OFFLINE_DESCRIPTION',
        "You can work offline but can't create observations, upload media, or access asset explorer. Data will be uploaded when the connection is back."
      )}
      collapsible
      initializeCollapsed={isGotItButtonClicked}
      okButtonText={t('NETWORK_STATUS_BANNER_GOT_IT_BUTTON_TEXT', 'Got it')}
      onOkButtonClick={handleGotItClick}
    />
  );
};
