import type { DatapointAggregates, DatapointsMultiQuery } from '@cognite/sdk';
import type { Domain } from '@infield/features/trends/trends-chart/types';
import { xAccessorDefault, yAccessor } from '@infield/features/trends/utils';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { getYDomain } from './utils';

const OUTLIER_THRESHOLD = 1000;

export const insertData = (
  oldData: string | any[],
  newData: string | any[],
  xAccessor: (arg0: any) => number = xAccessorDefault
) => {
  const firstPoint = xAccessor(newData[0]);
  const lastPoint = xAccessor(newData[newData.length - 1]);
  let insertionStart = oldData.length;
  let insertionEnd = oldData.length;

  for (let idx = 0; idx < oldData.length; idx += 1) {
    if (xAccessor(oldData[idx]) >= firstPoint) {
      insertionStart = idx;
      break;
    }
  }
  for (let idx = oldData.length - 1; idx > 0; idx -= 1) {
    if (xAccessor(oldData[idx]) <= lastPoint) {
      insertionEnd = idx + 1;
      break;
    }
  }

  const data = [
    ...oldData.slice(0, insertionStart),
    ...newData,
    ...oldData.slice(insertionEnd),
  ];
  return data;
};

const calculateGranularity = (domain: number[], pps: number) => {
  const diff = domain[1] - domain[0];
  for (let i = 1; i <= 60; i += 1) {
    const points = diff / (1000 * i);
    if (points < pps) {
      return `${i === 1 ? '' : i}s`;
    }
  }
  for (let i = 1; i <= 60; i += 1) {
    const points = diff / (1000 * 60 * i);
    if (points < pps) {
      return `${i === 1 ? '' : i}m`;
    }
  }
  for (let i = 1; i < 24; i += 1) {
    const points = diff / (1000 * 60 * 60 * i);
    if (points < pps) {
      return `${i === 1 ? '' : i}h`;
    }
  }
  for (let i = 1; i < 10; i += 1) {
    const points = diff / (1000 * 60 * 60 * 24 * i);
    if (points < pps) {
      return `${i === 1 ? '' : i}day`;
    }
  }
  return 'day';
};

const latestPointRenderer =
  ({ lastPoint, raw }: any) =>
  (
    d: any,
    _: any,
    __: any,
    { x, y, color, opacity }: any,
    defaultPoints: any
  ) => {
    if (isEqual(lastPoint, d)) {
      return (
        <circle
          key={`${x}-${y}`}
          r={3}
          opacity={opacity}
          cx={x}
          cy={y}
          fill={color}
        />
      );
    }
    return raw ? defaultPoints : null;
  };

const dateToTimestamp = (datapoints: any[]) =>
  datapoints.map((dp: { timestamp: { getTime: () => any } }) => ({
    ...dp,
    timestamp: dp.timestamp.getTime(),
  }));

type CalculateNewDataParams = {
  domain: Domain;
  pointsPerSeries: number;
  oldSeries: any;
  reason: string;
  errorHandler: (e: any) => void;
  data: any;
  ySubDomain?: Domain;
  retrieveDatapointsFn: (
    query: DatapointsMultiQuery
  ) => Promise<DatapointAggregates[]>;
};

type CalculatedData = {
  data: any[];
  drawPoints: any | null;
  raw: boolean;
  yAccessor: any;
  reason: any;
  domain: Domain;
  ySubDomain?: Domain;
};

const calculateNewData = async ({
  domain,
  pointsPerSeries,
  oldSeries,
  reason,
  errorHandler,
  data,
  ySubDomain = undefined,
  retrieveDatapointsFn,
}: CalculateNewDataParams) => {
  let calculatedData: CalculatedData = {
    data: [],
    drawPoints: null,
    raw: false,
    yAccessor,
    reason,
    domain,
    ySubDomain,
  };

  try {
    const granularity = calculateGranularity(domain, pointsPerSeries);
    const { xAccessor, externalId, id, raw } = oldSeries;
    const params: any = {};
    if (externalId) {
      params.externalId = externalId;
    } else if (id) {
      params.id = id;
    } else {
      return calculatedData;
    }

    const startDate = domain[0] > domain[1] ? domain[1] : domain[0];
    const endDate = domain[0] > domain[1] ? domain[0] : domain[1];

    const datapointsFilter: DatapointsMultiQuery = raw
      ? {
          items: [params],
          start: startDate,
          end: endDate,
          limit: pointsPerSeries,
          ignoreUnknownIds: true,
        }
      : {
          items: [params],
          granularity,
          aggregates: ['average', 'min', 'max', 'count'],
          start: startDate,
          end: endDate,
          limit: pointsPerSeries,
          ignoreUnknownIds: true,
        };

    const [response] = await retrieveDatapointsFn(datapointsFilter);

    const pointsCount = response.datapoints.reduce(
      (p: number, c: { count?: number }) => p + (c.count || 0),
      0
    );
    let pointsWithTimestamp = {
      points: dateToTimestamp(response.datapoints),
      // for INTERVAL reason set raw value as of the oldSeries
      raw: reason === 'INTERVAL' ? oldSeries.raw : false,
    };

    if (pointsCount < pointsPerSeries / 5 && reason === 'MOUNTED') {
      const aggregateDatapointsParams: any = {};
      if (externalId) {
        aggregateDatapointsParams.externalId = externalId;
      } else {
        aggregateDatapointsParams.id = id;
      }
      // Can use raw dataseries instead of any aggregates
      const [rawDataResponse] = await retrieveDatapointsFn({
        items: [aggregateDatapointsParams],
        start: startDate,
        end: endDate,
        limit: pointsPerSeries,
        ignoreUnknownIds: true,
      });
      pointsWithTimestamp = {
        points: dateToTimestamp(rawDataResponse.datapoints),
        raw: true,
      };
    }

    const newData =
      oldSeries && pointsWithTimestamp.points.length > 0
        ? insertData(data, pointsWithTimestamp.points, xAccessor)
        : [];
    const lastPoint = newData.length
      ? newData[newData.length - 1]
      : data[data.length - 1];

    calculatedData = {
      data: newData,
      drawPoints: latestPointRenderer({
        lastPoint,
        raw: pointsWithTimestamp.raw,
      }),
      raw: pointsWithTimestamp.raw,
      yAccessor,
      reason,
      domain,
      ySubDomain,
    };
  } catch (e) {
    errorHandler(e);
  }

  return calculatedData;
};

const filterOutliers = (someArray: string | any[], threshold = 1.5) => {
  if (someArray.length < 4) {
    return someArray;
  }

  const values = [...someArray].slice().sort((a, b) => a - b);
  let q1;
  let q3;
  if ((values.length / 4) % 1 === 0) {
    // find quartiles
    q1 = (1 / 2) * (values[values.length / 4] + values[values.length / 4 + 1]);
    let q3Index = values.length * (3 / 4) + 1;
    q3Index = q3Index >= values.length ? values.length - 1 : q3Index;
    q3 = (1 / 2) * (values[values.length * (3 / 4)] + values[q3Index]);
  } else {
    q1 = values[Math.floor(values.length / 4 + 1)];
    const q3Index = Math.ceil(values.length * (3 / 4) + 1);
    q3 = values[q3Index >= values.length ? values.length - 1 : q3Index];
  }

  const iqr = q3 - q1;
  const maxValue = q3 + iqr * threshold;
  const minValue = q1 - iqr * threshold;

  return values.filter((x) => x >= minValue && x <= maxValue);
};

const getDatapointMinOrValue = (datapoint: any) => {
  if (datapoint.min !== undefined) return datapoint.min;
  if (datapoint.average !== undefined) return datapoint.average;

  return datapoint.value;
};

const getDatapointMaxOrValue = (datapoint: any) => {
  if (datapoint.max !== undefined) return datapoint.max;
  if (datapoint.average !== undefined) return datapoint.average;

  return datapoint.value;
};

export const CogniteLoaders =
  (errorHandler: any, retrieveDatapointsFn: any) => async (props: any) => {
    const {
      timeDomain,
      timeSubDomain,
      pointsPerSeries = 1000,
      oldSeries,
      reason,
    } = props;
    const newTimeDomain = timeDomain.map((value: number) => Math.floor(value));
    const newTimeSubDomain = timeSubDomain.map((value: number) =>
      Math.floor(value)
    );
    const { xAccessor } = oldSeries;
    let { data } = oldSeries;
    let ySubDomain;

    if (reason === 'MOUNTED' && !isEqual(newTimeDomain, newTimeSubDomain)) {
      const timeDomainData = await calculateNewData({
        domain: newTimeDomain,
        pointsPerSeries,
        oldSeries,
        reason,
        data,
        errorHandler,
        retrieveDatapointsFn,
      });
      ({ data } = timeDomainData);
      const oneYearAgo = dayjs().subtract(1, 'year').valueOf();

      const oneYearData = data.filter(
        (p: { timestamp: number }) => p.timestamp >= oneYearAgo
      );

      const mins = oneYearData.map(getDatapointMinOrValue);
      const filteredSortedMins = filterOutliers(mins, OUTLIER_THRESHOLD);

      const maxs = oneYearData.map(getDatapointMaxOrValue);
      const filteredSortedMaxs = filterOutliers(maxs, OUTLIER_THRESHOLD);

      ySubDomain = getYDomain([
        filteredSortedMins[0],
        filteredSortedMaxs[filteredSortedMaxs.length - 1],
      ]);
    }

    const updateDomain =
      reason === 'INTERVAL' && data.length
        ? [(xAccessor || xAccessorDefault)(data[data.length - 1]), Date.now()]
        : newTimeSubDomain;

    return calculateNewData({
      domain: updateDomain,
      pointsPerSeries,
      oldSeries,
      reason,
      data,
      errorHandler,
      ySubDomain,
      retrieveDatapointsFn,
    });
  };
