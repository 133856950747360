import { Heading, Menu } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const MobileMenu = styled(Menu)`
  min-width: 300px;
`;

export const NavigationMenuItemTitle = styled(Heading)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled && `color: var(--cogs-text-icon--interactive--disabled);`}
`;

export const TopBarHeader = styled(Heading).attrs({ level: 5 })<{
  $expandOverflow: boolean;
  $isTitleClickable: boolean;
}>`
  ${({ $isTitleClickable }) =>
    $isTitleClickable
      ? `color: var(--cogs-text-icon--interactive--default);`
      : `color: var(--cogs-text-icon--medium);`};
  ${({ $isTitleClickable }) => $isTitleClickable && 'cursor: pointer'};
  ${({ $expandOverflow }) =>
    !$expandOverflow &&
    `white-space: nowrap; overflow: hidden; text-overflow: ellipsis;`};
`;

export const TopBarHeaderWrapper = styled.div`
  flex-grow: 1;
  min-width: 0;
  text-align: center;
`;

export const MobileTopBarWrapper = styled.div<{
  $darkMode: boolean;
  $expandOverflow: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 6px 8px;
  background-color: ${({ $darkMode }) =>
    $darkMode ? 'var(--cogs-surface--muted--inverted)' : 'var(--cogs-white)'};
  ${({ $darkMode }) =>
    !$darkMode && 'border-bottom: 1px solid rgb(217,217,217);'}
  ${({ $expandOverflow }) =>
    !$expandOverflow && `min-height: 53px; max-height: 53px;`};
  gap: 16px;
`;
