import { Body, Textarea } from '@cognite/cogs.js-v10';
import type { FC } from 'react';
import { useState } from 'react';

import * as S from './elements';

interface Props {
  isLocked?: boolean;
  message: string;
  onUpdateNote: (message: string) => void;
  focused?: boolean;
}
export const ChecklistItemNote: FC<Props> = ({
  isLocked,
  message,
  onUpdateNote,
}) => {
  const [note, setNote] = useState(message);

  const handleOnBlur = (ev: any) => {
    const message = ev.target.value.trim();

    if (message) onUpdateNote(message);
  };
  const textAreaUnwantedProps = {
    onBlur: handleOnBlur,
    autoFocus: message.length === 0,
  } as any;

  return (
    <S.Wrapper>
      <Body size="medium" strong>
        Note
      </Body>
      <Textarea
        {...textAreaUnwantedProps}
        disabled={isLocked}
        fullWidth
        value={note}
        onChange={(ev) => setNote(ev.target.value)}
        rows="auto"
        data-testid="checklist-item-note-input"
      />
    </S.Wrapper>
  );
};
