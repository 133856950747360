import { FontSize } from '../annotations/types';

import getFontSizeValue from './getFontSizeValue';

const getFontSizeInAbsoluteUnits = (
  fontSize: FontSize,
  containerWidth?: number
): number => {
  if (typeof fontSize === 'number') {
    if (containerWidth === undefined) {
      throw Error(
        'containerWidth must be provided in order to convert fontSize to absolute units'
      );
    }
    return fontSize * containerWidth;
  }
  return getFontSizeValue(fontSize);
};

export default getFontSizeInAbsoluteUnits;
