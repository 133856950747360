import { useEffect } from 'react';

export const useInterval = (handler: () => void, timeout: number) => {
  useEffect(() => {
    if (!handler) return;

    // run once before starting interval
    handler();
    const interval = setInterval(handler, timeout);

    // returning undefined doesn't run on unrender, but function does so it's fine
    return () => clearInterval(interval);
  }, [handler, timeout]);
};
