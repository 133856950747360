import { Body, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const LoadingWrapper = styled(Flex)`
  justify-content: space-between;
`;

export const MediaItemWrapper = styled.div<{
  height?: number;
  width?: number;
}>`
  position: relative;

  ${({ width }) => (width ? `width: ${width}px;` : '')};
  ${({ height }) => (height ? `height: ${height}px;` : '')};
`;

export const Overlay = styled.div`
  position: absolute;
  font-weight: bold;
  text-align: right;
  font-size: 30px;
  background-color: white;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`;

export const ImageItem = styled.img<{
  maxHeight?: number;
}>`
  border-radius: 8px;
  border: ${({ theme }) => theme['border--interactive--default']};
  width: 100%;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px;` : '150px')};
`;

export const VideoItem = styled.video`
  border-radius: 8px;
  border: ${({ theme }) => theme['border--interactive--default']};
  width: 100%;
  max-height: 100%;
`;

export const MediaItem = styled.div`
  padding: 0px 4px;
  overflow: hidden;
`;

export const Description = styled(Body).attrs({ size: 'x-small' })`
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
