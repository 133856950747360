import { Button, DeleteIcon, Flex, Input } from '@cognite/cogs.js-v10';
import { ComponentContainer } from '@infield/features/ui';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useAppConfigQuery } from '../hooks';
import { useUpsertAppConfigMutation } from '../hooks/use-mutation';
import type { AppConfig, Discipline } from '../types';

import * as S from './elements';

interface Props {
  hasAppConfigPermission: boolean;
}

export const AppConfigDiscipline: FC<Props> = ({ hasAppConfigPermission }) => {
  const [configuredDisciplines, setConfiguredDisciplines] = useState<
    Discipline[]
  >([]);
  const { data: appConfig } = useAppConfigQuery();
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();

  useEffect(() => {
    if (appConfig) {
      const appConfigDisciplines =
        appConfig.featureConfiguration?.disciplines ?? [];
      setConfiguredDisciplines(appConfigDisciplines);
    }
  }, [appConfig]);

  const handleSave = async () => {
    if (!appConfig) return;

    const newAppConfig: AppConfig = {
      ...appConfig,
      featureConfiguration: {
        ...appConfig.featureConfiguration,
        disciplines: [...configuredDisciplines],
      },
    };

    await upsertAppConfig({
      newAppConfig,
    });
  };

  const handleOnDisciplineChange = (value: string, externalId: string) => {
    setConfiguredDisciplines((prev) =>
      prev.map((prevDiscipline) => {
        if (prevDiscipline.externalId === externalId) {
          return {
            ...prevDiscipline,
            name: value,
          };
        }
        return prevDiscipline;
      })
    );
  };

  const handleDeleteDiscipline = (externalId: string) => {
    setConfiguredDisciplines((prev) =>
      prev.filter((prevDiscipline) => prevDiscipline.externalId !== externalId)
    );
  };

  return (
    <ComponentContainer>
      <S.Wrapper>
        <Flex direction="column" gap={8}>
          {configuredDisciplines &&
            configuredDisciplines.map((discipline) => (
              <Flex gap={8} key={discipline.externalId}>
                <Input
                  value={discipline.name}
                  onChange={(e) =>
                    handleOnDisciplineChange(
                      e.target.value,
                      discipline.externalId
                    )
                  }
                />
                <Button
                  icon={<DeleteIcon />}
                  type="destructive"
                  onClick={() => handleDeleteDiscipline(discipline.externalId)}
                />
              </Flex>
            ))}
          <Button
            onClick={() =>
              setConfiguredDisciplines([
                ...configuredDisciplines,
                { externalId: uuid(), name: '' },
              ])
            }
          >
            Add discipline
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={!hasAppConfigPermission}
          >
            Save disciplines
          </Button>
        </Flex>
      </S.Wrapper>
    </ComponentContainer>
  );
};
