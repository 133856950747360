import { Button, LogoAiIcon, Tooltip } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

export const FallbackAiDisabledButton: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.documents);
  return (
    <Tooltip
      content={t(
        'ASSET_OVERVIEW_DOCUMENT_CARD_AI_NOT_AVAILABLE',
        'AI Copilot is temporarily unavailable. Try again later.'
      )}
    >
      <Button
        aria-label="ai-is-not-available-fallback-button"
        icon={<LogoAiIcon />}
        disabled
      />
    </Tooltip>
  );
};
