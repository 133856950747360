import { Loader } from '@cognite/cogs.js-v10';
import {
  useChecklistItemsUpsert,
  useChecklistUpsertMutation,
} from '@infield/features/checklist';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import {
  useMeasurementNumericReadingUpsert,
  useMeasurementsUpsert,
} from '@infield/features/measurements';
import { MutationKeys } from '@infield/utils/queryKeys';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import type { QueryClient } from '@tanstack/react-query';
import { useIsRestoring } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { useState } from 'react';
import type { FC, PropsWithChildren } from 'react';

import * as S from './elements';
import { REACT_QUERY_PERSISTER_KEY } from './utils';

type Props = {
  client: QueryClient;
};

export const PersistQueryFunctionsProvider: FC<PropsWithChildren<Props>> = ({
  client,
  children,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.infobar);
  const [isDataRestored, setIsDataRestored] = useState(false);

  const persister = createSyncStoragePersister({
    key: REACT_QUERY_PERSISTER_KEY,
    storage: window.localStorage,
  });
  const isRestoringData = useIsRestoring();

  // list all mutate functions that you want to persist in local storage below
  const { mutateAsync: upsertChecklistItemsFn } = useChecklistItemsUpsert();
  const { mutateAsync: upsertChecklistFn } = useChecklistUpsertMutation();
  const { mutateAsync: upsertMeasurementFn } = useMeasurementsUpsert();
  const { mutateAsync: upsertReadingFn } = useMeasurementNumericReadingUpsert();
  client.setMutationDefaults([MutationKeys.USE_OPERATIONS_UPSERT], {
    mutationFn: upsertChecklistItemsFn,
  });
  client.setMutationDefaults([MutationKeys.UPSERT_CHECKLIST], {
    mutationFn: upsertChecklistFn,
  });
  client.setMutationDefaults([MutationKeys.USE_MEASURMENT_UPSERT], {
    mutationFn: upsertMeasurementFn,
  });
  client.setMutationDefaults(
    [MutationKeys.USE_MEASURMENT_NUMERIC_READING_UPSERT],
    {
      mutationFn: upsertReadingFn,
    }
  );

  return (
    <PersistQueryClientProvider
      client={client}
      persistOptions={{
        persister,
        dehydrateOptions: {
          shouldDehydrateQuery: () => false,
          shouldDehydrateMutation: (mutation: any) => mutation.state.isPaused,
        },
      }}
      onSuccess={() =>
        client.resumePausedMutations().then(() => {
          setIsDataRestored(true);
        })
      }
    >
      {!isDataRestored || isRestoringData ? (
        <Loader
          infoText={
            <S.MessageContentWrapper>
              {t(
                'NETWORK_STATUS_SYNCING_OFFLINE_CHANGES',
                'Keep InField open while we save the changes you made when you were offline'
              )}
            </S.MessageContentWrapper>
          }
        />
      ) : (
        children
      )}
    </PersistQueryClientProvider>
  );
};
