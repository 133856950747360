import { makeToast } from '@cognite/cogs-lab';
import type { FdmTimeSeries } from '@cognite/fdm-client';
import type { Timeseries } from '@cognite/sdk';
import { useSelectedRootLocationConfiguration } from '@infield/features/app-config/hooks';
import { useSelectedRootAsset } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useTimeseriesUpsert = () => {
  const { timeseriesService } = useFDMServices();
  const { data: rootAsset } = useSelectedRootAsset();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.timeseries);
  const configuredRootLocation = useSelectedRootLocationConfiguration();
  const datasetId = configuredRootLocation?.dataSetId;

  return useMutation<
    Timeseries[],
    Error,
    {
      timeseriesToUpsert: FdmTimeSeries;
    }
  >(
    async ({ timeseriesToUpsert }) => {
      return timeseriesService.upsertTimeseries(
        {
          ...timeseriesToUpsert,
          dataSetId: datasetId,
        },
        rootAsset?.id
      );
    },
    {
      onMutate: async ({ timeseriesToUpsert }) => {
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TIMESERIES, timeseriesToUpsert.externalId],
        });

        if (activeQuery.length > 0) {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries([QueryKeys.TIMESERIES]);

          const [{ queryKey: activeQueryKey }] = activeQuery;

          // Snapshot the previous value
          const previousTimeseries =
            (queryClient.getQueryData(activeQueryKey) as Timeseries) ?? {};

          // Optimistically update to the new value
          const nextTimeseries = {
            ...previousTimeseries,
            ...timeseriesToUpsert,
          };

          queryClient.setQueryData(activeQueryKey, nextTimeseries);

          // Return a context object with the snapshotted value
          return previousTimeseries;
        }
      },
      onError: (err, { timeseriesToUpsert }, previousTimeseries) => {
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TIMESERIES, timeseriesToUpsert.externalId],
        });

        // set cache back to its original state
        if (activeQuery.length > 0) {
          const [{ queryKey: activeQueryKey }] = activeQuery;

          queryClient.setQueryData(activeQueryKey, previousTimeseries);
        }

        makeToast({
          body: t('upsertTimeseriesError', 'Failed to update time series'),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-timeseries-upsert-mutation',
          },
        });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.TIMESERIES],
        });
      },
    }
  );
};
