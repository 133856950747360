import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ComponentScrollableContent = styled(Flex).attrs({
  direction: 'column',
})`
  overflow: auto;
  height: 100%;
  width: 100%;
  user-select: 'none';

  ::-webkit-scrollbar {
    display: none;
  }
`;
