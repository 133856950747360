import Konva from 'konva';

const createTextContainerDivFromNode = (
  node: Konva.Label,
  scaleFactor: number
): HTMLDivElement => {
  const container = document.createElement('div');
  const tagNode = node.getTag();
  const textNode = node.getText();

  const textNodeClientRect = textNode.getClientRect();
  container.style.position = 'absolute';
  container.style.top = textNodeClientRect.y + 'px';
  container.style.left = textNodeClientRect.x + 'px';
  container.style.width = textNodeClientRect.width + 'px';
  container.style.height = textNodeClientRect.height + 'px';
  container.style.display = 'flex';
  container.style.alignItems = 'center';
  container.style.justifyContent = 'center';

  // Blocking some mouse events from propagating so that selection works without firing
  // the sticky tool
  container.addEventListener('mousedown', (e) => e.stopPropagation());
  container.addEventListener('mouseup', (e) => e.stopPropagation());
  container.addEventListener('click', (e) => e.stopPropagation());

  const border = `${
    tagNode.strokeWidth() * scaleFactor
  }px solid ${tagNode.stroke()}`;

  const boxShadow = `${tagNode.shadowOffsetX() * scaleFactor}px ${
    tagNode.shadowOffsetY() * scaleFactor
  }px ${tagNode.shadowBlur() * scaleFactor}px ${tagNode.shadowColor()}`;

  container.style.backgroundColor = tagNode.fill();
  container.style.boxShadow = boxShadow;
  container.style.border = border;

  container.style.borderRadius = `${tagNode.cornerRadius() * scaleFactor}px`;
  container.style.padding = `${textNode.padding() * scaleFactor}` + 'px';

  return container;
};

export default createTextContainerDivFromNode;
