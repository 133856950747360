import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { LoaderIcon } from '@cognite/cogs.js-v10';
import { useFetchMedia } from '@infield/features/media';
import type { ImageToUpload } from '@infield/features/media/types';
import type { FC } from 'react';

import * as S from './elements';
import { MediaItem } from './media-item';

type Props = {
  fileInstanceId: InstanceOrExternalId;
  setSelectedMedia: (image: ImageToUpload) => void;
};

export const MediaViewer: FC<Props> = ({
  fileInstanceId,
  setSelectedMedia,
}) => {
  const { externalId, space } = fileInstanceId;
  const { isLoading, data: media } = useFetchMedia(externalId, space);

  const handleMediaClick = () => {
    if (media) {
      setSelectedMedia(media);
    }
  };

  return (
    <S.MediaContainer onClick={handleMediaClick}>
      {isLoading || !media ? (
        <LoaderIcon className="cogs-icon--rotating" />
      ) : (
        <MediaItem media={media} />
      )}
    </S.MediaContainer>
  );
};
