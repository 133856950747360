import Konva from 'konva';
import cloneDeep from 'lodash/cloneDeep';

import findContainerHeightWithoutOverflow from '../tools/CauseMapTool/findContainerHeightWithoutOverflow';
import findMaxFontSizeWithoutOverflow from '../tools/StickyTool/findMaxFontSizeWithoutOverflow';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import getFontSizeValue from '../utils/getFontSizeValue';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';
import isApproximatelyEqual from '../utils/isApproximatelyEqual';
import shamefulSafeKonvaScale from '../utils/shamefulSafeKonvaScale';

import getCauseMapStatusIconImage from './getCauseMapStatusIconImage';
import getMaxDiffingScalingFactor from './getMaxDiffingScalingFactor';
import setAnnotationNodeEventHandlers from './setAnnotationNodeEventHandlers';
import shouldBeDraggable from './shouldBeDraggable';
import shouldBeResizable from './shouldBeResizable';
import shouldBeSelectable from './shouldBeSelectable';
import {
  AnnotationSerializer,
  AnnotationType,
  CauseMapNodeAnnotation,
  Scale,
} from './types';

const SCALE_CHANGE_EPSILON = 1e-6;
const IOU_THRESHOLD = 0.25;

type LabelTransformationProperties = {
  scale: Scale;
  Text: {
    width?: number;
    height?: number;
    padding?: number;
  };
  Image?: {
    shouldScaleUniformly?: boolean;
    width?: number;
    height?: number;
    x?: number;
    y?: number;
  };
};

type LabelNodeProperties<MetadataType> = {
  [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION;
  annotationType: AnnotationType.CAUSE_MAP_NODE;
  id: string;
  strokeScaleEnabled: false;
  userGenerated: true;
  containerId?: string;
  isSelectable: boolean;
  isDraggable: boolean;
  isResizable: boolean;
  x: number;
  y: number;
  metadata?: MetadataType;
};

type TagNodeProperties = {
  fill: string | undefined;
  stroke: string | undefined;
  strokeWidth: number | undefined;
  cornerRadius: number[] | number | undefined;
};

type TextNodeProperties = {
  text: string;
  fill: string | undefined;
  padding: number | undefined;
  width: number;
  height: number;
  align: string;
  verticalAlign: string;
  fontSize: number;
  lineHeight: number | undefined;
};

export type CauseMapNodeProperties<MetadataType> = {
  labelNodeProperties: LabelNodeProperties<MetadataType>;
  tagNodeProperties: TagNodeProperties;
  textNodeProperties: TextNodeProperties;
};

const getNodePropertiesFromAnnotation = <MetadataType>(
  annotation: CauseMapNodeAnnotation<MetadataType>
): CauseMapNodeProperties<MetadataType> | undefined => {
  const labelNodeProperties: LabelNodeProperties<MetadataType> = {
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
    annotationType: AnnotationType.CAUSE_MAP_NODE,
    id: annotation.id,
    strokeScaleEnabled: false,
    userGenerated: true,
    containerId: annotation.containerId,
    isSelectable: shouldBeSelectable(annotation.isSelectable),
    isDraggable: shouldBeDraggable(annotation.isDraggable),
    isResizable: shouldBeResizable(annotation.isResizable),
    x: annotation.x,
    y: annotation.y,
    metadata: annotation.metadata,
  };

  const tagNodeProperties: TagNodeProperties = {
    fill: annotation.style.backgroundColor,
    stroke: annotation.style.borderColor,
    strokeWidth: annotation.style.borderWidth,
    cornerRadius: annotation.style.borderRadius,
  };

  const textNodeProperties: TextNodeProperties = {
    text: annotation.text,
    fill: annotation.style.color,
    padding: annotation.style.padding,
    width: annotation.width,
    height: annotation.height,
    align: 'center',
    verticalAlign: 'middle',
    lineHeight: annotation.style.lineHeight,
    fontSize:
      annotation.style.fontSize !== undefined
        ? getFontSizeValue(annotation.style.fontSize)
        : findMaxFontSizeWithoutOverflow(annotation.text, {
            width: annotation.width,
            height: annotation.height,
            padding: annotation.style.padding ?? 0,
          }),
  };

  return {
    labelNodeProperties,
    tagNodeProperties,
    textNodeProperties,
  };
};

const onLoadStatusIcon = (target: Konva.Label) => (statusIcon: Konva.Image) => {
  const targetSize = target.size();
  const minSize = Math.min(targetSize.width, targetSize.height);
  const nextSize =
    statusIcon.attrs.shouldScaleUniformly === true
      ? { width: minSize, height: minSize }
      : targetSize;
  statusIcon.size(nextSize);

  // Calculate the position to center the image
  statusIcon.position({
    x: (targetSize.width - nextSize.width) / 2,
    y: (targetSize.height - nextSize.height) / 2,
  });

  target.add(statusIcon);
};

const getScaledImageProps = (
  imageProps: LabelTransformationProperties['Image'],
  textSize: { width: number; height: number },
  scaleDirection: 'uniform' | 'horizontal' | 'vertical',
  scaleFactor: number
) => {
  if (imageProps === undefined) {
    return undefined;
  }
  const { width, height, shouldScaleUniformly } = imageProps;
  if (shouldScaleUniformly === true) {
    const nextSize = Math.min(textSize.width, textSize.height);
    return {
      width: nextSize,
      height: nextSize,
      x: (textSize.width - nextSize) / 2,
      y: (textSize.height - nextSize) / 2,
    };
  }
  if (scaleDirection === 'uniform') {
    return {
      width: width !== undefined ? width * scaleFactor : undefined,
      height: height !== undefined ? height * scaleFactor : undefined,
    };
  }
  if (scaleDirection === 'horizontal') {
    return { width: width !== undefined ? width * scaleFactor : undefined };
  }
  if (scaleDirection === 'vertical') {
    return { height: height !== undefined ? height * scaleFactor : undefined };
  }

  return undefined;
};

const CauseMapNodeSerializer: AnnotationSerializer<
  CauseMapNodeAnnotation,
  LabelTransformationProperties
> = {
  isSelectable: (node: Konva.Node) =>
    shouldBeSelectable(node.attrs.isSelectable),

  isDraggable: (node: Konva.Node) => shouldBeDraggable(node.attrs.isDraggable),

  isResizable: (node: Konva.Node) => shouldBeResizable(node.attrs.isResizable),

  getTransformationPropertiesFromNode: (node: Konva.Node) => {
    if (!(node instanceof Konva.Label)) {
      throw new Error('Expected node to be a Konva.Label');
    }

    const textNode = node.getText();
    const statusNode = node.findOne('Image');

    return {
      scale: shamefulSafeKonvaScale(node.scale()),
      Text: {
        padding: textNode.padding(),
        width: textNode.width(),
        height: textNode.height(),
      },
      Image: {
        ...statusNode?.size(),
        shouldScaleUniformly: statusNode?.attrs.shouldScaleUniformly,
      },
    };
  },

  normalize: ({ scale, Text: { padding, width, height }, Image }) => {
    if (padding === undefined || width === undefined || height === undefined) {
      throw new Error(
        'Width, height and padding must be defined to normalize a cause map.'
      );
    }
    const scaleFactor = getMaxDiffingScalingFactor(scale);
    const hasXChanged = !isApproximatelyEqual(
      Math.abs(scale.x),
      1.0,
      SCALE_CHANGE_EPSILON
    );
    const hasYChanged = !isApproximatelyEqual(
      Math.abs(scale.y),
      1.0,
      SCALE_CHANGE_EPSILON
    );

    // Horizontal scaling
    if (hasXChanged && !hasYChanged) {
      const nextWidth = width * scaleFactor;
      return {
        // Rescale the points to the new scale factor
        Text: { padding: padding * scaleFactor, width: nextWidth },
        scale: { x: 1, y: 1 },
        Image: getScaledImageProps(
          Image,
          { width: nextWidth, height },
          'horizontal',
          scaleFactor
        ),
      };
    }

    // Vertical scaling
    if (!hasXChanged && hasYChanged) {
      const nextHeight = height * scaleFactor;
      return {
        // Rescale the points to the new scale factor
        Text: { padding: padding * scaleFactor, height: nextHeight },
        scale: { x: 1, y: 1 },
        Image: getScaledImageProps(
          Image,
          { width, height: nextHeight },
          'vertical',
          scaleFactor
        ),
      };
    }

    // Uniform scaling
    const nextSize = {
      width: width * scaleFactor,
      height: height * scaleFactor,
    };
    return {
      // Rescale the points to the new scale factor
      Text: {
        ...nextSize,
        padding: padding * scaleFactor,
      },
      scale: { x: 1, y: 1 },
      Image: getScaledImageProps(Image, nextSize, 'uniform', scaleFactor),
    };
  },

  serialize: (node, _) => {
    if (!(node instanceof Konva.Label)) {
      throw new Error('Expected node to be a Konva.Label');
    }

    const tag = node.getTag();
    const text = node.getText();
    return {
      id: node.id(),
      type: AnnotationType.CAUSE_MAP_NODE,
      text: text.text(),
      isSelectable: shouldBeSelectable(node.attrs.isSelectable),
      isDraggable: shouldBeDraggable(node.attrs.isDraggable),
      isResizable: shouldBeResizable(node.attrs.isResizable),
      width: node.width(),
      height: node.height(),
      x: node.x(),
      y: node.y(),
      style: {
        padding: text.padding(),
        color: text.fill(),
        lineHeight: text.lineHeight(),
        fontSize: `${text.fontSize()}px`,
        backgroundColor: tag.fill(),
        borderColor: tag.stroke(),
        borderWidth: tag.strokeWidth(),
        borderRadius: tag.cornerRadius(),
      },
      metadata: cloneDeep(node.attrs.metadata),
    };
  },

  deserialize: (annotation, unifiedViewer) => {
    const nodeProperties = getNodePropertiesFromAnnotation(annotation);
    if (nodeProperties === undefined) {
      return undefined;
    }

    const label = new Konva.Label(nodeProperties.labelNodeProperties);
    label.add(new Konva.Tag(nodeProperties.tagNodeProperties));
    label.add(new Konva.Text(nodeProperties.textNodeProperties));

    if (annotation.status !== undefined) {
      getCauseMapStatusIconImage(annotation.status, onLoadStatusIcon(label));
    }

    return setAnnotationNodeEventHandlers(
      label,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },

  updateNode: (node, annotation, unifiedViewer): void => {
    if (!(node instanceof Konva.Label)) {
      throw new Error('Expected node to be a Konva.Label');
    }

    const baseNodeProperties = getNodePropertiesFromAnnotation(annotation);
    if (baseNodeProperties === undefined) {
      return;
    }

    const hasFontSizeDecreased =
      annotation.style.fontSize !== undefined &&
      node.getText().fontSize() > getFontSizeValue(annotation.style.fontSize);
    const iouThresholdForDownsizingContainer = hasFontSizeDecreased
      ? IOU_THRESHOLD
      : Number.NEGATIVE_INFINITY;
    const correctedHeight = findContainerHeightWithoutOverflow(
      baseNodeProperties,
      1.0,
      iouThresholdForDownsizingContainer
    );
    const nodeProperties = {
      ...baseNodeProperties,
      textNodeProperties: {
        ...baseNodeProperties.textNodeProperties,
        height: correctedHeight ?? baseNodeProperties.textNodeProperties.height,
      },
    };

    node.setAttrs(nodeProperties.labelNodeProperties);
    node.getChildren().forEach((child) => {
      if (child instanceof Konva.Tag) {
        child.setAttrs(nodeProperties.tagNodeProperties);
      }

      if (child instanceof Konva.Text) {
        child.setAttrs(nodeProperties.textNodeProperties);
      }
    });

    node.find('Image').forEach((node) => node.destroy());
    if (annotation.status !== undefined) {
      getMetricsLogger()?.trackEvent(
        TrackedEventType.CAUSE_MAP_STATUS_CHANGED,
        { currentStatus: annotation.status }
      );
      getCauseMapStatusIconImage(annotation.status, onLoadStatusIcon(node));
    }

    setAnnotationNodeEventHandlers(
      node,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },
};

export default CauseMapNodeSerializer;
