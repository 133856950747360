import Konva from 'konva';
import clamp from 'lodash/clamp';

import UnifiedViewerEventType from '../../core/UnifiedViewerEventType';
import { Position, Scale } from '../annotations/types';
import {
  UNIFIED_VIEWER_SCALE_MAX,
  UNIFIED_VIEWER_SCALE_MIN,
} from '../constants';
import { UnifiedViewer } from '../UnifiedViewer';
import { isSecondaryMouseButtonPressed } from '../utils/eventUtils';
import getEuclideanDistance from '../utils/getEuclideanDistance';
import shamefulSafeKonvaScale from '../utils/shamefulSafeKonvaScale';

const getCoordinatesFromTouch = (touch: Touch): Position => {
  return {
    x: touch.clientX,
    y: touch.clientY,
  };
};

const getCenterPosition = (p1: Position, p2: Position): Position => ({
  x: (p1.x + p2.x) / 2,
  y: (p1.y + p2.y) / 2,
});

type TouchState = {
  scale: Scale;
  position: Position;
  firstTouchPosition: Position;
  secondTouchPosition: Position;
};

export default abstract class Interactions {
  public static readonly MOUSE_WHEEL_SCALE_FACTOR = 0.0003;
  public static readonly TOUCHPAD_SCALE_FACTOR = 0.01;
  public static readonly SCALE_DELTA_MAX = 0.15;
  public static readonly TOUCHPAD_PAN_FACTOR = 0.75;
  public static readonly TOUCHPAD_PAN_DELTA_MAX = 100;

  public unifiedViewer: UnifiedViewer;
  public stage: Konva.Stage;

  private previousTouchState: TouchState | null = null;

  public constructor(unifiedViewer: UnifiedViewer) {
    this.unifiedViewer = unifiedViewer;
    this.stage = unifiedViewer.stage;

    this.stage.on('touchstart', this.onStageTouchStart);
    this.stage.on('touchmove', this.onStageTouchMove);
    this.stage.on('touchend', this.onStageTouchEnd);
  }

  private onPanStart = (): void => {
    this.stage.startDrag();
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_PAN_START);
    this.unifiedViewer.onViewportChange();
  };

  private onPanMove = (): void => {
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_PAN_MOVE);
    this.unifiedViewer.onViewportChange();
  };

  private onPanEnd = (): void => {
    this.stage.stopDrag();
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_PAN_END);
    this.unifiedViewer.onViewportChange();
  };

  protected onStageMouseDown = (event: MouseEvent): void => {
    this.stage.setPointersPositions(event);

    if (isSecondaryMouseButtonPressed(event)) {
      this.onPanStart();
      return;
    }
  };

  protected onStageMouseMove = (event: MouseEvent): void => {
    this.stage.setPointersPositions(event);
    if (isSecondaryMouseButtonPressed(event)) {
      this.onPanMove();
      return;
    }
  };

  protected onStageMouseUp = (event: MouseEvent): void => {
    this.stage.setPointersPositions(event);

    const wasEventTriggeredBySecondaryButton = event.button === 2;
    if (
      wasEventTriggeredBySecondaryButton &&
      !isSecondaryMouseButtonPressed(event)
    ) {
      this.onPanEnd();
      return;
    }
  };

  protected onStageContextMenu = (event: MouseEvent): void => {
    event.preventDefault();
    this.stage.setPointersPositions(event);
  };

  protected onStageMouseOver = (event: MouseEvent): void => {
    this.stage.setPointersPositions(event);

    if (isSecondaryMouseButtonPressed(event) && !this.stage.isDragging()) {
      this.onPanStart();
      return;
    }
  };

  protected onStageMouseOut = (event: MouseEvent): void => {
    this.stage.setPointersPositions(event);
    if (this.stage.isDragging()) {
      this.onPanEnd();
      return;
    }
  };

  protected onZoom = (scale: number, pointer: boolean): void => {
    const oldScale = shamefulSafeKonvaScale(this.stage.scale());
    let referencePoint: Position | null = null;
    if (pointer) {
      referencePoint = this.stage.getPointerPosition();
    } else {
      referencePoint = {
        x: this.stage.width() / 2,
        y: this.stage.height() / 2,
      } as Konva.Vector2d;
    }

    if (!referencePoint) {
      return;
    }

    const referencePointTo = {
      x: (referencePoint.x - this.stage.x()) / oldScale.x,
      y: (referencePoint.y - this.stage.y()) / oldScale.y,
    };

    const clampedScale = -clamp(
      scale,
      -Interactions.SCALE_DELTA_MAX,
      Interactions.SCALE_DELTA_MAX
    );

    // Explanation of the formula:
    // Main idea is that if we start with a scale of 1 and first zoom out by 0.1 and then zoom in by 0.1, we should end up with a scale of 1.
    // If the clampedScale is positive, we multiply the oldScale by (1 + clampedScale) (1.1 in this example), to zoom out with 10%.
    // If the clampedScale is negative, we divide the oldScale by (1 - clampedScale) (1.1 in the example) to get back to the original scale.
    // If we multiplied with (1 + clampedScale) (0.9) instead, we would not get back to the original scale, but to 1 * 1.1 * 0.9 = 0.99.
    const newScale = {
      x:
        clampedScale < 0
          ? oldScale.x / (1 - clampedScale)
          : oldScale.x * (1 + clampedScale),
      y:
        clampedScale < 0
          ? oldScale.y / (1 - clampedScale)
          : oldScale.y * (1 + clampedScale),
    };

    if (
      newScale.x < UNIFIED_VIEWER_SCALE_MIN ||
      newScale.y < UNIFIED_VIEWER_SCALE_MIN ||
      newScale.x > UNIFIED_VIEWER_SCALE_MAX ||
      newScale.y > UNIFIED_VIEWER_SCALE_MAX
    ) {
      return;
    }

    const { zoomId } = this.unifiedViewer.onZoomStart();
    this.stage.scale({ x: newScale.x, y: newScale.y });
    this.unifiedViewer.onZoomEnd(zoomId, newScale.x);

    const newPos = {
      x: referencePoint.x - referencePointTo.x * newScale.x,
      y: referencePoint.y - referencePointTo.y * newScale.y,
    };

    this.stage.position(newPos);
    this.unifiedViewer.onViewportChange();
  };

  private onStageTouchStart = (e: Konva.KonvaEventObject<TouchEvent>) => {
    Konva.hitOnDragEnabled = true;

    if (e.evt.touches.length < 2) {
      return;
    }
  };

  private onStageTouchMove = (e: Konva.KonvaEventObject<TouchEvent>) => {
    e.evt.preventDefault();

    if (e.evt.touches.length < 2) {
      return;
    }

    if (this.stage.isDragging()) {
      this.stage.stopDrag();
    }

    const firstTouchPosition = getCoordinatesFromTouch(e.evt.touches[0]);
    const secondTouchPosition = getCoordinatesFromTouch(e.evt.touches[1]);

    const previousTouchState = this.previousTouchState;
    if (previousTouchState === null) {
      this.previousTouchState = {
        scale: shamefulSafeKonvaScale(this.stage.scale()),
        position: this.stage.position(),
        firstTouchPosition,
        secondTouchPosition,
      };

      return;
    }

    const previousDistance = getEuclideanDistance(
      previousTouchState.firstTouchPosition,
      previousTouchState.secondTouchPosition
    );

    const currentDistance = getEuclideanDistance(
      firstTouchPosition,
      secondTouchPosition
    );

    const distancePercentageChange = currentDistance / previousDistance;

    const nextScale = {
      x: clamp(
        previousTouchState.scale.x * distancePercentageChange,
        UnifiedViewer.SCALE_MIN,
        UnifiedViewer.SCALE_MAX
      ),
      y: clamp(
        previousTouchState.scale.y * distancePercentageChange,
        UnifiedViewer.SCALE_MIN,
        UnifiedViewer.SCALE_MAX
      ),
    };

    const { zoomId } = this.unifiedViewer.onZoomStart();
    this.stage.scale(nextScale);
    this.unifiedViewer.onZoomEnd(zoomId, nextScale.x);

    const previousCenterPosition = getCenterPosition(
      previousTouchState.firstTouchPosition,
      previousTouchState.secondTouchPosition
    );
    const currentCenterPosition = getCenterPosition(
      firstTouchPosition,
      secondTouchPosition
    );

    const pointTo = {
      x:
        (previousCenterPosition.x - previousTouchState.position.x) /
        previousTouchState.scale.x,
      y:
        (previousCenterPosition.y - previousTouchState.position.y) /
        previousTouchState.scale.y,
    };

    const dx = currentCenterPosition.x - previousCenterPosition.x;
    const dy = currentCenterPosition.y - previousCenterPosition.y;

    const nextPosition = {
      x: currentCenterPosition.x - pointTo.x * nextScale.x + dx,
      y: currentCenterPosition.y - pointTo.y * nextScale.y + dy,
    };

    this.stage.position(nextPosition);

    this.previousTouchState = {
      scale: shamefulSafeKonvaScale(this.stage.scale()),
      position: this.stage.position(),
      firstTouchPosition,
      secondTouchPosition,
    };
  };
  private onStageTouchEnd = (e: Konva.KonvaEventObject<TouchEvent>) => {
    if (e.evt.touches.length < 2) {
      this.previousTouchState = null;
    }
  };
}
