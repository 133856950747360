import type { LocationFilterDTO } from '@cognite/apm-config';
import { Body, Modal } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

interface Props {
  visible: boolean;
  location?: LocationFilterDTO;
  onClose?: () => void;
  onConfirm?: (location: LocationFilterDTO) => Promise<void>;
}

export const ADSLocationDeleteModal: FC<Props> = ({
  visible,
  location,
  onClose,
  onConfirm,
}) => {
  const handleDelete = () => {
    if (!location) return;
    onConfirm?.(location);
    onClose?.();
  };

  return (
    <Modal
      size="small"
      title="Delete location"
      visible={visible}
      onCancel={onClose}
      onOk={handleDelete}
      destructive
    >
      <Body>
        Are you sure you want to delete this location? This action cannot be
        reversed.
      </Body>
    </Modal>
  );
};
