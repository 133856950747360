import type { InFieldUser } from '@cognite/apm-client';
import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { convertUserProfileToInFieldUser } from './utils/convert-user-profile-to-infield-user';

export const useSearchUserQuery = (query: string | undefined) => {
  const { client } = useAuthContext();

  return useQuery<InFieldUser[]>(
    [QueryKeys.USER, 'search', query],
    async () => {
      if (query) {
        const response = await client.profiles.search({
          search: { name: query },
        });

        return convertUserProfileToInFieldUser(response);
      }
      return [];
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: `${QueryKeys.USER} search`,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
