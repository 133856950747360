import type { APMAsset, FdmFile, Measurement } from '@cognite/apm-client';
import {
  AddLargeIcon,
  Body,
  Button,
  DeleteIcon,
  EditIcon,
  ErrorFilledIcon,
  Flex,
  IconWrapper,
  Input,
  LoaderIcon,
  SearchIcon,
  Tooltip,
} from '@cognite/cogs.js-v10';
import type { FdmTimeSeries } from '@cognite/fdm-client';
import type { Asset, Timeseries } from '@cognite/sdk';
import { useSelectedRootLocationConfiguration } from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useMeasurementDelete } from '@infield/features/measurements';
import { SearchResult, useTimeseriesSearch } from '@infield/features/search';
import { getTitle } from '@infield/features/search/search-result/utils';
import { TaskFormBlockWrapper } from '@infield/features/task/task-form/elements';
import { selectedTaskAtom } from '@infield/features/template';
import { TimeseriesUpsertModal } from '@infield/features/timeseries';
import { useOnClickOutside } from '@infield/features/ui';
import { useDebounce } from '@infield/hooks/use-debounce';
import { useEffect, useRef, useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import { useRecoilValue } from 'recoil';

import * as S from './elements';
import { getTimeseriesFilter } from './utils';

type Props = {
  measurement: Measurement;
  isUpsertingMeasurement: boolean;
  relevantAsset?: Asset;
  onTimeseriesSelect: (timeseries: FdmTimeSeries | null) => void;
  onRangeChange: (
    min?: number | null,
    max?: number | null,
    selectedTimeseries?: FdmTimeSeries
  ) => void;
};

const SEARCH_RESULT_LIMIT = 10;

export const MeasurementNumericConfiguration: FC<Props> = ({
  measurement,
  isUpsertingMeasurement,
  relevantAsset,
  onTimeseriesSelect,
  onRangeChange,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);
  const selectedTask = useRecoilValue(selectedTaskAtom);
  const [timeseriesSearchInput, setTimeseriesSearchInput] = useState('');
  const debouncedQuery = useDebounce(timeseriesSearchInput, 750);
  const [minInput, setMinInput] = useState('');
  const [maxInput, setMaxInput] = useState('');
  const [isMinFocused, setIsMinFocused] = useState(false);
  const [isMaxFocused, setIsMaxFocused] = useState(false);
  const [showTimeseriesModal, setShowTimeseriesModal] = useState(false);
  const selectedlocation = useSelectedRootLocationConfiguration();
  const timeseriesDataSet = selectedlocation?.dataSetId;

  const { mutateAsync: deleteMeasurement, isLoading: isDeletingMeasurement } =
    useMeasurementDelete();

  const { timeseries } = measurement;

  const timeseriesFilter = getTimeseriesFilter(timeseriesDataSet);

  const timeseriesSearchResult = useTimeseriesSearch(
    debouncedQuery,
    SEARCH_RESULT_LIMIT,
    timeseriesFilter
  );

  const minMaxError: boolean =
    Boolean(minInput) &&
    Boolean(maxInput) &&
    Number(minInput) > Number(maxInput);

  useEffect(() => {
    if (timeseries?.name && timeseries?.name.length > 0) {
      setTimeseriesSearchInput(timeseries.name);
    }
  }, [timeseries]);

  useEffect(() => {
    if (!isMinFocused) {
      if (measurement.min !== undefined && measurement.min !== null) {
        setMinInput(String(measurement.min));
      } else {
        setMinInput('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurement.min]);

  useEffect(() => {
    if (!isMaxFocused) {
      if (measurement.max !== undefined && measurement.max !== null) {
        setMaxInput(String(measurement.max));
      } else {
        setMaxInput('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurement.max]);

  const handleClearTimeseriesSearch = () => {
    if (timeseries?.externalId && timeseries.externalId.length > 0) {
      onTimeseriesSelect(null);
      setTimeseriesSearchInput('');
    }
  };

  const handleOnTimseriesSearchBlur = () => {
    if (timeseriesSearchInput.length === 0) {
      handleClearTimeseriesSearch();
    }
  };

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeseriesSearchInput(e.target.value);
    if (e.target.value.length === 0) {
      handleClearTimeseriesSearch();
    }
  };

  const handleSearchResultSelect = (
    selectedResource: Timeseries | Asset | APMAsset | FdmFile
  ) => {
    if (
      'isString' in selectedResource &&
      selectedResource.isString !== undefined
    ) {
      setTimeseriesSearchInput(getTitle(selectedResource));
      onTimeseriesSelect(selectedResource);
    }
  };

  const showSearchResult =
    !isUpsertingMeasurement &&
    timeseries?.name !== timeseriesSearchInput &&
    timeseriesSearchInput.length > 0;

  const handleRangeChange = (type: 'min' | 'max') => {
    if (!timeseries) return;
    let input = type === 'min' ? minInput : maxInput;

    if (input.startsWith('.')) {
      input = '0'.concat(input);
    }

    if (input.endsWith('.0')) {
      input = input.slice(0, -2);
    }

    if (type === 'min' && Number(input) !== measurement.min) {
      setMinInput(input);
      onRangeChange(input === '' ? null : Number(input), undefined, timeseries);
    } else if (type === 'max' && Number(input) !== measurement.max) {
      setMaxInput(input);
      onRangeChange(undefined, input === '' ? null : Number(input), timeseries);
    }
  };

  const minRef = useRef<HTMLInputElement>(null);
  const maxRef = useRef<HTMLInputElement>(null);

  const handleMinOnClickOutside = (event: MouseEvent | TouchEvent) => {
    if (minRef.current) {
      minRef.current.blur();
      event.stopPropagation();
    }
  };

  useOnClickOutside(minRef, handleMinOnClickOutside);

  const handleMaxOnClickOutside = (event: MouseEvent | TouchEvent) => {
    if (maxRef.current) {
      maxRef.current.blur();
      event.stopPropagation();
    }
  };

  useOnClickOutside(maxRef, handleMaxOnClickOutside);

  const getTimeseriesEditIcon = () => {
    if (isUpsertingMeasurement) {
      return <LoaderIcon />;
    }
    if (timeseries) {
      return <EditIcon />;
    }
    return <AddLargeIcon />;
  };

  return (
    <Flex direction="column" gap={12}>
      <TaskFormBlockWrapper>
        <S.TimeseriesInputContainer>
          <Flex gap={8}>
            <Input
              data-testid="numerical-reading-configuration-timeseries-search-input"
              fullWidth
              icon={<SearchIcon />}
              disabled={isUpsertingMeasurement}
              onChange={handleOnInputChange}
              onBlur={handleOnTimseriesSearchBlur}
              clearable
              value={timeseriesSearchInput}
              placeholder={t(
                'TEMPLATE_TASK_FORM_NUMERICAL_READING_EDIT_INPUT_CONNECT_TIMESERIES',
                'Search and connect existing time series'
              )}
            />
            <Tooltip
              content={
                timeseries
                  ? t(
                      'TEMPLATE_TASK_FORM_NUMERICAL_READING_EDIT_TIMESERIES_BUTTON_TOOLTIP',
                      'Edit time series'
                    )
                  : t(
                      'TEMPLATE_TASK_FORM_NUMERICAL_READING_CREATE_TIMESERIES_BUTTON_TOOLTIP',
                      'Create time series'
                    )
              }
              wrapped
            >
              <Button
                data-testid="numerical-reading-configuration-edit-timeseries-button"
                type="secondary"
                aria-label="Create / edit time series"
                icon={getTimeseriesEditIcon()}
                disabled={isUpsertingMeasurement}
                onClick={() => setShowTimeseriesModal(true)}
              />
            </Tooltip>
          </Flex>
          {showSearchResult && (
            <S.SearchResultWrapper>
              <SearchResult
                searchingText={t(
                  'TEMPLATE_TASK_FORM_NUMERICAL_READING_EDIT_TIMESERIES_SEARCH_RESULT_INFO',
                  'Searching...'
                )}
                query={timeseriesSearchInput}
                queryResult={timeseriesSearchResult}
                onSelect={handleSearchResultSelect}
              />
            </S.SearchResultWrapper>
          )}
          <S.TimeseriesRequiredText>
            {t(
              'TEMPLATE_TASK_FORM_NUMERICAL_READING_EDIT_TIMSERIES_REQUIRED_TEXT',
              'A time series connection is required to save the numerical reading.'
            )}
          </S.TimeseriesRequiredText>
        </S.TimeseriesInputContainer>
        <Flex direction="column" gap={4}>
          <Flex gap={12}>
            <Input
              ref={minRef}
              data-testid="numerical-reading-configuration-timeseries-min-range-input"
              type="number"
              status={minMaxError ? 'critical' : undefined}
              disabled={!timeseries || isUpsertingMeasurement}
              onFocus={() => setIsMinFocused(true)}
              onChange={(e) => setMinInput(e.target.value)}
              onBlur={() => {
                handleRangeChange('min');
                setIsMinFocused(false);
              }}
              fullWidth
              value={minInput}
              suffix={timeseries?.unit}
              placeholder={t(
                'TEMPLATE_TASK_FORM_NUMERICAL_READING_EDIT_RANGE_MIN',
                'Min. value'
              )}
            />
            <Input
              ref={maxRef}
              data-testid="numerical-reading-configuration-timeseries-max-range-input"
              type="number"
              status={minMaxError ? 'critical' : undefined}
              disabled={!timeseries || isUpsertingMeasurement}
              onFocus={() => setIsMaxFocused(true)}
              onChange={(e) => setMaxInput(e.target.value)}
              onBlur={() => {
                handleRangeChange('max');
                setIsMaxFocused(false);
              }}
              fullWidth
              value={maxInput}
              suffix={timeseries?.unit}
              placeholder={t(
                'TEMPLATE_TASK_FORM_NUMERICAL_READING_EDIT_RANGE_MAX',
                'Max. value'
              )}
            />
          </Flex>
          {minMaxError && (
            <Flex alignItems="center" gap={4}>
              <IconWrapper size={12}>
                <ErrorFilledIcon
                  style={{
                    color: 'var(--cogs-themed-text-icon--status-critical)',
                  }}
                />
              </IconWrapper>
              <Body
                size="x-small"
                style={{
                  color: 'var(--cogs-themed-text-icon--status-critical)',
                }}
              >
                {t(
                  'TEMPLATE_TASK_FORM_NUMERICAL_READING_MIN_MAX_ERROR_TEXT',
                  'Min. value cannot exceed the max. value'
                )}
              </Body>
            </Flex>
          )}
        </Flex>
        <Flex justifyContent="flex-end">
          <Button
            data-testid="numerical-reading-configuration-remove-reading-button"
            type="tertiary"
            icon={<DeleteIcon />}
            loading={isDeletingMeasurement}
            onClick={() =>
              deleteMeasurement({
                measurementExternalIds: [measurement.externalId],
                taskExternalId: selectedTask!.externalId,
              })
            }
          >
            {t(
              'TEMPLATE_TASK_FORM_NUMERICAL_READING_EDIT_BUTTON_REMOVE_COMPONENT',
              'Remove component'
            )}
          </Button>
        </Flex>
        {showTimeseriesModal && (
          <TimeseriesUpsertModal
            size="small"
            visible
            title={
              timeseries
                ? t('EDIT_TIMESERIES_DIALOGUE_TITLE', 'Edit time series')
                : t('CREATE_TIMESERIES_DIALOGUE_TITLE', 'Create time series')
            }
            subtitle={
              timeseries
                ? t(
                    'EDIT_TIMESERIES_DIALOGUE_SUBTITLE',
                    'Edit time series for {{externalId}}',
                    { externalId: timeseries.externalId }
                  )
                : t(
                    'CREATE_TIMESERIES_DIALOGUE_SUBTITLE',
                    'Fields marked with star are required'
                  )
            }
            relevantAsset={relevantAsset}
            onSubmit={(timeseries: FdmTimeSeries) => {
              onTimeseriesSelect(timeseries);
              setShowTimeseriesModal(false);
            }}
            onCancel={() => setShowTimeseriesModal(false)}
            cancelText={t('CREATE_TIMESERIES_DIALOGUE_BUTTON_CANCEL', 'Cancel')}
            timeseriesToEdit={timeseries ?? undefined}
          >
            {null}
          </TimeseriesUpsertModal>
        )}
      </TaskFormBlockWrapper>
    </Flex>
  );
};
