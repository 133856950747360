export const EmptyStateSearchSad = () => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5489 41.0984C14.5489 25.1926 27.4431 12.2984 43.3489 12.2984C59.2547 12.2984 72.1489 25.1926 72.1489 41.0984C72.1489 48.9922 68.9731 56.1443 63.8295 61.3466L63.597 61.579C58.3947 66.7226 51.2427 69.8984 43.3489 69.8984C27.4431 69.8984 14.5489 57.0042 14.5489 41.0984ZM62.241 65.9306C56.9973 69.926 50.4502 72.2984 43.3489 72.2984C26.1176 72.2984 12.1489 58.3297 12.1489 41.0984C12.1489 23.8672 26.1176 9.89844 43.3489 9.89844C60.5802 9.89844 74.5489 23.8672 74.5489 41.0984C74.5489 48.1999 72.1764 54.7472 68.1807 59.9909L75.1569 66.967L77.2797 64.8442L108.348 95.9122L98.1653 106.095L67.0973 75.0266L69.2172 72.9067L62.241 65.9306ZM65.2894 63.2807L64.1374 64.4328L70.9137 71.2092L73.4593 68.6636L66.6829 61.8872L65.5312 63.039C65.4511 63.12 65.3705 63.2006 65.2894 63.2807ZM70.4914 75.0266L98.1653 102.7L104.954 95.9122L77.2797 68.2383L70.4914 75.0266Z"
        fill="#4255BB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1659 18.9269C46.4117 13.8545 60.451 19.6698 65.5234 31.9156C70.5958 44.1615 64.7805 58.2007 52.5347 63.2731C40.2888 68.3455 26.2496 62.5303 21.1772 50.2844C16.1048 38.0386 21.92 23.9993 34.1659 18.9269ZM38.9665 32.8798C38.9665 34.9449 37.2924 36.619 35.2273 36.619C33.1622 36.619 31.4881 34.9449 31.4881 32.8798C31.4881 30.8147 33.1622 29.1406 35.2273 29.1406C37.2924 29.1406 38.9665 30.8147 38.9665 32.8798ZM55.1161 32.8798C55.1161 34.9449 53.4421 36.619 51.3769 36.619C49.3118 36.619 47.6377 34.9449 47.6377 32.8798C47.6377 30.8147 49.3118 29.1406 51.3769 29.1406C53.4421 29.1406 55.1161 30.8147 55.1161 32.8798ZM43.3488 48.6519C38.416 48.6519 34.202 50.8679 31.9885 54.0276L30.0228 52.6506C32.7389 48.7734 37.7313 46.2519 43.3488 46.2519C48.966 46.2519 53.9585 48.7707 56.6749 52.6482L54.7092 54.0252C52.496 50.8659 48.2821 48.6519 43.3488 48.6519Z"
        fill="#6E85FC"
      />
    </svg>
  );
};
