import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  className: 'operation-card',
  direction: 'column',
  gap: 12,
  role: 'button',
})<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme['border--muted']};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;
