import { Flex } from '@cognite/cogs.js-v10';
import { AxisDisplayMode, DataProvider, LineChart } from '@cognite/griff-react';
import type { Timeseries } from '@cognite/sdk';
import {
  useDomains,
  useTimeseriesChart,
  useTrendsLatestDatapoint,
} from '@infield/features/trends';
import { enhancedSeries } from '@infield/features/trends/trends-chart/utils';
import {
  y0Accessor,
  y1Accessor,
  yAccessor,
} from '@infield/features/trends/utils';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';

import * as S from './elements';

interface Props {
  timeseries: Timeseries;
}
export const AssetTrendsCardChart: FC<Props> = memo(({ timeseries }) => {
  const {
    data: firstPoint,
    isLoading,
    error,
  } = useTrendsLatestDatapoint(timeseries.externalId!);

  if (error) {
    throw error;
  }

  const activeTimeseries = useMemo(() => [timeseries], [timeseries]);

  const domain = useDomains({ activeTimeseries });

  const chart = useTimeseriesChart({
    timeseries: activeTimeseries,
    ySubDomains: domain.ySubDomains,
    setYSubDomains: domain.setYSubDomains,
    isLive: domain.isLive,
    buttonClicked: domain.buttonClicked,
  });

  const coloredSeries = useMemo(
    () => enhancedSeries(activeTimeseries),
    [activeTimeseries]
  );
  const chartRef = useRef<null | HTMLDivElement>(null);

  const updateChartWidth = useCallback(() => {
    if (!chartRef.current) {
      return;
    }
    const chartWidth = Math.floor(
      chartRef.current.getBoundingClientRect().width
    );
    domain.handleChartWidthChange(chartWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstSeries: (Timeseries & { color: string }) | undefined =
    coloredSeries[0];

  useEffect(() => {
    if (!isLoading) {
      updateChartWidth();
    }
  }, [isLoading, updateChartWidth]);

  const getYAxisForFirstSeries = () => {
    if (!firstSeries || !domain.ySubDomains?.[firstSeries.id]) {
      return null;
    }

    return domain.ySubDomains[firstSeries.id];
  };

  return (
    <Flex direction="column" alignItems="baseline">
      <S.TrendName>{firstSeries?.name}</S.TrendName>
      <S.ValueWrapper>
        <S.Value color={firstSeries?.color || ''}>
          {firstPoint?.value.toFixed(3)}
        </S.Value>
        <S.Unit>{firstSeries?.unit}</S.Unit>
      </S.ValueWrapper>
      <Flex direction="column" style={{ flex: 1, width: '100%' }}>
        <S.YAxisValue>{getYAxisForFirstSeries()?.[1]}</S.YAxisValue>
        <S.ChartWrapper ref={chartRef}>
          {domain.initialXDomain && domain.xSubDomain && (
            <DataProvider
              timeDomain={domain.initialXDomain}
              timeSubDomain={domain.xSubDomain}
              defaultLoader={chart.loader}
              xAccessor={(d: any) => d.timestamp}
              yAccessor={yAccessor}
              y0Accessor={y0Accessor}
              y1Accessor={y1Accessor}
              series={coloredSeries}
              updateInterval={2000}
              onTimeSubDomainChanged={chart.onXSubDomainChange}
              pointsPerSeries={1000}
              isTimeSubDomainSticky={domain.isLive}
              limitTimeSubDomain={domain.limitXSubDomain}
              onFetchData={chart.handleFetchData}
              onUpdateDomains={domain.handleUpdateDomains}
              zoomable={false}
            >
              <LineChart
                crosshair={false}
                contextChart={{ visible: false }}
                yAxisDisplayMode={AxisDisplayMode.NONE}
              />
            </DataProvider>
          )}
        </S.ChartWrapper>
        <S.YAxisValue>{getYAxisForFirstSeries()?.[0]}</S.YAxisValue>
      </Flex>
    </Flex>
  );
});
