import { Body, Flex, Heading, Input } from '@cognite/cogs.js-v10';
import { ComponentContainer } from '@infield/features/ui';
import isEqual from 'lodash/isEqual';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { useAppConfigQuery } from '../hooks';
import { useUpsertAppConfigMutation } from '../hooks/use-mutation';
import type { AppConfig } from '../types';

import * as S from './elements';

interface Props {
  hasAppConfigPermission: boolean;
}

export const AppConfigAsset: FC<Props> = ({ hasAppConfigPermission }) => {
  const [highlightedProperties, setHighlightedProperties] =
    useState<string>('');
  const [linkableAssetKeys, setLinkableAssetKeys] = useState<string>('');

  const { data: appConfig, isSuccess, isLoading } = useAppConfigQuery();
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();

  const propertyConfiguration =
    appConfig?.featureConfiguration?.assetPageConfiguration?.propertyCard;

  const createKeyArray = (string: string) =>
    string.split(',').map((key) => key.trim());

  useEffect(() => {
    if (isSuccess && appConfig?.featureConfiguration?.assetPageConfiguration) {
      if (propertyConfiguration) {
        setHighlightedProperties(
          (propertyConfiguration.highlightedProperties || []).join(', ')
        );
        setLinkableAssetKeys(
          (propertyConfiguration.linkableAssetKeys || []).join(', ')
        );
      }
    }
  }, [isSuccess, appConfig, propertyConfiguration]);

  const hasConfigChanged =
    !isEqual(
      createKeyArray(highlightedProperties),
      propertyConfiguration?.highlightedProperties
    ) ||
    !isEqual(
      createKeyArray(linkableAssetKeys),
      propertyConfiguration?.linkableAssetKeys
    );

  const handleSave = async () => {
    if (!appConfig) return;

    const newAppConfig: AppConfig = {
      ...appConfig,
      featureConfiguration: {
        ...appConfig.featureConfiguration,
        assetPageConfiguration: {
          ...appConfig.featureConfiguration?.assetPageConfiguration,
          propertyCard: {
            linkableAssetKeys: createKeyArray(linkableAssetKeys),
            highlightedProperties: createKeyArray(highlightedProperties),
          },
        },
      },
    };

    await upsertAppConfig({
      newAppConfig,
    });
  };

  const propertyConfig = (
    <Flex direction="column" gap={8}>
      <Heading level={5}>Property card</Heading>
      <Body size="x-small">
        Configure which properties are clickable, and highlighted on the
        property card on the asset overview page.
      </Body>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Highlighted properties
          </Body>
          <Input
            disabled={!hasAppConfigPermission}
            value={highlightedProperties}
            onChange={(e) => setHighlightedProperties(e.target.value)}
          />
          <Body size="x-small">Comma-separated list of metadata fields.</Body>
        </Flex>
      </Flex>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Linkable asset keys
          </Body>
          <Input
            disabled={!hasAppConfigPermission}
            value={linkableAssetKeys}
            onChange={(e) => setLinkableAssetKeys(e.target.value)}
          />
          <Body size="x-small">
            Comma-separated list of metadata fields that should link to another
            asset.
          </Body>
        </Flex>
      </Flex>
    </Flex>
  );

  const saveButton = (
    <Flex direction="column" gap={4}>
      <S.SaveButton
        onClick={handleSave}
        disabled={!hasConfigChanged}
        loading={isLoading}
      >
        Save configuration
      </S.SaveButton>
    </Flex>
  );

  return (
    <ComponentContainer>
      <S.Wrapper>
        <Flex direction="column" gap={16}>
          {propertyConfig}
          {saveButton}
        </Flex>
      </S.Wrapper>
    </ComponentContainer>
  );
};
