import type { ModelAndRevision3D } from '@infield/features/3d';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const use3dModelList = () => {
  const { threeDService } = useFDMServices();

  return useQuery<ModelAndRevision3D[]>(
    [QueryKeys.THREE_D, 'model and revision list'],
    async () => {
      return threeDService.get3DModels();
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.THREE_D,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
