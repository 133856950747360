import Konva from 'konva';

import getCachedImage from '../getCachedImage';
import { CauseMapNodeStatusType } from '../types';

import CheckMark from './CheckMark.svg';
import QuestionMark from './QuestionMark.svg';
import RemoveAltIcon from './RemoveAltIcon.svg';

const STATUS_TO_ICON_URL = {
  [CauseMapNodeStatusType.ACCEPTED]: CheckMark,
  [CauseMapNodeStatusType.POSSIBLE]: QuestionMark,
  [CauseMapNodeStatusType.REJECTED]: RemoveAltIcon,
} satisfies Record<CauseMapNodeStatusType, string>;

const STATUS_TO_SHOULD_SCALE_UNIFORMLY = {
  [CauseMapNodeStatusType.ACCEPTED]: true,
  [CauseMapNodeStatusType.POSSIBLE]: true,
  [CauseMapNodeStatusType.REJECTED]: true,
} satisfies Record<CauseMapNodeStatusType, boolean>;

const getCauseMapStatusIconImage = (
  status: CauseMapNodeStatusType,
  onImageLoad: (image: Konva.Image) => void
): void => {
  const iconUrl = STATUS_TO_ICON_URL[status];
  if (iconUrl === undefined) {
    throw new Error(`Unknown status '${status}' was provided.`);
  }
  const konvaImage = new Konva.Image({
    image: undefined,
    shouldScaleUniformly: STATUS_TO_SHOULD_SCALE_UNIFORMLY[status],
  });
  getCachedImage(iconUrl, (image: CanvasImageSource) => {
    konvaImage.image(image);
    onImageLoad(konvaImage);
  });
};

export default getCauseMapStatusIconImage;
