import React from 'react';

import styled from 'styled-components';

type TimeIndicatorProps = {
  currentTimeSeconds: number;
};

const TimeIndicator: React.FC<TimeIndicatorProps> = ({
  currentTimeSeconds,
}) => {
  const currentTimeMinutesPart = Math.floor(currentTimeSeconds / 60);
  const currentTimeSecondsPart = Math.floor(currentTimeSeconds % 60);

  return (
    <Container>
      {String(currentTimeMinutesPart)}:
      {String(currentTimeSecondsPart).padStart(2, '0')}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 64px;
`;

export default TimeIndicator;
