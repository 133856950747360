export const WHITE_PIXEL_VALUE = 0xffffffff;
export const BLACK_PIXEL_VALUE = 0xff000000;
const DEFAULT_MARGIN_PX = 2;
const DOWNSIZED_WIDTH = 256;
const DOWNSIZED_HEIGHT = 256;

const isEmptyCanvasArea = (
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  dx: number,
  dy: number,
  isPixelNotEmpty: (value: number) => boolean,
  margin: number = DEFAULT_MARGIN_PX
): boolean => {
  return !new Uint32Array(
    context.getImageData(
      x + margin,
      y + margin,
      dx - 2 * margin,
      dy - 2 * margin
    ).data.buffer
  ).some(isPixelNotEmpty);
};

const getDownSizedCanvas = (originalCanvas: HTMLCanvasElement) => {
  const resizedCanvas = document.createElement('canvas');
  resizedCanvas.width = DOWNSIZED_WIDTH;
  resizedCanvas.height = DOWNSIZED_HEIGHT;
  const resizedContext = resizedCanvas.getContext('2d', {
    willReadFrequently: true,
  });
  if (resizedContext === null) {
    throw new Error(
      'Downsized canvas context is null. This should not happen.'
    );
  }
  // Draw the original canvas content onto the resized canvas
  resizedContext.drawImage(
    originalCanvas,
    0,
    0,
    DOWNSIZED_WIDTH,
    DOWNSIZED_HEIGHT
  );
  return { resizedCanvas, resizedContext };
};

const isEmptyCanvas = async (
  canvas: HTMLCanvasElement,
  emptyCanvasPixelValue = WHITE_PIXEL_VALUE,
  margin = DEFAULT_MARGIN_PX
): Promise<boolean> => {
  const width = canvas.width;
  const height = canvas.height;

  if (width === 0 || height === 0) {
    // eslint-disable-next-line no-console
    console.warn('Canvas has no width or height');
    return true;
  }

  const context = canvas.getContext('2d', { willReadFrequently: true });
  if (context === null) {
    throw new Error('Canvas context is null. This should not happen.');
  }

  const { resizedContext, resizedCanvas } = getDownSizedCanvas(canvas);
  try {
    return isEmptyCanvasArea(
      resizedContext,
      0,
      0,
      resizedCanvas.width,
      resizedCanvas.height,
      (x) => x !== emptyCanvasPixelValue,
      margin
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Could not check if canvas is empty:', e);
    // If we couldn't check, assume the canvas is empty
    return true;
  }
};

export default isEmptyCanvas;
