import { CognitePointCloudModel } from '@cognite/reveal';
import { use3dModels, useReveal } from '@cognite/reveal-react-components';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { useEffect, useMemo } from 'react';

import { getBoundingBoxFromAnnotation } from '../utils';

import { useAssetMappingsByModelIdQuery } from './use-asset-mappings-by-model-id-query';
import { useBoundingBoxByTreeIndexesQuery } from './use-bounding-box-by-tree-indexes-query';
import { usePointcloudAnnotationByAssetIdQuery } from './use-pointcloud-annotation-by-asset-id-query';

const ZOOM_BBOX_RADIUS_FACTOR = 4;
const ZOOM_DURATION_MS = 1000;

// Zooms camera on passed asset id
export const useZoomToClassicAsset = (assetId?: number | null) => {
  const viewer = useReveal();

  const { isIdm } = useAppConfigContext();

  const models = use3dModels();
  const model = models[0];
  const assetMappingsQuery = useAssetMappingsByModelIdQuery({
    assetId,
    modelId: model?.modelId || 0,
    revisionId: model?.revisionId || 0,
  });

  const cadTreeIndexes = useMemo(
    () => assetMappingsQuery.data?.map(({ treeIndex }) => treeIndex),
    [assetMappingsQuery]
  );

  const cadBoundingBoxQuery = useBoundingBoxByTreeIndexesQuery(cadTreeIndexes);

  const pointCloudAnnotations = usePointcloudAnnotationByAssetIdQuery(
    assetId || undefined
  );

  useEffect(() => {
    // Zoom in point cloud model
    if (!model || isIdm) return;

    if (
      model instanceof CognitePointCloudModel &&
      pointCloudAnnotations.data &&
      pointCloudAnnotations.data[0] &&
      pointCloudAnnotations.data[0].data
    ) {
      const boundingBox = getBoundingBoxFromAnnotation(
        pointCloudAnnotations.data[0].data,
        model
      );

      if (!boundingBox) return;
      viewer.fitCameraToBoundingBox(boundingBox, ZOOM_DURATION_MS);
    }

    // Zoom in CAD model
    else if (model?.type === 'cad' && cadBoundingBoxQuery.data) {
      viewer.fitCameraToBoundingBox(
        cadBoundingBoxQuery.data,
        ZOOM_DURATION_MS,
        ZOOM_BBOX_RADIUS_FACTOR
      );
    }
  }, [
    cadBoundingBoxQuery,
    model,
    viewer,
    pointCloudAnnotations,
    cadTreeIndexes,
    isIdm,
  ]);

  return {
    error:
      assetMappingsQuery.error ||
      cadBoundingBoxQuery.error ||
      pointCloudAnnotations.error,
    isLoading:
      assetMappingsQuery.isInitialLoading ||
      (cadBoundingBoxQuery.isLoading && Boolean(cadTreeIndexes?.length)) ||
      pointCloudAnnotations.isInitialLoading,
  };
};
