import { SegmentedControl } from '@cognite/cogs.js-v10';
import {
  useFeatureToggleConfig,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';
import { useRecoilState } from 'recoil';

import { selectedOverviewTableAtom } from '../state';
import { isOverviewTable, OVERVIEW_TABLE_OPTIONS } from '../utils';

export const ActivityTableSelectionControl: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const [activityTable, setActivityTable] = useRecoilState(
    selectedOverviewTableAtom
  );

  const handleSelectedControlChange = (nextValue: string) => {
    if (isOverviewTable(nextValue)) {
      setActivityTable(nextValue);
    }
  };

  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );

  const maintenanceTableEnabled =
    rootLocationFeatureToggles?.workorderChecklistFlow;
  const checklistTableEnabled =
    rootLocationFeatureToggles?.templateChecklistFlow;

  return (
    <SegmentedControl
      currentKey={activityTable}
      onButtonClicked={handleSelectedControlChange}
    >
      <SegmentedControl.Button
        key={OVERVIEW_TABLE_OPTIONS[0]}
        data-testid="activity-table-selection-control-maintenance"
        hidden={!maintenanceTableEnabled}
      >
        {t('ACTIVITY_OVERVIEW_SEGMENTED_CONTROL_MAINTENANCE', 'Maintenance')}
      </SegmentedControl.Button>

      <SegmentedControl.Button
        key={OVERVIEW_TABLE_OPTIONS[1]}
        data-testid="activity-table-selection-control-checklists"
        hidden={!checklistTableEnabled}
      >
        {t('ACTIVITY_OVERVIEW_SEGMENTED_CONTROL_CHECKLISTS', 'Checklists')}
      </SegmentedControl.Button>
    </SegmentedControl>
  );
};
