import { ReactNode } from 'react';

import { isPlainObject, startCase } from 'lodash';

import { getPropertyValue, hasProperty } from './propertyUtils';
import { TimeDisplay } from './TimeDisplay';

const getHumanReadablePathPortion = (path: string): string => {
  // if it contains a dot, pick the last part, otherwise return the whole thing
  if (path.includes('.')) {
    return path.split('.').pop()!;
  }

  return path;
};

const isMaybeTimestamp = <T,>(value: T | number): boolean => {
  if (typeof value !== 'number') {
    return false;
  }

  // This is the acceptable range of timestamps. If the number is between this range
  // then it's probably a timestamp.
  return value > 1000000000000 && value < 100000000000000;
};

const getDisplayValue = (value: any): ReactNode => {
  if (value instanceof Date) {
    return <TimeDisplay value={value.getTime()} />;
  }

  if (isMaybeTimestamp(value)) {
    // CogniteEvent might send the Timestamp as a number rather than a Date.
    // Maybe we should upstream a fix? But I'm guessing there's a legacy reason they are doing that
    // Note haxx, hopefully not an obscenely large number for no good reason
    return <TimeDisplay value={value} />;
  }

  if (isPlainObject(value)) {
    return JSON.stringify(value);
  }

  return value;
};

const resolveReadableProperty = <T,>(
  obj: T,
  path: string
): {
  label: string;
  value: ReactNode;
} => {
  const label = startCase(getHumanReadablePathPortion(path));

  if (!hasProperty(obj, path)) {
    return {
      label,
      value: '-',
    };
  }

  return {
    label,
    value: getDisplayValue(getPropertyValue(obj, path)),
  };
};
export default resolveReadableProperty;
