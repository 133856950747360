import React from 'react';

import styled from 'styled-components';

import { MAX_PAGE_WIDTH_MOBILE, MAX_PAGE_WIDTH_TABLET } from '../../constants';

type PageContentProps = {
  children: React.ReactNode;
  className?: string;
  'data-testid'?: string;
  fullWidth?: boolean;
};

export const PageContent = ({
  children,
  className,
  'data-testid': dataTestId,
  fullWidth,
}: PageContentProps): JSX.Element => {
  return (
    <Container className={className} data-testid={dataTestId}>
      <Content $fullWidth={fullWidth}>{children}</Content>
    </Container>
  );
};

const Content = styled.div<{ $fullWidth?: boolean }>`
  margin: 24px 64px;
  max-width: ${({ $fullWidth }) => ($fullWidth ? 'unset' : '1400px')};
  width: 100%;
`;

const Container = styled.div`
  container: main / inline-size;
  display: flex;
  justify-content: center;

  @container main (inline-size <= ${MAX_PAGE_WIDTH_TABLET}px) {
    ${Content} {
      margin: 20px 40px;
    }
  }

  @container main (inline-size <= ${MAX_PAGE_WIDTH_MOBILE}px) {
    ${Content} {
      margin: 12px 12px;
    }
  }
`;
