import type { ViewConfigDTO, ViewConfigEntities } from '@cognite/apm-config';
import type { ViewReference } from '@cognite/sdk';

export const createViewConfigFromViewReference = (
  viewReference: ViewReference,
  representsEntity: ViewConfigEntities
): ViewConfigDTO => {
  return {
    externalId: viewReference.externalId,
    space: viewReference.space,
    version: viewReference.version,
    representsEntity,
  };
};
