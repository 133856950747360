import omit from 'lodash/omit';

import { Data } from '@cognite/plotting-components';
import { CogniteClient } from '@cognite/sdk';

import calculateGranularity from './calculateGranularity';
import { CHART_POINTS_PER_SERIES } from './getTimeseriesChartData';
import { mapToChartData } from './mapToChartData';
import { DateRange } from './types';

type CdmDatapointResposeType = {
  id: string;
  instanceId: {
    externalId: string;
    space: string;
  };
  isString: boolean;
  isStep: boolean;
  unitExternalId: string;
  datapoints: {
    timestamp: Date;
    value: number;
  }[];
};

export type TimeseriesCdmDatapoints = Omit<
  CdmDatapointResposeType,
  'datapoints'
> & {
  data: Data;
};

export const getTimeseriesCdmDatapoints = async (
  sdk: CogniteClient,
  {
    query,
  }: {
    query: {
      instanceId: string;
      instanceSpace: string;
      dateRange: DateRange;
      numberOfPoints?: number;
    };
  }
): Promise<TimeseriesCdmDatapoints> => {
  const {
    instanceId,
    instanceSpace,
    dateRange: [startDate, endDate],
    numberOfPoints: limit = CHART_POINTS_PER_SERIES,
  } = query;

  const start = startDate.getTime();
  const end = endDate.getTime();
  const granularity = calculateGranularity([startDate, endDate], limit);

  const response = await sdk.post<{ items: CdmDatapointResposeType[] }>(
    `api/v1/projects/${sdk.project}/timeseries/data/list`,
    {
      data: {
        items: [
          {
            instanceId: {
              space: instanceSpace,
              externalId: instanceId,
            },
          },
        ],
        start,
        end,
        limit,
        granularity,
        aggregates: ['average', 'min', 'max', 'count'],
      },
      headers: {
        'cdf-version': 'alpha',
      },
    }
  );

  const data = response.data.items[0];

  const mappedToChartData = mapToChartData({
    datapoints: data.datapoints,
    metadata: {
      isStep: data.isStep,
      isString: data.isString,
      unit: data.unitExternalId,
      dataFetchMode: 'aggregate',
      numberOfPoints: data.datapoints.length,
    },
  });

  return { data: mappedToChartData, ...omit(data, 'datapoints') };
};
