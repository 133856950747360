import type { APMAsset, Operation } from '@cognite/apm-client';
import { groupOperationByField } from '@infield/features/activities/utils';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { OperationCardAssetList } from '../operation-card-asset-list';
import type { Label } from '../types';

import * as S from './elements';

type Props = {
  operations: Operation[];
  assets?: APMAsset[];
  onAssetClick: (assetExternalId: string) => void;
};

export const OperationList: FC<Props> = ({
  operations,
  assets,
  onAssetClick,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const groupedOperations = groupOperationByField(
    operations,
    assets ?? [],
    'id'
  );

  const getLabels = (operation: Operation): Label[] => {
    const labels = [];
    if (operation.mainResource) {
      labels.push({
        label: t('OPERATION_LABEL_WORK_CENTER', 'Work Center'),
        value: String(operation.mainResource),
      });
    }
    if (operation.order) {
      labels.push({
        label: t('OPERATION_LABEL_ORDER', 'Order'),
        value: String(operation.order),
      });
    }
    return labels;
  };

  return (
    <S.Container>
      {groupedOperations.map(({ operation, assets }) => {
        const { externalId, title } = operation;

        return (
          <OperationCardAssetList
            key={externalId}
            title={title ?? ''}
            labels={getLabels(operation)}
            assets={assets}
            onAssetClick={onAssetClick}
          />
        );
      })}
    </S.Container>
  );
};
