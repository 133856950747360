import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const SearchWrapper = styled(Flex).attrs({
  gap: 16,
  direction: 'column',
})``;

export const SearchInput = styled.div`
  .input-wrapper,
  input {
    width: 100%;
  }
`;

export const SearchList = styled.div`
  border: 1px solid ${({ theme }) => theme['border--muted']};

  > div {
    &:nth-child(even) {
      background-color: ${({ theme }) => theme['surface--medium']};
    }
  }
`;
