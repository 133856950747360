import type { ViewConfigDTO } from '@cognite/apm-config';
import type { ViewReference } from '@cognite/sdk';

export const getViewReference = (
  viewMapping: ViewConfigDTO | undefined
): ViewReference | undefined => {
  if (!viewMapping) return;
  return {
    externalId: viewMapping.externalId,
    space: viewMapping.space,
    type: 'view',
    version: viewMapping.version,
  };
};
