import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useIsAppConfiguratorQuery = () => {
  const { client } = useAuthContext();
  return useQuery(
    [QueryKeys.APP_CONFIGURATOR_QUERY],
    async () => {
      const groups = await client.groups.list();

      const hasConfigPermission =
        groups.filter((group) => group.name === 'applications-configuration')
          .length > 0;

      return hasConfigPermission;
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: QueryKeys.APP_CONFIGURATOR_QUERY,
          },
        }),
    }
  );
};
