import {
  DocumentIcon,
  TagIcon,
  TimeseriesIcon,
  TreeIcon,
} from '@cognite/cogs.js-v10';
import type { Timeseries } from '@cognite/sdk';
import {
  useFeatureToggleConfig,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { TIMESERIES_ASSET_EXTERNAL_ID_KEY } from '@infield/features/timeseries/timeseries-idm-migrator';
import { selectedTimeseriesExternalIdsAtom } from '@infield/features/trends/state';
import { useDebounce } from '@infield/hooks/use-debounce';
import { useSearch } from '@infield/hooks/use-search';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { useFDMServices } from '@infield/providers/fdm-services';
import { useRef, useState } from 'react';
import type { FC } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { TopBarSearchUI } from './top-bar-search-UI';
import type { SearchResourceType, SearchTab } from './types';

const searchTabs: SearchTab[] = [
  {
    key: 'asset',
    label: 'Asset',
    icon: <TagIcon />,
  },
  {
    key: 'hierarchy',
    label: 'Hierarchy',
    icon: <TreeIcon />,
  },
  {
    key: 'timeseries',
    label: 'Time series',
    icon: <TimeseriesIcon />,
  },
  {
    key: 'documents',
    label: 'Documents',
    icon: <DocumentIcon />,
  },
];

interface Props {
  assetPageId?: string;
  onClose: () => void;
  onResultSelected?: () => void;
}

export const TopBarSearch: FC<Props> = ({
  assetPageId,
  onClose,
  onResultSelected,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.search);
  const isDesktop = useIsDesktop();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState('');
  const debouncedQuery = useDebounce(searchInput, 750);
  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  const setSelectedTimeseriesExternalIds = useSetRecoilState(
    selectedTimeseriesExternalIdsAtom
  );
  const { assetService } = useFDMServices();

  const navigate = useNavigate();
  const { searchResourceType } = useParams();
  const [selectedTab, setSelectedTab] = useState<SearchResourceType>(
    (searchResourceType || 'asset') as SearchResourceType
  );

  const isAssetHierarchySearchEnabled = Boolean(assetPageId);

  const {
    assetSearchResult,
    timeseriesSearchResult,
    documentSearchResult,
    parentAsset,
    childAssets,
  } = useSearch({
    query: debouncedQuery,
    resourceType: selectedTab,
    resultLimit: 50,
    assetPageId,
  });

  const filteredSearchTabs = searchTabs.filter((tab) => {
    if (tab.key === 'timeseries') {
      return rootLocationFeatureToggles?.trends !== false;
    }
    if (tab.key === 'documents') {
      return rootLocationFeatureToggles?.documents !== false;
    }
    if (tab.key === 'hierarchy') {
      return isAssetHierarchySearchEnabled;
    }
    return true;
  });

  const handleAssetSelect = (assetExternalId: string) => {
    navigate(`/asset/${encodeURIComponent(assetExternalId)}`);
    onClose();
  };

  const handleDocumentSelect = (documentExternalId: string, space?: string) => {
    searchParams.set('viewDocId', encodeURIComponent(documentExternalId));
    if (space) {
      searchParams.set('viewDocSpace', encodeURIComponent(space));
    }
    setSearchParams(searchParams);
    onResultSelected?.();
  };

  const handleTimeseriesSelect = async (timeseries: Timeseries) => {
    if (timeseries?.assetId) {
      const asset = await assetService.getAssetById(timeseries.assetId);
      if (asset?.externalId) {
        navigate(`/asset/${encodeURIComponent(asset.externalId)}/trends`);
        setSelectedTimeseriesExternalIds([timeseries.externalId!]);
      }
    } else if (timeseries.metadata?.[TIMESERIES_ASSET_EXTERNAL_ID_KEY]) {
      navigate(
        `/asset/${encodeURIComponent(
          timeseries.metadata?.[TIMESERIES_ASSET_EXTERNAL_ID_KEY]
        )}/trends`
      );
      setSelectedTimeseriesExternalIds([timeseries.externalId!]);
    }
    onClose();
  };

  const handleTabSelect = (tab: SearchResourceType) => {
    setSelectedTab(tab);
    // Wait for browser to finish rendering before focusing the input
    requestAnimationFrame(() => {
      searchInputRef.current?.focus(); // Focus after the next render cycle
    });
  };

  return (
    <TopBarSearchUI
      searchInput={searchInput}
      searchInputRef={searchInputRef}
      searchTabs={filteredSearchTabs}
      selectedTab={selectedTab}
      assetSearchResult={assetSearchResult}
      documentSearchResult={documentSearchResult}
      timeseriesSearchResult={timeseriesSearchResult}
      parentAsset={parentAsset}
      childAssets={childAssets}
      fullscreen={!isDesktop}
      t={t}
      setSearchInput={setSearchInput}
      setSelectedTab={handleTabSelect}
      onAssetSelect={handleAssetSelect}
      onDocumentSelect={handleDocumentSelect}
      onTimeseriesSelect={handleTimeseriesSelect}
      onClose={onClose}
    />
  );
};
