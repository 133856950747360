import type { APMClient, InFieldUser, TaskInterval } from '@cognite/apm-client';
import { FDMClient } from '@cognite/fdm-client';
import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import type { CogniteClient } from '@cognite/sdk';

export class IntervalService extends FDMClient {
  apmClient: APMClient;
  constructor(client: CogniteClient, apmClient: APMClient) {
    super(client);
    this.apmClient = apmClient;
  }

  async getIntervals(filters?: Filters, sort?: Sort, pageSize = 1000) {
    const {
      list: { items: intervals },
    } = await this.apmClient.intervals.list(
      `
      externalId
      status
      startTime
      endTime
      timezone
      freq
      interval
      until
      byDay
      exceptionDates

      createdBy {
        externalId
        name
        email
        space
      }
      updatedBy {
        externalId
        name
        email
        space
      }
      `,
      { filters, sort, pageSize }
    );
    return intervals as TaskInterval[];
  }

  async createIntervals(intervals: TaskInterval[], user: InFieldUser) {
    const upsertedIntervals = intervals.map((interval) => ({
      ...interval,
      createdBy: user,
    }));
    return this.upsertIntervals(upsertedIntervals);
  }

  async updateIntervals(intervals: TaskInterval[], user: InFieldUser) {
    const upsertedIntervals = intervals.map((interval) => ({
      ...interval,
      updatedBy: user,
    }));
    return this.upsertIntervals(upsertedIntervals);
  }

  async upsertIntervals(intervals: TaskInterval[]) {
    const intervalsToUpsert = intervals.map((interval) => {
      return {
        ...interval,
        updatedBy: interval.updatedBy?.externalId
          ? {
              externalId: interval.updatedBy?.externalId,
              space: this.apmClient.userInstanceSpace,
            }
          : undefined,
        createdBy: interval.createdBy?.externalId
          ? {
              externalId: interval.createdBy?.externalId,
              space: this.apmClient.userInstanceSpace,
            }
          : undefined,
      };
    });

    return this.apmClient.intervals.upsert(intervalsToUpsert);
  }

  async deleteIntervals(externalIds: string[]) {
    return this.apmClient.intervals.delete(externalIds);
  }
}
