import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useDatamodelVersion = (space: string) => {
  const { client } = useAuthContext();

  return useQuery(
    [QueryKeys.DATAMODEL_VERSION, space],
    async () => {
      return client.dataModels
        .list({ space, allVersions: true, includeGlobal: true })
        .then((models) =>
          models.items
            .filter((x) => x.externalId === space)
            .map((x) => x.version)
        );
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: QueryKeys.DATAMODEL_VERSION,
          },
        }),
      enabled: space !== '',
    }
  );
};
