import type { Notification } from '@cognite/apm-client';
import { Flex, TagChip } from '@cognite/cogs.js-v10';
import * as S from '@infield/features/asset/elements';
import type { ListItem } from '@infield/features/asset/shared';
import { StyledList } from '@infield/features/asset/shared';
import type { FC } from 'react';

type Props = {
  notifications: Notification[];
  onNotificationClick: (notification: string) => void;
};

export const AssetNotificationsList: FC<Props> = ({
  notifications,
  onNotificationClick,
}) => {
  const items = (): ListItem[] => {
    if (notifications) {
      return notifications.map((notification) => {
        return {
          key: notification.externalId,
          onClick: () => onNotificationClick(notification.externalId!),
          content: (
            <>
              <Flex justifyContent="space-between" alignItems="center" gap={3}>
                <S.ItemTitle>{notification.title || '-'}</S.ItemTitle>
                {notification.type && (
                  <TagChip size="x-small" label={notification.type} />
                )}
              </Flex>
              <S.ItemDescription size="medium">
                {notification.sourceId || '-'}
              </S.ItemDescription>
            </>
          ),
        };
      });
    }
    return [];
  };

  return <StyledList items={items()} />;
};
