import Konva from 'konva';

import { Container } from '../../containers';
import { SELECTION_RECTANGLE_STYLE } from '../../UnifiedViewerTransformer';
import getRectRelativeToStage from '../../utils/getRectRelativeToStage';

const createTargetIndicatorForContainer = (
  container: Container
): Konva.Rect => {
  const node = container.getNode();
  const rect = getRectRelativeToStage(container.getContentNode());
  const { x, y, width, height } = rect;
  const dropTargetIndicator = new Konva.Rect({
    id: `drop-target-indicator-${node.id()}`,
    parentId: node.id(),
    attrs: { container },
    isParentAnnotationNode: false,
    x,
    y,
    width,
    height,
    stroke: SELECTION_RECTANGLE_STYLE.stroke,
    strokeWidth: 2,
    strokeScaleEnabled: false,
  });
  dropTargetIndicator.listening(false);
  return dropTargetIndicator;
};

export default createTargetIndicatorForContainer;
