export type IdEither = {
  type: 'internal' | 'external';
  id?: number;
  externalId?: string;
};

export type BaseFilterDTO = {
  dataSetIds?: number[];
  assetSubtreeIds?: IdEither[];
};

export type DataModelConfigDTO = {
  externalId: string;
  space: string;
  version: string;
};

export type ViewConfigDTO = {
  externalId: string;
  space: string;
  version: string;
  representsEntity: ViewConfigEntities;
};

export type ViewConfigEntities =
  | 'MAINTENANCE_ORDER'
  | 'OPERATION'
  | 'ASSET'
  | 'NOTIFICATION';

export const entityMapping: { [key: string]: ViewConfigEntities } = {
  activity: 'MAINTENANCE_ORDER',
  asset: 'ASSET',
  operation: 'OPERATION',
  notification: 'NOTIFICATION',
};

export type SceneConfigDTO = {
  externalId: string;
  space: string;
};

export type AssetCentricConfigDTO = {
  dataSetIds?: number[];
  assetSubtreeIds?: IdEither[];
  assets?: BaseFilterDTO;
  events?: BaseFilterDTO;
  files?: BaseFilterDTO;
  timeseries?: BaseFilterDTO;
  sequences?: BaseFilterDTO;
};

export type LocationFilterDTO = {
  externalId: string;
  id: number;
  name: string;
  createdTime: number;
  lastUpdatedTime: number;
  description?: string;
  parentId?: number;
  dataModels?: DataModelConfigDTO[];
  views?: ViewConfigDTO[];
  instanceSpaces?: string[];
  scene?: SceneConfigDTO;
  assetCentric?: AssetCentricConfigDTO;
};

export type LocationFilterUpsertRequestDTO = {
  id?: number;
  externalId: string;
  name: string;
  description?: string;
  parentId?: number;
  dataModels?: DataModelConfigDTO[];
  views?: ViewConfigDTO[];
  instanceSpaces?: string[];
  scene?: SceneConfigDTO;
  assetCentric?: AssetCentricConfigDTO;
};

export type LocationFilterListRequestDTO = {
  flat?: boolean;
};
