import type { APMAsset } from '@cognite/apm-client';
import { Skeleton } from '@cognite/cogs.js-v10';
import type { Asset } from '@cognite/sdk';
import { useAppConfigQuery } from '@infield/features/app-config';
import type { AssetPropertiesListItem } from '@infield/features/asset/asset-properties';
import { AssetPropertiesList } from '@infield/features/asset/asset-properties';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './elements';

type Props = {
  asset?: Asset | APMAsset;
  isLoading?: boolean;
};

export const AssetPropertiesView: FC<Props> = ({ asset, isLoading }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);
  const navigate = useNavigate();
  const { data: appConfig } = useAppConfigQuery();

  const linkableAssetKeys =
    appConfig?.featureConfiguration?.assetPageConfiguration?.propertyCard
      ?.linkableAssetKeys;

  const propertyListItems = (): AssetPropertiesListItem[] => {
    return [
      {
        fieldName: t('ASSET_PROPERTIES_PAGE_FIELD_COLUMN_HEADER', 'Field'),
        fieldValue: t('ASSET_PROPERTIES_PAGE_VALUE_COLUMN_HEADER', 'Value'),
        isHeader: true,
      },
      ...Object.keys(asset?.metadata || {}).map((key) => {
        const isClickable = linkableAssetKeys?.includes(key);
        const value = asset!.metadata![key];
        return {
          fieldName: key,
          fieldValue: value,
          onClickValue: isClickable
            ? () => navigate(`/asset/${encodeURIComponent(value)}`)
            : undefined,
        };
      }),
    ] as AssetPropertiesListItem[];
  };

  if (isLoading) {
    return <Skeleton.List lines={8} />;
  }

  if (!isLoading && asset === undefined) {
    return (
      <div>
        {t(
          'ASSET_PROPERTIES_PAGE_FAILED_TO_LOAD_ASSET_ERROR_MESSAGE',
          'Failed to load asset, try refreshing the page'
        )}
      </div>
    );
  }
  return (
    <S.Container direction="column">
      {asset?.metadata && (
        <S.TableWrapper direction="row">
          <AssetPropertiesList items={propertyListItems()} />
        </S.TableWrapper>
      )}
    </S.Container>
  );
};
