import type { Language } from '@cognite/user-profile-components';

export const LOCIZE_PROJECT_ID = String(
  import.meta.env?.LOCIZE_PROJECT_ID || 'c29c3c04-808e-4b44-92a6-6ab4a7ebb36d'
);

export const LOCIZE_API_KEY = String(
  import.meta.env?.LOCIZE_API_KEY || 'b61bb8e6-80a0-499b-916c-551fe05277bd'
);

export const LOCIZE_LANGUAGE = localStorage.getItem('INFIELD_APP_LANG') || 'en';

export const LOCIZE_NAMESPACES = {
  activity: 'activity',
  asset: 'asset',
  assignment: 'assignment',
  checklist: 'checklist',
  conditions: 'conditions',
  configuration: 'configuration',
  fileUploader: 'FileUploader',
  documents: 'documents',
  errorView: 'ErrorView',
  fileViewer: 'file-viewer',
  filters: 'filters',
  infobar: 'infobar',
  mainTopbar: 'main-topbar',
  measurement: 'measurement',
  mediaManager: 'media-manager',
  notification: 'notification',
  observations: 'observations',
  search: 'search',
  sidebar: 'sidebar',
  template: 'template',
  threeD: 'ThreeD',
  timeseries: 'timeseries',
  trends: 'trends',
  user: 'user',
  userOnboarding: 'user-onboarding',
  landingPage: 'LandingPage',
};

export const DEFAULT_LANGUAGE: Language = {
  code: 'en',
  label: 'English',
};

export const languageLabels: { [key in Language['code']]?: string } = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  nl: 'Nederlands',
  nb: 'Norsk',
  pt: 'Português',
  sv: 'Svenska',
  ko: '한국어',
  zh: '中文',
  ja: '日本語',
};
