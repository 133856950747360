export const findClosestDatapointInTime = (
  series: { timestamp: Date; value: number }[],
  targetTimestamp: Date
): { timestamp: Date; value: number } | undefined => {
  if (series.length === 0) return undefined;

  return series.reduce((closestDataPoint, currentDataPoint) => {
    const closestDataPointDifference = Math.abs(
      targetTimestamp.getTime() - closestDataPoint.timestamp.getTime()
    );
    const currentDataPointDifference = Math.abs(
      targetTimestamp.getTime() - currentDataPoint.timestamp.getTime()
    );

    return currentDataPointDifference < closestDataPointDifference
      ? currentDataPoint
      : closestDataPoint;
  }, series[0]);
};
