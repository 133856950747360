import { Flex } from '@cognite/cogs.js-v10';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { ProgressBar } from '@infield/features/ui/progress-bar';
import { QueryKeys } from '@infield/utils/queryKeys';
import {
  onlineManager,
  useIsMutating,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';

import { AlertBox } from '../alert-box';
import * as S from '../elements';
import { useNetworkStatusContext } from '../network-status-provider';

import { getProgressPercentage } from './utils';

export const BackOnlineBanner: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.infobar);
  const metrics = useMetrics(METRICS_NAMESPACES.offlineMode);
  const queryClient = useQueryClient();
  const { closeBackOnlineBanner } = useNetworkStatusContext();
  const remainingChangesToUpload = useIsMutating();
  const [totalChangesToUpload] = useState(remainingChangesToUpload);

  const isUploading = totalChangesToUpload !== 0;
  const percentage = getProgressPercentage(
    totalChangesToUpload,
    remainingChangesToUpload
  );
  const isDone = percentage === 100;

  useEffect(() => {
    metrics.track('backOnlineBannerShown');
  }, [metrics]);

  if (!isUploading || isDone) {
    closeBackOnlineBanner();
    // invalidate relevant queries to refresh the data
    queryClient.invalidateQueries({ queryKey: [QueryKeys.CHECKLIST] });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.CHECKLIST_ITEM_STATUS],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TIMESERIES],
    });
  }

  const handleContinuePausedMutations = useCallback(() => {
    const numberOfRunningMutations = queryClient.isMutating({
      predicate: (mutation) => !mutation.state.isPaused,
    });

    if (numberOfRunningMutations === 0 && onlineManager.isOnline()) {
      const nextPausedMutation = queryClient
        .getMutationCache()
        .find({ predicate: (mutation) => mutation.state.isPaused });

      if (nextPausedMutation) {
        nextPausedMutation.continue();
      }
    }
  }, [queryClient]);

  useEffect(() => {
    // if there is an error due to network, all mutations will move to paused state
    // once online, we need to manually continue the next paused mutation to make sure the upload is not stuck
    handleContinuePausedMutations();
  }, [handleContinuePausedMutations]);

  return (
    <AlertBox
      status="success"
      title={t('NETWORK_STATUS_BANNER_ONLINE_TITLE', 'Back Online')}
      description={
        isUploading
          ? t(
              'NETWORK_STATUS_BANNER_ONLINE_DESCRIPTION',
              'The data upload is in progress. Please keep InField open.'
            )
          : undefined
      }
      collapsible={false}
      additionalContent={
        isUploading ? (
          <S.ProgressBarWrapper>
            <ProgressBar progress={percentage || 0} type="success" rounded />
            <Flex justifyContent="space-between">
              <S.ProgressText
                data-testid={
                  isDone
                    ? 'back-online-banner-done'
                    : 'back-online-banner-in-progress'
                }
                size="x-small"
              >
                {isDone
                  ? t(
                      'NETWORK_STATUS_BANNER_ONLINE_PROGRESS_BAR_UPLOADING_IS_FINISHED',
                      'Done'
                    )
                  : t(
                      'NETWORK_STATUS_BANNER_ONLINE_PROGRESS_BAR_UPLOADING',
                      'Uploading...'
                    )}
              </S.ProgressText>
              <S.ProgressText size="x-small">{percentage}%</S.ProgressText>
            </Flex>
          </S.ProgressBarWrapper>
        ) : undefined
      }
    />
  );
};
