import type { InFieldUser } from '@cognite/apm-client';
import { Body, Input, SearchIcon, UserIcon } from '@cognite/cogs.js-v10';
import type { Discipline } from '@infield/features/app-config/types';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useEffect, useState } from 'react';
import type { FC } from 'react';

import { AssignmentAssignees } from './assignment-assignees';
import { AssignmentSelect } from './assignment-select';
import * as S from './elements';
import type { Assignees } from './types';

interface Props {
  activityAssignees?: Assignees;
  onChange?: (assignedTo: string[]) => void;
}

export const Assignment: FC<Props> = ({ activityAssignees, onChange }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.assignment);
  const [searchValue, setSearchValue] = useState('');
  const [assignedUsers, setAssignedUsers] = useState<InFieldUser[] | undefined>(
    activityAssignees?.users
  );
  const [assignedDisciplines, setAssignedDisciplines] = useState<
    Discipline[] | undefined
  >(activityAssignees?.disciplines);
  const [showAssignmentSearchList, setShowAssignmentSearchList] =
    useState(false);

  const isAssigned =
    (assignedUsers && assignedUsers?.length > 0) ||
    (assignedDisciplines && assignedDisciplines?.length > 0);

  const handleToggleUser = (userToToggle: InFieldUser) => {
    const wasUserAssigned =
      assignedUsers &&
      assignedUsers.find((user) => user.externalId === userToToggle.externalId);

    if (wasUserAssigned) {
      setAssignedUsers((prevUsers) =>
        prevUsers?.filter(
          (prevUser) => prevUser.externalId !== userToToggle.externalId
        )
      );
    } else {
      setAssignedUsers((prevUsers) => [...(prevUsers ?? []), userToToggle]);
    }
  };

  const handleToggleDiscipline = (disciplineToToggle: Discipline) => {
    const wasDisciplineAssigned =
      assignedDisciplines &&
      assignedDisciplines.filter(
        (discipline) => discipline.externalId === disciplineToToggle.externalId
      ).length > 0;

    if (wasDisciplineAssigned) {
      setAssignedDisciplines((prevDisciplines) =>
        prevDisciplines?.filter(
          (prevDiscipline) =>
            prevDiscipline.externalId !== disciplineToToggle.externalId
        )
      );
    } else {
      setAssignedDisciplines((prevDisciplines) => [
        ...(prevDisciplines ?? []),
        disciplineToToggle,
      ]);
    }
  };

  const handleRemoveUser = (removeUser: InFieldUser) => {
    setAssignedUsers((prevUsers) =>
      prevUsers?.filter(
        (prevUser) => prevUser.externalId !== removeUser.externalId
      )
    );
  };

  const handleRemoveDiscipline = (removeDiscipline: Discipline) => {
    setAssignedDisciplines((prevDisciplines) =>
      prevDisciplines?.filter(
        (prevDiscipline) =>
          prevDiscipline.externalId !== removeDiscipline.externalId
      )
    );
  };

  const handleOnDone = () => {
    setShowAssignmentSearchList(false);
    setSearchValue('');
  };

  const createDisciplineAssignIds = (disciplines: Discipline[]) => {
    if (disciplines.length === 0) return [];
    return disciplines.map(
      (selectedDiscipline) => `discipline:${selectedDiscipline.externalId}`
    );
  };

  useEffect(() => {
    const usersExternalIds = assignedUsers?.map(
      (selectedUser) => selectedUser.externalId
    );

    const disciplinesExternalIds = createDisciplineAssignIds(
      assignedDisciplines ?? []
    );

    onChange?.([
      ...(disciplinesExternalIds ?? []),
      ...(usersExternalIds ?? []),
    ]);
  }, [assignedUsers, assignedDisciplines, onChange]);

  return (
    <S.Container>
      <Body size="medium" strong>
        {t('ASSIGN_TITLE', 'Assign to discipline(s)')}
      </Body>
      <S.SearchResultDropdown
        visible={showAssignmentSearchList}
        onClickOutside={() => setShowAssignmentSearchList(false)}
        content={
          <AssignmentSelect
            query={searchValue}
            selectedUsers={assignedUsers ?? []}
            selectedDisciplines={assignedDisciplines ?? []}
            onAssignUser={handleToggleUser}
            onAssignDiscipline={handleToggleDiscipline}
            onDone={handleOnDone}
          />
        }
        appendTo={document.body}
      >
        <Input
          icon={<SearchIcon />}
          clearable
          fullWidth
          onClick={() => setShowAssignmentSearchList(true)}
          placeholder={t(
            'ASSIGN_SEARCH_PLACEHOLDER',
            'Search disciplines or individuals'
          )}
          helpText={t(
            'ASSIGN_SEARCH_HELP_TEXT',
            'You can always reassign or add assignees later.'
          )}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          data-testid="assignment-search-input"
        />
      </S.SearchResultDropdown>
      {isAssigned ? (
        <AssignmentAssignees
          users={assignedUsers ?? []}
          disciplines={assignedDisciplines ?? []}
          onRemoveDiscipline={handleRemoveDiscipline}
          onRemoveUser={handleRemoveUser}
        />
      ) : (
        <S.EmptyAssignment>
          <UserIcon />
          <Body size="small">
            {t('ASSIGN_NONE_ASSIGNED_MESSAGE', 'No one is assigned yet')}
          </Body>
        </S.EmptyAssignment>
      )}
    </S.Container>
  );
};
