import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import EmptyImg from '@infield/assets/EmptyImg.svg';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { MediaEmptyView } from '../media-empty-view';
import { MediaFetcher } from '../media-viewer/media-fetcher';
import { MediaViewer } from '../media-viewer/media-viewer';
import type { ImageItemMetadata, ImageToUpload } from '../types';

import * as S from './elements';

export interface Props {
  mediaToUpload: ImageToUpload[];
  uploadedMediaToShow: InstanceOrExternalId[];
  onSelectMedia: (media: ImageToUpload) => void;
  getMediaUpdatedMetadata?: (
    externalId: string
  ) => ImageItemMetadata | undefined;
  showEmptyView: boolean;

  isLoading: boolean;
  isUploadingMedia: boolean;
  loadingText?: string;
}

export const SelectedMediaDialogueContent: FC<Props> = ({
  mediaToUpload,
  showEmptyView,
  getMediaUpdatedMetadata,
  uploadedMediaToShow,
  onSelectMedia,
  isLoading,
  loadingText,
  isUploadingMedia,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);
  return (
    <S.Container>
      {showEmptyView && (
        <MediaEmptyView
          isLoading={isLoading}
          loadingText={loadingText}
          text={t(
            'EMPTY_STATE_MESSAGE_NO_IMAGES_OR_VIDEOS',
            'No images or videos uploaded yet. Select an option below to add images or videos.'
          )}
          emptyImage={EmptyImg}
        />
      )}

      {!showEmptyView && (
        <S.MediaGrid>
          {mediaToUpload?.map((media) => (
            <MediaViewer
              toBeUploaded
              isUploading={isUploadingMedia}
              key={media.externalId}
              media={media}
              onClick={() => onSelectMedia(media)}
            />
          ))}
          {uploadedMediaToShow.map(({ externalId, space }) => (
            <MediaFetcher
              key={externalId}
              fileExternalId={externalId}
              fileSpace={space}
            >
              {({ media }) => (
                <MediaViewer
                  key={externalId || media?.externalId}
                  media={{
                    ...media,
                    metadata: {
                      ...media.metadata,
                      ...(getMediaUpdatedMetadata
                        ? getMediaUpdatedMetadata(media.externalId!)
                        : {}),
                    },
                  }}
                  onClick={() => onSelectMedia(media)}
                />
              )}
            </MediaFetcher>
          ))}
        </S.MediaGrid>
      )}
    </S.Container>
  );
};
