import type { Filters } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { TemplateActivityTypes } from '../../types';

export const useActivityDetails = (externalId: string) => {
  const { activityService } = useFDMServices();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const filters: Filters[] = [];

  if (externalId.length > 0) {
    filters.push({
      equals: {
        property: 'externalId',
        eq: externalId,
      },
    });
  }

  const activityFilter: Filters = {
    and: [
      ...filters,
      {
        not: {
          equals: {
            property: 'type',
            eq: TemplateActivityTypes.template,
          },
        },
      },
    ],
  };

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  return useQuery(
    [QueryKeys.ACTIVITY_DETAILS, activityFilter],
    async () => {
      sliTimerStartTime.current = Date.now();

      return activityService.getActivityDetails(activityFilter);
    },
    {
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.ACTIVITY_DETAILS,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.ACTIVITY_DETAILS, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.ACTIVITY_DETAILS, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: externalId.length > 0,
    }
  );
};
