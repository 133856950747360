import { Body, Heading } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ChartWrapper = styled.div`
  width: 100%;
  height: 120px;

  .linechart-container {
    grid-template: 'context .' auto / 1fr auto auto !important;
  }

  .x-axis-container {
    display: none !important;
  }
`;

export const TrendName = styled(Body).attrs(() => ({ size: 'x-small' }))`
  color: ${({ theme }) => theme['text-icon--muted']};
  margin: 0;
`;

export const ValueWrapper = styled.p`
  margin: 0;
`;

export const Value = styled(Heading).attrs({ level: 3 })<{ color: string }>`
  color: ${({ color }) => color};
`;

export const Unit = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme['text-icon--muted']};
  font-size: 16px;
`;

export const YAxisValue = styled.p`
  margin: 0;
  text-align: right;
  color: ${({ theme }) => theme['text-icon--muted']};
`;
