import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useFdmSpace = () => {
  const { client } = useAuthContext();

  return useQuery(
    [QueryKeys.FDM_SPACE],
    async () => {
      return client.spaces.list().then((res) => res.items);
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: QueryKeys.FDM_SPACE,
          },
        }),
    }
  );
};
