import Konva from 'konva';
import { v4 as uuid } from 'uuid';

import getCachedImage from '../annotations/getCachedImage';
import { AnnotationType, Position, Size } from '../annotations/types';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import { UnifiedViewer } from '../UnifiedViewer';
import getAspectRatio from '../utils/getAspectRatio';
import getFontSizeInAbsoluteUnits from '../utils/getFontSizeInAbsoluteUnits';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';

import { ContainerAttachmentHelper } from './ContainerAttachmentHelper';
import getShapeEventPropsFromKonvaShape from './getShapeEventPropsFromKonvaShape';
import Tool from './Tool';
import { ImageToolConfig, ToolType } from './types';

const DEFAULT_IMAGE_SIZE_ABSOLUTE_UNITS = '150px';
const PREVIEW_NODE_OPACITY = 0.5;

const getNodeDimensions = (node: Konva.Node): Size & Position => ({
  width: node.width(),
  height: node.height(),
  x: node.x(),
  y: node.y(),
});

export default class ImageTool extends Tool {
  public readonly type = ToolType.IMAGE;
  public override readonly cursor = 'crosshair';
  private config: ImageToolConfig;

  private previewNode: Konva.Image | undefined;
  private containerAttachmentHelper: ContainerAttachmentHelper;

  public constructor({
    unifiedViewer,
    config,
  }: {
    unifiedViewer: UnifiedViewer;
    config: ImageToolConfig;
  }) {
    super(unifiedViewer);
    this.containerAttachmentHelper = new ContainerAttachmentHelper(
      unifiedViewer
    );
    this.config = config;
    this.setConfig(config);
  }

  public setConfig = (config: ImageToolConfig): void => {
    this.config = config;

    const konvaImage = new Konva.Image({
      image: undefined,
      id: uuid(),
      opacity: PREVIEW_NODE_OPACITY,
      userGenerated: true,
    });

    getCachedImage(this.config.imageUrl, (image) => {
      this.previewNode?.destroy();

      konvaImage.image(image);
      this.previewNode = konvaImage;
      this.updatePreviewNodeSize();
      konvaImage.listening(false);
      konvaImage.hide();

      this.containerAttachmentHelper.start([this.previewNode]);
      this.unifiedViewer.layers.main.add(this.previewNode);
    });
  };

  private updatePreviewNodeSize = (): void => {
    const image = this.previewNode?.image();
    if (this.previewNode === undefined || image === undefined) {
      return;
    }

    const imageSize = getFontSizeInAbsoluteUnits(
      this.config?.imageSize ?? DEFAULT_IMAGE_SIZE_ABSOLUTE_UNITS
    );

    const aspectRatio = getAspectRatio(image);
    this.previewNode.width(imageSize);
    this.previewNode.height(imageSize / aspectRatio);
  };

  public override onMouseDown = (): void => {
    if (this.previewNode === undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        'ImageTool: preview node or image is undefined on mouse down'
      );
      return;
    }

    const dimensions = getNodeDimensions(this.previewNode);

    const imageNode = new Konva.Image({
      [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
      image: this.previewNode.image(),
      id: uuid(),
      url: this.config.imageUrl,
      opacity: 1,
      userGenerated: true,
      name: 'user-drawing',
      source: 'ImageTool',
      containerId: undefined,
      annotationType: AnnotationType.IMAGE,
      isSelectable: true,
      isDraggable: true,
      isResizable: true,
      originalImageSize:
        this.config?.imageSize ?? DEFAULT_IMAGE_SIZE_ABSOLUTE_UNITS,
      ...dimensions,
    });
    this.unifiedViewer.setDirtyNodes([imageNode]);
    this.containerAttachmentHelper.end([imageNode]);
    this.containerAttachmentHelper.start([this.previewNode]);
    this.unifiedViewer.commitDirtyNodes();

    getMetricsLogger()?.trackEvent(TrackedEventType.CREATE_IMAGE, {
      ...getShapeEventPropsFromKonvaShape(imageNode),
    });
  };

  private updatePreviewNodePosition = (): void => {
    if (this.previewNode === undefined) {
      return;
    }

    const translatedMousePosition =
      this.unifiedViewer.stage.getRelativePointerPosition();

    if (translatedMousePosition === null) {
      return;
    }

    this.previewNode.x(
      translatedMousePosition.x - this.previewNode.width() / 2
    );
    this.previewNode.y(
      translatedMousePosition.y - this.previewNode.height() / 2
    );
  };

  public override onMouseMove = (): void => {
    if (this.previewNode === undefined) {
      return;
    }
    this.updatePreviewNodePosition();

    if (!this.previewNode.isVisible()) {
      this.previewNode.show();
    }
  };

  public override onDestroy(): void {
    this.previewNode?.destroy();
    this.containerAttachmentHelper.onDestroy();
  }
}
