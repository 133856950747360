import { Body, Flex, TagChip } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ClickableArea = styled(Flex).attrs({
  direction: 'column',
})`
  cursor: pointer;
`;

export const TagContainer = styled(Flex).attrs({
  direction: 'row',
  justifyContent: 'space-between',
})`
  margin-top: 12px;
`;

export const Tag = styled(TagChip).attrs({
  size: 'x-small',
})`
  padding: 2 6;
`;

export const Title = styled(Body).attrs({
  strong: true,
  level: 2,
})`
  margin-bottom: 4px;
`;

export const DateTagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
