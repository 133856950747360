import type { ObservationStatus } from '@cognite/apm-client';
import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import type { FieldInfo } from '@infield/features/activities/hooks/use-query/types';

export const DEFAULT_STATUS: ObservationStatus = 'Draft';

export const OBSERVATION_FILE_SIZE_LIMIT_BYTES = 50 * 1024 * 1024; // 50MB

export const MEDIA_METADATA_PROP_POINTING_TO_OBSERVATION =
  'observationExternalId';

export const observationFieldInfoList: FieldInfo[] = [
  {
    name: 'createdTime',
    type: 'date',
    list: false,
  },
  {
    name: 'status',
    type: 'text',
    list: false,
  },
  {
    name: 'sourceId',
    type: 'text',
    list: false,
  },
  {
    name: 'asset',
    type: 'direct',
    list: false,
  },
  {
    name: 'priority',
    type: 'text',
    list: false,
  },
  {
    name: 'type',
    type: 'text',
    list: false,
  },
  {
    name: 'description',
    type: 'text',
    list: false,
  },
  {
    name: 'troubleshooting',
    type: 'text',
    list: false,
  },
  {
    name: 'createdBy',
    type: 'text',
    list: false,
  },
  {
    name: 'updatedBy',
    type: 'text',
    list: false,
  },
  {
    name: 'files',
    type: 'file',
    list: true,
  },
];

export const ObservationStatusOptionList: GridFilterOptionsType[] = [
  { label: 'Draft', value: 'Draft' },
  { label: 'Completed', value: 'Completed' },
  { label: 'Sent', value: 'Sent' },
  { label: 'Not sent', value: 'Not sent' },
  { label: 'File not sent', value: 'File not sent' },
];
export const observationFilterableFields = ['status', 'type', 'priority']; // excluding 'asset' (handled separately)
