import type {
  AppConfig,
  AssetPageConfiguration,
  DocumentConfiguration,
  FeatureConfiguration,
  RootLocationFeatureToggles,
} from './types';

export const defaultAssetPageConfiguration: AssetPageConfiguration = {
  propertyCard: {
    highlightedProperties: ['EQUIPMENT/LINE NUMBER', 'SERIAL NUMBER'],
    linkableAssetKeys: [],
  },
};

export const defaultDocumentConfiguration: DocumentConfiguration = {
  title: 'name',
  description: 'metadata.document:title',
  type: 'metadata.Type',
};

export const defaultFeatureConfiguration: FeatureConfiguration = {
  assetPageConfiguration: defaultAssetPageConfiguration,
  documents: defaultDocumentConfiguration,
  psnConfiguration: {},
};

export const defaultAppConfig: AppConfig = {
  name: 'default',
  externalId: 'default-config',
  customerDataSpaceId: 'APM_SourceData',
  customerDataSpaceVersion: '1',
  appDataSpaceId: 'APM_AppData',
  appDataSpaceVersion: '1',
  featureConfiguration: defaultFeatureConfiguration,
  fieldConfiguration: {},
  rootLocationsConfiguration: { locations: [] },
  createdOn: new Date(),
  isActive: false,
  isDefault: false,
};

export const defaultFeatureToggleConfig: RootLocationFeatureToggles = {
  threeD: true,
  trends: true,
  documents: true,
  workorders: true,
  notifications: true,
  media: true,
  templateChecklistFlow: true,
  workorderChecklistFlow: true,
  observations: {
    isEnabled: true,
    isWriteBackEnabled: false,
    notificationsEndpointExternalId: '',
    attachmentsEndpointExternalId: '',
  },
};

export const defaultIDMFeatureToggleConfig: RootLocationFeatureToggles = {
  threeD: true,
  trends: true,
  documents: true,
  workorders: true,
  notifications: true,
  media: true,
  templateChecklistFlow: false,
  workorderChecklistFlow: false,
  observations: {
    isEnabled: false,
    isWriteBackEnabled: false,
    notificationsEndpointExternalId: '',
    attachmentsEndpointExternalId: '',
  },
};
