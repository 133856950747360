import type { IRect } from 'konva/lib/types';
import type { GetViewportParameters } from 'pdfjs-dist/types/src/display/api';

import type { Size } from '../../annotations/types';

import { PDF_TO_CSS_UNITS } from './constants';

/** Represents the region of the PDF image visible in the stage */
export type VisibleRegion = {
  /**
   * size and offset normalized to the content, used to scale and position the
   * image node.
   * @example { x: 0, y: 0, width: 1, height: 1 }, whole page is visible
   * @example { x: 0.5, y: 0.5, width: 0.5, height: 0.5 }, bottom right quarter
   * @example { x: 0, y: 0, width: 0.5, height: 1 }, left half of the page
   */
  normalizedRect: IRect;
  /** size in pixels that should be rendered for this visible region and quality */
  canvasSize: Size;
  /** parameters to match the PDFJS viewport to this visible region and scale */
  pdfViewport: GetViewportParameters;
};

/**
 * Intersects the content rect with the stage viewport to determine the visible
 * region of the PDF image. If the content rect is outside the stage viewport,
 * returns null.
 *
 * @param stage - The stage to retrieve viewport dimensions from
 * @param contentOffset - the offset of the content relative to the stage
 * @param pageSize - The size of the page and scale relative to the stage
 * @param qualityScale - The quality scale, to render the same region at higher
 * or lower resolution
 * @returns a VisibleRegion object or null if the content is outside the viewport
 */
const getVisibleRegion = (
  stage: { width(): number; height(): number; scaleX(): number },
  contentOffset: { x: number; y: number },
  pageSize: Size & { scale: number },
  qualityScale: number
): VisibleRegion | null => {
  const scale = stage.scaleX();
  // Get the rect of the content in screen coordinates and size (i.e. scaled
  // by the stage). We use pageSize rather than just the clientRect because
  // the current child image may be larger than the content itself.
  const contentClientRect = {
    ...contentOffset,
    width: pageSize.width * scale,
    height: pageSize.height * scale,
  };

  const stageRect = {
    x: 0,
    y: 0,
    width: stage.width(),
    height: stage.height(),
  };

  // Calculate the size of the intersection of contentRect and stageRect
  const clientSize = {
    width:
      Math.min(
        contentClientRect.x + contentClientRect.width,
        stageRect.x + stageRect.width
      ) - Math.max(contentClientRect.x, stageRect.x),
    height:
      Math.min(
        contentClientRect.y + contentClientRect.height,
        stageRect.y + stageRect.height
      ) - Math.max(contentClientRect.y, stageRect.y),
  };

  // Rect is outside the viewport
  if (clientSize.width < 1 || clientSize.height < 1) {
    return null;
  }

  // Calculate the offset of the intersection relative to the contentClientRect
  const clientOffset = {
    x: Math.max(contentClientRect.x, stageRect.x) - contentClientRect.x,
    y: Math.max(contentClientRect.y, stageRect.y) - contentClientRect.y,
  };

  const normalizedRect = {
    x: clientOffset.x / contentClientRect.width,
    y: clientOffset.y / contentClientRect.height,
    width: clientSize.width / contentClientRect.width,
    height: clientSize.height / contentClientRect.height,
  };

  const canvasSize = {
    width: clientSize.width * qualityScale,
    height: clientSize.height * qualityScale,
  };

  const pdfViewport: GetViewportParameters = {
    scale: PDF_TO_CSS_UNITS * pageSize.scale * scale * qualityScale,
    offsetX: -normalizedRect.x * pageSize.width * scale * qualityScale,
    offsetY: -normalizedRect.y * pageSize.height * scale * qualityScale,
  };

  return { normalizedRect, canvasSize, pdfViewport };
};

export { getVisibleRegion as default };
