import type { TimeseriesFilter } from '@cognite/sdk';

export const addStringTimeseriesFilter = (
  filter?: TimeseriesFilter
): TimeseriesFilter => {
  return {
    ...filter,
    isString: false,
  };
};
