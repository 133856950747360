import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 8px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme['border--muted']};
  display: flex;
  flex-direction: column;
`;

export const Content = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
  align-items: center;
  padding: 12px 16px;
`;

export const IconWrapper = styled.div`
  padding: 12px;
  display: flex;
  border-radius: 6px;
`;
