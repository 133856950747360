import dayjs from 'dayjs';

import { DatapointAggregate, Datapoints, CogniteEvent } from '@cognite/sdk';

import {
  ChartEventResults,
  EventsCollection,
  EventsEntry,
} from '../../../models';
import { ChartThreshold, VerticalMarker } from '../../../types';
import {
  formatNumberWithSuffix,
  hexToRGBA,
  isThresholdValid,
} from '../../../utils';
import { HORIZONTAL_MARGIN, VERTICAL_MARGIN } from '../constants';

import { calculateDistanceInPxToMs } from './calculateDistanceInPxToMs';
import { calculateStackedYRange } from './calculateStackedYRange';
import {
  createTimeSeriesValueAnnotation,
  createVerticalLineShape,
  createXAxisTimestampAnnotation,
} from './createVerticalMarkerElements';
import { findClosestDatapointInTime } from './findClosestDatapointInTime';
import { getEventsLayout } from './getEventsLayout';
import {
  linearInterpolation,
  stepWiseInterpolation,
} from './timeSeriesInterpolation';
import { PlotlyDragMode, SeriesData, SeriesDataCollection } from './types';

export const isEventSelected = (
  allEventItems: EventsCollection,
  currentEvent: CogniteEvent
) => {
  return allEventItems.find((evt: EventsEntry) => evt.id === currentEvent.id);
};

type LayoutInputParam = {
  isPreview: boolean;
  isGridlinesShown: boolean;
  yAxisLocked: boolean;
  showYAxis: boolean;
  stackedMode: boolean;
  seriesDataCollection: SeriesDataCollection[];
  eventData: ChartEventResults[];
  storedSelectedEvents: EventsCollection;
  yAxisValues: { width: number; margin: number };
  dateFrom: string;
  dateTo: string;
  dragmode: PlotlyDragMode;
  highlightedTimeseriesId: string | undefined;
  manuallyAdjustedAxisText: string;
  verticalMarkers: VerticalMarker[];
  containerWidth: number;
  verticalMarkerDatapointSnapDistancePX?: number;
  horizontalMargin?: number;
  verticalMargin?: number;
};

const getSeriesColor = (
  seriesData: SeriesData,
  highlightedTimeseriesId: string | undefined
): string | undefined => {
  if (seriesData.style.color === undefined) {
    return undefined;
  }

  const isSomeSourceHighlighted = highlightedTimeseriesId !== undefined;
  const isThisSourceHighlighted = seriesData.id === highlightedTimeseriesId;
  if (isSomeSourceHighlighted && !isThisSourceHighlighted) {
    return hexToRGBA(seriesData.style.color, 0.3) ?? undefined;
  }

  return seriesData.style.color;
};

export function generateLayout({
  isPreview,
  isGridlinesShown,
  yAxisLocked,
  showYAxis,
  stackedMode,
  seriesDataCollection,
  eventData,
  storedSelectedEvents,
  yAxisValues,
  dateFrom,
  dateTo,
  dragmode,
  highlightedTimeseriesId,
  manuallyAdjustedAxisText,
  verticalMarkers,
  containerWidth,
  verticalMarkerDatapointSnapDistancePX = 50,
  horizontalMargin,
  verticalMargin,
}: LayoutInputParam): Partial<Plotly.Layout> {
  const horizontalMargins =
    horizontalMargin || (isPreview || !showYAxis ? 10 : HORIZONTAL_MARGIN);

  const verticalMargins = verticalMargin || (isPreview ? 0 : VERTICAL_MARGIN);

  const UNIT_LEGEND_HEIGHT = 20;

  const filteredSeriesDataCollection = seriesDataCollection.filter(
    (c) => c.isVisible
  );

  const layout: Partial<Plotly.Layout> = {
    margin: {
      l: horizontalMargins,
      r: horizontalMargins,
      b: verticalMargins,
      t: verticalMargins + UNIT_LEGEND_HEIGHT, // shift to make space for unit legend on top
    },
    xaxis: {
      type: 'date',
      autorange: false,
      domain: showYAxis
        ? [
            yAxisValues.width * (filteredSeriesDataCollection.length - 1) +
              yAxisValues.margin,
            1,
          ]
        : [0, 1],
      range: [
        dayjs(dateFrom).format('YYYY-MM-DD HH:mm:ss.SSS'),
        dayjs(dateTo).format('YYYY-MM-DD HH:mm:ss.SSS'),
      ],
      showspikes: true,
      spikemode: 'across',
      spikesnap: 'cursor',
      spikethickness: 1,
      spikecolor: '#bfbfbf',
      spikedash: 'solid',
      showgrid: isGridlinesShown,
    },
    hovermode: 'x',
    hoverdistance: 50,
    showlegend: false,
    dragmode,
    annotations: [],
    shapes: [],
  };

  const yAxisDefaults: Partial<Plotly.LayoutAxis> = {
    hoverformat: '.3g',
    zeroline: false,
    type: 'linear', // IMPORTANT! missing causes more renders
    fixedrange: yAxisLocked,
  };
  filteredSeriesDataCollection.forEach(
    ({ unit, range, series, thresholds }, index) => {
      const isAutoRanged = range && range.every((datum) => datum === null);
      const color = getSeriesColor(series[0], highlightedTimeseriesId);
      const datapoints = series.reduce(
        (acc: (Datapoints | DatapointAggregate)[], s: SeriesData) =>
          acc.concat(s.datapoints),
        []
      );

      /**
       * For some reason plotly doesn't like that you overwrite the range input (doing this the wrong way?)
       */
      const serializedYRange = range
        ? JSON.parse(JSON.stringify(range))
        : undefined;

      const rangeY = stackedMode
        ? calculateStackedYRange(
            datapoints as (Datapoints | DatapointAggregate)[],
            index,
            filteredSeriesDataCollection.length
          )
        : serializedYRange;

      // removed tickvals for range diff smaller than 0.001 to show only 2 significant digits
      const yAxisId = `yaxis${index ? index + 1 : ''}`;
      (layout as any)[yAxisId] = {
        ...yAxisDefaults,
        visible: showYAxis,
        linecolor: color,
        linewidth: 1,
        tickcolor: color,
        tickwidth: 1,
        ticklen: 4,
        tickfont: {
          size: 11,
        },
        side: 'left',
        overlaying: index !== 0 ? 'y' : undefined,
        anchor: 'free',
        position: yAxisValues.width * index + 0.019,
        range: rangeY,
        showgrid: isGridlinesShown,
      } satisfies Partial<Plotly.LayoutAxis>;

      /** Display thresholds */
      if (thresholds.length) {
        thresholds.forEach((threshold: ChartThreshold) => {
          let y0 = 0;
          let y1 = 0;

          const thresholdColor = threshold.color
            ? threshold.color
            : series.find((s) => s.id === threshold.sourceId)?.style.color;

          switch (threshold.type) {
            case 'under':
              y0 = threshold.upperLimit || 0;
              y1 = threshold.upperLimit || 0;
              break;

            case 'over':
              y0 = threshold.lowerLimit || 0;
              y1 = threshold.lowerLimit || 0;
              break;

            default:
              y0 = threshold.lowerLimit || 0;
              y1 = threshold.upperLimit || 0;
          }

          (layout.annotations as any[]).push({
            x: showYAxis ? yAxisValues.width * index + 0.025 : -0.015,
            xanchor: showYAxis ? 'right' : 'left',
            y: parseFloat(String(y0)),
            xref: 'paper',
            yref: `y${index ? index + 1 : '0'}`,
            text: formatNumberWithSuffix(parseFloat(String(y0)), 1),
            showarrow: false,
            bgcolor: thresholdColor,
            font: {
              color: '#ffffff',
            },
            borderpad: 4,
            visible: isThresholdValid(threshold) && threshold.visible,
          });

          (layout.shapes as any[]).push({
            visible: isThresholdValid(threshold) && threshold.visible,
            xref: 'paper',
            yref: `y${index ? index + 1 : '0'}`,
            x0: showYAxis ? yAxisValues.width * index + 0.0195 : -0.015,
            x1: 1.015,
            y0: parseFloat(String(y0)),
            y1: parseFloat(String(y1)),
            type: threshold.type === 'between' ? 'rect' : 'line',
            fillcolor: hexToRGBA(thresholdColor, 0.1),
            line: {
              dash: 'dot',
              color: thresholdColor,
              width: 1.5,
            },
          });
        });
      }

      if (showYAxis) {
        /**
         * Display units as annotations and manually placing them on top of y-axis lines
         * Plotly does not support labels on top of axes.
         */
        if (unit) {
          (layout.annotations as any[]).push({
            xref: 'paper',
            yref: 'paper',
            x: yAxisValues.width * index,
            xanchor: 'left',
            y: 1,
            yanchor: 'bottom',
            text: unit,
            showarrow: false,
            xshift: isPreview && horizontalMargin ? -horizontalMargin / 3 : 15,
            yshift: 0,
            textangle: -45, // rotating allows for tighter spacing between y axes
          });
        }

        /**
         * Display y-axes top and bottom markers
         */
        (layout.shapes as any).push(
          ...[
            // Top axis marker
            {
              type: 'line',
              xref: 'paper',
              yref: 'paper',
              x0: yAxisValues.width * index + 0.015,
              y0: 1,
              x1: yAxisValues.width * index + 0.019,
              y1: 1,
              line: {
                color,
                width: 1,
              },
            },
            // Bottom axis marker
            {
              type: 'line',
              xref: 'paper',
              yref: 'paper',
              x0: yAxisValues.width * index + 0.015,
              y0: 0,
              x1: yAxisValues.width * index + 0.019,
              y1: 0,
              line: {
                color,
                width: 1,
              },
            },
          ]
        );

        if (!isAutoRanged && !isPreview) {
          (layout.annotations as any) = [
            ...(layout.annotations ?? []),
            // We are using annotations as a cheap indicator that the axis is manually set
            // Font family etc are are used to get the desired look
            {
              xref: 'paper',
              yref: 'paper',
              x: yAxisValues.width * index + 0.015,
              y: -0.005,
              text: '⏺',
              showarrow: false,
              hovertext: manuallyAdjustedAxisText,
              font: {
                family: 'Helvetica',
                size: 12,
                color,
              },
              yanchor: 'top',
            },
          ];
        }
      }
    }
  );

  verticalMarkers
    .filter((marker) => {
      return (
        dayjs(marker.timestamp).isAfter(dateFrom) &&
        dayjs(marker.timestamp).isBefore(dateTo)
      );
    })
    .forEach((marker) => {
      (layout as any).shapes.push(
        createVerticalLineShape(new Date(marker.timestamp))
      );

      (layout as any).annotations.push(
        createXAxisTimestampAnnotation(new Date(marker.timestamp))
      );

      filteredSeriesDataCollection.forEach((series, index) => {
        series.series.forEach((timeSeries) => {
          const datapoints = timeSeries.datapoints.map((dp) =>
            'value' in dp ? dp : { value: dp.average, timestamp: dp.timestamp }
          );

          if (timeSeries.style.lineStyle === 'none') {
            const closestDatapoint = findClosestDatapointInTime(
              // @ts-ignore
              datapoints,
              new Date(marker.timestamp)
            );

            if (closestDatapoint) {
              const distance = Math.abs(
                closestDatapoint.timestamp.getTime() -
                  new Date(marker.timestamp).getTime()
              );

              if (
                distance <
                calculateDistanceInPxToMs(
                  new Date(dateFrom),
                  new Date(dateTo),
                  verticalMarkerDatapointSnapDistancePX,
                  containerWidth
                )
              ) {
                (layout as any).annotations.push(
                  createTimeSeriesValueAnnotation({
                    value: closestDatapoint.value,
                    timestamp: new Date(closestDatapoint.timestamp),
                    yref: `y${index ? index + 1 : '1'}`,
                    unit: series.unit,
                    color: timeSeries.style.color,
                  })
                );
              }
            }
          }

          if (
            timeSeries.style.lineStyle !== 'none' &&
            timeSeries.interpolation === 'linear'
          ) {
            const yValue = linearInterpolation(
              // @ts-ignore
              datapoints,
              marker.timestamp
            );

            if (yValue !== undefined) {
              (layout as any).annotations.push(
                createTimeSeriesValueAnnotation({
                  value: yValue,
                  timestamp: new Date(marker.timestamp),
                  yref: `y${index ? index + 1 : '1'}`,
                  unit: series.unit,
                  color: timeSeries.style.color,
                })
              );
            }
          }

          if (
            timeSeries.style.lineStyle !== 'none' &&
            timeSeries.interpolation === 'hv'
          ) {
            const yValue = stepWiseInterpolation(
              // @ts-ignore
              datapoints,
              marker.timestamp
            );

            if (yValue !== undefined) {
              (layout as any).annotations.push(
                createTimeSeriesValueAnnotation({
                  value: yValue,
                  timestamp: new Date(marker.timestamp),
                  yref: `y${index ? index + 1 : '1'}`,
                  unit: series.unit,
                  color: timeSeries.style.color,
                })
              );
            }
          }
        });
      });
    });

  const eventLayout = getEventsLayout(
    eventData,
    storedSelectedEvents,
    dateFrom,
    dateTo
  );

  layout.shapes = [...(layout.shapes ?? []), ...eventLayout.shapes];

  return layout;
}
