import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { useRecoilState } from 'recoil';
import { useDebounce } from 'use-debounce';

import { DatapointsMultiQuery } from '@cognite/sdk-alpha';

import { CHART_POINTS_PER_SERIES } from '../constants';
import { chartAtom } from '../models';
import {
  useSetCoreTimeSeries,
  CoreTimeseriesEntry,
} from '../models/core-timeseries-results';
import { fetchRawOrAggregatedDatapoints } from '../services/fetch-raw-or-aggregated-datapoints-alpha';
import { fetchInstance } from '../services/fetchInstance';
import { CoreTimeseries, DecoratedCoreTimeseries } from '../types';
import { calculateGranularity } from '../utils';
import { useAlphaSDK } from '../utils/alphaSDKProvider';

const entryMatchesCoreTimeSeres = (
  entry: CoreTimeseriesEntry,
  coreTimeSeries: CoreTimeseries
) =>
  entry.nodeReference.space === coreTimeSeries.nodeReference.space &&
  entry.nodeReference.externalId === coreTimeSeries.nodeReference.externalId;

export function CoreTimeseriesCollectionEffects({
  aggregationDataPointLimit,
}: {
  aggregationDataPointLimit: number;
}) {
  const [chart] = useRecoilState(chartAtom);

  const timeseriesEffectElements = chart?.coreTimeseriesCollection?.map(
    (coreTimeSeries) => (
      <CoreTimeseriesEffects
        key={`${coreTimeSeries.nodeReference.space}${coreTimeSeries.nodeReference.externalId}`}
        coreTimeseries={coreTimeSeries}
        aggregationDataPointLimit={aggregationDataPointLimit}
      />
    )
  );

  return <>{timeseriesEffectElements}</>;
}

function CoreTimeseriesEffects({
  coreTimeseries,
  aggregationDataPointLimit,
}: {
  coreTimeseries: CoreTimeseries;
  aggregationDataPointLimit: number;
}) {
  const [chart] = useRecoilState(chartAtom);
  const { dateFrom, dateTo } = chart!;

  const setCoreTimeseries = useSetCoreTimeSeries();
  const sdk = useAlphaSDK();

  const [debouncedRange] = useDebounce({ dateFrom, dateTo }, 50, {
    equalityFn: (l, r) => isEqual(l, r),
  });

  const { nodeReference, viewReference } = coreTimeseries;

  const {
    data: timeSeriesInstance,
    isFetching,
    isSuccess,
  } = useQuery(
    ['chart-data', 'coreTimeseries', nodeReference.externalId, nodeReference],
    () =>
      fetchInstance(
        sdk,
        { instanceType: 'node', ...nodeReference },
        { type: 'view', ...viewReference }
      ),
    {
      enabled: true,
    }
  );

  const query: DatapointsMultiQuery = {
    items: [
      {
        //@ts-expect-error SDK hasn't been updated yet..
        instanceId: {
          space: nodeReference.space,
          externalId: nodeReference.externalId,
        },
      },
    ],
    start: dayjs(debouncedRange.dateFrom!).toDate(),
    end: dayjs(debouncedRange.dateTo!).toDate(),
    granularity: calculateGranularity(
      [
        dayjs(debouncedRange.dateFrom!).valueOf(),
        dayjs(debouncedRange.dateTo!).valueOf(),
      ],
      CHART_POINTS_PER_SERIES
    ),
    aggregates: ['average', 'min', 'max', 'count', 'sum'],
    limit: CHART_POINTS_PER_SERIES,
  };

  const {
    data: datapoints,
    isFetching: isFetchingDatapoints,
    isSuccess: isSuccessDatapoints,
  } = useQuery(
    [
      'chart-data',
      'coreTimeseries-datapoints',
      nodeReference.externalId,
      query,
    ],
    () => fetchRawOrAggregatedDatapoints(sdk, query, aggregationDataPointLimit),
    {
      enabled: !!nodeReference.externalId,
    }
  );

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    setCoreTimeseries((collection) => {
      const properties: any =
        timeSeriesInstance?.properties![viewReference.space][
          `${viewReference.externalId}/${viewReference.version}`
        ];

      const decoratedTimeSeries: DecoratedCoreTimeseries = {
        ...coreTimeseries,
        name: coreTimeseries.name || properties.name || '',
        description: properties.description,
        assets: properties.assets,
        interpolation: properties.isStep ? 'hv' : 'linear',
        unitReference: properties.unit,
        isStep: properties.isStep,
        sourceUnit: properties.sourceUnit,
        instanceCreatedTime: timeSeriesInstance.createdTime,
        instanceLastUpdatedTime: timeSeriesInstance.lastUpdatedTime,
      };

      const existingEntry = collection.find((entry) =>
        entryMatchesCoreTimeSeres(entry, coreTimeseries)
      );

      const output = collection
        .filter((entry) => !entryMatchesCoreTimeSeres(entry, coreTimeseries))
        .concat({
          ...decoratedTimeSeries,
          loading: isFetchingDatapoints,
          series: isSuccessDatapoints ? datapoints : existingEntry?.series,
        });

      return output;
    });
  }, [
    isFetching,
    isSuccess,
    isSuccessDatapoints,
    isFetchingDatapoints,
    setCoreTimeseries,
    coreTimeseries,
    datapoints,
    timeSeriesInstance,
    viewReference,
  ]);
  return null;
}
