import type { InFieldUser } from '@cognite/apm-client';
import { useAuthContext } from '@cognite/e2e-auth';
import { Metrics } from '@cognite/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { useRegisterCurrentUser } from '../use-mutation';

export const useCurrentUserQuery = () => {
  const { apmClient } = useFDMServices();
  const { mutateAsync: registerUser } = useRegisterCurrentUser();
  const { authState } = useAuthContext();

  return useQuery<InFieldUser | null, Error>(
    [QueryKeys.USER],
    async () => {
      return apmClient.users.getCurrentUser(authState.user.cdfId);
    },
    {
      onSuccess: (data) => {
        if (data === null) {
          registerUser({});
        } else {
          Metrics.props({
            selectedLocationId: data.preferences?.infield?.location?.externalId,
            selectedAssetExternalId:
              data.preferences?.infield?.location?.assetExternalId,
            selectedLocationName: data.preferences?.infield?.location?.name,
          });
        }
      },
      onError: (err) => {
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: QueryKeys.USER,
          },
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
