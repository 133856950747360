import type { FilterProperty, Filters, NestedFilter } from '../types';

export const isFilterProperty = (
  filter?: Filters[] | Filters | FilterProperty | NestedFilter | string
): filter is FilterProperty => {
  return Boolean((filter as FilterProperty)?.property);
};
export const isFilters = (
  filter?: Filters[] | Filters | FilterProperty | NestedFilter | string
): filter is Filters[] => {
  return Array.isArray(filter);
};
export const isFilter = (
  filter?: Filters[] | Filters | FilterProperty | NestedFilter | string
): filter is Filters => {
  return !Array.isArray(filter) && !(filter as FilterProperty).property;
};

/**
 * Converts a FDM filter object to a GraphQL filter object
 * @param filter FDM filter object
 * @returns Corresponding GraphQL filter object
 */
export const parseFilters = (filter?: Filters) => {
  const parsed: any = {};
  if (!filter || Object.keys(filter).length === 0) return null;

  Object.keys(filter).forEach((operand) => {
    // This property is only used in DMS requests and irrelevant for graphql filters
    if (operand !== 'directRelationInstanceSpace') {
      const value = filter[operand as keyof typeof filter];

      if (isFilterProperty(value)) {
        const { property, ...rest } = value;
        parsed[property] = { ...rest };
      }

      if (operand === 'directRelationFilter') {
        const directFilters = value as Filters['directRelationFilter'];
        if (directFilters) {
          Object.keys(directFilters).forEach((directKey) => {
            if (directFilters[directKey]) {
              parsed[directKey] = parseFilters(directFilters[directKey]);
            }
          });
        }
      } else if (isFilter(value) && parseFilters(value)) {
        parsed[operand] = parseFilters(value);
      } else if (isFilters(value)) {
        parsed[operand] = value.map(parseFilters).filter(Boolean);
      }
    }
  });

  return parsed;
};
