import type { InFieldLocationConfig } from '@cognite/apm-client';
import { PageHeader } from '@cognite/cogs-lab';
import { Button, Divider, SaveIcon } from '@cognite/cogs.js-v10';
import { ToggleRow } from '@infield/features/app-config';
import type { FC } from 'react';
import { useState } from 'react';

import {
  ConfigPageContentWrapper,
  ContentContainer,
  LocationInfieldContentWrapper,
} from '../elements';
import { getInitialInfieldLocation } from '../utils/get-initial-infield-location';

import { getTogglesUpdatedState } from './utils/get-toggles-updated-state';

type Props = {
  adsLocationId?: string;
  infieldLocation?: InFieldLocationConfig | null;
  isSaving: boolean;
  onSave: (nextConfig: InFieldLocationConfig) => void;
};

export const LocationInfieldAssetExplorer: FC<Props> = ({
  adsLocationId,
  infieldLocation,
  isSaving,
  onSave,
}) => {
  const [newFeatureToggles, setNewFeatureToggles] = useState(
    infieldLocation?.featureToggles
  );

  const {
    isThreeDUpdated,
    isTrendsUpdated,
    isDocumentsUpdated,
    isWorkordersUpdated,
    isNotificationsUpdated,
    isMediaUpdated,
    isUpdated,
  } = getTogglesUpdatedState(
    newFeatureToggles,
    infieldLocation?.featureToggles
  );

  const handleToggleUpdate = (checked: boolean, toggle: string) => {
    setNewFeatureToggles((prev: any) => {
      return {
        ...prev,
        [toggle]: checked,
      };
    });
  };

  const handleSave = () => {
    if (!adsLocationId) return;
    onSave({
      ...(infieldLocation || getInitialInfieldLocation(adsLocationId)),
      featureToggles: newFeatureToggles,
    });
  };

  return (
    <LocationInfieldContentWrapper>
      <PageHeader fullWidth>
        <PageHeader.TitleArea
          title="Asset explorer"
          description="What kind of data do you want to see in your asset explorer?"
        />
        <PageHeader.Actions>
          <Button
            type="primary"
            data-testid="config-save-button"
            icon={<SaveIcon />}
            onClick={handleSave}
            loading={isSaving}
            disabled={!isUpdated}
          >
            Save
          </Button>
        </PageHeader.Actions>
      </PageHeader>
      <ConfigPageContentWrapper>
        <ContentContainer>
          <ToggleRow
            title="3D"
            dataTestId="3d-toggle"
            isLoading={false}
            isUpdated={isThreeDUpdated}
            checked={newFeatureToggles?.threeD}
            hasAppConfigPermission
            onChange={(e) => handleToggleUpdate(e.target.checked, 'threeD')}
          />
          <Divider />
          <ToggleRow
            title="Trends"
            dataTestId="trends-toggle"
            isLoading={false}
            isUpdated={isTrendsUpdated}
            checked={newFeatureToggles?.trends}
            hasAppConfigPermission
            onChange={(e) => handleToggleUpdate(e.target.checked, 'trends')}
          />
          <Divider />
          <ToggleRow
            title="Documents"
            dataTestId="documents-toggle"
            isLoading={false}
            isUpdated={isDocumentsUpdated}
            checked={newFeatureToggles?.documents}
            hasAppConfigPermission
            onChange={(e) => handleToggleUpdate(e.target.checked, 'documents')}
          />
          <Divider />
          <ToggleRow
            title="Work orders"
            dataTestId="work-orders-toggle"
            isLoading={false}
            isUpdated={isWorkordersUpdated}
            checked={newFeatureToggles?.workorders}
            hasAppConfigPermission
            onChange={(e) => handleToggleUpdate(e.target.checked, 'workorders')}
          />
          <Divider />
          <ToggleRow
            title="Notifications"
            dataTestId="notifications-toggle"
            isLoading={false}
            isUpdated={isNotificationsUpdated}
            checked={newFeatureToggles?.notifications}
            hasAppConfigPermission
            onChange={(e) =>
              handleToggleUpdate(e.target.checked, 'notifications')
            }
          />
          <Divider />
          <ToggleRow
            title="Media"
            dataTestId="media-toggle"
            isLoading={false}
            isUpdated={isMediaUpdated}
            checked={newFeatureToggles?.media}
            hasAppConfigPermission
            onChange={(e) => handleToggleUpdate(e.target.checked, 'media')}
          />
        </ContentContainer>
      </ConfigPageContentWrapper>
    </LocationInfieldContentWrapper>
  );
};
