import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { FC, PropsWithChildren } from 'react';

export enum DeviceTypes {
  mobile = 'MOBILE',
  desktop = 'DESKTOP',
}
export type DeviceType = 'MOBILE' | 'DESKTOP';

export const DEVICE_TYPE_VARIABLE = 'INFIELD_USER_DEVICE';

type DeviceContextType = {
  device: DeviceType | null;
  setDevice: (device: DeviceType) => void;
} | null;

const DeviceContext = createContext<DeviceContextType>(null);

export const DeviceProvider: FC<PropsWithChildren> = ({ children }) => {
  const [presetDevice, setPresetDevice] = useState<DeviceType | null>(null);

  const setDevice = useCallback((device: DeviceType) => {
    localStorage.setItem(DEVICE_TYPE_VARIABLE, device);
    setPresetDevice(device);
  }, []);

  const getDeviceType = () => {
    let deviceType = DeviceTypes.desktop;
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        navigator.userAgent
      )
    ) {
      deviceType = DeviceTypes.mobile;
    }
    return deviceType;
  };

  useEffect(() => {
    const storedDevice = localStorage.getItem(
      DEVICE_TYPE_VARIABLE
    ) as DeviceType;
    if (storedDevice) {
      setPresetDevice(storedDevice);
    } else {
      const deviceType = getDeviceType();
      setDevice(deviceType);
    }
  }, [setDevice]);

  const context = useMemo(
    () => ({ device: presetDevice, setDevice }),
    [presetDevice, setDevice]
  );

  return (
    <DeviceContext.Provider value={context}>{children}</DeviceContext.Provider>
  );
};

export const useDeviceContext = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error('deviceContext must be used within a DeviceProvider');
  }
  return context;
};
