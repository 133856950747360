export const DEFAULT_MAX_WORDS_IN_ANSWER = 100;
export const DEFAULT_MAX_WORDS_IN_SUMMARY = 80;

export type TRACKING_TOKENS =
  | 'CogAi.QuestionAsked'
  | 'CogAi.QuestionAnswered'
  | 'CogAi.OnPageSourceClicked';

export const AI_SERVICES = {
  CHAT_COMPLETIONS: 'Chat completions',
  GRAPHQL_COMPLETIONS: 'GraphQL completions',
  PYTHON_CODEGEN: 'Python code generation',
  PYTHON_CODE_EDIT: 'Python code edit',
  DOCUMENT_QA: 'Document questioning',
  DOCUMENT_SUMMARIZATION: 'Document summarization',
};
