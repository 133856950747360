import { Button } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { MobileVersionPageType } from '@infield/pages/page-layout';
import type { FC } from 'react';
import { useRecoilValue } from 'recoil';

import { AlertBox } from './alert-box';
import { BackOnlineBanner } from './banners/back-online-banner';
import { DataLostBanner } from './banners/data-lost-banner';
import { OfflineBannerChecklist } from './banners/offline-banner-checklist';
// import { SlowNetworkBanner } from './banners/slow-network-banner';
import * as S from './elements';
import { isDataLostWhileOfflineAtom } from './state';

type Props = {
  isOnline: boolean;
  // isNetworkSlow: boolean;
  isBackOnline: boolean;
  isDesktop: boolean;
  isUnableToUpload?: boolean;
  isChecklistDataLoaded?: boolean;
  page?: MobileVersionPageType;
  onClickTryAgain?: () => void;
};

export const NetworkStatusBanner: FC<Props> = ({
  isOnline,
  // isNetworkSlow,
  isBackOnline,
  isDesktop,
  isUnableToUpload,
  onClickTryAgain,
  page,
  isChecklistDataLoaded,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.infobar);
  const isDataLost = useRecoilValue(isDataLostWhileOfflineAtom);

  const infobarContent = () => {
    if (!isOnline && isDesktop) {
      return (
        <AlertBox
          status="warning"
          title={t('NETWORK_STATUS_BANNER_OFFLINE_TITLE', "You're offline")}
          description={t(
            'NETWORK_STATUS_BANNER_OFFLINE_DESKTOP_DESCRIPTION',
            'Please check your network connection and try again'
          )}
          collapsible
          okButtonText={t('NETWORK_STATUS_BANNER_GOT_IT_BUTTON_TEXT', 'Got it')}
        />
      );
    }

    if (!isOnline && page === 'mobile-checklist' && isChecklistDataLoaded) {
      return <OfflineBannerChecklist />;
    }

    if (!isOnline && page === 'mobile-observation') {
      return (
        <AlertBox
          status="warning"
          title={t('NETWORK_STATUS_BANNER_OFFLINE_TITLE', "You're offline")}
          description={t(
            'NETWORK_STATUS_BANNER_OBSERVATION_OFFLINE_DESCRIPTION',
            'Observations are unavailable. Continue to work on the checklist offline.'
          )}
          collapsible
          okButtonText={t('NETWORK_STATUS_BANNER_GOT_IT_BUTTON_TEXT', 'Got it')}
        />
      );
    }

    if (!isOnline && page === 'mobile-explore') {
      return (
        <AlertBox
          status="warning"
          title={t('NETWORK_STATUS_BANNER_OFFLINE_TITLE', "You're offline")}
          description={t(
            'NETWORK_STATUS_BANNER_OFFLINE_DESKTOP_DESCRIPTION',
            'Please check your network connection and try again'
          )}
          collapsible
          okButtonText={t('NETWORK_STATUS_BANNER_GOT_IT_BUTTON_TEXT', 'Got it')}
        />
      );
    }

    if (!isOnline) {
      return (
        <AlertBox
          status="warning"
          title={t('NETWORK_STATUS_BANNER_OFFLINE_TITLE', "You're offline")}
          description={t(
            'NETWORK_STATUS_BANNER_OFFLINE_DESCRIPTION',
            'Go online, load the checklist, and then work offline'
          )}
          collapsible
          okButtonText={t('NETWORK_STATUS_BANNER_GOT_IT_BUTTON_TEXT', 'Got it')}
        />
      );
    }

    if (isBackOnline && !isDesktop) {
      return <BackOnlineBanner />;
    }

    if (isDataLost) {
      return <DataLostBanner />;
    }

    if (isUnableToUpload) {
      return (
        <AlertBox
          status="critical"
          title={t(
            'NETWORK_STATUS_BANNER_UNABLE_TO_UPLOAD_TITLE',
            'Unable to upload'
          )}
          description={t(
            'NETWORK_STATUS_BANNER_UNABLE_TO_UPLOAD_DESCRIPTION',
            'Wait a bit and try again'
          )}
          additionalContent={
            <S.TryAgainButtonWrapper>
              <Button
                type="tertiary"
                onClick={() => onClickTryAgain && onClickTryAgain()}
              >
                {t(
                  'NETWORK_STATUS_BANNER_UNABLE_TO_UPLOAD_TRY_AGAIN_BUTTON',
                  'Try again'
                )}
              </Button>
            </S.TryAgainButtonWrapper>
          }
          collapsible={false}
        />
      );
    }

    // disable slow network banner for now
    // if (isNetworkSlow) {
    //   return <SlowNetworkBanner />;
    // }

    return null;
  };

  return (
    <S.Container
      $isChecklistLoaded={Boolean(
        page === 'mobile-checklist' && isChecklistDataLoaded
      )}
    >
      {infobarContent()}
    </S.Container>
  );
};
