import { Divider, Flex, Input, Switch, Textarea } from '@cognite/cogs.js-v10';
import type { Dispatch, FC, SetStateAction } from 'react';

import type {
  ObservationConfigFieldProperty,
  ObservationsConfig,
} from '../../../types';

import * as S from './elements';

interface Props {
  prop: keyof ObservationsConfig;
  observationField: ObservationConfigFieldProperty;
  hasAppConfigPermission: boolean;
  setObservationsConfig: Dispatch<SetStateAction<ObservationsConfig>>;
  showRequiredToggle?: boolean;
  withAccordion?: boolean;
}

export const ObservationConfigField: FC<Props> = ({
  prop,
  observationField,
  hasAppConfigPermission,
  setObservationsConfig,
  showRequiredToggle,
  withAccordion = true,
}) => {
  const handleFieldUpdate = (
    value: string | boolean,
    field: keyof ObservationConfigFieldProperty
  ) => {
    setObservationsConfig((prevState) => ({
      ...prevState,
      [prop]: {
        ...prevState[prop],
        [field]: value,
      },
    }));
  };

  const fieldConfig = (
    <Flex direction="column" gap={16}>
      <Input
        label={{
          text: 'Title display on the UI',
          required: true,
        }}
        value={observationField.displayTitle}
        onChange={(e) => handleFieldUpdate(e.target.value, 'displayTitle')}
        disabled={!hasAppConfigPermission}
        status={observationField.displayTitle ? undefined : 'critical'}
        statusText={
          observationField.displayTitle ? undefined : 'The title must be filled'
        }
      />
      <Textarea
        label="Description display on the UI"
        value={observationField.displayDescription}
        onChange={(e) =>
          handleFieldUpdate(e.target.value, 'displayDescription')
        }
        disabled={!hasAppConfigPermission}
      />
      {showRequiredToggle && (
        <Switch
          checked={observationField.isRequired}
          disabled={!hasAppConfigPermission}
          label="Required field"
          onChange={(e) => {
            handleFieldUpdate(e.target.checked, 'isRequired');
          }}
        />
      )}
    </Flex>
  );

  return withAccordion ? (
    <>
      <S.AlignedAccordion
        title={prop.charAt(0).toUpperCase() + prop.slice(1)}
        type="ghost"
        indicatorPlacement="left"
      >
        {fieldConfig}
      </S.AlignedAccordion>
      <Divider />
    </>
  ) : (
    fieldConfig
  );
};
