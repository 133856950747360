import type { DateRangeType } from '@cognite/apm-observation';
import type { DateRangePickerProps } from '@cognite/cogs.js-v10';
import { DateRangePicker as CogsDateRange } from '@cognite/cogs.js-v10';
import { useTranslation } from '@infield/features/i18n';
import dayjs from 'dayjs';

import { getCorrectDateFormat } from './utils';

type Props = Omit<
  DateRangePickerProps,
  'onChange' | 'localizationProviderProps'
> & {
  onChange: (newRange: DateRangeType) => void;
};

export const DateRangePicker: React.FC<Props> = ({ onChange, ...rest }) => {
  const { i18n } = useTranslation();
  const dateFormat = getCorrectDateFormat();

  return (
    <CogsDateRange
      localizationProviderProps={{
        adapterLocale: i18n.language,
      }}
      format={dateFormat}
      timezone={dayjs.tz.guess()}
      onChange={(newValue) => {
        if (newValue[0] && newValue[1]) {
          const endOfDay = newValue[1].endOf('day');
          onChange([newValue[0], endOfDay]);
        }
      }}
      {...rest}
    />
  );
};
