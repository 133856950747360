import type { Observation } from '@cognite/apm-client';
import { getHeaderStatus, Header } from '@cognite/apm-observation';
import { makeToast } from '@cognite/cogs-lab';
import { Body, Button, LoaderIcon } from '@cognite/cogs.js-v10';
import { useMetrics } from '@cognite/metrics';
import {
  useFeatureToggleConfig,
  useIsTemplateAdminQuery,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import {
  DEFAULT_STATUS,
  getDefaultObservation,
  getIsAllRequiredFieldsFilled,
  getShowCompleteButton,
  getShowSendToSapButton,
  ObservationDesktop,
  useObservationCreateMutation,
  useObservationsDetailsQuery,
  useObservationUpsertMutation,
} from '@infield/features/observation';
import { useSendObservationsToSap } from '@infield/features/observation/hooks';
import { useObservationFields } from '@infield/features/observation/hooks/use-observation-fields';
import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as S from './elements';

export const ObservationPageDesktop: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);
  const metrics = useMetrics(METRICS_NAMESPACES.observationsDesktop);
  const { mutateAsync: upsertObservation, isLoading: isObservationUpserting } =
    useObservationUpsertMutation();
  const { mutateAsync: createObservation, isLoading: isObservationCreating } =
    useObservationCreateMutation();
  const { observationExternalId } = useParams<{
    observationExternalId: string;
  }>();
  const defaultObservation = getDefaultObservation();
  const { observationFields } = useObservationFields();
  const { isSending, sendToSap } = useSendObservationsToSap();
  const navigate = useNavigate();

  const { data: observations, isInitialLoading } = useObservationsDetailsQuery(
    observationExternalId ? [observationExternalId] : []
  );
  const observation = observations ? observations[0] : undefined;

  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );
  const isSapWriteBackEnabled = Boolean(
    rootLocationFeatureToggles?.observations?.isWriteBackEnabled
  );

  const { data: isTemplateAdmin } = useIsTemplateAdminQuery();

  // We don't need to pass edgeStartNode here since we don't bind observation to checklist item
  const handleOnUpdate = (observation: Partial<Observation>) => {
    if (!observationExternalId) {
      createObservation(
        {
          observation: {
            ...defaultObservation,
            ...observation,
          },
        },
        {
          onSuccess: () => {
            metrics.track('observationCreated from Desktop form');
            navigate(
              `/observation/${encodeURIComponent(
                defaultObservation.externalId
              )}`,
              { replace: true }
            );
          },
        }
      );
    } else {
      upsertObservation({
        observation: {
          externalId: observationExternalId,
          ...observation,
        },
      });
    }
  };

  const pageHeaderTitle =
    observation?.status === DEFAULT_STATUS ||
    observation?.status === undefined ||
    observation?.status === null
      ? t(
          'OBSERVATION_DESKTOP_FORM_PAGE_HEADER_TITLE_CREATE_NEW',
          'Create observation'
        )
      : t(
          'OBSERVATION_DESKTOP_FORM_PAGE_HEADER_TITLE_VIEW_EXISTING',
          'View observation'
        );

  const handleOnComplete = () => {
    if (!observationExternalId) return;
    upsertObservation(
      {
        observation: {
          externalId: observationExternalId,
          status: 'Completed',
        },
      },
      {
        onSuccess: () => {
          makeToast({
            type: 'success',
            body: t(
              'OBSERVATION_STEP_COMPLETED_TITLE',
              'Observation completed'
            ),
          });
          metrics.track(`observationWasCompletedFrom Desktop form}`);
        },
      }
    );
  };

  const handleObservationSentSuccess = (sourceId?: string) => {
    makeToast({
      type: 'success',
      body: t(
        'OBSERVATION_WRITE_BACK_FROM_CHECKLIST_SUCCESS_TOAST',
        'Observation sent. Id: {{sourceId}} ',
        { sourceId }
      ),
    });
    metrics.track('observationWasSentToSAP from Desktop form');
  };

  const handleSendToSAP = () => {
    if (observation) {
      sendToSap([observation], handleObservationSentSuccess);
    }
  };

  const showCompleteButton = getShowCompleteButton(
    isSapWriteBackEnabled,
    observation?.status
  );

  const showSendToSapButton = getShowSendToSapButton(
    isSapWriteBackEnabled,
    observation?.status
  );

  const isAllRequiredFieldsFilled = getIsAllRequiredFieldsFilled(
    observation || defaultObservation,
    observationFields
  );

  const disableCompleteButton =
    !isAllRequiredFieldsFilled || observation?.status === 'Completed';

  const disableSendToSapButton = !isAllRequiredFieldsFilled || !isTemplateAdmin;

  if (isInitialLoading) {
    return (
      <S.Container gap={6}>
        <LoaderIcon className="cogs-icon--rotating" />
        <Body>
          {t(
            'OBSERVATION_DESKTOP_FORM_INITIAL_LOADING_MESSAGE',
            'Loading observation'
          )}
        </Body>
      </S.Container>
    );
  }

  return (
    <>
      <Header
        title={pageHeaderTitle}
        chipProps={getHeaderStatus({
          t,
          observation,
          isSaving: isObservationUpserting || isObservationCreating,
        })}
        onClickBack={() => navigate('/observations')}
      >
        {showCompleteButton && (
          <Button
            type="primary"
            onClick={handleOnComplete}
            disabled={disableCompleteButton}
          >
            {t(
              'OBSERVATION_DESKTOP_FORM_PAGE_HEADER_COMPLETE_BUTTON',
              'Complete'
            )}
          </Button>
        )}
        {showSendToSapButton && (
          <Button
            type="primary"
            onClick={handleSendToSAP}
            disabled={disableSendToSapButton}
            loading={isSending}
          >
            {t('OBSERVATION_STEP_COMPLETED_BUTTON_TITLE_SEND', 'Send to SAP')}
          </Button>
        )}
      </Header>
      <ObservationDesktop
        observation={observation}
        observationFields={observationFields}
        onUpdate={handleOnUpdate}
      />
    </>
  );
};
