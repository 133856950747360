import { useCallback, useMemo, useState } from 'react';

import styled from 'styled-components';
const Seekbar: React.FC<{
  currentTime: number;
  duration: number;
  onSeek: (timeSeconds: number) => void;
  videoRef: HTMLVideoElement;
}> = ({ currentTime, duration, onSeek, videoRef }) => {
  const [wasPlaying, setWasPlaying] = useState(false);

  const eventHandlers = useMemo(
    () => ({
      onMouseDown: (e: React.MouseEvent) => {
        e.stopPropagation();
      },
      onMouseMove: (e: React.MouseEvent) => {
        e.stopPropagation();
      },
      onMouseUp: (e: React.MouseEvent) => {
        e.stopPropagation();
      },
    }),
    []
  );

  const handleInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setWasPlaying(!videoRef.paused);

      if (!videoRef.paused) {
        videoRef.pause();
      }

      const time = Number(event.target.value);
      onSeek(time);
    },
    [onSeek]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const time = Number(event.target.value);
      onSeek(time);
    },
    [onSeek, wasPlaying]
  );

  return (
    <StyledRange
      {...eventHandlers}
      type="range"
      min={0}
      max={Math.round(duration)}
      value={currentTime}
      step={0.25}
      onInput={handleInput}
      onChange={handleChange}
    />
  );
};

const StyledRange = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
  }
`;

export default Seekbar;
