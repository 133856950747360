import type { ViewConfigDTO } from '@cognite/apm-config';

import { defaultViewMappings } from './constants';

export const setDefaultViewMappings = (
  customViews?: ViewConfigDTO[]
): ViewConfigDTO[] => {
  const mergedViews = [...defaultViewMappings];
  if (customViews) {
    customViews.forEach((customView) => {
      const index = mergedViews.findIndex(
        (view) => view.representsEntity === customView.representsEntity
      );
      if (index !== -1) {
        mergedViews[index] = customView;
      } else {
        mergedViews.push(customView);
      }
    });
  }
  return mergedViews;
};
