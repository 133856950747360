import { Flex } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { ProgressBar } from '@infield/features/ui/progress-bar';
import type { FC } from 'react';

import { Description } from './elements';

type Props = {
  total?: number;
  completed?: number;
};

const getPercent = (total?: number, completed?: number) =>
  completed && total ? Math.floor((completed * 1000) / total) / 10 : 0;

export const ChecklistProgressInfo: FC<Props> = ({ total, completed }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);
  const isProgressDescriptionVisible =
    completed !== undefined && total !== undefined;
  return (
    <Flex direction="column">
      <ProgressBar progress={getPercent(total, completed)} />
      {isProgressDescriptionVisible && (
        <Flex justifyContent="space-between">
          <Description>
            {t(
              'CHECKLIST_NUMBER_OF_TASKS_COMPLETED',
              '{{completed}} of {{total}} tasks completed.',
              {
                completed,
                total,
              }
            )}
          </Description>
          <Description>{`${getPercent(total, completed)}% done.`}</Description>
        </Flex>
      )}
    </Flex>
  );
};
