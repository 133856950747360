import { AxisRange, PlotRange, PresetPlotRange } from '../types';

import { getAxisRangeFromValues } from './getAxisRangeFromValues';

export const convertToPlotRange = (
  range?: PlotRange | PresetPlotRange
): PlotRange | undefined => {
  const xRange = getAxisRangeFromValues(range?.x?.[0], range?.x?.[1]);
  const yRange = getAxisRangeFromValues(range?.y?.[0], range?.y?.[1]);

  return {
    x: xRange as AxisRange,
    y: yRange as AxisRange,
  };
};
