import type { Activity, Checklist, Operation } from '@cognite/apm-client';
import type { Sort } from '@cognite/fdm-client';
import { getChecklistItemsAggregatedStatus } from '@infield/features/checklist';

import { translateChecklistStatus } from '../utils';

import type { DataGridRow } from './types';

export const convertActivityToDataGridRow = (
  activity: Activity,
  checklists: Checklist[] = [],
  assetName?: string
): DataGridRow => {
  return {
    hierarchy: [activity.externalId],
    ...activity,
    id: activity.externalId,
    workorderId: activity.id,
    title: activity.title ?? '-',
    type: activity.type ?? '-',
    startTime: activity.startTime ?? '-',
    endTime: activity.endTime ?? '-',
    status: activity.status ?? '-',
    resource: activity.resource,
    activity,
    checklists,
    assetExternalId: assetName ?? '-',
    checklistItemsStatusAggregation:
      checklists.length > 0
        ? getChecklistItemsAggregatedStatus(checklists)
        : undefined,
    assigneesCount: activity.assignedTo?.length,
    workOrderNumber: activity.externalId,
    rowType: 'Activity',
  };
};

export const convertOperationToDataGridRow = (
  t: any,
  operation: Operation,
  checklist?: Checklist
): DataGridRow => {
  return {
    hierarchy: operation.parentActivityId
      ? [operation.parentActivityId, operation.externalId]
      : [operation.externalId],
    id: operation.externalId,
    operationNumber: operation.order ?? '-',
    title: operation.title ?? '-',
    type: operation.type ?? '-',
    startTime: operation.startTime ?? '-',
    endTime: operation.endTime ?? '-',
    resource: operation.mainResource ?? '-',
    operationOrder: operation.order ?? '-',
    checklists: checklist ? [checklist] : undefined,
    checklistStatus: translateChecklistStatus(t, checklist?.status),
    checklistItemsStatusAggregation: checklist
      ? getChecklistItemsAggregatedStatus([checklist])
      : undefined,
    isActivity: true,
    rowType: 'SubActivity',
  };
};

export const DEFAULT_MAINTENANCE_TABLE_SORTING: Sort = [
  {
    endTime: 'ASC',
  },
];
