import causeMapNodeCompareFn from './causeMapNodeCompareFn';

const getCauseMapEdgeCompareFn =
  <
    Node extends {
      id: string;
      width: number;
      height: number;
      x: number;
      y: number;
    },
    Edge extends { fromId: string; toId: string }
  >(
    nodeMap: Map<string, Node>
  ) =>
  (a: Edge, b: Edge): number => {
    const fromNodeA = nodeMap.get(a.fromId);
    const fromNodeB = nodeMap.get(b.fromId);
    if (fromNodeA === undefined || fromNodeB === undefined) {
      return Infinity;
    }

    const toNodeA = nodeMap.get(a.toId);
    const toNodeB = nodeMap.get(b.toId);
    if (toNodeA === undefined || toNodeB === undefined) {
      return Infinity;
    }

    if (fromNodeA.id === fromNodeB.id) {
      return causeMapNodeCompareFn(toNodeA, toNodeB);
    }

    return causeMapNodeCompareFn(fromNodeA, fromNodeB);
  };

export default getCauseMapEdgeCompareFn;
