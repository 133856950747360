import { makeToast } from '@cognite/cogs-lab';
import { DownloadIcon } from '@cognite/cogs.js-v10';
import { useAuthContext } from '@cognite/e2e-auth';
import type {
  FileContainerProps,
  UnifiedViewer,
} from '@cognite/unified-file-viewer';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useIsDesktop } from '@infield/hooks';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { StyledModal } from './elements';
import { FileViewer, useDocumentByExternalId } from './file-viewer';
import type { UfvAnnotation } from './types';

export const FileViewerModal = ({
  isAkerbpCustomCode,
}: {
  isAkerbpCustomCode: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const fileExternalId = decodeURIComponent(
    searchParams.get('viewDocId') ?? ''
  );
  const fileSpace = decodeURIComponent(searchParams.get('viewDocSpace') ?? '');
  const page = parseInt(searchParams.get('page') ?? '1', 10);
  const navigate = useNavigate();
  const { isIdm } = useAppConfigContext();
  const isDesktop = useIsDesktop();
  const [container, setContainer] = useState<FileContainerProps>();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [fileViewerRef, setFileViewerRef] = useState<UnifiedViewer | null>(
    null
  );

  const fileInstanceId =
    isIdm && fileExternalId && fileSpace
      ? { externalId: fileExternalId, space: fileSpace }
      : undefined;
  const { data: file, error } = useDocumentByExternalId({
    externalId: fileExternalId || undefined,
    instanceId: fileInstanceId,
  });

  const { t } = useTranslation(LOCIZE_NAMESPACES.fileViewer);

  const { client } = useAuthContext();

  const navigateToFilePage = (
    fileExternalId: string,
    space?: string,
    pageNumber?: number
  ) => {
    searchParams.set('viewDocId', encodeURIComponent(fileExternalId));
    if (space) {
      searchParams.set('viewDocSpace', space);
    }
    if (pageNumber) {
      searchParams.set('page', String(pageNumber));
    }
    setSearchParams(searchParams, { replace: true });
  };

  const navigateToFilePageNumber = (pageNumber: number) => {
    searchParams.set('page', String(pageNumber ?? 1));
    setSearchParams(searchParams, { replace: true });
  };

  const navigateToAssetPage = (assetExternalId: string) => {
    navigate(`/asset/${encodeURIComponent(assetExternalId)}`);
  };

  const showErrorToast = (annotation: UfvAnnotation) => {
    makeToast({
      type: 'danger',
      body: t(
        'FILE_VIEWER_ASSET_ANNOTATION_INVALID_RESOURCE_TYPE',
        'Error: Unknown annotation resource type. Clicked annotation: {{annotation}}',
        { annotation: JSON.stringify(annotation) }
      ),
    });
  };

  const handleAnnotationClick = (annotation: UfvAnnotation): void => {
    if (annotation.metadata.resourceType === 'file') {
      if (isIdm) {
        if (annotation.metadata.endNode) {
          const { externalId, space } = annotation.metadata.endNode;
          const pageNumber: number | undefined =
            annotation.metadata.endNodePageNumber;
          navigateToFilePage(externalId, space, pageNumber);
        } else {
          showErrorToast(annotation);
        }
      } else {
        client.files
          .retrieve([{ id: Number(annotation.metadata.resourceId) }], {
            ignoreUnknownIds: true,
          })
          .then((files) => {
            if (files.length === 0 || files[0].externalId === undefined) {
              makeToast({
                type: 'danger',
                body: t(
                  'FILE_VIEWER_FILE_ANNOTATION_INVALID_REFERENCE',
                  'Error: Document not found.'
                ),
              });
            } else {
              navigateToFilePage(files[0].externalId!);
            }
          });
      }
    } else if (annotation.metadata.resourceType === 'asset') {
      if (isIdm) {
        if (annotation.metadata.endNode) {
          const { externalId } = annotation.metadata.endNode;
          navigateToAssetPage(externalId);
        } else {
          showErrorToast(annotation);
        }
      } else {
        // Retrieve external ID of asset since the annotation.metadata.resourceId is the asset ID and we need the external ID to navigate to the asset page
        client.assets
          .retrieve([{ id: Number(annotation.metadata.resourceId) }], {
            ignoreUnknownIds: true,
          })
          .then((assets) => {
            if (assets.length === 0 || assets[0].externalId === undefined) {
              makeToast({
                type: 'danger',
                body: t(
                  'FILE_VIEWER_ASSET_ANNOTATION_INVALID_REFERENCE',
                  'Error: Asset not found.'
                ),
              });
            } else {
              navigateToAssetPage(assets[0].externalId!);
            }
          });
      }
    } else {
      showErrorToast(annotation);
    }
  };

  const handleCloseModal = () => {
    searchParams.delete('viewDocId');
    searchParams.delete('viewDocSpace');
    searchParams.delete('page');
    setSearchParams(searchParams, { replace: true });
  };

  const handleDownloadFile = async () => {
    if (!fileViewerRef || !container) return;
    try {
      setDownloading(true);
      await fileViewerRef.exportWorkspaceToPdf({
        shouldExportCanvasCoverPage: false,
        fileName: file?.name || fileInstanceId?.externalId,
        pages: [
          {
            containerId: container.id,
            pages: Array.from(
              { length: totalPages },
              (_, pageNumber) => pageNumber + 1
            ),
            shouldIncludeContainerAnnotations: true,
            shouldIncludeCanvasAnnotations: true,
          },
        ],
      });
    } catch (err) {
      makeToast({
        type: 'danger',
        body: 'Failed to download file',
      });
      captureException(err);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <StyledModal
      title={file?.name ?? String(error) ?? t('loading', 'Loading...')}
      visible={Boolean(fileExternalId)}
      hideFooter={!isDesktop || !isAkerbpCustomCode}
      onCancel={handleCloseModal}
      additionalActions={
        // TODO: Not working on mobile so only on desktop for now
        isDesktop &&
        isAkerbpCustomCode &&
        ({
          children: 'Download',
          icon: <DownloadIcon />,
          loading: downloading,
          type: 'secondary',
          onClick: handleDownloadFile,
        } as any)
      }
    >
      {(file !== undefined || fileInstanceId !== undefined) && (
        <FileViewer
          file={file}
          fileInstanceId={fileInstanceId}
          page={page}
          container={container}
          totalPages={totalPages}
          isAkerbpCustomCode={isAkerbpCustomCode}
          onPageChange={navigateToFilePageNumber}
          onAnnotationClick={handleAnnotationClick}
          setFileViewerRef={setFileViewerRef}
          setContainer={setContainer}
          setTotalPages={setTotalPages}
        />
      )}
    </StyledModal>
  );
};
