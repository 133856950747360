import { NodeEventMap } from '../annotations/setAnnotationNodeEventHandlers';

const mapEventForMobile = <EventType extends keyof NodeEventMap>(
  eventType: EventType
): (EventType | string)[] => {
  if (eventType === 'click') {
    return ['click', 'tap'];
  }

  if (eventType === 'mousedown') {
    return ['mousedown', 'touchstart'];
  }

  if (eventType === 'mousemove') {
    return ['mousemove', 'touchmove'];
  }

  if (eventType === 'mouseup') {
    return ['mouseup', 'touchend'];
  }

  return [eventType];
};

export default mapEventForMobile;
