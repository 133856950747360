import type { APMAppDataServiceOptions } from '../apm-app-data-service';
import { APMAppDataService } from '../apm-app-data-service';
import type { ServiceContract } from '../types';
import { VIEW_VERSIONS } from '../utils';

import type { Measurement } from './types';

export class MeasurementService extends APMAppDataService<Measurement> {
  constructor(contract: ServiceContract, options: APMAppDataServiceOptions) {
    super(
      contract,
      'MeasurementReading',
      VIEW_VERSIONS.MEASUREMENT_READING,
      options
    );
  }
}
