import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';

import addUnit from './addUnit';
import getTooltipNumericValue from './getTooltipNumericValue';

const getTooltipRawDatapointValue = (
  value?: string | number,
  unit?: string
): undefined | number | string => {
  if (isUndefined(value)) {
    return undefined;
  }

  if (isNumber(value)) {
    return getTooltipNumericValue(value, unit);
  }

  return addUnit(value, unit);
};

export default getTooltipRawDatapointValue;
