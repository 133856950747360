import type { Sort } from '@cognite/fdm-client';

export const getSortPropertiesWithTiebreaker = (
  sort?: Sort
): Sort | undefined => {
  if (sort && sort.length) {
    return [...sort, { externalId: 'DESC' }];
  }
  return sort;
};
