import type { FdmFile } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { MediaUpsertType } from '../types';

// TODO(INFIELD-540): Use correct datasets to upload images.
export const useUpdateMedia = () => {
  const { mediaService } = useFDMServices();
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);

  const queryClient = useQueryClient();

  return useMutation<
    { items: FdmFile[] },
    Error,
    {
      items: MediaUpsertType;
    }
  >(
    async ({ items }) => {
      return mediaService.updateMedia({ items });
    },
    {
      onError: (err) => {
        makeToast({
          body: t('updateMediaFailed', 'Failed to update media'),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-update-media',
          },
        });
      },

      onSuccess: async (data, { items }) => {
        items.forEach((item) =>
          queryClient.invalidateQueries([QueryKeys.MEDIA_ITEM, item.externalId])
        );
      },
    }
  );
};
