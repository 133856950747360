import styled from 'styled-components';

export const CustomCollapseWrapper = styled.div<{
  $isDragging: boolean;
  $isWithoutPadding: boolean;
}>`
  ${({ $isWithoutPadding }) => ($isWithoutPadding ? '' : 'padding: 16px;')}

  ${({ $isDragging, $isWithoutPadding }) =>
    $isWithoutPadding || $isDragging
      ? ''
      : 'border-bottom: 1px solid rgb(217, 217, 217);'}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const HeaderContentWrapper = styled.div<{ $isWithoutPadding: boolean }>`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  ${({ $isWithoutPadding }) =>
    $isWithoutPadding
      ? `border-right: 1px solid rgb(217, 217, 217);
      border-bottom: 1px solid rgb(217, 217, 217);`
      : 'border: 1px solid rgb(217, 217, 217);'}

  background-color: var(--cogs-surface--action--muted--default);
`;

export const HeaderExpandButtonWrapper = styled.div`
  margin-right: 8px;
  border-right: 1px solid rgb(217, 217, 217);
`;

export const HeaderActionMenuWrapper = styled.div<{
  $isWithoutPadding: boolean;
}>`
  ${({ $isWithoutPadding }) =>
    $isWithoutPadding
      ? 'border-bottom: 1px solid rgb(217, 217, 217);'
      : `border-top: 1px solid rgb(217, 217, 217);
      border-bottom: 1px solid rgb(217, 217, 217);
      border-right: 1px solid rgb(217, 217, 217);`}

  background-color: var(--cogs-surface--action--muted--default);
`;

export const ContentWrapper = styled.div`
  border-right: 1px solid rgb(217, 217, 217);
  border-left: 1px solid rgb(217, 217, 217);
`;
