import { useAuthContext } from '@cognite/e2e-auth';
import type { FileInfo } from '@cognite/sdk';
import ReactUnifiedViewer, {
  ContainerType,
  getContainerConfigFromFileInfo,
  getPdfCache,
} from '@cognite/unified-file-viewer';
import type {
  FileContainerProps,
  UnifiedViewer,
} from '@cognite/unified-file-viewer';
import { getApplicationId } from '@infield/features/auth';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { useEffect, useState } from 'react';
import type { FC } from 'react';

import { useIsDesktop } from '../../../hooks';
import type { UfvAnnotation } from '../types';

import {
  DESKTOP_MAX_CONTAINER_HEIGHT,
  DESKTOP_MAX_CONTAINER_WIDTH,
  MOBILE_MAX_CONTAINER_HEIGHT,
  MOBILE_MAX_CONTAINER_WIDTH,
  ROOT_CONTAINER_ID,
} from './constants';
import * as S from './elements';
import { FileViewerPagination } from './file-viewer-pagination';
import { useAnnotations, useIdmAnnotations } from './hooks';
import { getUnifiedFileViewerState } from './utils/getUnifiedFileViewerState';

interface Props {
  file?: FileInfo;
  fileInstanceId?: { externalId: string; space: string };
  page: number;
  container?: FileContainerProps;
  totalPages: number;
  isAkerbpCustomCode: boolean;
  onPageChange: (pageNumber: number) => void;
  onAnnotationClick: (annotation: UfvAnnotation) => void;
  setFileViewerRef: (fileViewerRef: UnifiedViewer | null) => void;
  setContainer: (container: FileContainerProps) => void;
  setTotalPages: (totalPages: number) => void;
}

export const FileViewer: FC<Props> = ({
  file,
  fileInstanceId,
  page,
  container,
  totalPages,
  isAkerbpCustomCode,
  onPageChange,
  onAnnotationClick,
  setFileViewerRef,
  setContainer,
  setTotalPages,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { client } = useAuthContext();
  const isDesktop = useIsDesktop();
  const { isIdm } = useAppConfigContext();
  const fileExternalId =
    fileInstanceId?.externalId || file?.externalId || 'missingFileExternalId';

  // IDM annotations
  const { data: idmAnnotations } = useIdmAnnotations(
    onAnnotationClick,
    isIdm,
    container
  );

  // Legacy annotations
  const { data: cogniteAnnotations } = useAnnotations(fileExternalId, !isIdm);
  const { annotations: classicAnnotations } = getUnifiedFileViewerState({
    containerId: fileExternalId,
    cogniteAnnotations,
    currentPage,
    onAnnotationClick,
  });

  const annotations = isIdm ? idmAnnotations : classicAnnotations;

  useEffect(() => {
    setCurrentPage(page);
    (async () => {
      const containerConfig = await getContainerConfigFromFileInfo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        client,
        fileInstanceId || file!,
        {
          id: fileExternalId,
          page: currentPage,
          maxHeight: isDesktop
            ? DESKTOP_MAX_CONTAINER_HEIGHT
            : MOBILE_MAX_CONTAINER_HEIGHT,
          maxWidth: isDesktop
            ? DESKTOP_MAX_CONTAINER_WIDTH
            : MOBILE_MAX_CONTAINER_WIDTH,
        }
      );
      setContainer(containerConfig);

      if (
        containerConfig.type === ContainerType.DOCUMENT &&
        containerConfig.url
      ) {
        const pages = await getPdfCache().getPdfNumPages(containerConfig.url);
        setTotalPages(pages);
      } else {
        setTotalPages(1);
      }
    })();
  }, [
    file,
    fileExternalId,
    client,
    currentPage,
    page,
    isDesktop,
    fileInstanceId,
    setContainer,
    setTotalPages,
  ]);

  if (container === undefined) {
    return null;
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  return (
    <S.FileViewerWrapper>
      <ReactUnifiedViewer
        key={fileInstanceId?.externalId ?? file?.id} // Re-mount component when file changes to trigger fit-to-screen etc.
        applicationId={getApplicationId()}
        id={ROOT_CONTAINER_ID}
        nodes={[container, ...(annotations || [])]}
        shouldShowZoomControls={isDesktop}
        setRef={setFileViewerRef}
      />
      <FileViewerPagination
        onPageChange={handlePageChange}
        page={page}
        totalPages={totalPages}
        isAkerbpCustomCode={isAkerbpCustomCode}
      />
    </S.FileViewerWrapper>
  );
};
