export const yAccessor = (d: {
  min?: any;
  max?: any;
  value?: any;
  average?: any;
}) => {
  if (d.value !== undefined) {
    return Number(d.value);
  }
  if (d.average !== undefined) {
    return Number(d.average);
  }
  // devNotification(d);
  return 0;
};

export const xAccessorDefault = (d: any) => d.timestamp;

export const y0Accessor = (data: { min: any }) =>
  Number.isNaN(Number(data.min)) ? yAccessor(data) : data.min;

export const y1Accessor = (data: { max: any }) =>
  Number.isNaN(Number(data.max)) ? yAccessor(data) : data.max;
