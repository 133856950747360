import { Button } from '@cognite/cogs.js-v10';
import type { RootLocationConfiguration } from '@infield/features/app-config/types';
import type { FC } from 'react';

import * as S from './elements';
import { RootLocationConfig } from './root-location-config';

interface Props {
  hasAppConfigPermission: boolean;
  rootLocation: RootLocationConfiguration;
  onDelete: () => void;
  onClickEdit: () => void;
  onEdit: (assetExternalId: string, displayName?: string) => void;
}

export const RootLocationConfigCard: FC<Props> = ({
  hasAppConfigPermission,
  rootLocation,
  onDelete,
  onClickEdit,
  onEdit,
}) => {
  return (
    <S.Container>
      <RootLocationConfig
        hasAppConfigPermission={hasAppConfigPermission}
        assetExternalId={rootLocation.assetExternalId}
        displayName={rootLocation.displayName ?? ''}
        onAssetSelect={onEdit}
        onAssetExternalIdChange={onEdit}
        onDisplayNameChange={(displayName) =>
          onEdit(rootLocation.assetExternalId, displayName)
        }
        disableInputs
      />

      <Button type="primary" onClick={onClickEdit}>
        Edit asset
      </Button>
      <Button type="destructive" onClick={onDelete}>
        Delete asset
      </Button>
    </S.Container>
  );
};
