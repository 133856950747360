import type { InFieldLocationConfig } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useFDMServices } from '@infield/providers/fdm-services';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpsertInfieldLocationConfig = () => {
  const { apmClient } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.configuration);

  return useMutation<
    InFieldLocationConfig[] | undefined,
    Error,
    InFieldLocationConfig[]
  >(
    async (infieldLocationConfigToUpsert) => {
      const {
        data: { items: infieldLocationConfig },
      } = await apmClient.infieldLocationConfig.upsert(
        infieldLocationConfigToUpsert
      );
      return infieldLocationConfig;
    },
    {
      onError: (err) => {
        makeToast({
          type: 'danger',
          body: t(
            'INFIELD_LOCATION_CONFIG_UPSERT_ERROR',
            'Failed to update InField location config'
          ),
        });
        captureException(err);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          QueryKeys.INFIELD_LOCATION_CONFIG,
        ]);
      },
      mutationKey: [MutationKeys.USE_INFIELD_LOCATION_CONFIG_UPSERT],
    }
  );
};
