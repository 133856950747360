import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import type { FC } from 'react';
import { lazy, Suspense, useMemo } from 'react';

const getComponent = (isDesktop: boolean) =>
  isDesktop
    ? lazy(() => import('./nav-hints-desktop'))
    : lazy(() => import('./nav-hints-mobile'));

/**
 * Shows full-screen overlay with hints about how to navigate in 3d model
 */
export const ThreeDNavigationHints: FC = () => {
  const isDesktop = useIsDesktop();
  const NavHintsComponent = useMemo(() => getComponent(isDesktop), [isDesktop]);

  return (
    <Suspense>
      <NavHintsComponent />
    </Suspense>
  );
};
