import {
  Button,
  DeleteIcon,
  Infobox,
  Input,
  Modal,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { MEDIA_METADATA_PROP_POINTING_TO_OBSERVATION } from '@infield/features/observation';
import type { FC } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MediaViewer } from '../media-viewer/media-viewer';
import type { ImageToUpload } from '../types';

import * as S from './elements';

interface Props {
  disableObservationItems: boolean;
  visible: boolean;
  media: ImageToUpload;
  onClose: (updatedMediaDescription?: string) => void;
  onDelete: () => void;
}

export const MediaEditor: FC<Props> = ({
  disableObservationItems,
  media,
  visible,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);

  const [mediaDescription, setMediaDescription] = useState<string>(
    media.metadata.description || ''
  );

  const navigate = useNavigate();

  const observationExternalId =
    media.metadata[MEDIA_METADATA_PROP_POINTING_TO_OBSERVATION];

  const disabled = Boolean(disableObservationItems && observationExternalId);

  const handleOnClickGotoObservation = () => {
    if (observationExternalId) {
      navigate(`/observation/${encodeURIComponent(observationExternalId)}`);
    }
  };

  const renderFooter = () => {
    return (
      <S.StyledFlex>
        <Input
          value={mediaDescription}
          fullWidth
          autoFocus
          disabled={disabled}
          onChange={(ev) => setMediaDescription(ev.target.value)}
          placeholder={t(
            'MODAL_UPLOADED_MEDIA_VIEW_INPUT_DESCRIPTION_PLACEHOLDER',
            'Description'
          )}
        />
        {!disabled && (
          <>
            <Button
              type="primary"
              disabled={disabled}
              onClick={() =>
                onClose(
                  mediaDescription === media.metadata.description
                    ? undefined
                    : mediaDescription
                )
              }
            >
              {t(
                'MODAL_UPLOADED_MEDIA_VIEW_BUTTON_TITLE_ADD_TO_UPLOAD',
                'Add to upload'
              )}
            </Button>
            <Button
              type="destructive"
              disabled={disabled}
              icon={<DeleteIcon />}
              onClick={onDelete}
            >
              {t('deleteImage', 'Delete image')}
            </Button>
          </>
        )}

        {disabled && (
          <Infobox
            actions={[
              {
                children: t(
                  'MODAL_UPLOADED_MEDIA_VIEW_BUTTON_GO_TO_OBSERVATION',
                  'Go to observation'
                ),
                type: 'tertiary',
                onClick: handleOnClickGotoObservation,
              },
            ]}
          >
            {t(
              'MODAL_UPLOADED_MEDIA_VIEW_LINKED_TO_MEDIA_NOTE',
              'This media item is linked to an observation. To delete or update it, you must navigate to the observation itself.'
            )}
          </Infobox>
        )}
      </S.StyledFlex>
    );
  };

  return (
    <Modal
      visible={visible}
      size="small"
      hideFooter
      title={t('MEDIA_MODAL_TITLE', 'Upload images or videos')}
      onCancel={() => onClose()}
    >
      <S.Container>
        <MediaViewer
          key={`${media.externalId}-editor`}
          media={media}
          showDescription={false}
          mediaHeight={300}
        />
      </S.Container>
      {renderFooter()}
    </Modal>
  );
};
