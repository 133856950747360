import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';

import { UnifiedViewer } from '../UnifiedViewer';
import mapEventForMobile from '../utils/mapEventForMobile';

import { EventableContainer } from './Container';
import { ContainerConfig } from './types';

export type NodeEventMap = GlobalEventHandlersEventMap & {
  [index: string]: any;
};

type ContainerEventHandler<EventType extends keyof NodeEventMap> = {
  event: EventType;
  handler:
    | ((
        event: KonvaEventObject<NodeEventMap[EventType]>,
        annotation: ContainerConfig
      ) => void)
    | undefined;
};

const setContainerNodeEventHandlers = <
  T extends Konva.Node,
  EventType extends keyof NodeEventMap,
  C extends ContainerConfig<any> & EventableContainer
>(
  node: T,
  eventableContainerConfig: C,
  unifiedViewer: UnifiedViewer
): T => {
  const containerEventHandlers: ContainerEventHandler<EventType>[] = [
    {
      event: 'click' as EventType,
      handler: eventableContainerConfig.onClick,
    },
    {
      event: 'mouseover' as EventType,
      handler: eventableContainerConfig.onMouseOver,
    },
    {
      event: 'mouseout' as EventType,
      handler: eventableContainerConfig.onMouseOut,
    },
  ];

  unifiedViewer.eventHandler.removeMultipleEventListeners(
    node,
    containerEventHandlers
      .map(({ event }) => event)
      .flatMap(mapEventForMobile) as string[]
  );

  containerEventHandlers.forEach(({ event: eventType, handler }) => {
    if (handler !== undefined) {
      // For now we need to pass the Konva event objects, since the wrapped events are not
      // cancellable. i.e. MouseEvent.stopPropagation() does not work - the event will still
      // bubble

      unifiedViewer.eventHandler.addMultipleEventListeners(
        node,
        mapEventForMobile(eventType) as string[],
        (event) => handler(event, eventableContainerConfig)
      );
    }
  });
  return node;
};

export default setContainerNodeEventHandlers;
