import maxBy from 'lodash/maxBy';

const getLatestViewVersion = (
  viewVersions: { version: string; createdTime: number }[]
): string => {
  const latestView = maxBy(viewVersions, 'createdTime');
  if (latestView === undefined) {
    throw new Error(`No latest view found in ${viewVersions}`);
  }
  return latestView.version;
};

export default getLatestViewVersion;
