import isPlainObject from 'lodash/isPlainObject';

import isNotUndefined from '../../../utils/isNotUndefined';

import {
  NodeInstanceDefinition,
  PropertyDefinition,
  TypeInformation,
  ViewReference,
} from './types';

const isObject = (val: any): val is object => isPlainObject(val);
const getViewIdentifier = (view: ViewReference): string =>
  `${view.externalId}/${view.version}`;

type PrimitiveType = string | number | boolean | undefined;
const PRIMITIVE_TYPES = [
  'text',
  'boolean',
  'float32',
  'float64',
  'int32',
  'int64',
  'timestamp',
  'date',
  // 'json', <-- ignore for now, since we don't have a good way of rendering json objects
];

const isPrimitiveType = (type: string): boolean =>
  PRIMITIVE_TYPES.includes(type);

const parsePropertyDefinition = <PropertyType>(
  view: ViewReference,
  propertyDefinition: PropertyDefinition<PropertyType>
): Record<string, PropertyType> => {
  const views = propertyDefinition[view.space];
  if (views === undefined) {
    throw new Error(`Found no views in space ${view.space}`);
  }

  const viewIdentifier = getViewIdentifier(view);
  const properties = views[viewIdentifier];
  if (properties === undefined) {
    throw new Error(
      `Found no properties in view with identifier ${viewIdentifier}`
    );
  }

  return properties;
};

const parsePropertyValue = (
  type: string,
  value: PrimitiveType
): PrimitiveType | Date => {
  if (
    (typeof value === 'string' || typeof value === 'number') &&
    (type === 'date' || type === 'timestamp')
  ) {
    return new Date(value);
  }
  return value;
};

const parseInstance = (
  view: ViewReference,
  instance: NodeInstanceDefinition,
  typeInfo: TypeInformation
): { [property: string]: PrimitiveType | Date } => {
  const properties = instance.properties;
  if (properties === undefined) {
    throw new Error(
      `No properties found for instance with space ${instance.space} and external id ${instance.externalId}`
    );
  }
  // propertyValues will only contain the properties that have their values set
  const propertyValues = parsePropertyDefinition(view, properties);
  // typePropertyValues will contain the *type information* about the properties of the given instance
  const typePropertyValues = parsePropertyDefinition(view, typeInfo);
  // To simplify the rendering of the FDM instances, we will for now only support primitive types
  const filteredTypePropertyValues = Object.fromEntries(
    Object.entries(typePropertyValues).filter(
      ([
        _,
        {
          type: { type: propertyType },
        },
      ]) => isPrimitiveType(propertyType)
    )
  );

  const typesWithValues = Object.fromEntries(
    Object.entries(filteredTypePropertyValues)
      .map(([propertyName, propertyTypeInfo]) => {
        const {
          type: { type },
        } = propertyTypeInfo;
        const propertyValue = propertyValues[propertyName];
        // Ignore properties with arrays or objects as values, since we don't
        // have a good way of displaying them, yet.
        if (Array.isArray(propertyValue) || isObject(propertyValue)) {
          return undefined;
        }
        return [propertyName, parsePropertyValue(type, propertyValue)];
      })
      .filter(isNotUndefined)
  );
  return typesWithValues;
};

export default parseInstance;
