import type { Activity } from '@cognite/apm-client';

type CoreApmActivityFields = keyof Activity;

export const METADATA_BLACKLIST: CoreApmActivityFields[] = [
  'externalId',
  'id',
  'title',
  'description',
  'source',
  'startTime',
  'endTime',
  'assetExternalIds',
  'assetExternalId',
  'functionalLocation',
  'rootLocation',
  'assignedTo',
  'subActivities',
  'resource',
  'type',
  'status',
  'isArchived',
  'isInApp',
  'createdAt',
  'createdBy',
  'lastUpdatedDate',
  'mutatedBy',
  'cloneOf',
  'mutatedFields',
  'isMutated',
  'space',
  'createdTime',
  'lastUpdatedTime',

  // IDM CogniteWorkOrder fields
  'name',
  'tags,',
  'aliases',
  'sourceId',
  'sourceContext',
  'source',
  'sourceCreatedTime',
  'sourceUpdatedTime',
  'sourceCreatedUser',
  'sourceUpdatedUser',
  'scheduledStartTime',
  'scheduledEndTime',
  'mainAsset',
  'assets',
  'equipment',
  'timeSeries',
  'priority',
  'priorityDescription',
];
