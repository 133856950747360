import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ConfigPageContentWrapper = styled(Flex).attrs({
  direction: 'row',
  justifyContent: 'space-between',
})`
  height: 100%;
  padding: 32px 62px;
`;

export const HelpTextAreaWrapper = styled(Flex).attrs({
  direction: 'column',
  gap: 8,
})`
  width: 30%;
  padding-left: 36px;
  border-left: 1px solid var(--cogs-border--interactive--default);
`;

export const ContentContainer = styled(Flex).attrs({
  direction: 'column',
  gap: 16,
})`
  width: 400px;
  padding-right: 16px;
`;

export const LocationInfieldContentWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  width: 100%;
`;

export const LocationContainer = styled(Flex)`
  height: 100%;
`;
