import { Body } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Card = styled.div`
  padding: 8px;
  width: 100%;
  background: ${({ theme }) => theme['surface--muted']};
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(0, 0, 0, 0.2);
`;

export const EmptyCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.075),
    0px 3px 8px rgba(0, 0, 0, 0.05);
`;

export const EmptyDescription = styled(Body).attrs({
  size: 'small',
})`
  padding: 16px;
  color: ${({ theme }) => theme['text-icon--muted']};
`;
