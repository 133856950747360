import {
  DragHandleVerticalIcon,
  Flex,
  Heading,
  IconWrapper,
  Tooltip,
} from '@cognite/cogs.js-v10';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { CustomCollapse } from '@infield/features/ui/custom-collapse/custom-collapse';
import type { FC } from 'react';

import { GroupMenu } from './group-menu';

type Props = {
  groupName: string;
  index: number;
  groupTaskList: JSX.Element[];
  isEditable: boolean;
  onClickUnGroup: () => void;
  onClickRename: () => void;
};

export const TasksGroup: FC<Props> = ({
  groupName,
  groupTaskList,
  isEditable,
  onClickUnGroup,
  onClickRename,
  index,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);

  return (
    <Draggable
      key={groupName}
      isDragDisabled={!isEditable}
      draggableId={groupName}
      index={index}
    >
      {(dragProvided, { isDragging }) => (
        <div ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
          <CustomCollapse
            isDragging={isDragging}
            header={
              <Flex
                alignItems="center"
                gap={8}
                {...dragProvided.dragHandleProps}
              >
                {isEditable && (
                  <IconWrapper size={14}>
                    <DragHandleVerticalIcon />
                  </IconWrapper>
                )}
                <Heading level={6}>{groupName}</Heading>
              </Flex>
            }
            extra={
              isEditable ? (
                <Tooltip
                  content={t(
                    'TASKS_GROUP_MENU_BUTTON_TOOLTIP_EXTRA_ACTIONS',
                    'Extra actions'
                  )}
                >
                  <GroupMenu
                    onClickUnGroup={onClickUnGroup}
                    onClickRename={onClickRename}
                  />
                </Tooltip>
              ) : undefined
            }
          >
            <Droppable
              droppableId={`$infield-droppable-group-${groupName}`}
              type="grouped"
            >
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {groupTaskList}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </CustomCollapse>
        </div>
      )}
    </Draggable>
  );
};
