import { Size } from '../../annotations/types';
import UnifiedViewerEventType from '../../UnifiedViewerEventType';
import isEmptyCanvas, { BLACK_PIXEL_VALUE } from '../../utils/isEmptyCanvas';
import { ContainerType } from '../ContainerType';
import ReactContainer, {
  ReactContainerRenderContentProps,
} from '../ReactContainer';
import ErrorContent from '../TimeseriesContainer/ErrorContent';

import { VideoContainerProps } from './types';
import VideoContainerContent from './VideoContainerContent';

export default class VideoContainer<MetadataType> extends ReactContainer<
  MetadataType,
  VideoContainerProps<MetadataType>
> {
  public readonly type = ContainerType.VIDEO;

  private videoRef: HTMLVideoElement | null = null;

  /**
   * Creates a canvas with the same aspect ratio as the contentNode and scales it to fit the video.
   * @param videoDimensions
   * @param contentNodeDimensions
   */
  private createScaledCanvas = (
    videoDimensions: Size,
    contentNodeDimensions: Size
  ): HTMLCanvasElement => {
    const canvas = document.createElement('canvas');

    const scaleFactor = Math.max(
      videoDimensions.width / contentNodeDimensions.width,
      videoDimensions.height / contentNodeDimensions.height
    );

    canvas.width = contentNodeDimensions.width * scaleFactor;
    canvas.height = contentNodeDimensions.height * scaleFactor;

    return canvas;
  };

  protected override async isCapturedCanvasEmpty(
    canvas: HTMLCanvasElement
  ): Promise<boolean> {
    return isEmptyCanvas(canvas, BLACK_PIXEL_VALUE);
  }

  protected override async captureFn(): Promise<HTMLCanvasElement | undefined> {
    if (this.loadingStatus.isError) {
      return super.captureFn();
    }

    if (this.videoRef === null) {
      return undefined;
    }

    const videoElement = this.videoRef;

    const videoDimensions = {
      width: videoElement.videoWidth,
      height: videoElement.videoHeight,
    };

    const contentNodeDimensions = this.getContentNode().getClientRect({
      skipTransform: true,
    });

    /**
     * We create a canvas that has the same aspect ratio as the contentNode and scale it to fit the video.
     * The reason we do this is so that if the container is larger than the video frame, we will
     * center the frame inside of the target canvas. This is to avoid having the video frame
     * be stretched to fit the container.
     */
    const targetCanvas = this.createScaledCanvas(
      videoDimensions,
      contentNodeDimensions
    );

    const targetCanvasContext = targetCanvas.getContext('2d');
    if (targetCanvasContext === null) {
      return undefined;
    }

    // Fill with black background
    targetCanvasContext.fillRect(0, 0, targetCanvas.width, targetCanvas.height);

    // Draw videoFrameCanvas onto the middle of the targetCanvas
    targetCanvasContext.drawImage(
      videoElement,
      (targetCanvas.width - videoDimensions.width) / 2,
      (targetCanvas.height - videoDimensions.height) / 2,
      videoDimensions.width,
      videoDimensions.height
    );

    return targetCanvas;
  }

  protected getPropsRequiringUpdate(): Array<
    keyof Pick<VideoContainerProps, 'url' | 'currentTimeSeconds'>
  > {
    return ['url', 'currentTimeSeconds'];
  }

  protected override onDomNodeHidden = (): void => {
    this.videoRef?.pause();
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      containers: [this.serialize()],
      annotations: [],
    });
  };

  private handleRef = (ref: HTMLVideoElement | null) => {
    this.videoRef = ref;
  };

  protected renderContent = ({
    width,
    height,
    shouldAutoSize,
    setLoadingStatus,
    onContentSizeChange,
    unscaledWidth,
    unscaledHeight,
  }: ReactContainerRenderContentProps): JSX.Element => {
    if (this.loadingStatus.isError) {
      return <ErrorContent width={width} height={height} />;
    }
    return (
      <VideoContainerContent
        width={width}
        height={height}
        shouldAutoSize={shouldAutoSize}
        url={this.props.url}
        setLoadingStatus={setLoadingStatus}
        onContentSizeChange={onContentSizeChange}
        onRef={this.handleRef}
        currentTimeSeconds={this.props.currentTimeSeconds}
        unscaledWidth={unscaledWidth}
        unscaledHeight={unscaledHeight}
      />
    );
  };

  private getSerializableCurrentTimeSeconds = (): number | undefined => {
    const readCurrentTime = this.videoRef?.currentTime;

    if (readCurrentTime === undefined) {
      return undefined;
    }

    return readCurrentTime;
  };

  public serialize = (): VideoContainerProps => {
    return {
      ...this.serializeBaseContainerProps(),
      type: this.type,
      url: this.props.url,
      currentTimeSeconds: this.getSerializableCurrentTimeSeconds(),
    };
  };
}
