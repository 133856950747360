import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';

import styled from 'styled-components';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { difference, isEmpty, debounce, get } from 'lodash';
import { RecoilRoot, useSetRecoilState } from 'recoil';

import {
  ChartsProvider,
  Chart,
  PlotNavigationUpdate,
  AxisUpdate,
  ChartPlotContainer,
  chartAtom,
  AxisRange,
  ChartTimeSeries,
  ChartWorkflow,
  ScheduledCalculation,
  CoreTimeseries,
} from '@cognite/charts-lib';
import { Button, Body, PromoChip } from '@cognite/cogs.js-v10';
import { CogniteClient } from '@cognite/sdk';
import { SDKProvider } from '@cognite/sdk-provider';

import { ReactContainerRenderContentProps } from '../ReactContainer';
import useStatusChange from '../ReactContainer/useStatusChange';

import { ShamefulChartsContext } from './ChartsContainer';
import getAxisUpdatesFromSourcePropsById from './getAxisUpdatesFromSourcePropsById';
import getSourcePropsByIdFromSourceCollection from './getSourcePropsByIdFromSourceCollection';
import type { ChartSourcePropsById, ChartsContainerProps } from './types';
import { updateToggleVisibility } from './updateChartVisibility';
import useInitializedChart from './useInitializedChart';
import useIsLoadingChartData from './useIsLoadingChartData';

const SCROLL_ZOOM_TIMEOUT_MS = 400;
const ON_RANGE_CHANGE_DEBOUNCE_MS = 1000;

type ChartsContentProps = Pick<
  ReactContainerRenderContentProps,
  'width' | 'height' | 'unscaledWidth' | 'unscaledHeight' | 'setLoadingStatus'
> & {
  project: string;
  onRangeChange: (startDate: Date, endDate: Date) => void;
  onYAxisRangeChange: (updates: AxisUpdate[], idsToRemove?: string[]) => void;
  overriddenSourcePropsById?: ChartSourcePropsById;
} & Pick<ChartsContainerProps, 'instance' | 'startDate' | 'endDate'>;

const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0;
};

const mergeRangeProps = <RangeItem extends { id: string; range?: AxisRange }>(
  item: RangeItem,
  propsById: ChartSourcePropsById | undefined
): RangeItem => {
  if (propsById === undefined) {
    return item;
  }
  return {
    ...item,
    range: propsById[item.id]?.range ?? item.range,
  };
};

const ChartsContent: React.FC<ChartsContentProps> = ({
  width,
  height,
  unscaledWidth,
  unscaledHeight,
  startDate,
  endDate,
  instance,
  project,
  setLoadingStatus,
  onRangeChange,
  onYAxisRangeChange,
  overriddenSourcePropsById,
}): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isScrollZoomEnabled, setIsScrollZoomEnabled] = useState(true);
  const chartsLegendRef = useRef<HTMLDivElement>(null);
  const [legendHeight, setLegendHeight] = useState(0);

  const {
    data: chart,
    isFetching,
    isError,
  } = useInitializedChart({
    id: instance.id,
    projectId: project,
  });
  const setChart = useSetRecoilState(chartAtom);
  const mergeUnits = get(chart, 'settings.mergeUnits', true);

  // For a period of time – while the date range changes are being debounced –
  // the rendered date ranges might be different from the final date, hence they
  // are kept here until the debouncing has finished.
  const dirtyDateRange = useRef<
    { dateFrom: string; dateTo: string } | undefined
  >(undefined);

  const isLoadingChartData = useIsLoadingChartData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedRangeChange = useCallback(
    debounce((dateFrom: string, dateTo: string) => {
      onRangeChange(new Date(dateFrom), new Date(dateTo));
      setChart((prevChart: Chart | undefined) => {
        if (prevChart === undefined) {
          return undefined;
        }
        return { ...prevChart, dateFrom, dateTo };
      });
      dirtyDateRange.current = undefined;
    }, ON_RANGE_CHANGE_DEBOUNCE_MS),
    [onRangeChange, setChart]
  );

  const handleRangeChange = useCallback(
    ({ x: dateRange, y: yAxisUpdates }: PlotNavigationUpdate) => {
      if (dateRange.length === 2) {
        onDebouncedRangeChange(dateRange[0], dateRange[1]);
        dirtyDateRange.current = {
          dateFrom: dateRange[0],
          dateTo: dateRange[1],
        };
      }
      if (yAxisUpdates.length > 0) {
        onYAxisRangeChange(yAxisUpdates);
      }
    },
    [onDebouncedRangeChange, onYAxisRangeChange]
  );

  const sourcePropsById = useMemo(() => {
    if (chart === undefined) {
      return undefined;
    }
    return {
      ...getSourcePropsByIdFromSourceCollection(
        chart?.timeSeriesCollection ?? []
      ),
      ...getSourcePropsByIdFromSourceCollection(
        chart?.workflowCollection ?? []
      ),
      ...getSourcePropsByIdFromSourceCollection(
        chart?.scheduledCalculationCollection ?? []
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chart?.timeSeriesCollection,
    chart?.workflowCollection,
    chart?.scheduledCalculationCollection,
  ]);

  useEffect(() => {
    // If no valid source properties are provided, there is nothing to do. It is
    // important we do an early return if sourcePropsById is empty, since this
    // addresses an issue where this side effect gets stuck in an infinite
    // update loop when sourcePropsById, for some reason, continuously gets
    // re-created as an empty object. We have seen this happen for empty charts
    // as well as older charts that contains time series collections with empty ranges
    if (sourcePropsById === undefined || isEmptyObject(sourcePropsById)) {
      return;
    }
    // Serialize the Y-axis ranges provided by the Charts backend if an
    // initial range for at least one collection is *not* provided
    if (
      overriddenSourcePropsById === undefined ||
      isEmpty(overriddenSourcePropsById)
    ) {
      onYAxisRangeChange(getAxisUpdatesFromSourcePropsById(sourcePropsById));
      return;
    }

    const removedIds = difference(
      Object.keys(overriddenSourcePropsById),
      Object.keys(sourcePropsById)
    );
    if (removedIds.length > 0) {
      onYAxisRangeChange(
        getAxisUpdatesFromSourcePropsById(sourcePropsById),
        removedIds
      );
      return;
    }
  }, [sourcePropsById, overriddenSourcePropsById, onYAxisRangeChange]);

  useEffect(() => {
    if (isFetching) {
      return;
    }
    setChart((prevChart) => {
      if (prevChart === undefined) {
        return undefined;
      }
      return {
        ...prevChart,
        dateFrom: startDate,
        dateTo: endDate,
        timeSeriesCollection: prevChart.timeSeriesCollection?.map((ts) =>
          mergeRangeProps(ts, overriddenSourcePropsById)
        ),
        workflowCollection: prevChart.workflowCollection?.map((wf) =>
          mergeRangeProps(wf, overriddenSourcePropsById)
        ),
        scheduledCalculationCollection:
          prevChart.scheduledCalculationCollection?.map((sc) =>
            mergeRangeProps(sc, overriddenSourcePropsById)
          ),
      };
    });
  }, [isFetching, overriddenSourcePropsById, startDate, endDate, setChart]);

  useStatusChange({
    data: chart,
    isLoading: isFetching || isLoadingChartData,
    isError,
    setLoadingStatus,
  });

  // convert time series collection to object for easier access

  const combinedSourceCollection = useMemo(() => {
    const idsMap = {} as Record<
      string,
      CoreTimeseries | ScheduledCalculation | ChartTimeSeries | ChartWorkflow
    >;
    chart?.workflowCollection?.forEach((workflow) => {
      idsMap[workflow.id] = workflow;
    });
    chart?.timeSeriesCollection?.forEach((ts) => {
      idsMap[ts.id] = ts;
    });
    chart?.coreTimeseriesCollection?.forEach((ts) => {
      idsMap[ts.id] = ts;
    });
    chart?.scheduledCalculationCollection?.forEach((calculation) => {
      idsMap[calculation.id] = calculation;
    });
    return idsMap;
  }, [
    chart?.coreTimeseriesCollection,
    chart?.scheduledCalculationCollection,
    chart?.timeSeriesCollection,
    chart?.workflowCollection,
  ]);

  const toggleSources = (id: string) => {
    // filter source collection on click, if it clicked again
    setChart((prevChart) => {
      if (prevChart === undefined) {
        return undefined;
      }
      return updateToggleVisibility(prevChart, id);
    });
  };
  useEffect(() => {
    if (isFetching || isLoadingChartData) {
      setIsScrollZoomEnabled(false);
      return;
    }
    // Wait a little bit with re-enabling scroll-zooming once loading has
    // finished. This is to prevent users from aggressively scrolling (and thus
    // triggering re-fetching of Charts data) immediately after finishing a
    // scrolling operation.

    const timeoutId = setTimeout(
      () => setIsScrollZoomEnabled(true),
      SCROLL_ZOOM_TIMEOUT_MS
    );

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isFetching, isLoadingChartData]);

  const scale = Math.min(width / unscaledWidth, height / unscaledHeight);

  const onMouseDown = (event: React.MouseEvent) => {
    onDebouncedRangeChange.cancel();
    // Stop propagating the event before it reaches the select tool.
    // Otherwise, the select tool mouse down handler will treat the SHIFT
    // + CLICK as a multi-select operation on the container
    if (event.shiftKey) {
      event.stopPropagation();
    }
  };
  const onWheel = () => {
    onDebouncedRangeChange.cancel();
  };

  useEffect(() => {
    const element = containerRef.current;
    if (element === null) {
      return;
    }
    const handleWheel = (event: WheelEvent) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };
    // active listener to prevent the default behavior of the wheel event
    // prevents accidental page zooming when using touchpad gestures.
    element.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
      element.removeEventListener('wheel', handleWheel);
    };
  }, []);

  useEffect(() => {
    if (chartsLegendRef.current) {
      setLegendHeight(chartsLegendRef.current.clientHeight);
    }
  }, [combinedSourceCollection]);
  return (
    <>
      <div
        ref={containerRef}
        style={{ transform: `scale(${scale})`, transformOrigin: 'top left' }}
        onMouseDown={onMouseDown}
        onWheel={onWheel}
      >
        <div
          style={{
            boxSizing: 'border-box',
            width: unscaledWidth,
            // to fit all the content we need to scale - legend height
            height: unscaledHeight - legendHeight,
            cursor: 'default',
            border: '1px solid #D9D9D9',
            position: 'relative',
          }}
        >
          <div ref={chartsLegendRef}>
            {Object.keys(combinedSourceCollection).map((key) => {
              return (
                <Button
                  type="ghost"
                  key={key}
                  style={{
                    display: 'inline-flex',
                    gap: 8,
                    opacity: combinedSourceCollection[key].enabled ? 1 : 0.5,
                  }}
                  onClick={() => toggleSources(key)}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      borderRadius: 4,
                      width: 12,
                      height: 12,
                      background: combinedSourceCollection[key].color,
                    }}
                  ></span>
                  <Body size="xx-small">
                    {combinedSourceCollection[key].name}
                  </Body>
                </Button>
              );
            })}
            <BetaChipContainer>{betaChip}</BetaChipContainer>
          </div>
          {chart !== undefined && (
            <ChartPlotContainer
              chart={
                dirtyDateRange.current !== undefined
                  ? {
                      ...chart,
                      ...dirtyDateRange.current,
                    }
                  : chart
              }
              onPlotNavigation={handleRangeChange}
              scatterType="scatter"
              scrollZoomEnabled={isScrollZoomEnabled}
              mergeUnits={mergeUnits}
              isGridlinesShown={true}
              isYAxisShown={true}
              calculationStaleTime={Infinity}
            />
          )}
        </div>
      </div>
      {(isFetching || isLoadingChartData) && (
        <div
          style={{
            position: 'absolute',
            inset: 0,
            background: 'rgba(255,255,255,0.7)',
          }}
        />
      )}
    </>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 10 * 60 * 1000, // Pretty long, 600 seconds
    },
  },
});

const WrappedChartsContent = ({
  sdk,
  chartsContext,
  ...props
}: Omit<ChartsContentProps, 'project'> & {
  sdk: CogniteClient;
  chartsContext: ShamefulChartsContext;
}): JSX.Element => {
  const { getToken, getUserInformation, getProject, isProduction, getCluster } =
    chartsContext;

  const project = getProject();

  return (
    <RecoilRoot>
      <SDKProvider sdk={sdk}>
        <QueryClientProvider client={queryClient}>
          <ChartsProvider
            getToken={getToken}
            getUserInformation={getUserInformation}
            isProduction={isProduction}
            getProject={getProject}
            getCluster={getCluster}
          >
            <ChartsContent {...props} project={project} />
          </ChartsProvider>
        </QueryClientProvider>
      </SDKProvider>
    </RecoilRoot>
  );
};

// The Beta chip is overridable in IC if it is necessary to provide translated
// text. The container is configured to make sure PromoChip is renderable by
// HTML2Canvas by setting its display from inline-flex to inline.
let betaChip = <PromoChip>BETA</PromoChip>;

export const setBetaChip = (newBetaChip: React.ReactElement): void => {
  betaChip = newBetaChip;
};

const BetaChipContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  &&& .cogs.cogs-promochip {
    display: inline;
  }
`;

export default WrappedChartsContent;
