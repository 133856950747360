import chunk from 'lodash/chunk';

import { UnifiedViewerRenderer } from '../../../index';
import { LineType, PolylineAnnotation, Position, Size } from '../types';

import getConnectionPoints from './getConnectionPoints';

const getDenormalizedPolylineDimensionsFromAnnotation = (
  annotation: PolylineAnnotation,
  containerRect: Size
): Position[] =>
  annotation.vertices === undefined
    ? []
    : annotation.vertices.map(({ x, y }) => ({
        x: containerRect.width * x,
        y: containerRect.height * y,
      }));

const getPoints = (
  annotation: PolylineAnnotation,
  unifiedViewerRenderer: UnifiedViewerRenderer
): Position[] | undefined => {
  if (annotation.fromId !== undefined || annotation.toId !== undefined) {
    const connectionPoints = getConnectionPoints(
      annotation.style?.lineType ?? LineType.STRAIGHT,
      annotation.fromId,
      annotation.toId,
      annotation.anchorStartTo,
      annotation.anchorEndTo,
      annotation.vertices,
      unifiedViewerRenderer
    );

    if (connectionPoints === undefined) {
      return undefined;
    }

    return chunk(connectionPoints, 2).map(([x, y]) => ({ x, y }));
  }

  if (annotation.containerId === undefined) {
    return annotation.vertices;
  }

  const container = unifiedViewerRenderer.getContainerById(
    annotation.containerId
  );
  if (container === undefined) {
    return undefined;
  }

  return getDenormalizedPolylineDimensionsFromAnnotation(
    annotation,
    container.getContentNode().size()
  );
};

export default getPoints;
