import { Body } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const InputDescription = styled(Body)`
  color: var(--cogs-text-icon--muted);
`;

export const LimitMessage = styled(Body).attrs({
  size: 'x-small',
})<{
  $limitReached: boolean;
}>`
  color: var(--cogs-text-icon--status-critical);
  display: ${(props) => (props.$limitReached ? 'flex' : 'none')};
`;

export const LimitCounter = styled(Body).attrs({
  size: 'x-small',
})<{
  $limitReached: boolean;
}>`
  margin-left: auto;
  color: ${(props) =>
    props.$limitReached
      ? 'var(--cogs-text-icon--status-critical)'
      : 'var(--cogs-text-icon--muted)'};
`;
