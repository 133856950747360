import { Segment, Point, Rectangle, intersections } from '@mathigon/euclid';
import { IRect } from 'konva/lib/types';

const getStraightConnectionPathPointsFromBorder = (
  fromRect: IRect,
  toRect: IRect
): [number, number, number, number] => {
  // We want the connection to be drawn from the border of the container or
  // annotation to the border of the other container or annotation. If the
  // containers or annotations are overlapping, we use the center of the
  // containers or annotations instead.

  const fromEuclidRect = new Rectangle(
    new Point(fromRect.x, fromRect.y),
    fromRect.width,
    fromRect.height
  );
  const toEuclidRect = new Rectangle(
    new Point(toRect.x, toRect.y),
    toRect.width,
    toRect.height
  );

  const centerToCenterSegment = new Segment(
    fromEuclidRect.center,
    toEuclidRect.center
  );

  const fromRectIntersections = intersections(
    centerToCenterSegment,
    fromEuclidRect
  );
  const toRectIntersections = intersections(
    centerToCenterSegment,
    toEuclidRect
  );
  if (fromRectIntersections.length > 1 || toRectIntersections.length > 1) {
    throw new Error(
      'Found more that one intersection point between the center to center segment and the annotation/container boundary. This should not happen.'
    );
  }

  const fromPoint =
    fromRectIntersections.length === 1
      ? fromRectIntersections[0]
      : fromEuclidRect.center;
  const toPoint =
    toRectIntersections.length === 1
      ? toRectIntersections[0]
      : toEuclidRect.center;

  return [fromPoint.x, fromPoint.y, toPoint.x, toPoint.y];
};

export default getStraightConnectionPathPointsFromBorder;
