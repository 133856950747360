import { useTranslation } from '@infield/features/i18n';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createContext, useContext } from 'react';
import type { FC, PropsWithChildren } from 'react';

const DayJSContext = createContext<null>(null);

export const DayJSProvider: FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();
  const code = i18n?.language || 'en';

  return (
    <DayJSContext.Provider value={null}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={code}>
        {children}
      </LocalizationProvider>
    </DayJSContext.Provider>
  );
};

export const useDayJSContext = () => {
  const context = useContext(DayJSContext);
  if (context) {
    return context;
  }
  throw new Error('useDayJSContext must be used within DayJSProvider');
};
