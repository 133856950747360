import {
  Annotation,
  AnnotationType,
  isSingleDocumentAnnotation,
} from './annotations/types';
import { ContainerType } from './containers/ContainerType';
import { ContainerConfig } from './containers/types';

export const UNIFIED_VIEWER_NODE_TYPE_KEY = 'unifiedViewerType';

export enum UnifiedViewerNodeType {
  CONTAINER_GROUP = 'ContainerGroup',
  CONTAINER_CONTENT_GROUP = 'ContainerContentGroup',
  CONTAINER_BOUNDARY_RECT = 'ContainerBoundaryRect',
  CONTAINER_DOM_IMAGE_NODE = 'ContainerDomImageNode',
  TEXT_CONTAINER_BACKGROUND = 'TextContainerBackground',
  CONTAINER_IMAGE = 'ContainerImage',
  CONTAINER_LOW_DETAIL_IMAGE = 'ContainerLowDetailImage',
  CONTAINER_HEADER_GROUP = 'ContainerHeaderGroup',
  CONTAINER_TEXT = 'ContainerText',
  TEXT_CONTAINER_LABEL = 'TextContainerLabel',
  TEXT_CONTAINER_VALUE = 'TextContainerValue',
  ANNOTATION = 'annotation',
}

export enum InstanceSource {
  ACDM = 'acdm',
  FDM = 'fdm',
  SOLUTIONS = 'solutions',
}

export enum AcdmResourceType {
  TIMESERIES = 'timeseries',
  EVENT = 'event',
  ASSET = 'asset',
}

export enum TimeseriesDisplayMode {
  GRAPH = 'graph',
  VALUE = 'value',
}

export type TimeseriesAcdmInstance = {
  source: InstanceSource.ACDM;
  type: AcdmResourceType.TIMESERIES;
  id: number;
};

export type EventAcdmInstance = {
  source: InstanceSource.ACDM;
  type: AcdmResourceType.EVENT;
  id: number;
};

export type AssetAcdmInstance = {
  source: InstanceSource.ACDM;
  type: AcdmResourceType.ASSET;
  id: number;
};

export type AcdmInstance =
  | TimeseriesAcdmInstance
  | AssetAcdmInstance
  | EventAcdmInstance;

export type FdmInstance = {
  source: InstanceSource.FDM;
  instanceExternalId: string;
  instanceSpace: string;
  viewExternalId: string;
  viewSpace: string;
  viewVersion?: string;
};

export enum SolutionsResourceType {
  CHART = 'chart',
}

export type ChartInstance = {
  source: InstanceSource.SOLUTIONS;
  type: SolutionsResourceType.CHART;
  id: string;
};

export type SolutionsInstance = ChartInstance;

export type Instance = AcdmInstance | FdmInstance | SolutionsInstance;

export const isAssetAcdmInstance = (
  instance: Instance
): instance is AssetAcdmInstance =>
  instance.source === InstanceSource.ACDM &&
  instance.type === AcdmResourceType.ASSET;

export const isTimeseriesAcdmInstance = (
  instance: Instance
): instance is TimeseriesAcdmInstance =>
  instance.source === InstanceSource.ACDM &&
  instance.type === AcdmResourceType.TIMESERIES;

export const isEventAcdmInstance = (
  instance: Instance
): instance is EventAcdmInstance =>
  instance.source === InstanceSource.ACDM &&
  instance.type === AcdmResourceType.EVENT;

export const isFdmInstance = (instance: Instance): instance is FdmInstance =>
  instance.source === InstanceSource.FDM;

export const isChartsInstance = (
  instance: Instance
): instance is ChartInstance =>
  instance.source === InstanceSource.SOLUTIONS &&
  instance.type === SolutionsResourceType.CHART;

export type IdsByType = {
  containerIds: string[];
  annotationIds: string[];
};

export type Metadata<MetadataType> = MetadataType extends never
  ? {
      metadata?: never;
    }
  : {
      metadata: MetadataType;
    };

export type UnifiedViewerNode<NodeMetadata = any> =
  | Annotation<NodeMetadata>
  | ContainerConfig<NodeMetadata>;

export const isAnnotation = (node: UnifiedViewerNode): node is Annotation =>
  Object.values(AnnotationType).some((type) => node.type === type);

export const isContainerAnnotation = (node: UnifiedViewerNode): boolean =>
  isAnnotation(node) && isSingleDocumentAnnotation(node);

export const isContainerConfig = (
  node: UnifiedViewerNode
): node is ContainerConfig =>
  Object.values(ContainerType).some((type) => node.type === type);
