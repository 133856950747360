import { Heading } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 5px;
`;

export const Value = styled(Heading).attrs({ level: 6 })<{
  color: string;
}>`
  right: 0px;
  text-align: center;
  color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Line = styled.div<{ color: string }>`
  width: 20px;
  height: 2px;
  background-color: ${({ color }) => color};
`;
