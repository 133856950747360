export const BREAKPOINT_PAD_LANDSCAPE = 1180;
export const BREAKPOINT_PAD_PORTRAIT = 820;
export const BREAKPOINT_MOBILE_PORTRAIT = 390;
export const BREAKPOINT_SIDEBAR_COMPACT_CONTENT = 600;

export const breakPoints = {
  padLandscape: `${BREAKPOINT_PAD_LANDSCAPE}px`,
  padPortrait: `${BREAKPOINT_PAD_PORTRAIT}px`,
  mobilePortrait: `${BREAKPOINT_MOBILE_PORTRAIT}px`,
};

export type BreakPointsType = {
  padLandscape: string;
  padPortrait: string;
  mobilePortrait: string;
};
