import { Body } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const SearchWrapper = styled.div`
  .search-result-wrapper {
    overflow-y: auto;
    width: 100%;
  }
`;

export const StyledBody = styled(Body)`
  margin-bottom: 6px;
`;
