import {
  Button,
  ChevronDownIcon,
  CloseLargeIcon,
  Dropdown,
  Flex,
  Menu,
} from '@cognite/cogs.js-v10';
import { showGlobalOverlayAtom, useOnClickOutside } from '@infield/features/ui';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import type { Filter, FilterKey } from '../hooks';

import * as S from './elements';

type Props = {
  filterTitle: string;
  filterButtonText: string;
  filterOptions: { storedOption: string; shownOption: string }[];
  filterKey: FilterKey;
  filters: Filter;
  updateFilters: (filterKey: FilterKey, newValue: string) => void;
};

export const FilterDropdown: FC<Props> = ({
  filterTitle,
  filterButtonText,
  filterOptions,
  filterKey,
  filters,
  updateFilters,
}) => {
  const setShowOverlay = useSetRecoilState(showGlobalOverlayAtom);
  const isDesktop = useIsDesktop();
  const [isMenuShown, setIsMenuShown] = useState(false);

  const outsideClickRef = useRef(null);
  useOnClickOutside(outsideClickRef, () => setIsMenuShown(false));

  useEffect(() => {
    if (!isDesktop) {
      setShowOverlay(isMenuShown);
    } else {
      setShowOverlay(false);
    }
  }, [setShowOverlay, isDesktop, isMenuShown]);

  const FilterMenu = isMenuShown && (
    <S.StyledMenu $isDesktop={isDesktop}>
      <div ref={outsideClickRef}>
        <Flex gap={8} alignItems="center" justifyContent="space-between">
          <Menu.Header>{filterTitle}</Menu.Header>
          <Button
            data-testid="checklist-filter-close-button"
            aria-label="close-filter-dropdown"
            icon={<CloseLargeIcon />}
            type="ghost"
            onClick={() => setIsMenuShown(false)}
          />
        </Flex>
        {filterOptions.map(({ storedOption, shownOption }) => {
          return (
            <Menu.Item
              data-testid={`checklist-filter-status-menu-item-${storedOption}`}
              onClick={() => updateFilters(filterKey, storedOption)}
              key={storedOption}
              toggled={filters[filterKey].includes(storedOption)}
            >
              {shownOption}
            </Menu.Item>
          );
        })}
      </div>
    </S.StyledMenu>
  );

  const FilterButton = (
    <Button
      data-testid={`checklist-filtering-menu-button-${filterKey}`}
      type="secondary"
      icon={<ChevronDownIcon />}
      iconPlacement="right"
      toggled={filters[filterKey].length > 0}
      onClick={() => setIsMenuShown(!isMenuShown)}
    >
      {filterButtonText}
    </Button>
  );

  return isDesktop ? (
    <Dropdown
      visible={isMenuShown}
      content={FilterMenu}
      appendTo={document.body}
      hideOnSelect={{
        hideOnContentClick: false,
        hideOnOutsideClick: true,
      }}
    >
      {FilterButton}
    </Dropdown>
  ) : (
    <>
      {FilterMenu}
      {FilterButton}
    </>
  );
};
