import { AiProvider } from '@cognite/ai';
import { ToastContainer } from '@cognite/cogs-lab';
import { ConfigProvider } from '@cognite/cogs.js-v10';
import type { AuthStateUser } from '@cognite/e2e-auth';
import { AuthConsumer, AuthProvider } from '@cognite/e2e-auth';
import { FlagProvider } from '@cognite/react-feature-flags';
import type { CogniteClient } from '@cognite/sdk';
import { SDKProvider } from '@cognite/sdk-provider';
import { defaultAppConfig } from '@infield/features/app-config';
import { isProduction } from '@infield/features/auth';
import i18n from '@infield/features/i18n/i18n';
import { AccessProvider } from '@infield/providers/access-provider';
import { FDMServicesProvider } from '@infield/providers/fdm-services';
import { ThemeProviderExtended } from '@infield/providers/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { FC, PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import type { MutableSnapshot } from 'recoil';
import { RecoilRoot } from 'recoil';

import { AppConfigFiltersProvider } from './app-config-filters-provider';
import { BackButtonUrlProvider } from './back-button-url-provider';
import { ChameleonWrapper } from './chameleon-wrapper';
import { DayJSProvider } from './day-js-provider';
import { DeviceProvider } from './device-provider';
import { ErrorBoundaryProvider } from './error-boundary-provider/error-boundary-provider';
import { AppConfigProvider } from './is-idm-provider/app-config-provider';
import { NetworkStatusProvider } from './network-status-provider/network-status-provider';
import { PersistQueryFunctionsProvider } from './persist-query-functions-provider';
import { RevealContextProvider } from './reveal-context-provider/reveal-context-provider';
import { UserProvider } from './user-provider';

const exponentialBackoff = (attempts: number) =>
  Math.min(1000 * 2 ** attempts, 30000);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 3,
      retryDelay: exponentialBackoff,
    },
  },
});

//  PR previews in github pages are nested, so require the HashRouter.
const isPRPreview = window.location.host === 'cognitedata.github.io';
const Router = isPRPreview ? HashRouter : BrowserRouter;

const useProductionAd = isProduction();

type Props = {
  mock?: {
    client?: CogniteClient;
    user?: AuthStateUser;
  };
  recoilInitialState?: (mutableSnapshot: MutableSnapshot) => void;
};

export const AppProviders: FC<PropsWithChildren<Props>> = ({
  children,
  mock,
  recoilInitialState,
}) => {
  const fdmServiceProviderMock = mock
    ? {
        ...mock,
        appConfig: defaultAppConfig,
      }
    : undefined;
  const localAppInstanceSpace = process.env.INFIELD_LOCAL_APP_INSTANCE_SPACE;

  return (
    <I18nextProvider i18n={i18n}>
      <ErrorBoundaryProvider>
        <ThemeProviderExtended>
          <Router>
            <RecoilRoot initializeState={recoilInitialState}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />
                <AiProvider customQueryClient={queryClient}>
                  <AuthProvider
                    appId="INFIELD"
                    appName="Cognite InField"
                    mock={mock}
                    useProductionAadApp={useProductionAd}
                  >
                    <AuthConsumer>
                      {(auth) =>
                        auth && (
                          <FlagProvider
                            projectName={auth.client.project}
                            appName="InField"
                            apiToken="*:default.2b1d2c87022af6b1469024aa5dc3f95207caab17a67e06de55a1eab3"
                            proxyUrl="https://unleash-edge.cogniteapp.com/api/proxy"
                          >
                            <AppConfigProvider>
                              <DayJSProvider>
                                <ConfigProvider>
                                  <>
                                    <ToastContainer />
                                    <FDMServicesProvider
                                      mocks={fdmServiceProviderMock}
                                      localAppInstanceSpace={
                                        localAppInstanceSpace
                                      }
                                    >
                                      <DeviceProvider>
                                        <BackButtonUrlProvider>
                                          <NetworkStatusProvider>
                                            <UserProvider mocks={mock?.user}>
                                              <AppConfigFiltersProvider>
                                                <PersistQueryFunctionsProvider
                                                  client={queryClient}
                                                >
                                                  <ChameleonWrapper>
                                                    <SDKProvider
                                                      sdk={auth.client}
                                                    >
                                                      <RevealContextProvider
                                                        client={auth.client}
                                                      >
                                                        <AccessProvider>
                                                          {children}
                                                        </AccessProvider>
                                                      </RevealContextProvider>
                                                    </SDKProvider>
                                                  </ChameleonWrapper>
                                                </PersistQueryFunctionsProvider>
                                              </AppConfigFiltersProvider>
                                            </UserProvider>
                                          </NetworkStatusProvider>
                                        </BackButtonUrlProvider>
                                      </DeviceProvider>
                                    </FDMServicesProvider>
                                  </>
                                </ConfigProvider>
                              </DayJSProvider>
                            </AppConfigProvider>
                          </FlagProvider>
                        )
                      }
                    </AuthConsumer>
                  </AuthProvider>
                </AiProvider>
              </QueryClientProvider>
            </RecoilRoot>
          </Router>
        </ThemeProviderExtended>
      </ErrorBoundaryProvider>
    </I18nextProvider>
  );
};
