import type { ObservationConfig } from '@cognite/apm-client/src/observation-fields-config/types';
import type {
  ObservationConfigDropdownProperty,
  ObservationConfigFieldProperty,
  ObservationsConfig,
} from '@infield/features/app-config/types';
import { v4 as uuid } from 'uuid';

export const defaultObservationFieldsConfig = {
  files: {
    displayTitle: 'Upload an image',
    displayDescription: 'Upload the image from the device',
    isRequired: false,
  },
  description: {
    displayTitle: 'Short description',
    displayDescription: 'Describe the observation',
    isRequired: true,
  },
  asset: {
    displayTitle: 'Asset',
    displayDescription: 'Select or search for an asset',
    isRequired: true,
  },
  troubleshooting: {
    displayTitle: 'How did you troubleshoot?',
    displayDescription: 'Describe troubleshooting steps',
    isRequired: true,
  },
  priority: {
    displayTitle: 'Priority',
    displayDescription: 'Select one of the priorities',
    isRequired: true,
    options: [
      {
        id: 'default_value_1',
        value: '1',
        label: '1: Immediate',
      },
      {
        id: 'default_value_2',
        value: '2',
        label: '2: Within 2 weeks',
      },
      {
        id: 'default_value_3',
        value: '3',
        label: '3: Within 2 to 4 weeks',
      },
      {
        id: 'default_value_4',
        value: '4',
        label: '4: Within 4 to 8 weeks',
      },
      {
        id: 'default_value_5',
        value: '5',
        label: '5: Greater than 8 weeks',
      },
      {
        id: 'default_value_6',
        value: '6',
        label: '6: TA/SD/PPM High',
      },
      {
        id: 'default_value_7',
        value: '7',
        label: '7: TA/SD/PPM Medium',
      },
      {
        id: 'default_value_8',
        value: '8',
        label: '8: TA/SD/PPM Low',
      },
    ],
  },
  type: {
    displayTitle: 'Type',
    displayDescription: 'Select observation type',
    isRequired: true,
    options: [
      {
        id: 'default_value_1',
        value: 'M1',
        label: 'M1: Non-Process Related',
      },
      {
        id: 'default_value_2',
        value: 'M2',
        label: 'M2: Process Related',
      },
      {
        id: 'default_value_3',
        value: 'M4',
        label: 'M4: Safety/Regulatory',
      },
      {
        id: 'default_value_4',
        value: 'M5',
        label: 'M5: Non-ERV/Modific/Proj',
      },
      {
        id: 'default_value_5',
        value: 'M8',
        label: 'M8: PM03 Corrective Repair',
      },
      {
        id: 'default_value_6',
        value: 'MP',
        label: 'MP: Predictive Maintenance',
      },
    ],
  },
};

export const getDefaultObservationsConfig = (
  relevantAssetId: string
): ObservationConfig => {
  return {
    externalId: uuid(),
    rootLocationExternalIds: [relevantAssetId || ''],
    fieldConfigurations: defaultObservationFieldsConfig,
  };
};

export const defaultObservationFields: (keyof ObservationsConfig)[] = [
  'files',
  'description',
  'asset',
  'priority',
  'type',
  'troubleshooting',
];

export const checkIfFieldSet = (value: string) =>
  Boolean(value) && value.trim() !== '';

export const isDropdownProperty = (
  field: ObservationConfigFieldProperty | ObservationConfigDropdownProperty
): field is ObservationConfigDropdownProperty => {
  return (field as ObservationConfigDropdownProperty).options !== undefined;
};
