// This matches OpenJDK's String#hashCode implementation, from here:
// http://hg.openjdk.java.net/jdk7u/jdk7u6/jdk/file/8c2c5d63a17e/src/share/classes/java/lang/String.java#l1445
const hashCode = (a: string) =>
  a
    .split('')
    .map((c) => c.charCodeAt(0))
    // eslint-disable-next-line no-bitwise
    .reduce((hash, char) => (31 * hash + char) | 0, 0);

export default hashCode;
