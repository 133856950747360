import {
  SortAscendingIcon,
  SortAscendingLettersIcon,
  SortDescendingLettersIcon,
} from '@cognite/cogs.js-v10';

import type { SortType } from './types';

export const getSortIcon = (sortType: SortType) => {
  if (sortType === 'orderCreated') {
    return <SortAscendingIcon />;
  }
  if (sortType === 'alphabetical') {
    return <SortAscendingLettersIcon />;
  }
  if (sortType === 'reverseAlphabetical') {
    return <SortDescendingLettersIcon />;
  }
};
