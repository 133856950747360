import {
  Button,
  CloseLargeIcon,
  EllipsisVerticalIcon,
  Flex,
  Menu,
  Tooltip,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import * as S from './elements';
import type { ActionType } from './types';
import { HALF_WIDTH, LOWEST_WIDTH, MAXIMUM_WIDTH } from './utils';

interface Props {
  visible?: boolean;
  subTitle?: string;
  actions: ActionType[];
  onClose: () => void;
  onActionClick: (key: string) => void;
}

export const ActionMenu: FC<Props> = ({
  visible,
  subTitle,
  actions,
  onClose,
  onActionClick,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(ref.current?.clientWidth || LOWEST_WIDTH);

  useEffect(() => {
    setWidth(ref.current?.clientWidth || LOWEST_WIDTH);
  }, [ref.current?.clientWidth]);

  const actionBar = [];
  const actionMenu = [];
  if (width >= MAXIMUM_WIDTH) {
    actionBar.push(...actions);
  } else if (width >= HALF_WIDTH) {
    actionBar.push(...actions.slice(0, 4));
    actionMenu.push(...actions.slice(4));
  } else if (width >= LOWEST_WIDTH) {
    actionBar.push(...actions.slice(0, 2));
    actionMenu.push(...actions.slice(2));
  } else {
    actionMenu.push(...actions);
  }

  if (!visible) return null;

  return (
    <S.Wrapper ref={ref}>
      <S.Container>
        <Flex direction="column">
          <S.Title>{t('TEMPLATE_ACTION_MENU_SELECTED', 'Selected')}</S.Title>
          <S.SubTitle>{subTitle}</S.SubTitle>
        </Flex>
        <Flex>
          <Flex gap={8}>
            {actionBar.map((action) => (
              <Tooltip
                key={action.key}
                content={action.tooltip}
                disabled={!action.tooltip}
              >
                <Button
                  data-testid={action.dataTestId}
                  icon={action.icon}
                  type={action.type}
                  inverted
                  disabled={action.disabled}
                  loading={action.loading}
                  hidden={action.hidden}
                  onClick={() => onActionClick(action.key)}
                >
                  {action.name}
                </Button>
              </Tooltip>
            ))}
          </Flex>

          <S.Divider direction="vertical" spacing="16px" inverted />
          {showMenu && actionMenu.length > 0 && (
            <S.SubActionMenu>
              {actionMenu?.map((action) => (
                <Tooltip
                  key={action.key}
                  content={action.tooltip}
                  disabled={!action.disabled}
                >
                  <Menu.Item
                    icon={action.icon}
                    disabled={action.disabled}
                    destructive={action.key === 'Delete'}
                    onClick={() => {
                      onActionClick(action.key);
                      setShowMenu(false);
                    }}
                  >
                    {action.name}
                  </Menu.Item>
                </Tooltip>
              ))}
            </S.SubActionMenu>
          )}
          {actionMenu.length > 0 && (
            <Button
              icon={<EllipsisVerticalIcon />}
              type="ghost"
              inverted
              aria-label="show-action-menu"
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            />
          )}
          <Button
            aria-label="close-action-menu"
            data-testid="close-action-menu"
            icon={<CloseLargeIcon />}
            type="ghost"
            inverted
            onClick={onClose}
          />
        </Flex>
      </S.Container>
    </S.Wrapper>
  );
};
