import { uniq } from 'lodash-es';

import { AxisRange } from '../../../types';

import { AxisUpdate, PlotlyEventData, SeriesDataCollection } from './types';

const findNumberInString = (str: string): number | undefined => {
  const match = str.match(/\d+/);
  if (match === null) {
    return undefined;
  }
  return +match[0];
};

export const mapAxisNameToIndex = (axisName: string): number => {
  const axisNumber = findNumberInString(axisName) ?? 1;
  return axisNumber - 1;
};

const getSeriesDataByAxisName = (
  seriesDataCollection: SeriesDataCollection[],
  axisName: string
): SeriesDataCollection | undefined => {
  const axisIndex = mapAxisNameToIndex(axisName);
  return seriesDataCollection[axisIndex];
};

const getRangeByAxisName = (
  eventData: PlotlyEventData,
  axisName: string,
  existingRange: AxisRange
): AxisRange => {
  const lowerBound = eventData[`${axisName}.range[0]`];
  const upperBound = eventData[`${axisName}.range[1]`];

  if (eventData[`${axisName}.autorange`]) {
    return [null, null];
  }

  if (lowerBound === undefined && upperBound === undefined) {
    return existingRange;
  }

  if (
    (lowerBound === undefined || upperBound === undefined) &&
    existingRange.length < 2
  ) {
    /**
     * This should probably not happen, but for instance, if we only get the upper bound
     * and we don't have an existing range, then how do we merge that into the second element?
     */
    console.warn(
      `Existing range was not defined, but lower or upper bound was. Returning existing range.
      Existing range: ${existingRange}, lower bound: ${lowerBound}, upper bound: ${upperBound}
      This is likely a bug. Please investigate.
      `
    );
    return existingRange;
  }

  return [lowerBound ?? existingRange[0], upperBound ?? existingRange[1]];
};

const getYAxisNameByEventDataKey = (key: string): string => {
  if (!key.startsWith('yaxis')) {
    throw new Error('Expected key to start with yaxis. Got: ' + key);
  }
  return key.split('.')[0];
};

export function getYAxisUpdatesFromEventData(
  seriesDataCollection: SeriesDataCollection[],
  eventData: PlotlyEventData
): AxisUpdate[] {
  const yAxisNames = uniq(
    Object.keys(eventData)
      .filter((key) => key.includes('yaxis'))
      .map(getYAxisNameByEventDataKey)
  );

  return yAxisNames.flatMap((axisName) => {
    const selectedSeriesData = getSeriesDataByAxisName(
      seriesDataCollection,
      axisName
    );

    if (selectedSeriesData === undefined) {
      return [];
    }

    const nextRange = getRangeByAxisName(
      eventData,
      axisName,
      selectedSeriesData.range ?? ([null, null] as AxisRange) // TODO: This is a smell - it should be defined at initialisation
    );
    return selectedSeriesData.series.map(({ id, type }) => ({
      id,
      type,
      range: nextRange,
    }));
  });
}
