import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ObservationDesktopContainer = styled.div`
  width: 712px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 40px;
  padding-bottom: 40px;
  align-self: center;
  gap: 16px;
  padding-left: 4px;
  padding-right: 4px;
`;

export const ObservationStepWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  width: 100%;
`;
