import { ComponentProps, ComponentPropsWithRef } from 'react';

import styled from 'styled-components';

import { Button as DefaultButton, LogoAiIcon } from '@cognite/cogs.js-v10';

import { useTranslation } from '../../hooks';

import { ButtonAi } from './ButtonAi';

export const Button = (props: ComponentProps<typeof DefaultButton>) => {
  return <DefaultButton {...props} />;
};

export const ButtonAskQuestion = (
  props: ComponentPropsWithRef<typeof DefaultButton>
) => {
  const { t } = useTranslation();
  return (
    <StyledButton
      icon={<LogoAiIcon />}
      themeProps={{ theme: 'ai', inverted: false }}
      {...props}
    >
      {t('AI_ASK_A_QUESTION')}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  white-space: nowrap;
  &&& {
    background: rgba(111, 59, 228, 0.08);
    color: rgba(96, 42, 207, 1);
  }

  &&&:hover {
    background: rgba(111, 59, 228, 0.1);
    color: rgba(96, 42, 207, 1);
  }
`;

Button.Ai = ButtonAi;
Button.AskQuestion = ButtonAskQuestion;
