import { FontSize } from '../annotations/types';

import isFontSizeInPixelUnits from './isFontSizeInPixelUnits';

const getFontSizeValue = (fontSize: FontSize): number => {
  if (!isFontSizeInPixelUnits(fontSize)) {
    throw new TypeError(
      `Provided font size '${fontSize}' is not in pixel units`
    );
  }

  const value = parseFloat(fontSize.slice(0, -2));
  if (Number.isNaN(value)) {
    throw new TypeError(
      `'${fontSize}' does not contain a numeric font size value`
    );
  }
  if (value < 0) {
    throw new TypeError(
      `Font size '${fontSize}' must have a positive size value`
    );
  }
  return value;
};

export default getFontSizeValue;
