const setStyles = (id: string, styles: string): void => {
  const styleElement =
    document.getElementById(id) ?? document.createElement('style');
  styleElement.setAttribute('type', 'text/css');
  styleElement.id = id;
  styleElement.textContent = styles;
  document.head.appendChild(styleElement);
};

export default setStyles;
