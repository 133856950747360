import type { FC } from 'react';

import * as S from './elements';

type Props = {
  mediaSrc: string;
  mediaName?: string;
  isFullScreen?: boolean;
};

export const ImageItem: FC<Props> = ({ mediaSrc, mediaName, isFullScreen }) => {
  if (isFullScreen) {
    return <S.FullScreenImage alt={mediaName} src={mediaSrc} />;
  }

  return <S.ImageItem alt={mediaName} src={mediaSrc} />;
};
