import type { Asset } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useRootAssetList = ({ enabled }: { enabled: boolean }) => {
  const { assetService } = useFDMServices();

  return useQuery<Asset[]>(
    [QueryKeys.ASSET, 'root list'],
    async () => {
      return assetService.getRootAssets();
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: `${QueryKeys.ASSET} root list`,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled,
    }
  );
};
