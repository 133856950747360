import { getDirectRelationship, VIEW_VERSIONS } from '@cognite/apm-client';
import type {
  Action,
  APMClient,
  Condition,
  ConditionalAction,
  ConditionalActionsResponseType,
  ConditionalActionsReturnType,
  ConditionalActionUpsertType,
  InFieldUser,
} from '@cognite/apm-client';
import { FDMClient } from '@cognite/fdm-client';
import type { CogniteClient } from '@cognite/sdk';

export class ConditionalActionsService extends FDMClient {
  apmClient: APMClient;
  modelSpace: string;
  instanceSpace: string;
  BASE_URL: string;

  constructor(client: CogniteClient, apmClient: APMClient) {
    super(client);
    this.apmClient = apmClient;
    this.modelSpace = apmClient.appDataModelSpace;
    this.instanceSpace = apmClient.appDataInstanceSpace;
    this.BASE_URL = `${this.cogniteClient.getBaseUrl()}/api/v1/projects/${
      this.cogniteClient.project
    }`;
  }

  async createConditionalActions(
    conditionalActions: ConditionalAction[],
    user: InFieldUser
  ) {
    const upsertedConditionalActions: ConditionalAction[] =
      conditionalActions.map((conditionalAction) => ({
        ...conditionalAction,
        createdBy: user,
      }));
    return this.upsertConditionalActions(upsertedConditionalActions).then(
      (result) => result.data.items[0]
    );
  }

  async updateConditionalActions(
    conditionalActions: ConditionalAction[],
    user: InFieldUser
  ) {
    const upsertedConditionalActions: ConditionalAction[] =
      conditionalActions.map((conditionalAction) => ({
        ...conditionalAction,
        updatedBy: user,
      }));

    return this.upsertConditionalActions(upsertedConditionalActions);
  }

  async upsertConditionalActions(conditionalActions: ConditionalAction[]) {
    const upsertedConditionalActions: ConditionalActionUpsertType[] =
      conditionalActions.map((conditionalAction) => {
        const parentObjectRelationship = getDirectRelationship(
          conditionalAction.parentObject
        );
        const createdByRelationship = getDirectRelationship({
          externalId: conditionalAction.createdBy?.externalId,
          space: this.apmClient.userInstanceSpace,
        });
        const updatedByRelationship = getDirectRelationship({
          externalId: conditionalAction.updatedBy?.externalId,
          space: this.apmClient.userInstanceSpace,
        });

        return {
          ...conditionalAction,
          parentObject: parentObjectRelationship,
          createdBy: createdByRelationship,
          updatedBy: updatedByRelationship,
        };
      });
    return this.apmClient.conditionalActions.upsert(upsertedConditionalActions);
  }

  async deleteConditionalActions(externalIds: string[]) {
    return this.apmClient.conditionalActions.delete(externalIds);
  }

  async getConditionalActionsByParentObject(
    externalIds: string[],
    parentObjectView: 'TemplateItem' | 'ChecklistItem',
    limit = 1500
  ) {
    const data = {
      with: {
        parentObjectItems: {
          limit,
          nodes: {
            filter: {
              and: [
                {
                  equals: {
                    property: ['node', 'space'],
                    value: this.instanceSpace,
                  },
                },
                {
                  in: {
                    property: ['node', 'externalId'],
                    values: externalIds,
                  },
                },
              ],
            },
          },
        },
        conditionalActions: {
          limit,
          nodes: {
            from: 'parentObjectItems',
            direction: 'inwards',
            through: {
              view: {
                type: 'view',
                space: this.modelSpace,
                externalId: 'ConditionalAction',
                version: VIEW_VERSIONS.CONDITIONAL_ACTION,
              },
              identifier: 'parentObject',
            },
          },
        },
        conditions: {
          limit,
          nodes: {
            from: 'conditionalActions',
            direction: 'inwards',
            through: {
              view: {
                type: 'view',
                space: this.modelSpace,
                externalId: 'Condition',
                version: VIEW_VERSIONS.CONDITION,
              },
              identifier: 'conditionalAction',
            },
          },
        },
        actions: {
          limit,
          nodes: {
            from: 'conditionalActions',
            direction: 'inwards',
            through: {
              view: {
                type: 'view',
                space: this.modelSpace,
                externalId: 'Action',
                version: VIEW_VERSIONS.ACTION,
              },
              identifier: 'conditionalActions',
            },
          },
        },
      },
      select: {
        parentObjectItems: {
          sources: [
            {
              source: {
                type: 'view',
                space: this.modelSpace,
                externalId: parentObjectView,
                version:
                  parentObjectView === 'TemplateItem'
                    ? VIEW_VERSIONS.TEMPLATE_ITEM
                    : VIEW_VERSIONS.CHECKLIST_ITEM,
              },
              properties: ['*'],
            },
          ],
        },
        conditionalActions: {
          sources: [
            {
              source: {
                type: 'view',
                space: this.modelSpace,
                externalId: 'ConditionalAction',
                version: VIEW_VERSIONS.CONDITIONAL_ACTION,
              },
              properties: ['*'],
            },
          ],
        },
        conditions: {
          sources: [
            {
              source: {
                type: 'view',
                space: this.modelSpace,
                externalId: 'Condition',
                version: VIEW_VERSIONS.CONDITION,
              },
              properties: ['*'],
            },
          ],
        },
        actions: {
          sources: [
            {
              source: {
                type: 'view',
                space: this.modelSpace,
                externalId: 'Action',
                version: VIEW_VERSIONS.ACTION,
              },
              properties: ['*'],
            },
          ],
        },
      },
    };

    return this.cogniteClient
      .post<{ items: ConditionalActionsResponseType }>(
        `${this.BASE_URL}/models/instances/query`,
        {
          data,
          withCredentials: true,
        }
      )
      .then((result) => {
        const data: ConditionalActionsResponseType = result.data.items;

        // Return only properties
        const conditionalActions: ConditionalAction[] =
          data.conditionalActions?.map((conditionalAction) => {
            return {
              ...conditionalAction.properties[this.modelSpace][
                `ConditionalAction/${VIEW_VERSIONS.CONDITIONAL_ACTION}`
              ],
              externalId: conditionalAction.externalId,
            };
          });
        const conditions: Condition[] = data.conditions?.map((condition) => {
          return {
            ...condition.properties[this.modelSpace][
              `Condition/${VIEW_VERSIONS.CONDITION}`
            ],
            externalId: condition.externalId,
          };
        });
        const actions: Action[] = data.actions?.map((action) => {
          return {
            ...action.properties[this.modelSpace][
              `Action/${VIEW_VERSIONS.ACTION}`
            ],
            externalId: action.externalId,
          };
        });

        // Map conditions and actions to conditional actions
        const conditionalActionsMapping: ConditionalAction[] =
          conditionalActions.map((conditionalAction) => {
            return {
              ...conditionalAction,
              conditions: conditions.filter(
                (condition) =>
                  condition.conditionalAction?.externalId ===
                  conditionalAction.externalId
              ),
              actions: actions.filter(
                (action) =>
                  action.conditionalActions?.externalId ===
                  conditionalAction.externalId
              ),
            };
          });

        return {
          conditionalActions: conditionalActionsMapping,
          conditions,
          actions,
        } as ConditionalActionsReturnType;
      });
  }

  async getConditionalActions(externalIds: string[], limit = 1500) {
    const data = {
      with: {
        conditionalActions: {
          limit,
          nodes: {
            filter: {
              and: [
                {
                  equals: {
                    property: ['node', 'space'],
                    value: this.instanceSpace,
                  },
                },
                {
                  in: {
                    property: ['node', 'externalId'],
                    values: externalIds,
                  },
                },
              ],
            },
          },
        },
        conditions: {
          limit,
          nodes: {
            from: 'conditionalActions',
            direction: 'inwards',
            through: {
              view: {
                type: 'view',
                space: this.modelSpace,
                externalId: 'Condition',
                version: VIEW_VERSIONS.CONDITION,
              },
              identifier: 'conditionalAction',
            },
          },
        },
        actions: {
          limit,
          nodes: {
            from: 'conditionalActions',
            direction: 'inwards',
            through: {
              view: {
                type: 'view',
                space: this.modelSpace,
                externalId: 'Action',
                version: VIEW_VERSIONS.ACTION,
              },
              identifier: 'conditionalActions',
            },
          },
        },
      },
      select: {
        conditionalActions: {
          sources: [
            {
              source: {
                type: 'view',
                space: this.modelSpace,
                externalId: 'ConditionalAction',
                version: VIEW_VERSIONS.CONDITIONAL_ACTION,
              },
              properties: ['*'],
            },
          ],
        },
        conditions: {
          sources: [
            {
              source: {
                type: 'view',
                space: this.modelSpace,
                externalId: 'Condition',
                version: VIEW_VERSIONS.CONDITION,
              },
              properties: ['*'],
            },
          ],
        },
        actions: {
          sources: [
            {
              source: {
                type: 'view',
                space: this.modelSpace,
                externalId: 'Action',
                version: VIEW_VERSIONS.ACTION,
              },
              properties: ['*'],
            },
          ],
        },
      },
    };

    return this.cogniteClient
      .post<{ items: ConditionalActionsResponseType }>(
        `${this.BASE_URL}/models/instances/query`,
        {
          data,
          withCredentials: true,
        }
      )
      .then((result) => {
        const data: ConditionalActionsResponseType = result.data.items;

        // Return only properties
        const conditionalActions: ConditionalAction[] =
          data.conditionalActions?.map((conditionalAction) => {
            return {
              ...conditionalAction.properties[this.modelSpace][
                `ConditionalAction/${VIEW_VERSIONS.CONDITIONAL_ACTION}`
              ],
              externalId: conditionalAction.externalId,
            };
          });
        const conditions: Condition[] = data.conditions?.map((condition) => {
          return {
            ...condition.properties[this.modelSpace][
              `Condition/${VIEW_VERSIONS.CONDITION}`
            ],
            externalId: condition.externalId,
          };
        });
        const actions: Action[] = data.actions?.map((action) => {
          return {
            ...action.properties[this.modelSpace][
              `Action/${VIEW_VERSIONS.ACTION}`
            ],
            externalId: action.externalId,
          };
        });

        // Map conditions and actions to conditional actions
        const conditionalActionsMapping: ConditionalAction[] =
          conditionalActions.map((conditionalAction) => {
            return {
              ...conditionalAction,
              conditions: conditions.filter(
                (condition) =>
                  condition.conditionalAction?.externalId ===
                  conditionalAction.externalId
              ),
              actions: actions.filter(
                (action) =>
                  action.conditionalActions?.externalId ===
                  conditionalAction.externalId
              ),
            };
          });

        return {
          conditionalActions: conditionalActionsMapping,
          conditions,
          actions,
        } as ConditionalActionsReturnType;
      });
  }
}
