import type {
  Action,
  Checklist,
  ChecklistItem,
  Condition,
  ConditionalAction,
  Measurement,
  TaskInterval,
} from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import type { Edge, Filters } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { useSelectedRootAPMAsset } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserQuery } from '@infield/features/user';
import { useFDMServices } from '@infield/providers/fdm-services';
import { dmsItemIngestionLimit } from '@infield/utils/dms-requests';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import { v4 as uuid } from 'uuid';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

interface Props {
  templateExternalId: string;
  plannedStartTime?: string;
  plannedEndTime?: string;
  selectedTemplateItems?: string[];
  assignedTo?: string[];
}

type MutationContext = {
  sliTimerStartTime: number;
};

export const useTemplateCreateChecklist = () => {
  const {
    apmClient,
    checklistService,
    templateService,
    measurementsService,
    conditionalActionsService,
    conditionsService,
    actionsService,
  } = useFDMServices();
  const queryClient = useQueryClient();
  const { data: user } = useCurrentUserQuery();
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const { data: rootAPMAsset } = useSelectedRootAPMAsset();
  const { template: configFilters } = useAppConfigFiltersContext();
  const checklistAuditMetrics = useMetrics(METRICS_NAMESPACES.auditChecklist);
  const checklistItemAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditChecklistItem
  );
  const measurementAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditMeasurement
  );
  const conditionalActionsAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditConditionalAction
  );
  const conditionAuditMetrics = useMetrics(METRICS_NAMESPACES.auditCondition);
  const actionAuditMetrics = useMetrics(METRICS_NAMESPACES.auditAction);
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  return useMutation<
    {
      createdChecklist: Checklist;
      createdChecklistItems: ChecklistItem[];
      createdMeasurements: Measurement[];
      createdConditionalActions: ConditionalAction[];
      createdConditions: Condition[];
      createdActions: Action[];
      schedules: TaskInterval[];
    },
    Error,
    Props,
    MutationContext
  >(
    async ({
      templateExternalId,
      plannedStartTime,
      plannedEndTime,
      selectedTemplateItems,
      assignedTo,
    }) => {
      const templateFilter: Filters[] = [];

      if (configFilters.rootAssetExternalIds) {
        templateFilter.push(configFilters.rootAssetExternalIds);
      }

      const template = await templateService.getTemplateData({
        and: [
          ...templateFilter,
          {
            equals: {
              property: 'externalId',
              eq: templateExternalId,
            },
          },
        ],
      });
      const { templateItems = [] } = template;

      const templateItemsWithSchedules = templateItems.filter(
        (templateItem) => templateItem.schedules
      );

      const templateItemExternalIds =
        selectedTemplateItems ||
        templateItems.map((templateItem) => templateItem.externalId);

      const conditionsData =
        templateItemExternalIds.length > 0
          ? await conditionalActionsService.getConditionalActionsByParentObject(
              templateItemExternalIds,
              'TemplateItem'
            )
          : undefined;

      if (templateItemExternalIds.length === 0)
        throw new Error(
          t(
            'CREATE_CHECKLIST_FROM_TEMPLATE_ERROR_NO_TASKS',
            'Checklist cannot be created from a template without tasks'
          )
        );

      const newChecklist: Checklist = {
        externalId: uuid(),
        sourceId: template.externalId,
        title: template.title,
        type: 'Round',
        status: 'Ready',
        startTime: plannedStartTime,
        endTime: plannedEndTime,
        rootLocation: rootAPMAsset || undefined,
        assignedTo: assignedTo || template.assignedTo,
      };

      const templateItemsToCreate = selectedTemplateItems
        ? templateItems.filter((templateItem) =>
            selectedTemplateItems.includes(templateItem.externalId)
          )
        : templateItems;

      const newChecklistItems: ChecklistItem[] = templateItemsToCreate.map(
        (templateItem) => ({
          externalId: uuid(),
          sourceId: templateItem.externalId,
          title: templateItem.title,
          description: templateItem.description,
          labels: templateItem.labels,
          order: templateItem.order,
          startTime: plannedStartTime,
          endTime: plannedEndTime,
          asset: templateItem.asset,
        })
      );

      const newChecklistItemEdges: Edge[] = newChecklistItems.map(
        (newChecklistItem) => ({
          externalId: `${newChecklist.externalId}_${newChecklistItem.externalId}_relation`,
          modelName: 'referenceChecklistItems',
          startNode: newChecklist.externalId,
          endNode: newChecklistItem.externalId,
        })
      );

      const { newMeasurements, newMeasurementEdges } = templateItems.reduce(
        (acc, templateItem) => {
          const checklistItemExternalId = newChecklistItems.find(
            (checklistItem) =>
              checklistItem.sourceId === templateItem.externalId
          )?.externalId;

          if (!checklistItemExternalId) {
            throw new Error();
          }

          templateItem.measurements?.forEach((measurement) => {
            const newMeasurementExternalId = uuid();
            acc.newMeasurementEdges.push({
              externalId: `${checklistItemExternalId}_${newMeasurementExternalId}_relation`,
              modelName: 'referenceMeasurements',
              startNode: checklistItemExternalId,
              endNode: newMeasurementExternalId,
            });

            acc.newMeasurements.push({
              ...measurement,
              externalId: newMeasurementExternalId,
            });
          });

          return acc;
        },
        {
          newMeasurements: [] as Measurement[],
          newMeasurementEdges: [] as Edge[],
        }
      );

      const oldToNewChecklistItemExternalIds = newChecklistItems.reduce(
        (acc, { externalId, sourceId }) => {
          if (sourceId !== undefined) {
            acc[sourceId] = externalId;
          }
          return acc;
        },
        {} as Record<string, string>
      );

      const { newConditionalActions, mapOldToNewConditionalActionExternalId } =
        (conditionsData?.conditionalActions ?? []).reduce(
          (acc, curr) => {
            const {
              conditions: _conditions,
              actions: _actions,
              ...currConditionalAction
            } = curr;

            if (currConditionalAction.parentObject?.externalId === undefined) {
              throw new Error(
                'Conditional action does not have a parent object'
              );
            }

            const newConditionalAction: ConditionalAction = {
              ...currConditionalAction,
              externalId: uuid(),
              parentObject: {
                externalId:
                  oldToNewChecklistItemExternalIds[
                    currConditionalAction.parentObject.externalId
                  ],
                space: apmClient.appDataInstanceSpace,
              },
            };

            return {
              newConditionalActions: [
                ...acc.newConditionalActions,
                newConditionalAction,
              ],
              mapOldToNewConditionalActionExternalId: {
                ...acc.mapOldToNewConditionalActionExternalId,
                [currConditionalAction.externalId]:
                  newConditionalAction.externalId,
              },
            };
          },
          {
            newConditionalActions: [] as ConditionalAction[],
            mapOldToNewConditionalActionExternalId: {} as Record<
              string,
              string
            >,
          }
        );

      const newConditions = (conditionsData?.conditions ?? []).map(
        (condition) => {
          if (condition.conditionalAction === undefined) {
            throw new Error('Condition does not have a conditional action');
          }
          if (condition.source === undefined) {
            throw new Error('Condition does not have a source');
          }
          const newCondition: Condition = {
            ...condition,
            externalId: uuid(),
            conditionalAction: {
              externalId:
                mapOldToNewConditionalActionExternalId[
                  condition.conditionalAction.externalId
                ],
              space: apmClient.appDataInstanceSpace,
            },
            source: {
              externalId:
                oldToNewChecklistItemExternalIds[condition.source.externalId],
              space: apmClient.appDataInstanceSpace,
            },
          };
          return newCondition;
        }
      );

      const newActions = (conditionsData?.actions ?? []).map((action) => {
        if (action.conditionalActions === undefined) {
          throw new Error('Action does not have a conditional action');
        }

        const newAction: Action = {
          ...action,
          externalId: uuid(),
          // target not in use yet
          conditionalActions: {
            externalId:
              mapOldToNewConditionalActionExternalId[
                action.conditionalActions.externalId
              ],
            space: apmClient.appDataInstanceSpace,
          },
        };
        return newAction;
      });

      const checklistItemsWithConditions = newChecklistItems.filter(
        (checklistItem) =>
          newConditionalActions.find(
            (conditionalAction) =>
              conditionalAction.parentObject?.externalId ===
              checklistItem.externalId
          )
      );

      const templateItemsWithSchedulesAndConditions =
        templateItemsWithSchedules.filter((templateItem) =>
          checklistItemsWithConditions.find(
            (checklistItem) =>
              checklistItem.sourceId === templateItem.externalId
          )
        );

      const schedules = templateItemsWithSchedulesAndConditions.flatMap(
        (templateItem) => templateItem.schedules || []
      );

      const batchedChecklistItems = chunk(
        newChecklistItems,
        dmsItemIngestionLimit
      );
      const batchedChecklistItemEdges = chunk(
        newChecklistItemEdges.concat(newMeasurementEdges),
        dmsItemIngestionLimit
      );
      const batchedMeasurements = chunk(newMeasurements, dmsItemIngestionLimit);

      const batchedConditionalActions = chunk(
        newConditionalActions,
        dmsItemIngestionLimit
      );

      const batchedConditions = chunk(newConditions, dmsItemIngestionLimit);
      const batchedActions = chunk(newActions, dmsItemIngestionLimit);

      try {
        await checklistService.createChecklist(newChecklist, user!);
        await Promise.all(
          batchedChecklistItems.map((items) =>
            checklistService.createChecklistItems(items, user!)
          )
        );
        if (newMeasurements.length > 0) {
          await Promise.all(
            batchedMeasurements.map((measurements) =>
              measurementsService.createMeasurements(measurements, user!)
            )
          );
        }

        await Promise.all(
          batchedChecklistItemEdges.map((edges) =>
            apmClient.edgeService
              .upsert(edges)
              .then((result) => result.data.items)
          )
        );

        if (newConditionalActions.length > 0) {
          await Promise.all(
            batchedConditionalActions.map((condidionalActions) =>
              conditionalActionsService.createConditionalActions(
                condidionalActions,
                user!
              )
            )
          );
        }

        if (newConditions.length > 0) {
          await Promise.all(
            batchedConditions.map((conditions) =>
              conditionsService.createConditions(
                conditions,
                user!,
                'ChecklistItem'
              )
            )
          );
        }

        if (newActions.length > 0) {
          await Promise.all(
            batchedActions.map((actions) =>
              actionsService.createActions(actions, user!)
            )
          );
        }

        return {
          createdChecklist: newChecklist,
          createdChecklistItems: newChecklistItems,
          createdMeasurements: newMeasurements,
          createdConditionalActions: newConditionalActions,
          createdConditions: newConditions,
          createdActions: newActions,
          schedules,
        };
      } catch (error) {
        if (newActions.length > 0) {
          await actionsService.deleteActions(
            newActions.map((action) => action.externalId)
          );
        }

        if (newConditions.length > 0) {
          await conditionsService.deleteConditions(
            newConditions.map((condition) => condition.externalId)
          );
        }

        if (newConditionalActions.length > 0) {
          await conditionalActionsService.deleteConditionalActions(
            newConditionalActions.map(
              (conditionalAction) => conditionalAction.externalId
            )
          );
        }

        if (newMeasurements.length > 0) {
          await Promise.all(
            batchedMeasurements.map((measurements) =>
              measurementsService.deleteMeasurements(
                measurements.map((measurement) => measurement.externalId)
              )
            )
          );
        }
        await Promise.all(
          batchedChecklistItems.map((items) =>
            checklistService.deleteChecklistItems(
              items.map((item) => item.externalId)
            )
          )
        );
        await checklistService.deleteChecklist([newChecklist.externalId]);

        throw error;
      }
    },
    {
      onMutate: async () => {
        const sliTimerStartTime = Date.now();

        return { sliTimerStartTime };
      },
      onSuccess: async (data, _, context) => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.CHECKLIST_CREATE_FROM_TEMPLATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });

        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.CHECKLISTS_LIST],
        });

        checklistAuditMetrics.track('Create', data.createdChecklist);
        data.createdChecklistItems.forEach((checklistItem) => {
          checklistItemAuditMetrics.track('Create', checklistItem);
        });
        data.createdMeasurements.forEach((measurement) => {
          measurementAuditMetrics.track('Create', measurement);
        });
        data.createdConditionalActions.forEach((conditionalAction) => {
          conditionalActionsAuditMetrics.track('Create', conditionalAction);
        });
        data.createdConditions.forEach((condition) => {
          conditionAuditMetrics.track('Create', condition);
        });
        data.createdActions.forEach((action) => {
          actionAuditMetrics.track('Create', action);
        });
        data.schedules.forEach((schedule) => {
          conditionalActionsAuditMetrics.track('With schedule', schedule);
        });
      },
      onError: (err, _, context) => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.CHECKLIST_CREATE_FROM_TEMPLATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });

        makeToast({
          body: t(
            'CREATE_CHECKLIST_FROM_TEMPLATE_ERROR',
            'Failed to create checklist'
          ),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-template-create-checklist-mutation',
          },
        });
      },
    }
  );
};
