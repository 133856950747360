import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

export const useOperations = (activityExternalId?: string, enabled = true) => {
  const { activityService } = useFDMServices();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const parentActivityIdFilter: string[] = [];

  if (activityExternalId) {
    parentActivityIdFilter.push(activityExternalId);
  }

  const filters: Filters[] = [];

  if (activityExternalId) {
    filters.push({
      in: {
        property: 'parentActivityId',
        in: parentActivityIdFilter,
      },
    });
  }

  const operationsFilter: Filters = {
    and: [...filters],
  };

  const sort: Sort = [
    {
      order: 'ASC',
    },
    // So that the assets are ordered when expanding the operation
    {
      assetExternalId: 'ASC',
    },
  ];

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  return useQuery(
    [QueryKeys.OPERATIONS, operationsFilter, sort],
    async () => {
      sliTimerStartTime.current = Date.now();

      return activityService.getOperations(operationsFilter, sort);
    },
    {
      onError: (err: Error) => {
        captureException(err, {
          level: 'warning',
          tags: {
            queryKey: QueryKeys.OPERATIONS,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.OPERATIONS, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.OPERATIONS, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: enabled && Boolean(activityExternalId),
    }
  );
};
