import Konva from 'konva';

import BoundaryRect from '../containers/BoundaryRect';
import { UnifiedViewer } from '../UnifiedViewer';
import UnifiedViewerEventType from '../UnifiedViewerEventType';

const ANIMATION_SYNC_EVENTS = [
  UnifiedViewerEventType.ON_DRAG_MOVE,
  UnifiedViewerEventType.ON_DRAG_END,
  UnifiedViewerEventType.ON_TRANSFORM_CHANGE,
  UnifiedViewerEventType.ON_TRANSFORM_END,
];

export class LoadingSpinner extends Konva.Group {
  private readonly MILLISECONDS = 1000;
  private readonly ANGULAR_SPEED = 360;
  private readonly BASE_STROKE_WIDTH = 2;
  private readonly BASE_RADIUS = 16;
  private readonly CONTAINER_WIDTH_SCALE = 0.075;

  private rotateAnimation = new Konva.Animation((frame: any) => {
    const angleDiff = (frame.timeDiff * this.ANGULAR_SPEED) / this.MILLISECONDS;
    this.animationLayerGroup.rotate(angleDiff);
  });

  public recalculateSize = (containerWidth: number): void => {
    const scaledRadius = containerWidth * this.CONTAINER_WIDTH_SCALE;
    this.circle.radius(scaledRadius);
    this.arc.innerRadius(scaledRadius);
    this.arc.outerRadius(scaledRadius);
    const scaledStrokeWidth =
      this.BASE_STROKE_WIDTH * (scaledRadius / this.BASE_RADIUS);
    this.circle.strokeWidth(scaledStrokeWidth);
    this.arc.strokeWidth(scaledStrokeWidth);
  };

  private circle = new Konva.Circle({
    x: 0,
    y: 0,
    radius: this.BASE_RADIUS,
    strokeWidth: this.BASE_STROKE_WIDTH,
    stroke: '#bbb',
  });

  private arc = new Konva.Arc({
    x: 0,
    y: 0,
    radius: this.BASE_RADIUS,
    stroke: '#666',
    angle: 90,
    innerRadius: this.BASE_RADIUS,
    outerRadius: this.BASE_RADIUS,
    strokeWidth: this.BASE_STROKE_WIDTH,
  });

  private animationLayerGroup = new Konva.Group();

  public constructor(private unifiedViewer: UnifiedViewer) {
    super();

    this.animationLayerGroup.add(this.circle);
    this.animationLayerGroup.add(this.arc);
    this.unifiedViewer.layers.animation.add(this.animationLayerGroup);
    this.shamefullySyncAnimationGroup();
    this.rotateAnimation.start();

    ANIMATION_SYNC_EVENTS.forEach((eventType) => {
      this.unifiedViewer.addEventListener(
        eventType,
        this.shamefullySyncAnimationGroup
      );
    });
  }

  public override destroy(): this {
    ANIMATION_SYNC_EVENTS.forEach((eventType) => {
      this.unifiedViewer.removeEventListener(
        eventType,
        this.shamefullySyncAnimationGroup
      );
    });
    this.rotateAnimation.stop();
    this.animationLayerGroup.destroy();
    return super.destroy();
  }

  public override clone(): this {
    // Don't clone this node, it will be recreated if necessary
    return new Konva.Group() as this;
  }

  public centerInsideBoundaryRect = (boundaryRect: BoundaryRect): void => {
    this.position({
      x: boundaryRect.getWidth() / 2,
      y: boundaryRect.getHeight() / 2,
    });
    this.shamefullySyncAnimationGroup();
  };

  // Syncs the position of the animationLayerGroup with the position of this
  // node in its container.
  //
  // Shameful because it relies on the queueMicrotask to ensure the correct
  // positioning after DRAG_END and TRANSFORM_END and I'm not sure why.
  private shamefullySyncAnimationGroup = (): void => {
    queueMicrotask(() => {
      if (this.parent !== null) {
        this.recalculateSize(
          // @ts-expect-error Konva types
          this.parent.getClientRect({ relativeTo: this.getStage() }).width
        );
      }
      // @ts-expect-error Konva types
      const position = this.getClientRect({ relativeTo: this.getStage() });
      this.animationLayerGroup.position(position);
    });
  };
}
