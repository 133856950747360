import type { FDMClient } from '@cognite/fdm-client';
import type { Edge } from '@cognite/fdm-client/src/types';

import type { ServiceContract } from './types';

export class APMEdgeDataService {
  instanceSpace: string;
  modelSpace: string;
  modelVersion: string;
  fdmClient: FDMClient;
  constructor(contract: ServiceContract) {
    this.instanceSpace = contract.instanceSpace;
    this.modelSpace = contract.modelSpace;
    this.modelVersion = contract.modelVersion;
    this.fdmClient = contract.fdmClient;
  }

  /**
   * Upserting nodes.
   * Nodes without an external ID will be given one
   * @param edges -
   * @param userId - user ID of who is making the changes
   * @returns Result of query
   */
  async upsert(edges: Edge[]) {
    return this.fdmClient.upsertEdges(
      edges,
      this.instanceSpace,
      this.modelSpace
    );
  }
}
