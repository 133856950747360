import { getDirectRelationship, VIEW_VERSIONS } from '@cognite/apm-client';
import type {
  APMClient,
  Condition,
  ConditionSourceViews,
  ConditionUpsertType,
  InFieldUser,
} from '@cognite/apm-client';
import { FDMClient } from '@cognite/fdm-client';
import type { CogniteClient } from '@cognite/sdk';

export class ConditionsService extends FDMClient {
  apmClient: APMClient;
  modelSpace: string;
  constructor(client: CogniteClient, apmClient: APMClient) {
    super(client);
    this.apmClient = apmClient;
    this.modelSpace = apmClient.appDataModelSpace;
  }

  async createConditions(
    conditions: Condition[],
    user: InFieldUser,
    sourceView: ConditionSourceViews
  ) {
    const upsertedConditions: Condition[] = conditions.map((condition) => ({
      ...condition,
      createdBy: user,
      sourceView: `${this.modelSpace}/${sourceView}/${
        sourceView === 'TemplateItem'
          ? VIEW_VERSIONS.TEMPLATE_ITEM
          : VIEW_VERSIONS.CHECKLIST_ITEM
      }`,
    }));
    return this.upsertConditions(upsertedConditions).then(
      (result) => result.data.items[0]
    );
  }

  async updateConditions(conditions: Condition[], user: InFieldUser) {
    const upsertedConditions: Condition[] = conditions.map((condition) => ({
      ...condition,
      updatedBy: user,
    }));
    return this.upsertConditions(upsertedConditions);
  }

  async upsertConditions(conditions: Condition[]) {
    const upsertedConditions: ConditionUpsertType[] = conditions.map(
      (condition) => {
        const conditionalActionRelationship = getDirectRelationship(
          condition.conditionalAction
        );
        const sourceRelationship = getDirectRelationship(condition.source);
        const createdByRelationship = getDirectRelationship({
          externalId: condition.createdBy?.externalId,
          space: this.apmClient.userInstanceSpace,
        });
        const updatedByRelationship = getDirectRelationship({
          externalId: condition.updatedBy?.externalId,
          space: this.apmClient.userInstanceSpace,
        });
        return {
          ...condition,
          conditionalAction: conditionalActionRelationship,
          source: sourceRelationship,
          createdBy: createdByRelationship,
          updatedBy: updatedByRelationship,
        };
      }
    );
    return this.apmClient.conditions.upsert(upsertedConditions);
  }

  async deleteConditions(externalIds: string[]) {
    return this.apmClient.conditions.delete(externalIds);
  }
}
