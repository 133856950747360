import type { TransformMatrix } from './mathUtils';

/**
 * Returns an array containing all ancestor `HTMLElement`s, starting at the
 * provided element and usually ending with the `<body>` element.
 */
export function getHtmlElementAncestors(elem: Element | null): HTMLElement[] {
  const elems: HTMLElement[] = [];
  while (elem !== null && elem instanceof HTMLElement) {
    elems.push(elem);
    elem = elem.parentElement;
  }
  return elems;
}

/**
 * Returns the `TransformMatrix` of an element, if defined in its computed
 * style. Returns `null` if there is no transform on the element.
 */
export const getElementTransform = (elem: Element): TransformMatrix | null => {
  const style = window.getComputedStyle(elem, null);
  const transform =
    style.getPropertyValue('-webkit-transform') ||
    style.getPropertyValue('-moz-transform') ||
    style.getPropertyValue('-ms-transform') ||
    style.getPropertyValue('-o-transform') ||
    style.getPropertyValue('transform');
  return parseTransformMatrix(transform);
};

export const getTransformOrigin = (
  element: Element
): { x: number | null; y: number | null } => {
  const style = getComputedStyle(element);
  const origin = style.transformOrigin.split(' ').map(parseFloat);
  return {
    x: isNaN(origin[0]) ? null : origin[0],
    y: isNaN(origin[1]) ? null : origin[1],
  };
};

const MATRIX_REGEX = /^matrix\(([^)]+)\)$/;
const SPLIT_REGEX = /[, ]+/;
/**
 * Attempts to parse a string such as `"matrix(1, 0, 1, 1, 100, 0)"` into an
 * array such as `[1, 0, 1, 1, 100, 0]`.
 *
 * If unable to do so, `null` is returned.
 */
export const parseTransformMatrix = (
  transform: string | null
): TransformMatrix | null => {
  if (transform == null || transform === 'none') {
    return null;
  }

  const matrixStrings = transform.match(MATRIX_REGEX);

  if (matrixStrings == null || matrixStrings.length < 2) {
    return null;
  }

  const matrix = matrixStrings[1].split(SPLIT_REGEX).map(parseFloat);

  if (matrix.length !== 6) {
    return null;
  }

  return matrix as TransformMatrix;
};
