import { Container } from '../../containers';
import { ContainerProps } from '../../containers/Container';

// Konva has a method for getting all nodes that intersect with a point,
// but the documentation indicates it is slow:
//
// const intersections = this.unifiedViewer.stage.getAllIntersections(
//   this.unifiedViewer.stage.getPointerPosition()!
// );
//
// Since there are fewer containers than all nodes, instead just iterate
// the containers to find the top intersection. Could be performance
// tested against the Konva solution if necessary.
const getContainerUnderMouse = (containers: Container[]): Container | null =>
  containers
    // Find intersecting containers
    .filter((container) => {
      const node = container.getContentNode();
      const mouse = node.getRelativePointerPosition();
      return mouse === null
        ? false
        : mouse.x > 0 &&
            mouse.y > 0 &&
            mouse.x < node.width() &&
            mouse.y < node.height();
    })
    // Reduce to highest z-index container
    .reduce<Container<any, ContainerProps<any>> | null>(
      (top, next) =>
        next.getNode().zIndex() > (top?.getNode().zIndex() ?? -Infinity)
          ? next
          : top,
      null
    );

export default getContainerUnderMouse;
