import type { ModalProps } from '@cognite/cogs.js-v10';
import { Body, Flex, Input, Modal } from '@cognite/cogs.js-v10';
import type { FdmTimeSeries } from '@cognite/fdm-client';
import type { Asset } from '@cognite/sdk';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { AssetSearchInput } from '@infield/features/search';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import * as S from './elements';
import type { TimeseriesKey } from './timeseries-upsert-modal';

export type TimeseriesUpsertModalContentProps = {
  timeseriesToEdit?: FdmTimeSeries;
  updatedTimeseries?: FdmTimeSeries;
  timeseriesAsset?: Asset;
  isTimseriesExists: boolean;
  isAssetSearchDisabled?: boolean;
  isNewTimeseriesUpserting: boolean;
  onTimeseriesUpdate: (
    ket: TimeseriesKey,
    value: string | number | undefined
  ) => void;
  onAssetSelect: (asset?: Asset) => void;
  onSubmit: () => void;
  onCancel: () => void;
  children?: ReactNode;
} & ModalProps;

export const TimeseriesUpsertModalContent: FC<
  TimeseriesUpsertModalContentProps
> = ({
  timeseriesToEdit,
  updatedTimeseries,
  timeseriesAsset,
  okDisabled,
  isTimseriesExists,
  isAssetSearchDisabled,
  isNewTimeseriesUpserting,
  onTimeseriesUpdate,
  onAssetSelect,
  onSubmit,
  onCancel,
  ...rest
}) => {
  const [externalIdInput, setExternalIdInput] = useState(
    timeseriesToEdit?.externalId || ''
  );
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const hasMissingFields =
    !updatedTimeseries?.name?.trim() ||
    !updatedTimeseries?.externalId ||
    !updatedTimeseries.unit;

  useEffect(() => {
    if (timeseriesToEdit) {
      if (
        updatedTimeseries?.assetId !== timeseriesToEdit?.assetId ||
        updatedTimeseries?.name !== timeseriesToEdit.name ||
        updatedTimeseries?.description !== timeseriesToEdit.description
      ) {
        setHasChanges(true);
      } else {
        setHasChanges(false);
      }
    }
  }, [timeseriesToEdit, updatedTimeseries]);

  return (
    <Modal
      onOk={onSubmit}
      okText={
        timeseriesToEdit
          ? t('EDIT_TIMESERIES_DIALOGUE_BUTTON_SAVE', 'Save')
          : t('CREATE_TIMESERIES_DIALOGUE_BUTTON_SAVE', 'Create')
      }
      okDisabled={
        okDisabled ||
        isNewTimeseriesUpserting ||
        hasMissingFields ||
        (!hasChanges && Boolean(timeseriesToEdit)) ||
        (isTimseriesExists && !timeseriesToEdit)
      }
      onCancel={!isNewTimeseriesUpserting ? onCancel : undefined}
      okButtonProps={{
        'data-testid': 'timeseries-upsert-modal-ok-button',
        loading: isNewTimeseriesUpserting,
      }}
      cancelButtonProps={{ disabled: isNewTimeseriesUpserting }}
      {...rest}
    >
      <S.Wrapper>
        <Body strong>
          {t('CREATE_TIMESERIES_DIALOGUE_EXTERNALID_TITLE', 'External ID')}
          <S.RequiredIndicator>*</S.RequiredIndicator>
        </Body>
        <Input
          data-testid="timeseries-upsert-modal-externalId-input"
          type="text"
          autoFocus
          fullWidth
          disabled={Boolean(timeseriesToEdit) || isNewTimeseriesUpserting}
          onChange={(e) => setExternalIdInput(e.target.value)}
          onBlur={(e) => onTimeseriesUpdate('externalId', e.target.value)}
          value={externalIdInput}
          placeholder={t(
            'CREATE_TIMESERIES_DIALOGUE_INPUT_EXTERNALID_PLACEHOLDER',
            'External ID'
          )}
          helpText={t(
            'CREATE_TIMESERIES_DIALOGUE_INPUT_EXTERNALID_HELPTEXT',
            'This is the unique identifier of the time series'
          )}
        />
        {isTimseriesExists &&
          !isNewTimeseriesUpserting &&
          !timeseriesToEdit && (
            <span className="error-message ">
              {t(
                'CREATE_TIMESERIES_DIALOGUE_EXTERNALID_ERROR_ALREADY_EXISTS',
                'This external ID already exists. Set a different external ID.'
              )}
            </span>
          )}
        <Body strong>
          {t('CREATE_TIMESERIES_DIALOGUE_TAG_TITLE', 'Time series asset')}
        </Body>
        <Flex>
          {!isAssetSearchDisabled && (
            <AssetSearchInput
              disabled={isNewTimeseriesUpserting}
              selected={timeseriesAsset}
              onSelect={onAssetSelect}
              helpText={t(
                'CREATE_TIMESERIES_DIALOGUE_TAG_HELPTEXT',
                'If no asset is selected, the time series will be linked to the root asset'
              )}
            />
          )}
        </Flex>
        <Body strong>
          {t('CREATE_TIMESERIES_DIALOGUE_NAME_TITLE', 'Time series name')}
          <S.RequiredIndicator>*</S.RequiredIndicator>
        </Body>
        <Input
          data-testid="timeseries-upsert-modal-name-input"
          type="text"
          fullWidth
          disabled={isNewTimeseriesUpserting}
          onChange={(e) => onTimeseriesUpdate('name', e.target.value)}
          value={updatedTimeseries?.name}
          helpText={t(
            'CREATE_TIMESERIES_DIALOGUE_NAME_HELPTEXT',
            'Name of the timeseries in Cognite Data Fusion'
          )}
          placeholder={t(
            'CREATE_TIMESERIES_DIALOGUE_NAME_PLACEHOLDER',
            'Time series name'
          )}
        />
        <Body strong>
          {t(
            'CREATE_TIMESERIES_DIALOGUE_DESCRIPTION_TITLE',
            'Time series description'
          )}
        </Body>
        <Input
          data-testid="timeseries-upsert-modal-description-input"
          type="text"
          fullWidth
          disabled={isNewTimeseriesUpserting}
          helpText={t(
            'CREATE_TIMESERIES_DIALOGUE_DESCRIPTON_INPUT_HELPTEXT',
            'The description will display as checklist reading label name. Other applications can display the description when trending time series.'
          )}
          onChange={(e) => onTimeseriesUpdate('description', e.target.value)}
          value={updatedTimeseries?.description}
          placeholder={t(
            'CREATE_TIMESERIES_DIALOGUE_DESCRIPTION_INPUT_PLACEHOLDER',
            'Description'
          )}
        />
        <Body strong>
          {t('CREATE_TIMESERIES_DIALOGUE_UNIT_TITLE', 'Unit')}
          <S.RequiredIndicator>*</S.RequiredIndicator>
        </Body>
        <Input
          data-testid="timeseries-upsert-modal-unit-input"
          type="text"
          fullWidth
          disabled={Boolean(timeseriesToEdit) || isNewTimeseriesUpserting}
          onChange={(e) => onTimeseriesUpdate('unit', e.target.value)}
          value={updatedTimeseries?.unit}
          placeholder={t(
            'CREATE_TIMESERIES_DIALOGUE_UNIT_INPUT_PLACEHOLDER',
            'Unit'
          )}
          helpText={t(
            'CREATE_TIMESERIES_DIALOGUE_UNIT_INPUT_HELPTEXT',
            'The unit of the time series, for example "bar" or "celcius".'
          )}
        />
      </S.Wrapper>
    </Modal>
  );
};
