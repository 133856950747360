import { version } from 'process';

import { makeToast } from '@cognite/cogs-lab';
import { useAuthContext } from '@cognite/e2e-auth';
import type { ViewDefinition, ViewReference } from '@cognite/sdk';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';
import uniqBy from 'lodash/uniqBy';

export const useGetViewsByReferences = (
  viewReferences: Omit<ViewReference, 'type'>[],
  enabled: boolean
) => {
  const { client } = useAuthContext();
  return useQuery<ViewDefinition[] | undefined>(
    [QueryKeys.VIEWS, viewReferences],
    async () => {
      const { items: views } = await client.views.retrieve(
        uniqBy(
          viewReferences,
          (view) => `${view.externalId}-${view.space}-${version}`
        )
      );
      return views;
    },
    {
      enabled: viewReferences.length > 0 && enabled,
      onError: (error) => {
        makeToast({
          type: 'danger',
          body: `Failed to fetch views. ${error}`,
        });
      },
    }
  );
};
