import type { APMClient } from '@cognite/apm-client';
import type { ThreeDService } from '@infield/features/3d';
import type { ActivityService } from '@infield/features/activities';
import type { AssetService } from '@infield/features/asset';
import type { ChecklistService } from '@infield/features/checklist';
import type {
  ActionsService,
  ConditionalActionsService,
  ConditionsService,
} from '@infield/features/conditions';
import type { MeasurementService } from '@infield/features/measurements';
import type { MediaService } from '@infield/features/media';
import type { NotificationService } from '@infield/features/notifications';
import type { ObservationService } from '@infield/features/observation';
import type { IntervalService } from '@infield/features/task/interval-list';
import type { TemplateService } from '@infield/features/template';
import type { TimeseriesService } from '@infield/features/timeseries';
import { createContext } from 'react';

type FDMServices = {
  mediaService: InstanceType<typeof MediaService>;
  activityService: InstanceType<typeof ActivityService>;
  assetService: InstanceType<typeof AssetService>;
  notificationService: InstanceType<typeof NotificationService>;
  threeDService: InstanceType<typeof ThreeDService>;
  measurementsService: InstanceType<typeof MeasurementService>;
  conditionsService: InstanceType<typeof ConditionsService>;
  conditionalActionsService: InstanceType<typeof ConditionalActionsService>;
  actionsService: InstanceType<typeof ActionsService>;
  timeseriesService: InstanceType<typeof TimeseriesService>;
  templateService: InstanceType<typeof TemplateService>;
  checklistService: InstanceType<typeof ChecklistService>;
  intervalService: InstanceType<typeof IntervalService>;
  observationService: InstanceType<typeof ObservationService>;
  apmClient: InstanceType<typeof APMClient>;
};

export const FDMServicesContext = createContext<FDMServices | undefined>(
  undefined
);
