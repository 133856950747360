import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';

import { useAPMAssetQuery } from './use-apm-asset-query';
import { useAssetQuery } from './use-asset-query';

export const useClassicOrAPMAssetQuery = (assetExternalId: string) => {
  const { isIdm } = useAppConfigContext();
  const classicAssetQuery = useAssetQuery(!isIdm ? assetExternalId : undefined);
  const apmAssetQuery = useAPMAssetQuery(isIdm ? assetExternalId : undefined);

  return !isIdm ? classicAssetQuery : apmAssetQuery;
};
