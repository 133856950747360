import { ChartWorkflow } from '../types';

export const isWorkflowRunnable = (workflow: ChartWorkflow) => {
  if (!workflow.version) {
    const { nodes } = workflow;
    return (
      nodes && nodes.length > 0 && nodes.filter((node) => !node.outputPins)
    );
  }
  if (workflow.version === 'v2') {
    const elements = workflow.flow?.elements;
    return elements && elements.length > 0;
  }
  return false;
};
