import type { APMAsset } from '@cognite/apm-client';
import type { Asset } from '@cognite/sdk';
import { type Step } from '@infield/features/observation';
import { AssetSearchInput } from '@infield/features/search';
import type { FC } from 'react';

import { ObservationStepWrapper } from '../elements';

import * as S from './elements';

interface Props {
  step?: Step;
  asset?: APMAsset;
  isLocked?: boolean;
  onChange: (asset?: Asset, apmAsset?: APMAsset) => void;
}

export const ObservationDetailsDesktopAssetInput: FC<Props> = ({
  step,
  asset,
  isLocked,
  onChange,
}) => {
  return (
    <ObservationStepWrapper>
      <S.SearchWrapper>
        <AssetSearchInput
          data-testid="asset-search-input"
          label={step?.name}
          selected={asset}
          onSelect={(classicAsset, apmAsset) =>
            onChange(classicAsset, apmAsset)
          }
          disabled={isLocked}
          required={step?.isRequired}
        />
      </S.SearchWrapper>
    </ObservationStepWrapper>
  );
};
