import { Heading, Loader } from '@cognite/cogs.js-v10';
import { Modal } from '@cognite/cogs.js-v10';
import type { AuthStateUser } from '@cognite/e2e-auth';
import { useUserSpaceAccess } from '@infield/features/access';
import {
  AppConfigPage,
  selectedRootLocationAtom,
  useDisciplines,
  useIsAppConfiguratorQuery,
  useLocations,
} from '@infield/features/app-config';
import { AppConfigErrorScreen } from '@infield/features/app-config/app-config-error-screen';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { useInfieldTranslation } from '@infield/features/i18n/use-infield-translation';
import { useCurrentUserQuery, UserOnboarding } from '@infield/features/user';
import { useInfieldLogout } from '@infield/hooks/use-infield-logout';
import { PageContainerStyle } from '@infield/pages/elements';
import { useEffect, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  MissingPermissionsErrorComponent,
  UserErrorComponent,
} from './components';
import { validateUserLocation } from './utils';

interface Props {
  mocks?: AuthStateUser;
}

export const UserProvider: FC<PropsWithChildren<Props>> = ({
  children,
  mocks,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.user);
  const {
    isLoading: isUserDataLoading,
    isError,
    error,
  } = useCurrentUserQuery();
  const setSelectedRootLocation = useSetRecoilState(selectedRootLocationAtom);
  const { logout } = useInfieldLogout();
  const [showUserOnboarding, setShowUserOnboarding] = useState(false);
  const { data: hasAppConfigPermission } = useIsAppConfiguratorQuery();
  const [showAppConfig, setShowAppConfig] = useState(false);

  const userSpace = 'cognite_app_data';
  const {
    selectableLocations,
    isSelectableLocationsLoading,
    configuredRootLocationsOptions,
    selectedRootLocation,
    handleSaveLocation,
    updateLocationInLocalStorage,
  } = useLocations();

  const {
    data: hasUserSpaceAccess,
    isSuccess: isUserSpaceAccessSuccess,
    isLoading: isLoadingUserSpaceAccess,
  } = useUserSpaceAccess();

  const { selectedLanguage, supportedLanguages, updateLanguage } =
    useInfieldTranslation();

  const {
    configuredDisciplineOptions,
    selectedDiscipline,
    handleSaveDiscipline,
  } = useDisciplines();

  const validUserLocation = validateUserLocation(
    selectableLocations,
    selectedRootLocation?.value
  );

  const isLoadingUserData =
    (isUserDataLoading ||
      isSelectableLocationsLoading ||
      isLoadingUserSpaceAccess) &&
    !mocks;

  useEffect(() => {
    if (!validUserLocation && !isLoadingUserData) {
      setShowUserOnboarding(true);
    } else if (validUserLocation) {
      if (selectedRootLocation?.value) {
        updateLocationInLocalStorage(selectedRootLocation?.value);
      }
      setSelectedRootLocation(selectedRootLocation?.value);
    }
  }, [
    validUserLocation,
    isLoadingUserData,
    setSelectedRootLocation,
    selectedRootLocation,
    updateLocationInLocalStorage,
  ]);

  const handleSaveUserOnboarding = () => {
    setShowUserOnboarding(false);
  };

  if (mocks) {
    return <>{children}</>;
  }

  if (isError) {
    return (
      <UserErrorComponent
        userSpace={userSpace}
        error={error}
        onLogout={logout}
      />
    );
  }

  if (!hasUserSpaceAccess && isUserSpaceAccessSuccess) {
    return (
      <>
        <MissingPermissionsErrorComponent
          userSpace={userSpace}
          hasAppConfigPermission={hasAppConfigPermission}
          onShowAppConfig={() => setShowAppConfig(true)}
          onLogout={logout}
        />
        <Modal
          title="App configuration"
          visible={showAppConfig}
          size="full-screen"
          onCancel={() => setShowAppConfig(false)}
          onOk={() => setShowAppConfig(false)}
        >
          <AppConfigPage hasAppConfigPermission={hasAppConfigPermission} />
        </Modal>
      </>
    );
  }

  if (isLoadingUserData && !showUserOnboarding) {
    return (
      <Loader
        infoText={
          <Heading level={6}>
            {t('loadingUserData', 'Loading user data')}
          </Heading>
        }
      />
    );
  }

  if (
    !configuredRootLocationsOptions ||
    configuredRootLocationsOptions.length === 0
  ) {
    return (
      <AppConfigErrorScreen
        title={t(
          'USER_ERROR_LOCATION_MISSING_TITLE',
          'Error: Location is missing.'
        )}
        body={t(
          'USER_ERROR_LOCATION_MISSING_DESCRIPTION',
          "The InField admin has not defined any location yet, or you don't have access to any of the configured locations. Contact your admin if you have any questions."
        )}
      />
    );
  }

  if (showUserOnboarding) {
    return (
      <PageContainerStyle>
        <UserOnboarding
          languages={supportedLanguages}
          selectedLanguage={selectedLanguage}
          onSelectLanguage={updateLanguage}
          locations={configuredRootLocationsOptions}
          selectedLocation={selectedRootLocation}
          onSelectLocation={handleSaveLocation}
          disciplines={configuredDisciplineOptions}
          selectedDiscipline={selectedDiscipline}
          onSelectDiscipline={handleSaveDiscipline}
          onComplete={handleSaveUserOnboarding}
        />
      </PageContainerStyle>
    );
  }

  return <>{children}</>;
};
