import { VIEW_VERSIONS } from '@cognite/apm-client';

import type { APMAppDataServiceOptions } from '../apm-app-data-service';
import { APMAppDataService } from '../apm-app-data-service';
import type { ServiceContract } from '../types';

import type { Action } from './types';

export class ActionsService extends APMAppDataService<Action> {
  constructor(contract: ServiceContract, options: APMAppDataServiceOptions) {
    super(contract, 'Action', VIEW_VERSIONS.ACTION, options);
  }
}
