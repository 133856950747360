import type { Node3D } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const use3dNodesByNodeId = (
  modelId: number,
  revisionId: number,
  nodeId?: number
) => {
  const { threeDService } = useFDMServices();

  return useQuery<Node3D[]>(
    [QueryKeys.THREE_D, modelId, revisionId, 'nodes', nodeId],
    () => {
      return threeDService.get3DNodesByNodeId(modelId, revisionId, nodeId!);
    },
    {
      enabled: typeof nodeId === 'number',
      onError: (err) => captureException(err),
    }
  );
};
