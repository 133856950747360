import Konva from 'konva';
import cloneDeep from 'lodash/cloneDeep';

import {
  UNIFIED_VIEWER_NODE_TYPE_KEY,
  UnifiedViewerNodeType,
} from '../../types';
import UnifiedViewerRenderer from '../../UnifiedViewerRenderer';
import shamefulSafeKonvaScale from '../../utils/shamefulSafeKonvaScale';
import getBaseStylePropertiesFromNode from '../getBaseStylePropertiesFromNode';
import getMaxDiffingScalingFactor from '../getMaxDiffingScalingFactor';
import setAnnotationNodeEventHandlers from '../setAnnotationNodeEventHandlers';
import shouldBeDraggable from '../shouldBeDraggable';
import shouldBeResizable from '../shouldBeResizable';
import shouldBeSelectable from '../shouldBeSelectable';
import shouldBeStrokeScaleEnabled from '../shouldBeStrokeScaleEnabled';
import {
  AnnotationSerializer,
  AnnotationType,
  LineStyleProperties,
  PolylineAnnotation,
  PolylineEndType,
  Position,
  Scale,
  ShapeAnchorPoint,
  Size,
} from '../types';

import getNormalizedPolylineDimensionsFromNode from './getNormalizedPolylineDimensionsFromNode';
import getPoints from './getPoints';
import getPointsRelativeToPosition from './getPointsRelativeToPosition';
import rescalePoints from './rescalePoints';

type PolylineNodeProperties<MetadataType> = {
  [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION;
  annotationType: AnnotationType.POLYLINE;
  id: string;
  containerId?: string;
  strokeScaleEnabled: boolean;
  isSelectable: boolean;
  isDraggable: boolean;
  isResizable: boolean;
  points: number[];
  pointerAtBeginning: boolean;
  pointerAtEnding: boolean;
  fromId?: string;
  toId?: string;
  anchorStartTo?: ShapeAnchorPoint;
  anchorEndTo?: ShapeAnchorPoint;
  x: number;
  y: number;
  metadata?: MetadataType;
} & LineStyleProperties;

const getNodePropertiesFromAnnotation = <MetadataType>(
  annotation: PolylineAnnotation<MetadataType>,
  unifiedViewerRenderer: UnifiedViewerRenderer
): PolylineNodeProperties<MetadataType> | undefined => {
  if (
    (annotation.vertices === undefined || annotation.vertices.length === 0) &&
    annotation.fromId === undefined &&
    annotation.toId === undefined
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      'Polyline annotation has no vertices and no from/to annotation id'
    );
    return;
  }

  const points = getPoints(annotation, unifiedViewerRenderer);
  if (points === undefined) {
    return undefined;
  }

  const minimumPosition = {
    x: Math.min(...points.map((p) => p.x)),
    y: Math.min(...points.map((p) => p.y)),
  };

  return {
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
    annotationType: AnnotationType.POLYLINE,
    id: annotation.id,
    strokeScaleEnabled: shouldBeStrokeScaleEnabled(annotation),
    containerId: annotation.containerId,
    isSelectable: shouldBeSelectable(annotation.isSelectable),
    isDraggable: shouldBeDraggable(annotation.isDraggable),
    isResizable: shouldBeResizable(annotation.isResizable),
    points: getPointsRelativeToPosition(points, minimumPosition).flatMap(
      ({ x, y }) => [x, y]
    ),
    fill: annotation.style?.fill,
    stroke: annotation.style?.stroke,
    strokeWidth: annotation.style?.strokeWidth,
    opacity: annotation.style?.opacity,
    dash: annotation.style?.dash,
    dashEnabled: annotation.style?.dashEnabled,
    lineType: annotation.style?.lineType,
    fromId: annotation.fromId,
    toId: annotation.toId,
    anchorStartTo: annotation.anchorStartTo,
    anchorEndTo: annotation.anchorEndTo,
    pointerAtBeginning: annotation.startEndType === PolylineEndType.ARROW,
    pointerAtEnding: annotation.endEndType === PolylineEndType.ARROW,
    metadata: annotation.metadata,
    // Note: If containerId is set, then we always should assume that the dimensions are
    // normalized.
    ...minimumPosition,
  };
};

type PolylineTransformationProperties = {
  size: Size;
  scale: Scale;
  points: number[];
  position: Position;
};

const PolylineSerializer: AnnotationSerializer<
  PolylineAnnotation,
  PolylineTransformationProperties
> = {
  isSelectable: (node: Konva.Node) =>
    shouldBeSelectable(node.attrs.isSelectable),

  isDraggable: (node: Konva.Node) => shouldBeDraggable(node.attrs.isDraggable),

  isResizable: (node: Konva.Node) => shouldBeResizable(node.attrs.isResizable),

  getTransformationPropertiesFromNode: (node: Konva.Node) => {
    if (!(node instanceof Konva.Arrow)) {
      throw new Error('Node is not a Konva.Arrow');
    }

    return {
      size: node.size(),
      scale: shamefulSafeKonvaScale(node.scale()),
      points: node.points(),
      position: node.position(),
    };
  },

  normalize: ({ size, scale, points, position }) => {
    const scaleFactor = getMaxDiffingScalingFactor(scale);
    return {
      size: {
        width: size.width * scaleFactor,
        height: size.height * scaleFactor,
      },
      // Rescale the points to the new scale factor
      points: rescalePoints({ points, scale, size }),
      position,
      scale: {
        x: 1,
        y: 1,
      },
    };
  },

  serialize: (node, unifiedViewerRenderer): PolylineAnnotation => {
    return {
      id: node.id(),
      type: AnnotationType.POLYLINE,
      containerId: node.attrs.containerId,
      isSelectable: shouldBeSelectable(node.attrs.isSelectable),
      isDraggable: shouldBeDraggable(node.attrs.isDraggable),
      isResizable: shouldBeResizable(node.attrs.isResizable),
      vertices: getNormalizedPolylineDimensionsFromNode(
        node as Konva.Arrow, // TODO: Improve typing
        unifiedViewerRenderer.getContainerRectRelativeToStageById
      ),
      startEndType:
        node.attrs.pointerAtBeginning === true
          ? PolylineEndType.ARROW
          : PolylineEndType.NONE,
      endEndType:
        node.attrs.pointerAtEnding === true
          ? PolylineEndType.ARROW
          : PolylineEndType.NONE,
      anchorStartTo: node.attrs.anchorStartTo,
      anchorEndTo: node.attrs.anchorEndTo,
      style: {
        ...getBaseStylePropertiesFromNode(node as Konva.Arrow),
        lineType:
          node.attrs.fromId === undefined || node.attrs.toId === undefined
            ? undefined
            : node.attrs.lineType,
      },
      fromId: node.attrs.fromId,
      toId: node.attrs.toId,
      metadata: cloneDeep(node.attrs.metadata),
    };
  },

  deserialize: (annotation, unifiedViewer, unifiedViewerRenderer) => {
    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return undefined;
    }

    return setAnnotationNodeEventHandlers(
      new Konva.Arrow(nodeProperties),
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },

  updateNode: (
    node,
    annotation,
    unifiedViewer,
    unifiedViewerRenderer
  ): void => {
    if (!(node instanceof Konva.Arrow)) {
      throw new Error('Expected node to be a Konva.Arrow');
    }

    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return;
    }

    node.setAttrs(nodeProperties);

    setAnnotationNodeEventHandlers(
      node,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },
};

export default PolylineSerializer;
