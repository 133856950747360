import { ContainerType } from '../ContainerType';
import ReactContainer, {
  ReactContainerRenderContentProps,
} from '../ReactContainer';
import ErrorContent from '../TimeseriesContainer/ErrorContent';

import TableContent from './TableContent';
import { TableContainerProps } from './types';

export default class TableContainer<MetadataType> extends ReactContainer<
  MetadataType,
  TableContainerProps<MetadataType>
> {
  public readonly type = ContainerType.TABLE;

  protected getPropsRequiringUpdate(): Array<
    keyof Pick<TableContainerProps, 'items' | 'title'>
  > {
    return ['items', 'title'];
  }

  protected renderContent = ({
    width,
    height,
    unscaledWidth,
    unscaledHeight,
    shouldAutoSize,
    setLoadingStatus,
    onContentSizeChange,
  }: ReactContainerRenderContentProps): JSX.Element => {
    if (this.loadingStatus.isError) {
      return <ErrorContent width={width} height={height} />;
    }
    return (
      <TableContent
        width={width}
        height={height}
        unscaledWidth={unscaledWidth}
        unscaledHeight={unscaledHeight}
        shouldAutoSize={shouldAutoSize}
        title={this.props.title}
        items={this.props.items}
        containerPadding={this.getHeaderNodeDimensions().height}
        setLoadingStatus={setLoadingStatus}
        onContentSizeChange={onContentSizeChange}
      />
    );
  };

  public serialize = (): TableContainerProps => {
    return {
      ...this.serializeBaseContainerProps(),
      type: this.type,
      title: this.props.title,
      items: this.props.items,
    };
  };
}
