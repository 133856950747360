import { Allotment } from 'allotment';
import type { FC, PropsWithChildren } from 'react';

import * as S from './elements';

export const SplitViewWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <S.SplitViewStyleOverwrite>
      <Allotment separator={false} proportionalLayout={false}>
        {children}
      </Allotment>
    </S.SplitViewStyleOverwrite>
  );
};
