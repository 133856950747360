import type { Filters, Sort } from '@cognite/fdm-client';

import type { DefaultCDFViewMigrator } from '.';

export class CDFIDMViewMigrator implements DefaultCDFViewMigrator {
  space: string;
  constructor(_space: string) {
    this.space = _space;
  }
  mapPropertiesInward(properties: string[]): string[] {
    const replace: Record<string, string> = {
      id: 'sourceId',
      cloneOf: 'REMOVED',
    };
    return properties
      .map((p) => replace?.[p] || p)
      .filter((p) => p !== 'REMOVED');
  }
  mapFiltersInward = (filters: Filters): Filters => {
    const newFilters = { ...filters };
    Object.keys(filters).forEach((key) => {
      if (key === 'and') {
        newFilters.and = filters.and!.map((f) => this.mapFiltersInward(f));
      }
      if (key === 'or') {
        newFilters.or = filters.or!.map((f) => this.mapFiltersInward(f));
      }

      if (key === 'not') {
        newFilters.not = this.mapFiltersInward(newFilters.not!);
      }

      if (key === 'equals') {
        if (filters.equals?.property === 'rootLocation') {
          console.warn(
            'Root location no longer supported! Use a nested filter on asset.root'
          );
          delete newFilters.equals;
        }
        if (filters.equals?.property === 'isInApp') {
          console.warn('isInApp no longer supported!');
          delete newFilters.equals;
        }
        if (filters.equals?.property === 'hasBeenMutated') {
          console.warn('hasBeenMutated no longer supported!');
          delete newFilters.equals;
        }
        if (filters.equals?.property === 'mutation') {
          console.warn('mutation no longer supported!');
          delete newFilters.equals;
        }
        if (filters.equals?.property === 'assetExternalId') {
          console.warn('assetExternalId no longer supported!');
          delete newFilters.equals;
        }
        if (filters.equals?.property === 'source') {
          console.warn('source is now a direct relation!');
          delete newFilters.equals;
        }
      }

      if (key === 'isNull') {
        if (filters.isNull?.property === 'cloneOf') {
          console.warn('Clone of no longer supported!');
          delete newFilters.isNull;
        }
        if (filters.isNull?.property === 'hasBeenMutated') {
          console.warn('hasBeenMutated no longer supported!');
          delete newFilters.isNull;
        }
        if (filters.isNull?.property === 'createdDate') {
          console.warn('createdDate no longer supported!');
          delete newFilters.isNull;
        }
      }

      if (key === 'in') {
        if (filters.in?.property === 'rootLocation') {
          delete newFilters.in;
          console.warn('rootLocation is no longer supported!');
        }
        if (filters.in?.property === 'id') {
          console.warn('id no longer supported! Use sourceId');
          newFilters.in!.property = 'sourceId';
        }

        if (filters.in?.property === 'cloneOf') {
          console.warn('cloneOf no longer supported!');
          delete newFilters.in;
        }
        if (filters.in?.property === 'parentActivityId') {
          newFilters.in!.property = 'maintenanceOrder';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newFilters.in.in = filters.in.in.map((id) => ({
            space: this.space,
            externalId: id,
          }));
          console.warn(
            'parentActivityId is replaced by Direct relation maintenanceOrder'
          );
        }
      }
    });
    return newFilters;
  };

  mapSortInward(sort: Sort): Sort {
    if (!sort) {
      return [];
    }
    return sort.map((x) => {
      if (Object.keys(x)[0] === 'order') {
        console.log('Sorting on order no longer supported - sort on sequence');
        return { sequence: x.order };
      }
      return x;
    });
  }

  mapDataOutward(data: any): any {
    return data;
  }
  mapDataInward(data: any) {
    return data;
  }
}
