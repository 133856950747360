import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import { EventsIcon } from '@cognite/cogs.js-v10';
import { Asset, CogniteEvent } from '@cognite/sdk';
import { useSDK } from '@cognite/sdk-provider';

import { EventAcdmInstance } from '../../../types';
import resolveReadableProperty from '../../common/resolveReadableProperty';
import { ReactContainerRenderContentProps } from '../../ReactContainer';
import useStatusChange from '../../ReactContainer/useStatusChange';
import TableContent from '../../TableContainer/TableContent';
import { DEFAULT_EVENT_CONTAINER_PROPERTIES } from '../constants';
import { PropertyListContainerProps } from '../types';

import EventAssets from './EventAssets';
import getEventTableTitle from './getEventTableTitle';

type EventPropertyListContentProps = Pick<
  ReactContainerRenderContentProps,
  | 'width'
  | 'height'
  | 'unscaledWidth'
  | 'unscaledHeight'
  | 'setLoadingStatus'
  | 'onContentSizeChange'
  | 'shouldAutoSize'
> &
  Pick<PropertyListContainerProps, 'properties'> & {
    instance: EventAcdmInstance;
    containerPadding: number;
  };

const resolveEventPropertyDisplayItems = (
  event: CogniteEvent,
  eventAssets: Asset[],
  properties: string[]
) => {
  return properties.map((path) => {
    const resolvedProperty = resolveReadableProperty(event, path);

    // Hard coded special cases per container type
    if (path === 'assetIds') {
      return {
        label: 'Linked asset(s)',
        value: <EventAssets eventId={event.id} assets={eventAssets} />,
      };
    }

    return {
      label: resolvedProperty.label,
      value: resolvedProperty.value,
    };
  });
};

const EventPropertyList: React.FC<EventPropertyListContentProps> = ({
  width,
  height,
  unscaledWidth,
  unscaledHeight,
  shouldAutoSize,
  containerPadding,
  setLoadingStatus,
  onContentSizeChange,
  instance,
  properties = DEFAULT_EVENT_CONTAINER_PROPERTIES,
}) => {
  const sdk = useSDK();
  const {
    data: event,
    isLoading,
    isError,
  } = useQuery(['event', instance], async () => {
    const events = await sdk.events.retrieve([{ id: instance.id }]);
    if (events.length !== 1) {
      throw Error('There must be exactly one unique event for an event id');
    }
    return events[0];
  });

  const {
    data: eventAssets = [],
    isLoading: isEventAssetsLoading,
    isError: isEventAssetsError,
  } = useQuery(
    ['eventAssets', instance, event?.assetIds],
    async (): Promise<Asset[]> => {
      if (event === undefined) {
        return [];
      }

      if (event.assetIds === undefined) {
        return [];
      }

      return sdk.assets.retrieve(event.assetIds.map((id) => ({ id })));
    },
    {
      enabled: event !== undefined,
    }
  );

  useStatusChange({
    data: event,
    isLoading: isLoading || (!isError && isEventAssetsLoading),
    isError: isError || isEventAssetsError,
    setLoadingStatus,
  });

  return (
    <div>
      {event !== undefined && (
        <TableContent
          width={width}
          height={height}
          unscaledWidth={unscaledWidth}
          unscaledHeight={unscaledHeight}
          shouldAutoSize={shouldAutoSize}
          containerPadding={containerPadding}
          title={
            <TitleWrapper>
              <ColoredEventsIcon /> {}
              {getEventTableTitle(event)}
            </TitleWrapper>
          }
          items={resolveEventPropertyDisplayItems(
            event,
            eventAssets,
            properties
          )}
          setLoadingStatus={setLoadingStatus}
          onContentSizeChange={onContentSizeChange}
        />
      )}
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            inset: 0,
            background: 'rgba(255,255,255,0.7)',
          }}
        />
      )}
    </div>
  );
};

const ColoredEventsIcon = styled(EventsIcon)`
  color: #c945db;
  position: relative;
  margin-right: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export default EventPropertyList;
