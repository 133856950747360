import { useContainerDimensions } from '@infield/hooks/use-container-dimensions';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { BREAKPOINT_PAD_LANDSCAPE } from '@infield/providers/theme';
import { useMemo, useRef, useState } from 'react';
import type { FC } from 'react';
import { useRecoilState } from 'recoil';

import * as S from './elements';
import { useDomains, useTrendsList } from './hooks';
import { selectedTimeseriesExternalIdsAtom } from './state';
import { TrendsChart } from './trends-chart';
import { TrendsChartEmptyView } from './trends-chart-empty-view';
import { TrendControls } from './trends-controls';
import { TrendsList } from './trends-list';
import { getHighlightedTimeseries, getOtherTimeseries } from './utils';

interface Props {
  assetExternalId: string;
}

export const Trends: FC<Props> = ({ assetExternalId }) => {
  const isDesktop = useIsDesktop();
  const ref = useRef<HTMLDivElement>(null);
  const [selectedTimeseriesExternalIds, setSelectedTimeseriesExternalIds] =
    useRecoilState(selectedTimeseriesExternalIdsAtom);

  const { data: timeseries, isLoading } = useTrendsList(assetExternalId);
  const highlightedTimeseries = getHighlightedTimeseries(timeseries);
  const otherTimeseries = getOtherTimeseries(timeseries);

  const [yAxisShown, setYAxisShown] = useState(false);
  const [isFullView, setIsFullView] = useState(false);

  const handleYAxisToggle = () => setYAxisShown((YAxisShown) => !YAxisShown);

  const handleToggleSelectedTimeseries = (timeseriesExternalId: string) => {
    if (selectedTimeseriesExternalIds.includes(timeseriesExternalId))
      return setSelectedTimeseriesExternalIds([
        ...selectedTimeseriesExternalIds.filter(
          (selectedTimeseriesId) =>
            selectedTimeseriesId !== timeseriesExternalId
        ),
      ]);

    setSelectedTimeseriesExternalIds([
      ...selectedTimeseriesExternalIds,
      timeseriesExternalId,
    ]);
  };
  const selectedTimeseriesList = useMemo(
    () =>
      (timeseries || [])?.filter(({ externalId: tsExternalId }) =>
        selectedTimeseriesExternalIds.includes(tsExternalId!)
      ),
    [selectedTimeseriesExternalIds, timeseries]
  );

  const domain = useDomains({ activeTimeseries: selectedTimeseriesList });

  const handleFullScreen = () => {
    if (isFullView) {
      setIsFullView(false);
    } else {
      setIsFullView(true);
    }
  };

  const { width: containerWidth } = useContainerDimensions(ref);

  const renderAsMobile =
    !isDesktop || containerWidth < BREAKPOINT_PAD_LANDSCAPE;
  const renderChart = () => {
    if (selectedTimeseriesList.length > 0) {
      return (
        <TrendsChart
          isFullView={isFullView}
          timeseries={selectedTimeseriesList}
          zoomable
          isShowYAxis={yAxisShown}
          ySubDomains={domain.ySubDomains}
          setYSubDomains={domain.setYSubDomains}
          initialXDomain={domain.initialXDomain}
          xSubDomain={domain.xSubDomain}
          onXSubDomainChange={domain.handleXSubDomainChange}
          handleChartWidthChange={domain.handleChartWidthChange}
          limitXSubDomain={domain.limitXSubDomain}
          handleUpdateDomains={domain.handleUpdateDomains}
          isLive={domain.isLive}
          buttonClicked={domain.buttonClicked}
          renderAsMobile={renderAsMobile}
        />
      );
    }

    return <TrendsChartEmptyView />;
  };

  return (
    <S.Wrapper isDesktop={!renderAsMobile} isFullView={isFullView} ref={ref}>
      {renderChart()}
      <TrendControls
        selectedDomain={domain.selectedButtonValue}
        yAxisShown={yAxisShown}
        live={domain.isLive}
        isFullView={isFullView}
        containerWidth={containerWidth}
        onToggleFullscreen={handleFullScreen}
        alarmLimitsShown={false}
        onDomainClick={domain.handleDomainClick}
        onLiveClick={domain.handleLiveClick}
        onYAxisToggle={handleYAxisToggle}
        onCompareTrendsClick={() => ({})}
        onShowAlarmLimitsToggle={() => ({})}
      />
      <TrendsList
        isLoading={isLoading}
        timeseries={highlightedTimeseries || []}
        otherTimeseries={otherTimeseries || []}
        selectedTimeseriesExternalIds={selectedTimeseriesExternalIds}
        onToggleSelectedTimeseries={handleToggleSelectedTimeseries}
      />
    </S.Wrapper>
  );
};
