import { getIsUsingUSFormat } from '@infield/features/i18n/utils';
import { DEFAULT_TIME_FORMAT } from '@infield/utils/defaultDateFormats';
import dayjs from 'dayjs';

export const getDayOfWeek = (date: string) => {
  return dayjs(date).format('ddd');
};

export const getLocalTime = (date: string) => {
  return dayjs(date).format(DEFAULT_TIME_FORMAT);
};

export const getDayAndMonth = (date?: string, isShortForm?: boolean) => {
  if (!date) {
    return '?';
  }

  const isUSFormat = getIsUsingUSFormat();
  if (isUSFormat) {
    if (isShortForm) {
      return dayjs(date).format('MMM D');
    }
    return dayjs(date).format('MMMM D');
  }

  if (isShortForm) {
    return dayjs(date).format('D MMM');
  }

  return dayjs(date).format('D MMMM');
};

export const getIsDayTime = (date?: string) => {
  const hour = dayjs(date).hour();
  return hour > 6 && hour < 17;
};
