import { Flex } from '@cognite/cogs.js-v10';
import { Card as BaseCard } from '@infield/features/ui';
import styled from 'styled-components';

export const PageContainerStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: -webkit-fill-available;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: auto;
  overflow-wrap: break-word;
  > main {
    flex: 1;
    overflow: hidden;
  }
`;

export const Container = styled(Flex).attrs({
  alignItems: 'stretch',
  gap: 12,
})`
  padding: 12px 12px;
  height: 100%;
`;

export const Card = styled(BaseCard)`
  min-width: 375px;
`;

export const PageNotFoundContainer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})`
  padding: 16px;
  height: 100%;
  width: 100%;
`;
