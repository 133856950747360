import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const FileViewerWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 1);
`;

export const ErrorWrapper = styled(Flex).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})`
  height: 100%;
  width: 100%;
`;
