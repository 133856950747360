import {
  AddIcon,
  Button,
  DeleteIcon,
  Divider,
  DragHandleVerticalIcon,
  Flex,
  Heading,
  Input,
  Tooltip,
} from '@cognite/cogs.js-v10';
import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import type { Dispatch, FC, SetStateAction } from 'react';
import { v4 as uuid } from 'uuid';

import type {
  ObservationConfigDropdownProperty,
  ObservationsConfig,
} from '../../../types';

import * as S from './elements';
import { ObservationConfigField } from './observation-config-field';

interface Props {
  prop: keyof Omit<
    ObservationsConfig,
    'files' | 'description' | 'asset' | 'troubleshooting'
  >;
  observationField: ObservationConfigDropdownProperty;
  hasAppConfigPermission: boolean;
  setObservationsConfig: Dispatch<SetStateAction<ObservationsConfig>>;
}

export const ObservationConfigDropdownField: FC<Props> = ({
  prop,
  observationField,
  hasAppConfigPermission,
  setObservationsConfig,
}) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedOptions = Array.from(observationField.options);
    const [removed] = reorderedOptions.splice(result.source.index, 1);
    reorderedOptions.splice(result.destination.index, 0, removed);

    setObservationsConfig((prevState) => {
      return {
        ...prevState,
        [prop]: {
          ...prevState[prop],
          options: reorderedOptions,
        },
      };
    });
  };

  const handleAddNewFieldOption = () => {
    setObservationsConfig((prevState) => {
      const newOptions = [
        ...prevState[prop].options,
        {
          id: uuid(),
          value: '',
          label: '',
        },
      ];
      return {
        ...prevState,
        [prop]: {
          ...prevState[prop],
          options: newOptions,
        },
      };
    });
  };

  const handleDeleteFieldOption = (optionId: string) => {
    setObservationsConfig((prevState) => {
      const newOptions = prevState[prop].options.filter(
        ({ id }) => optionId !== id
      );
      return {
        ...prevState,
        [prop]: {
          ...prevState[prop],
          options: newOptions,
        },
      };
    });
  };

  const handleFieldOptionsUpdate = (
    value: string,
    optionId: string,
    field: 'value' | 'label'
  ) => {
    setObservationsConfig((prevState) => {
      const previousOptions = prevState[prop].options;
      const newOptions = previousOptions.map((option) => {
        if (option.id === optionId) {
          return {
            ...option,
            [field]: value,
          };
        }
        return option;
      });

      return {
        ...prevState,
        [prop]: {
          ...prevState[prop],
          options: newOptions,
        },
      };
    });
  };

  const isDeleteOptionDisabled =
    !hasAppConfigPermission || observationField.options.length === 1;

  return (
    <>
      <S.AlignedAccordion
        title={prop.charAt(0).toUpperCase() + prop.slice(1)}
        type="ghost"
        indicatorPlacement="left"
      >
        <Flex direction="column" gap={16}>
          <ObservationConfigField
            prop={prop}
            observationField={observationField}
            hasAppConfigPermission={hasAppConfigPermission}
            setObservationsConfig={setObservationsConfig}
            withAccordion={false}
            showRequiredToggle
          />
          <Heading level={5}>List of options</Heading>
          <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
            <Droppable droppableId="priority-options">
              {(provided) => (
                <Flex
                  direction="column"
                  gap={12}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {observationField.options.map(
                    ({ id, value, label }, index) => (
                      <Draggable
                        key={id}
                        draggableId={id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <Flex
                            gap={16}
                            alignItems="flex-end"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Button
                              icon={<DragHandleVerticalIcon />}
                              type="ghost"
                            />
                            <Input
                              label={{ text: 'Actual value', required: true }}
                              value={value}
                              onChange={(e) =>
                                handleFieldOptionsUpdate(
                                  e.target.value,
                                  id,
                                  'value'
                                )
                              }
                              disabled={!hasAppConfigPermission}
                              status={value ? undefined : 'critical'}
                              statusText={
                                value
                                  ? undefined
                                  : 'The actual value must be filled'
                              }
                            />
                            <Input
                              label={{
                                text: 'Value to display on UI',
                                required: true,
                              }}
                              required
                              value={label}
                              onChange={(e) =>
                                handleFieldOptionsUpdate(
                                  e.target.value,
                                  id,
                                  'label'
                                )
                              }
                              disabled={!hasAppConfigPermission}
                              status={label ? undefined : 'critical'}
                              statusText={
                                label
                                  ? undefined
                                  : 'The UI value must be filled'
                              }
                            />
                            {isDeleteOptionDisabled ? (
                              <Tooltip content="At least one option is required">
                                <Button
                                  icon={<DeleteIcon />}
                                  type="secondary"
                                  onClick={() => handleDeleteFieldOption(id)}
                                  disabled={isDeleteOptionDisabled}
                                />
                              </Tooltip>
                            ) : (
                              <Button
                                icon={<DeleteIcon />}
                                type="secondary"
                                onClick={() => handleDeleteFieldOption(id)}
                                disabled={isDeleteOptionDisabled}
                              />
                            )}
                          </Flex>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
          <S.AddOptionButton
            icon={<AddIcon />}
            iconPlacement="right"
            type="secondary"
            onClick={() => handleAddNewFieldOption()}
            disabled={!hasAppConfigPermission}
          >
            Add option
          </S.AddOptionButton>
        </Flex>
      </S.AlignedAccordion>
      <Divider />
    </>
  );
};
