import { ChevronRightIcon, Flex, Menu } from '@cognite/cogs.js-v10';
import { useIsChecklistAdminQuery } from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { preventViewportOverflowOptions } from '@infield/utils/tippy-utils';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  statusMenu: JSX.Element;
  subMenu: JSX.Element;
  handleSetRemainingTasksClick: () => void;
  handleSetChecklistClick: () => void;
  disableSetRemainingTaskStatuses: boolean;
  isMarkedAsDone: boolean;
}

export const ActionsMenu: FC<Props> = ({
  statusMenu,
  subMenu,
  handleSetRemainingTasksClick,
  handleSetChecklistClick,
  disableSetRemainingTaskStatuses,
  isMarkedAsDone,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);
  const isDesktop = useIsDesktop();
  const { data: isChecklistAdmin } = useIsChecklistAdminQuery();

  const taskStatusActionDisabledHelpText = isMarkedAsDone
    ? t(
        'CHECKLIST_CHANGE_REMAINING_TASKS_STATUS_DONE_DESCRIPTION',
        'Checklist marked as done'
      )
    : t(
        'CHECKLIST_CHANGE_REMAINING_TASKS_STATUS_DISABLED_DESCRIPTION',
        'All statuses are set'
      );

  return isDesktop ? (
    <S.ActionMenu $isDesktop={isDesktop}>
      <Menu.Submenu
        disabled={disableSetRemainingTaskStatuses}
        content={statusMenu}
        popperOptions={preventViewportOverflowOptions}
      >
        <Flex direction="column">
          {t(
            'CHECKLIST_CHANGE_REMAINING_TASKS_STATUS',
            'Set remaining tasks to'
          )}
          {disableSetRemainingTaskStatuses && (
            <S.DescriptionText size="x-small">
              {taskStatusActionDisabledHelpText}
            </S.DescriptionText>
          )}
        </Flex>
      </Menu.Submenu>
      {isChecklistAdmin && (
        <Menu.Submenu
          content={subMenu}
          popperOptions={preventViewportOverflowOptions}
        >
          {t('CHECKLIST_MARK_STATUS', 'Set checklist to')}
        </Menu.Submenu>
      )}
    </S.ActionMenu>
  ) : (
    <S.ActionMenu $isDesktop={isDesktop}>
      <Menu.Item
        onClick={handleSetRemainingTasksClick}
        icon={<ChevronRightIcon />}
        iconPlacement="right"
        disabled={disableSetRemainingTaskStatuses}
        description={
          disableSetRemainingTaskStatuses
            ? t(
                'CHECKLIST_CHANGE_REMAINING_TASKS_STATUS_DISABLED_DESCRIPTION',
                'All statuses are set'
              )
            : undefined
        }
      >
        {t('CHECKLIST_CHANGE_REMAINING_TASKS_STATUS', 'Set remaining tasks to')}
      </Menu.Item>
      {isChecklistAdmin && (
        <Menu.Item
          data-testid="checklist-mobile-set-checklist-to-button"
          onClick={handleSetChecklistClick}
          icon={<ChevronRightIcon />}
          iconPlacement="right"
        >
          {t('CHECKLIST_MARK_STATUS', 'Set checklist to')}
        </Menu.Item>
      )}
    </S.ActionMenu>
  );
};
