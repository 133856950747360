import {
  EmptyState,
  PreviewIllustration,
  WiFiIllustration,
} from '@cognite/cogs.js-v10';
import { ActivityDetails } from '@infield/features/activities';
import { Asset } from '@infield/features/asset';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { EmptyStateWrapper } from '@infield/features/ui/table-empty-state/elements';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import type { FC } from 'react';

import * as S from './elements';

type Props = {
  openedActivityDetailsId: string;
  openedAssetDetailsId: string;
  handleOnAssetClick: (assetExternalId: string) => void;
  setOpenedActivityDetailsId: (id: string) => void;
  setOpenedAssetDetailsId: (id: string) => void;
};

export const AssetContainerContent: FC<Props> = ({
  openedActivityDetailsId,
  openedAssetDetailsId,
  handleOnAssetClick,
  setOpenedActivityDetailsId,
  setOpenedAssetDetailsId,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const { isOnline } = useNetworkStatusContext();

  if (openedAssetDetailsId) {
    return (
      <S.AssetContainer>
        <Asset
          assetExternalId={openedAssetDetailsId}
          onClose={() => setOpenedAssetDetailsId('')}
        />
      </S.AssetContainer>
    );
  }

  if (openedActivityDetailsId) {
    return (
      <S.ActivityDetailsContainer>
        <ActivityDetails
          activityId={openedActivityDetailsId}
          onAssetClick={handleOnAssetClick}
          onClose={() => setOpenedActivityDetailsId('')}
        />
      </S.ActivityDetailsContainer>
    );
  }

  if (!isOnline) {
    return (
      <EmptyStateWrapper>
        <EmptyState
          illustration={<WiFiIllustration />}
          title={t(
            'CHECKLIST_DESKTOP_PAGE_NO_NETWORK_CONNECTION_TITLE',
            "You're offline"
          )}
          description={t(
            'CHECKLIST_DESKTOP_PAGE_NO_NETWORK_CONNECTION_BODY',
            'Connect to your network to access asset explorer.'
          )}
        />
      </EmptyStateWrapper>
    );
  }

  return (
    <EmptyStateWrapper>
      <EmptyState
        illustration={<PreviewIllustration />}
        title={t(
          'CHECKLIST_DESKTOP_PAGE_EXPLORE_YOUR_DATA_TITLE',
          'Explore your data'
        )}
        description={t(
          'CHECKLIST_DESKTOP_PAGE_EXPLORE_YOUR_DATA_BODY',
          'Select a link to view asset information.'
        )}
      />
    </EmptyStateWrapper>
  );
};
