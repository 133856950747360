import { DatapointAggregate, DoubleDatapoint } from '@cognite/sdk-beta';

import { hexToRGBA } from '../../../utils';

import { convertLineStyle } from './convertLineStyle';
import { hasRawPoints } from './hasRawPoints';
import { ScatterType, SeriesData, SeriesDataCollection } from './types';

const getMode = (seriesData: SeriesData) => {
  if (seriesData.style.lineStyle === 'none') {
    return 'markers';
  }
  if (!hasRawPoints(seriesData.datapoints)) {
    return 'lines';
  }
  return 'lines+markers';
};

const createGoodTrace = (
  seriesData: SeriesData,
  index: number,
  xValues: Date[],
  yVlaues: (number | null)[],
  highlightedTimeseriesId?: string,
  scatterType: ScatterType = 'scattergl'
) => {
  const color =
    highlightedTimeseriesId !== undefined &&
    highlightedTimeseriesId !== seriesData.id
      ? hexToRGBA(seriesData.style.color, 0.15) || seriesData.style.color
      : seriesData.style.color;

  return {
    type: scatterType,
    mode: getMode(seriesData),
    opacity: seriesData.isDataOutdated ? 0.5 : 1,
    name: seriesData.name,
    marker: {
      color,
      size: (seriesData.style.size || 1) * 2 + 2,
    },
    fill: 'none',
    line: {
      shape: seriesData.interpolation,
      color,
      width:
        highlightedTimeseriesId === seriesData.id
          ? 2
          : seriesData.style.size || 1,
      dash: convertLineStyle(seriesData.style.lineStyle) || 'solid',
    },
    yaxis: `y${index !== 0 ? index + 1 : ''}`,
    hoverinfo: 'skip',
    x: xValues,
    y: yVlaues,
  };
};

const createUncertainTrace = (
  seriesData: SeriesData,
  index: number,
  xValues: Date[],
  yValues: (number | null)[],
  highlightedTimeseriesId?: string,
  scatterType: ScatterType = 'scattergl'
) => {
  const color =
    highlightedTimeseriesId !== undefined &&
    highlightedTimeseriesId !== seriesData.id
      ? hexToRGBA(seriesData.style.color, 0.15) || seriesData.style.color
      : hexToRGBA(seriesData.style.color, 0.25);

  return {
    type: scatterType,
    mode: getMode(seriesData),
    opacity: seriesData.isDataOutdated ? 0.5 : 1,
    name: seriesData.name,
    marker: {
      symbol: 'square',
      color,
      size: (seriesData.style.size || 1) * 2 + 2,
    },
    fill: 'none',
    line: {
      shape: seriesData.interpolation,
      color,
      width:
        highlightedTimeseriesId === seriesData.id
          ? 2
          : seriesData.style.size || 1,
      dash: convertLineStyle(seriesData.style.lineStyle) || 'solid',
    },
    yaxis: `y${index !== 0 ? index + 1 : ''}`,
    hoverinfo: 'skip',
    x: xValues,
    y: yValues,
  };
};

const createConnectingTrace = (
  seriesData: SeriesData,
  index: number,
  xValues: Date[],
  yValues: (number | null)[],
  highlightedTimeseriesId?: string,
  scatterType: ScatterType = 'scattergl'
) => {
  const color =
    highlightedTimeseriesId !== undefined &&
    highlightedTimeseriesId !== seriesData.id
      ? hexToRGBA(seriesData.style.color, 0.15) || seriesData.style.color
      : hexToRGBA(seriesData.style.color, 0.25);

  return {
    type: scatterType,
    mode: 'lines',
    opacity: seriesData.isDataOutdated ? 0.5 : 1,
    name: seriesData.name,
    fill: 'none',
    line: {
      shape: seriesData.interpolation,
      color,
      width:
        highlightedTimeseriesId === seriesData.id
          ? 2
          : seriesData.style.size || 1,
      dash: convertLineStyle(seriesData.style.lineStyle) || 'solid',
    },
    yaxis: `y${index !== 0 ? index + 1 : ''}`,
    hoverinfo: 'skip',
    x: xValues,
    y: yValues,
  };
};

const createHoverTrace = (
  seriesData: SeriesData,
  index: number,
  xValues: Date[],
  yValues: (number | null)[],
  unitLabel?: string,
  scatterType: ScatterType = 'scattergl'
) => {
  const hovertemplate = `%{y:.6}${
    unitLabel || ''
  } &#183; <span style="color:#8c8c8c">%{fullData.name}</span><extra></extra>`;
  return {
    type: scatterType,
    mode: 'none',
    name: seriesData.name,
    fill: 'none',
    yaxis: `y${index !== 0 ? index + 1 : ''}`,
    x: xValues,
    y: yValues,
    hovertemplate,
    hoverlabel: {
      bgcolor: '#ffffff',
      bordercolor: seriesData.style.color,
      font: {
        color: '#333333',
      },
    },
  };
};

const createMinMaxTrace = (
  seriesData: SeriesData,
  index: number,
  xValues: Date[],
  yVlaues: (number | null)[]
) => {
  return {
    type: 'scatter',
    mode: 'none',
    name: seriesData.name,
    fillcolor: hexToRGBA(seriesData.style.color, 0.2),
    fill: 'toself',
    yaxis: `y${index !== 0 ? index + 1 : ''}`,
    hoverinfo: 'skip',
    x: xValues,
    y: yVlaues,
  };
};

const getMainStatusCategoryFromCode = (code: number) => {
  const hex = code.toString(16);

  switch (hex[0]) {
    case '8':
      return 'bad';
    case '4':
      return 'uncertain';
    default:
      return 'good';
  }
};

const getMainStatusCategoryFromAggregatedDatapoint = (
  datapoint: DatapointAggregate
) => {
  if (!datapoint.count) {
    throw new Error('DatapointAggregate must have count');
  }

  const percentageUncertain =
    ((datapoint.countUncertain || 0) / datapoint.count) * 100;
  const percentageBad = ((datapoint.countBad || 0) / datapoint.count) * 100;

  if (percentageUncertain === 100) {
    return 'uncertain';
  }

  if (percentageBad + percentageUncertain >= 40) {
    return 'bad';
  }

  if (percentageBad + percentageUncertain >= 15) {
    return 'uncertain';
  }

  return 'good';
};

const getDatapointMainStatusCategory = (
  datapoint: DoubleDatapoint | DatapointAggregate
) => {
  if ('count' in datapoint) {
    return getMainStatusCategoryFromAggregatedDatapoint(datapoint);
  }

  if ('status' in datapoint && datapoint.status !== undefined) {
    return getMainStatusCategoryFromCode(datapoint.status.code);
  }

  // Good datapoints omits status property
  return 'good';
};

const splitDatapointsByStatus = (seriesData: SeriesData) => {
  const dataPointsSplit: any = {
    hover: [],
    good: [],
    uncertain: [],
    connecting: [],
  };

  seriesData.datapoints.forEach((datapoint, index) => {
    const datapointStatusMainCategory =
      getDatapointMainStatusCategory(datapoint);

    if (datapointStatusMainCategory === 'bad') return;

    dataPointsSplit.hover.push(datapoint);

    dataPointsSplit[datapointStatusMainCategory].push(datapoint);

    const nextDatapoint = seriesData.datapoints[index + 1];
    if (!nextDatapoint) {
      return;
    }

    const nextDatapointStatusMainCategory =
      getDatapointMainStatusCategory(nextDatapoint);

    if (datapointStatusMainCategory !== nextDatapointStatusMainCategory) {
      dataPointsSplit[datapointStatusMainCategory].push({
        timestamp: datapoint.timestamp,
        value: null,
      });

      if (nextDatapointStatusMainCategory !== 'bad') {
        dataPointsSplit.connecting.push(datapoint);
        dataPointsSplit.connecting.push(nextDatapoint);
        dataPointsSplit.connecting.push({
          timestamp: nextDatapoint.timestamp,
          value: null,
        });
      }
    }
  });

  return dataPointsSplit;
};

const datapointToXY = (datapoints: (DoubleDatapoint | DatapointAggregate)[]) =>
  datapoints.reduce(
    (acc, datapoint) => {
      acc.x.push(datapoint.timestamp);
      acc.y.push(
        'average' in datapoint && datapoint.average !== undefined
          ? datapoint.average
          : (datapoint as DoubleDatapoint).value
      );
      return acc;
    },
    { x: [] as Date[], y: [] as (number | null)[] }
  );

const datapointToMinMax = (datapoints: DatapointAggregate[]) =>
  datapoints.reduce(
    (acc, datapoint) => {
      if (datapoint.min !== undefined && datapoint.max !== undefined) {
        acc.x.push(datapoint.timestamp);
        acc.min.push(datapoint.min ?? null);
        acc.max.push(datapoint.max ?? null);
      }
      return acc;
    },
    {
      x: [] as Date[],
      min: [] as (number | null)[],
      max: [] as (number | null)[],
    }
  );

const splitSeriesByStatus = (
  seriesData: SeriesData,
  index: number,
  showMinMax: boolean,
  highlightedTimeseriesId?: string,
  unitLabel?: string,
  scatterType: ScatterType = 'scattergl'
) => {
  const traces: any = [];

  const datapointsByStatus = splitDatapointsByStatus(seriesData);

  const goodDatapointValues = datapointToXY(datapointsByStatus.good);
  traces.push(
    createGoodTrace(
      seriesData,
      index,
      goodDatapointValues.x,
      goodDatapointValues.y,
      highlightedTimeseriesId,
      scatterType
    )
  );

  const uncertainDatapointValues = datapointToXY(datapointsByStatus.uncertain);
  traces.push(
    createUncertainTrace(
      seriesData,
      index,
      uncertainDatapointValues.x,
      uncertainDatapointValues.y,
      highlightedTimeseriesId,
      scatterType
    )
  );

  const hoverDatapointValues = datapointToXY(datapointsByStatus.hover);
  traces.push(
    createHoverTrace(
      seriesData,
      index,
      hoverDatapointValues.x,
      hoverDatapointValues.y,
      unitLabel,
      scatterType
    )
  );

  if (seriesData.style.lineStyle !== 'none') {
    const connectingDatapointValues = datapointToXY(
      datapointsByStatus.connecting
    );
    traces.push(
      createConnectingTrace(
        seriesData,
        index,
        connectingDatapointValues.x,
        connectingDatapointValues.y,
        highlightedTimeseriesId,
        scatterType
      )
    );
  }

  if (showMinMax && !hasRawPoints(seriesData.datapoints)) {
    const minMax = datapointToMinMax(datapointsByStatus.hover);
    const xValues = minMax.x.concat([...minMax.x].reverse());
    const yValues = minMax.max.concat([...minMax.min].reverse());

    traces.push(createMinMaxTrace(seriesData, index, xValues, yValues));
  }

  return traces;
};

export const formatPlotlyData = (
  seriesDataCollection: SeriesDataCollection[],
  showMinMax: boolean,
  highlightedTimeseriesId: string | undefined,
  scatterType: ScatterType = 'scattergl'
): Plotly.Data[] => {
  const groupedAggregateTraces = seriesDataCollection.map((collection, index) =>
    collection.series.map((seriesData) => {
      if (!collection.isVisible) {
        return [];
      }
      const valueTraces = splitSeriesByStatus(
        seriesData,
        index,
        showMinMax,
        highlightedTimeseriesId,
        collection.unit,
        scatterType
      );

      return valueTraces;
    })
  );

  return groupedAggregateTraces.flat(2) as Plotly.Data[];
};
