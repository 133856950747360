import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { CogniteClient } from '@cognite/sdk';
import { SDKProvider } from '@cognite/sdk-provider';

import {
  isAssetAcdmInstance,
  isEventAcdmInstance,
  isFdmInstance,
} from '../../types';
import { ReactContainerRenderContentProps } from '../ReactContainer';

import AssetPropertyList from './AssetPropertyList/AssetPropertyList';
import EventPropertyList from './EventPropertyList/EventPropertyList';
import FdmPropertyList from './FdmPropertyList/FdmPropertyList';
import { PropertyListContainerProps } from './types';

type PropertyListContentProps = Pick<
  ReactContainerRenderContentProps,
  | 'width'
  | 'height'
  | 'unscaledWidth'
  | 'unscaledHeight'
  | 'setLoadingStatus'
  | 'onContentSizeChange'
  | 'shouldAutoSize'
> &
  Pick<PropertyListContainerProps, 'instance' | 'properties'> & {
    containerPadding: number;
  };

const PropertyListContent: React.FC<PropertyListContentProps> = ({
  instance,
  ...otherProps
}) => {
  // TODO: This is very naive and should be improved
  if (
    isAssetAcdmInstance(instance) ||
    (isFdmInstance(instance) &&
      instance.viewExternalId.toLowerCase().includes('asset'))
  ) {
    return <AssetPropertyList {...otherProps} instance={instance} />;
  }

  if (isEventAcdmInstance(instance)) {
    return <EventPropertyList {...otherProps} instance={instance} />;
  }

  if (instance.source === 'fdm') {
    return <FdmPropertyList {...otherProps} instance={instance} />;
  }

  return null;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 10 * 60 * 1000, // Pretty long, 600 seconds
    },
  },
});

const WrappedPropertyListContent = ({
  sdk,
  ...props
}: PropertyListContentProps & {
  sdk: CogniteClient;
}): JSX.Element => {
  return (
    <SDKProvider sdk={sdk}>
      <QueryClientProvider client={queryClient}>
        <PropertyListContent {...props} />
      </QueryClientProvider>
    </SDKProvider>
  );
};

export default WrappedPropertyListContent;
