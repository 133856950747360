import { TagChip } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ChipDropdownWrapper = styled.div`
  min-width: 65px;
`;

export const TagChipWithIconOnRight = styled(TagChip)`
  cursor: pointer;
  flex-direction: row-reverse;
`;
