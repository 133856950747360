import { useFlag } from '@cognite/react-feature-flags';
import { loadedChecklistIdsAtom } from '@infield/features/checklist/state';
import { FileViewerModal } from '@infield/features/file-viewer-modal';
import { useInfieldTranslation } from '@infield/features/i18n/use-infield-translation';
import { GlobalOverlay } from '@infield/features/ui';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { NetworkStatusBanner } from '@infield/providers/network-status-provider/network-status-banner';
import { useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { PageContainerStyle } from './elements';
import { TopBarPage } from './topbar';

export type MobileVersionPageType =
  | 'mobile-checklist'
  | 'mobile-observation'
  | 'mobile-explore'
  | 'other';

export const PageLayout: FC<{ page?: MobileVersionPageType }> = ({ page }) => {
  const { activityId } = useParams();
  const { isEnabled: isAkerbpCustomCode } = useFlag(
    'INFIELD.akerbp_custom_code',
    { forceRerender: true, fallback: false }
  );
  const checklistIds = useRecoilValue(loadedChecklistIdsAtom);

  const {
    isOnline,
    // isNetworkSlow,
    isBackOnline,
  } = useNetworkStatusContext();
  const { getInitialLanguage } = useInfieldTranslation();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    getInitialLanguage();
  }, [getInitialLanguage]);

  const isChecklistDataLoaded = useMemo(() => {
    return Boolean(activityId && checklistIds.includes(activityId));
  }, [activityId, checklistIds]);

  return (
    <PageContainerStyle>
      <TopBarPage page={page} />
      <NetworkStatusBanner
        isOnline={isOnline}
        // isNetworkSlow={isNetworkSlow}
        isBackOnline={isBackOnline}
        isDesktop={isDesktop}
        isChecklistDataLoaded={isChecklistDataLoaded}
        page={page}
      />
      <main>
        <Outlet />
      </main>
      {/* Global components */}
      <FileViewerModal isAkerbpCustomCode={isAkerbpCustomCode} />
      <GlobalOverlay
        isOnline={isOnline}
        isOnChecklistPageOnMobile={
          page === 'mobile-checklist' && isChecklistDataLoaded
        }
      />
    </PageContainerStyle>
  );
};
