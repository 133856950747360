import { Flex, Menu } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

const MOBILE_MENU_PADDING = 16;

export const FilterGroup = styled(Flex).attrs({
  direction: 'row',
  gap: 8,
})`
  padding: 8px 12px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
  border-top: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const StyledMenu = styled(Menu)<{
  $isDesktop: boolean;
}>`
  max-height: 235px;
  overflow-y: auto;
  width: 288px;
  z-index: ${z.ACTION_MENU};

  ${({ $isDesktop }) =>
    $isDesktop
      ? ''
      : `
      position: absolute;
      bottom: ${MOBILE_MENU_PADDING}px;
      left: ${MOBILE_MENU_PADDING}px;
      max-width: 100vw;
      width: calc(100vw - ${MOBILE_MENU_PADDING * 2}px);
      .cogs-tooltip__content + div {
        display: none;
      }
  `};
`;
