import { FileInfo } from '@cognite/sdk';

const getFileDataCacheKey = (file: FileInfo): string =>
  `${file.id}-${
    file.lastUpdatedTime instanceof Date
      ? file.lastUpdatedTime.getTime()
      : file.lastUpdatedTime
  }`;

export default getFileDataCacheKey;
