import { Pagination as CogsPagination } from '@cognite/cogs.js-v10';
import { useIsDesktop } from '@infield/hooks';
import type { FC } from 'react';
import styled from 'styled-components';

interface Props {
  onPageChange: (pageNumber: number) => void;
  page: number;
  totalPages: number;
  isAkerbpCustomCode: boolean;
}

export const FileViewerPagination: FC<Props> = ({
  onPageChange,
  page,
  totalPages,
  isAkerbpCustomCode,
}) => {
  const isDesktop = useIsDesktop();

  if (totalPages > 1) {
    return (
      <ToolBar $showFooter={isDesktop && isAkerbpCustomCode}>
        <CogsPagination
          totalPages={totalPages}
          initialCurrentPage={page}
          currentPage={page}
          onPageChange={onPageChange}
          hideItemsPerPage
          size="medium"
          elevated
        />
      </ToolBar>
    );
  }
  return null;
};

const ToolBar = styled.div<{ $showFooter: boolean }>`
  position: absolute;
  isolation: isolate;
  left: 50%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  bottom: ${({ $showFooter }) => ($showFooter ? '74px' : '10px')};
`;
