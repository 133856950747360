import { Body, Tabs as CogsTabs, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
`;

export const TabPane = styled(CogsTabs.Tab)`
  padding: 8px;
`;

export const TabPanelWrapper = styled.div`
  height: 100%;
  overflow: auto;
  margin-top: 8px;
`;

export const TitleContainer = styled(Flex).attrs({
  alignItems: 'center',
})`
  height: 100%;
  padding: 0px 16px;
`;

export const PermissionInfoContainer = styled(Flex).attrs({
  alignItems: 'center',
})`
  height: 100%;
  padding: 0px 16px;
`;

export const NotPermittedText = styled(Body).attrs({ size: 'x-small' })`
  color: ${({ theme }) => theme['text-icon--status-critical']};
`;

export const PermittedText = styled(Body).attrs({ size: 'x-small' })`
  color: ${({ theme }) => theme['text-icon--status-success']};
`;
