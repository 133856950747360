import type { ChecklistItem } from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

type Props = {
  checklistItemExternalId?: string;
};

export const useChecklistItemObservations = ({
  checklistItemExternalId,
}: Props) => {
  const { checklistService } = useFDMServices();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const filters: Filters[] = [];

  if (checklistItemExternalId) {
    filters.push({
      equals: {
        property: 'externalId',
        eq: checklistItemExternalId,
      },
    });
  }

  const checklistItemFilter: Filters = {
    and: [...filters],
  };

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  return useQuery<ChecklistItem[]>(
    [QueryKeys.CHECKLIST_ITEM_OBSERVATIONS, checklistItemFilter],
    async () => {
      sliTimerStartTime.current = Date.now();

      return checklistService.getChecklistItemObservations(checklistItemFilter);
    },
    {
      onError: (err) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.CHECKLIST_ITEM_OBSERVATIONS,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.CHECKLIST_ITEM_OBSERVATIONS, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.CHECKLIST_ITEM_OBSERVATIONS, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: Boolean(checklistItemExternalId),
    }
  );
};
