import type { Measurement, TemplateItem } from '@cognite/apm-client';
import { Flex, Heading, InfoIcon, Tooltip } from '@cognite/cogs.js-v10';
import type { FdmTimeSeries } from '@cognite/fdm-client';
import type { Asset } from '@cognite/sdk';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  MeasurementNumericConfiguration,
  useMeasurementsUpsert,
} from '@infield/features/measurements';
import { TaskFormBlockTitleWrapper } from '@infield/features/task/task-form/elements';
import { useTimeseriesUpsert } from '@infield/features/timeseries';
import type { FC } from 'react';

interface Props {
  task: TemplateItem;
  taskTag?: Asset;
}

export const MeasurementNumericConfigurationList: FC<Props> = ({
  task,
  taskTag,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);

  const { mutateAsync: upsertMeasurement, isLoading: isUpsertingMeasurement } =
    useMeasurementsUpsert();
  const measurementList = task.measurements?.filter(
    (measurement) => measurement.type === 'numerical'
  );
  const { mutateAsync: upsertTimeseries } = useTimeseriesUpsert();

  const getMinOrMaxValues = (value: string | undefined) => {
    if (value === undefined) {
      return null;
    }
    if (value === '') {
      return null;
    }
    return Number(value);
  };

  const handleTimeseriesSelect = (
    timeseries: FdmTimeSeries | null,
    measurementExternalId: string
  ) => {
    const updatedMeasurement: Measurement = {
      type: 'numerical',
      externalId: measurementExternalId,
      timeseries,
      min: getMinOrMaxValues(timeseries?.metadata?.minVal),
      max: getMinOrMaxValues(timeseries?.metadata?.maxVal),
    };

    upsertMeasurement({
      measurementToUpsert: [updatedMeasurement],
    });
  };

  const handleRangeChange = (
    measurementExternalId: string,
    min?: number | null,
    max?: number | null,
    timeseries?: FdmTimeSeries
  ) => {
    const updatedMeasurement: Measurement = {
      type: 'numerical',
      externalId: measurementExternalId,
      min,
      max,
    };

    upsertMeasurement(
      {
        measurementToUpsert: [updatedMeasurement],
      },
      {
        onSuccess: () => {
          if (timeseries) {
            const updatedMetadata = { ...timeseries.metadata };

            if (min === null) {
              updatedMetadata.minVal = '';
            } else if (min !== undefined) {
              updatedMetadata.minVal = min.toString();
            }

            if (max === null) {
              updatedMetadata.maxVal = '';
            } else if (max !== undefined) {
              updatedMetadata.maxVal = max.toString();
            }

            const updatedTimeseries: FdmTimeSeries = {
              ...timeseries,
              metadata: updatedMetadata,
            };

            upsertTimeseries({ timeseriesToUpsert: updatedTimeseries });
          }
        },
      }
    );
  };

  return (
    <Flex direction="column" gap={12}>
      <TaskFormBlockTitleWrapper>
        <Heading
          data-testid="task-form-numerical-reading-list-header"
          level={6}
        >
          {t(
            'TEMPLATE_TASK_FORM_NUMERICAL_READING_EDIT_TITLE',
            'Numerical reading'
          )}
        </Heading>
        <Tooltip
          content={t(
            'TEMPLATE_TASK_FORM_NUMERICAL_READING_EDIT_INFO',
            'Connect or create a time series.'
          )}
        >
          <InfoIcon />
        </Tooltip>
      </TaskFormBlockTitleWrapper>
      {measurementList?.map((measurement) => (
        <div key={measurement.externalId}>
          <MeasurementNumericConfiguration
            measurement={measurement}
            isUpsertingMeasurement={isUpsertingMeasurement}
            relevantAsset={taskTag}
            onTimeseriesSelect={(timeseries) =>
              handleTimeseriesSelect(timeseries, measurement.externalId)
            }
            onRangeChange={(min, max, selectedTimeseries) =>
              handleRangeChange(
                measurement.externalId,
                min,
                max,
                selectedTimeseries
              )
            }
          />
        </div>
      ))}
    </Flex>
  );
};
