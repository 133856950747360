import type {
  CogniteClient,
  Datapoints,
  DatapointsMultiQuery,
  DatapointsQueryExternalId,
} from '@cognite/sdk';

import { filterDatapointStrings } from './utils';

/*
   DatapointService
   A temporary service to support instance ID, once the support is added to the SDK this service will be removed.
   TODO: INFIELD-2624 Remove this service once the SDK supports instance ID. 
*/
export class DatapointService {
  client;

  constructor(client: CogniteClient) {
    this.client = client;
  }

  async retrieveLatest(externalIds: string[], timeseriesSpace: string) {
    const {
      data: { items },
    } = await this.client.post<{ items: Datapoints[] }>(
      `api/v1/projects/${this.client.project}/timeseries/data/latest`,
      {
        data: {
          items: externalIds.map((externalId) => ({
            instanceId: {
              externalId,
              space: timeseriesSpace,
            },
          })),
        },
        headers: {
          'cdf-version': 'alpha',
        },
      }
    );
    return items;
  }

  retrieve(isIdm: boolean, timeseriesSpace?: string) {
    const { client } = this;

    if (!isIdm || !timeseriesSpace) {
      return (query: DatapointsMultiQuery) =>
        new Promise((resolve, reject) => {
          client.datapoints
            .retrieve(query)
            .then((data) => {
              resolve(
                filterDatapointStrings(data as Datapoints[]).map((item) => ({
                  ...item,
                  datapoints: item.datapoints.map((dp) => ({
                    ...dp,
                    timestamp: new Date(dp.timestamp),
                  })),
                }))
              );
            })
            .catch(reject);
        });
    }

    return (query: DatapointsMultiQuery) =>
      new Promise((resolve, reject) => {
        client
          .post<{ items: Datapoints[] }>(
            `api/v1/projects/${this.client.project}/timeseries/data/list`,
            {
              data: {
                ...query,
                items: query.items.map((item) => {
                  const typedItem = item as DatapointsQueryExternalId;
                  return {
                    instanceId: {
                      externalId: typedItem.externalId,
                      space: timeseriesSpace,
                    },
                  };
                }),
              },
              headers: {
                'cdf-version': 'alpha',
              },
            }
          )
          .then(({ data: { items } }) => {
            resolve(
              filterDatapointStrings(items).map((item) => ({
                ...item,
                datapoints: item.datapoints.map((dp) => ({
                  ...dp,
                  timestamp: new Date(dp.timestamp),
                })),
              }))
            );
          })
          .catch(reject);
      });
  }
}
