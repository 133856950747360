import chunk from 'lodash/chunk';

type PromiseFunction<T> = () => Promise<T>;

export const executeFunction = async <T>(
  functions: PromiseFunction<T>[][],
  results: T[][]
): Promise<T[][]> => {
  const functionsChunk = functions.shift();

  if (functionsChunk) {
    const result = await Promise.all(functionsChunk.map((fn) => fn()));
    return executeFunction(functions, [...results, result]);
  }

  return results;
};

/* Example usage:
const functions = [0, 1, 2, 3, 4, 5, 6, 7].map(
  () => () => asyncFunctionToExecute()
);
const result = await limitConcurrency(functions, 3);
*/

export const limitConcurrency = async <T>(
  functions: PromiseFunction<T>[],
  limit = 4
): Promise<T[]> => {
  const chunkedFunctions = chunk(functions, limit);

  const result = await executeFunction(chunkedFunctions, []);

  return result.flat();
};
