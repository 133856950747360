import Konva from 'konva';
import { v4 as uuid } from 'uuid';

import { UnifiedViewer } from '../..';
import { DEFAULT_STROKE_SCALE_ENABLED } from '../annotations/constants';
import { AnnotationType, Position } from '../annotations/types';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import UnifiedViewerEventType from '../UnifiedViewerEventType';
import { UnifiedViewerPointerEvent } from '../UnifiedViewerRenderer/UnifiedEventHandler';
import { isPrimaryMouseButtonPressed } from '../utils/eventUtils';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';

import { ContainerAttachmentHelper } from './ContainerAttachmentHelper';
import getShapeEventPropsFromKonvaShape from './getShapeEventPropsFromKonvaShape';
import Tool from './Tool';
import { RectangleToolConfig, ToolType } from './types';

export default class RectangleTool extends Tool {
  public readonly type = ToolType.RECTANGLE;
  public override readonly cursor = 'crosshair';
  private config: RectangleToolConfig;
  private pendingNode: Konva.Rect | null = null;
  private pendingNodeStartPos: Position | null = null;
  private containerAttachmentHelper: ContainerAttachmentHelper;

  public constructor({
    unifiedViewer,
    config,
  }: {
    unifiedViewer: UnifiedViewer;
    config: RectangleToolConfig;
  }) {
    super(unifiedViewer);
    this.containerAttachmentHelper = new ContainerAttachmentHelper(
      unifiedViewer
    );
    this.config = config;
    this.setConfig(config);
    this.containerAttachmentHelper.start();
  }

  public setConfig = (options: RectangleToolConfig): void => {
    this.config = options;
  };

  private getRelativePointerPosition = (): Position | null => {
    return this.unifiedViewer.stage.getRelativePointerPosition();
  };

  public override onMouseDown = (e: UnifiedViewerPointerEvent): void => {
    e.target.stopDrag();

    this.pendingNodeStartPos = this.getRelativePointerPosition();

    if (this.pendingNodeStartPos === null) {
      return;
    }

    this.pendingNode = new Konva.Rect({
      [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
      id: uuid(),
      x: this.pendingNodeStartPos.x,
      y: this.pendingNodeStartPos.y,
      width: 1,
      height: 1,
      fill: this.config?.fill,
      stroke: this.config?.stroke,
      strokeWidth: this.config?.strokeWidth,
      strokeScaleEnabled:
        this.config?.shouldEnableStrokeScale ?? DEFAULT_STROKE_SCALE_ENABLED,
      userGenerated: true,
      name: 'user-drawing',
      source: 'RectTool',
      containerId: undefined,
      annotationType: AnnotationType.RECTANGLE,
      isSelectable: true,
      isDraggable: true,
      isResizable: true,
    });

    this.containerAttachmentHelper.lockTarget();
    this.unifiedViewer.setDirtyNodes([this.pendingNode]);
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_TOOL_START);
  };

  public override onMouseMove = (): void => {
    if (this.pendingNode === null || this.pendingNodeStartPos === null) {
      return;
    }

    const mousePosition = this.getRelativePointerPosition();
    if (mousePosition === null) {
      return;
    }
    this.pendingNode.position({
      x: Math.min(this.pendingNodeStartPos.x, mousePosition.x),
      y: Math.min(this.pendingNodeStartPos.y, mousePosition.y),
    });
    this.pendingNode.size({
      width: Math.abs(mousePosition.x - this.pendingNodeStartPos.x),
      height: Math.abs(mousePosition.y - this.pendingNodeStartPos.y),
    });
  };

  public override onMouseUp = (): void => {
    this.onComplete();
  };

  public override onMouseEnter = (e: MouseEvent): void => {
    if (this.pendingNode && !isPrimaryMouseButtonPressed(e)) {
      this.onComplete();
    }
  };

  public override onComplete = (): void => {
    if (this.pendingNode === null) {
      return;
    }
    getMetricsLogger()?.trackEvent(TrackedEventType.CREATE_RECTANGLE, {
      ...getShapeEventPropsFromKonvaShape(this.pendingNode),
    });
    this.containerAttachmentHelper.end([this.pendingNode]);
    this.unifiedViewer.commitDirtyNodes();
    this.pendingNode = null;
    this.pendingNodeStartPos = null;
    this.containerAttachmentHelper.start();

    this.unifiedViewer.emit(UnifiedViewerEventType.ON_TOOL_END);
  };

  public override onDestroy = (): void => {
    this.containerAttachmentHelper.onDestroy();
  };
}
