enum UnifiedViewerEventType {
  ON_UPDATE_REQUEST = 'onUpdateRequest',
  ON_DELETE_REQUEST = 'onDeleteRequest',
  ON_CONTAINER_LOAD = 'onContainerLoad',
  ON_CONTAINER_ERROR = 'onContainerError',
  ON_ANNOTATIONS_LOAD = 'onAnnotationsLoad',
  ON_NODES_LOAD = 'onNodesLoad',
  ON_ZOOM_START = 'onZoomStart',
  ON_ZOOM_CHANGE = 'onZoomChange',
  ON_ZOOM_END = 'onZoomEnd',
  ON_PAN_START = 'onPanStart',
  ON_PAN_MOVE = 'onPanMove',
  ON_PAN_END = 'onPanEnd',
  ON_VIEWPORT_CHANGE = 'onViewportChange',
  ON_DRAG_START = 'onDragStart',
  ON_DRAG_MOVE = 'onDragMove',
  ON_DRAG_END = 'onDragEnd',
  ON_TRANSFORM_START = 'onTransformStart',
  ON_TRANSFORM_CHANGE = 'onTransformChange',
  ON_TRANSFORM_END = 'onTransformEnd',
  ON_CLICK = 'onClick',
  ON_SELECT = 'onSelect',
  ON_SELECTION_DRAG_START = 'onSelectionDragStart',
  ON_SELECTION_DRAG_END = 'onSelectionDragEnd',
  ON_TOOL_CHANGE = 'onToolChange',
  ON_TOOL_START = 'onToolStart',
  ON_TOOL_END = 'onToolEnd',
  ON_CONTEXT_MENU = 'onContextMenu',
}

export default UnifiedViewerEventType;
