import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ClickableCellTitle = styled.div`
  padding: 0 8px;
  color: ${({ theme }) => theme['border--interactive--toggled-default']};
`;

export const NonClickableCellTitle = styled.div`
  padding: 0 8px;
`;

export const FullHeightFlex = styled(Flex)`
  height: 100%;
`;
