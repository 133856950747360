import { TimeseriesEntry } from '../../../models';
import { ChartThreshold, ChartTimeSeries } from '../../../types';
import { convertThresholdUnits, convertUnits, units } from '../../../utils';

import { SeriesData, SeriesDataCollection } from './types';

const getTimeseriesUnitLabel = (timeSeries: ChartTimeSeries) =>
  units.find(
    (unitOption) => unitOption.value === timeSeries.preferredUnit?.toLowerCase()
  )?.label ||
  timeSeries.customUnitLabel ||
  timeSeries.originalUnit;

const createSeriesDataFromTimeSeries = (
  timeSeries: ChartTimeSeries,
  datapoints: TimeseriesEntry | undefined
): SeriesData => ({
  id: timeSeries.id,
  name: timeSeries.name,
  type: 'timeseries',
  style: {
    color: timeSeries.color,
    size: timeSeries.lineWeight || 1,
    lineStyle: timeSeries.lineStyle || 'solid',
  },
  isDataOutdated: datapoints?.loading ?? false,
  datapoints: convertUnits(
    datapoints?.series?.datapoints || [],
    timeSeries.unit,
    timeSeries.preferredUnit
  ),
  interpolation: timeSeries.interpolation,
});

export function calculateSeriesDataFromTimeSeries(
  timeSeriesCollection: ChartTimeSeries[],
  datapointsCollection: TimeseriesEntry[],
  thresholds: ChartThreshold[]
): SeriesDataCollection[] {
  const seriesDataCollections: SeriesDataCollection[] = [];

  for (const timeseries of timeSeriesCollection) {
    const datapoints = datapointsCollection.find(
      (dp) => dp.externalId === timeseries.tsExternalId
    );

    const unitLabel = getTimeseriesUnitLabel(timeseries);
    const seriesData = createSeriesDataFromTimeSeries(timeseries, datapoints);

    let seriesThresholds = thresholds.filter(
      (th) => th.sourceId === timeseries.id
    );
    if (seriesThresholds.length > 0) {
      seriesThresholds = convertThresholdUnits(
        seriesThresholds,
        timeseries.unit,
        timeseries.preferredUnit
      );
    }

    seriesDataCollections.push({
      unit: unitLabel,
      range: timeseries.range,
      thresholds: seriesThresholds,
      series: [seriesData],
      isVisible: timeseries.enabled,
    });
  }

  return seriesDataCollections;
}
