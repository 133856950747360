import { ChartWorkflow } from '../../../types';

export const cleanWorkflowCollection = (
  wfCollection?: ChartWorkflow[]
): ChartWorkflow[] | undefined => {
  return wfCollection?.map((wf) => {
    return {
      ...wf,
      statisticsCalls: undefined,
      dataProfilingCalls: undefined,
      calls: undefined,
    };
  });
};
