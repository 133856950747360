import type { Notification } from '@cognite/apm-client';
import {
  EmptyState,
  Flex,
  PlansIllustration,
  Skeleton,
} from '@cognite/cogs.js-v10';
import { AssetTypeFilter } from '@infield/features/asset/asset-type-filter';
import * as S from '@infield/features/asset/elements';
import { ListAccordion } from '@infield/features/asset/shared';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  NotificationDetails,
  useAssetNotificationsQuery,
} from '@infield/features/notifications';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AssetNotificationsList } from './asset-notifications-list';
import { selectedNotificationInPanelAtom } from './state';

type Props = {
  assetExternalId: string;
};

const getDateYear = (date?: string) => {
  return date ? dayjs(date).year().toString() : '-';
};

export const AssetNotificationsView: FC<Props> = ({ assetExternalId }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.notification);

  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const [activePanels, setActivePanels] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const selectedNotification = useRecoilValue<string>(
    selectedNotificationInPanelAtom
  );
  const setSelectedNotification = useSetRecoilState(
    selectedNotificationInPanelAtom
  );

  useEffect(() => {
    return () => {
      setSelectedNotification('');
    };
  }, [setSelectedNotification]);

  const { data: notifications, isLoading } = useAssetNotificationsQuery(
    assetExternalId,
    [],
    [{ createdDate: 'DESC' }]
  );

  const handleOnAssetClick = (assetExternalId: string) => {
    navigate(`/asset/${encodeURIComponent(assetExternalId)}`);
  };

  useEffect(() => {
    if (activePanels.length === 0) {
      if (selectedNotification) {
        const notification = notifications?.find(
          (notification) => notification.externalId === selectedNotification
        );

        if (notification) {
          const year = getDateYear(notification.createdDate);
          setActivePanels([year]);
        }
      } else if (notifications && notifications[0].createdDate) {
        const year = getDateYear(notifications[0].createdDate);

        setActivePanels([year]);
      }
    }
    // we don't want this to trigger when we collapse/expand panels
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, selectedNotification]);

  const handleTogglePanel = (panel: string) => {
    setActivePanels((prevActivePanel) =>
      prevActivePanel.includes(panel)
        ? prevActivePanel.filter((key) => key !== panel)
        : [...prevActivePanel, panel]
    );
  };

  const handleOnNotificationClick = (notificationExternalId: string) => {
    if (isDesktop) {
      setSelectedNotification(notificationExternalId);
    } else {
      navigate(
        `/notification/${encodeURIComponent(notificationExternalId)}/details`
      );
    }
  };

  const hasNotifications = notifications && notifications.length > 0;

  if (isLoading) {
    return <Skeleton.List />;
  }

  if (!hasNotifications) {
    return (
      <S.Container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <EmptyState
          illustration={<PlansIllustration />}
          title={t(
            'ASSET_OVERVIEW_NOTIFICATIONS_EXPANDED_VIEW_NO_NOTIFICATION_TITLE',
            'No notifications'
          )}
          description={t(
            'ASSET_OVERVIEW_NOTIFICATIONS_EXPANDED_VIEW_NO_NOTIFICATION_BODY',
            'There are no notifications found on this asset.'
          )}
        />
      </S.Container>
    );
  }

  const filterValues = Array.from(
    new Set(
      notifications
        .map((notification) => notification.type)
        .filter(Boolean) as string[]
    )
  );

  const filteredNotifications =
    selectedTypes.length > 0
      ? notifications?.filter(
          ({ type }) => type && selectedTypes.includes(type)
        )
      : notifications;

  const notificationsGrouped =
    filteredNotifications?.reduce((prevValue, currentNotification) => {
      const year = getDateYear(currentNotification.createdDate);
      if (prevValue[year]) {
        return {
          ...prevValue,
          [year]: [...prevValue[year], currentNotification],
        };
      }

      return {
        ...prevValue,
        [year]: [currentNotification],
      };
    }, {} as Record<string, Notification[]>) || {};

  return (
    <S.Container>
      <S.ContentWrapper>
        <Flex>
          <AssetTypeFilter
            selectedTypes={selectedTypes}
            filterValues={{ types: filterValues }}
            onClickType={(type) =>
              setSelectedTypes((prevSelectedTypes) =>
                prevSelectedTypes.includes(type)
                  ? prevSelectedTypes.filter((key) => key !== type)
                  : [...prevSelectedTypes, type]
              )
            }
          />
        </Flex>

        <S.GroupsWrapper>
          {Object.entries(notificationsGrouped).map(
            ([groupName, groupNotifications]) => (
              <ListAccordion
                key={groupName}
                title={groupName}
                numberOfItems={groupNotifications.length}
                expanded={activePanels.some((a) => a === groupName)}
                toggleExpand={() => handleTogglePanel(groupName)}
              >
                <AssetNotificationsList
                  notifications={groupNotifications}
                  selectedNotification={selectedNotification}
                  onNotificationClick={handleOnNotificationClick}
                />
              </ListAccordion>
            )
          )}
        </S.GroupsWrapper>
      </S.ContentWrapper>

      {selectedNotification && (
        <S.DetailWrapper>
          <NotificationDetails
            onClose={() => setSelectedNotification('')}
            onAssetClick={handleOnAssetClick}
            notificationId={selectedNotification}
          />
        </S.DetailWrapper>
      )}
    </S.Container>
  );
};
