import {
  useAPMAssetHierarchySearch,
  useAssetSearch,
  useClassicAssetHierarchySearch,
  useDocumentSearch,
  useTimeseriesSearch,
} from '@infield/features/search';
import { useAPMAssetSearch } from '@infield/features/search/hooks/use-query/use-apm-asset-search';
import type { SearchResourceType } from '@infield/features/top-bar/top-bar-search/types';
import { useAppConfigFiltersContext } from '@infield/providers/app-config-filters-provider';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';

type Props = {
  query: string;
  resourceType: SearchResourceType;
  assetPageId?: string;
  resultLimit: number;
};
export const useSearch = ({
  query,
  resourceType,
  resultLimit,
  assetPageId,
}: Props) => {
  const { isIdm } = useAppConfigContext();
  const { timeseries: timeseriesConfigFilters } = useAppConfigFiltersContext();

  const classicAssetSearchResult = useAssetSearch(
    resourceType === 'asset' ? query : undefined,
    resultLimit,
    undefined,
    !isIdm
  );
  const apmAssetSearchResult = useAPMAssetSearch({
    query,
    limit: resultLimit,
    enabled: isIdm && resourceType === 'asset',
  });
  const timeseriesSearchResult = useTimeseriesSearch(
    resourceType === 'timeseries' ? query : undefined,
    resultLimit,
    timeseriesConfigFilters?.cdfClassicFilters
  );
  const documentSearchResult = useDocumentSearch(
    resourceType === 'documents' ? query : undefined,
    resultLimit
  );

  const { parentAsset: classicParentAsset, childAssets: classicChildAssets } =
    useClassicAssetHierarchySearch({
      query,
      assetExternalId: assetPageId,
      enabled: resourceType === 'hierarchy' && !isIdm,
    });

  const {
    data: { parentAsset: apmParentAsset, childAssets: apmChildAssets } = {},
  } = useAPMAssetHierarchySearch({
    query,
    assetExternalId: assetPageId,
    enabled: resourceType === 'hierarchy' && isIdm,
  });

  return {
    assetSearchResult: !isIdm ? classicAssetSearchResult : apmAssetSearchResult,
    apmAssetSearchResult,
    timeseriesSearchResult,
    documentSearchResult,
    parentAsset: !isIdm ? classicParentAsset : apmParentAsset,
    childAssets: !isIdm ? classicChildAssets : apmChildAssets,
  };
};
