import type { ObservationFieldOption } from '@cognite/apm-config';
import { type Step } from '@infield/features/observation';
import type { FC } from 'react';

import { ObservationDetailsStepInfo } from '../observation-details-step-wrapper/observation-details-step-info';

import * as S from './elements';

interface Props {
  step: Step;
  value?: string;
  isLocked?: boolean;
  onChange: (value: string) => void;
}

export const ObservationDetailsStepPriority: FC<Props> = ({
  step,
  value,
  isLocked,
  onChange,
}) => {
  const priorityOptions = step.options;
  const selectedValue: ObservationFieldOption | undefined =
    priorityOptions?.find((option) => option.value === value);

  return (
    <>
      <ObservationDetailsStepInfo step={step} />
      <S.PrioritySelection
        options={priorityOptions ?? []}
        value={selectedValue}
        disabled={isLocked}
        onChange={(selected: ObservationFieldOption) =>
          onChange(selected.value)
        }
      />
    </>
  );
};
