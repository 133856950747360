import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ErrorWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  height: 100%;
  width: 100%;
  padding: 16px;
  position: relative;
  background: ${({ theme }) => theme['surface--muted']};
`;

export const ChartWrapper = styled.div<{
  isShowYAxis: boolean;
  renderAsMobile?: boolean;
}>`
  background-color: ${({ theme }) => theme['surface--medium']};
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: ${({ renderAsMobile }) =>
    renderAsMobile ? 'calc(100% - 94px)' : '100%'};

  .lines-container {
    opacity: ${(props) => (props.isShowYAxis ? '0.5' : '1')};
  }

  .y-axis-container {
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    right: 40px;
    grid-area: auto !important;
  }
`;

export const Disclaimer = styled.p`
  position: absolute;
  bottom: 4px;
  left: 16px;
  margin: 0;
  width: 100%;
  text-align: left;
`;

export const DisabledYAxis = styled.div<{ isFullView: boolean }>`
  display: flex;
  height: calc(100% - 50px);
  position: absolute;
  right: 20px;
  flex-direction: row-reverse;
  max-width: ${(props) => (props.isFullView ? 'unset' : '280px')};
  overflow: ${(props) => (props.isFullView ? 'unset' : 'hidden')};
`;
