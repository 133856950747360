import isNotUndefined from '../utils/isNotUndefined';

import { ContainerType } from './ContainerType';
import { ContainerConfig } from './types';

const findDuplicates = <T>(array: T[]): T[] => {
  return array.filter((item, index) => array.indexOf(item) !== index);
};

const isValidIsoString = (dateString: string): boolean => {
  return !isNaN(Date.parse(dateString));
};

const validateContainerConfig = (
  containerConfig: ContainerConfig
): string[] | undefined => {
  if (containerConfig.type === ContainerType.TIMESERIES) {
    // Check if containerConfig.startDate is a valid iso string
    // Check if containerConfig.endDate is a valid iso string
    if (containerConfig.startDate === undefined) {
      return ['Timeseries container config must have a startDate'];
    }
    if (containerConfig.endDate === undefined) {
      return ['Timeseries container config must have an endDate'];
    }

    if (!isValidIsoString(containerConfig.startDate)) {
      return [
        `Timeseries container config for ${containerConfig.id}: startDate must be a valid iso string`,
      ];
    }

    if (!isValidIsoString(containerConfig.endDate)) {
      return [
        `Timeseries container config for ${containerConfig.id}: endDate must be a valid iso string`,
      ];
    }

    if (
      new Date(containerConfig.startDate) > new Date(containerConfig.endDate)
    ) {
      return [
        `Timeseries container config for ${containerConfig.id}: startDate must be before endDate, got startDate: ${containerConfig.startDate} and endDate: ${containerConfig.endDate}`,
      ];
    }
  }

  return undefined;
};

const validateContainerConfigs = (
  containerConfigs: ContainerConfig[]
): string[] | undefined => {
  const duplicatedContainerIds = findDuplicates(
    containerConfigs.map(({ id }) => id)
  );
  if (duplicatedContainerIds.length > 0) {
    return [
      `Duplicate container config ids are not allowed. Duplicate ids: ${duplicatedContainerIds}`,
    ];
  }

  const errors = containerConfigs
    .map(validateContainerConfig)
    .flat()
    .filter(isNotUndefined);

  if (errors.length > 0) {
    return errors;
  }

  return undefined;
};

export default validateContainerConfigs;
