import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import * as S from './elements';

export const TrendsChartEmptyView: FC = () => {
  const { Trans, t } = useTranslation(LOCIZE_NAMESPACES.trends);
  return (
    <S.Wrapper>
      <S.EmptyText size="medium">
        <Trans i18nKey="pleaseSelectOneTimeseries">
          Please select one of the available timeseries below
        </Trans>
      </S.EmptyText>
    </S.Wrapper>
  );
};
