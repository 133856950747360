import {
  AnnotationIdPointCloudObjectCollection,
  AssetNodeCollection,
  CogniteCadModel,
  CogniteModel,
  CognitePointCloudModel,
  DefaultNodeAppearance,
} from '@cognite/reveal';
import { CogniteClient } from '@cognite/sdk';

import { AssetAcdmInstance } from '../../types';

import { getFirstAnnotationForAssetId } from './getAssetAcdmInstanceBoundingBoxForPointCloudModel';

/**
 * The higher the number here, the more likely it is that Reveal will include the nodes in
 * the nodes that it chooses to render. 10 is a good number according to the docs, but through testing
 * setting it that high might lead to the nodes closest to the camera being very heavily prioritised
 * in a way that does not add to the overall perception (aka everything else nearby is culled).
 * Check the docs for the API, and this thread for more context on the bug
 * https://cognitedata.slack.com/archives/C8A0QGMLN/p1718958025505339
 */
const HIGHLIGHTED_NODES_PRIORITY_HINT = 1;

const highlightInstance = async (
  client: CogniteClient,
  threeDModel: CogniteModel,
  instance: AssetAcdmInstance | undefined
): Promise<void> => {
  if (instance === undefined) {
    return;
  }

  if (threeDModel instanceof CogniteCadModel) {
    const assetNodes = new AssetNodeCollection(client, threeDModel);
    await assetNodes.executeFilter({ assetId: instance.id });

    threeDModel.assignStyledNodeCollection(assetNodes, {
      ...DefaultNodeAppearance.Highlighted,
      prioritizedForLoadingHint: HIGHLIGHTED_NODES_PRIORITY_HINT,
    });
  }

  if (threeDModel instanceof CognitePointCloudModel) {
    // This will lead to a double fetch of the annotations, once for the computing
    // of the bounding box, and once for the highlighting, but tbh, it should not be a
    // big deal to keep the code relatively clean. Poyan 2024-06-19
    const assetAnnotation = await getFirstAnnotationForAssetId(
      client,
      threeDModel.modelId,
      instance.id
    );

    if (assetAnnotation === undefined) {
      return;
    }

    const assetNodeCollection = new AnnotationIdPointCloudObjectCollection([
      assetAnnotation.id,
    ]);
    threeDModel.assignStyledObjectCollection(assetNodeCollection, {
      ...DefaultNodeAppearance.Highlighted,
    });
  }
};

export default highlightInstance;
