import UnifiedViewerRenderer from '../../UnifiedViewerRenderer';
import isNotUndefined from '../../utils/isNotUndefined';
import getRightAnglesConnectionPathPoints from '../getRightAnglesConnectionPathPoints';
import { isRectangleAnnotation } from '../types';

const getRightAngleConnection = (
  fromId: string,
  toId: string,
  unifiedViewerRenderer: UnifiedViewerRenderer
): number[] | undefined => {
  const fromNode =
    unifiedViewerRenderer.getContainerOrAnnotationNodeById(fromId);
  const toNode = unifiedViewerRenderer.getContainerOrAnnotationNodeById(toId);
  if (fromNode === undefined || toNode === undefined) {
    // eslint-disable-next-line no-console
    console.warn(
      'Could not find `from` or `end` node for ConnectionAnnotation. Endpoints might not have been rendered yet'
    );
    return undefined;
  }

  const fromRect = unifiedViewerRenderer.getNodeRectRelativeToStage(fromNode);
  const toRect = unifiedViewerRenderer.getNodeRectRelativeToStage(toNode);
  if (fromRect === undefined || toRect === undefined) {
    // eslint-disable-next-line no-console
    console.debug(
      'Could not find dimensions of annotation(s) for connection annotation, the annotation(s) might not have been rendered yet'
    );
    return undefined;
  }

  // TODO(UFV-593): generalize right angle annotation to work with containers as well
  const fromAnnotation = unifiedViewerRenderer.getAnnotationFromNode(fromNode);
  const toAnnotation = unifiedViewerRenderer.getAnnotationFromNode(toNode);
  if (
    !isRectangleAnnotation(fromAnnotation) ||
    !isRectangleAnnotation(toAnnotation) ||
    fromAnnotation.containerId === undefined ||
    toAnnotation.containerId === undefined
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      'Right angle connection annotation is only supported with RectangleAnnotations with containerId as endpoints for now'
    );
    return undefined;
  }

  const containerRects = unifiedViewerRenderer
    .getContainers()
    .map((container) =>
      unifiedViewerRenderer.getContainerRectRelativeToStageById(container.id)
    )
    .filter(isNotUndefined);

  const fromContainerRect =
    unifiedViewerRenderer.getContainerRectRelativeToStageById(
      fromAnnotation.containerId
    );
  const toContainerRect =
    unifiedViewerRenderer.getContainerRectRelativeToStageById(
      toAnnotation.containerId
    );
  if (fromContainerRect === undefined || toContainerRect === undefined) {
    return undefined;
  }
  return getRightAnglesConnectionPathPoints({
    fromAnnotationRect: fromRect,
    toAnnotationRect: toRect,
    fromContainerRect,
    toContainerRect,
    obstacles: containerRects,
    obstaclePadding: 20,
  });
};

export default getRightAngleConnection;
