import { Flex, Input, Menu } from '@cognite/cogs.js-v10';
import { ComponentScrollableContent } from '@infield/features/ui';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

export const TaskFormContainer = styled(Flex).attrs({
  direction: 'column',
  justifyContent: 'space-between',
})`
  height: 100%;
  min-width: 400px;
`;

export const TaskTitleInput = styled(Input)`
  width: 100%;
`;

export const FormActions = styled(Flex).attrs({
  direction: 'column',
  gap: 8,
})`
  padding: 16px;
`;

export const ComponentMenu = styled(Menu)`
  width: 368px;
`;

export const TaskFormBlockWrapper = styled(Flex).attrs({
  gap: 12,
  direction: 'column',
})`
  padding: 8px;
  background-color: ${({ theme }) => theme['surface--medium']};
  border: 1px solid ${({ theme }) => theme['border--muted']};
  border-radius: 6px;

  // override cogs.js styles
  .cogs-select--container {
    background-color: ${({ theme }) => theme['surface--muted']};
  }
`;

export const TaskFormBlockTitleWrapper = styled(Flex).attrs({
  gap: 4,
  alignItems: 'center',
})`
  .cogs-tooltip__content {
    display: flex;
  }
`;

export const ScrollableContentWithOverlay = styled(ComponentScrollableContent)`
  position: relative;
`;

export const FormContentWrapper = styled.div`
  position: relative;
`;

export const FormContent = styled(Flex).attrs({ direction: 'column', gap: 12 })`
  padding: 16px;
`;

export const LockedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) =>
    theme['surface--interactive--disabled--alt']};
  z-index: ${z.INFOBAR_OVERLAY};
`;

export const TaskFormReferenceElement = styled.div`
  overflow-y: auto;
  height: 100%;
`;
