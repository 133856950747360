import type { APMClient } from '@cognite/apm-client';
import { useAuthContext } from '@cognite/e2e-auth';
import { createContext, useContext, useMemo } from 'react';
import type { FunctionComponent, PropsWithChildren } from 'react';

import { MediaService } from '../features/media';
import { ObservationService } from '../features/observation/observation-service';

type APMObservationServices = {
  mediaService: MediaService;
  observationService: ObservationService;
  apmClient: APMClient;
};

const APMObservationContext = createContext<APMObservationServices | undefined>(
  undefined
);

type APMObservationProviderProps = PropsWithChildren<{
  apmClient: APMClient; // Replace 'any' with the correct type for apmClient if available
}>;

export const APMObservationProvider: FunctionComponent<
  APMObservationProviderProps
> = ({ apmClient, children }) => {
  const { client: cogniteClient } = useAuthContext();

  const observationServices = useMemo(
    () => ({
      observationService: new ObservationService(cogniteClient, apmClient),
      mediaService: new MediaService(cogniteClient),
      apmClient,
    }),
    [cogniteClient, apmClient]
  );

  return (
    <APMObservationContext.Provider value={observationServices}>
      {children}
    </APMObservationContext.Provider>
  );
};

export const useAPMObservationServices = () => {
  const context = useContext(APMObservationContext);
  if (!context) {
    throw new Error(
      'useFDMServices must be used within an instance of APMObservationProvider'
    );
  }
  return context;
};
