import assertNever from '../utils/assertNever';

import CauseMapTool from './CauseMapTool';
import EllipseTool from './EllipseTool';
import ImageTool from './ImageTool';
import LineTool from './LineTool';
import PolylineTool from './PolylineTool';
import RectangleTool from './RectangleTool';
import SelectTool from './SelectTool';
import StickyTool from './StickyTool';
import TextTool from './TextTool';
import Tool from './Tool';
import {
  isCauseMapToolConfig,
  isEllipseToolConfig,
  isImageToolConfig,
  isLineToolConfig,
  isPanToolConfig,
  isPolylineToolConfig,
  isRectangleToolConfig,
  isSelectToolConfig,
  isStickyToolConfig,
  isTextToolConfig,
  ToolConfig,
} from './types';

const setConfigForTool = (tool: Tool, toolConfig: ToolConfig): void => {
  if (isEllipseToolConfig(toolConfig)) {
    if (tool instanceof EllipseTool) {
      tool.setConfig(toolConfig);
      return;
    }

    throw new Error(
      '`toolConfig` is an EllipseToolConfig and `tool` is not an EllipseTool.'
    );
  }

  if (isImageToolConfig(toolConfig)) {
    if (tool instanceof ImageTool) {
      tool.setConfig(toolConfig);
      return;
    }

    throw new Error(
      '`toolConfig` is an ImageToolConfig and `tool` is not an ImageTool.'
    );
  }

  if (isLineToolConfig(toolConfig)) {
    if (tool instanceof LineTool) {
      tool.setConfig(toolConfig);
      return;
    }

    throw new Error(
      '`toolConfig` is a LineToolConfig and `tool` is not a LineTool.'
    );
  }

  if (isPanToolConfig(toolConfig)) {
    // PanTool does not have any options.
    return;
  }

  if (isPolylineToolConfig(toolConfig)) {
    if (tool instanceof PolylineTool) {
      tool.setConfig(toolConfig);
      return;
    }

    throw new Error(
      '`toolConfig` is a PolylineToolConfig and `tool` is not a PolylineTool.'
    );
  }

  if (isRectangleToolConfig(toolConfig)) {
    if (tool instanceof RectangleTool) {
      tool.setConfig(toolConfig);
      return;
    }

    throw new Error(
      '`toolConfig` is a RectangleToolConfig and `toolConfig` is not a RectangleToolConfig.'
    );
  }

  if (isSelectToolConfig(toolConfig)) {
    if (tool instanceof SelectTool) {
      tool.setConfig(toolConfig);
      return;
    }
    throw new Error(
      '`tool` is a SelectTool but `toolConfig` is not a SelectToolConfig.'
    );
  }

  if (isStickyToolConfig(toolConfig)) {
    if (tool instanceof StickyTool) {
      tool.setConfig(toolConfig);
      return;
    }

    throw new Error(
      '`toolConfig` is a StickyToolConfig and `toolConfig` is not a StickyToolConfig.'
    );
  }

  if (isTextToolConfig(toolConfig)) {
    if (tool instanceof TextTool) {
      tool.setConfig(toolConfig);
      return;
    }

    throw new Error(
      '`toolConfig` is a TextToolConfig and `toolConfig` is not a TextToolConfig.'
    );
  }

  if (isCauseMapToolConfig(toolConfig)) {
    if (tool instanceof CauseMapTool) {
      tool.setConfig(toolConfig);
      return;
    }

    throw new Error(
      '`toolConfig` is a CauseMapToolConfig and `toolConfig` is not a CauseMapToolConfig.'
    );
  }

  assertNever(
    toolConfig,
    'setToolConfigForTool not implemented for toolConfig: ' + toolConfig
  );
};

export default setConfigForTool;
