import type {
  AppConfig,
  ViewConfigDTO,
  ViewConfigEntities,
  ViewMappings,
} from '@cognite/apm-config';
import { entityMapping } from '@cognite/apm-config';

const getOldViewMappings = (
  oldViewMappings: Partial<ViewMappings> | undefined
): ViewConfigDTO[] | undefined => {
  if (!oldViewMappings) return undefined;

  return Object.keys(oldViewMappings)
    .map((key) => {
      const viewReference = oldViewMappings[key as keyof ViewMappings];
      if (viewReference) {
        return {
          externalId: viewReference.externalId,
          space: viewReference.space,
          version: viewReference.version,
          representsEntity: entityMapping[key] || (key as ViewConfigEntities),
        };
      }
      return undefined;
    })
    .filter(
      (viewConfig): viewConfig is ViewConfigDTO => viewConfig !== undefined
    );
};

export const getViewMappings = (
  isIdmEnabled: boolean,
  views: ViewConfigDTO[] | undefined,
  oldAppConfig?: AppConfig
) => {
  if (isIdmEnabled) {
    return views || undefined;
  }
  const oldViewMappings =
    oldAppConfig?.featureConfiguration?.viewMappings || undefined;
  return getOldViewMappings(oldViewMappings);
};
