import { Body, Flex, Heading } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Item = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 12px 0px;

  &:hover {
    background-color: var(--cogs-themed-surface--interactive--hover);
  }
`;

export const ItemContent = styled(Flex).attrs({ gap: 12 })`
  min-width: 0px;
  flex: 1;
`;

export const ItemDetailsWrapper = styled(Flex).attrs({
  direction: 'column',
  gap: 4,
})`
  text-align: left;
  min-width: 0px;
`;

export const Name = styled(Body).attrs({ size: 'x-small' })`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: -webkit-fill-available;
  color: ${({ theme }) => theme['text-icon--muted']};
`;

export const Value = styled(Heading).attrs(() => ({ level: 3 }))<{
  $color: string;
}>`
  color: ${({ $color }) => $color};
  display: inline-block;
`;
