import { APMClient, VIEW_VERSIONS } from '@cognite/apm-client';
import { Heading, Loader } from '@cognite/cogs.js-v10';
import { useAuthContext } from '@cognite/e2e-auth';
import { ThreeDService } from '@infield/features/3d';
import { ActivityService } from '@infield/features/activities';
import {
  defaultAppConfig,
  selectedRootLocationAtom,
} from '@infield/features/app-config';
import type { AppConfig } from '@infield/features/app-config/types';
import {
  getIsNewConfigVersion,
  SELECTED_ROOT_LOCATION_KEY,
} from '@infield/features/app-config/utils/utils';
import { AssetService } from '@infield/features/asset';
import { ChecklistService } from '@infield/features/checklist';
import {
  ActionsService,
  ConditionalActionsService,
  ConditionsService,
} from '@infield/features/conditions';
import { useTranslation } from '@infield/features/i18n';
import { MeasurementService } from '@infield/features/measurements';
import { MediaService } from '@infield/features/media';
import { NotificationService } from '@infield/features/notifications';
import { ObservationService } from '@infield/features/observation';
import { IntervalService } from '@infield/features/task/interval-list/interval-service';
import { TemplateService } from '@infield/features/template';
import { TimeseriesService } from '@infield/features/timeseries';
import { useGetViewsByReferences } from '@infield/providers/hooks';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { removeEntityFromView } from '@infield/providers/is-idm-provider/utils/remove-entity-from-view';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { FDMServicesContext } from './fdm-services-context';

interface Props {
  mocks?: {
    appConfig: AppConfig;
  };
  localAppInstanceSpace?: string;
}

export const FDMServicesProvider: FunctionComponent<
  PropsWithChildren<Props>
> = ({ mocks, localAppInstanceSpace, children }) => {
  const { Trans, t } = useTranslation();
  const { client: cogniteClient } = useAuthContext();
  const { appConfig, location, isIdm } = useAppConfigContext();
  const isNewConfigVersion = getIsNewConfigVersion(appConfig);
  const selectedRootLocation = useRecoilValue(selectedRootLocationAtom);
  const selectedRootLocationFromLocalStorage = localStorage.getItem(
    SELECTED_ROOT_LOCATION_KEY
  );

  const selectedRootLocationConfigData =
    appConfig?.featureConfiguration?.rootLocationConfigurations?.find(
      (configuredRootLocation) => {
        const selectedExternalId =
          selectedRootLocation?.externalId ??
          selectedRootLocationFromLocalStorage;
        const selectedAssetExternalId =
          selectedRootLocation?.assetExternalId ??
          selectedRootLocationFromLocalStorage;
        if (isNewConfigVersion) {
          return configuredRootLocation.externalId === selectedExternalId;
        }
        return (
          configuredRootLocation.assetExternalId === selectedExternalId ||
          configuredRootLocation.assetExternalId === selectedAssetExternalId
        );
      }
    );

  const selectedAppDataInstanceSpaceId =
    location?.appInstanceSpace ??
    selectedRootLocationConfigData?.appDataInstanceSpace ??
    appConfig?.appDataSpaceId;

  const selectedSourceDataInstanceSpaceId =
    selectedRootLocationConfigData?.sourceDataInstanceSpace ??
    appConfig?.customerDataSpaceId;

  const baseAssetViewReference = useMemo(
    () => ({
      externalId: !isIdm ? 'Asset' : 'CogniteAsset',
      space: !isIdm ? 'cdf_core' : 'cdf_cdm',
      version: !isIdm ? 'v1' : 'v1',
    }),
    [isIdm]
  );

  const baseTimeseriesViewReference = useMemo(
    () =>
      isIdm
        ? {
            externalId: 'CogniteTimeSeries',
            space: 'cdf_cdm',
            version: VIEW_VERSIONS.TIMESERIES,
          }
        : undefined,
    [isIdm]
  );

  const configuredAssetViewReference = removeEntityFromView(
    location?.views?.find((view) => view.representsEntity === 'ASSET')
  ) || { ...baseAssetViewReference };

  const apmConfig = useMemo(
    () =>
      new APMClient(
        {
          cogniteClient,
          appDataModelSpace: 'cdf_apm',
          appDataModelName: 'ApmAppData',
          appDataModelVersion: 'v6',
          appDataInstanceSpace:
            localAppInstanceSpace ??
            selectedAppDataInstanceSpaceId ??
            defaultAppConfig.appDataSpaceId!,
          sourceDataModelSpace:
            appConfig?.customerDataSpaceId ??
            defaultAppConfig.customerDataSpaceId!,
          sourceDataModelName:
            appConfig?.customerDataSpaceId ??
            defaultAppConfig.customerDataSpaceId!,
          sourceDataModelVersion:
            appConfig?.customerDataSpaceVersion ??
            defaultAppConfig.customerDataSpaceVersion!,
          sourceDataInstanceSpace:
            selectedSourceDataInstanceSpaceId ??
            defaultAppConfig.customerDataSpaceId!,
        },
        {
          userViewName: 'CDF_InFieldUser',
          userModelSpace: 'cdf_infield',
          userModelName: 'InField',
          userInstanceSpace: 'cognite_app_data',
          userModelVersion: 'v1',
          userPreferencesViewName: 'CDF_InFieldUserPreferences',
          activitiesView: appConfig?.featureConfiguration?.viewMappings
            ?.activity || {
            externalId: 'APM_Activity',
            space:
              appConfig?.customerDataSpaceId ??
              defaultAppConfig.customerDataSpaceId!,
            version:
              appConfig?.customerDataSpaceVersion ??
              defaultAppConfig.customerDataSpaceVersion!,
          },
          operationsView: appConfig?.featureConfiguration?.viewMappings
            ?.operation || {
            externalId: 'APM_Operation',
            space:
              appConfig?.customerDataSpaceId ??
              defaultAppConfig.customerDataSpaceId!,
            version:
              appConfig?.customerDataSpaceVersion ??
              defaultAppConfig.customerDataSpaceVersion!,
          },
          notificationsView: appConfig?.featureConfiguration?.viewMappings
            ?.notification || {
            externalId: 'APM_Notification',
            space:
              appConfig?.customerDataSpaceId ??
              defaultAppConfig.customerDataSpaceId!,
            version:
              appConfig?.customerDataSpaceVersion ??
              defaultAppConfig.customerDataSpaceVersion!,
          },
          assetsView:
            appConfig?.featureConfiguration?.viewMappings?.asset ||
            baseAssetViewReference,
          filesView: isIdm
            ? {
                externalId: 'CogniteFile',
                space: 'cdf_cdm',
                version: 'v1',
              }
            : undefined,
          timeseriesView: baseTimeseriesViewReference,
        }
      ),
    [
      cogniteClient,
      localAppInstanceSpace,
      selectedAppDataInstanceSpaceId,
      appConfig?.customerDataSpaceId,
      appConfig?.customerDataSpaceVersion,
      appConfig?.featureConfiguration?.viewMappings?.activity,
      appConfig?.featureConfiguration?.viewMappings?.operation,
      appConfig?.featureConfiguration?.viewMappings?.notification,
      appConfig?.featureConfiguration?.viewMappings?.asset,
      selectedSourceDataInstanceSpaceId,
      baseAssetViewReference,
      baseTimeseriesViewReference,
      isIdm,
    ]
  );

  const {
    data: [baseAssetView, customAssetView] = [undefined, undefined],
    isLoading: isLoadingViews,
  } = useGetViewsByReferences(
    [baseAssetViewReference, configuredAssetViewReference],
    Boolean(appConfig)
  );

  const fdmServices = useMemo(() => {
    if ((!isLoadingViews && baseAssetView) || mocks) {
      return {
        activityService: new ActivityService(cogniteClient, apmConfig, isIdm),
        assetService: new AssetService(
          cogniteClient,
          apmConfig,
          isIdm,
          baseAssetView,
          customAssetView || baseAssetView
        ),
        notificationService: new NotificationService(
          cogniteClient,
          apmConfig,
          isIdm
        ),
        mediaService: new MediaService(cogniteClient, apmConfig, isIdm),
        threeDService: new ThreeDService(cogniteClient, apmConfig),
        measurementsService: new MeasurementService(cogniteClient, apmConfig),
        conditionsService: new ConditionsService(cogniteClient, apmConfig),
        conditionalActionsService: new ConditionalActionsService(
          cogniteClient,
          apmConfig
        ),
        actionsService: new ActionsService(cogniteClient, apmConfig),
        timeseriesService: new TimeseriesService(cogniteClient, apmConfig),
        templateService: new TemplateService(cogniteClient, apmConfig),
        checklistService: new ChecklistService(cogniteClient, apmConfig),
        apmClient: apmConfig,
        intervalService: new IntervalService(cogniteClient, apmConfig),
        observationService: new ObservationService(cogniteClient, apmConfig),
      };
    }
  }, [
    isLoadingViews,
    baseAssetView,
    mocks,
    cogniteClient,
    apmConfig,
    isIdm,
    customAssetView,
  ]);

  if (fdmServices || mocks) {
    return (
      <FDMServicesContext.Provider value={fdmServices}>
        {children}
      </FDMServicesContext.Provider>
    );
  }

  return (
    <Loader
      infoText={
        <Heading level={6}>
          <Trans i18nKey="SERVICE_PROVIDER_LOADING_APP_CONFIGURATION">
            Loading app configuration
          </Trans>
        </Heading>
      }
    />
  );
};

export const useFDMServices = () => {
  const context = useContext(FDMServicesContext);
  if (!context) {
    throw new Error(
      'useFDMServices must be used within an instance of FDMServicesProvider'
    );
  }
  return context;
};
