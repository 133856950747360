type IndexOfWordsInQuery = {
  firstIndex: number;
  lastIndex: number;
};

export const getIndexOfQuery = (searchQuery: string, text: string): number =>
  text.toLowerCase().indexOf(searchQuery.toLowerCase());

export const getIndexOfWordsInQuery = (
  searchQuery: string,
  text: string
): IndexOfWordsInQuery[] =>
  searchQuery
    .split(' ')
    .map((word) => {
      const firstIndex = getIndexOfQuery(word, text);

      return {
        firstIndex,
        lastIndex: firstIndex + word.length,
      };
    })
    .filter((word) => word.firstIndex >= 0);

export const getIsHighlighted = (
  index: number,
  firstIndex: number,
  lastIndex: number
): boolean => index >= firstIndex && index < lastIndex;
