import type { ChecklistItemStatus } from '@cognite/apm-client';
import {
  CheckmarkIcon,
  CloseIcon,
  ErrorIcon,
  Menu,
  WarningIcon,
} from '@cognite/cogs.js-v10';
import { useChecklistItemStatusTranslation } from '@infield/features/checklist/hooks';
import { DismissableContent } from '@infield/features/ui';
import type { FC } from 'react';

import { checklistItemStatuses } from '../constants';
import { getChecklistItemStatus } from '../utils';

import * as S from './elements';

interface Props {
  status: string;
  onClose: () => void;
  onStatusClick: (status: ChecklistItemStatus) => void;
  showAll?: boolean;
  contentOnly?: boolean;
}

export const ChecklistStatusMenu: FC<Props> = ({
  status,
  onClose,
  onStatusClick,
  showAll,
  contentOnly,
}) => {
  const translatedStatuses = useChecklistItemStatusTranslation();
  const statusItems = showAll
    ? checklistItemStatuses
    : checklistItemStatuses.filter(
        (checklistItemStatus) =>
          checklistItemStatus !== (getChecklistItemStatus(status) ?? 'Ok')
      );

  const renderIcon = (status: ChecklistItemStatus): JSX.Element | undefined => {
    switch (status) {
      case 'Ok':
        return <CheckmarkIcon />;
      case 'Not ok':
        return <ErrorIcon />;
      case 'Not applicable':
        return <CloseIcon />;
      case 'Blocked':
        return <WarningIcon />;
      default:
        return undefined;
    }
  };

  return !contentOnly ? (
    <DismissableContent onDismiss={onClose}>
      <S.StatusMenu>
        {statusItems.map((status) => (
          <Menu.Item
            key={status}
            data-testid={`checklist-items-group-set-remaining-to-${status}`}
            icon={renderIcon(status)}
            iconPlacement="left"
            onClick={() => onStatusClick(status)}
          >
            {translatedStatuses[status]}
          </Menu.Item>
        ))}
      </S.StatusMenu>
    </DismissableContent>
  ) : (
    <Menu>
      {statusItems.map((status) => (
        <Menu.Item
          key={status}
          icon={renderIcon(status)}
          iconPlacement="left"
          onClick={() => onStatusClick(status)}
        >
          {translatedStatuses[status]}
        </Menu.Item>
      ))}
    </Menu>
  );
};
