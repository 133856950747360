import Konva from 'konva';
import { IRect } from 'konva/lib/types';
import { v4 as uuid } from 'uuid';

import { UnifiedViewer } from '../UnifiedViewer';
import UnifiedViewerEventType from '../UnifiedViewerEventType';
import findNearestAnnotationAncestor from '../utils/findNearestAnnotationAncestor';
import findNearestContainerGroup from '../utils/findNearestContainerGroup';
import isAnnotationNode from '../utils/isAnnotationNode';
import isContainerNode from '../utils/isContainerNode';
import {
  isSelectableAnnotationNode,
  isSelectableContainerNode,
} from '../utils/nodeUtils';

const HOVER_RECT_STYLE = {
  fill: 'rgba(128, 128, 128, 0.1)',
  stroke: 'rgb(74, 103, 251)', // cogs-border--interactive--toggled-pressed
  strokeWidth: 4,
};

const getConnectionNodeEndpoint = (
  shape: Konva.Node | undefined
): Konva.Node | undefined => {
  if (shape === undefined) {
    return undefined;
  }

  // Lines can't be used for connections
  if (shape instanceof Konva.Arrow) {
    return undefined;
  }

  const annotationNode = findNearestAnnotationAncestor(shape);
  if (
    annotationNode !== undefined &&
    isSelectableAnnotationNode(annotationNode)
  ) {
    return annotationNode;
  }

  const containerNode = findNearestContainerGroup(shape);
  if (containerNode !== undefined && isSelectableContainerNode(containerNode)) {
    return containerNode;
  }

  return undefined;
};

class ConnectionHelper {
  private unifiedViewer: UnifiedViewer;
  private hoverRect: Konva.Rect | undefined = undefined;
  private mouseDownNode: Konva.Node | undefined = undefined;
  private mouseUpNode: Konva.Node | undefined = undefined;
  private isEnabled: boolean = true;
  public constructor(unifiedViewer: UnifiedViewer) {
    this.unifiedViewer = unifiedViewer;
    this.enable();
  }

  public enable = (): void => {
    this.isEnabled = true;
    this.unifiedViewer.stage.on('mousedown', this.handleMouseDown);
    this.unifiedViewer.stage.on('mouseup', this.handleMouseUp);
    this.unifiedViewer.addEventListener(
      UnifiedViewerEventType.ON_ANNOTATIONS_LOAD,
      this.onUpdateRequest
    );
  };

  public disable = (): void => {
    this.isEnabled = false;
    this.unifiedViewer.stage.off('mousedown', this.handleMouseDown);
    this.unifiedViewer.stage.off('mouseup', this.handleMouseUp);
    this.unifiedViewer.removeEventListener(
      UnifiedViewerEventType.ON_ANNOTATIONS_LOAD,
      this.onUpdateRequest
    );
    this.reset();
  };

  public clearHoverRect = (): void => {
    this.hoverRect?.destroy();
    this.hoverRect = undefined;
  };

  public clearConnection = (): void => {
    this.mouseDownNode = undefined;
    this.mouseUpNode = undefined;
  };

  // Update the hover effect if nodes change while the connection helper
  // is enabled, ensuring we don't get lingering hover rects.
  private onUpdateRequest = () => {
    if (!this.isEnabled || this.hoverRect === undefined) {
      return;
    }
    this.updateHoverEffect();
  };

  private getEndpointClientRectFromShape = (
    shape: Konva.Node
  ): IRect | undefined => {
    const connectionNode = getConnectionNodeEndpoint(shape);
    if (connectionNode !== undefined) {
      if (isAnnotationNode(connectionNode)) {
        return this.unifiedViewer.getAnnotationRectRelativeToStageById(
          connectionNode.id()
        );
      }
      if (isContainerNode(connectionNode)) {
        return this.unifiedViewer.getContainerRectRelativeToStageById(
          connectionNode.id()
        );
      }
    }
    return undefined;
  };

  private getIntersectionEndpoint = () => {
    const pointerPosition = this.unifiedViewer.stage.getPointerPosition();

    if (pointerPosition === null) {
      return;
    }

    const hoveredShape =
      this.unifiedViewer.layers.main.layer.getIntersection(pointerPosition);

    if (hoveredShape === undefined || hoveredShape === null) {
      return undefined;
    }

    return getConnectionNodeEndpoint(hoveredShape);
  };

  private handleMouseDown = () => {
    this.mouseDownNode = this.getIntersectionEndpoint();
  };

  private handleMouseUp = () => {
    this.mouseUpNode = this.getIntersectionEndpoint();
  };

  public updateHoverEffect = (): void => {
    if (!this.isEnabled) {
      return;
    }

    const intersectionEndpoint = this.getIntersectionEndpoint();
    if (intersectionEndpoint === undefined) {
      this.clearHoverRect();
      return;
    }

    const clientRect =
      this.getEndpointClientRectFromShape(intersectionEndpoint);

    if (this.hoverRect === undefined) {
      this.hoverRect = new Konva.Rect({
        ...HOVER_RECT_STYLE,
        ...clientRect,
        id: uuid(),
      });
      this.unifiedViewer.layers.main.add(this.hoverRect);
      this.hoverRect.moveToTop();
      this.hoverRect.moveDown();
      this.hoverRect.listening(false);
    }

    this.hoverRect.setAttrs(clientRect);
  };

  public getConnectionEndpointNodes = (): {
    mouseDownNode: Konva.Node | undefined;
    mouseUpNode: Konva.Node | undefined;
  } => {
    return {
      mouseDownNode: this.mouseDownNode,
      mouseUpNode: this.mouseUpNode,
    };
  };

  public reset = (): void => {
    this.clearHoverRect();
    this.clearConnection();
  };

  public onDestroy = (): void => {
    this.disable();
    this.reset();
  };
}
export default ConnectionHelper;
