import { Textarea } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const MessageContainer = styled.div`
  width: 100%;
`;

export const MessageTextarea = styled(Textarea)`
  &.cogs-textarea {
    flex: 1;
    .cogs-textarea-container {
      display: flex;
    }
  }
`;
