import {
  RevealContext,
  RevealKeepAlive,
} from '@cognite/reveal-react-components';
import type { CogniteClient } from '@cognite/sdk';
import { isLowEndDevice } from '@infield/features/3d/utils';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { Suspense } from 'react';
import type { FC, PropsWithChildren } from 'react';

type Props = {
  client: CogniteClient;
};
export const RevealContextProvider: FC<PropsWithChildren<Props>> = ({
  children,
  client,
}) => {
  const { isIdm } = useAppConfigContext();
  const RESOLUTION_THRESHOLD_REDUCTION = 0.65;
  const viewerOptions = isLowEndDevice()
    ? {
        rendererResolutionThreshold: 320 * 570 * RESOLUTION_THRESHOLD_REDUCTION,
        ssaoQualityHint: 'disabled' as const,
        antiAliasingHint: 'disabled' as const,
        continuousModelStreaming: false,
        enableEdges: false,
      }
    : undefined;
  return (
    <RevealKeepAlive>
      <RevealContext
        sdk={client}
        viewerOptions={viewerOptions}
        useCoreDm={isIdm}
      >
        <Suspense>{children}</Suspense>
      </RevealContext>
    </RevealKeepAlive>
  );
};
