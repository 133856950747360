import { Body, Menu } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

export const ActionMenu = styled(Menu)<{
  $isDesktop: boolean;
}>`
  z-index: ${z.ACTION_MENU};
  ${({ $isDesktop }) =>
    $isDesktop
      ? ''
      : `
    position: absolute;
    bottom: 0;
    margin: 16px 0;
    width: calc(100vw - 16px);
    max-width: 500px;
    left: 50%;
    transform: translate(-50%, 0);
  `};
`;

export const DescriptionText = styled(Body)`
  color: var(--cogs-text-icon--interactive--disabled);
`;
