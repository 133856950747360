import { FDMClient } from '@cognite/fdm-client';
import type { CogniteClient, Timeseries, ViewReference } from '@cognite/sdk';

import { ActionsService } from './actions';
import type { APMAppDataService } from './apm-app-data-service';
import { APMEdgeDataService } from './apm-edge-data-service';
import { ChecklistItemsService } from './checklist-items';
import { ChecklistsService } from './checklists';
import { ConditionalActionsService } from './conditional-actions';
import { ConditionsService } from './conditions';
import { InFieldLocationConfigService } from './infield-location-config';
import { IntervalsService } from './intervals';
import { MeasurementService } from './measurements';
import { ObservationConfigService } from './observation-fields-config';
import { ObservationsService } from './observations';
import { TemplateItemsService } from './template-items';
import { TemplatesService } from './templates';
import type {
  Action,
  Activity,
  APMAsset,
  ChecklistItemResponseType,
  ChecklistResponseType,
  Condition,
  ConditionalAction,
  FdmFile,
  Measurement,
  Notification,
  Observation,
  Operation,
  ServiceContract,
  TaskIntervalUpsertType,
  TemplateItemResponseType,
  TemplateResponseType,
  UserPreferenceViewName,
  UserViewName,
} from './types';
import { UserPreferencesService } from './user-preferences';
import { UserService } from './users';
import { VIEW_VERSIONS } from './utils';
import { ViewCache } from './view-cache';
import { CDFView } from './view-service';

type APMClientProps = {
  cogniteClient: CogniteClient;
  appDataModelSpace: string;
  appDataModelName: string;
  appDataModelVersion: string;
  appDataInstanceSpace: string;
  sourceDataModelSpace: string;
  sourceDataModelName: string;
  sourceDataModelVersion: string;
  sourceDataInstanceSpace: string;
};

export type Options = {
  userViewName?: UserViewName;
  userModelSpace?: string;
  userModelName?: string;
  userInstanceSpace?: string;
  userModelVersion?: string;
  userPreferencesViewName?: UserPreferenceViewName;

  activitiesView: Omit<ViewReference, 'type'>;
  operationsView: Omit<ViewReference, 'type'>;
  notificationsView: Omit<ViewReference, 'type'>;

  assetsView?: Omit<ViewReference, 'type'>;
  filesView?: Omit<ViewReference, 'type'>;
  timeseriesView?: Omit<ViewReference, 'type'>;
};

export class APMClient {
  appDataModelSpace: string;
  appDataModelName: string;
  appDataModelVersion: string;
  appDataInstanceSpace: string;
  sourceDataModelSpace: string;
  sourceDataModelName: string;
  sourceDataModelVersion: string;
  sourceDataInstanceSpace: string;
  userInstanceSpace: string;
  fdmClient: FDMClient;

  // Source data
  activities: CDFView<Activity>;
  notifications: CDFView<Notification>;
  operations: CDFView<Operation>;

  // App data
  templates: APMAppDataService<TemplateResponseType>;
  templateItems: APMAppDataService<TemplateItemResponseType>;
  checklists: APMAppDataService<ChecklistResponseType>;
  checklistItems: APMAppDataService<ChecklistItemResponseType>;
  edgeService: APMEdgeDataService;
  observations: APMAppDataService<Observation>;
  observationsDMS: CDFView<Observation>;
  intervals: APMAppDataService<TaskIntervalUpsertType>;
  measurements: APMAppDataService<Measurement>;
  conditions: APMAppDataService<Condition>;
  conditionalActions: APMAppDataService<ConditionalAction>;
  actions: APMAppDataService<Action>;
  users: UserService;
  userPreferences: UserPreferencesService;
  observationConfig: ObservationConfigService;
  infieldLocationConfig: InFieldLocationConfigService;
  assets: CDFView<APMAsset>;
  files?: CDFView<FdmFile>;
  timeseries?: CDFView<Timeseries>;
  viewCache: ViewCache;

  options: Options | undefined;

  constructor(data: APMClientProps, options: Options) {
    this.appDataModelSpace = data.appDataModelSpace;
    this.appDataModelName = data.appDataModelName;
    this.appDataModelVersion = data.appDataModelVersion;
    this.appDataInstanceSpace = data.appDataInstanceSpace;
    this.sourceDataModelSpace = data.sourceDataModelSpace;
    this.sourceDataModelName = data.sourceDataModelName;
    this.sourceDataModelVersion = data.sourceDataModelVersion;
    this.sourceDataInstanceSpace = data.sourceDataInstanceSpace;
    this.userInstanceSpace = options?.userInstanceSpace ?? 'cognite_app_data';
    this.viewCache = new ViewCache(data.cogniteClient);

    this.fdmClient = new FDMClient(data.cogniteClient);

    this.options = options;

    this.activities = new CDFView<Activity>(
      data.cogniteClient,
      options.activitiesView,
      this.sourceDataInstanceSpace,
      this.viewCache
    );
    this.operations = new CDFView<Operation>(
      data.cogniteClient,
      options.operationsView,
      this.sourceDataInstanceSpace,
      this.viewCache
    );
    this.notifications = new CDFView<Notification>(
      data.cogniteClient,
      options.notificationsView,
      this.sourceDataInstanceSpace,
      this.viewCache
    );

    this.assets = new CDFView<APMAsset>(
      data.cogniteClient,
      options.assetsView || {
        externalId: 'Asset',
        space: 'cdf_core',
        version: 'v1',
      },
      this.sourceDataInstanceSpace,
      this.viewCache
    );

    if (options.filesView) {
      this.files = new CDFView<FdmFile>(
        data.cogniteClient,
        options.filesView,
        this.sourceDataInstanceSpace,
        this.viewCache
      );
    }

    if (options.timeseriesView) {
      this.timeseries = new CDFView<Timeseries>(
        data.cogniteClient,
        options.timeseriesView,
        this.sourceDataInstanceSpace,
        this.viewCache
      );
    }

    const appServiceContract: ServiceContract = {
      modelSpace: this.appDataModelSpace,
      modelName: this.appDataModelName,
      modelVersion: this.appDataModelVersion,
      instanceSpace: this.appDataInstanceSpace,
      fdmClient: this.fdmClient,
    };

    const userServiceContract: ServiceContract = {
      modelSpace: this.options?.userModelSpace ?? 'cdf_apps_shared',
      instanceSpace: this.options?.userInstanceSpace ?? 'cognite_app_data',
      modelName: this.options?.userModelName ?? 'CDF_User',
      modelVersion: this.options?.userModelVersion ?? 'v1',
      fdmClient: this.fdmClient,
    };

    const inFieldSpecificConfigServiceContract: ServiceContract = {
      modelSpace: 'cdf_infield',
      modelName: 'InField',
      modelVersion: 'v1',
      instanceSpace: 'APM_Config',
      fdmClient: this.fdmClient,
    };

    this.templates = new TemplatesService(appServiceContract, {
      ignoreArchivedByDefault: true,
    });
    this.templateItems = new TemplateItemsService(appServiceContract, {
      ignoreArchivedByDefault: true,
    });
    this.checklists = new ChecklistsService(appServiceContract, {
      ignoreArchivedByDefault: true,
    });
    this.checklistItems = new ChecklistItemsService(appServiceContract, {
      ignoreArchivedByDefault: true,
    });
    this.observations = new ObservationsService(appServiceContract, {
      ignoreArchivedByDefault: true,
    });
    this.observationsDMS = new CDFView<Observation>( // DMS because we need aggregation on direct relation (asset)
      data.cogniteClient,
      {
        externalId: 'Observation',
        space: 'cdf_apm',
        version: VIEW_VERSIONS.OBSERVATION,
      },
      this.appDataInstanceSpace,
      this.viewCache
    );
    this.edgeService = new APMEdgeDataService(appServiceContract);
    this.intervals = new IntervalsService(appServiceContract, {
      ignoreArchivedByDefault: true,
    });
    this.measurements = new MeasurementService(appServiceContract, {
      ignoreArchivedByDefault: true,
    });
    this.conditions = new ConditionsService(appServiceContract, {
      ignoreArchivedByDefault: true,
    });
    this.conditionalActions = new ConditionalActionsService(
      appServiceContract,
      {
        ignoreArchivedByDefault: true,
      }
    );
    this.actions = new ActionsService(appServiceContract, {
      ignoreArchivedByDefault: true,
    });
    this.users = new UserService(
      userServiceContract,
      data.cogniteClient,
      this.options
    );
    this.userPreferences = new UserPreferencesService(
      userServiceContract,
      this.options
    );
    this.observationConfig = new ObservationConfigService(
      inFieldSpecificConfigServiceContract
    );
    this.infieldLocationConfig = new InFieldLocationConfigService(
      inFieldSpecificConfigServiceContract
    );
  }
}
