import { CogniteClient } from '@cognite/sdk';

import { ServicesAvaialabilityResponseDTO } from '../../types';

export const getServicesAvailability = async (sdk: CogniteClient) => {
  const { data } = await sdk.get<ServicesAvaialabilityResponseDTO>(
    `/api/v1/projects/${sdk.project}/ai/services/availability`,
    {
      headers: {
        'cdf-version': 'alpha',
      },
    }
  );

  return data.items;
};
