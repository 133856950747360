import { useEffect, useRef, useState } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Vector3 } from 'three';

import { Cognite3DViewer, CogniteModel } from '@cognite/reveal';
import { CogniteClient } from '@cognite/sdk';
import { SDKProvider, useSDK } from '@cognite/sdk-provider';

import { ReactContainerRenderContentProps } from '../ReactContainer';

import getInstanceBoundingBox from './getInstanceBoundingBox';
import highlightInstance from './highlightInstance';
import { RevealContainerProps } from './types';

type RevealContentProps = Pick<
  ReactContainerRenderContentProps,
  'width' | 'height'
> &
  Pick<
    RevealContainerProps,
    'modelId' | 'revisionId' | 'initialInstance' | 'camera'
  > & {
    onModelLoaded: (viewer: Cognite3DViewer, model: CogniteModel) => void;
    onLoading: (
      itemsLoaded: number,
      itemsRequested: number,
      itemsCulled: number
    ) => void;
  };

const onKeyDown = (event: React.KeyboardEvent) => {
  if (event.key === 'Escape') {
    return;
  }
  event.stopPropagation();
};

const useCameraState = ({
  viewer,
  model,
  camera,
  sdk,
  modelId,
  revisionId,
  initialInstance,
  onModelLoaded,
}: {
  viewer: Cognite3DViewer | undefined;
  model: CogniteModel | undefined;
  camera:
    | {
        position: {
          x: number;
          y: number;
          z: number;
        };
        target: {
          x: number;
          y: number;
          z: number;
        };
      }
    | undefined;
  sdk: CogniteClient;
  modelId: number;
  revisionId: number;
  initialInstance: RevealContainerProps['initialInstance'];
  onModelLoaded: (viewer: Cognite3DViewer, model: CogniteModel) => void;
}) => {
  useEffect(() => {
    const loadViewerState = async () => {
      if (viewer === undefined) {
        return;
      }

      if (model === undefined) {
        return;
      }

      if (camera !== undefined) {
        viewer.cameraManager.setCameraState({
          position: new Vector3(
            camera.position.x,
            camera.position.y,
            camera.position.z
          ),
          target: new Vector3(
            camera.target.x,
            camera.target.y,
            camera.target.z
          ),
        });
        return;
      }

      if (initialInstance !== undefined) {
        const assetBoundingBox = await getInstanceBoundingBox(
          sdk,
          model,
          modelId,
          revisionId,
          initialInstance
        );

        if (assetBoundingBox !== undefined) {
          viewer.fitCameraToBoundingBox(assetBoundingBox, 0, 3);
          return;
        }
      }

      viewer.loadCameraFromModel(model);
    };
    loadViewerState();
  }, [
    viewer,
    model,
    modelId,
    revisionId,
    camera,
    initialInstance,
    onModelLoaded,
    sdk,
  ]);
};

const useHighlightInstance = ({
  sdk,
  model,
  initialInstance,
}: {
  sdk: CogniteClient;
  model: CogniteModel | undefined;
  initialInstance: RevealContainerProps['initialInstance'];
}) => {
  useEffect(() => {
    if (model === undefined) {
      return;
    }

    highlightInstance(sdk, model, initialInstance);
  }, [sdk, model, initialInstance]);
};

const useModel = ({
  viewer,
  initialInstance,
  modelId,
  revisionId,
  onModelLoaded,
  sdk,
}: {
  viewer: Cognite3DViewer | undefined;
  initialInstance: RevealContainerProps['initialInstance'];
  modelId: number;
  revisionId: number;
  onModelLoaded: (viewer: Cognite3DViewer, model: CogniteModel) => void;
  sdk: CogniteClient;
}) => {
  const [model, setModel] = useState<CogniteModel | undefined>(undefined);
  useEffect(() => {
    return () => {
      if (model !== undefined) {
        // The model could have already been disposed from the viewer by an earlier call
        // to viewer.dispose()
        const doesViewerHaveModel = viewer?.models.some(
          (viewerModel) => viewerModel === model
        );

        if (doesViewerHaveModel) {
          viewer?.removeModel(model);
        }
        setModel(undefined);
      }
    };
  }, [viewer, model, initialInstance]);

  useEffect(() => {
    (async () => {
      if (viewer === undefined) {
        return;
      }
      const loadedModel = await viewer.addModel({ modelId, revisionId });
      onModelLoaded(viewer, loadedModel);
      setModel(loadedModel);
    })();
  }, [sdk, viewer, modelId, revisionId, onModelLoaded, initialInstance]);
  return model;
};

const RevealContent: React.FC<RevealContentProps> = ({
  width,
  height,
  modelId,
  revisionId,
  initialInstance,
  camera,
  onModelLoaded,
  onLoading,
}) => {
  const [viewer, setViewer] = useState<Cognite3DViewer>();

  const canvasWrapperRef = useRef<HTMLDivElement>(null);
  const sdk = useSDK();

  useEffect(() => {
    if (canvasWrapperRef.current === null) {
      throw new Error('Failure in mounting RevealContainer to DOM.');
    }

    const newViewer = new Cognite3DViewer({
      sdk,
      onLoading: (itemsLoaded, itemsRequested, itemsCulled) =>
        onLoading(itemsLoaded, itemsRequested, itemsCulled),
      domElement: canvasWrapperRef.current,
      useFlexibleCameraManager: true,
    });
    // Set budget to avoid loading too much data
    newViewer.cadBudget = {
      highDetailProximityThreshold: 5,
      maximumRenderCost: 3_000_000,
    };
    // Half of default in reveal
    newViewer.pointCloudBudget = {
      numberOfPoints: 1_500_000,
    };

    setViewer(newViewer);
    return () => {
      newViewer?.dispose();
    };
  }, [sdk, onLoading]);

  const model = useModel({
    viewer,
    modelId,
    revisionId,
    initialInstance,
    onModelLoaded,
    sdk,
  });

  useHighlightInstance({
    sdk,
    model,
    initialInstance,
  });

  useCameraState({
    viewer,
    model,
    camera,
    sdk,
    modelId,
    revisionId,
    initialInstance,
    onModelLoaded,
  });

  return (
    <div
      onKeyDown={onKeyDown}
      style={{ width, height }}
      ref={canvasWrapperRef}
    />
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 10 * 60 * 1000, // Pretty long, 600 seconds
    },
  },
});

const WrappedRevealContent = ({
  sdk,
  ...props
}: RevealContentProps & { sdk: CogniteClient }): JSX.Element => {
  return (
    <SDKProvider sdk={sdk}>
      <QueryClientProvider client={queryClient}>
        <RevealContent {...props} />
      </QueryClientProvider>
    </SDKProvider>
  );
};

export default WrappedRevealContent;
