import chroma from 'chroma-js';
import { memoize } from 'lodash-es';

const isTransparentColor: (color: string | undefined) => boolean = memoize(
  (color) => {
    if (color === undefined) {
      return true;
    }

    // Chroma does not recognise "transparent" keyword, hence this check
    if (color === 'transparent') {
      return true;
    }

    if (chroma(color).alpha() === 0) {
      return true;
    }

    return false;
  }
);

export default isTransparentColor;
