import { AddIcon, Button, Flex, Skeleton, TagChip } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { PanelHeader } from '@infield/features/ui';
import type { FC } from 'react';

import * as S from './elements';

type Props = {
  newObservationsTodayCount?: number;
  isCountLoading?: boolean;
  onCreateNewObservation: () => void;
};

export const ObservationsOverviewToolbar: FC<Props> = ({
  newObservationsTodayCount,
  isCountLoading,
  onCreateNewObservation,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);
  const countLabelValue = newObservationsTodayCount || 0;

  return (
    <PanelHeader
      title={t('OBSERVATIONS_TOOLBAR_HEADER_TITLE', 'All observations')}
      childrenPosition="start"
    >
      <Flex alignItems="center" gap={16}>
        {isCountLoading ? (
          <Skeleton.Rectangle
            aria-label="observation-toolbar-skeleton-loader"
            width="80px"
            height="100%"
          />
        ) : (
          <S.ToolbarContentWrapper gap={8} alignItems="center">
            <TagChip
              colorScheme="blue"
              size="x-small"
              label={`${countLabelValue} ${t(
                'OBSERVATIONS_OVERVIEW_TOOLBAR_NEW_TODAY',
                'new today'
              )}`}
            />
          </S.ToolbarContentWrapper>
        )}
        <Button
          icon={<AddIcon />}
          type="primary"
          onClick={() => onCreateNewObservation()}
        >
          {t(
            'OBSERVATIONS_TOOLBAR_BUTTON_CREATE_OBSERVATION',
            'Create observation'
          )}
        </Button>
      </Flex>
    </PanelHeader>
  );
};
