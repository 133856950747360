import { useCallback } from 'react';

import { TOptions } from 'i18next';

import { useTypedTranslation } from '@cognite/cdf-i18n-utils';

// ENGLISH is the default language and source of truth.
import english from '../i18n/en/ai.json';

export type TranslationKey = keyof typeof english;

export type TFunction = (key: TranslationKey, options?: TOptions) => string;

const test = false;

export const useTranslation = () => {
  const { t: i18nTranslate } = useTypedTranslation();

  const translate: TFunction = useCallback(
    (key: TranslationKey, options: TOptions = {}) => {
      if (test) {
        return 'TRANSLATED_STRING' as string;
      }

      return i18nTranslate(key, {
        defaultValue: english[key],
        ...options,
      });
    },
    [i18nTranslate]
  );

  return {
    t: translate,
  };
};
