import {
  Body,
  DesktopIcon,
  MobileIcon,
  SegmentedControl,
} from '@cognite/cogs.js-v10';
import { TabContent } from '@cognite/user-profile-components';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { DeviceType } from '@infield/providers/device-provider';
import {
  DeviceTypes,
  useDeviceContext,
} from '@infield/providers/device-provider';
import { type FC } from 'react';

import * as S from './elements';

export const DeviceTabContent: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.user);
  const { device, setDevice } = useDeviceContext();

  const handleOnDeviceChange = (key: DeviceType) => {
    if (device !== key) {
      setDevice(key);
    }
  };

  const deviceTypeMobileTrans = t(
    'USER_PROFILE_DEVICE_TAB_MOBILE_OPTION',
    'mobile'
  );
  const deviceTypeDesktopTrans = t(
    'USER_PROFILE_DEVICE_TAB_DESKTOP_OPTION',
    'desktop'
  );

  return (
    <TabContent.Container>
      <TabContent.Body>
        <Body size="medium" strong>
          {t('USER_PROFILE_DEVICE_TAB_TITLE', 'Select mode')}
        </Body>
        <SegmentedControl
          fullWidth
          currentKey={device || DeviceTypes.desktop}
          onButtonClicked={handleOnDeviceChange}
        >
          <S.ControlButton
            key={DeviceTypes.mobile}
            icon={<MobileIcon />}
            style={{ fontWeight: 500 }}
          >
            {deviceTypeMobileTrans}
          </S.ControlButton>
          <S.ControlButton key={DeviceTypes.desktop} icon={<DesktopIcon />}>
            {deviceTypeDesktopTrans}
          </S.ControlButton>
        </SegmentedControl>
        <Body size="medium">
          {t(
            'USER_PROFILE_DEVICE_TAB_SELECTED_OPTION_DESCRIPTION',
            `You are using a {{currentDevice}} version of InField. Switch to {{anotherDevice}} mode to make it default on this device.`,
            {
              currentDevice:
                device === DeviceTypes.mobile
                  ? deviceTypeMobileTrans
                  : deviceTypeDesktopTrans,
              anotherDevice:
                device === DeviceTypes.mobile
                  ? deviceTypeDesktopTrans
                  : deviceTypeMobileTrans,
            }
          )}
        </Body>
      </TabContent.Body>
    </TabContent.Container>
  );
};
