import type { APMClient } from '@cognite/apm-client';
import { FDMClient } from '@cognite/fdm-client';
import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import type {
  Asset,
  AssetFilterProps,
  CogniteClient,
  ItemsResponse,
  ViewDefinition,
} from '@cognite/sdk';

import { AssetIDMMigrator } from './asset-idm-migrator';

export class AssetService extends FDMClient {
  client;
  apmClient: APMClient;

  constructor(
    client: CogniteClient,
    apmClient: APMClient,
    isIdm: boolean,
    baseViewDefinition?: ViewDefinition,
    customerViewDefinition?: ViewDefinition
  ) {
    super(client);
    this.client = client;
    this.apmClient = apmClient;

    if (isIdm && baseViewDefinition && customerViewDefinition) {
      this.apmClient.assets.migrator = new AssetIDMMigrator(
        this.apmClient.sourceDataInstanceSpace,
        baseViewDefinition,
        customerViewDefinition
      );
    }
  }

  /**
   * Fetches APM assets by externalId across all spaces
   * @param assetExternalIds
   * @param akerbpCustomFilter
   * @returns
   */
  async getAPMAssetByExternalIds(
    assetExternalIds: string[],
    akerbpCustomFilter?: boolean
  ) {
    if (assetExternalIds.length === 0) return [];
    const uniqueAssetExternalIds = [...new Set(assetExternalIds)];
    const { items: assets } = await this.apmClient.assets.list({
      filters: {
        in: {
          property: 'externalId',
          in: uniqueAssetExternalIds,
        },
      },
      spaces: [], // Query across all spaces
    });

    if (assets && assets.length > 0 && akerbpCustomFilter) {
      // TODO: When AkerBP migrates to IDM, check if asset is voided (WORKFLOW STATUS == 'Void')
    }
    return assets;
  }

  async getAssetByExternalId(
    assetExternalId: string,
    akerbpCustomFilter?: boolean
  ) {
    const [assetData] = await this.client.assets.retrieve([
      { externalId: assetExternalId },
    ]);
    if (akerbpCustomFilter) {
      if (assetData.metadata?.['WORKFLOW STATUS'] === 'Void') {
        throw new Error('Asset is voided');
      }
    }
    return assetData;
  }

  async getAssetByExternalIds(assetExternalId: string[]) {
    const assetsData = await this.client.assets.retrieve(
      assetExternalId.map((externalId) => ({ externalId })),
      {
        ignoreUnknownIds: true,
      }
    );
    return assetsData;
  }

  // TODO: Deprecate this in future PR, use above method instead
  async getLocationAssetsByExternalIds(assetExternalIds: string[]) {
    if (assetExternalIds.length === 0) return [];
    const assetData = await this.client
      .post<ItemsResponse<Asset>>(
        `/api/v1/projects/${this.client.project}/assets/list`,
        {
          headers: {
            'cdf-version': 'alpha',
          },
          data: {
            advancedFilter: {
              in: {
                property: ['externalId'],
                values: [...assetExternalIds],
              },
            },
          },
        }
      )
      .then((res) => res.data.items);

    return assetData.sort((a, b) => a.name.localeCompare(b.name));
  }

  async getAssetById(assetId: number) {
    const [assetData] = await this.client.assets.retrieve([{ id: assetId }]);
    return assetData;
  }

  async getAssets(filter?: AssetFilterProps, akerbpCustomFilter?: boolean) {
    const { items: assets } = await this.client.assets.list({
      filter,
    });
    return assets.filter((asset) => {
      if (akerbpCustomFilter) {
        return asset.metadata?.['WORKFLOW STATUS'] !== 'Void';
      }
      return asset;
    });
  }

  async getAssetByName(
    name: string,
    cdfClassicFilters: AssetFilterProps | undefined
  ) {
    const {
      items: [asset],
    } = await this.client.assets.list({
      filter: { name, ...cdfClassicFilters },
      limit: 1,
    });
    return asset;
  }

  /**
   * TODO(INFIELD2-2548): Replace this method's filter with new upcoming solution (isRoot property), since this filter is not performant for large numbers of assets (e.g. > 100 000).
   * @returns Root assets across all spaces
   */
  async getRootAPMAssets() {
    const rootAssets = await this.apmClient.assets.list({
      filters: {
        nested: {
          scope: 'parent',
          filters: {
            isNull: {
              property: 'externalId',
              isNull: true,
            },
          },
        },
      },
      spaces: [], // Query across all spaces
    });
    return rootAssets.items;
  }

  async getRootAssets() {
    const rootAssets = await this.client.assets.list({
      filter: { root: true },
      limit: 1000,
    });
    return rootAssets.items;
  }

  async getAPMAsset(filters?: Filters, sort?: Sort, pageSize = 1000) {
    const {
      items: [asset],
    } = await this.apmClient.assets.list({ filters, sort, pageSize });
    return asset;
  }

  async listAPMAssets(
    filters?: Filters,
    sort?: Sort,
    pageSize = 1000,
    spaces?: string[]
  ) {
    const { items: assets } = await this.apmClient.assets.list({
      filters,
      sort,
      pageSize,
      spaces,
    });
    return assets;
  }

  async searchAssets(
    query?: string,
    limit?: number,
    filter?: AssetFilterProps,
    akerbpCustomFilter?: boolean
  ): Promise<Asset[]> {
    const assets = await this.client.assets.search({
      search: {
        query,
      },
      limit,
      filter,
    });
    return assets.filter((asset) => {
      if (akerbpCustomFilter) {
        return asset.metadata?.['WORKFLOW STATUS'] !== 'Void';
      }
      return asset;
    });
  }

  async getAPMAssetByName(name: string, filter?: Filters) {
    const filters: Filters[] = [];

    if (filter) {
      filters.push({ ...filter });
    }
    const nameFilter = {
      equals: {
        property: 'title',
        eq: name,
      },
    };
    filters.push(nameFilter);

    const {
      items: [asset],
    } = await this.apmClient.assets.list({
      filters: {
        and: [...filters],
      },
    });

    return asset;
  }
}
