import { Dropdown, Menu } from '@cognite/cogs-lab';
import {
  Button,
  CertificateIcon,
  Topbar as CogsTopBar,
  Divider,
  DocumentationIcon,
  Flex,
  HelpIcon,
  SpeakerphoneIcon,
} from '@cognite/cogs.js-v10';
import { useAuthContext } from '@cognite/e2e-auth';
import { useMetrics } from '@cognite/metrics';
import { ReleaseManagementModal } from '@cognite/release-toggles';
import { UserMenu } from '@cognite/user-profile-components';
import { useIsAppConfiguratorQuery } from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { NavigationDesktop } from '@infield/features/top-bar';
import type { NavigationItem } from '@infield/features/top-bar';
import { useIsSelfServiceReleaseManagementEnabled } from '@infield/hooks/index';
import { useInfieldLogout } from '@infield/hooks/use-infield-logout';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { type FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { allowedVersions } from './constants';
import * as S from './elements';

type Props = {
  isAkerbpCustomCode: boolean;
  navigationItems: NavigationItem[];
  onShowSearch: () => void;
};

export const TopBarDesktop: FunctionComponent<Props> = ({
  isAkerbpCustomCode,
  navigationItems,
  onShowSearch,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mainTopbar);
  const { logout } = useInfieldLogout();
  const metrics = useMetrics(METRICS_NAMESPACES.navigation);
  const { isOnline } = useNetworkStatusContext();
  const navigate = useNavigate();
  const { data: hasAppConfigPermission } = useIsAppConfiguratorQuery();

  const [isReleaseManagementModalVisible, setShowSelfServiceReleaseModal] =
    useState(false);
  const isSelfServiceReleaseManagementEnabled =
    useIsSelfServiceReleaseManagementEnabled();

  const {
    authState: { user },
  } = useAuthContext();

  return (
    <>
      <CogsTopBar>
        <CogsTopBar.Left>
          <S.LogoContainer>
            <CogsTopBar.Logo type="InField">Cognite InField</CogsTopBar.Logo>
            <Divider direction="vertical" length="56px" />
          </S.LogoContainer>
          <NavigationDesktop navigationItems={navigationItems} />
        </CogsTopBar.Left>
        <CogsTopBar.Right>
          <Flex gap={16} alignItems="center">
            <Divider direction="vertical" length="56px" />
            <Button
              key="search"
              type="ghost"
              aria-label="desktop-topbar-search"
              icon={
                <S.TopBarSearchIcon
                  data-testid="desktop-topbar-search"
                  disabled={!isOnline}
                  data-chmln="infield-beginning-6581b8b08073d100194fb218-step-2"
                />
              }
              disabled={!isOnline}
              onClick={onShowSearch}
            />
            {hasAppConfigPermission && (
              <Button
                key="app-configuration"
                aria-label="app-configuration"
                disabled={!(hasAppConfigPermission && isOnline)}
                onClick={() => navigate('/application-configuration')}
                icon={
                  <S.TopBarSettingsIcon
                    data-testid="desktop-topbar-settings"
                    disabled={!isOnline}
                  />
                }
                type="ghost"
              />
            )}
            {isAkerbpCustomCode && (
              <Menu
                renderTrigger={(props) => (
                  <Button
                    {...props}
                    icon={<HelpIcon />}
                    type="ghost"
                    disabled={!isOnline}
                    data-testid="help-menu-button"
                  />
                )}
                size="medium"
              >
                <Menu.ItemAction
                  label={t(
                    'TOPBAR-HELP-BUTTON-VIEW-DOCUMENTATION',
                    'View documentation'
                  )}
                  icon={<DocumentationIcon />}
                  onClick={() =>
                    window
                      .open('https://docs.cognite.com/infield_vnext/', '_blank')
                      ?.focus()
                  }
                  data-testid="view-documentation-button"
                />
                <Menu.ItemAction
                  label={t('TOPBAR-HELP-BUTTON-WHATS-NEW', "What's new")}
                  icon={<SpeakerphoneIcon />}
                  onClick={() =>
                    window
                      .open('https://docs.cognite.com/cdf/whatsnew/', '_blank')
                      ?.focus()
                  }
                  data-testid="whats-new-button"
                />
                <Menu.ItemAction
                  label={t(
                    'TOPBAR-HELP-BUTTON-ACADEMY',
                    'Learn at the Academy'
                  )}
                  icon={<CertificateIcon />}
                  onClick={() =>
                    window
                      .open(
                        'https://learn.cognite.com/path/working-with-cognite-infield',
                        '_blank'
                      )
                      ?.focus()
                  }
                  data-testid="academy-button"
                />
              </Menu>
            )}
            <Dropdown
              key="avatar"
              disabled={!isOnline}
              placement="bottom-end"
              content={
                <UserMenu
                  userInfo={{
                    name: user.name,
                    email: user.email,
                  }}
                  onLogoutClick={logout}
                  menuTitle={t('label-account', 'Account')}
                  menuItemManageAccountBtnText={t(
                    'label-manage-account',
                    'Manage Account'
                  )}
                  menuItemLogoutBtnText={t('sign-out-btn-text', 'Sign out')}
                  onTrackEvent={(eventName) => metrics.track(eventName)}
                >
                  {isSelfServiceReleaseManagementEnabled ? (
                    <S.MenuItemAction
                      label="Self-service release management"
                      key="selfServiceRelease"
                      onClick={() => setShowSelfServiceReleaseModal(true)}
                      icon={undefined}
                    />
                  ) : undefined}
                </UserMenu>
              }
            >
              <S.TopBarAvatar
                text={user.name}
                tooltip={false}
                disabled={!isOnline}
                onClick={() => metrics.track('userAvatarClicked')}
                image={user.picture}
              />
            </Dropdown>
          </Flex>
        </CogsTopBar.Right>
      </CogsTopBar>
      <ReleaseManagementModal
        onClose={() => setShowSelfServiceReleaseModal(false)}
        allowedVersions={allowedVersions}
        visible={isReleaseManagementModalVisible}
        appName="InField"
      />
    </>
  );
};
