import Konva from 'konva';

import BackgroundLayerContainer from '../../containers/BackgroundLayer';
import DocumentContainer from '../../containers/DocumentContainer';
import {
  UNIFIED_VIEWER_NODE_TYPE_KEY,
  UnifiedViewerNodeType,
} from '../../types';
import type { UnifiedViewer } from '../../UnifiedViewer';
import pollUntilTrue from '../pollUntilTrue';
import { addRelativeMarginToRect } from '../rectUtils';

import {
  BACKGROUND_IMAGE_POLLING_MAX_ATTEMPTS,
  BACKGROUND_IMAGE_POLLING_TIME_MS,
  CANVAS_BOUNDARY_RELATIVE_MARGIN,
  MAX_HEIGHT,
  MAX_WIDTH,
  PDF_SCALE,
} from './constants';

export const findContentGroup = (group: Konva.Group): Konva.Group | undefined =>
  group.children?.find(
    (n): n is Konva.Group =>
      n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_CONTENT_GROUP &&
      n instanceof Konva.Group
  );

const findImageGroup = (group: Konva.Group): Konva.Image | undefined =>
  group.children?.find(
    (n): n is Konva.Image =>
      n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_IMAGE && n instanceof Konva.Image
  );

const getImageNodeFromGroup = (group: Konva.Group): Konva.Image | undefined => {
  const contentGroup = findContentGroup(group);
  return contentGroup !== undefined ? findImageGroup(contentGroup) : undefined;
};

const updateDocumentImageContentNode = async (
  viewer: UnifiedViewer,
  node: Konva.Node,
  scale: number
): Promise<void> => {
  if (!(node instanceof Konva.Group)) {
    return;
  }
  const container = viewer.getContainerById(node.id());
  if (container === undefined || !(container instanceof DocumentContainer)) {
    return;
  }
  const imageNode = getImageNodeFromGroup(node);
  if (imageNode === undefined) {
    return;
  }
  await container.safelyRenderPageToImage(imageNode, { scale });
};

const getPrintStage = async (viewer: UnifiedViewer): Promise<Konva.Stage> => {
  // Clone the viewer stage to avoid modifying the original viewer stage
  const printStage: Konva.Stage = viewer.stage.clone();

  // We remove listeners for width and height changes to avoid calls to
  // stage._resizeDOM (https://github.com/konvajs/konva/blob/6d34326084bba32704da676e39621e2574942cc1/src/Stage.ts#L383)
  // which is incredibly expensive and unnecessary for our use case, as we only need the underlying canvas of the stage
  printStage.off('widthChange.konva heightChange.konva');

  const mainLayer = printStage
    .getLayers()
    .find((layer) => layer.name() === viewer.layers.main.layer.name());

  // Destroy the (copied) background layer from the viewer and replace it with a
  // local variant which we may adjust the scale of. This is so that the
  // background image scale matches the PDF rendering scale
  printStage
    .getLayers()
    .find((layer) => layer.name() === viewer.layers.background.layer.name())
    ?.destroy();
  const clonedBackgroundLayer = new BackgroundLayerContainer({
    name: `${viewer.layers.background.layer.name()}-copy`,
  });
  printStage.add(clonedBackgroundLayer.layer);
  clonedBackgroundLayer.layer.moveToBottom();

  // Enforce that all nodes are visible and update the scale of the documents to
  // match the scale the PDF will be rendered in
  const nodes = [...(mainLayer?.getChildren() ?? [])];
  for (const node of nodes) {
    if (node instanceof Konva.Group) {
      findContentGroup(node)
        ?.getChildren()
        .filter(
          (n) =>
            // Annotations may be hidden due to the viewport culling done in UnifiedViewer
            n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
              UnifiedViewerNodeType.ANNOTATION ||
            // Container DOM image nodes may be hidden if the container is in active mode
            n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
              UnifiedViewerNodeType.CONTAINER_DOM_IMAGE_NODE
        )
        .forEach((n) => n.visible(true));
    }
    node.visible(true);
    await updateDocumentImageContentNode(viewer, node, PDF_SCALE);
  }

  // Calculate the bounding rectangle of the stage/main layer
  const boundingRect = mainLayer?.getClientRect({
    skipTransform: true,
  }) ?? { x: 0, y: 0, width: 0, height: 0 };
  const { x, y, width, height } = addRelativeMarginToRect(
    boundingRect,
    CANVAS_BOUNDARY_RELATIVE_MARGIN
  );

  // Clamp the canvas boundaries based on the max width and height
  const clampedWidth = Math.min(width, MAX_WIDTH);
  const clampedHeight = Math.min(height, MAX_HEIGHT);
  const scale = Math.min(1.0, MAX_WIDTH / width, MAX_HEIGHT / height);

  // Adjust the "print stage" using the computed bounds
  printStage.scale({ x: scale, y: scale });

  // Keep polling until the background image for the background layer is loaded.
  // The background image may for some reason *sometimes* be loaded slowly
  try {
    await pollUntilTrue(
      () => clonedBackgroundLayer.isFillPatternImageLoaded(),
      BACKGROUND_IMAGE_POLLING_TIME_MS,
      BACKGROUND_IMAGE_POLLING_MAX_ATTEMPTS
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(
      `Max retries for loading background image for canvas has been reached`
    );
  }
  clonedBackgroundLayer.possiblyUpdateBackgroundByScale(scale);

  printStage.size({ width: clampedWidth, height: clampedHeight });
  printStage.position({ x: -x * scale, y: -y * scale });

  return printStage;
};

export default getPrintStage;
