import { BREAKPOINT_PAD_PORTRAIT } from '@infield/providers/theme';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import * as S from './elements';

type Props = {
  items: AssetPropertiesListItem[];
};

export type AssetPropertiesListItem = {
  fieldName: string;
  fieldValue: string;
  isHeader?: boolean;
  onClickName?: () => unknown;
  onClickValue?: () => unknown;
};

export const AssetPropertiesList: FC<Props> = ({ items }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    setContainerWidth(containerRef.current?.offsetWidth);
  }, [containerRef.current?.offsetWidth]);

  const isFullWidth =
    containerWidth !== undefined && containerWidth > BREAKPOINT_PAD_PORTRAIT;

  const row = ({
    fieldName,
    fieldValue,
    isHeader,
    onClickName,
    onClickValue,
  }: AssetPropertiesListItem) => {
    return (
      <S.Row key={fieldName} $containerWidth={containerWidth}>
        <S.LeftColumn $containerWidth={containerWidth}>
          <S.ListItem
            strong={isHeader || !isFullWidth}
            $containerWidth={containerWidth}
            $isHeader={isHeader || !isFullWidth}
            $isClickable={onClickName !== undefined}
            onClick={onClickName}
          >
            {fieldName}
          </S.ListItem>
        </S.LeftColumn>
        <S.RightColumn>
          <S.ListItem
            strong={isHeader}
            $containerWidth={containerWidth}
            $isHeader={isHeader}
            $isClickable={onClickValue !== undefined}
            onClick={onClickValue}
          >
            {fieldValue}
          </S.ListItem>
        </S.RightColumn>
      </S.Row>
    );
  };
  return (
    <S.List ref={containerRef}>
      {containerWidth
        ? items.map((listItem) => {
            if (listItem.isHeader && !isFullWidth) {
              return;
            }
            return row(listItem);
          })
        : null}
    </S.List>
  );
};
