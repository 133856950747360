import type { APMAsset } from '@cognite/apm-client';
import type { Asset } from '@cognite/sdk';
import type { FC } from 'react';

import type { AssetInfoBaseProps } from '../../../types';

import * as S from './elements';

type Props = Pick<AssetInfoBaseProps, 'onAssetNameClick'> & {
  asset: Asset | APMAsset;
};
export const AssetInfoMobileContent: FC<Props> = ({
  asset,
  onAssetNameClick,
}) => {
  const area = asset.metadata?.AREA;
  return (
    <>
      <S.AssetTitle>
        <S.MobileAssetName
          onClick={() =>
            onAssetNameClick && onAssetNameClick(asset.externalId!)
          }
        >
          {'name' in asset ? asset.name : asset.title}
        </S.MobileAssetName>
        {area && <div>&nbsp;{`(${area})`}</div>}
      </S.AssetTitle>
      <div>{asset.description}</div>
    </>
  );
};
