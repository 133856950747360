import { APMAppDataService } from '../apm-app-data-service';
import type { Options } from '../core';
import type { ServiceContract } from '../types';

import type { UserPreferences } from './types';

export class UserPreferencesService extends APMAppDataService<UserPreferences> {
  constructor(contract: ServiceContract, options?: Options) {
    super(
      contract,
      options?.userPreferencesViewName ?? 'CDF_UserPreferences',
      'v1'
    );
  }
}
