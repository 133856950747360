import type { CustomReadingLabelColor } from '@cognite/apm-client';

export const getBackgroundColor = (type?: CustomReadingLabelColor) => {
  switch (type) {
    case 'neutral':
      return 'var(--cogs-themed-surface--status-neutral--muted--default)';
    case 'success':
      return 'var(--cogs-themed-surface--status-success--muted--default)';
    case 'warning':
      return 'var(--cogs-themed-surface--status-warning--muted--default)';
    case 'danger':
      return 'var(--cogs-themed-surface--status-critical--muted--default)';
    default:
      return 'rgba(102, 102, 102, 0.14)';
  }
};

export const getColor = (type?: CustomReadingLabelColor) => {
  switch (type) {
    case 'neutral':
      return 'var(--cogs-themed-text-icon--status-neutral)';
    case 'success':
      return 'var(--cogs-themed-text-icon--status-success)';
    case 'warning':
      return 'var(--cogs-themed-text-icon--status-warning)';
    case 'danger':
      return 'var(--cogs-themed-text-icon--status-critical)';
    default:
      return 'var(--cogs-text-icon--medium)';
  }
};
