import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ObservationDesktopContainer = styled.div`
  padding: 20px 64px;
  height: calc(100vh - 175px);
  overflow: auto;
`;

export const ObservationStepWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  width: 100%;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  align-items: end;
`;
