import groupBy from 'lodash-es/groupBy';

import { calculateMaxRange } from './calculateMaxRange';
import { SeriesDataCollection } from './types';

export function mergeSeriesDataCollectionsByUnit(
  seriesDataCollection: SeriesDataCollection[]
): SeriesDataCollection[] {
  const mergedSeriesData: SeriesDataCollection[] = [];
  const seriesGroupedByUnit = groupBy(seriesDataCollection, 'unit');
  Object.keys(seriesGroupedByUnit).forEach((unit) => {
    if (unit === 'undefined' || unit === '') {
      mergedSeriesData.push(...seriesGroupedByUnit[unit]);
    } else {
      mergedSeriesData.push({
        isVisible: true,
        unit,
        range: calculateMaxRange(seriesGroupedByUnit[unit]),
        series: seriesGroupedByUnit[unit].flatMap(
          ({ series }: SeriesDataCollection) => series
        ),
        thresholds: seriesGroupedByUnit[unit].reduce(
          (result: any[], groupedSeries: SeriesDataCollection) =>
            result.concat(...groupedSeries.thresholds),
          []
        ),
      });
    }
  });

  return mergedSeriesData;
}
