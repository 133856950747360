import { captureException } from '@sentry/react';
import type { ErrorBoundaryPropsWithComponent } from 'react-error-boundary';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { FallbackContent } from './fallback-content';

export function ErrorBoundaryProvider(
  props: Partial<ErrorBoundaryPropsWithComponent> & { children: any }
) {
  return (
    <ReactErrorBoundary
      {...props}
      FallbackComponent={props.FallbackComponent || FallbackContent}
      onError={(error, errorInfo) => {
        captureException(error, {
          level: 'warning',
          tags: {
            queryKey: 'ErrorBoundaryProvider',
          },
        });
        if (props.onError) {
          props.onError(error, errorInfo);
        }
      }}
    />
  );
}
