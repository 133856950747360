import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ComponentSidebar = styled(Flex).attrs({
  direction: 'column',
})`
  height: 100%;
  min-width: 320px;
  max-width: 320px;
  position: relative;
  background: ${({ theme }) => theme['surface--muted']};
  overflow-x: auto;
`;
