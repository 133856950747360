import type { ViewConfigDTO } from '@cognite/apm-config';

export const getIsThisViewMappingChanged = (
  currentEntity: string,
  currentViewMappings: ViewConfigDTO[],
  initialViewMappings?: ViewConfigDTO[]
) => {
  const initialView = initialViewMappings?.find(
    (view) => view.representsEntity === currentEntity
  );
  const currentView = currentViewMappings.find(
    (view) => view.representsEntity === currentEntity
  );
  return JSON.stringify(initialView) !== JSON.stringify(currentView);
};
