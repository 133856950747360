import { Segment, Point, Rectangle, intersections } from '@mathigon/euclid';
import type { IRect } from 'konva/cmj/types';

import type { Position, Vector } from '../annotations/types';

import { getMidpoint, getPerpendicularVector, scaleVector } from './mathUtils';

export const addMarginToRect = (rect: IRect, margin: number): IRect => {
  return {
    x: rect.x - margin,
    y: rect.y - margin,
    width: rect.width + margin * 2,
    height: rect.height + margin * 2,
  };
};

export const addRelativeMarginToRect = (
  rect: IRect,
  relativeMargin: number
): IRect => {
  const margin = Math.min(rect.width, rect.height) * relativeMargin;
  return addMarginToRect(rect, margin);
};

export const getEnclosingRect = (rect1: IRect, rect2: IRect): IRect => {
  const x = Math.min(rect1.x, rect2.x);
  const y = Math.min(rect1.y, rect2.y);
  const width = Math.max(rect1.x + rect1.width, rect2.x + rect2.width) - x;
  const height = Math.max(rect1.y + rect1.height, rect2.y + rect2.height) - y;
  return { x, y, width, height };
};

export const getEnclosingRectFromRects = (
  baseRect: IRect,
  rects: IRect[]
): IRect => rects.reduce(getEnclosingRect, baseRect);

export const areRectsOverlapping = (rect1: IRect, rect2: IRect): boolean => {
  return (
    rect1.x < rect2.x + rect2.width &&
    rect1.x + rect1.width > rect2.x &&
    rect1.y < rect2.y + rect2.height &&
    rect1.y + rect1.height > rect2.y
  );
};

export const isStrictlyInRange = (
  x: number,
  min: number,
  max: number
): boolean => x > min && x < max;

export const isPointInRect = (point: Position, rect: IRect): boolean => {
  return (
    isStrictlyInRange(point.x, rect.x, rect.x + rect.width) &&
    isStrictlyInRange(point.y, rect.y, rect.y + rect.height)
  );
};

export const isRectInsideRect = (rect1: IRect, rect2: IRect): boolean => {
  return (
    isStrictlyInRange(rect1.x, rect2.x, rect2.x + rect2.width) &&
    isStrictlyInRange(rect1.y, rect2.y, rect2.y + rect2.height) &&
    isStrictlyInRange(rect1.x + rect1.width, rect2.x, rect2.x + rect2.width) &&
    isStrictlyInRange(rect1.y + rect1.height, rect2.y, rect2.y + rect2.height)
  );
};

export const filterOutRectsThatAreInsideOtherRects = (
  rects: IRect[]
): IRect[] => {
  return rects.filter(
    (rect1) =>
      !rects.some((rect2) => rect1 !== rect2 && isRectInsideRect(rect1, rect2))
  );
};

export const isRectSmallerThanRect = (rect1: IRect, rect2: IRect): boolean => {
  return (
    rect1.x + rect1.width < rect2.x + rect2.width &&
    rect1.y + rect1.height < rect2.y + rect2.height
  );
};

export const isRectContainedByRect = (
  innerRect: IRect,
  outerRect: IRect
): boolean => {
  return (
    innerRect.x >= outerRect.x &&
    innerRect.y >= outerRect.y &&
    innerRect.x + innerRect.width <= outerRect.x + outerRect.width &&
    innerRect.y + innerRect.height <= outerRect.y + outerRect.height
  );
};

export const isLineIntersectingWithRectangle = (
  line: {
    start: Position;
    end: Position;
  },
  rect: IRect
): boolean => {
  const { start, end } = line;
  const euclidSegment = new Segment(
    new Point(start.x, start.y),
    new Point(end.x, end.y)
  );
  const euclidRect = new Rectangle(
    new Point(rect.x, rect.y),
    rect.width,
    rect.height
  );

  // Rectangle contains line
  if (
    euclidRect.contains(euclidSegment.p1) ||
    euclidRect.contains(euclidSegment.p2)
  ) {
    return true;
  }
  const computedIntersections = intersections(euclidRect, euclidSegment);
  return computedIntersections.length > 0;
};

const getRectPerimeter = (rect: IRect) => 2 * (rect.width + rect.height);

const getRectSvgCommand = (rect: IRect) =>
  `M 0 0 H ${rect.width} V ${rect.height} H 0 L 0 0`;

const getPointAtLength = (rect: IRect, length: number): Vector => {
  // By creating an SVG element, we can use the built-in getPointAtLength function.
  // Konva has a similar function that I would prefer to use here, but it cannot handle floats.
  // Note: this does not add the element to the DOM.
  const svgNamespace = 'http://www.w3.org/2000/svg';
  const svgElement = document.createElementNS(svgNamespace, 'path');
  svgElement.setAttribute('d', getRectSvgCommand(rect));

  return svgElement.getPointAtLength(length);
};

const getBumpSvgCommand = (
  startPoint: Vector,
  endPoint: Vector,
  bumpAmplitude: number
): string => {
  const vector = {
    x: endPoint.x - startPoint.x,
    y: endPoint.y - startPoint.y,
  };
  const midpoint = getMidpoint(startPoint, endPoint);
  const perpendicularVector = getPerpendicularVector(
    scaleVector(vector, bumpAmplitude)
  );
  const controlPoint = {
    x: midpoint.x + -perpendicularVector.x,
    y: midpoint.y + -perpendicularVector.y,
  };

  return (
    'Q' + [controlPoint.x, controlPoint.y, endPoint.x, endPoint.y].join(',')
  );
};

export const getCloudSvgCommand = (outerRect: IRect): string => {
  // BUMP_AMPLITUDE_SCALE_FACTOR determines the amplitude of each cloud bump.
  // Increasing this value makes the cloud "flatter".
  // Decreasing this value makes the cloud "pointier."
  const BUMP_AMPLITUDE_SCALE_FACTOR = 10;
  const bumpAmplitude =
    Math.min(outerRect.width, outerRect.height) / BUMP_AMPLITUDE_SCALE_FACTOR;

  // The outerRect is the rectangle specified by the annotation, used for selection, resizing, etc.
  // The innerRect is the reference "base rectangle" that we will use to create the cloud shape.
  const innerRect: IRect = {
    ...outerRect,
    width: outerRect.width - bumpAmplitude,
    height: outerRect.height - bumpAmplitude,
  };
  const innerPerimeter = getRectPerimeter(innerRect);

  const bumpWidth = bumpAmplitude * 2;
  const stepCount = Math.ceil(innerPerimeter / bumpWidth);

  const origin = { x: 0, y: 0 };
  const startCommand = 'M0,0';
  const pathSegments: string[] = [];

  for (let i = 0; i < stepCount; ++i) {
    const isLastPoint = i === stepCount - 1;

    const startLength = (i * innerPerimeter) / stepCount;
    const startPoint = getPointAtLength(innerRect, startLength);

    const endLength = ((i + 1) * innerPerimeter) / stepCount;
    const endPoint = isLastPoint
      ? origin // We want the last point to return exactly to the origin to create an enclosed shape.
      : getPointAtLength(innerRect, endLength);

    pathSegments.push(getBumpSvgCommand(startPoint, endPoint, bumpAmplitude));
  }

  return [startCommand, ...pathSegments].join(' ');
};

export const getIntersectionOverUnion = (
  rect1: IRect,
  rect2: IRect
): number => {
  const { x: x1, y: y1, width: width1, height: height1 } = rect1;
  const { x: x2, y: y2, width: width2, height: height2 } = rect2;

  const xLeft = Math.max(x1, x2);
  const yTop = Math.max(y1, y2);
  const xRight = Math.min(x1 + width1, x2 + width2);
  const yBottom = Math.min(y1 + height1, y2 + height2);

  if (xRight < xLeft || yBottom < yTop) {
    return 0;
  }

  const intersectionArea = (xRight - xLeft) * (yBottom - yTop);
  const unionArea = width1 * height1 + width2 * height2 - intersectionArea;

  return intersectionArea / unionArea;
};
