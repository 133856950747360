import type { Project } from '@cognite/e2e-auth';
import type { HttpResponse } from '@cognite/sdk';

export const IS_IDM_FLAG = 'DATA_MODELING_ONLY';

export const getIsIdmFromProjectEndpointResponse = (
  isIdmEndpointResponse: HttpResponse<any>,
  currentProject: string
) => {
  const relevantProject = isIdmEndpointResponse.data?.items?.find(
    (projectInfo: Project) => projectInfo.name === currentProject
  );
  const isIdmProject = relevantProject?.dataModelingStatus === IS_IDM_FLAG;

  return isIdmProject;
};
