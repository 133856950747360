import type { APMClient } from '@cognite/apm-client';
import type { FdmTimeSeries } from '@cognite/fdm-client';
import type {
  CogniteClient,
  ExternalDatapoint,
  Timeseries,
  TimeseriesFilter,
} from '@cognite/sdk';

import { DatapointService } from './datapoint-service';
import {
  timeseriesFilterToFdmFilter,
  TimeseriesIDMMigrator,
} from './timeseries-idm-migrator';
import { filterDatapointStrings, filterTimeseriesStrings } from './utils';

export class TimeseriesService {
  client;
  apmClient;
  datapointService;
  spaces: string[];
  constructor(client: CogniteClient, apmClient: APMClient) {
    this.spaces = [];

    if (apmClient.appDataInstanceSpace) {
      this.spaces.push(apmClient.appDataInstanceSpace);
    }

    if (apmClient.sourceDataInstanceSpace) {
      this.spaces.push(apmClient.sourceDataInstanceSpace);
    }

    this.client = client;
    this.apmClient = apmClient;
    this.datapointService = new DatapointService(client);

    if (this.apmClient.timeseries && apmClient.options?.timeseriesView?.space) {
      const migrator = new TimeseriesIDMMigrator();
      this.apmClient.timeseries.migrator = migrator;
    }
  }

  async getTimeseriesByExternalId(externalId: string) {
    if (this.apmClient.timeseries) {
      const [timeseriesData] = await this.apmClient.timeseries.byId([
        { externalId },
      ]);
      return timeseriesData;
    }

    const [timeseriesData] = await this.client.timeseries.retrieve(
      [{ externalId }],
      { ignoreUnknownIds: true }
    );
    return timeseriesData;
  }

  /**
   * upsertTimeseries
   * Upsert functionality is not available in asset explorer.
   * Therefore we don't need to support IDM for now.
   */
  async upsertTimeseries(timeseries: FdmTimeSeries, rootAssetId?: number) {
    let timeseriesData;
    const assetId = timeseries.assetId || rootAssetId;

    if (timeseries.id) {
      // since metadata is completely overwritten we need to get the original timeseries metadata
      const originalTimeseries = await this.getTimeseriesByExternalId(
        timeseries.externalId!
      );

      timeseriesData = await this.client.timeseries.update([
        {
          externalId: timeseries.externalId || '',
          update: {
            name: { set: timeseries.name || '' },
            description: { set: timeseries.description || '' },
            unit: { set: timeseries.unit || '' },
            metadata: {
              set: {
                ...originalTimeseries.metadata,
                ...timeseries.metadata,
                source: 'APP',
              },
            },
            assetId: assetId ? { set: assetId } : undefined,
          },
        },
      ]);
    } else {
      timeseriesData = await this.client.timeseries.create([
        {
          ...timeseries,
          assetId: timeseries.assetId || rootAssetId,
          metadata: {
            source: 'APP',
          },
        },
      ]);
    }

    return timeseriesData;
  }

  /**
   * insertDataPoint
   * Add datapoint is not available in asset explorer.
   * Therefore we don't need to support IDM for now.
   */
  async insertDataPoint(
    timeseriesExternalId: string,
    dataPoint: ExternalDatapoint
  ) {
    return this.client.datapoints.insert([
      { externalId: timeseriesExternalId, datapoints: [dataPoint] },
    ]);
  }

  /**
   * deleteDataPoint
   * Delete datapoint is not available in asset explorer.
   * Therefore we don't need to support IDM for now.
   */
  async deleteDataPoint(timeseriesExternalId: string, timestamp: number) {
    return this.client.datapoints.delete([
      {
        externalId: timeseriesExternalId,
        inclusiveBegin: timestamp,
        exclusiveEnd: timestamp + 1,
      },
    ]);
  }

  async getTimeseriesByAsset(
    assetExternalId: string,
    filter: TimeseriesFilter = {},
    limit?: number
  ) {
    const finalFilters: TimeseriesFilter = {
      assetExternalIds: [assetExternalId],
      isString: false,
      ...filter,
    };

    if (this.apmClient.timeseries) {
      const { items } = await this.apmClient.timeseries.list({
        filters: timeseriesFilterToFdmFilter(
          this.apmClient.assets.defaultInstanceSpace,
          finalFilters
        ),
        spaces: this.spaces,
        pageSize: limit,
      });

      return items;
    }

    const { items } = await this.client.timeseries.list({
      filter: finalFilters,
      limit,
    });
    return items;
  }

  async getTimeseriesByRelationships(assetExternalId: string, limit?: number) {
    // In IDM we will not use relationships to get timeseries
    if (this.apmClient.timeseries) {
      return [];
    }

    let timeseriesByRelationships: Timeseries[] = [];
    const relationships = await this.client.relationships
      .list({
        filter: {
          sourceTypes: ['timeSeries'],
          targetExternalIds: [assetExternalId],
          targetTypes: ['asset'],
        },
        limit,
      })
      .autoPagingToArray({ limit });

    if (relationships.length > 0) {
      const uniqueTimeseriesIds = [
        ...new Set(
          relationships.map((relationship) => relationship.sourceExternalId)
        ),
      ];
      timeseriesByRelationships = await this.client.timeseries.retrieve(
        uniqueTimeseriesIds.map((externalId) => ({ externalId }), {
          ignoreUnknownIds: true,
        })
      );
    }

    return filterTimeseriesStrings(timeseriesByRelationships);
  }

  // the max limit for client.datapoints is 100 .retrieveLatest
  async getTimeseriesLatestDatapoints(externalIds: string[]) {
    if (this.apmClient.timeseries) {
      const datapoint = await this.datapointService.retrieveLatest(
        externalIds,
        this.apmClient.timeseries.defaultInstanceSpace
      );
      return filterDatapointStrings(datapoint);
    }

    const datapoints = await this.client.datapoints.retrieveLatest(
      externalIds.map(
        (externalId) => ({
          externalId,
        }),
        {
          ignoreUnknownIds: true,
        }
      )
    );

    return filterDatapointStrings(datapoints);
  }

  async searchTimeseries(
    query: string,
    limit?: number,
    filters?: TimeseriesFilter
  ) {
    if (this.apmClient.timeseries) {
      const timeseries = await this.apmClient.timeseries.search({
        query,
        filters: timeseriesFilterToFdmFilter(
          this.apmClient.assets.defaultInstanceSpace,
          filters,
          true
        ),
        spaces: this.spaces,
        pageSize: limit,
      });

      return timeseries;
    }

    const timeseries = await this.client.timeseries.search({
      search: {
        query,
      },
      filter: filters,
      limit,
    });
    return timeseries;
  }
}
