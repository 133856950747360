import type { Measurement, TemplateItem } from '@cognite/apm-client';
import { Flex, Heading, InfoIcon, Tooltip } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { TaskFormBlockTitleWrapper } from '@infield/features/task/task-form/elements';
import type { FC } from 'react';

import { MeasurementMessageConfiguration } from '../measurement-message-configuration';

interface Props {
  task: TemplateItem;
  messageList: Measurement[];
}

export const MeasurementMessageConfigurationList: FC<Props> = ({
  task,
  messageList,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);

  return (
    <Flex direction="column" gap={12}>
      <TaskFormBlockTitleWrapper>
        <Heading level={6} data-testid="task-form-message">
          {t('TEMPLATE_TASK_FORM_COMPONENT_MENU_ACTION_MESSAGE', 'Message')}
        </Heading>
        <Tooltip
          content={t(
            'TEMPLATE_TASK_FORM_MESSAGE_INFO',
            'Request to answer a question in free-text'
          )}
        >
          <InfoIcon />
        </Tooltip>
      </TaskFormBlockTitleWrapper>
      {messageList?.map((message) => (
        <MeasurementMessageConfiguration
          key={message.externalId}
          measurement={message}
          taskExternalId={task.externalId}
        />
      ))}
    </Flex>
  );
};
