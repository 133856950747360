import {
  DocumentMimeType,
  ImageMimeType,
  TextMimeType,
  VideoMimeType,
} from '../../containers/types';
import ValueOf from '../ValueOf';

export const NativelySupportedMimeTypesObject = {
  ...ImageMimeType,
  ...DocumentMimeType,
  ...TextMimeType,
  ...VideoMimeType,
} as const;

export type NativelySupportedMimeType = ValueOf<
  typeof NativelySupportedMimeTypesObject
>;

const isNativelySupportedMimeType = (
  mimeType: string | undefined
): mimeType is NativelySupportedMimeType => {
  return (
    Object.values(NativelySupportedMimeTypesObject).find(
      (supportedMimeType) => supportedMimeType === mimeType
    ) !== undefined
  );
};

export default isNativelySupportedMimeType;
