import type { FC, PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const BackButtonUrlContext = createContext<{
  history: string[];
  navigateBack: () => void;
} | null>(null);

type HistoryEntry = {
  key: string;
  pathName: string;
};

export const BackButtonUrlProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [history, setHistory] = useState<HistoryEntry[]>([
    { key: '', pathName: '/' },
  ]);

  useEffect(() => {
    setHistory((prev) => {
      const samePageAsBefore =
        prev[prev.length - 1]?.pathName === location.pathname;
      if (samePageAsBefore) {
        return prev;
      }

      // Navigating to a page generates a unique key. The back button in the browser
      // will navigate to the previous page with the same key.
      const backButtonPressed = prev.find(
        (entry) => entry.key === location.key
      );
      if (backButtonPressed) {
        const index = prev.findIndex((entry) => entry.key === location.key);
        return prev.slice(0, index + 1);
      }

      // Otherwise, append new page to history
      return [...prev, { key: location.key, pathName: location.pathname }];
    });
  }, [history, location]);

  const navigateBack = useCallback(() => {
    if (history.length > 1) {
      navigate(history[history.length - 2].pathName);
      setHistory((prev) => prev.slice(0, -2));
    } else {
      navigate('/');
    }
  }, [history, navigate]);

  const value = useMemo(() => {
    return {
      history: history.map((entry) => entry.pathName),
      navigateBack,
    };
  }, [history, navigateBack]);

  return (
    <BackButtonUrlContext.Provider value={value}>
      {children}
    </BackButtonUrlContext.Provider>
  );
};

export const useBackButtonUrlContext = () => {
  const context = useContext(BackButtonUrlContext);
  if (!context) {
    throw new Error(
      'BackButtonUrlContext must be used within a BackButtonUrlProvider'
    );
  }
  return context;
};
