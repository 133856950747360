import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const AssetList = styled.div.attrs({
  className: 'operation-card-asset-list',
})`
  border-left: 1px solid ${({ theme }) => theme['border--muted']};
  border-right: 1px solid ${({ theme }) => theme['border--muted']};
  .operation-card-asset-list-item {
    &:nth-child(even) {
      background-color: ${({ theme }) => theme['surface--medium']};
    }
  }
  .operation-card-asset-list-item {
    &:last-child {
      border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
    }
  }
`;

export const AssetListItem = styled(Flex).attrs({
  className: 'operation-card-asset-list-item',
})`
  padding: 8px 16px;
  align-items: flex-start;
  align-self: stretch;
`;
