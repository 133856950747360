import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ResultContainer = styled(Flex).attrs({
  direction: 'column',
})`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme['surface--interactive--hover']};
  }
`;
