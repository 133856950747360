import styled from 'styled-components';

export const TrendList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
  }
`;
