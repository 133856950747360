import type {
  RenderTask,
  GetViewportParameters,
  PDFPageProxy,
} from 'pdfjs-dist/types/src/display/api';

import { Size } from '../../annotations/types';
import { AbortError } from '../../utils/errors';
import isEmptyCanvas from '../../utils/isEmptyCanvas';

const BLACK_PIXEL_VALUE = 0x0;

export const getCanvasFromPdfPage = async (
  page: PDFPageProxy,
  viewport: GetViewportParameters,
  size: Size,
  signal?: AbortSignal
): Promise<HTMLCanvasElement> => {
  const { canvas, canvasContext } = createCanvas(size);
  await cancelRenderOnAbort(
    page.render({ canvasContext, viewport: page.getViewport(viewport) }),
    signal
  );

  if (signal?.aborted) {
    throw new AbortError();
  }

  if (await isEmptyCanvas(canvas, BLACK_PIXEL_VALUE)) {
    throw new Error('Rendered empty canvas');
  }
  return canvas;
};

const createCanvas = ({
  width,
  height,
}: Size): {
  canvas: HTMLCanvasElement;
  canvasContext: CanvasRenderingContext2D;
} => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const canvasContext = canvas.getContext('2d', { alpha: false });
  if (canvasContext === null) {
    throw new Error('Could not get canvas context');
  }
  return { canvas, canvasContext };
};

/** Cancels a PDFJS rendering task if the provided AbortSignal is triggered */
const cancelRenderOnAbort = async (
  task: RenderTask,
  signal?: AbortSignal
): Promise<void> => {
  signal?.addEventListener('abort', () => task.cancel(), { once: true });
  try {
    await task.promise;
    if (signal?.aborted) {
      throw new AbortError();
    }
  } catch (error: unknown) {
    if (
      error instanceof Error &&
      error.name === 'RenderingCancelledException'
    ) {
      throw new AbortError();
    }
    throw error;
  }
};

export { getCanvasFromPdfPage as default };
