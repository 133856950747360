import { BREAKPOINT_PAD_LANDSCAPE } from '@infield/providers/theme';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

export const Wrapper = styled.div<{
  isDesktop: boolean;
  isFullView: boolean;
}>`
  ${({ isFullView }) =>
    isFullView
      ? `
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: ${z.OVERLAY}; 
  `
      : ''};
  background-color: ${({ theme }) => theme['surface--medium']};
  display: ${({ isDesktop }) => (isDesktop ? 'grid' : 'block')};
  grid-template-columns: ${({ isFullView }) =>
    isFullView ? '300px minmax(0, 1fr)' : '300px minmax(300px, auto)'};
  grid-template-rows: 59px minmax(0, 1fr);
  grid-template-areas:
    'topbar topbar'
    'sidebar chart';

  height: 100%;
  overflow: auto;

  @media screen and (max-width: ${BREAKPOINT_PAD_LANDSCAPE}px) {
    display: block;
  }
`;
