import { Body, Flex, Input } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

export const TimeseriesRequiredText = styled(Body).attrs({
  size: 'x-small',
})`
  color: ${({ theme }) => theme['text-icon--medium']};
`;

export const TimeseriesInputContainer = styled(Flex).attrs({
  gap: 4,
  direction: 'column',
})`
  position: relative;
`;

export const SearchResultWrapper = styled.div`
  position: absolute;
  z-index: ${z.OVERLAY};
  background: white;
  margin-top: 40px;
  width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
`;
