import type { Cluster, ClusterAppInfo, Customer } from './types';

// azure app registrations must be created for each new cluster via terraform
// for example see: https://github.com/cognitedata/terraform/pull/7163
// Afterwards, you can get the values for aapAppId and stagingAadAppId from azure https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade
// You can filter on "Cognite APM Suite" since all industry-applications are under this application

export const clusters: Record<Cluster, ClusterAppInfo> = {
  ew1: {
    name: 'ew1',
    aadAppId: '642059cc-fdef-49c5-9b11-599c6b23549c',
    stagingAadAppId: '8fbd8a14-9ddd-4b60-930d-5df7b32f0b96',
    apiBaseURL: 'https://api.cognitedata.com',
  },
  'westeurope-1': {
    name: 'westeurope-1',
    aadAppId: 'f54c2cb4-eab0-4aa0-a770-9a74bfa3ea99',
    stagingAadAppId: '6528599e-c47b-4966-b4eb-2f528d8262ab',
    apiBaseURL: 'https://westeurope-1.cognitedata.com',
  },
  'az-eastus-1': {
    name: 'az-eastus-1',
    aadAppId: '3f3f50c3-47f2-41a3-8cea-527747a0dd92',
    stagingAadAppId: '6e2d0a74-50ec-45a6-b31a-e30362080d02',
    apiBaseURL: 'https://az-eastus-1.cognitedata.com',
  },
  'az-arn-001': {
    name: 'az-arn-001',
    aadAppId: '8f3f38bd-34ec-4c29-8c46-b2fc1e5a14d6',
    stagingAadAppId: '4ddb768e-8aa7-4937-9d63-705d257094d7',
    apiBaseURL: 'https://az-arn-001.cognitedata.com',
  },
  'azure-dev': {
    name: 'azure-dev',
    aadAppId: '154ed310-e973-4899-aa7f-9bbc0e45e794',
    stagingAadAppId: '8628007c-8e52-47c4-aff6-4d9939a024a3',
    apiBaseURL: 'https://azure-dev.cognitedata.com',
  },
  greenfield: {
    name: 'greenfield',
    aadAppId: 'b7f068bb-918b-4980-892d-87ad2de17a21',
    stagingAadAppId: 'c5058185-8124-4d82-8861-5884a66347b8',
    apiBaseURL: 'https://greenfield.cognitedata.com',
  },
  omv: {
    name: 'omv',
    aadAppId: 'b4c20e36-5b0a-4047-8765-28eb3281159d',
    stagingAadAppId: '89b8ff89-3191-40c1-beb3-4c0e305bf5aa',
    apiBaseURL: 'https://omv.cognitedata.com',
  },
  bluefield: {
    name: 'bluefield',
    aadAppId: '3497c47d-067f-4b60-99ed-f5e6693236e1',
    stagingAadAppId: '24be28d3-60f4-42c6-a73f-8ee268e6f622',
    apiBaseURL: 'https://bluefield.cognitedata.com',
  },
  'asia-northeast1-1': {
    name: 'asia-northeast1-1',
    aadAppId: '659ad9fc-b066-45b2-b16c-e989ea876e81',
    stagingAadAppId: '13714210-b351-4be1-89d0-cca6abcb26fb',
    apiBaseURL: 'https://asia-northeast1-1.cognitedata.com',
  },
  'az-power-no-northeurope': {
    name: 'az-power-no-northeurope',
    aadAppId: '',
    stagingAadAppId: '',
    apiBaseURL: 'https://az-power-no-northeurope.cognitedata.com',
  },
  'az-tyo-gp-001': {
    name: 'az-tyo-gp-001',
    aadAppId: 'b6fadf00-371c-4fe4-90ac-ca5dd3fd5389',
    stagingAadAppId: '470111c6-3df8-4945-a13d-9799f2b993e2',
    apiBaseURL: 'https://az-tyo-gp-001.cognitedata.com',
  },
  orangefield: {
    name: 'orangefield',
    aadAppId: 'a96940c1-2a46-4615-ade9-7e4469ba713c',
    stagingAadAppId: 'a96940c1-2a46-4615-ade9-7e4469ba713c',
    apiBaseURL: 'https://orangefield.cognitedata.com',
  },
  'az-sin-sp-001': {
    name: 'az-sin-sp-001',
    aadAppId: '47459dcd-9371-4b92-95bd-6cfe964b1d51',
    stagingAadAppId: '79e6cdc5-1bad-414c-8584-c1bc18955626',
    apiBaseURL: 'https://az-sin-sp-001.cognitedata.com',
  },
  'aw-was-gp-001': {
    name: 'aw-was-gp-001',
    aadAppId: '51d02c43-add3-4fd8-9d01-c3bcdfbc00d2',
    stagingAadAppId: '25818ee4-eb39-45f2-bc45-9f449a9ab5ff',
    apiBaseURL: 'https://aw-was-gp-001.cognitedata.com',
  },
  'az-ams-sp-002': {
    name: 'az-ams-sp-002',
    aadAppId: '762c63da-0faf-47d0-81c2-5c65a8de3907',
    stagingAadAppId: 'ef6e931b-493f-453e-ab2c-0cb1044db553',
    apiBaseURL: 'https://az-ams-sp-002.cognitedata.com',
  },
  'aws-dub-dev': {
    name: 'aws-dub-dev',
    aadAppId: '',
    stagingAadAppId: '',
    apiBaseURL: 'https://aws-dub-dev.cognitedata.com',
  },
};

export const CUSTOMERS: Record<string, Customer> = {
  '3d-test': {
    id: '3d-test',
    projects: [
      {
        id: '3d-test',
        authType: 'azureAD',
        cluster: clusters.greenfield,
        aadDirectory: 'threedcognite.onmicrosoft.com',
      },
    ],
  },
  '3d-test-az': {
    id: '3d-test-az',
    projects: [
      {
        id: '3d-test-az',
        authType: 'azureAD',
        cluster: clusters.bluefield,
        aadDirectory: 'threedcognite.onmicrosoft.com',
      },
    ],
  },
  '3d-test-aws': {
    id: '3d-test-aws',
    projects: [
      {
        id: '3d-test-aws',
        authType: 'azureAD',
        cluster: clusters.orangefield,
        aadDirectory: 'threedcognite.onmicrosoft.com',
      },
    ],
  },
  'audit-dev': {
    id: 'audit-dev',
    projects: [
      {
        id: 'audit-dev',
        authType: 'azureAD',
        cluster: clusters['azure-dev'],
        aadDirectory: 'dataenablers.onmicrosoft.com',
      },
    ],
  },
  '3d-dev-az': {
    id: '3d-dev-az',
    projects: [
      {
        id: '3d-dev-az',
        authType: 'azureAD',
        cluster: clusters['azure-dev'],
        aadDirectory: 'threedcognite.onmicrosoft.com',
      },
    ],
  },
  '3ddemo': {
    id: '3ddemo',
    projects: [
      {
        id: '3ddemo',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: 'threedcognite.onmicrosoft.com',
      },
    ],
  },
  officerobotics: {
    id: 'officerobotics',
    projects: [
      {
        id: 'officerobotics',
        authType: 'cogidp',
        cluster: clusters.greenfield,
        org: 'officerobotics',
      },
    ],
  },
  tupras: {
    id: 'tupras',
    projects: [
      {
        id: 'tupras',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: 'tuprasbycognite.onmicrosoft.com',
      },
    ],
  },
  andershaf: {
    id: 'andershaf',
    projects: [
      {
        id: 'andershaf',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: 'hafreager.onmicrosoft.com',
      },
    ],
  },
  albert: {
    id: 'albert',
    projects: [
      {
        id: 'albert',
        authType: 'azureAD',
        cluster: clusters.bluefield,
        aadDirectory: 'albertbycognite.onmicrosoft.com',
      },
    ],
  },
  'equinor-dev': {
    // infield
    id: 'equinor-dev',
    projects: [
      {
        id: 'equinor-dev',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'equinor',
      },
    ],
  },
  platypus: {
    id: 'platypus',
    projects: [
      {
        id: 'platypus',
        authType: 'azureAD',
        cluster: clusters.greenfield,
        aadDirectory: 'cogniteappdev.onmicrosoft.com',
      },
    ],
  },
  robotics: {
    id: 'robotics',
    projects: [
      {
        id: 'robotics',
        authType: 'cogidp',
        cluster: clusters.ew1,
        org: 'officerobotics',
      },
    ],
  },
  taurob: {
    id: 'taurob',
    projects: [
      {
        id: 'taurob',
        authType: 'cogidp',
        cluster: clusters.ew1,
        org: 'taurob',
      },
    ],
  },
  'americas-robotics': {
    id: 'americas-robotics',
    projects: [
      {
        id: 'americas-robotics',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'cog-americas',
      },
    ],
  },
  'anybotics-sandbox': {
    id: 'anybotics-sandbox',
    projects: [
      {
        id: 'anybotics-sandbox',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'anybotics',
      },
    ],
  },
  'infield-dev': {
    // infield
    id: 'infield-dev',
    projects: [
      {
        id: 'infield-dev',
        authType: 'cogidp',
        cluster: clusters.ew1,
        org: 'cog-infield',
      },
    ],
  },
  fusion: {
    id: 'fusion',
    aliases: ['fusion-dev'],
    projects: [
      {
        id: 'fusion-dev',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: '0b320d24-2c6f-45c6-be28-8d63e0804f51',
      },
    ],
  },
  'lervik-industries': {
    // infield
    id: 'lervik-industries',
    aliases: ['lervik'],
    projects: [
      {
        id: 'lervik-industries',
        authType: 'cogidp',
        cluster: clusters.ew1,
        org: 'cog-demo',
      },
    ],
  },
  'atlas-greenfield': {
    // infield
    id: 'atlas-greenfield',
    aliases: ['atlas'],
    projects: [
      {
        id: 'atlas-greenfield',
        authType: 'cogidp',
        cluster: clusters.greenfield,
        configurationSchemaVersion: 5,
        org: 'cog-atlas',
      },
    ],
  },
  'atlas-e2e': {
    // infield
    id: 'atlas-e2e',
    projects: [
      {
        id: 'atlas-e2e',
        authType: 'cogidp',
        cluster: clusters.bluefield,
        org: 'cog-atlas',
      },
    ],
  },
  'atlas-aws-dub': {
    // infield
    id: 'atlas-aws-dub',
    aliases: ['atlas-aws-dub'],
    projects: [
      {
        id: 'atlas-aws-dub',
        authType: 'cogidp',
        cluster: clusters['aws-dub-dev'],
        org: 'cog-atlas',
      },
    ],
  },
  'engdahl-industries': {
    id: 'engdahl-industries',
    aliases: ['engdahl'],
    projects: [
      {
        id: 'engdahl-industries',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: 'a9ae5b54-3600-4917-a9dc-3020723360b3',
      },
    ],
  },
  'omv-test': {
    id: 'omv-test',
    projects: [
      {
        id: 'omv-test',
        authType: 'azureAD',
        cluster: clusters.omv,
        aadDirectory: 'a8f2ac6f-681f-4361-b51f-c85d86014a17',
      },
    ],
  },
  'az-omv-test': {
    id: 'omv-test',
    aliases: ['az-omv-test', 'azure-omv-test'],
    projects: [
      {
        id: 'omv-test',
        authType: 'azureAD',
        cluster: clusters['az-arn-001'],
        aadDirectory: 'a8f2ac6f-681f-4361-b51f-c85d86014a17',
      },
    ],
  },
  'noc-test': {
    id: 'noc-test',
    projects: [
      {
        id: 'noc-test',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'noc',
      },
    ],
  },
  noc: {
    id: 'noc',
    aliases: ['noc-prod'],
    projects: [
      {
        id: 'noc',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'noc',
      },
    ],
  },
  'noc-pre-prod': {
    // infield
    id: 'noc-pre-prod',
    projects: [
      {
        id: 'noc-pre-prod',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'noc',
      },
    ],
  },
  exxon: {
    id: 'exxon',
    aliases: ['xom'],
    projects: [
      {
        id: 'xom-upstream',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'xom',
      },
    ],
  },
  'exxon-test': {
    id: 'exxon-test',
    aliases: ['xom-test', 'xomt'],
    projects: [
      {
        id: 'xom-upstream-test',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'xom',
      },
    ],
  },
  baytown: {
    id: 'exxon-baytown',
    aliases: ['baytown', 'xom-baytown', 'exxon-baytown'],
    projects: [
      {
        id: 'xom-baytown-dev',
        authType: 'azureAD',
        cluster: clusters['az-eastus-1'],
        aadDirectory: 'xombycognite.onmicrosoft.com',
        configurationSchemaVersion: 3,
      },
    ],
  },
  oxy: {
    id: 'oxy',
    aliases: ['oxy'],
    projects: [
      {
        id: 'oxy',
        authType: 'azureAD',
        cluster: clusters['az-eastus-1'],
        aadDirectory: 'oxybycognite.onmicrosoft.com',
        configurationSchemaVersion: 2,
      },
    ],
  },
  'omv-dev': {
    id: 'omv-dev',
    aliases: ['omv-dev'],
    projects: [
      {
        id: 'omv-dev',
        authType: 'azureAD',
        cluster: clusters.omv,
        aadDirectory: 'a8f2ac6f-681f-4361-b51f-c85d86014a17',
        configurationSchemaVersion: 3,
      },
    ],
  },
  'az-omv-dev': {
    id: 'omv-dev',
    aliases: ['az-omv-dev', 'azure-omv-dev'],
    projects: [
      {
        id: 'omv-dev',
        authType: 'azureAD',
        cluster: clusters['az-arn-001'],
        aadDirectory: 'a8f2ac6f-681f-4361-b51f-c85d86014a17',
      },
    ],
  },
  'doyle-industries': {
    id: 'doyle-industries',
    projects: [
      {
        id: 'doyle-industries',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: 'a9ae5b54-3600-4917-a9dc-3020723360b3',
      },
    ],
  },
  omv: {
    id: 'omv',
    projects: [
      {
        id: 'omv',
        authType: 'azureAD',
        cluster: clusters.omv,
        aadDirectory: 'a8f2ac6f-681f-4361-b51f-c85d86014a17',
      },
    ],
  },
  'az-omv': {
    id: 'omv',
    aliases: ['az-omv', 'azure-omv'],
    projects: [
      {
        id: 'omv',
        authType: 'azureAD',
        cluster: clusters['az-arn-001'],
        aadDirectory: 'a8f2ac6f-681f-4361-b51f-c85d86014a17',
      },
    ],
  },
  petronasdemo: {
    id: 'petronasdemo',
    projects: [
      {
        id: 'petronasdemo',
        authType: 'azureAD',
        cluster: clusters.bluefield,
        aadDirectory: '530ad738-d53c-4a26-91d0-1304a1ee263b',
      },
    ],
  },
  bruneilng: {
    id: 'bruneilng',
    projects: [
      {
        id: 'blng',
        authType: 'azureAD',
        cluster: clusters['asia-northeast1-1'],
        aadDirectory: 'a1aec769-6fec-4287-9210-a844e0772046',
      },
    ],
  },
  akercarboncapturedev: {
    id: 'acc-dev',
    projects: [
      {
        id: 'acc-dev',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: '708a95a2-4f02-4e6f-9fab-173c61a419a0',
      },
    ],
  },
  mfgtechstaging: {
    id: 'mfg-tech-staging',
    aliases: ['mfg-tech-staging'],
    projects: [
      {
        id: 'mfg-tech-staging',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: 'd4f21b24-81a6-4563-af51-5d8c9b7301bf',
      },
    ],
  },
  mfgtechdemo: {
    id: 'mfg-tech-demo',
    aliases: ['mfg-tech-demo'],
    projects: [
      {
        id: 'mfg-tech-demo',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: 'd4f21b24-81a6-4563-af51-5d8c9b7301bf',
      },
    ],
  },
  'petro-tech-staging': {
    // infield
    id: 'petro-tech-staging',
    aliases: ['petro-tech-staging'],
    projects: [
      {
        id: 'petro-tech-staging',
        authType: 'cogidp',
        cluster: clusters.ew1,
        org: 'cog-tech-sales',
      },
    ],
  },
  'petro-tech-demo': {
    id: 'petro-tech-demo',
    aliases: ['petro-tech-demo'],
    projects: [
      {
        id: 'petro-tech-demo',
        authType: 'cogidp',
        cluster: clusters.ew1,
        org: 'cog-tech-sales',
      },
    ],
  },
  'power-ops-staging': {
    id: 'power-ops-staging',
    projects: [
      {
        id: 'power-ops-staging',
        authType: 'cogidp',
        cluster: clusters.bluefield,
        org: 'cog-power-ops',
      },
    ],
  },
  'verdantix-demo': {
    // infield
    id: 'verdantix-demo',
    aliases: ['verdantix-demo'],
    projects: [
      {
        id: 'verdantix-demo',
        authType: 'cogidp',
        cluster: clusters.ew1,
        org: 'cog-tech-sales',
      },
    ],
  },
  'lyse-dev': {
    id: 'lyse-dev',
    projects: [
      {
        id: 'lyse-dev',
        authType: 'cogidp',
        cluster: clusters['az-power-no-northeurope'],
        org: 'lyse',
      },
    ],
  },
  'lyse-prod': {
    id: 'lyse-prod',
    projects: [
      {
        id: 'lyse-prod',
        authType: 'cogidp',
        cluster: clusters['az-power-no-northeurope'],
        org: 'lyse',
      },
    ],
  },
  'heco-dev': {
    id: 'heco-dev',
    projects: [
      {
        id: 'heco-dev',
        authType: 'cogidp',
        cluster: clusters['az-power-no-northeurope'],
        org: 'hafslundeco',
      },
    ],
  },
  'heco-prod': {
    id: 'heco-prod',
    projects: [
      {
        id: 'heco-prod',
        authType: 'cogidp',
        cluster: clusters['az-power-no-northeurope'],
        org: 'hafslundeco',
      },
    ],
  },
  'oe-dev': {
    id: 'oe-dev',
    projects: [
      {
        id: 'oe-dev',
        authType: 'cogidp',
        cluster: clusters['az-power-no-northeurope'],
        org: 'ostfoldenergi',
      },
    ],
  },
  'oe-prod': {
    id: 'oe-prod',
    projects: [
      {
        id: 'oe-prod',
        authType: 'cogidp',
        cluster: clusters['az-power-no-northeurope'],
        org: 'ostfoldenergi',
      },
    ],
  },
  'sre-probing-power-no-northeurope': {
    id: 'sre-probing-power-no-northeurope',
    projects: [
      {
        id: 'sre-probing-power-no-northeurope',
        authType: 'azureAD',
        cluster: {
          name: 'az-power-no-northeurope',
          aadAppId: 'ebcd14e2-9c0e-4c66-9c05-eab3e4920c25',
          stagingAadAppId: 'ebcd14e2-9c0e-4c66-9c05-eab3e4920c25',
          apiBaseURL: 'https://az-power-no-northeurope.cognitedata.com',
        },
        aadDirectory: 'aaf125d7-ac32-4151-be8f-597685b5a007',
      },
    ],
  },
  'slb-industries': {
    // infield
    id: 'slb-industries',
    aliases: ['slb-industries'],
    projects: [
      {
        id: 'slb-industries',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'slb-dev',
      },
    ],
  },
  'slb-midstream': {
    // infield
    id: 'slb-midstream',
    aliases: ['slb-midstream'],
    projects: [
      {
        id: 'slb-midstream',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'slb-dev',
      },
    ],
  },
  'sorama-dev': {
    id: 'sorama-dev',
    aliases: ['sorama-dev'],
    projects: [
      {
        id: 'sorama-dev',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'sorama',
      },
    ],
  },
  bbraun: {
    id: 'bbraun',
    projects: [
      {
        id: 'bbraun-prod',
        authType: 'azureAD',
        cluster: clusters['westeurope-1'],
        aadDirectory: '15d1bef2-0a6a-46f9-be4c-023279325e51',
        configurationSchemaVersion: 1,
      },
    ],
  },
  'bbraun-qa': {
    // infield
    id: 'bbraun-qa',
    projects: [
      {
        id: 'bbraun-qa',
        authType: 'azureAD',
        cluster: clusters['westeurope-1'],
        aadDirectory: '15d1bef2-0a6a-46f9-be4c-023279325e51',
        configurationSchemaVersion: 1,
      },
    ],
  },
  'bbraun-dev-az': {
    // infield
    id: 'bbraun-dev-az',
    projects: [
      {
        id: 'bbraun-dev-az',
        authType: 'azureAD',
        cluster: clusters['westeurope-1'],
        aadDirectory: '15d1bef2-0a6a-46f9-be4c-023279325e51',
        configurationSchemaVersion: 1,
      },
    ],
  },
  'bbraun-prod': {
    // infield
    id: 'bbraun-prod',
    projects: [
      {
        id: 'bbraun-prod',
        authType: 'azureAD',
        cluster: clusters['westeurope-1'],
        aadDirectory: '15d1bef2-0a6a-46f9-be4c-023279325e51',
        configurationSchemaVersion: 1,
      },
    ],
  },
  'f25e-bootcamp-test': {
    id: 'f25e-bootcamp-test',
    projects: [
      {
        id: 'f25e-bootcamp-test',
        authType: 'azureAD',
        cluster: clusters['westeurope-1'],
        aadDirectory: 'a9ae5b54-3600-4917-a9dc-3020723360b3',
      },
    ],
  },
  'f25e-bootcamp-prod': {
    id: 'f25e-bootcamp-prod',
    projects: [
      {
        id: 'f25e-bootcamp-prod',
        authType: 'azureAD',
        cluster: clusters['westeurope-1'],
        aadDirectory: 'a9ae5b54-3600-4917-a9dc-3020723360b3',
      },
    ],
  },
  'oq-dev': {
    // infield
    id: 'oq-dev',
    projects: [
      {
        id: 'oq-dev',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'oq',
      },
    ],
  },
  'oq-test': {
    // infield
    id: 'oq-test',
    projects: [
      {
        id: 'oq-test',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'oq',
      },
    ],
  },
  'oq-prod': {
    // infield
    id: 'oq-prod',
    projects: [
      {
        id: 'oq-prod',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'oq',
      },
    ],
  },
  'neptune-no-dev': {
    // infield
    id: 'neptune-no-dev',
    projects: [
      {
        id: 'neptune-no-dev',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'neptuneenergy',
      },
    ],
  },
  'neptune-no': {
    // infield
    id: 'neptune-no',
    aliases: ['neptune-no'],
    projects: [
      {
        id: 'neptune-no',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'neptuneenergy',
      },
    ],
  },
  'cog-lont': {
    id: 'cog-lont',
    aliases: ['cog-lont'],
    projects: [
      {
        id: 'lont-industries',
        authType: 'azureAD',
        cluster: clusters.greenfield,
        aadDirectory: '8da64a4c-6dbc-4fce-b28d-63ba32cd74be',
      },
    ],
  },
  'dss-dev': {
    id: 'dss-dev',
    aliases: ['dss-dev'],
    projects: [
      {
        id: 'dss-dev',
        authType: 'azureAD',
        cluster: clusters.greenfield,
        aadDirectory: '963e089e-a007-4800-8b11-c04e39356cdd',
      },
    ],
  },
  'akerbp-dev': {
    // infield
    id: 'akerbp-dev',
    aliases: ['akerbp-dev'],
    projects: [
      {
        id: 'akerbp-dev',
        authType: 'cogidp',
        cluster: clusters.ew1,
        org: 'akerbp',
      },
    ],
  },
  celanese: {
    // infield
    id: 'celanese',
    projects: [
      {
        id: 'celanese',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'celanese',
      },
    ],
  },
  'celanese-dev': {
    // infield
    id: 'celanese-dev',
    aliases: ['celanese-dev'],
    projects: [
      {
        id: 'celanese-dev',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'celanese',
      },
    ],
  },
  'celanese-stg': {
    // infield
    id: 'celanese-stg',
    projects: [
      {
        id: 'celanese-stg',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'celanese',
      },
    ],
  },
  'renewable-tech-staging': {
    id: 'renewable-tech-staging',
    aliases: ['renewable-tech-staging'],
    projects: [
      {
        id: 'renewable-tech-staging',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: 'd4f21b24-81a6-4563-af51-5d8c9b7301bf',
      },
    ],
  },
  'jpower-thermal': {
    id: 'jpower-thermal',
    aliases: ['jpower-thermal'],
    projects: [
      {
        id: 'jpower-thermal',
        authType: 'cogidp',
        cluster: clusters['az-tyo-gp-001'],
        configurationSchemaVersion: 3,
        org: 'jpower-thermal',
      },
    ],
  },
  maruzen: {
    // infield
    id: 'maruzen',
    aliases: ['maruzen', 'chemiway'],
    projects: [
      {
        id: 'maruzen',
        authType: 'cogidp',
        cluster: clusters['az-tyo-gp-001'],
        org: 'chemiway',
      },
    ],
  },
  jdblue: {
    id: 'jdblue',
    aliases: ['jdblue'],
    projects: [
      {
        id: 'jdblue',
        authType: 'cogidp',
        cluster: clusters.bluefield,
        org: 'cog-jasond',
      },
    ],
  },
  jdgreen: {
    id: 'jdgreen',
    aliases: ['jdgreen'],
    projects: [
      {
        id: 'jdgreen',
        authType: 'cogidp',
        cluster: clusters.greenfield,
        org: 'cog-jasond',
      },
    ],
  },
  jdorange: {
    id: 'jdorange',
    aliases: ['jdorange'],
    projects: [
      {
        id: 'jdorange',
        authType: 'cogidp',
        cluster: clusters.orangefield,
        org: 'cog-jasond',
      },
    ],
  },
  'slb-artemis-dev': {
    id: 'slb-artemis-dev',
    aliases: ['slb-artemis-dev'],
    projects: [
      {
        id: 'slb-artemis-dev',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'delfi-dev',
      },
    ],
  },
  'slb-artemis-demo-eu': {
    id: 'slb-artemis-demo-eu',
    aliases: ['slb-artemis-demo-eu'],
    projects: [
      {
        id: 'slb-artemis-demo-eu',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'delfi',
      },
    ],
  },
  'slb-artemis-dev2': {
    id: 'slb-artemis-dev2',
    aliases: ['slb-artemis-dev2'],
    projects: [
      {
        id: 'slb-artemis-dev2',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'delfi-dev',
      },
    ],
  },
  kuwaitFacility: {
    // infield
    id: 'kuwait-facility',
    projects: [
      {
        id: 'kuwait-facility',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'koc',
      },
    ],
  },
  'sre-probing-orangefield': {
    id: 'sre-probing-orangefield',
    projects: [
      {
        id: 'sre-probing-orangefield',
        authType: 'azureAD',
        cluster: clusters.orangefield,
        aadDirectory: 'aaf125d7-ac32-4151-be8f-597685b5a007',
      },
    ],
  },
  'wd-dev': {
    id: 'wd-dev',
    projects: [
      {
        id: 'wd-dev',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: '77b89f9e-cf5c-4c4c-af28-7a4de6f60b90',
      },
    ],
  },
  'hess-us': {
    // infield
    id: 'hess-us',
    projects: [
      {
        id: 'hess-us',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'hess',
      },
    ],
  },
  'hess-dev': {
    // infield
    id: 'hess-dev',
    projects: [
      {
        id: 'hess-dev',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'hess',
      },
    ],
  },
  'tokyogas-dev': {
    // infield
    id: 'tokyogas-dev',
    projects: [
      {
        id: 'tokyogas-dev',
        authType: 'cogidp',
        cluster: clusters['asia-northeast1-1'],
        org: 'tokyogas',
      },
    ],
  },
  'zeon-mizushima': {
    id: 'zeon-mizushima',
    projects: [
      {
        id: 'zeon-mizushima',
        authType: 'cogidp',
        cluster: clusters['az-tyo-gp-001'],
        org: 'zeon',
      },
    ],
  },
  zeon: {
    id: 'zeon',
    projects: [
      {
        id: 'zeon',
        authType: 'cogidp',
        cluster: clusters['az-tyo-gp-001'],
        org: 'zeon',
      },
    ],
  },
  teco: {
    id: 'teco',
    projects: [
      {
        id: 'teco',
        authType: 'cogidp',
        cluster: clusters['az-tyo-gp-001'],
        org: 'teco-demo',
      },
    ],
  },
  'ak-sfx': {
    // infield
    id: 'ak-sfx',
    projects: [
      {
        id: 'ak-sfx',
        authType: 'cogidp',
        cluster: clusters['asia-northeast1-1'],
        org: 'asahi-kasei',
      },
    ],
  },
  tokyogas: {
    id: 'tokyogas',
    projects: [
      {
        id: 'tokyogas',
        authType: 'azureAD',
        cluster: clusters['az-tyo-gp-001'],
        aadDirectory: '50444dcd-8238-44a9-bd35-dda034e721c0',
      },
    ],
  },
  'prm-ansk': {
    // infield
    id: 'prm-ansk',
    projects: [
      {
        id: 'prm-ansk',
        authType: 'cogidp',
        cluster: clusters['az-tyo-gp-001'],
        org: 'prm',
      },
    ],
  },
  fujioil: {
    // infield
    id: 'fujioil',
    projects: [
      {
        id: 'fujioil',
        authType: 'cogidp',
        cluster: clusters['az-tyo-gp-001'],
        org: 'foc',
      },
    ],
  },
  'x-3in1': {
    // infield
    id: 'x-3in1',
    projects: [
      {
        id: 'x-3in1',
        authType: 'cogidp',
        cluster: clusters['az-tyo-gp-001'],
        org: 'cosmo',
      },
    ],
  },
  shokubai: {
    // infield
    id: 'shokubai',
    projects: [
      {
        id: 'shokubai',
        authType: 'cogidp',
        cluster: clusters['az-tyo-gp-001'],
        org: 'shokubai',
      },
    ],
  },
  jfes: {
    id: 'jfes',
    projects: [
      {
        id: 'jfes',
        authType: 'azureAD',
        cluster: clusters['az-tyo-gp-001'],
        aadDirectory: '16fb108f-65a0-4ef6-9942-97b06880ede3',
      },
    ],
  },
  'tkodama-dev': {
    id: 'tkodama-dev',
    projects: [
      {
        id: 'tkodama-dev',
        authType: 'azureAD',
        cluster: clusters.greenfield,
        aadDirectory: 'b572ee47-12a4-4c3c-a92f-769cae654524',
      },
    ],
  },
  linccs: {
    id: 'linccs',
    projects: [
      {
        id: 'linccs',
        authType: 'azureAD',
        cluster: clusters['westeurope-1'],
        aadDirectory: '48a8c5fd-ae33-444e-92b3-532725568f85',
      },
    ],
  },
  'novachem-dev': {
    // infield
    id: 'novachem-dev',
    projects: [
      {
        id: 'novachem-dev',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        configurationSchemaVersion: 2,
        org: 'novachem',
      },
    ],
  },
  'cog-da-personal': {
    id: 'cog-da-personal',
    projects: [
      {
        id: 'cog-da-personal',
        authType: 'azureAD',
        cluster: clusters['az-eastus-1'],
        aadDirectory: '384f081c-5dc0-4ad6-a7b8-8204ffd68e27',
      },
    ],
  },
  'marathon-dev': {
    // infield
    id: 'marathon-dev',
    projects: [
      {
        id: 'marathon-dev',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'marathon-dev',
      },
    ],
  },
  'arcelor-mittal-development': {
    // infield
    id: 'arcelor-mittal-development',
    projects: [
      {
        id: 'arcelor-mittal-development',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'arcelor-mittal',
      },
    ],
  },
  'arcelor-mittal-production': {
    // infield
    id: 'arcelor-mittal-production',
    projects: [
      {
        id: 'arcelor-mittal-production',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'arcelor-mittal',
      },
    ],
  },
  'petronas-pma-dev': {
    // infield
    id: 'petronas-pma-dev',
    projects: [
      {
        id: 'petronas-pma-dev',
        authType: 'cogidp',
        cluster: clusters['az-sin-sp-001'],
        org: 'petronas-pma',
      },
    ],
  },
  'petronas-pma-prod': {
    id: 'petronas-pma-prod',
    projects: [
      {
        id: 'petronas-pma-prod',
        authType: 'azureAD',
        cluster: clusters['az-sin-sp-001'],
        aadDirectory: '530ad738-d53c-4a26-91d0-1304a1ee263b',
      },
    ],
  },
  'project-loader-dev': {
    id: 'project-loader-dev',
    projects: [
      {
        id: 'project-loader-dev',
        authType: 'azureAD',
        cluster: clusters.greenfield,
        aadDirectory: 'b8976172-70dd-451f-9e05-b55a77a31a62',
      },
    ],
  },
  'petronas-gpk-dev': {
    // infield
    id: 'petronas-gpk-dev',
    projects: [
      {
        id: 'petronas-gpk-dev',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'petronas',
      },
    ],
  },
  'slb-pov-pttep': {
    id: 'slb-pov-pttep',
    projects: [
      {
        id: 'slb-pov-pttep',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'pttep',
      },
    ],
  },
  'slb-psdc': {
    // infield
    id: 'slb-psdc',
    projects: [
      {
        id: 'slb-psdc',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        aadDirectory: 'slb-dev',
      },
    ],
  },
  nammo: {
    id: 'nammo',
    projects: [
      {
        id: 'nammo',
        authType: 'azureAD',
        cluster: clusters['westeurope-1'],
        aadDirectory: '3c22a471-477f-4292-96a7-d3cee324d7f3',
      },
    ],
  },
  'equate-dev': {
    // infield
    id: 'equate-dev',
    projects: [
      {
        id: 'equate-dev',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'equate',
      },
    ],
  },
  'huntsman-dev': {
    // infield
    id: 'huntsman-dev',
    projects: [
      {
        id: 'huntsman-dev',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        configurationSchemaVersion: 1,
        org: 'huntsman',
      },
    ],
  },
  'slb-mlc': {
    id: 'slb-mlc',
    projects: [
      {
        id: 'slb-mlc',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'delfi',
      },
    ],
  },
  'slb-forum': {
    id: 'slb-forum',
    projects: [
      {
        id: 'slb-forum',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'delfi',
      },
    ],
  },
  'cognite-support-qa': {
    id: 'cognite-support-qa',
    projects: [
      {
        id: 'cognite-support-qa',
        authType: 'azureAD',
        cluster: clusters.ew1,
        aadDirectory: 'fdb7a10f-8ec9-4838-960c-1eb2edba13d6',
      },
    ],
  },
  'gsk-dev': {
    // infield
    id: 'gsk-dev',
    projects: [
      {
        id: 'gsk-dev',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'gsk',
      },
    ],
  },
  'gsk-prod': {
    id: 'gsk-prod',
    projects: [
      {
        id: 'gsk-prod',
        authType: 'azureAD',
        cluster: clusters['westeurope-1'],
        aadDirectory: '2d3cba4e-94b2-44cd-86c2-f58ffb3c1bc0',
      },
    ],
  },
  'kaes-development': {
    // infield
    id: 'kaes-development',
    projects: [
      {
        id: 'kaes-development',
        authType: 'cogidp',
        cluster: clusters['aw-was-gp-001'],
        org: 'kaes-poc',
      },
    ],
  },
  'schlumberger-dev': {
    id: 'schlumberger-dev',
    projects: [
      {
        id: 'schlumberger-dev',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'slb-dev',
      },
    ],
  },
  'tech-sales-toolkit': {
    id: 'tech-sales-toolkit',
    projects: [
      {
        id: 'tech-sales-toolkit',
        authType: 'azureAD',
        cluster: clusters['westeurope-1'],
        aadDirectory: 'd4f21b24-81a6-4563-af51-5d8c9b7301bf',
      },
    ],
  },
  'knauf-dev': {
    // infield
    id: 'knauf-dev',
    projects: [
      {
        id: 'knauf-dev',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'knauf',
      },
    ],
  },
  'knauf-prod': {
    id: 'knauf-prod',
    projects: [
      {
        id: 'knauf-prod',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'knauf',
      },
    ],
  },
  'pinnacle-demo': {
    id: 'pinnacle-demo',
    projects: [
      {
        id: 'pinnacle-demo',
        authType: 'azureAD',
        cluster: clusters['az-eastus-1'],
        aadDirectory: 'bef7e9a0-43b5-45b4-94ed-5792274af7fd',
      },
    ],
  },
  'oxy-aws-dev': {
    // infield
    id: 'oxy-aws-dev',
    projects: [
      {
        id: 'oxy-aws-dev',
        authType: 'cogidp',
        cluster: clusters['aw-was-gp-001'],
        org: 'oxy',
      },
    ],
  },
  'oxy-aws': {
    // infield
    id: 'oxy-aws',
    projects: [
      {
        id: 'oxy-aws',
        authType: 'cogidp',
        cluster: clusters['aw-was-gp-001'],
        org: 'oxy',
      },
    ],
  },
  abp: {
    id: 'abp',
    projects: [
      {
        id: 'abp',
        authType: 'cogidp',
        cluster: clusters['az-ams-sp-002'],
        org: 'akerbp',
      },
    ],
  },
  'abp-dev': {
    id: 'abp-dev',
    projects: [
      {
        id: 'abp-dev',
        authType: 'cogidp',
        cluster: clusters['az-ams-sp-002'],
        org: 'akerbp',
      },
    ],
  },
  'petrobras-dev': {
    // infield
    id: 'petrobras-dev',
    projects: [
      {
        id: 'petrobras-dev',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'petrobras',
      },
    ],
  },
  'nova-dev': {
    id: 'nova-dev',
    projects: [
      {
        id: 'nova-dev',
        authType: 'azureAD',
        cluster: clusters['az-eastus-1'],
        aadDirectory: '0d396c31-441f-4e16-a6e6-3b017f2f63d1',
      },
    ],
  },
  nova: {
    id: 'nova',
    projects: [
      {
        id: 'nova',
        authType: 'azureAD',
        cluster: clusters['az-eastus-1'],
        aadDirectory: '0d396c31-441f-4e16-a6e6-3b017f2f63d1',
      },
    ],
  },
  'abp-test': {
    id: 'abp-test',
    projects: [
      {
        id: 'abp-test',
        authType: 'cogidp',
        cluster: clusters['az-ams-sp-002'],
        org: 'akerbp',
      },
    ],
  },
  'nova-staging': {
    id: 'nova-staging',
    projects: [
      {
        id: 'nova-staging',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'nova',
      },
    ],
  },
  'createc-dev': {
    id: 'createc-dev',
    aliases: ['createc-dev'],
    projects: [
      {
        id: 'createc-dev',
        authType: 'cogidp',
        cluster: clusters['westeurope-1'],
        org: 'createc',
      },
    ],
  },
  'auth-ak-test': {
    id: 'auth-ak-test',
    projects: [
      {
        id: 'auth-ak-test',
        authType: 'cogidp',
        cluster: clusters.greenfield,
        org: 'cog-auth-ak-test',
      },
    ],
  },
  'hess-malaysia-dev': {
    id: 'hess-malaysia-dev',
    projects: [
      {
        id: 'hess-malaysia-dev',
        authType: 'cogidp',
        cluster: clusters['az-sin-sp-001'],
        org: 'hess-malaysia',
      },
    ],
  },
  tommy: {
    id: 'tommy',
    projects: [
      {
        id: 'tommy',
        authType: 'cogidp',
        cluster: clusters.greenfield,
        org: 'cog-unstructured-search',
      },
    ],
  },
  'xom-downstream': {
    id: 'xom-downstream',
    projects: [
      {
        id: 'xom-downstream',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'xom',
      },
    ],
  },
  'kaes-dev': {
    id: 'kaes-dev',
    projects: [
      {
        id: 'kaes-dev',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'kaes-dev',
      },
    ],
  },
  'jp-azure': {
    id: 'jp-azure',
    projects: [
      {
        id: 'jp-azure',
        authType: 'cogidp',
        cluster: clusters['az-tyo-gp-001'],
        org: 'cog-japan',
      },
    ],
  },
  'kaes-uat': {
    id: 'kaes-uat',
    projects: [
      {
        id: 'kaes-uat',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'kaes-uat',
      },
    ],
  },
  'kaes-prod': {
    id: 'kaes-prod',
    projects: [
      {
        id: 'kaes-prod',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'kaes-prod',
      },
    ],
  },
  'hess-malaysia-prod': {
    id: 'hess-malaysia-prod',
    projects: [
      {
        id: 'hess-malaysia-prod',
        authType: 'cogidp',
        cluster: clusters['az-sin-sp-001'],
        org: 'hess-malaysia',
      },
    ],
  },
  'cdf-ip-test': {
    id: 'cdf-ip-test',
    projects: [
      {
        id: 'cdf-ip-test',
        authType: 'cogidp',
        cluster: clusters['az-eastus-1'],
        org: 'ipaper',
      },
    ],
  },
};

export default CUSTOMERS;
