import {
  DocumentIcon,
  EmptyState,
  PlansIllustration,
  Skeleton,
} from '@cognite/cogs.js-v10';
import type { Filters } from '@cognite/fdm-client/src/types';
import { useAppConfigQuery } from '@infield/features/app-config';
import { AssetCard } from '@infield/features/asset/shared/asset-card/asset-card';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useAssetNotificationsQuery } from '@infield/features/notifications';
import { EmptyStateWrapper } from '@infield/features/ui/table-empty-state/elements';
import type { FC, ReactNode } from 'react';

import { AssetNotificationsList } from './asset-notifications-list';

const NUMBER_OF_NOTIFICATIONS_TO_FIT_CARD = 6;

interface Props {
  assetExternalId: string;
  onOpen?: () => void;
  onNotificationClick: (notificationExternalId: string) => void;
}

export const AssetNotificationsCard: FC<Props> = ({
  assetExternalId,
  onOpen,
  onNotificationClick,
}) => {
  const { data: appConfig } = useAppConfigQuery();
  const { t } = useTranslation(LOCIZE_NAMESPACES.notification);

  const notificationsConfiguration =
    appConfig?.featureConfiguration?.notifications;

  const getFiltersFromConfiguration = () => {
    return Object.keys(
      notificationsConfiguration?.overviewCard?.filters || {}
    ).reduce((acc, field) => {
      if (notificationsConfiguration?.overviewCard?.filters)
        return [
          ...acc,
          {
            or: notificationsConfiguration?.overviewCard?.filters[field].map(
              (f) => ({
                equals: { property: field, eq: f.value },
              })
            ),
          },
        ];

      return acc;
    }, [] as Filters[]);
  };

  const { data: notifications, isLoading } = useAssetNotificationsQuery(
    assetExternalId,
    getFiltersFromConfiguration(),
    [{ createdDate: 'DESC' }],
    NUMBER_OF_NOTIFICATIONS_TO_FIT_CARD
  );
  const hasNotifications = notifications && notifications.length > 0;

  let content: ReactNode;
  if (isLoading) {
    content = <Skeleton.List lines={NUMBER_OF_NOTIFICATIONS_TO_FIT_CARD} />;
  } else if (!hasNotifications) {
    content = (
      <EmptyStateWrapper>
        <EmptyState
          illustration={<PlansIllustration />}
          title={t(
            'ASSET_OVERVIEW_NOTIFICATIONS_CARD_NO_NOTIFICATION_TITLE',
            'No notifications'
          )}
          description={t(
            'ASSET_OVERVIEW_NOTIFICATIONS_CARD_NO_NOTIFICATION_BODY',
            'There are no notifications found on this asset.'
          )}
        />
      </EmptyStateWrapper>
    );
  } else {
    content = (
      <AssetNotificationsList
        notifications={notifications}
        onNotificationClick={onNotificationClick}
      />
    );
  }

  return (
    <AssetCard
      icon={<DocumentIcon />}
      title={t('ASSET_OVERVIEW_NOTIFICATIONS_CARD_TITLE', 'Notifications')}
      buttonText={t('ASSET_OVERVIEW_NOTIFICATIONS_CARD_BUTTON_OPEN', 'Open')}
      onButtonClick={!isLoading && hasNotifications ? onOpen : undefined}
    >
      {content}
    </AssetCard>
  );
};
