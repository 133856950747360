import getEuclideanDistance from './getEuclideanDistance';

type Circle = {
  x: number;
  y: number;
  radius: number;
};

const isOverlapping = (circle1: Circle, circle2: Circle): boolean => {
  const distance = getEuclideanDistance(
    { x: circle1.x, y: circle1.y },
    { x: circle2.x, y: circle2.y }
  );
  return distance < circle1.radius + circle2.radius;
};

const areCirclesOverlapping = (circles: Circle[]): boolean => {
  return circles.some((circle1, i) =>
    circles.slice(i + 1).some((circle2) => isOverlapping(circle1, circle2))
  );
};

export default areCirclesOverlapping;
