import type { AssetMapping3D } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { ThreeDModelIdentifier } from '../types';

// gives mappings for assetId for one specific 3D model
export const useAssetMappingsByModelIdQuery = <TData = AssetMapping3D[]>(
  {
    assetId,
    modelId,
    revisionId,
  }: Partial<ThreeDModelIdentifier & { assetId: number | null }>,
  options?: UseQueryOptions<AssetMapping3D[], unknown, TData>
) => {
  const { threeDService } = useFDMServices();

  return useQuery<AssetMapping3D[], unknown, TData>(
    [QueryKeys.THREE_D, modelId, revisionId, 'mappings-by-asset-id', assetId],
    () =>
      threeDService.filterAssetMappingsByAssetId(
        modelId!,
        revisionId!,
        assetId!
      ),
    {
      onError: (err) =>
        captureException(err, {
          level: 'warning',
          tags: {
            queryKey: '3d mappings-by-asset-id',
          },
        }),
      staleTime: Infinity,
      enabled: Boolean(assetId) && Boolean(modelId) && Boolean(revisionId),
      ...options,
    }
  );
};
