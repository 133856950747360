import Konva from 'konva';

import { UNIFIED_VIEWER_NODE_TYPE_KEY } from '../types';

import findNearestAncestor from './findNearestAncestor';

const findNearestAncestorByUnifiedViewerType = (
  unifiedViewerType: string
): ((node: Konva.Node) => Konva.Group | undefined) =>
  findNearestAncestor<Konva.Group | undefined>(
    (node) => node.getAttr(UNIFIED_VIEWER_NODE_TYPE_KEY) === unifiedViewerType
  );

export default findNearestAncestorByUnifiedViewerType;
