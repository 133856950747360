import { useState } from 'react';

import { cloneDeep } from 'lodash-es';

import { PlotRange, PresetPlotRange } from '../types';
import { convertToPlotRange } from '../utils/convertToPlotRange';
import { getUnifiedPlotRange } from '../utils/getUnifiedPlotRange';
import { isUndefinedPlotRange } from '../utils/isUndefinedPlotRange';

import { useDeepCallback, useDeepEffect, useDeepMemo } from './useDeep';

interface Props {
  initialRange?: PlotRange;
  plotDataRange?: PlotRange;
  presetRange?: PresetPlotRange;
  onRangeChange?: (range: PlotRange) => void;
}

/**
 * IMPORTANT NOTICE:
 * Plotly mutates the range values by REFERENCE.
 * So, we have to clone the values to avoid @initialRange being mutated when changing the plot range.
 * PLEASE DON'T REMOVE ANY CLONING STEPS HERE.
 */
export const useHandlePlotRange = ({
  initialRange,
  plotDataRange,
  presetRange,
  onRangeChange,
}: Props) => {
  const [range, setRange] = useState<PlotRange | PresetPlotRange>();
  const [enableAutoAlign, setEnableAutoAlign] = useState<boolean>(false);

  useDeepEffect(() => {
    if (initialRange) {
      const initialRangeClone = cloneDeep(initialRange);
      setRange(initialRangeClone);
    }
  }, [initialRange]);

  /**
   * To ensure all the datapoints are shown in the plot area.
   * This alignment is done only if @enableAutoAlign is true.
   */
  useDeepEffect(() => {
    if (enableAutoAlign && plotDataRange && plotRange) {
      const unifiedPlotRange = getUnifiedPlotRange(plotDataRange, plotRange);
      setRange(unifiedPlotRange);
    }
  }, [plotDataRange]);

  const plotRange = useDeepMemo(() => {
    const presetPlotRange = convertToPlotRange(presetRange);

    return {
      x: presetPlotRange?.x || range?.x,
      y: presetPlotRange?.y || range?.y,
    } as PlotRange | undefined;
  }, [presetRange, range]);

  const setPlotRange = useDeepCallback(
    (changedRange: Partial<PlotRange>, allowAutoAlign = true) => {
      if (isUndefinedPlotRange(changedRange) || !plotRange) {
        return;
      }

      const newRange = {
        x: changedRange.x || plotRange.x,
        y: changedRange.y || plotRange.y,
      };

      setRange(newRange);
      onRangeChange?.(newRange);
      setEnableAutoAlign(allowAutoAlign);
    },
    [plotRange]
  );

  const resetPlotRange = useDeepCallback(() => {
    if (initialRange) {
      const initialRangeClone = cloneDeep(initialRange);
      setRange(initialRangeClone);
      onRangeChange?.(initialRangeClone);
    }
  }, [initialRange]);

  return {
    range: plotRange,
    setPlotRange,
    resetPlotRange,
  };
};
