export class PriorityQueue<T> {
  private items: T[];
  // The compareFn function should return a negative, zero, or positive value depending on
  // whether the first argument is considered smaller than, equal to, or greater than the second argument.
  private compareFn: (a: T, b: T) => number;

  public constructor(compareFn: (a: T, b: T) => number) {
    this.items = [];
    this.compareFn = compareFn;
  }

  private parentIndex(index: number): number {
    return Math.floor((index - 1) / 2);
  }

  private leftChildIndex(index: number): number {
    return index * 2 + 1;
  }

  private rightChildIndex(index: number): number {
    return index * 2 + 2;
  }

  private swap(i: number, j: number): void {
    const temp = this.items[i];
    this.items[i] = this.items[j];
    this.items[j] = temp;
  }

  private heapifyUp(index: number): void {
    while (
      index > 0 &&
      this.compareFn(this.items[index], this.items[this.parentIndex(index)]) < 0
    ) {
      this.swap(index, this.parentIndex(index));
      index = this.parentIndex(index);
    }
  }

  private heapifyDown(index: number): void {
    let smallest = index;
    const left = this.leftChildIndex(index);
    const right = this.rightChildIndex(index);

    if (
      left < this.items.length &&
      this.compareFn(this.items[left], this.items[smallest]) < 0
    ) {
      smallest = left;
    }

    if (
      right < this.items.length &&
      this.compareFn(this.items[right], this.items[smallest]) < 0
    ) {
      smallest = right;
    }

    if (smallest !== index) {
      this.swap(index, smallest);
      this.heapifyDown(smallest);
    }
  }

  public enqueue(item: T): void {
    this.items.push(item);
    this.heapifyUp(this.items.length - 1);
  }

  public dequeue(): T | undefined {
    if (this.isEmpty()) {
      return undefined;
    }

    const item = this.items[0];
    this.items[0] = this.items[this.items.length - 1];
    this.items.pop();
    this.heapifyDown(0);
    return item;
  }

  public peek(): T | undefined {
    return this.items[0];
  }

  public isEmpty(): boolean {
    return this.items.length === 0;
  }

  public includes(item: T): boolean {
    return this.items.includes(item);
  }
}
