import type { ChecklistStatus } from '@cognite/apm-client';

import type { OverviewPageTableKind } from './types';

export const OVERVIEW_TABLE_OPTIONS = <const>[
  'maintenance-table',
  'checklists-table',
];

export const isOverviewTable = (arg: string): arg is OverviewPageTableKind => {
  return OVERVIEW_TABLE_OPTIONS.some((el) => el === arg);
};

export const translateChecklistStatus = (
  t: any,
  status: ChecklistStatus | undefined
) => {
  switch (status) {
    case 'In progress':
      return t(
        'ACTIVITY_PLANNING_OVERVIEW_TABLE_STATUS_IN_PROGRESS',
        'In progress'
      );

    case 'Done':
      return t('ACTIVITY_PLANNING_OVERVIEW_TABLE_STATUS_DONE', 'Done');

    case 'Ready':
      return t('ACTIVITY_PLANNING_OVERVIEW_TABLE_STATUS_READY', 'Ready');
  }
  return undefined;
};
