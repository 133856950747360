const pollUntilTrue = async (
  conditionFn: () => boolean,
  intervalMs = 1000,
  maxAttempts = 10
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    let attempts = 0;
    const intervalId = setInterval(() => {
      if (conditionFn()) {
        clearInterval(intervalId);
        return resolve();
      }
      attempts++;
      if (attempts >= maxAttempts) {
        clearInterval(intervalId);
        return reject(new Error('Exceeded maximum polling attempts'));
      }
    }, intervalMs);
  });
};

export default pollUntilTrue;
