import { type Activity, CDFIDMViewMigrator } from '@cognite/apm-client';
import type { Filters, Sort } from '@cognite/fdm-client';
import cloneDeep from 'lodash/cloneDeep';

export class ActivityIDMMigrator extends CDFIDMViewMigrator {
  mapDataOutward = (data: Record<string, any>): Activity => {
    return {
      ...data,
      externalId: data.externalId,
      title: data.name,
      assetExternalId: data.mainAsset?.externalId,
      id: data.sourceId,
      startTime: data.scheduledStartTime,
      endTime: data.scheduledEndTime,
      actualStartTime: data.startTime,
      actualEndTime: data.endTime,
    };
  };
  mapFiltersInward = (filters: Filters): Filters => {
    const newFilters = cloneDeep(filters);
    Object.keys(filters).forEach((key) => {
      if (key === 'and') {
        newFilters.and = filters.and!.map((f) => this.mapFiltersInward(f));
      }
      if (key === 'or') {
        newFilters.or = filters.or!.map((f) => this.mapFiltersInward(f));
      }
      if (key === 'not') {
        newFilters.not = this.mapFiltersInward(newFilters.not!);
      }

      if (key === 'equals') {
        if (filters.equals?.property === 'rootLocation') {
          newFilters.equals = undefined;
          console.log(
            `Removed filter for "rootLocation" since the since CogniteMaintenanceOrder has source as CogniteSourceSystem and we don't know how to filter on that yet`
          );
        }
        if (filters.equals?.property === 'assetExternalId') {
          newFilters.equals = undefined;
          newFilters.directRelationFilter = {
            mainAsset: {
              equals: { property: 'externalId', eq: String(filters.equals.eq) },
            },
          };
          newFilters.directRelationInstanceSpace = this.space;
        }
        if (filters.equals?.property === 'source') {
          newFilters.equals = undefined;
          console.log(
            `Removed filter for "source" since the since CogniteMaintenanceOrder has source as CogniteSourceSystem and we don't know how to filter on that yet`
          );
        }
      }
      if (key === 'isNull') {
        if (filters.isNull?.property === 'cloneOf') {
          newFilters.isNull = undefined;
          console.log(
            'Removed filter for "cloneOf" since the property does not exist on CogniteMaintenanceOrder data model'
          );
        }
      }
    });
    return newFilters;
  };
  mapSortInward = (sort: Sort): Sort => {
    return sort.map((x) => {
      if (Object.keys(x)[0] === 'endTime') {
        return { scheduledEndTime: x.endTime };
      }
      if (Object.keys(x)[0] === 'actualEndTime') {
        return { endTime: x.actualEndTime };
      }
      return x;
    });
  };
}
