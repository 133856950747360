import { ChevronDownIcon, ChevronUpIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';
import { useState } from 'react';

import * as S from './elements';

type Props = {
  description: string;
};

export const ActivityDescription: FC<Props> = ({ description }) => {
  const [expandDescription, setExpandDescription] = useState(false);
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  return (
    <S.DescriptionWrapper $expand={expandDescription}>
      <S.DescriptionDetail $expand={expandDescription}>
        {description}
      </S.DescriptionDetail>
      {(description?.length || 0) > 160 && (
        <S.ExpandDescriptionButton
          icon={expandDescription ? <ChevronUpIcon /> : <ChevronDownIcon />}
          size="small"
          iconPlacement="right"
          onClick={() => {
            setExpandDescription((prev) => !prev);
          }}
        >
          {expandDescription
            ? t('ACTIVITY_DETAILS_DESCRIPTION_ACTION_CLOSE', 'Close')
            : t('ACTIVITY_DETAILS_DESCRIPTION_ACTION_EXPAND', 'Expand')}
        </S.ExpandDescriptionButton>
      )}
    </S.DescriptionWrapper>
  );
};
