import { CogniteClient } from '@cognite/sdk';

import { UnifiedViewer } from '../../UnifiedViewer';
import UnifiedViewerEventType from '../../UnifiedViewerEventType';
import { ContainerType } from '../ContainerType';
import ReactContainer, {
  ReactContainerRenderContentProps,
} from '../ReactContainer';

import ErrorContent from './ErrorContent';
import modifySvgPlotClipPathToInternalUrlReference from './modifySvgPlotClipPathToInternalUrlReference';
import TimeseriesContent from './TimeseriesContent';
import { TimeseriesContainerProps } from './types';

export default class TimeseriesContainer<MetadataType> extends ReactContainer<
  MetadataType,
  TimeseriesContainerProps<MetadataType>
> {
  public readonly type = ContainerType.TIMESERIES;
  // For a period of time, the rendered dates might be different from the serialized date,
  // hence they are kept here until we have notified the application layer.
  private dirtyStartDate: Date | undefined;
  private dirtyEndDate: Date | undefined;
  private cogniteClient: CogniteClient;

  // The following tags are ignored when cloning the style of the Plotly.js SVGs
  // when performing a html2canvas screenshot as they don't directly affect the
  // styling.
  private IGNORED_STYLE_TAGS = new Set([
    'div',
    'g',
    'style',
    'rect',
    'defs',
    'clippath',
  ]);

  public constructor(
    props: TimeseriesContainerProps<MetadataType>,
    unifiedViewer: UnifiedViewer
  ) {
    super(props, unifiedViewer);

    const client = this.unifiedViewer.getCogniteClient();
    if (client === undefined) {
      throw new Error(
        'CogniteClient is not defined - please provide a CogniteClient instance to the UnifiedViewer to use TimeseriesContainer'
      );
    }
    this.cogniteClient = client;
  }

  protected getPropsRequiringUpdate(): Array<
    keyof Pick<
      TimeseriesContainerProps,
      | 'instance'
      | 'startDate'
      | 'endDate'
      | 'numberOfPoints'
      | 'displayMode'
      | 'style'
    >
  > {
    return [
      'instance',
      'startDate',
      'endDate',
      'numberOfPoints',
      'displayMode',
      'style',
    ];
  }

  private getStartDateAsDate = () => {
    return new Date(this.props.startDate);
  };

  private getEndDateAsDate = () => {
    return new Date(this.props.endDate);
  };

  public renderContent = ({
    width,
    height,
    unscaledWidth,
    unscaledHeight,
    setLoadingStatus,
  }: ReactContainerRenderContentProps): JSX.Element => {
    if (this.loadingStatus.isError) {
      return <ErrorContent width={width} height={height} />;
    }
    return (
      <TimeseriesContent
        instance={this.props.instance}
        sdk={this.cogniteClient}
        width={width}
        height={height}
        unscaledWidth={unscaledWidth}
        unscaledHeight={unscaledHeight}
        numberOfPoints={this.props.numberOfPoints}
        startDate={this.getStartDateAsDate()}
        endDate={this.getEndDateAsDate()}
        onRangeChange={this.onRangeChange}
        setLoadingStatus={setLoadingStatus}
        displayMode={this.props.displayMode}
        style={this.props.style}
      />
    );
  };

  protected override shouldForceSkipStyleCloning = (
    element: HTMLElement
  ): boolean => {
    const tagName = element.tagName.toLowerCase();
    // Ignore "styleless" tags that don't affect the final rendering.
    if (this.IGNORED_STYLE_TAGS.has(tagName)) {
      return true;
    }

    // The lines in the Plotly.js plots are SVG paths with the class 'js-line'.
    // These can be ignored as they are not styled by CSS.
    if (tagName === 'path' && element.classList.contains('js-line')) {
      return true;
    }

    // Do not clone elements that are not children of the container's DOM node.
    if (!this.domNode.contains(element)) {
      return true;
    }

    return false;
  };

  protected override onClone = (clonedDomNode: HTMLElement): void => {
    const plotlyPlot = clonedDomNode.querySelector('.js-plotly-plot');
    if (plotlyPlot === null) {
      return;
    }
    const svg = plotlyPlot.querySelector('svg');
    if (svg === null) {
      return;
    }

    // HACK: Per https://github.com/niklasvh/html2canvas/issues/2016,
    // html2canvas does not have proper clip-path support for SVGs.
    // However, for some reason, it seems to work fine if the clip-path
    // reference is internal (e.g. "clip-path: url(#clipPathid)").  For
    // our time series component, this means in practice that the lines in
    // the plots are not clipped correctly in the rendered screenshots.
    // As such, below we modify the generated SVGs of Plotly.js plots so
    // that the URL of the clip-path always is specified as an internal
    // reference.
    modifySvgPlotClipPathToInternalUrlReference(svg);

    // Similar to above, for some reason, some of the font properties of
    // the text elements in the Plotly.js SVGs aren't rendered properly in
    // html2canvas. The fix(hack) here is to use the style of the SVG the
    // text elements belong to.
    const textElements = plotlyPlot.querySelectorAll('text');
    const svgStyle = window.getComputedStyle(svg, null);
    const fontWeight = svgStyle.getPropertyValue('font-weight');
    const fontFamily = svgStyle.getPropertyValue('font-family');
    textElements.forEach((text) => {
      text.style.fontWeight = fontWeight;
      text.style.fontFamily = fontFamily;
    });
  };

  public serialize = (): TimeseriesContainerProps => {
    return {
      ...this.serializeBaseContainerProps(),
      type: this.type,
      instance: this.props.instance,
      startDate: new Date(
        this.dirtyStartDate || this.props.startDate
      ).toISOString(),
      endDate: new Date(this.dirtyEndDate || this.props.endDate).toISOString(),
      numberOfPoints: this.props.numberOfPoints,
      displayMode: this.props.displayMode,
    };
  };

  private onRangeChange = (startDate: Date, endDate: Date) => {
    this.setDirtyState({ startDate, endDate });
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      containers: [this.serialize()],
      annotations: [],
    });
    this.clearDirtyState();
  };

  private setDirtyState = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => {
    this.dirtyStartDate = startDate;
    this.dirtyEndDate = endDate;
  };

  private clearDirtyState = () => {
    this.dirtyStartDate = undefined;
    this.dirtyEndDate = undefined;
  };
}
