import { isProduction } from '@infield/features/auth';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocizeBackend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

import {
  LOCIZE_API_KEY,
  LOCIZE_LANGUAGE,
  LOCIZE_NAMESPACES,
  LOCIZE_PROJECT_ID,
} from './constants';

const locizeOptions = {
  projectId: LOCIZE_PROJECT_ID,
  apiKey: LOCIZE_API_KEY, // YOU should not expose your apps API key to production!!!
  referenceLng: LOCIZE_LANGUAGE,
  allowedAddOrUpdateHosts: ['localhost', 'infield2.staging.cogniteapp.com'],
  version: isProduction() ? 'production' : 'latest',
};

const locize = new LocizeBackend(locizeOptions);
export const getAvailableLanguages = async () => locize.getLanguages();

i18n
  .use(LocizeBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: Object.values(LOCIZE_NAMESPACES),
    fallbackLng: 'en',
    saveMissing: !isProduction(),
    lng: LOCIZE_LANGUAGE,
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: locizeOptions,
  });

export const { t } = i18n;

export default i18n;
