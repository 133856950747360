import { IRect } from 'konva/lib/types';
import keyBy from 'lodash/keyBy';

import UnifiedViewerRenderer from '../../UnifiedViewerRenderer';
import getCauseMapEdges from '../../utils/getCauseMapEdges';
import getConnectedComponents from '../../utils/getConnectedComponents';
import isApproximatelyEqual from '../../utils/isApproximatelyEqual';
import isNotUndefined from '../../utils/isNotUndefined';
import {
  CauseMapNodeAnnotation,
  isCauseMapNodeAnnotation,
  isPolylineAnnotation,
} from '../types';

const isMidXEqual = (rect: IRect, target: IRect) =>
  isApproximatelyEqual(rect.x + rect.width / 2, target.x + target.width / 2);

const getElbowConnectionPadding = ({
  fromAnnotation,
  toAnnotation,
  fromRect,
  toRect,
  renderer,
}: {
  fromAnnotation: CauseMapNodeAnnotation;
  toAnnotation: CauseMapNodeAnnotation;
  fromRect: IRect;
  toRect: IRect;
  renderer: UnifiedViewerRenderer;
}): { from: number; to: number } | undefined => {
  const annotations = renderer.getAnnotations();
  const causeMapNodes = annotations.filter(isCauseMapNodeAnnotation);
  if (causeMapNodes.length === 0) {
    return undefined;
  }
  const nodesInConnectedComponents = getConnectedComponents(
    [fromAnnotation, toAnnotation],
    {
      nodes: causeMapNodes,
      edges: getCauseMapEdges({
        connections: annotations.filter(isPolylineAnnotation),
        causeMapNodesById: keyBy(causeMapNodes, 'id'),
      }),
    }
  );

  // NOTE: fromAnnotation and toAnnotation must belong to the same component since they are connected
  if (nodesInConnectedComponents.length !== 1) {
    // eslint-disable-next-line no-console
    console.warn(
      `Expected nodes with ids ${fromAnnotation.id} and ${toAnnotation.id} to be in the same connected component. This should not happen.`
    );
    return undefined;
  }
  const nodeRectsInComponent = nodesInConnectedComponents[0]
    .map(({ id }) => renderer.getAnnotationRectRelativeToStageById(id))
    .filter(isNotUndefined);

  const fromColumnWidths = nodeRectsInComponent
    .filter((rect) => isMidXEqual(rect, fromRect))
    .map(({ width }) => width);
  const toColumnWidths = nodeRectsInComponent
    .filter((rect) => isMidXEqual(rect, toRect))
    .map(({ width }) => width);

  return {
    from: Math.max(...fromColumnWidths) - fromRect.width,
    to: Math.max(...toColumnWidths) - toRect.width,
  };
};

export default getElbowConnectionPadding;
