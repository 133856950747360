import type { InFieldUser } from '@cognite/apm-client';
import { useDisciplines } from '@infield/features/app-config';
import type { Discipline } from '@infield/features/app-config/types';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useAssignedTo = (assignedTo: string[]) => {
  const { apmClient } = useFDMServices();
  const { configuredDisciplines } = useDisciplines();

  return useQuery<{
    disciplines: Discipline[] | undefined;
    users: InFieldUser[] | undefined;
  }>(
    [QueryKeys.USER, 'assignedTo', assignedTo],
    async () => {
      const disciplinesAssigned = assignedTo
        .filter((assigned) => assigned.startsWith('discipline'))
        .map((discipline) => discipline.split(':')[1]);

      const disciplines = configuredDisciplines?.filter(
        (configuredDiscipline) =>
          disciplinesAssigned.includes(configuredDiscipline.externalId)
      );

      const assignedUsers = assignedTo.filter(
        (assigned) => !assigned.startsWith('discipline')
      );

      let users;

      if (assignedUsers.length > 0) {
        const response = await apmClient.users.list(
          `
        externalId
        name
        email
        `,
          {
            filters: {
              in: {
                property: 'externalId',
                in: assignedUsers,
              },
            },
          }
        );

        users = response.list.items;
      }

      return { disciplines, users };
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: `${QueryKeys.USER} assignedTo`,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
