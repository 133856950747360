import { CogniteCadModel } from '@cognite/reveal';
import { use3dModels } from '@cognite/reveal-react-components';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Box3 } from 'three';

// gives you bounding box for passed tree indexes
export const useBoundingBoxByTreeIndexesQuery = (treeIndexes?: number[]) => {
  const model = use3dModels()[0] as CogniteCadModel;
  const getBoundingBoxForAssetMappings = useCallback(async () => {
    if (!(model instanceof CogniteCadModel) || !treeIndexes) return;
    const boundingBoxPromises = treeIndexes.map((treeIndex) =>
      model.getBoundingBoxByTreeIndex(treeIndex)
    );
    const resultingBoundingBox = new Box3();

    const assetMappingsBoundingBoxes = (
      await Promise.all(boundingBoxPromises)
    ).filter((bboxOrEmpty) => Boolean(bboxOrEmpty)) as Box3[];

    assetMappingsBoundingBoxes.forEach((bbox) => {
      resultingBoundingBox.union(bbox);
    });

    return resultingBoundingBox;
  }, [treeIndexes, model]);

  return useQuery(
    [
      QueryKeys.THREE_D,
      model?.modelId,
      model?.revisionId,
      'bounding-box-by-tree-indexes',
      treeIndexes?.join('-'),
    ],
    getBoundingBoxForAssetMappings,
    {
      onError: (err) =>
        captureException(err, {
          level: 'warning',
          tags: {
            queryKey: '3d bounding-box-by-tree-indexes',
          },
        }),
      enabled: Boolean(treeIndexes?.length),
    }
  );
};
