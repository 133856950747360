import type { Filters } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import type { ChecklistItemStatusAggregation } from '@infield/features/checklist';
import { getChecklistItemsAggregatedStatus } from '@infield/features/checklist';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQueries } from '@tanstack/react-query';
import { useRef } from 'react';

export const useChecklistItemStatus = (
  /*
  We want to input chunks (arrays) of checklist ids.
  Otherwise, as the number of ids grow,
  the query will eventually start taking way too much time or fail.
  */
  checklistExternalIdLists?: string[][]
) => {
  const { checklistService } = useFDMServices();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  const result = useQueries({
    // then these chunks are split into separate queries
    queries: (checklistExternalIdLists || []).map((checklistExternalIds) => ({
      queryKey: [QueryKeys.CHECKLIST_ITEM_STATUS, checklistExternalIds],
      queryFn: async () => {
        sliTimerStartTime.current = Date.now();

        const checklistItemsFilter: Filters = {
          and: [
            {
              in: {
                property: 'externalId',
                in: checklistExternalIds,
              },
            },
          ],
        };

        const checklistItemsStatus =
          await checklistService.getChecklistItemsStatus(checklistItemsFilter);

        const aggregatedStatuses = checklistItemsStatus.reduce(
          (acc, currChecklist) => {
            return {
              ...acc,
              [currChecklist.externalId]: getChecklistItemsAggregatedStatus([
                currChecklist,
              ]),
            };
          },
          {} as Record<string, ChecklistItemStatusAggregation>
        );

        return aggregatedStatuses;
      },
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.CHECKLIST_ITEM_STATUS,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.CHECKLIST_ITEM_STATUS, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.CHECKLIST_ITEM_STATUS, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(checklistExternalIds) && checklistExternalIds.length > 0,
    })),
  });

  const isInitialLoading = result
    .map((query) => query.isInitialLoading)
    .some(Boolean);
  const data = result.reduce((acc, curr) => {
    if (!curr.data) {
      return { ...acc };
    }
    return {
      ...acc,
      ...curr.data,
    };
  }, {} as Record<string, ChecklistItemStatusAggregation>);

  return { data, isInitialLoading };
};
