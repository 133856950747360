import { create, windowedFiniteBatchScheduler } from '@yornaath/batshit';
import { memoize, uniq } from 'lodash';

import type {
  CogniteClient,
  FileInfo,
  FileLink,
  InternalId,
  ItemsResponse,
} from '@cognite/sdk';

import { getContainerConfigFromUrl, getFileDataCacheKey } from '../../index';

import encodeAsPdfDataUrl from './encodeAsPdfDataUrl';
import getComputedMimeTypeFromFileInfo from './getComputedMimeTypeFromFileInfo';
import {
  FileContainerProps,
  FileContainerPropsWithoutUrlAndType,
} from './getContainerConfigFromUrl';
import getResponseCache from './getResponseCache';
import doesDocumentPreviewApiSupportFile from './mimeTypes/doesDocumentPreviewApiSupportFile';
import isNativelySupportedMimeType from './mimeTypes/isNativelySupportedMimeType';
import QueuedTaskRunner from './QueuedTaskRunner';

type FileInstanceId = { space: string; externalId: string };

// NOTE: CDF file URLs expire after 60 minutes when extendedExpiration is set to true
export const DEFAULT_CACHE_AUTO_EXPIRE_MS = 59 * 60 * 1000;
const BATCH_WINDOW_MS = 100;
const BATCH_MAX_SIZE = 100;

// The document preview API has a rate limit of 20 requests per second. As
// such, we limit the number of concurrent requests to 4 to avoid hitting the
// rate limit
const PREVIEW_URL_SEMAPHORE = new QueuedTaskRunner<() => Promise<ArrayBuffer>>(
  4
);

/**
 * Shameful to get around the fact that the JS SDK doesn't support setting extendedExpiration as a query parameter for the request
 * https://cognitedata.atlassian.net/browse/AH-2564
 */
const batchedShamefulGetDownloadUrls = memoize((client: CogniteClient) =>
  create({
    fetcher: async (files: FileInfo[]) => {
      const fileIds = files.map(({ id }) => id);
      const response = await client.post<ItemsResponse<FileLink & InternalId>>(
        `/api/v1/projects/${client.project}/files/downloadlink`,
        {
          headers: { 'cdf-version': 'alpha' },
          data: { items: uniq(fileIds).map((id) => ({ id })) },
          params: { extendedExpiration: true },
        }
      );
      return response.data.items;
    },
    resolver: (links, query) => links.find(({ id }) => id === query.id),
    scheduler: windowedFiniteBatchScheduler({
      windowMs: BATCH_WINDOW_MS,
      maxBatchSize: BATCH_MAX_SIZE,
    }),
  })
);

const getFileInstanceInfo = async (
  client: CogniteClient,
  instanceId: FileInstanceId
): Promise<FileInfo> => {
  const response = await client.post<{ items: FileInfo[] }>(
    `/api/v1/projects/${client.project}/files/byids`,
    {
      headers: { 'cdf-version': 'alpha' },
      data: { items: [{ instanceId }] },
    }
  );

  if (response.data.items.length !== 1) {
    throw new Error(`Expected one file, got ${response.data.items.length}`);
  }
  return response.data.items[0];
};

const isFileInstanceId = (
  file: FileInfo | FileInstanceId
): file is FileInstanceId => 'space' in file && 'externalId' in file;

const getFileDownloadData = async (
  client: CogniteClient,
  file: FileInfo | FileInstanceId
): Promise<{
  url: string;
  mimeType: string | undefined;
  dataCacheKey: string;
}> => {
  const fileInfo = isFileInstanceId(file)
    ? await getFileInstanceInfo(client, file)
    : file;
  const mimeType = getComputedMimeTypeFromFileInfo(fileInfo);
  if (isNativelySupportedMimeType(mimeType)) {
    const link = await batchedShamefulGetDownloadUrls(client).fetch(fileInfo);
    if (link === undefined) {
      throw new Error(`Failed to get download link for file ${fileInfo.id}`);
    }
    return {
      url: link.downloadUrl,
      mimeType: getComputedMimeTypeFromFileInfo(fileInfo),
      dataCacheKey: getFileDataCacheKey(fileInfo),
    };
  }

  if (doesDocumentPreviewApiSupportFile(fileInfo)) {
    const { data: pdfBuffer } = await getResponseCache().fetchFromCustomSource(
      getFileDataCacheKey(fileInfo),
      async () => {
        return new Response(
          await client.documents.preview.documentAsPdf(fileInfo.id),
          { headers: { 'Content-Type': 'application/pdf' } }
        );
      }
    );
    return {
      url: encodeAsPdfDataUrl(pdfBuffer),
      mimeType: 'application/pdf',
      dataCacheKey: getFileDataCacheKey(fileInfo),
    };
  }

  throw new Error(`Unsupported mime type: ${mimeType}`);
};

const getContainerConfigFromFileInfo = async (
  client: CogniteClient,
  file: FileInfo | FileInstanceId,
  props: FileContainerPropsWithoutUrlAndType,
  autoExpireMs = DEFAULT_CACHE_AUTO_EXPIRE_MS
): Promise<FileContainerProps> => {
  const { url, mimeType, dataCacheKey } = await getFileDownloadData(
    client,
    file
  );
  return getContainerConfigFromUrl(
    () => Promise.resolve({ url, mimeType }),
    { ...props, dataCacheKey },
    {
      key: isFileInstanceId(file)
        ? `${file.space}-${file.externalId}`
        : `${file.id}`,
      autoExpireMs,
    }
  );
};

export default getContainerConfigFromFileInfo;
