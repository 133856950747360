import type { Action, Condition, ConditionalAction } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface Props {
  conditionalActionsToBeDeleted?: ConditionalAction[];
  conditionsToBeDeleted?: Condition[];
  actionsToBeDeleted?: Action[];
}

export const useConditionalActionDelete = () => {
  const { conditionalActionsService, conditionsService, actionsService } =
    useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.conditions);
  const conditionalActionAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditConditionalAction
  );
  const conditionAuditMetrics = useMetrics(METRICS_NAMESPACES.auditCondition);
  const actionAuditMetrics = useMetrics(METRICS_NAMESPACES.auditAction);

  return useMutation<Props, Error, string[]>(
    async (conditionalActionsExternalIds) => {
      const {
        conditionalActions: conditionalActionsToBeDeleted,
        conditions: conditionsToBeDeleted,
        actions: actionsToBeDeleted,
      } = await conditionalActionsService.getConditionalActions(
        conditionalActionsExternalIds
      );

      // Delete actions
      if (actionsToBeDeleted && actionsToBeDeleted.length > 0) {
        await actionsService.deleteActions(
          actionsToBeDeleted.map((action) => action.externalId)
        );
      }

      // Delete conditions
      if (conditionsToBeDeleted && conditionsToBeDeleted.length > 0) {
        await conditionsService.deleteConditions(
          conditionsToBeDeleted.map((condition) => condition.externalId)
        );
      }

      // Delete conditional actions
      if (
        conditionalActionsToBeDeleted &&
        conditionalActionsToBeDeleted.length > 0
      ) {
        await conditionalActionsService.deleteConditionalActions(
          conditionalActionsToBeDeleted.map(
            (conditionalAction) => conditionalAction.externalId
          )
        );
      }

      return {
        conditionalActionsToBeDeleted,
        conditionsToBeDeleted,
        actionsToBeDeleted,
      };
    },
    {
      onError: (err) => {
        makeToast({
          type: 'danger',
          body: t(
            'CONDITIONAL_ACTION_DELETE_MUTATION_FAILED',
            'Failed to delete condition'
          ),
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.CONDITIONAL_ACTION_DELETE,
          },
        });
      },
      onSuccess: async ({
        conditionalActionsToBeDeleted,
        conditionsToBeDeleted,
        actionsToBeDeleted,
      }) => {
        queryClient.invalidateQueries([QueryKeys.TEMPLATE]);
        conditionalActionsToBeDeleted?.forEach((conditionalAction) => {
          conditionalActionAuditMetrics.track('Delete', {
            conditionalAction,
          });

          conditionsToBeDeleted?.forEach((condition) => {
            conditionAuditMetrics.track('Delete', {
              condition,
            });
          });

          actionsToBeDeleted?.forEach((action) => {
            actionAuditMetrics.track('Delete', {
              action,
            });
          });
        });
      },
      mutationKey: [MutationKeys.CONDITIONAL_ACTION_DELETE],
    }
  );
};
