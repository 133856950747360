import type { InFieldLocationConfig } from '@cognite/apm-client';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

export const useAllInFieldLocationConfigs = () => {
  const { apmClient } = useFDMServices();

  return useQuery<InFieldLocationConfig[], Error>(
    [QueryKeys.INFIELD_LOCATION_CONFIG, 'all'],
    async () => {
      const response = await apmClient.infieldLocationConfig.list(
        `
      externalId
      rootLocationExternalId
      featureToggles
    `
      );
      return response.list.items;
    }
  );
};
