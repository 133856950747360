import { useThreeDModelsConfig } from '@infield/features/3d/hooks';
import type { ThreeDConfiguration } from '@infield/features/app-config';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';

// returns list of model ids that have asset mappings
// side effect: updates cache for individual asset mappings queries (asset mappings for one 3d model)
export const useAssetModelsClassic = <TData = ThreeDConfiguration>(
  assetId?: number,
  options?: UseQueryOptions<ThreeDConfiguration, unknown, TData>
) => {
  const assetEnv3DModels = useThreeDModelsConfig();
  const { threeDService } = useFDMServices();

  return useQuery<ThreeDConfiguration, unknown, TData>(
    [
      QueryKeys.THREE_D,
      'all-asset-mappings-by-asset-id',
      assetId,
      assetEnv3DModels,
    ],
    async () => {
      const modelsWithMappings: ThreeDConfiguration = { fullWeightModels: [] };

      if (!assetId) return modelsWithMappings;

      const {
        data: { items },
      } = await threeDService.getClassicAssetModels(assetId);

      return {
        fullWeightModels: items
          // filter out models that are not in the config list
          .filter(({ modelId, revisionId }) =>
            // if no config, return all models, making config optional
            assetEnv3DModels && assetEnv3DModels.fullWeightModels.length > 0
              ? assetEnv3DModels.fullWeightModels.some(
                  (model) =>
                    model.modelId === modelId && model.revisionId === revisionId
                )
              : true
          ),
      };
    },
    {
      onError: (err) => captureException(err),
      enabled: Boolean(assetId),
      staleTime: Infinity,
      ...options,
    }
  );
};
