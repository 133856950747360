import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const AXIS_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';

const formatDate = (date: Date): string => {
  return dayjs(date).utc().format(AXIS_DATE_FORMAT);
};

export default formatDate;
