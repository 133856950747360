import { NotificationDetails } from '@infield/features/notifications';
import { useBackButtonUrlContext } from '@infield/providers/back-button-url-provider';
import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const NotificationDetailsView: FC = () => {
  const { notificationId } = useParams();
  const navigate = useNavigate();
  const { navigateBack } = useBackButtonUrlContext();

  const handleOnAssetClick = (assetExternalId: string) => {
    navigate(`/asset/${encodeURIComponent(assetExternalId)}`);
  };

  if (notificationId)
    return (
      <NotificationDetails
        notificationId={notificationId}
        onAssetClick={handleOnAssetClick}
        onClose={() => navigateBack()}
      />
    );
  return <div>Notification details for: {notificationId} is not available</div>;
};
