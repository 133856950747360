import type { Node } from 'konva/lib/Node';

import type { UnifiedViewer } from '../../UnifiedViewer';

import {
  type ActiveInteraction,
  startContainerAttachmentInteraction,
} from './containerAttachmentInteraction';

/**
 * Helper class to manage the container attachment interaction
 *
 * See {@link startContainerAttachmentInteraction} for more details.
 */
export class ContainerAttachmentHelper {
  private interaction: ActiveInteraction | null = null;

  public constructor(private unifiedViewer: UnifiedViewer) {}

  public start = (annotationsToKeepOnTop?: Node[]): void => {
    this.interaction?.end();
    this.interaction = startContainerAttachmentInteraction(
      this.unifiedViewer,
      annotationsToKeepOnTop
    );
  };

  public get isActive(): boolean {
    return this.interaction !== null;
  }

  public lockTarget: ActiveInteraction['lockTarget'] = () => {
    this.interaction?.lockTarget();
  };

  public end: ActiveInteraction['end'] = (annotationsToAttachOrDetach) => {
    this.interaction?.end(annotationsToAttachOrDetach);
    this.interaction = null;
  };

  public onDestroy = (): void => {
    this.end();
  };
}
