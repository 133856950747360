import { Chart, ChartSource } from '../types';

export function updateWorkflow<T extends ChartSource>(
  chart: Chart,
  id: string,
  update: Partial<T>
): Chart {
  return {
    ...chart,
    workflowCollection: chart?.workflowCollection?.map((wf) =>
      wf.id === id ? { ...wf, ...update } : wf
    ),
  };
}
