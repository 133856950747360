import { Heading, Infobox, Skeleton } from '@cognite/cogs.js-v10';
import {
  useAssetDocumentsQuery,
  useClassicOrAPMAssetQuery,
} from '@infield/features/asset';
import { AssetDocumentList } from '@infield/features/asset/asset-documents';
import * as S from '@infield/features/asset/elements';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { getTitle } from '@infield/features/search/search-result/utils';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  assetExternalId?: string;
}

export const AssetDocumentsView: FC<Props> = ({ assetExternalId }) => {
  const { assetId: assetExternalIdFromUrl } = useParams();
  const asset = assetExternalId || assetExternalIdFromUrl;
  const { t } = useTranslation(LOCIZE_NAMESPACES.documents);
  const { data: assetData, isInitialLoading: isAssetLoading } =
    useClassicOrAPMAssetQuery(asset!);
  const { data: documents, isInitialLoading: isDocumentsLoading } =
    useAssetDocumentsQuery(asset);

  if (!assetData) return null;

  if (isAssetLoading || isDocumentsLoading)
    return <Skeleton.List lines={4} borders />;

  if (!asset)
    return (
      <Infobox status="warning">
        {t(
          'ASSET_DOCUMENTS_WARNING_NO_ASSET_ID',
          'No valid asset id was passed in URL'
        )}
      </Infobox>
    );

  if (!assetData)
    return (
      <Infobox status="warning">
        {t(
          'ASSET_DOCUMENTS_WARNING_NO_ASSET_DATA',
          'Did not find any data for the given asset id'
        )}
      </Infobox>
    );

  return (
    <>
      {!assetExternalId && (
        <S.Header justifyContent="space-between">
          <S.AssetInformation direction="column">
            <Heading level={6}>{getTitle(assetData)}</Heading>
            <S.AssetDescription>{assetData.description}</S.AssetDescription>
          </S.AssetInformation>
        </S.Header>
      )}
      {documents && documents.length > 0 ? (
        <AssetDocumentList documents={documents} />
      ) : (
        <Infobox>
          {t(
            'ASSET_DOCUMENTS_WARNING_NO_DOCUMENTS_FOUND',
            'No documents found'
          )}
        </Infobox>
      )}
    </>
  );
};
