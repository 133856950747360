import { Modal } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const StyledModal = styled(Modal).attrs({
  size: 'full-screen',
})`
  .cogs-modal__content {
    height: 100%;
  }

  .cogs-modal-cancel-button,
  .cogs-modal-ok-button {
    display: none;
  }
`;
