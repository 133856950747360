import React from 'react';

type ErrorContentProps = {
  width: number;
  height: number;
};

const PADDING_MULTIPLIER = 0.1;

const ErrorContent: React.FC<ErrorContentProps> = ({ width, height }) => (
  <div
    style={{
      position: 'relative',
      boxSizing: 'border-box',
      overflow: 'hidden',
      width,
      height,
      pointerEvents: 'none',
      color: 'red',
      border: 'red 2px solid',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '5px',
      padding: `${width * PADDING_MULTIPLIER}px`,
    }}
  >
    <div
      style={{
        fontFamily: 'sans-serif',
        fontSize: `12px`,
        overflow: 'hidden',
        cursor: 'default',
        pointerEvents: 'all',
      }}
    >
      There was an issue loading this content. Please try again. If the issue
      persists, please contact support.
    </div>
  </div>
);

export default ErrorContent;
