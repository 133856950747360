import styled from 'styled-components';

import { FullScreenIcon, PauseIcon, PlayIcon } from '@cognite/cogs.js-v10';

import Seekbar from './Seekbar';
import TimeIndicator from './TimeIndicator';

const VideoControls: React.FC<{
  videoRef: HTMLVideoElement;
  isPlaying: boolean;
  currentTimeSeconds: number;
  duration: number;
  onSeek: (timeSeconds: number) => void;
}> = ({ isPlaying, videoRef, duration, currentTimeSeconds, onSeek }) => {
  const playPause = () => {
    if (videoRef.paused) {
      videoRef.play();
    } else {
      videoRef.pause();
    }
  };

  return (
    <Container>
      <Row>
        <PlayPauseButton onClick={playPause}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </PlayPauseButton>
        <Seekbar
          currentTime={currentTimeSeconds}
          duration={duration}
          onSeek={onSeek}
          videoRef={videoRef}
        />
        <TimeIndicator currentTimeSeconds={currentTimeSeconds} />
        <FullscreenButton onClick={() => videoRef.requestFullscreen()}>
          <FullScreenIcon />
        </FullscreenButton>
      </Row>
    </Container>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > * {
    margin: 0 4px;
  }

  & > *:first-child {
    margin-left: 0;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

const PlayPauseButton = styled.button`
  background-color: gray;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  opacity: 0.7;

  &:hover {
    opacity: 1;
    color: white;
  }
`;

const FullscreenButton = styled.button`
  background-color: gray;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
    color: white;
  }
`;

const Container = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 8px;
  left: 8px;
  right: 8px;
  flex-direction: row;
`;

export default VideoControls;
