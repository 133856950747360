import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { useDebounce } from 'use-debounce';

import { DatapointsMultiQuery } from '@cognite/sdk-alpha';

import { CHART_POINTS_PER_SERIES } from '../constants';
import { useScheduledCalculationTargetTimeSeries } from '../hooks/useScheduledCalculationTargetTimeSeries';
import { useScheduledCalculationTasks } from '../hooks/useScheduledCalculationTasks';
import {
  ScheduledCalculationTask,
  useChartAtom,
  useScheduledCalculationData,
} from '../models';
import { fetchRawOrAggregatedDatapoints } from '../services/fetch-raw-or-aggregated-datapoints-alpha';
import { calculateGranularity } from '../utils';
import { useAlphaSDK } from '../utils/alphaSDKProvider';

export const ScheduledCalculationCollectionEffects = ({
  aggregationDataPointLimit,
}: {
  aggregationDataPointLimit: number;
}) => {
  const [chart] = useChartAtom();
  const taskExternalIds = chart?.scheduledCalculationCollection?.map(
    (scheduledCalculation) => scheduledCalculation.id
  );

  const { data: tasks } = useScheduledCalculationTasks(
    taskExternalIds?.map((externalId) => ({ externalId })) || []
  );

  const scheduledCalculationElements = tasks?.map((task) => (
    <ScheduledCalculationEffects
      key={task.externalId}
      task={task}
      aggregationDataPointLimit={aggregationDataPointLimit}
    />
  ));

  return <>{scheduledCalculationElements}</>;
};

export const ScheduledCalculationEffects = ({
  task,
  aggregationDataPointLimit,
}: {
  task: ScheduledCalculationTask;
  aggregationDataPointLimit: number;
}) => {
  const [chart] = useChartAtom();
  const { dateFrom, dateTo } = chart!;
  const [, setScheduledCalculationData] = useScheduledCalculationData();
  const sdk = useAlphaSDK();

  const { data: targetTimeSeries } =
    useScheduledCalculationTargetTimeSeries(task);

  const [debouncedRange] = useDebounce({ dateFrom, dateTo }, 50, {
    equalityFn: (l, r) => isEqual(l, r),
  });

  const query: DatapointsMultiQuery = {
    items:
      targetTimeSeries && 'space' in targetTimeSeries
        ? [
            {
              //@ts-expect-error SDK hasn't been updated yet..
              instanceId: {
                space: targetTimeSeries?.space,
                externalId: targetTimeSeries?.externalId,
              },
            },
          ]
        : [
            {
              externalId: targetTimeSeries?.externalId,
            },
          ],

    start: dayjs(debouncedRange.dateFrom!).toDate(),
    end: dayjs(debouncedRange.dateTo!).toDate(),
    granularity: calculateGranularity(
      [
        dayjs(debouncedRange.dateFrom!).valueOf(),
        dayjs(debouncedRange.dateTo!).valueOf(),
      ],
      CHART_POINTS_PER_SERIES
    ),
    aggregates: ['average', 'min', 'max', 'count', 'sum'],
    limit: CHART_POINTS_PER_SERIES,
  };

  const {
    data: timeseriesData,
    isFetching,
    isSuccess,
  } = useQuery(
    ['chart-data', 'scheduled-calculation', query],
    () => fetchRawOrAggregatedDatapoints(sdk, query, aggregationDataPointLimit),
    {
      enabled: !!targetTimeSeries,
    }
  );

  useEffect(() => {
    if (targetTimeSeries) {
      setScheduledCalculationData((scheduledCalculationData) => {
        return {
          ...scheduledCalculationData,
          [task.externalId]: {
            ...task,
            loading: isFetching,
            series: isSuccess
              ? timeseriesData
              : scheduledCalculationData?.[targetTimeSeries.externalId!]
                  ?.series,
          },
        };
      });
    }
  }, [
    isSuccess,
    isFetching,
    timeseriesData,
    setScheduledCalculationData,
    targetTimeSeries,
  ]);

  return null;
};
