import { ImageContainerProps } from '../containers/ImageContainer';
import { Metadata } from '../types';

import { ChartsContainerProps } from './ChartsContainer';
import { DocumentContainerProps } from './DocumentContainer';
import { PropertyListContainerProps } from './PropertyListContainer';
import { RevealContainerProps } from './RevealContainer';
import { TableContainerProps } from './TableContainer';
import { TextContainerProps } from './TextContainer';
import { TimeseriesContainerProps } from './TimeseriesContainer';
import { VideoContainerProps } from './VideoContainer/types';

//
export type ContainerConfig<MetadataType = any> = Metadata<MetadataType> &
  (
    | PropertyListContainerProps<MetadataType>
    | TimeseriesContainerProps<MetadataType>
    | ChartsContainerProps<MetadataType>
    | RevealContainerProps<MetadataType>
    | DocumentContainerProps<MetadataType>
    | ImageContainerProps<MetadataType>
    | TextContainerProps<MetadataType>
    | TableContainerProps<MetadataType>
    | VideoContainerProps<MetadataType>
  );

// Remember to update `getSupportedMimeTypeFromUrl` in `getContainerConfigFromUrl.ts` if you add a new mime type
export const DocumentMimeType = {
  PDF: 'application/pdf',
} as const;

export const ImageMimeType = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  SVG: 'image/svg+xml',
  TIFF: 'image/tiff',
  WEBP: 'image/webp',
} as const;

export const TextMimeType = {
  TXT: 'text/plain',
  CSV: 'text/csv',
  JSON: 'application/json',
} as const;

export const VideoMimeType = {
  MP4: 'video/mp4',
  WEBM: 'video/webm',
} as const;
