import type { Measurement } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import type { ExternalDatapoint } from '@cognite/sdk';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserQuery } from '@infield/features/user';
import { useFDMServices } from '@infield/providers/fdm-services';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type MutationContext = {
  cachedIsOnline: boolean;
};

export const useMeasurementNumericReadingUpsert = () => {
  const { measurementsService, timeseriesService } = useFDMServices();
  const queryClient = useQueryClient();
  const { isOnline } = useNetworkStatusContext();
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);
  const { data: user } = useCurrentUserQuery();
  const metrics = useMetrics(METRICS_NAMESPACES.auditMeasurement);

  return useMutation<
    { measurement: Measurement; onMeasurementChange?: () => void },
    Error,
    {
      measurement: Measurement;
      datapointExternalId: string;
      previousMeasureTimestamp?: number;
      newDatapoint: ExternalDatapoint | null;
      onMeasurementChange?: () => void;
    },
    MutationContext
  >(
    async ({
      measurement,
      datapointExternalId,
      previousMeasureTimestamp,
      newDatapoint,
      onMeasurementChange,
    }) => {
      // if previous datapoint was made, delete it
      if (previousMeasureTimestamp) {
        await timeseriesService.deleteDataPoint(
          datapointExternalId,
          previousMeasureTimestamp
        );
      }

      try {
        if (newDatapoint) {
          await timeseriesService.insertDataPoint(
            datapointExternalId,
            newDatapoint
          );
        }
        await measurementsService.updateMeasurements([measurement], user!);
        return { measurement, onMeasurementChange };
      } catch (error) {
        throw new Error(String(error));
      }
    },
    {
      onMutate: ({ onMeasurementChange }) => {
        if (!isOnline && onMeasurementChange) {
          onMeasurementChange();
        }

        return {
          cachedIsOnline: isOnline,
        };
      },
      onError: (err) => {
        makeToast({
          body: t('READING_REQUEST_UPDATE_ERROR', 'Failed to update reading'),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.USE_MEASURMENT_NUMERIC_READING_UPSERT,
          },
        });
      },
      onSuccess: async ({ measurement, onMeasurementChange }, _, context) => {
        metrics.track('Update', measurement);

        // cachedIsOnline is used to check if the user was online when the mutation was triggered
        if (context?.cachedIsOnline) {
          await queryClient.invalidateQueries({
            queryKey: [QueryKeys.TIMESERIES],
          });
          await queryClient.invalidateQueries({
            queryKey: [QueryKeys.CHECKLIST],
          });

          if (onMeasurementChange) {
            onMeasurementChange();
          }
        }
      },
      mutationKey: [MutationKeys.USE_MEASURMENT_NUMERIC_READING_UPSERT],
      retry: 10,
      retryDelay: 2000,
    }
  );
};
