import type {
  Action,
  APMAsset,
  Condition,
  Measurement,
  TaskInterval,
  TemplateItem,
} from '@cognite/apm-client';
import {
  Body,
  Button,
  ChevronDownSmallIcon,
  ConditionIcon,
  Divider,
  Dropdown,
  EventsIcon,
  Flex,
  GaugeIcon,
  GridIcon,
  HelpIcon,
  Menu,
  StatusChip,
} from '@cognite/cogs.js-v10';
import { useMetrics } from '@cognite/metrics';
import { useAssetQuery } from '@infield/features/asset';
import {
  ConditionConfigurationList,
  useConditionalActionCreate,
} from '@infield/features/conditions';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  MeasurementLabelConfigurationList,
  MeasurementMessageConfigurationList,
  MeasurementNumericConfigurationList,
  useMeasurementCreate,
  useMeasurementDelete,
} from '@infield/features/measurements';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { AssetSearchInput } from '@infield/features/search';
import {
  isNewTemplateAtom,
  selectedTaskAtom,
  selectedTemplateAtom,
  showAssetDetailsAtom,
  useTemplateItemsCreate,
  useTemplateItemsUpdate,
} from '@infield/features/template';
import { DeleteModal } from '@infield/features/template/template-detail/delete-modal';
import { PanelHeader, useOnClickOutside } from '@infield/features/ui';
import { MutationKeys } from '@infield/utils/queryKeys';
import { useIsMutating } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { IntervalList, useIntervalsCreate } from '../interval-list';
import { getDefaultIntervalSettings } from '../interval-list/utils';
import { TaskTextarea } from '../task-textarea';

import * as S from './elements';

type Props = {
  task: TemplateItem;
  isEditable: boolean;
  isNewtask: boolean;
};

export const TaskForm: FC<Props> = ({ task, isEditable, isNewtask }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const selectedTemplate = useRecoilValue(selectedTemplateAtom);
  const setIsNewTemplate = useSetRecoilState(isNewTemplateAtom);
  const resetSelectedTask = useResetRecoilState(selectedTaskAtom);
  const resetShowAssetDetails = useResetRecoilState(showAssetDetailsAtom);
  const [taskName, setTaskName] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);
  const { data: taskTag } = useAssetQuery(task.asset?.externalId);
  const { mutateAsync: updateTemplateItems } = useTemplateItemsUpdate();
  const { mutateAsync: createTemplateItems } = useTemplateItemsCreate();
  const metrics = useMetrics(METRICS_NAMESPACES.templates);

  const measurementList = task.measurements?.filter(
    (measurement) => measurement.type === 'numerical'
  );
  const customReadingLabelList = task.measurements?.filter(
    (measurement) => measurement.type === 'label'
  );
  const messageList = task.measurements?.filter(
    (measurement) => measurement.type === 'message'
  );
  const conditionList = task.conditionalActions?.flatMap(
    (conditionalAction) => conditionalAction.conditions || []
  );
  const actionList = task.conditionalActions?.flatMap(
    (conditionalAction) => conditionalAction.actions || []
  );

  const { schedules: intervals } = task;

  const { mutateAsync: createMeasurement } = useMeasurementCreate();
  const { mutateAsync: deleteMeasurement } = useMeasurementDelete();
  const { mutateAsync: createIntervals } = useIntervalsCreate();
  const { mutateAsync: createCondition } = useConditionalActionCreate();

  const isTemplateItemsCreateLoading =
    useIsMutating([MutationKeys.TEMPLATE_ITEM_CREATE]) > 0;

  const isTemplateItemsUpsertLoading =
    useIsMutating([MutationKeys.TEMPLATE_ITEM_UPDATE]) > 0;

  const isMeasurementUpsertLoading =
    useIsMutating(['useMeasurementUpsert']) > 0;

  const isMeasurementDeleteMutating =
    useIsMutating(['useMeasurementDelete']) > 0;

  const isIntervalsUpsertLoading = useIsMutating(['useIntervalsUpsert']) > 0;

  const isIntervalDeleteMutating = useIsMutating(['useIntervalsDelete']) > 0;

  const isSaving =
    isTemplateItemsCreateLoading ||
    isTemplateItemsUpsertLoading ||
    isMeasurementUpsertLoading ||
    isMeasurementDeleteMutating ||
    isIntervalsUpsertLoading ||
    isIntervalDeleteMutating;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isTaskCreated = !isNewtask && !isTemplateItemsCreateLoading;

  useEffect(() => {
    if (!isTitleFocused) {
      setTaskName(task.title || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.title]);

  useEffect(() => {
    if (!isDescriptionFocused) {
      setTaskDescription(task.description || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.description]);

  const handleTemplateItemSave = <T extends keyof TemplateItem>(
    field: T,
    value: TemplateItem[T] | null
  ) => {
    if (!selectedTemplate) return;
    if (isNewtask) {
      createTemplateItems({
        newTemplateItems: [
          {
            externalId: task.externalId ?? uuid(),
            [field]: value ?? null,
            order: task.order ?? (selectedTemplate.templateItems?.length || 0),
          },
        ],
        templateExternalId: selectedTemplate.externalId,
      }).then(() => {
        setIsNewTemplate(false);
      });
    } else {
      updateTemplateItems({
        templateItemsToUpsert: [
          {
            externalId: task.externalId,
            [field]: value ?? null,
          },
        ],
      }).then(() => {
        setIsNewTemplate(false);
      });
    }
  };

  const handleOnAssetSelect = (selectedAsset?: APMAsset) => {
    handleTemplateItemSave('asset', selectedAsset);
  };

  const handleNameSave = () => {
    if (taskName === task.title) return;
    handleTemplateItemSave('title', taskName);
  };

  const handleDescriptionSave = () => {
    if (taskDescription === task.description) return;
    handleTemplateItemSave('description', taskDescription);
  };

  const handleAddNumericalReading = async () => {
    const newMeasurement: Measurement = {
      type: 'numerical',
      externalId: uuid(),
      order: task.measurements?.length || 0,
    };

    createMeasurement({
      newMeasurement,
      templateItemExternalId: task.externalId,
    });
  };

  const handleAddCustomLabels = async () => {
    const newMeasurement: Measurement = {
      type: 'label',
      externalId: uuid(),
      order: task.measurements?.length || 0,
      options: [{ label: '', id: uuid() }],
    };

    createMeasurement({
      newMeasurement,
      templateItemExternalId: task.externalId,
    });
  };

  const handleAddInterval = () => {
    const newInterval: TaskInterval = getDefaultIntervalSettings();

    createIntervals({
      newIntervals: [
        {
          taskExternalId: task.externalId,
          intervals: [newInterval],
        },
      ],
    });
  };

  const handleAddMessage = () => {
    const newMeasurement: Measurement = {
      type: 'message',
      externalId: uuid(),
      order: task.measurements?.length || 0,
    };

    createMeasurement({
      newMeasurement,
      templateItemExternalId: task.externalId,
    });
  };

  const handleAddCondition = () => {
    const newCondition: Condition = {
      externalId: uuid(),
      source: task,
      value: 'Not ok',
    };
    const newAction: Action = {
      externalId: uuid(),
    };

    createCondition({
      condition: newCondition,
      action: newAction,
    });
  };

  const handleCloseForm = async () => {
    await deleteMeasurement({
      taskExternalId: task.externalId,
      removeMeasurementsWithoutTimeseries: true,
    });

    resetSelectedTask();
    resetShowAssetDetails();
  };

  const taskComponentRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const handleOnClickOutside = (event: MouseEvent | TouchEvent) => {
    if (titleRef.current) {
      titleRef.current.blur();
      event.stopPropagation();
    }

    if (descriptionRef.current) {
      descriptionRef.current.blur();
      event.stopPropagation();
    }
  };

  useOnClickOutside(taskComponentRef, handleOnClickOutside);

  return (
    <S.TaskFormReferenceElement ref={taskComponentRef}>
      <S.TaskFormContainer>
        <PanelHeader
          titleTestId="task-form-header-title"
          closeButtonTestId="task-form-close-task-button"
          title={
            task.title || task.asset?.title
              ? t('TEMPLATE_TASK_FORM_TITLE_EDIT', 'Edit task')
              : t('TEMPLATE_TASK_FORM_TITLE_ADD', 'Add task')
          }
          description={task.title}
          onCloseClick={() => {
            handleCloseForm();
            metrics.track('TaskForm_CloseButton_Clicked');
          }}
        />
        <S.ScrollableContentWithOverlay>
          <S.FormContentWrapper>
            <S.FormContent>
              <S.TaskFormBlockWrapper>
                <Flex direction="column" gap={6}>
                  <Body size="medium" strong data-testid="task-form-asset">
                    {t('TEMPLATE_TASK_FORM_TAG', 'Asset')}
                  </Body>
                  <AssetSearchInput
                    selected={taskTag}
                    onSelect={(_, apmAsset) => handleOnAssetSelect(apmAsset)}
                  />
                </Flex>
              </S.TaskFormBlockWrapper>
              <S.TaskFormBlockWrapper>
                <Flex direction="column" gap={6}>
                  <Body size="medium" strong>
                    {t('TEMPLATE_TASK_FORM_NAME', 'Task name')}
                  </Body>
                  <S.TaskTitleInput
                    ref={titleRef}
                    fullWidth
                    data-testid="task-form-title-input"
                    name="Name"
                    placeholder={t(
                      'TEMPLATE_TASK_FORM_NAME_PLACEHOLDER',
                      'Enter short task description'
                    )}
                    value={taskName}
                    onChange={(e) => setTaskName(e.currentTarget.value)}
                    onFocus={() => setIsTitleFocused(true)}
                    onBlur={() => {
                      handleNameSave();
                      setIsTitleFocused(false);
                    }}
                  />
                </Flex>
              </S.TaskFormBlockWrapper>
              <S.TaskFormBlockWrapper>
                <Flex direction="column" gap={6}>
                  <Body
                    size="medium"
                    strong
                    data-testid="task-form-description"
                  >
                    {t('TEMPLATE_TASK_FORM_DESCRIPTION_FIELD', 'Description')}
                  </Body>
                  <TaskTextarea
                    ref={descriptionRef}
                    value={taskDescription}
                    onChange={setTaskDescription}
                    onBlur={() => {
                      handleDescriptionSave();
                      setIsDescriptionFocused(false);
                    }}
                    onFocus={() => setIsDescriptionFocused(true)}
                    placeholder={t(
                      'TEMPLATE_TASK_FORM_DESCRIPTION_PLACEHOLDER',
                      'Describe what needs to be done to complete the task'
                    )}
                    characterLimitMessage={t(
                      'TEMPLATE_TASK_FORM_DESCRIPTION_CHARACTER_LIMIT_MESSAGE',
                      'Character limit reached'
                    )}
                    characterLimit={3000}
                  />
                </Flex>
              </S.TaskFormBlockWrapper>
              {measurementList && measurementList?.length > 0 && (
                <>
                  <Divider length="100%" />
                  <MeasurementNumericConfigurationList
                    task={task}
                    taskTag={taskTag}
                  />
                </>
              )}
              {customReadingLabelList && customReadingLabelList.length > 0 && (
                <>
                  <Divider length="100%" />
                  <MeasurementLabelConfigurationList task={task} />
                </>
              )}
              {intervals && intervals.length > 0 && (
                <>
                  <Divider length="100%" />
                  <IntervalList
                    taskExternalId={task.externalId}
                    intervals={intervals!}
                  />
                </>
              )}
              {messageList && messageList.length > 0 && (
                <>
                  <Divider length="100%" />
                  <MeasurementMessageConfigurationList
                    task={task}
                    messageList={messageList}
                  />
                </>
              )}
              {task.conditionalActions &&
                conditionList &&
                actionList &&
                conditionList.length > 0 && (
                  <>
                    <Divider length="100%" />
                    <ConditionConfigurationList
                      conditionalActionList={task.conditionalActions}
                      conditionList={conditionList}
                      actionList={actionList}
                    />
                  </>
                )}
            </S.FormContent>
            {!isEditable && (
              <S.LockedOverlay data-testid="task-form-locked-overlay" />
            )}
          </S.FormContentWrapper>
        </S.ScrollableContentWithOverlay>
        {isEditable && (
          <S.FormActions>
            <Flex gap={8} justifyContent="space-between">
              {isTaskCreated && (
                <Dropdown
                  hideOnSelect={{
                    hideOnContentClick: true,
                    hideOnOutsideClick: true,
                  }}
                  content={
                    <S.ComponentMenu>
                      <Menu.Header data-testid="task-form-add-component-header">
                        {t(
                          'TEMPLATE_TASK_FORM_COMPONENT_MENU_TITLE',
                          'Select an option'
                        )}
                      </Menu.Header>
                      <Menu.Item
                        data-testid="task-form-add-component-numerical-reading-button"
                        icon={<GaugeIcon />}
                        iconPlacement="left"
                        onClick={handleAddNumericalReading}
                      >
                        {t(
                          'TEMPLATE_TASK_FORM_COMPONENT_MENU_ACTION_NUMERICAL_READING',
                          'Numerical reading'
                        )}
                      </Menu.Item>
                      <Menu.Item
                        data-testid="task-form-add-component-label-reading-button"
                        icon={<GridIcon />}
                        iconPlacement="left"
                        onClick={handleAddCustomLabels}
                      >
                        {t(
                          'TEMPLATE_TASK_FORM_COMPONENT_MENU_ACTION_CUSTOM_READING_LABELS',
                          'Check item'
                        )}
                      </Menu.Item>
                      <Menu.Item
                        icon={<EventsIcon />}
                        iconPlacement="left"
                        onClick={handleAddInterval}
                        data-testid="task-options-menu-interval-option"
                      >
                        {t(
                          'TEMPLATE_TASK_FORM_COMPONENT_MENU_ACTION_TASK_SCHEDULE',
                          'Schedule'
                        )}
                      </Menu.Item>
                      <Menu.Item
                        icon={<HelpIcon />}
                        iconPlacement="left"
                        onClick={handleAddMessage}
                      >
                        {t(
                          'TEMPLATE_TASK_FORM_COMPONENT_MENU_ACTION_MESSAGE',
                          'Message'
                        )}
                      </Menu.Item>
                      <Menu.Item
                        icon={<ConditionIcon />}
                        iconPlacement="left"
                        onClick={handleAddCondition}
                      >
                        {t(
                          'TEMPLATE_TASK_FORM_COMPONENT_MENU_ACTION_CONDITION',
                          'Condition'
                        )}
                      </Menu.Item>
                    </S.ComponentMenu>
                  }
                >
                  <Button
                    data-testid="task-form-add-component-button"
                    type="secondary"
                    icon={<ChevronDownSmallIcon />}
                    iconPlacement="right"
                  >
                    {t(
                      'TEMPLATE_TASK_FORM_BUTTON_EDIT_COMPONENTS',
                      'Edit components'
                    )}
                  </Button>
                </Dropdown>
              )}
              {isTaskCreated && (
                <StatusChip
                  status={isSaving ? 'neutral' : 'success'}
                  variant="ghost"
                  label={
                    isSaving
                      ? t('TEMPLATE_TASK_FORM_SAVING', 'Saving...')
                      : t('TEMPLATE_TASK_FORM_SAVED', 'Saved')
                  }
                />
              )}
            </Flex>
          </S.FormActions>
        )}
        {showDeleteModal && (
          <DeleteModal
            tasks={[task]}
            subtitle={task.title}
            closeModal={() => setShowDeleteModal(false)}
            onSubmit={() => {
              resetSelectedTask();
              resetShowAssetDetails();
            }}
          />
        )}
      </S.TaskFormContainer>
    </S.TaskFormReferenceElement>
  );
};
