import { AnnotationType } from './annotations/types';
import { UnifiedViewerNode } from './types';

const getUnifiedViewerNodesWithOffset = <T extends UnifiedViewerNode>(
  nodes: T,
  offset: {
    x: number;
    y: number;
  }
): T => {
  if (nodes.type === AnnotationType.POLYLINE) {
    if (nodes.vertices !== undefined) {
      return {
        ...nodes,
        vertices: nodes.vertices.map((vertex) => ({
          ...vertex,
          x: vertex.x + offset.x,
          y: vertex.y + offset.y,
        })),
      };
    }
    return nodes;
  }

  return {
    ...nodes,
    x: (nodes.x ?? 0) + offset.x,
    y: (nodes.y ?? 0) + offset.y,
  };
};

export default getUnifiedViewerNodesWithOffset;
