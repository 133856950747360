import { AssetHeader, useAssetQuery } from '@infield/features/asset';
import { AssetNotificationsView } from '@infield/features/asset/asset-notifications';
import { ComponentScrollableContent } from '@infield/features/ui';
import { useBackButtonUrlContext } from '@infield/providers/back-button-url-provider';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import * as S from './elements';

export const AssetNotifications: FC = () => {
  const { assetId } = useParams();
  const { navigateBack } = useBackButtonUrlContext();
  const { data: assetData } = useAssetQuery(assetId);

  const handleClose = () => {
    navigateBack();
  };

  return (
    <S.Container>
      {assetData && (
        <AssetHeader
          canBeExpanded={false}
          onClose={handleClose}
          assetData={assetData}
        />
      )}
      <ComponentScrollableContent>
        {assetId && <AssetNotificationsView assetExternalId={assetId} />}
      </ComponentScrollableContent>
    </S.Container>
  );
};
