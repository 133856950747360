import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { Status } from '@cognite/calculation-backend';
import { useSDK } from '@cognite/sdk-provider';

import { fetchCalculationStatus } from '../services';

export const useCalculationStatus = (
  id: string | number,
  queryOpts?: UseQueryOptions<Status>
) => {
  const sdk = useSDK();
  return useQuery<Status>(
    ['calculation', 'status', id],
    () => fetchCalculationStatus(sdk, String(id)),
    {
      ...queryOpts,
      retry: 1,
      retryDelay: 1000,
      enabled: !!id,
    }
  );
};
