import type { InFieldUser, InFieldUserPreferences } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useAuthContext } from '@cognite/e2e-auth';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

/**
 * Registers the current user and links to its user preferences.
 *
 * @remarks
 * For just upserting user preferences, use "useUpsertUserPreferences".
 */

export const useRegisterCurrentUser = () => {
  const { apmClient } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const { authState } = useAuthContext();

  return useMutation<
    InFieldUser,
    Error,
    { preferences?: InFieldUserPreferences }
  >(
    async ({ preferences }) => {
      const userId = authState.user.cdfId;
      if (!userId) {
        throw new Error('User profile not found');
      }

      await apmClient.userPreferences.upsert([
        {
          ...preferences,
          externalId: userId,
        },
      ]);
      const {
        data: {
          items: [user],
        },
      } = await apmClient.users.upsert([
        {
          externalId: userId,
          email: authState.user.email || '',
          name: authState.user.name || 'Missing name',
          preferences: {
            externalId: userId,
            space: apmClient.userInstanceSpace,
          },
        },
      ]);

      return user as InFieldUser;
    },
    {
      onError: (err) => {
        makeToast({
          type: 'danger',
          body: t('failedToUpdateUser', 'Failed to update user data'),
        });
        captureException(err, {
          level: 'fatal',
          tags: {
            mutationKey: 'use-register-current-user',
          },
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.USER]);
      },
    }
  );
};
