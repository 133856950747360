import type { TemplateItem } from '@cognite/apm-client';
import { Flex, Heading, InfoIcon, Tooltip } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { TaskFormBlockTitleWrapper } from '@infield/features/task/task-form/elements';
import type { FC } from 'react';

import { MeasurementLabelConfiguration } from '../measurement-label-configuration/measurement-label-configuration';

interface Props {
  task: TemplateItem;
}

export const MeasurementLabelConfigurationList: FC<Props> = ({ task }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);
  const measurementsList = task.measurements?.filter(
    (measurement) => measurement.type === 'label'
  );

  return (
    <Flex direction="column" gap={12}>
      <TaskFormBlockTitleWrapper>
        <Heading level={6} data-testid="task-form-label-reading-list-header">
          {t(
            'TEMPLATE_TASK_FORM_CUSTOM_READING_LABEL_EDIT_TITLE',
            'Check item'
          )}
        </Heading>
        <Tooltip
          content={t(
            'TEMPLATE_TASK_FORM_CUSTOM_READING_LABEL_EDIT_INFO',
            'Add an extra item to check or verify with an action button.'
          )}
        >
          <InfoIcon />
        </Tooltip>
      </TaskFormBlockTitleWrapper>
      {measurementsList?.map((measurement) => (
        <MeasurementLabelConfiguration
          key={measurement.externalId}
          measurement={measurement}
          taskExternalId={task.externalId}
        />
      ))}
    </Flex>
  );
};
