import { Body, Flex, Switch } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  title: string;
  subTitle?: string;
  dataTestId?: string;
  isLoading: boolean;
  isUpdated?: boolean;
  checked?: boolean;
  hasAppConfigPermission: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const ToggleRow: FC<Props> = ({
  title,
  subTitle,
  dataTestId,
  isLoading,
  isUpdated = false,
  checked,
  hasAppConfigPermission,
  onChange,
}) => {
  return (
    <Flex direction="column" gap={8}>
      <S.ToggleRowHeading $isUpdated={isUpdated}>
        {title} {isUpdated && '(updated)'}
      </S.ToggleRowHeading>
      {subTitle && <Body size="x-small">{subTitle}</Body>}
      <Switch
        data-testid={dataTestId}
        onChange={onChange}
        checked={checked || false}
        disabled={!hasAppConfigPermission || isLoading}
      />
    </Flex>
  );
};
