import React, { Component, ComponentType, forwardRef, memo } from 'react';

import { shallowCompareProperties } from '../../utils/compare';

import { PlotWrapper } from './elements';
import { useDebouncePropsOnNavigation } from './navigation-safe-plot-hooks';

export function createNavigationSafePlotComponent<P>(
  plotlyComponent: ComponentType<P>
) {
  const MemoizedPlot = memo(plotlyComponent, shallowCompareProperties);

  const SafePlotlyComponent = forwardRef(
    (props: React.PropsWithoutRef<P>, ref: React.Ref<Component<P>>) => {
      const debouncedPlotProps = useDebouncePropsOnNavigation(props);

      return (
        <PlotWrapper>
          <MemoizedPlot {...debouncedPlotProps} ref={ref} />
        </PlotWrapper>
      );
    }
  );

  return SafePlotlyComponent;
}
