import { Heading } from '@cognite/cogs.js-v10';

import * as S from './elements';

export const FallbackContent = () => {
  return (
    <S.StyledFlex
      role="alert"
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Heading level={1}>Something went wrong.</Heading>
      <br />
      <p>
        You have encountered an internal error. We have been notified, but
        please reach out to{' '}
        <a href="mailto:support@cognite.com">support@cognite.com</a> if you are
        stuck.
      </p>
      <p>Please reload the page to try again.</p>
      <S.StyledReloadButton
        type="primary"
        onClick={() => window.location.reload()}
      >
        Reload
      </S.StyledReloadButton>
    </S.StyledFlex>
  );
};
