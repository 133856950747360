import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';

import { ToolType } from '../tools/types';
import { UnifiedViewer } from '../UnifiedViewer';
import type { UnifiedViewerPointerEvent } from '../UnifiedViewerRenderer/UnifiedEventHandler';
import mapEventForMobile from '../utils/mapEventForMobile';

import { Annotation } from './types';

export type NodeEventMap = GlobalEventHandlersEventMap & {
  [index: string]: any;
};

type AnnotationEventHandler<EventType extends keyof NodeEventMap> = {
  event: EventType;
  handler:
    | ((
        event: KonvaEventObject<NodeEventMap[EventType]>,
        annotation: Annotation
      ) => void)
    | undefined;
};

const setAnnotationNodeEventHandlers = <
  T extends Konva.Node,
  EventType extends keyof NodeEventMap
>(
  node: T,
  annotation: Annotation,
  annotationEventHandlers: AnnotationEventHandler<EventType>[],
  unifiedViewer: UnifiedViewer
): T => {
  // Caveat: node.listening is by default true, and this must be the case even if we are not explicitly
  // declaring any event listeners here on the node. If listening is turned to false, the SelectTool
  // will not capture this node as a potential target for selection.
  annotationEventHandlers.forEach(({ event: eventType, handler }) => {
    if (handler !== undefined) {
      // For now we need to pass the Konva event objects, since the wrapped events are not
      // cancellable. i.e. MouseEvent.stopPropagation() does not work - the event will still
      // bubble
      unifiedViewer.eventHandler.removeMultipleEventListeners(
        node,
        mapEventForMobile(eventType) as string[]
      );

      unifiedViewer.eventHandler.addMultipleEventListeners(
        node,
        mapEventForMobile(eventType) as string[],
        (event: UnifiedViewerPointerEvent) => {
          if (
            unifiedViewer.getActiveToolType() !== ToolType.SELECT &&
            unifiedViewer.getActiveToolType() !== ToolType.PAN
          ) {
            return;
          }
          return handler(event, annotation);
        }
      );
    }
  });
  return node;
};

export default setAnnotationNodeEventHandlers;
