import { Infobox, Input, SearchIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useDebounce } from '@infield/hooks/use-debounce';
import { captureException } from '@sentry/react';
import { useEffect, useMemo, useState } from 'react';

import * as S from './elements';
import type { SearchProps } from './types';

export const ActivityDetailsSearch = <T,>({
  searchAlgorithm,
  mapChild,
}: SearchProps<T>) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debounce = useDebounce(searchQuery);
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const [searchResults, setSearchResult] = useState<T[]>();
  const [queryState, setQueryState] = useState<'LOADING' | 'ERROR' | 'OK'>(
    'LOADING'
  );

  useEffect(() => {
    setQueryState('LOADING');
    setSearchResult(undefined);

    searchAlgorithm(debounce)
      .then((res) => {
        setQueryState('OK');
        setSearchResult(res);
      })
      .catch((err) => {
        setQueryState('ERROR');
        captureException(err, {
          level: 'warning',
        });
      });
  }, [searchAlgorithm, debounce, t]);

  const results = useMemo(() => {
    return !searchResults || !searchResults.length ? (
      <Infobox
        status="neutral"
        title={t('ACTIVITY_DETAILS_TABS_NO_DATA_INFO_TITLE', 'No data')}
      >
        {t(
          'ACTIVITY_DETAILS_TABS_NO_DATA_INFO_MESSAGE',
          'No search results available.'
        )}
      </Infobox>
    ) : (
      <S.SearchList>{searchResults.map(mapChild)}</S.SearchList>
    );
  }, [mapChild, searchResults, t]);

  if (queryState === 'ERROR') {
    return (
      <div>
        <Infobox status="critical" title="Error">
          {t(
            'ACTIVITY_DETAILS_SEARCH_ERROR',
            'Failed to load the search results'
          )}
        </Infobox>
      </div>
    );
  }

  return (
    <S.SearchWrapper>
      <S.SearchInput>
        <Input
          type="search"
          variant="solid"
          fullWidth
          icon={<SearchIcon />}
          placeholder={t(
            'ACTIVITY_DETAILS_SEARCH_INPUT_PLACEHOLDER',
            'Search activity metadata'
          )}
          value={searchQuery}
          clearable
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      </S.SearchInput>
      {results}
    </S.SearchWrapper>
  );
};
