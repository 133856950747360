import type { APMAsset } from '@cognite/apm-client';
import { type FC, useState } from 'react';

import { AssetName } from '../elements';
import { OperationCard } from '../operation-card/operation-card';
import type { Label } from '../types';

import * as S from './elements';

interface Props {
  title: string;
  labels: Label[];
  assets: APMAsset[];
  onAssetClick: (assetExternalId: string) => void;
}

export const OperationCardAssetList: FC<Props> = ({
  title,
  labels,
  assets = [],
  onAssetClick,
}) => {
  const [showAssets, setShowAssets] = useState<boolean>(false);
  return (
    <div className="operation-card-asset-list-wrapper">
      <OperationCard
        title={title}
        labels={labels}
        assetAggregation={assets.length}
        showAssets={showAssets}
        onClick={() => setShowAssets(!showAssets)}
      />
      {showAssets && (
        <S.AssetList>
          {assets.map((asset) => (
            <S.AssetListItem key={asset.externalId}>
              <AssetName onClick={() => onAssetClick(asset.externalId)}>
                {asset.title}
              </AssetName>
            </S.AssetListItem>
          ))}
        </S.AssetList>
      )}
    </div>
  );
};
