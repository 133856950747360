import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import throttle from 'lodash/throttle';

import { UnifiedViewer } from '../UnifiedViewer';

import DD = Konva.DD;

export type UnifiedViewerPointerEvent = KonvaEventObject<
  MouseEvent | TouchEvent
> & {
  justDragged?: boolean;
};

type EventListener = (event: UnifiedViewerPointerEvent) => void;

const MOUSE_MOVE_DEFAULT_THROTTLE_MS = 16;
const MOUSE_MOVE_PERFORMANCE_THROTTLE_MS = 32;

export default class UnifiedEventHandler {
  private nodesEventListenersByEventType: WeakMap<
    Konva.Node,
    Map<string, EventListener[]>
  > = new Map();

  private shamefulTouchStartTarget: Konva.Node | null = null;
  private shamefulTouchEndTarget: Konva.Node | null = null;
  private shamefulMouseDownTarget: Konva.Node | null = null;

  private lastPointerMoveTarget: Konva.Node | null = null;

  private unifiedViewer: UnifiedViewer;

  public constructor(unifiedViewer: UnifiedViewer) {
    this.unifiedViewer = unifiedViewer;

    this.unifiedViewer.host.addEventListener('click', this.clickEventDelegator);
    this.unifiedViewer.host.addEventListener(
      'mousedown',
      this.mouseDownEventDelegator
    );
    this.unifiedViewer.host.addEventListener(
      'mouseup',
      this.mouseUpEventDelegator
    );

    // Throttling mousemove events to improve performance
    this.mouseMoveEventDelegator = throttle(
      this.mouseMoveEventDelegator,
      this.unifiedViewer.options.shouldUseShamefulFastMode
        ? MOUSE_MOVE_PERFORMANCE_THROTTLE_MS
        : MOUSE_MOVE_DEFAULT_THROTTLE_MS,
      {
        // Note: disabling trailing is important so that we don't fire mouseMove with an old event
        leading: true,
        trailing: false,
      }
    );

    this.unifiedViewer.host.addEventListener(
      'mousemove',
      this.mouseMoveEventDelegator
    );
    this.unifiedViewer.host.addEventListener(
      'mouseover',
      this.mouseOverEventDelegator
    );
    this.unifiedViewer.host.addEventListener(
      'mouseout',
      this.mouseOutEventDelegator
    );
    this.unifiedViewer.host.addEventListener(
      'mouseenter',
      this.mouseEnterEventDelegator
    );
    this.unifiedViewer.host.addEventListener(
      'mouseleave',
      this.mouseLeaveEventDelegator
    );

    this.unifiedViewer.host.addEventListener(
      'touchstart',
      this.touchStartEventDelegator
    );
    this.unifiedViewer.host.addEventListener(
      'touchmove',
      this.touchMoveEventDelegator
    );
    this.unifiedViewer.host.addEventListener(
      'touchend',
      this.touchEndEventDelegator
    );
  }

  private getTargetAtPointerPosition = (
    event: MouseEvent | TouchEvent
  ): Konva.Shape | Konva.Stage => {
    this.unifiedViewer.stage.setPointersPositions(event);
    // Note: Using getPointerPosition (singular) instead of getPointersPositions (plural)
    // because it is hitting another code path in Konva source code that accounts for touch
    // events that have changed. Particularly, it "correctly" gives us the interesected target
    // on touchend, which the plural version of the method does not.
    const pointerPosition = this.unifiedViewer.stage.getPointerPosition();

    if (pointerPosition === null) {
      return this.unifiedViewer.stage;
    }

    return (
      this.unifiedViewer.stage.getIntersection(pointerPosition) ??
      this.unifiedViewer.stage
    );
  };

  private onHostMouseMove = (
    target: Konva.Shape | Konva.Stage,
    event: MouseEvent | TouchEvent
  ) => {
    // If we don't check if we are currently dragging a node, the behaviour is that as soon as the drag
    // starts, the mousemove will fire on the node underneath it (instead of the node we are dragging).
    // This would then lead to the mouseout event being fired on the node we are dragging.
    // This is what Konva is doing at the line below
    // https://github.com/konvajs/konva/blob/e7b2bd6d1512686754e4f288adaa065346d25ef7/src/Stage.ts#L506
    const isEventsEnabled = !DD.isDragging;
    if (!isEventsEnabled) {
      return;
    }

    if (target !== this.lastPointerMoveTarget) {
      if (this.lastPointerMoveTarget !== null) {
        const konvaEventObject: UnifiedViewerPointerEvent = {
          type: 'mouseout',
          evt: event,
          target: this.lastPointerMoveTarget as unknown as Konva.Shape,
          cancelBubble: false,
          currentTarget: this.unifiedViewer.stage,
          justDragged: DD.justDragged,
        };
        this.fireNodeEvent(
          this.lastPointerMoveTarget,
          'mouseout',
          konvaEventObject
        );
      }

      const konvaEventObject: UnifiedViewerPointerEvent = {
        type: 'mouseover',
        evt: event,
        target,
        cancelBubble: false,
        currentTarget: this.unifiedViewer.stage,
        justDragged: DD.justDragged,
      };
      this.fireNodeEvent(target, 'mouseover', konvaEventObject);
    }

    this.lastPointerMoveTarget = target;
  };

  private onHostMouseDown = (target: Konva.Shape | Konva.Stage) => {
    this.shamefulMouseDownTarget = target;
  };

  private onHostTouchStart = (target: Konva.Shape | Konva.Stage) => {
    this.shamefulTouchStartTarget = target;
  };

  private onHostTouchEnd = (
    target: Konva.Shape | Konva.Stage,
    event: MouseEvent | TouchEvent
  ) => {
    this.shamefulTouchEndTarget = target;

    if (this.shamefulTouchStartTarget === this.shamefulTouchEndTarget) {
      // This approximately mimics how Konva works today - on touchend, if the touchstart and
      // touchend are on the same target, a tap will be fired. Using setTimeout to ensure
      // that the tap event is fired after the touchend event
      setTimeout(() => {
        this.fireNodeEvent(target, 'tap', {
          type: 'tap',
          evt: event,
          target,
          cancelBubble: false,
          currentTarget: this.unifiedViewer.stage,
          justDragged: DD.justDragged,
        });
      }, 0);
    }
  };

  private hostToKonvaEventDelegator =
    <T extends MouseEvent | TouchEvent>(
      eventType: string
    ): ((event: any) => void) =>
    (event: T) => {
      const target = this.getTargetAtPointerPosition(event);
      if (!target.isListening()) {
        return;
      }

      if (eventType === 'click' && target !== this.shamefulMouseDownTarget) {
        // We will only fire click if the mouse down and mouse up targets are the same
        return;
      }

      const konvaEventObject: UnifiedViewerPointerEvent = {
        type: eventType,
        evt: event,
        target,
        cancelBubble: false,
        currentTarget: this.unifiedViewer.stage,
        justDragged: DD.justDragged,
      };
      this.fireNodeEvent(target, eventType, konvaEventObject);

      if (eventType === 'mousedown') {
        this.onHostMouseDown(target);
      }

      if (eventType === 'mousemove') {
        this.onHostMouseMove(target, event);
      }

      if (eventType === 'touchstart') {
        this.onHostTouchStart(target);
      }

      if (eventType === 'touchend') {
        this.onHostTouchEnd(target, event);
      }
    };

  private clickEventDelegator = this.hostToKonvaEventDelegator('click');
  private mouseDownEventDelegator = this.hostToKonvaEventDelegator('mousedown');
  private mouseUpEventDelegator = this.hostToKonvaEventDelegator('mouseup');
  private mouseMoveEventDelegator = this.hostToKonvaEventDelegator('mousemove');
  private mouseOverEventDelegator = this.hostToKonvaEventDelegator('mouseover');
  private mouseOutEventDelegator = this.hostToKonvaEventDelegator('mouseout');
  private mouseEnterEventDelegator =
    this.hostToKonvaEventDelegator('mouseenter');
  private mouseLeaveEventDelegator =
    this.hostToKonvaEventDelegator('mouseleave');

  private touchStartEventDelegator =
    this.hostToKonvaEventDelegator('touchstart');
  private touchMoveEventDelegator = this.hostToKonvaEventDelegator('touchmove');
  private touchEndEventDelegator = this.hostToKonvaEventDelegator('touchend');

  private fireNodeEvent = (
    node: Konva.Node,
    eventType: string,
    event: UnifiedViewerPointerEvent
  ): void => {
    const eventListeners = this.getNodeEventListenersByEventType(
      node,
      eventType
    );

    eventListeners.forEach((eventListener) => {
      eventListener(event);
    });

    if (!event.cancelBubble && node.parent !== null) {
      this.fireNodeEvent(node.parent, eventType, event);
    }
  };

  private getNodeEventListeners = (
    node: Konva.Node
  ): Map<string, EventListener[]> => {
    return this.nodesEventListenersByEventType.get(node) ?? new Map();
  };
  private setNodeEventListeners = (
    node: Konva.Node,
    nodeEventListeners: Map<string, EventListener[]>
  ): void => {
    this.nodesEventListenersByEventType.set(node, nodeEventListeners);
  };

  public getNodeEventListenersByEventType = (
    node: Konva.Node,
    eventType: string
  ): EventListener[] => {
    return this.getNodeEventListeners(node).get(eventType) ?? [];
  };

  private setNodeEventHandlersByEventType = (
    node: Konva.Node,
    eventType: string,
    eventHandlers: EventListener[]
  ): void => {
    this.nodesEventListenersByEventType.set(
      node,
      new Map([...this.getNodeEventListeners(node), [eventType, eventHandlers]])
    );
  };

  public addEventListener = (
    node: Konva.Node,
    eventType: string,
    listenerFn: EventListener
  ): (() => void) => {
    this.setNodeEventHandlersByEventType(node, eventType, [
      ...this.getNodeEventListenersByEventType(node, eventType),
      listenerFn,
    ]);

    return () => {
      this.removeEventListener(node, eventType, listenerFn);
    };
  };

  /**
   * Just a convenience function to add multiple event listeners at once
   * @param node
   * @param eventType
   * @param listenerFn
   */
  public addMultipleEventListeners = (
    node: Konva.Node,
    eventType: string[],
    listenerFn: EventListener
  ): void => {
    eventType.forEach((event) => {
      this.addEventListener(node, event, listenerFn);
    });
  };

  public removeEventListener = (
    node: Konva.Node,
    eventType: string,
    listenerFn?: EventListener
  ): void => {
    if (listenerFn === undefined) {
      this.setNodeEventHandlersByEventType(node, eventType, []);
      return;
    }

    this.setNodeEventHandlersByEventType(node, eventType, [
      ...this.getNodeEventListenersByEventType(node, eventType).filter(
        (existingListenerFn) => existingListenerFn !== listenerFn
      ),
    ]);
  };

  /**
   * Just a convenience function to remove multiple event listeners at once
   * @param node
   * @param eventType
   * @param listenerFn
   */
  public removeMultipleEventListeners = (
    node: Konva.Node,
    eventType: string[],
    listenerFn?: EventListener
  ): void => {
    eventType.forEach((event) => {
      this.removeEventListener(node, event, listenerFn);
    });
  };

  public removeAllEventListeners = (node: Konva.Node): void => {
    this.setNodeEventListeners(node, new Map());
  };

  public onDestroy = (): void => {
    this.unifiedViewer.host.removeEventListener(
      'click',
      this.clickEventDelegator
    );
    this.unifiedViewer.host.removeEventListener(
      'mousedown',
      this.mouseDownEventDelegator
    );
    this.unifiedViewer.host.removeEventListener(
      'mouseup',
      this.mouseUpEventDelegator
    );
    this.unifiedViewer.host.removeEventListener(
      'mousemove',
      this.mouseMoveEventDelegator
    );
    this.unifiedViewer.host.removeEventListener(
      'mouseover',
      this.mouseOverEventDelegator
    );
    this.unifiedViewer.host.removeEventListener(
      'mouseout',
      this.mouseOutEventDelegator
    );
    this.unifiedViewer.host.removeEventListener(
      'mouseenter',
      this.mouseEnterEventDelegator
    );
    this.unifiedViewer.host.removeEventListener(
      'mouseleave',
      this.mouseLeaveEventDelegator
    );
    this.unifiedViewer.host.removeEventListener(
      'touchstart',
      this.touchStartEventDelegator
    );
    this.unifiedViewer.host.removeEventListener(
      'touchmove',
      this.touchMoveEventDelegator
    );
    this.unifiedViewer.host.removeEventListener(
      'touchend',
      this.touchEndEventDelegator
    );
  };
}
