import chroma from 'chroma-js';
import Konva from 'konva';
import cloneDeep from 'lodash/cloneDeep';

import { Container } from '../containers/Container';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import UnifiedViewerRenderer from '../UnifiedViewerRenderer';
import getAspectRatio from '../utils/getAspectRatio';
import getFontSizeInAbsoluteUnits from '../utils/getFontSizeInAbsoluteUnits';
import shamefulSafeKonvaScale from '../utils/shamefulSafeKonvaScale';

import getCachedImage from './getCachedImage';
import getNormalizedDimensionsFromNode from './getNormalizedDimensionsFromNode';
import setAnnotationNodeEventHandlers from './setAnnotationNodeEventHandlers';
import setNodeColor from './setNodeColor';
import shouldBeDraggable from './shouldBeDraggable';
import shouldBeResizable from './shouldBeResizable';
import shouldBeSelectable from './shouldBeSelectable';
import {
  AnnotationSerializer,
  AnnotationType,
  ImageAnnotation,
  ImageSize,
  ImageStyleProperties,
  Rotation,
  Scale,
  Size,
} from './types';

type ImageDimensions = {
  x: number;
  y: number;
  width: number;
};

const getDenormalizedDimensionsFromImageAnnotation = (
  annotation: ImageAnnotation,
  containerRect: Size
): ImageDimensions => {
  return {
    x: containerRect.width * annotation.x,
    y: containerRect.height * annotation.y,
    width: getFontSizeInAbsoluteUnits(annotation.size, containerRect.width),
  };
};

const getDimensions = (
  annotation: ImageAnnotation,
  getContainerById: (id: string) => Container | undefined
): ImageDimensions | undefined => {
  if (annotation.containerId === undefined) {
    // Note: The annotation validation we do earlier ensures that images specified without a
    // parent container must have its image size given in absolute (pixel) units.
    return getImageDimensionsFromAnnotation(annotation);
  }

  const container = getContainerById(annotation.containerId);
  if (container === undefined) {
    return undefined;
  }

  return getDenormalizedDimensionsFromImageAnnotation(
    annotation,
    container.getContentNode().size()
  );
};

const getImageDimensionsFromAnnotation = (
  annotation: ImageAnnotation
): ImageDimensions => ({
  x: annotation.x,
  y: annotation.y,
  width: getFontSizeInAbsoluteUnits(annotation.size),
});

const updateNodeWidthAndHeight = (
  node: Konva.Image,
  dimensions: ImageDimensions,
  image: CanvasImageSource
): void => {
  const aspectRatio = getAspectRatio(image);
  node.width(dimensions.width);
  node.height(dimensions.width / aspectRatio);
};

type ImageNodeProperties<MetadataType> = {
  [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION;
  annotationType: AnnotationType.IMAGE;
  id: string;
  containerId?: string;
  url: string;
  isSelectable: boolean;
  isDraggable: boolean;
  isResizable: boolean;
  originalImageSize: ImageSize;
  metadata?: MetadataType;
} & ImageStyleProperties &
  ImageDimensions &
  Rotation;

const getNodePropertiesFromAnnotation = <MetadataType>(
  annotation: ImageAnnotation<MetadataType>,
  unifiedViewerRenderer: UnifiedViewerRenderer
): ImageNodeProperties<MetadataType> | undefined => {
  const dimensions = getDimensions(
    annotation,
    unifiedViewerRenderer.getContainerById
  );
  if (dimensions === undefined) {
    // eslint-disable-next-line no-console
    console.warn(
      `Could not get dimensions for annotation ${annotation.id}. If it has a container, maybe the container hasn't loaded yet?`
    );
    return undefined;
  }

  return {
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
    annotationType: AnnotationType.IMAGE,
    id: annotation.id,
    containerId: annotation.containerId,
    url: annotation.url,
    color: annotation.style?.color,
    isSelectable: shouldBeSelectable(annotation.isSelectable),
    isDraggable: shouldBeDraggable(annotation.isDraggable),
    isResizable: shouldBeResizable(annotation.isResizable),
    originalImageSize: annotation.size,
    rotation: annotation.rotation,
    metadata: annotation.metadata,
    ...dimensions,
  };
};

type TransformationProperties = {
  size: Size;
  scale: Scale;
  rotation: number;
};

const ImageSerializer: AnnotationSerializer<
  ImageAnnotation,
  TransformationProperties
> = {
  isSelectable: (node: Konva.Node) =>
    shouldBeSelectable(node.attrs.isSelectable),

  isDraggable: (node: Konva.Node) => shouldBeDraggable(node.attrs.isDraggable),

  isResizable: (node: Konva.Node) => shouldBeResizable(node.attrs.isResizable),

  getTransformationPropertiesFromNode: (node: Konva.Node) => {
    if (!(node instanceof Konva.Image)) {
      throw new Error('Expected node to be a Konva.Image');
    }

    return {
      scale: shamefulSafeKonvaScale(node.scale()),
      size: node.size(),
      rotation: node.rotation(),
    };
  },
  normalize: ({
    size,
    scale,
    rotation,
  }: {
    size: Size;
    scale: Scale;
    rotation: number;
  }) => ({
    size: {
      width: size.width * scale.x,
      height: size.height * scale.y,
    },
    scale: {
      x: 1,
      y: 1,
    },
    rotation,
  }),

  serialize: (node, unifiedViewerRenderer): ImageAnnotation => {
    if (!(node instanceof Konva.Image)) {
      throw new Error('Expected node to be a Konva.Image');
    }
    const {
      x,
      y,
      width: normalizedWidth,
    } = getNormalizedDimensionsFromNode(
      node,
      unifiedViewerRenderer.getContainerRectRelativeToStageById
    );
    return {
      id: node.id(),
      url: node.attrs.url,
      type: AnnotationType.IMAGE,
      containerId: node.attrs.containerId,
      isSelectable: shouldBeSelectable(node.attrs.isSelectable),
      isDraggable: shouldBeDraggable(node.attrs.isDraggable),
      isResizable: shouldBeResizable(node.attrs.isResizable),
      rotation: node.rotation(),
      x,
      y,
      size:
        node.attrs.containerId === undefined
          ? `${node.width()}px`
          : normalizedWidth,
      style: { color: node.attrs.color },
      metadata: cloneDeep(node.attrs.metadata),
    };
  },

  deserialize: (annotation, unifiedViewer, unifiedViewerRenderer) => {
    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return undefined;
    }

    const imageNode = new Konva.Image({ image: undefined });
    imageNode.setAttrs(nodeProperties);

    getCachedImage(annotation.url, (image) => {
      imageNode.image(image);
      updateNodeWidthAndHeight(imageNode, nodeProperties, image);
    });

    if (nodeProperties.color) {
      setNodeColor(imageNode, chroma(nodeProperties.color).rgb());
    }

    return setAnnotationNodeEventHandlers(
      imageNode,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },

  updateNode: (
    node,
    annotation,
    unifiedViewer,
    unifiedViewerRenderer
  ): void => {
    if (!(node instanceof Konva.Image)) {
      throw new Error('Expected node to be a Konva.Image');
    }

    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return;
    }

    if (nodeProperties.url !== node.attrs.url) {
      getCachedImage(nodeProperties.url, (image) => {
        node.image(image);
        updateNodeWidthAndHeight(node, nodeProperties, image);
      });
    }

    if (nodeProperties.width !== node.width()) {
      // Here we compute the aspect ratio of the image based on the original node attrs.
      // Computing it using the client rect would result in the aspect ratio being converge to 1
      const aspectRatio = node.width() / node.height();
      node.height(nodeProperties.width / aspectRatio);
    }

    node.setAttrs(nodeProperties);
    if (nodeProperties.color !== undefined) {
      setNodeColor(node, chroma(nodeProperties.color).rgb());
    }

    setAnnotationNodeEventHandlers(
      node,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },
};

export default ImageSerializer;
