import {
  ArrowLeftIcon,
  Button,
  Flex,
  Heading,
  Tooltip,
} from '@cognite/cogs.js-v10';
import { useFeatureToggleConfig } from '@infield/features/app-config/hooks';
import { useAPMAssetsQuery } from '@infield/features/asset';
import type { FC } from 'react';

import * as S from './elements';
import { RootLocationConfig3d } from './root-location-config-3d';
import { RootLocationConfigDataFilters } from './root-location-config-data-filters';
import { RootLocationConfigDataset } from './root-location-config-dataset';
import { RootLocationConfigFeatureToggles } from './root-location-config-feature-toggle';
import { RootLocationConfigObservations } from './root-location-config-observations';
import { RootLocationConfigSpace } from './root-location-config-space';
import { RootLocationTemplateChecklistAdmins } from './root-location-config-template-checklist-admin';

interface Props {
  hasAppConfigPermission: boolean;
  rootLocationExternalId: string;
  assetExternalId: string;
  setEditingRootLocation: (rootLocationExternalId: string | undefined) => void;
  onClickBack: () => void;
}

export const RootLocationConfigFeatures: FC<Props> = ({
  hasAppConfigPermission,
  rootLocationExternalId,
  assetExternalId,
  setEditingRootLocation,
  onClickBack,
}) => {
  const { data: [asset] = [] } = useAPMAssetsQuery({
    externalIds: [assetExternalId],
    spaces: [],
  });

  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    rootLocationExternalId
  );

  return (
    <S.ConfigWrapper>
      <Flex justifyContent="space-between">
        <S.TitleContainer>
          <Button
            icon={<ArrowLeftIcon />}
            onClick={onClickBack}
            aria-label="back-button"
          />
          <Heading level={5}>{asset?.title}</Heading>
        </S.TitleContainer>
      </Flex>
      <S.Tabs defaultActiveKey="Feature toggle">
        <S.TabPane tabKey="Feature toggle" label="Feature toggle">
          <RootLocationConfigFeatureToggles
            hasAppConfigPermission={hasAppConfigPermission}
            rootLocationExternalId={rootLocationExternalId}
          />
        </S.TabPane>
        <S.TabPane tabKey="3D" label="3D">
          <RootLocationConfig3d
            hasAppConfigPermission={hasAppConfigPermission}
            rootLocationExternalId={rootLocationExternalId}
          />
        </S.TabPane>
        <S.TabPane tabKey="Dataset" label="Dataset">
          <RootLocationConfigDataset
            hasAppConfigPermission={hasAppConfigPermission}
            rootLocationExternalId={rootLocationExternalId}
          />
        </S.TabPane>
        <S.TabPane tabKey="TemplateAdmins" label="Template / checklist admins">
          <RootLocationTemplateChecklistAdmins
            hasAppConfigPermission={hasAppConfigPermission}
            rootLocationExternalId={rootLocationExternalId}
          />
        </S.TabPane>
        <S.TabPane tabKey="AppDataInstanceSpace" label="AppData Instance Space">
          <RootLocationConfigSpace
            dataType="app"
            hasAppConfigPermission={hasAppConfigPermission}
            rootLocationExternalId={rootLocationExternalId}
          />
        </S.TabPane>
        <S.TabPane
          tabKey="SourceDataInstanceSpace"
          label="SourceData Instance Space"
        >
          <RootLocationConfigSpace
            dataType="source"
            hasAppConfigPermission={hasAppConfigPermission}
            rootLocationExternalId={rootLocationExternalId}
          />
        </S.TabPane>
        <S.TabPane tabKey="DataFilters" label="Data filters">
          <RootLocationConfigDataFilters
            hasAppConfigPermission={hasAppConfigPermission}
            rootLocationExternalId={rootLocationExternalId}
            setEditingRootLocation={setEditingRootLocation}
          />
        </S.TabPane>
        <S.TabPane
          tabKey="Observations"
          label={
            <Tooltip
              appendTo={document.body}
              disabled={rootLocationFeatureToggles?.observations?.isEnabled}
              content="Feature must be toggled on to continue configuration"
            >
              <span>Observations</span>
            </Tooltip>
          }
          disabled={!rootLocationFeatureToggles?.observations?.isEnabled}
        >
          <RootLocationConfigObservations
            hasAppConfigPermission={hasAppConfigPermission}
            rootLocationExternalId={rootLocationExternalId}
          />
        </S.TabPane>
      </S.Tabs>
    </S.ConfigWrapper>
  );
};
