import type { APMAsset } from '@cognite/apm-client';
import { Body, Flex, TagChip } from '@cognite/cogs.js-v10';
import type { Asset } from '@cognite/sdk';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { getTitle } from '@infield/features/search/search-result/utils';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  parentAsset?: Asset | APMAsset;
  childAssets?: (Asset | APMAsset)[];
  onSelect: (assetExternalId: string) => void;
}

export const TopBarSearchAssetHierarchy: FC<Props> = ({
  parentAsset,
  childAssets,
  onSelect,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.search);
  return (
    <>
      {parentAsset && (
        <S.ResultContainer onClick={() => onSelect?.(parentAsset.externalId!)}>
          <Flex alignItems="center" gap={8}>
            <Body size="medium">{getTitle(parentAsset)}</Body>
            <TagChip
              label={t(`TOP_BAR_SEARCH_ASSET_HIERARCHY_PARENT`, 'Parent')}
              size="x-small"
            />
          </Flex>

          <Body size="x-small">{parentAsset.description}</Body>
        </S.ResultContainer>
      )}
      {childAssets &&
        childAssets.map((child) => (
          <S.ResultContainer
            key={child.externalId}
            onClick={() => onSelect?.(child.externalId!)}
          >
            <Flex alignItems="center" gap={8}>
              <Body size="medium">{getTitle(child)}</Body>
              <TagChip
                label={t(`TOP_BAR_SEARCH_ASSET_HIERARCHY_CHILD`, 'Child')}
                size="x-small"
              />
            </Flex>

            <Body size="x-small">{child.description}</Body>
          </S.ResultContainer>
        ))}
    </>
  );
};
