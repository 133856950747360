import {
  Body,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  TagChip,
  TagIcon,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { FC, MouseEvent } from 'react';
import { v4 as uuid } from 'uuid';

import type { Label } from '../types';

import * as S from './elements';

interface Props {
  title: string;
  labels: Label[];
  assetAggregation?: number;
  showAssets: boolean;
  onClick?: (
    event: MouseEvent<HTMLDivElement> | MouseEvent<HTMLButtonElement>
  ) => void;
}

export const OperationCard: FC<Props> = ({
  title,
  labels,
  assetAggregation,
  showAssets,
  onClick,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  return (
    <S.Container onClick={onClick}>
      <Flex justifyContent="space-between" alignItems="center">
        <Body size="medium" strong>
          {title}
        </Body>
        <Button
          aria-label={
            showAssets
              ? 'close-operation-assets-menu'
              : 'open-operation-assets-menu'
          }
          icon={showAssets ? <ChevronUpIcon /> : <ChevronDownIcon />}
          type="ghost"
        />
      </Flex>

      {labels && (
        <Flex gap={4}>
          {labels.map((label) => (
            <TagChip
              tooltip={label.label}
              key={uuid()}
              label={label.value}
              size="small"
            />
          ))}
          {assetAggregation ? (
            <TagChip
              tooltip={t(
                'OPERATION_LABEL_NUMBER_OF_ASSETS',
                'Number of assets'
              )}
              icon={<TagIcon />}
              label={String(assetAggregation)}
              size="small"
            />
          ) : undefined}
        </Flex>
      )}
    </S.Container>
  );
};
