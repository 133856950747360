import { useAssetQuery } from '@infield/features/asset';

import { useAssetDirectChildSearch } from './use-asset-child-search';

type Props = {
  query: string;
  assetExternalId?: string;
  enabled: boolean;
};

export const useClassicAssetHierarchySearch = ({
  query,
  assetExternalId,
  enabled,
}: Props) => {
  const { data: activeAsset } = useAssetQuery(
    enabled ? assetExternalId : undefined
  );
  const { data: parentAsset } = useAssetQuery(activeAsset?.parentExternalId);
  const { data: childAssets } = useAssetDirectChildSearch({
    parentExternalId: assetExternalId || '',
    query: enabled ? query : undefined,
    enabled,
  });

  return {
    parentAsset,
    childAssets,
  };
};
