import { RefObject } from 'react';

import { applyTransform, getCumulativeTransform } from './mathUtils';

const getRelativeCursorPosition = (
  clientPosition: { x: number; y: number },
  offsetParent: Element
) => {
  const chartBounds = offsetParent.getBoundingClientRect();
  const x = clientPosition.x - chartBounds.left;
  const y = clientPosition.y - chartBounds.top;
  const transform = getComputedStyle(offsetParent).transform;

  if (transform === '' || transform === 'none') {
    return { x, y };
  }

  const scaleX = parseFloat(transform.split(',')[0].slice(7));
  const scaleY = parseFloat(transform.split(',')[3]);
  return { x: x / scaleX, y: y / scaleY };
};

export const getCursorPosition = (
  chartRef: RefObject<HTMLDivElement>,
  event: MouseEvent,
  shouldRecursivelyApplyScale?: boolean
) => {
  const clientPosition = { x: event.clientX, y: event.clientY };

  if (chartRef.current === null) {
    return undefined;
  }

  if (shouldRecursivelyApplyScale !== true) {
    return getRelativeCursorPosition(clientPosition, chartRef.current);
  }

  const { offsetParent } = chartRef.current;

  if (offsetParent === null) {
    return clientPosition;
  }

  const transform = getCumulativeTransform(offsetParent);

  if (transform === null) {
    return clientPosition;
  }

  const transformedPosition = applyTransform(transform, clientPosition);
  return transformedPosition;
};
