import type {
  CustomReadingLabelColor,
  CustomReadingLabelColorOption,
} from '@cognite/apm-client';
import { Body, Flex, Menu } from '@cognite/cogs.js-v10';
import { LabelReading } from '@infield/features/measurements/label/measurement-label-reading/label-reading';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export type Props = {
  option: CustomReadingLabelColorOption;
  onSelectColor: (color: CustomReadingLabelColor) => void;
};

export const ColorOption: React.FC<Props> = ({ option, onSelectColor }) => {
  return (
    <Menu.Item
      key={option.value}
      onClick={() => {
        onSelectColor(option.value);
      }}
    >
      <Flex justifyContent="space-between" alignItems="center" gap={8}>
        <Body size="medium">
          <Trans
            t={t}
            i18nKey={`TEMPLATE_TASK_FORM_CUSTOM_READING_COLOR_LABEL_${option.label.toUpperCase()}`}
          >
            {option.label}
          </Trans>
        </Body>
        <div>
          <LabelReading
            isSelected
            isDisabled={false}
            size="small"
            type={option.value}
          >
            <Trans
              t={t}
              i18nKey="TEMPLATE_TASK_FORM_CUSTOM_READING_LABEL_EXAMPLE"
            >
              Example
            </Trans>
          </LabelReading>
        </div>
      </Flex>
    </Menu.Item>
  );
};
