import { DataFetchMode } from './types';

export type DataFetchOptions =
  | {
      mode: DataFetchMode;
      rawDatapointsLimit?: never;
    }
  | {
      mode?: 'auto';
      rawDatapointsLimit: number;
    };

interface Props {
  numberOfPoints: number;
  dataFetchOptions?: DataFetchOptions;
  isString?: boolean;
}

export const DEFAULT_RAW_DATAPOINTS_LIMIT = 500;

export const getDataFetchMode = ({
  numberOfPoints,
  dataFetchOptions = {} as DataFetchOptions,
  isString,
}: Props): DataFetchMode => {
  if (isString) {
    return 'raw';
  }

  const { mode, rawDatapointsLimit = DEFAULT_RAW_DATAPOINTS_LIMIT } =
    dataFetchOptions;

  if (mode && mode !== 'auto') {
    return mode;
  }

  if (numberOfPoints > rawDatapointsLimit) {
    return 'aggregate';
  }

  return 'raw';
};
