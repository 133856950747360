import { Input } from '@cognite/cogs.js-v10';
import { Button, Flex, SegmentedControl } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const SearchContainer = styled(Flex).attrs({
  direction: 'column',
  gap: 8,
})<{ $noShadow?: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  background: ${({ theme }) => theme['surface--muted']};
  box-shadow: ${({ $noShadow }) =>
    $noShadow
      ? 'none'
      : '0px 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(0, 0, 0, 0.2)'};
`;

export const HeaderContainer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
})`
  height: 52px;
  padding: 0px 16px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};

  flex-shrink: 0;
`;
export const InputContainer = styled(Flex)`
  width: 100%;
  // Need to manually apply fullwidth to input container because of cogs bug with inputExp
  // Also need display: flex, otherwise the "clear" button will cause the contents of the headercontainer to overflow
  .cogs-input-container {
    width: 100%;
    display: flex;
  }
`;

export const SearchInput = styled(Input)`
  flex-grow: 1;
`;

export const CloseButton = styled(Button)`
  padding: 8px 0px !important;
`;

export const TabContainer = styled(Flex).attrs({})`
  padding: 0px 16px;
  overflow-x: auto;
  flex-shrink: 0;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ResultContainer = styled(Flex)<{ $fullscreen: boolean }>`
  padding: 16px;
  ${({ $fullscreen }) => ($fullscreen ? '' : 'max-height: 500px')};

  overflow-y: auto;
  overflow-x: hidden;

  border-top: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const SegmentedControlButton = styled(SegmentedControl.Button)`
  white-space: nowrap;
`;
