import type { ViewConfigDTO } from '@cognite/apm-config';

export const updateViewMappings = (
  existingViews: ViewConfigDTO[] | undefined,
  newViewMappings: ViewConfigDTO[]
): ViewConfigDTO[] => {
  const updatedViews = [...(existingViews ?? [])];

  newViewMappings.forEach((newView) => {
    const index = updatedViews.findIndex(
      (view) => view.representsEntity === newView.representsEntity
    );
    if (index !== -1) {
      updatedViews[index] = newView;
    } else {
      updatedViews.push(newView);
    }
  });

  return updatedViews;
};
