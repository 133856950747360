import type { Operation } from '@cognite/apm-client';
import { CDFIDMViewMigrator } from '@cognite/apm-client';
import type { Filters, Sort } from '@cognite/fdm-client';
import cloneDeep from 'lodash/cloneDeep';

export class OperationIDMMigrator extends CDFIDMViewMigrator {
  mapDataOutward = (data: Record<string, any>): Operation => {
    return {
      ...data,
      externalId: data.externalId,
      id: data.sourceId,
      title: data.name,
      order: data.sequence,
      mainResource: data.mainDiscipline,
      numberOfMainResource: data.numberOfMainDiscipline,
      parentActivityId: data.maintenanceOrder?.externalId,
      labels: data.tags,
      assetExternalId: data.mainAsset?.externalId,
    };
  };
  mapFiltersInward = (filters: Filters): Filters => {
    const newFilters = cloneDeep(filters);
    Object.keys(filters).forEach((key) => {
      if (key === 'and') {
        newFilters.and = filters.and!.map((f) => this.mapFiltersInward(f));
      }
      if (key === 'or') {
        newFilters.or = filters.or!.map((f) => this.mapFiltersInward(f));
      }
      if (key === 'not') {
        newFilters.not = this.mapFiltersInward(newFilters.not!);
      }

      if (key === 'in') {
        if (filters.in?.property === 'parentActivityId') {
          newFilters.in = undefined;
          newFilters.directRelationFilter = {
            maintenanceOrder: {
              in: { property: 'externalId', in: filters.in.in },
            },
          };
          newFilters.directRelationInstanceSpace = this.space;
        }
      }
      if (key === 'equals') {
        if (filters.equals?.property === 'source') {
          newFilters.equals = undefined;
          console.log(
            `Removed filter for "source" since the since CogniteOperation has source as CogniteSourceSystem and we don't know how to filter on that yet`
          );
        }
        if (filters.equals?.property === 'assetExternalId') {
          newFilters.equals = undefined;
          newFilters.directRelationFilter = {
            mainAsset: {
              equals: { property: 'externalId', eq: String(filters.equals.eq) },
            },
          };
          newFilters.directRelationInstanceSpace = this.space;
        }
      }
      if (key === 'isNull') {
        if (filters.isNull?.property === 'cloneOf') {
          newFilters.isNull = undefined;
          console.log(
            'Removed filter for "cloneOf" since the property does not exist on CogniteOperation data model'
          );
        }
      }
    });
    return newFilters;
  };
  mapSortInward = (sort: Sort): Sort => {
    return sort.map((x) => {
      if (Object.keys(x)[0] === 'order') {
        return { sequence: x.order };
      }
      return x;
    });
  };
}
