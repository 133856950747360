import { ChevronDownIcon, Menu, ToolBar } from '@cognite/cogs.js-v10';
import { use3dModels } from '@cognite/reveal-react-components';
import type { Model3D } from '@cognite/sdk';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { use3dModelsQuery, useAssetModels } from '../../hooks';
import type { ThreeDModelIdentifier } from '../../types';

import * as S from './elements';

type Props = {
  assetId?: number;
};

/**
 * Check if there are more than 1 model available for the active asset
 * if Yes – show model selector on top of the 3d viewer
 * if No – show nothing
 */
export const ModelSelector: FC<Props> = ({ assetId }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.threeD);
  const [model] = use3dModels();

  const { isIdm } = useAppConfigContext();

  const { assetId: assetExternalId } = useParams();

  const { allMappedModels } = useAssetModels(assetExternalId, assetId);

  const otherAvailableModels = useMemo(
    () =>
      allMappedModels?.filter(
        ({ modelId, revisionId }) =>
          modelId !== model?.modelId && revisionId !== model?.revisionId
      ) || [],
    [allMappedModels, model?.modelId, model?.revisionId]
  );

  const { data: enrichedClassicModel } = use3dModelsQuery<
    ThreeDModelIdentifier[]
  >(otherAvailableModels, {
    enabled: Boolean(otherAvailableModels.length) && !isIdm,
    select: useCallback(
      (models: Model3D[]) =>
        models.map((modelFromApi) => {
          const modelIdentifier = otherAvailableModels.find(
            ({ modelId }) => modelId === modelFromApi.id
          )!;

          return {
            ...modelIdentifier,
            name: modelFromApi.name,
          };
        }),
      [otherAvailableModels]
    ),
  });

  const enrichedModels = isIdm ? otherAvailableModels : enrichedClassicModel;

  const navigate = useNavigate();

  if (!enrichedModels?.length) {
    return null;
  }

  return (
    <S.Container>
      <ToolBar direction="horizontal">
        <ToolBar.ButtonGroup
          key="model-selector"
          buttonGroup={[
            {
              icon: <ChevronDownIcon />,
              text: t('Select another model'),
              dropdownProps: {
                placement: 'bottom-start',
              },
              dropdownContent: (
                <Menu data-testid="threed-model-selector">
                  {enrichedModels.map(({ name, modelId, revisionId }) => (
                    <Menu.Item
                      key={revisionId}
                      style={{ minWidth: 179 }}
                      onClick={() =>
                        navigate(
                          `/asset/${encodeURIComponent(
                            assetExternalId!
                          )}/3d?modelId=${modelId}&revisionId=${revisionId}`
                        )
                      }
                    >
                      {name}
                    </Menu.Item>
                  ))}
                </Menu>
              ),
              style: {
                minWidth: 140,
              },
            },
          ]}
        />
      </ToolBar>
    </S.Container>
  );
};
