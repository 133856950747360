import { linkifyOptions } from '@infield/utils/linkify';
import Linkify from 'linkify-react';
import type { FC } from 'react';

import { StyledInfobox } from './elements';

interface ConditionMessageProps {
  conditionMessage?: string;
}

export const ConditionMessage: FC<ConditionMessageProps> = ({
  conditionMessage,
}) =>
  conditionMessage ? (
    <StyledInfobox
      data-testid="checklist-item-conditional-message"
      status="neutral"
      compact
    >
      <Linkify options={linkifyOptions}>{conditionMessage}</Linkify>
    </StyledInfobox>
  ) : null;
