import Konva from 'konva';

import { Position } from '../../annotations/types';
import getBoundingBoxCornerPosition from '../../utils/getBoundingBoxCornerPosition';
import getRectRelativeToStage from '../../utils/getRectRelativeToStage';

import { getAnchorOffset } from './getAnchorOffset';

const getOffsetAnchorPosition = ({
  anchor,
  target,
  anchorPaddingPx,
}: {
  anchor: Konva.Group;
  target: Konva.Node | undefined;
  anchorPaddingPx?: number;
}): Position | undefined => {
  if (target === undefined) {
    return undefined;
  }
  const rect = getRectRelativeToStage(target);
  const anchorPosition = getBoundingBoxCornerPosition(
    anchor.attrs.anchorName,
    rect
  );
  const anchorOffset = getAnchorOffset(
    anchor.attrs.anchorName,
    anchorPaddingPx
  );
  return {
    x: anchorPosition.x + anchorOffset.x,
    y: anchorPosition.y + anchorOffset.y,
  };
};

export default getOffsetAnchorPosition;
