import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { FdmInstance } from '../../../types';

import { FdmClient } from './FdmClient';
import getLatestViewVersion from './getLatestViewVersion';

export function useView(
  instance: FdmInstance,
  fdmClient: FdmClient
): UseQueryResult<
  { space: string; externalId: string; version: string },
  unknown
> {
  const view = useQuery(
    ['view', instance.viewSpace, instance.viewExternalId, instance.viewVersion],
    async () => {
      return {
        space: instance.viewSpace,
        externalId: instance.viewExternalId,
        version:
          instance.viewVersion ??
          getLatestViewVersion(
            await fdmClient.getViewVersions(
              instance.viewSpace,
              instance.viewExternalId
            )
          ),
      };
    }
  );

  return view;
}
