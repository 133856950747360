import { MultipleSpaceSelectOrCreator } from '@cognite/apm-config';
import type { LocationFilterDTO } from '@cognite/apm-config';
import { PageHeader } from '@cognite/cogs-lab';
import { Body, Button, SaveIcon } from '@cognite/cogs.js-v10';
import { useAuthContext } from '@cognite/e2e-auth';
import { useState } from 'react';
import type { FC } from 'react';

import {
  ConfigPageContentWrapper,
  ContentContainer,
  HelpTextAreaWrapper,
  LocationInfieldContentWrapper,
} from '../elements';

import * as S from './elements';

type Props = {
  location?: LocationFilterDTO;
  isSaving?: boolean;
  onSave: (nextConfig: LocationFilterDTO) => void;
};

export const LocationInstanceSpaces: FC<Props> = ({
  location,
  isSaving,
  onSave,
}) => {
  const { client } = useAuthContext();

  const [newInstanceSpaces, setNewInstanceSpaces] = useState(
    location?.instanceSpaces
  );

  const upsertNewSpace = (newSpace?: string) => {
    if (!newSpace) return;
    client.spaces.upsert([{ space: newSpace }]);
  };

  const handleInstanceSpacesUpdate = (spaces?: string[]) => {
    setNewInstanceSpaces(spaces || []);
  };
  const handleSpaceCreate = (newSpace?: string) => {
    if (!newSpace) return;
    setNewInstanceSpaces([...(newInstanceSpaces || []), newSpace]);
    upsertNewSpace(newSpace);
  };

  const handleSave = () => {
    if (!newInstanceSpaces || !location) return;
    onSave({
      ...location,
      instanceSpaces: newInstanceSpaces,
    });
  };

  const isChanged =
    JSON.stringify(location?.instanceSpaces) !==
    JSON.stringify(newInstanceSpaces);

  return (
    <LocationInfieldContentWrapper>
      <PageHeader fullWidth>
        <PageHeader.TitleArea
          title="Customer instance spaces"
          description="Which spaces have the customer data for this location?"
        />
        <PageHeader.Actions>
          <Button
            type="primary"
            icon={<SaveIcon />}
            onClick={handleSave}
            loading={isSaving}
            disabled={!isChanged}
          >
            Save
          </Button>
        </PageHeader.Actions>
      </PageHeader>
      <ConfigPageContentWrapper>
        <ContentContainer>
          <S.AutocomleteWrapper $isChanged={isChanged}>
            <MultipleSpaceSelectOrCreator
              sdk={client}
              selectedSpaces={newInstanceSpaces}
              onSpacesSelect={handleInstanceSpacesUpdate}
              onSpaceCreate={handleSpaceCreate}
            />
          </S.AutocomleteWrapper>
        </ContentContainer>
        <HelpTextAreaWrapper>
          <Body>
            Choose all spaces where assets, work orders, operations and other
            customer data for the location is stored.
          </Body>
          <Body>
            Applications will filter data using the selected spaces. If no
            spaces are defined, apps will search all spaces.
          </Body>
          <Body>
            We highly recommend you to organise location data into specific
            spaces for querying efficiency.
          </Body>
        </HelpTextAreaWrapper>
      </ConfigPageContentWrapper>
    </LocationInfieldContentWrapper>
  );
};
