import { Flex, IconWrapper, InfoIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import * as S from './elements';

export const TrendsListEmpty: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.trends);

  return (
    <S.Wrapper>
      <Flex direction="column" alignItems="center" gap={12}>
        <IconWrapper size={16}>
          <InfoIcon />
        </IconWrapper>
        {t('no_search_result_message', 'No timeseries available.')}
      </Flex>
    </S.Wrapper>
  );
};
