import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { makeToast } from '@cognite/cogs-lab';
import { useAuthContext } from '@cognite/e2e-auth';
import { useFlag } from '@cognite/react-feature-flags';
import { useSelectedRootLocationConfiguration } from '@infield/features/app-config/hooks';
import { useSelectedRootAsset } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useFDMServices } from '@infield/providers/fdm-services';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { ImageToUpload } from '../types';

export const useUploadMedia = () => {
  const { mediaService } = useFDMServices();
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);
  const { isEnabled: isAwsProject } = useFlag('INFIELD_IS_AWS_PROJECT', {
    fallback: false,
    forceRerender: true,
  });

  const { data: rootAsset } = useSelectedRootAsset();
  const { location } = useAppConfigContext();

  const configuredRootLocation = useSelectedRootLocationConfiguration();
  const datasetId = configuredRootLocation?.dataSetId;
  const queryClient = useQueryClient();

  const { authState } = useAuthContext();

  return useMutation<
    { externalId: string | undefined; space: string | undefined }[],
    Error,
    {
      media: ImageToUpload[];
      assetIds?: number[];
      assetInstanceIds?: InstanceOrExternalId[];
    }
  >(
    async ({ media, assetIds, assetInstanceIds }) => {
      const contextualizedAssets = () => {
        if (assetIds && assetIds.length > 0) return assetIds;
        if (rootAsset) return [rootAsset.id];
        return undefined;
      };

      const contextualizedIdmAssets = () => {
        if (assetInstanceIds && assetInstanceIds.length > 0)
          return assetInstanceIds.map(({ externalId, space }) => ({
            externalId,
            space: space || '',
          }));
        return undefined;
      };

      const mediaToUpload = Promise.all(
        media.map((file) =>
          mediaService.uploadMedia({
            image: file.url,
            metadata: file.metadata,
            externalId: file.externalId,
            name: file.name,
            author: authState.user.email,
            assetIds: contextualizedAssets(),
            assetInstanceIds: contextualizedIdmAssets(),
            dataSetId: datasetId,
            isAwsProject,
            space: location?.appInstanceSpace,
          })
        )
      );

      return new Promise((resolve) => {
        const uploadedMedia = mediaToUpload;
        setTimeout(() => resolve(uploadedMedia), 2500); // need to await file deletion to be reflected in CDF before refetch
      });
    },
    {
      onError: (err) => {
        makeToast({
          body: t('uploadMediaFailed', 'Failed to upload image'),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-upload-media',
          },
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.MEDIA_LIST],
        });
      },
    }
  );
};
