import type { ChecklistItemStatus } from '@cognite/apm-client';

export const checklistItemStatuses: ChecklistItemStatus[] = [
  'Ok',
  'Not ok',
  'Not applicable',
  'Blocked',
];

export const TODO_STATUS: ChecklistItemStatus = 'To do';
