import type { ViewConfigDTO } from '@cognite/apm-config';

export const removeEntityFromView = (viewConfig?: ViewConfigDTO) => {
  if (!viewConfig) return undefined;
  return {
    externalId: viewConfig.externalId,
    space: viewConfig.space,
    version: viewConfig.version,
  };
};
