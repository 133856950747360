import type { FdmTimeSeries } from '@cognite/fdm-client/src/types';

import type {
  ChecklistItemStatus,
  DirectRelationship,
  FdmMetadata,
  InFieldUser,
} from '../types';

export type Measurement = {
  type?: MeasurementType;
  externalId: string;
  title?: string;
  description?: string;
  timeseries?: FdmTimeSeries | null;
  measuredAt?: string;
  min?: number | null;
  max?: number | null;
  numericReading?: number | null;
  stringReading?: string;
  order?: number;
  options?: CustomReadingLabel[];

  createdBy?: InFieldUser;
  updatedBy?: InFieldUser;
} & FdmMetadata;

export type MeasurementUpsertType = Omit<
  Measurement,
  'timeseries' | 'createdBy' | 'updatedBy'
> & {
  timeseries?: string | null;
  createdBy?: DirectRelationship;
  updatedBy?: DirectRelationship;
};

export type MeasurementType = 'numerical' | 'label' | 'message';

export type CustomReadingLabelColor =
  | 'default'
  | 'neutral'
  | 'success'
  | 'warning'
  | 'danger';

export type CustomReadingLabel = {
  id?: string;
  label?: string;
  color?: CustomReadingLabelColor;
  status?: ChecklistItemStatus;
};

export type CustomReadingLabelColorOption = {
  label: string;
  value: CustomReadingLabelColor;
};

export const customReadingLabelColors: CustomReadingLabelColorOption[] = [
  { label: 'Blue', value: 'neutral' },
  { label: 'Green', value: 'success' },
  { label: 'Yellow', value: 'warning' },
  { label: 'Red', value: 'danger' },
  { label: 'Default', value: 'default' },
];

export type CustomReadingLabelStatusOption = {
  label: string;
  value?: ChecklistItemStatus;
};

export const customReadingLabelStatuses: CustomReadingLabelStatusOption[] = [
  { label: 'OK', value: 'Ok' },
  { label: 'Not OK', value: 'Not ok' },
  { label: 'Not applicable', value: 'Not applicable' },
  { label: 'Blocked', value: 'Blocked' },
];
