// This should only be used internally.

import Konva from 'konva';
import { IRect } from 'konva/lib/types';

import { UnifiedViewerErrorEvent } from '../UnifiedViewer';
import EventEmitter from '../utils/EventEmitter';
import isAnnotationNode from '../utils/isAnnotationNode';

import { Container, ContainerProps } from './Container';
import { ContainerEventType } from './ContainerEventType';
import { Errorable, isErrorable } from './Errorable';
import { EventListenerMap } from './EventListenerMap';

// Unsure if this should be called Container. It shares some but not all properties
// of other containers. Maybe it should be named something different but that is shared
// together with Container.
export default class LayerContainer extends EventEmitter<EventListenerMap> {
  public layer: Konva.Layer = new Konva.Layer({});
  private isReady: boolean = false;
  protected children: Container[] = [];

  public constructor({ name }: { name: string }) {
    super();
    this.layer.name(name);
  }

  public draw = (): void => {
    this.layer.draw();
  };

  public getIsReady = (): boolean => this.isReady;

  public getChildren = (): Container[] => this.children;

  public add = (...args: any[]): Konva.Layer => this.layer.add(...args);

  public addChild(child: Container): void {
    this.layer.add(child.getNode());
    this.children.push(child);
    this.trackChildReady(child);
    this.trackChildError(child);
  }

  public getNode = (): Konva.Layer => this.layer;

  public getContentNode = (): Konva.Layer => this.layer;

  // TODO: Probably shouldn't expose these directly, but ok for now
  public removeChildren(): Konva.Layer {
    this.children = [];

    // NOTE: This is a hack because we might be keeping connections in the layer as well
    // which are not Containers.
    this.layer.children?.forEach(
      (node) => !isAnnotationNode(node) && node.remove()
    );
    return this.layer;
  }

  public find = (
    selector: string | ((node: Konva.Node) => boolean)
  ): Konva.Node[] => this.layer.find(selector);

  public getClientRect = (...args: any[]): IRect =>
    this.layer.getClientRect(...args);

  public onChildReadyStateChange = (): void => {
    const compoundIsReady = this.getChildren().every(
      (child) =>
        child.getIsReady() ||
        (isErrorable(child) && child.getHasErrorOccurred())
    );

    if (this.getIsReady() !== compoundIsReady) {
      this.setIsReady(compoundIsReady);
    }
  };

  public trackChildReady = (child: Container): void => {
    child.addEventListener(
      ContainerEventType.ON_READY_STATE_CHANGE,
      this.onChildReadyStateChange
    );
  };

  public onChildErrorStateChange = (
    container: Container<ContainerProps> & Errorable,
    error: UnifiedViewerErrorEvent
  ): void => {
    this.emit(ContainerEventType.ON_CONTAINER_ERROR, container, error);
  };

  public trackChildError = (child: Container): void => {
    child.addEventListener(
      ContainerEventType.ON_CONTAINER_ERROR,
      this.onChildErrorStateChange
    );
  };

  protected setIsReady(isReady: boolean): void {
    if (this.isReady !== isReady) {
      this.isReady = isReady;
      this.emit(ContainerEventType.ON_READY_STATE_CHANGE, this);
    }
  }

  public isSelectable = (): boolean => false;

  public isDraggable = (): boolean => false;

  public isResizable = (): boolean => false;
}
