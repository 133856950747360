import { Skeleton } from '@cognite/cogs.js-v10';
import { useAuthContext } from '@cognite/e2e-auth';
import { useIsAppConfiguratorQuery } from '@infield/features/app-config';
import type { FC } from 'react';
import { useEffect } from 'react';

import { ConfigView } from './config-view';

export const IdmConfigPage: FC = () => {
  const { authState } = useAuthContext();
  const { data: hasAppConfigPermission, isLoading } =
    useIsAppConfiguratorQuery();

  const org = authState?.projectInfo.org;
  const project = authState?.projectInfo.id;
  const cluster = authState?.projectInfo.cluster.apiBaseURL?.replace(
    'https://',
    ''
  );

  useEffect(() => {
    if (!isLoading && !hasAppConfigPermission) {
      if (org && project && cluster) {
        window.location.href = `https://${org}.fusion.cognite.com/${project}?cluster=${cluster}`;
      } else {
        window.location.href = `https://fusion.cognite.com`;
      }
    }
  }, [cluster, hasAppConfigPermission, isLoading, org, project]);

  if (isLoading) {
    return <Skeleton.List />;
  }

  if (hasAppConfigPermission) {
    return <ConfigView />;
  }

  return <div>Redirecting to Fusion...</div>;
};
