import Konva from 'konva';
import { v4 as uuid } from 'uuid';

import { DEFAULT_STROKE_SCALE_ENABLED } from '../annotations/constants';
import {
  AnnotationType,
  PolylineEndType,
  Position,
} from '../annotations/types';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import type { UnifiedViewer } from '../UnifiedViewer';
import UnifiedViewerEventType from '../UnifiedViewerEventType';
import { UnifiedViewerPointerEvent } from '../UnifiedViewerRenderer/UnifiedEventHandler';
import { getImaginaryHorizontalOrVerticalEndPosition } from '../utils/getImaginaryHorizontalOrVerticalEndPosition';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';

import { ContainerAttachmentHelper } from './ContainerAttachmentHelper';
import getShapeEventPropsFromKonvaShape from './getShapeEventPropsFromKonvaShape';
import Tool from './Tool';
import { PolylineToolConfig, ToolType } from './types';

const getNumberOfPoints = (points: number[]) => {
  return points.length / 2;
};

const getNthPointPair = (points: number[], n: number) => {
  return points.slice(n * 2, n * 2 + 2);
};

const calculateDistanceBetweenTwoPoints = (
  [x1, y1]: number[],
  [x2, y2]: number[]
): number => {
  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
};

const calculateDistanceBetweenLastTwoPoints = (points: number[]) => {
  if (getNumberOfPoints(points) < 2) {
    throw new Error('Cannot calculate distance between less than 2 points');
  }

  return calculateDistanceBetweenTwoPoints(
    getNthPointPair(points, getNumberOfPoints(points) - 2),
    getNthPointPair(points, getNumberOfPoints(points) - 1)
  );
};

// TODO: Maybe this should depend on the zoom level and be something like 1% of the viewport size?
const MAX_DISTANCE_TO_COMPLETE_POLYLINE = 2;

export default class PolylineTool extends Tool {
  public readonly type = ToolType.POLYLINE;
  public override readonly cursor = 'crosshair';
  private config: PolylineToolConfig;
  private pendingNode: Konva.Arrow | undefined;
  private containerAttachmentHelper: ContainerAttachmentHelper;

  public constructor({
    unifiedViewer,
    config,
  }: {
    unifiedViewer: UnifiedViewer;
    config: PolylineToolConfig;
  }) {
    super(unifiedViewer);
    this.containerAttachmentHelper = new ContainerAttachmentHelper(
      unifiedViewer
    );
    this.setConfig(config);
    this.config = config;
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);
    this.containerAttachmentHelper.start();
  }

  public setConfig(options: PolylineToolConfig): void {
    this.config = options;
  }

  public override onMouseDown = (e: UnifiedViewerPointerEvent): void => {
    e.target.stopDrag();

    if (this.pendingNode === undefined) {
      this.initializePendingNodeIfAllowed();
    }

    if (this.pendingNode === undefined) {
      return;
    }

    const currentPoints = this.pendingNode.points();
    if (
      getNumberOfPoints(currentPoints) > 1 &&
      calculateDistanceBetweenLastTwoPoints(currentPoints) <
        MAX_DISTANCE_TO_COMPLETE_POLYLINE
    ) {
      this.pendingNode.points(currentPoints.slice(0, currentPoints.length - 2));
      this.onComplete();
      return;
    }

    this.addCurrentPositionAsIntermediatePoint(e.evt.shiftKey);
  };

  public override onMouseMove = (e: UnifiedViewerPointerEvent): void => {
    if (this.pendingNode === undefined) {
      return;
    }

    this.addCurrentPositionAsImaginaryPoint(e.evt.shiftKey);
  };

  private getRelativePointerPosition = (): Position | null => {
    return this.unifiedViewer.stage.getRelativePointerPosition();
  };

  private initializePendingNodeIfAllowed = () => {
    const currentPosition = this.getRelativePointerPosition();
    if (currentPosition === null) {
      return;
    }

    this.pendingNode = new Konva.Arrow({
      ...this.config,
      [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
      id: uuid(),
      userGenerated: true,
      name: 'user-drawing',
      source: this.type,
      containerId: undefined,
      annotationType: AnnotationType.POLYLINE,
      isSelectable: true,
      isDraggable: true,
      isResizable: true,
      pointerAtBeginning: this.config?.startEndType === PolylineEndType.ARROW,
      pointerAtEnding: this.config?.endEndType === PolylineEndType.ARROW,
      points: [currentPosition.x, currentPosition.y],
      strokeWidth: this.config?.strokeWidth,
      strokeScaleEnabled:
        this.config?.shouldEnableStrokeScale ?? DEFAULT_STROKE_SCALE_ENABLED,
    });

    this.unifiedViewer.setDirtyNodes([this.pendingNode]);
    this.containerAttachmentHelper.lockTarget();
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_TOOL_START);
  };

  private getNextLineSegmentEndPosition = (
    currentPoints: number[],
    currentLineSegmentEndPosition: Position,
    shouldEnforceRightAngles: boolean
  ): Position => {
    return shouldEnforceRightAngles
      ? getImaginaryHorizontalOrVerticalEndPosition(
          {
            x: currentPoints[currentPoints.length - 2],
            y: currentPoints[currentPoints.length - 1],
          },
          currentLineSegmentEndPosition
        )
      : currentLineSegmentEndPosition;
  };

  private addCurrentPositionAsIntermediatePoint = (
    shouldEnforceRightAngles: boolean
  ) => {
    if (this.pendingNode === undefined) {
      return;
    }
    const currentPosition = this.getRelativePointerPosition();
    if (currentPosition === null) {
      return;
    }
    const currentPoints = this.pendingNode.points();
    const nextLineSegmentEndPosition = this.getNextLineSegmentEndPosition(
      currentPoints,
      currentPosition,
      shouldEnforceRightAngles
    );
    const nextPoints = [
      ...currentPoints,
      nextLineSegmentEndPosition.x,
      nextLineSegmentEndPosition.y,
    ];
    this.pendingNode.points(nextPoints);
  };

  private addCurrentPositionAsImaginaryPoint = (
    shouldEnforceRightAngles: boolean
  ) => {
    if (this.pendingNode === undefined) {
      return;
    }
    const currentPosition = this.getRelativePointerPosition();
    if (currentPosition === null) {
      return;
    }
    const shouldExtendPolyline = this.pendingNode.points().length >= 4;
    const currentPoints = shouldExtendPolyline
      ? this.pendingNode.points().slice(0, -2)
      : this.pendingNode.points();

    const nextLineSegmentEndPosition = this.getNextLineSegmentEndPosition(
      currentPoints,
      currentPosition,
      shouldEnforceRightAngles && shouldExtendPolyline
    );
    const nextPoints = [
      ...currentPoints,
      nextLineSegmentEndPosition.x,
      nextLineSegmentEndPosition.y,
    ];
    this.pendingNode.points(nextPoints);
  };

  public override onDestroy(): void {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);

    if (this.pendingNode !== undefined) {
      this.unifiedViewer.setDirtyNodes([]);
      this.pendingNode = undefined;
      this.unifiedViewer.emit(UnifiedViewerEventType.ON_TOOL_END);
    }

    this.containerAttachmentHelper.onDestroy();

    this.unifiedViewer.stage.addEventListener('keydown', (e) => {
      // TODO: Improve typing
      this.onKeyDown(e as KeyboardEvent);
    });
  }

  public override onComplete = (): void => {
    this.containerAttachmentHelper.end(
      this.pendingNode !== undefined ? [this.pendingNode] : []
    );
    this.containerAttachmentHelper.start();
    this.unifiedViewer.commitDirtyNodes();
    if (this.pendingNode !== undefined) {
      getMetricsLogger()?.trackEvent(TrackedEventType.CREATE_POLYLINE, {
        ...getShapeEventPropsFromKonvaShape(this.pendingNode),
        points: this.pendingNode.points(),
      });
    }
    this.pendingNode = undefined;

    this.unifiedViewer.emit(UnifiedViewerEventType.ON_TOOL_END);
  };

  public onKeyUp = (e: KeyboardEvent): void => {
    if (e.key === 'Shift' && this.pendingNode !== undefined) {
      this.addCurrentPositionAsImaginaryPoint(e.shiftKey); // snaps line out of straight line mode
    }
  };

  public onKeyDown = (e: KeyboardEvent): void => {
    // If Escape is pressed, cancel the last line segment and commit the rest.
    if (e.key === 'Escape' && this.pendingNode !== undefined) {
      const allButLastPoint = this.pendingNode.points().slice(0, -2);
      // Cancel the pending line if there are less than 2 points (i.e., 4 coordinates)
      if (allButLastPoint.length < 4) {
        this.pendingNode.destroy();
        this.pendingNode = undefined;
        return;
      }
      this.pendingNode.points(allButLastPoint);
      this.onComplete();
      return;
    }

    // If Enter key then complete the polyline
    if (e.key === 'Enter' && this.pendingNode !== undefined) {
      this.onComplete();
    }
    if (e.key === 'Shift' && this.pendingNode !== undefined) {
      this.addCurrentPositionAsImaginaryPoint(e.shiftKey); // snaps line out of straight line mode
    }
  };
}
