import type { APMAsset } from '@cognite/apm-client';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CubeIcon,
  ToolBar,
} from '@cognite/cogs.js-v10';
import { useAuthContext } from '@cognite/e2e-auth';
import { DefaultNodeAppearance, type GeometryFilter } from '@cognite/reveal';
import {
  Reveal3DResources,
  RevealCanvas,
  use3dModels,
  useCameraNavigation,
  useClickedNodeData,
} from '@cognite/reveal-react-components';
import type { CommonResourceContainerProps } from '@cognite/reveal-react-components/dist/components/Reveal3DResources/types';
import type { Asset } from '@cognite/sdk';
import { useClassicByIdOrAPMAssetQuery } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  createApiError,
  openNotification,
} from '@infield/features/ui-notification';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { Color } from 'three';

import {
  use3dNodesByNodeId,
  useAssetMappingsByModelIdQuery,
  useZoomToClassicAsset,
  useZoomToIdmAsset,
} from '../hooks';
import { useNavigationalHintsContext } from '../hooks/use-navigational-hints-context';
import { getGeometryFilterByAssetId } from '../providers/utils';
import { getClickedAssetFromClickedNodeData, isLowEndDevice } from '../utils';

import { ThreeDNavigationHints } from './3d-navigation-hints';
import { AssetInfo } from './asset-info';
import * as S from './elements';
import { useAssetByNameQuery, useSelectionHistory } from './hooks';
import { ModelSelector } from './model-selector';
import { SearchInput } from './SearchInput';

interface Props {
  assetId?: number;
  assetExternalId?: string;
  space?: string;
  modelId: number;
  revisionId: number;
  showSidebar?: boolean;
}

/**
 * Provides full featured 3d viewer for asset exploration
 */
export const Asset3dViewer: FC<Props> = ({
  assetId,
  assetExternalId,
  space,
  modelId,
  revisionId,
  showSidebar,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.threeD);
  const [model] = use3dModels();
  const isDesktop = useIsDesktop();
  const { client } = useAuthContext();

  const cameraNavigation = useCameraNavigation();

  const { isIdm } = useAppConfigContext();

  const [isGhostMode, setIsGhostMode] = useState(false);

  const { data: assetMappings, isLoading: isLoadingAssetMappings } =
    useAssetMappingsByModelIdQuery({
      modelId,
      revisionId,
      assetId,
    });

  const nodeId = assetMappings?.[0]?.nodeId;

  const { data: threeDNodes } = use3dNodesByNodeId(modelId, revisionId, nodeId);

  const clickedNodeData = useClickedNodeData();

  // null represents that the latest clicked 3d node has no asset mappings
  const [highlightedAssetId, setHighlightedAssetId] = useState<number | null>(
    assetId || null
  );

  const [highlightedAssetExternalId, setHighlightedAssetExternalId] = useState<
    string | null
  >(isIdm ? assetExternalId || null : null);

  const [clickedAsset, setClickedAsset] = useState<number | string>();

  // zoom to currently selected asset
  const { error: zoomDataQueryError, isLoading: isZoomDataLoading } =
    useZoomToClassicAsset(highlightedAssetId);

  useZoomToIdmAsset(highlightedAssetExternalId, space);

  const [geometryFilter, setGeometryFilter] = useState<
    GeometryFilter | undefined
  >(undefined);

  const assetSelectionHistory = useSelectionHistory<number | string | null>(
    useCallback(
      (assetIdFromHistory) => {
        // that works fine here only because we push primitive values and
        // selection history checks if we're trying to add the same value again
        // which we do, because we push a value when highlighted asset id changes
        if (isIdm) {
          setHighlightedAssetExternalId(assetIdFromHistory as string);
        } else {
          setHighlightedAssetId(assetIdFromHistory as number);
        }

        // that's tricky one, we do this to handle this situation:
        // user has initial asset, then clicks somewhere without mappings and side panel shows "no mappings found"
        // then to return on previous asset user clicks the back button
        // then if user clicks the same 3d node without mappings again, then clickedTreeIndex won't be updated (as this is already undefined from the last time),
        // meaning the clickedAssetId won't be updated and that null value won't be pushed to the history again...
        // and the to avoid this – we set it to `undefined` to guarantee an update
        setClickedAsset(undefined);
      },
      [isIdm]
    )
  );

  // add currently highlighted asset to the selection history
  useEffect(() => {
    if (isIdm) {
      assetSelectionHistory.push(highlightedAssetExternalId);
    } else {
      assetSelectionHistory.push(highlightedAssetId);
    }
    // the rule is buggy... :( asks to add the whole assetSelectionHistory object as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assetSelectionHistory.push,
    highlightedAssetId,
    highlightedAssetExternalId,
  ]);

  // that's to show asset titles in prev/next buttons tooltips
  const previousAssetQuery = useClassicByIdOrAPMAssetQuery(
    assetSelectionHistory.previousItem
  );
  const nextAssetQuery = useClassicByIdOrAPMAssetQuery(
    assetSelectionHistory.nextItem
  );

  /*
   * there is a bug. If you click different treeIndexes that point on the same assetId,
   * and you do that not for the first time, meaning value is returned from cache so re-rending doesn't happen because query-result never set to undefined,
   * then you'll incorrectly see "asset not found page" because tree index clicks reset the highlighted asset value, but
   * this effect won't be rerun as clickedAssetId remains the same.
   * Some refactoring is needed to fix this as logic is cumbersome now, setClickedTreeIndex(undefined) is also a bad sign of the problem
   */
  useEffect(() => {
    const { assetId: newClickedAssetId } =
      getClickedAssetFromClickedNodeData(clickedNodeData);
    const { externalId: newClickedAssetExternalId } =
      getClickedAssetFromClickedNodeData(clickedNodeData);
    if (isIdm) {
      if (newClickedAssetExternalId !== clickedAsset) {
        setClickedAsset(newClickedAssetExternalId);
        setHighlightedAssetExternalId(newClickedAssetExternalId || null);
      }
    } else if (newClickedAssetId !== clickedAsset) {
      setClickedAsset(newClickedAssetId);
      setHighlightedAssetId(newClickedAssetId || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedNodeData]);

  const [assetInfoIsVisible, setAssetInfoIsVisible] = useState(false);

  // when selected asset changes – reopen asset info panel
  useEffect(() => {
    setAssetInfoIsVisible(true);
  }, [highlightedAssetId, highlightedAssetExternalId]);

  // on mobile, we change toolbar position depending on size of the info panel
  const [toolbarMobileBottomMargin, setToolbarMobileBottomMargin] = useState(0);

  const { closeNavigationalHints } = useNavigationalHintsContext();

  const [searchedAssetName, setSearchedAssetName] = useState('');
  const searchedAssetQuery = useAssetByNameQuery(searchedAssetName); // support external id as well INFIELD2-2507

  const handleSearch = (searchedName: string) => {
    setSearchedAssetName(searchedName);
    highlightSearchedAsset();
  };

  const highlightSearchedAsset = useCallback(() => {
    if (searchedAssetQuery.isSuccess) {
      const assetData = searchedAssetQuery.data;
      if (!assetData) {
        setHighlightedAssetId(null);
        setHighlightedAssetExternalId(null);
      } else if (!isIdm && 'id' in assetData) {
        setHighlightedAssetId(assetData?.id || null);
      } else {
        setHighlightedAssetExternalId(assetData?.externalId || null);
      }

      // if we change highlightedAssetId or setHighlightedAssetExternalId anyhow but clicking we must reset the last clicked element or it won't be highlighted if clicked again
      setClickedAsset(undefined);
    }
  }, [isIdm, searchedAssetQuery.data, searchedAssetQuery.isSuccess]);

  // that could be just useEffect, but we need to reset the highlighted asset id in case search button is clicked manually
  useEffect(() => {
    highlightSearchedAsset();
  }, [highlightSearchedAsset]);

  useEffect(() => {
    if (isLowEndDevice()) {
      if (
        !modelId ||
        !revisionId ||
        !assetId ||
        isLoadingAssetMappings ||
        !assetMappings ||
        !threeDNodes
      ) {
        return;
      }

      setGeometryFilter(undefined);
      getGeometryFilterByAssetId(Number(assetId), assetMappings, threeDNodes)
        .then(() => {
          setGeometryFilter(undefined);
          // setGeometryFilter(filter); set to undefined until https://cognitedata.atlassian.net/browse/BND3D-4564 is resolved
        })
        .catch((error) => {
          console.error('Failed to get geometry filter', error);
          setGeometryFilter(undefined); // Handle error, possibly by setting to undefined or some error state
        });
    }
  }, [
    client,
    modelId,
    revisionId,
    assetId,
    isLoadingAssetMappings,
    assetMappings,
    threeDNodes,
  ]); // Dependencies for useEffect

  useEffect(() => {
    const error =
      zoomDataQueryError ||
      searchedAssetQuery.error ||
      nextAssetQuery.error ||
      previousAssetQuery.error;

    if (error) {
      openNotification(createApiError());
    }
  }, [
    zoomDataQueryError,
    searchedAssetQuery.error,
    nextAssetQuery.error,
    previousAssetQuery.error,
  ]);

  const handleResize = useCallback(({ height }: DOMRect) => {
    setToolbarMobileBottomMargin(height);
  }, []);

  const instanceStyling: CommonResourceContainerProps['instanceStyling'] =
    useMemo(() => {
      if (highlightedAssetId) {
        return [
          {
            assetIds: [highlightedAssetId],
            style: {
              cad: DefaultNodeAppearance.Highlighted,
              pointcloud: DefaultNodeAppearance.Highlighted,
            },
          },
        ];
      }

      if (highlightedAssetExternalId && space) {
        return [
          {
            fdmAssetExternalIds: [
              {
                externalId: highlightedAssetExternalId,
                space,
              },
            ],
            style: {
              cad: DefaultNodeAppearance.Highlighted,
            },
          },
        ];
      }
    }, [highlightedAssetExternalId, highlightedAssetId, space]);

  const handleOnResourcesAdded = useCallback(() => {
    cameraNavigation.fitCameraToAllModels();
  }, [cameraNavigation]);

  return (
    <S.Wrapper>
      <S.Asset3DViewerWrapper>
        <S.Asset3DViewerContainer
          onClick={() => {
            // the reason why we check for desktop here is that because on desktop
            // we want modal to be closed only using the submit button (for some reason, kept the former behaviour...)
            // and there is a problem with the modal that events are propagated
            // from the modal until this component which catches them and closes the modal
            if (!isDesktop) {
              closeNavigationalHints();
            }
          }}
          onTouchStart={() => {
            if (!isDesktop) {
              closeNavigationalHints();
            }
          }}
        >
          <ThreeDNavigationHints />
          <RevealCanvas>
            <Reveal3DResources
              onResourcesAdded={handleOnResourcesAdded}
              resources={[{ modelId, revisionId, geometryFilter }]}
              instanceStyling={instanceStyling}
              defaultResourceStyling={
                isGhostMode
                  ? {
                      cad: {
                        default: DefaultNodeAppearance.Ghosted,
                      },
                      pointcloud: {
                        default: {
                          color: new Color('gray'),
                        },
                      },
                    }
                  : undefined
              }
            />
          </RevealCanvas>
          {model && <ModelSelector assetId={assetId} />}

          <S.ToolBar
            direction={isDesktop ? 'horizontal' : 'vertical'}
            $isDesktop={isDesktop}
            $marginBottom={toolbarMobileBottomMargin}
          >
            <>
              {/* 2 disabled buttons within one group look ugly (their disabled grey backgrounds overlap),
           that's why there is a group for each button */}
              <ToolBar.ButtonGroup
                buttonGroup={[
                  {
                    icon: <ArrowLeftIcon />,
                    description: isIdm
                      ? (previousAssetQuery.data as APMAsset)?.title
                      : (previousAssetQuery.data as Asset)?.name,
                    onClick: assetSelectionHistory.back,
                    disabled: !assetSelectionHistory.hasPrevious,
                  },
                ]}
                key="group-navigation-left"
              />
            </>
            <ToolBar.ButtonGroup
              buttonGroup={[
                {
                  icon: <ArrowRightIcon />,
                  description: isIdm
                    ? (nextAssetQuery.data as APMAsset)?.title
                    : (nextAssetQuery.data as Asset)?.name,
                  onClick: assetSelectionHistory.forward,
                  disabled: !assetSelectionHistory.hasNext,
                },
              ]}
              key="group-navigation-right"
            />
            <ToolBar.ButtonGroup
              buttonGroup={[
                {
                  icon: <CubeIcon />,
                  description: t(
                    'THREE_D_ASSET_INFO_TOGGLE_GHOST_MODE',
                    'Toggle ghost mode'
                  ),
                  onClick: () => setIsGhostMode((prev) => !prev),
                },
              ]}
              key="group-ghost"
            />
          </S.ToolBar>

          {isDesktop && (
            <S.SearchInputContainer>
              <SearchInput onSearch={handleSearch} />
            </S.SearchInputContainer>
          )}
        </S.Asset3DViewerContainer>
      </S.Asset3DViewerWrapper>

      {showSidebar && assetInfoIsVisible && (
        <AssetInfo
          onResize={handleResize}
          isLoading={searchedAssetQuery.isInitialLoading || isZoomDataLoading}
          assetId={isIdm ? highlightedAssetExternalId : highlightedAssetId}
          onClose={() => setAssetInfoIsVisible(false)}
        />
      )}
    </S.Wrapper>
  );
};
