import type { FC, PropsWithChildren } from 'react';
import { useEffect, useRef, useState } from 'react';

import * as S from './elements';

export const CollapsibleContent: FC<
  PropsWithChildren<{ isCollapsed: boolean }>
> = ({ isCollapsed, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setContentHeight(ref.current.clientHeight);
    }
  }, [children]);

  return (
    <S.CollapseContent $isCollapsed={isCollapsed} $childHeight={contentHeight}>
      <div ref={ref}>{children}</div>
    </S.CollapseContent>
  );
};
