import type { AuthStateUser } from '../../types';

class Flow {
  /**
   * User details stored after getTokenFactory is ran.
   */
  static user: AuthStateUser;
  /**
   * Creates a function that can be used to fetch an access token.
   * This function can then be added to the SDK
   */
  async getTokenFactory(): Promise<() => Promise<string>> {
    throw new Error('Unimplemented method: getTokenFactory');
  }

  /**
   * Gets the auth state of the user after login
   */
  async getUserState(): Promise<Omit<AuthStateUser, 'cdfId'>> {
    throw new Error('Unimplemented method: getUserState');
  }
}

export default Flow;
