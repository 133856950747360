import { Body, Flex } from '@cognite/cogs.js-v10';
import { BREAKPOINT_MOBILE_PORTRAIT } from '@infield/providers/theme';
import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  height: 100%;
  min-height: 0px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const DocumentContainer = styled(Flex).attrs({})<{
  $containerWidth?: number;
}>`
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
  &:nth-child(even) {
    background-color: ${({ theme }) => theme['surface--medium']};
  }
`;

export const DocumentContent = styled(Flex).attrs({})<{
  $containerWidth?: number;
}>`
  padding: ${({ $containerWidth }) => {
    if ($containerWidth && $containerWidth > BREAKPOINT_MOBILE_PORTRAIT) {
      return '0';
    }
    return '8px 16px';
  }} !important;

  flex-wrap: ${({ $containerWidth }) => {
    if ($containerWidth && $containerWidth > BREAKPOINT_MOBILE_PORTRAIT) {
      return 'nowrap';
    }
    return 'wrap';
  }} !important;

  gap: ${({ $containerWidth }) => {
    if ($containerWidth && $containerWidth > BREAKPOINT_MOBILE_PORTRAIT) {
      return '0px';
    }
    return '2px';
  }} !important;
`;

export const DocumentTitle = styled(Body).attrs({ level: 2, strong: true })<{
  $containerWidth?: number;
}>`
  color: ${({ theme }) => theme['border--interactive--toggled-default']};
  cursor: pointer;

  padding: ${({ $containerWidth }) => {
    if ($containerWidth && $containerWidth > BREAKPOINT_MOBILE_PORTRAIT) {
      return '14px 16px';
    }
    return '0';
  }} !important;

  min-width: 280px;

  border-right: ${({ $containerWidth }) => {
    if ($containerWidth && $containerWidth > BREAKPOINT_MOBILE_PORTRAIT) {
      return `1px solid`;
    }
    return 'none';
  }} !important;

  border-right-color: ${({ $containerWidth, theme }) => {
    if ($containerWidth && $containerWidth > BREAKPOINT_MOBILE_PORTRAIT) {
      return theme['border--muted'];
    }
    return 'transparent';
  }} !important;
`;

export const DocumentDescription = styled(Body).attrs({
  size: 'medium',
})<{
  $containerWidth?: number;
}>`
  color: ${({ theme }) => theme['text-icon--muted']};
  padding: ${({ $containerWidth }) => {
    if ($containerWidth && $containerWidth > BREAKPOINT_MOBILE_PORTRAIT) {
      return '14px 16px';
    }
    return '0';
  }} !important;
`;
