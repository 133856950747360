import type { LocationFilterDTO } from '@cognite/apm-config';

export const updateLocation = (
  previousLocation: LocationFilterDTO,
  newValues: Partial<LocationFilterDTO>
): LocationFilterDTO => {
  return {
    ...previousLocation,
    ...newValues,
  };
};
