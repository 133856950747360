import { Button, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

const TRANSITION_TIME_IN_SECONDS = 0.4;

export const Header = styled(Flex).attrs({
  justifyContent: 'space-between',
  alignContent: 'center',
})<{
  $isCollapsed: boolean;
}>`
  height: 32px;
  align-items: baseline;
  ${({ $isCollapsed }) => $isCollapsed && 'cursor: pointer;'}
`;

export const ExpandButton = styled(Button)``;

export const InfoBoxWrapper = styled(Flex)<{
  $isCollapsed: boolean;
}>`
  overflow: hidden;

  flex: 1;

  > .cogs-infobox {
    border-radius: 0px;
    padding: 12px 16px;

    > .cogs-infobox__icon {
      height: 16px;
      margin-top: 6px;
    }

    ${ExpandButton} {
      margin: 0px;
      padding: 8px;

      transition: ${TRANSITION_TIME_IN_SECONDS}s all;
      ${({ $isCollapsed }) => ($isCollapsed ? 'opacity: 1;' : `opacity: 0;`)}
    }
  }

  .cogs-infobox__content {
    margin-top: 0px;

    > p {
      margin: 0px;
    }

    h5 {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
`;

export const CollapseButtonWrapper = styled(Flex)`
  margin-top: 12px;
`;

export const CollapseContent = styled(Flex).attrs({ direction: 'column' })<{
  $isCollapsed: boolean;
  $childHeight: number;
}>`
  transition: ${TRANSITION_TIME_IN_SECONDS}s all;

  ${({ $isCollapsed, $childHeight }) =>
    $isCollapsed
      ? 'max-height: 0px;opacity: 0;'
      : `max-height: ${$childHeight}px;opacity: 1`}
`;
