import type { FdmFile } from '@cognite/apm-client';
import { Infobox } from '@cognite/cogs.js-v10';
import { useAppConfigQuery } from '@infield/features/app-config';
import { ListAccordion } from '@infield/features/asset/shared';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import type { FC } from 'react';
import { useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import * as S from './elements';

interface Props {
  documents: FdmFile[];
}

export const AssetDocumentList: FC<Props> = ({ documents }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.documents);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth = containerRef.current?.offsetWidth;
  const { data: appConfig } = useAppConfigQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const [expandedDocumentTypes, setExpandedDocumentTypes] =
    useState<string[]>();

  const documentConfiguration = appConfig?.featureConfiguration?.documents;

  const handleDocumentClick = (documentExternalId: string, space?: string) => {
    searchParams.set('viewDocId', encodeURIComponent(documentExternalId));
    if (space) {
      searchParams.set('viewDocSpace', space || '');
    }
    setSearchParams(searchParams, { replace: true });
  };

  const groupedDocumentTypes = useMemo(() => {
    const groupedDocumentsByType = groupBy(
      documents,
      documentConfiguration?.type ?? ''
    );

    const knownTypes = Object.keys(groupedDocumentsByType).filter(
      (group) => group !== 'undefined'
    );

    const sortedGroup: Record<string, FdmFile[]> = {};
    knownTypes.sort().forEach((key) => {
      sortedGroup[key] = groupedDocumentsByType[key];
    });

    const groupedDocuments: Record<string, FdmFile[] | undefined> = {
      ...sortedGroup,
      undefined: groupedDocumentsByType.undefined,
    };

    return groupedDocuments;
  }, [documents, documentConfiguration?.type]);

  const documentTypes = Object.keys(groupedDocumentTypes);

  const handleToggleDocumentTypes = (documentType: string) => {
    setExpandedDocumentTypes((prevExpanded) => {
      if (prevExpanded?.find((expanded) => expanded === documentType)) {
        return prevExpanded.filter((expanded) => expanded !== documentType);
      }
      return [...(prevExpanded ?? []), documentType];
    });
  };

  const validateExpandedDocumentTypes = (documentType: string) =>
    Boolean(
      expandedDocumentTypes?.find((expanded) => expanded === documentType)
    );

  const getDocumentTypeTitle = (documentType: string) => {
    if (documentType === 'undefined')
      return t('ASSET_DOCUMENTS_TYPE_UNKNOWN', 'Unknown document type');
    return documentType;
  };

  if (!documentConfiguration) {
    return (
      <Infobox>
        {t(
          'ASSET_DOCUMENTS_WARNING_NO_DOCUMENT_CONFIGURATION',
          'No documents will show since configuration is missing'
        )}
      </Infobox>
    );
  }

  return (
    <S.Container ref={containerRef}>
      {documentTypes.map((documentType) => {
        return (
          (groupedDocumentTypes[documentType] ?? []).length > 0 && (
            <ListAccordion
              key={documentType}
              title={getDocumentTypeTitle(documentType)}
              numberOfItems={groupedDocumentTypes[documentType]?.length || 0}
              expanded={validateExpandedDocumentTypes(documentType)}
              toggleExpand={() => handleToggleDocumentTypes(documentType)}
            >
              {groupedDocumentTypes[documentType]?.map((document) => (
                <S.DocumentContainer
                  key={document.externalId}
                  $containerWidth={containerWidth}
                >
                  <S.DocumentContent $containerWidth={containerWidth}>
                    <S.DocumentTitle
                      $containerWidth={containerWidth}
                      onClick={() =>
                        handleDocumentClick(document.externalId, document.space)
                      }
                    >
                      {documentConfiguration && documentConfiguration.title
                        ? get(document, documentConfiguration.title)
                        : null}
                    </S.DocumentTitle>
                    <S.DocumentDescription $containerWidth={containerWidth}>
                      {documentConfiguration &&
                      documentConfiguration.description
                        ? get(document, documentConfiguration.description)
                        : null}
                    </S.DocumentDescription>
                  </S.DocumentContent>
                </S.DocumentContainer>
              ))}
            </ListAccordion>
          )
        );
      })}
    </S.Container>
  );
};
