import { Body, Button, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const HeaderWrappper = styled(Flex).attrs({
  direction: 'column',
  justifyContent: 'center',
})`
  padding: 8px 16px;
  min-height: 56px;
`;

export const ObservationsContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: auto;
`;

export const ObservationWrapper = styled(Flex).attrs({
  gap: 8,
})`
  padding: 16px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  cursor: pointer;
`;

export const CreatedByBody = styled(Body)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CreationInfo = styled(Flex)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Footer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
})`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme['surface--strong']};
`;

export const FooterButton = styled(Button)`
  width: 100%;
`;
