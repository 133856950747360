import type { CogniteClient } from '@cognite/sdk';

import type { DocumentStatus } from '../../types';

export const GetDocumentStatus = async (
  documentIds: number[],
  sdk: CogniteClient
): Promise<DocumentStatus[]> => {
  const documentStatus = await sdk.post<{ items: DocumentStatus[] }>(
    `/api/v1/projects/${sdk.project}/documents/status`,
    {
      data: {
        items: documentIds.map((id) => ({
          id,
        })),
        includeStatistics: true,
      },
      headers: {
        'cdf-version': 'alpha',
      },
    }
  );

  return documentStatus.data.items;
};
