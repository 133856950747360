import {
  Body,
  EmptyState,
  Flex,
  Heading,
  Input,
  Skeleton,
  ThreeDIllustration,
} from '@cognite/cogs.js-v10';
import type { ThreeDModelIdentifier } from '@infield/features/3d';
import { use3dModelList } from '@infield/features/3d';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useFetchImageByUrl } from '@infield/features/media';
import type { FC } from 'react';

import * as S from './elements';

export type Props = {
  onClick: () => void;
  onlyImage?: boolean;
} & ThreeDModelIdentifier;

export const ModelDetails: FC<Props> = ({
  modelId,
  revisionId,
  onClick,
  onlyImage = false,
}): JSX.Element => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);
  const { data, isLoading } = use3dModelList();

  const revisionData = data?.find(
    (revision) =>
      revision.modelId === modelId && revision.revisionId === revisionId
  );

  const { data: thumbnailDataURL, isInitialLoading: isLoadingThumbnail } =
    useFetchImageByUrl(revisionData?.thumbnailURL);

  if (isLoading) {
    return <Skeleton.Rectangle width="388px" height="250px" />;
  }

  return (
    <Flex onClick={onClick} direction="column">
      <S.ImageWrapper>
        {isLoadingThumbnail && (
          <Skeleton.Rectangle width="388px" height="220px" />
        )}
        {!thumbnailDataURL && !isLoadingThumbnail && (
          <EmptyState
            illustration={<ThreeDIllustration />}
            title={t(
              'ASSET_OVERVIEW_THREED_CARD_NO_THUMBNAIL_TITLE',
              'No thumbnail to display'
            )}
            description={t(
              'ASSET_OVERVIEW_THREED_CARD_NO_THUMBNAIL_BODY',
              "The 3D model can still be accessed by pressing the 'Open' button."
            )}
          />
        )}
        {thumbnailDataURL && !isLoadingThumbnail && (
          <S.ImgStyled alt={revisionData?.name} src={thumbnailDataURL} />
        )}
      </S.ImageWrapper>

      {!onlyImage && (
        <Flex direction="column">
          <Heading level={3}>{revisionData?.name}</Heading>

          <Flex onClick={onClick} gap={8}>
            <Flex direction="column" gap={8}>
              <Body size="x-small" strong>
                modelId
              </Body>
              <Input fullWidth value={modelId} disabled />
              <Body size="x-small">3D viewer modelId</Body>
            </Flex>

            <Flex direction="column" gap={8}>
              <Body size="x-small" strong>
                revisionId
              </Body>
              <Input fullWidth value={revisionId} disabled />
              <Body size="x-small">3D viewer revisionId</Body>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
