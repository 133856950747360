import { z } from '@infield/utils/z';
import styled from 'styled-components';

export const ComponentWrapper = styled.div<{ fullPage: boolean }>`
  background-color: white;
  ${({ fullPage }) =>
    fullPage ? 'height: 100vh;  width: 100vw; padding: 0 8px;' : ''}
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: ${z.MAXIMUM};
`;
