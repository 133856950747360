import {
  AnnotationData,
  AnnotationModel as CogniteAnnotation,
} from '@cognite/sdk';

import {
  Annotation as UfvAnnotation,
  AnnotationType as UfvAnnotationType,
  Vertex as UfvVertex,
} from '../../../annotations/types';
import isNotUndefined from '../../isNotUndefined';
import getDefaultStylesByResourceType from '../getDefaultStylesByResourceType';

import {
  getBoundingBoxFromAnnotationData,
  getRefResourceType,
  isAnnotationDataWithBoundingBox,
  isAnnotationDataWithPoint,
  isAnnotationDataWithPolygon,
  isAnnotationDataWithPolyline,
} from './utils';

const DEFAULT_POINT_RADIUS = 0.01;

type GetMetadataFn<Metadata> = (annotation: CogniteAnnotation) => Metadata;

const getRectangleAnnotationFromCogniteAnnotation = <Metadata>(
  annotation: CogniteAnnotation,
  containerId: string,
  getMetadataFromCogniteAnnotation: GetMetadataFn<Metadata>
): UfvAnnotation<Metadata> => {
  const { xMin, yMin, xMax, yMax } = getBoundingBoxFromAnnotationData(
    annotation.data
  );
  return {
    id: String(annotation.id),
    containerId,
    type: UfvAnnotationType.RECTANGLE,
    x: xMin,
    y: yMin,
    width: xMax - xMin,
    height: yMax - yMin,
    style: getDefaultStylesByResourceType(getRefResourceType(annotation.data)),
    metadata: getMetadataFromCogniteAnnotation(annotation),
  };
};

const getPointAnnotationFromCogniteAnnotation = <Metadata>(
  annotation: CogniteAnnotation,
  containerId: string,
  getMetadataFromCogniteAnnotation: GetMetadataFn<Metadata>
): UfvAnnotation<Metadata> => {
  if (!isAnnotationDataWithPoint(annotation.data)) {
    throw Error(`The provided annotation data does not contain a point`);
  }
  return {
    id: String(annotation.id),
    containerId,
    type: UfvAnnotationType.ELLIPSE,
    ...annotation.data.position,
    radius: DEFAULT_POINT_RADIUS,
    style: getDefaultStylesByResourceType(getRefResourceType(annotation.data)),
    metadata: getMetadataFromCogniteAnnotation(annotation),
  };
};

const getVerticesFromAnnotationData = (data: AnnotationData): UfvVertex[] => {
  // We are guaranteed by the Annotations API that the vertices array is filled
  // given that the polyline or polygon key exists in the annotation.
  if (isAnnotationDataWithPolyline(data)) {
    return [...data.polyline!.vertices];
  }
  if (isAnnotationDataWithPolygon(data)) {
    // NOTE: As per Annotations API docs, Polygon primitives are assumed to have
    // the first and last vertex connected.
    const vertices = data.polygon!.vertices;
    return [...vertices, vertices[0]];
  }

  throw Error(
    'The provided annotation data does not contain a polyline or a polygon'
  );
};

const getPolylineAnnotationFromCogniteAnnotation = <Metadata>(
  annotation: CogniteAnnotation,
  containerId: string,
  getMetadataFromCogniteAnnotation: GetMetadataFn<Metadata>
): UfvAnnotation<Metadata> => {
  return {
    id: String(annotation.id),
    containerId,
    type: UfvAnnotationType.POLYLINE,
    vertices: getVerticesFromAnnotationData(annotation.data),
    style: getDefaultStylesByResourceType(getRefResourceType(annotation.data)),
    metadata: getMetadataFromCogniteAnnotation(annotation),
  };
};

const getAnnotationFromObjectDetectionAnnotation = <Metadata>(
  annotation: CogniteAnnotation,
  containerId: string,
  getMetadataFromCogniteAnnotation: GetMetadataFn<Metadata>
) => {
  const data = annotation.data;
  if (isAnnotationDataWithBoundingBox(data)) {
    return getRectangleAnnotationFromCogniteAnnotation(
      annotation,
      containerId,
      getMetadataFromCogniteAnnotation
    );
  }
  if (isAnnotationDataWithPolyline(data) || isAnnotationDataWithPolygon(data)) {
    return getPolylineAnnotationFromCogniteAnnotation(
      annotation,
      containerId,
      getMetadataFromCogniteAnnotation
    );
  }

  throw Error(
    "Provided object detection annotation doesn't have a bounding box, polygon or a polyline"
  );
};

export const getAnnotationFromCogniteAnnotation = <Metadata>(
  annotation: CogniteAnnotation,
  containerId: string,
  getMetadataFromCogniteAnnotation: GetMetadataFn<Metadata>
): UfvAnnotation<Metadata> | undefined => {
  if (annotation.annotatedResourceType !== 'file') {
    // eslint-disable-next-line no-console
    console.warn(
      'getAnnotationFromCogniteAnnotation currently only supports file annotations'
    );
    return undefined;
  }
  switch (annotation.annotationType) {
    // Diagrams annotations
    case 'diagrams.AssetLink':
    case 'diagrams.FileLink':
    case 'diagrams.UnhandledTextObject':
    case 'diagrams.UnhandledSymbolObject': {
      return getRectangleAnnotationFromCogniteAnnotation(
        annotation,
        containerId,
        getMetadataFromCogniteAnnotation
      );
    }
    case 'diagrams.Line': {
      return getPolylineAnnotationFromCogniteAnnotation(
        annotation,
        containerId,
        getMetadataFromCogniteAnnotation
      );
    }
    case 'diagrams.Junction': {
      return getPointAnnotationFromCogniteAnnotation(
        annotation,
        containerId,
        getMetadataFromCogniteAnnotation
      );
    }
    // Forms annotations
    case 'forms.Detection': {
      return getRectangleAnnotationFromCogniteAnnotation(
        annotation,
        containerId,
        getMetadataFromCogniteAnnotation
      );
    }
    // Document annotations
    case 'documents.ExtractedText': {
      return getRectangleAnnotationFromCogniteAnnotation(
        annotation,
        containerId,
        getMetadataFromCogniteAnnotation
      );
    }
    // Image annotations
    case 'images.ObjectDetection': {
      // NOTE: special handling for object detection annotations, since they may
      // contain *one of* polylines, polygons and bounding boxes.
      return getAnnotationFromObjectDetectionAnnotation(
        annotation,
        containerId,
        getMetadataFromCogniteAnnotation
      );
    }
    case 'images.TextRegion':
    case 'images.AssetLink': {
      return getRectangleAnnotationFromCogniteAnnotation(
        annotation,
        containerId,
        getMetadataFromCogniteAnnotation
      );
    }

    default: {
      return undefined;
    }
  }
};

const getAnnotationsFromCogniteAnnotations = <Metadata>(
  annotations: CogniteAnnotation[],
  containerId: string,
  getMetadata: GetMetadataFn<Metadata>
): UfvAnnotation<Metadata>[] =>
  annotations
    .map((annotation) =>
      getAnnotationFromCogniteAnnotation(annotation, containerId, getMetadata)
    )
    .filter(isNotUndefined);

export default getAnnotationsFromCogniteAnnotations;
