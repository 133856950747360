import { ImageMimeType } from '../containers/types';

import { FileData, readBlobAsDataUrl } from './getFileDataFromDropEvent';
import isNotUndefined from './isNotUndefined';

const getFileDataFromSystemClipboard = async (
  clipboard: Clipboard
): Promise<FileData[]> => {
  const items = await clipboard.read();
  // Note: No known cases where `clipboard.read` returns multiple file data
  const fileData: (FileData | undefined)[] = await Promise.all(
    items.map(async (item) => {
      const mimeType = item.types.find((type) =>
        Object.values<string>(ImageMimeType).includes(type)
      );
      if (mimeType === undefined) {
        return undefined;
      }
      const blob = await item.getType(mimeType);
      const dataUrl = await readBlobAsDataUrl(blob);
      if (dataUrl === undefined) {
        // eslint-disable-next-line no-console
        console.warn(
          `Was not able to generate data url for the clipboard entry`
        );
        return undefined;
      }
      return { dataUrl, mimeType };
    })
  );
  return fileData.filter(isNotUndefined);
};

export default getFileDataFromSystemClipboard;
