import {
  getElementTransform,
  getHtmlElementAncestors,
  getTransformOrigin,
} from './domUtils';

/**
 * Represents the affine transformation of the computed css transform property.
 * https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/matrix
 */
export type TransformMatrix = [number, number, number, number, number, number];
export type TranslateVector = [number, number];

const IDENTITY: TransformMatrix = [1, 0, 0, 1, 0, 0];

/**
 * Returns a `TransformMatrix` representing the cumulative transformation of
 * the element and all its parents. This transform converts from top-level
 * clientX/clientY coordinates (such as document mouse events) to internal
 * component offsetX/offsetY coordinates.
 */
export const getCumulativeTransform = (element: Element): TransformMatrix => {
  const elems = getHtmlElementAncestors(element);

  let transform = IDENTITY;
  let offsetParent: Element | null = null;
  elems.forEach((elem) => {
    // Apply css transform from any ancestor element
    const elementTransform = getElementTransform(elem);

    if (elementTransform != null) {
      const transformOrigin = getTransformOrigin(elem);
      const originX = transformOrigin.x ?? elem.clientWidth / 2;
      const originY = transformOrigin.y ?? elem.clientHeight / 2;
      transform = multiplyTranslate(transform, [originX, originY]);
      transform = multiplyMatrix(transform, invertMatrix(elementTransform));
      transform = multiplyTranslate(transform, [-originX, -originY]);
    }

    // Apply scroll offsets from any ancestor element
    let offsetX = elem.scrollLeft;
    let offsetY = elem.scrollTop;

    // Apply client+offset from only ancestor "offsetParent"
    if (offsetParent === null || elem === offsetParent) {
      offsetX -= elem.offsetLeft + elem.clientLeft;
      offsetY -= elem.offsetTop + elem.clientTop;
      offsetParent = elem.offsetParent;
    }
    transform = multiplyTranslate(transform, [offsetX, offsetY]);
  });
  return transform;
};

export const multiplyMatrix = (
  a: TransformMatrix,
  b: TransformMatrix
): TransformMatrix => {
  return [
    a[0] * b[0] + a[2] * b[1],
    a[1] * b[0] + a[3] * b[1],
    a[0] * b[2] + a[2] * b[3],
    a[1] * b[2] + a[3] * b[3],
    a[0] * b[4] + a[2] * b[5] + a[4],
    a[1] * b[4] + a[3] * b[5] + a[5],
  ];
};

/**
 * Convenience method for appending a translation to a transformation matrix.
 * Equivalent to `multiplyMatrix(m, [1, 0, 0, 1, ...v])`
 */
export const multiplyTranslate = (
  m: TransformMatrix,
  t: TranslateVector
): TransformMatrix => {
  return [
    m[0],
    m[1],
    m[2],
    m[3],
    m[0] * t[0] + m[2] * t[1] + m[4],
    m[1] * t[0] + m[3] * t[1] + m[5],
  ];
};

/**
 * http://mathworld.wolfram.com/MatrixInverse.html
 * https://stackoverflow.com/questions/2624422/efficient-4x4-matrix-inverse-affine-transform
 */
export const invertMatrix = (m: TransformMatrix): TransformMatrix => {
  const determinant = m[0] * m[3] - m[1] * m[2];

  if (determinant === 0) {
    throw new Error('Singular matrices cannot be inverted');
  }

  const inverseDeterminant = 1 / determinant;
  return [
    inverseDeterminant * m[3],
    inverseDeterminant * -m[1],
    inverseDeterminant * -m[2],
    inverseDeterminant * m[0],
    inverseDeterminant * (-m[3] * m[4] + m[2] * m[5]),
    inverseDeterminant * (m[1] * m[4] + -m[0] * m[5]),
  ];
};

/**
 * Applies the `TransformMatrix` m to the point p.
 * Returns the transformed point.
 */
export const applyTransform = (
  m: TransformMatrix,
  p: { x: number; y: number }
): { x: number; y: number } => ({
  x: m[0] * p.x + m[2] * p.y + m[4],
  y: m[1] * p.x + m[3] * p.y + m[5],
});
