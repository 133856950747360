import { Heading, Loader } from '@cognite/cogs.js-v10';
import { useFlag } from '@cognite/react-feature-flags';
import {
  useFeatureToggleConfig,
  useLocations,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { AppConfigErrorScreen } from '@infield/features/app-config/app-config-error-screen';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { useCurrentUserQuery } from '@infield/features/user';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import {
  ActivityChecklistMobile,
  ActivityDesktop,
  ActivityDetailsView,
  ActivityPlanningView,
} from '@infield/pages/activity';
import {
  Asset3d,
  AssetActivities,
  AssetDetails,
  AssetDocuments,
  AssetNotifications,
  AssetProperties,
  AssetTrends,
} from '@infield/pages/asset';
import { TemplatesOverview } from '@infield/pages/template';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { type FunctionComponent, useEffect } from 'react';
import { Navigate, Routes as ReactRoutes, Route } from 'react-router-dom';

import { ActivityListView } from './activity/activity-list-view';
import { AppConfigView } from './app-config';
import { LandingPage } from './landing-page';
import { NotificationDetailsView } from './notification';
import { ObservationPageDesktop, ObservationsOverview } from './observation';
import { ChecklistItemObservationListMobile } from './observation/checklist-item-observation-list-mobile';
import { ObservationDetailsMobile } from './observation/observation-details-mobile';
import { PageLayout } from './page-layout';
import { PageNotFound } from './page-not-found';
import { UserProfilePage } from './user-profile-page';
import {
  getIsChecklistPageEnabled,
  getIsObservationsPageEnabled,
  getIsOverviewPageEnabled,
  getIsTemplatesPageEnabled,
} from './utils';

export const Routes: FunctionComponent = () => {
  const { isIdm } = useAppConfigContext();
  const isDesktop = useIsDesktop();
  const { isEnabled: isSearchFeatureEnabled } = useFlag(
    'INFIELD_SEARCH_EVERYTHING_IS_ENABLED'
  );
  const isChecklistPageHidden = isDesktop && isSearchFeatureEnabled;
  const { t } = useTranslation(LOCIZE_NAMESPACES.user);

  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { data: userData, isLoading: isLoadingCurrentUser } =
    useCurrentUserQuery();
  const { handleSaveLocation } = useLocations();

  const {
    config: rootLocationFeatureToggles,
    isLoading: isFeatureConfigLoading,
  } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );

  useEffect(() => {
    if (isFeatureConfigLoading) return;
    if (selectedRootLocation !== undefined) return;

    const userLocationPreference = userData?.preferences?.infield?.location;
    if (userLocationPreference === undefined) return;

    // We have no valid selected root location. It might be because the user preference location is not within the config.
    // Clear the preferences to find out.
    handleSaveLocation(undefined);
  }, [
    handleSaveLocation,
    isFeatureConfigLoading,
    selectedRootLocation,
    userData?.preferences?.infield?.location,
  ]);

  const isObservationsFeatureEnabled = getIsObservationsPageEnabled(
    rootLocationFeatureToggles
  );
  const isOverviewPageEnabled = getIsOverviewPageEnabled(
    rootLocationFeatureToggles
  );
  const isChecklistPageEnabled = getIsChecklistPageEnabled(
    rootLocationFeatureToggles
  );
  const isTemplatesPageEnabled = getIsTemplatesPageEnabled(
    rootLocationFeatureToggles
  );

  const getLandingPagePath = () => {
    if (isChecklistPageEnabled && !isChecklistPageHidden) {
      return '/checklists';
    }
    if (isDesktop) {
      if (isIdm) {
        return '/application-configuration';
      }
      if (selectedRootLocation) {
        return `/asset/${selectedRootLocation.assetExternalId}`;
      }
    }
    return '/explore';
  };

  if (isFeatureConfigLoading || isLoadingCurrentUser) {
    return (
      <Loader
        infoText={
          <Heading level={6}>
            {t(
              'loadingFeatureConfiguration',
              'Loading configured application features...'
            )}
          </Heading>
        }
      />
    );
  }

  if (selectedRootLocation === undefined) {
    return (
      <AppConfigErrorScreen
        title={t(
          'USER_ERROR_LOCATION_MISSING_TITLE',
          'Error: Location is missing.'
        )}
        body={t(
          'USER_ERROR_LOCATION_MISSING_DESCRIPTION',
          "The InField admin has not defined any location yet, or you dont't have access to any of the configured locations. Contact your admin if you have any questions."
        )}
      />
    );
  }

  return (
    <ReactRoutes>
      <Route path="/" element={<PageLayout page="mobile-explore" />}>
        <Route
          path="/"
          element={
            !selectedRootLocation ? (
              <PageLayout />
            ) : (
              <Navigate to={getLandingPagePath()} />
            )
          }
        />
        <Route path="/explore" element={<LandingPage />} />
      </Route>

      <Route path="/" element={<PageLayout />}>
        <Route path="/application-configuration" element={<AppConfigView />} />
        <Route path="/profile" element={<UserProfilePage />} />
        {isChecklistPageEnabled && !isChecklistPageHidden && (
          <Route
            path="/checklists"
            element={isDesktop ? <ActivityDesktop /> : <ActivityListView />}
          />
        )}
        {isOverviewPageEnabled && (
          <Route path="/overview" element={<ActivityPlanningView />} />
        )}
        {isObservationsFeatureEnabled && (
          <Route
            path="/checklistItem/:checklistItemId/observations"
            element={<ChecklistItemObservationListMobile />}
          />
        )}
        {rootLocationFeatureToggles?.workorders && (
          <Route
            path="/activity/:activityId/details"
            element={<ActivityDetailsView />}
          />
        )}
        {isTemplatesPageEnabled && (
          <Route path="/templates" element={<TemplatesOverview />} />
        )}
        {isObservationsFeatureEnabled && (
          <Route path="/observations" element={<ObservationsOverview />} />
        )}
        <Route path="/asset/:assetId" element={<AssetDetails />} />
        {rootLocationFeatureToggles?.threeD && (
          <Route path="/asset/:assetId/3d" element={<Asset3d />} />
        )}
        {rootLocationFeatureToggles?.trends && (
          <Route path="/asset/:assetId/trends" element={<AssetTrends />} />
        )}
        <Route
          path="/asset/:assetId/properties"
          element={<AssetProperties />}
        />
        {rootLocationFeatureToggles?.workorders && (
          <Route
            path="/asset/:assetId/activities"
            element={<AssetActivities />}
          />
        )}
        {rootLocationFeatureToggles?.notifications && (
          <Route
            path="/asset/:assetId/notifications"
            element={<AssetNotifications />}
          />
        )}
        {rootLocationFeatureToggles?.notifications && (
          <Route
            path="/notification/:notificationId/details"
            element={<NotificationDetailsView />}
          />
        )}
        {rootLocationFeatureToggles?.documents && (
          <Route
            path="/asset/:assetId/documents"
            element={<AssetDocuments />}
          />
        )}
      </Route>

      {isChecklistPageEnabled && (
        <Route path="/" element={<PageLayout page="mobile-checklist" />}>
          <Route
            id="checklist"
            path="/activity/:activityId/checklist"
            element={<ActivityChecklistMobile />}
          />
        </Route>
      )}

      {isObservationsFeatureEnabled && (
        <Route path="/" element={<PageLayout page="mobile-observation" />}>
          <Route
            path="/observation"
            element={
              isDesktop ? (
                <ObservationPageDesktop />
              ) : (
                <ObservationDetailsMobile />
              )
            }
          />
          <Route
            path="/observation/:observationExternalId"
            element={
              isDesktop ? (
                <ObservationPageDesktop />
              ) : (
                <ObservationDetailsMobile />
              )
            }
          />
        </Route>
      )}
      {/* Catch-all route for 404 Not Found */}
      <Route path="*" element={<PageNotFound />} />
    </ReactRoutes>
  );
};
