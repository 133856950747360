import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import type { ObservationsPage } from '@infield/features/observation';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/browser';
import type { QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

export const useObservationsInfiniteQuery = (
  filter?: Filters,
  sort?: Sort,
  options?: Omit<
    UseInfiniteQueryOptions<
      ObservationsPage,
      Error,
      ObservationsPage,
      ObservationsPage,
      QueryKey
    >,
    'queryKey' | 'queryFn' | 'getNextParam'
  >
) => {
  const { observationService } = useFDMServices();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);
  const { observation: configFilters } = useAppConfigFiltersContext();

  const filters: Filters[] = [];

  if (filter) {
    filters.push({ ...filter });
  }

  const observationsFilter: Filters | undefined = {
    and: [...filters],
  };

  if (configFilters.rootAssetExternalIds) {
    observationsFilter.and?.push(configFilters.rootAssetExternalIds);
  }

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  const { data, ...rest } = useInfiniteQuery<ObservationsPage, Error>(
    [QueryKeys.OBSERVATION_INFINITE, sort, observationsFilter],
    async ({ pageParam = undefined }) => {
      sliTimerStartTime.current = Date.now();

      const returnedObservations =
        await observationService.getObservationsPaginated(
          observationsFilter,
          sort,
          pageParam
        );

      return returnedObservations;
    },
    {
      ...options,
      enabled: Boolean(configFilters.rootAssetExternalIds),
      getNextPageParam: (lastPage) =>
        lastPage?.pageInfo.hasNextPage
          ? lastPage.pageInfo.endCursor
          : undefined,
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.OBSERVATION_INFINITE,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.OBSERVATION_INFINITE, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.OBSERVATION_INFINITE, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
    }
  );

  const observations = data?.pages.flatMap((page) => page.items) || [];

  return { observations, ...rest };
};
