import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 48px;
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 8px;
  padding-bottom: 48px;
`;

export const AssetExplorationWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  max-width: 400px;
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme['border--muted']};
`;
