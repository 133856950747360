import { Body, Flex } from '@cognite/cogs.js-v10';
import { BREAKPOINT_PAD_PORTRAIT } from '@infield/providers/theme';
import styled from 'styled-components';

import { assetLinkStyle } from '../../shared/elements';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const isFullWidth = (width: number | undefined) => {
  return width !== undefined && width > BREAKPOINT_PAD_PORTRAIT;
};

export const Row = styled(Flex)<{ $containerWidth: number | undefined }>`
  flex-direction: ${({ $containerWidth }) =>
    isFullWidth($containerWidth) ? 'row' : 'column'};
  padding: ${({ $containerWidth }) =>
    isFullWidth($containerWidth) ? '0' : '6px 16px'};
  height: 58px;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme['surface--muted']};
  }
  &:nth-child(even) {
    background-color: ${({ theme }) => theme['surface--medium']};
  }
`;

export const LeftColumn = styled(Flex)<{ $containerWidth: number | undefined }>`
  height: 100%;
  width: 280px;
  align-items: center;
  ${({ theme, $containerWidth }) => {
    if (isFullWidth($containerWidth)) {
      return `border-right: 1px solid ${theme['border--muted']};
        flex-shrink: 0;
        `;
    }
  }}
`;

export const RightColumn = styled(Flex)`
  height: 100%;
  min-width: 0;
  align-items: center;
  flex-grow: 1;
`;

type ListItemProps = {
  $containerWidth: number | undefined;
  $isHeader?: boolean;
  $isClickable?: boolean;
};

export const ListItem = styled(Body).attrs({
  size: 'medium',
})<ListItemProps>`
  width: fit-content;
  overflow-wrap: break-word;
  padding: ${({ $containerWidth }) =>
    isFullWidth($containerWidth) ? '6px 16px' : '0'};
  color: ${({ theme, $isHeader }) =>
    $isHeader ? theme['text-icon--strong'] : theme['text-icon--muted']};
  ${({ $isClickable }) => ($isClickable ? assetLinkStyle : '')}
`;
