import type { FdmFile } from '@cognite/apm-client';
import { useFlag } from '@cognite/react-feature-flags';
import type { FileFilterProps } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

export const useAssetDocumentsQuery = (
  assetExternalId?: string,
  limit = 500
) => {
  const { mediaService } = useFDMServices();

  const { files: configFilters } = useAppConfigFiltersContext();

  const { isEnabled: isAkerbpCustomCode } = useFlag(
    'INFIELD.akerbp_custom_code',
    { forceRerender: true, fallback: false }
  );

  const filesFilter: FileFilterProps & { assetExternalIds?: string[] } = {
    assetExternalIds: assetExternalId ? [assetExternalId] : undefined,
    ...configFilters?.cdfClassicFilters,
  };

  return useQuery<FdmFile[]>(
    [QueryKeys.ASSET_DOCUMENTS, assetExternalId, filesFilter, limit],
    async () => {
      return mediaService.getDocuments(filesFilter, limit, isAkerbpCustomCode);
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.ASSET_DOCUMENTS,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(assetExternalId),
    }
  );
};
