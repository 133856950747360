import { showGlobalOverlayAtom } from '@infield/features/ui';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import * as S from './elements';

type Props = {
  isOnline: boolean;
  isOnChecklistPageOnMobile: boolean;
};

export const GlobalOverlay: FC<Props> = ({
  isOnline,
  isOnChecklistPageOnMobile,
}) => {
  const showOverlay = useRecoilValue<boolean>(showGlobalOverlayAtom);
  const { pathname } = useLocation();
  const isDesktop = useIsDesktop();

  const visible = () => {
    if (showOverlay) {
      return true;
    }
    if (!isOnline) {
      if (!isDesktop && !isOnChecklistPageOnMobile) {
        return true;
      }
      if (isDesktop && !pathname.endsWith('/checklists')) {
        return true;
      }
    }
    return false;
  };

  return (
    <S.GlobalOverlay
      data-testid="offline-mode-global-overlay"
      visible={visible()}
      onTouchEnd={(e) => e.stopPropagation()}
    />
  );
};
