import { Body, Flex, Heading } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

type ContainerProps = {
  $expandOverflow: boolean | undefined;
};

export const Container = styled(Flex).attrs({
  alignItems: 'center',
  gap: 12,
})<ContainerProps>`
  padding: 10px 16px;
  width: 100%;
  background-color: ${({ theme }) => theme['surface--muted']};
  border-bottom: ${({ theme }) => `1px solid ${theme['border--muted']}`};
  ${({ $expandOverflow }) =>
    !$expandOverflow && `min-height: 57px; max-height: 57px;`};
`;

export const TitleContainer = styled(Flex).attrs({
  direction: 'column',
})`
  min-width: 0px;
`;

export const Title = styled(Heading).attrs({
  level: 5,
})<{
  $overflow: boolean | undefined;
  $expandOverflow: boolean | undefined;
  $clickable: boolean | undefined;
}>`
  max-width: 100%;
  ${({ $overflow, $expandOverflow }) =>
    $overflow &&
    !$expandOverflow &&
    `overflow: hidden; text-overflow: ellipsis; white-space: nowrap;`};
  ${({ $clickable }) =>
    $clickable && `color: var(--cogs-text-icon--interactive--default);`};
  ${({ $clickable }) => $clickable && 'cursor: pointer'};
`;

export const Description = styled(Body)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TitleAndSegmentedControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  overflow: hidden;
`;

export const PanelActionsWrapper = styled(Flex)<{
  $childrenPosition?: 'start' | `end`;
}>`
  flex: 1;
  ${({ $childrenPosition }) =>
    $childrenPosition === 'start' ? `` : `justify-content: end;`};
`;
