import { Body, Button, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
  gap: 12,
})`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
  &:nth-child(even) {
    background-color: ${({ theme }) => theme['surface--medium']};
  }
`;

export const Information = styled(Flex).attrs({
  gap: 12,
})`
  width: 100%;
  min-width: 288px;
  justify-content: space-between;
  align-items: flex-start;
`;

export const TaskInformation = styled(Flex).attrs({
  direction: 'column',
  gap: 4,
})`
  width: 100%;
  min-width: 248px;
`;

export const AssetInformation = styled(Flex).attrs({
  direction: 'column',
})`
  flex: 1;
  min-width: 248px;
`;

export const AssetName = styled(Body).attrs({
  level: 2,
  strong: true,
})<{
  disabled?: boolean;
}>`
  max-width: 170px;
  color: ${({ disabled }) =>
    disabled
      ? 'var(--cogs-text-icon--interactive--disabled)'
      : 'var(--cogs-border--interactive--toggled-default)'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const HighlightedAssetName = styled.span<{
  $active: boolean;
}>`
  ${({ $active }) => $active && 'background: rgb(255, 237, 184);'}
`;

export const AssetDescription = styled(Body).attrs({
  size: 'small',
})`
  min-width: 248px;
  color: ${({ theme }) => theme['text-icon--muted']};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const OperationTitle = styled(Body).attrs({
  level: 2,
  strong: true,
})`
  color: ${({ theme }) => theme['text-icon--strong']};
`;

export const OperationDescription = styled.div`
  border: 1px solid var(--cogs-border--interactive--default);
  border-radius: var(--cogs-border-radius--default);
  padding: 8px;
  margin-top: 4px;
`;

export const OperationDescriptionText = styled(Body).attrs({
  size: 'medium',
})`
  color: ${({ theme }) => theme['text-icon--strong']};
  white-space: pre-line;
  overflow: hidden;
`;

export const DescriptionContainer = styled.div<{
  $expanded: boolean;
}>`
  max-height: ${({ $expanded }) => ($expanded ? 'fit-content' : '100px')};
`;

export const ExpandDescriptionButton = styled(Button)`
  width: 100%;
`;

export const PrimaryStatusButtonWrapper = styled.div`
  flex-shrink: 0;
`;

export const OtherStatusesButton = styled(Button)`
  flex-basis: 108px;
  flex-shrink: 1;
  max-height: 38px;
`;

export const AdditionalReportsButton = styled(Button)`
  flex-shrink: 0;
  max-height: 36px;
`;

export const StatusButtonsWrapper = styled(Flex).attrs({ gap: 8 })`
  margin-top: 18px;
`;
