import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex)`
  height: 100%;
`;

export const ActivityListContainer = styled.div`
  width: 100%;
  max-width: 320px;
  border-right: 1px solid ${({ theme }) => theme['border--muted']};
  position: relative;
`;

export const ActivityDetailsContainer = styled.div`
  width: 100%;
  max-width: 320px;
  border-right: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const AssetContainer = styled.div`
  min-width: 0px;
  height: 100%;
  width: 100%;
  border-right: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const ChecklistContainer = styled.div`
  border-right: 1px solid ${({ theme }) => theme['border--muted']};
  position: relative;
  height: 100%;
  overflow-y: auto;
  box-sizing: content-box;
`;

export const ChecklistOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--cogs-surface--interactive--disabled);
  display: inline-block;
  top: 0;
  left: 0;
`;
