import {
  DocumentIcon,
  EmptyState,
  PlansIllustration,
  Skeleton,
} from '@cognite/cogs.js-v10';
import type { Filters } from '@cognite/fdm-client/src/types';
import { useAssetActivitiesQuery } from '@infield/features/activities';
import { useAppConfigQuery } from '@infield/features/app-config';
import { AssetCard } from '@infield/features/asset/shared/asset-card/asset-card';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { EmptyStateWrapper } from '@infield/features/ui/table-empty-state/elements';
import type { FC, ReactNode } from 'react';

import { AssetActivitiesList } from './asset-activities-list';

const NUMBER_OF_ACTIVITIES_TO_FIT_CARD = 6;

interface Props {
  assetExternalId: string;
  onOpen?: () => void;
  onActivityClick: (activityExternalId: string) => void;
}

export const AssetActivitiesCard: FC<Props> = ({
  assetExternalId,
  onOpen,
  onActivityClick,
}) => {
  const { data: appConfig } = useAppConfigQuery();
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const activitiesConfiguration = appConfig?.featureConfiguration?.activities;

  const getFiltersFromConfiguration = () => {
    return Object.keys(
      activitiesConfiguration?.overviewCard?.filters || {}
    ).reduce((acc, field) => {
      if (activitiesConfiguration?.overviewCard?.filters)
        return [
          ...acc,
          {
            or: activitiesConfiguration?.overviewCard?.filters[field].map(
              (f) => ({
                equals: { property: field, eq: f.value },
              })
            ),
          },
        ];

      return acc;
    }, [] as Filters[]);
  };

  const { data: filteredActivities, isInitialLoading } =
    useAssetActivitiesQuery(
      assetExternalId,
      getFiltersFromConfiguration(),
      NUMBER_OF_ACTIVITIES_TO_FIT_CARD
    );
  // use 1 limit as we are just checking if there are any activities
  const { data: activities } = useAssetActivitiesQuery(assetExternalId, [], 1);

  const hasFilteredActivities =
    filteredActivities && filteredActivities.length > 0;
  const hasActivities = activities && activities.length > 0;

  let content: ReactNode;
  if (isInitialLoading) {
    content = <Skeleton.List lines={NUMBER_OF_ACTIVITIES_TO_FIT_CARD} />;
  } else if (!hasFilteredActivities) {
    content = (
      <EmptyStateWrapper>
        <EmptyState
          illustration={<PlansIllustration />}
          title={t(
            'ASSET_OVERVIEW_ACTIVITIES_CARD_NO_ACTIVITY_TITLE',
            'No work orders'
          )}
          description={t(
            'ASSET_OVERVIEW_ACTIVITIES_CARD_NO_ACTIVITY_BODY',
            'There are no work orders found on this asset.'
          )}
        />
      </EmptyStateWrapper>
    );
  } else {
    content = (
      <AssetActivitiesList
        activities={filteredActivities}
        onActivityClick={onActivityClick}
      />
    );
  }

  return (
    <AssetCard
      icon={<DocumentIcon />}
      title={t('ASSET_OVERVIEW_ACTIVITIES_CARD_TITLE', 'Work orders')}
      buttonText={t('ASSET_OVERVIEW_ACTIVITIES_CARD_BUTTON_OPEN', 'Open')}
      onButtonClick={!isInitialLoading && hasActivities ? onOpen : undefined}
    >
      {content}
    </AssetCard>
  );
};
