import type { APMAsset } from '@cognite/apm-client';
import type { OptionType } from '@cognite/cogs.js-v10';
import { Select } from '@cognite/cogs.js-v10';
import type { Asset } from '@cognite/sdk';
import { useRootAssetList } from '@infield/features/asset';
import { useRootAPMAssetList } from '@infield/features/asset/hooks/use-query/use-root-apm-asset-list';
import { getTitle } from '@infield/features/search/search-result/utils';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import type { FC } from 'react';

interface RootLocationDropdownProps {
  hasAppConfigPermission: boolean;
  disableInputs?: boolean;
  onAssetChange: (asset: Asset | APMAsset) => void;
  externalId?: string;
}

export const RootLocationDropdown: FC<RootLocationDropdownProps> = ({
  hasAppConfigPermission,
  disableInputs,
  onAssetChange,
  externalId,
}): JSX.Element => {
  const { isIdm } = useAppConfigContext();
  const { data: classicAssets = [] } = useRootAssetList({
    enabled: !isIdm,
  });
  const { data: apmAssets = [] } = useRootAPMAssetList({
    enabled: isIdm,
  });

  const assets: (Asset | APMAsset)[] = !isIdm ? classicAssets : apmAssets;

  const selectOptions = assets.map((asset) => ({
    value: asset.externalId,
    label: getTitle(asset),
  }));

  const selectedAsset = selectOptions.find(
    (asset) => asset.value === externalId
  );

  const handleAssetChange = (newAsset: OptionType<string>) => {
    const newSelectedAsset = assets.find(
      (asset: Asset | APMAsset) => asset.externalId === newAsset.value
    );
    if (newSelectedAsset) onAssetChange(newSelectedAsset);
  };

  return (
    <Select
      options={selectOptions}
      value={selectedAsset}
      onChange={handleAssetChange}
      disabled={!hasAppConfigPermission || disableInputs}
    />
  );
};
