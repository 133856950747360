import type { FC } from 'react';

import * as S from './elements';

type Props = {
  items: ListItem[];
};

export type ListItem = {
  key: string;
  content: React.ReactNode;
  isSelected?: boolean;
  onClick?: () => void;
};

export const StyledList: FC<Props> = ({ items }) => {
  return (
    <S.List>
      {items.map(({ key, content, isSelected, onClick }) => {
        return (
          <S.ListItem
            key={key}
            $isSelected={isSelected}
            $isClickable={Boolean(onClick)}
            onClick={onClick}
          >
            {content}
          </S.ListItem>
        );
      })}
    </S.List>
  );
};
