import {
  CoreTimeseriesEntryCollection,
  CoreTimeseriesEntry,
} from '../../../models/core-timeseries-results';
import { ChartThreshold, CoreTimeseries } from '../../../types';
import { convertThresholdUnits, convertUnits, units } from '../../../utils';

import { SeriesData, SeriesDataCollection } from './types';

const getTimeseriesUnitLabel = (
  coreTimeSeriesData: CoreTimeseriesEntry | undefined
) =>
  units.find(
    (unitOption) =>
      unitOption.value === coreTimeSeriesData?.preferredUnit?.toLowerCase()
  )?.label ||
  coreTimeSeriesData?.customUnitLabel ||
  coreTimeSeriesData?.unit;

const createSeriesDataFromCoreTimeSeries = (
  coreTimeSeries: CoreTimeseriesEntry
): SeriesData => ({
  id: coreTimeSeries.id,
  name: coreTimeSeries.name || '',
  type: 'timeseries',
  style: {
    color: coreTimeSeries.color,
    size: coreTimeSeries.lineWeight || 1,
    lineStyle: coreTimeSeries.lineStyle || 'solid',
  },
  isDataOutdated: coreTimeSeries.loading ?? false,

  datapoints: convertUnits(
    coreTimeSeries.series?.datapoints || [],
    coreTimeSeries.unit,
    coreTimeSeries.preferredUnit
  ),
  interpolation: coreTimeSeries.interpolation,
});

export function calculateSeriesDataFromCoreTimeSeries(
  coreTimeSeriesCollection: CoreTimeseries[],
  coreTimeSeriesDataCollection: CoreTimeseriesEntryCollection,
  thresholds: ChartThreshold[]
): SeriesDataCollection[] {
  const seriesDataCollections: SeriesDataCollection[] = [];

  for (const timeSeries of coreTimeSeriesCollection) {
    const coreTimeSeries = coreTimeSeriesDataCollection.find(
      (ts) => ts.id === timeSeries.id
    );

    if (!coreTimeSeries) {
      continue;
    }

    const unitLabel = getTimeseriesUnitLabel(coreTimeSeries);
    const seriesData = createSeriesDataFromCoreTimeSeries(coreTimeSeries);

    let seriesThresholds = thresholds.filter(
      (th) => th.sourceId === coreTimeSeries.id
    );

    if (seriesThresholds.length > 0) {
      seriesThresholds = convertThresholdUnits(
        seriesThresholds,
        coreTimeSeries.unit,
        coreTimeSeries.preferredUnit
      );
    }

    seriesDataCollections.push({
      unit: unitLabel,
      range: coreTimeSeries.range,
      thresholds: seriesThresholds,
      series: [seriesData],
      isVisible: coreTimeSeries.enabled,
    });
  }

  return seriesDataCollections;
}
