import { Position } from '../annotations/types';
import UnifiedViewerEventType from '../UnifiedViewerEventType';
import { UnifiedViewerPointerEvent } from '../UnifiedViewerRenderer/UnifiedEventHandler';
import {
  isMouseEvent,
  isPrimaryMouseButtonPressed,
  isSingleTouch,
  isTouchEvent,
} from '../utils/eventUtils';
import getEuclideanDistance from '../utils/getEuclideanDistance';

import Tool from './Tool';
import { ToolType } from './types';

// If the mouse has moved – between a mousedown and mousemove event – more than
// this limit, the final mouse operation is *not* counted as a click.
const MAX_MOUSE_DELTA_PIXELS = 5;

export default class PanTool extends Tool {
  public setConfig(): void {
    // No-op
  }

  public readonly type = ToolType.PAN;
  public override cursor = 'grab';
  private isPanning: boolean = false;
  private mouseDownPosition: Position | undefined = undefined;

  private onPanStart = (): void => {
    this.unifiedViewer.stage.startDrag();
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_PAN_START);
    this.unifiedViewer.onViewportChange();
  };

  private onPanMove = (): void => {
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_PAN_MOVE);
    this.unifiedViewer.onViewportChange();
  };

  private onPanStop = (): void => {
    this.unifiedViewer.stage.stopDrag();
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_PAN_END);
    this.unifiedViewer.onViewportChange();
  };

  private getMouseDownPosition = (
    event: MouseEvent | TouchEvent
  ): Position | undefined => {
    if (isTouchEvent(event) && isSingleTouch(event)) {
      const touch = event.touches[0];
      return { x: touch.clientX, y: touch.clientY };
    }
    if (isMouseEvent(event) && isPrimaryMouseButtonPressed(event)) {
      return { x: event.clientX, y: event.clientY };
    }

    return undefined;
  };

  public override onMouseDown = ({
    evt: event,
  }: UnifiedViewerPointerEvent): void => {
    this.cursor = 'grabbing';
    if (isPrimaryMouseButtonPressed(event) || isSingleTouch(event)) {
      this.mouseDownPosition = this.getMouseDownPosition(event);
    }
  };

  public override onMouseMove = ({
    evt: event,
  }: UnifiedViewerPointerEvent): void => {
    if (!isPrimaryMouseButtonPressed(event) && !isSingleTouch(event)) {
      return;
    }

    const mouseMovePosition = this.getMouseDownPosition(event);
    if (
      !this.isPanning &&
      this.mouseDownPosition !== undefined &&
      mouseMovePosition !== undefined &&
      getEuclideanDistance(this.mouseDownPosition, mouseMovePosition) >
        MAX_MOUSE_DELTA_PIXELS
    ) {
      this.isPanning = true;
      this.onPanStart();
      return;
    }

    if (this.isPanning) {
      this.onPanMove();
      return;
    }
  };

  public override onMouseUp = ({
    evt: event,
  }: UnifiedViewerPointerEvent): void => {
    this.cursor = 'grab';
    if (
      this.isPanning &&
      !isPrimaryMouseButtonPressed(event) &&
      !isSingleTouch(event)
    ) {
      this.mouseDownPosition = undefined;
      this.isPanning = false;
      this.onPanStop();
    }
  };

  public override onMouseEnter = (event: MouseEvent): void => {
    if (isPrimaryMouseButtonPressed(event) || isSingleTouch(event)) {
      this.onPanStart();
    }
  };

  public override onMouseLeave = (event: MouseEvent): void => {
    if (isPrimaryMouseButtonPressed(event) || isSingleTouch(event)) {
      this.onPanStop();
    }
  };
}
