import { PolylineAnnotation } from '../../annotations/types';

const areConnectionPolylineVerticesDefined = (
  annotation: PolylineAnnotation
): boolean => {
  if (annotation.vertices === undefined) {
    return false;
  }

  if (annotation.vertices.length < 2) {
    return false;
  }

  return true;
};

export default areConnectionPolylineVerticesDefined;
