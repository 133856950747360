import { PolylineAnnotation } from '../annotations/types';

type CauseMapEdge = { fromId: string; toId: string };

const getCauseMapEdges = <Node extends { id: string }>({
  connections,
  causeMapNodesById,
}: {
  connections: PolylineAnnotation[];
  causeMapNodesById: Record<string, Node>;
}): CauseMapEdge[] =>
  connections
    .map(({ fromId, toId }) => ({ fromId, toId }))
    .filter(
      (edge): edge is { fromId: string; toId: string } =>
        edge.fromId !== undefined && edge.toId !== undefined
    )
    .map(({ fromId, toId }) => ({
      from: causeMapNodesById[fromId],
      to: causeMapNodesById[toId],
    }))
    .filter(({ from, to }) => from !== undefined && to !== undefined)
    .map(({ from, to }) => ({ fromId: from.id, toId: to.id }));

export default getCauseMapEdges;
