import { useAuthContext } from '@cognite/e2e-auth';
import { useMetrics } from '@cognite/metrics';
import {
  AssetNodeCollection,
  CognitePointCloudModel,
  DefaultNodeAppearance,
  IndexSet,
  NumericRange,
  TreeIndexNodeCollection,
} from '@cognite/reveal';
import { use3dModels } from '@cognite/reveal-react-components';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import type { FC } from 'react';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { TreeIndexAndSubtreeSize } from '../types';

type ThreeDContextType = {
  toggleGhostMode: () => void;
  deselectAll: () => void;
  selectTreeIndexes: (
    treeIndexesAndSubtrees: TreeIndexAndSubtreeSize[]
  ) => void;
};

export const ThreeDStylingContext = createContext<
  ThreeDContextType | undefined
>(undefined);

// TODO: INFIELD2-2754: Reveal handles this internally we should switch to reveal's internal implementation
// provides styling functions for Reveal and stores current styles
export const ThreeDStylingContextProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const metrics = useMetrics(METRICS_NAMESPACES.threeD);
  const model = use3dModels()[0];
  const treeIndexNodeCollectionRef = useRef(new TreeIndexNodeCollection());
  const { client } = useAuthContext();
  const isPointCloudModel = model instanceof CognitePointCloudModel;
  const assetCadNodeCollectionRef = useRef(
    isPointCloudModel ? undefined : new AssetNodeCollection(client, model)
  ); // For cad models

  // that just binds node collection to the model,
  // so we can modify collection directly and styles will be applied
  // also does clean up effects for models
  // because we don't recreate models, we need to manually reset the changes in models which done by this hook
  useEffect(() => {
    if (!model) return;
    // Remove point cloud styling
    if (isPointCloudModel) {
      return () => {
        model.removeAllStyledObjectCollections();
      };
    }

    // Remove cad styling
    if (!assetCadNodeCollectionRef.current) return;
    const treeIndexesSet = treeIndexNodeCollectionRef.current;
    const assetSet = assetCadNodeCollectionRef.current;
    model.assignStyledNodeCollection(
      treeIndexesSet,
      DefaultNodeAppearance.Highlighted
    );
    model.assignStyledNodeCollection(assetSet, {
      ...DefaultNodeAppearance.Highlighted,
      // https://cognitedata.github.io/reveal-docs/docs/examples/cad-prioritized-nodes/#prioritize-highlighted-nodes-for-loading
      prioritizedForLoadingHint: 5,
    });
    return () => {
      model.setDefaultNodeAppearance(DefaultNodeAppearance.Default);
    };
  }, [model, assetCadNodeCollectionRef, isPointCloudModel]);

  const deselectAll = useCallback(() => {
    if (!model) return;
    if (model.type === 'cad') {
      if (treeIndexNodeCollectionRef.current) {
        treeIndexNodeCollectionRef.current.clear();
      }
      if (assetCadNodeCollectionRef.current) {
        assetCadNodeCollectionRef.current.clear();
      }
    } else if (model.type === 'pointcloud') {
      (model as CognitePointCloudModel).removeAllStyledObjectCollections();
    }
  }, [model]);

  const selectTreeIndexes = useCallback(
    (treeIndexesAndSubtrees: TreeIndexAndSubtreeSize[]) => {
      const indexSet = new IndexSet();
      treeIndexesAndSubtrees.forEach(({ treeIndex, subtreeSize }) =>
        indexSet.addRange(new NumericRange(treeIndex, subtreeSize))
      );
      treeIndexNodeCollectionRef.current.updateSet(indexSet);
    },
    []
  );

  const [ghostModeEnabled, setGhostModeEnabled] = useState(false);

  const toggleGhostMode = useCallback(() => {
    if (isPointCloudModel) return;
    if (ghostModeEnabled) {
      model.setDefaultNodeAppearance(DefaultNodeAppearance.Default);
    } else {
      model.setDefaultNodeAppearance(DefaultNodeAppearance.Ghosted);
    }

    metrics.track(`GhostMode ${ghostModeEnabled ? 'disabled' : 'enabled'}`);

    setGhostModeEnabled(!ghostModeEnabled);
  }, [ghostModeEnabled, metrics, model, isPointCloudModel]);

  return (
    <ThreeDStylingContext.Provider
      value={useMemo(
        () => ({
          toggleGhostMode,
          deselectAll,
          selectTreeIndexes,
        }),
        [deselectAll, selectTreeIndexes, toggleGhostMode]
      )}
    >
      {children}
    </ThreeDStylingContext.Provider>
  );
};
