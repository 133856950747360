import { Modal } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ErrorStateContainer = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  /* padding: 80px 0px;
  background-color: ${({ theme }) => theme['surface--strong']}; */
`;

export const StyledModal = styled(Modal)`
  .cogs-modal__content {
    height: 100%;
    overflow: auto;
  }
`;
