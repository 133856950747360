import styled from 'styled-components';

export const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  width: 140px;
  background-color: var(--cogs-themed-surface--action--muted--default);
  border-radius: 6px;
`;

export const MediaGridWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
`;

export const FullScreenImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const FullScreenVideo = styled.video`
  height: 100%;
  width: 100%;
`;

export const ImageItem = styled.img`
  max-height: 140px;
  max-width: 140px;
`;

export const VideoItem = styled.video`
  max-height: 140px;
  max-width: 140px;
`;
