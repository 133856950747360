import mime from 'mime';

import OPFSCache, { FileData } from './OPFSCache';

// Based on https://blog.uuid.rocks/how-to-convert-http-status-codes-to-statustext-in-javascript/
const STATUS_CODE_TO_STATUS_TEXT_MAP = new Map<number, string>([
  [100, 'Continue'],
  [101, 'Switching Protocols'],
  [102, 'Processing'],
  [200, 'OK'],
  [201, 'Created'],
  [202, 'Accepted'],
  [203, 'Non-authoritative Information'],
  [204, 'No Content'],
  [205, 'Reset Content'],
  [206, 'Partial Content'],
  [207, 'Multi-Status'],
  [208, 'Already Reported'],
  [226, 'IM Used'],
  [300, 'Multiple Choices'],
  [301, 'Moved Permanently'],
  [302, 'Found'],
  [303, 'See Other'],
  [304, 'Not Modified'],
  [305, 'Use Proxy'],
  [307, 'Temporary Redirect'],
  [308, 'Permanent Redirect'],
  [400, 'Bad Request'],
  [401, 'Unauthorized'],
  [402, 'Payment Required'],
  [403, 'Forbidden'],
  [404, 'Not Found'],
  [405, 'Method Not Allowed'],
  [406, 'Not Acceptable'],
  [407, 'Proxy Authentication Required'],
  [408, 'Request Timeout'],
  [409, 'Conflict'],
  [410, 'Gone'],
  [411, 'Length Required'],
  [412, 'Precondition Failed'],
  [413, 'Payload Too Large'],
  [414, 'Request-URI Too Long'],
  [415, 'Unsupported Media Type'],
  [416, 'Requested Range Not Satisfiable'],
  [417, 'Expectation Failed'],
  [418, "I'm a teapot"],
  [421, 'Misdirected Request'],
  [422, 'Unprocessable Entity'],
  [423, 'Locked'],
  [424, 'Failed Dependency'],
  [426, 'Upgrade Required'],
  [428, 'Precondition Required'],
  [429, 'Too Many Requests'],
  [431, 'Request Header Fields Too Large'],
  [444, 'Connection Closed Without Response'],
  [451, 'Unavailable For Legal Reasons'],
  [499, 'Client Closed Request'],
  [500, 'Internal Server Error'],
  [501, 'Not Implemented'],
  [502, 'Bad Gateway'],
  [503, 'Service Unavailable'],
  [504, 'Gateway Timeout'],
  [505, 'HTTP Version Not Supported'],
  [506, 'Variant Also Negotiates'],
  [507, 'Insufficient Storage'],
  [508, 'Loop Detected'],
  [510, 'Not Extended'],
  [511, 'Network Authentication Required'],
  [599, 'Network Connect Timeout Error'],
]);

// This function exists because it is not guaranteed that Response.statusText contains a status message (https://developer.mozilla.org/en-US/docs/Web/API/Response/statusText)
const getResponseStatusText = (code: number): string => {
  return STATUS_CODE_TO_STATUS_TEXT_MAP.get(code) ?? 'Unknown Error';
};

const getErrorMessage = async (response: Response): Promise<string> => {
  try {
    const responseJson = await response.json();
    return (
      responseJson?.error?.message ?? getResponseStatusText(response.status)
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return getResponseStatusText(response.status);
  }
};

// Use SHA-256 to create a hash of the URL
// example taken from https://developer.mozilla.org/en-US/docs/Web/API/Web_Crypto_API/Non-cryptographic_uses_of_subtle_crypto
const hash = async (s: string) => {
  const utf8 = new TextEncoder().encode(s);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

const getClonedFileData = (original: FileData): FileData => ({
  data: original.data.slice(0),
  contentType: original.contentType,
});

class ResponseCache {
  private opfsCache: OPFSCache = new OPFSCache();
  private memoryCache: Map<string, FileData> = new Map();
  private inFlightFetchCalls: Map<string, Promise<FileData>> = new Map();

  private async _baseFetch(
    cacheKey: string,
    fetchFn: () => Promise<Response>
  ): Promise<FileData> {
    // Check if the data lives in the in-memory cache
    const memoryCachedData = this.memoryCache.get(cacheKey);
    if (memoryCachedData !== undefined) {
      return getClonedFileData(memoryCachedData);
    }

    // No in-memory data; check first if there are any in-flight fetch for the same cache key
    const inFlightResolveCall = this.inFlightFetchCalls.get(cacheKey);
    if (inFlightResolveCall !== undefined) {
      const data = await inFlightResolveCall;
      return getClonedFileData(data);
    }

    // No in-memory cache or in-flight fetch; fetch from network and cache the
    // promise to ensure 1) only one fetch is made and 2) the OPFS cache is only
    // queried and/or updated once
    const fetchPromiseFn = async () => {
      const opfsCachedData = await this.opfsCache.get(cacheKey);
      if (opfsCachedData !== undefined) {
        this.memoryCache.set(cacheKey, getClonedFileData(opfsCachedData));
        return opfsCachedData;
      }

      // Fetch from network if not cached
      try {
        const response = await fetchFn();
        if (!response.ok) {
          const errorMessage = await getErrorMessage(response);
          throw new Error(`Error ${response.status}: ${errorMessage}`);
        }

        const arrayBuffer = await response.arrayBuffer();
        const contentType = response.headers.get('Content-Type') || null;

        // Set in memory and OPFS cache
        await this.opfsCache.set(cacheKey, {
          // NOTE: We need to create a copy of the buffer here since it will be
          // transferred to a web worker which will store it in OPFS.
          data: arrayBuffer.slice(0),
          fileExtension: mime.getExtension(contentType ?? ''),
        });
        this.memoryCache.set(
          cacheKey,
          getClonedFileData({ data: arrayBuffer, contentType })
        );

        return { data: arrayBuffer, contentType };
      } catch (error) {
        // Clean up cache entries on error
        this.memoryCache.delete(cacheKey);
        await this.opfsCache.delete(cacheKey);
        throw error;
      }
    };

    // Cache the fetch promise to ensure only one fetch is made
    // and clean up the cache entry when the fetch is done
    const fetchPromise = fetchPromiseFn();
    this.inFlightFetchCalls.set(cacheKey, fetchPromise);
    try {
      return await fetchPromise;
    } finally {
      this.inFlightFetchCalls.delete(cacheKey);
    }
  }

  public async fetch(
    url: string,
    options?: { key?: string }
  ): Promise<FileData> {
    // NOTE: We hash key/URL mainly so that we do not get any invalid characters
    // in the cache key (and thus the OPFS file name).
    const cacheKey = await hash(options?.key ?? url);
    return this._baseFetch(cacheKey, () => fetch(url));
  }

  public async fetchFromCustomSource(
    key: string,
    customFetchFn: () => Promise<Response>
  ): Promise<FileData> {
    const cacheKey = await hash(key);
    return this._baseFetch(cacheKey, customFetchFn);
  }

  public async delete(url: string, options?: { key?: string }): Promise<void> {
    const cacheKey = await hash(options?.key ?? url);
    this.memoryCache.delete(cacheKey);
    await this.opfsCache.delete(cacheKey);
  }
}

let responseCache: ResponseCache | undefined = undefined;
const getResponseCache = (): ResponseCache => {
  if (responseCache === undefined) {
    responseCache = new ResponseCache();
  }
  return responseCache;
};

export default getResponseCache;
