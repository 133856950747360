import { Body, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Link = styled(Body).attrs({ level: 2, strong: true })`
  color: ${({ theme }) => theme['border--interactive--toggled-default']};
  min-width: fit-content;
  cursor: pointer;
`;

interface TaskListItemProps {
  $isSelected: boolean;
  $isEditable: boolean;
  $fadeColor?: boolean;
  $isDragging?: boolean;
}
export const Wrapper = styled.li<TaskListItemProps>`
  display: flex;
  gap: 6px;
  min-height: 44px;
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};

  ${({ $isDragging, theme }) =>
    $isDragging ? `border: 1px solid ${theme['border--muted']};` : ''}

  cursor: pointer;
  cursor: ${({ $isEditable }) => ($isEditable ? 'pointer' : 'initial')};
  word-break: break-word;
  width: 100%;
  background-color: ${({ theme, $isSelected }) =>
    $isSelected
      ? theme['surface--interactive--toggled-default']
      : theme['surface--muted']};
  a {
    margin-right: 8px;
  }
  &:hover {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme['surface--interactive--toggled-hover']
        : theme['surface--interactive--hover']};
  }

  @keyframes changeColor {
    from {
      background-color: ${({ theme }) =>
        theme['surface--status-undefined--muted--default']};
    }

    80% {
      background-color: ${({ theme }) =>
        theme['surface--status-undefined--muted--default']};
    }
  }

  ${({ $fadeColor }) => ($fadeColor ? `animation: changeColor 1000ms;` : '')}
`;

export const Content = styled(Flex).attrs({
  justifyContent: 'space-between',
})`
  width: 100%;
`;
