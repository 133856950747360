import { isUndefined } from 'lodash';

import { Data, ValueType } from '@cognite/plotting-components';

import { getDatapointValue } from './getDatapointValue';
import { DataFetchMode, TimeseriesDatapoint } from './types';

interface TimeseriesChartMetadata {
  numberOfPoints: number;
  dataFetchMode: DataFetchMode;
  isStep?: boolean;
  isString?: boolean;
  unit?: string;
}

interface Props {
  datapoints: TimeseriesDatapoint[];
  metadata: TimeseriesChartMetadata;
}

export const mapToChartData = ({ datapoints, metadata }: Props): Data => {
  let x: ValueType[] = [];
  let y: ValueType[] = [];
  let customData: TimeseriesDatapoint[] = [];

  for (let i = 0; i < datapoints.length; i++) {
    const datapoint = datapoints[i];
    const { timestamp } = datapoint;
    const datapointValue = getDatapointValue(datapoint);

    if (isUndefined(datapointValue)) {
      continue;
    }
    const timestampIsoString = new Date(timestamp).toISOString();
    x.push(timestampIsoString);
    y.push(datapointValue);
    customData.push(datapoint);
  }

  return {
    x,
    y,
    customData,
    interpolation: metadata.isStep ? 'step' : undefined,
  };
};
