// The hosted strategy expects the PDFJS dependency to be statically hosted by
// the application webserver. Currently it is hardcoded to use the path of
// `fusion-shell`.
import type { DocumentInitParameters } from 'pdfjs-dist/types/src/display/api';

import { setImportPDFJS } from '.';

const PDFJS_BASE_URL = '/dependencies/pdfjs-dist@4.3.136';
const cMapUrl = `${PDFJS_BASE_URL}/cmaps/`;
const workerUrl = `${PDFJS_BASE_URL}/build/pdf.worker.min.mjs`;

let pdfjsPromise: Promise<typeof import('pdfjs-dist')> | null = null;

setImportPDFJS(async () => {
  if (pdfjsPromise === null) {
    pdfjsPromise = Promise.all([import('pdfjs-dist'), fetch(workerUrl)]).then(
      async ([PDFJS, workerRes]) => {
        const workerBlob = new Blob([await workerRes.text()], {
          type: 'text/javascript',
        });
        PDFJS.GlobalWorkerOptions.workerPort = new Worker(
          URL.createObjectURL(workerBlob),
          { type: 'module' }
        );

        return new Proxy(PDFJS, {
          get(target, prop: keyof typeof PDFJS) {
            if (prop === 'getDocument') {
              return (docParams: DocumentInitParameters) =>
                target.getDocument({ ...docParams, cMapUrl });
            }
            return target[prop];
          },
        });
      }
    );
  }
  return pdfjsPromise;
});
