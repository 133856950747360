import Konva from 'konva';

import bgImage from '../assets/bg.png';

import LayerContainer from './LayerContainer';

// Size of the background layer in logical coordinates — we want this be feel infinite in both directions
const BACKGROUND_RECT_SIZE = 5_000_000;

enum BackgroundSize {
  TINY = 'TINY',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  HUGE = 'HUGE',
  ENORMOUS = 'ENORMOUS',
}

const MAX_GRID_SPACING = 200;
const backgroundSizeToGridSpacing = {
  [BackgroundSize.ENORMOUS]: MAX_GRID_SPACING / Math.pow(2, 0),
  [BackgroundSize.HUGE]: MAX_GRID_SPACING / Math.pow(2, 1),
  [BackgroundSize.LARGE]: MAX_GRID_SPACING / Math.pow(2, 2),
  [BackgroundSize.MEDIUM]: MAX_GRID_SPACING / Math.pow(2, 3),
  [BackgroundSize.SMALL]: MAX_GRID_SPACING / Math.pow(2, 4),
  [BackgroundSize.TINY]: MAX_GRID_SPACING / Math.pow(2, 5),
};

const TINY_SCALE = 3;
const lowerScaleAndBackgroundSize: [number, BackgroundSize][] = [
  [TINY_SCALE / Math.pow(2, 0), BackgroundSize.TINY],
  [TINY_SCALE / Math.pow(2, 1), BackgroundSize.SMALL],
  [TINY_SCALE / Math.pow(2, 2), BackgroundSize.MEDIUM],
  [TINY_SCALE / Math.pow(2, 3), BackgroundSize.LARGE],
  [TINY_SCALE / Math.pow(2, 4), BackgroundSize.HUGE],
  [0, BackgroundSize.ENORMOUS],
];

const getBackgroundRect = (
  imageSrc: string,
  backgroundSize: BackgroundSize
): Konva.Rect => {
  const backgroundRect = new Konva.Rect({
    unselectable: true,
  });

  const backgroundImage = new Image();
  backgroundImage.src = imageSrc;
  backgroundImage.crossOrigin = 'anonymous';
  backgroundImage.onload = () => {
    const imageWidth = backgroundImage.width;

    const scale = backgroundSizeToGridSpacing[backgroundSize] / imageWidth;
    const size = BACKGROUND_RECT_SIZE / scale;

    backgroundRect.width(size);
    backgroundRect.height(size);

    // Offset the background image so that it's centered, and the grid lines are aligned with the logical coordinate system (one dot on (0, 0)).
    // Since the circle is centered, we need to offset by half the size of the background image
    const offset = (Math.floor(size / imageWidth / 2) + 0.5) * imageWidth;
    backgroundRect.offset({ x: offset, y: offset });

    backgroundRect.scale({ x: scale, y: scale });

    backgroundRect.fillPatternImage(backgroundImage);
  };

  return backgroundRect;
};

const getBackgroundSizeByScale = (scale: number): BackgroundSize => {
  for (const [lowerScale, backgroundSize] of lowerScaleAndBackgroundSize) {
    if (scale > lowerScale) {
      return backgroundSize;
    }
  }
  return BackgroundSize.MEDIUM;
};

export default class BackgroundLayerContainer extends LayerContainer {
  private readonly backgroundRect: Konva.Rect;
  private currentBackgroundSize: BackgroundSize;
  public constructor({ name }: { name: string }) {
    super({ name });
    this.currentBackgroundSize = getBackgroundSizeByScale(1);
    this.backgroundRect = getBackgroundRect(
      bgImage,
      this.currentBackgroundSize
    );
    this.layer.add(this.backgroundRect);
  }

  public isFillPatternImageLoaded = (): boolean =>
    this.backgroundRect.fillPatternImage() !== undefined;

  private updateBackgroundRect(backgroundSize: BackgroundSize) {
    const imageWidth = this.backgroundRect.fillPatternImage()?.width;
    if (imageWidth === 0 || imageWidth === undefined) {
      return;
    }

    this.currentBackgroundSize = backgroundSize;
    const scale = backgroundSizeToGridSpacing[backgroundSize] / imageWidth;
    this.backgroundRect.scale({ x: scale, y: scale });
  }

  public possiblyUpdateBackgroundByScale(scale: number): void {
    const backgroundSize = getBackgroundSizeByScale(scale);
    if (this.currentBackgroundSize === backgroundSize) {
      return;
    }
    this.updateBackgroundRect(backgroundSize);
  }
}
