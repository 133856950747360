import { Button, Flex, Radio } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

export const SearchFilterSortContainer = styled(Flex).attrs({
  gap: 8,
})`
  padding: 8px 16px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const SortContainer = styled(Flex).attrs({
  direction: 'column',
  gap: 20,
})`
  z-index: ${z.OVERLAY};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--cogs-surface--muted);
  padding: 24px 16px 12px 16px;

  animation-name: moveUp, fade-in;
  animation-duration: 300ms, 300ms;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
  }

  @keyframes moveUp {
    from {
      transform: translateY(135px);
    }
  }
`;

export const SortHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

export const SortButton = styled(Button)`
  margin-top: auto;
`;

export const SortRadio = styled(Radio)`
  margin: 12px 0;
`;
