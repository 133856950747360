import type { SelectedColumnOption } from '../activity-planning/types';

export const setUserPreferredColumnsToSelected = (
  userPreferredColumns: string[],
  allColumns: SelectedColumnOption[]
): SelectedColumnOption[] => {
  if (!userPreferredColumns.length) return allColumns;

  return allColumns
    .map((col) => {
      return {
        ...col,
        selected: userPreferredColumns.includes(col.key),
      };
    })
    .sort((a, b) => {
      const index1 = userPreferredColumns.indexOf(a.key);
      const index2 = userPreferredColumns.indexOf(b.key);
      return (
        (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity)
      );
    });
};
