import { Body, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
})`
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const Description = styled(Body).attrs({
  strong: true,
  size: 'x-small',
})`
  padding: 4px 8px;
  color: ${({ theme }) => theme['text-icon--muted']};
`;
