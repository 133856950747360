import type { InFieldUser } from '@cognite/apm-client';
import { Body, DeleteIcon, Flex, Menu } from '@cognite/cogs.js-v10';
import type { Discipline } from '@infield/features/app-config/types';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { DisciplineIcon } from '../discipline-icon';
import { UserIcon } from '../user-icon';

import * as S from './elements';

interface Props {
  users: InFieldUser[];
  disciplines: Discipline[];
  onRemoveDiscipline: (discipline: Discipline) => void;
  onRemoveUser: (user: InFieldUser) => void;
}

export const AssignmentAssignees: FC<Props> = ({
  users,
  disciplines,
  onRemoveDiscipline,
  onRemoveUser,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.assignment);
  const isUserAssigned = users && users.length > 0;
  const isDisciplineAssigned = disciplines && disciplines.length > 0;

  return (
    <S.Container>
      <Menu.Header>
        <Body size="medium" strong>
          {t('assignees', 'Assignees:')}
        </Body>
      </Menu.Header>
      {isDisciplineAssigned && (
        <Menu.Section label={t('disciplines', 'Disciplines')}>
          {disciplines.map((discipline) => (
            <Menu.Item
              key={discipline.externalId}
              onClick={() => onRemoveDiscipline(discipline)}
              icon={<DeleteIcon />}
            >
              <Flex alignItems="center" justifyContent="space-between">
                <DisciplineIcon>{discipline.name}</DisciplineIcon>
              </Flex>
            </Menu.Item>
          ))}
        </Menu.Section>
      )}
      {isUserAssigned && (
        <Menu.Section label={t('individuals', 'Individuals')}>
          {users.map((user) => (
            <Menu.Item
              key={user.externalId}
              onClick={() => onRemoveUser(user)}
              icon={<DeleteIcon />}
            >
              <Flex alignItems="center" justifyContent="space-between">
                <UserIcon>
                  <Flex direction="column">
                    <div>{user.name}</div>
                    <Body size="x-small">{user.email}</Body>
                  </Flex>
                </UserIcon>
              </Flex>
            </Menu.Item>
          ))}
        </Menu.Section>
      )}
    </S.Container>
  );
};
