import React, { PropsWithChildren, useContext } from 'react';

import { CogniteClient } from '@cognite/sdk-beta';

const SdkBetaContext = React.createContext<CogniteClient | null>(null);
SdkBetaContext.displayName = 'CogniteSdkProvider';

type Props = { betaClient: CogniteClient };
export function BetaSDKProvider({
  children,
  betaClient,
}: PropsWithChildren<Props>) {
  return (
    <SdkBetaContext.Provider value={betaClient}>
      {children}
    </SdkBetaContext.Provider>
  );
}

export const useBetaSDK = () => {
  const sdk = useContext(SdkBetaContext);
  if (!sdk) {
    throw new Error(
      `SdkBetaContext not found, add '<SdkBetaContext value={sdk}>' around your component/app`
    );
  }
  return sdk;
};
