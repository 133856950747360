import type {
  CogniteClient,
  ViewDefinition,
  ViewReference,
} from '@cognite/sdk';

export class ViewCache {
  viewCache: Record<string, ViewDefinition> = {};
  cogniteSDK: CogniteClient;
  constructor(_sdk: CogniteClient) {
    this.cogniteSDK = _sdk;
  }

  async fetchViewDetails(viewReference?: ViewReference) {
    if (viewReference === undefined) return;
    const key = `${viewReference.space}:${viewReference.externalId}:${viewReference.version}`;
    if (this.viewCache[key]) {
      return this.viewCache[key];
    }
    const viewDefinition = await this.cogniteSDK.views
      .retrieve([
        {
          externalId: viewReference.externalId,
          space: viewReference.space,
          version: viewReference.version,
        },
      ])
      .then((res) => res.items[0]);
    this.viewCache[key] = viewDefinition;

    if (viewDefinition === undefined) {
      console.error(viewReference);
      throw new Error('Could not find view definition');
    }

    return viewDefinition;
  }
}
