type IframeEntry = {iframe: HTMLIFrameElement; key: string};

export class CachedIframe {
    private static storage: IframeEntry[] = [];
    constructor() {}

    public static clearAll(): void {
        this.storage.forEach(({iframe}) => iframe.remove());
        this.storage = [];
    }

    public static saveIframe(key: string, iframe: HTMLIFrameElement): void {
        const frameIndex = this.storage.findIndex((fr) => fr.key === key);
        if (frameIndex !== -1) {
            this.storage[frameIndex].iframe = iframe;
            return;
        }
        this.storage.push({iframe, key});
    }

    public static deleteIframe(key: string): void {
        const frameIndex = this.storage.findIndex((fr) => fr.key === key);
        const frame = this.storage[frameIndex];
        if (frame !== undefined && frameIndex !== -1) {
            frame.iframe.remove();
            this.storage.splice(frameIndex, 1);
        }
    }
    public static getIframe(key: string): IframeEntry | undefined {
        return this.storage.find((fr) => fr.key === key);
    }
}
