import Konva from 'konva';

import isAnnotationNode from './isAnnotationNode';
import isContainerNode from './isContainerNode';

const filterUniqueAncestorNodes = (
  annotationAndContainerNodes: Konva.Node[]
): Konva.Node[] => {
  const containerNodes = annotationAndContainerNodes.filter(isContainerNode);

  if (containerNodes.length === 0) {
    // Early exit if there are no container nodes
    return annotationAndContainerNodes;
  }

  const containerNodeIds = containerNodes.map((n) => n.id());
  const filteredAnnotationNodes = annotationAndContainerNodes
    .filter(isAnnotationNode)
    .filter((node) => {
      const containerId = node.getAttr('containerId');
      if (containerId === undefined) {
        return true;
      }
      return !containerNodeIds.includes(containerId);
    });
  return [...containerNodes, ...filteredAnnotationNodes];
};

export default filterUniqueAncestorNodes;
