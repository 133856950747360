import type { InFieldLocationConfig } from '@cognite/apm-client';
import { Body, Flex, Heading } from '@cognite/cogs.js-v10';
import { ComponentContainer } from '@infield/features/ui';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { defaultFeatureToggleConfig } from '../default-config';
import { useAllInFieldLocationConfigs, useAppConfigQuery } from '../hooks';
import {
  useDeleteInfieldLocationConfig,
  useUpsertAppConfigMutation,
  useUpsertInfieldLocationConfig,
} from '../hooks/use-mutation';
import type { AppConfig, RootLocationConfiguration } from '../types';
import { getDefaultDataFilters, getIsNewConfigVersion } from '../utils/utils';

import * as S from './elements';
import {
  RootLocationConfigCard,
  RootLocationConfigDialogue,
} from './root-location-config';
import { RootLocationConfigFeatures } from './root-location-config-features';

interface Props {
  hasAppConfigPermission: boolean;
}

export const AppConfigRootLocations: FC<Props> = ({
  hasAppConfigPermission,
}) => {
  const { data: infieldLocationConfigs } = useAllInFieldLocationConfigs();
  const { mutateAsync: upsertInFieldLocationConfig } =
    useUpsertInfieldLocationConfig();
  const { mutateAsync: deleteInFieldLocationConfig } =
    useDeleteInfieldLocationConfig();
  const [rootLocationsConfiguration, setRootLocationsConfiguration] = useState<
    RootLocationConfiguration[]
  >([]);
  const [newLocationConfigs, setNewLocationConfigs] = useState<
    InFieldLocationConfig[]
  >([]);
  const [locationConfigsToDelete, setLocationConfigsToDelete] = useState<
    string[]
  >([]);

  const [editingRootLocation, setEditingRootLocation] = useState<string>();

  const [showAddRootLocation, setShowRootLocation] = useState(false);

  const { data: appConfig, isSuccess } = useAppConfigQuery();
  const isNewConfigVersion = getIsNewConfigVersion(appConfig);
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();

  const savedRootLocationConfig =
    appConfig?.featureConfiguration?.rootLocationConfigurations;

  useEffect(() => {
    if (isSuccess && savedRootLocationConfig !== undefined) {
      setRootLocationsConfiguration(savedRootLocationConfig);
    }
  }, [isSuccess, appConfig, savedRootLocationConfig]);

  const hasConfigChanged =
    JSON.stringify(rootLocationsConfiguration) !==
    JSON.stringify(savedRootLocationConfig);

  const handleSave = async () => {
    if (!appConfig) return;

    const newAppConfig: AppConfig = {
      ...appConfig,
      featureConfiguration: {
        ...appConfig.featureConfiguration,
        rootLocationConfigurations: [...rootLocationsConfiguration],
      },
    };

    await upsertAppConfig({
      newAppConfig,
    });
    if (newLocationConfigs.length > 0) {
      await upsertInFieldLocationConfig(newLocationConfigs);
      setNewLocationConfigs([]);
    }
    if (locationConfigsToDelete.length > 0) {
      await deleteInFieldLocationConfig(locationConfigsToDelete);
      setLocationConfigsToDelete([]);
    }
  };

  const handleAddRootLocation = async (
    assetExternalId: string,
    newDisplayName: string
  ) => {
    const defaultDataFilters = isNewConfigVersion
      ? getDefaultDataFilters(assetExternalId)
      : undefined;
    const newRootLocationExternalId = isNewConfigVersion ? uuid() : undefined;
    setShowRootLocation(false);
    setRootLocationsConfiguration((prevRootLocations) => [
      ...prevRootLocations,
      {
        externalId: newRootLocationExternalId,
        assetExternalId,
        displayName: newDisplayName,
        threeDConfiguration: {
          fullWeightModels: [],
        },
        dataFilters: defaultDataFilters,
      },
    ]);
    setNewLocationConfigs((prevState) => [
      ...prevState,
      {
        externalId: uuid(),
        rootLocationExternalId: newRootLocationExternalId || assetExternalId,
        featureToggles: defaultFeatureToggleConfig,
      },
    ]);
  };

  const handleDeleteRootLocation = (externalId: string) => {
    setRootLocationsConfiguration((prevRootLocations) => [
      ...prevRootLocations.filter(
        (prev) =>
          externalId !==
          (isNewConfigVersion ? prev.externalId : prev.assetExternalId)
      ),
    ]);

    const isSavedRootLocation = Boolean(
      savedRootLocationConfig?.find(
        (rootLocation) =>
          externalId === rootLocation.externalId ||
          externalId === rootLocation.assetExternalId
      )
    );
    if (isSavedRootLocation) {
      const locationConfigsToDelete = infieldLocationConfigs?.find(
        ({ rootLocationExternalId }) => rootLocationExternalId === externalId
      );
      if (locationConfigsToDelete) {
        setLocationConfigsToDelete((prevState) => [
          ...prevState,
          locationConfigsToDelete.externalId,
        ]);
      }
    } else {
      setNewLocationConfigs((prevState) => [
        ...prevState.filter(
          ({ rootLocationExternalId }) => rootLocationExternalId !== externalId
        ),
      ]);
    }
  };

  const handleEditRootLocation = (
    oldRootLocationExternalId: string,
    newAssetExternalId: string,
    newDisplayName?: string
  ) => {
    setShowRootLocation(false);
    setRootLocationsConfiguration((prevRootLocations) => [
      ...prevRootLocations.map((rootLocation) => {
        if (
          oldRootLocationExternalId ===
          (isNewConfigVersion
            ? rootLocation.externalId
            : rootLocation.assetExternalId)
        )
          return {
            ...rootLocation,
            assetExternalId: newAssetExternalId,
            displayName: newDisplayName ?? rootLocation.displayName,
          };

        return { ...rootLocation };
      }),
    ]);
  };

  return (
    <ComponentContainer>
      {showAddRootLocation && (
        <RootLocationConfigDialogue
          visible
          hasAppConfigPermission={hasAppConfigPermission}
          existingRootLocations={rootLocationsConfiguration}
          onClose={() => setShowRootLocation(false)}
          onSave={handleAddRootLocation}
        />
      )}

      <S.Wrapper>
        {editingRootLocation && (
          <RootLocationConfigFeatures
            hasAppConfigPermission={hasAppConfigPermission}
            rootLocationExternalId={editingRootLocation}
            setEditingRootLocation={(
              rootLocationExternalId: string | undefined
            ) => setEditingRootLocation(rootLocationExternalId)}
            assetExternalId={
              rootLocationsConfiguration.find(
                (config) =>
                  editingRootLocation ===
                  (isNewConfigVersion
                    ? config.externalId
                    : config.assetExternalId)
              )!.assetExternalId
            }
            onClickBack={() => setEditingRootLocation(undefined)}
          />
        )}

        {!editingRootLocation && (
          <Flex direction="column" gap={16}>
            <Flex direction="column" gap={8}>
              <Heading level={5}>Root Locations</Heading>
              <Body size="x-small">Configure root locations</Body>

              <S.Container>
                {rootLocationsConfiguration.map((rootLocationConfig) => (
                  <RootLocationConfigCard
                    key={
                      isNewConfigVersion
                        ? rootLocationConfig.externalId
                        : rootLocationConfig.assetExternalId
                    }
                    hasAppConfigPermission={hasAppConfigPermission}
                    rootLocation={rootLocationConfig}
                    onDelete={() =>
                      handleDeleteRootLocation(
                        isNewConfigVersion
                          ? rootLocationConfig.externalId!
                          : rootLocationConfig.assetExternalId
                      )
                    }
                    onClickEdit={() =>
                      setEditingRootLocation(
                        isNewConfigVersion
                          ? rootLocationConfig.externalId
                          : rootLocationConfig.assetExternalId
                      )
                    }
                    onEdit={(newAssetExternalId, newDisplayName) =>
                      handleEditRootLocation(
                        isNewConfigVersion
                          ? rootLocationConfig.externalId!
                          : rootLocationConfig.assetExternalId,
                        newAssetExternalId,
                        newDisplayName
                      )
                    }
                  />
                ))}
              </S.Container>
            </Flex>
            <Flex direction="column" gap={4}>
              <S.AddButton
                onClick={() => {
                  setShowRootLocation(true);
                }}
              >
                Add Root Location
              </S.AddButton>
              <S.SaveButton onClick={handleSave} disabled={!hasConfigChanged}>
                Save configuration
              </S.SaveButton>
            </Flex>
          </Flex>
        )}
      </S.Wrapper>
    </ComponentContainer>
  );
};
