import { AbortError } from './errors';

const retryWithExponentialBackoff = async (
  task: {
    run: (backoffFactor: number) => Promise<void>;
    signal?: AbortSignal;
  },
  attempt = 0,
  maxAttempts = 10,
  lastError?: unknown
): Promise<void> => {
  const { run, signal } = task;

  if (signal?.aborted) {
    throw new AbortError();
  }

  if (attempt >= maxAttempts) {
    throw lastError ?? new Error('Failed after max attempts');
  }

  const backoffFactor = 0.5 ** attempt;

  try {
    await run(backoffFactor);
  } catch (error: unknown) {
    if (error instanceof AbortError) {
      return;
    }
    // TODO: Ideally we do some Sentry logging here to understand failing tasks
    // eslint-disable-next-line no-console
    console.warn('Failed task, retrying with backoff');
    await retryWithExponentialBackoff(task, attempt + 1, maxAttempts, error);
  }
};

export default retryWithExponentialBackoff;
