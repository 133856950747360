import Konva from 'konva';

import type { CauseMapNodeProperties } from '../../annotations/CauseMapNodeSerializer';

export type TextTagBoxProperties = Omit<
  CauseMapNodeProperties<any>,
  'labelNodeProperties'
>;

const getTextTagBoxPropertiesFromCauseMapNode = (
  node: Konva.Label
): TextTagBoxProperties => {
  const textNode = node.getText();
  const tagNode = node.getTag();
  return {
    textNodeProperties: {
      ...textNode.getClientRect(),
      align: textNode.align(),
      fontSize: textNode.fontSize(),
      lineHeight: textNode.lineHeight(),
      padding: textNode.padding(),
      text: textNode.text(),
      fill: textNode.fill(),
      verticalAlign: textNode.verticalAlign(),
    },
    tagNodeProperties: {
      cornerRadius: tagNode.cornerRadius(),
      stroke: tagNode.stroke(),
      strokeWidth: tagNode.strokeWidth(),
      fill: tagNode.fill(),
    },
  };
};

export default getTextTagBoxPropertiesFromCauseMapNode;
