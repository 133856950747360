import { Annotation, AnnotationType } from '../../annotations/types';
import { ContainerConfig } from '../../containers/types';

const hasAtLeastOneDependencyBeenUpdated = (
  annotation: Annotation,
  updatedAnnotationsAndContainerConfigsById: Record<
    string,
    Annotation | ContainerConfig
  >
): boolean => {
  if (annotation.type !== AnnotationType.POLYLINE) {
    return false;
  }

  const { fromId, toId } = annotation;
  if (
    fromId !== undefined &&
    updatedAnnotationsAndContainerConfigsById[fromId] !== undefined
  ) {
    return true;
  }

  if (
    toId !== undefined &&
    updatedAnnotationsAndContainerConfigsById[toId] !== undefined
  ) {
    return true;
  }

  return false;
};

export default hasAtLeastOneDependencyBeenUpdated;
