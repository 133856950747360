import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DomainButton = styled.div<{ active: boolean }>`
  margin: 5px 4px 5px 0px;
  padding: 0px 5px;
  cursor: pointer;

  ${({ active, theme }) =>
    active &&
    `
        color: ${theme['surface--action--strong--default']};
        border-bottom: solid;
    `}
`;
