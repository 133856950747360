import { DocumentStatus } from '../types';

export const getDocumentsWithEmbeddings = (
  documentStatuses: DocumentStatus[]
) =>
  documentStatuses
    ?.filter(
      (status) =>
        status.statistics.numberOfVectors &&
        status.statistics.numberOfVectors > 0
    )
    .map((status) => status.id) || [];
