import Konva from 'konva';
import { v4 as uuid } from 'uuid';

import { DEFAULT_STROKE_SCALE_ENABLED } from '../annotations/constants';
import { AnnotationType, Position } from '../annotations/types';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import { UnifiedViewer } from '../UnifiedViewer';
import UnifiedViewerEventType from '../UnifiedViewerEventType';
import { UnifiedViewerPointerEvent } from '../UnifiedViewerRenderer/UnifiedEventHandler';
import {
  isPrimaryMouseButtonPressed,
  isShiftKeyPressed,
} from '../utils/eventUtils';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';

import { ContainerAttachmentHelper } from './ContainerAttachmentHelper';
import getShapeEventPropsFromKonvaShape from './getShapeEventPropsFromKonvaShape';
import Tool from './Tool';
import { EllipseToolConfig, ToolType } from './types';

export default class EllipseTool extends Tool {
  public readonly type = ToolType.ELLIPSE;
  public override readonly cursor = 'crosshair';
  private pendingNode: Konva.Ellipse | null = null;
  private pendingNodeStartPos: Position | null = null;
  private containerAttachmentHelper: ContainerAttachmentHelper;
  private uniformScaling: boolean = false;
  private config: EllipseToolConfig;

  public constructor({
    unifiedViewer,
    config,
  }: {
    unifiedViewer: UnifiedViewer;
    config: EllipseToolConfig;
  }) {
    super(unifiedViewer);
    this.containerAttachmentHelper = new ContainerAttachmentHelper(
      unifiedViewer
    );
    this.config = config;
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);
    this.containerAttachmentHelper.start();
  }

  public setConfig(config: EllipseToolConfig): void {
    this.config = config;
  }

  private getRelativePointerPosition = (): Position | null => {
    return this.unifiedViewer.stage.getRelativePointerPosition();
  };

  public override onMouseDown = (e: UnifiedViewerPointerEvent): void => {
    e.target.stopDrag();

    this.pendingNodeStartPos = this.getRelativePointerPosition();

    if (this.pendingNodeStartPos === null) {
      return;
    }

    this.pendingNode = new Konva.Ellipse({
      ...this.config,
      [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
      id: uuid(),
      radiusX: 1,
      radiusY: 1,
      x: this.pendingNodeStartPos.x,
      y: this.pendingNodeStartPos.y,
      width: 1,
      height: 1,
      userGenerated: true,
      name: 'user-drawing',
      source: this.type,
      containerId: undefined,
      annotationType: AnnotationType.ELLIPSE,
      isSelectable: true,
      isDraggable: true,
      isResizable: true,
      strokeWidth: this.config?.strokeWidth,
      strokeScaleEnabled:
        this.config?.shouldEnableStrokeScale ?? DEFAULT_STROKE_SCALE_ENABLED,
    });

    this.containerAttachmentHelper.lockTarget();
    this.unifiedViewer.setDirtyNodes([this.pendingNode]);
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_TOOL_START);
  };

  public override onMouseMove = (): void => {
    if (this.pendingNode === null || this.pendingNodeStartPos === null) {
      return;
    }

    const mousePosition = this.getRelativePointerPosition();
    if (mousePosition === null) {
      return;
    }
    const ellipseSize = {
      width: mousePosition.x - this.pendingNodeStartPos.x,
      height: mousePosition.y - this.pendingNodeStartPos.y,
    };
    const uniformSize = Math.min(ellipseSize.width, ellipseSize.height);
    const width = this.uniformScaling ? uniformSize : ellipseSize.width;
    const height = this.uniformScaling ? uniformSize : ellipseSize.height;

    this.pendingNode.radius({
      x: Math.abs(width) / 2,
      y: Math.abs(height) / 2,
    });
    this.pendingNode.offsetX(
      -0.5 * Math.abs(width) * Math.sign(ellipseSize.width)
    );
    this.pendingNode.offsetY(
      -0.5 * Math.abs(height) * Math.sign(ellipseSize.height)
    );
  };

  public onKeyDown = (e: KeyboardEvent): void => {
    this.uniformScaling = isShiftKeyPressed(e);
  };

  public onKeyUp = (e: KeyboardEvent): void => {
    this.uniformScaling = isShiftKeyPressed(e);
  };

  public override onMouseUp = (): void => {
    this.onComplete();
  };

  public override onMouseEnter = (e: MouseEvent): void => {
    if (this.pendingNode && !isPrimaryMouseButtonPressed(e)) {
      this.onComplete();
    }
  };

  public override onComplete = (): void => {
    if (this.pendingNode === null) {
      return;
    }

    this.pendingNode.x(this.pendingNode.x() - this.pendingNode.offsetX());
    this.pendingNode.y(this.pendingNode.y() - this.pendingNode.offsetY());
    this.pendingNode.offsetX(0);
    this.pendingNode.offsetY(0);
    getMetricsLogger()?.trackEvent(TrackedEventType.CREATE_ELLIPSE, {
      ...getShapeEventPropsFromKonvaShape(this.pendingNode),
      radiusX: this.pendingNode.radiusX(),
      radiusY: this.pendingNode.radiusY(),
    });

    this.containerAttachmentHelper.end([this.pendingNode]);
    this.unifiedViewer.commitDirtyNodes();
    this.pendingNode = null;
    this.pendingNodeStartPos = null;
    this.containerAttachmentHelper.start();

    this.unifiedViewer.emit(UnifiedViewerEventType.ON_TOOL_END);
  };

  public override onDestroy = (): void => {
    this.containerAttachmentHelper.onDestroy();
  };
}
