import type { Notification } from '@cognite/apm-client';
import {
  Body,
  Button,
  CloseIcon,
  DataTableIcon,
  Flex,
  Heading,
  Skeleton,
} from '@cognite/cogs.js-v10';
import { useClassicOrAPMAssetQuery } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { DEFAULT_DATE_FORMAT } from '@infield/utils/defaultDateFormats';
import { humanizeValue } from '@infield/utils/humanizeValue';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { useNotificationDetails } from '../hooks/use-query/use-notification-details';

import * as S from './elements';

import type { SearchResult } from '.';
import {
  DetailRow,
  NotificationDescription,
  NotificationDetailsSearch,
} from '.';

type Props = {
  notificationId: string;
  onAssetClick: (assetExternalId: string) => void;
  onClose?: () => void;
};

type CoreApmNotificationFields = keyof Notification;
const METADATA_BLACKLIST: CoreApmNotificationFields[] = [
  'externalId',
  'title',
  'description',
  'source',
  'assetExternalId',
  'type',
  'status',
  'createdTime',
  'rootLocationExternalId',
  'createdDate',
  'sourceId',
  'lastUpdatedTime',
  'space',
];

export const NotificationDetails: FC<Props> = ({
  notificationId,
  onAssetClick,
  onClose,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.notification);
  const {
    data: notification,
    isInitialLoading,
    isError,
  } = useNotificationDetails(notificationId);
  const { data: asset } = useClassicOrAPMAssetQuery(
    notification?.assetExternalId || ''
  );

  const [selectedTab, setSelectedTab] = useState<'details' | 'media'>(
    'details'
  );

  const mapMetadataChild = useCallback(
    (result: SearchResult) => (
      <DetailRow
        key={result.key}
        metadataKey={startCase(result.key)}
        value={humanizeValue(result.value)}
      />
    ),
    []
  );

  const searchAlgorithm = useCallback(
    async (
      query: string
    ): Promise<
      {
        key: string;
        value: any;
      }[]
    > => {
      return Object.keys(notification || {})
        .filter(
          (key) => !METADATA_BLACKLIST.includes(key as keyof Notification)
        )
        .map((metadataKey) => ({
          key: metadataKey,
          value: notification?.[metadataKey as keyof Notification],
        }))
        .filter((child) =>
          child.key.toLowerCase().includes(query.toLowerCase())
        )
        .filter((child) => Boolean(child.value))
        .sort((a, b) => a.key.localeCompare(b.key));
    },
    [notification]
  );

  const tabContent = useMemo(() => {
    const detailTab = (
      <S.Content>
        <S.MandatoryFields>
          <Flex direction="column">
            <S.MandatoryKey>
              {t('NOTIFICATION_DETAILS_NOTIFICATION_ID', 'NOTIFICATION ID')}
            </S.MandatoryKey>
            <Body size="medium" strong>
              {notification?.sourceId || '-'}
            </Body>
          </Flex>
          <Flex direction="column">
            <S.MandatoryKey>
              {t('NOTIFICATION_DETAILS_TYPE', 'TYPE')}
            </S.MandatoryKey>
            <Body size="medium" strong>
              {notification?.type || '-'}
            </Body>
          </Flex>
          <Flex direction="column">
            <S.MandatoryKey>
              {t('NOTIFICATION_DETAILS_STATUS', 'STATUS')}
            </S.MandatoryKey>
            <Body size="medium" strong>
              {notification?.status || '-'}
            </Body>
          </Flex>
          <Flex direction="column">
            <S.MandatoryKey>
              {t('NOTIFICATION_DETAILS_PRIORITY', 'PRIORITY')}
            </S.MandatoryKey>
            <Body size="medium" strong>
              {notification?.priority || '-'}
            </Body>
          </Flex>
          <Flex direction="column">
            <S.MandatoryKey>
              {t('NOTIFICATION_DETAILS_CREATED_DATE', 'CREATED DATE')}
            </S.MandatoryKey>
            <Body size="medium" strong>
              {notification?.createdDate
                ? dayjs(notification?.createdDate).format(DEFAULT_DATE_FORMAT)
                : '-'}
            </Body>
          </Flex>
          <Flex direction="column">
            <S.MandatoryKey>
              {t('NOTIFICATION_DETAILS_LOCATION', 'LOCATION')}
            </S.MandatoryKey>
            <Body size="medium" strong>
              {asset ? (
                <S.AssetName onClick={() => onAssetClick(asset.externalId!)}>
                  {'name' in asset ? asset.name : asset.title}
                </S.AssetName>
              ) : (
                '-'
              )}
            </Body>
          </Flex>
        </S.MandatoryFields>
        <Flex direction="column" gap={16}>
          {notification?.description && (
            <NotificationDescription description={notification?.description} />
          )}
          <NotificationDetailsSearch<SearchResult>
            key={notification?.externalId}
            searchAlgorithm={searchAlgorithm}
            mapChild={mapMetadataChild}
          />
        </Flex>
      </S.Content>
    );

    switch (selectedTab) {
      case 'details':
        return detailTab;
      default:
        return detailTab;
    }
  }, [
    notification,
    asset,
    mapMetadataChild,
    onAssetClick,
    searchAlgorithm,
    selectedTab,
    t,
  ]);

  if (isError)
    return (
      <S.Container>
        <div>
          {t(
            'NOTIFICATION_DETAILS_LOADING_ERROR',
            'Notification  details was unable to load data'
          )}
        </div>
      </S.Container>
    );

  return (
    <S.Container>
      <S.Header>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading level={5}>{notification?.title}</Heading>
          {onClose && (
            <Button
              icon={<CloseIcon />}
              onClick={onClose}
              type="ghost"
              aria-label={t(
                'NOTIFICATION_DETAILS_TAB_PANEL_INFO_CLOSE',
                'Close'
              )}
            />
          )}
        </Flex>
      </S.Header>

      <S.TagContainer>
        <S.Tag
          type="secondary"
          toggled={selectedTab === 'details'}
          key="Details"
          onClick={() => setSelectedTab('details')}
          icon={<DataTableIcon />}
        >
          {t('NOTIFICATION_DETAILS_TAB_PANEL_LABEL_DETAILS', 'Details')}
        </S.Tag>
      </S.TagContainer>

      {isInitialLoading && !notification ? (
        <Skeleton.List lines={8} borders />
      ) : (
        tabContent
      )}
    </S.Container>
  );
};
