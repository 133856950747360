import { Body, Button, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
  justifyContent: 'space-between',
})`
  height: 100%;
`;

export const Header = styled(Flex)`
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const HeaderWrapper = styled(Flex).attrs({
  justifyContent: 'center',
  direction: 'column',
})`
  padding: 9px 16px 9px 8px;
  border-left: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const BackButton = styled(Button)`
  width: 56px;
`;

export const ContentWrapper = styled(Flex).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})`
  height: 100%;
  padding: 16px;
  max-height: 100%;
  min-height: 0px;
`;

export const Footer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
})`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme['surface--strong']};
`;

export const FooterButton = styled(Button)`
  width: 100%;
`;

export const DescriptionBody = styled(Body).attrs({
  size: 'small',
})`
  color: ${({ theme }) => theme['text-icon--medium']};
`;
