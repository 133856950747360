import { Row } from '@cognite/cogs.js-v10';
import { MediaEmptyView } from '@infield/features/media';
import styled from 'styled-components';

export const MediaGrid = styled(Row).attrs(({ cols }) => ({
  cols: cols || 4,
  gap: 10,
}))<{ cols: number }>`
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
`;

export const StyledMediaEmptyView = styled(MediaEmptyView)`
  &&& {
    width: 280px;
  }
`;

export const MediaGridWrapper = styled.div`
  overflow: hidden;
  padding: 8px 24px;
`;
