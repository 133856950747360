import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { useMemo } from 'react';

import type { ThreeDModelIdentifier } from '../types';

import { useAssetModelsClassic } from './use-asset-models-classic';
import { useAssetModelsIDM } from './use-asset-models-idm';
import { useThreeDModelsConfig } from './use-threed-models-config';

export const useAssetModels = (assetExternalId?: string, assetId?: number) => {
  const { isIdm } = useAppConfigContext();

  const { data: threeDModelsWithMapping } = useAssetModelsClassic(assetId);

  const threeDModelsForRootAsset = useThreeDModelsConfig();

  const { data: assetIDMModels } = useAssetModelsIDM({ assetExternalId });

  const allMappedModels = useMemo(() => {
    if (!isIdm) {
      if (
        threeDModelsWithMapping?.fullWeightModels &&
        threeDModelsWithMapping?.fullWeightModels.length > 0
      ) {
        return threeDModelsWithMapping.fullWeightModels;
      }
      return threeDModelsForRootAsset?.fullWeightModels;
    }

    return assetIDMModels;
  }, [
    assetIDMModels,
    isIdm,
    threeDModelsForRootAsset?.fullWeightModels,
    threeDModelsWithMapping?.fullWeightModels,
  ]);

  // If it is classic asset, we need to get the model from the asset mapping or the config.
  const firstAvailableModel: ThreeDModelIdentifier | undefined = useMemo(() => {
    if (!isIdm) {
      return (
        threeDModelsWithMapping?.fullWeightModels[0] ||
        threeDModelsForRootAsset?.fullWeightModels[0]
      );
    }

    return assetIDMModels?.[0];
  }, [
    isIdm,
    threeDModelsWithMapping?.fullWeightModels,
    threeDModelsForRootAsset?.fullWeightModels,
    assetIDMModels,
  ]);

  return { firstAvailableModel, allMappedModels };
};
