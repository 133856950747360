import type {
  Activity,
  APMAsset,
  Checklist,
  ChecklistItem,
  InFieldUser,
  Operation,
} from '@cognite/apm-client';
import type { Edge } from '@cognite/fdm-client';
import { v4 as uuid } from 'uuid';

export type GenerateChecklistParams = {
  activity: Activity;
  operations: Operation[];
  user: InFieldUser;
  rootAPMAsset: APMAsset;
  operationAPMAssets: APMAsset[];
  assignees: string[];
  userInstanceSpace: string;
  plannedStartTime?: string;
  plannedEndTime?: string;
};

export const generateChecklist = ({
  activity,
  operations,
  user,
  rootAPMAsset,
  operationAPMAssets,
  assignees,
  userInstanceSpace,
  plannedStartTime,
  plannedEndTime,
}: GenerateChecklistParams) => {
  const getOperationAPMAsset = (assetExternalId?: string) =>
    operationAPMAssets?.find(
      ({ externalId }) => externalId === assetExternalId
    );

  const newChecklist: Checklist = {
    externalId: uuid(),
    sourceId: activity.externalId,
    title: activity.title,
    description: activity.description,
    type: activity.type,
    status: 'Ready',
    startTime: plannedStartTime,
    endTime: plannedEndTime,
    rootLocation: rootAPMAsset,
    assignedTo: assignees,
    createdBy: {
      externalId: user.externalId,
      space: userInstanceSpace,
    },
  };

  const newChecklistItems: ChecklistItem[] = operations.map((operation) => ({
    externalId: uuid(),
    sourceId: operation.externalId,
    title: operation.title,
    description: operation.description,
    order: operation.order,
    startTime: plannedStartTime,
    endTime: plannedEndTime,
    createdBy: {
      externalId: user.externalId,
      space: userInstanceSpace,
    },
    asset: getOperationAPMAsset(operation.assetExternalId ?? undefined),
  }));

  const newChecklistItemEdges: Edge[] = newChecklistItems.map(
    (newChecklistItem) => ({
      externalId: `${newChecklist.externalId}_${newChecklistItem.externalId}_relation`,
      modelName: 'referenceChecklistItems',
      startNode: newChecklist.externalId,
      endNode: newChecklistItem.externalId,
    })
  );
  return { newChecklistItems, newChecklistItemEdges, newChecklist };
};
