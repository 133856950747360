import Konva from 'konva';

import { ShapeEventProps } from '../utils/getMetricsLogger';

const getShapeEventPropsFromKonvaShape = (
  shape: Konva.Shape
): ShapeEventProps => ({
  id: shape.id(),
  x: shape.x(),
  y: shape.y(),
  width: shape.width(),
  height: shape.height(),
  strokeScaleEnabled: shape.strokeScaleEnabled(),
  name: shape.name(),
  userGenerated: shape.getAttr('userGenerated'),
  source: shape.getAttr('source'),
  containerId: shape.getAttr('containerId'),
  annotationType: shape.getAttr('annotationType'),
});

export default getShapeEventPropsFromKonvaShape;
