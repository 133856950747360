import { Body, Button, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const RightItemContainer = styled(Flex).attrs({ gap: 12 })`
  margin-left: auto;
  white-space: nowrap;
`;

export const DeleteModalParagraph = styled(Body).attrs({
  level: 3,
  as: 'p',
})`
  margin-bottom: 16px;
`;

export const AddTaskButton = styled(Button)`
  white-space: nowrap;
`;
