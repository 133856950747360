export const getIsUsingUSFormat = () => {
  // this is based on system's language
  // we might need to look into geoposition to be more precise
  return Boolean(
    new Intl.DateTimeFormat(navigator.language, {
      hour: 'numeric',
    })
      .format(0)
      .match(/\s/)
  );
};

export const SELECTED_LANGUAGE_KEY = 'INFIELD_APP_LANG';
