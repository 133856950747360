import type { ImageToUpload } from '@infield/features/media/types';
import type { FC } from 'react';

import { ImageItem } from './image-item';
import { getIsVideo, getMediaSrc } from './utils';
import { VideoItem } from './video-item';

type Props = {
  media: ImageToUpload;
  isFullScreen?: boolean;
};

export const MediaItem: FC<Props> = ({ media, isFullScreen }) => {
  const isVideo = getIsVideo(media);
  const mediaSrc = getMediaSrc(media);

  if (isVideo) {
    return <VideoItem mediaSrc={mediaSrc} isFullScreen={isFullScreen} />;
  }

  return (
    <ImageItem
      mediaName={media.name}
      mediaSrc={mediaSrc}
      isFullScreen={isFullScreen}
    />
  );
};
