import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';

import { useAPMAssetQuery } from './use-apm-asset-query';
import { useAssetByIdQuery } from './use-asset-by-id-query';

export const useClassicByIdOrAPMAssetQuery = (
  assetIdentifier: string | number | null
) => {
  const { isIdm } = useAppConfigContext();
  const classicAssetQuery = useAssetByIdQuery(
    !isIdm ? (assetIdentifier as number) : null
  );
  const apmAssetQuery = useAPMAssetQuery(
    isIdm ? (assetIdentifier as string) : undefined
  );

  return !isIdm ? classicAssetQuery : apmAssetQuery;
};
