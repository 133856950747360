import { Body, Flex } from '@cognite/cogs.js-v10';
import styled, { css } from 'styled-components';

const textareaStyles = css`
  width: 100%;
  min-height: 80px;
  overflow-y: auto;
  border-radius: 6px;
  border: none;
  padding: 8px 10px;
  &:hover,
  &:focus {
    outline: 2px solid var(--cogs-border--interactive--hover);
  }
`;

export const StyledTextArea = styled.textarea<{
  height?: number;
}>`
  ${textareaStyles};
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
`;

export const StyledDiv = styled.div`
  ${textareaStyles};
  white-space: pre-line;
  max-height: 200px;

  &::before {
    content: attr(data-placeholder);
    color: var(--cogs-text-icon--interactive--disabled);
  }
`;

export const StyledDivContainer = styled(Flex)`
  flex-direction: column;
  border-radius: var(--cogs-border-radius--default);
  border: 2px solid var(--cogs-themed-border--interactive--default);
  background-color: white;
`;

export const LimitMessage = styled(Body).attrs({
  size: 'x-small',
})<{
  $limitReached: boolean;
}>`
  color: var(--cogs-text-icon--status-critical);
  display: ${(props) => (props.$limitReached ? 'flex' : 'none')};
`;

export const LimitCounter = styled(Body).attrs({
  size: 'x-small',
})<{
  $limitReached: boolean;
}>`
  margin-left: auto;
  color: ${(props) =>
    props.$limitReached
      ? 'var(--cogs-text-icon--status-critical)'
      : 'var(--cogs-text-icon--status-undefined)'};
`;
