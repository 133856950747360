import { Flex, Row } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled.div`
  height: 305px;
  overflow: auto;
`;

export const MediaGrid = styled(Row).attrs(() => ({ cols: 2, gap: 10 }))`
  width: fit-content;
`;

export const StyledFlex = styled(Flex).attrs({
  direction: 'column',
  gap: 8,
})`
  padding-top: 8px;
`;
