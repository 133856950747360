import type { LocationFilterDTO } from '@cognite/apm-config';
import { ADSLocationFilterSDK } from '@cognite/apm-config';
import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useADSLocationConfigQuery = (enabled = true) => {
  const { client } = useAuthContext();

  const locationConfigClient = new ADSLocationFilterSDK(client);

  return useQuery<LocationFilterDTO[] | undefined, Error>(
    [QueryKeys.ADS_LOCATION_CONFIG],
    async () => {
      if (!client.project) throw new Error('No project');

      const locationConfig = await locationConfigClient.listLocationFilter({
        flat: true,
      });

      return locationConfig.items;
    },
    {
      onError: (err) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.ADS_LOCATION_CONFIG,
          },
        });
      },
      staleTime: 86400000, // 1 day
      cacheTime: 86400000, // 1 day
      enabled,
    }
  );
};
