import {
  type APMClient,
  type FdmFile,
  VIEW_VERSIONS,
} from '@cognite/apm-client';
import type {
  InstanceId,
  InstanceOrExternalId,
} from '@cognite/apm-client/src/file/types';
import UploadGCS from '@cognite/gcs-browser-upload';
import type {
  CogniteClient,
  EdgeDefinition,
  FileFilterProps,
  FileInfo,
  FileUploadResponse,
} from '@cognite/sdk';
import type { IdmAnnotation } from '@infield/features/file-viewer-modal/types';
import axios from 'axios';
import { v4 as uuid } from 'uuid';

import { fileFilterToFdmFilter, FileIDMMigrator } from './file-idm-migrator';
import type { BlobFile, ImageItemMetadata, MediaUpsertType } from './types';
import { isSupportedDocumentType, toFdmFile } from './utils';

export class MediaService {
  client;
  apmClient;
  isIdm: boolean;

  constructor(client: CogniteClient, apmClient: APMClient, isIdm: boolean) {
    this.client = client;
    this.apmClient = apmClient;
    this.isIdm = isIdm;

    if (isIdm && this.apmClient.files && apmClient.options?.filesView?.space) {
      const migrator = new FileIDMMigrator(apmClient.options.filesView.space);
      this.apmClient.files.migrator = migrator;
    }
  }
  listAllFiles = async (
    limit = 100,
    filter?: FileFilterProps
  ): Promise<FdmFile[]> => {
    if (this.isIdm && this.apmClient.files) {
      const { items } = await this.apmClient.files.list({
        spaces: [
          this.apmClient.appDataInstanceSpace,
          this.apmClient.sourceDataInstanceSpace,
        ],
        filters: fileFilterToFdmFilter(
          this.apmClient.sourceDataInstanceSpace,
          filter
        ),
      });

      return items;
    }

    return this.client.files
      .list({
        filter,
      })
      .autoPagingToArray({ limit })
      .then((files) => files.map(toFdmFile));
  };

  getAllMedia = async (
    limit = 100,
    filter?: FileFilterProps
  ): Promise<FdmFile[]> => {
    const mimeTypes = [
      'image/png',
      'image/jpeg',
      'video/quicktime',
      'video/mp4',
    ];

    const mediaPromises = mimeTypes.map((mimeType) =>
      this.listAllFiles(limit, {
        ...filter,
        mimeType,
        uploaded: true,
      })
    );
    const images = await Promise.all(mediaPromises);

    return images.flat();
  };

  getBlobByFileExternalId = async (
    fileExternalId: string
  ): Promise<Blob | null> => {
    const fileLinks = await this.client.files.getDownloadUrls([
      { externalId: fileExternalId },
    ]);
    const resp = await axios.get(fileLinks[0].downloadUrl, {
      responseType: 'blob',
    });
    return resp.data;
  };

  getBlobByFileInstanceId = async (
    externalId: string,
    space: string
  ): Promise<Blob | null> => {
    // This method should be replaced by the js sdk when it's available.
    const fileLinks = await this.client
      .post<{ items: (InstanceId & { downloadUrl: string })[] }>(
        `/api/v1/projects/${this.client.project}/files/downloadlink`,
        {
          data: {
            items: [
              {
                instanceId: {
                  externalId,
                  space,
                },
              },
            ],
          },
        }
      )
      .then((res) => res.data.items);
    const resp = await axios.get(fileLinks[0].downloadUrl, {
      responseType: 'blob',
    });
    return resp.data;
  };

  getMediaByUrl = async (imageURL: string) => {
    const resp = await this.client.get<BlobPart>(imageURL, {
      responseType: 'arraybuffer',
    });

    const data = new Blob([resp.data]);
    const url = URL.createObjectURL(data);
    return url;
  };

  getFileByExternalId = async (externalId: string): Promise<FileInfo> => {
    return this.client.files
      .retrieve([
        {
          externalId,
        },
      ])
      .then((files) => files[0]);
  };

  getFileByInstanceId = async (externalId: string, space: string) => {
    const fdmFile = await this.apmClient.files!.byId([{ externalId, space }]);
    return { ...fdmFile[0], id: 0 } as FileInfo;
  };

  getMediaByFileExternalId = async (fileExternalId: string, space?: string) => {
    let fileInfo: FileInfo | undefined;
    let blob: Blob | null;

    if (this.isIdm && space) {
      fileInfo = await this.getFileByInstanceId(fileExternalId, space);
      blob = await this.getBlobByFileInstanceId(fileExternalId, space);
    } else {
      fileInfo = await this.getFileByExternalId(fileExternalId);
      blob = await this.getBlobByFileExternalId(fileExternalId);
    }
    if (!blob)
      throw new Error(
        `No file with an external ID of ${fileExternalId} was found`
      );

    const blobType = fileInfo.mimeType?.startsWith('image') ? 'image' : 'video';
    const blobFile: BlobFile = {
      url: URL.createObjectURL(blob),
      type: blobType,
    };

    return {
      externalId: fileInfo.externalId,
      metadata: { ...fileInfo.metadata },
      fileUrl: blobFile.url,
      url: {
        type: blob.type,
        size: blob.size,
        name: fileInfo.metadata!.fileName || fileInfo.metadata!.name,
      } as File,
      space,
    };
  };

  uploadMedia = async ({
    assetIds = [],
    assetInstanceIds = [],
    metadata,
    image,
    author,
    externalId,
    dataSetId,
    name,
    isAwsProject,
    space,
  }: {
    assetIds?: number[];
    assetInstanceIds?: InstanceId[];
    metadata: ImageItemMetadata;
    image: File;
    author: string;
    externalId?: string;
    dataSetId?: number;
    name?: string;
    isAwsProject: boolean;
    space?: string;
  }) => {
    let uploadUrl;
    if (this.isIdm && this.apmClient.files && space) {
      await this.apmClient?.files.upsert([
        {
          name: name ?? uuid(),
          mimeType: image.type,
          externalId: externalId || '',
          metadata: {
            ...(metadata as Record<string, string>),
            author,
          },
          dataSetId,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          assets: assetInstanceIds,
          space,
        },
      ]);

      const uploadedFile = await this.client
        .post<{ items: FileUploadResponse[] }>(
          `/api/v1/projects/${this.client.project}/files/uploadlink`,
          {
            data: {
              items: [
                {
                  instanceId: {
                    externalId,
                    space,
                  },
                },
              ],
            },
          }
        )
        .then((res) => res.data.items);
      uploadUrl = uploadedFile[0].uploadUrl;
    } else {
      const fileUploadResponse: FileUploadResponse =
        (await this.client.files.upload({
          name: name ?? uuid(),
          mimeType: image.type,
          externalId,
          metadata: {
            ...(metadata as Record<string, string>),
            source: 'APP',
            author,
          },
          assetIds,
          dataSetId,
        })) as FileUploadResponse;
      uploadUrl = fileUploadResponse.uploadUrl;
    }

    const chunkMultiple = Math.min(
      Math.max(
        2, // at least 2 chunks
        Math.ceil((image.size / 20) * 262144) // Calculate the number of 256 KB chunks in 1/20th of the image size
      ),
      200 // no more than 200 chunks
    );
    if (isAwsProject) {
      // AWS projects require a different upload method than UploadGCS allows. We will make a more permanent solution soon.
      await axios({
        method: 'PUT',
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': image.type,
        },
        url: uploadUrl,
        data: image,
      });
    } else {
      await new UploadGCS({
        id: 'datastudio-upload',
        url: uploadUrl,
        file: image,
        contentType: image.type,
        chunkSize: 262144 * chunkMultiple,
      }).start();
    }

    return { externalId, space };
  };

  deleteMedia = async ({
    fileInstanceIds,
  }: {
    fileInstanceIds: InstanceOrExternalId[];
  }) => {
    if (fileInstanceIds.length > 0) {
      if (this.isIdm && this.apmClient.files) {
        return this.apmClient.files.delete(fileInstanceIds);
      }
      return this.client.files.delete(
        fileInstanceIds.map(({ externalId }) => ({ externalId }))
      );
    }
  };

  updateMedia = async ({
    items,
    dataSetId,
  }: {
    // id is required for changes, and metadata is
    // the only thing that can be changed by the user
    items: MediaUpsertType;
    dataSetId?: number;
  }): Promise<{ items: FdmFile[] }> => {
    if (this.isIdm && this.apmClient.files) {
      const fdmFiles: FdmFile[] = items.map((item) => {
        return {
          externalId: item.externalId!,
          space: item.space,
          metadata: item.metadata,
        };
      });
      await this.apmClient.files.upsert(fdmFiles);
      return { items: fdmFiles };
    }
    const formattedItems = items.map((item) => {
      return {
        externalId: item.externalId!,
        update: {
          ...(Boolean(item.metadata) && {
            metadata: {
              add: { ...(item.metadata as Record<string, string>) },
              remove: [],
            },
          }),
          ...(Boolean(item.assetIds) && {
            assetIds: { set: item.assetIds as number[] },
          }),
          ...(dataSetId && { dataSetId: { set: dataSetId } }),
        },
      };
    });

    const filesInfo = await this.client.files.update(formattedItems);

    return { items: filesInfo.map(toFdmFile) };
  };

  getDocuments = async (
    filter?: FileFilterProps & { assetExternalIds?: string[] },
    limit?: number,
    isAkerbpCustomCode = false
  ): Promise<FdmFile[]> => {
    const documents = await this.listAllFiles(limit, filter);
    return documents.filter((document) =>
      isSupportedDocumentType(document, isAkerbpCustomCode)
    );
  };

  searchDocuments = async (
    query: string,
    limit?: number,
    filter?: FileFilterProps,
    isAkerbpCustomCode = false
  ) => {
    let documents: FdmFile[] = [];
    if (this.isIdm && this.apmClient.files) {
      // Convert filters
      const newFilters = fileFilterToFdmFilter(
        this.apmClient.sourceDataInstanceSpace,
        filter,
        true
      );

      // Get from apmClient
      documents = await this.apmClient.files.search({
        query,
        filters: newFilters,
        pageSize: limit,
        spaces: [
          this.apmClient.appDataInstanceSpace,
          this.apmClient.sourceDataInstanceSpace,
        ],
      });
    } else {
      documents = await this.client.files
        .search({
          search: {
            name: query,
          },
          filter,
          limit,
        })
        .then((documents) => documents.map(toFdmFile));
    }

    return documents.filter((document) =>
      isSupportedDocumentType(document, isAkerbpCustomCode)
    );
  };

  getFileAnnotations = async (
    fileExternalId: string
  ): Promise<IdmAnnotation[]> => {
    const viewReference = {
      externalId: 'CogniteDiagramAnnotation',
      space: 'cdf_cdm',
      version: VIEW_VERSIONS.ANNOTATION,
    };

    const response = await this.client.instances.query({
      with: {
        files: {
          nodes: {
            filter: {
              in: {
                property: ['node', 'externalId'],
                values: [fileExternalId],
              },
            },
          },
        },
        annotations: {
          edges: {
            from: 'files',
            direction: 'outwards',
          },
        },
      },
      select: {
        annotations: {
          sources: [
            {
              source: {
                externalId: 'CogniteDiagramAnnotation',
                space: 'cdf_cdm',
                type: 'view',
                version: VIEW_VERSIONS.ANNOTATION,
              },
              properties: [
                'confidence',
                'status',
                'startNodeText',
                'startNodeYMax',
                'startNodeYMin',
                'startNodeXMax',
                'startNodeXMin',
                'endNodeText',
                'endNodeYMax',
                'endNodeYMin',
                'endNodeXMax',
                'endNodeXMin',
                'startNodePageNumber',
                'endNodePageNumber',
              ],
            },
          ],
        },
      },
    });

    const annotations = response.items.annotations
      .filter((annotation) => annotation.properties)
      .map((annotation) => {
        const annotationEdge = annotation as EdgeDefinition;
        return {
          type: annotationEdge.type,
          startNode: annotationEdge.startNode,
          endNode: annotationEdge.endNode,
          externalId: annotationEdge.externalId,
          properties:
            annotationEdge.properties![viewReference.space][
              `${viewReference.externalId}/${viewReference.version}`
            ],
        } as IdmAnnotation;
      });

    return annotations;
  };
}
