import { useEffect } from 'react';

import { Chart, useChart, useChartAtom } from '@cognite/charts-lib';

// Based on useInitializedChart found in the Charts code in Fusion
const useInitializedChart = ({
  id,
  projectId,
}: {
  id: string;
  projectId: string;
}): { data: Chart | undefined; isFetching: boolean; isError: boolean } => {
  const [chart, setChart] = useChartAtom();
  const {
    data: originalChart,
    isFetched,
    isError,
  } = useChart({ id, projectId });

  useEffect(() => {
    if (originalChart === undefined || chart?.id === id) {
      return;
    }
    setChart(originalChart);
  }, [originalChart, chart, id, setChart]);

  return {
    data: chart,
    isFetching:
      !isFetched ||
      (isFetched && originalChart !== undefined && chart === undefined),
    isError,
  };
};

export default useInitializedChart;
