import type { InFieldLocationConfig } from '@cognite/apm-client';
import type { LocationFilterDTO } from '@cognite/apm-config';
import {
  useInFieldLocationConfig,
  useUpsertInfieldLocationConfig,
} from '@infield/features/app-config';
import type { FC } from 'react';
import { useState } from 'react';

import * as S from './elements';
import { useADSUpsertLocation } from './hooks';
import { LocationGeneral } from './location-general';
import { LocationHeader } from './location-header';
import { LocationInfieldAssetExplorer } from './location-infield-asset-explorer';
import { LocationInfieldInstanceSpace } from './location-infield-instance-space';
import { LocationInstanceSpaces } from './location-instance-spaces';
import { LocationMenu } from './location-menu';
import { LocationViewMappings } from './location-view-mappings/location-view-mappings';
import type { LocationMenuItems } from './types';

interface Props {
  selectedLocation: LocationFilterDTO | undefined;
  onNavigateToLocationList: () => void;
}

export const LocationView: FC<Props> = ({
  selectedLocation,
  onNavigateToLocationList,
}) => {
  const [activeMenu, setActiveMenu] = useState<LocationMenuItems>(
    'locationDescription'
  );

  const {
    data: infieldLocationConfig,
    isInitialLoading: isInfieldConfigLoading,
  } = useInFieldLocationConfig(selectedLocation?.externalId);

  const { mutateAsync: upsertADSLocation, isLoading: isUpsertingADSLocation } =
    useADSUpsertLocation();
  const {
    mutateAsync: upsertInFieldLocation,
    isLoading: isUpsertingInfieldLocation,
  } = useUpsertInfieldLocationConfig();

  const handleSaveADSLocation = (location: LocationFilterDTO) => {
    if (!location) return;
    const nextLocation = {
      ...location,
      createdTime: undefined,
      lastUpdatedTime: undefined,
    };

    upsertADSLocation({
      nextLocation,
    });
  };

  const handleSaveInfieldLocation = (nextConfig?: InFieldLocationConfig) => {
    if (!nextConfig) return;
    upsertInFieldLocation([nextConfig]);
  };

  const pageContent = () => {
    switch (activeMenu) {
      case 'locationDescription':
        return (
          <LocationGeneral
            location={selectedLocation}
            isSaving={isUpsertingADSLocation}
            onSave={handleSaveADSLocation}
          />
        );
      case 'viewMappings':
        return (
          <LocationViewMappings
            location={selectedLocation}
            isSaving={isUpsertingADSLocation}
            onSave={handleSaveADSLocation}
          />
        );
      case 'instanceSpaces':
        return (
          <LocationInstanceSpaces
            location={selectedLocation}
            isSaving={isUpsertingADSLocation}
            onSave={handleSaveADSLocation}
          />
        );
      case 'infieldInstanceSpace':
        return (
          <LocationInfieldInstanceSpace
            adsLocationId={selectedLocation?.externalId}
            infieldLocation={infieldLocationConfig}
            onSave={handleSaveInfieldLocation}
          />
        );
      case 'assetExplorer':
        return (
          <LocationInfieldAssetExplorer
            adsLocationId={selectedLocation?.externalId}
            infieldLocation={infieldLocationConfig}
            isSaving={isUpsertingInfieldLocation}
            onSave={handleSaveInfieldLocation}
          />
        );
      default:
        return (
          <LocationGeneral
            location={selectedLocation}
            isSaving={isUpsertingADSLocation}
            onSave={handleSaveADSLocation}
          />
        );
    }
  };

  return (
    <S.LocationContainer direction="column">
      <LocationHeader
        onNavigateToLocationList={onNavigateToLocationList}
        location={selectedLocation}
      />
      <S.LocationContainer>
        <LocationMenu
          activeMenu={activeMenu}
          isInfieldConfigLoading={isInfieldConfigLoading}
          setActiveMenu={setActiveMenu}
        />
        {pageContent()}
      </S.LocationContainer>
    </S.LocationContainer>
  );
};
