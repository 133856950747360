import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex)`
  min-width: 0px;
`;

export const DataGridWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  min-height: 0;
  height: calc(100vh - 112px);
  min-width: 0;
  overflow: auto;
  flex: 56;
`;

const SidebarInfo = styled(Flex)`
  height: calc(100vh - 112px);
  overflow: auto;
  min-width: 320px;
  border-left: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const AssetInfo = styled(SidebarInfo)`
  &&& {
    flex: 44;
  }
`;

export const ObservationInfo = styled(SidebarInfo)`
  &&& {
    width: 320px;
  }
`;
