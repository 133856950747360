import { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactContainerRenderContentProps } from '../ReactContainer';
import useStatusChange from '../ReactContainer/useStatusChange';

import { VideoContainerProps } from './types';
import VideoControls from './VideoControls';
import VideoWrapper from './VideoWrapper';

type VideoContentProps = Pick<
  ReactContainerRenderContentProps,
  | 'width'
  | 'height'
  | 'setLoadingStatus'
  | 'onContentSizeChange'
  | 'shouldAutoSize'
  | 'unscaledWidth'
  | 'unscaledHeight'
> &
  Pick<VideoContainerProps, 'url' | 'currentTimeSeconds'> & {
    onRef: (ref: HTMLVideoElement | null) => void;
  };

const VideoContent: React.FC<VideoContentProps> = ({
  setLoadingStatus,
  onContentSizeChange,
  shouldAutoSize = true,
  onRef,
  url,
  currentTimeSeconds, // currentTimeSeconds is the container config value,
  unscaledWidth,
  unscaledHeight,
  width,
  height,
}) => {
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null);
  const [hasMetadataLoaded, setHasMetadataLoaded] = useState(false);
  const [hasFirstFrameLoaded, setHasFirstFrameLoaded] = useState(false);
  const [hasSeeked, setHasSeeked] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  // dirtyCurrentTimeSeconds is used while the user is seeking using the seekbar.
  // Once the sought time has been persisted, it is set to undefined again.
  const [dirtyCurrentTimeSeconds, setDirtyCurrentTimeSeconds] = useState<
    number | undefined
  >(undefined);
  // readCurrentTimeSeconds reflects the current time of the video as read by the video element itself.
  const [readCurrentTimeSeconds, setReadCurrentTimeSeconds] = useState(
    currentTimeSeconds ?? 0
  );

  useStatusChange({
    data: url,
    isLoading:
      !hasFirstFrameLoaded ||
      !hasMetadataLoaded ||
      !hasSeeked ||
      videoRef === null,
    isError: false,
    setLoadingStatus,
  });

  const handleRef = useCallback(
    (ref: HTMLVideoElement | null) => {
      setVideoRef(ref);
      onRef(ref);
    },
    [onRef]
  );

  useEffect(() => {
    if (!hasFirstFrameLoaded || !hasMetadataLoaded) {
      return;
    }

    if (videoRef === null) {
      return;
    }

    if (!shouldAutoSize) {
      return;
    }

    onContentSizeChange({
      width: videoRef.videoWidth,
      height: videoRef.videoHeight,
    });
  }, [
    hasMetadataLoaded,
    hasFirstFrameLoaded,
    videoRef,
    shouldAutoSize,
    onContentSizeChange,
  ]);

  useEffect(() => {
    if (!hasFirstFrameLoaded || !hasMetadataLoaded) {
      return;
    }

    if (videoRef === null) {
      return;
    }

    if (
      currentTimeSeconds !== undefined &&
      videoRef.paused &&
      // Fun fact, setting the currentTime to the same value as it already is will not necessarily
      // end up rendering the same frame. This is probably because the browser will seek to the nearest keyframe.
      videoRef.currentTime !== currentTimeSeconds
    ) {
      videoRef.currentTime = currentTimeSeconds;
    }

    setHasSeeked(true);
  }, [hasMetadataLoaded, hasFirstFrameLoaded, videoRef, currentTimeSeconds]);

  const handleLoadedData = useCallback(() => {
    setHasFirstFrameLoaded(true);
  }, []);

  const handleLoadedMetadata = useCallback(() => {
    setHasMetadataLoaded(true);
  }, []);

  const handleSeek = useCallback((timeSeconds: number) => {
    setDirtyCurrentTimeSeconds(timeSeconds);
  }, []);

  useEffect(() => {
    if (!hasFirstFrameLoaded) {
      return;
    }

    if (videoRef === null) {
      return;
    }

    if (dirtyCurrentTimeSeconds === undefined) {
      return;
    }

    if (dirtyCurrentTimeSeconds !== videoRef.currentTime) {
      videoRef.currentTime = dirtyCurrentTimeSeconds;
    }
    setDirtyCurrentTimeSeconds(undefined);
  }, [dirtyCurrentTimeSeconds, hasFirstFrameLoaded, videoRef]);

  const onVideoClick = useCallback(() => {
    if (videoRef === null) {
      return;
    }

    const isPaused = videoRef.paused;
    if (isPaused) {
      videoRef.play();
      return;
    }

    videoRef.pause();
  }, [videoRef]);

  const scale = Math.min(width / unscaledWidth, height / unscaledHeight);

  return (
    <div style={{ transform: `scale(${scale})`, transformOrigin: 'top left' }}>
      <Container width={unscaledWidth} height={unscaledHeight}>
        <VideoWrapper
          url={url}
          onRef={handleRef}
          onLoadedData={handleLoadedData}
          onLoadedMetadata={handleLoadedMetadata}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onTimeUpdate={() =>
            setReadCurrentTimeSeconds(videoRef?.currentTime || 0)
          }
          onClick={onVideoClick}
          width={unscaledWidth}
          height={unscaledHeight}
        />
        {videoRef !== null && (
          <VideoControls
            videoRef={videoRef}
            isPlaying={isPlaying}
            currentTimeSeconds={
              dirtyCurrentTimeSeconds ?? readCurrentTimeSeconds
            }
            duration={videoRef.duration}
            onSeek={handleSeek}
          />
        )}
      </Container>
    </div>
  );
};

const Container = styled.div<{ width: number; height: number }>`
  box-sizing: border-box;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  cursor: default;
  background-color: black;
  position: relative;
`;

export default VideoContent;
