import type { TimeseriesViewMigrator } from '@cognite/apm-client';
import type { CogniteTimeseries } from '@cognite/apm-client/src/timeseries/types';
import type { Filters, Sort } from '@cognite/fdm-client';
import type { ExternalId, Timeseries, TimeseriesFilter } from '@cognite/sdk';

export const TIMESERIES_ASSET_EXTERNAL_ID_KEY =
  'IDM_INFIELD_APP_FIELD_FOR_ASSET_EXTERNAL_ID';

export class TimeseriesIDMMigrator implements TimeseriesViewMigrator {
  mapDataOutward(data: CogniteTimeseries): Timeseries {
    return {
      id: 0, // We shouldn't be using this property
      externalId: data.externalId,
      name: data.name,
      isString: data.type === 'string',
      // assetId:  // Asset ExternalId is Added in metadata instead
      metadata: {
        [TIMESERIES_ASSET_EXTERNAL_ID_KEY]: data.assets?.[0]?.externalId || '',
      },
      unit: data.unit?.name,
      // dataSetId, // we don't need this
      isStep: Boolean(data.isStep),
      description: data.description || '',
      // securityCategories // We don't need this
      unitExternalId: data.unit?.externalId,
      lastUpdatedTime: data.lastUpdatedTime
        ? new Date(data.lastUpdatedTime)
        : new Date(),
      createdTime: data.createdTime ? new Date(data.createdTime) : new Date(),
    };
  }

  mapDataInward(data: Timeseries): CogniteTimeseries {
    // We dont upsert timeseries in Data explorer, so not needed at the moment.
    return data as unknown as CogniteTimeseries;
  }

  mapFiltersInward(filters: Filters): Filters {
    return filters;
  }

  mapPropertiesInward = (properties: string[]): string[] => {
    // No property mapping needed at the moment for data explorer
    const propertyMap: Record<string, string> = {};
    return properties.map((property) => propertyMap[property] || property);
  };

  mapSortInward(sort: Sort): Sort {
    // Not needed at the moment for data explorer
    return sort;
  }
}

export const timeseriesFilterToFdmFilter = (
  assetSpace: string,
  filters?: TimeseriesFilter,
  isSearch = false
): Filters | undefined => {
  if (!filters) {
    return undefined;
  }
  // All TimeseriesFilter properties
  // name?: TimeseriesName;
  // unit?: TimeseriesUnit;
  // isString?: TimeseriesIsString;
  // isStep?: TimeseriesIsStep;
  // metadata?: Metadata;
  // assetIds?: CogniteInternalId[];
  // assetExternalIds?: CogniteExternalId[];
  // rootAssetIds?: CogniteInternalId[];
  // dataSetIds?: IdEither[];
  // assetSubtreeIds?: IdEither[];
  // externalIdPrefix?: ExternalIdPrefix;
  // unitExternalId?: CogniteExternalId;
  // lastUpdatedTime?: DateRange;
  // createdTime?: DateRange;

  // Need to update this
  const newFilters: Filters[] = [];

  // Currently not used in application
  // Therefore, not implemented
  // throwing error to make sure it is implemented when needed
  if (
    filters.name ||
    filters.unit ||
    filters.isString ||
    filters.isStep ||
    filters.assetIds ||
    filters.rootAssetIds ||
    filters.externalIdPrefix ||
    filters.unitExternalId ||
    filters.lastUpdatedTime ||
    filters.createdTime
  ) {
    throw new Error('TIMESERIES: Filter not implemented');
  }

  if (filters.dataSetIds) {
    // filter is not required in IDM
  }

  if (filters?.assetExternalIds && filters.assetExternalIds.length > 0) {
    newFilters.push({
      in: {
        property: 'assets',
        in: filters.assetExternalIds.map((externalId) => ({
          externalId,
          space: assetSpace,
        })),
      },
    });
  }

  if (
    filters?.assetSubtreeIds &&
    filters.assetSubtreeIds.length > 0 &&
    isSearch
  ) {
    // This functionality is only available in search API.
    // Which covers our use case.
    newFilters.push({
      nested: {
        scope: 'assets',
        filters: {
          containsAny: {
            property: 'path',
            containsAny: filters.assetSubtreeIds.map((subTreeId) => ({
              space: assetSpace,
              externalId: (subTreeId as ExternalId).externalId,
            })),
          },
        },
      },
    });
  }

  if (filters?.metadata) {
    // Currently we only use is_highlighted metadata in asset explorer.
    // We won't be providing this filter in IDM for now.
    // throw new Error('TIMESERIES: Filter not implemented');
  }

  const timeseriesFilters: Filters = {
    and: [...newFilters],
  };

  return timeseriesFilters;
};
