/**
 * Calculate the number of milliseconds between two dates based on the number of pixels
 * @param dateFrom The start date
 * @param dateTo The end date
 * @param numberOfPixels The number of pixels
 * @param containerWidthInPx The width of the container in pixels
 * @returns Number of milliseconds numerOfPixels corresponds to given the date range and container width
 */
export const calculateDistanceInPxToMs = (
  dateFrom: Date,
  dateTo: Date,
  numberOfPixels: number,
  containerWidthInPx: number
) => {
  const differenceInMs = Math.abs(dateTo.getTime() - dateFrom.getTime());
  const msPerPixel = differenceInMs / containerWidthInPx;
  return msPerPixel * numberOfPixels;
};
