import { Position, Size } from './types';

const getDenormalizedRectDimensionsFromAnnotation = (
  annotation: Size & Position & { containerId: string },
  containerRect: Size
): Size & Position => {
  return {
    x: containerRect.width * annotation.x,
    y: containerRect.height * annotation.y,
    width: containerRect.width * annotation.width,
    height: containerRect.height * annotation.height,
  };
};

export default getDenormalizedRectDimensionsFromAnnotation;
