import { Body, Button, Flex } from '@cognite/cogs.js-v10';
import { assetLinkStyle } from '@infield/features/asset/shared/elements';
import { device } from '@infield/utils/media-queries';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
})`
  height: 100%;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme['surface--muted']};
  overflow-wrap: break-word;
`;

export const Header = styled(Flex).attrs({ direction: 'column' })`
  padding: 16px 16px 8px 16px;
`;

export const MandatoryFields = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;

  @media ${device.max.tablet} {
    display: block;
    > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const MandatoryKey = styled(Body).attrs({ size: 'xx-small' })`
  min-width: 140px;
  color: ${({ theme }) => theme['text-icon--muted']};
`;

export const AssetName = styled.div`
  max-width: 100%;
  white-space: nowrap;
  ${assetLinkStyle};
`;

export const Content = styled(Flex).attrs({
  direction: 'column',
  gap: 16,
})`
  padding: 16px;
  overflow: auto;
  height: 100%;
  user-select: 'none';
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TagContainer = styled(Flex).attrs({
  gap: 8,
})`
  height: 58px;
  min-height: 58px;
  padding: 8px 16px;
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
  border-top: 1px solid ${({ theme }) => theme['border--muted']};
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const Tag = styled(Button)`
  .cogs.cogs-chip {
    margin-left: 5px;
  }
`;
