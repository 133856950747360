import Konva from 'konva';
import { IRect } from 'konva/cmj/types';
import cloneDeep from 'lodash/cloneDeep';

import { Container } from '../containers/Container';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import UnifiedViewerRenderer from '../UnifiedViewerRenderer';
import getFontSizeInAbsoluteUnits from '../utils/getFontSizeInAbsoluteUnits';
import isFontSizeInPixelUnits from '../utils/isFontSizeInPixelUnits';
import shamefulSafeKonvaScale from '../utils/shamefulSafeKonvaScale';

import getMaxDiffingScalingFactor from './getMaxDiffingScalingFactor';
import setAnnotationNodeEventHandlers from './setAnnotationNodeEventHandlers';
import shouldBeDraggable from './shouldBeDraggable';
import shouldBeResizable from './shouldBeResizable';
import shouldBeSelectable from './shouldBeSelectable';
import {
  AnnotationSerializer,
  AnnotationType,
  FontSize,
  Scale,
  Size,
  TextAnnotation,
} from './types';

type TextDimensions = {
  x: number;
  y: number;
  fontSize: number;
};

const getDimensions = (
  annotation: TextAnnotation,
  getContainerById: (id: string) => Container | undefined
) => {
  if (annotation.containerId === undefined) {
    // Note: The annotation validation we do earlier ensures that text specified without a
    // parent container must have its font size given in absolute (pixel) units.
    return getTextPropertiesFromAnnotation(annotation);
  }

  const container = getContainerById(annotation.containerId);
  if (container === undefined) {
    return undefined;
  }

  return getDenormalizedTextPropertiesFromAnnotation(
    annotation,
    container.getContentNode().size()
  );
};

const getTextPropertiesFromAnnotation = (
  annotation: TextAnnotation
): TextDimensions => ({
  x: annotation.x,
  y: annotation.y,
  fontSize: getFontSizeInAbsoluteUnits(annotation.style.fontSize),
});

const getNormalizedTextPropertiesFromNode = (
  node: Konva.Text,
  getContainerRectById: (id: string) => IRect | undefined
): TextDimensions => {
  const useNormalizedCoordinates = node.attrs.containerId !== undefined;

  if (!useNormalizedCoordinates) {
    return {
      x: node.x(),
      y: node.y(),
      fontSize: node.fontSize(),
    };
  }

  const containerRect = getContainerRectById(node.attrs.containerId);
  if (containerRect === undefined) {
    throw new Error('Could not find container dimensions for annotation');
  }

  return {
    x: node.x() / containerRect.width,
    y: node.y() / containerRect.height,
    fontSize: node.fontSize() / containerRect.width,
  };
};

const getDenormalizedTextPropertiesFromAnnotation = (
  annotation: TextAnnotation,
  containerRect: Size
): TextDimensions => {
  return {
    x: containerRect.width * annotation.x,
    y: containerRect.height * annotation.y,
    fontSize: getFontSizeInAbsoluteUnits(
      annotation.style.fontSize,
      containerRect.width
    ),
  };
};

type TextAnnotationNodeProperties<MetadataType> = {
  [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION;
  annotationType: AnnotationType.TEXT;
  id: string;
  strokeScaleEnabled: boolean;
  containerId?: string;
  text: string;
  isSelectable: boolean;
  isDraggable: boolean;
  isResizable: boolean;
  originalFontSize: FontSize;
  metadata?: MetadataType;
} & TextDimensions;

const getNodePropertiesFromAnnotation = <MetadataType>(
  annotation: TextAnnotation<MetadataType>,
  unifiedViewerRenderer: UnifiedViewerRenderer
): TextAnnotationNodeProperties<MetadataType> | undefined => {
  {
    const dimensions = getDimensions(
      annotation,
      unifiedViewerRenderer.getContainerById
    );
    if (dimensions === undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        `Could not get dimensions for annotation ${annotation.id}. If it has a container, maybe the container hasn't loaded yet?`
      );
      return undefined;
    }

    return {
      [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
      annotationType: AnnotationType.TEXT,
      id: annotation.id,
      strokeScaleEnabled: false,
      containerId: annotation.containerId,
      text: annotation.text,
      isSelectable: shouldBeSelectable(annotation.isSelectable),
      isDraggable: shouldBeDraggable(annotation.isDraggable),
      isResizable: shouldBeResizable(annotation.isResizable),
      originalFontSize: annotation.style.fontSize,
      metadata: annotation.metadata,
      // Note: If containerId is set, then we always should assume that the dimensions are
      // normalized.
      ...(annotation.style ?? {}),
      ...dimensions,
    };
  }
};

type TextTransformationProperties = {
  scale: Scale;
  fontSize: number;
};

const TextSerializer: AnnotationSerializer<
  TextAnnotation,
  TextTransformationProperties
> = {
  isSelectable: (node: Konva.Node) =>
    shouldBeSelectable(node.attrs.isSelectable),

  isDraggable: (node: Konva.Node) => shouldBeDraggable(node.attrs.isDraggable),

  isResizable: (node: Konva.Node) => shouldBeResizable(node.attrs.isResizable),

  getTransformationPropertiesFromNode: (node: Konva.Node) => {
    if (!(node instanceof Konva.Text)) {
      throw new Error('Expected node to be a Konva.Text');
    }

    return {
      scale: shamefulSafeKonvaScale(node.scale()),
      fontSize: node.fontSize(),
    };
  },

  normalize: ({ scale, fontSize }) => {
    const scaleFactor = getMaxDiffingScalingFactor(scale);
    return {
      // Rescale the points to the new scale factor
      fontSize: fontSize * scaleFactor,
      scale: {
        x: 1,
        y: 1,
      },
    };
  },

  serialize: (node: Konva.Node, unifiedViewer) => {
    if (!(node instanceof Konva.Text)) {
      throw new Error('Expected node to be a Konva.Text');
    }

    const {
      x,
      y,
      fontSize: normalizedFontSize,
    } = getNormalizedTextPropertiesFromNode(
      node as Konva.Text, // TODO: Improve typing
      unifiedViewer.getContainerRectRelativeToStageById
    );

    return {
      id: node.id(),
      // TODO: Need to store the type somewhere.
      type: AnnotationType.TEXT,
      text: node.text(),
      containerId: node.attrs.containerId,
      isSelectable: shouldBeSelectable(node.attrs.isSelectable),
      isDraggable: shouldBeDraggable(node.attrs.isDraggable),
      isResizable: shouldBeResizable(node.attrs.isResizable),
      x,
      y,
      style: {
        fontSize: isFontSizeInPixelUnits(node.getAttr('originalFontSize'))
          ? `${node.fontSize()}px`
          : normalizedFontSize,
        padding: node.padding(),
        fill: node.fill(),
      },
      metadata: cloneDeep(node.attrs.metadata),
    };
  },

  deserialize: (annotation, unifiedViewer, unifiedViewerRenderer) => {
    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return undefined;
    }

    return setAnnotationNodeEventHandlers(
      new Konva.Text(nodeProperties),
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },

  updateNode: (
    node,
    annotation,
    unifiedViewer,
    unifiedViewerRenderer
  ): void => {
    if (!(node instanceof Konva.Text)) {
      throw new Error('Expected node to be a Konva.Text');
    }

    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return;
    }

    node.setAttrs(nodeProperties);

    setAnnotationNodeEventHandlers(
      node,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },
};

export default TextSerializer;
