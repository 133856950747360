import type { Activity, Checklist } from '@cognite/apm-client';
import type { DateRangeType } from '@cognite/apm-observation';
import type { Sort } from '@cognite/fdm-client/src/types';
import { addRecoilKey } from '@infield/utils/recoil';
import dayjs from 'dayjs';
import { atom } from 'recoil';

import type { OverviewPageTableKind } from './types';

export const sortAtom = atom<Sort | undefined>({
  key: addRecoilKey('activity-planning-sort'),
  default: [{ endTime: 'ASC' }],
});

export const selectedActivityAtom = atom<Activity | undefined>({
  key: addRecoilKey('activity-planning-selected-activities'),
  default: undefined,
});

export const dateFilterAtom = atom<DateRangeType>({
  key: 'activity-planning-date-filter',
  default: [dayjs().startOf('week'), dayjs().endOf('week')],
});

export const selectedOverviewTableAtom = atom<OverviewPageTableKind>({
  key: 'selected-overview-table',
  default: 'maintenance-table',
});

export const overviewTableSearchQueryAtom = atom<string>({
  key: 'overview-table-search-query',
  default: '',
});

export const overviewTableRecentlyCreatedChecklistAtom = atom<
  Checklist | undefined
>({
  key: 'overview-table-created-checklist-atom',
  default: undefined,
});
