export const getProgressPercentage = (
  totalChangesToUpload: number,
  remainingChangesToUpload: number
) => {
  if (remainingChangesToUpload > totalChangesToUpload) {
    return 0;
  }

  if (totalChangesToUpload === 0) {
    return 100;
  }

  return Math.round(
    ((totalChangesToUpload - remainingChangesToUpload) * 100) /
      totalChangesToUpload
  );
};
