import { registerHistory } from '@lexical/history';
import { registerPlainText } from '@lexical/plain-text';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  createEditor,
  type LexicalEditor,
} from 'lexical';

import setStyles from '../setStyles';

import getTextContent from './getTextContent';

const HISTORY_DELAY_MS = 200;

const LEXICAL_PLACEHOLDER_CLASS_NAME = 'lexical-placeholder';
const PLACEHOLDER_TEXT = 'Type here...';
const PLACEHOLDER_CLASS_NAME = 'editor-container';
const PLACEHOLDER_COMMON_STYLE = `
  content: "${PLACEHOLDER_TEXT}";
  color: rgba(0, 0, 0, 0.6);
  font-style: italic;
  pointer-events: none;
`;

export const setEditorContainerStyles = (
  id: string,
  container: HTMLElement
): void => {
  container.className = PLACEHOLDER_CLASS_NAME;
  setStyles(
    id,
    `.${PLACEHOLDER_CLASS_NAME}:empty::before {
      ${PLACEHOLDER_COMMON_STYLE}
    }
    .${LEXICAL_PLACEHOLDER_CLASS_NAME}:has(br):not(:has(span))::before {
      ${PLACEHOLDER_COMMON_STYLE}
      position: relative;
    }`
  );
};

const registerEditorListeners = (
  editor: LexicalEditor,
  shouldWrapText?: boolean,
  shouldDisablePlaceholder?: boolean
): void => {
  registerPlainText(editor);
  registerHistory(
    editor,
    { current: null, redoStack: [], undoStack: [] },
    HISTORY_DELAY_MS
  );

  // Lexical sets a default value for white-space which makes the text unintentionally wrap. Below we override this default value.
  // Source: // https://github.com/facebook/lexical/blob/7eaf70cbfe6e1abb46f35c613d308daa0f11442d/packages/lexical/src/LexicalEditor.ts#L975-L976
  editor.registerRootListener((root) => {
    if (root === null) {
      return;
    }
    root.style.whiteSpace = shouldWrapText === true ? 'pre-wrap' : 'pre';
  });

  // When Lexical initializes the editor, the "blank" editor state will, for
  // some reason, always contain a <p>-node with a <br/>-child. This renders the
  // style rules we defined for PLACEHOLDER_CLASS_NAME ineffective. So, to
  // handle this, we manually append a style rule LEXICAL_PLACEHOLDER_CLASS_NAME
  // that is specific for the root paragraph node of the editor.
  // NOTE: we assume the following set-up; there's one root <p>-node (which we
  // append LEXICAL_PLACEHOLDER_CLASS_NAME to) where the child text nodes are
  // created as <span>-nodes. This is the default behavior of Lexical.
  editor.registerUpdateListener(({ editorState }) => {
    if (shouldDisablePlaceholder === true) {
      return;
    }
    editorState.read(() => {
      if (getTextContent(editorState).length !== 0) {
        return;
      }
      const childrenKeys = editor
        .getEditorState()
        .read(() => $getRoot().getChildrenKeys());
      // Assumption: only one root <p>-node is created by the editor
      if (childrenKeys.length !== 1) {
        return;
      }
      const childElement = editor.getElementByKey(childrenKeys[0]);
      childElement?.classList.add(LEXICAL_PLACEHOLDER_CLASS_NAME);
    });
  });
};

const createLexicalEditorAtDomNode = (
  container: HTMLElement,
  {
    initialText,
    shouldWrapText,
    shouldDisablePlaceholder,
  }: {
    initialText?: string;
    shouldWrapText?: boolean;
    shouldDisablePlaceholder?: boolean;
  }
): LexicalEditor => {
  const editor = createEditor({
    // eslint-disable-next-line no-console
    onError: console.error,
  });
  editor.setRootElement(container);
  registerEditorListeners(editor, shouldWrapText, shouldDisablePlaceholder);

  // Initialize the editor from the provided text
  editor.update(() => {
    if (initialText === undefined || initialText.length === 0) {
      return;
    }
    const root = $getRoot();
    const paragraphNode = $createParagraphNode();
    const textNode = $createTextNode(initialText);
    paragraphNode.append(textNode);
    root.append(paragraphNode);
  });
  return editor;
};

export default createLexicalEditorAtDomNode;
