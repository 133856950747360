import type { Template, TemplateItem } from '@cognite/apm-client';
import { addRecoilKey } from '@infield/utils/recoil';
import { atom } from 'recoil';

export const selectedTemplateAtom = atom<Template | undefined>({
  key: addRecoilKey('template-details-selected-template'),
  default: undefined,
});

export const selectedTaskAtom = atom<TemplateItem | undefined>({
  key: addRecoilKey('template-details-selected-task'),
  default: undefined,
});

export const checkedTasksAtom = atom<string[]>({
  key: addRecoilKey('template-details-checked-task'),
  default: [],
});

export const showAssetDetailsAtom = atom<boolean>({
  key: addRecoilKey('template-details-show-asset-details'),
  default: false,
});

export const isNewTemplateAtom = atom<boolean>({
  key: addRecoilKey('template-details-is-new-template'),
  default: false,
});
