import type { AuthTokens } from '@cognite/sdk';
import { CogniteAuthentication, REDIRECT } from '@cognite/sdk';

import type { AuthStateUser, CustomerProject } from '../../types';
import { parseJwt } from '../../utils';

import Flow from './flow-class';

class CogniteAuthFlow extends Flow {
  public projectInfo: CustomerProject;
  public tokens: AuthTokens | null = null;
  constructor(projectInfo: CustomerProject) {
    super();
    this.projectInfo = projectInfo;
  }
  async getTokenFactory(): Promise<() => Promise<string>> {
    const legacyInstance = new CogniteAuthentication({
      project: this.projectInfo.id,
      baseUrl: this.projectInfo.cluster.apiBaseURL,
    });

    await legacyInstance.handleLoginRedirect();
    // Retrieving access_token.
    this.tokens = await legacyInstance.getCDFToken();
    // // Return token if you already is logged.
    if (!this.tokens) {
      this.tokens = await legacyInstance.login({ onAuthenticate: REDIRECT });
    }

    return async () => {
      if (!legacyInstance) throw new Error('SDK instance missing');

      await legacyInstance.handleLoginRedirect();
      // Retrieving access_token.
      let token = await legacyInstance.getCDFToken();

      // Return token if you already is logged.
      if (token) return token.accessToken;

      token = await legacyInstance.login({ onAuthenticate: REDIRECT });

      // Returning token.
      if (token) return token.accessToken;

      throw new Error('error');
    };
  }
  async getUserState(): Promise<Omit<AuthStateUser, 'cdfId'>> {
    const legacyInstance = new CogniteAuthentication({
      project: this.projectInfo.id,
      baseUrl: this.projectInfo.cluster.apiBaseURL,
    });
    if (!legacyInstance) throw new Error('SDK instance missing');

    if (!this.tokens?.idToken) throw new Error('no user');
    const parsedIdToken = parseJwt(this.tokens?.idToken || '');
    const name = parsedIdToken.unique_name;
    return { id: name, email: name, name };
  }
}

export default CogniteAuthFlow;
