import type { Observation } from '@cognite/apm-client';
import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { Button, Flex, ImageIcon, UrgentIcon } from '@cognite/cogs.js-v10';
import {
  useFeatureToggleConfig,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { MediaCollectionViewer } from '@infield/features/ui/media-collection-viewer';
import { getIsObservationsPageEnabled } from '@infield/pages/utils';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { useState } from 'react';
import type { FC } from 'react';

interface Props {
  fileInstanceIds?: InstanceOrExternalId[];
  observations?: Observation[];
  onObservationsLabelClick: () => void;
}

export const ChecklistItemLabels: FC<Props> = ({
  fileInstanceIds,
  observations,
  onObservationsLabelClick,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);

  const [isImageCollectionModalVisible, setIsImageCollectionModalVisible] =
    useState(false);

  const { isOnline } = useNetworkStatusContext();
  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );
  const isObservationsFeatureEnabled = getIsObservationsPageEnabled(
    rootLocationFeatureToggles
  );

  if (
    !(fileInstanceIds && fileInstanceIds?.length > 0) &&
    !(observations && observations?.length > 0)
  )
    return null;

  return (
    <>
      {isImageCollectionModalVisible && (
        <MediaCollectionViewer
          fileInstanceIds={fileInstanceIds}
          onClose={() => setIsImageCollectionModalVisible(false)}
        />
      )}
      <Flex gap={8}>
        {fileInstanceIds && fileInstanceIds.length > 0 && (
          <Button
            size="small"
            icon={<ImageIcon />}
            onClick={() => setIsImageCollectionModalVisible(true)}
          >{`${fileInstanceIds.length} ${t(
            'CHECKLIST_ITEM_LABEL_IMAGES',
            'images'
          )}`}</Button>
        )}
        {isObservationsFeatureEnabled &&
          observations &&
          observations?.length > 0 && (
            <Button
              icon={<UrgentIcon />}
              size="small"
              data-testid="checklist-item-attached-observations-label"
              aria-disabled={!isOnline}
              onClick={!isOnline ? undefined : onObservationsLabelClick}
            >
              {t(
                'CHECKLIST_ITEM_LABEL_OBSERVATIONS',
                '{{count}} observations',
                {
                  count: observations.length,
                }
              )}
            </Button>
          )}
      </Flex>
    </>
  );
};
