import { type APMAsset } from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client';
import { useAppConfigFiltersContext } from '@infield/providers/app-config-filters-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

type Props = {
  query: string;
  limit?: number;
  filter?: Filters;
  enabled?: boolean;
};

export const useAPMAssetSearch = ({
  query,
  limit,
  filter,
  enabled = true,
}: Props) => {
  const { apmClient } = useFDMServices();
  const { asset: assetFilters } = useAppConfigFiltersContext();

  const filters: Filters[] = [];

  if (assetFilters?.dmFilters) {
    filters.push(assetFilters.dmFilters);
  }

  if (filter) {
    filters.push(filter);
  }

  return useQuery<APMAsset[] | undefined>(
    [QueryKeys.ASSET_SEARCH, query, limit, filters],
    async () => {
      return apmClient.assets.search({
        query,
        spaces: [apmClient.sourceDataInstanceSpace],
        pageSize: limit,
        filters: { and: filters },
        properties: ['title', 'description'],
      });
    },
    { enabled: Boolean(query) && enabled }
  );
};
