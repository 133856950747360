import type { FdmFile, FileCDFViewMigrator } from '@cognite/apm-client';
import type { CogniteFile } from '@cognite/apm-client/src/file/types';
import type { Filters, Sort } from '@cognite/fdm-client';
import type { ExternalId, FileFilterProps } from '@cognite/sdk';

export class FileIDMMigrator implements FileCDFViewMigrator {
  space: string;

  constructor(space: string) {
    this.space = space;
  }

  mapDataOutward = (data: CogniteFile): FdmFile => {
    return {
      externalId: data.externalId,
      space: data.space,
      name: data.name || '',
      mimeType: data.mimeType,
      directory: data.directory,

      // Modified fields
      labels: data.tags?.map((tag) => ({ externalId: tag })),
      uploaded: Boolean(data.isUploaded),
      uploadedTime: data.uploadedTime ? new Date(data.uploadedTime) : undefined,
      source: data.source?.externalId,
      metadata: {
        description: data.description || '',
        author: data.sourceCreatedUser || '',
        fileName: data.name || '',
        source: data.source?.externalId || '',
        // observationExternalId: '', address later
      },
      sourceCreatedTime: data.sourceCreatedTime
        ? new Date(data.sourceCreatedTime)
        : undefined,
      sourceModifiedTime: data.sourceUpdatedTime
        ? new Date(data.sourceUpdatedTime)
        : undefined,
      lastUpdatedTime: data.lastUpdatedTime
        ? new Date(data.lastUpdatedTime)
        : new Date(),
      createdTime: data.createdTime ? new Date(data.createdTime) : new Date(),
    };
  };

  mapDataInward(
    data: FdmFile & {
      assets: {
        externalId: string;
        space: string;
      }[];
    }
  ): CogniteFile {
    return {
      externalId: data.externalId,
      space: data.space,
      name: data.name,
      assets: data.assets,
      mimeType: data.mimeType,
      sourceCreatedUser: data.metadata?.author,
      description: data.metadata?.description,
      // Need to add source prop once InField is part of CogniteSourceSystem,
    };
  }

  mapFiltersInward(filters: Filters): Filters {
    return filters;
  }

  mapPropertiesInward = (properties: string[]): string[] => {
    const propertyMap: Record<string, string> = {
      labels: 'tags',
      uploaded: 'isUploaded',
      sourceModifiedTime: 'sourceUpdatedTime',
    };
    return properties.map((property) => propertyMap[property] || property);
  };

  mapSortInward(sort: Sort): Sort {
    return sort;
  }
}

export const fileFilterToFdmFilter = (
  assetSpace: string,
  filters?: FileFilterProps & { assetExternalIds?: string[] }, // assetExternalIds is not part of the sdk for some reason
  isSearch = false
): Filters | undefined => {
  if (!filters) {
    return undefined;
  }

  const newFilters: Filters[] = [];

  // Currently not used in application
  if (
    filters.name ||
    filters.metadata ||
    filters.rootAssetIds ||
    filters.dataSetIds ||
    filters.directoryPrefix ||
    filters.source ||
    filters.createdTime ||
    filters.lastUpdatedTime ||
    filters.uploadedTime ||
    filters.sourceCreatedTime ||
    filters.sourceModifiedTime ||
    filters.externalIdPrefix ||
    filters.labels ||
    filters.geoLocation
  ) {
    throw new Error('FILE: Filter not implemented');
  }

  if (filters?.assetExternalIds && filters.assetExternalIds.length > 0) {
    newFilters.push({
      containsAny: {
        property: 'assets',
        containsAny: filters.assetExternalIds.map((externalId) => ({
          externalId,
          space: assetSpace,
        })),
      },
    });
  }

  if (
    filters?.assetSubtreeIds &&
    filters.assetSubtreeIds.length > 0 &&
    isSearch
  ) {
    // This functionality is only available in search API.
    // Which covers our use case.

    const subtreeExternalIds: ExternalId[] = filters.assetSubtreeIds
      .filter((subTreeId) => 'externalId' in subTreeId)
      .map((subTreeId) => subTreeId as ExternalId);
    newFilters.push({
      or: [
        // to cover the case when file connected to asset other than root (nested)
        {
          nested: {
            scope: 'assets',
            filters: {
              containsAny: {
                property: 'path',
                containsAny: subtreeExternalIds.map(({ externalId }) => ({
                  space: assetSpace,
                  externalId,
                })),
              },
            },
          },
        },
        // to cover the case when file connected directly to root asset
        {
          containsAny: {
            property: 'assets',
            containsAny: subtreeExternalIds.map(({ externalId }) => ({
              externalId,
              space: assetSpace,
            })),
          },
        },
      ],
    });
  }

  if (filters?.mimeType) {
    newFilters.push({
      in: {
        property: 'mimeType',
        in: [filters.mimeType],
      },
    });
  }

  if (filters?.uploaded !== undefined) {
    newFilters.push({
      in: {
        property: 'isUploaded',
        in: [filters.uploaded],
      },
    });
  }

  if (newFilters.length > 0) {
    const fileFilters: Filters = {
      and: [...newFilters],
    };

    return fileFilters;
  }

  return undefined;
};
