import type { Checklist } from '@cognite/apm-client';
import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import type { ActivitiesWithChecklistPage } from '@infield/features/activities/types';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { getUniqueExternalIds } from '@infield/utils/filtering-helpers';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import type { QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

const MIN_ITEMS_PER_PAGE = 50;
const ITEMS_TO_FETCH = 1000;

export const useActivitiesWithChecklistInfiniteQuery = (
  activityFilter?: Filters,
  checklistFilter?: Filters,
  sort?: Sort,
  options?: Omit<
    UseInfiniteQueryOptions<
      ActivitiesWithChecklistPage,
      Error,
      ActivitiesWithChecklistPage,
      ActivitiesWithChecklistPage,
      QueryKey
    >,
    'queryKey' | 'queryFn' | 'getNextParam'
  >
) => {
  const { activityService, checklistService } = useFDMServices();
  const { activity: configFilters } = useAppConfigFiltersContext();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const activityFilterWithRootAsset: Filters[] = [];

  if (activityFilter) {
    activityFilterWithRootAsset.push({ ...activityFilter });
  }

  if (configFilters.rootAssetExternalIds) {
    activityFilterWithRootAsset.push(configFilters.rootAssetExternalIds);
  }

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading: isLoadingActivitiesWithChecklist,
    isFetchingNextPage,
    ...rest
  } = useInfiniteQuery<ActivitiesWithChecklistPage, Error>(
    [
      QueryKeys.ACTIVITY_INFINITE_WITH_CHECKLIST,
      sort,
      activityFilterWithRootAsset,
      checklistFilter,
    ],
    async ({ pageParam = undefined }) => {
      sliTimerStartTime.current = Date.now();

      const {
        items: returnedActivities,
        pageInfo: { nextCursor },
      } = await activityService.getActivitiesPaginated(
        {
          and: [...activityFilterWithRootAsset],
        },
        sort,
        pageParam,
        ITEMS_TO_FETCH
      );

      const activitiesUniqueExternalIds =
        getUniqueExternalIds(returnedActivities);

      let checklists: Checklist[] = [];
      if (activitiesUniqueExternalIds.length > 0) {
        const { items } = await checklistService.getChecklistsAndChecklistItems(
          {
            and: [
              {
                in: {
                  property: 'sourceId',
                  in: activitiesUniqueExternalIds,
                },
              },
              ...(checklistFilter ? [checklistFilter] : []),
            ],
          },
          undefined,
          undefined,
          ITEMS_TO_FETCH
        );

        checklists = items;
      }

      return {
        pageInfo: {
          endCursor: nextCursor || '',
          hasNextPage: nextCursor !== undefined,
        },
        items: {
          activities: returnedActivities,
          checklists,
        },
      };
    },
    {
      ...options,

      // TO BE REMOVED BEFORE MERGE - Since we cannot make a root location yet, theres no way to provide the correct space
      // This is here to show that the rest of the PR works, but will be removed before merging
      // enabled: Boolean(configFilters.rootAssetExternalIds),
      getNextPageParam: (lastPage) =>
        lastPage?.pageInfo.hasNextPage
          ? lastPage.pageInfo.endCursor
          : undefined,
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.ACTIVITY_INFINITE_WITH_CHECKLIST,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.ACTIVITY_INFINITE_WITH_CHECKLIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.ACTIVITY_INFINITE_WITH_CHECKLIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
    }
  );

  const activities = data?.pages.flatMap((page) => page.items.activities) || [];
  const checklists = data?.pages.flatMap((page) => page.items.checklists) || [];

  const isLoading =
    isLoadingActivitiesWithChecklist ||
    isFetchingNextPage ||
    (checklistFilter && checklists.length < MIN_ITEMS_PER_PAGE && hasNextPage);

  // We fetch the next page if checklist filter is applied and we have less than MIN_ITEMS_PER_PAGE
  useEffect(() => {
    if (
      checklistFilter &&
      checklists.length < MIN_ITEMS_PER_PAGE &&
      hasNextPage &&
      !isLoadingActivitiesWithChecklist &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    checklistFilter,
    checklists.length,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoadingActivitiesWithChecklist,
  ]);

  return {
    activities,
    checklists,
    hasNextPage,
    isLoading,
    fetchNextPage,
    ...rest,
  };
};
