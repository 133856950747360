import { Button, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  gap: 16,
  wrap: 'wrap',
  alignContent: 'flex-start',
})`
  width: 100%;
  padding: 16px 16px;
  overflow: auto;
  background: ${({ theme }) => theme['surface--muted']};
`;

export const Wrapper = styled.div`
  padding: 16px;
`;

export const SaveButton = styled(Button)`
  width: 200px;
`;

export const AddButton = styled(Button)`
  width: 200px;
`;
