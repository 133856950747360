const isZeroRect = (rect: DOMRect): boolean =>
  rect.x === 0 &&
  rect.y === 0 &&
  rect.width === 0 &&
  rect.height === 0 &&
  rect.top === 0 &&
  rect.right === 0 &&
  rect.bottom === 0 &&
  rect.left === 0;

const getCaretRect = (): DOMRect | undefined => {
  const selection = window.getSelection();
  const originalRange = selection?.getRangeAt(0);
  const range = originalRange?.cloneRange();
  if (range?.collapsed !== true) {
    return undefined;
  }

  const rect = range.getBoundingClientRect();
  // When entering empty new lines (e.g., using SHIFT+ENTER),
  // getBoundingClientRect returns, for some reason, a rectangle with size and
  // position set as zero. To mitigate this issue, we insert a "zero-width
  // space" in the range, and call getBoundingClientRect again. This second call
  // returns the expected rect of the range.
  if (isZeroRect(rect)) {
    const tmpZeroWidthTextNode = document.createTextNode('\ufeff');
    range.insertNode(tmpZeroWidthTextNode);
    const correctedRect = range.getBoundingClientRect();
    tmpZeroWidthTextNode.remove();
    return correctedRect;
  }

  return rect;
};

export default getCaretRect;
