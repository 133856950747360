import type { Template } from '@cognite/apm-client';
import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

export const useTemplateQuery = (
  templateExternalId?: string,
  isNewTemplate = false
) => {
  const { conditionalActionsService, templateService } = useFDMServices();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const templateFilter: Filters = templateExternalId
    ? {
        equals: {
          property: 'externalId',
          eq: templateExternalId,
        },
      }
    : {};

  const sort: Sort = [
    {
      order: 'ASC',
    },
  ];

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  return useQuery(
    [QueryKeys.TEMPLATE, templateFilter, sort],
    async () => {
      sliTimerStartTime.current = Date.now();

      const template = await templateService.getTemplateData(
        templateFilter,
        undefined,
        undefined,
        sort,
        undefined
      );

      const templateItemExternalIds: string[] =
        template.templateItems
          ?.filter(Boolean)
          .map((templateItem) => templateItem.externalId) || [];

      const conditionsData =
        templateItemExternalIds.length > 0
          ? await conditionalActionsService.getConditionalActionsByParentObject(
              templateItemExternalIds,
              'TemplateItem'
            )
          : undefined;

      const templateWithConditions: Template = {
        ...template,
        templateItems: template.templateItems?.map((templateItem) => {
          return {
            ...templateItem,
            conditionalActions: conditionsData
              ? conditionsData.conditionalActions?.filter(
                  (conditionalAction) =>
                    conditionalAction.parentObject?.externalId ===
                    templateItem.externalId
                )
              : [],
          };
        }),
      };

      return templateWithConditions;
    },
    {
      onError: (err) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.TEMPLATE,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.TEMPLATE, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.TEMPLATE, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(templateExternalId) && !isNewTemplate,
    }
  );
};
