import { Button, Flex, Input, Menu } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

export const InputWrapper = styled(Flex)`
  flex: 1;
`;

export const HalfWidthWrapper = styled(Flex)`
  width: 50%;
`;

export const LabelInput = styled(Input)`
  width: 100%;
`;

export const DeleteButton = styled(Button)`
  height: 36px;
  width: 36px;
`;

export const ColorMenu = styled(Menu)`
  position: absolute;
  top: 65px;
  z-index: ${z.ACTION_MENU};
`;

export const ColorInputWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  position: relative;
  flex: 1;
`;

export const FullWidthButton = styled(Button)`
  width: 100%;
`;
