import type { InternalId, Timeseries } from '@cognite/sdk';
import { useAppConfigFiltersContext } from '@infield/providers/app-config-filters-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import uniqBy from 'lodash/uniqBy';

export const HIGHLIGHTED_METADATA_NAME = 'is_highlighted';

export const useTrendsList = (
  assetExternalId: string,
  filterHighlighted = false
) => {
  const { timeseriesService } = useFDMServices();
  const { timeseries: configFilters } = useAppConfigFiltersContext();

  return useQuery<Timeseries[]>(
    [QueryKeys.TRENDS, assetExternalId, filterHighlighted],
    async () => {
      const timeseriesFilter = {
        ...configFilters?.cdfClassicFilters,
      };
      if (filterHighlighted) {
        timeseriesFilter.metadata = {
          [HIGHLIGHTED_METADATA_NAME]: 'true',
        };
      }

      const trendsByAsset = await timeseriesService.getTimeseriesByAsset(
        assetExternalId,
        timeseriesFilter
      );
      const trendsByRelationships =
        await timeseriesService.getTimeseriesByRelationships(assetExternalId);

      // We filter it after request since when fetching timeseries by id (retrieve method) there is no filter options
      const filteredTrendsByRelationships = trendsByRelationships?.filter(
        ({ dataSetId, externalId, metadata }) => {
          let datasetMatch = true;
          let externalIdPrefixMatch = true;
          if (configFilters?.cdfClassicFilters.dataSetIds) {
            datasetMatch = Boolean(
              configFilters.cdfClassicFilters.dataSetIds.find(
                (id) => (id as InternalId).id === dataSetId
              )
            );
          }
          if (configFilters?.cdfClassicFilters.externalIdPrefix) {
            externalIdPrefixMatch = Boolean(
              externalId?.startsWith(
                configFilters.cdfClassicFilters.externalIdPrefix
              )
            );
          }
          if (filterHighlighted) {
            return (
              datasetMatch &&
              externalIdPrefixMatch &&
              Boolean(metadata?.[HIGHLIGHTED_METADATA_NAME])
            );
          }
          return datasetMatch && externalIdPrefixMatch;
        }
      );

      return uniqBy(
        trendsByAsset.concat(filteredTrendsByRelationships),
        'externalId'
      );
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.TRENDS,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
