import React from 'react';

import { PageHeader } from '@cognite/cogs-lab';
import { AccountThemedIcon } from '@cognite/cogs.js-v10';

import { UserInfo } from '../../common/types';
import { ProfilePageTheme } from '../../pages/user-profile-page/UserProfilePage';

export type ProfilePageHeaderProps = {
  description?: string;
  theme?: ProfilePageTheme;
  userInfo?: UserInfo;
};

export const ProfilePageHeader = ({
  description = 'View and update your settings',
  theme,
  userInfo,
}: ProfilePageHeaderProps): JSX.Element => {
  const name = userInfo?.name ?? '';
  return (
    <PageHeader icon={<AccountThemedIcon color={theme} />}>
      <PageHeader.TitleArea description={description} title={name} />
    </PageHeader>
  );
};
