import getFontSizeInAbsoluteUnits from '../utils/getFontSizeInAbsoluteUnits';
import isFontSizeInPixelUnits from '../utils/isFontSizeInPixelUnits';

import { Annotation, isTextAnnotation, isLabelAnnotation } from './types';

const getAnnotationWithAbsoluteUnitsScaled = (
  annotation: Annotation,
  scale: number
): Annotation | undefined => {
  // Note: Currently, only text and labels have properties that can be specified
  // in absolute units while they belong to a (parent) container.
  if (
    (isTextAnnotation(annotation) || isLabelAnnotation(annotation)) &&
    isFontSizeInPixelUnits(annotation.style.fontSize)
  ) {
    const scaledFontSize =
      scale * getFontSizeInAbsoluteUnits(annotation.style.fontSize);
    return {
      ...annotation,
      style: {
        ...annotation.style,
        fontSize: `${scaledFontSize}px`,
      },
    };
  }
  return undefined;
};

export default getAnnotationWithAbsoluteUnitsScaled;
