import type { Action, Condition, ConditionalAction } from '@cognite/apm-client';
import {
  TaskStatusOptions,
  useActionUpsert,
  useConditionalActionDelete,
  useConditionUpsert,
} from '@infield/features/conditions';
import { useOnClickOutside } from '@infield/features/ui';
import { MutationKeys } from '@infield/utils/queryKeys';
import { useIsMutating } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';

import { ConditionConfiguration } from './condition-configuration';
import { actionTypes } from './constants';

type Props = {
  condition: Condition;
  action: Action;
  conditionalAction: ConditionalAction;
};

export const ConditionConfigurationContainer: FC<Props> = ({
  condition,
  action,
  conditionalAction,
}) => {
  const { mutateAsync: updateCondition } = useConditionUpsert();
  const { mutateAsync: updateAction } = useActionUpsert();
  const { mutateAsync: deleteCondition } = useConditionalActionDelete();

  const isLoading = useIsMutating([MutationKeys.CONDITIONAL_ACTION_DELETE]) > 0;

  const [taskStatus, setTaskStatus] = useState<TaskStatusOptions>(
    (condition.value as TaskStatusOptions) || TaskStatusOptions.NotOk
  );
  const [conditionAction, setConditionAction] = useState<
    (typeof actionTypes)[0]
  >(actionTypes[0]);
  const conditionMessageRef = useRef<HTMLTextAreaElement>(null);
  const [conditionMessage, setConditionMessage] = useState<string>(
    action.parameters?.message || ''
  );
  const [isConditionMessageFocused, setIsConditionMessageFocused] =
    useState<boolean>(false);

  useEffect(() => {
    if (isConditionMessageFocused) return;
    setConditionMessage(action.parameters?.message || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action.parameters?.message]);

  const handleTaskStatusChange = (status: TaskStatusOptions) => {
    setTaskStatus(status);
    updateCondition({ ...condition, value: status });
  };

  const handleOnClickOutside = (event: MouseEvent | TouchEvent) => {
    if (conditionMessageRef.current) {
      conditionMessageRef.current.blur();
      event.stopPropagation();
    }
  };

  useOnClickOutside(conditionMessageRef, handleOnClickOutside);

  const handleMessageOnBlur = () => {
    updateAction({ ...action, parameters: { message: conditionMessage } });

    setIsConditionMessageFocused(false);
  };

  const handleDeleteCondition = () => {
    deleteCondition([conditionalAction.externalId]);
  };

  return (
    <ConditionConfiguration
      conditionExternalId={condition.externalId}
      taskStatus={taskStatus}
      conditionAction={conditionAction}
      conditionMessage={conditionMessage}
      conditionMessageRef={conditionMessageRef}
      isDeleteConditionLoading={isLoading}
      handleTaskStatusChange={handleTaskStatusChange}
      handleConditionActionChange={setConditionAction}
      setConditionMessage={setConditionMessage}
      setIsConditionMessageFocused={setIsConditionMessageFocused}
      handleMessageOnBlur={handleMessageOnBlur}
      handleDeleteCondition={handleDeleteCondition}
    />
  );
};
