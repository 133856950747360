const getDimensionsFromContainerProps = (props: {
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
}): {
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
} => ({
  width: props.width,
  height: props.height,
  maxWidth: props.maxWidth,
  maxHeight: props.maxHeight,
});

export default getDimensionsFromContainerProps;
