import type { FdmFile } from '@cognite/apm-client';
import type { FileInfo } from '@cognite/sdk';

const supportedDocumentMimeTypes = [
  'text/plain',
  'text/csv',
  'application/json',
  'application/pdf',
  'image/svg+xml',
];

export const isSupportedDocumentType = (
  document: FdmFile,
  isAkerbpCustomCode: boolean
) => {
  // Check if the document's mimeType is supported
  if (!supportedDocumentMimeTypes.includes(document.mimeType ?? '')) {
    return false;
  }

  // If isAkerbpCustomCode is true, filter out documents with 'WORKFLOW STATUS' metadata set to 'Void'
  if ('metadata' in document && isAkerbpCustomCode) {
    return document.metadata?.['WORKFLOW STATUS'] !== 'Void';
  }

  // If none of the above conditions are met, include the document
  return true;
};

export const toFdmFile = (file: FileInfo): FdmFile => {
  return {
    ...file,
    externalId: file.externalId || '',
  };
};
