import type { ChecklistItemStatusAggregation } from '@infield/features/checklist';
import { ClickableTagChip } from '@infield/features/ui/Chip';
import type { FC } from 'react';

import { getChecklistProgressCount, getTasksProgressColor } from './utils';

type Props = {
  aggregatedChecklistStatuses?: ChecklistItemStatusAggregation;
  onClickAction?: () => void;
};

export const ChecklistProgressCell: FC<Props> = ({
  aggregatedChecklistStatuses,
  onClickAction,
}: Props) => {
  const label = getChecklistProgressCount(aggregatedChecklistStatuses);
  const statusColor = getTasksProgressColor(aggregatedChecklistStatuses);

  return (
    <ClickableTagChip
      size="small"
      colorScheme={statusColor}
      label={label}
      onClick={onClickAction}
    />
  );
};
