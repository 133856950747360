import type { ViewConfigDTO } from '@cognite/apm-config';

import type { ViewKey } from '../types';

export const VIEW_KEYS: ViewKey[] = [
  {
    name: 'activity',
    shouldImplementView: 'CogniteMaintenanceOrder',
    representsEntity: 'MAINTENANCE_ORDER',
  },
  {
    name: 'asset',
    shouldImplementView: 'CogniteAsset',
    representsEntity: 'ASSET',
  },
  {
    name: 'operation',
    shouldImplementView: 'CogniteOperation',
    representsEntity: 'OPERATION',
  },
  {
    name: 'notification',
    shouldImplementView: 'CogniteNotification',
    representsEntity: 'NOTIFICATION',
  },
];

export const defaultViewMappings: ViewConfigDTO[] = [
  {
    externalId: 'CogniteMaintenanceOrder',
    space: 'cdf_idm',
    version: 'v1',
    representsEntity: 'MAINTENANCE_ORDER',
  },
  {
    externalId: 'CogniteOperation',
    space: 'cdf_idm',
    version: 'v2',
    representsEntity: 'OPERATION',
  },
  {
    externalId: 'CogniteNotification',
    space: 'cdf_idm',
    version: 'v1',
    representsEntity: 'NOTIFICATION',
  },
  {
    externalId: 'CogniteAsset',
    space: 'cdf_cdm',
    version: 'v1',
    representsEntity: 'ASSET',
  },
];
