import { useAuthContext } from '@cognite/e2e-auth';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useUserTimeseriesAccess } from '@infield/providers/hooks';
import type { FC, PropsWithChildren } from 'react';
import { useContext, useMemo } from 'react';

import { AccessContext } from './access-context';

export const AccessProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: hasAccess, isLoading } = useUserTimeseriesAccess();
  const { client } = useAuthContext();
  const metrics = useMetrics(METRICS_NAMESPACES.timeseriesAccess);

  const value = useMemo(() => {
    if (!isLoading && hasAccess !== undefined) {
      metrics.track('hasTimeseriesReadWriteAccess', {
        hasAccess,
        project: client.project,
      });
    }

    return { hasTimeSeriesRwAccess: hasAccess };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAccess]);

  return (
    <AccessContext.Provider value={value}>{children}</AccessContext.Provider>
  );
};

export const useAccessContext = () => {
  const context = useContext(AccessContext);
  if (!context) {
    throw new Error('AccessContext must be used within an AccessProvider');
  }
  return context;
};
