import { ActivityDetails } from '@infield/features/activities';
import { useBackButtonUrlContext } from '@infield/providers/back-button-url-provider';
import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const ActivityDetailsView: FC = () => {
  const { activityId } = useParams();
  const navigate = useNavigate();
  const { navigateBack } = useBackButtonUrlContext();

  const handleOnAssetClick = (assetExternalId: string) => {
    navigate(`/asset/${encodeURIComponent(assetExternalId)}`);
  };

  if (activityId)
    return (
      <ActivityDetails
        activityId={activityId}
        onAssetClick={handleOnAssetClick}
        onClose={() => navigateBack()}
      />
    );
  return <div>Activity details for: {activityId} is not available</div>;
};
