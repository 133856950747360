import type { InFieldLocationConfig } from '@cognite/apm-client';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

export const useInFieldLocationConfig = (rootLocationExternalId?: string) => {
  const { apmClient } = useFDMServices();

  return useQuery<InFieldLocationConfig | null, Error>(
    [QueryKeys.INFIELD_LOCATION_CONFIG, rootLocationExternalId ?? 'undefined'],
    async () => {
      if (!rootLocationExternalId) {
        return null;
      }

      const response = await apmClient.infieldLocationConfig.list(
        `
      externalId
      rootLocationExternalId
      appInstanceSpace
      featureToggles
    `,
        {
          filters: {
            equals: {
              property: 'rootLocationExternalId',
              eq: rootLocationExternalId,
            },
          },
        }
      );
      return response.list.items[0] || null;
    }
  );
};
