import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';

import { useSDK } from '@cognite/sdk-provider';

import { getAnswerToQuestionMultipleFiles } from '../network';
import { queryKeys } from '../queryKeys';

export const useAnswerQuestionMultipleFilesQuery = ({
  question,
  fileIds,
  limit = 100,
}: {
  question: string;
  fileIds: number[];
  limit?: number;
  manual?: boolean;
}) => {
  const sdk = useSDK();
  const isValidQuery = !isEmpty(question) && fileIds.length > 0;

  return useQuery(
    queryKeys.answerQuestionMultipleFiles(question, fileIds, limit),
    () => {
      return getAnswerToQuestionMultipleFiles(sdk, question, fileIds, limit);
    },
    {
      enabled: isValidQuery,
    }
  );
};
