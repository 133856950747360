import {
  BulletListIcon,
  EmptyState,
  ListsIllustration,
} from '@cognite/cogs.js-v10';
import type { Metadata } from '@cognite/sdk';
import type { PropertyConfiguration } from '@infield/features/app-config/types';
import type { AssetPropertiesListItem } from '@infield/features/asset/asset-properties';
import { AssetPropertiesList } from '@infield/features/asset/asset-properties';
import { AssetCard } from '@infield/features/asset/shared';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { EmptyStateWrapper } from '@infield/features/ui/table-empty-state/elements';
import some from 'lodash/some';
import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  assetMetadata?: Metadata;
  config?: PropertyConfiguration;
  onOpen?: () => void;
};

export const AssetPropertiesCard: FC<Props> = ({
  assetMetadata,
  config,
  onOpen,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);
  const navigate = useNavigate();
  const isClickableProperty = (key: string): boolean => {
    return some(config?.linkableAssetKeys, (assetKey) => assetKey === key);
  };
  const properties = config
    ? config.highlightedProperties
    : Object.keys(assetMetadata || {});

  const itemsDefined: AssetPropertiesListItem[] = [];
  const itemsUndefined: AssetPropertiesListItem[] = [];
  (properties || []).forEach((key) => {
    const value = assetMetadata ? assetMetadata[key] : null;
    const item = {
      fieldName: key,
      fieldValue: value ?? '-',
      onClickValue:
        isClickableProperty(key) && value
          ? () => navigate(`/asset/${encodeURIComponent(value)}`)
          : undefined,
    };
    if (value) {
      itemsDefined.push(item);
    } else {
      itemsUndefined.push(item);
    }
  });
  const sortedItems = [...itemsDefined, ...itemsUndefined];

  const hasHighlightedProperties = sortedItems.length > 0;
  const hasProperties = Object.keys(assetMetadata || {}).length > 0;

  let content: ReactNode;
  if (!hasProperties) {
    content = (
      <EmptyStateWrapper>
        <EmptyState
          illustration={<ListsIllustration />}
          title={t(
            'ASSET_OVERVIEW_PROPERTIES_CARD_NO_PROPERTIES_TITLE',
            'There are no properties to display.'
          )}
          description={t(
            'ASSET_OVERVIEW_PROPERTIES_CARD_NO_PROPERTIES_BODY',
            'There are no properties on this asset.'
          )}
        />
      </EmptyStateWrapper>
    );
  } else if (!hasHighlightedProperties) {
    content = (
      <EmptyStateWrapper>
        <EmptyState
          illustration={<ListsIllustration />}
          title={t(
            'ASSET_OVERVIEW_PROPERTIES_CARD_NO_HIGHLIGHTED_PROPERTIES_TITLE',
            'No highlighted properties to display'
          )}
          description={t(
            'ASSET_OVERVIEW_PROPERTIES_CARD_NO_HIGHLIGHTED_PROPERTIES_BODY',
            "The asset properties can still be accessed by pressing the 'Open' button."
          )}
        />
      </EmptyStateWrapper>
    );
  } else {
    content = <AssetPropertiesList items={sortedItems} />;
  }

  return (
    <AssetCard
      icon={<BulletListIcon />}
      title={t('Properties', 'Properties')}
      buttonText={t('open', 'Open')}
      onButtonClick={hasProperties ? onOpen : undefined}
    >
      {content}
    </AssetCard>
  );
};
