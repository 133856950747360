import { Menu } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

const MOBILE_MENU_PADDING = 16;

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 8px 12px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const StyledMenu = styled(Menu)`
  margin-top: 6px;

  z-index: ${z.DEFAULT};

  max-height: 235px;
  overflow-y: auto;
  width: 288px;

  @media only screen and (max-width: 1000px) {
    position: absolute;
    bottom: ${MOBILE_MENU_PADDING}px;
    left: ${MOBILE_MENU_PADDING}px;
    max-width: 100vw;
    width: calc(100vw - ${MOBILE_MENU_PADDING * 2}px);
    // don't show tooltip on mob/tablet
    .cogs-tooltip__content + div {
      display: none;
    }
  }
`;
