import { Flex, LoaderIcon } from '@cognite/cogs.js-v10';
import type { FC, ReactNode } from 'react';

import * as S from './elements';

type Props = {
  loadingImage?: string;
  isLoading?: boolean;
  loadingText?: string;
  className?: string;
  text?: ReactNode;
  emptyImage?: string;
  extras?: ReactNode;
};

export const MediaEmptyView: FC<Props> = ({
  loadingImage = '',
  isLoading = false,
  loadingText = '',
  text = '',
  emptyImage = '',
  className = '',
  extras = null,
}) => (
  <S.Wrapper className={className}>
    {loadingImage && <img src={loadingImage} alt="" />}
    {isLoading ? (
      <div>
        <LoaderIcon className="cogs-icon--rotating" />
        {loadingText}
      </div>
    ) : (
      <Flex direction="column" gap={10}>
        {emptyImage && <S.Img src={emptyImage} alt={JSON.stringify(text)} />}
        <S.EmptyText size="medium">{text}</S.EmptyText>
        {extras && <S.ExtrasWrapper>{extras}</S.ExtrasWrapper>}
      </Flex>
    )}
  </S.Wrapper>
);
