import styled from 'styled-components';

import {
  Button,
  RestoreIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from '@cognite/cogs.js-v10';

import { ZOOM_TO_FIT_MARGIN } from '../../constants';
import type { UnifiedViewer, ZoomToFitMode } from '../../UnifiedViewer';

const ZoomControls = ({
  unifiedViewerRef,
  zoomToFitMode,
}: {
  unifiedViewerRef: UnifiedViewer | undefined;
  zoomToFitMode: ZoomToFitMode;
}): JSX.Element => {
  const handleZoomOut = () => {
    unifiedViewerRef?.zoomOut();
  };

  const handleZoomIn = () => {
    unifiedViewerRef?.zoomIn();
  };

  const resetZoom = () => {
    unifiedViewerRef?.zoomToFit(zoomToFitMode, {
      relativeMargin: ZOOM_TO_FIT_MARGIN,
    });
  };

  return (
    <Container>
      <Button
        icon={<ZoomOutIcon />}
        aria-label="Zoom Out"
        onClick={handleZoomOut}
      />
      <Button icon={<RestoreIcon />} aria-label="Restore" onClick={resetZoom} />
      <Button
        icon={<ZoomInIcon />}
        aria-label="Zoom In"
        onClick={handleZoomIn}
      />
    </Container>
  );
};

const Container = styled.div`
  display: inline-flex;
  border-radius: 4px;
  overflow: hidden;
`;

export default ZoomControls;
