import { Modal } from '@cognite/cogs.js-v10';
import { Assignment } from '@infield/features/assignment';
import { useAssignedTo } from '@infield/features/assignment/hooks';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';
import { useState } from 'react';

interface Props {
  title: string;
  visible: boolean;
  onClose: () => void;
  onAssign: (assignedTo: string[]) => void;
  assignedTo?: string[];
}

export const AssignModal: FC<Props> = ({
  title,
  visible,
  onAssign,
  onClose,
  assignedTo,
}) => {
  const { data: assignees } = useAssignedTo(assignedTo ?? []);

  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const [modifiedAssignedTo, setModifiedAssignedTo] = useState<string[]>();

  const handleSave = () => {
    onAssign(modifiedAssignedTo ?? []);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      size="small"
      onCancel={onClose}
      cancelText={t('ASSIGN_MODAL_CANCEL', 'Cancel')}
      onOk={handleSave}
      okText={t('ASSIGN_MODAL_OK', 'Assign')}
    >
      <Assignment
        activityAssignees={assignees}
        onChange={setModifiedAssignedTo}
      />
    </Modal>
  );
};
