import type { UserPreferenceLocation } from '@cognite/apm-client';
import { addRecoilKey } from '@infield/utils/recoil';
import { atom } from 'recoil';

export const selectedRootLocationAtom = atom<
  UserPreferenceLocation | undefined
>({
  key: addRecoilKey('selected-root-location-atom'),
  default: undefined,
});
