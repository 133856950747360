import { IConfig as FlagProviderConfig } from '@unleash/proxy-client-react';
import queryString from 'query-string';

export const getQueryParameter = (parameterKey: string) => {
  const parameters = queryString.parse(window.location.search) ?? {};
  return parameters[parameterKey] ?? '';
};

export const getProject = () => {
  const project = getQueryParameter('project') as string;

  if (project) {
    return project;
  }

  return window.location.pathname.split('/')[1];
};

export const getCluster = () => {
  const cluster = getQueryParameter('cluster');
  return Array.isArray(cluster) ? cluster[0] : cluster;
};

export const getWorkspaceId = () => {
  return getQueryParameter('workspace');
};

export const getAppUrlName = () => {
  return window.location.pathname.split('/')[2];
};

export const getUrl = (
  hostname: string,
  protocol: 'https' | 'http' = 'https'
) => {
  let url = hostname;
  if (hostname.substr(0, protocol.length) !== protocol) {
    url = `${protocol}://${hostname}`;
  }

  return url;
};

export const getSearchQuery = (
  queries: any = {},
  opts?: queryString.StringifyOptions
) => {
  const cluster = getCluster();
  const workspaceId = getWorkspaceId();
  const query = queryString.stringify(
    {
      ...queries,
      ...(cluster ? { cluster } : {}),
      ...(workspaceId ? { workspace: workspaceId } : {}),
    },
    opts
  );

  return query;
};

export const createLink = (
  path: string,
  queries: any = {},
  opts?: queryString.StringifyOptions & { ignoreProject?: boolean }
): string => {
  const { ignoreProject, ...queryStringOptions } = opts || {};

  const hash = window.location.hash ?? '';
  const query = getSearchQuery(queries, queryStringOptions);
  const safePath = path.startsWith('/') ? path : `/${path}`;

  if (ignoreProject) {
    return [safePath, query && `?${query}`].filter(Boolean).join('');
  }

  const project = getProject() || '';

  if (query.length > 0) {
    return `/${project}${safePath}?${query}${hash}`;
  }
  if (safePath !== '/') {
    return `/${project}${safePath}${hash}`;
  }

  return `/${project}${hash}`;
};

export enum Envs {
  PROD = 'prod',
  DEV = 'dev',
  LOCAL = 'local',
  LOCALHOST = 'localhost',
  STAGING = 'staging',
  NEXT_RELEASE = 'next-release',
  PR = 'preview',
  PR_PREVIEW = 'pr-preview',
}

/**
 * We check what Fusion environment it is by extracting what we suppose to be env
 * directly from the URL. We can't just check if the string representing env
 * is in the hostname because some org named include "dev" or "prod" string.
 *
 * Examples:
 *
 * dev.fusion.cogniteapp.com - dev - will return true for "dev"
 * fusion.cognite.com - prod - will return true for "prod"
 * next-release.fusion.cognite.com - next-release - will return true for "next-release"
 * devex.dev.fusion.cogniteapp.com - dev - will return true for "dev"
 * devex.fusion.cognite.com - prod - will return true for "prod"
 * devex.next-release.fusion.cognite.com - next-release - will return true for "next-release"
 */
export const checkUrl = (env: Envs) => {
  const { hostname } = window.location;

  if (env === Envs.LOCAL) {
    return hostname.endsWith('local.cognite.ai');
  }

  const prPreviewRegex = /fusion-pr-preview.cogniteapp/gm;
  if (hostname.match(prPreviewRegex)) {
    return env === Envs.PR_PREVIEW;
  }

  // Disabling this eslint rule because Regex actually DOES need that escape
  // eslint-disable-next-line no-useless-escape
  const regex = /([^\.\s]*?)(?=.fusion)/gi;
  const hostnameEnvMatch = hostname.match(regex);
  const hostnameEnv = hostnameEnvMatch?.[0];
  if (env === 'prod') {
    /**
     * Production environment will return:
     * - null - if there's no org name in URL
     * - org name - if there is org name
     * To detect if env is production, we just check if hostname env is null or some
     * other string than other env names.
     */
    const nonProdEnvs = Object.keys(Envs).filter((e) => e !== Envs.PROD);
    return !hostnameEnv || !nonProdEnvs.includes(hostnameEnv);
  }
  return hostnameEnv === env;
};

export const isDevelopment = (): boolean =>
  checkUrl(Envs.DEV) || checkUrl(Envs.LOCALHOST) || checkUrl(Envs.LOCAL);
export const isStaging = (): boolean =>
  checkUrl(Envs.STAGING) || checkUrl(Envs.PR) || checkUrl(Envs.NEXT_RELEASE);
export const isProduction = (): boolean => !(isStaging() || isDevelopment());

export const getEnvironment = () => {
  if (isDevelopment()) {
    return 'development';
  }
  if (isStaging()) {
    return 'staging';
  }
  return 'production';
};

export const isValidEmail = (email: string) => {
  // Just checking by length as it's super tricky and fragile to check by char here.
  return /^.{1,64}@.{1,255}$/.test(email);
};

export const getFlagProviderConfig = (
  config: Omit<FlagProviderConfig, 'url' | 'clientKey'>
): FlagProviderConfig => {
  return {
    url: 'https://unleash-edge.cogniteapp.com/api/frontend',
    clientKey:
      '*:default.2b1d2c87022af6b1469024aa5dc3f95207caab17a67e06de55a1eab3',
    refreshInterval: 60, // seconds
    ...config,
  };
};
