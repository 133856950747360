import { Menu } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Menu)`
  padding: 0;
  border-radius: none !important;
  box-shadow: none !important;
  overflow-y: auto;
  max-height: 40vh;
`;
