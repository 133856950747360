import type { InFieldLocationConfig } from '@cognite/apm-client';
import { SpaceSelectOrCreator } from '@cognite/apm-config';
import { PageHeader } from '@cognite/cogs-lab';
import { Body, Button, SaveIcon } from '@cognite/cogs.js-v10';
import { useAuthContext } from '@cognite/e2e-auth';
import type { FC } from 'react';
import { useState } from 'react';

import {
  ConfigPageContentWrapper,
  ContentContainer,
  HelpTextAreaWrapper,
  LocationInfieldContentWrapper,
} from '../elements';
import { getInitialInfieldLocation } from '../utils/get-initial-infield-location';

import * as S from './elements';

type Props = {
  adsLocationId?: string;
  infieldLocation?: InFieldLocationConfig | null;
  onSave: (nextConfig: InFieldLocationConfig) => void;
};

export const LocationInfieldInstanceSpace: FC<Props> = ({
  adsLocationId,
  infieldLocation,
  onSave,
}) => {
  const { client } = useAuthContext();
  const [newAppInstanceSpace, setNewAppInstanceSpace] = useState(
    infieldLocation?.appInstanceSpace
  );

  const handleAppSpaceUpdate = (space?: string) => {
    setNewAppInstanceSpace(space);
  };
  const handleSpaceCreate = (newSpace?: string) => {
    if (!newSpace) return;
    setNewAppInstanceSpace(newSpace);
    client.spaces.upsert([{ space: newSpace }]);
  };

  const handleSave = () => {
    if (!adsLocationId) return;
    onSave({
      ...(infieldLocation || getInitialInfieldLocation(adsLocationId)),
      appInstanceSpace: newAppInstanceSpace,
    });
  };

  const isChanged = infieldLocation?.appInstanceSpace !== newAppInstanceSpace;

  return (
    <LocationInfieldContentWrapper>
      <PageHeader fullWidth>
        <PageHeader.TitleArea
          title="InField instance space"
          description="Where do you want to store the data created in Infield?"
        />
        <PageHeader.Actions>
          <Button
            type="primary"
            icon={<SaveIcon />}
            disabled={!isChanged}
            onClick={handleSave}
          >
            Save
          </Button>
        </PageHeader.Actions>
      </PageHeader>
      <ConfigPageContentWrapper>
        <ContentContainer>
          <S.AutocomleteWrapper $isChanged={isChanged}>
            <SpaceSelectOrCreator
              sdk={client}
              space={newAppInstanceSpace}
              onSpaceSelect={handleAppSpaceUpdate}
              onSpaceCreate={handleSpaceCreate}
            />
          </S.AutocomleteWrapper>
        </ContentContainer>
        <HelpTextAreaWrapper>
          <Body>
            All images and files uploaded through InField for this location will
            be stored in this space.
          </Body>
          <Body>This is the same as AppDataInstanceSpace.</Body>
        </HelpTextAreaWrapper>
      </ConfigPageContentWrapper>
    </LocationInfieldContentWrapper>
  );
};
