import * as Sentry from '@sentry/react';
import { omit, partial } from 'lodash-es';

import { CogniteClient } from '@cognite/sdk';

import { Chart } from '../../types';

import {
  AdsListResponse,
  AdsChartEntity,
  AdsCreateChartEntity,
} from './adsTypes';
import { ChartsStorageServiceInterface } from './ChartsStorageServiceInterface';

const adaptChartToAdsChartEntity = (
  chart: Partial<Chart>,
  chartId: string
): AdsCreateChartEntity => {
  return {
    externalId: chartId || chart.id,
    visibility: chart.public ? 'PUBLIC' : 'PRIVATE',
    data: omit(chart, ['user', 'id', 'public', 'createdAt', 'updatedAt']),
  };
};

const adaptAdsChartEntityToChart = (adsChartEntity: AdsChartEntity): Chart => {
  return {
    id: adsChartEntity.externalId,
    public: adsChartEntity.visibility === 'PUBLIC',
    createdAt: adsChartEntity.createdTime,
    updatedAt: adsChartEntity.lastUpdatedTime,
    user: adsChartEntity.ownerId,
    ...adsChartEntity.data,
  };
};

const fetchChartsFromAds = (
  sdk: CogniteClient,
  filter: { visibility?: 'PUBLIC' | 'PRIVATE'; isOwned?: boolean } = {}
) => {
  return sdk
    .post<AdsListResponse>(
      `/apps/v1/projects/${sdk.project}/storage/charts/charts/list`,
      {
        data: {
          filter,
        },
      }
    )
    .catch((e) => {
      Sentry.captureException(e, {
        tags: { storage: 'ADS', source: 'fetchChartsFromAds' },
      });
      throw e;
    })
    .then((res) => {
      const { items = [] } = res.data || {};
      return items.map(adaptAdsChartEntityToChart);
    });
};

// will use private filter here when we have private/public/all charts instead of my/public charts
export const fetchUserChartsFromAds = (
  sdk: CogniteClient,
  _projectId: string,
  _userId: string,
  _userEmail?: string
) => fetchChartsFromAds(sdk, { isOwned: true });

export const fetchPublicChartsFromAds = (sdk: CogniteClient) =>
  fetchChartsFromAds(sdk, { visibility: 'PUBLIC' });

export const fetchChartByIdFromAds = (
  sdk: CogniteClient,
  _projectId: string,
  externalId: string
) => {
  return sdk
    .post<AdsListResponse>(
      `/apps/v1/projects/${sdk.project}/storage/charts/charts/byids`,
      {
        data: { items: [{ externalId }] },
      }
    )
    .catch((e) => {
      Sentry.captureException(e, {
        tags: { storage: 'ADS', source: 'fetchChartByIdFromAds' },
      });
      throw e;
    })
    .then((res) => {
      const { items = [] } = res.data || {};
      const charts = items.map(adaptAdsChartEntityToChart);
      return charts[0];
    });
};

export const upsertChartsIntoAds = (
  sdk: CogniteClient,
  _projectId: string,
  chartId: string,
  chart: Partial<Chart>
): Promise<Chart> => {
  return sdk
    .put<AdsListResponse>(
      `/apps/v1/projects/${sdk.project}/storage/charts/charts`,
      {
        data: { items: [adaptChartToAdsChartEntity(chart, chartId)] },
      }
    )
    .catch((e) => {
      Sentry.captureException(e, {
        tags: { storage: 'ADS', source: 'upsertChartsIntoAds' },
      });
      throw e;
    })
    .then((res) => {
      const { items = [] } = res.data || {};
      const charts = items.map(adaptAdsChartEntityToChart);
      return charts[0];
    });
};

export const deleteChartsFromAds = (
  sdk: CogniteClient,
  _projectId: string,
  externalId: string
) => {
  return sdk
    .post<void>(
      `/apps/v1/projects/${sdk.project}/storage/charts/charts/delete`,
      {
        data: { items: [{ externalId }] },
      }
    )
    .catch((e) => {
      Sentry.captureException(e, {
        tags: { storage: 'ADS', source: 'deleteChartsFromAds' },
      });
      throw e;
    })
    .then((res) => res.data);
};

export const getAdsService = (
  sdk: CogniteClient
): ChartsStorageServiceInterface => ({
  fetchPublicCharts: partial(fetchPublicChartsFromAds, sdk),
  fetchUserCharts: partial(fetchUserChartsFromAds, sdk),
  fetchChart: partial(fetchChartByIdFromAds, sdk),
  updateChart: partial(upsertChartsIntoAds, sdk),
  createChart: partial(upsertChartsIntoAds, sdk),
  deleteChart: partial(deleteChartsFromAds, sdk),
});
