import type { TaskInterval } from '@cognite/apm-client';
import { AddIcon, Button, Heading } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { Interval } from './interval';

type Props = {
  taskExternalId: string;
  intervals: TaskInterval[];
  addInterval?: () => void;
  updateInterval?: (draftInterval: TaskInterval) => void;
  removeInterval?: (intervalToRemove: TaskInterval) => void;
  disableLastItemRemoval?: boolean;
};

export const IntervalList: FC<Props> = ({
  taskExternalId,
  intervals,
  addInterval,
  updateInterval,
  removeInterval,
  disableLastItemRemoval,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);

  return intervals?.length > 0 ? (
    <>
      <Heading level={6} data-testid="task-form-schedule">
        {t('TASK_SCHEDULE_TITLE', 'Schedule')}
      </Heading>
      {intervals.map((interval) => (
        <Interval
          taskExternalId={taskExternalId}
          key={interval.externalId}
          interval={interval}
          updateInterval={updateInterval}
          removeInterval={removeInterval}
          isRemovable={disableLastItemRemoval && intervals.length > 1}
        />
      ))}
      {addInterval && (
        <Button
          data-testid="interval-list-add-interval-button"
          type="ghost"
          icon={<AddIcon />}
          onClick={addInterval}
        >
          {t('TASK_INTERVALS_ADD_SCHEDULE_BUTTON_TITLE', 'Add schedule')}
        </Button>
      )}
    </>
  ) : null;
};
