import type { TimeseriesFilter } from '@cognite/sdk';

export const getTimeseriesFilter = (
  timeseriesDataSet?: number
): TimeseriesFilter | undefined => {
  // We shall normally look for timeseries created by infield in the configured dataset
  // If the dataset is not configured, we shall look for all timeseries
  if (timeseriesDataSet) {
    return {
      dataSetIds: [
        {
          id: timeseriesDataSet,
        },
      ],
    };
  }
  return undefined;
};
