import { Button, Dropdown, Flex, Menu } from '@cognite/cogs.js-v10';
import { device } from '@infield/utils/media-queries';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
})`
  height: 100%;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme['surface--muted']};
`;

export const Header = styled(Flex).attrs({
  alignItems: 'center',
})`
  height: 57px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const ActivityList = styled(Flex).attrs({
  direction: 'column',
})`
  user-select: 'none';
`;

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div {
    width: 100%;
  }
  > div + div {
    transform: translate3d(0px, 65.5px, 0px) !important;
  }
`;

export const StyledButton = styled(Button)`
  &.cogs.cogs-button {
    width: 100%;
    justify-content: space-between;
    height: 56px;
    border-radius: unset;
    padding-right: 16px;
    font-size: 16px;
  }
`;

export const StyledMenu = styled(Menu)`
  @media ${device.max.tablet} {
    max-width: 100vw;
    width: 100vw;
  }
`;

export const EmptyStateContainer = styled(Flex)`
  justify-content: center;
  margin: 16px;
`;

export const ActivityListWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  overflow-y: auto;
  width: 100%;
  user-select: 'none';

  ::-webkit-scrollbar {
    display: none;
  }
`;
