import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

type Props = {
  error: unknown;
};

// shows meaningful error description for common 3d errors or throws otherwise
export const AssetInfoError: FC<Props> = ({ error }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.threeD);
  const errors = (error as any).errors as
    | undefined
    | Array<{
        status: number;
        errorMessage: string;
        missing?: Array<{ id: number }>;
      }>;
  if (
    errors?.length &&
    errors[0].errorMessage?.includes('Asset id not found')
  ) {
    // TODO(INFIELD2-626): proper styles
    return (
      <>
        <p>
          {t(
            'THREE_D_ASSET_INFO_ERROR_MAPPED_TO_NON_EXISTING_ASSET',
            '3D node mapped to non-existing asset'
          )}
        </p>
        {errors[0]?.missing?.length ? (
          <p>
            {t('THREE_D_ASSET_INFO_ERROR_MISSING_MAPPING', 'Missing')}:{' '}
            {errors[0]?.missing.map(({ id }) => id).join(', ')}
          </p>
        ) : null}
      </>
    );
  }
  throw error;
};
