import type { APMAsset, Observation } from '@cognite/apm-client';
import {
  ObservationDetails,
  useObservationCreateMutation,
  useObservationsDetailsQuery,
  useObservationUpsertMutation,
} from '@infield/features/observation';
import type { FC } from 'react';

type Props = {
  observationExternalId?: string;
  checklistItemExternalId?: string;
  checklistItemAsset?: APMAsset;
  onCancel: () => void;
  onCreated: (observationExternalId: string) => void;
};

export const ObservationDetailDesktop: FC<Props> = ({
  observationExternalId,
  checklistItemExternalId,
  checklistItemAsset,
  onCancel,
  onCreated,
}) => {
  const { mutateAsync: upsertObservation } = useObservationUpsertMutation();
  const { mutateAsync: createObservation } = useObservationCreateMutation();

  const { data: observations } = useObservationsDetailsQuery(
    observationExternalId ? [observationExternalId] : []
  );
  const observation = observations ? observations[0] : undefined;

  // We pass edgeStartNode only once when observation is created for the first time
  const checklistItemForEdge = !observationExternalId
    ? checklistItemExternalId
    : undefined;

  const handleOnUpdate = (observation: Observation) => {
    if (!observationExternalId) {
      createObservation(
        { observation, edgeStartNode: checklistItemForEdge },
        {
          onSuccess: () => {
            onCreated(observation.externalId);
          },
        }
      );
    } else {
      upsertObservation({ observation });
    }
  };

  return (
    <ObservationDetails
      observation={observation}
      checklistItemAsset={checklistItemAsset}
      onUpdate={handleOnUpdate}
      onCancel={onCancel}
      showBackToChecklistDescription
    />
  );
};
