import partition from 'lodash/partition';

import { Annotation } from '../annotations/types';
import { ContainerConfig } from '../containers/types';
import { UnifiedViewerNode, isContainerConfig } from '../types';

const partitionIntoContainersAndAnnotations = (
  nodes: UnifiedViewerNode[]
): { containers: ContainerConfig[]; annotations: Annotation[] } => {
  const [containers, annotations] = partition(nodes, isContainerConfig);
  return { containers, annotations };
};

export default partitionIntoContainersAndAnnotations;
