import { CUSTOMERS } from '../customers';
import type { AuthState } from '../types';

export const getInitialAuthState = (): AuthState => {
  const savedAuthStateRaw = localStorage.getItem('APP_AUTH_STATE');
  if (savedAuthStateRaw) {
    const savedAuthState = JSON.parse(savedAuthStateRaw) as AuthState;

    // If we were authenticated, auto-reauthenticate
    if (savedAuthState.status === 'AUTHENTICATED') {
      if (savedAuthState.projectInfo) {
        const { projectInfo } = savedAuthState;
        const { id } = projectInfo;
        const customerProject = CUSTOMERS[id];

        // Update configurationSchemaVersion if changed
        if (
          customerProject?.projects[0]?.configurationSchemaVersion !==
          projectInfo.configurationSchemaVersion
        ) {
          savedAuthState.projectInfo.configurationSchemaVersion =
            customerProject.projects[0].configurationSchemaVersion;
        }
      }

      return {
        status: 'AUTHENTICATING',
        projectInfo: savedAuthState.projectInfo,
      };
    }
    return savedAuthState;
  }
  return { status: 'UNAUTHENTICATED' };
};
