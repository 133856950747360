import styled from 'styled-components';

export const ComponentListItem = styled.div<{
  $selected?: boolean;
}>`
  padding: 16px;
  width: 100%;
  /* background: ${({ theme }) => theme['surface--muted']}; */
  ${({ $selected, theme }) =>
    $selected &&
    `background: ${theme['surface--interactive--toggled-default']};`}
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;
