import { getIntersectionOverUnion } from '../../utils/rectUtils';

import type { TextTagBoxProperties } from './getTextTagBoxPropertiesFromCauseMapNode';

const CORRECTED_HEIGHT_MARGIN_PX = 10;

const getContainer = (
  { textNodeProperties, tagNodeProperties }: TextTagBoxProperties,
  scaleFactor: number
) => {
  const container = document.createElement('div');
  container.style.position = 'absolute';
  container.style.width = `${textNodeProperties.width}px`;
  container.style.height = `${textNodeProperties.height}px`;
  container.style.display = 'flex';
  container.style.justifyContent = 'center';
  container.style.alignItems = 'center';

  container.style.padding = `${
    (textNodeProperties.padding ?? 0) * scaleFactor
  }px`;
  container.style.border =
    tagNodeProperties.stroke !== undefined
      ? `${(tagNodeProperties.strokeWidth ?? 0) * scaleFactor}px solid ${
          tagNodeProperties.stroke
        }`
      : '';
  container.style.borderRadius =
    typeof tagNodeProperties.cornerRadius === 'number'
      ? `${(tagNodeProperties.cornerRadius ?? 0) * scaleFactor}px`
      : '';

  return container;
};

const getTextContainer = (
  { textNodeProperties, tagNodeProperties }: TextTagBoxProperties,
  scaleFactor: number
) => {
  const textContainer = document.createElement('div');
  textContainer.innerText = textNodeProperties.text;
  textContainer.style.fontSize =
    `${textNodeProperties.fontSize * scaleFactor}` + 'px';
  textContainer.style.overflow = 'hidden';
  textContainer.style.outline = 'none';
  textContainer.style.resize = 'none';
  textContainer.style.lineHeight = `${textNodeProperties.lineHeight ?? 1.0}`;
  textContainer.style.fontFamily = 'Arial';
  textContainer.style.textAlign = textNodeProperties.align;
  textContainer.style.overflowWrap = 'break-word';
  textContainer.style.boxSizing = 'content-box';
  // Add a negative margin to account for the container stroke
  textContainer.style.margin = `-${Math.floor(
    (tagNodeProperties.strokeWidth ?? 0) * scaleFactor
  )}px`;
  textContainer.style.minWidth = `1em`;
  textContainer.style.minHeight = `1em`;
  textContainer.style.maxWidth = `${textNodeProperties.width}px`;
  textContainer.style.maxHeight = `${textNodeProperties.height}px`;
  return textContainer;
};

const isOverflowing = (
  container: HTMLDivElement,
  textContainer: HTMLDivElement
) => textContainer.scrollHeight > container.clientHeight;

const findContainerHeightWithoutOverflow = (
  props: TextTagBoxProperties,
  scaleFactor: number = 1.0,
  iouThresholdForDownsizingContainer: number = Number.NEGATIVE_INFINITY
): number | undefined => {
  // Create temporary divs to measure if the text content overflows
  const container = getContainer(props, scaleFactor);
  const textContainer = getTextContainer(props, scaleFactor);

  container.appendChild(textContainer);
  document.body.appendChild(container);

  const nextHeight = isOverflowing(container, textContainer)
    ? textContainer.scrollHeight + 2 * (props.textNodeProperties.padding ?? 0)
    : undefined;

  const textClientRect = textContainer.getClientRects()[0];
  const containerClientRect = container.getClientRects()[0];
  const textClientHeight = textContainer.clientHeight;

  container.remove();

  if (
    nextHeight === undefined &&
    getIntersectionOverUnion(textClientRect, containerClientRect) <
      iouThresholdForDownsizingContainer
  ) {
    return (
      textClientHeight +
      2 * (props.textNodeProperties.padding ?? 0) +
      CORRECTED_HEIGHT_MARGIN_PX
    );
  }
  return nextHeight !== undefined
    ? nextHeight + CORRECTED_HEIGHT_MARGIN_PX
    : undefined;
};

export default findContainerHeightWithoutOverflow;
