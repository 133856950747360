import { Flex, Modal } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

const MODAL_FOOTER_HEIGHT = 64; // 36px(button height) + 14*2(top and bottom padding)

export const StyledModal = styled(Modal)`
  &.cogs.cogs-modal--no-footer {
    padding-bottom: 0;
  }
`;

export const StyledModalContent = styled(Flex).attrs({
  direction: 'column',
  gap: 12,
})`
  margin-bottom: ${MODAL_FOOTER_HEIGHT}px;
`;

export const StyledCustomFooter = styled(Flex)`
  padding: 14px 16px;
  background: #fff;
  position: fixed;
  height: ${MODAL_FOOTER_HEIGHT}px;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: ${z.OVERLAY + 2};
`;
