import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { useSelectedRootLocationConfiguration } from '../use-selected-root-location-configuration';

export const useIsChecklistAdminQuery = () => {
  const { client } = useAuthContext();
  const selectedLocation = useSelectedRootLocationConfiguration();

  const checklistAdminGroups = selectedLocation?.checklistAdmins?.filter(
    (value) => value.trim() !== ''
  );

  return useQuery(
    [QueryKeys.CHECKLIST_ADMIN_QUERY, checklistAdminGroups],
    async () => {
      const groups = await client.groups.list();

      const groupsNames = groups?.map((group) => group.name);

      if (
        checklistAdminGroups === undefined ||
        checklistAdminGroups.length === 0
      ) {
        return true;
      }
      return checklistAdminGroups.some((value) => groupsNames?.includes(value));
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.CHECKLIST_ADMIN_QUERY,
          },
        }),
    }
  );
};
