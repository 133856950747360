import type { ObservationFieldOption } from '@cognite/apm-config';
import { Select } from '@cognite/cogs-lab';
import type { FC } from 'react';

interface Props {
  dataTestId?: string;
  label?: string;
  placeholder?: string;
  options?: ObservationFieldOption[];
  value?: string;
  isLocked?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
}

export const ObservationDesktopSelectInput: FC<Props> = ({
  label,
  placeholder,
  options,
  value,
  isLocked,
  required,
  onChange,
  dataTestId,
}) => {
  return (
    <Select
      fullWidth
      label={{ text: label || '', required }}
      options={options}
      value={value}
      disabled={isLocked}
      placeholder={placeholder}
      onChange={(_, value) => onChange(String(value))}
      data-testid={dataTestId}
    />
  );
};
