import { getDirectRelationship } from '@cognite/apm-client';
import type {
  Action,
  ActionUpsertType,
  APMClient,
  InFieldUser,
} from '@cognite/apm-client';
import { FDMClient } from '@cognite/fdm-client';
import type { CogniteClient } from '@cognite/sdk';

export class ActionsService extends FDMClient {
  apmClient: APMClient;
  constructor(client: CogniteClient, apmClient: APMClient) {
    super(client);
    this.apmClient = apmClient;
  }

  async createActions(actions: Action[], user: InFieldUser) {
    const upsertedActions: Action[] = actions.map((action) => ({
      ...action,
      createdBy: user,
    }));
    return this.upsertActions(upsertedActions).then(
      (result) => result.data.items[0]
    );
  }

  async updateAction(action: Action, user: InFieldUser) {
    const upsertedAction: Action = {
      ...action,
      updatedBy: user,
    };
    return this.upsertActions([upsertedAction]);
  }

  async upsertActions(actions: Action[]) {
    const upsertedActions: ActionUpsertType[] = actions.map((action) => {
      const conditionalActionRelationship = getDirectRelationship(
        action.conditionalActions
      );
      const targetRelationship = getDirectRelationship(action.target);
      const createdByRelationship = getDirectRelationship({
        externalId: action.createdBy?.externalId,
        space: this.apmClient.userInstanceSpace,
      });
      const updatedByRelationship = getDirectRelationship({
        externalId: action.updatedBy?.externalId,
        space: this.apmClient.userInstanceSpace,
      });

      return {
        ...action,
        conditionalActions: conditionalActionRelationship,
        target: targetRelationship,
        createdBy: createdByRelationship,
        updatedBy: updatedByRelationship,
      };
    });
    return this.apmClient.actions.upsert(upsertedActions);
  }

  async deleteActions(externalIds: string[]) {
    return this.apmClient.actions.delete(externalIds);
  }
}
