import { APP_CONFIG_V2_EXTERNAL_ID } from '../constants';
import type {
  AppConfig,
  ResourceFilters,
  RootLocationDataFilters,
} from '../types';

export const SELECTED_ROOT_LOCATION_KEY = 'INFIELD_SELECTED_ROOT_LOC';

export const mergeConfigFilterObjects = (
  newFilters: ResourceFilters,
  oldFilters: ResourceFilters = {},
  assetFilters = false
) => {
  const mergedFilters: ResourceFilters = {
    assetSubtreeExternalIds: newFilters.assetSubtreeExternalIds || [],
    externalIdPrefix: oldFilters.externalIdPrefix,
  };
  if (!assetFilters) {
    mergedFilters.datasetIds = oldFilters.datasetIds;
  }

  return mergedFilters;
};

export const mergeDefaultAndAdditionalFilters = (
  infieldDefaultFilter?: string,
  additionalConfigFilter?: string[] | null
) => {
  const combinedRootAssetFilters = [
    infieldDefaultFilter,
    ...(additionalConfigFilter || []),
  ].filter(Boolean) as string[];

  return combinedRootAssetFilters;
};

export const getIsNewConfigVersion = (appConfig: AppConfig | undefined) => {
  if (appConfig) {
    return appConfig.externalId === APP_CONFIG_V2_EXTERNAL_ID;
  }
  return false;
};

export const getDefaultDataFilters = (rootAssetExternalId: string) => {
  const defaultFilters: ResourceFilters = {
    assetSubtreeExternalIds: [rootAssetExternalId],
  };
  const dataFilters: RootLocationDataFilters = {
    general: defaultFilters,
    assets: defaultFilters,
    timeseries: defaultFilters,
    files: defaultFilters,
  };
  return dataFilters;
};
