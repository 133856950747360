import type { ChecklistItemStatusAggregation } from '@infield/features/checklist';
import { ClickableTagChip } from '@infield/features/ui/Chip';
import type { FC } from 'react';

import { getTasksProgressColor } from './utils';

type Props = {
  status?: string;
  aggregatedChecklistStatuses?: ChecklistItemStatusAggregation;
  onClickAction?: () => void;
};

export const ChecklistStatusCell: FC<Props> = ({
  status,
  aggregatedChecklistStatuses,
  onClickAction,
}: Props) => {
  if (!status) {
    return null;
  }

  const statusColor = getTasksProgressColor(aggregatedChecklistStatuses);

  return (
    <ClickableTagChip
      size="small"
      colorScheme={statusColor}
      label={status}
      onClick={onClickAction}
    />
  );
};
