import Konva from 'konva';
import clamp from 'lodash/clamp';

import { Position, Size, Vector } from '../annotations/types';

export const getDimensionsConstrainedToContainerDimensions = (
  { x, y, width, height }: Position & Size,
  containerDimensions: Size,
  containerPositionOffset: number = 0,
  uniformScaling: boolean = false
): {
  width: number;
  height: number;
} => {
  const clampedWidth = clamp(
    width,
    0,
    containerDimensions.width - x - containerPositionOffset
  );
  const clampedHeight = clamp(
    height,
    0,
    containerDimensions.height - y - containerPositionOffset
  );

  const uniformSize = Math.min(clampedWidth, clampedHeight);
  return {
    width: uniformScaling ? uniformSize : clampedWidth,
    height: uniformScaling ? uniformSize : clampedHeight,
  };
};

export const isLineSegment = (node: Konva.Node): node is Konva.Line =>
  node instanceof Konva.Line && node.points().length === 4;

export const getPositionConstrainedToContainerDimensions = (
  position: Position,
  containerDimensions: Size,
  containerPositionOffset: number = 0
): Position => {
  return {
    x: clamp(position.x, containerPositionOffset, containerDimensions.width),
    y: clamp(position.y, containerPositionOffset, containerDimensions.height),
  };
};

export const getVectorLength = (vector: Vector): number =>
  Math.sqrt(vector.x * vector.x + vector.y * vector.y);

export const getPerpendicularVector = (vector: Vector): Vector => {
  return { x: -vector.y, y: vector.x };
};

export const getMidpoint = (vector1: Vector, vector2: Vector): Vector => {
  return { x: (vector1.x + vector2.x) / 2, y: (vector1.y + vector2.y) / 2 };
};

export const scaleVector = (vector: Vector, outputLength: number): Vector => {
  const vectorLength = getVectorLength(vector);
  const scaleFactor = outputLength / vectorLength;
  return { x: scaleFactor * vector.x, y: scaleFactor * vector.y };
};
