/**
 *
 * @param name
 * @return the file extension of the given file name without dot (e.g. 'txt'). if the file has no extension, an empty string is returned
 */
const getFileExtension = (name: string): string => {
  const parts = name.split('.');

  if (parts.length === 1) {
    return '';
  }

  return parts[parts.length - 1].toLowerCase();
};

export default getFileExtension;
