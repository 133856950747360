import { Container } from '../../containers';
import { ContainerConfig } from '../../containers/types';
import { UnifiedViewer } from '../../UnifiedViewer';

import getContainerByContainerConfig from './getContainerByContainerConfig';
import getContainerCacheKey from './getContainerCacheKey';

export default class ContainerCache {
  private containerCache: Map<string, Container> = new Map();

  public getContainer = (
    containerConfig: ContainerConfig,
    unifiedViewer: UnifiedViewer
  ): Container => {
    if (!this.containerCache.has(getContainerCacheKey(containerConfig))) {
      this.containerCache.set(
        getContainerCacheKey(containerConfig),
        getContainerByContainerConfig(containerConfig, unifiedViewer)
      );
    }

    const container = this.containerCache.get(
      getContainerCacheKey(containerConfig)
    );

    if (container === undefined) {
      throw new Error(
        'Container from cache is undefined, this should not happen'
      );
    }

    return container;
  };

  public removeStaleCachedContainers = (
    containerConfigs: ContainerConfig[]
  ): void => {
    const currentContainerCacheKeys = Array.from(this.containerCache.keys());
    const nextContainerCacheKeys = containerConfigs.map(getContainerCacheKey);

    const containerCacheKeysToRemove = currentContainerCacheKeys.filter(
      (key) => !nextContainerCacheKeys.includes(key)
    );

    containerCacheKeysToRemove.forEach((key) => {
      this.containerCache.get(key)?.onDestroy?.();
      this.containerCache.delete(key);
    });
  };
}
