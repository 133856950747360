import type { Activity } from '@cognite/apm-client';
import {
  EmptyState,
  Flex,
  PlansIllustration,
  Skeleton,
} from '@cognite/cogs.js-v10';
import {
  ActivityDetails,
  useAssetActivitiesQuery,
} from '@infield/features/activities';
import { AssetTypeFilter } from '@infield/features/asset/asset-type-filter';
import * as S from '@infield/features/asset/elements';
import { ListAccordion } from '@infield/features/asset/shared';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AssetActivitiesList } from './asset-activities-list';
import { selectedActivityInPanelAtom } from './state';

type Props = {
  assetExternalId: string;
};

export const AssetActivitiesView: FC<Props> = ({ assetExternalId }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const navigate = useNavigate();

  const [activePanels, setActivePanels] = useState<string[]>([]);

  const isDesktop = useIsDesktop();

  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const selectedActivity = useRecoilValue<string>(selectedActivityInPanelAtom);
  const setSelectedActivity = useSetRecoilState(selectedActivityInPanelAtom);

  useEffect(() => {
    return () => {
      setSelectedActivity('');
    };
  }, [setSelectedActivity]);

  const { data: activities, isInitialLoading } = useAssetActivitiesQuery(
    assetExternalId,
    []
  );

  useEffect(() => {
    if (activePanels.length === 0) {
      if (selectedActivity) {
        const activity = activities?.find(
          (activity) => activity.externalId === selectedActivity
        );

        if (activity) {
          setActivePanels([activity.status!]);
        }
      } else if (activities && activities[0].status) {
        setActivePanels([activities[0].status]);
      }
    }
    // we don't want this to trigger when we collapse/expand panels
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities, selectedActivity]);

  const handleTogglePanel = (panel: string) => {
    setActivePanels((prevActivePanel) =>
      prevActivePanel.includes(panel)
        ? prevActivePanel.filter((key) => key !== panel)
        : [...prevActivePanel, panel]
    );
  };

  const handleOnAssetClick = (assetExternalId: string) => {
    navigate(`/asset/${encodeURIComponent(assetExternalId)}`);
  };

  const hasActivities = activities && activities.length > 0;

  if (isInitialLoading) {
    return <Skeleton.List />;
  }

  if (!hasActivities) {
    return (
      <S.Container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <EmptyState
          illustration={<PlansIllustration />}
          title={t(
            'ASSET_OVERVIEW_ACTIVITIES_EXPANDED_VIEW_NO_ACTIVITY_TITLE',
            'No work orders'
          )}
          description={t(
            'ASSET_OVERVIEW_ACTIVITIES_EXPANDED_VIEW_NO_ACTIVITY_BODY',
            'There are no work orders found on this asset.'
          )}
        />
      </S.Container>
    );
  }

  const filterValues = Array.from(
    new Set(activities.map((activity) => activity.type))
  ) as string[];

  const filteredActivities =
    selectedTypes.length > 0
      ? activities?.filter((activity) => selectedTypes.includes(activity.type!))
      : activities;

  const activitiesGrouped =
    filteredActivities?.reduce((prevValue, currentActivity) => {
      if (prevValue[currentActivity.status!]) {
        return {
          ...prevValue,
          [currentActivity.status!]: [
            ...prevValue[currentActivity.status!],
            currentActivity,
          ],
        };
      }

      return { ...prevValue, [currentActivity.status!]: [currentActivity] };
    }, {} as Record<string, Activity[]>) || {};

  const handleOnActivityClick = (activityExternalId: string) => {
    if (isDesktop) {
      setSelectedActivity(activityExternalId);
    } else {
      navigate(`/activity/${encodeURIComponent(activityExternalId)}/details`);
    }
  };

  return (
    <S.Container>
      <S.ContentWrapper>
        <Flex>
          <AssetTypeFilter
            selectedTypes={selectedTypes}
            filterValues={{ types: filterValues }}
            onClickType={(type) =>
              setSelectedTypes((prevSelectedTypes) =>
                prevSelectedTypes.includes(type)
                  ? prevSelectedTypes.filter((key) => key !== type)
                  : [...prevSelectedTypes, type]
              )
            }
          />
        </Flex>
        <S.GroupsWrapper>
          {Object.entries(activitiesGrouped).map(
            ([groupName, groupActivities]) => (
              <ListAccordion
                key={groupName}
                title={t(
                  `ASSET_OVERVIEW_ACTIVITIES_EXPANDED_VIEW_COLLAPSE_PANEL_${groupName}_TITLE`,
                  groupName
                )}
                numberOfItems={groupActivities.length}
                expanded={activePanels.some((a) => a === groupName)}
                toggleExpand={() => handleTogglePanel(groupName)}
              >
                <AssetActivitiesList
                  activities={groupActivities}
                  selectedActivity={selectedActivity}
                  setSelectedActivity={handleOnActivityClick}
                />
              </ListAccordion>
            )
          )}
        </S.GroupsWrapper>
      </S.ContentWrapper>

      {selectedActivity && (
        <S.DetailWrapper>
          <ActivityDetails
            onClose={() => setSelectedActivity('')}
            onAssetClick={handleOnAssetClick}
            activityId={selectedActivity}
          />
        </S.DetailWrapper>
      )}
    </S.Container>
  );
};
