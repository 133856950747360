import type { UserPreferenceLocation } from '@cognite/apm-client';
import { useSpaceReadAccess } from '@infield/features/access';
import { useFDMServices } from '@infield/providers/fdm-services';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';

import { getIsNewConfigVersion } from '../utils/utils';

import { useAppConfigQuery } from './use-query';

export const useSelectableLocations = () => {
  const { assetService } = useFDMServices();
  const { data: appConfig, isLoading: isLoadingAppConfig } =
    useAppConfigQuery();
  const { isIdm } = useAppConfigContext();
  const isNewConfigVersion = getIsNewConfigVersion(appConfig);
  const { data: access, isLoading: isLoadingSpaceAccess } =
    useSpaceReadAccess('app');

  return useQuery(
    [QueryKeys.SELECTABLE_LOCATIONS, access, isIdm],
    async () => {
      if (isIdm) {
        return [];
      }

      const spaceIds = access?.readAccessGroups || [];
      let selectableLocationsExternalIds = [];

      const configuredRootLocations =
        appConfig?.featureConfiguration?.rootLocationConfigurations;
      const configuredRootLocationExternalIds = configuredRootLocations?.map(
        (rootLocation) => ({
          assetExternalId: rootLocation.assetExternalId,
          configExternalId: rootLocation.externalId,
        })
      );

      if (
        configuredRootLocationExternalIds === undefined ||
        configuredRootLocationExternalIds.length === 0
      ) {
        throw Error(
          'Could not find any configured root location assetExternalIds'
        );
      }

      if (access?.hasReadAllSpacesAccess) {
        selectableLocationsExternalIds = configuredRootLocationExternalIds;
      } else {
        selectableLocationsExternalIds = configuredRootLocations!.reduce(
          (
            acc: {
              assetExternalId: string;
              configExternalId: string | undefined;
            }[],
            rootLocation
          ) => {
            const matchAppDataInstance =
              rootLocation.appDataInstanceSpace &&
              spaceIds.includes(rootLocation.appDataInstanceSpace);

            const matchSourceDataInstance =
              rootLocation.sourceDataInstanceSpace &&
              spaceIds.includes(rootLocation.sourceDataInstanceSpace);

            if (matchAppDataInstance || matchSourceDataInstance) {
              return [
                ...acc,
                {
                  assetExternalId: rootLocation.assetExternalId,
                  configExternalId: rootLocation.externalId,
                },
              ];
            }
            return acc;
          },
          []
        );
        if (selectableLocationsExternalIds.length === 0)
          throw Error('locationAccess');
      }

      const locationAssets = await assetService.getAPMAssetByExternalIds(
        selectableLocationsExternalIds.map(
          ({ assetExternalId }) => assetExternalId
        )
      );

      const sortedLocationAssets = locationAssets.sort((a, b) =>
        (a.title || '').localeCompare(b.title || '')
      );

      const selectableLocations = selectableLocationsExternalIds.reduce(
        (acc, { configExternalId, assetExternalId }) => {
          const correspondingAsset = sortedLocationAssets.find(
            ({ externalId }) => externalId === assetExternalId
          );
          if (correspondingAsset) {
            acc.push({
              externalId: isNewConfigVersion
                ? configExternalId!
                : assetExternalId,
              assetExternalId: isNewConfigVersion ? assetExternalId : undefined,
              name: correspondingAsset.title || correspondingAsset.externalId,
            });
          }
          return acc;
        },
        [] as UserPreferenceLocation[]
      );

      return selectableLocations;
    },
    {
      onError: (err: Error) => {
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: QueryKeys.SELECTABLE_LOCATIONS,
          },
        });
      },
      enabled: !isLoadingAppConfig && !isLoadingSpaceAccess,
      retry: false,
      refetchOnReconnect: false,
    }
  );
};
