import type { CustomReadingLabel, Measurement } from '@cognite/apm-client';
import {
  AddLargeIcon,
  Body,
  Button,
  DeleteIcon,
  Flex,
  Input,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  useMeasurementDelete,
  useMeasurementsUpsert,
} from '@infield/features/measurements/hooks';
import { TaskFormBlockWrapper } from '@infield/features/task/task-form/elements';
import { useOnClickOutside } from '@infield/features/ui';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { MeasurementLabelButtonConfiguration } from './measurement-label-button-configuration';

export type Props = {
  measurement: Measurement;
  taskExternalId: string;
};

export const MeasurementLabelConfiguration: FC<Props> = ({
  measurement,
  taskExternalId,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);

  const [title, setTitle] = useState<string>(measurement.title || '');

  const {
    mutateAsync: upsertCustomReadingLabel,
    isLoading: isCustomReadingLabelUpsertLoading,
  } = useMeasurementsUpsert();

  const [customReadingLabels, setCustomReadingLabels] = useState<
    CustomReadingLabel[]
  >(measurement.options || []);

  const {
    mutateAsync: deleteCustomReadingLabel,
    isLoading: isDeletingCustomReadingLabel,
  } = useMeasurementDelete();

  const saveCustomReadingLabels = (
    updatedMeasurementLabels: CustomReadingLabel[]
  ) => {
    upsertCustomReadingLabel({
      measurementToUpsert: [
        {
          externalId: measurement.externalId,
          options: updatedMeasurementLabels,
        },
      ],
    });
  };

  const addCustomReadingLabel = () => {
    const updatedMeasurementLabels = [
      ...customReadingLabels,
      { id: uuid(), label: '' },
    ];

    setCustomReadingLabels(updatedMeasurementLabels);
    saveCustomReadingLabels(updatedMeasurementLabels);
  };

  const titleRef = useRef<HTMLInputElement>(null);

  const handleOnClickOutside = (event: MouseEvent | TouchEvent) => {
    if (titleRef.current) {
      titleRef.current.blur();
      event.stopPropagation();
    }
  };

  useOnClickOutside(titleRef, handleOnClickOutside);

  const handleTitleOnBlur = () => {
    if (measurement.title !== title) {
      upsertCustomReadingLabel({
        measurementToUpsert: [
          {
            externalId: measurement.externalId,
            title,
          },
        ],
      });
    }
  };

  const handleUpdateMeasurementOption = (
    measurementOption: CustomReadingLabel
  ) => {
    const updatedMeasurementLabels = customReadingLabels.map((option) => {
      if (option.id === measurementOption.id) {
        return measurementOption;
      }
      return option;
    });
    setCustomReadingLabels(updatedMeasurementLabels);
    saveCustomReadingLabels(updatedMeasurementLabels);
  };

  const handleDeleteMeasurementOption = (
    measurementOption: CustomReadingLabel
  ) => {
    const updatedMeasurementLabels = customReadingLabels.filter(
      (option) => option.id !== measurementOption.id
    );
    setCustomReadingLabels(updatedMeasurementLabels);
    saveCustomReadingLabels(updatedMeasurementLabels);
  };

  const isValidCustomReadingLabelName = (
    id?: string,
    label?: string
  ): boolean => {
    if (!label) return true;

    if (customReadingLabels?.some((op) => op.label === label && op.id !== id)) {
      return false;
    }

    return true;
  };

  return (
    <TaskFormBlockWrapper key={measurement.externalId}>
      <Flex direction="column" gap={6}>
        <Body size="medium" strong>
          {t(
            'TEMPLATE_TASK_FORM_CUSTOM_READING_DESCRIPTION_FIELD',
            'Description'
          )}
        </Body>
        <Input
          data-testid="task-form-label-reading-configuration-description-input"
          ref={titleRef}
          fullWidth
          onChange={(e) => setTitle(e.target.value)}
          onBlur={handleTitleOnBlur}
          clearable
          value={title}
          placeholder={t(
            'TEMPLATE_TASK_FORM_CUSTOM_READING_DESCRIPTION_FIELD_PLACEHOLDER',
            'e.g. Is the valve open?'
          )}
        />
      </Flex>
      {customReadingLabels?.map((customReadingLabel, index) => (
        <MeasurementLabelButtonConfiguration
          key={customReadingLabel.id || index}
          customReadingLabel={customReadingLabel}
          onUpdate={handleUpdateMeasurementOption}
          isValidName={isValidCustomReadingLabelName}
          onDelete={handleDeleteMeasurementOption}
        />
      ))}
      <Flex gap={8} direction="column">
        <Button
          disabled={isCustomReadingLabelUpsertLoading}
          data-testid="task-form-label-reading-configuration-add-option-button"
          loading={isCustomReadingLabelUpsertLoading}
          iconPlacement="left"
          icon={<AddLargeIcon />}
          onClick={() => addCustomReadingLabel()}
          type="ghost"
        >
          {t('TEMPLATE_TASK_FORM_CUSTOM_READING_BUTTON_ADD', 'Add button')}
        </Button>
        <Flex justifyContent="flex-end">
          <Button
            data-testid="label-reading-configuration-remove-reading-button"
            type="tertiary"
            icon={<DeleteIcon />}
            loading={isDeletingCustomReadingLabel}
            onClick={() =>
              deleteCustomReadingLabel({
                measurementExternalIds: [measurement.externalId],
                taskExternalId,
              })
            }
          >
            {t('TEMPLATE_TASK_FORM_CUSTOM_READING_BUTTON_DELETE', 'Remove')}
          </Button>
        </Flex>
      </Flex>
    </TaskFormBlockWrapper>
  );
};
