import React from 'react';

import styled from 'styled-components';

type VideoProps = {
  url: string;
  onRef: (ref: HTMLVideoElement | null) => void;
  onLoadedMetadata?: () => void;
  onLoadedData?: () => void;
  onPlay: () => void;
  onPause: () => void;
  onClick: () => void;
  onTimeUpdate: () => void;
  width: number;
  height: number;
};

const VideoWrapper: React.FC<VideoProps> = ({
  url,
  onRef,
  onLoadedMetadata,
  onClick,
  onPlay,
  onPause,
  onTimeUpdate,
  onLoadedData,
  width,
  height,
}) => (
  <div
    style={{
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    }}
  >
    <StyledVideo
      src={url}
      muted
      crossOrigin="anonymous"
      playsInline
      ref={onRef}
      onLoadedMetadata={onLoadedMetadata}
      onLoadedData={onLoadedData}
      onPlay={onPlay}
      onPause={onPause}
      onTimeUpdate={onTimeUpdate}
      style={{
        width: width,
        height: height,
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    />
    <Overlay onClick={onClick} />
  </div>
);

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

const StyledVideo = styled.video`
  width: 100%;
`;

export default VideoWrapper;
