import { useMemo } from 'react';

import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import { ComponentIcon } from '@cognite/cogs.js-v10';
import { useSDK } from '@cognite/sdk-provider';

import { FdmInstance } from '../../../types';
import { TimeDisplay } from '../../common/TimeDisplay';
import { ReactContainerRenderContentProps } from '../../ReactContainer';
import useStatusChange from '../../ReactContainer/useStatusChange';
import { TableItem } from '../../TableContainer';
import TableContent from '../../TableContainer/TableContent';

import { FdmClient } from './FdmClient';
import parseInstance from './parseInstance';
import { useView } from './useView';

type FdmPropertyListProps = Pick<
  ReactContainerRenderContentProps,
  | 'width'
  | 'height'
  | 'unscaledWidth'
  | 'unscaledHeight'
  | 'setLoadingStatus'
  | 'onContentSizeChange'
  | 'shouldAutoSize'
> & {
  instance: FdmInstance;
  properties?: string[];
  containerPadding: number;
};

const FdmPropertyList: React.FC<FdmPropertyListProps> = ({
  width,
  height,
  unscaledWidth,
  unscaledHeight,
  shouldAutoSize,
  containerPadding,
  setLoadingStatus,
  onContentSizeChange,
  instance,
  properties,
}) => {
  const sdk = useSDK();
  const fdmClient = useMemo(() => new FdmClient(sdk), [sdk]);
  const {
    data: view,
    isFetching: isLoadingView,
    isError: isErrorView,
  } = useView(instance, fdmClient);

  const {
    data,
    isFetching: isLoadingInstance,
    isError: isErrorInstance,
  } = useQuery(
    ['datamodel', instance, view],
    async () => {
      if (view === undefined) {
        return undefined;
      }
      return fdmClient.getInstanceById(
        instance.instanceSpace,
        instance.instanceExternalId,
        view
      );
    },
    { enabled: view !== undefined }
  );
  useStatusChange({
    data,
    isLoading: isLoadingInstance || isLoadingView,
    isError: isErrorInstance || isErrorView,
    setLoadingStatus,
  });

  const instanceTableItems = useMemo(() => {
    if (data === undefined || view === undefined) {
      return undefined;
    }
    const instanceProperties = parseInstance(view, data.instance, data.typing);
    return Object.entries(instanceProperties)
      .filter(([key]) => properties === undefined || properties.includes(key))
      .map(
        ([property, value]): TableItem => ({
          label: property,
          value:
            value instanceof Date ? (
              <TimeDisplay value={value.getTime()} />
            ) : (
              value
            ),
        })
      );
  }, [data, properties, view]);

  return (
    <div>
      {instanceTableItems !== undefined && (
        <TableContent
          width={width}
          height={height}
          unscaledWidth={unscaledWidth}
          unscaledHeight={unscaledHeight}
          shouldAutoSize={shouldAutoSize}
          containerPadding={containerPadding}
          title={
            <TitleWrapper>
              <ColoredComponentIcon /> {}
              {instance.viewExternalId} · {instance.instanceExternalId}
            </TitleWrapper>
          }
          items={instanceTableItems}
          setLoadingStatus={setLoadingStatus}
          onContentSizeChange={onContentSizeChange}
        />
      )}
      {(isLoadingInstance || isLoadingView) && (
        <div
          style={{
            position: 'absolute',
            inset: 0,
            background: 'rgba(255,255,255,0.7)',
          }}
        />
      )}
    </div>
  );
};

const ColoredComponentIcon = styled(ComponentIcon)`
  color: #386dee;
  position: relative;
  margin-right: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export default FdmPropertyList;
