import { SplitButton } from '@cognite/cogs-lab';
import { Flex, Menu, Modal } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const FilterMenuButton = styled(SplitButton)`
  .tippy-content > div > .cogs-menu {
    padding: 0px;
  }
`;

export const MenuWrapper = styled(Menu)`
  padding: 8px !important;
`;

export const SubmenuLabel = styled(Flex)`
  align-items: center;
  gap: 4px;
`;

export const DeleteModal = styled(Modal)`
  .cogs-modal-header {
    height: auto;
    min-height: 64px;
  }
  .cogs-modal-title {
    white-space: normal;
  }
`;
