import { Button } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px;
  height: 600px;
`;

export const SaveButton = styled(Button)`
  width: 200px;
`;
