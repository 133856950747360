import { Colors } from '@cognite/cogs.js-v10';

export type ThemeCogs = typeof themeCogsLight;

/**
 * This is a collection of all (current) Cogs color tokens (without the inverted ones)
 * We may not use all of these tokens in the application, though they're provided here for completeness
 * We do NOT include the decorative tokens here as these might not need to change between themes
 * See https://cognitedata.github.io/cogs.js/?path=/docs/tokens-color--color
 */
export const themeCogsLight = {
  // Text or Icon
  'text-icon--strong': Colors['text-icon--strong'],
  'text-icon--strong--inverted': Colors['text-icon--strong--inverted'],
  'text-icon--medium': Colors['text-icon--medium'],
  'text-icon--muted': Colors['text-icon--muted'],
  'text-icon--on-contrast--strong': Colors['text-icon--on-contrast--strong'],
  'text-icon--interactive--default': Colors['text-icon--interactive--default'],
  'text-icon--interactive--hover': Colors['text-icon--interactive--hover'],
  'text-icon--interactive--pressed': Colors['text-icon--interactive--pressed'],
  'text-icon--interactive--disabled':
    Colors['text-icon--interactive--disabled'],
  'text-icon--status-neutral': Colors['text-icon--status-neutral'],
  'text-icon--status-success': Colors['text-icon--status-success'],
  'text-icon--status-warning': Colors['text-icon--status-warning'],
  'text-icon--status-critical': Colors['text-icon--status-critical'],
  'text-icon--status-undefined': Colors['text-icon--status-undefined'],
  // Border
  'border--muted': Colors['border--muted'],
  'border--interactive--default': Colors['border--interactive--default'],
  'border--interactive--default--alt':
    Colors['border--interactive--default--alt'],
  'border--interactive--hover': Colors['border--interactive--hover'],
  'border--interactive--toggled-default':
    Colors['border--interactive--toggled-default'],
  'border--interactive--toggled-hover':
    Colors['border--interactive--toggled-hover'],
  'border--interactive--toggled-pressed':
    Colors['border--interactive--toggled-pressed'],
  'border--interactive--disabled': Colors['border--interactive--disabled'],
  'border--status-neutral--muted': Colors['border--status-neutral--muted'],
  'border--status-neutral--strong': Colors['border--status-neutral--strong'],
  'border--status-success--muted': Colors['border--status-success--muted'],
  'border--status-success--strong': Colors['border--status-success--strong'],
  'border--status-warning--muted': Colors['border--status-warning--muted'],
  'border--status-warning--strong': Colors['border--status-warning--strong'],
  'border--status-critical--muted': Colors['border--status-critical--muted'],
  'border--status-critical--strong': Colors['border--status-critical--strong'],
  'border--status-undefined--muted': Colors['border--status-undefined--muted'],
  'border--status-undefined--strong':
    Colors['border--status-undefined--strong'],
  // Surface
  'surface--muted': Colors['surface--muted'],
  'surface--muted--inverted': Colors['surface--muted--inverted'],
  'surface--medium': Colors['surface--medium'],
  'surface--strong': Colors['surface--strong'],
  'surface--misc-transparent': Colors['surface--misc-transparent'],
  'surface--misc-backdrop': Colors['surface--misc-backdrop'],
  'surface--misc-canvas': Colors['surface--misc-canvas'],
  'surface--misc-code--muted': Colors['surface--misc-code--muted'],
  'surface--misc-code--medium': Colors['surface--misc-code--medium'],
  'surface--interactive--hover': Colors['surface--interactive--hover'],
  'surface--interactive--pressed': Colors['surface--interactive--pressed'],
  'surface--interactive--toggled-default':
    Colors['surface--interactive--toggled-default'],
  'surface--interactive--toggled-hover':
    Colors['surface--interactive--toggled-hover'],
  'surface--interactive--toggled-pressed':
    Colors['surface--interactive--toggled-pressed'],
  'surface--interactive--disabled': Colors['surface--interactive--disabled'],
  'surface--interactive--disabled--alt':
    Colors['surface--interactive--disabled--alt'],
  'surface--action--strong--default':
    Colors['surface--action--strong--default'],
  'surface--action--strong--hover': Colors['surface--action--strong--hover'],
  'surface--action--strong--pressed':
    Colors['surface--action--strong--pressed'],
  'surface--action--muted--default': Colors['surface--action--muted--default'],
  'surface--action--muted--default--alt':
    Colors['surface--action--muted--default--alt'],
  'surface--action--muted--hover': Colors['surface--action--muted--hover'],
  'surface--action--muted--pressed': Colors['surface--action--muted--pressed'],
  'surface--on-action-muted--hover': Colors['surface--action--muted--hover'],
  'surface--status-neutral--muted--default':
    Colors['surface--status-neutral--muted--default'],
  'surface--status-neutral--muted--default--alt':
    Colors['surface--status-neutral--muted--default--alt'],
  'surface--status-neutral--muted--hover':
    Colors['surface--status-neutral--muted--hover'],
  'surface--status-neutral--muted--pressed':
    Colors['surface--status-neutral--muted--pressed'],
  'surface--status-neutral--strong--default':
    Colors['surface--status-neutral--strong--default'],
  'surface--status-neutral--strong--hover':
    Colors['surface--status-neutral--strong--hover'],
  'surface--status-neutral--strong--pressed':
    Colors['surface--status-neutral--strong--pressed'],
  'surface--status-success--muted--default':
    Colors['surface--status-success--muted--default'],
  'surface--status-success--muted--default--alt':
    Colors['surface--status-success--muted--default--alt'],
  'surface--status-success--muted--hover':
    Colors['surface--status-success--muted--hover'],
  'surface--status-success--muted--pressed':
    Colors['surface--status-success--muted--pressed'],
  'surface--status-success--strong--default':
    Colors['surface--status-success--strong--default'],
  'surface--status-success--strong--hover':
    Colors['surface--status-success--strong--hover'],
  'surface--status-success--strong--pressed':
    Colors['surface--status-success--strong--pressed'],
  'surface--status-warning--muted--default':
    Colors['surface--status-warning--muted--default'],
  'surface--status-warning--muted--default--alt':
    Colors['surface--status-warning--muted--default--alt'],
  'surface--status-warning--muted--hover':
    Colors['surface--status-warning--muted--hover'],
  'surface--status-warning--muted--pressed':
    Colors['surface--status-warning--muted--pressed'],
  'surface--status-warning--strong--default':
    Colors['surface--status-warning--strong--default'],
  'surface--status-warning--strong--hover':
    Colors['surface--status-warning--strong--hover'],
  'surface--status-warning--strong--pressed':
    Colors['surface--status-warning--strong--pressed'],
  'surface--status-critical--muted--default':
    Colors['surface--status-critical--muted--default'],
  'surface--status-critical--muted--default--alt':
    Colors['surface--status-critical--muted--default--alt'],
  'surface--status-critical--muted--hover':
    Colors['surface--status-critical--muted--hover'],
  'surface--status-critical--muted--pressed':
    Colors['surface--status-critical--muted--pressed'],
  'surface--status-critical--strong--default':
    Colors['surface--status-critical--strong--default'],
  'surface--status-critical--strong--hover':
    Colors['surface--status-critical--strong--hover'],
  'surface--status-critical--strong--pressed':
    Colors['surface--status-critical--strong--pressed'],
  'surface--status-undefined--muted--default':
    Colors['surface--status-undefined--muted--default'],
  'surface--status-undefined--muted--default--alt':
    Colors['surface--status-undefined--muted--default--alt'],
  'surface--status-undefined--muted--hover':
    Colors['surface--status-undefined--muted--hover'],
  'surface--status-undefined--muted--pressed':
    Colors['surface--status-undefined--muted--pressed'],
  'surface--status-undefined--strong--default':
    Colors['surface--status-undefined--strong--default'],
  'surface--status-undefined--strong--hover':
    Colors['surface--status-undefined--strong--hover'],
  'surface--status-undefined--strong--pressed':
    Colors['surface--status-undefined--strong--pressed'],
};

export const themeCogsDark = Object.keys(themeCogsLight).reduce(
  (themeCogsDarkAcc, colorToken) => {
    return {
      ...themeCogsDarkAcc,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // Ignore linter since we're sure each color has an inverted variant
      [colorToken]: Colors[`${colorToken}--inverted`],
    };
  },
  {} as ThemeCogs
);
