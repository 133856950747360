import { makeToast } from '@cognite/cogs-lab';
import type { Asset } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import uniq from 'lodash/uniq';

export const useAssetsQuery = (assetExternalIds: string[] | undefined) => {
  const { assetService } = useFDMServices();
  const uniqueAssetIds = uniq(assetExternalIds);
  return useQuery<Asset[] | undefined>(
    [QueryKeys.ASSET, uniqueAssetIds],
    async () => {
      if (uniqueAssetIds && uniqueAssetIds?.length > 0)
        return assetService.getAssetByExternalIds(uniqueAssetIds);
    },
    {
      onError: (err) => {
        makeToast({
          body: 'Failed to fetch assets',
          type: 'danger',
        });
        captureException(err);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      enabled: Boolean(uniqueAssetIds) && uniqueAssetIds.length > 0,
    }
  );
};
