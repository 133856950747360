import type { DataGridColumn } from '@cognite/apm-observation';
import type { DatagridColumn, GridFilterOptionsType } from '@cognite/cogs-lab';
import { getStringListCellRenderer } from '@infield/features/activities';
import {
  AssigneeCell,
  ChecklistGroupStatusCell,
  ChecklistProgressCell,
  ChecklistStatusCell,
  DateCell,
  StringListCell,
} from '@infield/features/activities/activity-planning/common-table-cell-components';
import {
  CHECKLIST_STATUS_OPTIONS,
  ColumnWidths,
} from '@infield/features/activities/activity-planning/consts';
import type { DataGridRow } from '@infield/features/activities/activity-planning/maintenance-table/types';

import * as S from '../../../pages/activity/activity-planning/elements';

type StandardTableColumnProps = {
  gridFilterActivityTypeOptions: GridFilterOptionsType[] | undefined;
  locationFilterOption: GridFilterOptionsType[];
  isLoadingFilterLocationOptions: boolean;
  isAkerbpCustomCode: boolean;
  fetchMode: string;
  t: any;
  handleTitleClick: DataGridColumn;
  handleLocationClick: DataGridColumn;
  handleChecklistCellClick: (row: DataGridRow) => void;
  handleLocationFilterSearch: (search: string) => void;
};

export const getStandardTableColumns = ({
  gridFilterActivityTypeOptions,
  locationFilterOption,
  isLoadingFilterLocationOptions,
  isAkerbpCustomCode,
  fetchMode,
  t,
  handleTitleClick,
  handleLocationClick,
  handleChecklistCellClick,
  handleLocationFilterSearch,
}: StandardTableColumnProps): DatagridColumn[] => {
  const activityFieldColumns: DatagridColumn[] = [
    {
      field: 'title',
      headerName: t('ACTIVITIES_TABLE_COLUMN_DETAILS', 'Details'),
      minWidth: ColumnWidths.TITLE,
      flex: 1,
      renderCell: (params) => {
        if (params.row.activity) {
          return <S.ClickableCellTitle>{params.value}</S.ClickableCellTitle>;
        }
        return (
          <S.NonClickableCellTitle>{params.value}</S.NonClickableCellTitle>
        );
      },
      onCellClick: handleTitleClick,
    },
    {
      field: 'type',
      headerName: t('ACTIVITIES_TABLE_COLUMN_TYPE', 'Type'),
      minWidth: ColumnWidths.SMALL,
      flex: 1,
      filter: {
        isSearchable: true,
        options: gridFilterActivityTypeOptions || [],
        showSelectAll: true,
        showSelectedOptionOnTop: true,
        hideResultCount: true,
      },
    },
    {
      field: 'assetExternalId',
      headerName: t('ACTIVITIES_TABLE_COLUMN_LOCATION', 'Location'),
      minWidth: ColumnWidths.LARGE,
      flex: 1,
      renderCell: (params) => (
        <S.ClickableCellTitle>{params.value}</S.ClickableCellTitle>
      ),
      onCellClick: handleLocationClick,
      filter: {
        isSearchable: true,
        showSelectedOptionOnTop: true,
        showSelectAll: true,
        options: locationFilterOption as GridFilterOptionsType[],
        onSearch: handleLocationFilterSearch,
        isLoading: isLoadingFilterLocationOptions,
        hideResultCount: true,
      },
    },
    {
      field: 'startTime',
      headerName: t('ACTIVITIES_TABLE_COLUMN_STARTDATE', 'Start date'),
      sortable: fetchMode === 'list',
      renderCell: (params) => <DateCell date={params.value} />,
      minWidth: ColumnWidths.SMALL,
      width: ColumnWidths.MEDIUM,
    },
    {
      field: 'endTime',
      headerName: t('ACTIVITIES_TABLE_COLUMN_ENDDATE', 'End date'),
      sortable: fetchMode === 'list',
      renderCell: (params) => <DateCell date={params.value} />,
      minWidth: ColumnWidths.SMALL,
      width: ColumnWidths.MEDIUM,
    },
    {
      field: 'status',
      headerName: t('ACTIVITIES_TABLE_COLUMN_STATUS', 'Status'),
      renderCell: (params) => {
        return (
          <StringListCell values={params.value ? [params.value] : undefined} />
        );
      },
      minWidth: ColumnWidths.MEDIUM,
      flex: 1,
    },
    {
      field: 'resource',
      headerName: t('ACTIVITIES_TABLE_COLUMN_CHECKLIST_RESOURCE', 'Resource'),
      renderCell: getStringListCellRenderer,
      minWidth: ColumnWidths.MEDIUM,
      flex: 1,
    },
  ];
  const otherColumns: DatagridColumn[] = [
    {
      field: 'completedChecklistItems',
      headerName: t(
        'ACTIVITIES_TABLE_COLUMN_CHECKLIST_PROGRESS',
        'Checklist progress'
      ),
      renderCell: (params) => {
        return params.row.checklistItemsStatusAggregation ? (
          <ChecklistProgressCell
            aggregatedChecklistStatuses={
              params.row.checklistItemsStatusAggregation
            }
            onClickAction={() =>
              handleChecklistCellClick(params.row as DataGridRow)
            }
          />
        ) : undefined;
      },
      minWidth: ColumnWidths.MEDIUM,
      flex: 1,
    },
    {
      field: 'checklistStatus',
      headerName: t(
        'ACTIVITIES_TABLE_COLUMN_CHECKLIST_STATUS',
        'Checklist status'
      ),
      renderCell: (params) => {
        if (params.row.rowType === 'Activity') {
          return (
            <ChecklistGroupStatusCell checklists={params.row.checklists} />
          );
        }
        return (
          <ChecklistStatusCell
            status={params.value}
            aggregatedChecklistStatuses={
              params.row.checklistItemsStatusAggregation
            }
            onClickAction={() =>
              handleChecklistCellClick(params.row as DataGridRow)
            }
          />
        );
      },
      width: ColumnWidths['X-LARGE'],
      minWidth: ColumnWidths['X-LARGE'],
      filter: {
        options: CHECKLIST_STATUS_OPTIONS,
      },
    },
    {
      field: 'assigneesCount',
      headerName: t('ACTIVITIES_TABLE_COLUMN_CHECKLIST_ASSIGNEES', 'Assignees'),
      renderCell: (params) => (
        <AssigneeCell checklists={params.row.checklists} />
      ),
      minWidth: ColumnWidths.MEDIUM,
      flex: 1,
    },
    {
      field: 'operationNumber',
      headerName: t(
        'ACTIVITIES_TABLE_COLUMN_CHECKLIST_OPERATION_NUMBER',
        'Operation Number'
      ),
      renderCell: getStringListCellRenderer,
      minWidth: ColumnWidths.MEDIUM,
      flex: 1,
    },
  ];
  if (isAkerbpCustomCode) {
    return otherColumns;
  }
  return [...activityFieldColumns, ...otherColumns];
};
