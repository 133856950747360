import numbro from 'numbro';

export const yAxisFormatter = (val: number) =>
  Math.abs(val) > 9999
    ? numbro(val).format({
        average: true,
        mantissa: 2,
        trimMantissa: true,
        currencyPosition: 'postfix',
        spaceSeparated: true,
      })
    : Number.parseFloat(val.toPrecision(3));
