import { Annotation, AnnotationType } from '../../annotations/types';
import { ContainerConfig } from '../../containers/types';

const haveBothDependenciesBeenRemoved = (
  annotation: Annotation,
  currentAnnotationsAndContainerConfigsById: Record<
    string,
    Annotation | ContainerConfig
  >
): boolean => {
  if (annotation.type !== AnnotationType.POLYLINE) {
    return false;
  }

  const { fromId, toId } = annotation;
  if (fromId === undefined || toId === undefined) {
    return false;
  }

  return (
    currentAnnotationsAndContainerConfigsById[fromId] === undefined &&
    currentAnnotationsAndContainerConfigsById[toId] === undefined
  );
};

export default haveBothDependenciesBeenRemoved;
