import { Button, Input, SearchIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';
import { useState } from 'react';

import * as S from './elements';

// TODO(INFIELD2-618): Improve design
// It will be better if we can avoid overriding input styling in element.ts
type Props = {
  onSearch: (str: string) => unknown;
};
export const SearchInput: FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.threeD);

  const [value, setValue] = useState('');
  return (
    <div>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          onSearch(value);
        }}
      >
        <S.InputContainer>
          <Input
            variant="ghost"
            placeholder={t('locateTag', { defaultValue: 'Locate asset' })}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <Button
            type="secondary"
            icon={<SearchIcon />}
            aria-label="Search"
            onClick={() => onSearch(value)}
          />
        </S.InputContainer>
      </form>
    </div>
  );
};
