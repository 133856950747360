import type { Checklist } from '@cognite/apm-client';
import { MoonIcon, SunHighIcon } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

import * as S from './elements';
import {
  getDayAndMonth,
  getDayOfWeek,
  getIsDayTime,
  getLocalTime,
} from './utils';

type Props = {
  checklist: Checklist;
};

export const ActivityListDateTags: FC<Props> = ({ checklist }) => {
  const getChecklistDurationLabel = () => {
    return `${getDayAndMonth(checklist.startTime)} - ${getDayAndMonth(
      checklist.endTime
    )}`;
  };

  if (checklist.type === 'Round') {
    if (!checklist.startTime) {
      return null;
    }

    const isDayTime = getIsDayTime(checklist.startTime);
    return (
      <S.DateTagsWrapper>
        <S.Tag label={getDayAndMonth(checklist.startTime, true)} />
        <S.Tag label={getDayOfWeek(checklist.startTime)} />
        <S.Tag
          icon={isDayTime ? <SunHighIcon /> : <MoonIcon />}
          label={getLocalTime(checklist.startTime)}
        />
      </S.DateTagsWrapper>
    );
  }

  if (!checklist.startTime && !checklist.endTime) {
    return null;
  }

  return <S.Tag label={getChecklistDurationLabel()} />;
};
