import { Body, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  alignItems: 'center',
})`
  width: 100%;
  padding: 14px 16px 14px 12px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
  cursor: pointer;

  .cogs.cogs-chip--size-x-small {
    min-width: unset;
  }
`;

export const Wrapper = styled(Flex)<{ $expanded: boolean }>`
  ${({ theme, $expanded }) =>
    $expanded ? `border-bottom: 1px solid ${theme['border--muted']}` : ``}
`;

export const Title = styled(Body).attrs({
  level: 2,
  strong: true,
})`
  width: 100%;
  margin-left: 8px;
  margin-right: 4px;
  text-transform: capitalize;
`;
