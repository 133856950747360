import {
  Button,
  CloseLargeIcon,
  Heading,
  RadioGroup,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { type FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { SortType } from '../types';

import * as S from './elements';

interface Props {
  sortType: SortType;
  setSortType: (sortType: SortType) => void;
  onClose: () => void;
}

export const SortMenu: FC<Props> = ({ sortType, setSortType, onClose }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentValue, setCurrentValue] = useState<SortType>(sortType);

  return (
    <S.SortContainer>
      <S.SortHeader>
        <Heading level={5}>{t('CHECKLIST_SORT_MENU_HEADER', 'Sort')}</Heading>
        <Button
          type="ghost"
          size="small"
          data-testid="sort-menu-close-button"
          aria-label="sort-menu-close-button"
          icon={<CloseLargeIcon />}
          onClick={onClose}
        />
      </S.SortHeader>
      <RadioGroup
        defaultValue="orderCreated"
        name="sortOrder"
        value={currentValue}
        onChange={(e) => setCurrentValue(e.currentTarget.value as SortType)}
      >
        <S.SortRadio
          name="alphabetical"
          value="alphabetical"
          label={t('CHECKLIST_SORT_MENU_RADIO_OPTION_AZ', 'Assets A-Z')}
        />
        <S.SortRadio
          name="reverseAlphabetical"
          value="reverseAlphabetical"
          label={t('CHECKLIST_SORT_MENU_RADIO_OPTION_ZA', 'Assets Z-A')}
        />
        <S.SortRadio
          name="orderCreated"
          value="orderCreated"
          label={t('CHECKLIST_SORT_MENU_RADIO_OPTION_DEFAULT', 'Order created')}
        />
      </RadioGroup>
      <S.SortButton
        type="primary"
        disabled={currentValue === sortType}
        data-testid="sort-menu-sort-button"
        onClick={() => {
          setSortType(currentValue);

          // Set url params
          if (currentValue !== 'orderCreated') {
            searchParams.set('sortBy', currentValue);
          } else {
            searchParams.delete('sortBy');
          }
          setSearchParams(searchParams, { replace: true });

          onClose();
        }}
      >
        {t('CHECKLIST_SORT_MENU_HEADER', 'Sort')}
      </S.SortButton>
    </S.SortContainer>
  );
};
