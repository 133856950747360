import Konva from 'konva';

import { Size } from '../annotations/types';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';

// The font size is a multiplier of the container's width or height, whichever is larger.
// The reasoning to use the larger dimension, is that we want the label size to be the same
// for two documents where one is rotated 90 degrees of the other.
const LABEL_FONT_SIZE_MULTIPLIER = 0.02;
const LINE_HEIGHT = 1.4;

export const getHeaderHeight = (containerDimensions: Size): number => {
  const { width, height } = containerDimensions;
  const fontSize = LABEL_FONT_SIZE_MULTIPLIER * Math.max(width, height);
  return fontSize * LINE_HEIGHT;
};

export const getHeaderGroup = (
  label: string,
  containerDimensions: Size
): Konva.Group => {
  const headerGroup = new Konva.Group({
    x: 0,
    y: 0,
    fill: 'green',
    [UNIFIED_VIEWER_NODE_TYPE_KEY]:
      UnifiedViewerNodeType.CONTAINER_HEADER_GROUP,
  });

  const { width, height } = containerDimensions;
  const fontSize = LABEL_FONT_SIZE_MULTIPLIER * Math.max(width, height);

  const documentLabelName = new Konva.Text({
    text: label,
    fontSize,
    fontStyle: 'bold',
    fontFamily: 'Arial',
    lineHeight: LINE_HEIGHT,
    fill: 'rgba(0,0,0,0.45)',
    listening: false,
    perfectDrawEnabled: false,
    x: 0,
    y: 0,
  });

  // Resize the header to be the minWidth. We do this so that the header will take up only
  // the necessary space and not affect the layout. If it's a long label, it will expand
  // to maxWidth, but otherwise, the size of the container will be determined by the content node.
  const textWidth = documentLabelName.getTextWidth();
  const minWidth = width === undefined || textWidth < width ? textWidth : width;
  headerGroup.width(minWidth);
  documentLabelName.width(minWidth);

  // TODO: This will break on repeated updates
  headerGroup.add(documentLabelName);
  return headerGroup;
};
