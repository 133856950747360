import { Button } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

import * as S from './elements';
import type { FilterItem } from './types';

export const ActivityListFilter: FC<{
  items: FilterItem[];
  activeFilterKey?: string;
  onClick: (key: string) => void;
}> = ({ items, activeFilterKey, onClick }) => {
  return (
    <S.TagContainer>
      {items.map((item) => {
        return (
          <Button
            size="small"
            toggled={item.key === activeFilterKey}
            key={item.key}
            onClick={() => onClick(item.key)}
            data-testid={`activity-list-filter-${item.key}`}
          >
            {item.displayName}
          </Button>
        );
      })}
    </S.TagContainer>
  );
};
