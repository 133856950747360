import React from 'react';

import styled from 'styled-components';

import chroma from 'chroma-js';

const Container = styled.div`
  background: white;
`;

type OpacitySliderProps = {
  min?: number;
  max?: number;
  step?: number;
  value: string;
  onChange: (value: string) => void;
};

const Input = styled.input``;

const OpacitySlider: React.FC<OpacitySliderProps> = ({
  min = 0,
  max = 1,
  step = 0.1,
  value,
  onChange,
}) => (
  <Container>
    <Input
      type="range"
      min={min}
      max={max}
      step={step}
      value={chroma(value).alpha()}
      onChange={(e) =>
        onChange(chroma(value).alpha(parseFloat(e.target.value)).toString())
      }
    />
  </Container>
);

export default OpacitySlider;
