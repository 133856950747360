import type { Checklist } from '@cognite/apm-client';
import { Flex, LoaderIcon } from '@cognite/cogs.js-v10';
import type { ChecklistItemStatusAggregation } from '@infield/features/checklist';
import { SearchResultHighlight } from '@infield/features/checklist/checklist-item';
import { ComponentListItem } from '@infield/features/ui';
import type { FC } from 'react';

import { ActivityListDateTags } from './activity-list-date-tags';
import * as S from './elements';

type Props = {
  aggregatedStatuses?: ChecklistItemStatusAggregation;
  isItemStatusLoading: boolean;
  checklist: Checklist;
  selected: boolean;
  searchQuery: string;
  onSelectActivity: (activityExternalId: string) => void;
};

export const ActivityListItem: FC<Props> = ({
  checklist,
  selected,
  onSelectActivity,
  aggregatedStatuses,
  searchQuery,
  isItemStatusLoading,
}) => {
  const handleSelectActivity = () => {
    onSelectActivity(checklist.externalId);
  };

  const getTasksProgressColor = () => {
    if (aggregatedStatuses?.notOk) {
      return 'red';
    }
    if (aggregatedStatuses?.ok) {
      return 'green';
    }
    return undefined;
  };

  return (
    <ComponentListItem $selected={selected}>
      <S.ClickableArea
        data-testid="checklist-item-card"
        onClick={() => handleSelectActivity()}
      >
        <S.Title>
          <SearchResultHighlight
            text={checklist.title || ''}
            searchQuery={searchQuery}
          />
        </S.Title>
        <S.TagContainer>
          <Flex gap={8}>
            {isItemStatusLoading ? (
              <LoaderIcon className="cogs-icon--rotating" />
            ) : (
              <S.Tag
                colorScheme={getTasksProgressColor()}
                label={`${aggregatedStatuses?.completed ?? '?'}/${
                  aggregatedStatuses?.total ?? '?'
                }`}
              />
            )}
            <S.Tag label={checklist.type || ''} />
          </Flex>
          <ActivityListDateTags checklist={checklist} />
        </S.TagContainer>
      </S.ClickableArea>
    </ComponentListItem>
  );
};
