import { getEnvironment, isProduction } from '@infield/features/auth';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const setupSentry = () =>
  Sentry.init({
    dsn: 'https://a75db4d6b94177c5acaeaf4334f9470c@o4508040730968064.ingest.de.sentry.io/4508087442145360',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.05,
    sampleRate: 1,
    maxValueLength: 2500, // Increase the max length of strings for the error message]
    release: String(import.meta.env?.SENTRY_RELEASE),
    environment: getEnvironment(),
    beforeSend: (event, hint) => {
      // For ease of development, print errors to console if in dev mode
      if (!isProduction() && hint) {
        console.error(hint.originalException || hint.syntheticException);
      }

      const scrubbedEvent = {
        ...event,
        user: {
          ...event.user,
          email: undefined,
          username: undefined,
          // For some reason the Sentry JS SDK fills in the user's ip address when sending events.
          // Setting it to an example IP address to avoid leaking user's IP address (see RFC 5737).
          ip_address: '192.0.2.0',
          id: undefined,
        },
      };

      return scrubbedEvent;
    },
  });
