import { CogniteInternalId, CogniteExternalId } from '@cognite/sdk';

import calculateGranularity from './calculateGranularity';
import {
  DataFetchMode,
  DateRange,
  TimeseriesDatapointsQuery,
  TimeseriesDatapointsQueryBase,
} from './types';

export interface TimeseriesChartQuery {
  timeseriesId: number;
  dateRange?: DateRange;
  numberOfPoints?: number;
}

interface TimeseriesChartMetadata {
  id: CogniteInternalId;
  externalId?: CogniteExternalId;
  numberOfPoints: number;
  dataFetchMode: DataFetchMode;
  isStep?: boolean;
  isString?: boolean;
  unit?: string;
  color?: string;
}

interface Props {
  query: TimeseriesChartQuery;
  metadata: TimeseriesChartMetadata;
}

export const mapToTimeseriesDatapointsQuery = ({
  query,
  metadata,
}: Props): TimeseriesDatapointsQuery => {
  const { timeseriesId, dateRange } = query;
  const { numberOfPoints, dataFetchMode } = metadata;

  const queryBase: TimeseriesDatapointsQueryBase = {
    id: timeseriesId,
    start: dateRange?.[0],
    end: dateRange?.[1],
    limit: numberOfPoints,
  };

  if (dataFetchMode === 'raw') {
    return queryBase;
  }

  return {
    ...queryBase,
    aggregates: ['average', 'max', 'min', 'count'],
    granularity: calculateGranularity(dateRange, numberOfPoints),
  };
};
