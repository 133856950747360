import { getItem, setItem } from '@infield/utils/localStorage';
import type { FC, ReactNode } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

type ThreeDNavHintsContextType = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => unknown;
  doNotShowHintsAgainChecked: boolean;
  setDoNotShowHintsAgainChecked: (value: boolean) => unknown;
  closeNavigationalHints: () => unknown;
};

export const ThreeDNavHintsContext = createContext<
  ThreeDNavHintsContextType | undefined
>(undefined);

const NAV_HINTS_DO_NOT_SHOW_AGAIN_KEY = 'hide-threed-instructions';

export const ThreeDNavHintsContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState(
    !getItem(NAV_HINTS_DO_NOT_SHOW_AGAIN_KEY)
  );
  const [doNotShowHintsAgainChecked, setDoNotShowHintsAgainChecked] =
    useState(false);

  const closeNavigationalHints = useCallback(() => {
    if (doNotShowHintsAgainChecked) {
      setItem(NAV_HINTS_DO_NOT_SHOW_AGAIN_KEY, true);
    }
    setIsVisible(false);
  }, [doNotShowHintsAgainChecked]);

  const contextValue = useMemo(
    () => ({
      isVisible,
      setIsVisible,
      doNotShowHintsAgainChecked,
      setDoNotShowHintsAgainChecked,
      closeNavigationalHints,
    }),
    [closeNavigationalHints, doNotShowHintsAgainChecked, isVisible]
  );

  return (
    <ThreeDNavHintsContext.Provider value={contextValue}>
      {children}
    </ThreeDNavHintsContext.Provider>
  );
};
