import type { Model3D } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';

import type { ThreeDModelIdentifier } from '../types';

export const use3dModelsQuery = <TData = Model3D>(
  models: ThreeDModelIdentifier[],
  options?: UseQueryOptions<Model3D[], unknown, TData>
) => {
  const { threeDService } = useFDMServices();

  return useQuery<Model3D[], unknown, TData>(
    [QueryKeys.THREE_D, models, 'all-models'],
    () => {
      return Promise.all(
        models.map((fullModel) =>
          threeDService.get3DModelById(fullModel.modelId)
        )
      );
    },
    {
      ...options,
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: '3d all-models',
          },
        }),
    }
  );
};
