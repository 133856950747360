import { AutoComplete } from '@cognite/cogs.js-v10';
import type { CogniteClient } from '@cognite/sdk';
import { useQuery } from '@tanstack/react-query';
import { type FC } from 'react';

type ViewSelectorProps = {
  sdk: CogniteClient;
  space?: string;
  onSpaceSelect: (nextSpace?: string) => void;
  onSpaceCreate: (nextSpace?: string) => void;
  placeholder?: string;
};

export const SpaceSelectOrCreator: FC<ViewSelectorProps> = ({
  sdk,
  space,
  onSpaceSelect,
  onSpaceCreate,
  placeholder,
}) => {
  const { data: spaces } = useQuery(['spaces'], () => {
    return sdk.spaces.list({
      includeGlobal: true,
      limit: 1000,
    });
  });

  return (
    <AutoComplete
      placeholder={placeholder || 'Select a space'}
      options={spaces?.items.map((space) => ({
        label: space.name || space.space,
        value: space.space,
      }))}
      value={space ? { label: space, value: space } : undefined}
      onChange={({ value }: { value: string }) => onSpaceSelect(value)}
      onCreateOption={onSpaceCreate}
    />
  );
};
