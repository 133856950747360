import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { getCluster } from '@cognite/cdf-utilities';
import { DatapointAggregate, DatapointsMultiQuery } from '@cognite/sdk';
import { useSDK } from '@cognite/sdk-provider';

import { DEFAULT_AGGREGATION_DATA_POINTS_LIMIT } from '../constants';
import { WorkflowResult } from '../models';
import { fetchCalculationQueryResult } from '../services';

export const DEFAULT_CALCULATION_QUERY_RESULT_STALE_TIME_MS = 10_000;

export const useCalculationQueryResult = (
  id: string | number,
  query: DatapointsMultiQuery,
  queryOpts?: UseQueryOptions<WorkflowResult>,
  aggregationDataPointLimit = DEFAULT_AGGREGATION_DATA_POINTS_LIMIT
) => {
  const sdk = useSDK();
  return useQuery<WorkflowResult>(
    ['calculation', 'response_query_v2', id, query],
    async () => {
      const aggregatedResult = await fetchCalculationQueryResult(
        sdk,
        String(id),
        query,
        getCluster
      );

      const aggregatedCount = (
        aggregatedResult.datapoints as DatapointAggregate[]
      ).reduce((point: number, c: DatapointAggregate) => {
        return point + (c.count || 0);
      }, 0);

      const isRaw =
        !aggregatedResult.isDownsampled &&
        aggregatedCount < aggregationDataPointLimit;

      const getRawResult = () =>
        fetchCalculationQueryResult(
          sdk,
          String(id),
          {
            ...query,
            granularity: undefined,
            aggregates: undefined,
            limit: aggregationDataPointLimit,
          },
          getCluster
        );

      return isRaw ? getRawResult() : aggregatedResult;
    },
    {
      retry: 1,
      retryDelay: 1000,
      enabled: !!id,
      staleTime: DEFAULT_CALCULATION_QUERY_RESULT_STALE_TIME_MS,
      ...queryOpts,
    }
  );
};
