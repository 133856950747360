import { Body, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const MetadataRow = styled(Flex).attrs({ gap: 4 })`
  padding: 8px;
`;

export const MetadataKey = styled(Body).attrs({
  level: 2,
  strong: true,
})`
  color: ${({ theme }) => theme['text-icon--strong']};
`;

export const MetadataValue = styled(Body).attrs({
  size: 'medium',
})`
  color: ${({ theme }) => theme['text-icon--medium']};
`;
