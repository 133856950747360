import {
  use3dModels,
  useCameraNavigation,
  useReveal,
} from '@cognite/reveal-react-components';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { useEffect, useState } from 'react';

// Zooms camera on passed asset externalId
export const useZoomToIdmAsset = (
  idmAssetExternalId?: string | null,
  space?: string
) => {
  // Will insure that clicking on the same asset will not trigger the zoom again
  // Will insure that clicking where there is no asset will not trigger the zoom
  const [lastZoomedToAsset, setLastZoomedToAsset] = useState<string | null>(
    null
  );

  const { fitCameraToInstance, fitCameraToAllModels } = useCameraNavigation();

  const { isIdm } = useAppConfigContext();

  const viewer = useReveal();
  const models = use3dModels();
  const model = models[0];

  const assetModelIdentifier = `${model?.revisionId}_${idmAssetExternalId}`;

  useEffect(() => {
    if (
      idmAssetExternalId &&
      space &&
      isIdm &&
      lastZoomedToAsset !== assetModelIdentifier
    ) {
      setLastZoomedToAsset(assetModelIdentifier);
      fitCameraToInstance(idmAssetExternalId, space).catch(() => {
        viewer.fitCameraToModel(model);
      });
    }
  }, [
    setLastZoomedToAsset,
    idmAssetExternalId,
    fitCameraToInstance,
    isIdm,
    fitCameraToAllModels,
    viewer,
    model,
    space,
    lastZoomedToAsset,
    assetModelIdentifier,
  ]);
};
