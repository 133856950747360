import {
  CogniteClient,
  ListOfSpaceExternalIdsRequestWithTyping,
  NodeAndEdgeCollectionResponseV3Response,
  ViewReference,
} from '@cognite/sdk-alpha';

// TODO: Would be nice if this function accepted a generic type
export function fetchInstance(
  sdk: CogniteClient,
  //Could not find any NodeReference type in sdk...
  nodeReference: {
    instanceType: 'node';
    space: string;
    externalId: string;
  },

  viewReference: ViewReference
) {
  const query: ListOfSpaceExternalIdsRequestWithTyping = {
    items: [nodeReference],
    sources: [
      {
        source: viewReference,
      },
    ],
  };

  // Handle error?
  // If time series does not exist, items = [];
  return sdk.instances
    .retrieve(query)
    .then((r: NodeAndEdgeCollectionResponseV3Response) => {
      return r.items[0];
    });
}
