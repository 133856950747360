import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { CogniteClient } from '@cognite/sdk';

import {
  FdmInstance,
  isFdmInstance,
  TimeseriesAcdmInstance,
} from '../../types';
import {
  getTimeseriesCdmDatapoints,
  TimeseriesCdmDatapoints,
} from '../../utils/timeseries/getTimeseriesCdmDatapoints';
import getTimeseriesChartData, {
  GetTimeseriesChartData,
} from '../../utils/timeseries/getTimeseriesChartData';

export function useTimeseriesData(
  instance: FdmInstance | TimeseriesAcdmInstance,
  sdk: CogniteClient,
  props: { startDate: Date; endDate: Date; numberOfPoints: number | undefined }
): UseQueryResult<TimeseriesCdmDatapoints | GetTimeseriesChartData, unknown> {
  const { startDate, endDate, numberOfPoints } = props;

  const query = useQuery(
    [
      'timeseries',
      instance,
      startDate.valueOf(),
      endDate.valueOf(),
      numberOfPoints,
    ],
    async () => {
      if (isFdmInstance(instance)) {
        const cdmTimeseries = await getTimeseriesCdmDatapoints(sdk, {
          query: {
            instanceId: instance.instanceExternalId,
            instanceSpace: instance.instanceSpace,
            dateRange: [startDate, endDate],
            numberOfPoints,
          },
        });
        return cdmTimeseries;
      }
      const acdmTimeSeries = await getTimeseriesChartData(sdk, {
        query: {
          timeseriesId: instance.id,
          dateRange: [startDate, endDate],
          numberOfPoints,
        },
      });
      return acdmTimeSeries;
    }
  );

  return query;
}
