import { Infobox } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { Trends } from '@infield/features/trends';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  assetExternalId?: string;
}

export const AssetTrendsView: FC<Props> = ({ assetExternalId }) => {
  const { assetId } = useParams();
  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);

  const asset = assetId || assetExternalId;

  if (!asset)
    return (
      <Infobox status="warning">
        {t('noValidAssetIdInURL', 'No valid assetId was passed in URL')}
      </Infobox>
    );

  return <Trends assetExternalId={asset} />;
};
