import { Body } from '@cognite/cogs.js-v10';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '@infield/utils/defaultDateFormats';
import dayjs from 'dayjs';
import type { FC } from 'react';

type Props = {
  date?: string;
  renderTimeInfo?: boolean;
};

export const DateCell: FC<Props> = ({
  date,
  renderTimeInfo = false,
}: Props) => {
  return (
    <div key={date}>
      <Body size="medium" strong>
        {date ? dayjs(date).format(DEFAULT_DATE_FORMAT) : '-'}
      </Body>
      {renderTimeInfo && (
        <Body size="small">
          {date ? dayjs(date).format(DEFAULT_TIME_FORMAT) : '-'}
        </Body>
      )}
    </div>
  );
};
