import type { InFieldUser } from '@cognite/apm-client';
import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { convertUserProfileToInFieldUser } from './utils/convert-user-profile-to-infield-user';

type UsersPage = {
  users: {
    items: InFieldUser[];
    pageInfo: { hasNextPage: boolean; endCursor?: string };
  };
};

export const useUsersInfiniteQuery = (pageSize?: number) => {
  const { client } = useAuthContext();

  const { data, ...rest } = useInfiniteQuery<UsersPage, Error>(
    [QueryKeys.USER, 'list', pageSize],
    async ({ pageParam = undefined }) => {
      const response = await client.profiles.list({
        limit: pageSize,
        cursor: pageParam,
      });

      const returnedUsers = convertUserProfileToInFieldUser(response.items);
      const endCursor = response.nextCursor;
      const hasNextPage = Boolean(endCursor);
      const pageInfo = { hasNextPage, endCursor };

      return { users: { items: returnedUsers, pageInfo } };
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: `${QueryKeys.USER} list`,
          },
        }),
      getNextPageParam: (lastPage) =>
        lastPage?.users.pageInfo.hasNextPage
          ? lastPage.users.pageInfo.endCursor
          : undefined,
    }
  );

  const users = data?.pages.flatMap((page) => page?.users.items) || [];

  return { users, ...rest };
};
