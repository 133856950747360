import { Heading } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ToggleRowHeading = styled(Heading).attrs({
  level: 6,
})<{
  $isUpdated: boolean;
}>`
  ${({ $isUpdated }) =>
    $isUpdated && 'color: var(--cogs-border--status-warning--strong)'}
`;
