const getOrPrependDomNodeToHost = (
  id: string,
  host: HTMLDivElement
): HTMLDivElement => {
  const existingNode = document.getElementById(id) as HTMLDivElement;
  if (existingNode !== null) {
    return existingNode;
  }

  const newNode = document.createElement('div');
  newNode.id = id;
  host.prepend(newNode);
  return newNode;
};

export default getOrPrependDomNodeToHost;
