import { Flex, Heading, Tabs } from '@cognite/cogs.js-v10';
import { AppConfigDocument, AppConfigFDM } from '@infield/features/app-config';
import { AppConfigRootLocations } from '@infield/features/app-config/app-config-root-locations';
import type { FC } from 'react';

import { AppConfigActivities } from './app-config-activities';
import { AppConfigAsset } from './app-config-asset';
import { AppConfigCopilot } from './app-config-copilot/app-config-copilot';
import { AppConfigDiscipline } from './app-config-discipline';
import { AppConfigNotifications } from './app-config-notifications';
import * as S from './elements';

interface Props {
  hasAppConfigPermission?: boolean;
}

export const AppConfigPage: FC<Props> = ({ hasAppConfigPermission }) => {
  const renderPermissionInfo = () => {
    if (hasAppConfigPermission) {
      return (
        <S.PermissionInfoContainer>
          <S.PermittedText>
            You are permitted to change configuration
          </S.PermittedText>
        </S.PermissionInfoContainer>
      );
    }
    return (
      <S.PermissionInfoContainer>
        <S.NotPermittedText>
          You dont have access to change configuration
        </S.NotPermittedText>
      </S.PermissionInfoContainer>
    );
  };

  return (
    <S.ConfigWrapper>
      <Flex justifyContent="space-between">
        <S.TitleContainer>
          <Heading level={5}>Application configuration</Heading>
        </S.TitleContainer>
        {renderPermissionInfo()}
      </Flex>
      <S.TabPanelWrapper>
        <Tabs defaultActiveKey="FDM">
          <S.TabPane tabKey="FDM" label="FDM">
            <AppConfigFDM
              hasAppConfigPermission={hasAppConfigPermission ?? false}
            />
          </S.TabPane>
          <S.TabPane tabKey="Asset" label="Asset">
            <AppConfigAsset
              hasAppConfigPermission={hasAppConfigPermission ?? false}
            />
          </S.TabPane>
          <S.TabPane tabKey="Documents" label="Documents">
            <AppConfigDocument
              hasAppConfigPermission={hasAppConfigPermission ?? false}
            />
          </S.TabPane>
          <S.TabPane tabKey="Activities" label="Activities">
            <AppConfigActivities
              hasAppConfigPermission={hasAppConfigPermission ?? false}
            />
          </S.TabPane>
          <S.TabPane tabKey="Notifications" label="Notifications">
            <AppConfigNotifications
              hasAppConfigPermission={hasAppConfigPermission ?? false}
            />
          </S.TabPane>
          <S.TabPane tabKey="Root Location" label="Root Location">
            <AppConfigRootLocations
              hasAppConfigPermission={hasAppConfigPermission ?? false}
            />
          </S.TabPane>
          <S.TabPane tabKey="Disciplines" label="Disciplines">
            <AppConfigDiscipline
              hasAppConfigPermission={hasAppConfigPermission ?? false}
            />
          </S.TabPane>
          <S.TabPane tabKey="Copilot" label="CoPilot">
            <AppConfigCopilot
              hasAppConfigPermission={hasAppConfigPermission ?? false}
            />
          </S.TabPane>
        </Tabs>
      </S.TabPanelWrapper>
    </S.ConfigWrapper>
  );
};
