import { z } from '@infield/utils/z';
import styled from 'styled-components';

interface Props {
  visible: boolean;
}

export const GlobalOverlay = styled.div<Props>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: ${z.OVERLAY};
`;
