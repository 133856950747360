import { Select } from '@cognite/cogs-lab';
import { Body, Flex } from '@cognite/cogs.js-v10';
import { TabContent } from '@cognite/user-profile-components';
import { useDisciplines } from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

export const DisciplineTabContent: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mainTopbar);

  const {
    configuredDisciplineOptions,
    selectedDiscipline,
    handleSaveDiscipline,
  } = useDisciplines();

  return (
    <TabContent.Container>
      <TabContent.Body>
        <Flex direction="column" gap={6}>
          <Body size="medium" strong>
            {t('selectDiscipline', 'Select discipline')}
          </Body>
          <Select
            placeholder={t('selectDiscipline', 'Select discipline')}
            fullWidth
            onChange={(_, value) =>
              value ? handleSaveDiscipline(value) : null
            }
            options={configuredDisciplineOptions}
            value={selectedDiscipline?.value}
          />
        </Flex>
      </TabContent.Body>
    </TabContent.Container>
  );
};
