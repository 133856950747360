import type { RootLocationFeatureToggles } from '@infield/features/app-config';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { type FC, useState } from 'react';

import * as S from './elements';
import { SidebarContent } from './sidebar-content';
import { SidebarHeader } from './sidebar-header';
import { SidebarUserActions } from './sidebar-user-actions';

type Props = {
  isCollapsed: boolean;
  isOnline: boolean;
  userName?: string;
  userLocation?: string;
  rootLocationFeatureToggles?: RootLocationFeatureToggles;
  currentAppLocation: string;
  isAkerbpCustomCode: boolean;
  onNavigationItemClick: (navigateTo: string) => void;
  onCloseButtonClick: () => void;
  onUserProfileClick: () => void;
  onUserLogout: () => void;
  onLocationClick: () => void;
};

export const SidebarUI: FC<Props> = ({
  isCollapsed,
  isOnline,
  userName,
  userLocation,
  rootLocationFeatureToggles,
  currentAppLocation,
  isAkerbpCustomCode,
  onNavigationItemClick,
  onCloseButtonClick,
  onUserProfileClick,
  onUserLogout,
  onLocationClick,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.sidebar);
  const [showHelpMenu, setShowHelpMenu] = useState(false);

  return (
    <S.StyledSidebarContainer $isCollapsed={isCollapsed}>
      <SidebarHeader
        showHelpMenu={showHelpMenu}
        setShowHelpMenu={setShowHelpMenu}
        onCloseButtonClick={onCloseButtonClick}
      />
      {!showHelpMenu && (
        <>
          <S.LocationSelector disabled={!isOnline} onClick={onLocationClick}>
            {userLocation ||
              t('SIDEBAR_NO_LOCATION_SELECTED_MESSAGE', 'No location selected')}
          </S.LocationSelector>
          <SidebarContent
            rootLocationFeatureToggles={rootLocationFeatureToggles}
            isOnline={isOnline}
            currentAppLocation={currentAppLocation}
            onNavigationItemClick={onNavigationItemClick}
          />
        </>
      )}
      <SidebarUserActions
        isOnline={isOnline}
        userName={userName}
        showHelpMenu={showHelpMenu}
        isAkerbpCustomCode={isAkerbpCustomCode}
        onUserProfileClick={onUserProfileClick}
        onUserLogout={onUserLogout}
        setShowHelpMenu={setShowHelpMenu}
      />
    </S.StyledSidebarContainer>
  );
};
