import { getChipStatus } from '@cognite/apm-observation';
import {
  Avatar,
  Body,
  Divider,
  Heading,
  Skeleton,
  StatusChip,
  TagChip,
  Tooltip,
} from '@cognite/cogs.js-v10';
import { useChecklistItemObservations } from '@infield/features/checklist';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { OpenedObservationDetails } from '@infield/features/observation';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '@infield/utils/defaultDateFormats';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { Fragment } from 'react';

import * as S from './elements';

type Props = {
  checklistItemExternalId?: string;
  onCancel?: () => void;
  onOpenObservation: (observationDetails: OpenedObservationDetails) => void;
};

export const ChecklistItemObservationList: FC<Props> = ({
  checklistItemExternalId,
  onCancel,
  onOpenObservation,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);
  const {
    data: checklistItems,
    isInitialLoading: isLoadingChecklistItemObservations,
  } = useChecklistItemObservations({
    checklistItemExternalId,
  });
  const checklistItem = checklistItems && checklistItems[0];

  const observations = checklistItem?.observations;
  const asset = checklistItem?.asset;

  if (isLoadingChecklistItemObservations) {
    return <Skeleton.List lines={4} borders />;
  }

  return (
    <>
      <S.HeaderWrappper>
        <Heading level={5}>
          {t('CHECKLIST_ITEM_OBSERVATIONS_LIST_TITLE', 'All observations')}
        </Heading>
        {asset && <TagChip label={asset.title || ''} size="x-small" />}
      </S.HeaderWrappper>
      <Divider weight="1px" />
      <S.ObservationsContainer>
        {observations?.map((observation) => {
          const files = observation.files?.filter(
            (file) => file && file.externalId
          );
          const imageThumbnail =
            files && files.length > 0 && files[0]?.downloadLink?.downloadUrl;
          const createdByEmail = observation.createdBy?.email;
          return (
            <Fragment key={observation.externalId}>
              <S.ObservationWrapper
                gap={8}
                key={observation.externalId}
                onClick={() =>
                  onOpenObservation({
                    observationExternalId: observation.externalId,
                  })
                }
              >
                <div>
                  {imageThumbnail && (
                    <Avatar size="large" image={imageThumbnail} />
                  )}
                </div>
                <S.CreationInfo direction="column">
                  <Tooltip content={createdByEmail} interactive>
                    <S.CreatedByBody size="small">
                      {createdByEmail}
                    </S.CreatedByBody>
                  </Tooltip>
                  <Body size="small">
                    {dayjs(observation.createdTime).format(DEFAULT_DATE_FORMAT)}{' '}
                    –{' '}
                    {dayjs(observation.createdTime).format(DEFAULT_TIME_FORMAT)}
                  </Body>
                </S.CreationInfo>
                <StatusChip
                  size="small"
                  label={t(
                    `OBSERVATIONS_DATA_GRID_STATUS_${observation.status?.toUpperCase()}`,
                    { defaultValue: observation.status }
                  )}
                  status={getChipStatus(observation.status)}
                  variant="outline"
                />
              </S.ObservationWrapper>
              <Divider weight="1px" />
            </Fragment>
          );
        })}
      </S.ObservationsContainer>
      <S.Footer>
        <S.FooterButton onClick={onCancel}>
          {t('CHECKLIST_ITEM_OBSERVATIONS_LIST_FOOTER_BUTTON_CANCEL', 'Cancel')}
        </S.FooterButton>
        <S.FooterButton
          onClick={() =>
            onOpenObservation({
              checklistItemExternalId,
              asset: asset ?? undefined,
            })
          }
          type="primary"
        >
          {t(
            'CHECKLIST_ITEM_OBSERVATIONS_LIST_FOOTER_BUTTON_CREATE_NEW_OBSERVATION',
            'Create new'
          )}
        </S.FooterButton>
      </S.Footer>
    </>
  );
};
