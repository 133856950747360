import type { ChecklistItemStatus } from '@cognite/apm-client';
import { ChevronRightIcon, Menu } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { DismissableContent } from '@infield/features/ui/dismissableContent';
import type { FC } from 'react';
import { useState } from 'react';

import { ChecklistStatusMenu } from '../checklist-status-menu';

import * as S from './elements';

interface Props {
  onClose: () => void;
  onChangeStatus: (status: ChecklistItemStatus) => void;
  disabled?: boolean;
}

export const ChecklistGroupMenu: FC<Props> = ({
  onClose,
  onChangeStatus,
  disabled = false,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);

  const [statusMenu, setStatusMenu] = useState(false);

  if (statusMenu)
    return (
      <DismissableContent onDismiss={onClose}>
        <ChecklistStatusMenu
          status=""
          showAll
          onClose={onClose}
          onStatusClick={onChangeStatus}
        />
      </DismissableContent>
    );

  return (
    <DismissableContent onDismiss={onClose}>
      <S.StatusMenu>
        <Menu.Item
          data-testid="checklist-items-group-set-remaining-button"
          icon={<ChevronRightIcon />}
          iconPlacement="right"
          onClick={() => setStatusMenu(true)}
          disabled={disabled}
          description={
            disabled
              ? t(
                  'PERFORM_CHECKLIST_GROUP_CHANGE_REMAINING_TASKS_STATUS_DISABLED_DESCRIPTION',
                  'All statuses in the group are set'
                )
              : undefined
          }
        >
          {t(
            'PERFORM_CHECKLIST_GROUP_CHANGE_REMAINING_TASKS_STATUS',
            'Set remaining tasks to'
          )}
        </Menu.Item>
      </S.StatusMenu>
    </DismissableContent>
  );
};
