import type { Template, TemplateStatus } from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client';
import { useMetrics } from '@cognite/metrics';
import { useSelectedRootLocationConfiguration } from '@infield/features/app-config';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { handleTemplateStatusFilter } from '@infield/features/template/utils';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

type Props = {
  query: string;
  filter?: Filters;
  properties?: string[];
  enabled: boolean;
  limit: number;
  templateStatus?: TemplateStatus;
};

export const useTemplatesSearch = ({
  query,
  filter,
  properties,
  enabled = true,
  limit = 1000,
  templateStatus,
}: Props) => {
  const { apmClient } = useFDMServices();
  const { template: configFilters } = useAppConfigFiltersContext();
  const selectedRootLocationConfig = useSelectedRootLocationConfiguration();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);
  const templateFilters: Filters[] = [];

  const { filters, showArchived } = handleTemplateStatusFilter(templateStatus);
  templateFilters.push(...filters);

  if (configFilters.rootAssetExternalIds && selectedRootLocationConfig) {
    templateFilters.push(configFilters.rootAssetExternalIds);
  }

  if (filter) {
    templateFilters.push(filter);
  }

  const sliTimerStartTime = useRef<number>();

  return useQuery<Template[], Error>(
    [QueryKeys.SEARCH_TEMPLATES, query, templateFilters, properties],
    async () => {
      sliTimerStartTime.current = Date.now();

      return apmClient.templates.dmsSearch<Template>(
        query,
        'node',
        {
          and: templateFilters,
        },
        limit,
        properties,
        showArchived
      );
    },
    {
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-templates-search',
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.SEARCH_TEMPLATES, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.SEARCH_TEMPLATES, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      enabled:
        Boolean(configFilters.rootAssetExternalIds) &&
        Boolean(selectedRootLocationConfig) &&
        enabled,
    }
  );
};
