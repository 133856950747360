import {
  Button,
  CloseIcon,
  EllipsisHorizontalIcon,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import {
  type FC,
  type PropsWithChildren,
  type ReactNode,
  useState,
} from 'react';

import * as S from './elements';

type Props = {
  title?: string | JSX.Element;
  description?: string;
  titleOverflow?: boolean;
  onTitleClick?: () => void;
  onOptionClick?: () => void;
  onCloseClick?: () => void;
  childrenPosition?: 'start' | 'end';
  children?: ReactNode;
  actionBeforeTitle?: ReactNode;
  titleTestId?: string;
  closeButtonTestId?: string;
};

export const PanelHeader: FC<PropsWithChildren<Props>> = ({
  title,
  description,
  titleOverflow,
  onTitleClick,
  onOptionClick,
  onCloseClick,
  childrenPosition,
  children,
  titleTestId,
  closeButtonTestId,
  actionBeforeTitle,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const isTitleClickable = onTitleClick !== undefined;
  const isOptionVisible = onOptionClick !== undefined;
  const isCloseVisible = onCloseClick !== undefined;
  const [expandOverflow, setExpandOverflow] = useState(false);

  return (
    <S.Container $expandOverflow={expandOverflow}>
      <S.TitleAndSegmentedControlWrapper>
        {actionBeforeTitle}
        {(title || description) && (
          <S.TitleContainer>
            {title && (
              <S.Title
                data-testid={titleTestId}
                level={5}
                onClick={() => {
                  onTitleClick?.();
                  setExpandOverflow(titleOverflow ? !expandOverflow : false);
                }}
                $overflow={titleOverflow}
                $expandOverflow={expandOverflow}
                $clickable={isTitleClickable}
              >
                {title}
              </S.Title>
            )}

            {description && (
              <S.Description size="x-small">{description}</S.Description>
            )}
          </S.TitleContainer>
        )}
      </S.TitleAndSegmentedControlWrapper>
      <S.PanelActionsWrapper $childrenPosition={childrenPosition}>
        {isOptionVisible && (
          <Button
            icon={<EllipsisHorizontalIcon />}
            type="ghost"
            aria-label="show-options"
            onClick={onOptionClick}
          />
        )}
        {isCloseVisible && (
          <Button
            data-testid={closeButtonTestId}
            icon={<CloseIcon />}
            type="ghost"
            onClick={onCloseClick}
            aria-label={t('TEMPLATE_TASK_FORM_PANEL_INFO_CLOSE', 'Close')}
          />
        )}
        {children}
      </S.PanelActionsWrapper>
    </S.Container>
  );
};
