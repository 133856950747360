import { Menu } from '@cognite/cogs-lab';
import { Avatar, Flex, SearchIcon, SettingsIcon } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const LogoContainer = styled(Flex)`
  gap: 20px;
`;

export const TopBarSettingsIcon = styled(SettingsIcon)<{ disabled?: boolean }>`
  color: ${({ disabled }) =>
    disabled ? 'var(--cogs-text-icon--interactive--disabled)' : 'auto'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const TopBarSearchIcon = styled(SearchIcon)<{ disabled?: boolean }>`
  color: ${({ disabled }) =>
    disabled ? 'var(--cogs-text-icon--interactive--disabled)' : 'auto'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const TopBarAvatar = styled(Avatar)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled }) =>
    disabled &&
    `background-color: var(--cogs-surface--interactive--disabled--alt);`}

  &:not(.cogs-avatar--static):hover {
    ${({ disabled }) =>
      disabled &&
      `background-color: var(--cogs-surface--interactive--disabled--alt);`}
  }
`;

export const MenuItemAction = styled(Menu.ItemAction)`
  height: 36px;
  border-radius: 6px;

  .cogs-lab-menu__item__icon {
    display: none;
  }
`;
