import Konva from 'konva';

const createEditableTextFromNode = (
  node: Konva.Label,
  scaleFactor: number
): HTMLDivElement => {
  const editableText = document.createElement('div');
  const textNode = node.getText();
  const textNodeClientRect = textNode.getClientRect();

  editableText.contentEditable = 'true';
  editableText.innerHTML = textNode.text().replace(/(\r\n|\n|\r)/gm, '<br />');
  editableText.style.fontSize = `${textNode.fontSize() * scaleFactor}` + 'px';
  editableText.style.overflow = 'hidden';
  editableText.style.outline = 'none';
  editableText.style.resize = 'none';
  editableText.style.lineHeight = `${textNode.lineHeight()}`;
  editableText.style.fontFamily = textNode.fontFamily();
  editableText.style.textAlign = textNode.align();
  editableText.style.color = textNode.fill();
  editableText.style.overflowWrap = 'break-word';

  // minWidth and minHeight are here to always display the cursor, even when there's no content
  editableText.style.minWidth = '1em';
  editableText.style.minHeight = '1em';
  editableText.style.maxWidth = textNodeClientRect.width + 'px';
  editableText.style.maxHeight = textNodeClientRect.height + 'px';
  return editableText;
};

export default createEditableTextFromNode;
