import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import type { ImageToUpload } from '@infield/features/media/types';
import type { FC } from 'react';
import { useState } from 'react';

import * as S from './elements';
import { MediaItem } from './media-item';
import { MediaViewer } from './media-viewer';

type Props = {
  fileInstanceIds?: InstanceOrExternalId[];
};

export const MultipleMediaContent: FC<Props> = ({ fileInstanceIds }) => {
  const [selectedMedia, setSelectedMedia] = useState<ImageToUpload>();

  if (selectedMedia) {
    return <MediaItem isFullScreen media={selectedMedia} />;
  }

  return (
    <S.MediaGridWrapper>
      {fileInstanceIds?.map((file) => {
        return (
          <MediaViewer
            key={file.externalId}
            fileInstanceId={file}
            setSelectedMedia={setSelectedMedia}
          />
        );
      })}
    </S.MediaGridWrapper>
  );
};
