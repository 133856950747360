import { Button, Menu } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

export const ColorMenu = styled(Menu)`
  z-index: ${z.ACTION_MENU};
  width: 100%;
`;

export const FullWidthButton = styled(Button)`
  width: 100%;
`;
