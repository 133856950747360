import { Body, FilterChip, Flex, Select } from '@cognite/cogs.js-v10';
import { getIsNewConfigVersion } from '@infield/features/app-config/utils/utils';
import { ComponentContainer } from '@infield/features/ui';
import isEqual from 'lodash/isEqual';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { useAppConfigQuery, useGroupsQuery } from '../../../hooks';
import { useUpsertAppConfigMutation } from '../../../hooks/use-mutation';
import type { AppConfig } from '../../../types';

import * as S from './elements';

interface Props {
  hasAppConfigPermission: boolean;
  rootLocationExternalId: string;
}

export const RootLocationTemplateChecklistAdmins: FC<Props> = ({
  hasAppConfigPermission,
  rootLocationExternalId,
}) => {
  const [templateAdminGroups, setTemplateAdminGroups] = useState<string[]>();
  const [checklistAdminGroups, setChecklistAdminGroups] = useState<string[]>();
  const { data: appConfig, isSuccess, isLoading } = useAppConfigQuery();
  const isNewConfigVersion = getIsNewConfigVersion(appConfig);
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();
  const configuredRootLocation =
    appConfig?.featureConfiguration?.rootLocationConfigurations?.find(
      (rootLocation) =>
        rootLocationExternalId ===
        (isNewConfigVersion
          ? rootLocation.externalId
          : rootLocation.assetExternalId)
    );
  const { data: groups } = useGroupsQuery();

  const templateAdminOptions = groups
    ?.filter((group) => !templateAdminGroups?.includes(group.name))
    .map((group) => ({
      label: group.name,
      value: group.name,
    }));

  const checklistAdminOptions = groups
    ?.filter((group) => !checklistAdminGroups?.includes(group.name))
    .map((group) => ({
      label: group.name,
      value: group.name,
    }));

  useEffect(() => {
    if (isSuccess && configuredRootLocation) {
      if (configuredRootLocation.templateAdmins) {
        setTemplateAdminGroups(configuredRootLocation.templateAdmins);
      }
      if (configuredRootLocation.checklistAdmins) {
        setChecklistAdminGroups(configuredRootLocation.checklistAdmins);
      }
    }
  }, [isSuccess, configuredRootLocation, appConfig]);

  const hasConfigChanged =
    !isEqual(templateAdminGroups, configuredRootLocation?.templateAdmins) ||
    !isEqual(checklistAdminGroups, configuredRootLocation?.checklistAdmins);

  const handleSave = async () => {
    if (!appConfig) return;

    const newAppConfig: AppConfig = {
      ...appConfig,
      featureConfiguration: {
        ...appConfig.featureConfiguration,
        rootLocationConfigurations: [
          ...(appConfig.featureConfiguration?.rootLocationConfigurations?.map(
            (rootConfig) => {
              if (
                isNewConfigVersion
                  ? configuredRootLocation?.externalId === rootConfig.externalId
                  : configuredRootLocation?.assetExternalId ===
                    rootConfig.assetExternalId
              ) {
                return {
                  ...rootConfig,
                  templateAdmins: templateAdminGroups,
                  checklistAdmins: checklistAdminGroups,
                };
              }
              return { ...rootConfig };
            }
          ) || []),
        ],
      },
    };

    await upsertAppConfig({
      newAppConfig,
    });
  };

  const templateAdminConfigs = (
    <Flex direction="column" gap={24}>
      <Body size="x-small">
        Restrict who has access to create / edit templates and mark checklists
        as done to a set of users by referring to CDF group names that holds the
        users who shall have access. If no group is configured, everyone will
        have access.
      </Body>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Template admins
          </Body>
          <Select
            disabled={!hasAppConfigPermission}
            options={templateAdminOptions ?? []}
            onChange={(e: { label: string; value: string }) =>
              setTemplateAdminGroups((prev) => [...(prev ?? []), e.value])
            }
            width={500}
            placeholderElement={<div>Search and select group</div>}
          />
          <Flex gap={8}>
            {templateAdminGroups?.map((group) => (
              <FilterChip
                key={group}
                label={group}
                onRemove={
                  !hasAppConfigPermission
                    ? undefined
                    : () =>
                        setTemplateAdminGroups((prev) =>
                          prev?.filter((prevGroup) => prevGroup !== group)
                        )
                }
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Checklist admins
          </Body>
          <Select
            disabled={!hasAppConfigPermission}
            options={checklistAdminOptions ?? []}
            onChange={(e: { label: string; value: string }) =>
              setChecklistAdminGroups((prev) => [...(prev ?? []), e.value])
            }
            width={500}
            placeholderElement={<div>Search and select group</div>}
          />
          <Flex gap={8}>
            {checklistAdminGroups?.map((group) => (
              <FilterChip
                key={group}
                label={group}
                onRemove={
                  !hasAppConfigPermission
                    ? undefined
                    : () =>
                        setChecklistAdminGroups((prev) =>
                          prev?.filter((prevGroup) => prevGroup !== group)
                        )
                }
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );

  const saveButton = (
    <Flex direction="column" gap={4}>
      <S.SaveButton
        onClick={handleSave}
        disabled={!hasConfigChanged}
        loading={isLoading}
      >
        Save configuration
      </S.SaveButton>
    </Flex>
  );

  return (
    <ComponentContainer>
      <S.Wrapper>
        <Flex direction="column" gap={16}>
          {templateAdminConfigs}
          {saveButton}
        </Flex>
      </S.Wrapper>
    </ComponentContainer>
  );
};
