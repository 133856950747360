import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import type { Filters } from '@cognite/fdm-client';
import { useSelectedRootAsset } from '@infield/features/asset';
import { useFDMServices } from '@infield/providers/fdm-services';
import { limitConcurrency } from '@infield/utils/limit-concurrency';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';

export const useActivitiesAggregateFilterOptions = (
  fields: string[],
  filter?: Filters
) => {
  const { activityService } = useFDMServices();

  const { data: rootAsset } = useSelectedRootAsset();

  const filters: Filters[] = [];
  if (rootAsset?.externalId) {
    filters.push({
      equals: {
        property: 'rootLocation',
        eq: rootAsset.externalId,
      },
    });
  }
  if (filter) {
    filters.push(filter);
  }

  return useQuery<Record<string, GridFilterOptionsType[]>>(
    [QueryKeys.MAINTENANCE_AGGREGATE_FILTER_OPTIONS, filters, fields],
    async () => {
      const results = await limitConcurrency(
        fields.map((field) => {
          return () =>
            activityService.aggregateActivityByField(field, {
              and: filters,
            });
        })
      );

      return results.reduce((acc, curr, index) => {
        return {
          ...acc,
          [fields[index]]: curr.map((item) => ({
            label: item.value,
            value: item.value,
            count: item.count,
          })),
        };
      }, {} as Record<string, GridFilterOptionsType[]>);
    },
    {
      onError: (err) => {
        captureException(err, {
          level: 'warning',
          tags: {
            queryKey: QueryKeys.MAINTENANCE_AGGREGATE_FILTER_OPTIONS,
          },
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: fields.length > 0 && Boolean(rootAsset?.externalId),
    }
  );
};
