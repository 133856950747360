import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import type { ImageToUpload } from '../types';

export const useFetchMedia = (externalId: string, space?: string) => {
  const { mediaService } = useFDMServices();

  return useQuery<ImageToUpload>(
    [QueryKeys.MEDIA_ITEM, externalId, space],
    async () => {
      return mediaService.getMediaByFileExternalId(externalId, space);
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.MEDIA_ITEM,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
