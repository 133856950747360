import { Menu } from '@cognite/cogs-lab';
import {
  ConstrainProportionsOffIcon,
  EditIcon,
  EllipsisHorizontalIcon,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { ExtraActionsButton } from '../elements';

interface Props {
  onClickUnGroup: () => void;
  onClickRename: () => void;
}

export const GroupMenu: FC<Props> = ({ onClickUnGroup, onClickRename }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);

  return (
    <Menu
      renderTrigger={(props) => (
        <ExtraActionsButton
          {...props}
          type="ghost"
          size="x-large"
          icon={<EllipsisHorizontalIcon />}
          aria-label={t('TASKS_GROUP_MENU_BUTTON_OPEN_MENU', 'Open menu')}
          data-testid="extra-actions-button"
        />
      )}
      size="medium"
    >
      <Menu.ItemAction
        label={t('TASKS_GROUP_MENU_OPTION_UNGROUP', 'Ungroup')}
        icon={<ConstrainProportionsOffIcon />}
        onClick={onClickUnGroup}
        data-testid="ungroup-menu-item"
      />
      <Menu.ItemAction
        label={t('TASKS_GROUP_MENU_OPTION_RENAME', 'Rename')}
        icon={<EditIcon />}
        onClick={onClickRename}
        data-testid="rename-menu-item"
      />
    </Menu>
  );
};
