import type { DoubleDatapoint } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useTrendsLatestDatapoint = (timeSeriesExternalId: string) => {
  const { timeseriesService } = useFDMServices();

  return useQuery<DoubleDatapoint | null>(
    [QueryKeys.TRENDS, timeSeriesExternalId],
    async () => {
      const [datapoint] = await timeseriesService.getTimeseriesLatestDatapoints(
        [timeSeriesExternalId]
      );

      const datapoints = datapoint?.datapoints?.[0];

      if (!datapoints) {
        return null;
      }
      return datapoints as DoubleDatapoint;
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.TRENDS,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
