import { Button, Flex } from '@cognite/cogs.js-v10';
import { ComponentContainer } from '@infield/features/ui';
import styled from 'styled-components';

export const ScrollableContainer = styled(ComponentContainer)`
  &&& {
    overflow: auto;
    height: calc(100vh - 280px);
  }
`;

export const Wrapper = styled.div`
  padding: 16px;
`;

export const Card = styled(Flex).attrs({
  direction: 'column',
  gap: 16,
})`
  width: 420px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(0, 0, 0, 0.2);
  padding: 16px;
`;

export const Add3dModelButton = styled(Button)`
  width: 200px;
`;

export const SaveButton = styled(Button)`
  width: 200px;
`;
