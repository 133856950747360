import { FlowExportObject } from 'react-flow-renderer';

import { Operation } from '@cognite/calculation-backend';
import { Connection, Node } from '@cognite/connect';
import { EventFilter } from '@cognite/sdk';

import { ScheduledCalculationTask } from './models';

export type LineStyle = 'none' | 'solid' | 'dashed' | 'dotted';
export type Interpolation = 'linear' | 'hv';
type AxisRangeValue = number | null;
/**
 * AxisRange can be either [number, number], specifying the lower and upper bounds of the axis,
 * or either number can be replaced by null such as [number, null] or [null, number]. The meaning
 * of this is that the null direction of the range will be autofitted. If both elements are null
 * [null, null], then the entire axis will be fit around the datapoints currently rendered.
 */
export type AxisRange = [AxisRangeValue, AxisRangeValue];
export type ChartTimeSeries = {
  type: 'timeseries';
  id: string;
  name: string;
  color: string;
  tsId: number;
  tsExternalId?: string;
  lineWeight?: number;
  lineStyle?: LineStyle;
  interpolation?: Interpolation;
  displayMode?: 'lines' | 'markers';
  enabled: boolean;
  unit?: string;
  originalUnit?: string;
  preferredUnit?: string;
  customUnitLabel?: string;
  description?: string;
  range?: AxisRange;
  statisticsCalls?: StatisticsCallRef[];
  dataProfilingCalls?: dataProfilingCallRef[];
  createdAt: number;
};

// decorated will be used in UI created by decorating coreTimeseries coming from DB and properties data from FDM
export type DecoratedCoreTimeseries = CoreTimeseries & {
  name: string;
  description?: string;
  type: string;
  isStep: boolean;
  assets?: {
    space: string;
    externalId: string;
  }[];
  unitReference: {
    space: string;
    externalId: string;
  };
  sourceUnit?: string;
  // timestamps like this 1722496484510
  instanceCreatedTime: number;
  instanceLastUpdatedTime: number;
};

export type CoreTimeseries = {
  type: 'coreTimeseries';
  id: string;
  color: string;
  nodeReference: {
    space: string;
    externalId: string;
  };
  viewReference: {
    space: string;
    externalId: string;
    version: string;
  };
  name?: string;
  lineWeight?: number;
  lineStyle?: LineStyle;
  interpolation?: Interpolation;
  displayMode?: 'lines' | 'markers';
  enabled: boolean;
  preferredUnit?: string;
  unit?: string;
  customUnitLabel?: string;
  range?: AxisRange;
  statisticsCalls?: StatisticsCallRef[];
  dataProfilingCalls?: dataProfilingCallRef[];
  createdAt: number;
};

type ChartWorkflowBase = {
  type: 'workflow';
  id: string;
  name: string;
  lineWeight?: number;
  lineStyle?: LineStyle;
  interpolation?: Interpolation;
  displayMode?: 'lines' | 'markers';
  unit?: string;
  preferredUnit?: string;
  customUnitLabel?: string;
  color: string;
  enabled: boolean;
  range?: AxisRange;
  calls?: CalculationCallRef[];
  statisticsCalls?: StatisticsCallRef[];
  dataProfilingCalls?: dataProfilingCallRef[];
  createdAt?: number;
  attachTo?: string;
};

export enum NodeTypes {
  SOURCE = 'CalculationInput',
  FUNCTION = 'ToolboxFunction',
  COMMENT = 'Comment',
  CONSTANT = 'Constant',
  OUTPUT = 'CalculationOutput',
}

export type SourceOption = {
  type: 'timeseries' | 'coreTimeseries' | 'workflow' | 'scheduledCalculation';
  color: string;
  label: string;
  value: string;
};

/**
 * Format used for @cognite/connect
 */
type ChartWorkflowV1 = ChartWorkflowBase & {
  version: '' | undefined;
  nodes?: StorableNode[];
  connections?: Record<string, Connection>;
};
export type CommentNodeDataDehydrated = {
  value: string;
};
export type CommentNodeCallbacks = {
  onCommentChange: (nodeId: string, newValue: string) => void;
  onDuplicateNode: (nodeId: string, nodeType: NodeTypes) => void;
  onRemoveNode: (nodeId: string) => void;
};
export type CommentNodeData = CommentNodeDataDehydrated &
  CommentNodeCallbacks & {
    readOnly: boolean;
  };

export type ConstantNodeDataDehydrated = {
  name?: string;
  value: number;
};
export type ConstantNodeCallbacks = {
  onConstantChange: (nodeId: string, name: string, value: number) => void;
  onDuplicateNode: (nodeId: string, nodeType: NodeTypes) => void;
  onRemoveNode: (nodeId: string) => void;
};
export type ConstantNodeData = ConstantNodeDataDehydrated &
  ConstantNodeCallbacks & {
    readOnly: boolean;
  };

export type FunctionNodeDataDehydrated = {
  selectedOperation: { op: string; version: string };
  parameterValues: { [key: string]: string | number | number[] | boolean };
};
export type FunctionNodeCallbacks = {
  onParameterValuesChange: (
    nodeId: string,
    formData: { [key: string]: any }
  ) => void;
  onDuplicateNode: (nodeId: string, nodeType: NodeTypes) => void;
  onRemoveNode: (nodeId: string) => void;
};
export type FunctionNodeData = FunctionNodeDataDehydrated &
  FunctionNodeCallbacks & {
    operation?: Operation;
    readOnly: boolean;
  };

export type OutputNodeDataDehydrated = object;
export type OutputNodeCallbacks = {
  onOutputNameChange: (newName: string) => void;
};
export type OutputNodeData = OutputNodeDataDehydrated &
  OutputNodeCallbacks & {
    color: string;
    name: string;
    readOnly: boolean;
  };

export type SourceNodeDataDehydrated = {
  selectedSourceId: string;
  type?: string;
};
export type SourceNodeCallbacks = {
  onSourceItemChange: (
    nodeId: string,
    selectedItemId: string,
    selectedItemType: string
  ) => void;
  onDuplicateNode: (nodeId: string, nodeType: NodeTypes) => void;
  onRemoveNode: (nodeId: string) => void;
};
export type SourceNodeData = SourceNodeDataDehydrated &
  SourceNodeCallbacks & {
    sourceOptions: SourceOption[];
    readOnly: boolean;
  };

/**
 * Format used in the view
 */
export type NodeDataVariants =
  | SourceNodeData
  | ConstantNodeData
  | CommentNodeData
  | FunctionNodeData
  | OutputNodeData;

/**
 * Node callback types
 */
export type NodeCallbacks = SourceNodeCallbacks &
  CommentNodeCallbacks &
  ConstantNodeCallbacks &
  FunctionNodeCallbacks &
  OutputNodeCallbacks;
/**
 * Format stored in the data layer
 */
export type NodeDataDehydratedVariants =
  | SourceNodeDataDehydrated
  | CommentNodeDataDehydrated
  | ConstantNodeDataDehydrated
  | FunctionNodeDataDehydrated
  | OutputNodeDataDehydrated;
/**
 * Format used for react-flow
 */
export type ChartWorkflowV2 = ChartWorkflowBase & {
  version: 'v2';
  flow?: FlowExportObject<NodeDataDehydratedVariants>;
  settings: {
    autoAlign: boolean;
  };
};

export type ScheduledCalculation = Omit<
  ChartWorkflowV2,
  'type' | 'calls' | 'dataProfilingCalls'
> & {
  type: 'scheduledCalculation';
  description: ScheduledCalculationTask['description'];
};

export type ChartWorkflow = ChartWorkflowV1 | ChartWorkflowV2;
export type StorableNode = Omit<Node, 'functionEffect'> & {
  functionEffectReference?: string;
};
export type ChartSource =
  | ChartTimeSeries
  | ChartWorkflow
  | ScheduledCalculation
  | CoreTimeseries;

type CalculationCallRef = {
  id: string;
  status: string;
  callId: string;
  callDate: number;
  hash?: number;
  computation?: string;
};
type StatisticsCallRef = {
  callId: string;
  callDate: number;
  hash?: number;
};
type dataProfilingCallRef = {
  callId: string;
  callDate: number;
  hash?: number;
};
type ThresholdCallRef = {
  callId: string;
  callDate: number;
  hash?: number;
};
export type ChartThresholdEventFilter = {
  minValue?: number;
  maxValue?: number;
  minUnit?: string;
  maxUnit?: string;
};
export type ChartThreshold = {
  id: string;
  name: string;
  visible: boolean;
  sourceId?: ChartTimeSeries['id'] | ChartWorkflow['id'] | CoreTimeseries['id'];
  upperLimit?: number;
  lowerLimit?: number;
  type: 'under' | 'over' | 'between';
  calls?: ThresholdCallRef[];
  filter: ChartThresholdEventFilter;
  addedBy?: 'alertSidebar' | 'monitoringSidebar';
  color?: string;
};
export type VerticalMarker = {
  timestamp: number;
};
export type SourceType = ChartSource['type'];

export type SourceCollectionData = {
  type: SourceType;
  id: string;
};
export type CollectionType =
  | 'timeSeriesCollection'
  | 'workflowCollection'
  | 'scheduledCalculationCollection'
  | 'coreTimeseriesCollection';
export type UserInfo = {
  id: string;
  email?: string | null;
  displayName?: string | null;
  mail?: string;
};
export type UserProfile = {
  userIdentifier: string;
  lastUpdatedTime: number;
  givenName?: string;
  surname?: string;
  email?: string;
  displayName?: string;
  jobTitle?: string;
  pictureUrl?: string;
};
export type ChartEventFilters = {
  id: string;
  name: string;
  visible: boolean;
  filters: EventFilter;
  color?: string;
};
export type Chart = {
  version: number;
  id: string;
  name: string;
  user: string;
  userInfo?: UserInfo;
  // could be undefined for old charts
  createdAt: number;
  updatedAt: number;
  timeSeriesCollection?: ChartTimeSeries[];
  coreTimeseriesCollection?: CoreTimeseries[];
  workflowCollection?: ChartWorkflow[];
  // TODO(DEGR-2403): Remove sourceCollection
  // sourceCollection is complete garbage - its a merge copy of timeseries and workflow.
  // Either remove the sourceCollection or remove timeSeriesCollection and workflowCollection
  sourceCollection?: SourceCollectionData[];
  thresholdCollection?: ChartThreshold[];
  verticalMarkerCollection?: VerticalMarker[];
  scheduledCalculationCollection?: ScheduledCalculation[];
  dateFrom: string;
  dateTo: string;
  public?: boolean;
  dirty?: boolean;
  liveMode?: boolean;
  settings?: ChartSettings;
  eventFilters?: ChartEventFilters[];
  monitoringJobs?: ChartsMonitoringJob[];
};
type ChartSettings = {
  showYAxis?: boolean;
  showMinMax?: boolean;
  showGridlines?: boolean;
  mergeUnits?: boolean;
  autoAlign?: boolean;
};
export type ChartsMonitoringJob = {
  id: number;
  sourceType: SourceType;
  sourceId: string;
};
