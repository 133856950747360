import { Menu } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

export const StatusMenu = styled(Menu)`
  position: absolute;
  z-index: ${z.ACTION_MENU};
  bottom: 0;
  margin: 16px 0px;
  width: calc(100% - 16px);
  max-width: 500px;
  left: 50%;
  transform: translate(-50%, 0);
`;
