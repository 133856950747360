export enum MouseButton {
  PRIMARY = 1,
  SECONDARY = 2,
}

enum KeyboardKey {
  SHIFT = 'Shift',
  C = 'c',
  V = 'v',
}

export const isMouseEvent = (event: Event): event is MouseEvent =>
  event instanceof MouseEvent;

export const isTouchEvent = (event: Event): event is TouchEvent =>
  window.TouchEvent !== undefined && event instanceof TouchEvent;

const isMouseButtonPressed = (button: MouseButton) => (event: Event) =>
  isMouseEvent(event) && (event.buttons & button) !== 0;

export const isPrimaryMouseButtonPressed = isMouseButtonPressed(
  MouseButton.PRIMARY
);

export const isSingleTouch = (event: Event): boolean =>
  isTouchEvent(event) && event.touches.length === 1;

export const isSecondaryMouseButtonPressed = isMouseButtonPressed(
  MouseButton.SECONDARY
);

const isKeyPressed = (key: KeyboardKey) => (event: KeyboardEvent) =>
  event.key == key;

type IOEvent =
  | KeyboardEvent
  | MouseEvent
  | TouchEvent
  | PointerEvent
  | DragEvent;

export const isMacOs = (): boolean =>
  navigator.userAgent.toLowerCase().includes('mac');

export const isShiftKeyPressed = (event: IOEvent): boolean => event.shiftKey;

export const isMetaKeyPressed = (event: IOEvent): boolean => event.metaKey;

export const isCtrlKeyPressed = (event: IOEvent): boolean => event.ctrlKey;

export const isOsDependentMetaOrCtrlKeyPressed = (event: IOEvent): boolean =>
  isMacOs() ? isMetaKeyPressed(event) : isCtrlKeyPressed(event);

export const isCKeyPressed = isKeyPressed(KeyboardKey.C);
export const isVKeyPressed = isKeyPressed(KeyboardKey.V);
