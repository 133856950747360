import React from 'react';

import styled from 'styled-components';

import chroma from 'chroma-js';

const DEFAULT_COLOR_PICKER_ITEM_SIZE = 30;

type ColorPickerProps = {
  colors?: string[];
  value: string;
  onChange?: (color: string) => void;
  size?: number;
};

const Container = styled.div<{ itemSize: number }>`
  background-color: white;
  display: flex;
  padding: ${(props) => Math.round(props.itemSize / 2)}px;

  > * {
    margin-right: ${(props) => Math.round(props.itemSize / 2)}px;
  }

  > *:last-child {
    margin-right: 0;
  }
`;

const ColorPickerItem = styled.div<{
  size: number;
  stroke: string;
  fill: string;
}>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: ${(props) => Math.round(props.size / 10)}px solid;
  border-radius: 50%;
  background-color: ${(props) => props.fill};
  border-color: ${(props) => props.stroke};

  &:hover {
    transform: scale(1.2);
  }
`;

// 20 different nice colors
const Colors = [
  '#000000',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#ffffff',
];

const ColorPicker: React.FC<ColorPickerProps> = ({
  value,
  onChange,
  colors = Colors,
  size = DEFAULT_COLOR_PICKER_ITEM_SIZE,
}) => {
  const alpha = chroma(value).alpha();
  const hexInputValue = chroma(value).hex();
  return (
    <Container itemSize={size}>
      {colors
        .map((color) => chroma(color).alpha(alpha).hex())
        .map((color) => (
          <ColorPickerItem
            key={color}
            size={size}
            onClick={() => onChange?.(color)}
            fill={color}
            stroke={hexInputValue === color ? 'black' : '#ddd'}
          />
        ))}
    </Container>
  );
};

export default ColorPicker;
