import { Body, Flex, Heading, TagChip } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const GroupName = styled(Heading)`
  padding: 8px;
`;

export const GroupStatus = styled(TagChip)`
  margin-right: 8px;
  min-width: fit-content;
`;

export const MenuTitleContainer = styled(Flex)`
  width: 100%;
  align-items: center;
`;

export const GroupEndContainer = styled(Flex).attrs({
  justifyContent: 'center',
})`
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};

  background: ${({ theme }) => theme['surface--strong']};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-bottom: 2px solid ${({ theme }) => theme['border--muted']};
    transform: translateY(-50%);
  }
`;

export const GroupEndText = styled(Body).attrs({ size: 'x-small' })`
  max-width: 288px;
  padding: 6px 8px;
  position: relative;
  background: ${({ theme }) => theme['surface--strong']};
  color: ${({ theme }) => theme['text-icon--muted']};
  width: fit-content;
  font-style: italic;
`;
