import type { ObservationConfig } from '@cognite/apm-client/src/observation-fields-config/types';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useObservationConfigQuery = () => {
  const { apmClient } = useFDMServices();

  return useQuery<ObservationConfig[], Error>(
    [QueryKeys.OBSERVATION_CONFIG, 'list'],
    async () => {
      const {
        list: { items: observationConfigs },
      } = await apmClient.observationConfig.list(
        `
        externalId
        rootLocationExternalIds
        fieldConfigurations
      `
      );

      return observationConfigs;
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.OBSERVATION_CONFIG,
          },
        }),
    }
  );
};
