import Konva from 'konva';
import { isEqual } from 'lodash';
import throttle from 'lodash/throttle';
import { Vector3 } from 'three';

import { Cognite3DViewer } from '@cognite/reveal';
import { CogniteClient } from '@cognite/sdk';

import { Size } from '../../annotations/types';
import { UnifiedViewer } from '../../UnifiedViewer';
import UnifiedViewerEventType from '../../UnifiedViewerEventType';
import { LoadingStatus } from '../asyncContainerUtils';
import { ContainerType } from '../ContainerType';
import ReactContainer, {
  ReactContainerRenderContentProps,
} from '../ReactContainer';

import getShamefulSimpleCameraState from './getSimpleCameraState';
import RevealContent from './RevealContent';
import { RevealContainerProps } from './types';

const SCREENSHOT_THROTTLING_MS = 500;

const LOADING_OVERLAY_COLOR = 'rgba(255, 255, 255, 0.7)';
// We can ask Reveal for a higher-resolution screenshot to get better quality, especially
// if we capture the screenshot when zoomed out and the user later zooms in. This number is
// picked empirically.
const SCREENSHOT_SCALE_FACTOR = 4;

export default class RevealContainer<MetadataType> extends ReactContainer<
  MetadataType,
  RevealContainerProps<MetadataType>
> {
  public readonly type = ContainerType.REVEAL;

  // For the RevealContainer, the screenshot is being captured from the Reveal viewer directly
  // rather than screenshotting the encapsulating DOM-node like in the other ReactContainer
  // instances. Thus, we use Konva to render a loading overlay while it's loading.
  private loadingOverlayNode: Konva.Rect | undefined;

  private reveal: Cognite3DViewer | undefined;
  private cogniteClient: CogniteClient;
  protected override readonly shouldRefreshScreenshotWhileLoading: boolean =
    true;

  public constructor(
    props: RevealContainerProps<MetadataType>,
    unifiedViewer: UnifiedViewer
  ) {
    super(props, unifiedViewer);

    const cogniteClient = this.unifiedViewer.getCogniteClient();
    if (cogniteClient === undefined) {
      throw new Error(
        'CogniteClient is not defined - please provide a CogniteClient instance to the UnifiedViewer to use RevealContainer'
      );
    }
    this.cogniteClient = cogniteClient;

    // Attaching this to the parentNode to *avoid* double registering mouse events
    this.unifiedViewer.host.parentNode!.appendChild(this.domNode);
    this.setLoadingStatus(LoadingStatus.LOADING);
    this.refreshLoadingOverlay();
  }

  public renderContent = ({
    width,
    height,
  }: ReactContainerRenderContentProps): JSX.Element => {
    return (
      <RevealContent
        sdk={this.cogniteClient}
        width={width}
        height={height}
        modelId={this.props.modelId}
        revisionId={this.props.revisionId}
        initialInstance={this.props.initialInstance}
        camera={this.props.camera}
        onModelLoaded={this.onModelLoaded}
        onLoading={this.onRevealLoading}
      />
    );
  };

  private onModelLoaded = (viewer: Cognite3DViewer): void => {
    this.reveal = viewer;
  };

  private throttledRefreshDomNodeScreenshot = throttle(
    this.refreshDomNodeScreenshot,
    SCREENSHOT_THROTTLING_MS,
    {
      // We only fire this once for the last call in the SCREENSHOT_THROTTLING_MS wait period
      leading: false,
      trailing: true,
    }
  );

  private onRevealLoading = (
    itemsLoaded: number,
    itemsRequested: number
  ): void => {
    if (!this.isHidden) {
      return;
    }

    // Note: Seems like Reveal will sometimes call onLoading with itemsRequested === 0
    // probably in the beginning of loading. Why? Nobody knows.
    if (itemsLoaded < itemsRequested || itemsRequested === 0) {
      this.setLoadingStatus(LoadingStatus.LOADING);
      this.refreshLoadingOverlay();
    }

    if (itemsLoaded === itemsRequested) {
      this.destroyLoadingOverlay();
      this.setIsReady(true);
      this.setLoadingStatus(LoadingStatus.SUCCESS);
    }

    // We'll always refresh the screenshot while loading or when it's completed
    this.throttledRefreshDomNodeScreenshot();
  };

  protected override onDomNodeHidden = (): void => {
    // Whenever we hide the DOM-node, the user might have changed the POV of Reveal,
    // and we should update the application layer so that they can keep track of the
    // latest camera angle etc.
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      containers: [this.serialize()],
      annotations: [],
    });
  };

  protected override async captureDomNodeScreenshot(
    scaleFactor = SCREENSHOT_SCALE_FACTOR
  ): Promise<Konva.Image | undefined> {
    if (this.reveal === undefined) {
      return undefined;
    }

    const { width, height } = this.domNode.getBoundingClientRect();
    const revealScreenshotString = await this.reveal.getScreenshot(
      width * scaleFactor,
      height * scaleFactor,
      false
    );
    return new Promise((resolve) => {
      Konva.Image.fromURL(revealScreenshotString, (image: Konva.Image) => {
        resolve(image);
      });
    });
  }

  protected getPropsRequiringUpdate(): Array<
    keyof Pick<RevealContainerProps, 'camera' | 'modelId' | 'revisionId'>
  > {
    return ['camera', 'modelId', 'revisionId'];
  }

  public override setProps(props: RevealContainerProps<MetadataType>): void {
    const prevProps = { ...this.props };
    if (props.modelId !== prevProps.modelId) {
      throw new Error('TODO: Needs to be implemented');
    }

    if (props.revisionId !== prevProps.revisionId) {
      throw new Error('TODO: Needs to be implemented');
    }

    if (
      this.reveal !== undefined &&
      props.camera !== undefined &&
      !isEqual(
        props.camera,
        getShamefulSimpleCameraState(this.reveal?.getViewState().camera)
      )
    ) {
      this.reveal.cameraManager.setCameraState({
        position: new Vector3(
          props.camera?.position.x,
          props.camera?.position.y,
          props.camera?.position.z
        ),
        target: new Vector3(
          props.camera?.target.x,
          props.camera?.target.y,
          props.camera?.target.z
        ),
      });
    }
    super.setProps(props);
  }

  public serialize = (): RevealContainerProps => {
    return {
      ...this.serializeBaseContainerProps(),
      type: this.type,
      modelId: this.props.modelId,
      revisionId: this.props.revisionId,
      initialInstance: this.props.initialInstance,
      camera: getShamefulSimpleCameraState(this.reveal?.getViewState().camera),
    };
  };

  private refreshLoadingOverlay = (): void => {
    this.destroyLoadingOverlay();
    this.createLoadingOverlay();
  };

  private getExistingLoadingOverlayFromStage = (): Konva.Rect | undefined => {
    return this.getContentNode().findOne(
      (n: Konva.Node) => n.getAttr('isLoadingOverlayNode') === true
    ) as Konva.Rect;
  };

  private createLoadingOverlay = () => {
    this.loadingOverlayNode = new Konva.Rect({
      ...this.getContentNode().size(),
      fill: LOADING_OVERLAY_COLOR,
      isLoadingOverlayNode: true,
    });
    this.getContentNode().add(this.loadingOverlayNode);
  };

  protected override onContentNodeSizeChange = (dimensions: Size): void => {
    super.onContentNodeSizeChange(dimensions);
    this.updateExistingLoadingOverlay(dimensions);
  };

  private updateExistingLoadingOverlay = (dimensions?: {
    width: number;
    height: number;
  }) => {
    const loadingOverlayNode = this.getExistingLoadingOverlayFromStage();

    if (loadingOverlayNode === undefined) {
      return;
    }

    this.loadingOverlayNode = loadingOverlayNode;
    this.loadingOverlayNode.size({
      ...(dimensions ?? this.getContentNode().size()),
    });
  };

  private destroyLoadingOverlay = (): void => {
    const loadingOverlayNode = this.getExistingLoadingOverlayFromStage();
    loadingOverlayNode?.destroy();
    this.loadingOverlayNode = undefined;
  };
}
