import { AnnotationType } from '../annotations/types';
import { ContainerType } from '../containers/ContainerType';

const getNewEnumValues = <NewEnum>(
  previousEnumValues: string[],
  currentEnumValues: NewEnum[],
  enumSuffix: string
): ((str: string) => NewEnum | undefined) => {
  return (str: string) => {
    if (currentEnumValues.includes(str as NewEnum)) {
      return str as NewEnum;
    }
    if (previousEnumValues.includes(str)) {
      return `${str}${enumSuffix}` as NewEnum;
    }
    return undefined;
  };
};

const CONTAINER_TYPE_ENUM_SUFFIX = 'Container';
const CONTAINER_TYPE_ENUM_VALUES = Object.values(ContainerType);
const OLD_CONTAINER_TYPE_ENUM_VALUES = CONTAINER_TYPE_ENUM_VALUES.map(
  (containerType) => containerType.split(CONTAINER_TYPE_ENUM_SUFFIX)[0]
);

// As of UFV v18.0.0, the enum values of ContainerType have bee changed to have
// 'Container' as suffix. This method is provided as convenience for converting
// the old enum values to the new format.
export const getSafeUfvNodeTypeForContainer = getNewEnumValues(
  OLD_CONTAINER_TYPE_ENUM_VALUES,
  CONTAINER_TYPE_ENUM_VALUES,
  CONTAINER_TYPE_ENUM_SUFFIX
);

const ANNOTATION_TYPE_ENUM_SUFFIX = 'Annotation';
const ANNOTATION_TYPE_ENUM_VALUES = Object.values(AnnotationType);
const OLD_ANNOTATION_TYPE_ENUM_VALUES = ANNOTATION_TYPE_ENUM_VALUES.map(
  (annotationType) => annotationType.split(ANNOTATION_TYPE_ENUM_SUFFIX)[0]
);

// As of UFV v18.0.0, the enum values of AnnotationType have bee changed to have
// 'Annotation' as suffix. This method is provided as convenience for converting
// the old enum values to the new format.
export const getSafeUfvNodeTypeForAnnotation = getNewEnumValues(
  OLD_ANNOTATION_TYPE_ENUM_VALUES,
  ANNOTATION_TYPE_ENUM_VALUES,
  ANNOTATION_TYPE_ENUM_SUFFIX
);
