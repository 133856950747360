import {
  AnnotationType,
  type RectangleStyleProperties,
} from '@cognite/unified-file-viewer';

// This function is a copy of the function with the same name in UFV.
// It is reimplemented here to avoid an import error in the tests.
export const getDefaultStylesByResourceType = (
  resourceType?: string
): RectangleStyleProperties => {
  if (resourceType === 'asset') {
    return {
      ...defaultAnnotationStyle,
      stroke: AnnotationColors.ASSET,
      fill: 'transparent',
    };
  }
  if (resourceType === 'file') {
    return {
      ...defaultAnnotationStyle,
      stroke: AnnotationColors.FILE,
      fill: 'transparent',
    };
  }

  return defaultAnnotationStyle;
};

enum AnnotationColors {
  ASSET = '#D46AE2',
  FILE = '#FF8746',
  DEFAULT = '#A4B2FC',
}

export const defaultAnnotationStyle: RectangleStyleProperties = {
  stroke: AnnotationColors.DEFAULT,
  // Disable stroke scaling for contextualized annotations, as they otherwise
  // become too thin when the user is zoomed out
  shouldEnableStrokeScale: false,
  fill: 'transparent',
  strokeWidth: 2,
  opacity: 1,
  cornerRadius: 1,
};

export const RectangleAnnotationType = AnnotationType.RECTANGLE;
