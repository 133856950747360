import {
  Body,
  Button,
  CheckmarkFilledIcon,
  Flex,
  Heading,
} from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
  justifyContent: 'space-between',
})`
  height: 100%;
`;

export const Header = styled(Flex).attrs({
  justifyContent: 'space-between',
})`
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const HeaderTitle = styled(Heading).attrs({ level: 5 })`
  padding: 16px;
`;

export const CloseWrapper = styled(Flex)`
  border-left: 1px solid ${({ theme }) => theme['border--muted']};
  padding: 10px;
`;

export const StepContainer = styled(Flex).attrs({
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const StepTitle = styled(Heading).attrs({ level: 6 })`
  color: ${({ theme }) => theme['text-icon--medium']};
`;

export const StepDescription = styled(Body).attrs({ size: 'small' })`
  color: ${({ theme }) => theme['text-icon--medium']};
`;

export const StepIcon = styled(CheckmarkFilledIcon)<{
  $isCompleted?: boolean;
}>`
  color: ${({ theme, $isCompleted }) =>
    $isCompleted
      ? theme['text-icon--interactive--default']
      : theme['text-icon--interactive--disabled']};
`;

export const Footer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
})`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme['surface--strong']};
`;

export const FooterButton = styled(Button)`
  width: 100%;
`;

export const OverviewWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  max-height: 100%;
  overflow: auto;
  margin-bottom: auto;
`;
