import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

// We have to do this silly dance because the browser isn't authenticated to
// fetch the image, so we need to download the image and then serve it back
// to the <img /> tag via a `blob:garbage` URL.
// TODO(OI-1009): Remove this nonsense and just hand it the actual thumbnail.
export const useFetchImageByUrl = (imageURL?: string) => {
  const { mediaService } = useFDMServices();

  return useQuery<string>(
    [QueryKeys.MEDIA_ITEM, imageURL],
    async () => {
      return mediaService.getMediaByUrl(imageURL!);
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.MEDIA_ITEM,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(imageURL),
    }
  );
};
