import type { Observation, ObservationStatus } from '@cognite/apm-client';
import type { StatusChipState } from '@cognite/cogs.js-v10';
import { LoaderIcon } from '@cognite/cogs.js-v10';

const DEFAULT_STATUS: ObservationStatus = 'Draft';

export const getChipStatus = (
  status?: ObservationStatus
): StatusChipState | undefined => {
  switch (status) {
    case 'Sent':
      return 'success';
    case 'Completed':
      return 'neutral';
    case 'Not sent':
      return 'warning';
    case 'File not sent':
      return 'warning';
    default:
      return undefined;
  }
};

export const getHeaderStatus = ({
  t,
  observation,
  isSaving = false,
}: {
  t: any;
  observation?: Observation;
  isSaving: boolean;
}) => {
  if (isSaving) {
    return {
      label: t('APM_OBSERVATION_HEADER_STATUS_SAVING', 'Saving'),
      icon: <LoaderIcon />,
    };
  }
  const status = observation?.status || DEFAULT_STATUS;
  const statusChip = getChipStatus(status);
  const statusTranslation = t(
    `APM_OBSERVATION_HEADER_STATUS_${status.toUpperCase()}`,
    status
  );
  return {
    label: statusTranslation,
    status: statusChip,
  };
};
