const binarySearch = (
  lowerBound: number,
  upperBound: number,
  conditionFn: (value: number) => boolean,
  accuracy: number
): number => {
  while (upperBound - lowerBound > accuracy) {
    const midPoint = (lowerBound + upperBound) / 2;
    if (conditionFn(midPoint)) {
      upperBound = midPoint;
    } else {
      lowerBound = midPoint;
    }
  }
  return (lowerBound + upperBound) / 2;
};

export default binarySearch;
