import { Body, Flex, Modal, SegmentedControl } from '@cognite/cogs.js-v10';
import { useState } from 'react';

import { useSelfServiceReleaseState } from '../../hooks/use-self-service-release-state';

type VersionOption = {
  label: string;
  value: string;
};

type ReleaseManagementModalProps = {
  onClose: () => void;
  allowedVersions: VersionOption[];
  visible: boolean;
  appName: string;
};

export const ReleaseManagementModal = ({
  onClose,
  allowedVersions,
  visible,
  appName,
}: ReleaseManagementModalProps): JSX.Element => {
  const [selfServiceRelease, setSelfServiceRelease] =
    useSelfServiceReleaseState();

  const [localVersion, setLocalVersion] = useState(selfServiceRelease);

  return (
    <Modal
      onCancel={() => {
        setLocalVersion(selfServiceRelease);
        onClose();
      }}
      title="Release management"
      visible={visible}
      size="medium"
      onOk={() => {
        if (localVersion !== -1) {
          setSelfServiceRelease(localVersion);
        } else {
          setSelfServiceRelease(undefined);
        }
        onClose();
        // window.location.reload(); Reintroduce if needed
      }}
      okText="Save"
    >
      <Flex direction="column" gap={16}>
        <Body size="medium">
          You can opt-in for using upcoming releases of {appName} using the
          options below. Please note that you might still need respective teams
          to enable some flags for you to use work-in-progress features.
        </Body>
        <SegmentedControl
          currentKey={
            Number.isInteger(localVersion) &&
            allowedVersions.some(({ value }) => value === String(localVersion))
              ? String(localVersion)
              : 'production'
          }
          onButtonClicked={(key) => {
            const num = parseInt(key, 10);
            if (Number.isInteger(num)) {
              setLocalVersion(num);
            } else {
              setLocalVersion(undefined);
            }
          }}
        >
          {allowedVersions.map((option) => (
            <SegmentedControl.Button key={option.value}>
              {option.label}
            </SegmentedControl.Button>
          ))}
        </SegmentedControl>
      </Flex>
    </Modal>
  );
};
