import type { ObservationConfig } from '@cognite/apm-client/src/observation-fields-config/types';
import { Body, Flex, Heading } from '@cognite/cogs.js-v10';
import { useUpsertObservationConfig } from '@infield/features/observation/hooks/use-mutation/use-upsert-observation-config-mutation';
import { useObservationConfigByLocation } from '@infield/features/observation/hooks/use-observation-config-by-location';
import type { FC } from 'react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';

import type { ObservationsConfig } from '../../../types';
import * as S from '../elements';

import * as LocalS from './elements';
import { ObservationConfigDropdownField } from './observation-config-dropdown-field';
import { ObservationConfigField } from './observation-config-field';
import {
  checkIfFieldSet,
  defaultObservationFields,
  isDropdownProperty,
} from './utils';

interface Props {
  hasAppConfigPermission: boolean;
  rootLocationExternalId: string;
}

export const RootLocationConfigObservations: FC<Props> = ({
  hasAppConfigPermission,
  rootLocationExternalId,
}) => {
  const { observationConfig, defaultObservationConfig } =
    useObservationConfigByLocation(rootLocationExternalId);
  const { mutateAsync: upsertObservationConfig, isLoading } =
    useUpsertObservationConfig();

  const [observationFieldConfig, setObservationFieldConfig] =
    useState<ObservationsConfig>(
      observationConfig?.fieldConfigurations ||
        defaultObservationConfig?.fieldConfigurations
    );

  const isRequiredFieldsSet = Object.values(observationFieldConfig).every(
    (field) => {
      if (isDropdownProperty(field)) {
        return (
          checkIfFieldSet(field.displayTitle) &&
          field.options.every(
            ({ value, label }) =>
              checkIfFieldSet(value) && checkIfFieldSet(label)
          )
        );
      }
      return checkIfFieldSet(field.displayTitle);
    }
  );

  const hasConfigChanged = () => {
    const originalConfigJson = JSON.stringify(
      observationConfig?.fieldConfigurations
    );
    const currentConfigJson = JSON.stringify(observationFieldConfig);

    return originalConfigJson !== currentConfigJson;
  };

  const handleSave = async () => {
    let newConfig: ObservationConfig;
    if (!observationConfig) {
      newConfig = {
        externalId: uuid(),
        rootLocationExternalIds: [rootLocationExternalId],
        fieldConfigurations: observationFieldConfig,
      };
    } else {
      newConfig = {
        ...observationConfig,
        fieldConfigurations: observationFieldConfig,
      };
    }

    await upsertObservationConfig([newConfig]);
  };

  return (
    <S.Wrapper>
      <Flex direction="column">
        <LocalS.StyledInfobox icon={false}>
          <Heading level={5}>Customization</Heading>
          <Body size="x-small">Configure observation UI</Body>
        </LocalS.StyledInfobox>
        {defaultObservationFields.map((field) => {
          if (field !== 'type' && field !== 'priority') {
            return (
              <ObservationConfigField
                key={field}
                prop={field}
                observationField={observationFieldConfig?.[field]}
                hasAppConfigPermission={hasAppConfigPermission}
                setObservationsConfig={setObservationFieldConfig}
                showRequiredToggle
              />
            );
          }
          return (
            <ObservationConfigDropdownField
              key={field}
              prop={field}
              observationField={observationFieldConfig?.[field]}
              hasAppConfigPermission={hasAppConfigPermission}
              setObservationsConfig={setObservationFieldConfig}
            />
          );
        })}
        <S.FixedSaveButtonWrapper>
          <S.SaveButton
            onClick={handleSave}
            disabled={!hasConfigChanged() || !isRequiredFieldsSet}
            loading={isLoading}
          >
            Save configuration
          </S.SaveButton>
        </S.FixedSaveButtonWrapper>
      </Flex>
    </S.Wrapper>
  );
};
