import type { Checklist } from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client';
import { useMetrics } from '@cognite/metrics';
import { useSelectedRootLocationConfiguration } from '@infield/features/app-config';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

type Props = {
  query: string;
  filter?: Filters;
  properties?: string[];
  enabled: boolean;
  limit?: number;
  metrics?: string;
};

export const useSearchChecklists = ({
  query,
  filter,
  properties,
  enabled = true,
  limit = 1000,
  metrics,
}: Props) => {
  const { apmClient } = useFDMServices();
  const { checklist: configFilters } = useAppConfigFiltersContext();
  const selectedRootLocationConfig = useSelectedRootLocationConfiguration();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);
  const checklistFilters: Filters[] = [];

  if (configFilters.rootAssetExternalIds && selectedRootLocationConfig) {
    checklistFilters.push(configFilters.rootAssetExternalIds);
  }

  if (filter) {
    checklistFilters.push(filter);
  }

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  return useQuery<Checklist[], Error>(
    [QueryKeys.SEARCH_CHECKLISTS, query, checklistFilters, properties],
    async () => {
      sliTimerStartTime.current = Date.now();

      return apmClient.checklists.dmsSearch<Checklist>(
        query,
        'node',
        {
          and: checklistFilters,
        },
        limit,
        properties
      );
    },
    {
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.SEARCH_CHECKLISTS,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.SEARCH_CHECKLISTS, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
          from: metrics,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.SEARCH_CHECKLISTS, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
          from: metrics,
        });
      },
      enabled:
        Boolean(configFilters.rootAssetExternalIds) &&
        Boolean(selectedRootLocationConfig) &&
        enabled,
    }
  );
};
