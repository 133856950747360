import type { AnnotationModel } from '@cognite/sdk';
import type { Annotation } from '@cognite/unified-file-viewer';
import {
  getAnnotationsFromCogniteAnnotations,
  getDefaultStylesByResourceType,
} from '@cognite/unified-file-viewer';

import type { UfvAnnotation } from '../../types';
import { getMetadataFromCogniteAnnotation } from '../utils/getMetadataFromCogniteAnnotations';

type UnifiedFileViewerStateProps = {
  containerId: string;
  cogniteAnnotations?: AnnotationModel[];
  currentPage?: number;
  onAnnotationClick: (annotation: UfvAnnotation) => void;
};

export const getUnifiedFileViewerState = ({
  containerId,
  cogniteAnnotations,
  onAnnotationClick,
  currentPage = 1,
}: UnifiedFileViewerStateProps): {
  annotations: Annotation[];
} => {
  const ufvAnnotations: UfvAnnotation[] = getAnnotationsFromCogniteAnnotations(
    cogniteAnnotations ?? [],
    containerId,
    (annotation) => getMetadataFromCogniteAnnotation(annotation)
  ).filter(
    (annotation) =>
      annotation.metadata !== undefined &&
      annotation.metadata.status === 'approved'
  ) as UfvAnnotation[];

  const annotations: Annotation[] = ufvAnnotations
    .filter((annotation) => (annotation.metadata.page ?? 1) === currentPage)
    .map((annotation) => ({
      ...annotation,
      onClick: (e, clickedAnnotation) => {
        e.cancelBubble = true;
        onAnnotationClick(clickedAnnotation as UfvAnnotation);
      },
      style: {
        ...getDefaultStylesByResourceType(annotation.metadata.resourceType),
        // fill white with 0.1 opacity
        // this is required to make the annotation clickable
        fill: '#ffffff1a',
      },
    }));

  return { annotations };
};
