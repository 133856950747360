import { useEffect, useState } from 'react';

// example taken from https://developer.mozilla.org/en-US/docs/Web/API/Web_Crypto_API/Non-cryptographic_uses_of_subtle_crypto
export const hash = async (s: string) => {
  const utf8 = new TextEncoder().encode(s);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

// we have this lightweight useQuery implementation to allow projects with old
// react-query version use this package. while some projects use
// @tanstack/react-query, some others still use react-query
const useLightweightQuery = <D = unknown, V = unknown>(
  queryFn: (variables: V) => Promise<D>,
  variables: V
) => {
  const [state, setState] = useState<{
    data?: D;
    error?: unknown;
    loading?: boolean;
  }>({
    data: undefined,
    error: undefined,
    loading: undefined,
  });

  useEffect(() => {
    setState({ loading: true });
    queryFn(variables)
      .then((d: D) => {
        setState({ loading: false, data: d });
      })
      .catch((e: unknown) => {
        setState({ loading: false, error: e });
      });
    // we only want to run this at mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data: state.data, error: state.error, loading: state.loading };
};

export const useHash = (str: string) => {
  return useLightweightQuery(hash, str);
};
