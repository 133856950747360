import { Link } from '@cognite/cogs.js-v10';
import { Asset } from '@cognite/sdk';

import { createLink } from '../../../utils/linkUtils';

const EventAssets = ({
  eventId,
  assets,
}: {
  assets: Asset[];
  eventId: number;
}): JSX.Element => {
  if (assets.length === 0) {
    return <p />;
  }
  const assetsLink = createLink(
    assets.length === 1
      ? `/explore/asset/${assets[0].id}`
      : `/explore/events/${eventId}/asset`
  );
  const assetsLinkText =
    assets.length === 1 ? assets[0].name : `${assets.length} assets`;
  return (
    <Link
      href={assetsLink}
      size="small"
      target="_blank"
      className="table-item-value"
    >
      {assetsLinkText}
    </Link>
  );
};

export default EventAssets;
