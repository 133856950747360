import Konva from 'konva';
import { IRect } from 'konva/cmj/types';
import cloneDeep from 'lodash/cloneDeep';

import { Container } from '../containers';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import UnifiedViewerRenderer from '../UnifiedViewerRenderer';
import isTransparentColor from '../utils/isTransparentColor';
import shamefulSafeKonvaScale from '../utils/shamefulSafeKonvaScale';

import getBaseStylePropertiesFromNode from './getBaseStylePropertiesFromNode';
import getMaxDiffingScalingFactor from './getMaxDiffingScalingFactor';
import setAnnotationNodeEventHandlers from './setAnnotationNodeEventHandlers';
import shouldBeDraggable from './shouldBeDraggable';
import shouldBeResizable from './shouldBeResizable';
import shouldBeSelectable from './shouldBeSelectable';
import shouldBeStrokeScaleEnabled from './shouldBeStrokeScaleEnabled';
import {
  AnnotationSerializer,
  AnnotationType,
  EllipseAnnotation,
  EllipseStyleProperties,
  Scale,
  Size,
} from './types';

type EllipseDimensions = {
  x: number;
  y: number;
  radius: number | { x: number; y: number };
};

const getDimensions = (
  annotation: EllipseAnnotation,
  getContainerById: (id: string) => Container | undefined
) => {
  if (annotation.containerId === undefined) {
    return getEllipseDimensionsFromAnnotation(annotation);
  }

  const container = getContainerById(annotation.containerId);
  if (container === undefined) {
    return undefined;
  }

  return getDenormalizedEllipseDimensionsFromAnnotation(
    annotation,
    container.getContentNode().size()
  );
};

const getEllipseDimensionsFromAnnotation = (
  annotation: EllipseAnnotation
): EllipseDimensions => ({
  x: annotation.x,
  y: annotation.y,
  radius: annotation.radius,
});

const getDenormalizedEllipseDimensionsFromAnnotation = (
  annotation: EllipseAnnotation,
  containerRect: Size
): EllipseDimensions => {
  const { x: radiusX, y: radiusY } =
    typeof annotation.radius === 'number'
      ? { x: annotation.radius, y: annotation.radius }
      : annotation.radius;
  const { scaleX, scaleY } =
    typeof annotation.radius === 'number'
      ? // The scale values for uniform circles are chosen so that the created
        // ellipses (with uniform radius) is identical to the one that would've
        // been created using Konva.Circle using the same radius.
        { scaleX: containerRect.width, scaleY: containerRect.width }
      : { scaleX: containerRect.width, scaleY: containerRect.height };

  return {
    x: containerRect.width * annotation.x,
    y: containerRect.height * annotation.y,
    radius: {
      x: radiusX * scaleX,
      y: radiusY * scaleY,
    },
  };
};

const getNormalizedEllipseDimensionsFromNode = (
  node: Konva.Ellipse,
  getContainerRectById: (id: string) => IRect | undefined
): EllipseDimensions => {
  const useNormalizedCoordinates = node.attrs.containerId !== undefined;

  if (!useNormalizedCoordinates) {
    return {
      x: node.x(),
      y: node.y(),
      radius: node.radius(),
    };
  }

  const containerRect = getContainerRectById(node.attrs.containerId);
  if (containerRect === undefined) {
    throw new Error('Could not find container dimensions for annotation');
  }

  return {
    x: node.x() / containerRect.width,
    y: node.y() / containerRect.height,
    radius: {
      x: node.radius().x / containerRect.width,
      y: node.radius().y / containerRect.height,
    },
  };
};

type EllipseNodeProperties<MetadataType> = {
  [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION;
  annotationType: AnnotationType.ELLIPSE;
  id: string;
  strokeScaleEnabled: boolean;
  containerId?: string;
  isSelectable: boolean;
  isDraggable: boolean;
  isResizable: boolean;
  fillEnabled: boolean;
  metadata?: MetadataType;
} & EllipseDimensions &
  EllipseStyleProperties;

const getNodePropertiesFromAnnotation = <MetadataType>(
  annotation: EllipseAnnotation<MetadataType>,
  unifiedViewerRenderer: UnifiedViewerRenderer
): EllipseNodeProperties<MetadataType> | undefined => {
  const dimensions = getDimensions(
    annotation,
    unifiedViewerRenderer.getContainerById
  );
  if (dimensions === undefined) {
    // eslint-disable-next-line no-console
    console.debug(
      `Could not get dimensions for annotation ${annotation.id}. If it has a container, maybe the container hasn't loaded yet?`
    );
    return undefined;
  }

  return {
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
    id: annotation.id,
    annotationType: AnnotationType.ELLIPSE,
    containerId: annotation.containerId,
    isSelectable: shouldBeSelectable(annotation.isSelectable),
    isDraggable: shouldBeDraggable(annotation.isDraggable),
    isResizable: shouldBeResizable(annotation.isResizable),
    fill: annotation.style?.fill,
    fillEnabled: !isTransparentColor(annotation.style?.fill),
    stroke: annotation.style?.stroke,
    strokeWidth: annotation.style?.strokeWidth,
    strokeScaleEnabled: shouldBeStrokeScaleEnabled(annotation),
    opacity: annotation.style?.opacity,
    dash: annotation.style?.dash,
    metadata: annotation.metadata,
    // Note: If containerId is set, then we always should assume that the dimensions are
    // normalized.
    ...dimensions,
  };
};

type EllipseTransformationProperties = {
  size: Size;
  scale: Scale;
};

const EllipseSerializer: AnnotationSerializer<
  EllipseAnnotation,
  EllipseTransformationProperties
> = {
  getTransformationPropertiesFromNode: (node: Konva.Node) => {
    if (!(node instanceof Konva.Ellipse)) {
      throw new Error('Expected node to be a Konva.Ellipse');
    }

    return {
      size: node.size(),
      scale: shamefulSafeKonvaScale(node.scale()),
    };
  },
  normalize: ({ size, scale }: { size: Size; scale: Scale }) => {
    const scaleFactor = getMaxDiffingScalingFactor(scale);
    return {
      size: {
        width: size.width * scaleFactor,
        height: size.height * scaleFactor,
      },
      scale: {
        x: 1,
        y: 1,
      },
    };
  },

  isSelectable: (node: Konva.Node) =>
    shouldBeSelectable(node.attrs.isSelectable),

  isDraggable: (node: Konva.Node) => shouldBeDraggable(node.attrs.isDraggable),

  isResizable: (node: Konva.Node) => shouldBeResizable(node.attrs.isResizable),

  serialize: (node, unifiedViewerRenderer): EllipseAnnotation => {
    return {
      id: node.id(),
      type: AnnotationType.ELLIPSE,
      containerId: node.attrs.containerId,
      isSelectable: shouldBeSelectable(node.attrs.isSelectable),
      isDraggable: shouldBeDraggable(node.attrs.isDraggable),
      isResizable: shouldBeResizable(node.attrs.isResizable),
      ...getNormalizedEllipseDimensionsFromNode(
        node as Konva.Ellipse, // TODO: Improve typing
        unifiedViewerRenderer.getContainerRectRelativeToStageById
      ),
      style: getBaseStylePropertiesFromNode(node as Konva.Ellipse), // TODO: Improve typing
      metadata: cloneDeep(node.attrs.metadata),
    };
  },

  deserialize: (annotation, unifiedViewer, unifiedViewerRenderer) => {
    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return undefined;
    }

    return setAnnotationNodeEventHandlers(
      new Konva.Ellipse(nodeProperties as any), // TODO: It seems to work well with the type we have
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },

  updateNode: (
    node,
    annotation,
    unifiedViewer,
    unifiedViewerRenderer
  ): void => {
    if (!(node instanceof Konva.Ellipse)) {
      throw new Error('Expected node to be a Konva.Ellipse');
    }

    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return;
    }

    node.setAttrs(nodeProperties);

    setAnnotationNodeEventHandlers(
      node,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },
};

export default EllipseSerializer;
