class EventEmitter<
  EventMap extends Record<
    EventType,
    EventMap[EventType] extends (...args: infer U) => void
      ? (...args: U) => void
      : never
  >,
  EventType extends string = keyof EventMap extends string
    ? keyof EventMap
    : never
> {
  private eventListenersByEventType = new Map<
    EventType,
    EventMap[EventType][]
  >();

  public addEventListener<E extends EventType>(
    eventType: E,
    listenerFn: EventMap[E]
  ): () => void {
    const eventListeners = this.eventListenersByEventType.get(eventType) ?? [];
    this.eventListenersByEventType.set(eventType, [
      ...eventListeners,
      listenerFn,
    ]);

    return () => this.removeEventListener(eventType, listenerFn);
  }

  public removeEventListener<E extends EventType>(
    eventType: E,
    listenerFn: EventMap[E]
  ): void {
    const eventListeners = this.eventListenersByEventType.get(eventType) ?? [];
    this.eventListenersByEventType.set(
      eventType,
      eventListeners.filter((fn) => fn !== listenerFn)
    );
  }

  public once<E extends EventType>(
    eventType: E,
    listenerFn: EventMap[E]
  ): void {
    // @ts-expect-error
    const wrappedListener: EventMap[E] = (...args: Parameters<EventMap[E]>) => {
      this.removeEventListener(eventType, wrappedListener);
      listenerFn(...args);
    };

    this.addEventListener(eventType, wrappedListener);
  }

  public emit<E extends EventType>(
    eventType: E,
    ...args: Parameters<EventMap[E]>
  ): void {
    const eventListeners = this.eventListenersByEventType.get(eventType);
    if (eventListeners === undefined || eventListeners.length === 0) {
      return;
    }

    eventListeners.forEach((listener) => listener(...args));
  }

  public removeAllListeners(eventType?: EventType): void {
    if (eventType === undefined) {
      this.eventListenersByEventType.clear();
      return;
    }
    this.eventListenersByEventType.set(eventType, []);
  }
}

export default EventEmitter;
