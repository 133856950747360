import type { AxisUpdate } from '@cognite/charts-lib';

import isNotUndefined from '../../utils/isNotUndefined';

import type { ChartSourcePropsById } from './types';

const getAxisUpdatesFromSourcePropsById = (
  sourcePropsById: ChartSourcePropsById
): AxisUpdate[] =>
  Object.entries(sourcePropsById)
    .map(([id, props]) => {
      const { type, range } = props;
      if (
        type !== 'scheduledCalculation' &&
        type !== 'timeseries' &&
        type !== 'workflow'
      ) {
        return undefined;
      }
      if (range === undefined || range.length !== 2) {
        return undefined;
      }
      return { id, type, range };
    })
    .filter(isNotUndefined);

export default getAxisUpdatesFromSourcePropsById;
