import { getLanguage } from '@cognite/cdf-i18n-utils';
import type { CogniteClient, Document } from '@cognite/sdk';

import type { AiAnswer, AiDocument, DocumentAskAnswer } from '../../types';

const LANGUAGE_NAMES_IN_ENGLISH = new Intl.DisplayNames('en', {
  type: 'language',
});
const DEFAULT_LANGUAGE_CODE = 'en';
const DEFAULT_LANGUAGE_NAME = 'English';
const MAX_NUMBER_OF_FILE_IDS = 50; // This is the max limit from the /documents/semantic/search API
const whitelistedLanguages = [
  'en',
  'de',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'lv',
  'nl',
  'pt',
  'sv',
  'zh',
];

export const getAnswerToQuestionMultipleFiles = async (
  sdk: CogniteClient,
  question: string,
  fileIds: number[],
  maxWords = 80
): Promise<AiAnswer> => {
  if (question.length < 1) {
    return {
      answer: '',
      sources: [],
    };
  }

  // There's a max limit of what we can send to the GPT API.
  let languageCode = getLanguage() ?? DEFAULT_LANGUAGE_CODE;

  if (!whitelistedLanguages.includes(languageCode)) {
    languageCode = DEFAULT_LANGUAGE_CODE;
  }
  let languageName =
    LANGUAGE_NAMES_IN_ENGLISH.of(languageCode) ?? DEFAULT_LANGUAGE_NAME;

  if (languageName.includes(' ')) {
    const [languageNameWithoutLocale] = languageName.split(' ');
    languageName = languageNameWithoutLocale;
  }

  const mappedFileIds = fileIds
    .slice(0, MAX_NUMBER_OF_FILE_IDS - 1)
    .map((id) => ({ id }));

  const {
    data: { content },
  } = await sdk.post<{ content: DocumentAskAnswer[] }>(
    `/api/v1/projects/${sdk.project}/ai/tools/documents/ask`,
    {
      data: {
        question: question,
        fileIds: mappedFileIds,
        language: languageName,
      },
      headers: {
        'cdf-version': '20230101-beta',
      },
    }
  );

  const { items: filesInfo } = await sdk.documents.list({
    filter: {
      in: {
        property: ['id'],
        values: fileIds,
      },
    },
  });

  return mapPassageReferencesToSources(content, filesInfo);
};

export function mapPassageReferencesToSources(
  references: DocumentAskAnswer[],
  filesInfo: Document[]
) {
  const fileIdToNameMap = {} as Record<number, string>;
  filesInfo.forEach((document) => {
    fileIdToNameMap[document.id] = document.sourceFile.name;
  });

  // The answer is in chunk, each passage holding its references from where it comed from
  // We need to concatenate all the answers into one string
  let generatedAnswer = '';
  const sources = [] as AiDocument[];

  references.forEach((answer) => {
    generatedAnswer += answer.text + '\n';
    answer.references.forEach((reference) => {
      reference.locations.forEach((location) => {
        sources.push({
          pageContent: answer.text,
          metadata: {
            ...location,
            fileId: reference.fileId,
            page: location.pageNumber,
            title: fileIdToNameMap[reference.fileId],
          },
        });
      });
    });
  });

  return {
    answer: generatedAnswer,
    sources,
  };
}
