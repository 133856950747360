import { IRect } from 'konva/lib/types';
import { sortBy, uniq } from 'lodash';

import getEuclideanDistance from '../../utils/getEuclideanDistance';
import isNotUndefined from '../../utils/isNotUndefined';
import {
  addMarginToRect,
  filterOutRectsThatAreInsideOtherRects,
  isPointInRect,
  isStrictlyInRange,
} from '../../utils/rectUtils';
import { Position } from '../types';

import {
  TurnDirection,
  getTurnDirection,
  arePointsEqual,
  simplifyRightAnglesPath,
} from './utils';

const TURN_PENALTY = 100;
const OBSTACLE_PENALTY = 10000;

// This class is used in the A* algorithm: https://en.wikipedia.org/wiki/A*_search_algorithm
export class GridNode {
  public x: number;
  public y: number;
  public f: number; // The sum of the cost to reach this node and the estimated cost to the goal (f = g + h)
  public g: number; // The cost to reach this node from the start node along the best known path
  public h: number; // A heuristic that estimates the cost of the cheapest path from this node to the goal
  public xIndex: number;
  public yIndex: number;
  public parent: GridNode | null;
  public isObstacle: boolean;

  public constructor(
    x: number,
    y: number,
    xIndex: number,
    yIndex: number,
    isObstacle: boolean
  ) {
    this.x = x;
    this.y = y;
    this.xIndex = xIndex;
    this.yIndex = yIndex;
    this.f = 0;
    this.g = 0;
    this.h = 0;
    this.parent = null;
    this.isObstacle = isObstacle;
  }
}

const getNeighborCost = (gridNode: GridNode, neighbor: GridNode): number => {
  const distance = getEuclideanDistance(gridNode, neighbor);

  let cost = distance;
  if (neighbor.isObstacle) {
    cost += OBSTACLE_PENALTY;
  }

  // We want to penalize the path if it turns, so that the path is as straight as possible.
  const { parent } = gridNode;
  if (
    parent !== null &&
    getTurnDirection(parent, gridNode, neighbor) !== TurnDirection.STRAIGHT
  ) {
    cost += TURN_PENALTY;
  }

  return cost;
};

const transpose = <T>(matrix: T[][]): T[][] => {
  return matrix[0].map((_, i) => matrix.map((row) => row[i]));
};

type GridNodeWithCost = { neighbor: GridNode; cost: number };

export class Grid {
  public startNode: GridNode;
  public endNode: GridNode;
  public gridNodes: GridNode[][];

  public constructor(
    startNode: GridNode,
    endNode: GridNode,
    gridNodes: GridNode[][]
  ) {
    this.startNode = startNode;
    this.endNode = endNode;
    this.gridNodes = gridNodes;
  }

  public get = (x: number, y: number): GridNode | undefined => {
    return this.gridNodes[x]?.[y];
  };

  // The grid is created by adding horizontal and vertical lines at the edge of the obstacles (with the given padding).
  // Horizontal and vertical lines are also added at the start and end points, so that the start and end points are in the grid.
  // All the obstacles also need at least one horizontal and vertical line that goes through the obstacles, otherwise the there will be no grid nodes inside the obstacle (with the `isObstacle` property set).
  public static create = (
    startPoint: Position,
    endPoint: Position,
    obstacles: IRect[],
    obstaclePadding: number
  ): Grid => {
    // Remove all obstacles that are fully inside another obstacle since they will never be part of the path (also optimizes the algorithm)
    const filteredObstacles = filterOutRectsThatAreInsideOtherRects(
      obstacles
    ).map((obstacle) => addMarginToRect(obstacle, obstaclePadding));

    const horizontalLines: number[] = [];
    const verticalLines: number[] = [];

    for (const obstacle of filteredObstacles) {
      horizontalLines.push(obstacle.y, obstacle.y + obstacle.height);
      verticalLines.push(obstacle.x, obstacle.x + obstacle.width);
    }

    // There needs to be at least one line that goes through the obstacle, otherwise there will be no grid nodes inside the obstacle
    for (const obstacle of filteredObstacles) {
      const { x, y, width, height } = obstacle;
      if (
        !horizontalLines.some((line) => isStrictlyInRange(line, y, y + height))
      ) {
        horizontalLines.push(y + 0.5 * height);
      }

      if (
        !verticalLines.some((line) => isStrictlyInRange(line, x, x + width))
      ) {
        verticalLines.push(x + 0.5 * width);
      }
    }

    horizontalLines.push(startPoint.y, endPoint.y);
    verticalLines.push(startPoint.x, endPoint.x);

    const sortedHorizontalLinePositions = sortBy(uniq(horizontalLines));
    const sortedVerticalLinePositions = sortBy(uniq(verticalLines));

    let startNode: GridNode | undefined = undefined;
    let endNode: GridNode | undefined = undefined;
    const grid: GridNode[][] = [];
    for (const [xIndex, x] of sortedVerticalLinePositions.entries()) {
      grid[xIndex] = [];
      for (const [yIndex, y] of sortedHorizontalLinePositions.entries()) {
        grid[xIndex][yIndex] = new GridNode(x, y, xIndex, yIndex, false);

        if (x === startPoint.x && y === startPoint.y) {
          startNode = grid[xIndex][yIndex];
        }

        if (x === endPoint.x && y === endPoint.y) {
          endNode = grid[xIndex][yIndex];
        }
      }
    }

    if (startNode === undefined || endNode === undefined) {
      throw new Error(
        'Start or end node not found in grid. This should not happen.'
      );
    }

    // Mark obstacles
    for (const [xIndex, gridLine] of grid.entries()) {
      for (const [yIndex, gridNode] of gridLine.entries()) {
        const isObstacle = filteredObstacles.some((obstacle) =>
          isPointInRect(gridNode, obstacle)
        );
        grid[xIndex][yIndex].isObstacle = isObstacle;
      }
    }

    return new Grid(startNode, endNode, grid);
  };

  public getNeighborsWithCost = (gridNode: GridNode): GridNodeWithCost[] => {
    const { xIndex, yIndex } = gridNode;

    const offsets = [
      [-1, 0], // Left
      [0, 1], // Top
      [1, 0], // Right
      [0, -1], // Bottom
    ];

    return offsets
      .map(([xOffset, yOffset]) => {
        const neighbor = this.get(xIndex + xOffset, yIndex + yOffset);
        if (neighbor === undefined) {
          return undefined;
        }

        return {
          neighbor,
          cost: getNeighborCost(gridNode, neighbor),
        };
      })
      .filter(isNotUndefined);
  };

  public printPath = (path: Position[]): void => {
    // eslint-disable-next-line no-console
    console.log(
      transpose(this.gridNodes)
        .map((gridLine) =>
          gridLine
            .map((gridNode) => {
              if (arePointsEqual(gridNode, this.startNode)) {
                return 'S';
              }

              if (arePointsEqual(gridNode, this.endNode)) {
                return 'E';
              }

              if (path.some((point) => arePointsEqual(point, gridNode))) {
                return 'X';
              }

              if (gridNode.isObstacle) {
                return 'O';
              }

              return '_';
            })
            .join('')
        )
        .join('\n')
    );
    // eslint-disable-next-line no-console
    console.log('Path length:', path.length);
    // eslint-disable-next-line no-console
    console.log('Num turns', simplifyRightAnglesPath(path).length - 1);
  };

  public printGValues = (hightLightNode?: GridNode): void => {
    // eslint-disable-next-line no-console
    console.log(
      transpose(this.gridNodes)
        .map((gridLine) =>
          gridLine
            .map((gridNode) => {
              if (gridNode === hightLightNode) {
                return '  X ';
              }

              // 5 digits always (including sign and decimal point)
              return gridNode.g.toString().slice(0, 4).padStart(4, ' ');
            })
            .join(' ')
        )
        .join('\n')
    );
  };

  public printObstacles = (): void => {
    // eslint-disable-next-line no-console
    console.log(
      transpose(this.gridNodes)
        .map((gridLine) =>
          gridLine
            .map((gridNode) => {
              if (arePointsEqual(gridNode, this.startNode)) {
                return 'S';
              }

              if (arePointsEqual(gridNode, this.endNode)) {
                return 'E';
              }

              if (gridNode.isObstacle) {
                return 'O';
              }

              return '_';
            })
            .join('')
        )
        .join('\n')
    );
  };
}
