import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: absolute;
  top: 4px;
  display: flex;
  justify-content: center;
  padding-right: 0;
`;
