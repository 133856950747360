import {
  AssetHeader,
  useClassicOrAPMAssetQuery,
} from '@infield/features/asset';
import { AssetPropertiesView } from '@infield/features/asset/asset-properties';
import { ComponentScrollableContent } from '@infield/features/ui';
import { useBackButtonUrlContext } from '@infield/providers/back-button-url-provider';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import * as S from './elements';

export const AssetProperties: FC = () => {
  const { assetId: assetExternalId = '' } = useParams();
  const { navigateBack } = useBackButtonUrlContext();
  const { data: assetData, isLoading } =
    useClassicOrAPMAssetQuery(assetExternalId);
  const handleClose = () => {
    navigateBack();
  };

  return (
    <S.Container>
      {assetData && (
        <AssetHeader
          canBeExpanded={false}
          onClose={handleClose}
          assetData={assetData}
        />
      )}
      <ComponentScrollableContent>
        <AssetPropertiesView asset={assetData} isLoading={isLoading} />
      </ComponentScrollableContent>
    </S.Container>
  );
};
