import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  height: 380px;
  overflow: auto;
`;

export const ModelContainer = styled.div<{ selected?: boolean }>`
  padding: 16px;

  border-radius: 8px;
  border: 2px solid
    ${({ theme, selected }) =>
      selected
        ? theme['border--interactive--hover']
        : theme['border--interactive--default']};
`;
