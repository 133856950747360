import * as THREE from 'three';

import { CognitePointCloudModel } from '@cognite/reveal';
import {
  AnnotationModel,
  CogniteClient,
  AnnotationsBoundingVolume,
} from '@cognite/sdk';

const getAssetIdFromPointCloudAnnotation = (
  annotation: AnnotationModel
): string | number | undefined => {
  const annotationData = annotation.data as AnnotationsBoundingVolume;
  return annotationData.assetRef?.id;
};

const getAnnotationsForModel = async (
  client: CogniteClient,
  modelId: number
) => {
  const annotations = await client.annotations
    .list({
      filter: {
        annotatedResourceIds: [{ id: modelId }],
        annotatedResourceType: 'threedmodel',
        annotationType: 'pointcloud.BoundingVolume',
      },
      limit: 1000,
    })
    .autoPagingToArray({ limit: Infinity });
  return annotations.filter(
    (annotation) => getAssetIdFromPointCloudAnnotation(annotation) !== undefined
  );
};

export const getFirstAnnotationForAssetId = async (
  client: CogniteClient,
  modelId: number,
  assetId: number
): Promise<AnnotationModel | undefined> => {
  const annotations = await getAnnotationsForModel(client, modelId);
  return annotations.find(
    (annotation) => getAssetIdFromPointCloudAnnotation(annotation) === assetId
  );
};

const getBoundingBoxForAnnotation = async (
  threeDModel: CognitePointCloudModel,
  annotation: AnnotationModel
): Promise<THREE.Box3 | undefined> => {
  let boundingBox: THREE.Box3 | undefined = undefined;
  threeDModel.traverseStylableObjects((annotationMetadata) => {
    if (annotationMetadata.annotationId === annotation.id) {
      boundingBox = annotationMetadata.boundingBox;
    }
  });

  return boundingBox;
};

const getAssetAcdmInstanceBoundingBoxForPointCloudModel = async (
  client: CogniteClient,
  threeDModel: CognitePointCloudModel,
  modelId: number,
  assetId: number
): Promise<THREE.Box3 | undefined> => {
  /**
   * Notes for future travellers:
   *  - Asset to geometry mappings for PointClouds are stored in the Annotations API.
   *  - The geometries stored can to date be either Box or Cylinder.
   *  - The geometries stored in the Annotations API are stored in a different coordinate system
   *  than the 3D model by default. Additionally, the 3D model itself can have transformations set.
   *  - Once we have found the annotation id that maps to the asset we are interested in, the shortest
   *  path is therefore to ask Reveal to give us the bounding box for the equivalent annotation *with*
   *  all of the transformations applied.
   */
  const assetAnnotation = await getFirstAnnotationForAssetId(
    client,
    modelId,
    assetId
  );

  if (assetAnnotation === undefined) {
    return undefined;
  }

  return getBoundingBoxForAnnotation(threeDModel, assetAnnotation);
};

export default getAssetAcdmInstanceBoundingBoxForPointCloudModel;
