import { AbortError } from './errors';

type PendingTask<AsyncFn, AsyncFnResult> = {
  resolve: (result: AsyncFnResult) => void;
  reject: (error: any) => void;
  fn: AsyncFn;
  key?: string;
};

const DEFAULT_MAX_CONCURRENT_TASKS = 1;

// Essentially a semaphore that allows a limited number of tasks to run at once.
export default class QueuedTaskRunner<
  AsyncFn extends () => Promise<AsyncFnResult>,
  AsyncFnResult = Awaited<ReturnType<AsyncFn>>
> {
  private pendingTasks: PendingTask<AsyncFn, AsyncFnResult>[] = [];
  private currentPendingTasks: number = 0;
  private readonly maxConcurrentTasks: number = 1;
  private readonly name?: string = '';

  public constructor(
    maxConcurrentTasks = DEFAULT_MAX_CONCURRENT_TASKS,
    name?: string
  ) {
    this.maxConcurrentTasks = maxConcurrentTasks;
    this.name = name;
  }

  public schedule(
    fn: AsyncFn,
    options: { key?: string } = {}
  ): Promise<AsyncFnResult> {
    this.startTrackingTime();
    return new Promise((resolve, reject) => {
      if (options.key !== undefined) {
        this.pendingTasks
          .filter((task) => task.key === options.key)
          .forEach((task) => task.reject(new AbortError()));

        this.pendingTasks = this.pendingTasks.filter(
          (task) => task.key !== options.key
        );
      }

      this.pendingTasks.push({
        resolve,
        reject,
        fn,
        key: options.key,
      });
      this.attemptConsumingNextTask();
    });
  }

  public async attemptConsumingNextTask(): Promise<void> {
    if (this.pendingTasks.length === 0) {
      return;
    }

    if (this.currentPendingTasks >= this.maxConcurrentTasks) {
      return;
    }

    const pendingTask = this.pendingTasks.shift();
    if (pendingTask === undefined) {
      throw new Error('pendingTask is undefined, this should never happen');
    }

    this.currentPendingTasks++;
    const { fn, resolve, reject } = pendingTask;

    try {
      const result = await fn();
      resolve(result);
    } catch (e) {
      reject(e);
    } finally {
      this.currentPendingTasks--;

      this.tick();
      this.attemptConsumingNextTask();
    }
  }

  public clearQueue = (): void => {
    this.pendingTasks = [];
  };

  private startTime: number | null = null;

  private startTrackingTime = (): void => {
    if (this.startTime === null) {
      this.startTime = performance.now();
    }
  };

  private tick = (): void => {
    // eslint-disable-next-line no-console
    console.debug(
      `QueuedTaskRunner[${this.name}]: ${
        this.pendingTasks.length
      } pending tasks, ${
        this.currentPendingTasks
      } running tasks, total time: ${this.diffTime()}`
    );

    if (this.pendingTasks.length === 0) {
      this.startTime = null;
    }
  };

  private diffTime = (): number => {
    if (this.startTime === null) {
      return -1;
    }

    return performance.now() - this.startTime;
  };
}
