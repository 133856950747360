import { Flex, LoaderIcon } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

import type { ImageToUpload } from '../types';

import * as S from './elements';

interface Props {
  media: ImageToUpload;
  showDescription?: boolean;
  toBeUploaded?: boolean;
  isUploading?: boolean;
  mediaHeight?: number;
  mediaWidth?: number;
  onClick?: () => void;
}

export const MediaViewer: FC<Props> = ({
  media,
  onClick,
  showDescription = true,
  toBeUploaded = false,
  isUploading = false,
  mediaHeight,
  mediaWidth,
}) => {
  const mediaUrlType = media?.url.type;

  return (
    <S.MediaItem key={media.externalId} onClick={onClick}>
      <Flex direction="column" alignItems="center">
        <S.MediaItemWrapper height={mediaHeight} width={mediaWidth}>
          {mediaUrlType === 'video/mp4' ||
          mediaUrlType === `video/quicktime` ? (
            <S.VideoItem
              controls
              key={media.name}
              data-test-id="uploaded-video"
            >
              <source
                src={media.fileUrl ?? URL.createObjectURL(media.url)}
                type={media.type}
              />
            </S.VideoItem>
          ) : (
            <S.ImageItem
              key={media!.name}
              data-test-id="uploaded-image"
              src={media.fileUrl ?? URL.createObjectURL(media.url!)}
              maxHeight={mediaHeight}
            />
          )}
          {toBeUploaded && (
            <S.Overlay>
              {isUploading && <LoaderIcon className="cogs-icon--rotating" />}
            </S.Overlay>
          )}
        </S.MediaItemWrapper>
        {showDescription && (
          <S.Description>{media.metadata.description}</S.Description>
        )}
      </Flex>
    </S.MediaItem>
  );
};
