import type { FdmFile } from '@cognite/apm-client';
import type { FileFilterProps } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useAppConfigFiltersContext } from '../../../providers/app-config-filters-provider';

export const useFetchMediaList = (assetExternalId: string, limit = 500) => {
  const { mediaService } = useFDMServices();
  const { files: configFilters } = useAppConfigFiltersContext();

  const fileFilter = {
    assetExternalIds: [assetExternalId],
    ...configFilters?.cdfClassicFilters,
  } as FileFilterProps;

  const queryClient = useQueryClient();

  const invalidateQuery = () => {
    queryClient.invalidateQueries([QueryKeys.MEDIA_LIST, assetExternalId]);
  };

  const sortByNewestUploads = (a: number, b: number) => b - a;

  // TODO: Fetch Media from the right dataset
  return {
    invalidateQuery,
    ...useQuery<FdmFile[]>(
      [QueryKeys.MEDIA_LIST, assetExternalId, fileFilter],
      async () => {
        return mediaService
          .getAllMedia(limit, fileFilter)
          .then((media) =>
            media
              .filter((file) => Boolean(file.externalId))
              .sort((a, b) =>
                sortByNewestUploads(
                  b.uploadedTime?.valueOf() ?? 0,
                  a.uploadedTime?.valueOf() ?? 0
                )
              )
          );
      },
      {
        onError: (err) =>
          captureException(err, {
            level: 'error',
            tags: {
              queryKey: QueryKeys.MEDIA_LIST,
            },
          }),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    ),
  };
};
