import type { AssetFilterProps } from '@cognite/sdk';

export const assetsQueryKeys = {
  byIds(ids: number[]) {
    return ['assets', 'byIds', ...ids];
  },
  list(filter: AssetFilterProps, limit: number) {
    return ['timeseries', 'list', { filter, limit }];
  },
};
