import { useMutation } from '@tanstack/react-query';

import { Calculation } from '@cognite/calculation-backend';
import { useSDK } from '@cognite/sdk-provider';

import { createCalculation } from '../services';

export const useCreateCalculation = () => {
  const sdk = useSDK();
  return useMutation(async ({ definition }: { definition: Calculation }) => {
    return createCalculation(sdk, definition);
  });
};
