import { Flex, TagChip } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

type Props = {
  values?: string[];
};

export const StringListCell: FC<Props> = ({ values }: Props) => {
  return (
    <Flex gap={4}>
      {values?.map((status: string) => (
        <TagChip key={status} label={status} size="small" />
      ))}
    </Flex>
  );
};
