import { Chart } from '../../types';

export interface ChartsStorageServiceInterface {
  fetchPublicCharts: (projectId: string) => Promise<Chart[]>;
  fetchUserCharts: (
    projectId: string,
    userId: string,
    userEmail?: string
  ) => Promise<Chart[]>;
  fetchChart: (projectId: string, chartId: string) => Promise<Chart>;
  updateChart: (
    projectId: string,
    chartId: string,
    content: Partial<Chart>
  ) => Promise<Chart> | Promise<void>;
  createChart: ChartsStorageServiceInterface['updateChart'];
  deleteChart: (projectId: string, chartId: string) => Promise<void>;
}

export const shamefulDummyChartsStorageService: ChartsStorageServiceInterface =
  {
    fetchPublicCharts: async () => {
      throw new Error('Not implemented');
    },
    fetchUserCharts: async () => {
      throw new Error('Not implemented');
    },
    fetchChart: async () => {
      throw new Error('Not implemented');
    },
    updateChart: async () => {
      throw new Error('Not implemented');
    },
    createChart: async () => {
      throw new Error('Not implemented');
    },
    deleteChart: async () => {
      throw new Error('Not implemented');
    },
  };
