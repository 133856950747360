export const urlToImageCache = new Map<string, CanvasImageSource>();

// This code looks like it has a race-condition. It might not matter, but it's probably there :)
// aka if you get successive calls to getCachedImage before any of them have resolved
// - there would be multiple requests
// - the last response would end up being in cache.
// https://cognitedata.atlassian.net/browse/UFV-200
const getCachedImage = (
  url: string,
  onloadFn: (image: CanvasImageSource) => void
): void => {
  const cachedImage = urlToImageCache.get(url);
  if (cachedImage !== undefined) {
    onloadFn(cachedImage);
    return;
  }

  const image = new Image();
  image.src = url;
  // NOTE: We need to set the crossOrigin to "Anyonymous"; otherwise, we get an
  // error message from Konva when we call `imageNode.toDataUrl` on an image
  // annotation from a different origin.
  // See: https://konvajs.org/docs/posts/Tainted_Canvas.html)
  image.crossOrigin = 'Anonymous';
  image.onload = () => {
    urlToImageCache.set(url, image);
    onloadFn(image);
  };
};

export default getCachedImage;
