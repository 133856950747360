import styled from 'styled-components';

export const LabelReading = styled.div<{
  $disabled?: boolean;
  $selected?: boolean;
  $color: string;
  $backgroundColor: string;
  $size: 'small' | 'medium' | 'large';
}>`
  display: flex;
  border: 1px solid rgb(217, 217, 217);
  align-items: center;
  justify-content: center;
  padding: 4px 24px;
  border-radius: 6px;
  ${({ $size }) => $size === 'small' && 'height: 32px; font-size: 14px;'};
  ${({ $size }) => $size === 'medium' && 'height: 38px; font-size: 14px;'};
  ${({ $size }) => $size === 'large' && 'height: 44px; font-size: 16px;'};
  color: ${({ $color }) => $color};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  ${({ $selected, $backgroundColor }) =>
    $selected && `background-color: ${$backgroundColor}`};

  ${({ $selected, $disabled }) => {
    if (!$selected && !$disabled) {
      return `&:hover {
        background-color: rgba(102, 102, 102, 0.14);
      }`;
    }
  }}
`;
