import type { ViewConfigDTO } from '@cognite/apm-config';

export const getisViewMappingsChanged = (
  currentViewMappings: ViewConfigDTO[],
  initialViewMappings?: ViewConfigDTO[]
) => {
  return (
    JSON.stringify(initialViewMappings) !== JSON.stringify(currentViewMappings)
  );
};
