import type { LocationFilterDTO } from '@cognite/apm-config';
import { PageHeader } from '@cognite/cogs-lab';
import { Button, Input, SaveIcon } from '@cognite/cogs.js-v10';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import {
  ConfigPageContentWrapper,
  LocationInfieldContentWrapper,
} from '../elements';
import * as S from '../elements';
import { updateLocation } from '../utils/update-location';

interface Props {
  location: LocationFilterDTO | undefined;
  isSaving: boolean;
  onSave: (location: LocationFilterDTO) => void;
}

export const LocationGeneral: FC<Props> = ({ location, isSaving, onSave }) => {
  const [locationName, setLocationName] = useState<string>('');
  const [locationDescription, setLocationDescription] = useState<string>('');

  useEffect(() => {
    if (location?.name !== undefined) {
      setLocationName(location.name);
    }
  }, [location?.name]);

  useEffect(() => {
    if (location?.description !== undefined) {
      setLocationDescription(location.description);
    }
  }, [location?.description]);

  const isNameChanged = location?.name !== locationName;
  const isDescriptionChanged = location?.description !== locationDescription;

  const hasLocationChanged = isNameChanged || isDescriptionChanged;

  const handleSave = () => {
    if (location === undefined) {
      return;
    }

    const updates: Partial<LocationFilterDTO> = {
      name: locationName,
      description: locationDescription,
    };

    const updatedLocation = updateLocation(location, updates);

    onSave(updatedLocation);
  };

  return (
    <LocationInfieldContentWrapper>
      <PageHeader fullWidth>
        <PageHeader.TitleArea
          description="Configure name and description for this location"
          title="Location description"
        />
        <PageHeader.Actions>
          <Button
            type="primary"
            icon={<SaveIcon />}
            loading={isSaving}
            disabled={!hasLocationChanged}
            onClick={handleSave}
          >
            Save
          </Button>
        </PageHeader.Actions>
      </PageHeader>
      <ConfigPageContentWrapper>
        <S.ContentContainer>
          <Input
            label="Name"
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
            status={isNameChanged ? 'warning' : undefined}
            fullWidth
          />
          <Input
            label="Description"
            value={locationDescription}
            onChange={(e) => setLocationDescription(e.target.value)}
            status={isDescriptionChanged ? 'warning' : undefined}
            fullWidth
          />
        </S.ContentContainer>
      </ConfigPageContentWrapper>
    </LocationInfieldContentWrapper>
  );
};
