import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { Chameleon } from './chameleon';
import { useHash } from './util';

export type UserInfo = {
  email: string;
  language: string;
  idpType: string;
  [key: string]: string;
};

export type ChameleonProviderProps = {
  userInfo: UserInfo;
  project: string;
  idpType?: string;
  children?: React.ReactNode;
  disableChameleonList?: string[];
};

export type ChameleonContextType = {
  chameleonTrack?: (trackEvent: string) => void;
};

export const ChameleonContext = React.createContext<ChameleonContextType>({});

export const useChameleonTrack = () => {
  const context = React.useContext(ChameleonContext);
  if (context === undefined) {
    throw new Error(
      'useChameleonTrack must be used within a ChameleonProvider'
    );
  }
  return context;
};

export const ChameleonProvider: React.FC<
  PropsWithChildren<ChameleonProviderProps>
> = ({ children, userInfo, project, disableChameleonList }) => {
  const chameleon = useRef(new Chameleon(disableChameleonList));

  const { data: hashedEmail } = useHash(userInfo.email);

  if (sessionStorage.getItem('isE2eTest') !== 'true') {
    chameleon.current.initialize();
  }

  useEffect(() => {
    if (hashedEmail && project) {
      chameleon.current.identify(hashedEmail, {
        cdfProject: project,
        idpType: userInfo.idpType,
        language: userInfo.language,
        ...((userInfo.email.endsWith('@cognitedata.com') ||
          userInfo.email.endsWith('@cognite.com')) && {
          email: userInfo.email,
        }),
      });
    }
  }, [
    hashedEmail,
    project,
    userInfo.email,
    userInfo.idpType,
    userInfo.language,
  ]);

  const chameleonTrack = useCallback(
    (trackEvent: string) => {
      chameleon.current.track(trackEvent);
    },
    [chameleon]
  );

  return (
    <ChameleonContext.Provider value={{ chameleonTrack }}>
      {children}
    </ChameleonContext.Provider>
  );
};
