import * as THREE from 'three';

import { CogniteCadModel } from '@cognite/reveal';
import { CogniteClient } from '@cognite/sdk';

const getAssetAcdmInstanceBoundingBoxForCadModel = async (
  client: CogniteClient,
  threeDModel: CogniteCadModel,
  modelId: number,
  revisionId: number,
  assetId: number
): Promise<THREE.Box3> => {
  const assetMappings = await client.assetMappings3D
    .list(modelId, revisionId, { assetId })
    .autoPagingToArray({
      limit: Infinity,
    });

  const filteredAssetMappings = assetMappings.filter(
    (mapping) => mapping.treeIndex !== undefined
  );

  const boundingBoxNodes = await threeDModel.getBoundingBoxesByNodeIds(
    filteredAssetMappings.map((m) => m.nodeId)
  );

  const boundingBox = boundingBoxNodes.reduce((accl: THREE.Box3, box) => {
    return box ? accl.union(box) : accl;
  }, new THREE.Box3());

  return boundingBox;
};

export default getAssetAcdmInstanceBoundingBoxForCadModel;
