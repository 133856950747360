import type { APMAsset } from '@cognite/apm-client';
import type { Asset } from '@cognite/sdk';
import type { Step } from '@infield/features/observation/types';
import { AssetSearchInput } from '@infield/features/search';
import type { FC } from 'react';

import { ObservationDetailsStepInfo } from '../observation-details-step-wrapper/observation-details-step-info';

import * as S from './elements';

interface Props {
  step: Step;
  asset?: APMAsset;
  isLocked?: boolean;
  onChange: (asset?: Asset, apmAsset?: APMAsset) => void;
}

export const ObservationDetailsStepAsset: FC<Props> = ({
  step,
  asset,
  isLocked,
  onChange,
}) => {
  return (
    <>
      <ObservationDetailsStepInfo step={step} />
      <S.SearchWrapper>
        <AssetSearchInput
          selected={asset}
          onSelect={(classicAsset, apmAsset) =>
            onChange(classicAsset, apmAsset)
          }
          searchResultPosition="top"
          disabled={isLocked}
        />
      </S.SearchWrapper>
    </>
  );
};
