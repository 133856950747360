import clamp from 'lodash/clamp';

import type { UnifiedViewer } from '../UnifiedViewer';
import UnifiedViewerEventType from '../UnifiedViewerEventType';
import getInputTypeFromWheelEvent, {
  WheelEventInputType,
} from '../utils/getInputTypeFromWheelEvent';

import Interactions from './Interactions';

export default class MultiDocumentInteractions extends Interactions {
  public constructor(unifiedViewer: UnifiedViewer) {
    super(unifiedViewer);
    unifiedViewer.host.addEventListener('wheel', this.onStageMouseWheel);
    unifiedViewer.host.addEventListener('mousedown', this.onStageMouseDown);
    unifiedViewer.host.addEventListener('mousemove', this.onStageMouseMove);
    unifiedViewer.host.addEventListener('mouseup', this.onStageMouseUp);
    unifiedViewer.host.addEventListener('contextmenu', this.onStageContextMenu);
    unifiedViewer.host.addEventListener('mouseover', this.onStageMouseOver);
    unifiedViewer.host.addEventListener('mouseout', this.onStageMouseOut);
  }

  private onStageMouseWheel = (
    event: WheelEvent & { wheelDeltaY?: number }
  ) => {
    this.stage.setPointersPositions(event);
    event.preventDefault();
    const inputType = getInputTypeFromWheelEvent(event);
    if (
      event.ctrlKey ||
      event.metaKey ||
      inputType === WheelEventInputType.MOUSE_WHEEL ||
      inputType === WheelEventInputType.TOUCHPAD_PINCH_TO_ZOOM
    ) {
      const scaleFactor =
        inputType === WheelEventInputType.MOUSE_WHEEL
          ? Interactions.MOUSE_WHEEL_SCALE_FACTOR
          : Interactions.TOUCHPAD_SCALE_FACTOR;
      this.onZoom(scaleFactor * event.deltaY, true);
      return;
    }

    this.unifiedViewer.emit(UnifiedViewerEventType.ON_PAN_START);
    const dx = clamp(
      Interactions.TOUCHPAD_PAN_FACTOR * event.deltaX,
      -Interactions.TOUCHPAD_PAN_DELTA_MAX,
      Interactions.TOUCHPAD_PAN_DELTA_MAX
    );
    const dy = clamp(
      Interactions.TOUCHPAD_PAN_FACTOR * event.deltaY,
      -Interactions.TOUCHPAD_PAN_DELTA_MAX,
      Interactions.TOUCHPAD_PAN_DELTA_MAX
    );

    const x = this.stage.x() - dx;
    const y = this.stage.y() - dy;

    this.stage.x(x);
    this.stage.y(y);
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_PAN_END);
    this.unifiedViewer.onViewportChange();
  };
}
