import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { Modal } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { MultipleMediaContent } from './multiple-media-content';
import { SingleMediaContent } from './single-media-content';

type Props = {
  fileInstanceIds?: InstanceOrExternalId[];
  onClose: () => void;
};

export const MediaCollectionViewer: FC<Props> = ({
  fileInstanceIds,
  onClose,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.fileViewer);
  const isOnlyOneMedia = fileInstanceIds?.length === 1;

  return (
    <Modal
      title={t('MDEIA_COLLECTION_VIEWER_HEADER', 'Attached images')}
      hideFooter
      size="large"
      visible
      onCancel={onClose}
    >
      {isOnlyOneMedia ? (
        <SingleMediaContent fileInstanceId={fileInstanceIds[0]} />
      ) : (
        <MultipleMediaContent fileInstanceIds={fileInstanceIds} />
      )}
    </Modal>
  );
};
