import type { UserPreferenceLocation } from '@cognite/apm-client';
import type { LocationFilterDTO } from '@cognite/apm-config';

export const getSelectableIdmLocationOptions = (
  selectableIdmLocations: LocationFilterDTO[] | undefined
): UserPreferenceLocation[] => {
  return (
    selectableIdmLocations
      ?.map((location) => ({
        externalId: location.externalId,
        assetExternalId: undefined,
        name: location.name ?? '',
      }))
      .sort((a, b) => a.name.localeCompare(b.name)) || []
  );
};
