import { ChameleonProvider } from '@cognite/chameleon';
import { useAuthContext } from '@cognite/e2e-auth';
import { useTranslation } from '@infield/features/i18n';
import type { FC, PropsWithChildren } from 'react';

export const ChameleonWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { authState } = useAuthContext();
  const languageContainer = useTranslation();
  const project = authState?.projectInfo.id ?? '';
  const authType = authState.projectInfo.authType ?? '';

  return (
    <ChameleonProvider
      project={project}
      userInfo={{
        ...authState.user,
        id: authState.user.id.toString() ?? '',
        idpType: authType === 'azureAD' ? 'AZURE_AD' : 'COGIDP',
        language: languageContainer.i18n.language ?? 'en',
      }}
    >
      {children}
    </ChameleonProvider>
  );
};
