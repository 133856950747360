import type { InFieldUser } from '@cognite/apm-client';
import { Body, ErrorIcon, Flex, LoaderIcon, Menu } from '@cognite/cogs.js-v10';
import { useDisciplines } from '@infield/features/app-config';
import type { Discipline } from '@infield/features/app-config/types';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useSearchUserQuery } from '@infield/features/user/hooks';
import { useUsersInfiniteQuery } from '@infield/features/user/hooks/use-query/use-users-infinite-query';
import { useDebounce } from '@infield/hooks/use-debounce';
import type { FC } from 'react';
import { useState } from 'react';

import { DisciplineIcon } from '../discipline-icon';
import { UserIcon } from '../user-icon';

import * as S from './elements';

interface Props {
  query: string;
  selectedUsers: InFieldUser[];
  selectedDisciplines: Discipline[];
  onAssignUser: (selectedUser: InFieldUser) => void;
  onAssignDiscipline: (discipline: Discipline) => void;
  onDone: () => void;
}

const DEFAULT_NUMBER_OF_DISCIPLINES_TO_LOAD = 3;
const DEFAULT_NUMBER_OF_USERS_TO_LOAD = 3;

export const AssignmentSelect: FC<Props> = ({
  query,
  selectedUsers,
  selectedDisciplines,
  onAssignUser,
  onAssignDiscipline,
  onDone,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.assignment);
  const debouncedQuery = useDebounce(query);
  const [numberOfDisciplinesToLoad, setNumberOfDisciplinesToLoad] = useState(
    DEFAULT_NUMBER_OF_DISCIPLINES_TO_LOAD
  );
  const { data: searchedUsers, isLoading: isSearchUserLoading } =
    useSearchUserQuery(debouncedQuery);
  const {
    users: allUsers,
    hasNextPage: usersHasNextPage,
    fetchNextPage: usersFetchNextPage,
  } = useUsersInfiniteQuery(DEFAULT_NUMBER_OF_USERS_TO_LOAD);
  const { configuredDisciplines } = useDisciplines();
  const userList = debouncedQuery ? searchedUsers || [] : allUsers;

  const disciplineList = debouncedQuery
    ? configuredDisciplines?.filter((appConfigDiscipline) =>
        appConfigDiscipline.name
          .toLowerCase()
          .includes(debouncedQuery.toLowerCase())
      )
    : configuredDisciplines?.slice(0, numberOfDisciplinesToLoad);

  const disciplineHasNext =
    !debouncedQuery &&
    configuredDisciplines &&
    disciplineList &&
    configuredDisciplines?.length > disciplineList?.length;

  const isUserSelected = (user: InFieldUser) =>
    selectedUsers
      .map((selectedUser) => selectedUser.externalId)
      .includes(user.externalId);

  const isDisciplineSelected = (discipline: Discipline) =>
    selectedDisciplines
      .map((selectedDiscipline) => selectedDiscipline.externalId)
      .includes(discipline.externalId);

  const isUserResultSuccess = !isSearchUserLoading && userList.length > 0;

  return (
    <Menu>
      <S.MenuContent>
        <Menu.Section label={t('disciplines', 'Disciplines')}>
          <>
            {(!disciplineList || disciplineList?.length === 0) && (
              <S.EmptyResult>
                <ErrorIcon />
                <Body size="x-small">
                  {t('noDisciplinesFound', 'No disciplines found')}
                </Body>
              </S.EmptyResult>
            )}
            {disciplineList &&
              disciplineList.map((discipline) => (
                <Menu.Item
                  hideTooltip
                  key={discipline.externalId}
                  toggled={isDisciplineSelected(discipline)}
                  onClick={() => onAssignDiscipline(discipline)}
                >
                  <DisciplineIcon>{discipline.name}</DisciplineIcon>
                </Menu.Item>
              ))}
            {disciplineHasNext && (
              <S.LoadMore
                type="ghost"
                onClick={() => setNumberOfDisciplinesToLoad((prev) => prev + 3)}
              >
                {t('loadMore', 'Load more')}
              </S.LoadMore>
            )}
          </>
        </Menu.Section>
        <Menu.Section label={t('individuals', 'Individuals')}>
          <>
            {isSearchUserLoading && (
              <Flex direction="column" alignItems="center">
                <LoaderIcon className="cogs-icon--rotating" />
                <Body size="x-small">
                  {t('searchingUsers', 'Searching for users')}
                </Body>
              </Flex>
            )}
            {!isSearchUserLoading && !isUserResultSuccess && (
              <S.EmptyResult>
                <ErrorIcon />
                <Body size="x-small">
                  {t('noUsersFound', 'No users found')}
                </Body>
              </S.EmptyResult>
            )}
            {isUserResultSuccess &&
              userList.map((user) => (
                <Menu.Item
                  hideTooltip
                  key={user.externalId}
                  toggled={isUserSelected(user)}
                  onClick={() => onAssignUser(user)}
                >
                  <UserIcon>
                    <Flex direction="column">
                      <div>{user.name}</div>
                      <Body size="x-small">{user.email}</Body>
                    </Flex>
                  </UserIcon>
                </Menu.Item>
              ))}
            {!debouncedQuery && usersHasNextPage && userList && (
              <S.LoadMore type="ghost" onClick={() => usersFetchNextPage()}>
                {t('loadMore', 'Load more')}
              </S.LoadMore>
            )}
          </>
        </Menu.Section>
      </S.MenuContent>

      <Menu.Footer>
        <S.LoadMore type="primary" onClick={onDone}>
          {t('done', 'Done')}
        </S.LoadMore>
      </Menu.Footer>
    </Menu>
  );
};
