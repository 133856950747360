import { useQuery } from '@tanstack/react-query';

import { DEFAULT_CORE_TIME_SERIES_VIEW } from '../constants';
import { ScheduledCalculationTask } from '../models';
import { fetchInstance } from '../services/fetchInstance';
import { useAlphaSDK } from '../utils/alphaSDKProvider';

export const useScheduledCalculationTargetTimeSeries = (
  task: ScheduledCalculationTask
) => {
  const sdk = useAlphaSDK();

  const queryKey = [
    'scheduled-calculation',
    'target-time-series',
    task.externalId,
  ];

  const queryFn = async () => {
    if (task.targetTimeseriesInstanceId) {
      const { externalId, space } = task.targetTimeseriesInstanceId;

      const instance = await fetchInstance(
        sdk,
        { instanceType: 'node', externalId, space },
        DEFAULT_CORE_TIME_SERIES_VIEW
      );

      return instance;
    } else {
      const timeSeries = await sdk.timeseries.retrieve([
        { externalId: task.targetTimeseriesExternalId! },
      ]);
      if (timeSeries.length !== 1) {
        throw new Error(
          `Expected to retrieve one time series. Got ${timeSeries.length} instead`
        );
      }
      return timeSeries[0];
    }
  };

  const query = useQuery(queryKey, queryFn, {
    enabled: !!task,
  });

  return query;
};
