import type { Domain } from '@infield/features/trends/trends-chart/types';

const isValidYSubDomain = (domain: Domain | undefined) => {
  return (
    domain &&
    domain[0] !== undefined &&
    domain[1] !== undefined &&
    Number.isFinite(domain[0]) &&
    Number.isFinite(domain[1]) &&
    !Number.isNaN(domain[0]) &&
    !Number.isNaN(domain[1])
  );
};

export const getYDomain = (
  ySubDomain: Domain | undefined
): Domain | undefined => {
  if (!ySubDomain || !isValidYSubDomain(ySubDomain)) {
    return undefined;
  }

  if (ySubDomain[0] === ySubDomain[1]) {
    return [
      Math.floor((1 / 2) * ySubDomain[0]),
      Math.ceil((3 / 2) * ySubDomain[0]),
    ];
  }
  return [Math.floor(ySubDomain[0]), Math.ceil(ySubDomain[1])];
};
