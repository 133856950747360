import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const IconContainer = styled(Flex)`
  width: 20px;
  height: 20px;
  align-items: center;
  background-color: ${({ theme }) => theme['border--status-neutral--muted']};
  padding: 1px 4px;
  border-radius: 3px;
`;
