import keyBy from 'lodash/keyBy';

import isNotUndefined from './isNotUndefined';

const getConnectedComponents = <
  Node extends { id: string },
  Edge extends { fromId: string; toId: string }
>(
  nodes: Node[],
  graph: {
    nodes: Node[];
    edges: Edge[];
  }
): Node[][] => {
  const components: Node[][] = [];
  const visitedNodes = new Set<string>();
  const nodesById = keyBy(graph.nodes, 'id');

  const dfs = (node: Node, nodesInConnectedComponent: Node[]) => {
    if (visitedNodes.has(node.id)) {
      return;
    }
    visitedNodes.add(node.id);
    nodesInConnectedComponent.push(node);

    const predecessors = graph.edges
      .filter(({ toId }) => toId === node.id)
      .map(({ fromId }) => nodesById[fromId])
      .filter(isNotUndefined);
    predecessors.forEach((n) => dfs(n, nodesInConnectedComponent));

    const successors = graph.edges
      .filter(({ fromId }) => fromId === node.id)
      .map(({ toId }) => nodesById[toId])
      .filter(isNotUndefined);
    successors.forEach((n) => dfs(n, nodesInConnectedComponent));
  };

  nodes.forEach((node) => {
    const nodesInConnectedComponent: Node[] = [];
    dfs(node, nodesInConnectedComponent);
    if (nodesInConnectedComponent.length > 0) {
      components.push(nodesInConnectedComponent);
    }
  });

  return components;
};

export default getConnectedComponents;
