import { ChartTimeSeries } from '../../../types';

export const cleanTimeseriesCollection = <T = ChartTimeSeries>(
  tsCollection?: T[]
): T[] | undefined => {
  return tsCollection?.map((ts) => {
    return {
      ...ts,
      statisticsCalls: undefined,
      dataProfilingCalls: undefined,
    };
  }) as T[];
};
