import type { ViewDefinition, ViewPropertyDefinition } from '@cognite/sdk';
import { SUPPORTED_FIELD_TYPES } from '@infield/features/activities/consts';

import type { FieldInfo } from './types';
/**
 * Extracts table fields from a given view definition. Filters out non-string arrays and fields that are blacklisted.
 *
 * @param {ViewDefinition} viewDefinition - The view definition to extract fields from.
 * @param {string[]} blacklistedFields - An array of field names to exclude from the result.
 *
 * @returns {FieldInfo[]} An array of field information objects. Each object includes the field name, type, and a boolean indicating if it's a list.
 *
 */
export const getTableFieldsFromViewDefinition = (
  viewDefinition: ViewDefinition,
  blacklistedFields: string[]
): FieldInfo[] => {
  return Object.entries(viewDefinition.properties)
    .filter(([name, _]) => name && !blacklistedFields.includes(name))
    .filter(([_, value]) => {
      if ('type' in value && 'type' in value.type) {
        const isString = value.type.type === 'text';

        const isArray = 'list' in value.type && value.type.list;
        return (
          SUPPORTED_FIELD_TYPES.includes(value.type.type) &&
          // Allow lists for string properties
          (!isArray || isString)
        );
      }

      return false;
    })
    .map(([name, value]) => {
      const valueTyped = value as ViewPropertyDefinition;
      const isList = Boolean('list' in valueTyped.type && valueTyped.type.list);
      return {
        name,
        label: valueTyped.name,
        type: valueTyped.type.type,
        list: isList,
      };
    });
};
