import type { Template, TemplateItem } from '@cognite/apm-client';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserQuery } from '@infield/features/user';
import { useFDMServices } from '@infield/providers/fdm-services';
import { dmsItemIngestionLimit } from '@infield/utils/dms-requests';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';

// This hook optimistically update and retain the order of the newly provided operation list
export const useTemplateItemsReorderUpsert = () => {
  const { templateService } = useFDMServices();
  const queryClient = useQueryClient();
  const { data: user } = useCurrentUserQuery();
  const metrics = useMetrics(METRICS_NAMESPACES.auditTemplateItem);

  return useMutation<TemplateItem[], Error, { templateItems: TemplateItem[] }>(
    async ({ templateItems }) => {
      const templateItemsToUpsert = templateItems.map((item) => {
        const {
          measurements: _measurements,
          conditionalActions: _conditionalActions,
          space: _space,
          ...templateItem
        } = item;
        return templateItem;
      });

      const batchedOperations = chunk(
        templateItemsToUpsert,
        dmsItemIngestionLimit
      );

      await Promise.all(
        batchedOperations.map((batch) =>
          templateService
            .updateTemplateItems(batch, user!)
            .then((result) => result.data.items)
        )
      );

      return templateItemsToUpsert;
    },
    {
      onMutate: async ({ templateItems }) => {
        const queryCache = queryClient.getQueryCache();

        // Get the active queryKey
        const activeTemplateQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        if (activeTemplateQuery.length > 0) {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries([QueryKeys.TEMPLATE]);

          const [{ queryKey: activeQueryKey }] = activeTemplateQuery;

          // Snapshot the previous value
          const previousTemplate = queryClient.getQueryData(
            activeQueryKey
          ) as Template;

          const previousTemplateItems = previousTemplate.templateItems ?? [];

          // Optimistically update to the new value
          const templateItemsUpdatedOptimistically = templateItems.map(
            (templateItem) => {
              const prevOperation = previousTemplateItems.find(
                (po) => templateItem.externalId === po.externalId
              );
              // Update existing item
              if (prevOperation) {
                return {
                  ...prevOperation,
                  ...templateItem,
                };
              }
              return templateItem;
            }
          );

          const updatedTemplate = {
            ...previousTemplate,
            templateItems: templateItemsUpdatedOptimistically,
          };

          queryClient.setQueryData(activeQueryKey, updatedTemplate);

          // Return a context object with the snapshotted value
          return previousTemplate;
        }
      },
      onError: (err, previousTemplate) => {
        const queryCache = queryClient.getQueryCache();

        // Get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        // Set cache back to its original state
        if (activeQuery.length > 0) {
          const [{ queryKey: activeQueryKey }] = activeQuery;

          queryClient.setQueryData(activeQueryKey, previousTemplate);
        }
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-template-items-reorder-upsert-mutation',
          },
        });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE],
        });
      },
      onSuccess: async (_, { templateItems }) => {
        templateItems.forEach((templateItem) => {
          metrics.track('Update', templateItem);
        });
      },
    }
  );
};
