export enum z {
  'MAXIMUM' = 9999,
  'SEARCH_OVERLAY' = 8000,
  'MOBILE_SIDEBAR' = 7002,
  'MOBILE_SIDEBAR_OVERLAY' = 7001,
  'INFOBAR_OVERLAY' = 7000,
  'ACTION_MENU' = 2000,
  'OVERLAY' = 1000,
  'INFOBAR_LOADED_CHECKLIST_OVERLAY' = 900,
  'COPILOT' = 900,
  'TABLE_HEADER' = 800,
  'THREED_MOBILE_ASSET_INFO' = 180,
  'THREED_MOBILE_BOTTOM_GRADIENT' = 150,
  'THREED_TOOLBAR' = 100,
  'DEFAULT' = 1,
  'MINIMUM' = 0,
}
