import type { DateRangeType } from '@cognite/apm-observation';
import { Body, Button, ChecklistIcon, Flex, Modal } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { DateRangePicker } from '@infield/features/ui';
import dayjs from 'dayjs';
import { useState } from 'react';
import type { FC } from 'react';

interface Props {
  visible: boolean;
  onCreateChecklist: (startTime: string, endTime: string) => void;
  isCreateChecklistLoading: boolean;
  onClose: () => void;
}

export const CreateChecklistModal: FC<Props> = ({
  visible,
  onCreateChecklist,
  isCreateChecklistLoading,
  onClose,
}) => {
  const { Trans, t } = useTranslation(LOCIZE_NAMESPACES.template);
  const [dateFilter, setDateFilter] = useState<DateRangeType>([
    dayjs().startOf('day'),
    dayjs().endOf('day'),
  ]);

  const handleCreateChecklist = () => {
    if (dateFilter[0] && dateFilter[1]) {
      onCreateChecklist(
        dateFilter[0].utc().toISOString(),
        dateFilter[1].utc().toISOString()
      );
    }
  };

  return (
    <Modal
      visible={visible}
      icon={<ChecklistIcon />}
      size="small"
      title={t(
        'TEMPLATE_DIALOGUE_OPEN_AS_CHECKLIST_TITLE',
        'Open as checklist?'
      )}
      hideFooter
      closable={false}
      disableEnforceFocus
    >
      <Flex
        direction="column"
        justifyContent="space-between"
        gap={8}
        data-testid="template-modal-create-checklist"
      >
        <Flex direction="column" gap={16}>
          <Trans i18nKey="TEMPLATE_DIALOGUE_OPEN_AS_CHECKLIST_DESCRIPTION">
            InField can open a checklist from this template.
            <br />
            <br />
            You can begin performing this activity immediately by assigning it
            to a discipline or users from the Prepare page, or filter on “All
            activities” on the Perform page.
          </Trans>
          <Flex direction="column" gap={4}>
            <Body size="medium" strong>
              {t(
                'TEMPLATE_DIALOGUE_OPEN_AS_CHECKLIST_TIME_TITLE',
                'Planned start & end time'
              )}
            </Body>
            <DateRangePicker
              defaultValue={dateFilter}
              fullWidth
              onChange={setDateFilter}
            />

            <Body size="x-small">
              {t(
                'TEMPLATE_DIALOGUE_OPEN_AS_CHECKLIST_TIME_DESCRIPTION',
                'This is when you expect to start and finish your work, and is only used for filtering in InField. You can use the checklist immediately.'
              )}
            </Body>
          </Flex>
        </Flex>
        <Flex justifyContent="end" gap={8}>
          <Button
            type="ghost"
            onClick={onClose}
            data-testid="template-modal-create-checklist-cancel-button"
          >
            {t('TEMPLATE_DIALOGUE_OPEN_AS_CHECKLIST_BUTTON_CANCEL', 'Cancel')}
          </Button>
          <Button
            type="primary"
            onClick={handleCreateChecklist}
            loading={isCreateChecklistLoading}
          >
            {t(
              'TEMPLATE_DIALOGUE_OPEN_AS_CHECKLIST_BUTTON_CREATE_NEW_CHECKLIST',
              'Create new checklist'
            )}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
