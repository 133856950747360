import type { Step } from '../types';

import { useObservationConfigByLocation } from './use-observation-config-by-location';

export const useObservationFields = () => {
  const { observationConfig, defaultObservationConfig, isSuccess } =
    useObservationConfigByLocation();
  let steps: Step[] = [];

  if ((observationConfig || defaultObservationConfig) && isSuccess) {
    const { fieldConfigurations } =
      observationConfig || defaultObservationConfig;
    steps = [
      {
        name: fieldConfigurations.files.displayTitle,
        description: fieldConfigurations.files.displayDescription,
        isRequired: fieldConfigurations.files.isRequired,
        key: 'files',
      },
      {
        name: fieldConfigurations.description.displayTitle,
        description: fieldConfigurations.description.displayDescription,
        isRequired: fieldConfigurations.description.isRequired,
        key: 'description',
      },
      {
        name: fieldConfigurations.asset.displayTitle,
        description: fieldConfigurations.asset.displayDescription,
        isRequired: fieldConfigurations.asset.isRequired,
        key: 'asset',
      },
      {
        name: fieldConfigurations.priority.displayTitle,
        description: fieldConfigurations.priority.displayDescription,
        isRequired: fieldConfigurations.priority.isRequired,
        options: fieldConfigurations.priority.options,
        key: 'priority',
      },
      {
        name: fieldConfigurations.type.displayTitle,
        description: fieldConfigurations.type.displayDescription,
        isRequired: fieldConfigurations.type.isRequired,
        options: fieldConfigurations.type.options,
        key: 'type',
      },
      {
        name: fieldConfigurations.troubleshooting.displayTitle,
        description: fieldConfigurations.troubleshooting.displayDescription,
        isRequired: fieldConfigurations.troubleshooting.isRequired,
        key: 'troubleshooting',
      },
    ];
  }

  return {
    observationFields: steps,
  };
};
