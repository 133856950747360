import type { OpenedObservationDetails } from '@infield/features/observation';
import { useBackButtonUrlContext } from '@infield/providers/back-button-url-provider';
import type { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ChecklistItemObservationList } from '../checklist-item-observation-list';

import * as S from './elements';

export const ChecklistItemObservationListMobile: FC = () => {
  const navigate = useNavigate();
  const { navigateBack } = useBackButtonUrlContext();
  const location = useLocation();

  const { checklistItemId } = useParams();

  const handleOpenObservation = ({
    observationExternalId,
    asset,
  }: OpenedObservationDetails) => {
    if (observationExternalId) {
      navigate(`/observation/${encodeURIComponent(observationExternalId)}`, {
        state: { from: location.pathname },
      });
    } else {
      navigate('/observation', {
        state: {
          from: location.pathname,
          asset,
          checklistItemExternalId: checklistItemId,
        },
      });
    }
  };

  return (
    <S.FullHeightWrapper>
      <ChecklistItemObservationList
        checklistItemExternalId={checklistItemId}
        onCancel={() => navigateBack()}
        onOpenObservation={handleOpenObservation}
      />
    </S.FullHeightWrapper>
  );
};
