export const QueryKeys = {
  ACTIVITY_AGGREGATE_BY_LOCATION: 'ACTIVITY_AGGREGATE_BY_LOCATION',
  ACTIVITY_AGGREGATE_BY_TYPE: 'ACTIVITY_AGGREGATE_BY_TYPE',
  ACTIVITY_DETAILS: 'ACTIVITY_DETAILS',
  ACTIVITY_INTROSPECTION: 'ACTIVITY_INTROSPECTION',
  ACTIVITY_INFINITE_WITH_CHECKLIST: 'ACTIVITY_INFINITE_WITH_CHECKLIST',
  ADS_LOCATION_CONFIG: 'ADS_LOCATION_CONFIG',
  ANNOTATIONS: 'ANNOTATIONS',
  APM_ASSET: 'APM_ASSET',
  APM_ASSET_LIST: 'APM_ASSET_LIST',
  APP_CONFIG: 'APP_CONFIG',
  APP_CONFIGURATOR_QUERY: 'APP_CONFIGURATOR_QUERY',
  ASSET: 'ASSET',
  ASSET_ACTIVITIES: 'ASSET_ACTIVITIES',
  ASSET_DOCUMENTS: 'ASSET_DOCUMENTS',
  ASSET_HIERARCHY_SEARCH: 'ASSET_HIERARCHY_SEARCH',
  ASSET_NOTIFICATIONS: 'ASSET_NOTIFICATIONS',
  ASSET_SEARCH: 'ASSET_SEARCH',
  CHECKLIST: 'CHECKLIST',
  CHECKLIST_ADMIN_QUERY: 'CHECKLIST_ADMIN_QUERY',
  CHECKLIST_ITEM_OBSERVATIONS: 'CHECKLIST_ITEM_OBSERVATIONS',
  CHECKLIST_ITEM_STATUS: 'CHECKLIST_ITEM_STATUS',
  CHECKLISTS_LIST: 'CHECKLISTS_LIST',
  DATAMODEL_VERSION: 'DATAMODEL_VERSION',
  DATAPOINT: 'DATAPOINT',
  DOCUMENTS: 'DOCUMENTS',
  FDM_SPACE: 'FDM_SPACE',
  FDM_SPACE_BYID: 'FDM_SPACE_BYID',
  GROUPS: 'GROUPS',
  INFIELD_LOCATION_CONFIG: 'INFIELD_LOCATION_CONFIG',
  INFIELD_LOCATION_CONFIG_FROM_SERVICE: 'INFIELD_LOCATION_CONFIG_FROM_SERVICE',
  ISIDM: 'IS_IDM',
  MAINTENANCE_AGGREGATE_FILTER_OPTIONS: 'MAINTENANCE_AGGREGATE_FILTER_OPTIONS',
  MEDIA_BLOB: 'MEDIA_BLOB',
  MEDIA_ITEM: 'MEDIA_ITEM',
  MEDIA_LIST: 'MEDIA_LIST',
  NOTIFICATION_DETAILS: 'NOTIFICATION_DETAILS',
  OBSERVATION_INFINITE: 'OBSERVATION_INFINITE',
  OBSERVATION: 'OBSERVATION',
  OBSERVATION_AGGREGATE_BY_LOCATION: 'OBSERVATION_AGGREGATE_BY_LOCATION',
  OBSERVATION_AGGREGATE_FILTER_OPTIONS: 'OBSERVATION_AGGREGATE_FILTER_OPTIONS',
  OBSERVATION_CONFIG: 'OBSERVATION_CONFIG',
  OBSERVATION_COUNT_TODAY: 'OBSERVATION_COUNT_TODAY',
  OPERATION_CHECKLIST_LIST: 'OPERATION_CHECKLIST_LIST',
  OPERATIONS: 'OPERATIONS',
  SEARCH: 'SEARCH',
  SEARCH_ACTIVITIES_WITH_CHECKLIST: 'SEARCH_ACTIVITIES_WITH_CHECKLIST',
  SEARCH_CHECKLISTS: 'SEARCH_CHECKLISTS',
  SEARCH_TEMPLATES: 'SEARCH_TEMPLATES',
  SPACE_LIST: 'SPACE_LIST',
  SUB_ACTIVITIES_LIST: 'SUB_ACTIVITIES_LIST',
  SELECTABLE_LOCATIONS: 'SELECTABLE_LOCATIONS',
  TEMPLATE: 'TEMPLATE',
  TEMPLATE_ADMIN_QUERY: 'TEMPLATE_ADMIN_QUERY',
  TEMPLATE_LIST: 'TEMPLATE_LIST',
  THREE_D: 'THREE_D',
  ASSET_IDM_3D_MODELS: 'ASSET_IDM_3D_MODELS',
  POINT_CLOUD_ANNOTATIONS: 'POINT_CLOUD_ANNOTATIONS',
  TIMESERIES: 'TIMESERIES',
  TRENDS: 'TRENDS',
  USER: 'USER',
  USER_PREFERENCES: 'USER_PREFERENCES',
  VECTORSTORE: 'VECTORSTORE',
  VIEWS: 'VIEWS',
};

export const MutationKeys = {
  ACTION_UPDATE: 'ACTION_UPDATE',
  CHECKLIST_CREATE_FROM_ACTIVITY: 'CHECKLIST_CREATE_FROM_ACTIVITY',
  CHECKLIST_CREATE_FROM_TEMPLATE: 'CHECKLIST_CREATE_FROM_TEMPLATE',
  CHECKLIST_UPDATE: 'CHECKLIST_UPDATE',
  CHECKLIST_ITEM_UPDATE: 'CHECKLIST_ITEM_UPDATE',
  CONDITIONAL_ACTION_CREATE: 'CONDITIONAL_ACTION_CREATE',
  CONDITIONAL_ACTION_DELETE: 'CONDITIONAL_ACTION_DELETE',
  CONDITIONS_UPDATE: 'CONDITIONS_UPDATE',
  TEMPLATE_UPDATE: 'TEMPLATE_UPDATE',
  TEMPLATE_CREATE: 'TEMPLATE_CREATE',
  TEMPLATE_DUPLICATE: 'TEMPLATE_DUPLICATE',
  TEMPLATE_ITEM_UPDATE: 'TEMPLATE_ITEM_UPDATE',
  TEMPLATE_ITEM_CREATE: 'TEMPLATE_ITEM_CREATE',
  TEMPLATE_ITEM_DUPLICATE: 'TEMPLATE_ITEM_DUPLICATE',
  UPSERT_USER_PREFERENCES: 'UPSERT_USER_PREFERENCES',
  UPSERT_CURRENT_USER_PREFERENCES: 'UPSERT_CURRENT_USER_PREFERENCES',
  UPSERT_CHECKLIST: 'upsertChecklist',
  UPSERT_MULTIPLE_CHECKLISTS: 'upsertMultipleChecklists',
  USE_INFIELD_LOCATION_CONFIG_UPSERT: 'useInfieldLocationConfigUpsert',
  USE_INFIELD_LOCATION_CONFIG_DELETE: 'useInfieldLocationConfigDelete',
  USE_MEASURMENT_NUMERIC_READING_UPSERT: 'useMeasurementNumericReadingUpsert',
  USE_MEASURMENT_UPSERT: 'useMeasurementUpsert',
  USE_OPERATIONS_UPSERT: 'useOperationsUpsert',
  USE_OBSERVATION_CONFIG_UPSERT: 'useUpsertObservationConfig',
};
