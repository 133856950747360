import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled.div`
  height: 300px;
  overflow: auto;
`;

export const StyledFlex = styled(Flex).attrs({
  direction: 'column',
  gap: 8,
})`
  padding-top: 8px;
`;
