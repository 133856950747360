import { useContext } from 'react';

import { ThreeDNavHintsContext } from '../providers';

/**
 * Use this hook to access current reveal viewer and 3d model from the ThreeDContextProvider
 */
export const useNavigationalHintsContext = () => {
  const context = useContext(ThreeDNavHintsContext);
  if (!context) {
    throw new Error(
      'useNavigationalHintsContext must be used within a ThreeDNavHintsContext'
    );
  }
  return context;
};
