import { ComponentProps } from 'react';

import { Menu as DefaultMenu } from '@cognite/cogs.js-v10';

import { MenuItemAskQuestion } from './MenuItemAskQuestion';
import { MenuItemSummarize } from './MenuItemSummarize';

export const Menu = (props: ComponentProps<typeof DefaultMenu>) => {
  return <DefaultMenu {...props} />;
};

Menu.Header = DefaultMenu.Header;
Menu.AskQuestion = MenuItemAskQuestion;
Menu.Summarize = MenuItemSummarize;
