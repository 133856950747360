import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ClickableText = styled.span`
  color: var(--cogs-border--interactive--toggled-default);
`;

export const MultiLineText = styled.div`
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const InfoboxWrapper = styled(Flex)`
  flex: 1;
  max-height: 80px;
`;
