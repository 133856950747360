import { isEqualWith } from 'lodash';

const DEFAULT_TOLERANCE = 0.01;

const isApproximatelyEqual = (
  a: unknown,
  b: unknown,
  tolerance = DEFAULT_TOLERANCE
): boolean =>
  isEqualWith(a, b, (a, b) => {
    if (typeof a !== 'number' || typeof b !== 'number') {
      // Use default lodash comparison for non-numbers
      return;
    }
    return Math.abs(a - b) < tolerance;
  });

export default isApproximatelyEqual;
