import type { TemplateItem } from '@cognite/apm-client';
import { Body, DeleteIcon, Flex, Modal } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useTemplateItemsDelete } from '@infield/features/template/hooks';
import type { FC } from 'react';

export type GroupModalProps = {
  tasks: TemplateItem[];
  subtitle?: string;
  closeModal: () => void;
  onSubmit: () => void;
};

export const DeleteModal: FC<GroupModalProps> = ({
  tasks,
  subtitle,
  closeModal,
  onSubmit,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const { mutateAsync: deleteTemplateItems, isLoading } =
    useTemplateItemsDelete();

  const handleSubmit = () => {
    const externalIds = tasks.map((task) => task.externalId);
    deleteTemplateItems({ externalIds }).then(() => {
      onSubmit();
    });
  };

  return (
    <Modal
      size="small"
      visible
      title={t('TEMPLATE_TASKS_MODAL_DELETE_TITLE', 'Delete {{count}} tasks', {
        count: tasks.length,
      })}
      subtitle={subtitle}
      icon={<DeleteIcon />}
      onOk={handleSubmit}
      okButtonProps={{
        loading: isLoading,
        'data-testid': 'delete-modal-ok-button',
      }}
      onCancel={closeModal}
      cancelText={t(
        'TEMPLATE_TASKS_DELETE_MODAL_ACTION_CONFIRMATION_BACK',
        'Cancel'
      )}
      okText={t(
        'TEMPLATE_TASKS_DELETE_MODAL_ACTION_SAVE_CONFIRMATION',
        'Delete'
      )}
      destructive
    >
      <Flex direction="column" gap={4}>
        <Flex gap={16} direction="column">
          <Body size="small" strong>
            {t(
              'TEMPLATE_TASKS_MODAL_DELETE_TEXT',
              'This will delete the {{ key_one: "task", key_other: "tasks",}} from the template and can’t be undone.',
              {
                count: tasks.length,
              }
            )}
          </Body>
          <Body size="small" strong>
            {t(
              'TEMPLATE_TASKS_MODAL_DELETE_TEXT_WARNING',
              'Are you sure you want to delete {{ key_one: "this task", key_other: "these tasks",}}?',
              {
                count: tasks.length,
              }
            )}
          </Body>
        </Flex>
      </Flex>
    </Modal>
  );
};
