export const preventViewportOverflowOptions = {
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['bottom-end', 'right', 'left', 'top-end'], // Flips to prevent overflow
      },
    },
    {
      name: 'preventOverflow',
      options: {
        boundary: 'viewport', // Ensures the dropdown stays within the viewport
        padding: 5,
      },
    },
  ],
};
