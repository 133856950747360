import { useQuery } from '@tanstack/react-query';

import { useSDK } from '@cognite/sdk-provider';

import { AI_SERVICES } from '../../constants';
import { getServicesAvailability } from '../network/getServicesAvailability';
import { queryKeys } from '../queryKeys';

export const useServicesAvailability = () => {
  const sdk = useSDK();

  const queryResult = useQuery(queryKeys.servicesAvailability, () =>
    getServicesAvailability(sdk)
  );

  function isServiceAvailable(serviceName: string) {
    const service = queryResult.data?.find(
      (service) => service.name === serviceName
    );
    return !!(service && service.available);
  }

  return {
    chatCompletions: isServiceAvailable(AI_SERVICES.CHAT_COMPLETIONS),
    documentQA: isServiceAvailable(AI_SERVICES.DOCUMENT_QA),
    documentSummarization: isServiceAvailable(
      AI_SERVICES.DOCUMENT_SUMMARIZATION
    ),
    graphqlCompletions: isServiceAvailable(AI_SERVICES.GRAPHQL_COMPLETIONS),
    pythonCodegen: isServiceAvailable(AI_SERVICES.PYTHON_CODEGEN),
    pythonCodeEdit: isServiceAvailable(AI_SERVICES.PYTHON_CODE_EDIT),
    isLoading: queryResult.isFetching,
    isError: queryResult.isError,
    error: queryResult.error,
  };
};
