import type { Activity, APMAsset } from '@cognite/apm-client';
import type { Asset } from '@cognite/sdk';
import type { FC } from 'react';

import { DetailView } from './detail-view';
import * as S from './elements';
import { OperationListView } from './operation-list-view';

type Props = {
  selectedTab: 'details' | 'operations' | 'assets';
  activity?: Activity;
  asset?: Asset | APMAsset;
  onAssetClick: (assetExternalId: string) => void;
};

export const ActivityDetailsTabContent: FC<Props> = ({
  selectedTab,
  activity,
  asset,
  onAssetClick,
}) => {
  if (!selectedTab) {
    return null;
  }
  if (selectedTab === 'details') {
    return (
      <DetailView
        activity={activity}
        asset={asset}
        onAssetClick={onAssetClick}
      />
    );
  }

  if (selectedTab === 'operations') {
    return (
      <S.Content>
        <OperationListView activity={activity} onAssetClick={onAssetClick} />
      </S.Content>
    );
  }

  return (
    <DetailView activity={activity} asset={asset} onAssetClick={onAssetClick} />
  );
};
