import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import pluginTimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/nb';
import 'dayjs/locale/de';
import 'dayjs/locale/ja';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ko';
import 'dayjs/locale/nl';
import 'dayjs/locale/pt';
import 'dayjs/locale/sv';
import 'dayjs/locale/zh';

export const registerDayjsPlugins = () => {
  dayjs.extend(localizedFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(duration);
  dayjs.extend(pluginTimezone);
  dayjs.extend(utc);
  dayjs.extend(isLeapYear);
};
