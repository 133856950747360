import Konva from 'konva';

import Vector2d = Konva.Vector2d;

/**
 * Separate function to allow to fix this more easily when it's addressed in Konva.
 * @param scale
 */
const shamefulSafeKonvaScale = (scale: Vector2d | undefined): Vector2d => {
  if (scale === undefined) {
    // "trust me, it's never going to return undefined"
    // https://github.com/konvajs/konva/issues/1461
    throw new Error('scale is undefined, this should never happen');
  }

  return scale;
};

export default shamefulSafeKonvaScale;
