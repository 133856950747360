import Konva from 'konva';

import { ANCHOR_STYLE } from './constants';

const getAnchorCross = (): Konva.Path =>
  new Konva.Path({
    data: `M -${ANCHOR_STYLE.crossLength / 2} 0 L ${
      ANCHOR_STYLE.crossLength / 2
    } 0 M 0 -${ANCHOR_STYLE.crossLength / 2} L 0 ${
      ANCHOR_STYLE.crossLength / 2
    }`,
    stroke: ANCHOR_STYLE.stroke,
    strokeWidth: ANCHOR_STYLE.crossStrokeWidth,
    lineCap: 'round',
    lineJoin: 'round',
    listening: false,
    name: ANCHOR_STYLE.crossName,
  });

export default getAnchorCross;
