import type { UserService } from '@cognite/apm-client/src/users';
import { onlineManager } from '@tanstack/react-query';

export const SLOW_NETWORK_MBIT_THRESHOLD = 0.1;
export const SLOW_NETWORK_TIME_THRESHOLD = 2000;
export const OFFLINE_TIME_THRESHOLD = 7500;
export const BACK_ONLINE_TIME_THRESHOLD = 10000;

export const adjustNetworkStateBasedOnRequestTime = (
  requestTime: number,
  isOnline: boolean,
  setIsNetworkSlow: (nextState: boolean) => void,
  setIsOnline: (nextState: boolean) => void,
  setIsBackOnline: (nextState: boolean) => void
) => {
  // if response time is more than SLOW_NETWORK_THRESHOLD, show slow network banner
  if (requestTime > SLOW_NETWORK_TIME_THRESHOLD) {
    setIsNetworkSlow(true);
  } else {
    setIsNetworkSlow(false);
  }

  // if response time is more than OFFLINE_THRESHOLD, start offline mode
  if (requestTime > OFFLINE_TIME_THRESHOLD) {
    setIsOnline(false);
    onlineManager.setOnline(false);
  } else if (!isOnline) {
    // Introduce a delay before going back online
    setTimeout(() => {
      setIsBackOnline(true);
      setIsOnline(true);
      onlineManager.setOnline(undefined);
    }, BACK_ONLINE_TIME_THRESHOLD);
  } else {
    setIsOnline(true);
    onlineManager.setOnline(undefined);
  }
};

export const pollUsersEndpoint = (
  userService: UserService,
  isOnline: boolean,
  setIsWaitingForPollResponse: (nextState: boolean) => void,
  setIsNetworkSlow: (nextState: boolean) => void,
  setIsOnline: (nextState: boolean) => void,
  setIsBackOnline: (nextState: boolean) => void
) => {
  const startTime = Date.now();
  setIsWaitingForPollResponse(true);
  userService
    .list('externalId', { pageSize: 1 })
    .then(() => {
      const endTime = Date.now();
      const requestTime = endTime - startTime;
      adjustNetworkStateBasedOnRequestTime(
        requestTime,
        isOnline,
        setIsNetworkSlow,
        setIsOnline,
        setIsBackOnline
      );
    })
    .catch(() => {
      // if response failed, start (or keep) offline mode
      setIsOnline(false);
      onlineManager.setOnline(false);
    })
    .finally(() => {
      setIsWaitingForPollResponse(false);
    });
};
