const productionURLs = [
  'cognite-infield.cogniteapp.com',
  'cognite-infield.preview.cogniteapp.com',

  'infield.cogniteapp.com',
  'infield.preview.cogniteapp.com',
];

export const isProduction = () => {
  return productionURLs.includes(location.hostname);
};

export const getApplicationId = () => {
  return isProduction() ? 'infield2' : 'infield2-staging';
};

export const getEnvironment = () => {
  return isProduction() ? 'PRODUCTION' : 'DEVELOPMENT';
};
