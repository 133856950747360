import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  forwardRef,
} from 'react';
import { PlotParams } from 'react-plotly.js';

import { noop } from 'lodash-es';
import Plotly, { PlotMouseEvent } from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import useResizeObserver from 'use-resize-observer';

import {
  TimeseriesEntry,
  ScheduledCalculationsDataMap,
  InteractionData,
  ChartEventResults,
  EventsCollection,
  WorkflowState,
} from '../../models';
import { CoreTimeseriesEntryCollection } from '../../models/core-timeseries-results';
import {
  ChartThreshold,
  CoreTimeseries,
  VerticalMarker,
  ChartTimeSeries,
  ChartWorkflow,
  ScheduledCalculation,
} from '../../types';

import { PlotWrapper } from './elements';
import { createNavigationSafePlotComponent } from './navigation-safe-plot-component';
import { calculateSeriesDataFromCoreTimeSeries } from './utils/calculateSeriesDataFromCoreTimeSeries';
import { calculateSeriesDataFromScheduledCalculation } from './utils/calculateSeriesDataFromScheduledCalculation';
import { calculateSeriesDataFromTimeSeries } from './utils/calculateSeriesDataFromTimeSeries';
import { calculateSeriesDataFromWorkflow } from './utils/calculateSeriesDataFromWorkflow';
import { formatPlotlyData } from './utils/formatPlotlyData';
import { generateLayout } from './utils/generateLayout';
import { getXAxisUpdateFromEventData } from './utils/getXAxisUpdateFromEventData';
import { getYAxisUpdatesFromEventData } from './utils/getYAxisUpdatesFromEventData';
import { mergeSeriesDataCollectionsByUnit } from './utils/mergeSeriesDataCollectionsByUnits';
import {
  AxisUpdate,
  PlotlyDragMode,
  PlotlyEventData,
  ScatterType,
  SeriesDataCollection,
} from './utils/types';

const PlotlyComponent = createPlotlyComponent(Plotly);
const SafePlotlyComponent = createNavigationSafePlotComponent(PlotlyComponent);

const DEFAULT_Y_AXIS_WIDTH = 0.03;
const DEFAULT_Y_AXIS_MARGIN = 0.025;

const defaultChartStyles = {
  height: '100%',
  width: '100%',
};

export type PlotNavigationUpdate = {
  x: string[];
  y: AxisUpdate[];
  dragmode: PlotlyDragMode;
  eventdata: PlotlyEventData;
};

export type ContainerClickEvent = {
  x: number;
  y: number;
  xTimestamp: Date;
};

type Props = {
  dateFrom?: string;
  dateTo?: string;
  timeseries?: ChartTimeSeries[];
  timeseriesData?: TimeseriesEntry[];
  coreTimeSeries?: CoreTimeseries[];
  coreTimeSeriesData?: CoreTimeseriesEntryCollection;
  calculations?: ChartWorkflow[];
  calculationsData?: WorkflowState[];
  scheduledCalculations?: ScheduledCalculation[];
  scheduledCalculationsData?: ScheduledCalculationsDataMap;
  thresholds?: ChartThreshold[];
  verticalMarkers?: VerticalMarker[];
  eventData?: ChartEventResults[];
  storedSelectedEvents?: EventsCollection;
  isYAxisShown?: boolean;
  isMinMaxShown?: boolean;
  isGridlinesShown?: boolean;
  isPreview?: boolean;
  scrollZoomEnabled?: boolean;
  stackedMode?: boolean;
  mergeUnits?: boolean;
  yAxisWidth?: number;
  yAxisMargin?: number;
  dragmode?: PlotlyDragMode;
  onPlotNavigation?: (update: PlotNavigationUpdate) => void;
  plotlyProps?: ((prev: PlotParams) => PlotParams) | PlotParams;
  interactionData?: InteractionData;
  scatterType?: ScatterType;
  onClick?: (event: PlotMouseEvent) => void;
  onContainerClick?: (event: ContainerClickEvent) => void;
  horizontalMargin?: number;
  verticalMargin?: number;
};

export const PlotlyChart = forwardRef(
  ({
    dateFrom = new Date().toISOString(),
    dateTo = new Date(new Date().getTime() + 3600000).toISOString(),
    timeseries = [],
    timeseriesData = [],
    coreTimeSeries = [],
    coreTimeSeriesData = [],
    calculations = [],
    calculationsData = [],
    scheduledCalculations = [],
    scheduledCalculationsData = {},
    thresholds = [],
    verticalMarkers = [],
    eventData = [],
    storedSelectedEvents = [],
    isPreview = false,
    scrollZoomEnabled = true,
    isMinMaxShown = false,
    isGridlinesShown = false,
    isYAxisShown = true,
    mergeUnits = false,
    stackedMode = false,
    yAxisWidth = DEFAULT_Y_AXIS_WIDTH,
    yAxisMargin = DEFAULT_Y_AXIS_MARGIN,
    dragmode = 'pan',
    scatterType = 'scattergl',
    onPlotNavigation = noop,
    plotlyProps = (prev) => prev,
    interactionData,
    onClick,
    onContainerClick,
    horizontalMargin,
    verticalMargin,
  }: Props) => {
    const manuallyAdjustedAxisText =
      'Manually adjusted axis. Double click axis to use auto range.';

    const containerRef = useRef<HTMLDivElement>(null);

    const plotlyRef = useRef<React.Component<PlotParams>>(null);

    const [yAxisValues, setYAxisValues] = useState<{
      width: number;
      margin: number;
    }>({ width: yAxisWidth, margin: yAxisMargin });

    useEffect(() => {
      if (containerRef && containerRef.current) {
        setYAxisValues({
          width: yAxisWidth,
          margin: yAxisMargin,
        }); // TODO: find a way to cap y axis width at something sensible, to avoid taking too much space on big screen
      }
    }, [containerRef, yAxisWidth, yAxisMargin]);

    const [yAxisLocked, setYAxisLocked] = useState<boolean>(true);

    /**
     * Handle toggling of scroll behavior for y axis based on mouse position
     */
    const handleToggleYAxisLocked = useCallback(
      (shouldBeLocked: boolean) => {
        if (shouldBeLocked !== yAxisLocked) {
          setYAxisLocked(shouldBeLocked);
        }
      },
      [yAxisLocked]
    );

    const handleMouseMoveOnChart = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        const classes = Array.from((e.target as HTMLElement)?.classList);
        const isMainArea = classes.includes('nsewdrag');
        handleToggleYAxisLocked(isMainArea);
      },
      [handleToggleYAxisLocked]
    );

    const seriesDataCollection: SeriesDataCollection[] = useMemo(() => {
      const filteredTimeseries = timeseries.filter((ts) => ts.enabled);
      const timeSeriesSeriesData = calculateSeriesDataFromTimeSeries(
        filteredTimeseries,
        timeseriesData,
        thresholds
      );

      const filteredCoreTimeSeries = coreTimeSeries.filter((ts) => ts.enabled);
      const coreTimeSeriesSeriesData = calculateSeriesDataFromCoreTimeSeries(
        filteredCoreTimeSeries,
        coreTimeSeriesData,
        thresholds
      );

      const filteredCalculations = calculations.filter((calc) => calc.enabled);
      const workflowSeriesData = calculateSeriesDataFromWorkflow(
        filteredCalculations,
        calculationsData,
        thresholds
      );

      const filteredSceduledCalculations = scheduledCalculations.filter(
        (calc) => calc.enabled
      );
      const scheduledCalculationsSeriesData =
        calculateSeriesDataFromScheduledCalculation(
          filteredSceduledCalculations,
          scheduledCalculationsData,
          thresholds
        );

      const data = [
        ...timeSeriesSeriesData,
        ...coreTimeSeriesSeriesData,
        ...workflowSeriesData,
        ...scheduledCalculationsSeriesData,
      ];
      return mergeUnits ? mergeSeriesDataCollectionsByUnit(data) : data;
    }, [
      timeseries,
      coreTimeSeries,
      coreTimeSeriesData,
      calculations,
      timeseriesData,
      calculationsData,
      scheduledCalculations,
      scheduledCalculationsData,
      thresholds,
      mergeUnits,
    ]);

    const handleContainerClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (plotlyRef.current === null) return;

        // Get offset for container element
        const containerOffset =
          containerRef.current?.getBoundingClientRect().x || 0;

        // Get the offset for the start of the x axis
        const xAxisOffset = (plotlyRef as any).current.el._fullLayout.xaxis
          ._offset;

        // Get the plot position that was clicked
        const selectedXPositon = e.clientX - xAxisOffset - containerOffset;

        const xAxisValueInMs = (
          plotlyRef as any
        ).current.el._fullLayout.xaxis.p2c(selectedXPositon);

        const xAxisValueInMsUTC =
          xAxisValueInMs +
          new Date(xAxisValueInMs).getTimezoneOffset() * 60 * 1000;

        if (onContainerClick) {
          onContainerClick({
            x: e.clientX,
            y: e.clientY,
            xTimestamp: new Date(xAxisValueInMsUTC),
          });
        }
      },
      [onContainerClick]
    );

    const data: Plotly.Data[] = useMemo(
      () =>
        formatPlotlyData(
          seriesDataCollection,
          isMinMaxShown,
          interactionData?.highlightedTimeseriesId,
          scatterType
        ),
      [
        seriesDataCollection,
        scatterType,
        isMinMaxShown,
        interactionData?.highlightedTimeseriesId,
      ]
    );

    const layout = useMemo(() => {
      return generateLayout({
        isPreview,
        isGridlinesShown,
        yAxisLocked,
        showYAxis: isYAxisShown,
        stackedMode,
        seriesDataCollection,
        eventData,
        storedSelectedEvents,
        yAxisValues,
        dateFrom,
        dateTo,
        dragmode,
        highlightedTimeseriesId: interactionData?.highlightedTimeseriesId,
        manuallyAdjustedAxisText,
        verticalMarkers,
        containerWidth: containerRef.current?.clientWidth || 0,
        horizontalMargin,
        verticalMargin,
      });
    }, [
      isPreview,
      isGridlinesShown,
      yAxisLocked,
      isYAxisShown,
      stackedMode,
      seriesDataCollection,
      eventData,
      storedSelectedEvents,
      yAxisValues,
      dateFrom,
      dateTo,
      dragmode,
      interactionData?.highlightedTimeseriesId,
      manuallyAdjustedAxisText,
      verticalMarkers,
      horizontalMargin,
      verticalMargin,
    ]);

    const config = useMemo(() => {
      return {
        staticPlot: isPreview,
        autosize: true, // TODO: This does nothing according to Plotly docs? At least undocumented
        responsive: true,
        scrollZoom: scrollZoomEnabled,
        displaylogo: false,
        displayModeBar: false,
        doubleClick: 'autosize' as const,
      };
    }, [isPreview, scrollZoomEnabled]);

    const [isInitialized, setIsInitialized] = useState(false);

    const onRelayout = useCallback(
      (eventdata: Readonly<Plotly.PlotRelayoutEvent>) => {
        if (!isInitialized) {
          return;
        }

        const x = getXAxisUpdateFromEventData(eventdata);
        const y = getYAxisUpdatesFromEventData(seriesDataCollection, eventdata);

        onPlotNavigation({
          x,
          y,
          dragmode: eventdata['dragmode'] || dragmode,
          eventdata,
        });
      },
      [isInitialized, seriesDataCollection, onPlotNavigation, dragmode]
    );

    const computedPlotProps: PlotParams = {
      data,
      layout,
      config,
      onRelayout,
      style: defaultChartStyles,
      onClick,
    };

    const plotProps =
      typeof plotlyProps === 'function'
        ? plotlyProps(computedPlotProps)
        : { ...computedPlotProps, ...plotlyProps };

    /**
     * Workaround to not re-fire the ref callback for each layout change
     */
    const layoutRef = useRef(layout);
    useEffect(() => {
      layoutRef.current = layout;
    }, [layout]);

    /**
     * NOTE(eiriklv): Trigger a resize event every time the plot container changes,
     * as this will force the plot to resize and fill the container
     */
    const handleResize = useCallback(() => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    }, []);

    useResizeObserver({
      ref: containerRef,
      onResize: handleResize,
    });

    return (
      <PlotWrapper
        ref={containerRef}
        onMouseMove={handleMouseMoveOnChart}
        onClick={onContainerClick && handleContainerClick}
      >
        <SafePlotlyComponent
          onPurge={() => setIsInitialized(false)}
          onInitialized={() => setIsInitialized(true)}
          {...plotProps}
          ref={plotlyRef}
        />
      </PlotWrapper>
    );
  }
);

export type PlotlyChartRef = typeof PlotlyChart;
