import { CogniteClient } from '@cognite/sdk';

import { getAdsService } from './adsService';
import { ChartsStorageServiceInterface } from './ChartsStorageServiceInterface';

export const getChartsStorageService = (
  sdk?: CogniteClient
): ChartsStorageServiceInterface => {
  if (!sdk) {
    throw new Error(
      'CogniteClient is required to initialize ChartsStorageService'
    );
  }
  return getAdsService(sdk);
};
