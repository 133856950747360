import type { UserFilter } from '@cognite/apm-client';
import {
  CheckmarkFilledIcon,
  DeleteIcon,
  Divider,
  FavoriteFilledIcon,
  FavoriteIcon,
  Menu,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { TextInputModal } from '@infield/features/template';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  filters?: UserFilter[];
  isRenamingFilter?: string;
  isDeletingFilter?: string;
  showNewFilterModal: boolean;
  selectedFilter?: string;
  onApply: (filterExternalId: string) => void;
  onSetDefault: (filterExternalId: string) => void;
  onClearFilters: () => void;
  handleRename: (name: string) => void;
  handleDelete: () => void;
  handleSaveNewFilter: (name: string) => void;
  setIsRenamingFilter: (isRenamingFilter?: string) => void;
  setIsDeletingFilter: (isDeletingFilter?: string) => void;
  handleShowNewFilterModal: (showNewFilterModal: boolean) => void;
}

export const FilterMenu: FC<Props> = ({
  filters,
  isRenamingFilter,
  isDeletingFilter,
  showNewFilterModal,
  selectedFilter,
  onApply,
  onSetDefault,
  handleRename,
  handleDelete,
  handleSaveNewFilter,
  onClearFilters,
  setIsRenamingFilter,
  setIsDeletingFilter,
  handleShowNewFilterModal,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const getFilterName = (filterExternalId?: string) =>
    filters?.find((filter) => filter.externalId === filterExternalId)?.name;

  const subMenu = (filter: UserFilter): JSX.Element => (
    <S.MenuWrapper>
      <Menu.Item key="1" onClick={() => onApply(filter.externalId)}>
        {t('ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_APPLY', 'Apply')}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => onSetDefault(filter.externalId)}>
        {!filter.default
          ? t(
              'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_SET_AS_DEFAULT',
              'Set as default'
            )
          : t(
              'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_REMOVE_AS_DEFAULT',
              'Remove as default'
            )}
      </Menu.Item>
      <Divider />
      <Menu.Item key="3" onClick={() => setIsRenamingFilter(filter.externalId)}>
        {t('ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_RENAME', 'Rename')}
      </Menu.Item>
      <Divider />
      <Menu.Item
        key="4"
        destructive
        icon={<DeleteIcon />}
        iconPlacement="left"
        onClick={() => setIsDeletingFilter(filter.externalId)}
      >
        {t('ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DELETE', 'Delete')}
      </Menu.Item>
    </S.MenuWrapper>
  );

  const getFavoriteIcon = (filter: UserFilter) => {
    if (!filter.default) {
      return null;
    }
    if (selectedFilter === filter.externalId) {
      return <FavoriteFilledIcon />;
    }
    return <FavoriteIcon />;
  };

  return (
    <>
      <S.FilterMenuButton
        type="tertiary"
        label={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_CLEAR_FILTERS',
          'Clear filters'
        )}
        onClick={onClearFilters}
      >
        <S.MenuWrapper>
          {filters?.map((filter) => (
            <Menu.Submenu
              key={filter.externalId}
              className="filter-submenu"
              content={subMenu(filter)}
            >
              <S.SubmenuLabel>
                {selectedFilter === filter.externalId && !filter.default && (
                  <CheckmarkFilledIcon />
                )}
                {getFavoriteIcon(filter)}
                {filter.name}
              </S.SubmenuLabel>
            </Menu.Submenu>
          ))}
          {filters && filters.length > 0 && <Divider />}
          <Menu.Item
            onClick={() => handleShowNewFilterModal(true)}
            data-testid="save-new-filter"
          >
            {t(
              'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_SAVE_SELECTED_FILTERS',
              'Save selected filters'
            )}
          </Menu.Item>
        </S.MenuWrapper>
      </S.FilterMenuButton>
      <TextInputModal
        size="small"
        visible={Boolean(isRenamingFilter)}
        data-testid="filter-rename-input"
        title={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DIALOGUE_RENAME_TITLE',
          'Rename filter'
        )}
        inputLabel={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DIALOGUE_RENAME_LABEL',
          'Name'
        )}
        okText={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DIALOGUE_RENAME_BUTTON_CONFIRM',
          'Rename'
        )}
        cancelText={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DIALOGUE_RENAME_BUTTON_CANCEL',
          'Cancel'
        )}
        initialValue={isRenamingFilter && getFilterName(isRenamingFilter)}
        onSubmit={handleRename}
        onCancel={() => setIsRenamingFilter(undefined)}
      >
        {null}
      </TextInputModal>
      <S.DeleteModal
        visible={Boolean(isDeletingFilter)}
        size="small"
        title={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DIALOGUE_DELETE_TITLE',
          {
            defaultValue: 'Delete {{filterName}}',
            filterName: isDeletingFilter && getFilterName(isDeletingFilter),
          }
        )}
        cancelText={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DIALOGUE_DELETE_BUTTON_CANCEL',
          'Cancel'
        )}
        okText={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DIALOGUE_DELETE_BUTTON_CONFIRM',
          'Delete'
        )}
        onOk={handleDelete}
        onCancel={() => setIsDeletingFilter(undefined)}
        destructive
      >
        {null}
      </S.DeleteModal>
      <TextInputModal
        size="small"
        visible={showNewFilterModal}
        title={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DIALOGUE_SAVE_FILTER_TITLE',
          'Save filter'
        )}
        cancelText={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DIALOGUE_SAVE_FILTER_BUTTON_CANCEL',
          'Cancel'
        )}
        okText={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DIALOGUE_SAVE_FILTER_BUTTON_CONFIRM',
          'Save'
        )}
        inputLabel={t(
          'ACTIVITY_OVERVIEW_TOOLBAR_FILTER_LIST_DIALOGUE_SAVE_FILTER_LABEL',
          'Name'
        )}
        onSubmit={(value) => handleSaveNewFilter(value)}
        onCancel={() => handleShowNewFilterModal(false)}
      >
        {null}
      </TextInputModal>
    </>
  );
};
