export type TimeSeriesDatapoints = {
  value: number;
  timestamp: Date;
}[];

export const linearInterpolation = (
  dataPoints: TimeSeriesDatapoints,
  timestamp: number
) => {
  if (dataPoints.length === 0) {
    return undefined;
  }

  if (timestamp <= dataPoints[0].timestamp.getTime()) {
    return undefined;
  }

  if (timestamp >= dataPoints[dataPoints.length - 1].timestamp.getTime()) {
    return undefined;
  }

  for (let i = 1; i < dataPoints.length; i++) {
    const currentPointTime = dataPoints[i].timestamp.getTime();

    if (currentPointTime === timestamp) {
      return dataPoints[i].value;
    }

    if (currentPointTime > timestamp) {
      const x1 = currentPointTime;
      const y1 = dataPoints[i].value;
      const x0 = dataPoints[i - 1].timestamp.getTime();
      const y0 = dataPoints[i - 1].value;

      const y = y0 + (y1 - y0) * ((timestamp - x0) / (x1 - x0));
      return y;
    }
  }
  return undefined;
};

export const stepWiseInterpolation = (
  datapoints: TimeSeriesDatapoints,
  timestamp: number
) => {
  if (datapoints.length === 0) return undefined;
  if (datapoints[0].timestamp.getTime() > timestamp) return undefined;

  const lastDatapoint = datapoints.reduce(
    (previousDatapoint, currentDatapoint) => {
      if (currentDatapoint.timestamp.getTime() <= timestamp) {
        if (
          previousDatapoint.timestamp.getTime() <=
          currentDatapoint.timestamp.getTime()
        ) {
          return currentDatapoint;
        }
      }
      return previousDatapoint;
    },
    datapoints[0]
  );

  return lastDatapoint.value;
};
