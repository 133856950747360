import clamp from 'lodash/clamp';

import { PolylineAnnotation } from '../annotations/types';

const constrainPolylinePosition = (
  annotation: PolylineAnnotation
): PolylineAnnotation => {
  if (annotation.vertices === undefined) {
    return annotation;
  }

  const maxVertexX = Math.max(...annotation.vertices.map((vertex) => vertex.x));
  const maxVertexY = Math.max(...annotation.vertices.map((vertex) => vertex.y));

  const offsetX = clamp(maxVertexX - 1, 0, 1);
  const offsetY = clamp(maxVertexY - 1, 0, 1);

  return {
    ...annotation,
    vertices: annotation.vertices.map((vertex) => ({
      x: vertex.x - offsetX,
      y: vertex.y - offsetY,
    })),
  };
};

export default constrainPolylinePosition;
