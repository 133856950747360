import Konva from 'konva';

import { BaseStyleProperties } from './types';

const getBaseStylePropertiesFromNode = (
  node: Konva.Shape
): BaseStyleProperties => ({
  fill: node?.fill() ?? undefined,
  stroke: node?.stroke() ?? undefined,
  strokeWidth: node?.strokeWidth() ?? undefined,
  dash: node?.dash() ?? undefined,
  opacity: node?.opacity() ?? undefined,
  shouldEnableStrokeScale: node?.strokeScaleEnabled() ?? undefined,
});

export default getBaseStylePropertiesFromNode;
