import type { Timeseries } from '@cognite/sdk';
import { useTrendColor } from '@infield/features/trends/hooks';
import type { FC } from 'react';

import type { EnhancedTimeseries } from '../types';
import { yAxisFormatter } from '../utils';

import * as S from './elements';

interface Props {
  timeseries: Timeseries;
}
export const TrendsChartYAxis: FC<Props> = ({ timeseries }) => {
  const color = useTrendColor(timeseries.name);

  // round max and min to 2 decimal places
  const getYMinMax = (ts: EnhancedTimeseries) => {
    if (
      ts.ySubDomain &&
      ts.ySubDomain[0] !== Number.MAX_SAFE_INTEGER &&
      ts.ySubDomain[1] !== Number.MIN_SAFE_INTEGER
    ) {
      return ts.ySubDomain;
    }
    return [0, 0];
  };

  return (
    <S.Wrapper key={timeseries.id}>
      <S.Value color={color}>
        <S.Line color={color} />
        {yAxisFormatter(getYMinMax(timeseries)[1])}
      </S.Value>
      <S.Value color={color}>
        {yAxisFormatter(getYMinMax(timeseries)[0])}
      </S.Value>
    </S.Wrapper>
  );
};
