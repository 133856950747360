import { useCallback, useState } from 'react';

import { TODO_STATUS } from '../constants';

export type FilterKey = 'tag' | 'status';

export type Filter = {
  tag: string[];
  status: string[];
};

const INITIAL_STATE = {
  tag: [],
  status: [],
};

export const useChecklistFilter = () => {
  const [filters, setFilters] = useState<Filter>(INITIAL_STATE);

  const updateFilters = (filterKey: FilterKey, newValue: string) => {
    const updatedSelection = filters[filterKey].includes(newValue)
      ? filters[filterKey].filter((item) => item !== newValue)
      : [...filters[filterKey], newValue];

    setFilters((prevState) => ({
      ...prevState,
      [filterKey]: updatedSelection,
    }));
  };

  const resetFilters = useCallback(() => {
    setFilters(INITIAL_STATE);
  }, []);

  const checkTagFilterCondition = (assetExternalId?: string | null) =>
    Boolean(
      filters.tag.length === 0 ||
        (assetExternalId && filters.tag.includes(assetExternalId))
    );

  const checkStatusFilterCondition = (status?: string | null) =>
    Boolean(
      filters.status.length === 0 ||
        (status && filters.status.includes(status)) ||
        (!status && filters.status.includes(TODO_STATUS))
    );

  return {
    filters,
    setFilters,
    updateFilters,
    resetFilters,
    checkTagFilterCondition,
    checkStatusFilterCondition,
  };
};
