import type { Activity } from '@cognite/apm-client';
import { Infobox, Skeleton } from '@cognite/cogs.js-v10';
import { useOperations } from '@infield/features/activities';
import { useAPMAssetsQuery } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { OperationList } from '../operation-list/operation-list';

type Props = {
  activity?: Activity;
  onAssetClick: (assetExternalId: string) => void;
};

export const OperationListView: FC<Props> = ({ activity, onAssetClick }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const { data: operations, isInitialLoading: isOperationsLoading } =
    useOperations(activity?.externalId);

  const assetExternalIds = operations
    ?.map(({ assetExternalId }) => assetExternalId)
    .filter(Boolean) as string[] | undefined;

  const { data: apmAssets } = useAPMAssetsQuery({
    externalIds: assetExternalIds,
  });

  if (isOperationsLoading) {
    return <Skeleton.List lines={4} borders />;
  }

  if (!operations?.length) {
    return (
      <Infobox
        status="neutral"
        title={t('ACTIVITY_DETAILS_TABS_NO_DATA_INFO_TITLE', 'No data')}
      >
        {t(
          'ACTIVITY_DETAILS_OPERATIONS_TAB_NO_OPERATIONS_INFO',
          'There are no operations found in this activity.'
        )}
      </Infobox>
    );
  }

  return (
    <OperationList
      operations={operations}
      assets={apmAssets}
      onAssetClick={onAssetClick}
    />
  );
};
