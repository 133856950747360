import { Node, NodeConfig } from 'konva/lib/Node';

import { Container, ContainerProps } from '../../containers/Container';
import getNodeRectRelativeToStage from '../../utils/getNodeRectRelativeToStage';
import { getIntersectionOverUnion } from '../../utils/rectUtils';

export const attachOrDetachAnnotations = (
  annotations: Node<NodeConfig>[],
  target: Container<any, ContainerProps<any>> | null
): void => {
  if (target !== null) {
    attachAnnotationsToTarget(annotations, target);
    return;
  }
  detachAnnotations(annotations);
};

const attachAnnotationsToTarget = (
  annotations: Node<NodeConfig>[],
  target: Container<any, ContainerProps<any>>
): void => {
  const targetNode = target.getContentNode();
  annotations
    .filter((node) => node.getAttr('containerId') !== target.id)
    .forEach((node) => {
      const nodeRect = getNodeRectRelativeToStage(node);
      const containerRect = getNodeRectRelativeToStage(targetNode);

      // When a selected group of annotations is dropped on a container, some of
      // them may lie outside the container. Don't attach them in that case. In
      // some cases (a new Text annotation), the annotation won't have a size
      // yet, but we still want to attach it. In those cases there should only
      // be one annotation given anyway, so we can skip this check.
      if (
        annotations.length > 1 &&
        nodeRect !== null &&
        containerRect !== null &&
        getIntersectionOverUnion(nodeRect, containerRect) === 0
      ) {
        return;
      }

      moveNodeRelativeToNode(node, targetNode);
      node.setAttr('containerId', target.id);
    });
};

const detachAnnotations = (annotations: Node<NodeConfig>[]): void => {
  annotations
    .filter((node) => node.getAttr('containerId') !== undefined)
    .forEach((node) => {
      moveNodeRelativeToStage(node);
      node.setAttr('containerId', undefined);
    });
};

const moveNodeRelativeToNode = (node: Node, container: Node) => {
  const stage = node.getStage();
  if (stage === null) {
    return;
  }
  const positionRelativeToStage = node.getAbsolutePosition(stage);
  const containerPositionRelativeToStage = container.getAbsolutePosition(stage);
  node.position({
    x: positionRelativeToStage.x - containerPositionRelativeToStage.x,
    y: positionRelativeToStage.y - containerPositionRelativeToStage.y,
  });
};

const moveNodeRelativeToStage = (node: Node) => {
  const stage = node.getStage();
  if (stage === null) {
    return;
  }
  const positionRelativeToStage = node.getAbsolutePosition(stage);
  node.position(positionRelativeToStage);
};
