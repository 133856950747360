import {
  importPDFJS,
  type PDFDocumentLoadingTask,
  type PDFDocumentProxy,
} from '../../vendor/pdfjs-dist';

import getResponseCache from './getResponseCache';
import { isMobileOrTablet } from './isMobileOrTablet';

const DEFAULT_CACHE_AUTO_EXPIRE_MS = 30 * 1000;
type PdfCacheOptions = { key?: string };
const createAutoExpiringPdfCache = (
  autoExpireMs = DEFAULT_CACHE_AUTO_EXPIRE_MS
): {
  getPdfLoadingTask: (
    fileUrl: string,
    options?: PdfCacheOptions
  ) => Promise<PDFDocumentLoadingTask>;
  getPdf: (
    fileUrl: string,
    options?: PdfCacheOptions
  ) => Promise<PDFDocumentProxy>;
  getPdfNumPages: (
    fileUrl: string,
    options?: PdfCacheOptions
  ) => Promise<number>;
  deletePdfCacheEntry: (
    fileUrl: string,
    options?: PdfCacheOptions
  ) => Promise<void>;
} => {
  const pdfDocumentLoadingTaskByFile = new Map<
    string,
    PDFDocumentLoadingTask
  >();
  const expirationTimersByFile = new Map<string, NodeJS.Timeout>();

  const deleteCachedPdf = (fileUrl: string): void => {
    const pdfLoadingTask = pdfDocumentLoadingTaskByFile.get(fileUrl);
    if (pdfLoadingTask === undefined) {
      return;
    }
    pdfDocumentLoadingTaskByFile.delete(fileUrl);
    pdfLoadingTask.destroy();
  };

  const resetPdfFileCacheExpiration = (fileUrl: string): void => {
    const timer = expirationTimersByFile.get(fileUrl);
    if (timer) {
      clearTimeout(timer);
    }
    expirationTimersByFile.set(
      fileUrl,
      setTimeout(() => {
        deleteCachedPdf(fileUrl);
      }, autoExpireMs)
    );
  };

  const getPdfLoadingTask = async (
    fileUrl: string,
    options?: PdfCacheOptions
  ): Promise<PDFDocumentLoadingTask> => {
    resetPdfFileCacheExpiration(fileUrl);
    const existingPdfLoadingTask = pdfDocumentLoadingTaskByFile.get(fileUrl);
    if (existingPdfLoadingTask) {
      return existingPdfLoadingTask;
    }

    const PDFJS = await importPDFJS();

    return getResponseCache()
      .fetch(fileUrl, options)
      .then(({ data: arrayBuffer }) =>
        PDFJS.getDocument({
          data: new Uint8Array(arrayBuffer),
          // A user reported crashes on their mobile devices when loading a PDF
          // which had a very large embedded image. The crash is due to
          // attempting to create too large of an OffscreenCanvas.
          //
          // To be safe, don't allow PDFJS to render such large images. The
          // value was chosen based on iOS's console logged warnings.
          maxImageSize: isMobileOrTablet ? 4096 ** 2 : undefined,
        })
      )
      .then((pdfLoadingTask) => {
        pdfDocumentLoadingTaskByFile.set(fileUrl, pdfLoadingTask);
        return pdfLoadingTask;
      });
  };

  const getPdf = async (
    fileUrl: string,
    options?: PdfCacheOptions
  ): Promise<PDFDocumentProxy> =>
    (await getPdfLoadingTask(fileUrl, options)).promise;

  const getPdfNumPages = async (
    fileUrl: string,
    options?: PdfCacheOptions
  ): Promise<number> => {
    const pdf = await getPdf(fileUrl, options);
    return pdf.numPages;
  };

  const deletePdfCacheEntry = async (
    fileUrl: string,
    options?: PdfCacheOptions
  ): Promise<void> => {
    await getResponseCache().delete(fileUrl, options);
  };

  return { getPdfLoadingTask, getPdf, getPdfNumPages, deletePdfCacheEntry };
};

export default createAutoExpiringPdfCache;
