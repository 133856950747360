import { DatapointAggregate, Datapoints, DoubleDatapoint } from '@cognite/sdk';

import { getArrayMax, getArrayMin } from './array';

export function calculateStackedYRange(
  datapoints: (Datapoints | DatapointAggregate)[],
  index: number,
  numSeries: number
): number[] {
  const data = datapoints.map((datapoint) =>
    'average' in datapoint
      ? datapoint.average
      : (datapoint as DoubleDatapoint).value
  ) as number[];

  const min = getArrayMin(data);
  const max = getArrayMax(data);

  const range = max - min;
  const lower = min - index * range;
  const upper = lower + numSeries * range;

  return [lower, upper];
}
