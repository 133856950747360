const getAspectRatio = (image: CanvasImageSource): number => {
  if (image instanceof VideoFrame) {
    throw new Error('Cannot get aspect ratio of a VideoFrame.');
  }
  const width =
    typeof image.width === 'number' ? image.width : image.width.baseVal.value;
  const height =
    typeof image.height === 'number'
      ? image.height
      : image.height.baseVal.value;

  return width / height;
};

export default getAspectRatio;
