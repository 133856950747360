import { useLocalStorage } from 'usehooks-ts';

import { SELF_SERVICE_RELEASE_LOCAL_STORAGE_KEY } from '../constants';

export const useSelfServiceReleaseState = () => {
  return useLocalStorage<number | undefined>(
    SELF_SERVICE_RELEASE_LOCAL_STORAGE_KEY,
    undefined
  );
};
