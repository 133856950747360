/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { IRect } from 'konva/cmj/types';
import { countBy, uniq } from 'lodash';
import throttle from 'lodash/throttle';

import {
  CauseMapNodeAnnotation,
  isCauseMapNodeAnnotation,
  Position,
} from '../annotations/types';
import { ZOOM_TO_FIT_MARGIN } from '../constants';
import { ContainerConfig } from '../containers/types';
import { IdsByType, isAnnotation } from '../types';
import {
  UnifiedViewer,
  UnifiedViewerErrorEvent,
  ZoomToFitMode,
} from '../UnifiedViewer';
import UnifiedViewerEventType from '../UnifiedViewerEventType';
import conditionalDebounce from '../utils/conditionalDebounce';
import getBoundaryHeuristicForContainers from '../utils/getBoundaryHeuristicForContainers';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';
import partitionIntoContainersAndAnnotations from '../utils/partitionIntoContainersAndAnnotations';

import DeveloperConsole from './debug/DeveloperConsole';
import { ErrorComponent } from './ErrorComponent/ErrorComponent';
import NodeTooltip, { TooltipConfig } from './NodePopup';
import CanvasTooltip from './NodePopup/CanvasTooltip';
import { ReactUnifiedViewerProps } from './types';
import useUnifiedViewerEventHandler from './useUnifiedViewerEventHandler';
import ZoomControls from './ZoomControls/ZoomControls';

const IS_ZOOMING_DEBOUNCE_MS = 100;
const IS_PANNING_DEBOUNCE_MS = 100;
const IS_USING_TOOL_DEBOUNCE_MS = 100;
const IS_USING_SELECTION_DRAG_DEBOUNCE_MS = 100;
const IS_TRANSFORMING_DEBOUNCE_MS = 250;
const SET_DRAGGING_TARGET_IDS_DEBOUNCE_MS = 250;
const UPDATE_VIEWPORT_THROTTLE_MS = 16;

type ContextMenuProps = {
  position: Position;
  idsByType: IdsByType;
};

const shouldTooltipBeShown = ({
  tooltip,
  isPanning,
  isZooming,
}: {
  tooltip: TooltipConfig;
  isPanning: boolean;
  isZooming: boolean;
}) => {
  if (isPanning && tooltip.shouldBeVisibleWhilePanning !== true) {
    return false;
  }

  if (isZooming && tooltip.shouldBeVisibleWhileZooming !== true) {
    return false;
  }

  return true;
};

const getTargetIdsKey = (ids: string[]): string => ids.join('-');

const ReactUnifiedViewer: React.FC<ReactUnifiedViewerProps> = (props) => {
  const {
    id,
    applicationId,
    nodes,
    setRef,
    onUpdateRequest,
    onDeleteRequest,
    onClick,
    onSelect,
    renderContextMenu,
    interactionMode,
    tool,
    shouldFitOnLoad = true,
    shouldShowZoomControls = true,
    shouldUseShamefulFastMode = false,
    shouldShowSelectionRectanglePerNode = true,
    shouldOnlySelectFullyEnclosedNodes = false,
    zoomToFitMode = ZoomToFitMode.DEFAULT,
    tooltips,
    initialViewport,
    debug,
    shouldUseAdaptiveRendering,
    cogniteClient,
    shamefulChartsContext,
    shouldAllowDragDrop = true,
    namespace,
    shamefulShouldUseAlwaysActiveMode = false,
  } = props;
  const unifiedViewerRef = useRef<UnifiedViewer>();
  const [isReady, setIsReady] = useState(false);
  const [forceTooltipRefresh, setForceTooltipUpdate] = useState(0);
  const [contextMenuProps, setContextMenuProps] = useState<
    ContextMenuProps | undefined
  >(undefined);
  const [viewport, setViewport] = useState<IRect | undefined>(undefined);

  const [isZooming, setIsZooming] = useState(false);
  const [isPanning, setIsPanning] = useState(false);
  const [isUsingTool, setIsUsingTool] = useState(false);
  const [isUsingSelectionDrag, setIsUsingSelectionDrag] = useState(false);
  const [isDraggingTargetIds, setIsDraggingTargetIds] = useState<string[]>([]);
  const [isTransformingTargetIds, setIsTransformingTargetIds] = useState<
    string[]
  >([]);

  const debouncedSetIsZooming = useRef(
    conditionalDebounce(
      setIsZooming,
      (value: boolean) => !value,
      IS_ZOOMING_DEBOUNCE_MS
    )
  ).current;
  const debouncedSetIsPanning = useRef(
    conditionalDebounce(
      setIsPanning,
      (value: boolean) => !value,
      IS_PANNING_DEBOUNCE_MS
    )
  ).current;
  const debouncedSetIsUsingTool = useRef(
    conditionalDebounce(
      setIsUsingTool,
      (value: boolean) => !value,
      IS_USING_TOOL_DEBOUNCE_MS
    )
  ).current;
  const debouncedSetIsUsingSelectionDrag = useRef(
    conditionalDebounce(
      setIsUsingSelectionDrag,
      (value: boolean) => !value,
      IS_USING_SELECTION_DRAG_DEBOUNCE_MS
    )
  ).current;
  const debouncedSetIsDragging = useRef(
    conditionalDebounce(
      setIsDraggingTargetIds,
      (value: string[]) => value.length === 0,
      SET_DRAGGING_TARGET_IDS_DEBOUNCE_MS
    )
  ).current;
  const debouncedSetIsTransforming = useRef(
    conditionalDebounce(
      setIsTransformingTargetIds,
      (value: string[]) => value.length === 0,
      IS_TRANSFORMING_DEBOUNCE_MS
    )
  ).current;
  const isInDevelopmentMode = process.env.NODE_ENV === 'development';

  const [errorsByContainerId, setErrorsByContainerId] = useState<
    Map<string, UnifiedViewerErrorEvent>
  >(new Map());
  const [tooltipPositions, setTooltipPositions] = useState<
    Record<string, IRect>
  >({});

  useEffect(() => {
    if (!unifiedViewerRef.current) {
      unifiedViewerRef.current = new UnifiedViewer({
        hostElementId: id,
        applicationId,
        shouldLogMetrics: !debug && !isInDevelopmentMode,
        interactionMode,
        shouldUseAdaptiveRendering,
        shouldUseShamefulFastMode,
        shouldShowSelectionRectanglePerNode,
        shouldOnlySelectFullyEnclosedNodes,
        cogniteClient,
        shamefulChartsContext,
        namespace,
        shamefulShouldUseAlwaysActiveMode,
      });
    }

    const handleErrors = (
      containerConfig: ContainerConfig,
      errorEvent: UnifiedViewerErrorEvent
    ) => {
      const containerId = containerConfig.id;
      if (containerId !== undefined) {
        setErrorsByContainerId((prevErrorsByContainerId) => {
          return new Map(prevErrorsByContainerId).set(containerId, errorEvent);
        });
      }
    };

    unifiedViewerRef?.current.addEventListener(
      UnifiedViewerEventType.ON_CONTAINER_ERROR,
      handleErrors
    );

    return () => {
      unifiedViewerRef.current?.onDestroy();
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_CONTAINER_ERROR,
        handleErrors
      );
      unifiedViewerRef.current = undefined;
    };
  }, [id, interactionMode]);

  useEffect(() => {
    if (setRef && unifiedViewerRef.current) {
      setRef(unifiedViewerRef.current);
    }
  }, [unifiedViewerRef, setRef]);

  useEffect(() => {
    unifiedViewerRef.current?.toggleDragDrop(shouldAllowDragDrop);
  }, [shouldAllowDragDrop]);

  useEffect(() => {
    if (unifiedViewerRef.current === undefined) {
      return;
    }

    if (initialViewport !== undefined) {
      const unifiedViewer = unifiedViewerRef.current;
      unifiedViewer.setViewport(initialViewport);
      return;
    }

    if (shouldFitOnLoad) {
      const { containers } = partitionIntoContainersAndAnnotations(nodes);
      const boundaryHeuristic = getBoundaryHeuristicForContainers(containers, {
        relativeMargin: ZOOM_TO_FIT_MARGIN,
      });

      unifiedViewerRef.current.setViewport({
        x: boundaryHeuristic.x + boundaryHeuristic.width / 2,
        y: boundaryHeuristic.y + boundaryHeuristic.height / 2,
        width: boundaryHeuristic.width,
        height: boundaryHeuristic.height,
      });
      return;
    }
  }, []);

  useEffect(() => {
    unifiedViewerRef.current?.once(UnifiedViewerEventType.ON_NODES_LOAD, () => {
      if (shouldFitOnLoad) {
        unifiedViewerRef.current?.zoomToFit(zoomToFitMode, {
          relativeMargin: ZOOM_TO_FIT_MARGIN,
        });
      }
      setIsReady(true);
    });
  }, []);

  useEffect(() => {
    const t1 = performance.now();
    unifiedViewerRef.current?.once(UnifiedViewerEventType.ON_NODES_LOAD, () => {
      const t2 = performance.now();
      getMetricsLogger()?.trackEvent(
        TrackedEventType.PERFORMANCE_LOAD_ALL_NODES,
        {
          ...countBy(nodes, 'type'),
          $duration: parseFloat(((t2 - t1) / 1000).toFixed(3)),
        }
      );
    });
  }, []);

  // In some cases, the tooltip targets are known before the targets themselves
  // are fully loaded/ready. This means that if the target, for example, is a
  // container annotation and the container is loaded *after* the first tooltip
  // render (i.e., when isReady is true), then the tooltips attached to the
  // container annotations won't be shown. We mitigate this below by forcing a
  // refresh/re-render of the tooltips whenever containers are loaded
  useEffect(() => {
    unifiedViewerRef.current?.addEventListener(
      UnifiedViewerEventType.ON_CONTAINER_LOAD,
      () => setForceTooltipUpdate((prevForceUpdate) => prevForceUpdate + 1)
    );
  }, []);

  useEffect(() => {
    unifiedViewerRef.current?.setNodes(nodes);
  }, [nodes]);

  useEffect(() => {
    if (tool) {
      unifiedViewerRef.current?.setTool(tool);
    }
  }, [unifiedViewerRef, tool]);

  useEffect(() => {
    // Don't render the context menu if the library is not ready
    if (!isReady) {
      setContextMenuProps(undefined);
      return;
    }
    // Force re-render of the context menu if the user interacted with the canvas
    if (isPanning || isZooming || isUsingTool || isUsingSelectionDrag) {
      setContextMenuProps(undefined);
      return;
    }
    // Don't render the context menu if we're transforming or dragging something
    if (isDraggingTargetIds.length > 0 || isTransformingTargetIds.length > 0) {
      setContextMenuProps(undefined);
      return;
    }
  }, [
    isPanning,
    isZooming,
    isUsingTool,
    isUsingSelectionDrag,
    isReady,
    isDraggingTargetIds,
    isTransformingTargetIds,
    setContextMenuProps,
  ]);

  useEffect(() => {
    const ref = unifiedViewerRef.current;
    if (ref === undefined) {
      return;
    }

    if (tooltips === undefined) {
      return;
    }

    if (!isReady) {
      // We can get incorrect positioning of items if the library isn't ready yet.
      return;
    }

    if (isUsingTool || isUsingSelectionDrag) {
      // We skip costly measuring of positions when the viewport is in flux
      return;
    }

    const rectByTargetId = uniq(
      tooltips
        .filter((tooltip) =>
          shouldTooltipBeShown({ tooltip, isPanning, isZooming })
        )
        .map(({ targetIds }) => targetIds)
    )
      .filter((targetIds) => {
        // Skip measuring positions of targets that are currently being dragged or transformed
        return (
          !isDraggingTargetIds.some((draggingTargetId) =>
            targetIds.includes(draggingTargetId)
          ) &&
          !isTransformingTargetIds.some((transformingTargetId) =>
            targetIds.includes(transformingTargetId)
          )
        );
      })
      .reduce((acc, targetIds) => {
        const rect = ref.getRectByIds(targetIds);

        if (rect === undefined) {
          return acc;
        }

        return {
          ...acc,
          [getTargetIdsKey(targetIds)]: rect,
        };
      }, {});

    setTooltipPositions(rectByTargetId);
  }, [
    nodes,
    tooltips,
    isPanning,
    isZooming,
    isUsingTool,
    isDraggingTargetIds,
    isTransformingTargetIds,
    isUsingSelectionDrag,
    isReady,
    forceTooltipRefresh,
    viewport,
  ]);

  useEffect(() => {
    if (unifiedViewerRef.current === undefined) {
      return;
    }

    const arePersistentTooltipPresent = tooltips?.some(
      (tooltip) =>
        tooltip.shouldBeVisibleWhilePanning === true ||
        tooltip.shouldBeVisibleWhileZooming === true
    );

    const updateViewportIfPersistentTooltipsPresent = throttle(() => {
      if (arePersistentTooltipPresent) {
        setViewport(unifiedViewerRef.current?.getViewport());
      }
    }, UPDATE_VIEWPORT_THROTTLE_MS);

    // Keep track of if the component is panning or zooming
    const handleOnZoomStart = () => {
      debouncedSetIsZooming(true);
      updateViewportIfPersistentTooltipsPresent();
    };
    const handleOnZoomChange = () =>
      updateViewportIfPersistentTooltipsPresent();

    const handleOnZoomEnd = () => {
      debouncedSetIsZooming(false);
      updateViewportIfPersistentTooltipsPresent();
    };

    const handleOnPanStart = () => {
      debouncedSetIsPanning(true);
      updateViewportIfPersistentTooltipsPresent();
    };
    const handleOnPanMove = () => updateViewportIfPersistentTooltipsPresent();
    const handleOnPanEnd = () => {
      debouncedSetIsPanning(false);
      updateViewportIfPersistentTooltipsPresent();
    };

    const handleOnToolStart = () => debouncedSetIsUsingTool(true);
    const handleOnToolEnd = () => debouncedSetIsUsingTool(false);
    const handleSelectionDragStart = () =>
      debouncedSetIsUsingSelectionDrag(true);
    const handleSelectionDragEnd = () =>
      debouncedSetIsUsingSelectionDrag(false);

    const handleOnDragStart = (idsByType: IdsByType) =>
      debouncedSetIsDragging([
        ...idsByType.containerIds,
        ...idsByType.annotationIds,
      ]);

    const handleOnDragMove = (idsByType: IdsByType) =>
      debouncedSetIsDragging([
        ...idsByType.containerIds,
        ...idsByType.annotationIds,
      ]);

    const handleOnDragEnd = () => debouncedSetIsDragging([]);

    const handleOnTransformChange = (idsByType: IdsByType) =>
      debouncedSetIsTransforming([
        ...idsByType.containerIds,
        ...idsByType.annotationIds,
      ]);

    const handleTransformEnd = () => debouncedSetIsTransforming([]);

    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_ZOOM_START,
      handleOnZoomStart
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_ZOOM_CHANGE,
      handleOnZoomChange
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_ZOOM_END,
      handleOnZoomEnd
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_PAN_START,
      handleOnPanStart
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_PAN_MOVE,
      handleOnPanMove
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_PAN_END,
      handleOnPanEnd
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_TOOL_START,
      handleOnToolStart
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_TOOL_END,
      handleOnToolEnd
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_SELECTION_DRAG_START,
      handleSelectionDragStart
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_SELECTION_DRAG_END,
      handleSelectionDragEnd
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_DRAG_START,
      handleOnDragStart
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_DRAG_MOVE,
      handleOnDragMove
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_DRAG_END,
      handleOnDragEnd
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_TRANSFORM_CHANGE,
      handleOnTransformChange
    );
    unifiedViewerRef.current.addEventListener(
      UnifiedViewerEventType.ON_TRANSFORM_END,
      handleTransformEnd
    );

    return () => {
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_ZOOM_START,
        handleOnZoomStart
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_ZOOM_CHANGE,
        handleOnZoomChange
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_ZOOM_END,
        handleOnZoomEnd
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_PAN_START,
        handleOnPanStart
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_PAN_MOVE,
        handleOnPanMove
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_PAN_END,
        handleOnPanEnd
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_TOOL_START,
        handleOnToolStart
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_TOOL_END,
        handleOnToolEnd
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_SELECTION_DRAG_START,
        handleSelectionDragStart
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_SELECTION_DRAG_END,
        handleSelectionDragEnd
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_DRAG_START,
        handleOnDragStart
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_DRAG_MOVE,
        handleOnDragMove
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_DRAG_END,
        handleOnDragEnd
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_TRANSFORM_CHANGE,
        handleOnTransformChange
      );
      unifiedViewerRef.current?.removeEventListener(
        UnifiedViewerEventType.ON_TRANSFORM_END,
        handleTransformEnd
      );
    };
  }, [
    setIsZooming,
    setIsPanning,
    setIsDraggingTargetIds,
    setIsUsingTool,
    setIsUsingSelectionDrag,
    tooltips,
  ]);

  useUnifiedViewerEventHandler(
    unifiedViewerRef,
    UnifiedViewerEventType.ON_UPDATE_REQUEST,
    onUpdateRequest
  );
  useUnifiedViewerEventHandler(
    unifiedViewerRef,
    UnifiedViewerEventType.ON_DELETE_REQUEST,
    onDeleteRequest
  );
  useUnifiedViewerEventHandler(
    unifiedViewerRef,
    UnifiedViewerEventType.ON_CLICK,
    onClick
  );
  useUnifiedViewerEventHandler(
    unifiedViewerRef,
    UnifiedViewerEventType.ON_SELECT,
    onSelect
  );

  useUnifiedViewerEventHandler(
    unifiedViewerRef,
    UnifiedViewerEventType.ON_CONTEXT_MENU,
    renderContextMenu !== undefined
      ? (position: Position, idsByType: IdsByType): void => {
          setContextMenuProps({ position, idsByType });
        }
      : undefined
  );

  useEffect(() => {
    const viewer = unifiedViewerRef.current;
    if (!isReady || viewer === undefined) {
      return;
    }
    const causeMapNodes = nodes.filter(
      (node): node is CauseMapNodeAnnotation =>
        isAnnotation(node) && isCauseMapNodeAnnotation(node)
    );
    if (causeMapNodes.length === 0 || !viewer.needsRelayout(causeMapNodes)) {
      return;
    }
    viewer.relayoutCauseMapNodes(causeMapNodes);
  }, [isReady]);

  const shouldShowTooltips =
    tooltips !== undefined && !isUsingTool && !isUsingSelectionDrag && isReady;

  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div
        id={id}
        style={{ height: '100%', width: '100%', overflow: 'hidden' }}
        onClick={() => {
          setContextMenuProps(undefined);
        }}
      >
        {/*
            Canvas is not represented in the React code but will be
            prepended as the first child here
        */}

        <ErrorComponent
          unifiedViewerRef={unifiedViewerRef.current}
          errorsByContainerId={errorsByContainerId}
        />
      </div>

      {shouldShowTooltips &&
        tooltips
          .filter((tooltip) =>
            shouldTooltipBeShown({ tooltip, isPanning, isZooming })
          )
          .map((tooltip, index) => {
            if (isDraggingTargetIds.length > 0) {
              // Skip tooltips while dragging
              return;
            }

            if (isTransformingTargetIds.length > 0) {
              // Skip tooltips while transforming
              return;
            }

            const tooltipPosition =
              tooltipPositions[getTargetIdsKey(tooltip.targetIds)];

            if (tooltipPosition === undefined) {
              return;
            }

            return (
              getTargetIdsKey(tooltip.targetIds) && (
                <NodeTooltip
                  key={
                    tooltip.id !== undefined
                      ? tooltip.id
                      : `${getTargetIdsKey(tooltip.targetIds)}_${
                          tooltip.anchorTo
                        }_${index}`
                  }
                  targetRect={
                    tooltipPositions[getTargetIdsKey(tooltip.targetIds)]
                  }
                  anchorTo={tooltip.anchorTo}
                  shouldPositionStrictly={tooltip.shouldPositionStrictly}
                >
                  {tooltip.content}
                </NodeTooltip>
              )
            );
          })}

      {renderContextMenu !== undefined && contextMenuProps !== undefined && (
        <div onClick={() => setContextMenuProps(undefined)}>
          <CanvasTooltip
            key={`${id}-ufv-context-menu`}
            position={contextMenuProps.position}
          >
            {renderContextMenu(contextMenuProps.idsByType)}
          </CanvasTooltip>
        </div>
      )}

      {shouldShowZoomControls && (
        <ZoomControlsPositionContainer>
          <ZoomControls
            unifiedViewerRef={unifiedViewerRef.current}
            zoomToFitMode={zoomToFitMode}
          />
        </ZoomControlsPositionContainer>
      )}

      {isInDevelopmentMode && debug && unifiedViewerRef.current && (
        <DeveloperConsole unifiedViewer={unifiedViewerRef.current}>
          <ReactUnifiedViewer
            {...props}
            id="debugWorkspace"
            shouldShowZoomControls={false}
            debug={false}
          />
        </DeveloperConsole>
      )}
    </div>
  );
};

const ZoomControlsPositionContainer = styled.div`
  position: absolute;
  z-index: 2;
  right: 10px;
  bottom: 10px;
`;

export default ReactUnifiedViewer;
