import type { Template } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { useSelectedRootAPMAsset } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserQuery } from '@infield/features/user';
import { useFDMServices } from '@infield/providers/fdm-services';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useCreateTemplate = () => {
  const { templateService } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const { data: user } = useCurrentUserQuery();
  const metrics = useMetrics(METRICS_NAMESPACES.auditTemplate);
  const { data: rootAPMAsset } = useSelectedRootAPMAsset();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  return useMutation(
    (newTemplate: Template) => {
      const template = {
        ...newTemplate,
        rootLocation: rootAPMAsset || undefined,
      };
      return templateService.createTemplate(template, user!);
    },
    {
      onMutate: () => {
        const sliTimerStartTime = Date.now();
        return { sliTimerStartTime };
      },
      onError: (err, _, context) => {
        makeToast({
          body: t('CREATE_TEMPLATE_ERROR', `Unable to create template.`),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.TEMPLATE_CREATE,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_CREATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: (_, newTemplate, context) => {
        metrics.track('Create', newTemplate);

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_CREATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE_LIST],
        });
      },
    }
  );
};
