import type { Operation } from '@cognite/apm-client';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';

export const getOperationsWithUniqueOrder = (operations: Operation[]) => {
  const operationsByActivity = groupBy(operations, 'parentActivityId');

  const uniqueOperations: Operation[] = [];

  Object.values(operationsByActivity).forEach((singleActivityOperations) => {
    const [undefinedOrderOperations, definedOrderOperations] =
      singleActivityOperations.reduce(
        (acc, operation) => {
          if (!operation.order) {
            acc[0].push(operation);
          } else {
            acc[1].push(operation);
          }
          return acc;
        },
        [[], []] as [Operation[], Operation[]]
      );

    const operationsWithUniqueOrder = uniqBy(
      definedOrderOperations,
      (operation) => operation.order
    );

    uniqueOperations.push(
      ...operationsWithUniqueOrder,
      ...undefinedOrderOperations
    );
  });

  return uniqueOperations;
};
