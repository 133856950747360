import type { TFunction, TOptions, WithT } from 'i18next';
import type {
  ComponentProps,
  ComponentType,
  FC,
  HTMLProps,
  ReactNode,
} from 'react';
import { useMemo } from 'react';
import {
  Trans as BaseTrans,
  useTranslation as useTranslationInner,
} from 'react-i18next';

import i18n from './i18n';

export interface TransProps<E extends Element = HTMLDivElement>
  extends HTMLProps<E>,
    Partial<WithT> {
  components?: readonly ReactNode[] | { [tagName: string]: ReactNode };
  count?: number;
  defaults?: string;
  i18nKey?: string;
  ns?: string;
  parent?: string | ComponentType<unknown> | null; // used in createElement if not null
  tOptions?: Record<string, unknown>;
  values?: Record<string, unknown>;
  t?: TFunction;
}

export type TFunctionWrapper = (
  key: string,
  referenceValue: string,
  options?: TOptions
) => string;

export type TComponentWrapper = FC<TransProps>;

export const useTranslation = (namespace?: string) => {
  const { t } = useTranslationInner(namespace, { i18n, useSuspense: false });

  return useMemo(() => {
    const Trans: FC<
      Required<Pick<ComponentProps<typeof BaseTrans>, 'i18nKey' | 'children'>>
    > = ({ children, i18nKey }) => (
      <BaseTrans i18n={i18n} i18nKey={i18nKey} ns={namespace}>
        {children}
      </BaseTrans>
    );

    return {
      t,
      Trans,
      i18n,
    };
  }, [namespace, t]);
};
