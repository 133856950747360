import { ToolBar as CogsToolBar, Flex } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  height: 100%;
`;

export const Asset3DViewerWrapper = styled(Flex)`
  flex: 1;
`;

export const Asset3DViewerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ToolBar = styled(CogsToolBar)<{
  $isDesktop: boolean;
  $marginBottom: number;
}>`
  position: absolute;
  margin-bottom: ${({ $marginBottom }) => `${$marginBottom}px`};
  right: 4px;
  z-index: ${z.THREED_TOOLBAR};
  ${({ $isDesktop }) => ($isDesktop ? 'top: 4px' : 'bottom: 4px')}
`;

export const SearchInputContainer = styled.div`
  position: absolute;
  z-index: ${z.THREED_TOOLBAR};
  bottom: 8px;
  right: 8px;
  width: fit-content;
`;
