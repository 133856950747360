import { Size } from '../annotations/types';

const getConstrainedDimensions = (
  { maxWidth, maxHeight }: { maxWidth: number; maxHeight: number },
  { width, height }: Size
): Size & { scale: number } => {
  const scale = Math.min(maxWidth / width, maxHeight / height);
  const constrainedWidth = width * scale;
  const constrainedHeight = height * scale;

  return {
    width: constrainedWidth,
    height: constrainedHeight,
    scale,
  };
};

export default getConstrainedDimensions;
