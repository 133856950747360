import { PageHeader } from '@cognite/cogs-lab';
import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledPageHeader = styled(PageHeader)`
  .cogs-breadcrumbs__item__button {
    cursor: pointer !important;
  }
  .cogs-pageheader-navigation-wrapper {
    overflow: hidden;
  }
`;
