import type { APMAsset } from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client/src/types';
import { useCurrentUserQuery } from '@infield/features/user';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useSelectedRootAPMAsset = () => {
  const { assetService } = useFDMServices();
  const { data: userData } = useCurrentUserQuery();
  const userLocationPreference = userData?.preferences?.infield?.location;

  return useQuery<APMAsset | null>(
    [QueryKeys.APM_ASSET, userLocationPreference],
    async () => {
      if (!userLocationPreference) {
        return null;
      }

      const filters: Filters = {
        equals: {
          property: 'externalId',
          eq: userLocationPreference.assetExternalId
            ? userLocationPreference.assetExternalId
            : userLocationPreference.externalId,
        },
      };

      const asset = await assetService.getAPMAsset(filters);

      if (asset === undefined) {
        return null;
      }

      return asset;
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: QueryKeys.APM_ASSET,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(userLocationPreference?.externalId),
    }
  );
};
