import { Button, Flex } from '@cognite/cogs.js-v10';
import { TaskFormBlockWrapper } from '@infield/features/task/task-form/elements';
import styled from 'styled-components';

export const StatusRadioGroup = styled(Flex)`
  margin: 0 auto;
`;

export const DeleteButton = styled(Button)`
  margin-left: auto;
`;

export const MessageContainer = styled.div`
  width: 100%;
`;

export const TaskStatusWrapper = styled(TaskFormBlockWrapper)`
  &&& {
    background-color: var(--cogs-surface--muted);
  }
`;

// TODO(INFIELD2-1959): There is a cogs bug where if you click on the edge of a radio button, it is not registered
// This is a workaround until we get a fix for that
export const TemporaryRadioButtonWrapper = styled(Flex)``;
