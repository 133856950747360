import Konva from 'konva';
import { IRect } from 'konva/cmj/types';
import { take, takeRight } from 'lodash';
import chunk from 'lodash/chunk';

import { Vertex } from '../types';

const getPointsConsideringEndpoints = (node: Konva.Arrow) => {
  if (node.attrs.fromId === undefined && node.attrs.toId === undefined) {
    return node.points();
  }

  if (node.attrs.fromId !== undefined) {
    return takeRight(node.points(), 2);
  }

  if (node.attrs.toId !== undefined) {
    return take(node.points(), 2);
  }

  throw new Error(
    'Could not get points considering endpoints, this should never happen'
  );
};

const getNormalizedPolylineDimensionsFromNode = (
  node: Konva.Arrow,
  getContainerRectById: (id: string) => IRect | undefined
): Vertex[] => {
  const useNormalizedCoordinates = node.attrs.containerId !== undefined;
  const translation = node.position();

  if (!useNormalizedCoordinates) {
    return chunk(getPointsConsideringEndpoints(node), 2).map(([x, y]) => ({
      x: x + translation.x,
      y: y + translation.y,
    }));
  }

  const containerRect = getContainerRectById(node.attrs.containerId);
  if (containerRect === undefined) {
    throw new Error('Could not find container dimensions for annotation');
  }

  return chunk(node.points(), 2).map(([x, y]) => ({
    x: (x + translation.x) / containerRect.width,
    y: (y + translation.y) / containerRect.height,
  }));
};

export default getNormalizedPolylineDimensionsFromNode;
