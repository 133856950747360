import type { Timeseries } from '@cognite/sdk';
import dayjs from 'dayjs';
import type { DurationUnitType } from 'dayjs/plugin/duration';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';

import type { Domain } from '../../trends-chart/types';

import { getLimitedDomain, getPeriodFromXSubDomain } from './utils';

type Props = {
  activeTimeseries: Timeseries[];
  defaultButtonValue?: string;
};

export const useDomains = (props: Props) => {
  const { defaultButtonValue = 'month' } = props;

  const [xSubDomain, setXSubDomain] = useState<Domain | null>(null);
  const [ySubDomains, setYSubDomains] = useState<
    Record<string, Domain | undefined>
  >({});
  const [initialXDomain, setInitialXDomain] = useState<Domain | null>(null);
  const [chartWidth, setChartWidth] = useState<number>(0);

  const [isLive, setIsLive] = useState(false);
  const [selectedButtonValue, setSelectedButtonValue] =
    useState(defaultButtonValue);
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    setYSubDomains((prevYSubDomains) => {
      const tempYSubDomains = { ...prevYSubDomains };
      const subDomainsToRemove = Object.keys(tempYSubDomains).filter(
        (id) => !props.activeTimeseries.find((ts) => ts.id.toString() === id)
      );
      for (let i = 0; i < subDomainsToRemove.length; i++) {
        delete tempYSubDomains[subDomainsToRemove[i]];
      }

      return tempYSubDomains;
    });
  }, [props.activeTimeseries]);

  useEffect(() => {
    if (props.activeTimeseries.length === 1) {
      setIsLive(true);
    }
  }, [props.activeTimeseries.length]);

  useEffect(() => {
    // update the selected domain button value based on domain
    if (
      xSubDomain &&
      Number.isFinite(xSubDomain[0]) &&
      Number.isFinite(xSubDomain[1])
    )
      setSelectedButtonValue(getPeriodFromXSubDomain(xSubDomain));
  }, [xSubDomain]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDomainClick = useCallback(
    debounce((buttonValue) => {
      const newXSubDomain = getLimitedDomain(
        dayjs.duration(1, buttonValue).asMilliseconds(),
        chartWidth
      );

      setXSubDomain(newXSubDomain);
      setSelectedButtonValue(buttonValue);
      setButtonClicked(true);
    }, 300),
    [chartWidth]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedUpdateDomains = useCallback(
    debounce((newSubDomains) => {
      setYSubDomains((prevYSubDomains) => {
        const tsIds = Object.keys(newSubDomains);
        const tempYSubDomains = {
          ...prevYSubDomains,
          ...tsIds.reduce<Record<string, Domain>>((acc, id) => {
            acc[id] = newSubDomains[id].y.map((val: any) =>
              Number.parseFloat(val.toPrecision(3))
            );
            return acc;
          }, {}),
        };

        return tempYSubDomains;
      });
    }, 200),
    []
  );

  const handleLiveClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!xSubDomain) return;

    const newXSubDomain = getLimitedDomain(
      xSubDomain[1] - xSubDomain[0],
      chartWidth
    );

    setXSubDomain(newXSubDomain);
    setButtonClicked(true);
    setIsLive(true);
  };

  const handleChartWidthChange = useCallback(
    (newChartWidth: number) => {
      setChartWidth(newChartWidth);
      setXSubDomain(
        getLimitedDomain(
          dayjs
            .duration(1, selectedButtonValue as DurationUnitType)
            .asMilliseconds(),
          newChartWidth
        )
      );
      setInitialXDomain(
        getLimitedDomain(
          dayjs.duration(3, 'year').asMilliseconds(),
          newChartWidth
        )
      );
    },
    [selectedButtonValue]
  );

  const limitXSubDomain = (newXSubDomain: Domain) => {
    const tempXSubDomain = getLimitedDomain(
      newXSubDomain[1] - newXSubDomain[0],
      chartWidth,
      newXSubDomain[1]
    );
    return tempXSubDomain;
  };

  const handleXSubDomainChange = (newXSubDomain: Domain) => {
    // setting button clicked to false because the button is changing the subdomain :(

    setXSubDomain(newXSubDomain);
    setButtonClicked(false);
    setIsLive(
      newXSubDomain
        ? newXSubDomain[1] >= Date.now() && newXSubDomain[0] <= Date.now()
        : false
    );
  };

  const handleUpdateDomains = (newSubDomains: any) => {
    // When scrolling the y-axis, we only want to scroll the y-axis and not the page
    // So, when we call handleStopScroll, we set overflow hidden and when calling
    // handleStartScroll sets overflow to auto so we can scroll again

    // As a temporary solution, we stop scrolling whenever we change the ySubDomain
    // and then call handleStartScroll to enable scrolling when we're not changing
    // the ySubDomain
    const tsIds = Object.keys(newSubDomains);
    const tsWithNewySubDomain = tsIds.filter(
      (id) => ySubDomains[id] && !isEqual(newSubDomains[id].y, ySubDomains[id])
    );
    if (tsWithNewySubDomain.length) {
      handleDebouncedUpdateDomains(newSubDomains);
    }
  };

  return {
    ySubDomains,
    setYSubDomains,
    initialXDomain,
    xSubDomain,
    handleXSubDomainChange,
    handleChartWidthChange,
    limitXSubDomain,
    handleUpdateDomains,
    isLive,
    handleLiveClick,
    handleDomainClick,
    selectedButtonValue,
    buttonClicked,
  };
};
