const encodeAsPdfDataUrl = (arrayBuffer: ArrayBuffer): string => {
  const bytes = new Uint8Array(arrayBuffer);
  const binary = bytes.reduce(
    (acc, byte) => acc + String.fromCharCode(byte),
    ''
  );
  const base64String = btoa(binary);
  return `data:application/pdf;base64,${base64String}`;
};

export default encodeAsPdfDataUrl;
