import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { makeToast } from '@cognite/cogs-lab';
import { useSDK } from '@cognite/sdk-provider';

import { NOT_LISTED_OPERATION_CATEGORY } from '../constants';
import { fetchOperations } from '../services';

export const useOperations = () => {
  const sdk = useSDK();

  return useQuery<Awaited<ReturnType<typeof fetchOperations>>, Error>(
    ['operations'],
    async () => {
      try {
        const operations = await fetchOperations(sdk);
        return operations.filter(
          (o) => o.category !== NOT_LISTED_OPERATION_CATEGORY
        );
      } catch (error) {
        const axiosError = error as unknown as AxiosError<{ detail: string }>;
        const requestId =
          axiosError?.response?.headers['x-request-id'] || 'Missing request ID';
        const errorMessage =
          axiosError?.response?.data?.detail ||
          'Could not get available operations';
        makeToast({
          body: `Request ID: ${requestId}, error: ${errorMessage}`,
          type: 'danger',
        });
        Sentry.captureException(axiosError);
        return [];
      }
    },
    {
      staleTime: Infinity,
    }
  );
};
