import type { InputProps } from '@cognite/cogs.js-v10';
import { Body, Flex, Input, Link, Modal } from '@cognite/cogs.js-v10';
import {
  useAppConfigQuery,
  useUpsertAppConfigMutation,
} from '@infield/features/app-config/hooks';
import { useSpaceQuery } from '@infield/features/app-config/hooks/use-query/use-space-query';
import type { AppConfig } from '@infield/features/app-config/types';
import { getIsNewConfigVersion } from '@infield/features/app-config/utils/utils';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import { useEffect, useRef, useState } from 'react';

import * as S from '../elements';

interface Props {
  dataType: 'app' | 'source';
  hasAppConfigPermission: boolean;
  rootLocationExternalId: string;
}

export const RootLocationConfigSpace: FC<Props> = ({
  dataType,
  hasAppConfigPermission,
  rootLocationExternalId,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>('');
  const [instanceSpace, setInstanceSpace] = useState<string>('');
  const [inputStatus, setInputStatus] =
    useState<InputProps['status']>(undefined);
  const [inputStatusText, setInputStatusText] = useState<string>('');
  const [enableValidation, setEnableValidation] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const { data: spaces = [], status } = useSpaceQuery(
    inputValue,
    enableValidation
  );
  const { data: appConfig, isSuccess, isLoading } = useAppConfigQuery();
  const isNewConfigVersion = getIsNewConfigVersion(appConfig);
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();

  const configuredRootLocation =
    appConfig?.featureConfiguration?.rootLocationConfigurations?.find(
      (rootLocation) =>
        rootLocationExternalId ===
        (isNewConfigVersion
          ? rootLocation.externalId
          : rootLocation.assetExternalId)
    );
  const configuredSpace =
    dataType === 'app'
      ? configuredRootLocation?.appDataInstanceSpace
      : configuredRootLocation?.sourceDataInstanceSpace;

  useEffect(() => {
    if (isSuccess && configuredRootLocation) {
      if (dataType === 'app' && configuredRootLocation.appDataInstanceSpace) {
        setInstanceSpace(configuredRootLocation.appDataInstanceSpace);
      }
      if (
        dataType === 'source' &&
        configuredRootLocation.sourceDataInstanceSpace
      ) {
        setInstanceSpace(configuredRootLocation.sourceDataInstanceSpace);
      }
    }
  }, [isSuccess, configuredRootLocation, appConfig, dataType]);

  const handleSave = () => {
    if (inputValue !== configuredSpace) {
      if (dataType === 'app' && configuredRootLocation?.appDataInstanceSpace) {
        setShowConfirmModal(true);
      } else {
        saveConfig();
      }
    }
  };

  const saveConfig = async () => {
    if (!appConfig) return;
    const newAppConfig: AppConfig = {
      ...appConfig,
      featureConfiguration: {
        ...appConfig.featureConfiguration,
        rootLocationConfigurations: [
          ...(appConfig.featureConfiguration?.rootLocationConfigurations?.map(
            (rootConfig) => {
              if (
                isNewConfigVersion
                  ? configuredRootLocation?.externalId === rootConfig.externalId
                  : configuredRootLocation?.assetExternalId ===
                    rootConfig.assetExternalId
              ) {
                return dataType === 'app'
                  ? {
                      ...rootConfig,
                      appDataInstanceSpace: instanceSpace,
                    }
                  : {
                      ...rootConfig,
                      sourceDataInstanceSpace: instanceSpace,
                    };
              }
              return { ...rootConfig };
            }
          ) || []),
        ],
      },
    };

    await upsertAppConfig({
      newAppConfig,
    });

    window.location.reload();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (inputStatus) {
      setInputStatus(undefined);
    }
  };

  useEffect(() => {
    if (enableValidation && status !== 'loading') {
      if (spaces.length > 0 && status === 'success') {
        setInputStatus('success');
        setInputStatusText('Valid space id');
        setInstanceSpace(spaces[0].space);
      } else {
        setInputStatus('critical');
        setInputStatusText('Invalid space id');
      }
    }
  }, [enableValidation, spaces, status]);

  return (
    <S.Wrapper direction="column" gap={32}>
      <Flex direction="column" gap={8}>
        <Body size="x-small" strong>
          {dataType === 'app'
            ? 'AppData instance space'
            : 'SourceData instance space'}
        </Body>
        <Body size="x-small">
          Currently configured space: {configuredSpace || 'none'}.
        </Body>
        <Input
          ref={ref}
          value={inputValue}
          placeholder={configuredSpace || ''}
          disabled={!hasAppConfigPermission}
          onChange={handleChange}
          onBlur={() => setEnableValidation(true)}
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              // Press enter to validate spaceId
              ref.current?.blur();
            }
          }}
          onFocus={() => setEnableValidation(false)}
          status={inputStatus}
          statusText={inputStatusText}
        />
      </Flex>
      {dataType === 'source' && (
        <Body size="x-small" strong>
          Make sure you update the transformations in CDF accordingly.
        </Body>
      )}
      <Flex direction="column" gap={8}>
        <S.SaveButton
          onClick={handleSave}
          disabled={
            inputStatus !== 'success' || instanceSpace === configuredSpace
          }
          loading={isLoading}
        >
          Save configuration
        </S.SaveButton>
        <Body size="x-small">Saving configuration will reload the app</Body>
      </Flex>
      <Modal
        title="Confirm space change"
        visible={showConfirmModal}
        okText="Confirm"
        onOk={() => saveConfig()}
        onCancel={() => setShowConfirmModal(false)}
      >
        <Body>
          Pressing &apos;Confirm&apos; will cause all application data
          associated with this location to be stored in and fetched from the{' '}
          {inputValue} space. Make sure users in this location have access to
          the new space before proceeding.
          <br /> <br />
          Any existing application data on this location must be migrated
          manually. CDF transformations for this can be found{' '}
          <Link
            target="_blank"
            href="https://github.com/cognitedata/apm_datamodels/tree/main/transformations/infield/migrate_app_data_to_new_space"
          >
            here
          </Link>
          .
        </Body>
      </Modal>
    </S.Wrapper>
  );
};
