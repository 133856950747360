import { Body, Flex, Heading } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const UserOnboardingWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 80px 0px;
  background-color: ${({ theme }) => theme['surface--strong']};
`;

export const UserOnboardingContainer = styled(Flex)`
  margin: 0 16px;
  width: 100%;
  max-width: 416px;
`;

export const SubHeading = styled(Heading)`
  color: var(--cogs-text-icon--medium);
`;

export const LanguageSubText = styled(Body)`
  color: var(--cogs-text-icon--medium);
`;

export const RequiredText = styled(Body)`
  color: var(--cogs-themed-text-icon--status-critical);
  margin-left: 4px;
  font-size: 20px;
`;
