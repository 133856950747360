import { Button, ChevronDownIcon } from '@cognite/cogs.js-v10';
import { Collapse } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import { useMemo, useState } from 'react';

import * as S from './elements';

type Props = {
  isExpanded?: boolean;
  isDragging?: boolean;
  isWithoutPadding?: boolean;
  arrowButtonTestId?: string;
  header: JSX.Element;
  extra?: JSX.Element;
};

export const CustomCollapse: FC<PropsWithChildren<Props>> = ({
  isExpanded = false,
  isDragging = false,
  isWithoutPadding = false,
  arrowButtonTestId,
  header,
  extra = null,
  children,
}) => {
  const [expandedState, setExpandedState] = useState(isExpanded);

  const renderLeftIndicator = () => {
    return (
      <ChevronDownIcon
        className={`cogs-v10 cogs-accordion__header__icon ${
          expandedState ? 'cogs-v10 cogs-accordion__header__icon--expanded' : ''
        }`}
      />
    );
  };

  const ariaLabel = useMemo(() => {
    return expandedState ? 'Opened group' : 'Closed group';
  }, [expandedState]);

  return (
    <S.CustomCollapseWrapper
      $isWithoutPadding={isWithoutPadding}
      $isDragging={isDragging}
    >
      <S.HeaderWrapper>
        <S.HeaderContentWrapper
          $isWithoutPadding={isWithoutPadding}
          onClick={() => setExpandedState((prev) => !prev)}
        >
          <S.HeaderExpandButtonWrapper>
            <Button
              data-testid={arrowButtonTestId}
              size="x-large"
              type="ghost"
              aria-label={ariaLabel}
              icon={renderLeftIndicator()}
            />
          </S.HeaderExpandButtonWrapper>
          {header}
        </S.HeaderContentWrapper>

        {extra ? (
          <S.HeaderActionMenuWrapper $isWithoutPadding={isWithoutPadding}>
            {extra}
          </S.HeaderActionMenuWrapper>
        ) : null}
      </S.HeaderWrapper>
      <Collapse in={expandedState} timeout={250} unmountOnExit>
        <S.ContentWrapper>{children}</S.ContentWrapper>
      </Collapse>
    </S.CustomCollapseWrapper>
  );
};
