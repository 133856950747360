import type {
  Annotation,
  ContainerConfig,
  DocumentContainerProps,
} from '@cognite/unified-file-viewer';
import {
  ContainerType,
  getDefaultStylesByResourceType,
} from '@cognite/unified-file-viewer';
import type {
  UfvAnnotation,
  UfvAnnotationMetadata,
} from '@infield/features/file-viewer-modal/types';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

import { convertIdmAnnotationToUfvAnnotation } from '../utils/convertIdmAnnotationToUfvAnnotation';

export const useIdmAnnotations = (
  handleAnnotationClick: (annotation: UfvAnnotation) => void,
  enabled: boolean,
  container?: ContainerConfig<UfvAnnotationMetadata | undefined>
) => {
  const { mediaService } = useFDMServices();
  const documentContainer =
    container && container.type === ContainerType.DOCUMENT
      ? (container as DocumentContainerProps)
      : null;

  return useQuery<Annotation<UfvAnnotationMetadata>[] | null>(
    [QueryKeys.ANNOTATIONS, documentContainer],
    async () => {
      if (documentContainer === null || documentContainer.id === undefined) {
        return null;
      }

      const annotations = await mediaService.getFileAnnotations(
        documentContainer.id
      );
      const filteredAnnotationsByPage = annotations.filter(
        (ann) => ann.properties.startNodePageNumber === documentContainer.page
      );
      const documentAnnotations = convertIdmAnnotationToUfvAnnotation(
        filteredAnnotationsByPage,
        documentContainer.id
      ).map((annotation: UfvAnnotation) => ({
        ...annotation,
        onClick: (e: any, annotation: UfvAnnotation) => {
          e.cancelBubble = true;
          handleAnnotationClick(annotation);
        },
        style: {
          ...getDefaultStylesByResourceType(annotation.metadata.resourceType),
          // fill white with 0.1 opacity
          // this is required to make the annotation clickable
          fill: '#ffffff1a',
        },
      })) as Annotation<UfvAnnotationMetadata>[];

      return documentAnnotations.flat();
    },
    {
      enabled: documentContainer !== null && enabled,
    }
  );
};
