import { Accordion, Divider, Flex } from '@cognite/cogs.js-v10';
import { defaultFeatureToggleConfig } from '@infield/features/app-config/default-config';
import {
  useAppConfigQuery,
  useInFieldLocationConfig,
  useUpsertInfieldLocationConfig,
} from '@infield/features/app-config/hooks';
import type {
  ObservationFeatureToggles,
  RootLocationFeatureToggles,
} from '@infield/features/app-config/types';
import { useUpsertObservationConfig } from '@infield/features/observation/hooks/use-mutation/use-upsert-observation-config-mutation';
import { useObservationConfigByLocation } from '@infield/features/observation/hooks/use-observation-config-by-location';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import * as S from '../elements';

import { ObservationToggles } from './observation-toggles';
import { ToggleRow } from './toggle-row';

interface Props {
  hasAppConfigPermission: boolean;
  rootLocationExternalId: string;
}

export type FeatureToggle = keyof RootLocationFeatureToggles;

export const RootLocationConfigFeatureToggles: FC<Props> = ({
  hasAppConfigPermission,
  rootLocationExternalId,
}: Props) => {
  const [featureToggles, setFeatureToggles] =
    useState<RootLocationFeatureToggles>(defaultFeatureToggleConfig);
  const { mutateAsync: upsertObservationConfig } = useUpsertObservationConfig();

  const { data: appConfig, isSuccess, isLoading } = useAppConfigQuery();
  const { observationConfig, defaultObservationConfig } =
    useObservationConfigByLocation(rootLocationExternalId);
  const { mutateAsync: upsertInFieldLocationConfig } =
    useUpsertInfieldLocationConfig();

  const { data: infieldLocationConfig } = useInFieldLocationConfig(
    rootLocationExternalId
  );

  useEffect(() => {
    if (
      isSuccess &&
      infieldLocationConfig &&
      infieldLocationConfig.featureToggles
    ) {
      setFeatureToggles(infieldLocationConfig.featureToggles);
    }
  }, [isSuccess, appConfig, infieldLocationConfig]);

  const hasConfigChanged = () => {
    const originalConfigJson = JSON.stringify(
      infieldLocationConfig?.featureToggles
    );
    const currentConfigJson = JSON.stringify(featureToggles);

    return originalConfigJson !== currentConfigJson;
  };

  const handleSave = async () => {
    if (!appConfig) return;

    if (!observationConfig && featureToggles?.observations?.isEnabled) {
      await upsertObservationConfig([defaultObservationConfig]);
    }
    await upsertInFieldLocationConfig([
      {
        externalId: infieldLocationConfig?.externalId || uuid(),
        rootLocationExternalId,
        featureToggles,
      },
    ]);
  };

  const handleToggleUpdate = (
    value: boolean | Partial<ObservationFeatureToggles>,
    prop: FeatureToggle
  ) => {
    setFeatureToggles((prevState) => {
      return {
        ...prevState,
        [prop]:
          prop === 'observations'
            ? {
                ...(prevState || {})[prop],
                ...(value as Partial<ObservationFeatureToggles>),
              }
            : value,
      };
    });
  };

  return (
    <S.Wrapper direction="column" gap={32}>
      <Accordion
        expanded
        showBorder
        title="Feature"
        subtitle="Enable or disable a feature in the selected location"
      >
        <Flex direction="column" gap={8}>
          <ToggleRow
            title="Templates and checklists"
            subTitle="Create templates, assign checklists, view and analyze the collected data"
            isLoading={isLoading}
            checked={featureToggles?.templateChecklistFlow}
            hasAppConfigPermission={hasAppConfigPermission}
            onChange={(e) =>
              handleToggleUpdate(e.target.checked, 'templateChecklistFlow')
            }
          />
          <Divider />
          <ToggleRow
            title="Work orders"
            subTitle="Create checklists from work orders that come from a work management application and view and analyze collected data"
            isLoading={isLoading}
            checked={featureToggles?.workorderChecklistFlow}
            hasAppConfigPermission={hasAppConfigPermission}
            onChange={(e) =>
              handleToggleUpdate(e.target.checked, 'workorderChecklistFlow')
            }
          />
          <Divider />
          <ObservationToggles
            title="Observations"
            isLoading={isLoading}
            observationToggles={featureToggles?.observations}
            hasAppConfigPermission={hasAppConfigPermission}
            onChange={(
              observationToggles: Partial<ObservationFeatureToggles>
            ) => handleToggleUpdate(observationToggles, 'observations')}
          />
        </Flex>
      </Accordion>

      <Accordion
        expanded
        showBorder
        title="Asset explorer"
        subtitle="Enable or disable asset explorer card display on the user interface"
      >
        <Flex direction="column" gap={8}>
          <ToggleRow
            title="3D"
            isLoading={isLoading}
            checked={featureToggles?.threeD}
            hasAppConfigPermission={hasAppConfigPermission}
            onChange={(e) => handleToggleUpdate(e.target.checked, 'threeD')}
          />
          <Divider />
          <ToggleRow
            title="Trends"
            isLoading={isLoading}
            checked={featureToggles?.trends}
            hasAppConfigPermission={hasAppConfigPermission}
            onChange={(e) => handleToggleUpdate(e.target.checked, 'trends')}
          />

          <Divider />

          <ToggleRow
            title="Documents"
            isLoading={isLoading}
            checked={featureToggles?.documents}
            hasAppConfigPermission={hasAppConfigPermission}
            onChange={(e) => handleToggleUpdate(e.target.checked, 'documents')}
          />

          <Divider />

          <ToggleRow
            title="Work orders"
            isLoading={isLoading}
            checked={featureToggles?.workorders}
            hasAppConfigPermission={hasAppConfigPermission}
            onChange={(e) => handleToggleUpdate(e.target.checked, 'workorders')}
          />

          <Divider />

          <ToggleRow
            title="Notifications"
            isLoading={isLoading}
            checked={featureToggles?.notifications}
            hasAppConfigPermission={hasAppConfigPermission}
            onChange={(e) =>
              handleToggleUpdate(e.target.checked, 'notifications')
            }
          />

          <Divider />

          <ToggleRow
            title="Media"
            isLoading={isLoading}
            checked={featureToggles?.media}
            hasAppConfigPermission={hasAppConfigPermission}
            onChange={(e) => handleToggleUpdate(e.target.checked, 'media')}
          />
        </Flex>
      </Accordion>
      <S.FixedSaveButtonWrapper>
        <S.SaveButton
          onClick={handleSave}
          disabled={!hasConfigChanged()}
          loading={isLoading}
        >
          Save configuration
        </S.SaveButton>
      </S.FixedSaveButtonWrapper>
    </S.Wrapper>
  );
};
