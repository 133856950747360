import { Flex, IconWrapper, LoaderIcon } from '@cognite/cogs.js-v10';
import { useClassicByIdOrAPMAssetQuery } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { AssetInfoError } from '../../asset-info-error';
import type { AssetInfoBaseProps } from '../../types';

import { AssetInfoMobileContent } from './asset-info-mobile-content';
import * as S from './elements';

type Props = Omit<AssetInfoBaseProps, 'onClose'>;

export const AssetInfoMobile: FC<Props> = ({
  assetId,
  isLoading,
  onAssetNameClick,
}) => {
  const {
    data: asset,
    error,
    isInitialLoading: isLoadingAsset,
  } = useClassicByIdOrAPMAssetQuery(assetId);
  // no namespace because error text is reused from the associatedAssetNotFound notification
  // in case you reuse the object directly, the message won't be updated when user changes the language
  const { t } = useTranslation(LOCIZE_NAMESPACES.threeD);

  if (error) {
    return <AssetInfoError error={error} />;
  }

  if (isLoading || isLoadingAsset) {
    return (
      <Flex justifyContent="center">
        <IconWrapper size={32}>
          <LoaderIcon className="cogs-icon--rotating" />
        </IconWrapper>
      </Flex>
    );
  }

  if (!asset) {
    return (
      <Flex alignItems="center" gap={8}>
        <S.WarningIcon />
        <b>
          {t(
            'THREE_D_ASSET_INFO_ERROR_NO_ASSET_FOUND',
            'Could not find associated asset'
          )}
        </b>
      </Flex>
    );
  }

  return (
    <AssetInfoMobileContent onAssetNameClick={onAssetNameClick} asset={asset} />
  );
};
