import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import * as S from './elements';

export const ErrorFallback: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.trends);

  return (
    <S.ErrorWrapper>
      <div>
        {t('TRENDS_CHART_SOMETHING_WENT_WRONG', 'Something went wrong.')}
      </div>
    </S.ErrorWrapper>
  );
};
