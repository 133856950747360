import type { ModalProps } from '@cognite/cogs.js-v10';
import { Flex, Input, Modal } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { FC, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';

import * as S from './elements';

export type TextInputModalProps = {
  inputLabel: string;
  inputDescription?: string;
  initialValue?: string;
  onSubmit: (value: string) => unknown;
  // disable OK if initialValue and new value is same
  okDisableIfNoChange?: boolean;
  children?: ReactNode;
  okButtonTestId?: string;
  cancelButtonTestId?: string;
  textInputTestId?: string;
  maxCharacters?: number;
} & ModalProps;

export const TextInputModal: FC<TextInputModalProps> = ({
  inputLabel,
  inputDescription,
  initialValue,
  okDisableIfNoChange,
  onSubmit,
  okDisabled,
  onCancel,
  okButtonTestId,
  cancelButtonTestId,
  textInputTestId,
  maxCharacters,
  ...rest
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>(initialValue ?? '');
  const characterLimitReached = maxCharacters
    ? value.length >= maxCharacters
    : false;

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleCancel = () => {
    if (onCancel) {
      onCancel('cancelClick');
    }
    resetInputValue();
  };

  const resetInputValue = () => {
    if (initialValue) {
      setValue(initialValue);
    }
  };

  const handleSubmit = (value: string) => {
    if (value.trim().length > 0 && !okDisabled) {
      onSubmit(value.trim());
      setValue('');
    }
  };

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && inputRef.current?.value) {
        handleSubmit(value);
      }
    };
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Modal
      onOk={() => handleSubmit(value)}
      okButtonProps={{ 'data-testid': okButtonTestId }}
      okDisabled={
        value.trim().length === 0 ||
        okDisabled ||
        (okDisableIfNoChange && value === initialValue)
      }
      onCancel={handleCancel}
      cancelButtonProps={{ 'data-testid': cancelButtonTestId }}
      {...rest}
    >
      <Flex direction="column" gap={4}>
        <Input
          data-testid={textInputTestId}
          ref={inputRef}
          name={inputLabel}
          value={value}
          autoFocus
          onChange={(e) => {
            if (maxCharacters && e.target.value.length > maxCharacters) return;
            setValue(e.currentTarget.value);
          }}
          label={{
            text: inputLabel,
            required: true,
            info: undefined,
          }}
          fullWidth
        />
        <Flex>
          <S.LimitMessage
            $limitReached={characterLimitReached}
            data-testid="max-limit-message"
          >
            {t(
              'TEMPLATE_TASK_FORM_DESCRIPTION_CHARACTER_LIMIT_MESSAGE',
              'Character limit reached'
            )}
          </S.LimitMessage>
          {inputDescription && !characterLimitReached && (
            <S.InputDescription size="x-small">
              {inputDescription}
            </S.InputDescription>
          )}
          {Boolean(maxCharacters) && (
            <S.LimitCounter
              $limitReached={characterLimitReached}
            >{`${value.length}/${maxCharacters}`}</S.LimitCounter>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
