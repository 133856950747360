import type { APMAsset } from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client/src/types';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

type Props = {
  externalIds?: string[];
  spaces?: string[];
};

export const useAPMAssetsQuery = ({ externalIds, spaces }: Props) => {
  const { assetService } = useFDMServices();

  return useQuery<APMAsset[] | undefined>(
    [QueryKeys.APM_ASSET_LIST, externalIds],
    async () => {
      if (externalIds === undefined || externalIds.length === 0) {
        return;
      }
      const filters: Filters = {
        in: {
          property: 'externalId',
          in: externalIds,
        },
      };
      return assetService.listAPMAssets(filters, undefined, undefined, spaces);
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.APM_ASSET_LIST,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(externalIds && externalIds[0]),
    }
  );
};
