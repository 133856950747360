import { Button, Tabs as CogsTabs, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ConfigWrapper = styled.div`
  padding: 12px;
`;

export const Wrapper = styled(Flex)`
  padding: 16px;
  height: calc(100vh - 325px);
`;

export const SaveButton = styled(Button)`
  width: 200px;
`;

export const FixedSaveButtonWrapper = styled.div`
  position: fixed;
  bottom: 16px;
`;

export const Tabs = styled(CogsTabs)`
  .rc-tabs-nav {
    border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
  }
  .rc-tabs-content {
    height: calc(100vh - 106px);
  }
`;

export const TabPane = styled(CogsTabs.Tab)`
  padding: 8px;
  height: 100%;
`;

export const TitleContainer = styled(Flex).attrs({
  alignItems: 'center',
  gap: 16,
})`
  height: 100%;
  padding: 0px 16px;
`;
