import { CDFView } from '@cognite/apm-client';
import type { CogniteClient } from '@cognite/sdk';

import type { AppConfig } from '../types';

export class AppConfigClient {
  public SCHEMA_ID = 'APM_Config';
  public SCHEMA_VERSION = 1;

  public cogniteClient: CogniteClient;
  public cdfView: CDFView<AppConfig>;

  constructor(client: CogniteClient, schemaVersion?: number) {
    this.cogniteClient = client;

    this.cdfView = new CDFView(
      client,
      {
        externalId: this.SCHEMA_ID,
        space: this.SCHEMA_ID,
        version: String(schemaVersion || this.SCHEMA_VERSION),
      },
      this.SCHEMA_ID
    );
  }

  async list(): Promise<AppConfig[]> {
    return this.cdfView.list().then((res) => res.items);
  }

  async getLatestConfigView(): Promise<CDFView<AppConfig>> {
    const allViews = await this.cogniteClient.views.list({
      allVersions: false,
      space: this.SCHEMA_ID,
    });
    const apmConfigView = allViews.items.find(
      (view) => view.externalId === this.SCHEMA_ID
    );
    if (!apmConfigView) {
      throw new Error('APM Config view not found');
    }

    return new CDFView<AppConfig>(
      this.cogniteClient,
      {
        externalId: this.SCHEMA_ID,
        space: this.SCHEMA_ID,
        version: apmConfigView.version,
      },
      this.SCHEMA_ID
    );
  }

  async getAPM_Config(): Promise<AppConfig | undefined> {
    const latestConfigView = await this.getLatestConfigView();
    return latestConfigView.list().then((res) => res.items[0]);
  }

  async getAPM_ConfigV2(): Promise<AppConfig | undefined> {
    const latestConfigView = await this.getLatestConfigView();

    return latestConfigView
      .list({
        filters: {
          equals: {
            property: 'externalId',
            eq: 'APP_CONFIG_V2',
          },
        },
      })
      .then((res) => res.items[0]);
  }

  async upsertConfiguration(
    nextConfig: Partial<AppConfig> & { externalId: string }
  ) {
    return this.cdfView.upsert([nextConfig]);
  }
  async deleteConfiguration(externalId: string) {
    return this.cdfView.delete([{ externalId }]);
  }
}
