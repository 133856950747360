import type { ButtonProps } from '@cognite/cogs.js-v10';
import type { FC, PropsWithChildren } from 'react';

import * as S from './elements';

export const SidebarItem: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...otherProps
}) => (
  <S.StyledButton
    {...otherProps}
    type="ghost"
    themeProps={{ theme: 'base', inverted: true }}
  >
    {children}
  </S.StyledButton>
);
