import Konva from 'konva';

import getElbowConectionPathPoints from '../annotations/PolylineSerializer/getElbowedConnectionPathPoints';
import {
  FROM_ANCHOR_POINT,
  TARGET_ARROW_CONFIG,
  TO_ANCHOR_POINT,
} from '../tools/CauseMapAnchorHelper/constants';

import getRectRelativeToStage from './getRectRelativeToStage';

const configureCauseMapConnection = ({
  connection,
  fromNode,
  toNode,
}: {
  connection: Konva.Line;
  fromNode: Konva.Label;
  toNode: Konva.Label;
}): void => {
  // NOTE: Since cause maps are laid out from left to right, we define the
  // left-most node as the "from" node
  const [nextFromId, nextToId] =
    fromNode.x() < toNode.x()
      ? [fromNode.id(), toNode.id()]
      : [toNode.id(), fromNode.id()];
  connection.setAttrs({
    ...TARGET_ARROW_CONFIG,
    fromId: nextFromId,
    toId: nextToId,
    opacity: 1,
    strokeScaleEnabled: true,
    points: getElbowConectionPathPoints(
      {
        ...getRectRelativeToStage(fromNode),
        anchorPoint: FROM_ANCHOR_POINT,
      },
      {
        ...getRectRelativeToStage(toNode),
        anchorPoint: TO_ANCHOR_POINT,
      }
    ),
  });
};

export default configureCauseMapConnection;
