import { Body, Flex, Heading, Input, Switch } from '@cognite/cogs.js-v10';
import type { ObservationFeatureToggles } from '@infield/features/app-config/types';
import type { FC } from 'react';

interface Props {
  title: string;
  isLoading: boolean;
  observationToggles?: ObservationFeatureToggles;
  hasAppConfigPermission: boolean;
  onChange: (observationToggles: Partial<ObservationFeatureToggles>) => void;
}
export const ObservationToggles: FC<Props> = ({
  title,
  observationToggles,
  isLoading,
  hasAppConfigPermission,
  onChange,
}) => {
  const enableSapToggle = Boolean(
    observationToggles?.notificationsEndpointExternalId &&
      observationToggles?.attachmentsEndpointExternalId
  );

  const handleObservationTogglesConfigChange = (
    prop: keyof ObservationFeatureToggles,
    newValue: string | boolean
  ) => {
    const updatedObservationToggles = { [prop]: newValue };
    onChange(updatedObservationToggles);
  };

  const handleInputChange = (
    prop: keyof ObservationFeatureToggles,
    newValue: string
  ) => {
    if (!newValue) {
      onChange({ isWriteBackEnabled: false, [prop]: newValue });
    } else {
      handleObservationTogglesConfigChange(prop, newValue);
    }
  };

  return (
    <Flex direction="column" gap={8}>
      <Heading level={6}>{title}</Heading>
      <Body size="x-small">
        Create observations to record issues with equipment
      </Body>
      <Switch
        onChange={(e) =>
          handleObservationTogglesConfigChange('isEnabled', e.target.checked)
        }
        checked={Boolean(observationToggles?.isEnabled)}
        disabled={!hasAppConfigPermission || isLoading}
      />
      {observationToggles?.isEnabled && !isLoading && (
        <Flex direction="column" gap={16}>
          <Input
            label="Notifications endpoint externalId"
            value={observationToggles?.notificationsEndpointExternalId}
            onChange={(e) =>
              handleInputChange(
                'notificationsEndpointExternalId',
                e.target.value
              )
            }
            disabled={!hasAppConfigPermission}
          />
          <Input
            label="Attachments endpoint externalId"
            value={observationToggles?.attachmentsEndpointExternalId}
            onChange={(e) =>
              handleInputChange('attachmentsEndpointExternalId', e.target.value)
            }
            disabled={!hasAppConfigPermission}
          />
          <Switch
            onChange={(e) =>
              handleObservationTogglesConfigChange(
                'isWriteBackEnabled',
                e.target.checked
              )
            }
            label="Write back to SAP"
            checked={observationToggles?.isWriteBackEnabled}
            disabled={!hasAppConfigPermission || isLoading || !enableSapToggle}
          />
          <Body size="x-small">
            Send an observation as a Notification to SAP. Both external IDs must
            be provided.
          </Body>
        </Flex>
      )}
    </Flex>
  );
};
