import dayjs from 'dayjs';

import { PlotlyEventData } from './types';

export function getXAxisUpdateFromEventData(
  eventdata: PlotlyEventData
): string[] {
  const xaxisKeys = Object.keys(eventdata).filter((key) =>
    key.includes('xaxis')
  );
  return xaxisKeys.map((key) => dayjs(eventdata[key]).toISOString());
}
