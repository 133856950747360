import type { Activity, APMAsset } from '@cognite/apm-client';
import {
  Button,
  CloseIcon,
  DataTableIcon,
  Flex,
  Heading,
  Skeleton,
  WrenchIcon,
} from '@cognite/cogs.js-v10';
import type { Asset } from '@cognite/sdk';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';
import { useState } from 'react';

import { ActivityDetailsTabContent } from './activity-details-tab-content';
import * as S from './elements';

type Props = {
  activity?: Activity;
  asset?: Asset | APMAsset;
  isLoading: boolean;
  isError: boolean;
  onAssetClick: (assetExternalId: string) => void;
  onClose?: () => void;
};

export const ActivityDetailsContent: FC<Props> = ({
  activity,
  asset,
  isLoading,
  isError,
  onAssetClick,
  onClose,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const [selectedTab, setSelectedTab] = useState<
    'details' | 'assets' | 'operations'
  >('details');

  if (isError)
    return (
      <S.Container>
        <div>
          {t(
            'ACTIVITY_DETAILS_LOADING_ERROR',
            'Activity details was unable to load data'
          )}
        </div>
      </S.Container>
    );

  return (
    <S.Container>
      <S.Header>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading level={5}>{activity?.title}</Heading>
          {onClose && (
            <Button
              icon={<CloseIcon />}
              onClick={onClose}
              type="ghost"
              aria-label={t('ACTIVITY_DETAILS_INFO_CLOSE', 'Close')}
            />
          )}
        </Flex>
      </S.Header>

      <S.TagContainer>
        <S.Tag
          type="secondary"
          toggled={selectedTab === 'details'}
          key="Details"
          onClick={() => setSelectedTab('details')}
          icon={<DataTableIcon />}
        >
          {t('ACTIVITY_DETAILS_TAB_PANEL_LABEL_DETAILS', 'Details')}
        </S.Tag>
        <S.Tag
          type="secondary"
          toggled={selectedTab === 'operations'}
          key="Operations"
          onClick={() => setSelectedTab('operations')}
          icon={<WrenchIcon />}
        >
          {t('ACTIVITY_DETAILS_TAB_PANEL_LABEL_OPERATIONS', 'Operations')}
        </S.Tag>
      </S.TagContainer>

      {isLoading && !activity ? (
        <Skeleton.List lines={4} borders />
      ) : (
        <ActivityDetailsTabContent
          selectedTab={selectedTab}
          activity={activity}
          asset={asset}
          onAssetClick={onAssetClick}
        />
      )}
    </S.Container>
  );
};
