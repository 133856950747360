import type {
  IdmAnnotation,
  UfvAnnotation,
} from '@infield/features/file-viewer-modal/types';

import {
  getDefaultStylesByResourceType,
  RectangleAnnotationType,
} from './getDefaultStylesByResourceType';

export const convertIdmAnnotationToUfvAnnotation = (
  annotations: IdmAnnotation[],
  containerId: string
): UfvAnnotation[] => {
  return annotations
    .filter(
      (annotation) =>
        annotation.properties?.status === 'Approved' &&
        (annotation.type?.externalId === 'diagrams.AssetLink' ||
          annotation.type?.externalId === 'diagrams.FileLink')
    )
    .map((annotation) => {
      const resourceType =
        annotation.type?.externalId === 'diagrams.AssetLink' ? 'asset' : 'file';
      const { startNodeYMin, startNodeXMin, startNodeXMax, startNodeYMax } =
        annotation.properties;

      return {
        id: annotation.externalId,
        containerId,
        type: RectangleAnnotationType,

        x: Math.min(Number(startNodeXMin), Number(startNodeXMax)),
        y: Math.min(Number(startNodeYMin), Number(startNodeYMax)),
        width: Number(startNodeXMax) - Number(startNodeXMin),
        height: Number(startNodeYMax) - Number(startNodeYMin),
        style: getDefaultStylesByResourceType(resourceType),
        metadata: {
          resourceType:
            resourceType === 'file' ? ('file' as const) : ('asset' as const),
          startNode: annotation.startNode,
          endNode: annotation.endNode,
          endNodePageNumber:
            resourceType === 'file'
              ? (annotation.properties?.endNodePageNumber as number)
              : undefined,
        },
      };
    });
};
