import { EmptyState, ImageIcon, ImageIllustration } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useFetchMediaList } from '@infield/features/media/hooks/use-fetch-media-list';
import { MediaManager } from '@infield/features/media/media-manager/media-manager';
import { MediaViewerDialogue } from '@infield/features/media/media-viewer-dialogue/media-viewer-dialogue';
import type { ImageToUpload } from '@infield/features/media/types';
import { EmptyStateWrapper } from '@infield/features/ui/table-empty-state/elements';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { useState } from 'react';
import type { FC } from 'react';

import { AssetCard } from '../shared/asset-card/asset-card';

import { AssetMediaGrid } from './asset-media-grid';
import { MAX_MEDIA_ITEMS_DESKTOP, MAX_MEDIA_ITEMS_MOBILE } from './consts';
import * as S from './elements';

interface Props {
  assetExternalId: string;
  assetSpace?: string;
}

export const AssetMediaCard: FC<Props> = ({ assetExternalId, assetSpace }) => {
  const isDesktop = useIsDesktop();
  const {
    isLoading,
    data: media,
    invalidateQuery,
  } = useFetchMediaList(assetExternalId);

  const [showMediaManager, setShowMediaManager] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<ImageToUpload>();

  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);

  const maxMediaItems = isDesktop
    ? MAX_MEDIA_ITEMS_DESKTOP
    : MAX_MEDIA_ITEMS_MOBILE;

  const allMediaInstanceIds = media?.map(({ externalId, space }) => ({
    externalId,
    space,
  }));

  const assetInstanceId = { externalId: assetExternalId, space: assetSpace };

  return (
    <>
      {showMediaManager && (
        <MediaManager
          visible
          assetInstanceIds={[assetInstanceId]}
          mediaInstanceIds={allMediaInstanceIds}
          isLoading={isLoading}
          onSave={() => {
            invalidateQuery();
            setShowMediaManager(false);
          }}
          onClose={() => setShowMediaManager(false)}
        />
      )}

      {selectedMedia && (
        <MediaViewerDialogue
          visible
          media={selectedMedia}
          onClose={() => setSelectedMedia(undefined)}
        />
      )}

      <AssetCard
        icon={<ImageIcon />}
        title={t('Media', 'Media')}
        buttonText={t('open', 'Open')}
        onButtonClick={() => setShowMediaManager(true)}
      >
        {media && media.length > 0 ? (
          <S.MediaGridWrapper>
            <AssetMediaGrid
              fileInfoList={media.slice(0, maxMediaItems) || []}
              onSelectMedia={(media) => setSelectedMedia(media)}
              isLoading={isLoading}
            />
          </S.MediaGridWrapper>
        ) : (
          <EmptyStateWrapper>
            <EmptyState
              illustration={<ImageIllustration />}
              title={t(
                'ASSET_OVERVIEW_MEDIA_CARD_NO_MEDIA_TITLE',
                'No media uploaded'
              )}
              description={t(
                'ASSET_OVERVIEW_MEDIA_CARD_NO_MEDIA_BODY',
                "Upload images and videos by selecting the 'Open' button. Any images and videos uploaded on checklists will also appear here."
              )}
            />
          </EmptyStateWrapper>
        )}
      </AssetCard>
    </>
  );
};
