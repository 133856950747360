import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { Modal } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import type { ImageItemMetadata, ImageToUpload } from '../types';

import { MediaManagerDialogueFooter } from './media-manager-dialogue-footer';
import { SelectedMediaDialogueContent } from './selected-media-dialogue-content';
import type { Props as MediaDialogueContentProps } from './selected-media-dialogue-content';

interface Props {
  mediaToUpload: ImageToUpload[];
  uploadedMediaToShow: InstanceOrExternalId[];

  onClickSelectFile: () => void;
  onSelectMedia: (media: ImageToUpload) => void;
  getMediaUpdatedMetadata: (
    externalId: string
  ) => ImageItemMetadata | undefined;
  onClose: () => void;
  onSaveMedia: () => void;
  visible: boolean;
  showEmptyView: boolean;

  isSaving: boolean;
  isLoading: boolean;
  isThereAChange: boolean;
  isUploadingMedia: boolean;
  loadingText?: string;
}

export const SelectedMediaDialogue: FC<Props & MediaDialogueContentProps> = ({
  mediaToUpload,
  showEmptyView,
  getMediaUpdatedMetadata,
  uploadedMediaToShow,
  onClickSelectFile,
  onSelectMedia,
  onSaveMedia,
  visible,
  onClose,
  isSaving,
  isLoading,
  isUploadingMedia,
  isThereAChange,
  loadingText,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);

  return (
    <Modal
      visible={visible}
      size="small"
      hideFooter
      title={t('MEDIA_MODAL_TITLE', 'Upload images or videos')}
      onCancel={onClose}
    >
      <SelectedMediaDialogueContent
        mediaToUpload={mediaToUpload}
        showEmptyView={showEmptyView}
        getMediaUpdatedMetadata={getMediaUpdatedMetadata}
        uploadedMediaToShow={uploadedMediaToShow}
        onSelectMedia={onSelectMedia}
        isLoading={isLoading}
        loadingText={loadingText}
        isUploadingMedia={isUploadingMedia}
      />
      <MediaManagerDialogueFooter
        onClickSelectFile={onClickSelectFile}
        isUploadButtonDisabled={isSaving || isLoading}
        isSaveButtonDisabled={!isThereAChange || isSaving}
        isSaving={isSaving}
        onSave={onSaveMedia}
      />
    </Modal>
  );
};
