import { ActivityList } from '@infield/features/activities';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const ActivityListView: FC = () => {
  const navigate = useNavigate();

  const openChecklist = (activityExternalId: string) => {
    navigate(`/activity/${encodeURIComponent(activityExternalId)}/checklist`);
  };

  return <ActivityList onSelectActivity={openChecklist} />;
};
