import type { Configuration } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

import type { AuthStateUser, CustomerProject } from '../../types';

import Flow from './flow-class';

class AzureADFlow extends Flow {
  static user: AuthStateUser;
  public cdfScopes;
  public pca: PublicClientApplication;

  constructor(projectInfo: CustomerProject, useProductionAadApp?: boolean) {
    super();

    const {
      aadAppId,
      stagingAadAppId,
      apiBaseURL: baseUrl,
    } = projectInfo.cluster;
    const tenantId = projectInfo.aadDirectory || '';
    this.cdfScopes = [`${baseUrl}/user_impersonation`, `${baseUrl}/IDENTITY`];

    const configuration: Configuration = {
      auth: {
        clientId: (useProductionAadApp ? aadAppId : stagingAadAppId) || '',
        authority: `https://login.microsoftonline.com/${tenantId || 'common'}`,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: true,
      },
    };
    this.pca = new PublicClientApplication(configuration);
  }
  async getTokenFactory(redirect = false): Promise<() => Promise<string>> {
    if (!sessionStorage.getItem('account')) {
      try {
        if (redirect) {
          const r = await this.pca.handleRedirectPromise();
          if (r?.account) {
            sessionStorage.setItem('account', r.account?.localAccountId);
          } else {
            await this.pca.loginRedirect({
              prompt: 'select_account',
              scopes: this.cdfScopes,
            });
          }
        } else {
          const r = await this.pca.loginPopup({
            prompt: 'select_account',
            scopes: this.cdfScopes,
          });
          if (r.account?.localAccountId) {
            sessionStorage.setItem('account', r.account?.localAccountId);
          }
        }
      } catch (e) {
        return Promise.reject(e);
      }
    }

    return async () => {
      const accountId = sessionStorage.getItem('account');
      if (!accountId) throw new Error('no user_id found');

      // Get account by ID.
      const account = this.pca.getAccountByLocalId(accountId);
      if (!account) throw new Error('no user found');

      // Get token information.
      try {
        const token = await this.pca.acquireTokenSilent({
          account,
          scopes: this.cdfScopes,
        });

        return token.accessToken;
      } catch (e) {
        throw new Error('Failed to get token');
      }
    };
  }

  async getUserState(): Promise<Omit<AuthStateUser, 'cdfId'>> {
    const accountId = sessionStorage.getItem('account');
    if (!accountId) throw new Error('no user_id found');
    const account = this.pca.getAccountByLocalId(accountId);
    if (!account) throw new Error('no user found');
    return {
      id: account?.localAccountId,
      email: account?.username,
      name: account?.name || account?.username,
    };
  }
}

export default AzureADFlow;
