import type { APMAppDataServiceOptions } from '../apm-app-data-service';
import { APMAppDataService } from '../apm-app-data-service';
import type { ServiceContract } from '../types';
import { VIEW_VERSIONS } from '../utils';

import type { TemplateItemResponseType } from './types';

export class TemplateItemsService extends APMAppDataService<TemplateItemResponseType> {
  constructor(contract: ServiceContract, options: APMAppDataServiceOptions) {
    super(contract, 'TemplateItem', VIEW_VERSIONS.TEMPLATE_ITEM, options);
  }
}
