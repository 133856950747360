import { ViewerState } from '@cognite/reveal';

// Reveal is returning a richer object than the types specify, hence
// we only check the paths that we care about.
const getShamefulSimpleCameraState = (
  camera: ViewerState['camera'] | undefined
): undefined | ViewerState['camera'] => {
  return camera === undefined
    ? undefined
    : {
        position: {
          x: camera.position.x,
          y: camera.position.y,
          z: camera.position.z,
        },
        target: {
          x: camera.target.x,
          y: camera.target.y,
          z: camera.target.z,
        },
      };
};

export default getShamefulSimpleCameraState;
