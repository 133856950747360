import type { APMAppDataServiceOptions } from '../apm-app-data-service';
import { APMAppDataService } from '../apm-app-data-service';
import type { ServiceContract } from '../types';
import { VIEW_VERSIONS } from '../utils';

import type { TemplateResponseType } from './types';

export class TemplatesService extends APMAppDataService<TemplateResponseType> {
  constructor(contract: ServiceContract, options?: APMAppDataServiceOptions) {
    super(contract, 'Template', VIEW_VERSIONS.TEMPLATE, options);
  }
}
