import { Button, Flex, Heading } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const AssetTitle = styled(Heading).attrs({
  level: 6,
})`
  cursor: pointer;
  color: ${({ theme }) => theme['border--interactive--toggled-default']};
`;

export const AssetInformation = styled(Flex).attrs({
  direction: 'column',
})`
  max-width: 100%;
  min-width: 0;
  flex: 1;
`;

export const AssetActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AssetBackButton = styled(Button).attrs({
  type: 'ghost',
  iconPlacement: 'right',
})``;
