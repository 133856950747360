import React, { useCallback } from 'react';

import styled from 'styled-components';

import { Button, CommonCogsProps } from '@cognite/cogs.js-v10';

import getOptionPropertiesForToolType from '../../tools/getOptionPropertiesForToolType';
import { OptionProperty } from '../../tools/Tool';
import { ToolConfigByToolType, ToolConfig, ToolType } from '../../tools/types';

import ToolConfigRenderer from './ToolConfigRenderer';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  > * {
    margin-right: 12px;
  }
`;

const PrimaryPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: white;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SecondaryPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: white;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

type ToolbarItemConfiguration = {
  type: ToolType;
  icon: CommonCogsProps['icon'];
  properties?: OptionProperty[];
};

type ToolbarProps = {
  style?: React.CSSProperties;
  activeTool: ToolConfig;
  onToolChange: (tool: ToolConfig) => void;
  tools: ToolbarItemConfiguration[];
  toolConfigByToolType: ToolConfigByToolType;
  shouldHideSecondaryPanel?: boolean;
};

const Toolbar: React.FC<ToolbarProps> = ({
  activeTool,
  onToolChange,
  style,
  toolConfigByToolType,
  tools,
  shouldHideSecondaryPanel,
}) => {
  const [isSecondaryPanelOpen, setIsSecondaryPanelOpen] = React.useState(false);

  const onToolbarItemPress = useCallback(
    (toolType: ToolType) => {
      if (toolType !== activeTool.type) {
        onToolChange(toolConfigByToolType[toolType]);
        setIsSecondaryPanelOpen(true);
        return;
      }

      setIsSecondaryPanelOpen((prevState) => !prevState);
    },
    [activeTool, onToolChange, toolConfigByToolType]
  );

  return (
    <Container style={style}>
      <PrimaryPanel>
        {tools.map((tool) => (
          <Button
            key={tool.type}
            onClick={() => onToolbarItemPress(tool.type)}
            icon={tool.icon}
            size="small"
            type="ghost"
            aria-label={`${tool.type} tool`}
          />
        ))}
      </PrimaryPanel>

      {activeTool !== undefined &&
        isSecondaryPanelOpen &&
        !shouldHideSecondaryPanel && (
          <SecondaryPanel>
            {activeTool.type}

            {getOptionPropertiesForToolType(activeTool.type).map((option) => (
              <div>
                <div>{option.label}</div>
                <ToolConfigRenderer
                  key={`${option.key}_${option.type}`}
                  type={option.type}
                  value={
                    // TODO: Improve typing here.
                    (toolConfigByToolType[activeTool.type] as any)[option.key]
                  }
                  onChange={(value) => {
                    onToolChange({
                      ...activeTool,
                      [option.key]: value,
                    });
                  }}
                />
              </div>
            ))}
          </SecondaryPanel>
        )}
    </Container>
  );
};

export default Toolbar;
