import styled from 'styled-components';

export const TextareaWrapper = styled.div`
  max-height: 100%;
  overflow: auto;
  .cogs.cogs-textarea {
    max-height: 100%;
    width: 100%;
  }
  .cogs-textarea__textarea {
    overflow: auto !important;
  }
`;
