import { Input, Loader } from '@cognite/cogs.js-v10';
import { useState } from 'react';

import { ContinueButton, Container, LoginForm } from './elements';

export const MOST_RECENT_PROJECT_KEY = 'MOST_RECENT_PROJECT';

type LoginScreenProps = {
  onSubmit: (customerId: string) => void;
  error?: string;
  isLoading?: boolean;
  appName?: string;
};

export const LoginScreen = ({
  onSubmit,
  error,
  isLoading,
  appName,
}: LoginScreenProps) => {
  const [value, setValue] = useState(
    localStorage.getItem(MOST_RECENT_PROJECT_KEY) || ''
  );

  const handleSubmit = () => {
    localStorage.setItem(MOST_RECENT_PROJECT_KEY, value);
    onSubmit(value);
  };

  if (isLoading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <LoginForm>
        <header>
          <h2>Sign in to</h2>
          <h1>{appName}</h1>
        </header>
        {error && <div className="error">{error}</div>}
        <Input
          placeholder="Customer ID"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          fullWidth
        />
        <ContinueButton
          type="primary"
          style={{ width: '100%' }}
          onClick={handleSubmit}
        >
          Continue
        </ContinueButton>
      </LoginForm>
    </Container>
  );
};

export default LoginScreen;
