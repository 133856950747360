import type { InFieldUser } from '@cognite/apm-client';
import type { UserProfileItem } from '@cognite/sdk';

export const convertUserProfileToInFieldUser = (
  userProfiles: UserProfileItem[]
): InFieldUser[] => {
  return userProfiles.map((user) => ({
    externalId: user.userIdentifier,
    name: user.displayName || undefined,
    email: user.email || undefined,
  }));
};
