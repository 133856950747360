import {
  BREAKPOINT_MOBILE_PORTRAIT,
  BREAKPOINT_PAD_LANDSCAPE,
  BREAKPOINT_PAD_PORTRAIT,
} from '@infield/providers/theme';

const size = {
  mobile: BREAKPOINT_MOBILE_PORTRAIT,
  tablet: BREAKPOINT_PAD_PORTRAIT,
  desktop: BREAKPOINT_PAD_LANDSCAPE,
};

export const device = {
  min: {
    mobile: `(min-width: ${size.mobile}px)`,
    tablet: `(min-width: ${size.tablet}px)`,
    desktop: `(min-width: ${size.desktop}px)`,
  },
  max: {
    mobile: `(max-width: ${size.mobile}px)`,
    tablet: `(max-width: ${size.tablet}px)`,
    desktop: `(max-width: ${size.desktop}px)`,
  },
};
