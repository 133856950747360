import dayjs from 'dayjs';
import { Shape } from 'plotly.js-dist-min';

import { CogniteEvent } from '@cognite/sdk';

import { ChartEventResults, EventsCollection } from '../../../models';
import { DEFAULT_EVENT_COLOR, hexToRGBA } from '../../../utils';

import { isEventSelected } from './generateLayout';

export const getEventsLayout = (
  eventData: ChartEventResults[] | undefined,
  storedSelectedEvents: EventsCollection,
  dateFrom: string,
  dateTo: string
): {
  shapes: Partial<Plotly.Shape>[];
} => {
  const chartRange = dayjs(dateTo).diff(dayjs(dateFrom), 'millisecond');
  const dateFromMillis = new Date(dateFrom).getTime();
  const dateToMillis = new Date(dateTo).getTime();
  const PIXEL_RATIO_THRESHOLD = 0.003;

  if (eventData === undefined || eventData.length === 0) {
    return { shapes: [] };
  }

  return {
    shapes: eventData.flatMap((eventSet: ChartEventResults) => {
      const shapeColor = eventSet.color || DEFAULT_EVENT_COLOR;
      const isEventFilterValid = !!Object.keys(eventSet.filters).length;
      const { isLoading } = eventSet;

      return (eventSet.results || []).flatMap(
        (eventItem: CogniteEvent): Partial<Plotly.Shape>[] => {
          const { startTime } = eventItem;
          if (!startTime) {
            return [];
          }

          const endTime = eventItem.endTime ? eventItem.endTime : startTime;

          const startTimeMillis =
            startTime instanceof Date ? startTime.getTime() : startTime;
          const endTimeMillis =
            endTime instanceof Date ? endTime.getTime() : endTime;

          const showEvent =
            !isLoading &&
            isEventFilterValid &&
            eventSet.visible &&
            ((startTimeMillis >= dateFromMillis &&
              startTimeMillis <= dateToMillis) ||
              (endTimeMillis >= dateFromMillis &&
                endTimeMillis <= dateToMillis));

          const eventSelected = isEventSelected(
            storedSelectedEvents,
            eventItem
          );
          const eventDuration = Number(endTime) - Number(startTime);
          const screenPixelRatio = eventDuration / chartRange;

          const nonZeroDurationEventShapes: Partial<Plotly.Shape>[] = [
            {
              // Event rect left border
              visible: showEvent,
              xref: 'x',
              yref: `paper`,
              x0: startTime,
              x1: startTime,
              y0: 0,
              y1: 1,
              type: 'line',
              line: {
                width: eventSelected ? 1.5 : 0,
                color: eventSelected ? shapeColor : '#ffffff',
              },
            },
            {
              // Event rect right border
              visible: showEvent,
              xref: 'x',
              yref: `paper`,
              x0: endTime,
              x1: endTime,
              y0: 0,
              y1: 1,
              type: 'line',
              line: {
                width: eventSelected ? 1.5 : 0,
                color: eventSelected ? shapeColor : '#ffffff',
              },
            },
            {
              // Event rect
              visible: showEvent,
              xref: 'x',
              yref: `paper`,
              x0: startTime,
              x1: endTime,
              y0: 0,
              y1: 1,
              type: 'rect',
              fillcolor:
                hexToRGBA(shapeColor, eventSelected ? 0.3 : 0.1) ?? undefined,
              line: { width: 0 },
            },
            {
              // Bottom shade rect
              visible: showEvent,
              type: 'rect',
              xref: 'x',
              yref: 'paper',
              x0: startTime,
              x1: endTime,
              y0: 0,
              y1: 0.01,
              line: {
                width: 0,
              },
              fillcolor: eventSelected
                ? shapeColor
                : hexToRGBA(shapeColor, 0.7) ?? undefined,
            },
          ];
          const zeroDurationEventShapes: Partial<Shape>[] = [
            {
              // Event rect left border
              visible: showEvent,
              xref: 'x',
              yref: `paper`,
              x0: startTime,
              x1: startTime,
              y0: 0,
              y1: 1,
              type: 'line',
              line: {
                width: eventSelected ? 2.5 : 1.5,
                color: shapeColor,
              },
            },
            {
              // Event rect right border
              visible: showEvent,
              xref: 'x',
              yref: `paper`,
              x0: startTime,
              x1: startTime,
              y0: 0,
              y1: 1,
              type: 'line',
              line: {
                width: eventSelected ? 2.5 : 1.5,
                color: shapeColor,
              },
            },
          ];

          return screenPixelRatio < PIXEL_RATIO_THRESHOLD
            ? zeroDurationEventShapes
            : nonZeroDurationEventShapes;
        }
      );
    }),
  };
};
