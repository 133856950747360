import { ImageMimeType, TextMimeType } from '../../containers/types';

import { NativelySupportedMimeType } from './isNativelySupportedMimeType';

const getCanonicalMimeType = (
  mimeType: string
): NativelySupportedMimeType | string => {
  switch (mimeType) {
    case 'image/jpeg':
    case 'image/jpg':
      return ImageMimeType.JPEG;

    case 'image/tiff':
    case 'image/tif':
      return ImageMimeType.TIFF;

    case 'image/svg':
    case 'image/svg+xml':
      return ImageMimeType.SVG;

    case 'application/txt':
    case 'text/plain':
      return TextMimeType.TXT;

    default:
      return mimeType;
  }
};

export default getCanonicalMimeType;
