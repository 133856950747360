import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import { Flex } from '@cognite/cogs.js-v10';
import type { Filters } from '@cognite/fdm-client/src/types';
import { Asset as AssetExploration } from '@infield/features/asset/asset-exploration';
import { useFetchImagesByUrl } from '@infield/features/media';
import {
  useObservationCountToday,
  useObservationsInfiniteQuery,
} from '@infield/features/observation';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import * as S from './elements';
import { ObservationsDataGrid } from './observations-data-grid';
import { ObservationsOverviewToolbar } from './observations-overview-toolbar';
import { selectedObservationAtom, selectedPanelObservationView } from './state';

export const ObservationsOverview: FC = () => {
  const [filter, setFilter] = useState<Filters>();
  const [selectedObservationExternalId, setSelectedObservationExternalId] =
    useRecoilState(selectedObservationAtom);
  const navigate = useNavigate();

  const [panelView, setPanelView] = useRecoilState(
    selectedPanelObservationView
  );

  const [selectedFilterLocations, setSelectedFilterLocations] = useState<
    GridFilterOptionsType[]
  >([]);

  const {
    observations,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useObservationsInfiniteQuery(filter);

  const allMediaFiles = useMemo(
    () => observations.flatMap((observation) => observation.files || []),
    [observations]
  );

  const media = useFetchImagesByUrl(allMediaFiles);

  const {
    data: newObservationsTodayCount,
    isLoading: isNewObservationsCountLoading,
  } = useObservationCountToday();

  const selectedObservation = observations.find(
    ({ externalId }) => externalId === selectedObservationExternalId
  );

  const toggleAssetExploration = (data: string) => {
    setSelectedObservationExternalId(data);
    setPanelView('assetExploration');
  };

  const toggleObservation = (observationExternalId: string) => {
    navigate(`/observation/${encodeURIComponent(observationExternalId)}`);
  };

  const handleCreateNewObservation = () => {
    navigate('/observation');
  };

  return (
    <S.Container direction="column">
      <ObservationsOverviewToolbar
        newObservationsTodayCount={newObservationsTodayCount}
        isCountLoading={isNewObservationsCountLoading}
        onCreateNewObservation={handleCreateNewObservation}
      />
      <Flex>
        <S.DataGridWrapper>
          <ObservationsDataGrid
            data={observations}
            isLoading={isLoading}
            isError={isError}
            media={media}
            selectedFilterLocations={selectedFilterLocations}
            onClickAsset={toggleAssetExploration}
            onClickObservation={toggleObservation}
            setFilter={(filter) => setFilter(filter)}
            setSelectedLocationFilterOptions={(filterLocations) =>
              setSelectedFilterLocations(filterLocations)
            }
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        </S.DataGridWrapper>
        {panelView === 'assetExploration' && selectedObservation?.asset && (
          <S.AssetInfo>
            <AssetExploration
              assetExternalId={selectedObservation.asset.externalId}
              onClose={() => setPanelView('none')}
            />
          </S.AssetInfo>
        )}
      </Flex>
    </S.Container>
  );
};
