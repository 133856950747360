import type { APMAsset, Operation } from '@cognite/apm-client';

interface GroupedOperation {
  operation: Operation;
  assets: APMAsset[];
}

export function groupOperationByField(
  operations: Operation[],
  assets: APMAsset[],
  groupByField: keyof Operation
): GroupedOperation[] {
  const grouped = operations.reduce(
    (acc: Record<string, GroupedOperation>, operation) => {
      const groupValue =
        operation[groupByField] ?? `unique_${operation.externalId}`;
      if (!acc[groupValue]) {
        acc[groupValue] = {
          operation: {
            externalId: operation.externalId,
            id: operation.id,
            title: operation.title,
            mainResource: operation.mainResource,
            order: operation.order,
            assetExternalId: operation.assetExternalId,
          },
          assets: [],
        };
      }

      const linkedAssets = assets
        .filter((asset) => asset.externalId === operation.assetExternalId)
        .map((asset) => ({ ...asset, title: asset.title ?? '' }));
      acc[groupValue].assets = acc[groupValue].assets.concat(linkedAssets);

      return acc;
    },
    {}
  );

  return Object.values(grouped);
}
