import type { UserPreferenceLocation } from '@cognite/apm-client';
import type { AppConfig } from '@cognite/apm-config';

import type { LocationOption } from '../use-locations';

const getDisplayName = (
  externalId: string | undefined,
  appConfig: AppConfig,
  isNewConfigVersion: boolean
) => {
  return appConfig?.featureConfiguration?.rootLocationConfigurations?.find(
    (rootLocationConfig) =>
      externalId ===
      (isNewConfigVersion
        ? rootLocationConfig.externalId
        : rootLocationConfig.assetExternalId)
  )?.displayName;
};

export const getSelectableLocationOptions = (
  isIdm: boolean,
  selectableLocations: UserPreferenceLocation[] | undefined,
  appConfig: AppConfig,
  isNewConfigVersion: boolean
): LocationOption[] => {
  if (isIdm) {
    return (
      selectableLocations?.map((location) => ({
        label: location.name,
        value: location,
      })) || []
    );
  }

  return (
    selectableLocations
      ?.map((location) => ({
        label:
          getDisplayName(location.externalId, appConfig, isNewConfigVersion) ||
          location.name,
        value: location,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || []
  );
};
