import type {
  APMAsset,
  APMClient,
  InFieldUser,
  Observation,
  ObservationUpsertType,
} from '@cognite/apm-client';
import { getDirectRelationship } from '@cognite/apm-client';
import { FDMClient } from '@cognite/fdm-client';
import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import type {
  AggregatedNumberValue,
  CogniteClient,
  DirectRelationReference,
} from '@cognite/sdk';

export class ObservationService extends FDMClient {
  apmClient: APMClient;
  constructor(client: CogniteClient, apmClient: APMClient) {
    super(client);
    this.apmClient = apmClient;
  }

  async getObservations(filters?: Filters, sort?: Sort, pageSize = 200) {
    const {
      list: { items: observations },
    } = await this.apmClient.observations.list(
      `
        externalId
        status
        asset {
          externalId
          title
          space
        }
        description
        troubleshooting
        priority
        type
        createdBy {
          externalId
          name
          email
        }
        updatedBy {
          externalId
          name
          email
        }
        createdTime
        files {
          externalId
          name
          downloadLink {
            downloadUrl
          }
          metadata
        }
        space
      `,
      {
        filters,
        sort,
        pageSize,
      }
    );
    return observations;
  }

  async getObservationsPaginated(
    filters?: Filters,
    sort?: Sort,
    nextCursor?: string,
    pageSize = 50
  ) {
    const { list } = await this.apmClient.observations.list(
      `
        externalId
        asset {
          externalId
          title
          space
        }
        priority
        type
        description
        troubleshooting
        createdTime
        createdBy {
          externalId
          name
          email
        }
        updatedBy {
          externalId
          name
          email
        }
        status
        sourceId
        files {
          externalId
          downloadLink {
            downloadUrl
          }
        }
        space
      `,
      {
        filters,
        sort,
        nextCursor,
        pageSize,
      }
    );
    return list;
  }

  async createObservations(
    observation: Observation[],
    user: InFieldUser,
    rootLocation: APMAsset
  ) {
    const createdObservations: Observation[] = observation.map(
      (observation) => ({
        ...observation,
        rootLocation,
        createdBy: user,
      })
    );

    return this.upsertObservations(createdObservations);
  }

  async updateObservations(observation: Observation[], user: InFieldUser) {
    const createdObservations: Observation[] = observation.map(
      (observation) => ({
        ...observation,
        updatedBy: user,
      })
    );

    return this.upsertObservations(createdObservations);
  }

  async upsertObservations(observations: Observation[]) {
    const upsertedObservations: ObservationUpsertType[] = observations.map(
      (observation) => {
        const assetRelationship = getDirectRelationship(observation.asset);
        const rootLocationRelationship = getDirectRelationship(
          observation.rootLocation
        );
        return {
          ...observation,
          asset: assetRelationship,
          rootLocation: rootLocationRelationship,
          updatedBy: observation.updatedBy?.externalId
            ? {
                externalId: observation.updatedBy?.externalId,
                space: this.apmClient.userInstanceSpace,
              }
            : undefined,
          createdBy: observation.createdBy?.externalId
            ? {
                externalId: observation.createdBy?.externalId,
                space: this.apmClient.userInstanceSpace,
              }
            : undefined,
        };
      }
    );

    return this.apmClient.observations.upsert(upsertedObservations);
  }

  async aggregateObservationByField(field: string, filters?: Filters) {
    return this.apmClient.observationsDMS
      .aggregate({
        filters,
        aggregates: [{ count: { property: field } }],
        groupBy: [field],
      })
      .then((res) =>
        res.items.map((item) => ({
          value: String(item.group?.[field] || 'Unknown'),
          count: (
            item.aggregates.find(
              (x) => x.aggregate === 'count'
            ) as AggregatedNumberValue
          ).value,
        }))
      );
  }

  async aggregateObservationByLocation(filters?: Filters) {
    return this.apmClient.observationsDMS
      .aggregate({
        filters,
        aggregates: [{ count: { property: 'asset' } }],
        groupBy: ['asset'],
      })
      .then((res) => {
        return res.items.map((item) => ({
          field: String(
            (item.group?.asset as unknown as DirectRelationReference) // Need to convert it manually because the return type is wrong in the sdk
              .externalId || 'Unknown'
          ),
          count: (
            item.aggregates.find(
              (x) => x.aggregate === 'count'
            ) as AggregatedNumberValue
          ).value,
        }));
      });
  }

  async deleteObservations(externalIds: string[]) {
    return this.apmClient.observations.delete(externalIds);
  }
}
