import { ViewConfigPage } from '@cognite/apm-config';
import {
  Body,
  Button,
  ChevronDownIcon,
  Dropdown,
  Flex,
  Heading,
  Menu,
  SegmentedControl,
} from '@cognite/cogs.js-v10';
import { useAuthContext } from '@cognite/e2e-auth';
import { ComponentContainer } from '@infield/features/ui';
import isEqual from 'lodash/isEqual';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useAppConfigQuery } from '../hooks';
import { useUpsertAppConfigMutation } from '../hooks/use-mutation';
import { useDatamodelVersion } from '../hooks/use-query/use-datamodel-version';
import { useFdmSpace } from '../hooks/use-query/use-fdm-space';
import type { AppConfig, ViewMappings } from '../types';

import * as S from './elements';

interface Props {
  hasAppConfigPermission: boolean;
}

type DMSupportedMethod = 'viewMappings' | 'dataModels';

export const AppConfigFDM: FC<Props> = ({ hasAppConfigPermission }) => {
  const { data: fdmSpaces } = useFdmSpace();
  const { client } = useAuthContext();

  const [activeSupportedMethod, setActivesupportedMethod] =
    useState<DMSupportedMethod>('dataModels');
  const [customerSpaceId, setCustomerSpaceId] = useState<string>();
  const [customerSpaceVersion, setCustomerSpaceVersion] = useState<string>();
  const [viewMappings, setViewMappings] = useState<Partial<ViewMappings>>();
  const { data: appConfig, isSuccess } = useAppConfigQuery();
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();
  const { data: customerDataModelVersions } = useDatamodelVersion(
    customerSpaceId ?? ''
  );

  const isNewConfigValid =
    customerSpaceId !== undefined && customerSpaceVersion !== undefined;

  const hasConfigChanged =
    customerSpaceId !== appConfig?.customerDataSpaceId ||
    customerSpaceVersion !== appConfig?.customerDataSpaceVersion ||
    !isEqual(viewMappings, appConfig?.featureConfiguration?.viewMappings);

  useEffect(() => {
    if (isSuccess && appConfig !== undefined) {
      setCustomerSpaceId(appConfig.customerDataSpaceId);
      setCustomerSpaceVersion(appConfig.customerDataSpaceVersion);
      setViewMappings(appConfig.featureConfiguration?.viewMappings);
    }
  }, [isSuccess, appConfig]);

  const handleSave = async () => {
    if (!isNewConfigValid) return;

    const newFDMConfig: AppConfig = {
      name: appConfig?.name ?? 'default',
      externalId: appConfig?.externalId ?? uuid(),
      customerDataSpaceId: customerSpaceId,
      customerDataSpaceVersion: customerSpaceVersion,
      appDataSpaceId: '',
      appDataSpaceVersion: '',
      fieldConfiguration: {},
      rootLocationsConfiguration: { locations: [] },
      createdOn: new Date(),
      isActive: true,
      isDefault: true,
      viewMappings,
      featureConfiguration: {
        ...appConfig?.featureConfiguration,
        psnConfiguration: {},
        viewMappings,
        rootLocationConfigurations:
          appConfig?.featureConfiguration?.rootLocationConfigurations?.map(
            (rootLocationConfig) => {
              return {
                ...rootLocationConfig,
                appDataInstanceSpace: rootLocationConfig.appDataInstanceSpace,
                sourceDataInstanceSpace:
                  rootLocationConfig.sourceDataInstanceSpace || customerSpaceId,
              };
            }
          ) ?? [],
      },
    };
    await upsertAppConfig({
      newAppConfig: newFDMConfig,
    });

    window.location.reload();
  };

  const renderContent = () => {
    if (activeSupportedMethod === 'viewMappings') {
      return (
        <>
          <Heading level={5}>View mapping</Heading>
          <Body size="medium" style={{ marginBottom: 16 }}>
            The application requires a single view per Entity (e.g Asset,
            Activity). <br />
            Below is the mapping from entity to view.
          </Body>
          {appConfig && (
            <ViewConfigPage
              config={{
                ...appConfig,
                featureConfiguration: { viewMappings },
              }}
              onConfigChange={(nextConfig) =>
                setViewMappings(nextConfig.featureConfiguration?.viewMappings)
              }
              sdk={client}
            />
          )}
        </>
      );
    }

    return (
      <>
        <Heading level={5}>Customer space</Heading>
        <Body size="medium" style={{ marginBottom: 16 }}>
          Data models where the customer ingest data, eg: Assets and Activities.
          <br /> Data model must adhere to certain requirements.
          <ul>
            <li>The space and data model must be named the same</li>
            <li>
              The data model must have views that are named in the format
              APM_Entity (e.g. APM_Activity)
            </li>
          </ul>
        </Body>
        <Flex gap={16}>
          <Flex direction="column" gap={8}>
            <Body size="x-small" strong>
              Space and data model name
            </Body>

            <Dropdown
              disabled={
                !hasAppConfigPermission ||
                (fdmSpaces && fdmSpaces?.length === 0)
              }
              hideOnSelect={{
                hideOnContentClick: false,
                hideOnOutsideClick: true,
              }}
              appendTo={document.body}
              content={
                fdmSpaces && (
                  <Menu>
                    <S.DropdownContainer>
                      {fdmSpaces?.map((space) => {
                        return (
                          <Menu.Item
                            key={space.space}
                            onClick={() => {
                              if (space.space !== customerSpaceId) {
                                setCustomerSpaceId(space.space);
                                setCustomerSpaceVersion('1');
                              }
                            }}
                          >
                            {space.space}
                          </Menu.Item>
                        );
                      })}
                    </S.DropdownContainer>
                  </Menu>
                )
              }
            >
              <Button
                disabled={!hasAppConfigPermission}
                icon={<ChevronDownIcon />}
                iconPlacement="right"
                aria-label="expand-customer-space-id-dropdown"
              >
                {customerSpaceId}
              </Button>
            </Dropdown>
          </Flex>
          <Flex direction="column" gap={8}>
            <Body size="x-small" strong>
              Data model version
            </Body>
            <Dropdown
              disabled={
                !hasAppConfigPermission ||
                (customerDataModelVersions &&
                  customerDataModelVersions?.length === 0)
              }
              hideOnSelect={{
                hideOnContentClick: false,
                hideOnOutsideClick: true,
              }}
              appendTo={document.body}
              content={
                customerDataModelVersions && (
                  <S.DropdownContainer>
                    <Menu>
                      {customerDataModelVersions?.map((version) => {
                        return (
                          <Menu.Item
                            key={version}
                            onClick={() => setCustomerSpaceVersion(version)}
                          >
                            {version}
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  </S.DropdownContainer>
                )
              }
            >
              <Button
                disabled={!hasAppConfigPermission}
                icon={<ChevronDownIcon />}
                iconPlacement="right"
                aria-label="expand-customer-space-version-dropdown"
              >
                {customerSpaceVersion}
              </Button>
            </Dropdown>
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <ComponentContainer>
      <S.Wrapper>
        <Flex direction="column" gap={16}>
          <Heading level={5}>CDF Data modelling settings</Heading>
          <Body size="medium">
            We query CDF data modelling to filter and sort on various entities
            (e.g. Assets, Activities).
            <br /> The application supports two methods of CDF data modelling
            usage.
          </Body>
          <SegmentedControl currentKey={activeSupportedMethod}>
            <SegmentedControl.Button
              key="dataModels"
              onClick={() => setActivesupportedMethod('dataModels')}
            >
              Data models (legacy)
            </SegmentedControl.Button>
            <SegmentedControl.Button
              key="viewMappings"
              onClick={() => setActivesupportedMethod('viewMappings')}
            >
              View mappings
            </SegmentedControl.Button>
          </SegmentedControl>
          <Flex direction="column" gap={8}>
            {renderContent()}
          </Flex>
          <Flex direction="column" gap={4}>
            <S.SaveButton
              onClick={handleSave}
              disabled={!isNewConfigValid || !hasConfigChanged}
            >
              Save FDM configuration
            </S.SaveButton>
            <Body size="x-small">
              Saving FDM configuration will reload the app
            </Body>
          </Flex>
        </Flex>
      </S.Wrapper>
    </ComponentContainer>
  );
};
