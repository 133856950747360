import type { FdmFile } from '@cognite/apm-client';
import type { FDMFileWithDataUrl } from '@infield/pages/observation';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQueries } from '@tanstack/react-query';

export const useFetchImagesByUrl = (fdmFileList?: (FdmFile | null)[]) => {
  const { mediaService } = useFDMServices();

  const result = useQueries({
    queries: (fdmFileList || [])
      .filter((fdmFile) => fdmFile?.downloadLink?.downloadUrl)
      .map((fdmFile) => ({
        queryKey: [QueryKeys.MEDIA_BLOB, fdmFile?.externalId],
        queryFn: async () => {
          const dataUrl = await mediaService.getMediaByUrl(
            fdmFile!.downloadLink!.downloadUrl!
          );
          return { ...fdmFile, dataUrl };
        },
        staleTime: Infinity,
      })),
  });

  return result
    .filter(({ data }) => Boolean(data))
    .map(({ data }) => data) as FDMFileWithDataUrl[];
};
