import type { DataGridApi } from './types';

const getParentId = (
  api: DataGridApi,
  rowId: number | string
): number | string | undefined => {
  const currentNode = api.getRowNode(rowId);
  return currentNode?.parent || undefined;
};

const getChildrenIds = (
  api: DataGridApi,
  rowId: number | string
): (number | string)[] => {
  return api.getRowGroupChildren({
    groupId: rowId,
  });
};

const getHierarchyArray = (
  api: DataGridApi,
  rowId: number | string
): string[] => {
  return api.getRow(rowId)?.hierarchy || [];
};

const selectNewRow = (
  api: DataGridApi,
  nextSelectedRows: (number | string)[],
  previousRowsState: (number | string)[]
): (number | string)[] => {
  const selectedRow = nextSelectedRows.slice(-1)[0];
  const hierarchyOfSelectedRow = getHierarchyArray(api, selectedRow);
  const isWorkorderSelected = hierarchyOfSelectedRow.length === 1;

  if (isWorkorderSelected) {
    const relevantOperationsIds = getChildrenIds(api, selectedRow);
    return [selectedRow, ...relevantOperationsIds];
  }

  // that means that selected row is an operation
  const relevantWorkorder = getParentId(api, selectedRow);
  const newParent = hierarchyOfSelectedRow[0];
  const previousParent = getHierarchyArray(api, previousRowsState[0])[0];
  if (newParent === previousParent) {
    return nextSelectedRows;
  }
  return relevantWorkorder ? [relevantWorkorder, selectedRow] : [selectedRow];
};

const deselectNewRow = (
  api: DataGridApi,
  nextSelectedRows: (number | string)[],
  previousRowsState: (number | string)[]
): (number | string)[] => {
  const deselectedRow = previousRowsState.filter(
    (rowId) => !nextSelectedRows.includes(rowId)
  )[0];
  const hierarchyOfDeselectedRow = getHierarchyArray(api, deselectedRow);
  const isWorkorderDeselected = hierarchyOfDeselectedRow.length === 1;

  if (isWorkorderDeselected) {
    return [];
  }

  // that means that deselected row is an operation
  if (nextSelectedRows.length === 1) {
    return [];
  }
  return nextSelectedRows;
};

export const adjustNextSelectedRows = (
  api: DataGridApi,
  nextSelectedRows: (number | string)[],
  previousRowsState: (number | string)[]
): (number | string)[] => {
  const isNewRowSelected = nextSelectedRows.length > previousRowsState.length;

  if (isNewRowSelected) {
    return selectNewRow(api, nextSelectedRows, previousRowsState);
  }

  return deselectNewRow(api, nextSelectedRows, previousRowsState);
};
