import type {
  CogniteExternalId,
  CogniteInternalId,
  IdEither,
} from '@cognite/sdk';

export const queryKeys = {
  all: ['ai'] as const,

  answerQuestion: (
    question: string,
    text: string | string[] | undefined,
    limit: number
  ) => [...queryKeys.all, 'answer-question', question, text, limit] as const,

  answerQuestionMultipleFiles: (
    question: string,
    fileIds: number[],
    limit: number
  ) =>
    [
      ...queryKeys.all,
      'answer-question-multiple-files',
      question,
      fileIds,
      limit,
    ] as const,

  semanticSearch: (question: string, fileIds: number[]) =>
    [...queryKeys.all, 'semantic-search', question, fileIds] as const,

  textSummary: (text: string | string[] | undefined, limit: number) =>
    [...queryKeys.all, 'text-summary', text, limit] as const,

  fileById: (id?: IdEither | CogniteInternalId | CogniteExternalId) =>
    [...queryKeys.all, 'file-by-id', id] as const,

  documentStatus: (documentIds: number[]) =>
    [...queryKeys.all, 'document-status', documentIds] as const,

  documentEmbeddedText: (id?: number, link?: string) =>
    [...queryKeys.all, 'document-embedded-text', id, link] as const,

  servicesAvailability: ['services-availability'] as const,
};
