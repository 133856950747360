import {
  Body,
  DeleteIcon,
  Flex,
  Radio,
  RadioGroup,
  Select,
} from '@cognite/cogs.js-v10';
import {
  TaskStatusOptions,
  useTaskStatusTranslation,
} from '@infield/features/conditions';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { TaskTextarea } from '@infield/features/task';
import { TaskFormBlockWrapper } from '@infield/features/task/task-form/elements';
import type { FC } from 'react';

import { actionTypes } from './constants';
import * as S from './elements';

interface Props {
  conditionExternalId: string;
  taskStatus: TaskStatusOptions;
  conditionAction: (typeof actionTypes)[0];
  conditionMessage: string;
  conditionMessageRef: React.RefObject<HTMLTextAreaElement>;
  isDeleteConditionLoading: boolean;
  handleTaskStatusChange: (status: TaskStatusOptions) => void;
  handleConditionActionChange: (action: (typeof actionTypes)[0]) => void;
  setConditionMessage: (conditionMessage: string) => void;
  setIsConditionMessageFocused: (isFocused: boolean) => void;
  handleMessageOnBlur: () => void;
  handleDeleteCondition: () => void;
}

export const ConditionConfiguration: FC<Props> = ({
  conditionExternalId,
  taskStatus,
  conditionAction,
  conditionMessage,
  conditionMessageRef,
  isDeleteConditionLoading,
  handleTaskStatusChange,
  handleConditionActionChange,
  setConditionMessage,
  setIsConditionMessageFocused,
  handleMessageOnBlur,
  handleDeleteCondition,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.conditions);
  const translatedStatuses = useTaskStatusTranslation();

  return (
    <Flex direction="column" gap={12}>
      <TaskFormBlockWrapper>
        <Flex
          gap={10}
          direction="column"
          data-testid="condition-configuration-item"
        >
          {/* Task status */}
          <Flex gap={6} direction="column">
            <Body strong size="medium">
              {t(
                'TEMPLATE_TASK_FORM_CONDITION_TASK_STATUS_DESCRIPTION',
                'If the task status is'
              )}
            </Body>
            <S.TaskStatusWrapper>
              <RadioGroup
                defaultValue={TaskStatusOptions.NotOk}
                name={conditionExternalId}
                value={taskStatus}
              >
                <Flex direction="row">
                  <Flex gap={4} direction="column">
                    <S.TemporaryRadioButtonWrapper
                      onClick={() =>
                        handleTaskStatusChange(TaskStatusOptions.Ok)
                      }
                    >
                      <Radio
                        name={TaskStatusOptions.Ok + conditionExternalId}
                        value={TaskStatusOptions.Ok}
                        label={translatedStatuses.Ok}
                      />
                    </S.TemporaryRadioButtonWrapper>
                    <S.TemporaryRadioButtonWrapper
                      onClick={() =>
                        handleTaskStatusChange(TaskStatusOptions.NotOk)
                      }
                    >
                      <Radio
                        name={TaskStatusOptions.NotOk + conditionExternalId}
                        value={TaskStatusOptions.NotOk}
                        label={translatedStatuses['Not ok']}
                      />
                    </S.TemporaryRadioButtonWrapper>
                  </Flex>
                  <S.StatusRadioGroup gap={4} direction="column">
                    <S.TemporaryRadioButtonWrapper
                      onClick={() =>
                        handleTaskStatusChange(TaskStatusOptions.NotApplicable)
                      }
                    >
                      <Radio
                        name={
                          TaskStatusOptions.NotApplicable + conditionExternalId
                        }
                        value={TaskStatusOptions.NotApplicable}
                        label={translatedStatuses['Not applicable']}
                      />
                    </S.TemporaryRadioButtonWrapper>
                    <S.TemporaryRadioButtonWrapper
                      onClick={() =>
                        handleTaskStatusChange(TaskStatusOptions.Blocked)
                      }
                    >
                      <Radio
                        name={TaskStatusOptions.Blocked + conditionExternalId}
                        value={TaskStatusOptions.Blocked}
                        label={translatedStatuses.Blocked}
                      />
                    </S.TemporaryRadioButtonWrapper>
                  </S.StatusRadioGroup>
                </Flex>
              </RadioGroup>
            </S.TaskStatusWrapper>
          </Flex>

          <Flex gap={6} direction="column">
            {/* Action */}
            <Body strong size="medium">
              {t(
                'TEMPLATE_TASK_FORM_CONDITION_ACTION_DESCRIPTION',
                'Set action to'
              )}
            </Body>
            <Select
              disabled // Disabled for now until we implement more options
              value={conditionAction}
              options={actionTypes}
              onChange={handleConditionActionChange}
            />

            {/* Message */}
            <TaskFormBlockWrapper>
              <Flex direction="column" gap={6}>
                <Body size="medium" strong>
                  {t(
                    'TEMPLATE_TASK_FORM_CONDITION_MESSAGE_DESCRIPTION',
                    'Enter a question or message'
                  )}
                </Body>
                <TaskTextarea
                  ref={conditionMessageRef}
                  value={conditionMessage}
                  onChange={setConditionMessage}
                  onBlur={handleMessageOnBlur}
                  onFocus={() => setIsConditionMessageFocused(true)}
                  placeholder={t(
                    'TEMPLATE_TASK_FORM_CONDITION_MESSAGE_PLACEHOLDER',
                    "e.g. Provide the car's plate number"
                  )}
                />
              </Flex>
            </TaskFormBlockWrapper>
          </Flex>

          <S.DeleteButton
            icon={<DeleteIcon />}
            type="tertiary"
            onClick={handleDeleteCondition}
            loading={isDeleteConditionLoading}
          >
            {t('TEMPLATE_TASK_FORM_CONDITION_REMOVE_BUTTON', 'Remove')}
          </S.DeleteButton>
        </Flex>
      </TaskFormBlockWrapper>
    </Flex>
  );
};
