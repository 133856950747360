import { IRect } from 'konva/lib/types';
import { memoize } from 'lodash-es';

import getEuclideanDistance from '../../utils/getEuclideanDistance';
import { PriorityQueue } from '../../utils/PriorityQueue';
import { Position } from '../types';

import { Grid, GridNode } from './Grid';
import { Path, simplifyRightAnglesPath } from './utils';

const cacheKeyResolver = ({
  startPoint,
  endPoint,
  obstacles,
  obstaclePadding,
}: {
  startPoint: Position;
  endPoint: Position;
  obstacles: IRect[];
  obstaclePadding: number;
}) => JSON.stringify({ startPoint, endPoint, obstacles, obstaclePadding });

// A* algorithm to find the shortest path between the start and end points, avoiding obstacles.
// A padding can be added to avoid the path being too close to the obstacles.
const findShortestMiddlePath = memoize(
  ({
    startPoint,
    endPoint,
    obstacles,
    obstaclePadding,
  }: {
    startPoint: Position;
    endPoint: Position;
    obstacles: IRect[];
    obstaclePadding: number;
  }): Position[] | undefined => {
    const grid = Grid.create(startPoint, endPoint, obstacles, obstaclePadding);

    const openSet = new PriorityQueue<GridNode>((a, b) => a.f - b.f);
    const closedSet = new Set<GridNode>();

    openSet.enqueue(grid.startNode);

    while (!openSet.isEmpty()) {
      const currentNode = openSet.dequeue();
      if (currentNode === undefined) {
        // eslint-disable-next-line no-console
        console.warn(
          'Could not find current node for connection path. This should not happen.'
        );
        return undefined;
      }

      if (currentNode === grid.endNode) {
        const path: Position[] = [];
        let node: GridNode | null = currentNode;
        while (node !== null) {
          path.push({ x: node.x, y: node.y });
          node = node.parent;
        }
        return path.reverse();
      }

      closedSet.add(currentNode);

      for (const { neighbor, cost } of grid.getNeighborsWithCost(currentNode)) {
        if (closedSet.has(neighbor)) {
          continue;
        }

        const newG = currentNode.g + cost;
        const newH = getEuclideanDistance(neighbor, grid.endNode);
        const newF = newG + newH;

        if (openSet.includes(neighbor)) {
          if (newF < neighbor.f) {
            neighbor.f = newF;
            neighbor.g = newG;
            neighbor.h = newH;
            neighbor.parent = currentNode;
          }
        } else {
          neighbor.f = newF;
          neighbor.g = newG;
          neighbor.h = newH;
          neighbor.parent = currentNode;
          openSet.enqueue(neighbor);
        }
      }
    }

    // If no path is found, return an empty array
    // eslint-disable-next-line no-console
    console.warn(
      'Could not find path between start and end connection point. This should not happen.'
    );
    return undefined;
  },
  cacheKeyResolver
);

const getPathToContainerSide = (
  containerRect: IRect,
  annotationRect: IRect,
  obstaclePadding: number
): { annotationPoint: Position; containerPoint: Position } => {
  const distance = {
    top: annotationRect.y - containerRect.y,
    left: annotationRect.x - containerRect.x,
    right:
      containerRect.x +
      containerRect.width -
      (annotationRect.x + annotationRect.width),
    bottom:
      containerRect.y +
      containerRect.height -
      (annotationRect.y + annotationRect.height),
  };

  const minDistance = Math.min(...Object.values(distance));
  if (distance.left === minDistance) {
    return {
      annotationPoint: {
        x: annotationRect.x,
        y: annotationRect.y + annotationRect.height / 2,
      },
      containerPoint: {
        x: containerRect.x - obstaclePadding,
        y: annotationRect.y + annotationRect.height / 2,
      },
    };
  }

  if (distance.right === minDistance) {
    return {
      annotationPoint: {
        x: annotationRect.x + annotationRect.width,
        y: annotationRect.y + annotationRect.height / 2,
      },
      containerPoint: {
        x: containerRect.x + containerRect.width + obstaclePadding,
        y: annotationRect.y + annotationRect.height / 2,
      },
    };
  }

  if (distance.bottom === minDistance) {
    return {
      annotationPoint: {
        x: annotationRect.x + annotationRect.width / 2,
        y: annotationRect.y + annotationRect.height,
      },
      containerPoint: {
        x: annotationRect.x + annotationRect.width / 2,
        y: containerRect.y + containerRect.height + obstaclePadding,
      },
    };
  }

  return {
    annotationPoint: {
      x: annotationRect.x + annotationRect.width / 2,
      y: annotationRect.y,
    },
    containerPoint: {
      x: annotationRect.x + annotationRect.width / 2,
      y: containerRect.y - obstaclePadding,
    },
  };
};

const joinPath = ({
  startPath,
  middlePath = [],
  endPath,
}: {
  startPath: Path;
  middlePath?: Path;
  endPath: Path;
}) => startPath.concat(middlePath, endPath);

const getConnectionPath = ({
  fromContainerRect,
  toContainerRect,
  fromAnnotationRect,
  toAnnotationRect,
  obstacles,
  obstaclePadding,
}: {
  fromContainerRect: IRect;
  toContainerRect: IRect;
  fromAnnotationRect: IRect;
  toAnnotationRect: IRect;
  obstacles: IRect[];
  obstaclePadding: number;
}): Path => {
  const startPoints = getPathToContainerSide(
    fromContainerRect,
    fromAnnotationRect,
    obstaclePadding
  );

  const endPoints = getPathToContainerSide(
    toContainerRect,
    toAnnotationRect,
    obstaclePadding
  );

  const middlePath = findShortestMiddlePath({
    startPoint: startPoints.containerPoint,
    endPoint: endPoints.containerPoint,
    obstacles,
    obstaclePadding,
  });

  if (middlePath === undefined) {
    return [startPoints.annotationPoint, endPoints.annotationPoint];
  }

  const startPath = [startPoints.annotationPoint, startPoints.containerPoint];
  const endPath = [endPoints.containerPoint, endPoints.annotationPoint];
  return joinPath({ startPath, middlePath, endPath });
};

type GetRightAnglesConnectionPathPoints = {
  fromContainerRect: IRect;
  toContainerRect: IRect;
  fromAnnotationRect: IRect;
  toAnnotationRect: IRect;
  obstacles: IRect[];
  obstaclePadding: number;
};

const getRightAnglesConnectionPathPoints = ({
  fromContainerRect,
  toContainerRect,
  fromAnnotationRect,
  toAnnotationRect,
  obstacles,
  obstaclePadding,
}: GetRightAnglesConnectionPathPoints): number[] => {
  const path = getConnectionPath({
    fromContainerRect,
    toContainerRect,
    fromAnnotationRect,
    toAnnotationRect,
    obstacles,
    obstaclePadding,
  });

  const simplifiedPath = simplifyRightAnglesPath(path);

  return simplifiedPath.flatMap((point) => [point.x, point.y]);
};

export default getRightAnglesConnectionPathPoints;
