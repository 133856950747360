import type { FileInfo } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

export const useDocumentByExternalId = ({
  externalId,
  instanceId,
}: {
  externalId?: string;
  instanceId?: { externalId: string; space: string };
}) => {
  const { mediaService } = useFDMServices();

  return useQuery<FileInfo>(
    [QueryKeys.DOCUMENTS, externalId, instanceId],
    async () => {
      if (instanceId) {
        return mediaService.getFileByInstanceId(
          instanceId.externalId,
          instanceId.space
        );
      }
      return mediaService.getFileByExternalId(externalId!);
    },
    {
      enabled: Boolean(externalId || (instanceId && instanceId.externalId)),
    }
  );
};
