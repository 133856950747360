import {
  Body,
  Divider as CogsDivider,
  Flex,
  Heading,
  Menu,
} from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const ACTION_MENU_HEIGHT = 60;
export const ACTION_MENU_MARGIN = 16;

export const Wrapper = styled.div`
  position: absolute;
  bottom: ${ACTION_MENU_MARGIN}px;
  padding: 0 16px;
  height: 60px;
  width: 100%;
  white-space: nowrap;

  animation: moveUp 300ms;

  @keyframes moveUp {
    from {
      transform: translateY(80px);
    }
  }
`;

export const Container = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
})`
  position: relative;
  width: 100%;
  padding: 16px;
  height: ${ACTION_MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme['surface--muted--inverted']};
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.04), 0 3px 8px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
`;

export const Title = styled(Body).attrs({
  strong: true,
  size: 'x-small',
})`
  color: ${({ theme }) => theme['text-icon--strong--inverted']};
`;

export const SubTitle = styled(Heading).attrs({
  level: 6,
})`
  color: ${({ theme }) => theme['text-icon--strong--inverted']};
`;

export const Divider = styled(CogsDivider)`
  height: 20px !important;
`;

export const SubActionMenu = styled(Menu)`
  position: absolute;
  right: 50px;
  bottom: 65px;
  // Remove this when the menu allows for iconPlacement left
  .cogs-menu-item__container {
    flex-direction: row-reverse;
  }
`;
