export const DEFAULT_FILENAME = 'Workspace.pdf';
export const CANVAS_BOUNDARY_RELATIVE_MARGIN = 0.03;
export const MAX_WIDTH = 15_000;
export const MAX_HEIGHT = 15_000;
export const PDF_SCALE = 1.0;

// Poll every 0.1s for 3s (i.e., 30 times in total)
export const BACKGROUND_IMAGE_POLLING_TIME_MS = 100;
export const BACKGROUND_IMAGE_POLLING_MAX_ATTEMPTS = 30;

export const DEFAULT_FOOT_NOTE_STYLE = {
  fontSizePercent: 0.01,
  fontFamily: 'Arial',
  fill: '#000000',
  lineHeight: 1.1,
};

export const DEFAULT_PAGE_OPTIONS = {
  shouldIncludeContainerAnnotations: true,
  shouldIncludeCanvasAnnotations: true,
  shouldCropCanvasAnnotationsToFitInContainer: true,
  footNotes: [],
};
