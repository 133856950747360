import type { AxisUpdate } from '@cognite/charts-lib';
import { ChartsContextType } from '@cognite/charts-lib';
import { CogniteClient } from '@cognite/sdk';

import { UnifiedViewer } from '../../UnifiedViewer';
import UnifiedViewerEventType from '../../UnifiedViewerEventType';
import { ContainerType } from '../ContainerType';
import ReactContainer, {
  ReactContainerRenderContentProps,
} from '../ReactContainer';
import ErrorContent from '../TimeseriesContainer/ErrorContent';
import modifySvgPlotClipPathToInternalUrlReference from '../TimeseriesContainer/modifySvgPlotClipPathToInternalUrlReference';

import ChartsContent from './ChartsContent';
import getSourcePropsByIdFromSourceCollection from './getSourcePropsByIdFromSourceCollection';
import modifySvgOverplotClipPathToInternalUrlReference from './modifySvgOverplotClipPathToInternalUrlReference';
import type { ChartsContainerProps, ChartSourcePropsById } from './types';

const CONTENT_SCALE = 1.5;

export type ShamefulChartsContext = Omit<
  ChartsContextType,
  'chartsStorageService'
>;

export default class ChartsContainer<MetadataType> extends ReactContainer<
  MetadataType,
  ChartsContainerProps<MetadataType>
> {
  public readonly type = ContainerType.CHARTS;
  protected override readonly shouldRefreshScreenshotWhileInActiveMode: boolean =
    false;
  private cogniteClient: CogniteClient;

  private shamefulChartsContext: ShamefulChartsContext;

  // For a period of time, the rendered dates might be different from the serialized date,
  // hence they are kept here until we have notified the application layer.
  private dirtyStartDate: Date | undefined;
  private dirtyEndDate: Date | undefined;
  private dirtyOverriddenSourcePropsById: ChartSourcePropsById | undefined;

  public constructor(
    props: ChartsContainerProps<MetadataType>,
    unifiedViewer: UnifiedViewer
  ) {
    super(props, unifiedViewer);

    const client = this.unifiedViewer.getCogniteClient();
    const chartsContext = this.unifiedViewer.getShamefulChartsContext();

    if (client === undefined) {
      throw new Error(
        'CogniteClient is not defined - please provide a CogniteClient instance to the UnifiedViewer to use ChartsContainer'
      );
    }

    if (chartsContext === undefined) {
      throw new Error(
        'ChartsContext is not defined - please provide ChartsContext functions to the UnifiedViewer to use ChartsContainer'
      );
    }

    // Attaching this to the parentNode to *avoid* double registering mouse events
    this.unifiedViewer.host.parentNode!.appendChild(this.domNode);

    this.cogniteClient = client;
    this.shamefulChartsContext = chartsContext;
  }

  protected getPropsRequiringUpdate(): Array<
    keyof Pick<
      ChartsContainerProps,
      'instance' | 'startDate' | 'endDate' | 'overriddenSourcePropsById'
    >
  > {
    return ['instance', 'startDate', 'endDate', 'overriddenSourcePropsById'];
  }

  // For Charts containers, we can omit cloning the styles of the Plotly.js plot
  // itself, as it seems that the styles are not necessary when rendering the
  // screenshots. This provides a relatively noticeable performance boost when
  // screenshotting charts containers (from ~100ms to ~30ms screenshot time).
  protected override shouldForceSkipStyleCloning = (
    element: HTMLElement
  ): boolean => {
    if (element.className === 'js-plotly-plot') {
      return false;
    }
    // Check if the element or any of its ancestors has the class 'js-plotly-plot'
    const plotlyPlot = element.closest('.js-plotly-plot');
    return plotlyPlot !== null;
  };

  protected override onClone = (clonedDomNode: HTMLElement): void => {
    const plotlyPlot = clonedDomNode.querySelector('.js-plotly-plot');
    if (plotlyPlot === null) {
      return;
    }
    const svg = plotlyPlot.querySelector('svg');
    if (svg === null) {
      return;
    }

    // HACK: Per https://github.com/niklasvh/html2canvas/issues/2016,
    // html2canvas does not have proper clip-path support for SVGs.
    // However, for some reason, it seems to work fine if the clip-path
    // reference is internal (e.g. "clip-path: url(#clipPathid)").  For
    // our time series component, this means in practice that the lines in
    // the plots are not clipped correctly in the rendered screenshots.
    // As such, below we modify the generated SVGs of Plotly.js plots so
    // that the URL of the clip-path always is specified as an internal
    // reference.
    modifySvgPlotClipPathToInternalUrlReference(svg);
    modifySvgOverplotClipPathToInternalUrlReference(svg);

    // Similar to above, for some reason, some of the font properties of
    // the text elements in the Plotly.js SVGs aren't rendered properly in
    // html2canvas. The fix(hack) here is to use the style of the SVG the
    // text elements belong to.
    const textElements = plotlyPlot.querySelectorAll('text');
    const svgStyle = window.getComputedStyle(svg, null);
    const fontWeight = svgStyle.getPropertyValue('font-weight');
    const fontFamily = svgStyle.getPropertyValue('font-family');
    textElements.forEach((text) => {
      text.style.fontWeight = fontWeight;
      text.style.fontFamily = fontFamily;
    });
  };

  public renderContent = ({
    width,
    height,
    unscaledWidth,
    unscaledHeight,
    setLoadingStatus,
  }: ReactContainerRenderContentProps): JSX.Element => {
    if (this.loadingStatus.isError) {
      return <ErrorContent width={width} height={height} />;
    }

    return (
      <ChartsContent
        sdk={this.cogniteClient}
        chartsContext={this.shamefulChartsContext}
        instance={this.props.instance}
        width={width}
        height={height}
        unscaledWidth={CONTENT_SCALE * unscaledWidth}
        unscaledHeight={CONTENT_SCALE * unscaledHeight}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        setLoadingStatus={setLoadingStatus}
        onRangeChange={this.onRangeChange}
        onYAxisRangeChange={this.onYAxisRangeChange}
        overriddenSourcePropsById={this.props.overriddenSourcePropsById}
      />
    );
  };

  public serialize = (): ChartsContainerProps => {
    return {
      ...this.serializeBaseContainerProps(),
      type: this.type,
      instance: this.props.instance,
      startDate: new Date(
        this.dirtyStartDate || this.props.startDate
      ).toISOString(),
      endDate: new Date(this.dirtyEndDate || this.props.endDate).toISOString(),
      overriddenSourcePropsById:
        this.dirtyOverriddenSourcePropsById ??
        this.props.overriddenSourcePropsById,
    };
  };

  private onRangeChange = (startDate: Date, endDate: Date): void => {
    this.setDirtyState({ startDate, endDate });
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      containers: [this.serialize()],
      annotations: [],
    });
    this.clearDirtyState();
  };

  private onYAxisRangeChange = (
    updates: AxisUpdate[],
    idsToRemove: string[] = []
  ) => {
    const updatedSourcePropsById = {
      ...this.props.overriddenSourcePropsById,
      ...getSourcePropsByIdFromSourceCollection(updates),
    };
    const filteredSourcePropsById = Object.fromEntries(
      Object.entries(updatedSourcePropsById).filter(
        ([id]) => !idsToRemove.includes(id)
      )
    );

    this.setDirtyState({
      overriddenSourcePropsById: filteredSourcePropsById,
    });
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      containers: [this.serialize()],
      annotations: [],
    });
    this.clearDirtyState();
  };

  private setDirtyState = ({
    startDate,
    endDate,
    overriddenSourcePropsById,
  }: {
    startDate?: Date;
    endDate?: Date;
    overriddenSourcePropsById?: ChartSourcePropsById;
  }) => {
    this.dirtyStartDate = startDate;
    this.dirtyEndDate = endDate;
    this.dirtyOverriddenSourcePropsById = overriddenSourcePropsById;
  };

  private clearDirtyState = () => {
    this.dirtyStartDate = undefined;
    this.dirtyEndDate = undefined;
    this.dirtyOverriddenSourcePropsById = undefined;
  };
}
