const DEFAULT_MAX_WORD_LENGTH = 20;

const forceBreakWord = (word: string, maxWordLength: number): string[] => {
  if (word.length < maxWordLength) {
    return [word];
  }

  return [
    word.slice(0, maxWordLength),
    ...forceBreakWord(word.slice(maxWordLength), maxWordLength),
  ];
};

const forceBreakWords = (
  text: string,
  maxWordLength: number = DEFAULT_MAX_WORD_LENGTH
): string => {
  return text
    .split(' ')
    .map((word) => forceBreakWord(word, maxWordLength))
    .flat(Infinity)
    .join(' ');
};

export default forceBreakWords;
