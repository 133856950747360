import { Select } from '@cognite/cogs-lab';
import { ChevronDownIcon, ChevronUpIcon, Menu } from '@cognite/cogs.js-v10';
import { showGlobalOverlayAtom, useOnClickOutside } from '@infield/features/ui';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import * as S from './elements';

type OptionType<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  placeholder: string;
  menuOptions: OptionType<T>[];
  selectedValue?: OptionType<T>;
  selectValue: (value: OptionType<T>) => void;
};

export const SelectMobile = <T extends object>({
  placeholder,
  menuOptions,
  selectedValue,
  selectValue,
}: Props<T>) => {
  const isDesktop = useIsDesktop();
  const setShowOverlay = useSetRecoilState(showGlobalOverlayAtom);
  const [isMenuShown, setIsMenuShown] = useState(false);

  const setValue = () => {
    setShowOverlay(!isMenuShown);
    setIsMenuShown(!isMenuShown);
  };

  const outsideClickRef = useRef(null);
  useOnClickOutside(outsideClickRef, () => setValue());

  const SelectMenu = isMenuShown && (
    <S.StyledMenu>
      <div ref={outsideClickRef}>
        {menuOptions.map((option) => {
          return (
            <Menu.Item
              onClick={() => {
                selectValue(option);
                setValue();
              }}
              key={option.label}
              toggled={option.value === selectedValue?.value}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </div>
    </S.StyledMenu>
  );

  const SelectButton = (
    <S.StyledSelectButton
      type="tertiary"
      icon={isMenuShown ? <ChevronUpIcon /> : <ChevronDownIcon />}
      iconPlacement="right"
      onClick={setValue}
      $placeholder={selectedValue ? false : Boolean(placeholder)}
      aria-label="custom-select-button"
    >
      {selectedValue ? selectedValue.label : placeholder}
    </S.StyledSelectButton>
  );

  return isDesktop ? (
    <Select
      placeholder={placeholder}
      fullWidth
      options={menuOptions}
      value={selectedValue?.value}
      onChange={(_, val) => {
        const newValue = menuOptions.find((option) => option.value === val);
        return newValue ? selectValue(newValue) : null;
      }}
    />
  ) : (
    <>
      {SelectMenu}
      {SelectButton}
    </>
  );
};
