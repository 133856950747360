import { Position, Size } from '../annotations/types';

import isApproximatelyEqual from './isApproximatelyEqual';

const isSizeAndPositionApproximatelyEqual = <
  SizeAndPosition extends Size & Position
>(
  a: SizeAndPosition,
  b: SizeAndPosition
): boolean =>
  isApproximatelyEqual(a.x, b.x) &&
  isApproximatelyEqual(a.y, b.y) &&
  isApproximatelyEqual(a.width, b.width) &&
  isApproximatelyEqual(a.height, b.height);

export default isSizeAndPositionApproximatelyEqual;
