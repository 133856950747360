import type { FC, ReactNode } from 'react';
import { useEffect, useRef } from 'react';

import * as S from './elements';

export type ResizeObserverContainerProps = {
  onResize: (rect: DOMRect) => void;

  children?: ReactNode;
};
export const ResizeObserverContainer: FC<ResizeObserverContainerProps> = ({
  children,
  onResize,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current) {
        onResize(containerRef.current.getBoundingClientRect());
      }
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [onResize]);

  return (
    <S.ResizeObserverWrapper ref={containerRef}>
      {children}
    </S.ResizeObserverWrapper>
  );
};
