import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from './defaultDateFormats';

export const getLastUpdatedLabel = (
  lastUpdatedTime: Date,
  translatedSecondsAgoText: string
): string => {
  const curTimestamp = dayjs();
  const lastUpdatedTimestamp = dayjs(lastUpdatedTime);
  const diff = curTimestamp.diff(lastUpdatedTimestamp);

  // if several months ago, show date
  const numberOfMonths = dayjs.duration(diff).months();
  if (numberOfMonths > 0) {
    return lastUpdatedTimestamp.format(DEFAULT_DATE_FORMAT);
  }

  // if less than a month, describe in details using relativeTime plugin
  const numberOfMinutes = dayjs.duration(diff).minutes();
  if (numberOfMinutes > 0) {
    return lastUpdatedTimestamp.fromNow();
  }

  // if only a few seconds ago, show number of seconds
  return `${dayjs.duration(diff).seconds()} ${translatedSecondsAgoText}`;
};
