import { useFDMServices } from '@infield/providers/fdm-services';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import type { ThreeDModelIdentifier } from '../types';

export const useAssetModelsIDM = ({
  assetExternalId,
}: {
  assetExternalId?: string;
}) => {
  const { threeDService } = useFDMServices();

  const { isIdm } = useAppConfigContext();

  return useQuery<ThreeDModelIdentifier[], unknown>(
    [QueryKeys.THREE_D, QueryKeys.ASSET_IDM_3D_MODELS, assetExternalId],
    async () => {
      const modelsWithMappings: ThreeDModelIdentifier[] = [];

      if (!assetExternalId) return modelsWithMappings;

      const data = await threeDService.getIdmAssetModels(assetExternalId);
      return data;
    },
    {
      onError: (err) => captureException(err),
      staleTime: Infinity,
      enabled: Boolean(assetExternalId) && isIdm,
    }
  );
};
