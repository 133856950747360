export enum LoadingStatus {
  LOADING = 'isLoading',
  SUCCESS = 'isSuccess',
  ERROR = 'isError',
}

export type TLoadingStatus = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

export const defaultLoadingStatus: TLoadingStatus = {
  [LoadingStatus.LOADING]: false,
  [LoadingStatus.SUCCESS]: false,
  [LoadingStatus.ERROR]: false,
};
