import type { Template, TemplateStatus } from '@cognite/apm-client';
import { ChevronDownIcon, Dropdown, TagChip } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  checkedTasksAtom,
  selectedTemplateAtom,
} from '@infield/features/template';
import { useUpdateTemplate } from '@infield/features/template/hooks';
import { ClickableTagChip } from '@infield/features/ui/Chip';
import type { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import * as S from './elements';

export type StatusSelectorProps = {
  externalId: string;
  currentStatus: TemplateStatus;
  disabled?: boolean;
};

export const StatusSelector: FC<StatusSelectorProps> = ({
  externalId,
  currentStatus,
  disabled,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const template = useRecoilValue(selectedTemplateAtom);
  const { mutateAsync: upsertTemplate } = useUpdateTemplate();
  const [searchParams, setSearchParams] = useSearchParams();
  const resetCheckedTasks = useResetRecoilState(checkedTasksAtom);

  const handleChangeStatus = async (status: TemplateStatus) => {
    if (status) {
      const template: Template = {
        externalId,
        status,
      };
      resetCheckedTasks();
      await upsertTemplate(template, {
        onSuccess: () => {
          searchParams.set('templateStatus', status);
          setSearchParams(searchParams);
        },
      });
    }
  };
  const isDeleted = template && template.isArchived;
  const isReady = currentStatus === 'Ready';
  const dropdownStatusLabel = isReady
    ? t('ACTIVITY_STATUS_DRAFT', 'Draft')
    : t('ACTIVITY_STATUS_READY', 'Ready');

  const currentStatusLabel = () => {
    switch (currentStatus) {
      case 'Ready':
        return t('ACTIVITY_STATUS_READY', 'Ready');
      default:
        return t('ACTIVITY_STATUS_DRAFT', 'Draft');
    }
  };

  if (isDeleted) {
    return (
      <TagChip
        label={t('ACTIVITY_STATUS_DELETED', 'Deleted')}
        size="x-small"
        colorScheme="red"
      />
    );
  }

  if (disabled) {
    return (
      <TagChip
        label={currentStatusLabel()}
        size="x-small"
        colorScheme={isReady ? 'green' : undefined}
      />
    );
  }

  return (
    <Dropdown
      offset={[0, 4]}
      hideOnSelect={{
        hideOnContentClick: true,
        hideOnOutsideClick: true,
      }}
      disabled={isDeleted}
      content={
        <S.ChipDropdownWrapper>
          <ClickableTagChip
            label={dropdownStatusLabel}
            size="x-small"
            colorScheme={isReady ? undefined : 'green'}
            onClick={() => handleChangeStatus(isReady ? 'Draft' : 'Ready')}
            style={{ width: '100%' }}
            data-testid={
              isReady ? 'status-draft-option' : 'status-ready-option'
            }
          />
        </S.ChipDropdownWrapper>
      }
    >
      <S.TagChipWithIconOnRight
        label={currentStatusLabel()}
        size="x-small"
        colorScheme={isReady ? 'green' : undefined}
        icon={<ChevronDownIcon />}
        data-testid="template-status-selector"
      />
    </Dropdown>
  );
};
