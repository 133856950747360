import dayjs from 'dayjs';
import { isDate, isNumber } from 'lodash-es';

import { ValueType } from '../types';

export const getValueTypeAsNumber = (
  value: ValueType,
  defaultValue: string | number
): number => {
  if (isNumber(value)) {
    return value;
  }

  if (isDate(value)) {
    return value.getTime();
  }

  const date = dayjs(value);

  if (date.isValid()) {
    return date.valueOf();
  }

  return Number(defaultValue);
};
