import { CogniteClient } from '@cognite/sdk';

import { UnifiedViewer } from '../../..';
import { ContainerType } from '../ContainerType';
import ReactContainer, {
  ReactContainerRenderContentProps,
} from '../ReactContainer';
import ErrorContent from '../TimeseriesContainer/ErrorContent';

import PropertyListContent from './PropertyListContent';
import { PropertyListContainerProps } from './types';

export default class PropertyListContainer<MetadataType> extends ReactContainer<
  MetadataType,
  PropertyListContainerProps<MetadataType>
> {
  public readonly type = ContainerType.PROPERTY_LIST;

  private cogniteClient: CogniteClient;

  public constructor(
    props: PropertyListContainerProps<MetadataType>,
    unifiedViewer: UnifiedViewer
  ) {
    super(props, unifiedViewer);

    const client = this.unifiedViewer.getCogniteClient();
    if (client === undefined) {
      throw new Error(
        'CogniteClient is not defined - please provide a CogniteClient instance to the UnifiedViewer to use CardContainer'
      );
    }
    this.cogniteClient = client;
  }

  protected getPropsRequiringUpdate(): Array<
    keyof Pick<PropertyListContainerProps, 'instance' | 'properties'>
  > {
    return ['instance', 'properties'];
  }

  protected renderContent = ({
    width,
    height,
    unscaledWidth,
    unscaledHeight,
    shouldAutoSize,
    setLoadingStatus,
    onContentSizeChange,
  }: ReactContainerRenderContentProps): JSX.Element => {
    if (this.loadingStatus.isError) {
      return <ErrorContent width={width} height={height} />;
    }
    return (
      <PropertyListContent
        sdk={this.cogniteClient}
        width={width}
        height={height}
        unscaledWidth={unscaledWidth}
        unscaledHeight={unscaledHeight}
        shouldAutoSize={shouldAutoSize}
        instance={this.props.instance}
        containerPadding={this.getHeaderNodeDimensions().height}
        setLoadingStatus={setLoadingStatus}
        properties={this.props.properties}
        onContentSizeChange={onContentSizeChange}
      />
    );
  };

  public serialize = (): PropertyListContainerProps => {
    return {
      ...this.serializeBaseContainerProps(),
      type: this.type,
      instance: this.props.instance,
    };
  };
}
