import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  alignItems: 'stretch',
})`
  height: 100%;

  .cp-panel-content {
    overflow-y: hidden;
  }
`;
