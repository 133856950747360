import type {
  InFieldUser,
  InFieldUserPreferences,
  UserFilter,
  UserPreferenceDiscipline,
  UserPreferenceLocation,
} from '@cognite/apm-client';
import get from 'lodash/get';
import set from 'lodash/set';

type Props =
  | { preference: 'location'; data?: UserPreferenceLocation }
  | { preference: 'discipline'; data: UserPreferenceDiscipline }
  | { preference: 'maintenanceTableFilters'; data: UserFilter }
  | { preference: 'checklistsTableFilters'; data: UserFilter };

export const userPreferenceMutationFunction = (
  { preference, data }: Props,
  user: InFieldUser
): InFieldUserPreferences | undefined => {
  const newPreferences = {
    ...(user.preferences ?? {
      externalId: user.externalId,
    }),
  };

  if (!newPreferences) return;

  if (preference === 'location') {
    set(newPreferences, `infield.location`, data);
  }

  if (preference === 'discipline') {
    set(newPreferences, `infield.discipline`, data);
  }

  if (preference === 'maintenanceTableFilters') {
    const existingMaintenanceTableFilters: UserFilter[] = get(
      newPreferences,
      `infield.filters.maintenanceTableFilters`,
      []
    );

    const index = existingMaintenanceTableFilters.findIndex(
      (filter: UserFilter) => filter.externalId === data.externalId
    );

    if (index !== -1) {
      // replace existing filter
      existingMaintenanceTableFilters[index] = data;
    } else {
      // add new filter
      existingMaintenanceTableFilters.push(data);
    }

    set(
      newPreferences,
      `infield.filters.maintenanceTableFilters`,
      existingMaintenanceTableFilters
    );
  }

  if (preference === 'checklistsTableFilters') {
    const existingChecklistTableFilters: UserFilter[] = get(
      newPreferences,
      `infield.filters.checklistsTableFilters`,
      []
    );

    const index = existingChecklistTableFilters.findIndex(
      (filter: UserFilter) => filter.externalId === data.externalId
    );

    if (index !== -1) {
      // replace existing filter
      existingChecklistTableFilters[index] = data;
    } else {
      // add new filter
      existingChecklistTableFilters.push(data);
    }

    set(
      newPreferences,
      `infield.filters.checklistsTableFilters`,
      existingChecklistTableFilters
    );
  }

  return newPreferences;
};
