import { Accordion, Button, Infobox } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const AlignedAccordion = styled(Accordion).attrs({
  size: 'large',
})`
  width: calc(100vw - 100px) !important;
  .cogs-accordion__header__title > p {
    margin-bottom: 0;
  }
`;

export const AddOptionButton = styled(Button)`
  max-width: 200px;
`;

export const StyledInfobox = styled(Infobox)`
  border-end-end-radius: 0px;
  border-end-start-radius: 0px;
`;
