import { z } from '@infield/utils/z';
import styled from 'styled-components';

export const DesktopSearchContainer = styled.div`
  position: fixed;
  width: 100%;
  max-width: 550px;
  right: 8px;
  top: 63px;
  z-index: ${z.SEARCH_OVERLAY};
`;

export const MobileSearchContainer = styled.div<{ $searchOpened: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  height: 100%;
  z-index: ${z.SEARCH_OVERLAY};

  ${({ $searchOpened }) =>
    $searchOpened
      ? 'animation-name: moveUp, fade-in; animation-duration: 300ms, 300ms;'
      : ''};

  @keyframes fade-in {
    from {
      opacity: 0;
    }
  }

  @keyframes moveUp {
    from {
      transform: translateY(135px);
    }
  }
`;
