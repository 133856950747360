import {
  Button,
  EmptyState,
  LogoutIcon,
  SettingsIcon,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { useInfieldLogout } from '@infield/hooks/use-infield-logout';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { AppConfigView } from '@infield/pages/app-config';
import { PageContainerStyle } from '@infield/pages/elements';
import { useState } from 'react';
import type { FC } from 'react';

import { useIsAppConfiguratorQuery } from '../hooks';

import * as S from './elements';

interface Props {
  title: string;
  body: string;
}

export const AppConfigErrorScreen: FC<Props> = ({ title, body }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.user);
  const { logout } = useInfieldLogout();
  const isDesktop = useIsDesktop();
  const { data: hasAppConfigPermission } = useIsAppConfiguratorQuery();
  const [showAppConfig, setShowAppConfig] = useState(false);

  return (
    <PageContainerStyle>
      <S.ErrorStateContainer>
        <EmptyState
          title={title}
          primaryAction={
            <Button type="ghost" icon={<LogoutIcon />} onClick={logout}>
              {t('USER_ERROR_LOCATION_MISSING_LOGOUT_BUTTON', 'Logout')}
            </Button>
          }
          description={body}
          secondaryAction={
            hasAppConfigPermission && isDesktop ? (
              <Button
                type="ghost"
                icon={<SettingsIcon />}
                onClick={() => setShowAppConfig(true)}
              >
                {t(
                  'USER_ERROR_LOCATION_MISSING_GOTO_CONFIGURATION_BUTTON',
                  'Go to configuration'
                )}
              </Button>
            ) : null
          }
        />
        <S.StyledModal
          title="App configuration"
          visible={showAppConfig}
          size="full-screen"
          onCancel={() => setShowAppConfig(false)}
          onOk={() => setShowAppConfig(false)}
          disableEnforceFocus
        >
          <AppConfigView />
        </S.StyledModal>
      </S.ErrorStateContainer>
    </PageContainerStyle>
  );
};
