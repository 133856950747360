import { z } from '@infield/utils/z';
import styled from 'styled-components';

import type { SearchResultPosition } from './asset-search-input';

export const SearchContainer = styled.div`
  position: relative;
`;

export const SearchResultWrapper = styled.div<{
  $searchResultPosition: SearchResultPosition;
}>`
  position: absolute;
  z-index: ${z.OVERLAY};
  background: white;
  width: calc(100% - 35px);
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  ${(props) =>
    props.$searchResultPosition === 'bottom'
      ? 'margin-top: 10px;'
      : 'bottom: 46px;'}
`;
