import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { Button, CameraIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { MediaManager } from '@infield/features/media';
import {
  MEDIA_METADATA_PROP_POINTING_TO_OBSERVATION,
  OBSERVATION_FILE_SIZE_LIMIT_BYTES,
} from '@infield/features/observation';
import type { Step } from '@infield/features/observation';
import { useState } from 'react';
import type { FC } from 'react';

import { ObservationDetailsStepInfo } from '../observation-details-step-wrapper/observation-details-step-info';

interface Props {
  step: Step;
  fileInstanceIds?: InstanceOrExternalId[];
  observationExternalId?: string;
  assetExternalId?: string;
  isLocked?: boolean;
  onMediaSave: (fileExternalIds: string[]) => void;
}

export const ObservationDetailsStepMedia: FC<Props> = ({
  step,
  fileInstanceIds,
  observationExternalId,
  assetExternalId,
  isLocked,
  onMediaSave,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);

  const [showMediaManager, setShowMediaManager] = useState(false);

  return (
    <>
      <ObservationDetailsStepInfo step={step} />
      <Button
        type="tertiary"
        icon={<CameraIcon />}
        onClick={() => setShowMediaManager(true)}
        disabled={isLocked}
      >
        {t('OBSERVATION_STEP_MEDIA_UPLOAD_BUTTON_TITLE', 'Upload from device')}
      </Button>
      {showMediaManager && (
        <MediaManager
          visible
          enableEditingObservationMedia
          mediaInstanceIds={fileInstanceIds ?? []}
          metadata={{
            [MEDIA_METADATA_PROP_POINTING_TO_OBSERVATION]:
              observationExternalId,
          }}
          assetInstanceIds={
            assetExternalId ? [{ externalId: assetExternalId }] : undefined
          }
          isLoading={false}
          fileSizeLimitBytes={OBSERVATION_FILE_SIZE_LIMIT_BYTES}
          onSave={(mediaExternalIds) => {
            onMediaSave(mediaExternalIds);
          }}
          onClose={() => setShowMediaManager(false)}
        />
      )}
    </>
  );
};
