import { useAuthContext } from '@cognite/e2e-auth';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { onlineManager, useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';

import { NetworkStatusContext } from './network-status-context';
import {
  adjustNetworkStateBasedOnRequestTime,
  SLOW_NETWORK_MBIT_THRESHOLD,
} from './utils';

export const NetworkStatusProvider: FC<PropsWithChildren> = ({ children }) => {
  const isDesktop = useIsDesktop();
  const { client } = useAuthContext();
  const [isOnline, setIsOnline] = useState(true);
  const [isNetworkSlow, setIsNetworkSlow] = useState(false);
  const [isBackOnline, setIsBackOnline] = useState(false);
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const closeBackOnlineBanner = () => {
    setTimeout(() => {
      setIsBackOnline(false);
    }, 2000);
  };

  const value = useMemo(
    () => ({ isOnline, isNetworkSlow, isBackOnline, closeBackOnlineBanner }),
    [isOnline, isNetworkSlow, isBackOnline]
  );

  const checkDownloadSpeedForDesktopVersion = useCallback(() => {
    // For desktop we don't need precise download speed since offline mode is only for mobile view
    if (!isOnline) {
      setIsNetworkSlow(false);
      return;
    }

    // We can use navigator.connection for download speed check
    if (navigator.connection) {
      // Check if supported on current browser
      const { downlink } = navigator.connection;
      if (downlink !== undefined && downlink < SLOW_NETWORK_MBIT_THRESHOLD) {
        setIsNetworkSlow(true);
      } else {
        setIsNetworkSlow(false);
      }
    }
  }, [isOnline]);

  const { refetch: refetchUserEndpoint } = useQuery(
    ['offline-check'],
    async () => {
      const startTime = Date.now();
      await client.get(`${client.getBaseUrl()}/api/v1/token/inspect`);
      const endTime = Date.now();
      return endTime - startTime;
    },
    {
      enabled: false, // We control when it runs with refetch
      retry: 3, // Don't retry on error
      networkMode: 'always', // Ensure the query runs even if the network is "offline"
      onSuccess: (requestTime) => {
        adjustNetworkStateBasedOnRequestTime(
          requestTime,
          isOnline,
          setIsNetworkSlow,
          setIsOnline,
          setIsBackOnline
        );
        sliMetrics.track('offline-check', {
          status: 'success',
          networkSpeedMbps: requestTime,
        });
      },
      onError: (error) => {
        setIsOnline(false);
        onlineManager.setOnline(false);
        sliMetrics.track('offline-check', {
          status: 'error',
          error,
        });
      },
    }
  );

  const checkDownloadSpeed = useCallback(() => {
    if (isDesktop) {
      checkDownloadSpeedForDesktopVersion();
    } else {
      refetchUserEndpoint();
    }
  }, [checkDownloadSpeedForDesktopVersion, isDesktop, refetchUserEndpoint]);

  useEffect(() => {
    const setToOnline = () => {
      if (isDesktop) {
        setIsBackOnline(true);
        setIsOnline(true);
        onlineManager.setOnline(undefined);
      }
    };
    const setToOffline = () => {
      setIsOnline(false);
      onlineManager.setOnline(false);
    };
    window.addEventListener('offline', setToOffline);
    window.addEventListener('online', setToOnline);

    return () => {
      window.removeEventListener('offline', setToOffline);
      window.removeEventListener('online', setToOnline);
    };
  }, [isDesktop]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkDownloadSpeed();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [checkDownloadSpeed]);

  return (
    <NetworkStatusContext.Provider value={value}>
      {children}
    </NetworkStatusContext.Provider>
  );
};

export const useNetworkStatusContext = (): {
  isOnline: boolean;
  isNetworkSlow: boolean;
  isBackOnline: boolean;
  closeBackOnlineBanner: () => void;
} => {
  const context = useContext(NetworkStatusContext);
  if (context) {
    return context;
  }
  throw new Error(
    'useNetworkContext must be used within a NetworkStatusProvider'
  );
};
