import { Body, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
  gap: 16,
})`
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(0, 0, 0, 0.2);
  padding: 16px;
  width: 300px;
`;

export const DialogueContentWrapper = styled.div`
  min-height: 200px;
`;

export const NotValidRoot = styled(Body).attrs({ size: 'x-small' })`
  color: ${({ theme }) => theme['text-icon--status-critical']};
`;
