import {
  ChevronDownIcon,
  ChevronRightIcon,
  CounterChip,
} from '@cognite/cogs.js-v10';
import type { FC, PropsWithChildren } from 'react';

import * as S from './elements';

interface Props {
  title: string;
  numberOfItems: number;
  expanded: boolean;
  toggleExpand: () => void;
}

export const ListAccordion: FC<PropsWithChildren<Props>> = ({
  title,
  numberOfItems,
  children,
  expanded,
  toggleExpand,
}) => {
  return (
    <S.Wrapper direction="column" $expanded={expanded}>
      <S.Container onClick={toggleExpand}>
        {expanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
        <S.Title>{title}</S.Title>
        <CounterChip counter={numberOfItems} />
      </S.Container>
      {expanded && children}
    </S.Wrapper>
  );
};
