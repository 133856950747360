export enum TooltipAnchorPosition {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
  RIGHT_TOP = 'right-top',
  RIGHT_CENTER = 'right-center',
  RIGHT_BOTTOM = 'right-bottom',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_LEFT = 'bottom-left',
  LEFT_BOTTOM = 'left-bottom',
  LEFT_CENTER = 'left-center',
  LEFT_TOP = 'left-top',
}

export const TooltipPositionsOrder = [
  TooltipAnchorPosition.TOP_LEFT,
  TooltipAnchorPosition.TOP_CENTER,
  TooltipAnchorPosition.TOP_RIGHT,
  TooltipAnchorPosition.RIGHT_TOP,
  TooltipAnchorPosition.RIGHT_CENTER,
  TooltipAnchorPosition.RIGHT_BOTTOM,
  TooltipAnchorPosition.BOTTOM_RIGHT,
  TooltipAnchorPosition.BOTTOM_CENTER,
  TooltipAnchorPosition.BOTTOM_LEFT,
  TooltipAnchorPosition.LEFT_BOTTOM,
  TooltipAnchorPosition.LEFT_CENTER,
  TooltipAnchorPosition.LEFT_TOP,
];

export type TooltipConfig = {
  id?: string;
  targetIds: string[];
  anchorTo?: TooltipAnchorPosition;
  shouldPositionStrictly?: boolean;
  shouldBeVisibleWhilePanning?: boolean;
  shouldBeVisibleWhileZooming?: boolean;
  content: React.ReactElement;
};
