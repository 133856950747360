import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import type { Filters } from '@cognite/fdm-client';
import { useFlag } from '@cognite/react-feature-flags';
import { getCustomerSpecificFilterableFields } from '@infield/features/activities/utils';
import { useMemo } from 'react';

import type { FieldInfo } from './types';
import { useActivitiesAggregateFilterOptions } from './use-activities-aggregate-filter-options';
import { useActivityAggregateType } from './use-activities-aggregate-type';
import { useActivityFilterLocationOptions } from './use-activity-filter-location-options';

export const useMaintenanceTableFilterOptions = (
  fields: FieldInfo[],
  selectedFilterLocations: GridFilterOptionsType[],
  dateFilters?: Filters
) => {
  const { isEnabled: isAkerbpCustomCode } = useFlag(
    'INFIELD.akerbp_custom_code',
    { forceRerender: true, fallback: false }
  );

  const customerSpecificFilterableFields = useMemo(() => {
    return getCustomerSpecificFilterableFields(fields, isAkerbpCustomCode);
  }, [fields, isAkerbpCustomCode]);

  const {
    data: customerSpecificFilterOptionsMap = {},
    isLoading: isLoadingAgggregateFilterOptions,
  } = useActivitiesAggregateFilterOptions(
    customerSpecificFilterableFields,
    dateFilters
  );

  const {
    locationOptions: locationFilterOptions,
    isLoadingLocation: isLoadingLocationFilterOptions,
    setSearchInput,
    isBackendSearchEnabled,
  } = useActivityFilterLocationOptions(selectedFilterLocations, dateFilters);

  const { data: activityTypes, isLoading: isLoadingActivityTypes } =
    useActivityAggregateType(dateFilters);

  const gridFilterActivityTypeOptions: GridFilterOptionsType[] | undefined =
    useMemo(
      () =>
        activityTypes?.map(({ type, count }) => ({
          label: type,
          value: type,
          count,
        })),
      [activityTypes]
    );

  return {
    customerSpecificFilterOptionsMap,
    activityTypeFilterOptions: gridFilterActivityTypeOptions || [],
    locationFilterOptions,
    isLoading:
      isLoadingAgggregateFilterOptions ||
      isLoadingLocationFilterOptions ||
      isLoadingActivityTypes,
    setSearchInput,
    isBackendSearchEnabled,
  };
};
