import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Wrapper = styled(Flex).attrs({
  gap: 12,
  wrap: 'wrap',
  direction: 'column',
})`
  span.error-message {
    font-size: 13px;
    line-height: 16px;
    color: ${({ theme }) => theme['text-icon--status-critical']};
  }
`;

export const RequiredIndicator = styled.span`
  color: ${({ theme }) => theme['text-icon--status-critical']};
`;
