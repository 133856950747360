import { Textarea } from '@cognite/cogs.js-v10';
import { type Step } from '@infield/features/observation';
import { useOnClickOutside } from '@infield/features/ui';
import type { FC } from 'react';
import { useRef, useState } from 'react';

import { ObservationStepWrapper } from '../elements';

interface Props {
  step?: Step;
  value?: string;
  isLocked?: boolean;
  onChange: (value: string) => void;
}

export const ObservationDesktopProblemInput: FC<Props> = ({
  step,
  value,
  isLocked,
  onChange,
}) => {
  const [problemText, setProblemText] = useState(value);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleOnClickOutside = (event: MouseEvent | TouchEvent) => {
    if (value !== problemText && problemText) {
      onChange(problemText);
    }
    event.stopPropagation();
  };

  useOnClickOutside(textAreaRef, handleOnClickOutside);

  return (
    <ObservationStepWrapper>
      <Textarea
        label={step?.name}
        fullWidth
        ref={textAreaRef}
        value={problemText}
        onChange={(e) => setProblemText(e.target.value)}
        rows={4}
        placeholder={step?.description}
        disabled={isLocked}
        required={step?.isRequired}
      />
    </ObservationStepWrapper>
  );
};
