import { ArrowRightIcon, Button, Flex, Heading } from '@cognite/cogs.js-v10';
import type { FC, ReactNode } from 'react';

import * as S from './elements';

type Props = {
  icon?: ReactNode;
  title: string;
  buttonText?: string;
  aiWidget?: React.ReactNode;
  onButtonClick?: () => void;
  children?: React.ReactNode;
};

export const AssetCard: FC<Props> = ({
  icon,
  title,
  buttonText,
  aiWidget,
  onButtonClick,
  children,
}) => {
  return (
    <S.Container>
      <S.Content justifyContent="space-between">
        <Flex alignItems="center" gap={8}>
          {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
          <Heading level={6}>{title}</Heading>
        </Flex>
        <Flex alignItems="flex-end" gap={8}>
          {aiWidget}
          <Button
            disabled={onButtonClick === undefined}
            icon={<ArrowRightIcon />}
            iconPlacement="right"
            onClick={onButtonClick}
            data-testid={title}
          >
            {buttonText}
          </Button>
        </Flex>
      </S.Content>
      {children}
    </S.Container>
  );
};
