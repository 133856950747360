import type { RootLocationFeatureToggles } from '@infield/features/app-config';

export const getIsOverviewPageEnabled = (
  rootLocationFeatureToggles: RootLocationFeatureToggles | undefined
) =>
  Boolean(
    rootLocationFeatureToggles?.templateChecklistFlow ||
      rootLocationFeatureToggles?.workorderChecklistFlow
  );
export const getIsChecklistPageEnabled = (
  rootLocationFeatureToggles: RootLocationFeatureToggles | undefined
) =>
  Boolean(
    rootLocationFeatureToggles?.templateChecklistFlow ||
      rootLocationFeatureToggles?.workorderChecklistFlow
  );

export const getIsTemplatesPageEnabled = (
  rootLocationFeatureToggles: RootLocationFeatureToggles | undefined
) => Boolean(rootLocationFeatureToggles?.templateChecklistFlow);

export const getIsObservationsPageEnabled = (
  rootLocationFeatureToggles: RootLocationFeatureToggles | undefined
) => Boolean(rootLocationFeatureToggles?.observations?.isEnabled);
