import { CDFView } from '@cognite/apm-client';
import type { CogniteClient, ViewReference } from '@cognite/sdk';

import type { InfieldAppConfigDTO } from '../types';

export class AppInfieldSpecificConfig<
  TInfieldConfigSchema extends InfieldAppConfigDTO = InfieldAppConfigDTO
> {
  public space: string;
  public cogniteClient: CogniteClient;
  public infieldConfigView: CDFView<TInfieldConfigSchema>;

  constructor(
    client: CogniteClient,
    viewReference?: Omit<ViewReference, 'type'>
  ) {
    this.cogniteClient = client;
    this.space = 'APM_Config';
    this.infieldConfigView = new CDFView(
      client,
      {
        externalId: viewReference?.externalId ?? 'InFieldLocationConfig',
        space: viewReference?.space ?? 'cdf_infield',
        version: viewReference?.version ?? 'v1',
      },
      this.space
    );
  }

  async listAll(): Promise<InfieldAppConfigDTO[]> {
    const infieldConfigs = await this.infieldConfigView
      .list()
      .then((res) => res.items);

    return infieldConfigs;
  }

  async retrieveByADSLocationExternalId(
    adsLocationExternalId: string | null
  ): Promise<InfieldAppConfigDTO | null> {
    if (!adsLocationExternalId) {
      return null;
    }
    const infieldConfigs = await this.infieldConfigView
      .list({
        filters: {
          equals: {
            property: 'rootLocationExternalId',
            eq: adsLocationExternalId,
          },
        },
      })
      .then((res) => res.items);

    if (infieldConfigs.length > 0) {
      return infieldConfigs[0];
    }

    return null;
  }
}
