import type { ObservationFieldOption } from '@cognite/apm-config';
import { type Step } from '@infield/features/observation';
import type { FC } from 'react';

import { ObservationDetailsStepInfo } from '../observation-details-step-wrapper';

import * as S from './elements';

interface Props {
  step: Step;
  value?: string;
  isLocked?: boolean;
  onChange: (value: string) => void;
}

export const ObservationDetailsStepType: FC<Props> = ({
  step,
  value,
  isLocked,
  onChange,
}) => {
  const typeOptions = step.options;

  const selectedType = typeOptions?.find((option) => option.value === value);

  return (
    <>
      <ObservationDetailsStepInfo step={step} />
      <S.TypeSelection
        options={typeOptions ?? []}
        value={selectedType}
        disabled={isLocked}
        onChange={(selected: ObservationFieldOption) =>
          onChange(selected.value)
        }
      />
    </>
  );
};
