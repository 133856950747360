import type { Activity, Checklist } from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { getUniqueExternalIds } from '@infield/utils/filtering-helpers';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

const ITEMS_TO_FETCH = 1000;

export const useSearchActivitiesWithChecklists = (
  query: string,
  filter?: Filters,
  checklistFilter?: Filters,
  properties?: (keyof Activity)[],
  enabled = true
) => {
  const { apmClient, checklistService } = useFDMServices();
  const { activity: configFilters } = useAppConfigFiltersContext();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const filters: Filters[] = [];

  if (configFilters.rootAssetExternalIds) {
    filters.push(configFilters.rootAssetExternalIds);
  }

  if (filter) {
    filters.push(filter);
  }

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  return useQuery<{ activities: Activity[]; checklists: Checklist[] }, Error>(
    [
      QueryKeys.SEARCH_ACTIVITIES_WITH_CHECKLIST,
      query,
      filters,
      checklistFilter,
      properties,
    ],
    async () => {
      sliTimerStartTime.current = Date.now();

      const activities = await apmClient.activities.search({
        query,
        filters: {
          and: filters,
        } as any,
        pageSize: 1000,
      });

      const activitiesUniqueExternalIds = getUniqueExternalIds(activities);

      let checklists: Checklist[] = [];
      if (activitiesUniqueExternalIds.length > 0) {
        const { items } = await checklistService.getChecklistsAndChecklistItems(
          {
            and: [
              {
                in: {
                  property: 'sourceId',
                  in: activitiesUniqueExternalIds,
                },
              },
              ...(checklistFilter ? [checklistFilter] : []),
            ],
          },
          undefined,
          undefined,
          ITEMS_TO_FETCH
        );

        checklists = items;
      }

      return { activities, checklists };
    },
    {
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.SEARCH_ACTIVITIES_WITH_CHECKLIST,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.SEARCH_ACTIVITIES_WITH_CHECKLIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.SEARCH_ACTIVITIES_WITH_CHECKLIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      enabled: Boolean(configFilters.rootAssetExternalIds) && enabled,
    }
  );
};
