import { useQuery } from '@tanstack/react-query';

import { CogniteClient, ExternalId } from '@cognite/sdk';
import { useSDK } from '@cognite/sdk-provider';

import { ScheduledCalculationTask } from '../models';

const fetchScheduledCalculationTasks = (
  scheduledCalculationTaskIds: ExternalId[],
  sdk: CogniteClient
) => {
  return sdk.post<{ items: ScheduledCalculationTask[] }>(
    `api/v1/projects/${sdk.project}/calculations/schedules/byids`,
    {
      data: { items: scheduledCalculationTaskIds },
      headers: {
        'cdf-version': 'alpha',
      },
    }
  );
};

export const useScheduledCalculationTasks = (externalIds: ExternalId[]) => {
  const sdk = useSDK();

  const shouldFetchTasks = Boolean(externalIds?.length);

  return useQuery<ScheduledCalculationTask[]>(
    ['scheduled-calculations', externalIds, shouldFetchTasks],
    () => {
      return fetchScheduledCalculationTasks(externalIds, sdk).then(
        ({ data }) => data.items
      );
    },
    { enabled: shouldFetchTasks }
  );
};
