import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { Colors, Heading } from '@cognite/cogs.js-v10';

import { LoadingStatus } from '../asyncContainerUtils';
import { ReactContainerRenderContentProps } from '../ReactContainer';

import TableItemContent from './TableItemContent';
import { TableItem } from './types';

export const TABLE_CONTENT_COLOR = Colors['surface--muted'];

// Shameful because we don't have a good way of knowing when the initial load / render is completed
// so we just delay it by some time.
const SHAMEFUL_ON_INITIAL_LOAD_COMPLETED_DELAY_MS = 100;

const DOWNSIZE_CONTENT_PERCENTAGE = 0.7;
const PADDING = 16;

type TableContentProps = Pick<
  ReactContainerRenderContentProps,
  | 'width'
  | 'height'
  | 'unscaledWidth'
  | 'unscaledHeight'
  | 'setLoadingStatus'
  | 'onContentSizeChange'
  | 'shouldAutoSize'
> & {
  // We know that the unscaled width and height have the same ratio as the width and height.
  title?: React.ReactNode;
  items: TableItem[];
  containerPadding: number;
};

const TableContent: React.FC<TableContentProps> = ({
  width,
  height,
  unscaledHeight,
  unscaledWidth,
  title,
  items,
  shouldAutoSize,
  containerPadding,
  setLoadingStatus,
  onContentSizeChange,
}) => {
  const [divContainerRef, setDivContainerRef] = useState<HTMLDivElement | null>(
    null
  );
  const [contentContainerRef, setContentContainerRef] =
    useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      setLoadingStatus(LoadingStatus.SUCCESS);
    }, SHAMEFUL_ON_INITIAL_LOAD_COMPLETED_DELAY_MS);
  }, [setLoadingStatus]);

  useEffect(() => {
    if (divContainerRef === null || contentContainerRef === null) {
      return;
    }
    if (shouldAutoSize !== true) {
      return;
    }
    // Increase the height if the table content overflows
    if (divContainerRef.scrollHeight > divContainerRef.clientHeight) {
      const padding = 2 * PADDING + containerPadding;
      onContentSizeChange({
        width: unscaledWidth,
        height: divContainerRef.scrollHeight + padding,
      });
      return;
    }

    // Decrease the height if the table content covers less than a given
    // percentage of the table *and* the target height is smaller than the
    // current height
    const padding = 2 * PADDING + containerPadding;
    const targetHeight = contentContainerRef.clientHeight + padding;
    if (
      contentContainerRef.clientHeight / divContainerRef.clientHeight <
        DOWNSIZE_CONTENT_PERCENTAGE &&
      targetHeight < divContainerRef.clientHeight
    ) {
      onContentSizeChange({
        width: unscaledWidth,
        height: targetHeight,
      });
      return;
    }
  }, [
    divContainerRef,
    contentContainerRef,
    shouldAutoSize,
    items,
    width,
    height,
    unscaledWidth,
    unscaledHeight,
    onContentSizeChange,
    containerPadding,
  ]);

  const scale = Math.min(width / unscaledWidth, height / unscaledHeight);
  return (
    <div
      style={{
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
      }}
    >
      <div
        ref={setDivContainerRef}
        style={{
          boxSizing: 'border-box',
          overflow: 'hidden',
          width: unscaledWidth,
          height: unscaledHeight,
          border: `1px solid ${Colors['border--muted']}`,
          padding: PADDING,
        }}
      >
        <TableCard>
          <TableContentWrapper ref={setContentContainerRef}>
            {title !== undefined && (
              <TableHeader>
                <Heading level={4}>{title}</Heading>
              </TableHeader>
            )}
            {items.map((item, index) => (
              <TableItemContent key={index} {...item} />
            ))}
          </TableContentWrapper>
        </TableCard>
      </div>
    </div>
  );
};

const TableCard = styled.div`
  user-select: none;
  width: 100%;
  height: 100%;
  background-color: ${TABLE_CONTENT_COLOR};
`;

const TableHeader = styled.div``;

const TableContentWrapper = styled.div`
  padding: 6px 0;
`;

export default TableContent;
