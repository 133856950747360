import { Body, Modal } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  // need to set overflow visible to make dropdown work inside modal
  overflow: visible !important;
`;

export const DateRangeHelpText = styled(Body).attrs({ size: 'x-small' })`
  font-size: 13px;
  color: ${({ theme }) => theme['text-icon--medium']};
`;
