import type {
  Checklist,
  ChecklistItem,
  ChecklistItemStatus,
  ChecklistStatus,
} from '@cognite/apm-client';
import { TemplateActivityTypes } from '@infield/features/activities';
import type { SortType } from '@infield/features/search';
import { getGroupName } from '@infield/features/template/utils';

import { checklistItemStatuses } from './constants';
import type {
  ChecklistGroup,
  ChecklistGroupWithStatuses,
  ChecklistItemAndGroupList,
  ChecklistItemStatusAggregation,
} from './types';

export const getChecklistItemsAndGroupsList = (tasks?: ChecklistItem[]) => {
  return (tasks || []).reduce((accumulatedList, task: ChecklistItem) => {
    const groupName = getGroupName(task.labels);
    const lastItem = accumulatedList[accumulatedList.length - 1];

    if (!groupName) {
      accumulatedList.push(task);
    } else if (
      lastItem &&
      'groupName' in lastItem &&
      lastItem.groupName === groupName
    ) {
      lastItem.tasks.push(task);
    } else {
      accumulatedList.push({ groupName, tasks: [task] });
    }

    return accumulatedList;
  }, [] as ChecklistItemAndGroupList);
};

export const getChecklistGroupsListWithStatuses = (
  checklistItemAndGroupList: ChecklistItemAndGroupList
) => {
  return (checklistItemAndGroupList || []).reduce(
    (accumulatedList, item: ChecklistItem | ChecklistGroup) => {
      if (!('groupName' in item)) {
        return accumulatedList;
      }

      const allItems = item.tasks;

      const completedItems = allItems.filter(
        (item) => item.status !== 'To do' && Boolean(item.status)
      );

      const itemsNotOk = completedItems?.some(
        (item) => item.status === 'Not ok' || item.status === 'Blocked'
      );

      const itemsOk = completedItems?.some(
        (item) => item.status === 'Ok' || item.status === 'Not applicable'
      );

      return [
        ...accumulatedList,
        {
          ...item,
          status: {
            total: allItems.length,
            completed: completedItems.length,
            notOk: itemsNotOk,
            ok: itemsOk,
          },
        },
      ];
    },
    [] as ChecklistGroupWithStatuses[]
  );
};

export const flattenChecklistItemAndGroups = (
  taskAndGroupList: ChecklistItemAndGroupList
) =>
  taskAndGroupList.flatMap((item) => {
    if ('groupName' in item) {
      return item.tasks;
    }
    return item;
  }) as ChecklistItem[];

export const getGroupStatusColor = (notOk: boolean, ok: boolean) => {
  if (notOk) {
    return 'red';
  }
  if (ok) {
    return 'green';
  }
  return undefined;
};

export const getGroupStatusLabel = (completed: number, total: number) => {
  if (completed === total) {
    return 'Done';
  }
  return `${completed}/${total}`;
};

export const getChecklistItemStatus = (status: string) => {
  if (checklistItemStatuses.includes(status as ChecklistItemStatus))
    return status as ChecklistItemStatus;
};

export const getChecklistStatusColor = (status?: ChecklistStatus) => {
  switch (status) {
    case 'In progress':
      return 'neutral';
    case 'Done':
      return 'success';
    default:
      return 'default';
  }
};

export const LAST_VISITED_CHECKLIST_ITEM_KEY = 'lastVisitedChecklistItem';

export const getChecklistItemsAggregatedStatus = (
  checklists: Checklist[] = []
) => {
  const checklistItems: ChecklistItem[] = checklists
    .map((checklist) => checklist.checklistItems || [])
    .flat();

  const completedItems = checklistItems?.filter(
    (item) => item.status && checklistItemStatuses.includes(item.status!)
  );

  const itemsNotOk = completedItems?.some(
    (item) => item.status === 'Not ok' || item.status === 'Blocked'
  );

  const itemsOk =
    completedItems?.length === checklistItems?.length && !itemsNotOk;

  const statusAggregation: ChecklistItemStatusAggregation = {
    total: checklistItems?.length ?? 0,
    completed: completedItems?.length ?? 0,
    notOk: itemsNotOk ?? false,
    ok: itemsOk ?? false,
  };

  return statusAggregation;
};

export const sortChecklistItems = (
  checklistItems: ChecklistItemAndGroupList,
  sortType: SortType,
  checklistType?: string
): ChecklistItemAndGroupList => {
  if (
    sortType === 'orderCreated' ||
    checklistType === TemplateActivityTypes.round
  ) {
    return checklistItems;
  }

  const items: ChecklistItem[] = [];
  const groups: ChecklistGroup[] = [];

  checklistItems.forEach((checklistItem) => {
    if ('groupName' in checklistItem && checklistItem.groupName) {
      groups.push(checklistItem);
    } else {
      items.push(checklistItem as ChecklistItem);
    }
  });

  const sortedItems = items.sort((prev, next) => {
    const prevName = prev.asset?.title || prev.asset?.description || '';
    const nextName = next.asset?.title || next.asset?.description || '';

    if (sortType === 'alphabetical') {
      if (prevName < nextName) {
        return -1;
      }
      if (prevName > nextName) {
        return 1;
      }
    }
    if (sortType === 'reverseAlphabetical') {
      if (prevName < nextName) {
        return 1;
      }
      if (prevName > nextName) {
        return -1;
      }
    }
    return 0;
  });

  return [...sortedItems, ...groups];
};
