import { Skeleton } from '@cognite/cogs.js-v10';
import type { Timeseries } from '@cognite/sdk';
import type { FC } from 'react';

import * as S from './elements';
import { TrendsListEmpty } from './trends-list-empty';
import { TrendsListItem } from './trends-list-item';

interface Props {
  isLoading: boolean;
  timeseries: Timeseries[];
  selectedTimeseriesExternalIds: string[];
  onToggleSelectedTimeseries: (tsExternalId: string) => void;
}
export const TrendsListPanelContent: FC<Props> = ({
  isLoading,
  timeseries,
  selectedTimeseriesExternalIds,
  onToggleSelectedTimeseries,
}) => {
  if (isLoading) return <Skeleton.List lines={3} borders />;

  if (timeseries.length === 0) return <TrendsListEmpty />;

  const isTimeseriesSelected = (timeseriesExternalId: string) =>
    selectedTimeseriesExternalIds.includes(timeseriesExternalId);

  return (
    <S.TrendList>
      {timeseries.map((ts) => (
        <li key={`ts-${ts.id}`} aria-label={ts.name}>
          <TrendsListItem
            timeseries={ts}
            selected={isTimeseriesSelected(ts.externalId!)}
            onToggleSelectedTimeseries={onToggleSelectedTimeseries}
          />
        </li>
      ))}
    </S.TrendList>
  );
};
