import { Button, ChevronRightIcon, Flex } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FunctionComponent, MouseEvent } from 'react';

import * as S from './elements';

interface Props {
  selectedDomain: string;
  live: boolean;
  onDomainClick: (val: string) => void;
  onLiveClick: (e: MouseEvent<HTMLElement>) => void;
}

export const TrendsDomainSelector: FunctionComponent<Props> = ({
  selectedDomain,
  live,
  onDomainClick,
  onLiveClick,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.trends);

  const buttonsArray = [
    { value: 'year', title: t('1Y', { defaultValue: '1Y' }) },
    { value: 'month', title: t('1M', { defaultValue: '1M' }) },
    { value: 'week', title: t('1W', { defaultValue: '1W' }) },
    { value: 'day', title: t('1D', { defaultValue: '1D' }) },
    { value: 'hour', title: t('1H', { defaultValue: '1H' }) },
  ];

  return (
    <S.Wrapper>
      <Flex direction="row" alignItems="center">
        <Flex direction="row">
          {buttonsArray.map((btn) => (
            <S.DomainButton
              key={btn.value}
              active={selectedDomain === btn.value}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDomainClick(btn.value);
              }}
            >
              {btn.title}
            </S.DomainButton>
          ))}
        </Flex>
        <Button
          type="primary"
          disabled={live}
          onClick={onLiveClick}
          icon={<ChevronRightIcon />}
          iconPlacement="right"
          aria-label={
            live
              ? t('toggleLiveTrendsOff', 'Toggle live trends off')
              : t('toggleLiveTrendsOn', 'Toggle live trends on')
          }
        >
          {t('TRENDS_DOMAIN_SELECTOR_LATEST', 'Latest')}
        </Button>
      </Flex>
    </S.Wrapper>
  );
};
