import { getDirectRelationship } from '@cognite/apm-client';
import type {
  APMClient,
  InFieldUser,
  Template,
  TemplateItem,
  TemplateItemUpsertType,
  TemplateResponseType,
  TemplateUpsertType,
} from '@cognite/apm-client';
import { FDMClient } from '@cognite/fdm-client';
import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { parseFilters } from '@cognite/fdm-client/src/utils/parse-filters';
import type { CogniteClient } from '@cognite/sdk';
import { gql } from '@infield/utils/graphql-request';

export class TemplateService extends FDMClient {
  apmClient: APMClient;
  modelSpace: string;
  modelName: string;
  modelVersion: string;
  instanceSpace: string;
  constructor(client: CogniteClient, apmClient: APMClient) {
    super(client);
    this.apmClient = apmClient;
    this.modelSpace = apmClient.appDataModelSpace;
    this.modelName = apmClient.appDataModelName;
    this.modelVersion = apmClient.appDataModelVersion;
    this.instanceSpace = apmClient.appDataInstanceSpace;
  }

  async getTemplates(
    filters?: Filters,
    sort?: Sort,
    pageSize = 1000,
    showArchived = false
  ): Promise<Template[]> {
    const {
      list: { items: templates },
    } = await this.apmClient.templates.list(
      `
      externalId
      title
      status
      assignedTo
      createdBy {
        externalId
        name
        email
      }
      updatedBy {
        externalId
        name
        email
      }
      isArchived
      `,
      { filters, sort, pageSize },
      showArchived
    );
    const returnedTemplates = templates as TemplateResponseType[];

    return returnedTemplates.map((template) => ({
      ...template,
      templateItems: template.templateItems?.items.map((templateItem) => {
        return {
          ...templateItem,
          measurements: templateItem.measurements?.items,
          schedules: templateItem.schedules?.items,
        };
      }),
    }));
  }

  async getTemplateData(
    templateFilter?: Filters,
    templateSort?: Sort,
    itemsFilter?: Filters,
    itemsSort?: Sort,
    pageSize = 1000
  ): Promise<Template> {
    const {
      listTemplate: {
        items: [template],
      },
    } = await super.graphQL<{
      listTemplate: {
        items: TemplateResponseType[];
      };
    }>(
      gql`
        query GetTemplateData(
          $templateFilter: _ListTemplateFilter
          $templateSort: [_TemplateSort!]
          $itemsFilter: _ListTemplateItemFilter
          $itemsSort: [_TemplateItemSort!]
          $pageSize: Int
        ) {
          listTemplate(filter: $templateFilter, sort: $templateSort) {
            items {
              externalId
              space
              title
              status
              assignedTo
              isArchived
              templateItems(
                filter: $itemsFilter
                sort: $itemsSort
                first: $pageSize
              ) {
                items {
                  externalId
                  space
                  title
                  description
                  order
                  labels
                  asset {
                    externalId
                    title
                    description
                    space
                  }
                  measurements(sort: { order: ASC }) {
                    items {
                      type
                      externalId
                      title
                      description
                      timeseries {
                        externalId
                        id
                        assetId
                        name
                        description
                        unit
                      }
                      measuredAt
                      min
                      max
                      numericReading
                      stringReading
                      order
                      options
                    }
                  }
                  schedules {
                    items {
                      externalId
                      status
                      startTime
                      endTime
                      timezone
                      freq
                      interval
                      until
                      byDay
                      byMonth
                    }
                  }
                }
              }
            }
          }
        }
      `,
      this.modelSpace,
      this.modelName,
      this.modelVersion,
      {
        templateFilter: parseFilters({
          and: [
            { ...templateFilter },
            {
              equals: {
                property: 'space',
                eq: this.instanceSpace,
              },
            },
          ],
        }),
        templateSort,
        itemsFilter: parseFilters({
          and: [
            { ...itemsFilter },
            {
              not: {
                equals: {
                  property: 'isArchived',
                  eq: true,
                },
              },
            },
          ],
        }),
        itemsSort,
        pageSize,
      }
    );
    return {
      ...template,
      templateItems: template.templateItems?.items.map((templateItem) => {
        return {
          ...templateItem,
          measurements: templateItem.measurements?.items,
          schedules: templateItem.schedules?.items,
        };
      }),
    };
  }

  async createTemplate(newTemplate: Template, user: InFieldUser) {
    if (!newTemplate.rootLocation || !newTemplate.rootLocation.space) {
      throw new Error(
        'Current location is missing root asset or app / source instance space'
      );
    }

    const template: Template = {
      ...newTemplate,
      createdBy: user,
    };

    return this.upsertTemplates([template]);
  }

  async updateTemplate(newTemplate: Template, user: InFieldUser) {
    const template: Template = {
      ...newTemplate,
      updatedBy: user,
    };

    return this.upsertTemplates([template]);
  }

  async upsertTemplates(newTemplates: Template[]) {
    const templates: TemplateUpsertType[] = newTemplates.map((template) => {
      if (template.rootLocation && !template.rootLocation.space) {
        throw new Error('Missing space information from the location');
      }

      return {
        ...template,
        updatedBy: template.updatedBy?.externalId
          ? {
              externalId: template.updatedBy?.externalId,
              space: this.apmClient.userInstanceSpace,
            }
          : undefined,
        createdBy: template.createdBy?.externalId
          ? {
              externalId: template.createdBy?.externalId,
              space: this.apmClient.userInstanceSpace,
            }
          : undefined,
        rootLocation:
          template.rootLocation && template.rootLocation.space
            ? {
                space: template.rootLocation.space,
                externalId: template.rootLocation.externalId,
              }
            : undefined,
      };
    });

    return this.apmClient.templates.upsert(templates);
  }

  async createTemplateItems(templateItems: TemplateItem[], user: InFieldUser) {
    const upsertedTemplateItems: TemplateItem[] = templateItems.map(
      (templateItem) => ({
        ...templateItem,
        createdBy: user,
      })
    );

    return this.upsertTemplateItems(upsertedTemplateItems);
  }

  async updateTemplateItems(templateItems: TemplateItem[], user: InFieldUser) {
    const upsertedTemplateItems: TemplateItem[] = templateItems.map(
      (templateItem) => ({
        ...templateItem,
        updatedBy: user,
      })
    );

    return this.upsertTemplateItems(upsertedTemplateItems);
  }

  async upsertTemplateItems(templateItems: TemplateItem[]) {
    const upsertedTemplateItems: TemplateItemUpsertType[] = templateItems.map(
      (templateItem) => {
        const assetRelationship = getDirectRelationship(templateItem.asset);

        return {
          ...templateItem,
          measurements: undefined,
          schedules: undefined,
          updatedBy: templateItem.updatedBy?.externalId
            ? {
                externalId: templateItem.updatedBy?.externalId,
                space: this.apmClient.userInstanceSpace,
              }
            : undefined,
          createdBy: templateItem.createdBy?.externalId
            ? {
                externalId: templateItem.createdBy?.externalId,
                space: this.apmClient.userInstanceSpace,
              }
            : undefined,
          asset: assetRelationship,
        };
      }
    );

    return this.apmClient.templateItems.upsert(upsertedTemplateItems);
  }

  async softDeleteTemplateItems(externalIds: string[]) {
    const templateItems = externalIds.map((externalId) => ({
      externalId,
      isArchived: true,
    }));
    await this.apmClient.templateItems.upsert(templateItems);
    return externalIds;
  }

  async deleteTemplateItem(externalIds: string[]) {
    return this.apmClient.templateItems.delete(externalIds);
  }

  async deleteTemplate(externalIds: string[]) {
    return this.apmClient.templates.delete(externalIds);
  }
}
