import { Modal } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { MediaViewer } from '../media-viewer/media-viewer';
import type { ImageToUpload } from '../types';

import * as S from './elements';

interface Props {
  visible: boolean;
  media: ImageToUpload;
  onClose: () => void;
}

export const MediaViewerDialogue: FC<Props> = ({ media, visible, onClose }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);

  return (
    <Modal
      visible={visible}
      size="small"
      title={t('MEDIA_VIEWER_DIALOGUE_MODAL_TITLE', 'Image')}
      onCancel={onClose}
      cancelText={t('MEDIA_VIEWER_DIALOGUE_MODAL_BUTTON_CANCEL', 'Cancel')}
      onOk={onClose}
      okText={t('MEDIA_VIEWER_DIALOGUE_MODAL_BUTTON_OK', 'OK')}
    >
      <S.Container>
        <MediaViewer
          key={`${media.externalId}-viewer`}
          media={media}
          showDescription={false}
          mediaHeight={300}
        />
      </S.Container>
    </Modal>
  );
};
