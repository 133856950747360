import { Skeleton } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useTrendsLatestDatapoint } from '@infield/features/trends';
import { useInterval } from '@infield/hooks/useInterval';
import { getLastUpdatedLabel } from '@infield/utils/humanizeDuration';
import { useState } from 'react';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  color: string;
  timeseriesExternalId: string;
}

export const TrendsListItemDatapoint: FC<Props> = ({
  color,
  timeseriesExternalId,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.trends);

  const { data: datapoint, isLoading: isLoadingDatapoint } =
    useTrendsLatestDatapoint(timeseriesExternalId);

  const [lastUpdated, setLastUpdated] = useState('');

  const updateLastUpdated = () => {
    if (!isLoadingDatapoint && datapoint) {
      const lastUpdatedTimeLabel = getLastUpdatedLabel(
        datapoint.timestamp,
        t('TRENDS_LIST_LAST_UPDATED_TIME_SECONDS_AGO', 'seconds ago')
      );
      setLastUpdated(lastUpdatedTimeLabel);
    }
  };

  useInterval(updateLastUpdated, 1000);

  const lastUpdatedFallback = t(
    'TRENDS_NO_LAST_UPDATED_INFORMATION',
    'No last updated information'
  );

  if (isLoadingDatapoint)
    return <Skeleton.Rectangle width="80px" height="30px" />;

  return (
    <>
      <S.Value $color={color}>
        {datapoint ? datapoint.value.toFixed(3) : t('noDataValue', 'No data')}
      </S.Value>
      <S.LastUpdated>
        {datapoint ? lastUpdated || lastUpdatedFallback : lastUpdatedFallback}
      </S.LastUpdated>
    </>
  );
};
