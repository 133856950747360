import type { APMAsset } from '@cognite/apm-client';
import { Infobox } from '@cognite/cogs.js-v10';
import type { Asset } from '@cognite/sdk';
import {
  Asset3d,
  ThreeDNavHintsContextProvider,
  useAssetModels,
} from '@infield/features/3d';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  assetData?: Asset | APMAsset;
  showSidebar?: boolean;
}

export const Asset3dView: FC<Props> = ({ assetData, showSidebar }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.threeD);

  const assetExternalId = assetData?.externalId;
  const assetId = assetData && 'id' in assetData ? assetData?.id : undefined;

  const { firstAvailableModel: activeModel } = useAssetModels(
    assetExternalId,
    assetId
  );

  let currentRevisionId = activeModel?.revisionId;
  let currentModelId = activeModel?.modelId;

  const [searchParams] = useSearchParams({
    modelId: '',
    revisionId: '',
  });

  const revisionId = Number(searchParams.get('revisionId'));
  const modelId = Number(searchParams.get('modelId'));
  if (revisionId && modelId) {
    currentRevisionId = revisionId;
    currentModelId = modelId;
  }

  if (!currentRevisionId || !currentModelId) {
    throw new Error('3d page requires revisionId and modelId to be in the URL');
  }

  if (!assetExternalId)
    return (
      <Infobox status="warning">
        {t('noValidAssetIdInURL', 'No valid assetId was passed in URL')}
      </Infobox>
    );
  return (
    <ThreeDNavHintsContextProvider>
      <Asset3d
        assetExternalId={assetExternalId}
        modelId={currentModelId}
        revisionId={currentRevisionId}
        showSidebar={showSidebar}
      />
    </ThreeDNavHintsContextProvider>
  );
};
