import React, { useEffect, useState } from 'react';

import { IRect } from 'konva/cmj/types';

import { Position } from '../../annotations/types';

type CanvasTooltipProps = {
  position: Position;
};

const mapXYToTopLeft = ({ x, y }: Position): { top: number; left: number } => ({
  top: y,
  left: x,
});

const CanvasTooltip: React.FC<React.PropsWithChildren<CanvasTooltipProps>> = ({
  position,
  children,
}) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [tooltipContainerRect, setTooltipContainerRect] = React.useState<
    IRect | undefined
  >(undefined);

  useEffect(() => {
    const ref = containerRef;
    if (ref === null) {
      return;
    }

    const parentContainerRect =
      containerRef?.parentElement?.getBoundingClientRect();
    if (parentContainerRect === undefined) {
      // eslint-disable-next-line no-console
      console.warn('Parent container was not set - this should never happen');
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setTooltipContainerRect(ref.getBoundingClientRect());
    });

    resizeObserver.observe(ref);

    const currentTooltipContainerRect = ref.getBoundingClientRect();
    setTooltipContainerRect(currentTooltipContainerRect);

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  const parentContainerRect =
    containerRef?.parentElement?.getBoundingClientRect();

  const tooltipPosition =
    tooltipContainerRect !== undefined && parentContainerRect !== undefined
      ? position
      : undefined;

  return (
    <div
      ref={setContainerRef}
      style={{
        position: 'absolute',
        pointerEvents: 'all',
        ...(tooltipPosition !== undefined && parentContainerRect !== undefined
          ? mapXYToTopLeft(tooltipPosition)
          : { visibility: 'hidden' }),
      }}
    >
      {children}
    </div>
  );
};

export default CanvasTooltip;
