import type { Measurement } from '@cognite/apm-client';
import { Flex, Input, Message } from '@cognite/cogs.js-v10';
import {
  isAboveMax,
  isBelowMin,
} from '@infield/features/checklist/checklist-item/utils';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';

import * as S from './elements';

interface Props {
  measurement: Measurement;
  readingInput?: string;
  isLocked: boolean;
  hasReadWritePermissions?: boolean;
  onReadingChange: () => void;
  setReadingInput: Dispatch<SetStateAction<string | undefined>>;
}

export const MeasurementNumericReading: FC<Props> = ({
  measurement,
  readingInput,
  isLocked,
  hasReadWritePermissions = false,
  onReadingChange,
  setReadingInput,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);

  const { timeseries } = measurement;

  const isInputBelowMin = isBelowMin(measurement, readingInput);
  const isInputAboveMax = isAboveMax(measurement, readingInput);
  const isInsideRange =
    readingInput !== '-' &&
    Boolean(readingInput) &&
    !isInputBelowMin &&
    !isInputAboveMax;
  const isOutsideRange =
    Boolean(readingInput) && (isInputBelowMin || isInputAboveMax);

  const handleChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.validity.valid) {
      setReadingInput(e.target.value);
    }
  };

  return (
    <Flex direction="column" gap={4}>
      {Boolean(timeseries?.description) && (
        <S.ReadingInputLabel size="small">
          {timeseries?.description}
        </S.ReadingInputLabel>
      )}
      <S.ReadingInputContainer
        $isSuccess={isInsideRange}
        $isError={isOutsideRange}
        $isDisabled={isLocked || !hasReadWritePermissions}
        onBlur={() => onReadingChange()}
        onSubmit={(event) => {
          event.preventDefault();
          onReadingChange();
        }}
      >
        <Input
          fullWidth
          data-testid="measurement-numeric-reading-input"
          type="text"
          disabled={isLocked || !hasReadWritePermissions}
          variant="ghost"
          pattern="^-?\d*\.?\d*$"
          onChange={(e) => handleChangeInputValue(e)}
          value={readingInput ?? ''}
          suffix={measurement.timeseries?.unit}
        />
      </S.ReadingInputContainer>

      <Flex justifyContent="space-between">
        <S.RangeText $isError={isInputBelowMin}>
          {`${t('CHECKLIST_NUMERICAL_READING_RANGE_MIN', 'min.')} ${
            measurement.min ?? '-'
          }`}
        </S.RangeText>
        <S.RangeText $isError={isInputAboveMax}>
          {`${t('CHECKLIST_NUMERICAL_READING_RANGE_MAX', 'max.')} ${
            measurement.max ?? '-'
          }`}
        </S.RangeText>
      </Flex>
      {!hasReadWritePermissions && (
        <Message
          text={t(
            'CHECKLIST_NUMERICAL_READING_NO_ACCESS',
            'Access missing. Save data in notes and ask admin for help.'
          )}
          data-testid="measurement-numeric-reading-no-access"
        />
      )}
    </Flex>
  );
};
