import {DocumentCloner} from '../dom/document-cloner';

export class CachedDocumentCloner {
    constructor(
        private documentCloner: DocumentCloner,
        private element: HTMLElement,
        private containerWindow: Window,
        private referenceSelector: string
    ) {}

    private waitForImageLoad(image: HTMLImageElement): Promise<boolean> {
        return new Promise<boolean>((res) => {
            if (image.complete) {
                res(true);
                return;
            }
            if (image.src.indexOf('data:') === 0) {
                res(false);
                return;
            }
            image.addEventListener('load', () => res(true));
            image.addEventListener('error', () => res(false));
        });
    }

    private waitForLoad(clonedElement: HTMLElement): Promise<void> {
        return new Promise<void>(async (res) => {
            const images = Array.from(clonedElement.querySelectorAll('img'));
            await Promise.all(images.map(this.waitForImageLoad));
            res();
        });
    }

    public async clone(): Promise<HTMLElement | null> {
        const clonedReferenceNode = this.documentCloner.cloneNode(this.element, true) as HTMLElement;
        const containerReferenceElement = this.containerWindow.document.querySelector(this.referenceSelector);
        if (containerReferenceElement === null) {
            return null;
        }
        if (!containerReferenceElement.isSameNode(this.element)) {
            containerReferenceElement.replaceWith(clonedReferenceNode);
        }
        await this.waitForLoad(clonedReferenceNode);
        return clonedReferenceNode;
    }
}
