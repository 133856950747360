import { Body, Flex, Input } from '@cognite/cogs.js-v10';
import { getIsNewConfigVersion } from '@infield/features/app-config/utils/utils';
import { ComponentContainer } from '@infield/features/ui';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { useAppConfigQuery } from '../../../hooks';
import { useUpsertAppConfigMutation } from '../../../hooks/use-mutation';
import type { AppConfig } from '../../../types';
import * as S from '../elements';

interface Props {
  hasAppConfigPermission: boolean;
  rootLocationExternalId: string;
}

export const RootLocationConfigDataset: FC<Props> = ({
  hasAppConfigPermission,
  rootLocationExternalId,
}) => {
  const [datasetId, setDatasetId] = useState<number>();

  const { data: appConfig, isSuccess, isLoading } = useAppConfigQuery();
  const isNewConfigVersion = getIsNewConfigVersion(appConfig);
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();
  const configuredRootLocation =
    appConfig?.featureConfiguration?.rootLocationConfigurations?.find(
      (rootLocation) =>
        rootLocationExternalId ===
        (isNewConfigVersion
          ? rootLocation.externalId
          : rootLocation.assetExternalId)
    );

  useEffect(() => {
    if (isSuccess && configuredRootLocation) {
      if (configuredRootLocation.dataSetId) {
        setDatasetId(configuredRootLocation.dataSetId);
      }
    }
  }, [isSuccess, configuredRootLocation, appConfig]);

  const hasConfigChanged =
    datasetId !== (configuredRootLocation?.dataSetId ?? '');

  const handleSave = async () => {
    if (!appConfig) return;

    const newAppConfig: AppConfig = {
      ...appConfig,
      featureConfiguration: {
        ...appConfig.featureConfiguration,
        rootLocationConfigurations: [
          ...(appConfig.featureConfiguration?.rootLocationConfigurations?.map(
            (rootConfig) => {
              if (
                isNewConfigVersion
                  ? configuredRootLocation?.externalId === rootConfig.externalId
                  : configuredRootLocation?.assetExternalId ===
                    rootConfig.assetExternalId
              ) {
                return {
                  ...rootConfig,
                  dataSetId: datasetId,
                };
              }
              return { ...rootConfig };
            }
          ) || []),
        ],
      },
    };

    await upsertAppConfig({
      newAppConfig,
    });
  };

  const documentCardConfigs = (
    <Flex direction="column" gap={8}>
      <Body size="x-small">
        Timeseries that is created from InField will get a metadata field
        (source=APP) to identify its origin. You can additionally add a dataset
        you want these timeseries to be stored in.
      </Body>
      <Flex gap={16}>
        <Flex direction="column" gap={8}>
          <Body size="x-small" strong>
            Dataset id (internal id) for InField
          </Body>
          <Input
            disabled={!hasAppConfigPermission}
            value={datasetId !== undefined ? datasetId : ''}
            type="number"
            onChange={(e) =>
              setDatasetId(
                !Number.isNaN(e.target.valueAsNumber)
                  ? e.target.valueAsNumber
                  : undefined
              )
            }
          />
          <Body size="x-small">
            The dataset that will hold the files and timeseries created from
            InField.
          </Body>
        </Flex>
      </Flex>
    </Flex>
  );

  const saveButton = (
    <Flex direction="column" gap={4}>
      <S.SaveButton
        onClick={handleSave}
        disabled={!hasConfigChanged}
        loading={isLoading}
      >
        Save configuration
      </S.SaveButton>
    </Flex>
  );

  return (
    <ComponentContainer>
      <S.Wrapper>
        <Flex direction="column" gap={16}>
          {documentCardConfigs}
          {saveButton}
        </Flex>
      </S.Wrapper>
    </ComponentContainer>
  );
};
