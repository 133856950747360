import type { FDMClient } from '@cognite/fdm-client';
import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { gql } from '@cognite/fdm-client/src/utils/graphql-request';
import { parseFilters } from '@cognite/fdm-client/src/utils/parse-filters';
import type { NodeDefinition } from '@cognite/sdk';

import type { ServiceContract } from './types';
import { toDMSFilter } from './utils';
import { ViewCache } from './view-cache';

export type APMAppDataServiceOptions = {
  ignoreArchivedByDefault?: boolean;
};

export class APMAppDataService<T> {
  modelSpace: string;
  modelName: string;
  modelVersion: string;
  instanceSpace: string;
  view: string;
  viewVersion: string;
  fdmClient: FDMClient;
  options?: APMAppDataServiceOptions;
  constructor(
    contract: ServiceContract,
    view: string,
    viewVersion: string,
    options?: APMAppDataServiceOptions
  ) {
    this.modelSpace = contract.modelSpace;
    this.modelName = contract.modelName;
    this.modelVersion = contract.modelVersion;
    this.instanceSpace = contract.instanceSpace;
    this.view = view;
    this.viewVersion = viewVersion;
    this.fdmClient = contract.fdmClient;
    this.options = options;
  }

  /**
   * List items from graphql.
   * *Filters out any items that have a mutation*
   * @example apm.activities.list(gql`
   *    id
   *    name
   *    assignedTo {
   *        id
   *    }
   *  `, { filters: {}, sort: [], nextCursor: '', pageSize: '' })
   * @param fields GraphQL fields to fetch for this query
   * @param variables Optional variables, filtering, sorting, and cursoring
   * @returns List of Items
   */
  list(
    fields: string,
    variables?: {
      nextCursor?: string;
      pageSize?: number;
      filters?: Filters;
      sort?: Sort;
    },
    showArchived = false
  ) {
    const graphql = gql`query List($filters:_List${this.view}Filter, $sort:[_${this.view}Sort!], $nextCursor:String, $pageSize:Int) {
        list: list${this.view}(filter:$filters, sort:$sort, after:$nextCursor, first: $pageSize) {
          items {
            ${fields}
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }`;

    const filters: Filters = {
      and: [
        ...(variables?.filters ? [{ ...variables.filters }] : []),
        {
          equals: {
            property: 'space',
            eq: this.instanceSpace,
          },
        },
      ],
    };

    if (this.options?.ignoreArchivedByDefault && !showArchived) {
      filters.and!.push({
        not: {
          equals: {
            property: 'isArchived',
            eq: true,
          },
        },
      });
    }

    return this.fdmClient.graphQL<{
      list: {
        items: T[];
        pageInfo: { endCursor: string; hasNextPage: boolean };
      };
    }>(graphql, this.modelSpace, this.modelName, this.modelVersion, {
      ...variables,
      filters: parseFilters(filters),
    });
  }

  /**
   * Upserting nodes.
   * Nodes without an external ID will be given one
   * @param nodes -
   * @param userId - user ID of who is making the changes
   * @returns Result of query
   */
  async upsert<T extends { externalId: string }>(nodes: T[]) {
    return this.fdmClient.upsertNodes<T>(
      nodes,
      this.instanceSpace,
      this.modelSpace,
      this.view,
      this.viewVersion
    );
  }

  aggregate<T>(variables: {
    groupBy?: string[];
    filters?: Filters;
    query?: string;
  }) {
    const graphql = gql`query FilteredAggregateCount(
        $filters: _Search${this.view}Filter
        $groupBy: [_Search${this.view}Fields!]
        $query: String
      ) {
        list: aggregate${this.view}(
          filter: $filters
          groupBy: $groupBy
          query: $query
        ) {
          items {
            group
            count {
              externalId
            }
          }
        }
      }`;

    const filters: Filters = {
      and: [
        ...(variables?.filters ? [{ ...variables.filters }] : []),
        {
          equals: {
            property: 'space',
            eq: this.instanceSpace,
          },
        },
      ],
    };

    return this.fdmClient.graphQL<T>(
      graphql,
      this.modelSpace,
      this.modelName,
      this.modelVersion,
      {
        ...variables,
        filters: parseFilters(filters),
      }
    );
  }

  async search(
    query: string,
    fields: string,
    variables: {
      pageSize?: number;
      filters?: Filters;
    }
  ) {
    const graphql = gql`
        query Search($filters:_Search${this.view}Filter, $pageSize:Int) {
          search: search${this.view}(query: "${query}", filter:$filters, first: $pageSize) {
            items {
              ${fields}
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      `;
    const filters: Filters = {
      and: [
        ...(variables?.filters ? [{ ...variables.filters }] : []),
        {
          equals: {
            property: 'space',
            eq: this.instanceSpace,
          },
        },
      ],
    };

    return this.fdmClient.graphQL<{
      search: {
        items: T[];
        pageInfo: { endCursor: string; hasNextPage: boolean };
      };
    }>(graphql, this.modelSpace, this.modelName, this.modelVersion, {
      ...variables,
      filters: parseFilters(filters),
    });
  }

  /**
   * Deletes items based on external ID.
   * Will fail if any items do not have their source set to 'APP'
   * @example
   * `APM.activities.delete(["Activity_1", "Activity_2"])`
   * @param externalIds External IDs to delete
   * @returns
   */
  async delete(externalIds: string[]) {
    return this.fdmClient.deleteNodes(externalIds, this.instanceSpace);
  }

  /**
   * Get a single item by external ID
   * @param externalId External ID to fetch
   * @param fields GraphQL fields to fetch for this query
   * @returns Item
   */
  async byid(externalId: string, fields: string) {
    const graphql = gql`query GetById {
      list: get${this.view}ById(instance: {space: "${this.instanceSpace}", externalId: "${externalId}"}) {
        ${fields}
      }
    }`;
    return this.fdmClient.graphQL<{
      list: {
        items: T[];
        edges: { node: T }[];
        pageInfo: { endCursor: string; hasNextPage: boolean };
      };
    }>(graphql, this.modelSpace, this.modelName, this.modelVersion, {});
  }

  async dmsSearch<ReturnType>(
    query?: string,
    instanceType?: 'node',
    filter?: Filters,
    limit?: number,
    properties?: string[],
    showArchived = false
  ) {
    const filters: Filters = {
      and: [
        ...(filter ? [{ ...filter }] : []),
        {
          equals: {
            property: 'space',
            eq: this.instanceSpace,
          },
        },
      ],
    };

    const view = {
      externalId: this.view,
      version: this.viewVersion,
      space: this.modelSpace,
    };

    const viewDefinition = await this.fdmClient.cogniteClient.views
      .retrieve([view])
      .then((x) => x.items[0]);

    if (this.options?.ignoreArchivedByDefault && !showArchived) {
      filters.and!.push({
        not: {
          equals: {
            property: 'isArchived',
            eq: true,
          },
        },
      });
    }

    const dmsFilters = await toDMSFilter(
      filters,
      viewDefinition,
      new ViewCache(this.fdmClient.cogniteClient)
    );

    const nodesOrEdges = await this.fdmClient.search({
      view: { ...view, type: 'view' },
      query,
      instanceType,
      filter: filters ? dmsFilters : undefined,
      limit,
      properties,
    });

    return nodesOrEdges.map((nodeOrEdge) => {
      const node = nodeOrEdge as NodeDefinition;
      return {
        ...node.properties?.[this.modelSpace][
          `${this.view}/${this.viewVersion}`
        ],
        externalId: node.externalId,
      } as ReturnType;
    });
  }
}
