import type { Group } from '../types';

export const extractSpaceIdsRead = (groups: Group[]): string[] => {
  const spaceIds: string[] = [];

  groups.forEach((group) => {
    if (!group.isDeleted && group.capabilities) {
      group.capabilities.forEach((capability) => {
        if (
          capability.dataModelInstancesAcl?.scope &&
          capability.dataModelInstancesAcl.actions
        ) {
          const { spaceIdScope } = capability.dataModelInstancesAcl.scope;
          const { actions } = capability.dataModelInstancesAcl;
          if (
            spaceIdScope?.spaceIds &&
            spaceIdScope.spaceIds.length > 0 &&
            actions.includes('READ')
          ) {
            spaceIds.push(...spaceIdScope.spaceIds);
          }
        }
      });
    }
  });

  return [...new Set(spaceIds)];
};

export const extractSpaceIdsWrite = (groups: Group[]): string[] => {
  const spaceIds: string[] = [];

  groups.forEach((group) => {
    if (!group.isDeleted && group.capabilities) {
      group.capabilities.forEach((capability) => {
        if (
          capability.dataModelInstancesAcl?.scope &&
          capability.dataModelInstancesAcl.actions
        ) {
          const { spaceIdScope } = capability.dataModelInstancesAcl.scope;
          const { actions } = capability.dataModelInstancesAcl;
          if (
            spaceIdScope?.spaceIds &&
            spaceIdScope.spaceIds.length > 0 &&
            actions.includes('WRITE')
          ) {
            spaceIds.push(...spaceIdScope.spaceIds);
          }
        }
      });
    }
  });

  return [...new Set(spaceIds)];
};

export const hasGroupWithScopeAllRead = (groups: Group[]): boolean => {
  return groups.some((group) => {
    return (
      !group.isDeleted &&
      group.capabilities &&
      group.capabilities.some((capability) => {
        return (
          capability.dataModelInstancesAcl?.scope?.all &&
          capability.dataModelInstancesAcl.actions?.includes('READ')
        );
      })
    );
  });
};
