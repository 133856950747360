import type { ChecklistItemStatus } from '@cognite/apm-client';
import { useChecklistItemStatusTranslation } from '@infield/features/checklist/hooks';
import type { FC } from 'react';

import { getChecklistItemStatus } from '../utils';

import * as S from './elements';

interface Props {
  disabled: boolean;
  status: string;
  onStatusClick: (status: ChecklistItemStatus) => void;
}

export const ChecklistStatusButtons: FC<Props> = ({
  disabled,
  status,
  onStatusClick,
}) => {
  const translatedStatuses = useChecklistItemStatusTranslation();

  const activeStatus = getChecklistItemStatus(status);
  const renderStatus = activeStatus ?? 'Ok';
  const isActive = activeStatus === renderStatus ? 'active' : 'inactive';

  return (
    <S.StatusButton
      data-testid={`checklist-item-status-button-${renderStatus}-${isActive}`}
      disabled={disabled}
      $activeStatus={activeStatus}
      onClick={() => onStatusClick(renderStatus)}
    >
      {translatedStatuses[renderStatus]}
    </S.StatusButton>
  );
};
