import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import type { Filters } from '@cognite/fdm-client';
import { useSelectedRootAsset } from '@infield/features/asset';
import { getOptionLabel } from '@infield/pages/observation/observations-overview/observations-data-grid/utils/getObservationColumns';
import { useFDMServices } from '@infield/providers/fdm-services';
import { limitConcurrency } from '@infield/utils/limit-concurrency';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

import { useObservationFields } from './use-observation-fields';

export const useObservationsAggregateFilterOptions = (
  fields: string[],
  filter?: Filters
) => {
  const { observationService, apmClient } = useFDMServices();
  const { observationFields } = useObservationFields();

  const { data: rootAsset } = useSelectedRootAsset();

  const filters: Filters[] = [
    {
      not: { equals: { property: 'isArchived', eq: true } },
    },
  ];
  if (rootAsset?.externalId) {
    filters.push({
      directRelationFilter: {
        rootLocation: {
          equals: {
            property: 'externalId',
            eq: rootAsset.externalId,
          },
        },
      },
      directRelationInstanceSpace: apmClient.sourceDataInstanceSpace,
    });
  }
  if (filter) {
    filters.push(filter);
  }

  return useQuery<Record<string, GridFilterOptionsType[]>>(
    [
      QueryKeys.OBSERVATION_AGGREGATE_FILTER_OPTIONS,
      filters,
      fields,
      observationFields,
    ],
    async () => {
      const results = await limitConcurrency(
        fields.map((field) => {
          return () =>
            observationService.aggregateObservationByField(field, {
              and: filters,
            });
        })
      );

      return results.reduce((acc, curr, index) => {
        return {
          ...acc,
          [fields[index]]: curr.map((item) => ({
            label:
              getOptionLabel(fields[index], item.value, observationFields) ||
              item.value,
            value: item.value,
            count: item.count,
          })),
        };
      }, {} as Record<string, any>);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: fields.length > 0 && Boolean(rootAsset?.externalId),
    }
  );
};
