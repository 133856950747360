import dayjs from 'dayjs';

const getFormattedDate = (date: Date) => {
  return dayjs(date).format('DD.MM.YYYY');
};

const getFormattedTime = (date: Date) => {
  return dayjs(date).format('HH:mm:ss');
};

const getFormattedTimezone = (date: Date) => {
  const offset = date.getTimezoneOffset();

  const sign = offset < 0 ? '+' : '-';

  const offsetValue = Math.abs(offset);
  const offsetHours = String((offsetValue / 60) | 0).padStart(2, '0');
  const offsetMinutes = String(offsetValue % 60).padStart(2, '0');

  const formattedOffsetValue = `${offsetHours}:${offsetMinutes}`;

  return `${formattedOffsetValue} GMT(${sign}${formattedOffsetValue})`;
};

const getFormattedDateWithTimezone = (timestamp: Date): string => {
  const date = getFormattedDate(timestamp);
  const time = getFormattedTime(timestamp);
  const timezone = getFormattedTimezone(timestamp);

  return `${date}, ${time} ${timezone}`;
};

export default getFormattedDateWithTimezone;
