import type {
  ChecklistItemStatus,
  CustomReadingLabel,
  CustomReadingLabelColor,
} from '@cognite/apm-client';
import { Body, DeleteIcon, Divider, Flex } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useOnClickOutside } from '@infield/features/ui';
import type { FC } from 'react';
import { useRef, useState } from 'react';

import * as S from './elements';
import { MeasurementLabelColorSelector } from './measurement-label-color-selector';
import { MeasurementLabelStatusSelector } from './measurement-label-status-selector';

type Props = {
  customReadingLabel: CustomReadingLabel;
  onUpdate: (measurementOption: CustomReadingLabel) => void;
  onDelete: (measurementOption: CustomReadingLabel) => void;
  isValidName: (id?: string, label?: string) => boolean;
};
export const MeasurementLabelButtonConfiguration: FC<Props> = ({
  customReadingLabel,
  onUpdate,
  isValidName,
  onDelete,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);
  const [label, setLabel] = useState<string>(customReadingLabel.label || '');

  const handleSelectColor = (color?: CustomReadingLabelColor) => {
    const customReadingLabelChanges = {
      ...customReadingLabel,
      color,
    };

    onUpdate(customReadingLabelChanges);
  };

  const handleSetStatus = (status?: ChecklistItemStatus) => {
    onUpdate({ ...customReadingLabel, status });
  };

  const labelInputRef = useRef<HTMLInputElement>(null);

  const handleOnClickOutside = (event: MouseEvent | TouchEvent) => {
    if (labelInputRef.current) {
      labelInputRef.current.blur();
      event.stopPropagation();
    }
  };

  useOnClickOutside(labelInputRef, handleOnClickOutside);

  const handleNameOnBlur = () => {
    if (
      isValidName(customReadingLabel.id, label) &&
      label !== customReadingLabel.label
    ) {
      onUpdate({ ...customReadingLabel, label });
    }
  };

  const isValidInput = isValidName(customReadingLabel.id, label);

  return (
    <Flex direction="column" gap={12}>
      <Flex gap={8} justifyContent="space-between">
        <S.InputWrapper direction="column" gap={6}>
          <Body size="medium" strong>
            {t(
              'TEMPLATE_TASK_FORM_CUSTOM_READING_LABEL_EDIT_INPUT_LABEL',
              'Button name'
            )}
          </Body>
          <Flex gap={6}>
            <S.LabelInput
              status={!isValidInput ? 'critical' : undefined}
              statusText={
                !isValidInput
                  ? t(
                      'TEMPLATE_TASK_FORM_CUSTOM_READING_LABEL_EDIT_INPUT_INVALID_INPUT_ERROR',
                      'Button name already exists'
                    )
                  : ''
              }
              data-testid="task-form-label-reading-configuration-label-input"
              ref={labelInputRef}
              fullWidth
              onBlur={handleNameOnBlur}
              onChange={(e) => setLabel(e.target.value)}
              clearable
              value={label}
              placeholder={t(
                'TEMPLATE_TASK_FORM_CUSTOM_READING_LABEL_EDIT_INPUT',
                'Custom label'
              )}
            />
            <S.DeleteButton
              data-testid="task-form-label-reading-configuration-remove-option-button"
              type="tertiary"
              icon={<DeleteIcon />}
              onClick={() => onDelete(customReadingLabel)}
              aria-label="Delete"
            />
          </Flex>
        </S.InputWrapper>
      </Flex>

      <Flex gap={8} alignItems="end" justifyContent="space-between">
        <S.HalfWidthWrapper direction="column" gap={6}>
          <Body size="medium" strong>
            {t(
              'TEMPLATE_TASK_FORM_CUSTOM_READING_COLOR_EDIT_INPUT_LABEL',
              'Color label'
            )}
          </Body>
          <MeasurementLabelColorSelector
            customReadingLabel={customReadingLabel}
            onSelectColor={handleSelectColor}
          />
        </S.HalfWidthWrapper>
        <S.HalfWidthWrapper direction="column" gap={6}>
          <Body size="medium" strong>
            {t(
              'TEMPLATE_TASK_FORM_CUSTOM_READING_STATUS_EDIT_INPUT_LABEL',
              'Button status'
            )}
          </Body>
          <MeasurementLabelStatusSelector
            onSelectStatus={handleSetStatus}
            customReadingStatus={customReadingLabel.status}
          />
        </S.HalfWidthWrapper>
      </Flex>
      <Divider />
    </Flex>
  );
};
