import { VIEW_VERSIONS } from '@cognite/apm-client';

import type { APMAppDataServiceOptions } from '../apm-app-data-service';
import { APMAppDataService } from '../apm-app-data-service';
import type { ServiceContract } from '../types';

import type { Condition } from './types';

export class ConditionsService extends APMAppDataService<Condition> {
  constructor(contract: ServiceContract, options: APMAppDataServiceOptions) {
    super(contract, 'Condition', VIEW_VERSIONS.CONDITION, options);
  }
}
