import { AnnotationType } from './annotations/types';
import { UnifiedViewerNode } from './types';

const getUnifiedViewerNodeMinPosition = (
  node: UnifiedViewerNode
): { x: number; y: number } => {
  if (node.type === AnnotationType.POLYLINE) {
    if (node.vertices !== undefined) {
      return node.vertices.reduce(
        (minPosition, vertex) => ({
          x: Math.min(minPosition.x, vertex.x),
          y: Math.min(minPosition.y, vertex.y),
        }),
        { x: Infinity, y: Infinity }
      );
    }
    // TODO: Fix this, for connections
    return {
      x: 0,
      y: 0,
    };
  }

  return {
    x: node.x ?? 0,
    y: node.y ?? 0,
  };
};

export default getUnifiedViewerNodeMinPosition;
