import {
  Chart,
  ChartTimeSeries,
  ChartWorkflow,
  CoreTimeseries,
  ScheduledCalculation,
} from '@cognite/charts-lib';

export const updateToggleVisibility = (
  chart: Chart,
  sourceId: string
): Chart => {
  const toggleVisibility = <
    T extends
      | ChartTimeSeries
      | ChartWorkflow
      | CoreTimeseries
      | ScheduledCalculation
  >(
    collection: T[] | undefined
  ): T[] | undefined =>
    collection?.map((item) =>
      item.id === sourceId ? { ...item, enabled: !item.enabled } : item
    );
  return {
    ...chart,
    timeSeriesCollection: toggleVisibility(chart.timeSeriesCollection),
    coreTimeseriesCollection: toggleVisibility(chart.coreTimeseriesCollection),
    workflowCollection: toggleVisibility(chart.workflowCollection),
    scheduledCalculationCollection: toggleVisibility(
      chart.scheduledCalculationCollection
    ),
  };
};
