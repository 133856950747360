import type { Domain } from '@infield/features/trends/trends-chart/types';
import dayjs from 'dayjs';

const CUSTOM_MARGIN = 32;

export const getPeriodFromXSubDomain = (xSubDomain: Domain | null) => {
  if (!xSubDomain) return 'month';

  const xSubDomainLength = xSubDomain[1] - xSubDomain[0];
  if (xSubDomainLength >= dayjs.duration(1, 'year').asMilliseconds()) {
    return 'year';
  }
  if (xSubDomainLength >= dayjs.duration(1, 'month').asMilliseconds()) {
    return 'month';
  }
  if (xSubDomainLength >= dayjs.duration(1, 'week').asMilliseconds()) {
    return 'week';
  }
  if (xSubDomainLength >= dayjs.duration(1, 'day').asMilliseconds()) {
    return 'day';
  }
  return 'hour';
};

// limit the domain end such that 32px of space are between 'now' and the domain end
const getDomainEndLimit = (domainLength: number, chartWidth: number) => {
  return Math.floor(Date.now() + (CUSTOM_MARGIN * domainLength) / chartWidth);
};

export const getLimitedDomain = (
  domainLength: number,
  chartWidth: number,
  domainEnd: number | null = null
): Domain => {
  const minDomainLength = 5;
  const newDomainLength =
    domainLength < minDomainLength ? minDomainLength : domainLength;

  const domainEndLimit = getDomainEndLimit(newDomainLength, chartWidth);

  const limitedDomainEnd = domainEnd
    ? Math.min(domainEnd, domainEndLimit)
    : domainEndLimit;
  const domainStart = limitedDomainEnd - newDomainLength;

  return [domainStart, limitedDomainEnd];
};
