import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
})`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const StepContentWrapper = styled(Flex).attrs({
  direction: 'column',
  gap: 12,
})`
  width: 100%;
  max-height: 100%;
  max-width: 370px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme['border--muted']};
  background-color: ${({ theme }) => theme['surface--medium']};
`;

export const RequiredLabel = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme['text-icon--status-critical']};
`;

export const MediaContentWrapper = styled(Flex).attrs({
  direction: 'column',
  gap: 8,
})`
  width: 100%;
  max-width: 370px;
`;
