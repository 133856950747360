import React, { PropsWithChildren, useContext } from 'react';

import { CogniteClientAlpha } from '@cognite/sdk-alpha';

const SdkAlphaContext = React.createContext<CogniteClientAlpha | null>(null);
SdkAlphaContext.displayName = 'CogniteAlphaSdkProvider';

type Props = { alphaClient: CogniteClientAlpha };
export function AlphaSDKProvider({
  children,
  alphaClient,
}: PropsWithChildren<Props>) {
  return (
    <SdkAlphaContext.Provider value={alphaClient}>
      {children}
    </SdkAlphaContext.Provider>
  );
}

export const useAlphaSDK = () => {
  const sdk = useContext(SdkAlphaContext);
  if (!sdk) {
    throw new Error(
      `SdkAlphaContext not found, add '<SdkAlphaContext value={sdk}>' around your component/app`
    );
  }
  return sdk;
};
