import type { UserPreferenceLocation } from '@cognite/apm-client';
import {
  useCurrentUserQuery,
  useUpsertCurrentUserPreferences,
} from '@infield/features/user';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import { selectedRootLocationAtom } from '../state';
import { getIsNewConfigVersion, SELECTED_ROOT_LOCATION_KEY } from '../utils';

import { useSelectableLocations } from './use-selectable-locations';
import { getSelectableIdmLocationOptions } from './utils/get-selectable-idm-location-options';
import { getSelectableLocationOptions } from './utils/get-selectable-location-options';

export interface LocationOption {
  label: string;
  value: UserPreferenceLocation;
}

export const useLocations = () => {
  const { appConfig, isIdm, idmLocations } = useAppConfigContext();
  const {
    data: selectableNonIdmLocations,
    isLoading: isSelectableLocationsLoading,
  } = useSelectableLocations();
  const selectableIdmLocations = getSelectableIdmLocationOptions(idmLocations);

  const selectableLocations = isIdm
    ? selectableIdmLocations
    : selectableNonIdmLocations;

  const { data: userData } = useCurrentUserQuery();

  const { mutateAsync: upsertUserPreferences } =
    useUpsertCurrentUserPreferences();

  const isNewConfigVersion = getIsNewConfigVersion(appConfig);

  const configuredRootLocationsOptions = useMemo(() => {
    return getSelectableLocationOptions(
      isIdm,
      selectableLocations,
      appConfig,
      isNewConfigVersion
    );
  }, [appConfig, isIdm, isNewConfigVersion, selectableLocations]);

  const userLocationPreference = userData?.preferences?.infield?.location;

  const selectedRootLocation = configuredRootLocationsOptions.find(
    ({ value }) =>
      value.externalId === userLocationPreference?.externalId ||
      value.externalId === userLocationPreference?.assetExternalId
  );

  const setSelectedRootLocation = useSetRecoilState(selectedRootLocationAtom);

  const updateLocationInLocalStorage = (location?: UserPreferenceLocation) => {
    const locationAssetExternalId = isNewConfigVersion
      ? location?.externalId
      : location?.assetExternalId || location?.externalId;
    if (!locationAssetExternalId) {
      localStorage.removeItem(SELECTED_ROOT_LOCATION_KEY);
      return;
    }
    localStorage.setItem(SELECTED_ROOT_LOCATION_KEY, locationAssetExternalId);
  };

  const handleSaveLocation = async (location?: UserPreferenceLocation) => {
    await upsertUserPreferences(
      {
        preference: 'location',
        data: location,
      },
      {
        onSuccess: () => {
          updateLocationInLocalStorage(location);
          setSelectedRootLocation(location);
        },
      }
    );
  };

  return {
    selectableLocations,
    isSelectableLocationsLoading,
    configuredRootLocationsOptions,
    selectedRootLocation,
    handleSaveLocation,
    updateLocationInLocalStorage,
  };
};
