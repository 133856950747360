import type { CogniteClient, SearchRequestV3 } from '@cognite/sdk';

import type { Edge, IntrospectionQueryField } from '../types';
import { gql } from '../utils/graphql-request';

import type { FDMEdge, FDMError } from './types';

/**
 * This class can be used for interactions with CDF
 * You can create your own class that extends from this one
 * and add methods that handle data that is specific to your application.
 */
export class FDMClient {
  private DMS_HEADERS: Record<string, string>;

  protected BASE_URL: string;
  protected BASE_URL_DMS: string;
  public cogniteClient: CogniteClient;

  constructor(client: CogniteClient) {
    this.cogniteClient = client;

    this.BASE_URL = `${this.cogniteClient.getBaseUrl()}/api/v1/projects/${
      this.cogniteClient.project
    }`;
    this.BASE_URL_DMS = `${this.BASE_URL}/models/instances`;

    this.DMS_HEADERS = {
      'cdf-version': 'alpha',
    };
  }

  getGraphQLBaseURL(
    modelSpace: string,
    modelName: string,
    modelVersion: string
  ) {
    return `${this.BASE_URL}/userapis/spaces/${modelSpace}/datamodels/${modelName}/versions/${modelVersion}/graphql`;
  }

  /**
   * Make a direct query to a graphql endpoint in CDF
   * @example
   * `fdm.graphQL(`query FDM ...`, 'APM_SourceData', 1)`
   * @param query The graphql query you want to make
   * @param modelSpace The space that the query exists
   * @param modelVersion The data model version
   * @param variables (Optional) Variables for graphql
   * @param modelName The space that your data models exist in (default: spaceExternalId)
   * @returns T
   */
  public async graphQL<T>(
    query: string,
    modelSpace: string,
    modelName: string,
    modelVersion: string,
    variables?: Record<string, any>
  ) {
    return this.cogniteClient
      .post<{ data: T; errors: FDMError[] }>(
        this.getGraphQLBaseURL(modelSpace, modelName, modelVersion),
        {
          data: {
            query,
            variables,
          },
        }
      )
      .then((res) => {
        if (res.data.errors) {
          const { errors } = res.data;
          throw new Error(
            errors.length > 0
              ? JSON.stringify(errors.map((error) => error.message))
              : 'Error connecting to server'
          );
        }
        return res.data.data;
      });
  }

  async introspectionQuery(
    modelSpace: string,
    modelName: string,
    modelVersion: string,
    view: string
  ): Promise<IntrospectionQueryField[]> {
    const graphQl = gql`
      query Introspection($view: String!) {
        allFields: __type(name: $view) {
          name
          fields {
            name
            type {
              name
            }
          }
        }
      }
    `;
    return this.graphQL<{
      allFields: { fields: { name: string; type: { name: string } }[] };
    }>(graphQl, modelSpace, modelName, modelVersion, { view }).then((res) =>
      res.allFields.fields.map((field) => ({
        field: field.name,
        kind: field.type.name,
      }))
    );
  }

  /**
   * Upsert a new node into a container in CDF
   * @example
   * `fdm.upsertNodes([{ ... }], 'APM_Activity', 'APM_SourceData')`
   * @param nodes Nodes of type T to be upserted
   * @param modelName Name of datamodel (container) you want to insert into
   * @param spaceExternalId Space external Id where the datamodel (container) exists
   * @param instanceSpace Data space external id where the datamodel (container) exists
   * @returns Result of query
   */
  async upsertNodes<T extends { externalId?: string }>(
    nodes: T[],
    instanceSpace: string,
    modelSpace: string,
    view: string,
    viewVersion: string
  ) {
    const data = {
      items: nodes.map(({ externalId, ...properties }) => ({
        instanceType: 'node',
        space: instanceSpace,
        externalId,
        sources: [
          {
            source: {
              type: 'view',
              space: modelSpace,
              externalId: view,
              version: viewVersion,
            },
            properties,
          },
        ],
      })),
    };

    return this.cogniteClient.post<{ items: T[] }>(this.BASE_URL_DMS, {
      data,
      headers: this.DMS_HEADERS,
      withCredentials: true,
    });
  }

  /**
   * Delete a node from a datamodel
   * @example
   * `fdm.deleteNodes(['Activity_001', 'Activity_002'], 'APM_SourceData')`
   * @param externalIds External Ids of the nodes to delete
   * @param instanceSpace Space where these external Ids live
   * @returns Result of query
   */
  async deleteNodes(externalIds: string[] | string, instanceSpace: string) {
    const externalIdsAsArray = Array.isArray(externalIds)
      ? externalIds
      : [externalIds];

    const data = {
      items: externalIdsAsArray.map((externalId) => ({
        instanceType: 'node',
        space: instanceSpace,
        externalId,
      })),
    };

    return this.cogniteClient.post(`${this.BASE_URL_DMS}/delete`, {
      data,
      headers: this.DMS_HEADERS,
      withCredentials: true,
    });
  }

  /**
   * Upsert a new node into a container in CDF
   * @example
   * `fdm.upsertNodes([{ ... }], 'APM_Activity', 'APM_SourceData')`
   * @param nodes Nodes of type T to be upserted
   * @param modelName Name of datamodel (container) you want to insert into
   * @param instanceSpace Space external Id where the datamodel (container) exists
   * @returns Result of query
   */

  async upsertEdges(edges: Edge[], instanceSpace: string, modelSpace: string) {
    const items: FDMEdge[] = edges.map(
      ({ externalId, modelName, startNode, endNode }) => ({
        instanceType: 'edge',
        space: instanceSpace,
        externalId,
        type: {
          space: modelSpace,
          externalId: modelName,
        },
        startNode: {
          space: instanceSpace,
          externalId: startNode,
        },
        endNode: {
          space: instanceSpace,
          externalId: endNode,
        },
      })
    );

    return this.cogniteClient.post<{ items: FDMEdge[] }>(this.BASE_URL_DMS, {
      data: {
        autoCreateStartNodes: false,
        autoCreateEndNodes: false,
        skipOnVersionConflict: false,
        replace: false,
        items,
      },
      headers: this.DMS_HEADERS,
      withCredentials: true,
    });
  }

  async search(params: SearchRequestV3) {
    const response = await this.cogniteClient.instances.search(params);
    return response.items;
  }
}
