export const DEFAULT_ASSET_CONTAINER_PROPERTIES = [
  'name',
  'description',
  'id',
  'externalId',
  'createdTime',
  'lastUpdatedTime',
  'labels',
  'source',
];

export const DEFAULT_EVENT_CONTAINER_PROPERTIES = [
  'type',
  'subtype',
  'description',
  'id',
  'externalId',
  'startTime',
  'endTime',
  'assetIds',
  'createdTime',
  'lastUpdatedTime',
  'source',
];
