import { useQuery } from '@tanstack/react-query';

import { CogniteClient } from '@cognite/sdk/dist/src';

import {
  FdmInstance,
  isFdmInstance,
  TimeseriesAcdmInstance,
} from '../../types';

type CdmDatapointResponseType = {
  items: {
    id: number;
    datapoints: [];
    unit?: string;
  }[];
};

/**
 * Shameful because it should be supported by the SDK.
 */
const shamefulCdmDatapointsRetrieveLatest = async (
  {
    instance,
    before,
  }: {
    instance: FdmInstance;
    before: Date;
  },
  sdk: CogniteClient
): Promise<CdmDatapointResponseType['items']> => {
  const response = await sdk.post<CdmDatapointResponseType>(
    `api/v1/projects/${sdk.project}/timeseries/data/latest`,
    {
      data: {
        items: [
          {
            instanceId: {
              space: instance.instanceSpace,
              externalId: instance.instanceExternalId,
            },
            before: before.valueOf(),
          },
        ],
      },
    }
  );

  return response.data.items;
};

/**
 * Fetches the latest value of a timeseries instance BEFORE endDate.
 * @param instance
 * @param sdk
 * @param props
 */
const useTimeseriesLatestValue = (
  instance: FdmInstance | TimeseriesAcdmInstance,
  sdk: CogniteClient,
  props: { endDate: Date }
) => {
  const { endDate } = props;
  return useQuery(
    ['timeseriesLatestValue', instance, endDate.valueOf()],
    async () => {
      if (isFdmInstance(instance)) {
        const cdmTimeseries = await shamefulCdmDatapointsRetrieveLatest(
          {
            instance,
            before: endDate,
          },
          sdk
        );

        if (cdmTimeseries.length === 0) {
          throw new Error('No data found');
        }

        return cdmTimeseries[0];
      }

      const acdmTimeSeries = await sdk.datapoints.retrieveLatest([
        {
          id: instance.id,
          before: endDate.valueOf(),
        },
      ]);

      if (acdmTimeSeries.length === 0) {
        throw new Error('No data found');
      }

      return acdmTimeSeries[0];
    }
  );
};

export default useTimeseriesLatestValue;
