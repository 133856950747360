import { APP_CONFIG_V2_EXTERNAL_ID } from '@infield/features/app-config/constants';
import { ComponentContainer } from '@infield/features/ui';
import { type FC } from 'react';

import { useAppConfigQuery } from '../../../hooks';
import { useUpsertAppConfigMutation } from '../../../hooks/use-mutation';
import type {
  AppConfig,
  ResourceFilters,
  RootLocationDataFilters,
} from '../../../types';
import { mergeConfigFilterObjects } from '../../../utils/utils';
import * as S from '../elements';

import { DataFiltersTab } from './data-filters-tab';
import { MigrateConfig } from './migrate-config';

interface Props {
  hasAppConfigPermission: boolean;
  rootLocationExternalId: string;
  setEditingRootLocation: (rootLocationExternalId: string | undefined) => void;
}

export const RootLocationConfigDataFilters: FC<Props> = ({
  hasAppConfigPermission,
  rootLocationExternalId,
  setEditingRootLocation,
}) => {
  const { data: appConfig, isSuccess, isLoading } = useAppConfigQuery();
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();

  const hasNewConfig =
    appConfig?.externalId === APP_CONFIG_V2_EXTERNAL_ID && !isLoading;

  const configuredRootLocation =
    appConfig?.featureConfiguration?.rootLocationConfigurations?.find(
      (rootLocation) => rootLocation.externalId === rootLocationExternalId
    );

  const onConfigSave = async (updatedDataFilters: RootLocationDataFilters) => {
    if (!appConfig) return;

    const newAppConfig: AppConfig = {
      ...appConfig,
      featureConfiguration: {
        ...appConfig.featureConfiguration,
        rootLocationConfigurations: [
          ...(appConfig.featureConfiguration?.rootLocationConfigurations?.map(
            (rootConfig) => {
              if (
                rootConfig.externalId === configuredRootLocation?.externalId
              ) {
                return {
                  ...rootConfig,
                  dataFilters: {
                    ...rootConfig.dataFilters,
                    ...updatedDataFilters,
                  },
                };
              }
              return { ...rootConfig };
            }
          ) || []),
        ],
      },
    };

    await upsertAppConfig({
      newAppConfig,
    });
  };

  const onGeneralConfigSave = (newGeneralFilters: ResourceFilters) => {
    const oldDataFilters = configuredRootLocation?.dataFilters;
    const allFilterUpdates = {
      general: newGeneralFilters,
      assets: mergeConfigFilterObjects(
        newGeneralFilters,
        oldDataFilters?.assets,
        true
      ),
      timeseries: mergeConfigFilterObjects(
        newGeneralFilters,
        oldDataFilters?.timeseries
      ),
      files: mergeConfigFilterObjects(newGeneralFilters, oldDataFilters?.files),
    };
    onConfigSave(allFilterUpdates);
  };

  return (
    <ComponentContainer>
      {appConfig && !hasNewConfig ? (
        <MigrateConfig
          appConfig={appConfig}
          setEditingRootLocation={setEditingRootLocation}
        />
      ) : (
        <S.Tabs>
          <S.TabPane key="general" label="General">
            <DataFiltersTab
              hasAppConfigPermission={hasAppConfigPermission}
              configuredRootLocation={configuredRootLocation}
              isLoading={isLoading}
              isSuccess={isSuccess}
              onGeneralConfigSave={onGeneralConfigSave}
              dataFiltersTab="general"
            />
          </S.TabPane>
          <S.TabPane key="assets" label="Assets">
            <DataFiltersTab
              hasAppConfigPermission={hasAppConfigPermission}
              configuredRootLocation={configuredRootLocation}
              isLoading={isLoading}
              isSuccess={isSuccess}
              onConfigSave={onConfigSave}
              dataFiltersTab="assets"
            />
          </S.TabPane>
          <S.TabPane key="timeseries" label="Timeseries">
            <DataFiltersTab
              hasAppConfigPermission={hasAppConfigPermission}
              configuredRootLocation={configuredRootLocation}
              isLoading={isLoading}
              isSuccess={isSuccess}
              onConfigSave={onConfigSave}
              dataFiltersTab="timeseries"
            />
          </S.TabPane>
          <S.TabPane key="files" label="Files">
            <DataFiltersTab
              hasAppConfigPermission={hasAppConfigPermission}
              configuredRootLocation={configuredRootLocation}
              isLoading={isLoading}
              isSuccess={isSuccess}
              onConfigSave={onConfigSave}
              dataFiltersTab="files"
            />
          </S.TabPane>
        </S.Tabs>
      )}
    </ComponentContainer>
  );
};
