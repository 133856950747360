import { APMAppDataService } from '../apm-app-data-service';
import type { ServiceContract } from '../types';

import type { InFieldLocationConfig } from './types';

export class InFieldLocationConfigService extends APMAppDataService<InFieldLocationConfig> {
  constructor(contract: ServiceContract) {
    super(contract, 'InFieldLocationConfig', 'v1');
  }
}
