import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;

  .cogs-v10.cogs-input {
    background: transparent !important;
    color: white;
    border: 2px solid white;
    border-radius: 5px 0px 0px 5px;

    &:focus {
      border-color: white;
    }
    input::placeholder {
      color: white;
    }
  }

  .cogs-icon {
    color: white;
  }
`;
