import { Button, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const MenuContent = styled(Flex).attrs({
  direction: 'column',
})`
  max-height: 40vh;
  overflow: auto;
`;

export const EmptyResult = styled(Flex).attrs({
  gap: 8,
})`
  padding: 8px;
`;

export const LoadMore = styled(Button)`
  width: 100%;
`;
