import { Body, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex)`
  height: calc(100vh - 170px);
  flex: 1;
  width: 100%;
  position: relative;
`;

export const Header = styled(Flex).attrs({
  alignItems: 'center',
})`
  padding: 8px 16px;
  height: 56px;
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme['border--muted']};
  border-right: 1px solid ${({ theme }) => theme['border--muted']};
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const Item = styled(Flex).attrs({ gap: 12, direction: 'column' })`
  padding: 8px 0px;
`;

export const ItemTitle = styled(Body).attrs({
  size: 'medium',
  strong: true,
})`
  color: ${({ theme }) => theme['text-icon--interactive--default']};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const ItemDescription = styled(Body)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme['text-icon--muted']};
`;

export const ContentWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  min-width: 0;
  flex: 1;
  width: 100%;
`;

export const GroupsWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  flex: 1;
  overflow: auto;
  height: calc(100vh - 170px);
`;

export const DetailWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  border-left: 1px solid ${({ theme }) => theme['border--muted']};
`;

export const LastUpdated = styled(Body).attrs({ size: 'x-small' })`
  color: ${({ theme }) => theme['text-icon--muted']};
`;

export const AssetDescription = styled(Body).attrs({
  size: 'small',
  strong: true,
})`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AssetInformation = styled(Flex).attrs({
  direction: 'column',
})`
  min-width: 0;
`;
