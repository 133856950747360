import { HoverLineData } from '@cognite/plotting-components';

import { TimeseriesDatapoint } from '../../utils/timeseries/types';

import getFormattedDateWithTimezone from './getFormattedDateWithTimezone';

const formatHoverLineInfo = ({ customData }: HoverLineData): string => {
  const datapoint = customData as TimeseriesDatapoint;
  const { timestamp } = datapoint || {};

  if (!timestamp) {
    return '';
  }

  return getFormattedDateWithTimezone(
    timestamp instanceof Date ? timestamp : new Date(timestamp)
  );
};

export default formatHoverLineInfo;
