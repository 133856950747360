import { Dropdown, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
  gap: 8,
})`
  height: 100%;
`;

export const SearchResultDropdown = styled(Dropdown)`
  [data-tippy-root] {
    width: calc(100% - 32px);
    max-width: none;
  }
  .tippy-box {
    max-width: none !important;
  }
`;

export const EmptyAssignment = styled(Flex).attrs({
  direction: 'column',
  alignItems: 'center',
})`
  padding: 16px;
`;
