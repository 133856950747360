import {
  AxisDisplayMode,
  AxisPlacement,
  DataProvider,
  LineChart,
} from '@cognite/griff-react';
import type { Timeseries } from '@cognite/sdk';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '@infield/utils/defaultDateFormats';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useTimeseriesChart } from '../hooks';
import { y0Accessor, y1Accessor, yAccessor } from '../utils';

import { NUM_Y_AXIS_TICKS, Y_AXIS_WIDTH } from './constants';
import * as S from './elements';
import { ErrorFallback } from './error-fallback';
import { TrendsChartYAxis } from './trends-chart-y-axis';
import type { Domain } from './types';
import { enhancedSeries, yAxisFormatter, yLabel } from './utils';

interface Props {
  isShowYAxis: boolean;
  zoomable: boolean;
  ySubDomains: Record<string, Domain | undefined>;
  setYSubDomains: (newDomains: Record<string, Domain | undefined>) => void;
  initialXDomain: Domain | null;
  xSubDomain: Domain | null;
  onXSubDomainChange: (newXSubDomain: Domain) => void;
  handleChartWidthChange: (newChartWidth: number) => void;
  limitXSubDomain: (newXSubDomain: Domain) => void;
  handleUpdateDomains: any;
  isLive: boolean;
  buttonClicked: boolean;
  timeseries: Timeseries[];
  isFullView: boolean;
  renderAsMobile?: boolean;
}
export const TrendsChart: FC<Props> = ({
  isShowYAxis,
  zoomable,
  ySubDomains,
  setYSubDomains,
  initialXDomain,
  xSubDomain,
  onXSubDomainChange,
  handleChartWidthChange,
  limitXSubDomain,
  handleUpdateDomains,
  isLive,
  buttonClicked,
  timeseries,
  isFullView,
  renderAsMobile,
}) => {
  const [state, setState] = useState({
    pointsPerSeries: 1000,
    innerWidth: window.innerWidth,
  });

  const { t } = useTranslation(LOCIZE_NAMESPACES.trends);

  const chartRef = useRef<null | HTMLDivElement>(null);

  const chart = useTimeseriesChart({
    // A distinct id is required and used a key in griff-react.
    // As it is not used for any data fetching we can use it as key for now
    // considering that we won't be using griff-react in future, we can save time by not changing griff-react implementation
    timeseries: timeseries.map((ts, index) => ({ ...ts, id: index })),
    ySubDomains,
    setYSubDomains,
    isLive,
    buttonClicked,
  });

  const coloredSeries = useMemo(
    () => enhancedSeries(chart.timeseries),
    [chart.timeseries]
  );

  const updatePointsPerSeries = useCallback(() => {
    const pointsPerSeries = chartRef.current
      ? Math.floor(chartRef.current.getBoundingClientRect().width)
      : 1000;
    setState({ ...state, pointsPerSeries });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateChartWidth = useCallback(() => {
    if (!chartRef.current) {
      return;
    }
    const chartWidth = Math.floor(
      chartRef.current.getBoundingClientRect().width - Y_AXIS_WIDTH
    );
    handleChartWidthChange(chartWidth);
  }, [handleChartWidthChange]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdatePointsPerSeries = useCallback(
    debounce(() => {
      if (state.innerWidth !== window.innerWidth) {
        updatePointsPerSeries();
        setState({ ...state, innerWidth: window.innerWidth });
      }
    }, 300),
    [state.innerWidth, updatePointsPerSeries]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateChartWidth = useCallback(
    debounce(() => {
      if (state.innerWidth !== window.innerWidth) {
        updateChartWidth();
        setState({ ...state, innerWidth: window.innerWidth });
      }
    }, 300),
    [state.innerWidth, updateChartWidth]
  );

  // called when there is a click outside the chart, regardless of whether
  // the chart was already clicked/hovered over.
  // we can safely clear the ruler state in this case because
  // the event handler for the legend onClick listener stops the
  // propogation of the event.
  const handleOutsideClick: EventListenerOrEventListenerObject = useCallback(
    (e: any) => {
      if (chartRef.current && !chartRef.current.contains(e.target)) {
        chart.handleOutsideClick();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    updateChartWidth();
    updatePointsPerSeries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('resize', debouncedUpdatePointsPerSeries);
    window.addEventListener('resize', debouncedUpdateChartWidth);
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('resize', debouncedUpdatePointsPerSeries);
      window.removeEventListener('resize', debouncedUpdateChartWidth);
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [
    debouncedUpdatePointsPerSeries,
    debouncedUpdateChartWidth,
    handleOutsideClick,
  ]);

  const handleXSubDomainChange = (newXSubDomain: Domain) => {
    onXSubDomainChange(newXSubDomain);
    chart.onXSubDomainChange(newXSubDomain);
  };

  // we use this function to calculate the position of the timestamp on the ruler
  // using the height of the chart and height of the label
  const getTimeLabelPosition = (defaultPosition: number) =>
    defaultPosition - 40;

  const [renderChart, setRenderChart] = useState(true);

  const [errorTime, setErrorTime] = useState<Date>();
  const [errorCountsInLastSecond, setErrorCountsInLastSecond] = useState(0);

  const getTimeDiffInMilliSeconds = (t1: Date) => {
    const t2 = new Date();
    const dif = t1.getTime() - t2.getTime();

    return Math.abs(dif / 1000);
  };
  useEffect(() => {
    if (!renderChart) {
      /*
          This hook sets the render to true so that we can rerender chat in case of error
          The hook ensures that we reset only if the error count in last second doesn't exceed 10
          10 is an arbitrary number showing that the charts is unable to render without any error
      */
      if (errorTime) {
        const timeDiff = getTimeDiffInMilliSeconds(errorTime);
        if (timeDiff < 1) {
          setErrorCountsInLastSecond((v) => v + 1);
        } else {
          setErrorCountsInLastSecond(0);
          setErrorTime(new Date());
        }
      } else {
        setErrorTime(new Date());
      }

      if (errorCountsInLastSecond < 10) {
        setTimeout(() => {
          setRenderChart(true);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderChart]);

  if (chart.error)
    return (
      <S.ErrorWrapper>
        <div>
          {t('unableToLoadTrendsChart', 'Trends chart was unable to load data')}
        </div>
      </S.ErrorWrapper>
    );

  return (
    <S.ChartWrapper
      ref={chartRef}
      isShowYAxis={isShowYAxis}
      renderAsMobile={renderAsMobile}
      data-test-id="trends-chart"
    >
      {!isShowYAxis && (
        <S.DisabledYAxis isFullView={isFullView}>
          {(chart.timeseries as Timeseries[]).map((ts) => (
            <TrendsChartYAxis key={ts.externalId} timeseries={ts} />
          ))}
        </S.DisabledYAxis>
      )}
      {renderChart && (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={() => setRenderChart(false)}
        >
          {initialXDomain && xSubDomain && (
            <DataProvider
              timeDomain={initialXDomain}
              timeSubDomain={xSubDomain}
              defaultLoader={chart.loader}
              xAccessor={(d: any) => d.timestamp}
              yAccessor={yAccessor}
              y0Accessor={y0Accessor}
              y1Accessor={y1Accessor}
              series={coloredSeries}
              updateInterval={isLive ? 5000 : 0}
              onTimeSubDomainChanged={handleXSubDomainChange}
              pointsPerSeries={state.pointsPerSeries}
              isTimeSubDomainSticky={isLive}
              limitTimeSubDomain={limitXSubDomain}
              onFetchData={chart.handleFetchData}
              onUpdateDomains={handleUpdateDomains}
            >
              <LineChart
                crosshair={false}
                contextChart={{ visible: false }}
                ruler={{
                  visible: chart.isRulerVisible,
                  yLabel: (point: any) => yLabel(point, chart.timeseries),
                  timeLabel: (point: any) =>
                    dayjs(point.timestamp).format(
                      `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`
                    ),
                  getTimeLabelPosition,
                  timestamp: chart.rulerTimestamp,
                }}
                yAxisWidth={Y_AXIS_WIDTH}
                onMouseMove={chart.handleMouseMove}
                onMouseOut={chart.handleMouseOut}
                onBlur={chart.handleMouseOut}
                yAxisDisplayMode={
                  isShowYAxis ? undefined : AxisDisplayMode.NONE
                }
                zoomable={zoomable}
                yAxisTicks={NUM_Y_AXIS_TICKS}
                yAxisFormatter={yAxisFormatter}
                yAxisPlacement={AxisPlacement.LEFT}
              />
            </DataProvider>
          )}
        </ErrorBoundary>
      )}
    </S.ChartWrapper>
  );
};
