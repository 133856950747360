export const getTogglesUpdatedState = (
  newToggles: any,
  previousToggles: any
) => {
  const isThreeDUpdated = newToggles?.threeD !== previousToggles?.threeD;
  const isTrendsUpdated = newToggles?.trends !== previousToggles?.trends;
  const isDocumentsUpdated =
    newToggles?.documents !== previousToggles?.documents;
  const isWorkordersUpdated =
    newToggles?.workorders !== previousToggles?.workorders;
  const isNotificationsUpdated =
    newToggles?.notifications !== previousToggles?.notifications;
  const isMediaUpdated = newToggles?.media !== previousToggles?.media;

  const isUpdated =
    isThreeDUpdated ||
    isTrendsUpdated ||
    isDocumentsUpdated ||
    isWorkordersUpdated ||
    isNotificationsUpdated ||
    isMediaUpdated;

  return {
    isThreeDUpdated,
    isTrendsUpdated,
    isDocumentsUpdated,
    isWorkordersUpdated,
    isNotificationsUpdated,
    isMediaUpdated,
    isUpdated,
  };
};
