import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { v4 as uuid } from 'uuid';

import getStraightConnectionPathPointsFromBorder from '../../annotations/getStraightConnectionPathPointsFromBorder';
import { isStickyAnnotationNode, Position } from '../../annotations/types';
import { UpdateRequestSource, type UnifiedViewer } from '../../UnifiedViewer';
import UnifiedViewerEventType from '../../UnifiedViewerEventType';
import type UnifiedViewerRenderer from '../../UnifiedViewerRenderer';
import { isPrimaryMouseButtonPressed } from '../../utils/eventUtils';
import getEuclideanDistance from '../../utils/getEuclideanDistance';
import getRectRelativeToStage from '../../utils/getRectRelativeToStage';
import { areRectsOverlapping } from '../../utils/rectUtils';
import { ANCHOR_NAMES, ANCHOR_STYLE, AnchorName } from '../Anchor/constants';
import getAnchorCross from '../Anchor/getAnchorCross';
import getOffsetAnchorPosition from '../Anchor/getOffsetAnchorPosition';
import getPreviewNode from '../StickyTool/getPreviewNode';

import { PREVIEW_ARROW_CONFIG, MIN_MOUSE_DELTA_PX } from './constants';
import { getPreviewNodeOffset } from './getPreviewNodeOffset';

const createPreviewNode = (targetSticky: Konva.Label): Konva.Label => {
  const previewNode = getPreviewNode({
    label: {
      ...targetSticky.getAttrs(),
      id: `generated-sticky-${uuid()}`,
      visible: true,
    },
    tag: targetSticky.getTag().getAttrs(),
    text: targetSticky.getText().getAttrs(),
  });
  return previewNode;
};

const createPreviewArrow = (
  stickyNode: Konva.Label,
  previewNode: Konva.Label
): Konva.Arrow => {
  const previewArrow = new Konva.Arrow({
    ...PREVIEW_ARROW_CONFIG,
    id: `preview-arrow-${uuid()}`,
    fromId: stickyNode.id(),
    toId: previewNode.id(),
    visible: false,
    points: [],
  });
  return previewArrow;
};

class StickyAnchorHelper {
  private viewer: UnifiedViewer;
  private renderer: UnifiedViewerRenderer;
  private anchors: Konva.Group[] | undefined = undefined;
  private targetSticky: Konva.Label | undefined = undefined; // This is the sticky we want to attach the anchors to
  private previewNode: Konva.Label | undefined = undefined;
  private previewArrow: Konva.Arrow | undefined = undefined;

  private isMouseDownOnAnchor: boolean = false;
  private mouseDownPosition: Position | undefined = undefined;

  public constructor(viewer: UnifiedViewer, renderer: UnifiedViewerRenderer) {
    this.viewer = viewer;
    this.renderer = renderer;

    this.viewer.stage.on('mousemove', this.onStageMouseMove);
    this.viewer.stage.on('mouseup', this.onStageMouseUp);
    this.viewer.stage.on('mouseout', this.onStageMouseOut);
    this.viewer.host.addEventListener('keydown', (event) => {
      if (this.isMouseDownOnAnchor && event.key === 'Escape') {
        this.reset();
        return;
      }
    });
  }

  private clearAnchors = (): void => {
    this.anchors?.forEach((anchor) => anchor.destroy());
    this.anchors = undefined;
  };

  private clearPreviewNode = (): void => {
    this.previewNode?.destroy();
    this.previewNode = undefined;
  };

  private clearPreviewArrow = (): void => {
    this.previewArrow?.destroy();
    this.previewArrow = undefined;
  };

  private reset = (): void => {
    if (this.targetSticky === undefined) {
      return;
    }
    this.isMouseDownOnAnchor = false;
    const targetSticky = this.targetSticky;
    this.refreshAnchors([]);
    this.refreshAnchors([targetSticky]);
    this.viewer.emit(UnifiedViewerEventType.ON_TOOL_END);
  };

  public clear = (): void => {
    this.clearAnchors();
    this.clearPreviewNode();
    this.clearPreviewArrow();
  };

  private getArrowTarget = (
    event: KonvaEventObject<MouseEvent>
  ): Konva.Node | undefined => {
    const parentTarget = event.target.parent;
    if (
      parentTarget instanceof Konva.Label &&
      isStickyAnnotationNode(parentTarget)
    ) {
      this.previewNode?.hide();
      return parentTarget;
    }
    this.previewNode?.show();
    return this.previewNode;
  };

  private hasMouseMoved = (
    minMouseDeltaPx: number = MIN_MOUSE_DELTA_PX
  ): boolean => {
    const currentMousePosition = this.viewer.stage.getRelativePointerPosition();
    if (currentMousePosition === null) {
      return false;
    }
    return (
      this.mouseDownPosition !== undefined &&
      getEuclideanDistance(this.mouseDownPosition, currentMousePosition) >
        minMouseDeltaPx
    );
  };

  private onStageMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    if (this.targetSticky === undefined) {
      return;
    }
    if (!isPrimaryMouseButtonPressed(event.evt)) {
      this.isMouseDownOnAnchor = false;
    }
    if (!this.isMouseDownOnAnchor || !this.hasMouseMoved()) {
      return;
    }
    event.evt.stopImmediatePropagation();

    if (this.previewNode === undefined) {
      return;
    }

    this.viewer.setActiveToolCursor('crosshair');
    const pos = this.viewer.stage.getRelativePointerPosition();
    if (pos === null) {
      return;
    }
    this.previewNode.visible(true);
    this.previewNode.position({
      x: pos.x - this.previewNode.width() / 2,
      y: pos.y - this.previewNode.height() / 2,
    });
    this.clearAnchors();

    const arrowTarget = this.getArrowTarget(event);
    if (this.previewArrow === undefined || arrowTarget === undefined) {
      return;
    }

    // Hide the preview arrow if the arrow target is the same as the target sticky
    const arrowTargetRect = getRectRelativeToStage(arrowTarget);
    const targetStickyRect = getRectRelativeToStage(this.targetSticky);
    if (
      arrowTarget.id() === this.targetSticky.id() ||
      areRectsOverlapping(arrowTargetRect, targetStickyRect)
    ) {
      this.previewArrow.visible(false);
      return;
    }

    this.previewArrow.visible(true);
    this.previewArrow.attrs.toId = arrowTarget.id();
    this.previewArrow.points(
      getStraightConnectionPathPointsFromBorder(
        targetStickyRect,
        arrowTargetRect
      )
    );
    this.previewArrow.moveToBottom();
  };

  private onStageMouseUp = (event: KonvaEventObject<MouseEvent>): void => {
    // If no active anchors, return early
    if (this.targetSticky === undefined || this.previewArrow === undefined) {
      return;
    }

    // If mouseup is done on top of an anchor, stop the event propagation
    // so that the other tools' mouseup handlers do not get triggered
    if (this.isMouseDownOnAnchor) {
      event.evt.stopPropagation();
    }
    this.isMouseDownOnAnchor = false;
    this.mouseDownPosition = undefined;
    this.viewer.setActiveToolCursor('default');

    const arrowTarget = this.getArrowTarget(event);
    if (
      arrowTarget === undefined ||
      this.previewArrow === undefined ||
      this.previewArrow.points().length === 0
    ) {
      return;
    }

    if (arrowTarget.id() === this.targetSticky?.id()) {
      this.reset();
      return;
    }

    this.previewArrow.opacity(1);
    this.commitNodes([arrowTarget, this.previewArrow]);
  };

  private onStageMouseOut = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (!this.isMouseDownOnAnchor) {
      return;
    }

    const relatedTarget = event.evt.relatedTarget;
    if (!(relatedTarget instanceof HTMLElement)) {
      return;
    }
    if (this.viewer.host.contains(relatedTarget)) {
      this.previewNode?.visible(false);
      return;
    }
    this.reset();
  };

  private commitNodes = (nodes: Konva.Node[]): void => {
    const annotations = nodes.map(this.renderer.getAnnotationFromNode);
    this.viewer.onActiveToolComplete();
    this.viewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      source: UpdateRequestSource.STICKY_ANCHOR,
      annotations,
      containers: [],
    });
    this.viewer.emit(UnifiedViewerEventType.ON_TOOL_END);

    this.clearPreviewNode();
    this.clearPreviewArrow();
  };

  private attachEventHandlersToAnchor = (
    name: AnchorName,
    anchor: Konva.Group,
    originalSticky: Konva.Label
  ): void => {
    const onMouseOver = (event: KonvaEventObject<MouseEvent>) => {
      this.viewer.setActiveToolCursor('pointer');

      const target = event.currentTarget;
      if (!(target instanceof Konva.Group)) {
        return;
      }
      target.add(getAnchorCross());
      const scale = ANCHOR_STYLE.hoveredRadius / ANCHOR_STYLE.radius;
      target.scale({ x: scale, y: scale });

      this.previewNode = createPreviewNode(originalSticky);
      const previewNodeOffset = getPreviewNodeOffset(this.previewNode, name);
      this.previewNode.position({
        x: previewNodeOffset.x + this.previewNode.x(),
        y: previewNodeOffset.y + this.previewNode.y(),
      });

      this.viewer.layers.transformer.add(this.previewNode);
      this.previewNode.moveToBottom();
    };

    const onMouseOut = (event: KonvaEventObject<MouseEvent>) => {
      this.viewer.setActiveToolCursor('default');

      const target = event.currentTarget;
      if (!(target instanceof Konva.Group)) {
        return;
      }
      target
        .getChildren()
        .filter((child) => child.name() === ANCHOR_STYLE.crossName)
        .forEach((child) => child.destroy());
      target.scale({ x: 1, y: 1 });

      if (!this.isMouseDownOnAnchor) {
        this.clearPreviewNode();
      }
    };

    const onClick = () => {
      this.viewer.setActiveToolCursor('default');
      if (this.previewNode === undefined) {
        return;
      }
      this.commitNodes([this.previewNode]);
    };

    const onMouseDown = (event: KonvaEventObject<MouseEvent>) => {
      if (event.target.id() !== anchor.id()) {
        return;
      }
      if (!isPrimaryMouseButtonPressed(event.evt)) {
        return;
      }
      // Prevent the stage from registering the click on the anchor
      event.evt.stopPropagation();
      this.viewer.getTransformer()?.setNodes([]);
      this.isMouseDownOnAnchor = true;
      const currentPosition = this.viewer.stage.getRelativePointerPosition();
      if (currentPosition === null) {
        return;
      }
      this.mouseDownPosition = currentPosition;
      this.viewer.emit(UnifiedViewerEventType.ON_TOOL_START);

      if (this.previewNode === undefined) {
        return;
      }
      this.previewArrow = createPreviewArrow(originalSticky, this.previewNode);
      this.viewer.layers.transformer.add(this.previewArrow);
    };

    anchor.on('mousedown', onMouseDown);
    anchor.on('mouseover', onMouseOver);
    anchor.on('mouseout', onMouseOut);
    anchor.on('click', onClick);
  };

  private updateAnchorPosition = (anchor: Konva.Group): void => {
    const position = getOffsetAnchorPosition({
      anchor,
      target: this.targetSticky,
    });
    if (position === undefined) {
      return;
    }
    anchor.position(position);
  };

  private createAnchor = (name: AnchorName): Konva.Group => {
    if (this.targetSticky === undefined) {
      throw new Error(
        'targetSticky cannot be undefined when creating an anchor'
      );
    }
    const anchor = new Konva.Group({ anchorName: name, listening: true });
    anchor.add(new Konva.Circle({ ...ANCHOR_STYLE }));
    this.updateAnchorPosition(anchor);
    this.attachEventHandlersToAnchor(name, anchor, this.targetSticky);
    return anchor;
  };

  public refreshAnchors = (nodes: Konva.Node[]): void => {
    if (this.isMouseDownOnAnchor) {
      return;
    }

    if (nodes.length !== 1 || !isStickyAnnotationNode(nodes[0])) {
      this.targetSticky = undefined;
      this.clear();
      return;
    }

    const stickyNode = nodes[0];
    // Create new anchors only if we're targetting a new sticky
    if (
      this.targetSticky?.id() !== stickyNode.id() ||
      this.targetSticky?.getAttr('containerId') !==
        stickyNode.getAttr('containerId')
    ) {
      this.clear();
      if (stickyNode.getAttr('containerId') !== undefined) {
        // we don't support sticky anchors for sticky annotations inside containers
        return;
      }

      this.targetSticky = stickyNode;
      this.anchors = ANCHOR_NAMES.map(this.createAnchor);
      this.targetSticky.on('dragstart transformstart', this.clear);

      this.viewer.layers.transformer.add(...this.anchors);
      return;
    }

    // Otherwise, re-use the previous anchors
    this.anchors?.forEach(this.updateAnchorPosition);
    this.targetSticky.on('dragstart transformstart', this.clear);
  };
}

export default StickyAnchorHelper;
