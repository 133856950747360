import type { LocationFilterDTO } from '@cognite/apm-config';
import { Breadcrumbs, ListIcon, LocationIcon } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  location?: LocationFilterDTO;
  onNavigateToLocationList?: () => void;
}

export const LocationHeader: FC<Props> = ({
  location,
  onNavigateToLocationList,
}) => {
  return (
    <S.Container>
      <Breadcrumbs>
        <Breadcrumbs.Item
          icon={<ListIcon />}
          label="Locations"
          onClick={onNavigateToLocationList}
        />
        <Breadcrumbs.Item icon={<LocationIcon />} label={location?.name} />
      </Breadcrumbs>
    </S.Container>
  );
};
