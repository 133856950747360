import type { ChecklistItemStatusAggregation } from '@infield/features/checklist';

export const getTasksProgressColor = (
  aggregatedChecklistStatuses?: ChecklistItemStatusAggregation
) => {
  if (
    !aggregatedChecklistStatuses?.completed ||
    !aggregatedChecklistStatuses.total
  ) {
    return undefined;
  }
  if (aggregatedChecklistStatuses?.notOk) {
    return 'red';
  }
  if (aggregatedChecklistStatuses?.ok) {
    return 'green';
  }
  return 'lightBlue';
};

export const getChecklistProgressCount = (
  aggregatedChecklistStatuses?: ChecklistItemStatusAggregation
) => {
  const isAggregationValid =
    aggregatedChecklistStatuses?.completed !== undefined &&
    aggregatedChecklistStatuses?.total !== undefined;

  return isAggregationValid
    ? `${aggregatedChecklistStatuses?.completed}/${aggregatedChecklistStatuses?.total}`
    : 'N/A';
};
