import { AskQuestionMultipleFilesButton } from '@cognite/ai';
import {
  CogsIllustration,
  DocumentIcon,
  EmptyState,
  FilesIllustration,
  Flex,
  Skeleton,
  TagChip,
} from '@cognite/cogs.js-v10';
import { useAppConfigQuery } from '@infield/features/app-config';
import * as S from '@infield/features/asset/elements';
import { useAssetDocumentsQuery } from '@infield/features/asset/hooks';
import { AssetCard } from '@infield/features/asset/shared/asset-card/asset-card';
import { StyledList } from '@infield/features/asset/shared/styled-list';
import type { ListItem } from '@infield/features/asset/shared/styled-list';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { EmptyStateWrapper } from '@infield/features/ui/table-empty-state/elements';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import get from 'lodash/get';
import { useCallback } from 'react';
import type { FC, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSearchParams } from 'react-router-dom';

import { FallbackAiDisabledButton } from './fallback-ai-button';

const NUMBER_OF_DOCUMENTS_TO_FIT_CARD = 6;

interface Props {
  assetExternalId: string;
  onOpen?: () => void;
}

export const AssetDocumentsCard: FC<Props> = ({ assetExternalId, onOpen }) => {
  const { data: documents, isInitialLoading } =
    useAssetDocumentsQuery(assetExternalId);
  const { data: appConfig } = useAppConfigQuery();
  const { isIdm } = useAppConfigContext();
  const { t } = useTranslation(LOCIZE_NAMESPACES.documents);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleDocumentClick = useCallback(
    (documentExternalId: string, page?: string, documentSpace?: string) => {
      searchParams.set('viewDocId', encodeURIComponent(documentExternalId));
      if (documentSpace) {
        searchParams.set('viewDocSpace', documentSpace || '');
      }
      if (page) {
        searchParams.set('page', page);
      }
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  // The AI widget does not support document.externalId yet, so we need to map it from document.id
  const handleSourceDocumentClick = (documentId: string, page: string) => {
    const documentExternalId = documents?.find(
      (doc) => doc.id === Number(documentId)
    )?.externalId;

    if (documentExternalId) {
      searchParams.set('viewDocId', encodeURIComponent(documentExternalId));
      searchParams.set('page', page);
      setSearchParams(searchParams, { replace: true });
    }
  };

  const documentConfiguration = appConfig?.featureConfiguration?.documents;

  const items = (): ListItem[] => {
    if (documentConfiguration && documents) {
      return documents.map((document, i) => {
        return {
          key: `${document.externalId}-${i}`,
          content: (
            <>
              <Flex justifyContent="space-between" alignItems="center">
                {documentConfiguration.title && (
                  <S.ItemTitle
                    onClick={() =>
                      handleDocumentClick(
                        document.externalId || 'missingDocumentExternalId',
                        '1',
                        document.space
                      )
                    }
                  >
                    {get(document, documentConfiguration.title)}
                  </S.ItemTitle>
                )}
                {document.mimeType && (
                  <TagChip
                    size="x-small"
                    label={get(document, 'mimeType')?.split('/')[1] || ''}
                  />
                )}
              </Flex>
              {documentConfiguration.description && (
                <S.ItemDescription size="medium">
                  {get(document, documentConfiguration.description)}
                </S.ItemDescription>
              )}
            </>
          ),
        };
      });
    }
    return [];
  };

  const hasValidDocumentConfig =
    documentConfiguration &&
    documentConfiguration.description &&
    documentConfiguration.title;

  const hasDocuments = documents && documents.length > 0;

  let content: ReactNode;
  if (!hasValidDocumentConfig) {
    content = (
      <EmptyStateWrapper>
        <EmptyState
          illustration={<CogsIllustration />}
          title={t(
            'ASSET_OVERVIEW_ASSET_CARD_MISSING_CONFIGURATION_TITLE',
            'Missing configuration'
          )}
          description={t(
            'ASSET_OVERVIEW_ASSET_CARD_MISSING_CONFIGURATION_BODY',
            'Please contact your admin to complete the configuration.'
          )}
        />
      </EmptyStateWrapper>
    );
  } else if (isInitialLoading) {
    content = <Skeleton.List lines={NUMBER_OF_DOCUMENTS_TO_FIT_CARD} />;
  } else if (!hasDocuments) {
    content = (
      <EmptyStateWrapper>
        <EmptyState
          illustration={<FilesIllustration />}
          title={t(
            'ASSET_OVERVIEW_DOCUMENT_CARD_NO_DOCUMENTS_TITLE',
            'No documents'
          )}
          description={t(
            'ASSET_OVERVIEW_DOCUMENT_CARD_NO_DOCUMENTS_BODY',
            'There are no documents found on this asset.'
          )}
        />
      </EmptyStateWrapper>
    );
  } else {
    content = <StyledList items={items()} />;
  }

  // AI widget is not supported in IDM projects yet, since it requires document.id
  const fileIds = isIdm
    ? []
    : documents?.filter(({ id }) => id).map((document) => document.id!) || [];
  const showAiWidget = appConfig?.featureConfiguration?.copilot?.enabled;
  return (
    <AssetCard
      icon={<DocumentIcon />}
      title={t('Documents', 'Documents')}
      buttonText={t('open', 'Open')}
      aiWidget={
        !isIdm &&
        showAiWidget && (
          <ErrorBoundary FallbackComponent={FallbackAiDisabledButton}>
            <AskQuestionMultipleFilesButton
              fileIds={fileIds}
              onOpenDocument={handleSourceDocumentClick}
            />
          </ErrorBoundary>
        )
      }
      onButtonClick={
        hasValidDocumentConfig && !isInitialLoading && hasDocuments
          ? onOpen
          : undefined
      }
    >
      {content}
    </AssetCard>
  );
};
