import createAutoExpiringPdfCache from './createAutoExpiringPdfCache';
let autoExpiringPdfCache: ReturnType<typeof createAutoExpiringPdfCache>;
const getPdfCache = (): typeof autoExpiringPdfCache => {
  if (!autoExpiringPdfCache) {
    autoExpiringPdfCache = createAutoExpiringPdfCache();
  }
  return autoExpiringPdfCache;
};

export default getPdfCache;
