import { Flex, IconWrapper, ToolsIcon } from '@cognite/cogs.js-v10';
import type { FC, PropsWithChildren } from 'react';

import * as S from './elements';

export const DisciplineIcon: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex gap={8} alignItems="center">
      <S.IconContainer>
        <IconWrapper size={12}>
          <ToolsIcon />
        </IconWrapper>
      </S.IconContainer>
      {children}
    </Flex>
  );
};
