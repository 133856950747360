import type { UserPreferenceLocation } from '@cognite/apm-client';
import type { Discipline } from '@cognite/apm-config';
import { Body, Button, Flex, Heading, ProductLogo } from '@cognite/cogs.js-v10';
import type { Language } from '@cognite/user-profile-components';
import type {
  DisciplineOption,
  LocationOption,
} from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { SelectMobile } from '@infield/features/select-mobile';
import { GlobalOverlay } from '@infield/features/ui';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  languages?: Language[];
  selectedLanguage?: Language;
  onSelectLanguage: (language: Language) => void;
  locations?: LocationOption[];
  selectedLocation?: LocationOption;
  onSelectLocation: (location: UserPreferenceLocation) => void;
  disciplines?: DisciplineOption[];
  selectedDiscipline?: DisciplineOption;
  onSelectDiscipline: (discipline: Discipline) => void;
  onComplete: () => void;
}

export const UserOnboarding: FC<Props> = ({
  languages,
  selectedLanguage,
  onSelectLanguage,
  locations,
  selectedLocation,
  onSelectLocation,
  disciplines,
  selectedDiscipline,
  onSelectDiscipline,
  onComplete,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.userOnboarding);
  const isDesktop = useIsDesktop();

  const languageOptions = languages?.map((language) => ({
    label: language.label,
    value: language,
  }));
  const currentLanguage = selectedLanguage
    ? {
        label: selectedLanguage.label,
        value: selectedLanguage,
      }
    : undefined;

  return (
    <S.UserOnboardingWrapper>
      <GlobalOverlay isOnline isOnChecklistPageOnMobile />
      <S.UserOnboardingContainer direction="column" gap={40}>
        <Flex direction="column">
          <Flex gap={4} alignItems="center">
            <Heading level={isDesktop ? 1 : 4}>
              {t('USER_ONBOARDING_DESKTOP_HEADING', 'Welcome to InField')}
            </Heading>
            <ProductLogo type="InField" size={isDesktop ? '28px' : '16px'} />
          </Flex>

          <S.SubHeading level={isDesktop ? 4 : 6}>
            {t(
              'USER_ONBOARDING_DESKTOP_HEADING_DESCRIPTION',
              'Select your app preference now, you can always adjust it later.'
            )}
          </S.SubHeading>
        </Flex>
        <Flex direction="column" gap={4}>
          <Body size="medium" strong>
            {t(
              'USER_ONBOARDING_DESKTOP_HEADING_SELECT_LANGUAGE_HEADING',
              'What is your preferred language?'
            )}
          </Body>
          <SelectMobile<Language>
            placeholder={t(
              'USER_ONBOARDING_DESKTOP_SELECT_LOCATION_PLACEHOLDER',
              'Select location'
            )}
            menuOptions={languageOptions || []}
            selectedValue={currentLanguage}
            selectValue={(language) => onSelectLanguage(language.value)}
          />
          <S.LanguageSubText size="x-small">
            {t(
              'USER_ONBOARDING_DESKTOP_HEADING_SELECT_LANGUAGE_HELP_TEXT',
              'This will be your application language'
            )}
          </S.LanguageSubText>
        </Flex>
        <Flex direction="column" gap={4}>
          <Flex>
            <Body size="medium" strong>
              {t(
                'USER_ONBOARDING_DESKTOP_HEADING_SELECT_LOCATION_HEADING',
                'What is your main working location?'
              )}
            </Body>
            <S.RequiredText strong>*</S.RequiredText>
          </Flex>
          <SelectMobile<UserPreferenceLocation>
            placeholder={t(
              'USER_ONBOARDING_DESKTOP_SELECT_LOCATION_PLACEHOLDER',
              'Select location'
            )}
            menuOptions={locations || []}
            selectedValue={selectedLocation}
            selectValue={(location) => onSelectLocation(location.value)}
          />
        </Flex>
        {disciplines && disciplines.length > 0 && (
          <Flex direction="column" gap={4}>
            <Flex>
              <Body size="medium" strong>
                {t(
                  'USER_ONBOARDING_DESKTOP_HEADING_SELECT_DISCIPLINE_HEADING',
                  'What is your discipline?'
                )}
              </Body>
            </Flex>
            <SelectMobile<Discipline>
              placeholder={t(
                'USER_ONBOARDING_DESKTOP_SELECT_DISCIPLINE_PLACEHOLDER',
                'Select discipline'
              )}
              menuOptions={disciplines || []}
              selectedValue={selectedDiscipline}
              selectValue={(discipline) => onSelectDiscipline(discipline.value)}
            />
          </Flex>
        )}
        <Flex>
          <Button
            size="medium"
            onClick={onComplete}
            disabled={!selectedLocation}
          >
            {t('USER_ONBOARDING_DESKTOP_SAVE', 'Done')}
          </Button>
        </Flex>
      </S.UserOnboardingContainer>
    </S.UserOnboardingWrapper>
  );
};
