import type { APMAsset } from '@cognite/apm-client';
import type { Asset } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

export const useAssetByNameQuery = <TData = Asset | APMAsset | null>(
  name: string,
  options?: UseQueryOptions<Asset | APMAsset | null, unknown, TData>
) => {
  const { isIdm } = useAppConfigContext();
  const { asset: configFilters } = useAppConfigFiltersContext();

  const { assetService } = useFDMServices();

  return useQuery<Asset | APMAsset | null, unknown, TData>(
    ['asset-by-name', name, configFilters?.cdfClassicFilters],
    async () => {
      let asset;
      if (isIdm) {
        asset = await assetService.getAPMAssetByName(
          name,
          configFilters!.dmFilters
        );
      } else {
        asset = await assetService.getAssetByName(
          name,
          configFilters!.cdfClassicFilters
        );
      }
      return asset || null;
    },
    {
      enabled:
        Boolean(name) &&
        Boolean(
          isIdm ? configFilters!.dmFilters : configFilters?.cdfClassicFilters
        ),
      ...options,
    }
  );
};
