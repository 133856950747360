import { Button, Flex, Modal } from '@cognite/cogs.js-v10';
import type { ModelAndRevision3D } from '@infield/features/3d';
import { ModelDetails, use3dModelList } from '@infield/features/3d';
import type { FC } from 'react';
import { useState } from 'react';

import * as S from './elements';

interface SelectModelDialogueProps {
  visible: boolean;
  onClose: () => void;
  onSelect: (revision: ModelAndRevision3D) => void;
}

export const SelectModelDialogue: FC<SelectModelDialogueProps> = ({
  visible,
  onClose,
  onSelect,
}): JSX.Element => {
  const { data } = use3dModelList();

  const [selectedModel, setSelectedModel] = useState<ModelAndRevision3D>();

  const renderFooter = () => {
    return (
      <Flex direction="column" gap={8}>
        <Button
          onClick={() => onSelect(selectedModel!)}
          disabled={!selectedModel}
        >
          Select
        </Button>
      </Flex>
    );
  };

  return (
    <Modal
      visible={visible}
      size="medium"
      hideFooter
      title="Select 3d Model"
      onCancel={() => onClose()}
    >
      <S.Container>
        <Flex direction="column" gap={16}>
          {data?.map((revision) => (
            <S.ModelContainer
              key={revision.revisionId}
              selected={selectedModel?.revisionId === revision.revisionId}
            >
              <ModelDetails
                {...revision}
                onClick={() => setSelectedModel(revision)}
              />
            </S.ModelContainer>
          ))}
        </Flex>
      </S.Container>
      {renderFooter()}
    </Modal>
  );
};
