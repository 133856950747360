import type { APMAsset } from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client';
import { useAppConfigFiltersContext } from '@infield/providers/app-config-filters-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

type Props = {
  query: string;
  assetExternalId?: string;
  enabled: boolean;
};

export const useAPMAssetHierarchySearch = ({
  query,
  assetExternalId,
  enabled,
}: Props) => {
  const { apmClient } = useFDMServices();
  const { asset: configFilters } = useAppConfigFiltersContext();

  return useQuery<
    { parentAsset: APMAsset | undefined; childAssets: APMAsset[] } | undefined
  >(
    [QueryKeys.ASSET_HIERARCHY_SEARCH, query, assetExternalId],
    async () => {
      if (assetExternalId) {
        const [asset] = await apmClient.assets.byId(
          [
            {
              externalId: assetExternalId,
            },
          ],
          {
            propertyTree: {
              parent: {},
            },
          }
        );
        const parentAsset = asset.parent;

        let childAssets: APMAsset[] = [];
        const childAssetFilter: Filters = {
          directRelationFilter: {
            parent: {
              equals: {
                property: 'externalId',
                eq: assetExternalId,
              },
            },
          },
          directRelationInstanceSpace: apmClient.sourceDataInstanceSpace,
        };
        const filters = [childAssetFilter];

        if (configFilters?.dmFilters) {
          filters.push(configFilters.dmFilters);
        }
        if (query) {
          childAssets = await apmClient.assets.search({
            query,
            spaces: [],
            filters: { and: filters },
            properties: ['title', 'description'],
          });
        } else {
          const listAssets = await apmClient.assets.list({
            filters: childAssetFilter,
            properties: ['title', 'description'],
          });
          childAssets = listAssets.items;
        }

        return { parentAsset, childAssets };
      }
    },
    {
      enabled: Boolean(assetExternalId) && enabled,
    }
  );
};
