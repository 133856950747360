import { Button, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const MigrationRequiredMessageContainer = styled(Flex)`
  width: 1000px;
`;

export const MigrateConfigButton = styled(Button)`
  width: 300px;
`;
