import { Body, Heading } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Value = styled(Heading).attrs({ level: 3 })<{
  $color: string;
}>`
  color: ${({ $color }) => $color};
  display: inline-block;
`;

export const LastUpdated = styled(Body).attrs({ size: 'x-small' })`
  margin: 0;
  color: ${({ theme }) => theme['text-icon--muted']};
`;
