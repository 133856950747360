import * as de from './de/ai.json';
import * as en from './en/ai.json';
import * as es from './es/ai.json';
import * as fr from './fr/ai.json';
import * as it from './it/ai.json';
import * as ja from './ja/ai.json';
import * as ko from './ko/ai.json';
import * as lv from './lv/ai.json';
import * as nl from './nl/ai.json';
import * as pt from './pt/ai.json';
import * as sv from './sv/ai.json';
import * as zh from './zh/ai.json';

/**
 * AI lib translations
 */
export const translations = {
  en: { ai: en },
  de: { ai: de },
  es: { ai: es },
  fr: { ai: fr },
  it: { ai: it },
  ja: { ai: ja },
  ko: { ai: ko },
  lv: { ai: lv },
  nl: { ai: nl },
  pt: { ai: pt },
  sv: { ai: sv },
  zh: { ai: zh },
};
