import Konva from 'konva';

import getAnnotationSerializerByType from '../annotations/getAnnotationSerializerByType';
import isSupportedAnnotationType from '../annotations/isSupportedAnnotationType';
import getContainerFromContainerNode from '../containers/getContainerFromContainerNode';

import isContainerNode from './isContainerNode';

// Container node utils

export const isSelectableContainerNode = (node: Konva.Node): boolean => {
  if (!isContainerNode(node)) {
    return false;
  }

  const container = getContainerFromContainerNode(node);
  if (container === undefined) {
    return false;
  }

  return container.isSelectable();
};

export const isDraggableContainerNode = (node: Konva.Node): boolean => {
  if (!isContainerNode(node)) {
    return false;
  }

  const container = getContainerFromContainerNode(node);
  if (container === undefined) {
    return false;
  }

  return container.isDraggable();
};

export const isResizableContainerNode = (node: Konva.Node): boolean => {
  if (!isContainerNode(node)) {
    return false;
  }

  const container = getContainerFromContainerNode(node);
  if (container === undefined) {
    return false;
  }

  return container.isResizable();
};

// Annotation node utils

export const isResizableAnnotationNode = (node: Konva.Node): boolean =>
  isSupportedAnnotationType(node.attrs.annotationType) &&
  getAnnotationSerializerByType(node.attrs.annotationType).isResizable(node);

export const isSelectableAnnotationNode = (node: Konva.Node): boolean =>
  isSupportedAnnotationType(node.attrs.annotationType) &&
  getAnnotationSerializerByType(node.attrs.annotationType).isSelectable(node);

export const isDraggableAnnotationNode = (node: Konva.Node): boolean =>
  isSupportedAnnotationType(node.attrs.annotationType) &&
  getAnnotationSerializerByType(node.attrs.annotationType).isDraggable(node);

// Annotation & Container node utils

export const isResizableNode = (node: Konva.Node): boolean =>
  isResizableAnnotationNode(node) || isResizableContainerNode(node);

export const isSelectableNode = (node: Konva.Node): boolean =>
  node.visible() &&
  (isSelectableAnnotationNode(node) || isSelectableContainerNode(node));

export const isDraggableNode = (node: Konva.Node): boolean => {
  return (
    (isSelectableAnnotationNode(node) && isDraggableAnnotationNode(node)) ||
    isDraggableContainerNode(node)
  );
};
