import clamp from 'lodash/clamp';

import { EllipseAnnotation } from '../annotations/types';

const constrainEllipsePosition = (
  annotation: EllipseAnnotation
): EllipseAnnotation => {
  const radiusX =
    typeof annotation.radius === 'number'
      ? annotation.radius
      : annotation.radius.x;
  const radiusY =
    typeof annotation.radius === 'number'
      ? annotation.radius
      : annotation.radius.y;
  return {
    ...annotation,
    x: clamp(annotation.x, 0, 1 - radiusX),
    y: clamp(annotation.y, 0, 1 - radiusY),
  };
};

export default constrainEllipsePosition;
