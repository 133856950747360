import { Button, Heading } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { captureException } from '@sentry/browser';
import { useEffect, useRef } from 'react';

import { ComponentWrapper } from './elements';

interface Props {
  fullPage?: boolean;
  error?: Error;
}

const ErrorView = ({ fullPage = false, error }: Props) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.errorView);

  // that's in case some component recreate the same error multiple times, so we keep track if it's the same message (the same error)
  const lastErrorMessageRef = useRef('');

  useEffect(() => {
    if (error && error?.message !== lastErrorMessageRef.current) {
      captureException(error);
      lastErrorMessageRef.current = error.message;
    }
  }, [error]);

  return (
    <ComponentWrapper fullPage={fullPage}>
      {fullPage && (
        <Heading level={2}>{t('whoops', { defaultValue: 'Whoops!' })}</Heading>
      )}
      <p>
        {t('somethingWentWrong', {
          defaultValue: 'Something went wrong. Please reload the page',
        })}
      </p>
      {error?.message ? <p>{error.message}</p> : null}

      <Button
        onClick={() => window.location.reload()}
        type="primary"
        style={fullPage ? { width: 200 } : undefined}
      >
        {t('reload', { defaultValue: 'Reload' })}
      </Button>
    </ComponentWrapper>
  );
};

export default ErrorView;
