import { Button, HamburgerMenuIcon, SearchIcon } from '@cognite/cogs.js-v10';
import type { FunctionComponent } from 'react';

import * as S from './elements';

type Props = {
  title?: string;
  isTitleClickable?: boolean;
  showSearch?: boolean;
  darkMode?: boolean;
  isInteractionDisabled?: boolean;
  expandOverflow?: boolean;
  onSearchButtonClick?: () => void;
  onMenuButtonClick: () => void;
  handleOnTitleClick: () => void;
};

export const TopBarMobileContent: FunctionComponent<Props> = ({
  title,
  isTitleClickable = false,
  showSearch = true,
  darkMode = false,
  isInteractionDisabled = false,
  expandOverflow = false,
  onSearchButtonClick,
  onMenuButtonClick,
  handleOnTitleClick,
}) => (
  <S.MobileTopBarWrapper $darkMode={darkMode} $expandOverflow={expandOverflow}>
    <Button
      size="large"
      icon={<HamburgerMenuIcon />}
      aria-label="mobile-topbar-hamburger-menu"
      data-testid="mobile-topbar-menu-button"
      themeProps={darkMode ? { theme: 'base', inverted: true } : undefined}
      onClick={onMenuButtonClick}
    />
    <S.TopBarHeaderWrapper>
      {Boolean(title) && (
        <S.TopBarHeader
          onClick={handleOnTitleClick}
          $expandOverflow={expandOverflow}
          $isTitleClickable={isTitleClickable}
        >
          {title}
        </S.TopBarHeader>
      )}
    </S.TopBarHeaderWrapper>
    {showSearch && (
      <Button
        size="large"
        data-testid="mobile-topbar-search"
        aria-label="mobile-topbar-search"
        icon={<SearchIcon />}
        disabled={isInteractionDisabled}
        themeProps={darkMode ? { theme: 'base', inverted: true } : undefined}
        onClick={onSearchButtonClick}
      />
    )}
  </S.MobileTopBarWrapper>
);
