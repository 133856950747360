import type { GridFilterModel } from '@cognite/apm-observation';
import type { Filters } from '@cognite/fdm-client';
import type { FieldInfo } from '@infield/features/activities/hooks/use-query/types';

export const gridFilterToFDMFilter = (
  filterModel: GridFilterModel,
  // Mapping between grid filter fields and FDM fields
  // Only provide this if fields are different
  fieldsMapping: { [key: string]: string } = {},

  // Fields info to determine if field is a list
  // No need to provide this if you don't have any list fields
  fieldsInfo: FieldInfo[] = [],

  // Allows you to merge existing filters with grid filters
  // Here we assume that grid filters and other filters doesn't have any common field
  otherFilters?: Filters
): Filters | undefined => {
  if (filterModel.length === 0) {
    if (otherFilters) return { and: [otherFilters] };

    return undefined;
  }

  const isList = (fieldName: string) =>
    Boolean(fieldsInfo.find((field) => field.name === fieldName)?.list);

  const isDirectRelation = (fieldName: string) => {
    return (
      fieldsInfo.find((field) => field.name === fieldName)?.type === 'direct'
    );
  };

  return {
    and: [
      ...(otherFilters ? [otherFilters] : []),
      ...filterModel.map((filter) => {
        return {
          or: filter.value.map((value: string) => {
            const property = fieldsMapping[filter.field] || filter.field;
            if (isList(filter.field)) {
              return {
                containsAny: {
                  property,
                  containsAny: [value],
                },
              };
            }
            if (isDirectRelation(filter.field)) {
              return {
                directRelationFilter: {
                  [filter.field]: {
                    equals: {
                      property: 'externalId',
                      eq: value,
                    },
                  },
                },
              };
            }

            return {
              equals: {
                property,
                eq: value,
              },
            };
          }),
        };
      }),
    ],
  };
};

export const FDMFilterToGridFilter = (
  fdmFiltersToConvert: Filters,
  // Mapping between grid filter fields and FDM fields
  // Only provide this if fields are different
  fieldsMapping: { [key: string]: string } = {}
): GridFilterModel | undefined => {
  const fdmFilters = fdmFiltersToConvert.and;
  if (!fdmFilters || fdmFilters?.length === 0) {
    return undefined;
  }

  const fdmFiltersList = fdmFilters.flatMap((fdmFilter) => {
    return fdmFilter.or || [];
  });

  const gridFilters = fdmFiltersList
    .map((fdmFilter) => {
      if (fdmFilter?.equals) {
        return {
          field: fdmFilter.equals.property,
          value: [fdmFilter.equals.eq],
          operator: 'and',
        };
      }
      if (fdmFilter?.containsAny) {
        return {
          field: fdmFilter.containsAny.property,
          value: fdmFilter.containsAny.containsAny,
          operator: 'and',
        };
      }
      return undefined;
    })
    .filter(Boolean) as GridFilterModel;

  return gridFilters.reduce<GridFilterModel>(
    (gridFiltersList, currentValue) => {
      const field = fieldsMapping[currentValue.field] || currentValue.field;
      const index = gridFiltersList.findIndex((list) => list.field === field);
      let updatedGridFiltersList;

      if (index >= 0 && gridFiltersList[index]) {
        updatedGridFiltersList = [...gridFiltersList];
        updatedGridFiltersList[index] = {
          field,
          value: [...gridFiltersList[index].value, ...currentValue.value],
          operator: 'and',
        };
      } else {
        updatedGridFiltersList = [
          ...gridFiltersList,
          { field, value: currentValue.value, operator: 'and' },
        ];
      }
      return updatedGridFiltersList;
    },
    []
  );
};
