import type { FdmFile } from '@cognite/apm-client';
import { Flex } from '@cognite/cogs.js-v10';
import EmptyImg from '@infield/assets/EmptyImg.svg';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { MediaFetcher, MediaViewer } from '@infield/features/media';
import type { ImageToUpload } from '@infield/features/media/types';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import type { FC } from 'react';

import { MEDIA_COLUMN_DESKTOP, MEDIA_COLUMN_MOBILE } from './consts';
import * as S from './elements';

export interface Props {
  fileInfoList: FdmFile[];
  onSelectMedia: (media: ImageToUpload) => void;

  isLoading: boolean;
  loadingText?: string;
}

export const AssetMediaGrid: FC<Props> = ({
  fileInfoList,
  onSelectMedia,
  isLoading,
  loadingText,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);

  const isDesktop = useIsDesktop();

  if (fileInfoList.length === 0 || isLoading) {
    return (
      <Flex justifyContent="center">
        <S.StyledMediaEmptyView
          isLoading={isLoading}
          loadingText={loadingText}
          text={t(
            'EMPTY_STATE_MESSAGE_NO_IMAGES_OR_VIDEOS',
            'No images or videos uploaded yet. Select an option below to add images or videos.'
          )}
          emptyImage={EmptyImg}
        />
      </Flex>
    );
  }

  return (
    <S.MediaGrid cols={isDesktop ? MEDIA_COLUMN_DESKTOP : MEDIA_COLUMN_MOBILE}>
      {fileInfoList.map(({ externalId, space }) => (
        <MediaFetcher
          key={externalId}
          fileExternalId={externalId}
          fileSpace={space}
        >
          {({ media }) => (
            <MediaViewer
              key={externalId || media?.externalId}
              media={media}
              onClick={() => onSelectMedia(media)}
            />
          )}
        </MediaFetcher>
      ))}
    </S.MediaGrid>
  );
};
