import type { APMAsset } from '@cognite/apm-client';
import {
  ArrowLeftIcon,
  Button,
  CloseIcon,
  ExpandIcon,
} from '@cognite/cogs.js-v10';
import type { Asset } from '@cognite/sdk';
import { selectedAssetViewAtom } from '@infield/features/asset';
import { AssetDescription, Header } from '@infield/features/asset/elements';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { getTitle } from '@infield/features/search/search-result/utils';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import * as S from './elements';

interface Props {
  assetData: Asset | APMAsset;
  canBeExpanded?: boolean;
  onClose?: () => void;
}

export const AssetHeader: FC<Props> = ({
  onClose,
  assetData,
  canBeExpanded = true,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);

  const selectedAssetCard = useRecoilValue(selectedAssetViewAtom);
  const resetSelectedAssetCard = useResetRecoilState(selectedAssetViewAtom);

  const navigate = useNavigate();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    resetSelectedAssetCard();
  };

  const handleAssetClick = () => {
    if (canBeExpanded) {
      resetSelectedAssetCard();
      navigate(`/asset/${encodeURIComponent(assetData.externalId!)}`);
    }
  };

  return (
    <Header gap={8}>
      {selectedAssetCard && canBeExpanded && (
        <S.AssetBackButton
          icon={<ArrowLeftIcon />}
          aria-label="reset-selected-asset-card"
          onClick={resetSelectedAssetCard}
        />
      )}
      <S.AssetInformation>
        <S.AssetTitle onClick={handleAssetClick}>
          {getTitle(assetData)}
        </S.AssetTitle>
        <AssetDescription>{assetData.description}</AssetDescription>
      </S.AssetInformation>
      <S.AssetActionsWrapper>
        {canBeExpanded && (
          <Button
            icon={<ExpandIcon />}
            onClick={handleAssetClick}
            type="ghost"
          />
        )}
        {onClose && (
          <Button
            data-testid="asset-header-close-button"
            icon={<CloseIcon />}
            onClick={handleClose}
            type="ghost"
            aria-label={t('ASSET_INFO_CLOSE', 'Close')}
          />
        )}
      </S.AssetActionsWrapper>
    </Header>
  );
};
