import type { ChecklistStatus } from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { getUniqueExternalIds } from '@infield/utils/filtering-helpers';
import { QueryKeys } from '@infield/utils/queryKeys';
import { getSortPropertiesWithTiebreaker } from '@infield/utils/tiebreaker-utils';
import { captureException } from '@sentry/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

type Props = {
  checklistStatus?: ChecklistStatus;
  checklistType?: string;
  assignedTo?: string[];
  pageSize?: number;
};

export const useChecklistList = ({
  checklistStatus,
  checklistType,
  assignedTo,
  pageSize,
}: Props) => {
  const { checklistService } = useFDMServices();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);
  const { checklist: configFilters } = useAppConfigFiltersContext();

  const filters: Filters[] = [];

  if (checklistStatus) {
    filters.push({
      equals: {
        property: 'status',
        eq: checklistStatus,
      },
    });
  }

  if (checklistType) {
    filters.push({
      equals: {
        property: 'type',
        eq: checklistType,
      },
    });
  }

  if (assignedTo && assignedTo.length > 0) {
    filters.push({
      containsAny: {
        property: 'assignedTo',
        containsAny: assignedTo,
      },
    });
  }

  const checklistFilter: Filters = {
    and: [...filters],
  };

  if (configFilters.rootAssetExternalIds) {
    checklistFilter.and?.push(configFilters.rootAssetExternalIds);
  }

  const sort = getSortPropertiesWithTiebreaker([
    {
      startTime: 'DESC',
    },
  ]);

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  const { data, ...rest } = useInfiniteQuery(
    [QueryKeys.CHECKLISTS_LIST, checklistFilter, pageSize],
    async ({ pageParam = undefined }) => {
      sliTimerStartTime.current = Date.now();

      return checklistService.getChecklists(
        checklistFilter,
        sort,
        pageParam,
        pageSize
      );
    },
    {
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.CHECKLISTS_LIST,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.CHECKLISTS_LIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.CHECKLISTS_LIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      getNextPageParam: (lastPage) =>
        lastPage?.pageInfo.hasNextPage
          ? lastPage.pageInfo.endCursor
          : undefined,
      refetchOnWindowFocus: false,
      enabled: Boolean(configFilters.rootAssetExternalIds),
    }
  );

  const checklists = data?.pages.flatMap((page) => page.items) || [];
  const paginatedChecklistIds =
    data?.pages.map((page) => getUniqueExternalIds(page.items)) || [];

  return { checklists, paginatedChecklistIds, ...rest };
};
