import { Button, Tooltip } from '@cognite/cogs.js-v10';
import { Container, LoginForm } from '@cognite/e2e-auth';
import type { FC } from 'react';

type Props = {
  userSpace: string | undefined;
  hasAppConfigPermission: boolean | undefined;
  onShowAppConfig: () => void;
  onLogout: () => void;
};

export const MissingPermissionsErrorComponent: FC<Props> = ({
  userSpace,
  hasAppConfigPermission,
  onShowAppConfig,
  onLogout,
}) => {
  return (
    <Container>
      <LoginForm>
        <header>
          <h2>Error</h2>
          <h1>Missing necessary permissions</h1>
        </header>
        <p>
          You are missing access to the space where your user profile is stored.
          <ul>
            <li>
              Make sure you have both read and write access to the following
              space: <b>{userSpace}</b>{' '}
            </li>
            <li>
              Check that the correct app data model is configured for the app.{' '}
              {hasAppConfigPermission ? (
                <Button type="ghost-accent" onClick={() => onShowAppConfig()}>
                  Go to configuration
                </Button>
              ) : (
                <Tooltip content="You do not have access to configuration">
                  <Button disabled type="ghost-accent">
                    Go to configuration
                  </Button>
                </Tooltip>
              )}
            </li>
          </ul>
        </p>
        <Button onClick={onLogout} type="primary" style={{ width: '100%' }}>
          Logout
        </Button>
      </LoginForm>
    </Container>
  );
};
