import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const DesktopWrapper = styled(Flex)`
  width: 400px;
`;

export const ResizeObserverWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
