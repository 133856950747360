import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Wrapper = styled(Flex).attrs({
  alignItems: 'center',
  direction: 'column',
  justifyContent: 'center',
})`
  padding: '16px';
  flex: 1;
`;
