import {
  DEFAULT_ACTIVITY_COLUMNS_FIELD_NAME,
  DEFAULT_ACTIVITY_COLUMNS_FIELD_NAME_AKERBP,
  STRING_FIELD_TYPE,
} from '../consts';
import type { FieldInfo } from '../hooks/use-query/types';

export const getCustomerSpecificFilterableFields = (
  fields: FieldInfo[],
  isAkerbpCustomCode: boolean
) => {
  return fields
    .filter(
      ({ name, type }) =>
        name &&
        !(
          isAkerbpCustomCode
            ? DEFAULT_ACTIVITY_COLUMNS_FIELD_NAME_AKERBP
            : DEFAULT_ACTIVITY_COLUMNS_FIELD_NAME
        ).includes(name) &&
        type === STRING_FIELD_TYPE
    )
    .map((field) => field.name);
};
