import styled from 'styled-components';

import { Body, Colors, Flex, Link } from '@cognite/cogs.js-v10';

import { TableItem } from '../../..';

export const TableItemContent: React.FC<TableItem> = ({
  label,
  value,
  link,
}) => {
  return (
    <Flex>
      <TableItemContainer>
        <Body strong>{label}</Body>
        <Spacer />

        {value !== undefined &&
          (link !== undefined ? (
            <Link
              href={link}
              size="small"
              target="_blank"
              className="table-item-value"
            >
              {value}
            </Link>
          ) : (
            <Body className="table-item-value">{value}</Body>
          ))}
        {value === undefined && <Body muted>–</Body>}
      </TableItemContainer>
    </Flex>
  );
};

const TableItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  padding: 6px 0;

  .table-item-value {
    overflow-wrap: anywhere;
    word-break: normal;
  }
`;

const Spacer = styled.div`
  flex: 1;
  border-bottom: ${Colors['border--muted']} 1px dashed;
  margin: 4px 8px;
  min-width: 60px;
  height: 14px;
`;

export default TableItemContent;
