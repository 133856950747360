import { useAuthContext } from '@cognite/e2e-auth';

export const useInfieldLogout = () => {
  const { logout } = useAuthContext();

  const customLogout = () => {
    logout();
  };

  return { logout: customLogout };
};
