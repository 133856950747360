import { Flex } from '@cognite/cogs.js-v10';
import type { RootLocationFeatureToggles } from '@infield/features/app-config';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { SidebarItem } from './sidebar-item';
import { getGeneralNavigationItems } from './utils';

type Props = {
  rootLocationFeatureToggles?: RootLocationFeatureToggles;
  isOnline: boolean;
  currentAppLocation: string;
  onNavigationItemClick: (navigateTo: string) => void;
};

export const SidebarContent: FC<Props> = ({
  rootLocationFeatureToggles,
  isOnline,
  currentAppLocation,
  onNavigationItemClick,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.sidebar);

  const generalNavigationItems = getGeneralNavigationItems(
    t,
    rootLocationFeatureToggles
  );

  const isToggled = (navigationItemPath: string) =>
    currentAppLocation.includes(navigationItemPath);

  return (
    <Flex direction="column" gap={4}>
      {generalNavigationItems.map((item) => (
        <SidebarItem
          key={item.key}
          toggled={isToggled(item.to)}
          icon={item.icon}
          disabled={!isOnline}
          onClick={() => onNavigationItemClick(item.to)}
        >
          {item.name}
        </SidebarItem>
      ))}
    </Flex>
  );
};
