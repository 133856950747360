import { Body, Button, DeleteIcon, Flex, Input } from '@cognite/cogs.js-v10';
import { TextInputModal } from '@infield/features/template';
import { ComponentContainer } from '@infield/features/ui';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useAppConfigQuery } from '../hooks';
import { useUpsertAppConfigMutation } from '../hooks/use-mutation';
import type { AppConfig } from '../types';

import * as S from './elements';

interface Props {
  hasAppConfigPermission: boolean;
}

export const AppConfigNotifications: FC<Props> = ({
  hasAppConfigPermission,
}) => {
  const [overviewCardFilters, setOverviewCardFilters] = useState<
    Record<string, { externalId: string; value: string }[]>
  >({});

  const [showModal, setShowModal] = useState(false);

  const { data: appConfig, isSuccess, isLoading } = useAppConfigQuery();
  const { mutateAsync: upsertAppConfig } = useUpsertAppConfigMutation();
  const notificationsConfiguration =
    appConfig?.featureConfiguration?.notifications;

  useEffect(() => {
    if (isSuccess && notificationsConfiguration) {
      if (notificationsConfiguration?.overviewCard?.filters) {
        setOverviewCardFilters(notificationsConfiguration.overviewCard.filters);
      }
    }
  }, [isSuccess, notificationsConfiguration, appConfig]);

  const hasConfigChanged =
    overviewCardFilters !==
    (notificationsConfiguration?.overviewCard?.filters ?? '');

  const handleSave = async () => {
    if (!appConfig) return;

    const newAppConfig: AppConfig = {
      ...appConfig,
      featureConfiguration: {
        ...appConfig.featureConfiguration,
        notifications: {
          ...appConfig.featureConfiguration?.notifications,
          overviewCard: {
            ...appConfig.featureConfiguration?.notifications?.overviewCard,
            filters: overviewCardFilters,
          },
        },
      },
    };

    await upsertAppConfig({
      newAppConfig,
    });
  };

  const handleOnFilterValueChange = (
    field: string,
    value: string,
    externalId: string
  ) => {
    setOverviewCardFilters((prev) => ({
      ...prev,
      [field]: prev[field].map((prevFilterValue) => {
        if (prevFilterValue.externalId === externalId) {
          return {
            ...prevFilterValue,
            value,
          };
        }
        return prevFilterValue;
      }),
    }));
  };

  const handleDeleteFilterValue = (field: string, externalId: string) => {
    setOverviewCardFilters((prev) => ({
      ...prev,
      [field]: prev[field].filter(
        (prevFilterValue) => prevFilterValue.externalId !== externalId
      ),
    }));
  };

  const handleDeleteField = (field: string) => {
    setOverviewCardFilters((prev) => {
      const state = { ...prev };
      delete state[field];
      return state;
    });
  };

  const handleAddField = (field: string) => {
    setShowModal(false);
    setOverviewCardFilters({
      ...overviewCardFilters,
      [field]: [],
    });
  };

  const notificationConfigs = (
    <Flex direction="column" gap={8}>
      <Body size="x-small">
        Configure how the notifications shall display data in the asset
        notifications card or notifications full page. Fields are configured by
        its CDF field name, eg *id*, *name* or *metadata.title*.
      </Body>

      <Body size="x-small" strong>
        Overview Card Filters
      </Body>
      <Flex gap={16} direction="column">
        {Object.keys(overviewCardFilters).map((field) => (
          <Flex gap={8} key={field} direction="column">
            <Flex gap={8}>
              <Body size="x-small" strong>
                Field name:
              </Body>
              <Body size="x-small">{field}</Body>
              <Button
                icon={<DeleteIcon />}
                type="destructive"
                onClick={() => handleDeleteField(field)}
              />
            </Flex>

            <Flex gap={8}>
              <Body size="x-small" strong>
                Accepted values:
              </Body>
              {overviewCardFilters[field].map((filter) => (
                <Flex key={filter.externalId} gap={8}>
                  <Input
                    disabled={!hasAppConfigPermission}
                    value={filter.value}
                    onChange={(e) =>
                      handleOnFilterValueChange(
                        field,
                        e.target.value,
                        filter.externalId
                      )
                    }
                  />
                  <Button
                    icon={<DeleteIcon />}
                    type="destructive"
                    onClick={() =>
                      handleDeleteFilterValue(field, filter.externalId)
                    }
                  />
                </Flex>
              ))}

              <Button
                onClick={() =>
                  setOverviewCardFilters({
                    ...overviewCardFilters,
                    [field]: [
                      ...overviewCardFilters[field],
                      { externalId: uuid(), value: '' },
                    ],
                  })
                }
              >
                Add filter value
              </Button>
            </Flex>
          </Flex>
        ))}
      </Flex>

      <S.FixedWidthButton onClick={() => setShowModal(true)}>
        Add filter field
      </S.FixedWidthButton>
    </Flex>
  );

  const saveButton = (
    <Flex direction="column" gap={4}>
      <S.FixedWidthButton
        onClick={handleSave}
        disabled={!hasConfigChanged}
        loading={isLoading}
      >
        Save configuration
      </S.FixedWidthButton>
    </Flex>
  );

  return (
    <ComponentContainer>
      <S.Wrapper>
        <Flex direction="column" gap={16}>
          {notificationConfigs}
          {saveButton}
        </Flex>
      </S.Wrapper>

      <TextInputModal
        size="small"
        visible={showModal}
        title="Add new filter field"
        inputLabel="Field name"
        okText="Add field"
        onSubmit={handleAddField}
        onCancel={() => setShowModal(false)}
      >
        {null}
      </TextInputModal>
    </ComponentContainer>
  );
};
