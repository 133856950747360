import type { Checklist } from '@cognite/apm-client';
import { addRecoilKey } from '@infield/utils/recoil';
import { atom } from 'recoil';

export const loadedChecklistIdsAtom = atom<string[]>({
  key: addRecoilKey('loaded-checklist-ids'),
  default: [],
});

export const currentChecklistAtom = atom<Checklist | undefined>({
  key: addRecoilKey('activity-list-current-activity'),
  default: undefined,
});
