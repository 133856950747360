import type { APMAsset, Observation } from '@cognite/apm-client';
import { Flex } from '@cognite/cogs.js-v10';
import type { Asset } from '@cognite/sdk';
import { useSelectedRootAsset } from '@infield/features/asset';
import { useUpdateMedia } from '@infield/features/media';
import { useQueryClient } from '@tanstack/react-query';
import type { FC } from 'react';

import type { Step } from '../types';
import {
  getContextualizedAssetsForMedia,
  getIsObservationLocked,
  invalidateMediaQueries,
  prepareMediaUpdates,
} from '../utils';

import * as S from './elements';
import { ObservationDetailsDesktopAssetInput } from './observation-desktop-asset-input';
import { ObservationDesktopFileUploader } from './observation-desktop-file-uploader';
import { ObservationDesktopProblemInput } from './observation-desktop-problem-input';
import { ObservationDesktopSelectInput } from './observation-desktop-select-input/observation-desktop-select-input';
import { ObservationDesktopTroubleshootInput } from './observation-desktop-troubleshoot-input';

interface Props {
  observation?: Observation;
  observationFields?: Step[];
  onUpdate: (observation: Partial<Observation>) => void;
}

export const ObservationDesktop: FC<Props> = ({
  observation,
  observationFields,
  onUpdate,
}) => {
  const isLocked = getIsObservationLocked(observation);
  const { data: rootAsset } = useSelectedRootAsset();
  const queryClient = useQueryClient();
  const { mutateAsync: updateMedia } = useUpdateMedia();

  const getFieldInfo = (field: string) => {
    return observationFields?.find(({ key }) => key === field);
  };

  const handleObservationUpdate = (data: Partial<Observation>) => {
    onUpdate({
      ...data,
    });
  };

  const handleAssetChange = (asset?: Asset, apmAsset?: APMAsset) => {
    const previousAssetExternalId = observation?.asset?.externalId;
    handleObservationUpdate({
      asset: apmAsset ?? null,
    });
    if (observation?.files && observation?.files.length > 0) {
      const contextualizedAssets = getContextualizedAssetsForMedia(
        asset,
        apmAsset,
        rootAsset
      );
      const mediaUpdates = prepareMediaUpdates(
        observation?.files,
        contextualizedAssets
      );
      updateMedia({ items: mediaUpdates }).then(() => {
        invalidateMediaQueries(
          queryClient,
          previousAssetExternalId,
          asset?.externalId
        );
      });
    }
  };

  const priorityInformation = getFieldInfo('priority');
  const typeInformation = getFieldInfo('type');

  return (
    <S.ObservationDesktopContainer>
      <Flex direction="column" gap={16}>
        <S.Row>
          <ObservationDetailsDesktopAssetInput
            isLocked={isLocked}
            step={getFieldInfo('asset')}
            asset={observation?.asset ?? undefined}
            onChange={(classicAsset?: Asset, apmAsset?: APMAsset) => {
              handleAssetChange(classicAsset, apmAsset);
            }}
          />
          <ObservationDesktopSelectInput
            isLocked={isLocked}
            label={priorityInformation?.name}
            options={priorityInformation?.options}
            placeholder={priorityInformation?.description}
            value={observation?.priority}
            required={priorityInformation?.isRequired}
            onChange={(value) => handleObservationUpdate({ priority: value })}
          />
        </S.Row>
        <S.Row>
          <ObservationDesktopSelectInput
            label={typeInformation?.name}
            options={typeInformation?.options}
            placeholder={typeInformation?.description}
            value={observation?.type}
            isLocked={isLocked}
            required={priorityInformation?.isRequired}
            onChange={(value) => handleObservationUpdate({ type: value })}
          />
        </S.Row>
        <S.Row>
          <ObservationDesktopProblemInput
            step={getFieldInfo('description')}
            value={observation?.description}
            isLocked={isLocked}
            onChange={(value) =>
              handleObservationUpdate({ description: value })
            }
          />
          <ObservationDesktopTroubleshootInput
            step={getFieldInfo('troubleshooting')}
            value={observation?.troubleshooting}
            isLocked={isLocked}
            onChange={(value) =>
              handleObservationUpdate({ troubleshooting: value })
            }
          />
        </S.Row>
        <S.Row>
          <ObservationDesktopFileUploader
            step={getFieldInfo('files')}
            observation={observation}
            isLocked={isLocked}
            handleObservationUpdate={handleObservationUpdate}
          />
        </S.Row>
      </Flex>
    </S.ObservationDesktopContainer>
  );
};
