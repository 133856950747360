import { Flex, Heading } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Content = styled(Flex).attrs({
  direction: 'column',
  alignItems: 'center',
})`
  /* TODO(UX-1811): we don't have these gradient colors in design system */
  background: linear-gradient(
    152deg,
    #191a23 25.18%,
    #242633 57.67%,
    #3f4357 100%
  );
  height: 100%;
`;

export const Wrapper = styled(Flex).attrs({
  direction: 'column',
})`
  padding: 40px 16px 0px 16px;
  max-width: 500px;
  width: 100%;
`;

export const Header = styled(Heading).attrs({
  level: 3,
  inverted: true,
})`
  align-self: center;
  margin-bottom: 20px;
`;
