const modifySvgOverplotClipPathToInternalUrlReference = (
  svg: SVGElement
): void => {
  const svgDef = svg.querySelector('defs');
  const defId = svgDef?.id.split('-')[1];
  if (defId === undefined || defId.length === 0) {
    return;
  }

  const overplot = svg.querySelector('.overplot') as SVGGElement | null;
  if (overplot === null) {
    return;
  }

  const clipPathUpdates: { node: SVGGElement; clipPath: string }[] = [];
  Array.from(overplot.children).forEach((node) => {
    if (node instanceof SVGGElement) {
      const clipPath = `url(#clip${defId}${node.classList.value}plot)`;
      clipPathUpdates.push({ node, clipPath });
    }
  });
  // Batch-updating all clip paths
  clipPathUpdates.forEach(({ node, clipPath }) => {
    node.style.clipPath = clipPath;
  });
};

export default modifySvgOverplotClipPathToInternalUrlReference;
