import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { useAppConfigQuery } from '@infield/features/app-config';
import { currentChecklistAtom } from '@infield/features/checklist/state';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useSetRecoilState } from 'recoil';

export const useChecklist = (externalId: string) => {
  const { checklistService, conditionalActionsService } = useFDMServices();
  const { data: appConfig } = useAppConfigQuery();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);
  const setCurrentChecklist = useSetRecoilState(currentChecklistAtom);

  const isObservationsEnabled = Boolean(
    appConfig?.featureConfiguration?.observations?.enabled
  );

  const checklistFilter: Filters = {
    equals: {
      property: 'externalId',
      eq: externalId,
    },
  };

  const itemsSort: Sort = [
    {
      order: 'ASC',
    },
  ];

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  return useQuery(
    [QueryKeys.CHECKLIST, checklistFilter, itemsSort],
    async () => {
      sliTimerStartTime.current = Date.now();

      const checklist = await checklistService.getChecklistData(
        checklistFilter,
        undefined,
        undefined,
        itemsSort,
        isObservationsEnabled
      );

      const checklistItemExternalIds: string[] =
        checklist.checklistItems
          ?.filter(Boolean)
          .map((checklistItem) => checklistItem.externalId) || [];

      const conditionsData =
        checklistItemExternalIds.length > 0
          ? await conditionalActionsService.getConditionalActionsByParentObject(
              checklistItemExternalIds,
              'ChecklistItem'
            )
          : undefined;

      const checklistWithConditions = {
        ...checklist,
        checklistItems: checklist.checklistItems?.map((checklistItem) => {
          return {
            ...checklistItem,
            conditionalActions: conditionsData
              ? conditionsData.conditionalActions?.filter(
                  (conditionalAction) =>
                    conditionalAction.parentObject?.externalId ===
                    checklistItem.externalId
                )
              : [],
          };
        }),
      };

      setCurrentChecklist(checklistWithConditions);

      return checklistWithConditions;
    },
    {
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.CHECKLIST,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.CHECKLIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.CHECKLIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      enabled: Boolean(externalId),
      refetchOnReconnect: false,
    }
  );
};
