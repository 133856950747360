import type { Activity } from '@cognite/apm-client';
import { CalendarIcon, Flex, TagChip } from '@cognite/cogs.js-v10';
import * as S from '@infield/features/asset/elements';
import type { ListItem } from '@infield/features/asset/shared/styled-list';
import { StyledList } from '@infield/features/asset/shared/styled-list';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { DEFAULT_DATE_FORMAT } from '@infield/utils/defaultDateFormats';
import { getLastUpdatedLabel } from '@infield/utils/humanizeDuration';
import dayjs from 'dayjs';
import type { FC } from 'react';

type Props = {
  activities: Activity[];
  selectedActivity: string;
  setSelectedActivity: (externalId: string) => void;
};

export const AssetActivitiesList: FC<Props> = ({
  activities,
  selectedActivity,
  setSelectedActivity,
}) => {
  const { Trans, t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const items = (): ListItem[] => {
    if (activities) {
      return activities.map((activity) => {
        return {
          key: String(activity.id),
          isSelected: selectedActivity === activity.externalId,
          onClick: () => setSelectedActivity(activity.externalId),
          content: (
            <S.Item>
              <Flex direction="column" gap={4}>
                <S.ItemTitle>{activity.title || '-'}</S.ItemTitle>
                <S.ItemDescription size="small">
                  {activity.type} - {activity.id}
                </S.ItemDescription>

                <Flex gap={8}>
                  {activity.status && (
                    <TagChip size="small" label={activity.status} />
                  )}

                  {activity.resource && (
                    <TagChip size="small" label={activity.resource} />
                  )}

                  <TagChip
                    icon={<CalendarIcon />}
                    size="small"
                    label={
                      dayjs(activity?.startTime).format(DEFAULT_DATE_FORMAT) ||
                      '-'
                    }
                  />
                </Flex>
              </Flex>

              <Flex>
                <S.LastUpdated>
                  <Trans i18nKey="ASSET_OVERVIEW_ACTIVITIES_EXPANDED_VIEW_ITEM_LAST_UPDATED">
                    Last updated:
                  </Trans>{' '}
                  {getLastUpdatedLabel(
                    new Date(activity.lastUpdatedTime),
                    t(
                      'ASSET_OVERVIEW_ACTIVITIES_EXPANDED_VIEW_ITEM_UPDATED_SECONDS_AGO',
                      'seconds ago'
                    )
                  )}
                </S.LastUpdated>
              </Flex>
            </S.Item>
          ),
        };
      });
    }
    return [];
  };

  return <StyledList items={items()} />;
};
