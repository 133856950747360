import type { CogniteClient, ItemsResponse } from '@cognite/sdk';

import type {
  LocationFilterDTO,
  LocationFilterListRequestDTO,
  LocationFilterUpsertRequestDTO,
} from './types';

export class ADSLocationFilterSDK {
  cogniteClient: CogniteClient;
  baseUrl: string;
  constructor(cogniteClient: CogniteClient) {
    this.cogniteClient = cogniteClient;
    this.baseUrl = `/apps/v1/projects/${this.cogniteClient.project}/storage/config/locationfilters`;
  }

  async upsertLocationFilter(
    payload: LocationFilterUpsertRequestDTO
  ): Promise<LocationFilterDTO> {
    if (!payload.id) {
      return this.createLocationFilter({ ...payload, id: undefined });
    }
    return this.updateLocationFilter(payload);
  }

  async updateLocationFilter(
    payload: LocationFilterUpsertRequestDTO
  ): Promise<LocationFilterDTO> {
    const payloadWithoutId = { ...payload };
    delete payloadWithoutId.id;
    const url = this.baseUrl;
    return this.cogniteClient
      .put<LocationFilterDTO>(`${url}/${payload.id}`, {
        data: payloadWithoutId,
        withCredentials: true,
      })
      .then((res) => res.data);
  }

  async createLocationFilter(
    payload: LocationFilterUpsertRequestDTO
  ): Promise<LocationFilterDTO> {
    const url = this.baseUrl;
    return this.cogniteClient
      .post<LocationFilterDTO>(url, {
        data: payload,
        withCredentials: true,
      })
      .then((res) => res.data);
  }

  async listLocationFilter(
    payload: LocationFilterListRequestDTO
  ): Promise<ItemsResponse<LocationFilterDTO>> {
    const url = `${this.baseUrl}/list`;

    return this.cogniteClient
      .post<ItemsResponse<LocationFilterDTO>>(url, {
        data: payload,
        withCredentials: true,
      })
      .then((res) => res.data);
  }

  async deleteLocationFilter(id: number): Promise<unknown> {
    const url = String(this.baseUrl);

    return this.cogniteClient
      .delete(`${url}/${id}`, {
        withCredentials: true,
      })
      .then((res) => res.data);
  }
}
