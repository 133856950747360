import type { Timeseries, TimeseriesFilter } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { addStringTimeseriesFilter } from './utils';

export const useTimeseriesSearch = (
  query?: string,
  limit?: number,
  filter?: TimeseriesFilter
) => {
  const { timeseriesService } = useFDMServices();

  const timeseriesFilter = addStringTimeseriesFilter(filter);

  return useQuery<Timeseries[] | undefined>(
    [QueryKeys.SEARCH, 'timeseries', query, timeseriesFilter],
    async () => {
      if (query)
        return timeseriesService.searchTimeseries(
          query,
          limit,
          timeseriesFilter
        );
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: `${QueryKeys.SEARCH} timeseries`,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(query),
    }
  );
};
