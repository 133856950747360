import type { ModalProps } from '@cognite/cogs.js-v10';
import type { FdmTimeSeries } from '@cognite/fdm-client';
import type { Asset } from '@cognite/sdk';
import { useAssetByIdQuery } from '@infield/features/asset';
import {
  useTimeseriesQuery,
  useTimeseriesUpsert,
} from '@infield/features/timeseries';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { TimeseriesUpsertModalContent } from './timeseries-upsert-modal-content';

export type TimeseriesUpsertModalProps = {
  relevantAsset?: Asset;
  timeseriesToEdit?: FdmTimeSeries;
  onSubmit: (timeseries: FdmTimeSeries) => void;
  onCancel: () => void;
  children?: ReactNode;
} & ModalProps;

export type TimeseriesKey = keyof FdmTimeSeries;

export const TimeseriesUpsertModal: FC<TimeseriesUpsertModalProps> = ({
  relevantAsset,
  timeseriesToEdit,
  onSubmit,
  onCancel,
  ...rest
}) => {
  const [updatedTimeseries, setUpdatedTimeseries] = useState<
    FdmTimeSeries | undefined
  >(timeseriesToEdit);
  const [timeseriesAsset, setTimeseriesAsset] = useState<Asset>();
  const { data: asset } = useAssetByIdQuery(timeseriesToEdit?.assetId || null);
  const { data: timeseriesResult, isSuccess } = useTimeseriesQuery(
    updatedTimeseries?.externalId
  );
  const { mutateAsync: upsertTimeseries, isLoading: isNewTimeseriesUpserting } =
    useTimeseriesUpsert();

  const timeseriesExists = isSuccess && Boolean(timeseriesResult);

  const handleSave = () => {
    if (updatedTimeseries) {
      upsertTimeseries({ timeseriesToUpsert: updatedTimeseries }).then(
        (timeseries: FdmTimeSeries[]) => {
          onSubmit(timeseries[0]);
        }
      );
    }
  };

  const handleTimeseriesUpdate = (
    key: TimeseriesKey,
    value: string | number | undefined
  ) => {
    setUpdatedTimeseries(
      (prevTimeseries) =>
        ({
          ...prevTimeseries,
          [key]: value,
        } as FdmTimeSeries)
    );
  };

  const handleAssetSelect = (asset?: Asset) => {
    setTimeseriesAsset(asset);
    setUpdatedTimeseries(
      (prevTimeseries) =>
        ({
          ...prevTimeseries,
          assetId: asset?.id,
        } as FdmTimeSeries)
    );
  };

  useEffect(() => {
    if (relevantAsset) {
      handleAssetSelect(relevantAsset);
    }
  }, [relevantAsset]);

  useEffect(() => {
    if (asset) {
      setTimeseriesAsset(asset);
    }
  }, [asset]);

  return (
    <TimeseriesUpsertModalContent
      timeseriesToEdit={timeseriesToEdit}
      updatedTimeseries={updatedTimeseries}
      timeseriesAsset={timeseriesAsset}
      isTimseriesExists={timeseriesExists}
      isNewTimeseriesUpserting={isNewTimeseriesUpserting}
      onTimeseriesUpdate={handleTimeseriesUpdate}
      onAssetSelect={handleAssetSelect}
      onSubmit={handleSave}
      onCancel={onCancel}
      {...rest}
    />
  );
};
