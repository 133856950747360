import type { ObservationConfig } from '@cognite/apm-client/src/observation-fields-config/types';
import { makeToast } from '@cognite/cogs-lab';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useFDMServices } from '@infield/providers/fdm-services';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpsertObservationConfig = () => {
  const { apmClient } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);

  return useMutation<
    ObservationConfig[] | undefined,
    Error,
    ObservationConfig[]
  >(
    async (observationConfigToUpsert) => {
      const {
        data: { items: observationConfig },
      } = await apmClient.observationConfig.upsert(observationConfigToUpsert);
      return observationConfig;
    },
    {
      onError: (err) => {
        makeToast({
          type: 'danger',
          body: t(
            'OBSERVATION_CONFIG_UPSERT_ERROR',
            'Failed to update observation config'
          ),
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.USE_OBSERVATION_CONFIG_UPSERT,
          },
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.OBSERVATION_CONFIG]);
      },
      mutationKey: [MutationKeys.USE_OBSERVATION_CONFIG_UPSERT],
    }
  );
};
