import { IRect } from 'konva/lib/types';

import {
  DEFAULT_CONTAINER_HEIGHT,
  DEFAULT_CONTAINER_WIDTH,
} from '../constants';
import { ContainerConfig } from '../containers/types';

import { addRelativeMarginToRect, getEnclosingRect } from './rectUtils';

const getBoundaryHeuristicForContainers = (
  containers: ContainerConfig[],
  options?: { relativeMargin?: number }
): IRect => {
  const boundary = containers.reduce(
    (acc, container) => {
      const containerRect = {
        x: container.x ?? 0,
        y: container.y ?? 0,
        width: container.width ?? container.maxWidth ?? DEFAULT_CONTAINER_WIDTH,
        height:
          container.height ?? container.maxHeight ?? DEFAULT_CONTAINER_HEIGHT,
      };
      return getEnclosingRect(acc, containerRect);
    },
    { x: 0, y: 0, width: 0, height: 0 }
  );
  const { relativeMargin = 0 } = options ?? {};
  return addRelativeMarginToRect(boundary, relativeMargin);
};

export default getBoundaryHeuristicForContainers;
