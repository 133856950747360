import type { Position } from '../annotations/types';
import { UnifiedViewer } from '../UnifiedViewer';

type FitViewportParameters = {
  scaleFactor: number;
  setViewportDurationSeconds: number;
};

export const fitViewportToWidth = ({
  viewer,
  x,
  y,
  targetWidth,
  scaleFactor,
  setViewportDurationSeconds,
}: { viewer: UnifiedViewer; targetWidth: number } & Position &
  FitViewportParameters): void => {
  const scale = viewer.getScale();
  const stageWidth = viewer.stage.width();
  viewer.setViewport(
    {
      x: x + targetWidth / 2,
      y: y,
      width: Math.max(scaleFactor * targetWidth, stageWidth / scale),
    },
    { duration: setViewportDurationSeconds }
  );
};

export const fitViewportToHeight = ({
  viewer,
  x,
  y,
  targetHeight,
  scaleFactor,
  setViewportDurationSeconds,
}: { viewer: UnifiedViewer; targetHeight: number } & Position &
  FitViewportParameters): void => {
  const scale = viewer.getScale();
  const stageHeight = viewer.stage.height();
  viewer.setViewport(
    {
      x,
      y: y + targetHeight / 2,
      height: Math.max(scaleFactor * targetHeight, stageHeight / scale),
    },
    { duration: setViewportDurationSeconds }
  );
};
