import type { LoginState } from '@cognite/auth-client';
import { AuthClient } from '@cognite/auth-client';

import type { AuthStateUser, CustomerProject } from '../../types';

import Flow from './flow-class';

export type AppID = 'MAINTAIN' | 'INFIELD' | 'POWEROPS' | 'INROBOT';
export type LoginOptions = {
  organization?: string;
  state?: Record<string, string>;
};
const appToCogIDP: Record<AppID, { production: string; staging: string }> = {
  MAINTAIN: {
    production: 'eefb2f1b-ac42-48cb-ab16-59df7eee1361', // 'maintain-prod',
    staging: '7750fca5-76e0-44c6-b603-f0fa275ce23a', // 'maintain-dev',
  },
  INFIELD: {
    production: '3910ea87-1453-4bf9-925c-e9a1721d9949', // 'infield-prod',
    staging: '348f6f75-4f25-4ada-a9c0-4c7603089810', // 'infield-dev',
  },
  POWEROPS: {
    production: 'c3ae5dda-ba4e-4f7d-8717-98806bf2a0d1', // 'power-ops-prod',
    staging: '9fae1deb-1f4c-4ced-9393-56e43858b5ce', // 'power-ops-dev',
  },
  INROBOT: {
    production: '6b992573-ca77-40a9-adb6-c9e527b42bb4', // 'inrobot-prod',
    staging: '611e8870-bdd7-4774-b4d4-b90a6cb7debd', // 'inrobot-dev',
  },
};
const PREVIEW_APP_ID = 'a7ba6249-99af-42a6-ac44-c6a4222c296c'; // 'industry-apps-preview-dev';

const setCookie = (
  name: string,
  value: string,
  domain: string,
  expiresInMinutes: number
) => {
  const expires = new Date(Date.now() + expiresInMinutes * 60 * 1000);
  document.cookie = `${name}=${value};domain=${domain};expires=${expires.toUTCString()};path=/`;
};

class CogIDPFlow extends Flow {
  static user: AuthStateUser;
  public authClient: AuthClient;
  public loginOptions: LoginOptions;
  public isPreview: boolean;

  constructor({
    projectInfo,
    isProduction,
    app,
    isPreview = false,
  }: {
    projectInfo: CustomerProject;
    isProduction: boolean;
    app: AppID;
    isPreview: boolean;
  }) {
    super();

    const getClientId = () => {
      if (isPreview) {
        return PREVIEW_APP_ID;
      }
      return appToCogIDP[app][isProduction ? 'production' : 'staging'];
    };

    this.isPreview = isPreview;
    console.log('organization', projectInfo.org);
    this.authClient = new AuthClient({ clientId: getClientId() });
    this.loginOptions = {
      organization: projectInfo.org,
      state: {
        pathname: window.location.pathname,
        search: window.location.search,
      },
    };

    if (isPreview) {
      const redirectTo = new URL(window.location.href);

      setCookie(
        'cogRedirectTo',
        redirectTo.toString(),
        'preview.cogniteapp.com',
        10
      );
    }
  }

  getRedirectUri = () => {
    if (this.isPreview) {
      return 'https://oauth.preview.cogniteapp.com/signin/callback';
    }
    return `https://${window.location.host}`;
  };

  async getTokenFactory(): Promise<() => Promise<string>> {
    let state: LoginState | null = null;
    try {
      const token = await this.authClient.getAccessToken();
      if (token) {
        return () => this.authClient.getAccessToken() as Promise<string>;
      }
    } catch (e) {
      console.log('no token found', e);
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeUrlParam = urlParams.get('code');
    const stateUrlParam = urlParams.get('state');
    if (codeUrlParam || stateUrlParam) {
      state = await this.authClient.handleSigninRedirectCallback();
    }

    if (!state || Object.keys(state || {}).length === 0) {
      await this.authClient.signinRedirect({
        ...this.loginOptions,
        redirectUri: this.getRedirectUri(),
      });
    }
    return () => this.authClient.getAccessToken() as Promise<string>;
  }

  async getUserState(): Promise<AuthStateUser> {
    const user = await this.authClient.getUser();

    if (!user) {
      throw new Error('No user found');
    }

    return {
      email: user.email || '',
      name: user.name || '',
      id: user.id,
      picture: user.picture,
      cdfId: user.id,
    };
  }

  async getLogoutFunction(): Promise<() => Promise<void>> {
    return async () => {
      if (this.isPreview) {
        setCookie(
          'cogRedirectTo',
          `https://${window.location.host}`,
          'preview.cogniteapp.com',
          10
        );
      }

      const response = await this.authClient.signoutRedirect({
        redirectUri: this.isPreview
          ? 'https://oauth.preview.cogniteapp.com/signout/callback'
          : this.getRedirectUri(),
      });

      return response;
    };
  }
}

export default CogIDPFlow;
