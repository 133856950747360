import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { useEffect } from 'react';

export const useDisableOverscrollWhenOfflineMobile = () => {
  const isDesktop = useIsDesktop();
  const { isOnline } = useNetworkStatusContext();
  useEffect(() => {
    const setScrollToRefresh = (enabled: boolean) => {
      document.body.style.overscrollBehavior = enabled ? 'auto' : 'none';
      document.body.style.overflow = enabled ? 'auto' : 'hidden';
    };

    const enabled = isOnline || isDesktop;
    setScrollToRefresh(enabled);
  }, [isDesktop, isOnline]);
};
