import type { ChecklistStatus } from '@cognite/apm-client';

export const CHECKLIST_STATUS_OPTIONS: {
  label: string;
  value: ChecklistStatus;
}[] = [
  { label: 'Ready', value: 'Ready' },
  { label: 'In progress', value: 'In progress' },
  { label: 'Done', value: 'Done' },
];

export const ColumnWidths = {
  SMALL: 100,
  MEDIUM: 180,
  LARGE: 200,
  TITLE: 250,
  'X-LARGE': 280,
};
