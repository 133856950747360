import type { FC, ReactNode } from 'react';
import { useRef } from 'react';

import { useOnClickOutside } from '../hooks';

type Props = {
  onDismiss: () => void;
  children: ReactNode;
};

/**
 * Wrapper which calls a passable "onDismiss" function when the area outside the contents is clicked.
 */
export const DismissableContent: FC<Props> = ({ onDismiss, children }) => {
  const contentRef = useRef(null);
  useOnClickOutside(contentRef, () => onDismiss());

  return <div ref={contentRef}>{children}</div>;
};
