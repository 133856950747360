import type {
  APMClient,
  InFieldUser,
  Measurement,
  MeasurementUpsertType,
} from '@cognite/apm-client';
import { FDMClient } from '@cognite/fdm-client';
import type { CogniteClient } from '@cognite/sdk';

export class MeasurementService extends FDMClient {
  apmClient: APMClient;
  constructor(client: CogniteClient, apmClient: APMClient) {
    super(client);
    this.apmClient = apmClient;
  }

  async createMeasurements(measurements: Measurement[], user: InFieldUser) {
    const upsertedMeasurements: Measurement[] = measurements.map(
      (measurement) => ({
        ...measurement,
        createdBy: user,
      })
    );
    return this.upsertMeasurements(upsertedMeasurements);
  }

  async updateMeasurements(measurements: Measurement[], user: InFieldUser) {
    const upsertedMeasurements: Measurement[] = measurements.map(
      (measurement) => ({
        ...measurement,
        updatedBy: user,
      })
    );
    return this.upsertMeasurements(upsertedMeasurements);
  }

  async upsertMeasurements(measurements: Measurement[]) {
    const upsertedMeasurements: MeasurementUpsertType[] = measurements.map(
      (measurement) => ({
        ...measurement,
        timeseries: measurement.timeseries
          ? measurement.timeseries.externalId
          : measurement.timeseries,
        updatedBy: measurement.updatedBy?.externalId
          ? {
              externalId: measurement.updatedBy?.externalId,
              space: this.apmClient.userInstanceSpace,
            }
          : undefined,
        createdBy: measurement.createdBy?.externalId
          ? {
              externalId: measurement.createdBy?.externalId,
              space: this.apmClient.userInstanceSpace,
            }
          : undefined,
      })
    );
    return this.apmClient.measurements.upsert(upsertedMeasurements);
  }

  async deleteMeasurements(measurementExternalIds: string[]) {
    return this.apmClient.measurements.delete(measurementExternalIds);
  }
}
