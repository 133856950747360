import { LoaderIcon } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

import type { LocationMenuItems } from '../types';

import * as S from './elements';

interface Props {
  activeMenu: LocationMenuItems;
  isInfieldConfigLoading: boolean;
  setActiveMenu: (page: LocationMenuItems) => void;
}

export const LocationMenu: FC<Props> = ({
  activeMenu,
  isInfieldConfigLoading,
  setActiveMenu,
}) => {
  return (
    <S.StyledMenu>
      <S.StyledMenu.Section label="General">
        <S.StyledMenu.Item
          onClick={() => setActiveMenu('locationDescription')}
          toggled={activeMenu === 'locationDescription'}
        >
          Location description
        </S.StyledMenu.Item>
        <S.StyledMenu.Item
          onClick={() => setActiveMenu('viewMappings')}
          toggled={activeMenu === 'viewMappings'}
        >
          View mappings
        </S.StyledMenu.Item>
        <S.StyledMenu.Item
          onClick={() => setActiveMenu('instanceSpaces')}
          toggled={activeMenu === 'instanceSpaces'}
        >
          Customer instance spaces
        </S.StyledMenu.Item>
      </S.StyledMenu.Section>
      <S.StyledMenu.Section label="InField">
        <>
          <S.StyledMenu.Item
            icon={
              isInfieldConfigLoading ? (
                <LoaderIcon className="cogs-icon--rotating" />
              ) : undefined
            }
            iconPlacement="left"
            disabled={isInfieldConfigLoading}
            onClick={() => setActiveMenu('infieldInstanceSpace')}
            toggled={activeMenu === 'infieldInstanceSpace'}
          >
            {!isInfieldConfigLoading && 'InField instance space'}
          </S.StyledMenu.Item>
          <S.StyledMenu.Item
            icon={
              isInfieldConfigLoading ? (
                <LoaderIcon className="cogs-icon--rotating" />
              ) : undefined
            }
            iconPlacement="left"
            disabled={isInfieldConfigLoading}
            onClick={() => setActiveMenu('assetExplorer')}
            toggled={activeMenu === 'assetExplorer'}
          >
            {!isInfieldConfigLoading && 'Asset explorer'}
          </S.StyledMenu.Item>
        </>
      </S.StyledMenu.Section>
    </S.StyledMenu>
  );
};
