import type { Activity } from '@cognite/apm-client';
import type { GridFilterModel } from '@cognite/apm-observation';
import type { Filters, Sort } from '@cognite/fdm-client';
import {
  useActivitiesWithChecklistInfiniteQuery,
  useSearchActivitiesWithChecklists,
} from '@infield/features/activities';
import {
  convertActivityToDataGridRow,
  convertOperationToDataGridRow,
} from '@infield/features/activities/activity-planning/maintenance-table/utils';
import { overviewTableSearchQueryAtom } from '@infield/features/activities/activity-planning/state';
import { useSubActivitiesListQuery } from '@infield/features/activities/hooks/use-query/use-sub-activities-list';
import { useAssetsQuery } from '@infield/features/asset';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { WorkorderTableDataFetchMode } from '@infield/pages/activity/types';
import { getUniqueExternalIds } from '@infield/utils/filtering-helpers';
import groupBy from 'lodash/groupBy';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { getOperationGroupIdToChecklistMap } from '../../utils';
import { getOperationsWithUniqueOrder } from '../../utils/get-operations-with-unique-order';

export const useFetchDataGridData = (
  searchableFields: string[],
  sortSettings: Sort,
  checklistFilterModel: GridFilterModel,
  fdmActivitiesFilterWithDate?: Filters,
  fdmChecklistFilter?: Filters
) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const overviewTableSearchQuery = useRecoilValue(overviewTableSearchQueryAtom);
  const fetchMode: WorkorderTableDataFetchMode = overviewTableSearchQuery
    ? 'search'
    : 'list';

  const {
    activities: listActivities,
    checklists: listChecklists,
    isLoading: isListLoading,
    error: listError,
    isError: isListError,
    hasNextPage,
    fetchNextPage,
  } = useActivitiesWithChecklistInfiniteQuery(
    fdmActivitiesFilterWithDate,
    fdmChecklistFilter,
    sortSettings,
    {
      enabled: fetchMode === 'list',
    }
  );

  const {
    data: { activities: searchedActivities, checklists: searchedChecklists } = {
      activities: [],
      checklists: [],
    },
    isLoading: isSearchLoading,
    error: searchError,
    isError: isSearchError,
  } = useSearchActivitiesWithChecklists(
    overviewTableSearchQuery,
    fdmActivitiesFilterWithDate,
    fdmChecklistFilter,
    searchableFields,
    fetchMode === 'search'
  );

  const activities = useMemo(() => {
    if (fetchMode === 'search') {
      return searchedActivities;
    }
    return listActivities;
  }, [fetchMode, searchedActivities, listActivities]);

  const checklists = useMemo(() => {
    if (fetchMode === 'search') {
      return searchedChecklists;
    }
    return listChecklists;
  }, [fetchMode, listChecklists, searchedChecklists]);

  const { data: assets } = useAssetsQuery(
    activities.map((activity) => activity.assetExternalId || '')
  );

  const getActivityChecklists = useCallback(
    (activity?: Activity) => {
      return checklists.filter(
        (checklist) => checklist.sourceId === activity?.externalId
      );
    },
    [checklists]
  );

  const getActivityAssetName = useCallback(
    (activity?: Activity) => {
      const asset = assets?.find(
        (asset) => asset.externalId === activity?.assetExternalId
      );
      return asset?.name;
    },
    [assets]
  );

  const activitiesToDisplay = useMemo(() => {
    // Remove irrelevant activities if checklist filter is applied
    if (!fdmChecklistFilter) return activities;

    const activityExternalIdDictionary: Record<string, boolean> = {};
    if (checklists?.length) {
      for (let i = 0; i < checklists.length; i++) {
        activityExternalIdDictionary[checklists[i].sourceId!] = true;
      }
    }

    return activities.filter(
      (activity) => activity.externalId in activityExternalIdDictionary
    );
  }, [activities, checklists, fdmChecklistFilter]);

  const { data: operations = [], isInitialLoading: isOperationsLoading } =
    useSubActivitiesListQuery(getUniqueExternalIds(activitiesToDisplay));

  const operationGroupIdToChecklistMap = useMemo(() => {
    return getOperationGroupIdToChecklistMap(operations, checklists);
  }, [checklists, operations]);

  const operationIdToOperationsMap = useMemo(() => {
    return groupBy(operations, 'id');
  }, [operations]);

  const dataGridData = useMemo(() => {
    const activitiesAsDataGridRows = activitiesToDisplay.map((activity) =>
      convertActivityToDataGridRow(
        activity,
        getActivityChecklists(activity),
        getActivityAssetName(activity)
      )
    );

    // operations order field should be unique per activity, if not - filter out duplicates
    const uniqueOperations = getOperationsWithUniqueOrder(operations);

    const operationsToBeDisplayed = uniqueOperations
      .map((operation) => {
        const relatedByIdChecklist = operationGroupIdToChecklistMap.get(
          operation.id ?? ''
        );
        const relatedChecklist =
          relatedByIdChecklist?.sourceId === operation.parentActivityId
            ? relatedByIdChecklist
            : undefined;
        return convertOperationToDataGridRow(t, operation, relatedChecklist);
      })
      .filter((operation) =>
        checklistFilterModel.length > 0
          ? checklistFilterModel.find(
              (filter) =>
                filter.field === 'checklistStatus' &&
                filter.value.includes(operation.checklistStatus || '')
            )
          : operation
      )
      .sort((a, b) => a.operationNumber - b.operationNumber);

    return [...activitiesAsDataGridRows, ...operationsToBeDisplayed];
  }, [
    activitiesToDisplay,
    checklistFilterModel,
    getActivityAssetName,
    getActivityChecklists,
    operationGroupIdToChecklistMap,
    operations,
    t,
  ]);

  const isActivitiesLoading =
    fetchMode === 'search' ? isSearchLoading : isListLoading;
  const isLoading = isActivitiesLoading || isOperationsLoading;
  const isError = fetchMode === 'search' ? isSearchError : isListError;
  const errorMessage = fetchMode === 'search' ? searchError : listError;

  return {
    dataGridData,
    activities,
    operations,
    fetchMode,
    isLoading,
    isError,
    errorMessage,
    hasNextPage,
    fetchNextPage,
    operationIdToOperationsMap,
    operationGroupIdToChecklistMap,
  };
};
