import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { getIsIdmFromProjectEndpointResponse } from './utils';

export const useIsIdm = () => {
  const { authState, client } = useAuthContext();
  const { project } = client;
  const { org } = authState.projectInfo;

  return useQuery<boolean, Error>(
    [QueryKeys.ISIDM, project, org],
    async () => {
      if (!project) throw new Error('No project');
      if (!org) throw new Error('No org');

      const projectEndpointResponse = await client.get(
        `https://auth.cognite.com/api/v0/orgs/${org}/projects?includeDataModelingStatus=true`,
        {
          withCredentials: true,
          headers: {
            Authorization: String(
              client.getDefaultRequestHeaders().Authorization
            ),
          },
        }
      );

      const isIdmProject = getIsIdmFromProjectEndpointResponse(
        projectEndpointResponse,
        client.project
      );

      return isIdmProject;
    },
    {
      onError: (err) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.ISIDM,
          },
        });
      },
      staleTime: 86400000, // 1 day
      cacheTime: 86400000, // 1 day
      enabled: Boolean(org) && Boolean(project),
    }
  );
};
