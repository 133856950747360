import { useEffect, useMemo, useState } from 'react';

import { UnifiedViewer, UnifiedViewerErrorEvent } from '../../UnifiedViewer';
import UnifiedViewerEventType from '../../UnifiedViewerEventType';

const FONT_SIZE_MULTIPLIER = 0.03;
const PADDING_MULTIPLIER = 0.1;

export const ErrorComponent = ({
  unifiedViewerRef,
  errorsByContainerId,
}: {
  unifiedViewerRef: UnifiedViewer | undefined;
  errorsByContainerId: Map<string, UnifiedViewerErrorEvent>;
}): JSX.Element => {
  const [forceUpdate, setForceUpdate] = useState(0);

  useEffect(() => {
    if (unifiedViewerRef) {
      unifiedViewerRef?.addEventListener(
        UnifiedViewerEventType.ON_ZOOM_START,
        () => {
          setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
        }
      );
      unifiedViewerRef?.addEventListener(
        UnifiedViewerEventType.ON_ZOOM_CHANGE,
        () => {
          setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
        }
      );
      unifiedViewerRef?.addEventListener(
        UnifiedViewerEventType.ON_ZOOM_END,
        () => {
          setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
        }
      );
      unifiedViewerRef?.addEventListener(
        UnifiedViewerEventType.ON_PAN_START,
        () => {
          setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
        }
      );
      unifiedViewerRef?.addEventListener(
        UnifiedViewerEventType.ON_PAN_MOVE,
        () => {
          setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
        }
      );
      unifiedViewerRef?.addEventListener(
        UnifiedViewerEventType.ON_PAN_END,
        () => {
          setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
        }
      );
      unifiedViewerRef.addEventListener(
        UnifiedViewerEventType.ON_DRAG_START,
        () => {
          setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
        }
      );
      unifiedViewerRef.addEventListener(
        UnifiedViewerEventType.ON_DRAG_MOVE,
        () => {
          setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
        }
      );
      unifiedViewerRef.addEventListener(
        UnifiedViewerEventType.ON_TRANSFORM_START,
        () => {
          setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
        }
      );
      unifiedViewerRef.addEventListener(
        UnifiedViewerEventType.ON_TRANSFORM_CHANGE,
        () => {
          setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
        }
      );
      // NOTE: ON_CONTAINER_LOAD covers ON_TRANSFORM_END and ON_DRAG_END as
      // well. If we listen to the the latter two events, we will be registering
      // the container states *before* they have been properly rendered.
      // Listening to ON_CONTAINER_LOAD instead ensures that we get the most
      // up-to-date container states.
      unifiedViewerRef.addEventListener(
        UnifiedViewerEventType.ON_CONTAINER_LOAD,
        () => {
          setForceUpdate((prevForceUpdate) => prevForceUpdate + 1);
        }
      );
    }
  }, [unifiedViewerRef]);

  const Errors = useMemo(() => {
    if (unifiedViewerRef === undefined) {
      return [];
    }

    return Array.from(errorsByContainerId.entries()).map(
      ([containerId, errorEvent]) => {
        const rect = unifiedViewerRef.getRectById(containerId, {
          shouldExcludeContainerLabel: true,
        });
        if (rect === undefined) {
          return null;
        }

        return (
          <div
            key={containerId}
            style={{
              top: rect.y,
              left: rect.x,
              width: rect.width,
              height: rect.height,
              position: 'absolute',
              color: 'red',
              border: 'red 2px solid',
              borderRadius: '5px',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: `${rect.width * PADDING_MULTIPLIER}px`,
              boxSizing: 'border-box',
              pointerEvents: 'none',
            }}
          >
            <div
              style={{
                fontFamily: 'sans-serif',
                fontSize: `${rect.width * FONT_SIZE_MULTIPLIER}px`,
                overflow: 'hidden',
                cursor: 'default',
                pointerEvents: 'all',
              }}
            >
              {errorEvent.message}
            </div>
          </div>
        );
      }
    );
    // NOTE: forceUpdate is not used in the hook, but we want to re-render when it changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsByContainerId, unifiedViewerRef, forceUpdate]);

  return <>{Errors}</>;
};
