import type { FC } from 'react';

import * as S from './elements';

type Props = {
  mediaSrc: string;
  isFullScreen?: boolean;
};

export const VideoItem: FC<Props> = ({ mediaSrc, isFullScreen }) => {
  if (isFullScreen) {
    return <S.FullScreenVideo controls src={mediaSrc} />;
  }

  return <S.VideoItem src={mediaSrc} />;
};
