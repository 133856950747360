import { IRect } from 'konva/cmj/types';

import getEuclideanDistance from '../../utils/getEuclideanDistance';
import { simplifyRightAnglesPath } from '../getRightAnglesConnectionPathPoints/utils';
import { Position, ShapeAnchorPoint } from '../types';

type IRectWithAnchorPoint = IRect & {
  anchorPoint?: ShapeAnchorPoint;
  padding?: number;
};

const calculatePolylineLength = (points: Position[]): number =>
  points.reduce((totalLength, point1, index, points) => {
    if (index === 0) {
      return totalLength;
    }
    const point2 = points[index - 1];
    const segmentLength = getEuclideanDistance(point1, point2);
    return totalLength + segmentLength;
  }, 0);

const getElbowedPolyline = ({
  fromPoint,
  toPoint,
  fromRect,
  toRect,
}: {
  fromPoint: Position;
  toPoint: Position;
  fromRect: IRectWithAnchorPoint;
  toRect: IRectWithAnchorPoint;
}) => {
  const isHorizontalFrom =
    fromPoint.x === fromRect.x || fromPoint.x === fromRect.x + fromRect.width;
  const fromPadding = fromRect.padding ?? 0;
  const toPadding = toRect.padding ?? 0;
  const fromElbowPoint = isHorizontalFrom
    ? {
        x: (fromPoint.x + fromPadding / 2 + toPoint.x - toPadding / 2) / 2,
        y: fromPoint.y,
      }
    : {
        x: fromPoint.x,
        y: (fromPoint.y + fromPadding / 2 + toPoint.y - toPadding / 2) / 2,
      };

  const isHorizontalTo =
    toPoint.x === toRect.x || toPoint.x === toRect.x + toRect.width;
  const toElbowPoint = isHorizontalTo
    ? { x: fromElbowPoint.x, y: toPoint.y }
    : { x: toPoint.x, y: fromElbowPoint.y };

  return [fromPoint, fromElbowPoint, toElbowPoint, toPoint];
};

type ShapeAnchorPointMapping = {
  [ShapeAnchorPoint.TOP]: Position;
  [ShapeAnchorPoint.RIGHT]: Position;
  [ShapeAnchorPoint.BOTTOM]: Position;
  [ShapeAnchorPoint.LEFT]: Position;
};

const filterShapeAnchorPointMapping = (
  mapping: ShapeAnchorPointMapping,
  anchorPoints: ShapeAnchorPoint[] | undefined
): Partial<ShapeAnchorPointMapping> => {
  if (anchorPoints === undefined) {
    return { ...mapping };
  }
  return anchorPoints.reduce(
    (acc: Partial<ShapeAnchorPointMapping>, anchorPoint: ShapeAnchorPoint) => {
      if (!Object.prototype.hasOwnProperty.call(acc, anchorPoint)) {
        acc[anchorPoint] = mapping[anchorPoint];
      }
      return acc;
    },
    {}
  );
};

const findOptimalElbowedPolyline = (
  fromRect: IRectWithAnchorPoint,
  toRect: IRectWithAnchorPoint
): Position[] => {
  const fromPoints = filterShapeAnchorPointMapping(
    {
      [ShapeAnchorPoint.TOP]: {
        x: fromRect.x + fromRect.width / 2,
        y: fromRect.y,
      },
      [ShapeAnchorPoint.LEFT]: {
        x: fromRect.x,
        y: fromRect.y + fromRect.height / 2,
      },
      [ShapeAnchorPoint.BOTTOM]: {
        x: fromRect.x + fromRect.width / 2,
        y: fromRect.y + fromRect.height,
      },
      [ShapeAnchorPoint.RIGHT]: {
        x: fromRect.x + fromRect.width,
        y: fromRect.y + fromRect.height / 2,
      },
    },
    fromRect.anchorPoint !== undefined ? [fromRect.anchorPoint] : undefined
  );
  const toPoints = filterShapeAnchorPointMapping(
    {
      [ShapeAnchorPoint.TOP]: { x: toRect.x + toRect.width / 2, y: toRect.y },
      [ShapeAnchorPoint.LEFT]: { x: toRect.x, y: toRect.y + toRect.height / 2 },
      [ShapeAnchorPoint.BOTTOM]: {
        x: toRect.x + toRect.width / 2,
        y: toRect.y + toRect.height,
      },
      [ShapeAnchorPoint.RIGHT]: {
        x: toRect.x + toRect.width,
        y: toRect.y + toRect.height / 2,
      },
    },
    toRect.anchorPoint !== undefined ? [toRect.anchorPoint] : undefined
  );

  let minDistance = Infinity;
  let closestPolylinePoints: Position[] = [];
  for (const fromPoint of Object.values(fromPoints)) {
    for (const toPoint of Object.values(toPoints)) {
      const polyline = getElbowedPolyline({
        fromPoint,
        fromRect,
        toPoint,
        toRect,
      });
      const distance = calculatePolylineLength(polyline);
      if (distance < minDistance) {
        minDistance = distance;
        closestPolylinePoints = polyline;
      }
    }
  }
  return simplifyRightAnglesPath(closestPolylinePoints);
};

const getElbowConectionPathPoints = (
  fromRect: IRectWithAnchorPoint,
  toRect: IRectWithAnchorPoint
): number[] => {
  const polylinePoints = findOptimalElbowedPolyline(fromRect, toRect);
  return polylinePoints.flatMap(({ x, y }) => [x, y]);
};

export default getElbowConectionPathPoints;
