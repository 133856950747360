import { Skeleton } from '@cognite/cogs.js-v10';
import type { FC } from 'react';

import { useFetchMedia } from '../hooks/use-fetch-media';
import type { ImageToUpload } from '../types';

import { LoadingWrapper } from './elements';

interface Props {
  fileExternalId: string;
  fileSpace?: string;
  children: (args: { media: ImageToUpload }) => unknown;
}

export const MediaFetcher: FC<Props> = ({
  fileExternalId,
  fileSpace,
  children,
}) => {
  const { isLoading, data: media } = useFetchMedia(fileExternalId, fileSpace);

  return (
    <>
      {isLoading && (
        <LoadingWrapper>
          <Skeleton.Rectangle width="100%" height="80px" />
        </LoadingWrapper>
      )}
      {media &&
        children({
          media,
        })}
    </>
  );
};
