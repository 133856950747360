import { MutableRefObject, useEffect } from 'react';

import { UnifiedViewer, UnifiedViewerEventListenerMap } from '../UnifiedViewer';
import UnifiedViewerEventType from '../UnifiedViewerEventType';

const useUnifiedViewerEventHandler = (
  unifiedViewerRef: MutableRefObject<UnifiedViewer | undefined>,
  eventType: UnifiedViewerEventType,
  fn: UnifiedViewerEventListenerMap[UnifiedViewerEventType] | undefined
): void => {
  useEffect(() => {
    const ref = unifiedViewerRef.current;
    if (fn !== undefined) {
      ref?.addEventListener(eventType, fn);
    }

    return () => {
      if (fn !== undefined) {
        ref?.removeEventListener(eventType, fn);
      }
    };
  }, [eventType, fn, unifiedViewerRef]);
};

export default useUnifiedViewerEventHandler;
