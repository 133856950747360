import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex)`
  height: calc(100vh - 56px);
`;

export const TableWrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;
