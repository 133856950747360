import { UnifiedViewer } from '../UnifiedViewer';
import { UnifiedViewerPointerEvent } from '../UnifiedViewerRenderer/UnifiedEventHandler';

import { ToolConfig, ToolType } from './types';

export enum OptionPropertyType {
  NUMBER = 'number',
  COLOR = 'color',
  OPACITY = 'opacity',
}

export type OptionProperty = {
  key: string;
  type: OptionPropertyType;
  label: string;
};

export default abstract class Tool {
  protected readonly unifiedViewer: UnifiedViewer;
  public constructor(unifiedViewer: UnifiedViewer) {
    this.unifiedViewer = unifiedViewer;
  }

  public abstract readonly type: ToolType;
  public cursor?: string;
  public onMouseDown?(event: UnifiedViewerPointerEvent): void;
  public onMouseMove?(event: UnifiedViewerPointerEvent): void;
  public onMouseUp?(event: UnifiedViewerPointerEvent): void;
  public onMouseOver?(event: MouseEvent): void;
  public onMouseOut?(event: MouseEvent): void;
  public onMouseEnter?(event: MouseEvent): void;
  public onMouseLeave?(event: MouseEvent): void;
  public onDragStart?(event: UnifiedViewerPointerEvent): void;
  public onDragMove?(event: UnifiedViewerPointerEvent): void;
  public onDragEnd?(event: UnifiedViewerPointerEvent): void;
  public onDestroy?(): void;
  public onComplete?(): void;
  public abstract setConfig(tool: ToolConfig): void;
}
