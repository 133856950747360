import React, { useMemo } from 'react';

import styled from 'styled-components';

import {
  MAX_PAGE_WIDTH_MOBILE,
  MAX_PAGE_WIDTH_TABLET,
  PageContent,
} from '@fusion/ui-components';

import { LanguageIcon, UserIcon } from '@cognite/cogs.js-v10';

import { Language, UserInfo, VerticalTab } from '../../common/types';
import { LanguageTab } from '../../components/language-tab/LanguageTab';
import { PersonalInfoTab } from '../../components/personal-info-tab/PersonalInfoTab';
import { ProfilePageHeader } from '../../components/profile-page-header/ProfilePageHeader';
import { VerticalTabs } from '../../components/vertical-tabs/VerticalTabs';
import { useActiveTabKey } from '../../hooks/useActiveTabKey';
import { OnTrackEvent } from '../../metrics';

export type SidebarLocale = {
  // TODO: provide detailed descriptions
  personalInfoTabBtnText?: string;
  languageTabBtnText?: string;
};

export type LanguageTabLocale = {
  // TODO: provide detailed descriptions
  languageFieldLabel?: string;
};

export type PersonalInfoTabLocale = {
  // TODO: provide detailed descriptions
  userIdFieldLabel?: string;
  nameFieldLabel?: string;
  nameFieldHelpText?: string;
  emailFieldLabel?: string;
  emailFieldHelpText?: string;
};

export type ProfileHeaderLocale = {
  // TODO: provide detailed descriptions
  description?: string;
};

export type ProfilePageTheme =
  | 'sapphire'
  | 'turquoise'
  | 'amethyst'
  | 'onyx'
  | 'amber';

export type UserProfilePageProps = {
  userInfo?: UserInfo;
  isUserInfoLoading?: boolean;
  selectedLanguage: Language;
  supportedLanguages: Language[];
  onLanguageChange: (language: Language | undefined) => void;
  sidebarLocale?: SidebarLocale;
  languageTabLocale?: LanguageTabLocale;
  personalInfoTabLocale?: PersonalInfoTabLocale;
  profileHeaderLocale?: ProfileHeaderLocale;
  onTrackEvent?: OnTrackEvent;
  additionalTabsCategoryLabel?: string;
  additionalTabs?: VerticalTab[];
  theme?: ProfilePageTheme;
};

export const UserProfilePage = ({
  userInfo,
  isUserInfoLoading,
  selectedLanguage,
  supportedLanguages,
  onLanguageChange,
  sidebarLocale,
  languageTabLocale,
  personalInfoTabLocale,
  profileHeaderLocale,
  onTrackEvent,
  additionalTabsCategoryLabel,
  additionalTabs,
  theme = 'sapphire',
}: UserProfilePageProps): JSX.Element => {
  const id = userInfo?.id;
  const name = userInfo?.name ?? '';
  const email = userInfo?.email ?? '';
  const profilePicture = userInfo?.profilePicture ?? '';

  const builtinTabs: VerticalTab[] = useMemo(() => {
    return [
      {
        key: 'info',
        icon: <UserIcon />,
        title: sidebarLocale?.personalInfoTabBtnText || 'Personal information',
        content: (
          <PersonalInfoTab
            userInfo={{ id, email, name }}
            isUserInfoLoading={isUserInfoLoading}
            userIdFieldLabel={personalInfoTabLocale?.userIdFieldLabel}
            nameFieldLabel={personalInfoTabLocale?.nameFieldLabel}
            nameFieldHelpText={personalInfoTabLocale?.nameFieldHelpText}
            emailFieldLabel={personalInfoTabLocale?.emailFieldLabel}
            emailFieldHelpText={personalInfoTabLocale?.emailFieldHelpText}
          />
        ),
      },
      {
        key: 'language',
        icon: <LanguageIcon />,
        title: sidebarLocale?.languageTabBtnText || 'Language',
        content: (
          <LanguageTab
            selectedLanguage={selectedLanguage}
            supportedLanguages={supportedLanguages}
            onLanguageChange={onLanguageChange}
            languageFieldLabel={languageTabLocale?.languageFieldLabel}
            onTrackEvent={onTrackEvent}
          />
        ),
      },
    ];
  }, [
    email,
    isUserInfoLoading,
    languageTabLocale?.languageFieldLabel,
    name,
    onLanguageChange,
    onTrackEvent,
    personalInfoTabLocale?.emailFieldHelpText,
    personalInfoTabLocale?.emailFieldLabel,
    personalInfoTabLocale?.nameFieldHelpText,
    personalInfoTabLocale?.nameFieldLabel,
    selectedLanguage,
    sidebarLocale?.languageTabBtnText,
    sidebarLocale?.personalInfoTabBtnText,
    supportedLanguages,
  ]);

  const profileTabs: VerticalTab[] = useMemo(() => {
    return [...builtinTabs, ...(additionalTabs ?? [])];
  }, [builtinTabs, additionalTabs]);

  const [activeTabKey, setActiveTabKey] = useActiveTabKey(
    profileTabs.map(({ key }) => key)
  );

  const handleChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <Page>
      <ProfilePageHeader
        description={profileHeaderLocale?.description}
        theme={theme}
        userInfo={{ name, profilePicture }}
      />
      <PageContent>
        <Content>
          <ProfileTabs>
            <VerticalTabs
              activeKey={activeTabKey}
              onChange={handleChange}
              builtinTabs={builtinTabs}
              additionalTabs={additionalTabs}
              additionalTabsCategoryLabel={additionalTabsCategoryLabel}
              onTrackEvent={onTrackEvent}
            />
          </ProfileTabs>

          <TabContent>
            {profileTabs.find(({ key }) => key === activeTabKey)?.content ||
              profileTabs[0].content}
          </TabContent>
        </Content>
      </PageContent>
    </Page>
  );
};

const Page = styled.div`
  height: 100%;
`;

const Content = styled.div`
  display: grid;
  gap: 48px;
  grid-template-columns: [start] 260px [one] 1fr [end];

  @container main (inline-size <= ${MAX_PAGE_WIDTH_TABLET}px) {
    grid-template-columns: [start] 190px [one] 1fr [end];
    gap: 24px;

    .vertical-tabs-action-list {
      min-width: 190px;
    }
  }

  @container main (inline-size <= ${MAX_PAGE_WIDTH_MOBILE}px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const ProfileTabs = styled.div`
  grid-column: start / one;
`;

const TabContent = styled.div`
  grid-column: one / end;
`;
