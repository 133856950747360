import type { Template } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

/**
 * Soft-deletes template items plus related measurements and schedules.
 *
 * @param templateItems - Template items to soft delete
 */
export const useTemplateItemsDelete = () => {
  const { templateService } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const metrics = useMetrics(METRICS_NAMESPACES.auditTemplateItem);

  return useMutation<string[], Error, { externalIds: string[] }>(
    async ({ externalIds }) => {
      return templateService.softDeleteTemplateItems(externalIds);
    },
    {
      onMutate: async ({ externalIds }) => {
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        if (activeQuery.length > 0) {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries([QueryKeys.TEMPLATE]);

          const [{ queryKey: activeQueryKey }] = activeQuery;

          // Snapshot the previous value
          const previousTemplate = queryClient.getQueryData(
            activeQueryKey
          ) as Template;

          const previousTemplateItems = previousTemplate.templateItems ?? [];

          // Optimistically update to the new value
          const templateItemsUpdatedOptimistically =
            previousTemplateItems.filter(
              (templateItem) => !externalIds.includes(templateItem.externalId)
            );

          const updatedTemplate = {
            ...previousTemplate,
            templateItems: templateItemsUpdatedOptimistically,
          };

          queryClient.setQueryData(activeQueryKey, updatedTemplate);

          // Return a context object with the snapshotted value
          return previousTemplate;
        }
      },

      onError: (err) => {
        makeToast({
          body: t('TASK_DELETE_NOTIFICATION_ERROR', `Failed to delete task`),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-template-items-delete-mutation',
          },
        });
      },
      onSuccess: async (externalIds) => {
        externalIds.forEach((externalId) =>
          queryClient.invalidateQueries([QueryKeys.TEMPLATE, externalId])
        );
        externalIds.forEach((externalId) => {
          metrics.track('Update', {
            externalId,
            isArchived: true,
          });
        });
      },
    }
  );
};
