import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const CardContainer = styled.div<{
  $isDesktop?: boolean;
}>`
  padding: 16px;
  display: ${({ $isDesktop }) => ($isDesktop ? 'grid' : 'flex')};
  ${({ $isDesktop }) =>
    $isDesktop
      ? 'grid-template-columns: repeat(auto-fit, minmax(max(384px, 30%), 1fr));'
      : 'flex-wrap: wrap;'}

  gap: 16px;
  justify-content: flex-start;

  & > * {
    height: 416px;
    overflow: hidden;
    flex: 1;
    min-width: 288px;
  }
`;

export const AssetDetailsContent = styled(Flex).attrs({
  direction: 'column',
})`
  position: relative;
  height: 100%;
  width: 100%;
  border-right: 1px solid rgb(217, 217, 217);
  border-left: 1px solid rgb(217, 217, 217);
  border-bottom: 1px solid rgb(217, 217, 217);
`;

export const AssetDetailsWrapper = styled(Flex).attrs({ direction: 'column' })`
  max-width: 2000px;
  margin: 0 auto;
  width: 100%;
`;
