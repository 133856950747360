import UnifiedViewerRenderer from '../../UnifiedViewerRenderer';
import getStraightConnectionPathPointsFromBorder from '../getStraightConnectionPathPointsFromBorder';
import { Vertex } from '../types';

const getRectFromNodeId = (
  nodeId: string | undefined,
  vertices: Vertex[] | undefined,
  unifiedViewerRenderer: UnifiedViewerRenderer
) => {
  if (nodeId === undefined) {
    if (vertices === undefined) {
      throw new Error('Either id or vertices must be defined');
    }

    if (vertices.length === 0) {
      throw new Error('Vertices must not be empty');
    }

    return {
      x: vertices[0].x,
      y: vertices[0].y,
      width: 1,
      height: 1,
    };
  }

  const node = unifiedViewerRenderer.getContainerOrAnnotationNodeById(nodeId);
  if (node === undefined) {
    return undefined;
  }

  return unifiedViewerRenderer.getNodeRectRelativeToStage(node);
};

const getStraightLineConnection = (
  fromId: string | undefined,
  toId: string | undefined,
  vertices: Vertex[] | undefined,
  unifiedViewerRenderer: UnifiedViewerRenderer
): number[] | undefined => {
  if (fromId === undefined && toId === undefined) {
    throw new Error(
      'fromId or toId must be defined for straight line connections'
    );
  }

  if (
    (fromId === undefined || toId === undefined) &&
    (vertices === undefined || vertices.length === 0)
  ) {
    throw new Error(
      'If either fromId or toId is not defined, vertices must be defined'
    );
  }

  const fromRect = getRectFromNodeId(fromId, vertices, unifiedViewerRenderer);
  const toRect = getRectFromNodeId(toId, vertices, unifiedViewerRenderer);
  if (fromRect === undefined || toRect === undefined) {
    return undefined;
  }

  return getStraightConnectionPathPointsFromBorder(fromRect, toRect);
};

export default getStraightLineConnection;
