import type { Action, Condition, ConditionalAction } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserQuery } from '@infield/features/user';
import { useFDMServices } from '@infield/providers/fdm-services';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';

interface Props {
  condition: Condition;
  action: Action;
}

export const useConditionalActionCreate = () => {
  const { actionsService, conditionsService, conditionalActionsService } =
    useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.conditions);
  const { data: user } = useCurrentUserQuery();
  const conditionalActionAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditConditionalAction
  );
  const conditionAuditMetrics = useMetrics(METRICS_NAMESPACES.auditCondition);
  const actionAuditMetrics = useMetrics(METRICS_NAMESPACES.auditAction);

  return useMutation<
    {
      createdConditionalAction: ConditionalAction;
      createdCondition: Condition;
      createdAction: Action;
    },
    Error,
    Props
  >(
    async ({ condition, action }) => {
      // Create conditional action
      const newConditionalAction: ConditionalAction = {
        externalId: uuid(),
        logic: 'or',
        parentObject: condition.source,
      };
      const createdConditionalAction =
        await conditionalActionsService.createConditionalActions(
          [newConditionalAction],
          user!
        );

      // Create condition
      const newCondition: Condition = {
        ...condition,
        conditionalAction: {
          ...newConditionalAction,
          space: createdConditionalAction.space,
        },
        field: 'status',
        operator: 'equals',
      };
      await conditionsService
        .createConditions([newCondition], user!, 'TemplateItem')
        .catch(async (err) => {
          // Delete conditional action
          await conditionalActionsService.deleteConditionalActions([
            newConditionalAction.externalId,
          ]);

          throw new Error(err);
        });

      // Create action
      const newAction: Action = {
        ...action,
        conditionalActions: {
          ...newConditionalAction,
          space: createdConditionalAction.space,
        },
        actionType: 'Message',
      };
      await actionsService
        .createActions([newAction], user!)
        .catch(async (err) => {
          // Delete conditional action and condition
          await Promise.all([
            conditionalActionsService.deleteConditionalActions([
              newConditionalAction.externalId,
            ]),
            conditionsService.deleteConditions([newCondition.externalId]),
          ]);

          throw new Error(err);
        });

      return {
        createdConditionalAction: newConditionalAction,
        createdCondition: newCondition,
        createdAction: newAction,
      };
    },
    {
      onError: (err) => {
        makeToast({
          body: t(
            'CONDITION_REQUEST_CREATE_ERROR',
            'Failed to create condition'
          ),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.CONDITIONAL_ACTION_CREATE,
          },
        });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE],
        });
      },
      onSuccess: async ({
        createdConditionalAction,
        createdCondition,
        createdAction,
      }) => {
        conditionalActionAuditMetrics.track('Create', {
          createdConditionalAction,
        });
        conditionAuditMetrics.track('Create', {
          createdCondition,
        });
        actionAuditMetrics.track('Create', {
          createdAction,
        });
      },
      mutationKey: [MutationKeys.CONDITIONAL_ACTION_CREATE],
    }
  );
};
