import { useAuthContext } from '@cognite/e2e-auth';
import type { Access, Group } from '@infield/features/access';
import { useSelectedRootLocationConfiguration } from '@infield/features/app-config/hooks';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { extractSpaceIdsRead, hasGroupWithScopeAllRead } from './utils';

export const useSpaceReadAccess = (type: 'app' | 'source') => {
  const { client } = useAuthContext();
  const selectedLocation = useSelectedRootLocationConfiguration();
  const space =
    type === 'app'
      ? selectedLocation?.appDataInstanceSpace
      : selectedLocation?.sourceDataInstanceSpace;

  return useQuery<Access, Error>(
    [QueryKeys.USER, 'spaceAccess', space, type, selectedLocation],
    async () => {
      const groups = (await client.groups.list()) as Group[];

      const hasReadAllSpacesAccess = hasGroupWithScopeAllRead(groups);
      const spaceIdsWithReadAccess = extractSpaceIdsRead(groups);
      const readAccessGroups = hasReadAllSpacesAccess
        ? ['all']
        : spaceIdsWithReadAccess;

      return {
        hasReadAllSpacesAccess,
        readAccessGroups,
      };
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: `${QueryKeys.USER}, spaceAccess`,
          },
        }),
    }
  );
};
