import type { TemplateStatus } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { CollapsablePanel } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import {
  selectedTaskAtom,
  selectedTemplateAtom,
  TemplateDetail,
  TemplateList,
  useTemplateListQuery,
  useTemplatesSearch,
} from '@infield/features/template';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import * as S from './elements';

export const TemplatesOverview: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const [searchParams] = useSearchParams({
    templateStatus: '',
    search: '',
  });

  const selectedTask = useRecoilValue(selectedTaskAtom);
  const [selectedTemplate, setSelectedTemplate] =
    useRecoilState(selectedTemplateAtom);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const templateStatus = (searchParams.get('templateStatus') ||
    'Draft') as TemplateStatus;

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    searchParams.get('search') || ''
  );
  const fetchMode = debouncedSearchQuery === '' ? 'list' : 'search';

  const {
    data: templatesList = [],
    isInitialLoading: isListLoading,
    isFetching: isListFetching,
    error: listError,
  } = useTemplateListQuery(templateStatus);

  const {
    data: templatesSearchList = [],
    isInitialLoading: isSearchLoading,
    isFetching: isSearchFetching,
    error: searchError,
  } = useTemplatesSearch({
    query: debouncedSearchQuery,
    properties: ['title'],
    enabled: fetchMode === 'search',
    limit: 100,
    templateStatus,
  });

  const isInitialLoading =
    fetchMode === 'search' ? isSearchLoading : isListLoading;
  const isFetching = fetchMode === 'search' ? isSearchFetching : isListFetching;
  const error = fetchMode === 'search' ? searchError : listError;

  const templates = useMemo(() => {
    if (fetchMode === 'search') {
      return templatesSearchList;
    }
    return templatesList;
  }, [fetchMode, templatesSearchList, templatesList]);

  useEffect(() => {
    if (templates.length > 0 && !isFetching && !isInitialLoading) {
      if (fetchMode === 'list') {
        if (selectedTemplate === undefined) {
          setSelectedTemplate(templates[0]);
        } else {
          setSelectedTemplate(
            templates.find((t) => t.externalId === selectedTemplate.externalId)
          );
        }
      }
    }
  }, [
    templates,
    isInitialLoading,
    fetchMode,
    isFetching,
    setSelectedTemplate,
    selectedTemplate,
  ]);

  useEffect(() => {
    if (!selectedTemplate?.externalId) {
      setIsCollapsed(false);
    } else if (selectedTask?.externalId) {
      setIsCollapsed(true);
    }
  }, [selectedTask?.externalId, selectedTemplate?.externalId]);

  if (error) {
    makeToast({
      type: 'danger',
      body: t(
        'TEMPLATE_LIST_QUERY_ERROR',
        'Could not load any templates. Try again later.'
      ),
    });
  }

  return (
    <S.Container>
      <CollapsablePanel
        sidePanelLeft={
          <TemplateList
            templateStatus={templateStatus}
            templates={templates}
            isLoading={isInitialLoading}
            debouncedSearchQuery={debouncedSearchQuery}
            setDebouncedSearchQuery={setDebouncedSearchQuery}
          />
        }
        sidePanelLeftVisible={!isCollapsed}
        sidePanelLeftWidth={320}
      >
        <TemplateDetail
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      </CollapsablePanel>
    </S.Container>
  );
};
