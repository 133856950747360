import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

type Props = { viewerDomElement: HTMLElement };

/**
 * It only provides place to render Reveal output.
 * It requires container with position: relative.
 * Avoid adding any extra logic here.
 */
export const RevealDomElement: FC<Props> = ({ viewerDomElement }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const containerRef = wrapperRef.current;
    if (containerRef && viewerDomElement) {
      containerRef.appendChild(viewerDomElement);
    }
    return () => {
      containerRef?.removeChild(viewerDomElement);
    };
  }, [viewerDomElement]);

  return <Container ref={wrapperRef} />;
};

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;

  canvas {
    height: 100%;
    width: 100%;
  }
`;
