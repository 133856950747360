import { Button, Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px;
`;

export const SaveButton = styled(Button)`
  width: 200px;
`;

export const DropdownContainer = styled(Flex).attrs({ direction: 'column' })`
  height: 30vh;
  overflow: auto;
`;
