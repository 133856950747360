import type { Cognite3DViewerOptions } from '@cognite/reveal';
import { Cognite3DViewer } from '@cognite/reveal';
import type { FC } from 'react';
import { createContext, useEffect, useState } from 'react';

import type { ThreeDModelIdentifier } from '../types';
import { isLowEndDevice } from '../utils';

import { ThreeDStylingContextProvider } from './3d-styling-context';

export type ThreeDContextType = {
  viewer: Cognite3DViewer;
};

export const ThreeDContext = createContext<ThreeDContextType | undefined>(
  undefined
);

type Props = ThreeDModelIdentifier & {
  children?: React.ReactNode;
  viewerOptions: Cognite3DViewerOptions;
};

/**
 * This provider sets up reveal viewer.
 * It also initializes 3d-styling-context
 */
export const ThreeDContextProvider: FC<Props> = ({
  children,
  viewerOptions,
}) => {
  const [contextValue, setContextValue] = useState<ThreeDContextType>();

  useEffect(() => {
    const initializeViewer = async () => {
      const RESOLUTION_THRESHOLD_REDUCTION = 0.65;
      const viewer = new Cognite3DViewer({
        ...viewerOptions,

        // to improve performance on mobile devices
        ...(isLowEndDevice() && {
          rendererResolutionThreshold:
            320 * 570 * RESOLUTION_THRESHOLD_REDUCTION,
          ssaoQualityHint: 'disabled',
          antiAliasingHint: 'disabled',
          continuousModelStreaming: false,
          enableEdges: false,
        }),
      });

      if (isLowEndDevice()) {
        viewer.cadBudget = {
          ...viewer.cadBudget,
          maximumRenderCost: viewer.cadBudget.maximumRenderCost / 2,
        };
      }

      setContextValue({ viewer });
    };

    initializeViewer();
  }, [viewerOptions]);

  // for debug
  useEffect(() => {
    (window as any).viewer = contextValue?.viewer;
  }, [contextValue]);

  if (!contextValue) {
    return null;
  }

  return (
    <ThreeDContext.Provider value={contextValue}>
      <ThreeDStylingContextProvider>{children}</ThreeDStylingContextProvider>
    </ThreeDContext.Provider>
  );
};
