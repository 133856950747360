import type { APMAppDataServiceOptions } from '../apm-app-data-service';
import { APMAppDataService } from '../apm-app-data-service';
import type { ChecklistItemResponseType, ServiceContract } from '../types';
import { VIEW_VERSIONS } from '../utils';

export class ChecklistItemsService extends APMAppDataService<ChecklistItemResponseType> {
  constructor(contract: ServiceContract, options: APMAppDataServiceOptions) {
    super(contract, 'ChecklistItem', VIEW_VERSIONS.CHECKLIST_ITEM, options);
  }
}
