import { ViewReference } from '@cognite/sdk';

export const MONITORING_ACL = 'monitoringTasksAcl';
export const NOTIFICATIONS_ACL = 'notificationsAcl';
export const TIMESERIES_ACL = 'timeSeriesAcl';
export const SESSION_ACL = 'sessionsAcl';

export const CHART_POINTS_PER_SERIES = 2000;
export const RAW_DATA_POINTS_THRESHOLD = 100000;

export const AUTO_ALIGN_PARAM = 'align_timesteps';

export const DEFAULT_AGGREGATION_DATA_POINTS_LIMIT = 1000;

export const NOT_LISTED_OPERATION_CATEGORY = 'Not listed operations';

export const DEFAULT_CORE_TIME_SERIES_VIEW: ViewReference = {
  type: 'view',
  space: 'cdf_cdm',
  externalId: 'CogniteTimeSeries',
  version: 'v1',
};

export const DEFAULT_CORE_TIME_SERIES_PROPERTIES = {
  isStep: false,
  type: 'numeric',
};
