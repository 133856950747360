import { t } from '@infield/features/i18n';

// TODO(INFIELD2-623): Implement user facing errors/notifications
export const openNotification = (args: any) => {
  console.warn('User notification is not implemented. Passed args:', args);
};

// below some stuff form infield-1

const ERROR = 'error';
// const WARNING = 'warning';
// const SUCCESS = 'success';

export type UiNotificationObject = {
  type: 'error' | 'warning' | 'success';
  message: React.ReactNode;
  closable?: boolean;
  customHeight?: string;
  style?: React.CSSProperties;
};

export const createApiError = (): UiNotificationObject => ({
  type: ERROR,
  message: (
    <div className="notification-api-error">
      <div>
        {t('CouldNotConnectToCogniteServers', {
          defaultValue: 'Could not connect to Cognite servers',
        })}
      </div>
      <button
        className="notification-reload"
        onClick={() => window.location.reload()}
        type="button"
      >
        {t('Reload', {
          defaultValue: 'Reload',
        })}
      </button>
    </div>
  ),
  closable: true,
});
