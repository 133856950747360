import chroma from 'chroma-js';
import Konva from 'konva';

import { Size } from '../annotations/types';

const PLACEHOLDER_TEXT = 'Type here...';
// This value is found by trial and error. It's chosen to not make the placeholder text wrap.
const PLACEHOLDER_RELATIVE_FONT_SIZE = 0.2;

// Based on https://stackoverflow.com/a/3943023
// Returns black or white text color based on the luminance of the given
// background color to ensure readability.
const CONTRAST_THRESHOLD = 0.179;
const PLACEHOLDER_ALPHA = 0.55;
const getPlaceholderTextColor = (backgroundColor: string): string =>
  chroma(backgroundColor).luminance() > CONTRAST_THRESHOLD
    ? `rgba(0, 0, 0, ${PLACEHOLDER_ALPHA})`
    : `rgba(255, 255, 255, ${PLACEHOLDER_ALPHA})`;

const getPlaceholderFontSize = (
  scale: number,
  sizeAndPadding: Size & { padding: number }
): number => {
  const { width, padding } = sizeAndPadding;
  const placeholderFontSize =
    PLACEHOLDER_RELATIVE_FONT_SIZE * width - padding * 2;
  return scale * placeholderFontSize;
};

export const getPlaceHolderFontSizeFromNode = (
  node: Konva.Label,
  scaleFactor: number
): number => {
  const sizeAndPadding = { ...node.size(), padding: node.getText().padding() };
  return getPlaceholderFontSize(scaleFactor, sizeAndPadding);
};

const createPlaceholderElementFromNode = (
  node: Konva.Label,
  scaleFactor: number,
  sizeAndPadding: Size & { padding: number }
): HTMLElement => {
  const placeholder = document.createElement('div');
  const textNode = node.getText();
  const tagNode = node.getTag();
  placeholder.style.position = 'absolute';
  placeholder.style.inset = '0px';
  placeholder.style.fontSize = `${getPlaceholderFontSize(
    scaleFactor,
    sizeAndPadding
  )}px`;
  placeholder.style.padding = `${textNode.padding() * scaleFactor}px`;
  placeholder.style.overflow = 'hidden';
  placeholder.style.outline = 'none';
  placeholder.style.resize = 'none';
  placeholder.style.lineHeight = `${textNode.lineHeight()}`;
  placeholder.style.fontFamily = textNode.fontFamily();
  placeholder.style.transformOrigin = 'left top';
  placeholder.style.textAlign = textNode.align();
  placeholder.style.display = 'flex';
  placeholder.style.alignItems = 'center';
  placeholder.style.justifyContent = 'center';
  placeholder.style.color = getPlaceholderTextColor(tagNode.fill());
  placeholder.style.pointerEvents = 'none';
  placeholder.innerText = PLACEHOLDER_TEXT;
  return placeholder;
};

export default createPlaceholderElementFromNode;
