import { useActivityDetails } from '@infield/features/activities';
import { useClassicOrAPMAssetQuery } from '@infield/features/asset';
import type { FC } from 'react';

import { ActivityDetailsContent } from './activity-details-content';

type Props = {
  activityId: string;
  onAssetClick: (assetExternalId: string) => void;
  onClose?: () => void;
};

export const ActivityDetails: FC<Props> = ({
  activityId,
  onAssetClick,
  onClose,
}) => {
  const {
    data: activity,
    isInitialLoading,
    isError,
  } = useActivityDetails(activityId);
  const { data: asset } = useClassicOrAPMAssetQuery(
    activity?.assetExternalId || ''
  );

  return (
    <ActivityDetailsContent
      activity={activity}
      asset={asset}
      isLoading={isInitialLoading}
      isError={isError}
      onAssetClick={onAssetClick}
      onClose={onClose}
    />
  );
};
