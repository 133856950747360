import {
  Annotation,
  isConnectionPolylineAnnotation,
} from '../../annotations/types';
import { ContainerConfig } from '../../containers/types';

const hasAtLeastOneDependencyBeenRemoved = (
  annotation: Annotation,
  currentAnnotationsAndContainerConfigsById: Record<
    string,
    Annotation | ContainerConfig
  >
): boolean => {
  if (!isConnectionPolylineAnnotation(annotation)) {
    return false;
  }

  const { fromId, toId } = annotation;

  if (
    fromId !== undefined &&
    currentAnnotationsAndContainerConfigsById[fromId] === undefined
  ) {
    return true;
  }

  if (
    toId !== undefined &&
    currentAnnotationsAndContainerConfigsById[toId] === undefined
  ) {
    return true;
  }

  return false;
};

export default hasAtLeastOneDependencyBeenRemoved;
