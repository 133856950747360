export type CustomerProject = {
  id: string;
  authType: 'legacy' | 'azureAD' | 'cogidp';
  cluster: ClusterAppInfo;
  configurationSchemaVersion?: number;
  aadDirectory?: string;
  org?: string;
};
export type Customer = {
  id: string;
  aliases?: string[];
  projects: CustomerProject[];
};

export type ClusterAppInfo = {
  name: Cluster;
  aadAppId?: string;
  stagingAadAppId?: string;
  apiBaseURL: string;
};
export type Cluster =
  | 'ew1'
  | 'az-eastus-1'
  | 'az-arn-001'
  | 'greenfield'
  | 'omv'
  | 'westeurope-1'
  | 'bluefield'
  | 'azure-dev'
  | 'asia-northeast1-1'
  | 'az-power-no-northeurope'
  | 'az-tyo-gp-001'
  | 'orangefield'
  | 'az-sin-sp-001'
  | 'aw-was-gp-001'
  | 'az-ams-sp-002'
  | 'aws-dub-dev';

export type User = AuthStateUser;

export type AuthStateUser = {
  id: number | string;
  cdfId: string;
  name: string;
  email: string;
  picture?: string;
};

export type AuthStateUnauthenticated = { status: 'UNAUTHENTICATED' };
export type AuthStateAuthenticating = {
  status: 'AUTHENTICATING';
  projectInfo: CustomerProject;
};
export type AuthStateAuthenticated = {
  status: 'AUTHENTICATED';
  user: AuthStateUser;
  projectInfo: CustomerProject;
};
export type AuthStateError = { status: 'ERROR'; message: string };

export type AuthState =
  | AuthStateUnauthenticated
  | AuthStateAuthenticating
  | AuthStateAuthenticated
  | AuthStateError;

export type Project = {
  name: string;
  apiUrl: string;
};

export const hasAuthStateProjectInfo = (
  state: AuthState
): state is AuthStateAuthenticating | AuthStateAuthenticated => {
  return (
    (state.status === 'AUTHENTICATING' && state.projectInfo !== undefined) ||
    (state.status === 'AUTHENTICATED' && state.projectInfo !== undefined)
  );
};
