import React, {
  createContext,
  useContext,
  memo,
  useEffect,
  useState,
} from 'react';

import { RecoilRoot } from 'recoil';

import { ToastContainer } from '@cognite/cogs-lab';
import { CogniteClient } from '@cognite/sdk';
import { CogniteClient as CogniteClientBeta } from '@cognite/sdk-beta';
import { useSDK } from '@cognite/sdk-provider';

import { getChartsStorageService } from '../services';
import {
  ChartsStorageServiceInterface,
  shamefulDummyChartsStorageService,
} from '../services/storage/ChartsStorageServiceInterface';
import { UserInfo } from '../types';
import { BetaSDKProvider } from '../utils';

// TODO: use this type in UFV, and delete the local 'ChartsContextType' from UFV
export type ChartsContextType = {
  getToken: () => Promise<string>;
  getUserInformation: () => Promise<UserInfo>;
  isProduction: () => boolean;
  getProject: () => string;
  getCluster: () => string | null;
  chartsStorageService: ChartsStorageServiceInterface;
};

export const ChartsContext = createContext<ChartsContextType>({
  getToken: () => {
    throw new Error('Could not initialize getToken in ChartsContext');
  },
  getUserInformation: () => {
    throw new Error('Could not initialize getUserInformation in ChartsContext');
  },
  isProduction: () => {
    throw new Error('Could not initialize isProduction in ChartsContext');
  },
  getProject: () => {
    throw new Error('Could not initialize getProject in ChartsContext');
  },
  getCluster: () => {
    throw new Error('Could not initialize getCluster in ChartsContext');
  },
  chartsStorageService: shamefulDummyChartsStorageService,
});

type ChartsProviderProps = React.PropsWithChildren<
  Omit<ChartsContextType, 'chartsStorageService'>
>;

let betaClientSingleton: CogniteClientBeta | null = null;
const getBetaClient = async (
  stable: CogniteClient,
  getProject: () => string,
  getToken: () => Promise<string>
) => {
  if (!betaClientSingleton) {
    betaClientSingleton = new CogniteClientBeta({
      appId: 'fusion.cognite.com',
      project: getProject(),
      getToken,
      baseUrl: stable.getBaseUrl(),
    });
    await betaClientSingleton.authenticate();
  }
  return betaClientSingleton;
};

export const ChartsProvider: React.FC<ChartsProviderProps> = memo(
  ({
    children,
    getToken,
    getUserInformation,
    isProduction,
    getProject,
    getCluster,
  }) => {
    const sdk = useSDK();
    const [betaClient, setBetaClient] = useState<CogniteClientBeta | null>(
      null
    );

    useEffect(() => {
      getBetaClient(sdk, getProject, getToken).then(setBetaClient);
    }, []);

    if (!betaClient || !sdk) {
      return;
    }

    return (
      <ChartsContext.Provider
        value={{
          getToken,
          getUserInformation,
          isProduction,
          getProject,
          getCluster,
          chartsStorageService: getChartsStorageService(sdk),
        }}
      >
        <BetaSDKProvider betaClient={betaClient}>
          <ToastContainer />
          <RecoilRoot>{children}</RecoilRoot>
        </BetaSDKProvider>
      </ChartsContext.Provider>
    );
  }
);

export const useChartsContext = (): ChartsContextType =>
  useContext(ChartsContext);

export const useChartsStorageService = () =>
  useContext(ChartsContext).chartsStorageService;
