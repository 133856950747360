import type { Timeseries } from '@cognite/sdk';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useTimeseriesQuery = (
  timeseriesExternalId: string | undefined
) => {
  const { timeseriesService } = useFDMServices();

  return useQuery<Timeseries | null>(
    [QueryKeys.TIMESERIES, timeseriesExternalId],
    async () => {
      if (timeseriesExternalId) {
        const timeseries = await timeseriesService.getTimeseriesByExternalId(
          timeseriesExternalId
        );
        return timeseries || null;
      }
      return null;
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.TIMESERIES,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(timeseriesExternalId),
    }
  );
};
