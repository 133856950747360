import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { makeToast } from '@cognite/cogs-lab';
import { useAssetsQuery } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useDeleteMedia } from '../hooks/use-delete-media';
import { useUpdateMedia } from '../hooks/use-update-media';
import { useUploadMedia } from '../hooks/use-upload-media';
import { MediaEditor } from '../media-editor/media-editor';
import { FilePickerHeadless } from '../media-file-picker/media-picker-headless';
import { SelectedMediaDialogue } from '../selected-media-dialogue/selected-media-dialogue';
import type { ImageItem, ImageItemMetadata, ImageToUpload } from '../types';

import { convertBytesToMb } from './utils';

interface Props {
  visible: boolean;
  enableEditingObservationMedia?: boolean;
  isLoading: boolean;
  loadingText?: string;
  onSave: (externalIds: string[]) => void;
  onClose: () => void;
  mediaInstanceIds?: InstanceOrExternalId[];
  assetInstanceIds?: InstanceOrExternalId[];
  metadata?: ImageItemMetadata;
  fileSizeLimitBytes?: number;
}

export const MediaManager: FC<Props> = ({
  metadata,
  enableEditingObservationMedia,
  isLoading,
  loadingText,
  mediaInstanceIds = [],
  assetInstanceIds,
  visible,
  fileSizeLimitBytes,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);
  const { isIdm } = useAppConfigContext();
  const [mediaToUpload, setMediaToUpload] = useState<ImageToUpload[]>([]);
  const [removeMediaInstanceIds, setRemoveMediaInstanceIds] = useState<
    InstanceOrExternalId[]
  >([]);

  const [mediaUpdates, setMediaUpdates] = useState<
    Array<Pick<ImageItem, 'externalId' | 'space' | 'metadata'>>
  >([]);

  const { mutateAsync: upsertMedia, isLoading: isUploadingMedia } =
    useUploadMedia();

  const { mutateAsync: deleteMedia, isLoading: isDeletingMedia } =
    useDeleteMedia();

  const { mutateAsync: updateMedia, isLoading: isUpdatingMedia } =
    useUpdateMedia();

  // Fetch assetId if not using IDM, since we cannot contextualize with assetExternalId in classic
  const assetExternalIds = assetInstanceIds?.map(
    ({ externalId }) => externalId
  );
  const { data: classicAssets } = useAssetsQuery(
    !isIdm ? assetExternalIds : undefined
  );
  const classicAssetIds = classicAssets?.map(({ id }) => id);

  const [selectedMedia, setSelectedMedia] = useState<ImageToUpload | null>();

  const handleMediaUpload = ({ files: uploadedFile }: { files: File[] }) => {
    const file = uploadedFile[0];
    const isSizeLimitExceeded =
      fileSizeLimitBytes && file.size > fileSizeLimitBytes;
    if (isSizeLimitExceeded) {
      makeToast({
        type: 'danger',
        body: t(
          'MEDIA_MANAGER_FILE_SIZE_EXCEEDING_LIMIT_ERROR',
          'File exceeded {{limit}}MB size limit',
          { limit: convertBytesToMb(fileSizeLimitBytes) }
        ),
      });
    }
    if (file && file instanceof File && !isSizeLimitExceeded) {
      const newMedia = {
        metadata: {
          ...(Boolean(metadata) && metadata),
          fileName: file.name,
          size: file.size,
          uploadedToUiTime: Date.now(),
        },
        url: file,
        externalId: uuid(),
        name: file.name,
      };
      setMediaToUpload((media) => [...media, newMedia]);
      setSelectedMedia(newMedia);
    }
  };

  const handleMediaEditorOnClose = (updatedMediaDescription?: string) => {
    if (
      selectedMedia &&
      (updatedMediaDescription || updatedMediaDescription === '')
    ) {
      const isMediaNotUploaded = mediaToUpload.find(
        ({ externalId }) => externalId === selectedMedia.externalId
      );
      if (isMediaNotUploaded) {
        // Media is not uploaded yet
        setMediaToUpload((previousMediaToUpload) =>
          previousMediaToUpload.map((item) => {
            if (item.externalId === selectedMedia.externalId) {
              return {
                ...item,
                metadata: {
                  ...item.metadata,
                  description: updatedMediaDescription,
                },
              };
            }
            return item;
          })
        );
      } else if (
        mediaUpdates.some(
          ({ externalId }) => externalId === selectedMedia.externalId
        )
      ) {
        // Existing Media Updates with updated description
        setMediaUpdates((prevMediaUpdates) =>
          prevMediaUpdates.map((item) => {
            if (item.externalId === selectedMedia?.externalId) {
              return {
                ...item,
                metadata: {
                  ...item.metadata,
                  description: updatedMediaDescription,
                },
              };
            }
            return item;
          })
        );
      } else {
        // Existing Media Updates
        setMediaUpdates((prevMediaUpdates) => [
          ...prevMediaUpdates,
          {
            externalId: selectedMedia.externalId!,
            space: selectedMedia.space,
            metadata: {
              ...selectedMedia.metadata,
              description: updatedMediaDescription,
            },
          },
        ]);
      }
    }

    setSelectedMedia(null);
  };

  const handleOnDelete = () => {
    if (selectedMedia) {
      const isMediaNotUploaded = mediaToUpload.find(
        ({ externalId }) => externalId === selectedMedia.externalId
      );
      if (isMediaNotUploaded) {
        // Media is not uploaded yet
        removeMediaToUpload([selectedMedia.externalId!]);
      } else {
        // Remove Existing Media
        setRemoveMediaInstanceIds((previousRemoveMediaExternalIds) => [
          ...previousRemoveMediaExternalIds,
          { externalId: selectedMedia.externalId!, space: selectedMedia.space },
        ]);
      }
    }

    setSelectedMedia(null);
  };

  const removeMediaToUpload = (externalIds: string[]) => {
    if (mediaToUpload.length > 0)
      setMediaToUpload((media) =>
        media?.filter(
          (item) => item.externalId && !externalIds.includes(item.externalId)
        )
      );
  };

  const onSaveMedia = async () => {
    const uploadedInstanceIds = await upsertMedia({
      media: mediaToUpload,
      assetIds: classicAssetIds,
      assetInstanceIds,
    });

    const successfullyUploadedExternalIds = uploadedInstanceIds.filter(
      ({ externalId }) => Boolean(externalId)
    ) as InstanceOrExternalId[];

    removeMediaToUpload(
      successfullyUploadedExternalIds.map(({ externalId }) => externalId!)
    );

    if (removeMediaInstanceIds.length > 0)
      await deleteMedia({
        fileInstanceIds: removeMediaInstanceIds,
      });

    // Filter deleted items
    const filteredMediaUpdates = mediaUpdates.filter(
      (mediaUpdate) =>
        !removeMediaInstanceIds.find(
          ({ externalId }) => externalId === mediaUpdate.externalId!
        )
    );
    if (filteredMediaUpdates.length > 0)
      await updateMedia({
        items: filteredMediaUpdates.map((updates) => ({
          assetIds: classicAssetIds,
          metadata: { ...updates.metadata },
          externalId: updates.externalId,
          space: updates.space,
        })),
      });

    const updatedExternalIds = mediaInstanceIds
      .filter(
        ({ externalId }) =>
          !removeMediaInstanceIds.find(
            (removeMediaInstanceId) =>
              removeMediaInstanceId.externalId === externalId
          )
      )
      .concat(successfullyUploadedExternalIds)
      .map(({ externalId }) => externalId);

    onSave(updatedExternalIds);

    setRemoveMediaInstanceIds([]);
    setMediaUpdates([]);
  };

  const getMediaUpdatedMetadata = useCallback(
    (externalId: string) => {
      return mediaUpdates.find(
        (mediaUpdate) => mediaUpdate.externalId === externalId
      )?.metadata;
    },
    [mediaUpdates]
  );

  const uploadedMediaToShow = useMemo(() => {
    return mediaInstanceIds.filter(
      ({ externalId }) =>
        !removeMediaInstanceIds.find(
          (removeMediaInstanceId) =>
            removeMediaInstanceId.externalId === externalId
        )
    );
  }, [mediaInstanceIds, removeMediaInstanceIds]);

  const showEmptyView = useMemo(() => {
    if (isLoading) return true;
    if (mediaToUpload.length === 0 && uploadedMediaToShow.length === 0)
      return true;
    return false;
  }, [isLoading, mediaToUpload.length, uploadedMediaToShow.length]);

  const isThereAChange = useMemo(() => {
    return (
      mediaUpdates.length > 0 ||
      mediaToUpload.length > 0 ||
      removeMediaInstanceIds.length > 0
    );
  }, [mediaToUpload.length, mediaUpdates, removeMediaInstanceIds.length]);

  const isSaving = useMemo(() => {
    return isUploadingMedia || isDeletingMedia || isUpdatingMedia;
  }, [isDeletingMedia, isUpdatingMedia, isUploadingMedia]);

  return (
    <>
      {selectedMedia && (
        <MediaEditor
          disableObservationItems={!enableEditingObservationMedia}
          media={{
            ...selectedMedia,
            metadata: {
              ...selectedMedia.metadata,
              ...getMediaUpdatedMetadata(selectedMedia.externalId!),
            },
          }}
          visible
          onClose={handleMediaEditorOnClose}
          onDelete={handleOnDelete}
        />
      )}
      <FilePickerHeadless
        files={[]}
        accept="image/png, image/jpeg, video/mp4, video/quicktime"
        onChange={(files) => {
          handleMediaUpload({ files });
        }}
      >
        {({ onClickSelectFile }) => (
          <SelectedMediaDialogue
            mediaToUpload={mediaToUpload}
            showEmptyView={showEmptyView}
            getMediaUpdatedMetadata={getMediaUpdatedMetadata}
            uploadedMediaToShow={uploadedMediaToShow}
            onClickSelectFile={onClickSelectFile}
            onSelectMedia={setSelectedMedia}
            onSaveMedia={onSaveMedia}
            visible={visible}
            onClose={onClose}
            isSaving={isSaving}
            isLoading={isLoading}
            loadingText={loadingText}
            isUploadingMedia={isUploadingMedia}
            isThereAChange={isThereAChange}
          />
        )}
      </FilePickerHeadless>
    </>
  );
};
