import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { LoaderIcon } from '@cognite/cogs.js-v10';
import { useFetchMedia } from '@infield/features/media';
import type { FC } from 'react';

import { MediaItem } from './media-item';

type Props = {
  fileInstanceId: InstanceOrExternalId;
};

export const SingleMediaContent: FC<Props> = ({ fileInstanceId }) => {
  const { externalId, space } = fileInstanceId;
  const { isLoading, data: media } = useFetchMedia(externalId, space);

  if (isLoading || !media) {
    return <LoaderIcon className="cogs-icon--rotating" />;
  }

  return <MediaItem isFullScreen media={media} />;
};
