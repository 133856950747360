import Konva from 'konva';
import { IRect } from 'konva/cmj/types';
import cloneDeep from 'lodash/cloneDeep';

import { Container } from '../containers/Container';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import UnifiedViewerRenderer from '../UnifiedViewerRenderer';
import isTransparentColor from '../utils/isTransparentColor';
import { getCloudSvgCommand } from '../utils/rectUtils';
import shamefulSafeKonvaScale from '../utils/shamefulSafeKonvaScale';

import getBaseStylePropertiesFromNode from './getBaseStylePropertiesFromNode';
import getDenormalizedRectDimensionsFromAnnotation from './getDenormalizedRectDimensionsFromAnnotation';
import getNormalizedDimensionsFromNode from './getNormalizedDimensionsFromNode';
import setAnnotationNodeEventHandlers from './setAnnotationNodeEventHandlers';
import shouldBeDraggable from './shouldBeDraggable';
import shouldBeResizable from './shouldBeResizable';
import shouldBeSelectable from './shouldBeSelectable';
import shouldBeStrokeScaleEnabled from './shouldBeStrokeScaleEnabled';
import { getDimensionsFromPaths } from './SvgSerializer';
import {
  AnnotationSerializer,
  AnnotationType,
  isCloudAnnotation,
  isCloudNode,
  RectangleAnnotation,
  RectangleStyleProperties,
  Scale,
  Size,
  SvgPath,
} from './types';

// A rectangle annotation is a Konva.Path when it is in its "cloud form".
const isValidRectangleAnnotation = (
  node: Konva.Node
): node is Konva.Rect | Konva.Path =>
  node instanceof Konva.Rect || node instanceof Konva.Path;

const getDimensions = (
  annotation: RectangleAnnotation,
  getContainerById: (id: string) => Container | undefined
) => {
  if (annotation.containerId === undefined) {
    return getRectDimensionsFromAnnotation(annotation);
  }

  const container = getContainerById(annotation.containerId);
  if (container === undefined) {
    return undefined;
  }

  return getDenormalizedRectDimensionsFromAnnotation(
    annotation,
    container.getContentNode().size()
  );
};

const getRectDimensionsFromAnnotation = (
  annotation: RectangleAnnotation
): IRect => ({
  x: annotation.x,
  y: annotation.y,
  width: annotation.width,
  height: annotation.height,
});

const getRectStyleFromNode = (node: Konva.Rect | Konva.Path) => {
  if (isCloudNode(node)) {
    return {
      ...getBaseStylePropertiesFromNode(node),
      shouldApplyCloudTransform: true,
    };
  }
  return {
    ...getBaseStylePropertiesFromNode(node),
    cornerRadius: node.cornerRadius(),
    shouldApplyCloudTransform: false,
  };
};

type RectangleNodeProperties<MetadataType> = {
  [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION;
  annotationType: AnnotationType.RECTANGLE;
  id: string;
  strokeScaleEnabled: boolean;
  containerId?: string;
  isSelectable: boolean;
  isDraggable: boolean;
  isResizable: boolean;
  fillEnabled: boolean;
  data?: string;
  metadata?: MetadataType;
} & IRect &
  RectangleStyleProperties;

const getNodePropertiesFromAnnotation = <MetadataType>(
  annotation: RectangleAnnotation<MetadataType>,
  unifiedViewerRenderer: UnifiedViewerRenderer
): RectangleNodeProperties<MetadataType> | undefined => {
  const dimensions = getDimensions(
    annotation,
    unifiedViewerRenderer.getContainerById
  );
  if (dimensions === undefined) {
    // eslint-disable-next-line no-console
    console.warn(
      `Could not get dimensions for annotation ${annotation.id}. If it has a container, maybe the container hasn't loaded yet?`
    );
    return undefined;
  }

  return {
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
    annotationType: AnnotationType.RECTANGLE,
    id: annotation.id,
    data: isCloudAnnotation(annotation)
      ? getCloudSvgCommand(dimensions)
      : undefined,
    containerId: annotation.containerId,
    isSelectable: shouldBeSelectable(annotation.isSelectable),
    isDraggable: shouldBeDraggable(annotation.isDraggable),
    isResizable: shouldBeResizable(annotation.isResizable),
    fill: annotation.style?.fill,
    stroke: annotation.style?.stroke,
    strokeWidth: annotation.style?.strokeWidth,
    strokeScaleEnabled: shouldBeStrokeScaleEnabled(annotation),
    opacity: annotation.style?.opacity,
    dash: annotation.style?.dash,
    cornerRadius: annotation.style?.cornerRadius,
    fillEnabled: !isTransparentColor(annotation.style?.fill),
    metadata: annotation.metadata,
    // Note: If containerId is set, then we always should assume that the dimensions are
    // normalized.
    ...dimensions,
  };
};

type TransformationProperties = {
  size: Size;
  scale: Scale;
  path?: SvgPath;
};

const RectangleSerializer: AnnotationSerializer<
  RectangleAnnotation,
  TransformationProperties
> = {
  isSelectable: (node: Konva.Node) =>
    shouldBeSelectable(node.attrs.isSelectable),

  isDraggable: (node: Konva.Node) => shouldBeDraggable(node.attrs.isDraggable),

  isResizable: (node: Konva.Node) => shouldBeResizable(node.attrs.isResizable),

  getTransformationPropertiesFromNode: (node: Konva.Node) => {
    if (!isValidRectangleAnnotation(node)) {
      throw new Error('Expected node to be a Konva.Rect or Konva.Path');
    }

    return {
      size: node.size(),
      scale: shamefulSafeKonvaScale(node.scale()),
      path: isCloudNode(node) ? { d: node.data() } : undefined,
    };
  },

  normalize: ({
    size,
    scale,
    path,
  }: {
    size: Size;
    scale: Scale;
    path?: SvgPath;
  }) => {
    const dimensions = path ? getDimensionsFromPaths([path]) : undefined;
    const width = dimensions?.width ?? size.width;
    const height = dimensions?.height ?? size.height;

    return {
      size: {
        width: width * scale.x,
        height: height * scale.y,
      },
      scale: path !== undefined ? scale : { x: 1, y: 1 },
      path,
    };
  },

  serialize: (node, unifiedViewerRenderer) => {
    if (!isValidRectangleAnnotation(node)) {
      throw new Error('Expected node to be a Konva.Rect or Konva.Path');
    }

    return {
      id: node.id(),
      type: AnnotationType.RECTANGLE,
      containerId: node.attrs.containerId,
      isSelectable: shouldBeSelectable(node.attrs.isSelectable),
      isDraggable: shouldBeDraggable(node.attrs.isDraggable),
      isResizable: shouldBeSelectable(node.attrs.isResizable),
      ...getNormalizedDimensionsFromNode(
        node,
        unifiedViewerRenderer.getContainerRectRelativeToStageById
      ),
      style: getRectStyleFromNode(node),
      metadata: cloneDeep(node.attrs.metadata),
    };
  },

  deserialize: (annotation, unifiedViewer, unifiedViewerRenderer) => {
    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return undefined;
    }

    return setAnnotationNodeEventHandlers(
      isCloudAnnotation(annotation)
        ? new Konva.Path(nodeProperties)
        : new Konva.Rect(nodeProperties),
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },

  updateNode(node, annotation, unifiedViewer, unifiedViewerRenderer): void {
    if (!isValidRectangleAnnotation(node)) {
      throw new Error('Expected node to be a Konva.Rect or Konva.Path');
    }

    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return;
    }

    node.setAttrs(nodeProperties);

    setAnnotationNodeEventHandlers(
      node,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },
};

export default RectangleSerializer;
