import type { LocationFilterDTO } from '@cognite/apm-config';
import type { AppConfig } from '@infield/features/app-config';
import { createContext } from 'react';

import type { InfieldIdmConfig } from './types';

export const AppConfigContext = createContext<{
  appConfig: AppConfig;
  isIdm: boolean;
  idmLocations: LocationFilterDTO[] | undefined;
  location: InfieldIdmConfig | undefined;
} | null>(null);
