import type { TaskInterval } from '@cognite/apm-client';
import type { Timestamp } from '@cognite/sdk';
import { registerDayjsPlugins } from '@infield/utils/dayjsPlugins';
import { DEFAULT_TIME_FORMAT } from '@infield/utils/defaultDateFormats';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';

import { getAllTimezones } from './time-zone-options-utils';
import type { IntervalFrequencyOption, IntervalInputOption } from './types';

registerDayjsPlugins();

export enum Frequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export const DAYS_OF_WEEK = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
export const WEEKS = ['1', '2', '3', '4'];
export const MONTHS = [
  { value: '1', label: 'Jan' },
  { value: '2', label: 'Feb' },
  { value: '3', label: 'Mar' },
  { value: '4', label: 'Apr' },
  { value: '5', label: 'May' },
  { value: '6', label: 'Jun' },
  { value: '7', label: 'Jul' },
  { value: '8', label: 'Aug' },
  { value: '9', label: 'Sep' },
  { value: '10', label: 'Oct' },
  { value: '11', label: 'Nov' },
  { value: '12', label: 'Dec' },
];
export const FREQUENCY_OPTIONS = [
  Frequency.DAILY,
  Frequency.WEEKLY,
  Frequency.MONTHLY,
  Frequency.YEARLY,
];

const currentYear = dayjs().year();
const isLeapYear = dayjs(currentYear.toString()).isLeapYear();

export const INTERVAL_RANGES = {
  [Frequency.DAILY]: { min: 1, max: isLeapYear ? 366 : 365 },
  [Frequency.WEEKLY]: { min: 1, max: 52 },
  [Frequency.MONTHLY]: { min: 1, max: 12 },
  [Frequency.YEARLY]: { min: 1, max: 1 },
};

const DEFAULT_START_SHIFT_TIME = '360'; // '06:00' in minutes;
const DEFAULT_SHIFT_DURATION_HOURS = 12;
const DEFAULT_SHIFT_DURATION_MINUTES = DEFAULT_SHIFT_DURATION_HOURS * 60;
const MINUTES_PER_DAY = 24 * 60;

export const getTimeOptions = () => {
  return Array(24)
    .fill(null)
    .map((_, i) => {
      const valueDayjs = dayjs().hour(i).minute(0).second(0).millisecond(0);
      const halfHourValueDayjs = dayjs()
        .hour(i)
        .minute(30)
        .second(0)
        .millisecond(0);
      const valueInMinutes = dayjs.duration({ hours: i }).asMinutes();
      return [
        {
          value: String(valueInMinutes),
          label: valueDayjs.format(DEFAULT_TIME_FORMAT),
        },
        {
          value: String(valueInMinutes + 30),
          label: halfHourValueDayjs.format(DEFAULT_TIME_FORMAT),
        },
      ];
    })
    .flat();
};

export const findTimeOption = (
  timeOptions: IntervalInputOption[],
  time: number
) => timeOptions.find(({ value }) => value === String(time));

const getUtcOffsetMinutes = (tzName: string) => {
  const now = dayjs(new Date());
  return now.tz(tzName).utcOffset();
};

const getUtcOffsetString = (tzName: string): string => {
  const offsetMinutes = getUtcOffsetMinutes(tzName);
  const sign = offsetMinutes < 0 ? '-' : '+';
  const absOffsetMinutes = Math.abs(offsetMinutes);
  const hours = Math.floor(absOffsetMinutes / 60);
  const minutes = absOffsetMinutes % 60;
  return `${sign}${padZero(hours)}:${padZero(minutes)}`;
};

const padZero = (number: number): string => {
  return number.toString().padStart(2, '0');
};

export const timezoneOptions = getAllTimezones()
  .map((timeZone) => ({
    label: `${timeZone
      .replace(/(\w+\/)/g, '')
      .replace(/_/g, ' ')} (GMT${getUtcOffsetString(timeZone)})`,
    value: timeZone,
  }))
  .sort((timeZoneOne, timeZoneTwo) =>
    timeZoneOne.label.localeCompare(timeZoneTwo.label)
  );

export const findTimezoneOption = (timeZone: string) => {
  const timezoneOptionFound = timezoneOptions.find(
    (timezoneOption) => timezoneOption.value === timeZone
  );
  if (!timezoneOptionFound) {
    return timezoneOptions.find(
      (timezoneOption) => timezoneOption.value === 'Europe/Paris'
    )!;
  }
  return timezoneOptionFound;
};

export const findFrequencyOption = (
  options: IntervalFrequencyOption[],
  frequency?: string
) => {
  const frequencyOptionFound = options.find(
    (frequencyOption) => frequencyOption.value === frequency
  );
  if (!frequencyOptionFound) {
    return options.find(
      (frequencyOption) => frequencyOption.value === 'DAILY'
    )!;
  }
  return frequencyOptionFound;
};

export const filterByLabel = (
  option: IntervalInputOption,
  searchText: string
) => option.label.toLowerCase().includes(searchText.toLowerCase());

export const findInitialStartTime = (
  timeOptions: IntervalInputOption[],
  receivedStartTime: Timestamp,
  timezone: string
): IntervalInputOption => {
  const defaultStartTime = timeOptions.find(
    ({ value }) => value === DEFAULT_START_SHIFT_TIME
  )!;
  const startTimeInMinutes = convertTimestampToMinutes(
    receivedStartTime,
    timezone
  );
  return findTimeOption(timeOptions, startTimeInMinutes) || defaultStartTime;
};

export const findInitialEndTime = (
  timeOptions: IntervalInputOption[],
  receivedStartTime: IntervalInputOption,
  receivedEndTime: Timestamp,
  timezone: string
): IntervalInputOption => {
  const defaultEndTime = findIntuitiveEndTime(receivedStartTime.value);
  const endTimeInMinutes = convertTimestampToMinutes(receivedEndTime, timezone);

  return findTimeOption(timeOptions, endTimeInMinutes) || defaultEndTime;
};

// Finds the endTime based on the start time (basically we return startTime + DEFAULT_SHIFT_DURATION_MINUTES) while making sure
// that the endTime is not greater than 24 hours
export const findIntuitiveEndTime = (startTimeValue: string | number) => {
  const baseTime = Number(startTimeValue);
  const possibleEndTime =
    (baseTime + DEFAULT_SHIFT_DURATION_MINUTES) % MINUTES_PER_DAY;
  const possibleEndTimeDayjs = dayjs()
    .hour(0)
    .minute(possibleEndTime)
    .second(0)
    .millisecond(0);
  return {
    value: String(possibleEndTime),
    label: possibleEndTimeDayjs.format(DEFAULT_TIME_FORMAT),
  };
};

export const getDefaultIntervalSettings: () => TaskInterval = () => {
  const timezone = findTimezoneOption(dayjs.tz.guess()).value;
  const startTimeTimestamp = convertMinutesToTimestamp(
    Number(DEFAULT_START_SHIFT_TIME),
    timezone
  );
  const endTimeMinutes = findIntuitiveEndTime(DEFAULT_START_SHIFT_TIME).value;
  const endTimeTimestamp = convertMinutesToTimestamp(
    Number(endTimeMinutes),
    timezone
  );

  return {
    externalId: uuid(),
    startTime: startTimeTimestamp,
    endTime: endTimeTimestamp,
    timezone,
    freq: 'DAILY',
    interval: '1',
  };
};

export const convertTimestampToMinutes = (
  timestamp: Timestamp,
  timezone: string
) => {
  const startOfDayUTC = dayjs(timestamp).tz(timezone).startOf('day').valueOf();
  const differenceInMinutes =
    (dayjs(timestamp).valueOf() - startOfDayUTC) / (1000 * 60);

  return differenceInMinutes;
};

// To take into account offset changes when switching timezones which can shift
// startDate to another day
export const adjustDateWithTimezoneOffset = (
  timezone: string,
  prevTimezone: string,
  startDate: dayjs.Dayjs = dayjs()
) => {
  const prevOffset = startDate.tz(prevTimezone).utcOffset();
  const newOffset = startDate.tz(timezone).utcOffset();
  const timezoneDiffMinutes = prevOffset - newOffset;
  const adjustedStartDate = startDate.add(timezoneDiffMinutes, 'minute');
  return adjustedStartDate;
};

export const convertMinutesToTimestamp = (
  minutes: number,
  timezone: string,
  startDate: dayjs.Dayjs = dayjs()
) => {
  const startOfDayUTC = dayjs.tz(startDate, timezone).startOf('day');
  let timestamp;
  if (minutes > MINUTES_PER_DAY) {
    const remainingMinutes = minutes % MINUTES_PER_DAY;
    timestamp = startOfDayUTC
      .add(remainingMinutes, 'minutes')
      .add(1, 'day')
      .toDate();
  } else {
    timestamp = startOfDayUTC.add(minutes, 'minutes').toDate();
  }

  return timestamp;
};

export const findLocalStartDate = (
  startDate: Timestamp | undefined,
  timezone: string
) => {
  const baseStartDate = startDate ? dayjs(startDate) : dayjs();
  const localStartDate = dayjs(baseStartDate).tz(timezone);
  return localStartDate;
};

export const getEndTimeTimestamp = (
  endTimeInMinutes: number,
  startTimeInMinutes: number,
  timezone: string,
  startDate?: dayjs.Dayjs
) => {
  const adjustedMinutes =
    endTimeInMinutes < startTimeInMinutes
      ? endTimeInMinutes + MINUTES_PER_DAY
      : endTimeInMinutes;
  const endTimeTimestamp = convertMinutesToTimestamp(
    adjustedMinutes,
    timezone,
    startDate
  );
  return endTimeTimestamp;
};

export const getSettingsFromByDay = (
  byDayArray: string[] | null
): { daysFromByDay: string[]; weeksFromByDay: string[] } => {
  const selectedDaysSet = new Set<string>();
  const selectedWeeksSet = new Set<string>();

  byDayArray?.forEach((day) => {
    const weekNumber = day.slice(0, -2);
    const dayOfWeek = day.slice(-2);

    selectedDaysSet.add(dayOfWeek);
    selectedWeeksSet.add(weekNumber);
  });

  const daysFromByDay = Array.from(selectedDaysSet);
  const weeksFromByDay = Array.from(selectedWeeksSet);

  return { daysFromByDay, weeksFromByDay };
};

export const getUpdatedByDay = (
  selectedDays: string[] | null,
  selectedWeeks: string[] | null
) => {
  if (!selectedDays || selectedDays.length === 0) {
    return null;
  }
  if (!selectedWeeks || selectedWeeks.length === 0) {
    return selectedDays;
  }
  const updatedSelectedDays: string[] = [];

  selectedWeeks?.forEach((week) => {
    selectedDays?.forEach((day) => {
      updatedSelectedDays.push(week + day);
    });
  });

  return updatedSelectedDays;
};

export const humanizeSelectedOptions = (
  translationPrefix: string,
  t: any,
  selectedOptions?: string[]
) => {
  if (!selectedOptions || selectedOptions.length === 0) {
    return '';
  }
  const translatedOptions = selectedOptions?.map((option) =>
    t(`${translationPrefix}_${option}`, option)
  );
  const translatedAndPart = t(
    'TASK_INTERVAL_INTERVAL_REACURANCE_DESCRIPTION_AND_PART',
    'and'
  );

  if (translatedOptions.length === 1) {
    return translatedOptions[0];
  }
  // If the array has two or more elements, join them with commas and add 'and' before the last element
  return `${translatedOptions
    .slice(0, -1)
    .join(', ')} ${translatedAndPart} ${translatedOptions.slice(-1)}`;
};
