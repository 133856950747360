import type { APMAsset } from '@cognite/apm-client';
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Flex,
  Heading,
  TagChip,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC, PropsWithChildren } from 'react';

import { ObservationDetailsProgress } from '../observation-details-progress';

import * as S from './elements';

interface Props {
  asset?: APMAsset;
  totalSteps?: number;
  completedSteps?: number;
  hasNext?: boolean;
  onBack: () => void;
  onPrevious: () => void;
  onNext: () => void;
}

export const ObservationDetailsStepWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  asset,
  totalSteps: total,
  completedSteps: completed,
  hasNext,
  onBack,
  onPrevious,
  onNext,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);

  return (
    <S.Container>
      <Flex direction="column">
        <S.Header>
          <S.BackButton
            icon={<ArrowLeftIcon />}
            type="ghost"
            onClick={onBack}
          />
          <S.HeaderWrapper>
            <Heading level={5}>
              {t('OBSERVATION_STEP_HEADER_TITLE', 'Create observation')}
            </Heading>
            {asset && <TagChip label={asset.title || ''} size="x-small" />}
          </S.HeaderWrapper>
        </S.Header>
        <ObservationDetailsProgress total={total} completed={completed} />
      </Flex>
      <S.ContentWrapper>{children}</S.ContentWrapper>
      <S.Footer>
        <S.FooterButton icon={<ChevronLeftIcon />} onClick={onPrevious}>
          {t('OBSERVATION_STEP_FOOTER_BUTTON_PREVIOUS', 'Previous')}
        </S.FooterButton>
        <S.FooterButton
          icon={<ChevronRightIcon />}
          iconPlacement="right"
          disabled={!hasNext}
          onClick={onNext}
        >
          {t('OBSERVATION_STEP_FOOTER_BUTTON_NEXT', 'Next')}
        </S.FooterButton>
      </S.Footer>
    </S.Container>
  );
};
