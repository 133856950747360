import type { Observation } from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

export const useObservationsDetailsQuery = (externalIds?: string[]) => {
  const { observationService } = useFDMServices();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const observationFilter: Filters | undefined =
    externalIds && externalIds.length > 0
      ? {
          in: {
            property: 'externalId',
            in: externalIds!,
          },
        }
      : undefined;

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  return useQuery<Observation[]>(
    [QueryKeys.OBSERVATION, observationFilter],
    async () => {
      sliTimerStartTime.current = Date.now();

      const observations = await observationService.getObservations(
        observationFilter
      );
      return observations;
    },
    {
      onError: (err) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.OBSERVATION,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.OBSERVATION, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.OBSERVATION, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: Boolean(externalIds && externalIds.length > 0),
    }
  );
};
