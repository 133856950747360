import { addRecoilKey } from '@infield/utils/recoil';
import { atom } from 'recoil';

export const isDataLostWhileOfflineAtom = atom<boolean>({
  key: addRecoilKey('is-data-lost-while-offline'),
  default: false,
});

export const isClickedGotItForOfflineAtom = atom<boolean>({
  key: addRecoilKey('is-clicked-got-it-for-offline'),
  default: false,
});
