import type { APMAsset } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { Input, LoaderIcon, TagIcon } from '@cognite/cogs.js-v10';
import type { Asset } from '@cognite/sdk';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { SearchResult } from '@infield/features/search';
import { useOnClickOutside } from '@infield/features/ui';
import { useDebounce } from '@infield/hooks/use-debounce';
import { useSearch } from '@infield/hooks/use-search';
import { useFDMServices } from '@infield/providers/fdm-services';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';

import * as S from './elements';

const SEARCH_RESULT_LIMIT = 100;

export type SearchResultPosition = 'top' | 'bottom';
interface Props {
  selected?: Asset | APMAsset;
  helpText?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  searchResultPosition?: SearchResultPosition;
  onSelect?: (classicAsset?: Asset, apmAsset?: APMAsset) => void;
}

export const AssetSearchInput: FC<Props> = ({
  selected,
  helpText,
  label,
  disabled,
  required,
  searchResultPosition = 'bottom',
  onSelect,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.search);
  const { assetService } = useFDMServices();
  const [searchInput, setSearchInput] = useState<string>('');
  const [isLoadingSelected, setIsLoadingSelected] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const { isIdm } = useAppConfigContext();
  const debouncedQuery = useDebounce(searchInput, 750);
  const { assetSearchResult: searchResult } = useSearch({
    query: debouncedQuery,
    resourceType: 'asset',
    resultLimit: SEARCH_RESULT_LIMIT,
  });

  const outsideClickRef = useRef(null);

  function isClassicAsset(obj: any): obj is Asset {
    return Object.prototype.hasOwnProperty.call(obj, 'name');
  }

  const selectedName =
    selected && isClassicAsset(selected) ? selected?.name : selected?.title;

  useOnClickOutside(outsideClickRef, () => {
    setSearchInput(selectedName ?? '');
    setShowSearchResult(false);
  });

  useEffect(() => {
    if (selected && selectedName) {
      setSearchInput(selectedName);
    } else {
      setSearchInput('');
    }
    setIsLoadingSelected(false);
  }, [selected, selectedName]);

  const handleSelectAsset = async (asset: Asset | APMAsset) => {
    let apmAsset: APMAsset;
    if (!isIdm) {
      const classicAsset = asset as Asset;
      if (classicAsset.externalId === selected?.externalId) {
        setSearchInput(classicAsset.name);
        setShowSearchResult(false);
        return;
      }
      apmAsset = await assetService.getAPMAsset({
        equals: {
          property: 'externalId',
          eq: asset.externalId!,
        },
      });

      onSelect?.(classicAsset, apmAsset);
    } else {
      apmAsset = asset as APMAsset;
      if (apmAsset.externalId === selected?.externalId) {
        setSearchInput(apmAsset.title || apmAsset.externalId);
        setShowSearchResult(false);
        return;
      }
      onSelect?.(undefined, apmAsset);
    }

    if (!apmAsset) {
      makeToast({
        body: t(
          'ASSET_SEARCH_INPUT_NO_APM_ASSET',
          'Asset does not exist in APM'
        ),
        type: 'danger',
      });
      return;
    }

    setIsLoadingSelected(true);
    setShowSearchResult(false);
  };

  const handleSearchClear = () => {
    if (selected && selectedName && selectedName.length > 0) {
      onSelect?.(undefined);
      setSearchInput('');
    }
    setSearchInput('');
  };

  return (
    <S.SearchContainer>
      <div ref={outsideClickRef}>
        <Input
          data-testid="asset-search-input"
          fullWidth
          placeholder={t('ASSET_SEARCH_INPUT_PLACEHOLDER', 'Search assets')}
          clearable
          onFocus={() => setShowSearchResult(true)}
          label={
            label
              ? {
                  text: label,
                  required,
                }
              : undefined
          }
          icon={isLoadingSelected ? <LoaderIcon /> : <TagIcon />}
          value={searchInput}
          onChange={(e) => {
            if (e.type === 'click' || e.target.value === '') {
              handleSearchClear();
              return;
            }
            setSearchInput(e.target.value);
          }}
          helpText={helpText}
          disabled={disabled}
        />
        {showSearchResult &&
          searchResult.data &&
          searchResult.data.length > 0 && (
            <S.SearchResultWrapper
              $searchResultPosition={searchResultPosition}
              className="search-result-wrapper"
            >
              <SearchResult
                query={searchInput}
                queryResult={searchResult}
                onSelect={(selectedResource) =>
                  handleSelectAsset(selectedResource as Asset)
                }
              />
            </S.SearchResultWrapper>
          )}
      </div>
    </S.SearchContainer>
  );
};
