import type { Activity, Checklist } from '@cognite/apm-client';
import { ActivityDetails } from '@infield/features/activities';
import { Asset as AssetExploration } from '@infield/features/asset/asset-exploration';
import { ChecklistComponent } from '@infield/features/checklist';
import { useEffect, useState } from 'react';
import type { FC } from 'react';

import * as S from './elements';
import type { PanelViews } from './types';

type Props = {
  panelView: PanelViews;
  panelActivity?: Activity;
  panelChecklist?: Checklist;
  setPanelView: (nextPanelView: PanelViews) => void;
};

export const ActivityInformationSidebar: FC<Props> = ({
  panelView,
  panelActivity,
  panelChecklist,
  setPanelView,
}) => {
  const [assetExternalId, setAssetExternalId] = useState<string>('');

  const handleOnAssetClick = (externalId?: string) => {
    setPanelView('assetExploration');
    if (externalId) {
      setAssetExternalId(externalId);
    }
  };

  useEffect(() => {
    if (assetExternalId) {
      setAssetExternalId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelActivity?.externalId]);

  if (panelView === 'none') return null;

  if (
    panelView === 'assetExploration' &&
    !(assetExternalId || panelActivity?.assetExternalId)
  )
    return null;

  if (panelView === 'activityDetails' && !panelActivity?.externalId)
    return null;

  if (panelView === 'checklist' && !panelChecklist?.externalId) return null;

  return (
    <S.PanelViewWrapper>
      {panelView === 'assetExploration' &&
        (panelActivity?.assetExternalId || assetExternalId) && (
          <AssetExploration
            assetExternalId={panelActivity?.assetExternalId || assetExternalId}
            onClose={() => setPanelView('none')}
          />
        )}
      {panelView === 'activityDetails' && panelActivity?.externalId && (
        <ActivityDetails
          onClose={() => setPanelView('none')}
          onAssetClick={handleOnAssetClick}
          activityId={panelActivity.externalId}
        />
      )}
      {panelView === 'checklist' && panelChecklist?.externalId && (
        <ChecklistComponent
          onClose={() => setPanelView('none')}
          onAssetClick={handleOnAssetClick}
          checklistId={panelChecklist.externalId}
          onTitleClick={() => setPanelView('activityDetails')}
        />
      )}
    </S.PanelViewWrapper>
  );
};
