import Konva from 'konva';
import { $selectAll, type LexicalEditor } from 'lexical';
import { v4 as uuid } from 'uuid';

import UnifiedViewerEventType from '../../core/UnifiedViewerEventType';
import { AnnotationType, Position, Size } from '../annotations/types';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import { UnifiedViewer } from '../UnifiedViewer';
import { UnifiedViewerPointerEvent } from '../UnifiedViewerRenderer/UnifiedEventHandler';
import { SELECTION_RECTANGLE_STYLE } from '../UnifiedViewerTransformer';
import { isMouseEvent, MouseButton } from '../utils/eventUtils';
import getCaretRect from '../utils/getCaretRect';
import getFontSizeInAbsoluteUnits from '../utils/getFontSizeInAbsoluteUnits';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';
import createLexicalEditorAtDomNode, {
  setEditorContainerStyles,
} from '../utils/lexical/createLexicalEditorAtDomNode';
import getTextContent from '../utils/lexical/getTextContent';
import {
  fitViewportToHeight,
  fitViewportToWidth,
} from '../utils/viewportUtils';

import { ContainerAttachmentHelper } from './ContainerAttachmentHelper';
import getShapeEventPropsFromKonvaShape from './getShapeEventPropsFromKonvaShape';
import Tool from './Tool';
import { TextToolConfig, ToolType } from './types';

const DEFAULT_FONT_SIZE_RELATIVE_UNITS = 0.05;
const FIT_VIEWPORT_PARAMS = {
  scaleFactor: 2,
  setViewportDurationSeconds: 0.1,
};

const getDomNodeTransform = (
  { x, y }: Position,
  nodeScaleX: number,
  stageScale: number
): string => {
  return `translate(${x}px, ${y}px) scale(${stageScale * nodeScaleX})`;
};

const createEditorContainer = (
  node: Konva.Text,
  scaleFactor: number
): HTMLDivElement => {
  const editorContainer = document.createElement('div');
  editorContainer.contentEditable = 'true';
  editorContainer.style.fontSize = `${node.fontSize()}px`;
  // adding this negative margin to fix the jumping issue when switching to editing mode
  editorContainer.style.margin = '-2px -1px';
  editorContainer.style.position = 'absolute';
  editorContainer.style.overflow = 'hidden';
  editorContainer.style.background = 'transparent';
  editorContainer.style.outline = 'none';
  editorContainer.style.resize = 'none';
  editorContainer.style.lineHeight = `${node.lineHeight()}`;
  editorContainer.style.fontFamily = node.fontFamily();
  editorContainer.style.textAlign = node.align();
  editorContainer.style.color = node.fill();
  editorContainer.style.display = 'inline-block';
  editorContainer.style.boxSizing = 'border-box';
  editorContainer.style.padding = '1px';
  editorContainer.style.border = `dotted ${SELECTION_RECTANGLE_STYLE.stroke} 2px`;

  const { x, y } = node.getClientRect();
  editorContainer.style.transformOrigin = 'top left';
  editorContainer.style.transform = getDomNodeTransform(
    { x, y },
    1,
    scaleFactor
  );

  // Blocking some mouse events from propagating so that selection works without firing the text tool
  editorContainer.addEventListener('mousedown', (e) => e.stopPropagation());
  editorContainer.addEventListener('mouseup', (e) => e.stopPropagation());
  editorContainer.addEventListener('click', (e) => e.stopPropagation());

  return editorContainer;
};

const createTextEditor = (node: Konva.Text, scaleFactor: number) => {
  const container = createEditorContainer(node, scaleFactor);
  setEditorContainerStyles('text-tool-styles', container);
  const editor = createLexicalEditorAtDomNode(container, {
    initialText: node.text(),
    shouldWrapText: false,
  });
  return { editor, container };
};

const isTargetTextAnnotation = (e: UnifiedViewerPointerEvent): boolean => {
  return e.target.attrs.annotationType === AnnotationType.TEXT;
};

export default class TextTool extends Tool {
  public readonly type = ToolType.TEXT;
  public override readonly cursor = 'text';
  private config: TextToolConfig;
  private pendingNode: Konva.Text | undefined;
  private editor: LexicalEditor | undefined;
  private container: HTMLElement | undefined;
  private readonly STAGE_TRANSFORM_EVENTS = [
    UnifiedViewerEventType.ON_ZOOM_START,
    UnifiedViewerEventType.ON_ZOOM_CHANGE,
    UnifiedViewerEventType.ON_ZOOM_END,
    UnifiedViewerEventType.ON_PAN_START,
    UnifiedViewerEventType.ON_PAN_MOVE,
    UnifiedViewerEventType.ON_PAN_END,
  ];
  private containerAttachmentHelper: ContainerAttachmentHelper;

  public constructor({
    unifiedViewer,
    config,
  }: {
    unifiedViewer: UnifiedViewer;
    config: TextToolConfig;
  }) {
    super(unifiedViewer);
    this.containerAttachmentHelper = new ContainerAttachmentHelper(
      unifiedViewer
    );
    this.config = config;
    this.setConfig(config);

    this.STAGE_TRANSFORM_EVENTS.forEach((event) =>
      this.unifiedViewer.addEventListener(
        event,
        this.updateTextElementsTransforms
      )
    );

    this.containerAttachmentHelper.start();
  }

  public setConfig = (config: TextToolConfig): void => {
    this.config = config;
  };

  private getRelativePointerPosition = (): Position | null => {
    return this.unifiedViewer.stage.getRelativePointerPosition();
  };

  public override onMouseUp = (e: UnifiedViewerPointerEvent): void => {
    // Re-focus the (editable) text element once panning, using the right mouse button, is done
    if (isMouseEvent(e.evt) && e.evt.button === MouseButton.SECONDARY) {
      this.editor?.focus();
    }
  };

  public override onMouseDown = (e: UnifiedViewerPointerEvent): void => {
    e.target.stopDrag();

    if (this.pendingNode !== undefined) {
      this.onEditTextComplete();
      return;
    }

    const currentPosition = this.getRelativePointerPosition();
    if (currentPosition === null) {
      return;
    }

    const originalFontSize =
      this.config?.fontSize ?? DEFAULT_FONT_SIZE_RELATIVE_UNITS;
    const fontSizeValue = getFontSizeInAbsoluteUnits(originalFontSize);

    if (isTargetTextAnnotation(e)) {
      this.onEditText(e.target as Konva.Text);
      return;
    }

    const pendingNode = new Konva.Text({
      ...this.config,
      fontSize: fontSizeValue,
      originalFontSize,
      [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
      id: uuid(),
      userGenerated: true,
      name: 'user-drawing',
      source: this.type,
      strokeScaleEnabled: false,
      containerId: undefined,
      annotationType: AnnotationType.TEXT,
      isSelectable: true,
      isDraggable: true,
      isResizable: true,
      text: '',
      x: currentPosition.x,
      y: currentPosition.y,
    });
    this.containerAttachmentHelper.lockTarget();
    this.onEditText(pendingNode);
  };

  private updateTextElementsTransforms = (): void => {
    if (this.pendingNode === undefined || this.container === undefined) {
      return;
    }
    const { x, y } = this.pendingNode.getClientRect();
    const transform = getDomNodeTransform(
      { x, y },
      this.pendingNode.scaleX(),
      this.unifiedViewer.stage.scaleX()
    );
    this.container.style.transform = transform;
  };

  private onEditableTextKeyDown = (e: KeyboardEvent) => {
    // We don't want to propagate the keydown event to the stage since it would
    // for instance trigger shortcuts both in the text annotation and the viewer (such as CMD + Z to undo)
    e.stopPropagation();

    if (e.key === 'Escape') {
      this.onEditTextComplete();
      return;
    }

    if (e.metaKey && e.key === 'Enter') {
      this.onEditTextComplete();
      return;
    }
  };

  private calculateUnscaledTextSize = (): Size | undefined => {
    const textBound = this.container?.getBoundingClientRect();
    if (textBound === undefined) {
      return undefined;
    }
    const scale = this.unifiedViewer.getScale();
    return {
      width: textBound.width / scale,
      height: textBound.height / scale,
    };
  };

  private fitViewportToTextIfCaretIsOutOfBounds = (): void => {
    if (this.pendingNode === undefined) {
      return;
    }

    const caretRect = getCaretRect();
    if (caretRect === undefined) {
      return;
    }

    const unscaledTextSize = this.calculateUnscaledTextSize();
    if (unscaledTextSize === undefined) {
      return;
    }

    const stage = this.unifiedViewer.stage;
    const textPosition = this.pendingNode.getAbsolutePosition(stage);

    const { x: caretX } = caretRect; // We only need x since the range is collapsed (i.e., width === 0)
    if (caretX < 0 || caretX >= stage.width()) {
      fitViewportToWidth({
        ...FIT_VIEWPORT_PARAMS,
        ...textPosition,
        viewer: this.unifiedViewer,
        targetWidth: unscaledTextSize.width,
      });
      return;
    }

    const { bottom: caretBottom, top: caretTop } = caretRect;
    if (caretTop < 0 || caretBottom >= stage.height()) {
      fitViewportToHeight({
        ...FIT_VIEWPORT_PARAMS,
        ...textPosition,
        viewer: this.unifiedViewer,
        targetHeight: unscaledTextSize.height,
      });
      return;
    }
  };

  public onEditText = (node: Konva.Text): void => {
    if (this.pendingNode !== undefined) {
      this.onEditTextComplete();
    }

    this.pendingNode = node;
    this.unifiedViewer.setDirtyNodes([this.pendingNode]);
    node.hide();
    const { container, editor } = createTextEditor(
      node,
      this.unifiedViewer.stage.scaleX()
    );
    editor.registerRootListener((rootElement, prevRootElement) => {
      prevRootElement?.removeEventListener(
        'keydown',
        this.fitViewportToTextIfCaretIsOutOfBounds
      );
      rootElement?.addEventListener(
        'keydown',
        this.fitViewportToTextIfCaretIsOutOfBounds
      );
    });

    this.container = container;
    this.editor = editor;
    this.unifiedViewer.host.appendChild(container);
    container.addEventListener('keydown', this.onEditableTextKeyDown);

    setTimeout(() => {
      this.editor?.focus();
      this.editor?.update(() => {
        $selectAll();
      });
    });
  };

  private onEditTextComplete = () => {
    if (this.pendingNode !== undefined && this.editor !== undefined) {
      const nextText = getTextContent(this.editor.getEditorState());
      this.pendingNode.show();
      this.pendingNode.text(nextText);
      this.containerAttachmentHelper.end([this.pendingNode]);
      this.unifiedViewer.commitDirtyNodes();
      getMetricsLogger()?.trackEvent(TrackedEventType.CREATE_TEXT, {
        ...getShapeEventPropsFromKonvaShape(this.pendingNode),
        text: this.pendingNode.text(),
        fontSize: this.pendingNode.fontSize(),
      });

      const isStringWhitespaceOnly = nextText.trim() === '';
      if (isStringWhitespaceOnly) {
        this.unifiedViewer.emit(UnifiedViewerEventType.ON_DELETE_REQUEST, {
          containerIds: [],
          annotationIds: [this.pendingNode.id()],
        });
      }

      this.pendingNode = undefined;
    }

    if (this.container !== undefined && this.container.parentNode !== null) {
      this.container.parentNode.removeChild(this.container);
      this.container = undefined;
    }

    this.containerAttachmentHelper.start();
  };

  public override onDestroy(): void {
    this.STAGE_TRANSFORM_EVENTS.forEach((event) =>
      this.unifiedViewer.removeEventListener(
        event,
        this.updateTextElementsTransforms
      )
    );
    this.onEditTextComplete();
    this.containerAttachmentHelper.onDestroy();
  }
}
