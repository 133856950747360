import type { DatagridColumn, GridFilterOptionsType } from '@cognite/cogs-lab';
import type { Filters } from '@cognite/fdm-client';
import { useFlag } from '@cognite/react-feature-flags';
import type { DataGridRow } from '@infield/features/activities/activity-planning/maintenance-table/types';
import { useActivityViewFields } from '@infield/features/activities/hooks';
import { getStandardTableColumns } from '@infield/features/activities/utils';
import { getCustomerSpecificColumns } from '@infield/features/activities/utils/getCustomerSpecificColumns';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';

import { useMaintenanceTableFilterOptions } from './use-maintenance-table-filter-options';

// This hook does the following:

// 1. Gets filterOptions for locations and customer-specific activity fields
// 2. Gets standard columns for the maintenance table
// 3. Gets customer-specific columns for the maintenance table
// 4. Currently also returns setSearchInput and isBackendSearchEnabled for the maintenance table, can try to refactor these out if possible
export const useMaintenanceTableDynamicColumns = (
  fetchMode: 'search' | 'list',
  selectedFilterLocations: GridFilterOptionsType[],
  handleTitleClick: () => void,
  handleLocationClick: () => void,
  handleChecklistCellClick: (row: DataGridRow) => void,
  handleLocationFilterSearch: (search: string) => void,
  dateFilters?: Filters
): {
  standardColumns: DatagridColumn[];
  customerSpecificColumns: DatagridColumn[];
  isLoading: boolean;
  setSearchInput: (search: string) => void;
  isBackendSearchEnabled: boolean;
} => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const { isEnabled: isAkerbpCustomCode } = useFlag(
    'INFIELD.akerbp_custom_code',
    { forceRerender: true, fallback: false }
  );

  const {
    data: { fields } = { fields: [] },
    isLoading: isLoadingActivityViewFields,
  } = useActivityViewFields();

  const {
    customerSpecificFilterOptionsMap,
    activityTypeFilterOptions,
    locationFilterOptions,
    isLoading: isLoadingFilterOptions,
    setSearchInput,
    isBackendSearchEnabled,
  } = useMaintenanceTableFilterOptions(
    fields,
    selectedFilterLocations,
    dateFilters
  );

  const standardColumns = getStandardTableColumns({
    gridFilterActivityTypeOptions: activityTypeFilterOptions,
    locationFilterOption: locationFilterOptions,
    isLoadingFilterLocationOptions: isLoadingFilterOptions,
    isAkerbpCustomCode,
    fetchMode,
    t,
    handleTitleClick,
    handleLocationClick,
    handleChecklistCellClick,
    handleLocationFilterSearch,
  });

  const customerSpecificColumns = getCustomerSpecificColumns({
    activityFields: fields,
    standardColumnNames: standardColumns.map((col) => col.field),
    fetchMode,
    isLoadingFilterLocationOptions: isLoadingFilterOptions,
    filterOptions: customerSpecificFilterOptionsMap,
    gridFilterActivityTypeOptions: activityTypeFilterOptions,
    locationFilterOptions,
    handleTitleClick,
    handleLocationClick,
    handleLocationFilterSearch,
  });

  return {
    standardColumns,
    customerSpecificColumns,
    isLoading: isLoadingActivityViewFields,
    setSearchInput,
    isBackendSearchEnabled,
  };
};
