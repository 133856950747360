import { Infobox } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { ComponentScrollableContent } from '@infield/features/ui';
import { AssetExplorationView } from '@infield/pages/asset/asset';
import { useBackButtonUrlContext } from '@infield/providers/back-button-url-provider';
import type { FC } from 'react';

interface Props {
  assetExternalId?: string;
}

export const AssetDetailsView: FC<Props> = ({ assetExternalId }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);
  const { navigateBack } = useBackButtonUrlContext();

  const handleClose = () => {
    navigateBack();
  };

  if (!assetExternalId)
    return (
      <Infobox status="warning">
        {t('noValidAssetIdInURL', 'No valid assetId was passed in URL')}
      </Infobox>
    );

  return (
    <ComponentScrollableContent>
      <AssetExplorationView
        assetExternalId={assetExternalId}
        onClose={handleClose}
      />
    </ComponentScrollableContent>
  );
};
