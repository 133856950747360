import { useAuthContext } from '@cognite/e2e-auth';
import type { Asset } from '@cognite/sdk';
import { isProduction } from '@infield/features/auth';
import { captureException } from '@sentry/react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { assetsQueryKeys } from '../assetsQueryKeys';

export const useAssetsByIdsQuery = <TData = Asset[]>(
  ids: number[],
  options: UseQueryOptions<Asset[], unknown, TData> = {}
) => {
  const { client } = useAuthContext();
  const uniqueIds = [...new Set(ids)];
  return useQuery<Asset[], unknown, TData>(
    assetsQueryKeys.byIds(uniqueIds),
    () => {
      return client.assets.retrieve(uniqueIds.map((id) => ({ id })));
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: JSON.stringify(assetsQueryKeys.byIds(uniqueIds)),
          },
        }),
      placeholderData: [],
      ...options,
      enabled: options.enabled !== false && Boolean(ids.length),
    }
  );
};

const assetByIdSelectFn = (assets: Asset[]) => assets[0];

export const useAssetByIdQuery = (
  assetId: number | null,
  options: UseQueryOptions<Asset[], unknown, Asset> = {}
) => {
  if (!isProduction() && options.select) {
    throw new Error('implement reselect in useAssetByIdQuery');
  }
  return useAssetsByIdsQuery<Asset>([assetId!], {
    onError: (err) => captureException(err),
    enabled: typeof assetId === 'number',
    ...options,
    select: assetByIdSelectFn,
  });
};
