import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { clamp, keyBy, uniqBy } from 'lodash';
import { v4 as uuid } from 'uuid';

import getElbowConectionPathPoints from '../../annotations/PolylineSerializer/getElbowedConnectionPathPoints';
import {
  isCauseMapNodeAnnotationNode,
  isPolylineAnnotation,
  PolylineAnnotation,
} from '../../annotations/types';
import { UpdateRequestSource, type UnifiedViewer } from '../../UnifiedViewer';
import UnifiedViewerEventType from '../../UnifiedViewerEventType';
import type UnifiedViewerRenderer from '../../UnifiedViewerRenderer';
import assertNever from '../../utils/assertNever';
import { isPrimaryMouseButtonPressed } from '../../utils/eventUtils';
import getMetricsLogger, {
  TrackedEventType,
} from '../../utils/getMetricsLogger';
import getRectRelativeToStage from '../../utils/getRectRelativeToStage';
import isNotUndefined from '../../utils/isNotUndefined';
import {
  type AnchorName,
  ANCHOR_STYLE,
  DEFAULT_ANCHOR_PADDING_PX,
} from '../Anchor/constants';
import getAnchorCross from '../Anchor/getAnchorCross';
import getOffsetAnchorPosition from '../Anchor/getOffsetAnchorPosition';
import { getPreviewNodeOffset } from '../StickyAnchorHelper/getPreviewNodeOffset';
import getPreviewNode from '../StickyTool/getPreviewNode';

import {
  TARGET_ARROW_CONFIG,
  TARGET_NODE_PROPS,
  TO_ANCHOR_POINT,
  FROM_ANCHOR_POINT,
  MAX_ANCHOR_SCALE,
  MIN_ANCHOR_PADDING_PX,
  MAX_ANCHOR_PADDING_PX,
  TOP_AND_BOTTOM_ANCHORS,
  ALL_ANCHORS,
} from './constants';

type Connection = { from: Konva.Node; to: Konva.Node };

const createTargetNode = (
  targetCauseMapNode: Konva.Label,
  name: AnchorName
): Konva.Label => {
  // Offset the node so that it's centered on targetCauseMapNode
  const xOffset =
    targetCauseMapNode.width() / 2 - TARGET_NODE_PROPS.tag.width / 2;
  const yOffset =
    targetCauseMapNode.height() / 2 - TARGET_NODE_PROPS.tag.height / 2;
  const targetNode = getPreviewNode({
    label: {
      ...targetCauseMapNode.getAttrs(),
      ...TARGET_NODE_PROPS.label,
      id: `generated-cause-map-node-${uuid()}`,
      name: name,
      visible: true,
      x: targetCauseMapNode.x() + xOffset,
      y: targetCauseMapNode.y() + yOffset,
    },
    tag: TARGET_NODE_PROPS.tag,
    text: TARGET_NODE_PROPS.text,
  });
  targetNode.opacity(0);

  return targetNode;
};

class CauseMapAnchorHelper {
  private viewer: UnifiedViewer;
  private renderer: UnifiedViewerRenderer;
  private anchors: Konva.Group[] | undefined = undefined;
  private targetCauseMapNode: Konva.Label | undefined = undefined; // This is the cause map node we want to attach the anchors to
  private connectionAnnotationsToUpdate: PolylineAnnotation[] | undefined =
    undefined;
  private targetArrows: Konva.Arrow[] | undefined = undefined;
  private targetNode: Konva.Label | undefined = undefined;

  public constructor(viewer: UnifiedViewer, renderer: UnifiedViewerRenderer) {
    this.viewer = viewer;
    this.renderer = renderer;

    this.viewer.addEventListener(
      UnifiedViewerEventType.ON_ZOOM_CHANGE,
      this.applyScaleToAnchors
    );
    this.viewer.addEventListener(
      UnifiedViewerEventType.ON_ZOOM_END,
      this.applyScaleToAnchors
    );
  }

  private clearAnchors = (): void => {
    this.anchors?.forEach((anchor) => anchor.destroy());
    this.anchors = undefined;
  };

  private clearTargetNode = (): void => {
    this.targetNode?.destroy();
    this.targetNode = undefined;
  };

  private clearTargetArrows = (): void => {
    this.targetArrows?.forEach((arrow) => arrow.destroy());
    this.targetArrows = undefined;
    this.connectionAnnotationsToUpdate = undefined;
  };

  public clear = (): void => {
    this.clearAnchors();
    this.clearTargetNode();
    this.clearTargetArrows();
  };

  private commitNodes = (nodes: Konva.Node[]): void => {
    const connectionAnnotationsToUpdate =
      this.connectionAnnotationsToUpdate ?? [];
    const annotations = nodes.map(this.renderer.getAnnotationFromNode);
    this.viewer.onActiveToolComplete();
    this.viewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      source: UpdateRequestSource.CAUSE_MAP_ANCHOR,
      annotations: [...annotations, ...connectionAnnotationsToUpdate],
      containers: [],
    });

    this.clearTargetNode();
    this.clearTargetArrows();
  };

  private createConnectionArrows = (connections: Connection[]) =>
    connections.map(
      ({ from, to }) =>
        new Konva.Arrow({
          ...TARGET_ARROW_CONFIG,
          id: `target-arrow-${uuid()}`,
          fromId: from.id(),
          toId: to.id(),
          points: getElbowConectionPathPoints(
            { ...getRectRelativeToStage(from), anchorPoint: FROM_ANCHOR_POINT },
            { ...getRectRelativeToStage(to), anchorPoint: TO_ANCHOR_POINT }
          ),
        })
    );

  private getConnectionsToAddAndUpdate = (
    name: AnchorName,
    node: Konva.Label,
    targetNode: Konva.Label
  ): {
    connectionsToAdd: Connection[];
    connectionAnnotationsToUpdate?: PolylineAnnotation[];
  } => {
    // Add a new sibling node
    if (name === 'bottom-center' || name === 'top-center') {
      if (this.isRootNode(node)) {
        return {
          connectionsToAdd: this.getChildNodes(node).map((childNode) => ({
            from: targetNode,
            to: childNode,
          })),
        };
      }
      return {
        connectionsToAdd: this.getParentNodes(node).map((parentNode) => ({
          from: parentNode,
          to: targetNode,
        })),
      };
    }

    // Add a new root node
    if (name === 'middle-left') {
      const parentNodes = this.getParentNodes(node);
      if (parentNodes.length === 0) {
        return { connectionsToAdd: [{ from: targetNode, to: node }] };
      }

      const parentNodeIds = new Set(
        parentNodes.map((parentNode) => parentNode.id())
      );
      return {
        connectionsToAdd: parentNodes.map((parentNode) => ({
          from: parentNode,
          to: targetNode,
        })),
        connectionAnnotationsToUpdate: this.renderer
          .getAnnotations()
          .filter(isPolylineAnnotation)
          .filter(
            ({ fromId, toId }) =>
              fromId !== undefined &&
              toId !== undefined &&
              parentNodeIds.has(fromId) &&
              toId === node.id()
          )
          .map((annotation) => ({
            ...annotation,
            fromId: targetNode.id(),
            toId: node.id(),
          })),
      };
    }

    if (name === 'middle-right') {
      const childNodes = this.getChildNodes(node);
      if (childNodes.length === 0) {
        return { connectionsToAdd: [{ from: node, to: targetNode }] };
      }

      const childNodeIds = new Set(
        childNodes.map((childNode) => childNode.id())
      );
      return {
        connectionsToAdd: childNodes.map((childNode) => ({
          from: targetNode,
          to: childNode,
        })),
        connectionAnnotationsToUpdate: this.renderer
          .getAnnotations()
          .filter(isPolylineAnnotation)
          .filter(
            ({ fromId, toId }) =>
              fromId !== undefined &&
              toId !== undefined &&
              fromId === node.id() &&
              childNodeIds.has(toId)
          )
          .map((annotation) => ({
            ...annotation,
            fromId: node.id(),
            toId: targetNode.id(),
          })),
      };
    }
    assertNever(name, 'Unexpected anchor name');
  };

  private attachEventHandlersToAnchor = (
    name: AnchorName,
    anchor: Konva.Group,
    originalCauseMapNode: Konva.Label
  ): void => {
    const onMouseOver = (event: KonvaEventObject<MouseEvent>) => {
      this.viewer.setActiveToolCursor('pointer');

      const target = event.currentTarget;
      if (!(target instanceof Konva.Group)) {
        return;
      }
      target.add(getAnchorCross());
      const viewerScale = this.getClampedViewerScale();
      const hoveredAnchorScale =
        ANCHOR_STYLE.hoveredRadius / ANCHOR_STYLE.radius;
      const nextScale = hoveredAnchorScale / viewerScale;
      target.scale({ x: nextScale, y: nextScale });

      this.targetNode = createTargetNode(originalCauseMapNode, name);
      const offset = getPreviewNodeOffset(this.targetNode, name);
      this.targetNode.position({
        x: offset.x + this.targetNode.x(),
        y: offset.y + this.targetNode.y(),
      });

      this.viewer.layers.transformer.add(this.targetNode);
      this.targetNode.moveToBottom();

      const { connectionsToAdd, connectionAnnotationsToUpdate } =
        this.getConnectionsToAddAndUpdate(
          name,
          originalCauseMapNode,
          this.targetNode
        );
      this.connectionAnnotationsToUpdate = connectionAnnotationsToUpdate;
      this.targetArrows = this.createConnectionArrows(connectionsToAdd);

      this.viewer.layers.transformer.add(...this.targetArrows);
      this.targetArrows.forEach((arrow) => arrow.moveToBottom());
    };

    const onMouseOut = (event: KonvaEventObject<MouseEvent>) => {
      this.viewer.setActiveToolCursor('default');

      const target = event.currentTarget;
      if (!(target instanceof Konva.Group)) {
        return;
      }
      target
        .getChildren()
        .filter((child) => child.name() === ANCHOR_STYLE.crossName)
        .forEach((child) => child.destroy());
      const scale = this.getClampedViewerScale();
      target.scale({ x: 1 / scale, y: 1 / scale });
      this.clearTargetNode();
      this.clearTargetArrows();
    };

    const onClick = () => {
      this.viewer.setActiveToolCursor('default');
      if (this.targetNode === undefined || this.targetArrows === undefined) {
        return;
      }

      this.targetArrows.forEach((arrow) => arrow.opacity(1));
      this.commitNodes([this.targetNode, ...this.targetArrows]);
      getMetricsLogger()?.trackEvent(
        TrackedEventType.CAUSE_MAP_ANCHOR_PRESSED,
        { anchorName: this.targetNode?.name() }
      );
    };

    const onMouseDown = (event: KonvaEventObject<MouseEvent>) => {
      if (event.target.id() !== anchor.id()) {
        return;
      }
      if (!isPrimaryMouseButtonPressed(event.evt)) {
        return;
      }
      // Prevent the stage from registering the click on the anchor
      event.evt.stopPropagation();
      this.viewer.getTransformer()?.setNodes([]);
    };

    anchor.on('mousedown', onMouseDown);
    anchor.on('mouseover', onMouseOver);
    anchor.on('mouseout', onMouseOut);
    anchor.on('click', onClick);
  };

  private updateAnchorPosition = (anchor: Konva.Group): void => {
    const scale = this.getClampedViewerScale();
    const position = getOffsetAnchorPosition({
      anchor,
      target: this.targetCauseMapNode,
      anchorPaddingPx: clamp(
        DEFAULT_ANCHOR_PADDING_PX / scale,
        MIN_ANCHOR_PADDING_PX,
        MAX_ANCHOR_PADDING_PX
      ),
    });
    if (position === undefined) {
      return;
    }
    anchor.position(position);
  };

  private getClampedViewerScale = () =>
    Math.max(this.viewer.getScale(), MAX_ANCHOR_SCALE);

  private createAnchor = (name: AnchorName): Konva.Group => {
    if (this.targetCauseMapNode === undefined) {
      throw new Error(
        'targetCauseMapNode cannot be undefined when creating an anchor'
      );
    }
    const anchor = new Konva.Group({ anchorName: name, listening: true });
    anchor.add(new Konva.Circle({ ...ANCHOR_STYLE }));

    const scale = this.getClampedViewerScale();
    anchor.scale({ x: 1 / scale, y: 1 / scale });
    this.updateAnchorPosition(anchor);

    this.attachEventHandlersToAnchor(name, anchor, this.targetCauseMapNode);
    return anchor;
  };

  private getIncomingAnnotations = (node: Konva.Node) => {
    const annotationsById = keyBy(this.renderer.getAnnotations(), 'id');
    return uniqBy(
      Object.values(annotationsById).filter(
        (annotation): annotation is PolylineAnnotation =>
          isPolylineAnnotation(annotation) &&
          annotation.toId === node.id() &&
          annotation.fromId !== undefined &&
          annotationsById[annotation.fromId] !== undefined
      ),
      ({ fromId, toId }) => `${fromId}-${toId}`
    );
  };

  private getOutgoingAnnotations = (node: Konva.Node) => {
    const annotationsById = keyBy(this.renderer.getAnnotations(), 'id');
    return uniqBy(
      Object.values(annotationsById).filter(
        (annotation): annotation is PolylineAnnotation =>
          isPolylineAnnotation(annotation) &&
          annotation.fromId === node.id() &&
          annotation.toId !== undefined &&
          annotationsById[annotation.toId] !== undefined
      ),
      ({ fromId, toId }) => `${fromId}-${toId}`
    );
  };

  private hasMultipleParents = (node: Konva.Node) =>
    this.getIncomingAnnotations(node).length > 1;

  private getParentNodes = (node: Konva.Node): Konva.Node[] => {
    const parentNodeIds = this.getIncomingAnnotations(node)
      .map(({ fromId }) => fromId)
      .filter(isNotUndefined);
    return parentNodeIds
      .map((id) => this.renderer.getAnnotationNodeById(id))
      .filter(isNotUndefined);
  };

  private getChildNodes = (node: Konva.Node): Konva.Node[] => {
    const childNodeIds = this.getOutgoingAnnotations(node)
      .map(({ toId }) => toId)
      .filter(isNotUndefined);
    return childNodeIds
      .map((id) => this.renderer.getAnnotationNodeById(id))
      .filter(isNotUndefined);
  };

  private isRootNode = (node: Konva.Node): boolean =>
    this.getIncomingAnnotations(node).length === 0;

  public refreshAnchors = (nodes: Konva.Node[]): void => {
    if (nodes.length !== 1 || !isCauseMapNodeAnnotationNode(nodes[0])) {
      this.targetCauseMapNode = undefined;
      this.clear();
      return;
    }

    const causeMapNode = nodes[0];
    // Create new anchors only if we're targetting a new cause map node
    if (this.targetCauseMapNode?.id() !== causeMapNode.id()) {
      this.clear();

      this.targetCauseMapNode = causeMapNode;
      // If the root node has no children or more than one child, or if a node
      // has multiple parents, we don't show the top/bottom anchors because
      // adding sibling nodes to such nodes is not well defined.
      const anchorNames =
        (this.isRootNode(causeMapNode) &&
          this.getOutgoingAnnotations(causeMapNode).length !== 1) ||
        this.hasMultipleParents(causeMapNode)
          ? TOP_AND_BOTTOM_ANCHORS
          : ALL_ANCHORS;
      this.anchors = anchorNames.map(this.createAnchor);
      this.targetCauseMapNode.on('dragstart transformstart', this.clear);

      this.viewer.layers.transformer.add(...this.anchors);
      return;
    }

    // Otherwise, re-use the previous anchors
    this.anchors?.forEach(this.updateAnchorPosition);
    this.targetCauseMapNode.on('dragstart transformstart', this.clear);
  };

  private applyScaleToAnchors = (scale: number): void => {
    this.anchors?.forEach((anchor) => {
      const clampedScale = Math.max(scale, MAX_ANCHOR_SCALE);
      const newScale = { x: 1 / clampedScale, y: 1 / clampedScale };
      anchor.scale(newScale);
      this.updateAnchorPosition(anchor);
    });
  };
}

export default CauseMapAnchorHelper;
