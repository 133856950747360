import type { APMAsset } from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client/src/types';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useAPMAssetQuery = (externalId?: string) => {
  const { assetService } = useFDMServices();

  return useQuery<APMAsset | undefined>(
    [QueryKeys.APM_ASSET, externalId],
    async () => {
      if (externalId) {
        const filters: Filters = {
          equals: {
            property: 'externalId',
            eq: externalId,
          },
        };
        const [asset] = await assetService.listAPMAssets(filters);
        return asset;
      }
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: QueryKeys.APM_ASSET,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(externalId),
    }
  );
};
