import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const TagContainer = styled(Flex).attrs({
  gap: 8,
})`
  padding: 8px 16px;
  min-height: 44px;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
`;
