import type { InFieldLocationConfig } from '@cognite/apm-client';
import { AppInfieldSpecificConfig } from '@cognite/apm-config';
import { useAuthContext } from '@cognite/e2e-auth';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

export const useInFieldLocationConfigFromService = (
  rootLocationExternalId?: string
) => {
  const { client } = useAuthContext();
  const infieldConfigService = new AppInfieldSpecificConfig(client);

  return useQuery<InFieldLocationConfig | null, Error>(
    [
      QueryKeys.INFIELD_LOCATION_CONFIG_FROM_SERVICE,
      rootLocationExternalId ?? 'undefined',
    ],
    async () => {
      if (!rootLocationExternalId) {
        return null;
      }

      const response =
        await infieldConfigService.retrieveByADSLocationExternalId(
          rootLocationExternalId
        );

      return response;
    }
  );
};
