const modifySvgPlotClipPathToInternalUrlReference = (svg: SVGElement): void => {
  const svgDef = svg.querySelector('defs');
  const defId = svgDef?.id.split('-')[1];
  if (defId === undefined || defId.length === 0) {
    return;
  }
  const plot = svg.querySelector('.plot') as SVGGElement | null;
  if (plot === null) {
    return;
  }
  plot.style.clipPath = `url(#clip${defId}xyplot)`;
};

export default modifySvgPlotClipPathToInternalUrlReference;
