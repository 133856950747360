import { Body, Flex } from '@cognite/cogs.js-v10';
import { z } from '@infield/utils/z';
import styled from 'styled-components';

// Needed to make the infobar appear on top of the 'GlobalOverlay' component
export const Container = styled(Flex)<{ $isChecklistLoaded: boolean }>`
  background-color: white;
  opacity: 1;
  z-index: ${({ $isChecklistLoaded }) =>
    $isChecklistLoaded
      ? z.INFOBAR_LOADED_CHECKLIST_OVERLAY
      : z.INFOBAR_OVERLAY};
`;

export const TryAgainButtonWrapper = styled(Flex)`
  margin-top: 12px;
`;

export const ProgressBarWrapper = styled(Flex).attrs({
  direction: 'column',
  gap: 4,
})`
  margin-top: 12px;

  > .progress-bar {
    background-color: white;
  }
`;

export const ProgressText = styled(Body).attrs({ size: 'x-small' })`
  color: ${({ theme }) => theme['text-icon--muted']};
`;
