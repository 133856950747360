import styled from 'styled-components';

export type ProgressType = 'neutral' | 'success';

export const Container = styled.div<{ rounded?: boolean; type?: ProgressType }>`
  height: 12px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme, type }) =>
    type === 'success'
      ? theme['surface--status-success--muted--default']
      : theme['surface--status-neutral--muted--default']};

  ${({ rounded }) =>
    rounded && `border-radius: var(--cogs-border-radius--large);`}
`;

interface ProgressProps {
  progress: number;
  type?: ProgressType;
  rounded?: boolean;
}

export const Progress = styled.div<ProgressProps>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: ${({ theme, type }) =>
    type === 'success'
      ? theme['surface--status-success--strong--default']
      : theme['surface--status-neutral--strong--default']};
  width: ${(props) => props.progress}%;
  transition: width 0.25s linear;

  ${({ rounded }) =>
    rounded && `border-radius: var(--cogs-border-radius--large);`}
`;
